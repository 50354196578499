import { filterArrayByAnotherArray } from '../../utils/array.util';
import { IFloor, IFloorsListReducerState } from '../../interfaces';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SORT_ORDER } from '@shared/constants/constants';
import { entitiesProperties } from '../../constants';

import { sortFloors } from '@shared/utils/sort.utils';

const initialState: IFloorsListReducerState = {
	floorsList: [],
	floorsFilterList: [],
	visibleFloorsList: [],
};

const floorListSlice = createSlice({
	name: 'floorList',
	initialState,
	reducers: {
		updateFloorsList: (state: IFloorsListReducerState, action: PayloadAction<{ floors: IFloor[] }>) => {
			const floorsList: IFloor[] = sortFloors(action.payload.floors, SORT_ORDER.DESCENDING);
			state.visibleFloorsList = floorsList;
			state.floorsList = floorsList;
			state.floorsFilterList = [];
		},
		setFloorsListFilter: (state: IFloorsListReducerState, action: PayloadAction<{ filterList: IFloor[] }>) => {
			state.visibleFloorsList = filterArrayByAnotherArray(
				state.floorsList,
				action.payload.filterList,
				entitiesProperties.floorId
			);
			state.floorsFilterList = action.payload.filterList;
		},
		resetFilteredFloorsList: (state: IFloorsListReducerState) => {
			state.floorsFilterList = [];
			state.visibleFloorsList = state.floorsList;
		},
	},
});

export const { resetFilteredFloorsList, setFloorsListFilter, updateFloorsList } = floorListSlice.actions;
export default floorListSlice.reducer;
