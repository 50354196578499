export interface ICommentShared {
	commentId: string;
	dateCreated: Date;
	sender: {
		name: string;
		username: string;
	};
	content: {
		type: IssueCommentType;
		value: string;
		sizeInBytes?: number;
		filename?: string;
	};
}

export enum IssueCommentType {
	IMAGE = 'image',
	TEXT = 'text',
	PDF = 'pdf',
}
