import React, { useContext } from 'react';
import classes from './styles.module.scss';
import { ISequence } from '@shared/interfaces/ISequence';
import { SequenceTypeDropdown } from '@shared/components/SequenceTypeDropDown/SequenceTypeDropdown';
import { SequenceTypeEnum } from '@shared/interfaces/SequenceType.enum';
import { useSequenceSequenceItemsQuery } from '@src/hooks/queries/sequenceItem.query';
import classnames from 'classnames';
import { WorkplanContext } from '@src/Components/Pages/Workplan/WorkPlan';
import { updateSequence } from '@utils/sequence.utils';
import { SequenceNameChange } from '@src/Components/Pages/Workplan/ProgressTrackerTab/SequenceSelectionSection/SequenceSelectionItem/SequenceNameChange';

interface ISequenceItemSectionProps {
	sequence?: ISequence;
	onClick: (sequence: ISequence) => void;
	isSelected?: boolean;
	closeDropdown?: () => void;
}

export const SequenceSelectionItem = (props: ISequenceItemSectionProps) => {
	const { setSequences } = useContext(WorkplanContext)!;
	const { sequenceItems, isLoading } = useSequenceSequenceItemsQuery(props.sequence?._id);

	const canChangeSequenceType: boolean = !isLoading && sequenceItems.length === 0;

	const changeSequenceType = async (sequenceType: SequenceTypeEnum) => {
		if (!canChangeSequenceType) return;
		try {
			await updateSequence(props.sequence!._id, { sequenceType }, setSequences);
		} catch (e) {
			console.log(e);
		}
	};

	if (!props.sequence) return null;

	return (
		<div
			className={classnames(classes.SequenceSelectionItem_container, { [classes.selected]: props.isSelected })}
			onClick={() => props.onClick(props.sequence!)}
			data-testid="SequenceSelectionSection"
		>
			<SequenceTypeDropdown
				onClickSideEffect={props.closeDropdown}
				readOnly={!canChangeSequenceType}
				onSelect={changeSequenceType}
				currentSequenceType={props.sequence.sequenceType}
				projectId={props.sequence.projectId}
			/>
			<SequenceNameChange sequence={props.sequence} />
		</div>
	);
};
