import { IRowProperty } from '@interfaces/IRowProperty';
import { translationService } from '@src/servicesInitializers';
import { ISequenceItemProgressBySequenceItemReport } from '@interfaces/ISequenceItemProgressBySequenceItemReport';
import { ISortingState } from '@interfaces/ISortingState';
import { orderBy } from 'lodash';
import { ITableTitleItems } from '@src/interfaces';
import { ETACell } from '@src/Components/SequenceProgressAnalysis/ActivitiesProgress/ActivitiesProgressTable/cellComponents/ETACell';
import { NameCell } from '@src/Components/SequenceProgressAnalysis/ActivitiesProgress/ActivitiesProgressTable/cellComponents/NameCell';
import { ProfessionCell } from '@src/Components/SequenceProgressAnalysis/ActivitiesProgress/ActivitiesProgressTable/cellComponents/ProfessionCell';
import { OverallProgressCell } from '@src/Components/SequenceProgressAnalysis/ActivitiesProgress/ActivitiesProgressTable/cellComponents/OverallProgressCell';
import { AverageWeeklyProgressRateCell } from '@src/Components/SequenceProgressAnalysis/ActivitiesProgress/ActivitiesProgressTable/cellComponents/AverageWeeklyProgressRateCell';
import { stringToNumberBetweenRange } from '@utils/generalUtils';
import { getTradeGroupTranslation, getTradeTranslation } from '@utils/translations.utils';
import moment from 'moment';

export const getOverallProgressPercentage = (report: ISequenceItemProgressBySequenceItemReport): number => {
	const percentage = (report.overallProgress.completedAsiInPeriod / report.overallProgress.totalAsisInPeriod) * 100;

	const isCompleted: boolean = isProgressReportCompleted(report);
	const roundedPercentage: number = Number(percentage.toFixed(0));
	if (!isCompleted && roundedPercentage === 100) {
		return 99;
	}

	return roundedPercentage;
};

export const isProgressReportCompleted = (report: ISequenceItemProgressBySequenceItemReport): boolean => {
	return report.overallProgress.completedAsiInPeriod === report.overallProgress.totalAsisInPeriod;
};
export const shouldShowProgressTrend = (report: ISequenceItemProgressBySequenceItemReport): boolean => {
	const overallProgressPercentage: number = getOverallProgressPercentage(report);
	const isCompleted: boolean = isProgressReportCompleted(report);

	return !(report.trendPercentage == null || isCompleted || overallProgressPercentage < 2);
};

export const isRateBelowThreshold = (report: ISequenceItemProgressBySequenceItemReport): boolean => {
	const overallProgressPercentage: number = getOverallProgressPercentage(report);

	return overallProgressPercentage < 1;
};

export const isETABelowThreshold = (report: ISequenceItemProgressBySequenceItemReport): boolean => {
	const overallProgressPercentage: number = getOverallProgressPercentage(report);

	return overallProgressPercentage < 1;
};

export const getActivitiesProgressTableRowProperties = (): IRowProperty[] => {
	return [
		{ component: NameCell },
		{ component: ProfessionCell },
		{
			component: OverallProgressCell,
			center: true,
		},
		{ component: AverageWeeklyProgressRateCell },
		{ component: ETACell },
	];
};

export const getActivitiesProgressTableTitles = (): ITableTitleItems => ({
	name: { title: 'name' },
	profession: { title: 'profession' },
	overallProgress: { title: 'overallProgress', isTextCentered: true },
	avgWeeklyProgressRate: {
		title: 'avgWeeklyProgressRate',
		isTextCentered: true,
		tooltipText: translationService.get('avgWeeklyProgressRateTooltip'),
	},
	simulatedEta: {
		title: 'simulatedEta',
		tooltipText: translationService.get('progressEtaTooltip'),
	},
});

export const maxActivitiesProgressTableColumnsWidth: string[] = ['280px', '200px', '200px', '196px', '200px'];
export const minActivitiesProgressTableColumnsWidth = ['150px', '150px', '140px', '176px', '170px'];

export const getSortedReports = (
	reports: ISequenceItemProgressBySequenceItemReport[],
	sortColumn: string,
	sortOrder: ISortingState['sortOrder'],
	activitiesProgressTableTitles: ITableTitleItems
): ISequenceItemProgressBySequenceItemReport[] => {
	switch (sortColumn) {
		case activitiesProgressTableTitles.name.title:
			return orderBy(reports, [(report) => report.description], [sortOrder]);
		case activitiesProgressTableTitles.profession.title:
			return orderBy(
				reports,
				[
					(report) => report.profession.sortIndex,
					(report) => getTradeGroupTranslation(report.profession),
					(report) => getTradeTranslation(report.profession),
					(report) => report.profession.specialty,
					(report) => report.profession.contractor,
				],
				[sortOrder, sortOrder, sortOrder, sortOrder, sortOrder]
			);

		case activitiesProgressTableTitles.overallProgress.title:
			return orderBy(
				reports,
				[(report) => report.overallProgress.completedAsiInPeriod / report.overallProgress.totalAsisInPeriod],
				[sortOrder]
			);

		case activitiesProgressTableTitles.avgWeeklyProgressRate.title:
			return orderBy(
				reports,
				[
					(report) => isProgressReportCompleted(report) || isRateBelowThreshold(report),
					(report) => report.avgWeeklyProgressRate,
					(report) => report.trendPercentage,
				],
				[sortOrder, sortOrder]
			);

		case activitiesProgressTableTitles.simulatedEta.title:
			return orderBy(
				reports,
				[
					(report) => !isProgressReportCompleted(report),
					(report) =>
						!isProgressReportCompleted(report) &&
						(report.estimatedWeeksToComplete === null ||
							report.etaDate === null ||
							isETABelowThreshold(report) ||
							report.estimatedWeeksToComplete <= 0),
					(report) => report.estimatedWeeksToComplete,
				],
				[sortOrder, sortOrder, sortOrder]
			);
		default:
			return reports;
	}
};

export const getDemoAverageWeeklyProgressRate = (report: ISequenceItemProgressBySequenceItemReport): number => {
	return Number(stringToNumberBetweenRange(`${report.description}${report.sequenceItemId}`, 0.7, 4.5).toFixed(1));
};

export const getDemoEstimatedWeeksToComplete = (report: ISequenceItemProgressBySequenceItemReport): number => {
	const asisLeftToComplete: number =
		report.overallProgress.totalAsisInPeriod - report.overallProgress.completedAsiInPeriod;
	const weeklyRate: number = getDemoAverageWeeklyProgressRate(report);
	return Math.ceil(asisLeftToComplete / weeklyRate);
};

export const getDemoTrendPercentage = (report: ISequenceItemProgressBySequenceItemReport): number => {
	return Number(
		stringToNumberBetweenRange(`${report.description}${report.sequenceItemId}${report.profession._id}`, -35, 50)
	);
};

export const getDemoAdjustedProgressReports = (
	tz: string,
	reports: ISequenceItemProgressBySequenceItemReport[]
): ISequenceItemProgressBySequenceItemReport[] => {
	return reports.map((report) => {
		const estimatedWeeksToComplete: number = getDemoEstimatedWeeksToComplete(report);
		const etaDate: Date = moment.tz(tz).add(estimatedWeeksToComplete, 'weeks').toDate();
		return {
			...report,
			etaDate,
			estimatedWeeksToComplete,
			avgWeeklyProgressRate: getDemoAverageWeeklyProgressRate(report),
			trendPercentage: getDemoTrendPercentage(report),
		};
	});
};
