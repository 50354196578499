import React from 'react';
import classes from '../styles.module.scss';
import { GeneralDialog } from '@src/Components/GeneralDialog/GeneralDialog';
import { translationService } from '@src/servicesInitializers';

interface ISequenceLinkWarningDialogProps {
	show: boolean;
	hideDialog: () => void;
	actionFunction: () => void;
	title: string;
	content: string;
}

export const SequenceLinkWarningDialog = (props: ISequenceLinkWarningDialogProps) => {
	return (
		<GeneralDialog
			show={props.show}
			close={props.hideDialog}
			title={props.title}
			mainButton={{
				text: translationService.get('sequence_table_warning_dialog_confirm_button'),
				show: true,
				click: () => {
					props.actionFunction();
					props.hideDialog();
				},
			}}
			secondaryButton={{
				text: translationService.get('cancel'),
				show: true,
				click: props.hideDialog,
			}}
		>
			<div className={classes.dialogContent}>
				<p>{props.content}</p>
			</div>
		</GeneralDialog>
	);
};
