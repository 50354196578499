import React from 'react';
import { BaseNotificationCard } from '../BaseNotificationCard/BaseNotificationCard';
import classes from './styles.module.scss';
import { INotification } from '../../../interfaces/INotification';
import { TranslationService } from '../../../services/translation.service';
import { RequestService } from '../../../services/request.service';
import { isTrusstorMobile } from '../../../utils/mobile.utils';
import { StorageService } from '../../../services/storage.service';
import { IIssueStatusUpdatedNotificationMetadata } from '../../../interfaces/IIssueStatusUpdatedNotificationMetadata';
import { IconNames } from '../../TrusstorIconShared/IconNames.enum';
import { IconColor, TrusstorIconShared } from '../../TrusstorIconShared/TrusstorIconShared';
import { IssueStatusChip } from '../../Issue/IssueStatusChip/IssueStatusChip';
import { getIssueStatusUpdatedTitleText } from './IssueStatusUpdatedCardNotifications.texts';

interface ICommentAddedCardProps {
	notification: INotification;
	storageService: StorageService;
	translationService: TranslationService;
	tz: string;
	requester: RequestService;
	handleCardClick: (id: string) => void;
}

export const getIssueStatusUpdateTitleElement = (
	metadata: IIssueStatusUpdatedNotificationMetadata,
	translationService: TranslationService,
	isDarkTheme?: boolean
): React.ReactNode => {
	const titleClass = `${classes.title} ${isDarkTheme ? classes.titleDarkTheme : ''}`;
	return <div className={titleClass}>{getIssueStatusUpdatedTitleText(translationService, metadata)}</div>;
};

export const getIssueStatusUpdatedDescriptionElement = (
	metadata: IIssueStatusUpdatedNotificationMetadata
): React.ReactNode => {
	return (
		<div style={{ width: '100px' }}>
			<IssueStatusChip status={metadata.newStatus} />
		</div>
	);
};

export const getIssueStatusUpdatedIconElement = (): React.ReactNode => {
	return <TrusstorIconShared iconName={IconNames.issues} color={IconColor.White} />;
};

export const IssueStatusUpdatedCard = (props: ICommentAddedCardProps) => {
	const metadata: IIssueStatusUpdatedNotificationMetadata = props.notification
		.metadata as IIssueStatusUpdatedNotificationMetadata;

	const handleCardClick = () => {
		const issueId: string = metadata.issueId;
		if (!issueId || issueId === 'undefined') {
			return;
		}
		props.handleCardClick(issueId);
	};

	return (
		<BaseNotificationCard
			icon={getIssueStatusUpdatedIconElement()}
			titleElement={getIssueStatusUpdateTitleElement(metadata, props.translationService)}
			descriptionElement={getIssueStatusUpdatedDescriptionElement(metadata)}
			createdAt={props.notification.createdAt}
			tz={props.tz}
			translationService={props.translationService}
			requester={props.requester}
			notificationId={props.notification._id}
			handleCardClick={handleCardClick}
			isViewed={props.notification.isViewed}
			isClickable={!isTrusstorMobile() && !!metadata.issueId && metadata.issueId !== 'undefined'}
		/>
	);
};
