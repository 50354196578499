import { TranslationService } from '../../../services/translation.service';
import { IIssueAssigneeUpdatedNotificationMetadata } from '../../../interfaces/IIssueAssigneeUpdatedNotificationMetadata';

export const getIssueAssignedTitleText = (translationService: TranslationService): string => {
	return translationService.get('issueAssigned_notification_card');
};

export const getIssueAssignedDescriptionText = (metadata: IIssueAssigneeUpdatedNotificationMetadata): string => {
	return metadata.issueDetails.description;
};
