import { exportService, requestService, translationService } from '../index';
import moment, { Moment } from 'moment';
import {
	ICalendarSection,
	IFloorChipData,
	IFloorWithSomeDescription,
	IProfession,
	IUser,
	IWeekDayActivity,
	IWorkPlanActivity,
} from '../interfaces';
import { IRow } from '../services';
import { CalendarToggleOptions } from '../constants';
import { getProfessionDisplayText } from './professions.utils';
import { filterArrayByAnotherArray, mixArrayWithAnotherArray } from './array.util';
import { store } from '../store/store';
import { IActivityLabel } from '../interfaces/IActivityLabel';
import { compact, sum, uniqBy } from 'lodash';
import { IFloor } from '@shared/interfaces/IFloor';
import { IBaseFloorWithWorkTime } from '@shared/interfaces/IBaseFloorWithWorkTime';
import { getFloorsChipFromFloorsList } from '@shared/utils/floorsSelection.utils';
import { getProductRoundedHoursFromHours, getProductRoundedHoursFromMs } from '@shared/utils/roundNumbers.utils';
import { ActivityGroupStatus } from '@shared/interfaces/ActivityGroupStatus.enum';
import { getColorFromActivityGroupStatus } from '@utils/generalUtils';
import { getTimezoneFormattedDate } from '@utils/dateUtils';
import { getVisibleActivities } from '@utils/workPlan.utils';
import { IActivityGroupStatusFilterItem } from '@interfaces/IActivityGroupStatusFilterItem';
import { ISequence } from '@shared/interfaces/ISequence';

const CALENDER_DAY_FORMAT = 'ddd';
let writtenActivities: { [sectionId: string]: boolean } = {};
const lineBreak = '\r\n';

export const exportCalendarToExcel = async (
	isFormatted: boolean,
	projectId: string,
	sections: ICalendarSection[],
	datesRange: (Date | number)[],
	calendarMode: CalendarToggleOptions,
	excelFileName: string,
	tz: string,
	visibleStaticUsers: IUser[],
	visibleProfessions: IProfession[],
	visibleFloors: IFloor[],
	visibleLabels: IActivityLabel[],
	selectedActivityGroupStatuses: IActivityGroupStatusFilterItem[],
	selectedSequences: ISequence[]
) => {
	const datesHeaders: string[] = datesRange.map((date: Date | number) => {
		const momentDate: Moment = moment.tz(date, tz);
		const day: string = momentDate.format(CALENDER_DAY_FORMAT);

		const formattedDate: string = `${momentDate.format('MMM')} ${momentDate.format('Do')}`;
		return `${day} ${lineBreak} ${formattedDate}`;
	});
	const excelName: string = `${excelFileName} - ${store.getState().projectReducer?.workingProject?.name}`;
	const excelTimes: string = `${moment.tz(datesRange[0], tz).format(translationService.getDateFormat())} - ${moment
		.tz(datesRange[datesRange.length - 1], tz)
		.format(translationService.getDateFormat())}`;
	const fullExcelName: string = `${excelName} - ${excelTimes}`;
	const HEADLINE: string = excelFileName.replaceAll('_', ' ');

	const projectIdFilter: string = `projectId=${projectId}`;
	const fromDate: string = getTimezoneFormattedDate(tz, datesRange[0]);
	const toDate: string = getTimezoneFormattedDate(tz, datesRange[datesRange.length - 1]);
	const dateFilter: string = `fromDate=${fromDate}&toDate=${toDate}`;
	const sortByFilter: string = `sortBy=${calendarMode}`;
	// Needs to be this api because it returns all floor activities for each day (24.10.2023)
	const workPlanActivities: { [section: string]: IWeekDayActivity[][] } = await requestService.get(
		`/activities?${projectIdFilter}&${dateFilter}&${sortByFilter}`
	);

	const visibleActivities = getVisibleActivities(
		visibleStaticUsers,
		visibleProfessions,
		visibleFloors,
		visibleLabels,
		selectedActivityGroupStatuses,
		selectedSequences,
		workPlanActivities
	);
	const resultRows: IRow[] = [];
	for (const section of sections) {
		if (!visibleActivities[section.id]) {
			continue;
		}
		resultRows.push(
			...Object.values(visibleActivities[section.id]).flatMap((currentDayActivities) => {
				return calendarMode === CalendarToggleOptions.PROFESSIONS
					? handleSectionWhenProfessionsToggle(
							isFormatted,
							currentDayActivities,
							datesHeaders,
							datesRange,
							section,
							visibleFloors,
							visibleProfessions,
							tz
						)
					: handleSectionWhenFloorsToggle(currentDayActivities, datesHeaders, datesRange, section, tz);
			})
		);
	}
	await exportService.exportMultipleSheetsToExcel(
		{ [fullExcelName]: resultRows },
		fullExcelName,
		HEADLINE,
		excelTimes,
		{
			centerAligned: true,
			...(isFormatted && {
				disableRowHeight: true,
				mergeRowsEqualCells: true,
				mergeFirstColumnEqualRowCells: true,
				headerColor: 'FFFFFF',
				cellColor: 'ffffff',
				headerFontSize: 12,
				firstColumnWidth: 20,
			}),
		},
		true
	);
	writtenActivities = {};
};

const handleSectionWhenProfessionsToggle = (
	isFormatted: boolean,
	currentDayActivities: IWeekDayActivity[],
	datesHeaders: string[],
	datesRange: (Date | number)[],
	section: ICalendarSection,
	floorList: IFloor[],
	professions: IProfession[],
	tz: string
): IRow[] => {
	const rows: IRow[] = [];
	for (const activity of currentDayActivities) {
		if (writtenActivities[activity.groupId!]) {
			continue;
		}
		const row: IRow = {};
		const fullProfession: IProfession = professions.find((profession) => section.id === profession._id)!;
		row[translationService.get('profession')] = {
			value: getProfessionDisplayText(fullProfession),
		};
		datesRange.forEach((date: Date | number, dateRangeIndex: number) => {
			const isActivityNotInRange =
				moment.tz(activity.startDate, tz).isAfter(date) ||
				(moment.tz(date, tz).isAfter(moment.tz(tz)) && moment.tz(activity.endDate, tz).isBefore(date));
			if (isActivityNotInRange) {
				row[getRowKey(datesHeaders, dateRangeIndex)] = { value: '' };
				return;
			}
			const allActivitiesFromGroup = currentDayActivities.filter(
				(currActivity) => currActivity.groupId === activity.groupId
			);

			const floors = getActivitiesFloors(allActivitiesFromGroup);
			const floorsSuffix: string = getFloorSequenceSuffix(floors, floorList);
			const descriptionSuffix: string = `${translationService.get(
				floors.length > 0 ? 'floors' : 'floor'
			)} ${floorsSuffix}`;
			row[getRowKey(datesHeaders, dateRangeIndex)] = {
				value: `${activity.description} ${lineBreak} ${descriptionSuffix}`,
				...(isFormatted && { color: getColorFromActivityGroupStatus(activity.status) }),
			};
		});
		rows.push(row);
		writtenActivities[activity.groupId!] = true;
	}
	return rows;
};

const handleSectionWhenFloorsToggle = (
	currentDayActivities: IWeekDayActivity[],
	datesHeaders: string[],
	datesRange: (Date | number)[],
	section: ICalendarSection,
	tz: string
): IRow[] => {
	const rows: IRow[] = [];
	for (const activity of currentDayActivities) {
		if (writtenActivities[activity._id]) {
			continue;
		}
		const row: IRow = {};

		row[translationService.get('floor')] = {
			value: section.title,
			id: section.id,
		};

		row[translationService.get('label')] = {
			value: activity.label?.text || '',
		};
		row[translationService.get('responsibleManager')] = {
			value: activity.assignee || '',
		};

		datesRange.forEach((date: Date | number, dateRangeIndex: number) => {
			if (shouldNotDisplayActivityInDate(activity, tz, new Date(date))) {
				row[getRowKey(datesHeaders, dateRangeIndex)] = { value: '' };
				return;
			}
			const descriptionSuffix: string = getProfessionDisplayText(activity.profession);
			row[getRowKey(datesHeaders, dateRangeIndex)] = {
				value: `${activity.description} - ${descriptionSuffix}`,
			};
		});
		rows.push(row);
		writtenActivities[activity._id] = true;
	}
	return rows;
};

const shouldNotDisplayActivityInDate = (assignedActivity: IWeekDayActivity, tz: string, currDate: Date): boolean =>
	moment.tz(assignedActivity.startDate, tz).isAfter(currDate) ||
	(moment.tz(currDate, tz).isAfter(moment.tz(tz)) &&
		(assignedActivity.status === ActivityGroupStatus.overdue ||
			assignedActivity.status === ActivityGroupStatus.delayed)) ||
	(moment.tz(assignedActivity.endDate, tz).isBefore(currDate) &&
		assignedActivity.status !== ActivityGroupStatus.overdue &&
		assignedActivity.status !== ActivityGroupStatus.delayed);

const getRowKey = (arr: string[], index: number): string => {
	return arr[index];
};

const getActivitiesFloors = (activities: IWeekDayActivity[]): IFloorWithSomeDescription[] => {
	return activities.map((activity) => {
		if (activity.areas.length > 0) {
			return { ...activity.floor, areas: [...activity.areas] };
		}
		return activity.floor;
	});
};

const getFloorSequenceSuffix = (activityFloors: IActivityFloors[], floorList: IFloor[]): string => {
	const finalArray = getFloorSequence(activityFloors, floorList);
	return getFloorSequenceDisplay(finalArray);
};

const getFloorSequence = (activityFloors: IActivityFloors[], floorList: IFloor[]): string[][] => {
	const sortedFloors: IFloor[] = [...floorList].reverse();
	const floorsWithIndex: FloorWithIndex[] = sortedFloors.map((floor, index) => ({ ...floor, index }));
	const sortedActivityFloorsFiltered: FloorWithIndex[] = filterArrayByAnotherArray(
		floorsWithIndex,
		activityFloors,
		'floorId'
	);
	const fullSortedActivityFloors: FloorWithIndex[] = mixArrayWithAnotherArray(
		sortedActivityFloorsFiltered,
		activityFloors,
		'floorId'
	);
	const finalArray: string[][] = [];
	let currentSequence: string[] = [];
	let j = 0;
	const lastIndex = fullSortedActivityFloors[fullSortedActivityFloors.length - 1].index;
	for (let i = 0; i <= lastIndex; i++) {
		const floorIndex = fullSortedActivityFloors[j].index;
		if (floorIndex === i) {
			currentSequence.push(getFloorNickWithAreas(fullSortedActivityFloors[j]));
			j++;
			if (i === lastIndex) {
				finalArray.push(currentSequence);
			}
			continue;
		}
		if (currentSequence.length === 0) {
			continue;
		}
		finalArray.push(currentSequence);
		currentSequence = [];
	}
	return finalArray;
};

const getFloorNickWithAreas = (floor: IFloorWithSomeDescription): string => {
	const areasNicksStr: string = floor.areas?.map((area) => area.areaNick).join(', ') || '';
	return `${floor.shortFloorNick || ''} ${areasNicksStr ? `(${areasNicksStr})` : ''}`;
};

const getFloorSequenceDisplay = (finalArray: string[][]): string => {
	return finalArray
		.map((floors) => {
			if (floors.length > 2) {
				return `${floors[0]} - ${floors[floors.length - 1]}`;
			}
			if (floors.length === 2) {
				return `${floors[0]}, ${floors[1]}`;
			}
			return `${floors[0]}`;
		})
		.join(', ');
};

export const getLabelsFromWorkPlanActivities = <T extends IWorkPlanActivity>(workPlanActivities: {
	[section: string]: T[][];
}): IActivityLabel[] => {
	const activities: T[] = Object.values(workPlanActivities || {})
		.map((weekActivities: T[][]) => weekActivities.flat())
		.flat();
	const labels: IActivityLabel[] = compact(activities.map((activity: T) => activity.label));
	return uniqBy(labels, (label: IActivityLabel) => label._id);
};

export const getFloorsDisplayText = (activity: IWeekDayActivity): string => {
	const floorsDisplayText: string = getFloorsChipFromFloorsList(activity.floors ?? [activity.floor])
		.map((floorChipData: IFloorChipData) => {
			const fromFloorId: string = floorChipData.fromFloor.floorId;
			const toFloorId: string = floorChipData.toFloor.floorId;
			if (fromFloorId === toFloorId) {
				return floorChipData.label;
			}
			const floorRangeText: string = translationService.getIsRtl()
				? `${toFloorId} - ${fromFloorId}`
				: `${fromFloorId} - ${toFloorId}`;
			return `${translationService.get('floors')} ${floorRangeText}`;
		})
		.join(', ');

	return floorsDisplayText;
};

export const getTotalWorkTimeForWeekDayActivity = (activity: IWeekDayActivity, isViewByFloor?: boolean): number => {
	const activityFloors: IBaseFloorWithWorkTime[] = activity.floors;
	if (isViewByFloor) {
		const floorWorkTime =
			activityFloors.find((floor: IBaseFloorWithWorkTime) => floor.floorId === activity.floor.floorId)
				?.workTime || 0;
		return getProductRoundedHoursFromMs(floorWorkTime);
	}
	const floorsSpentHours: number[] = activityFloors.map((floor: IBaseFloorWithWorkTime) =>
		getProductRoundedHoursFromMs(floor.workTime)
	);
	return getProductRoundedHoursFromHours(sum(floorsSpentHours));
};

export const getNumberOfActiveFloors = (floors: IBaseFloorWithWorkTime[]): number => {
	return floors.filter((floor) => floor.workTime > 0).length;
};

interface FloorWithIndex extends IFloor {
	index: number;
}

interface IActivityFloors {
	floorId: string;
	floorNick: string;
}
