import { IssueStatus } from '../../../interfaces/IIssueShared';
import { TranslationService } from '../../../services/translation.service';

export const getStatusToUpdate = (
	isLimitedUser: boolean,
	issueStatus: IssueStatus,
	handleReviewCallback: () => void
): IssueStatus | void => {
	if (isLimitedUser) {
		return issueStatus === IssueStatus.FOR_REVIEW ? IssueStatus.PLANNED : IssueStatus.FOR_REVIEW;
	}
	if (issueStatus === IssueStatus.FOR_REVIEW) {
		handleReviewCallback();
		return;
	}
	return issueStatus === IssueStatus.COMPLETED ? IssueStatus.PLANNED : IssueStatus.COMPLETED;
};

export const getCTAText = (
	isLimitedUser: boolean,
	issueStatus: IssueStatus,
	translationService: TranslationService
): string => {
	if (isLimitedUser) {
		if (issueStatus === IssueStatus.REJECTED) {
			return translationService.get('limitedUser_Issue_rejectedCta');
		}
		return translationService.get(
			issueStatus === IssueStatus.FOR_REVIEW
				? 'limitedUser_Issue_undoReviewCta'
				: 'limitedUser_Issue_ForReviewCta'
		);
	}
	if (issueStatus === IssueStatus.FOR_REVIEW) {
		return translationService.get('unlimitedUser_Issue_doReviewCta');
	}
	if (issueStatus === IssueStatus.REJECTED) {
		return translationService.get('unlimitedUser_Issue_completeCta');
	}
	return translationService.get(
		issueStatus === IssueStatus.COMPLETED
			? 'unlimitedUser_Issue_undoCompleteCta'
			: 'unlimitedUser_Issue_completeCta'
	);
};
