import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classes from './styles.module.scss';
import { IObserveLocationReport } from '@shared/interfaces/IObserveLocationReport';
import { Languages } from '@shared/constants/languages';
import { getDateFormattedWithTimeFromTimestamp } from '@shared/utils/dateUtils';
import { selectTimezone } from '../../../../../store/slices/project.slice';
import { translationService } from '../../../../../index';
import { RecentReportCard } from '../RecentReportCard';
import { getLocationReportRoute } from '@utils/analysisCenter.utils';
import { analysisCenterReportGeneration_BI } from '@utils/bi.utils';
import { InvestigationReportTypes } from '@shared/interfaces/InvestigationReportTypes.enum';

interface ILocationReportCardProps {
	investigationReport: IObserveLocationReport;
}

const RecentLocationReportCard = (props: ILocationReportCardProps) => {
	const tz: string = useSelector(selectTimezone)!;
	const history = useHistory();

	const getLocationText = (): string => {
		if (props.investigationReport.location.length > 1) {
			return props.investigationReport.location[0].floorNick;
		}
		return `${props.investigationReport.location[0].floorNick}, ${props.investigationReport.location[0].areaNick}`;
	};

	const getFloorAndAreaData = (): JSX.Element => {
		return (
			<>
				<div className={classes.floorSection}>
					<div className={classes.floorName}>{getLocationText()}</div>
				</div>
				<div className={classes.divSeparator}></div>
				<div>
					{getDateFormattedWithTimeFromTimestamp(
						props.investigationReport.investigatedTimestamp,
						false,
						translationService.getChosenLanguage() === Languages.HEBREW,
						tz,
						translationService.getDateLocale()
					)}
				</div>
			</>
		);
	};

	const redirectToInvestigationReport = (): void => {
		analysisCenterReportGeneration_BI(props.investigationReport.investigationType, 'recent', [
			new Date(props.investigationReport.investigatedTimestamp),
		]);

		history.push(getLocationReportRoute(props.investigationReport._id!));
	};

	return (
		<RecentReportCard
			reportId={props.investigationReport._id!}
			investigationReport={props.investigationReport}
			redirectToReport={redirectToInvestigationReport}
			dateGenerated={props.investigationReport.dateGenerated}
		>
			{getFloorAndAreaData()}
		</RecentReportCard>
	);
};

export { RecentLocationReportCard };
