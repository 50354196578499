import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TitleSectionWithExportButton } from './ReportsComponents/ReportsSections/TitleSectionWithExportButton/TitleSectionWithExportButton';
import { translationService } from '../../../index';
import { makeStyles } from '@material-ui/core';
import { PeriodPickerSection } from './ReportsComponents/ReportsSections/PeriodPickerSection/PeriodPickerSection';
import { useDispatch, useSelector } from 'react-redux';
import { IProfession } from '../../../interfaces';
import { TrusstorBox } from '../../Boxes/Containers/TrusstorBox';
import { WorkersPerDaySection } from './ReportsComponents/ProfessionReport/WorkersPerDaySection';
import { HeaderBox } from '../../Boxes/Containers/HeaderBox/HeaderBox';
import { ActivityDistributionTable } from './ReportsComponents/Tables/ActivityDistributionTable/ActivityDistributionTable';
import { ActivitiesGanttTable } from './ReportsComponents/Tables/ActvitiesGantTable/ActivitiesGanttTable';
import { TitleColoredBox } from '../../Boxes/Containers/TitleColoredBox/TitleColoredBox';
import { IActivitiesProfessionReport, IActivitiesReport } from '../../../interfaces/IActivitiesReport';
import { ActivityHoursPerFloorGraph } from './ReportsComponents/Charts/ActivityHoursPerFloorGraph';
import { IRootState } from '../../../store/slices';
import {
	getFloorsList,
	getProductivityPeriodicReport,
	getProfessionPeriodicReport,
	stopGettingProductivityReport,
} from '../../../store/thunks';
import { getActivityPeriodicReport, stopGettingActivityReport } from '../../../store/thunks/activityReport.thunks';
import { useWorkingProfessions } from '../../../hooks/useWorkingProfessions.hook';
import { SummaryTypes } from '../../../interfaces/SummaryTypes.enum';
import {
	IRoundedProductivityProfessionReport,
	IRoundedProductivityReport,
} from '../../../interfaces/IRoundedProductivityReport';
import {
	IRoundedProfessionsAnalysisReport,
	IRoundedProfessionsAnalysisReportData,
} from '../../../interfaces/IRoundedProfessionsAnalysisReport';
import { SIDE_MAIN_COMPONENT_CLASS_NAME } from '../../../constants';
import { useInvestigationReportQuery } from '@src/hooks/queries/analysisCenter.queries.hooks';
import { IProjectOverviewReport } from '@shared/interfaces/IProjectOverviewReport';
import { IContractorAnalysisReport } from '@shared/interfaces/IContractorAnalysisReport';
import { Loader } from '@shared/components/Loader/Loader';
import arrow from '@shared/assets/icons/ArrowRight.svg';
import classNames from 'classnames';
import { addOneSelectedProfession, resetSelectedProfessions } from '@store/slices/professions.slice';
import { useRenderMonitoring } from '@shared/hooks/monitoring.hooks';
import { SingleProfessionGroupedDropdown } from '@src/Components/Dropdowns/Profession/SingleProfessionGroupedDropdown/SingleProfessionGroupedDropdown';

const useStyles = makeStyles((theme) => ({
	wholePageContainer: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	pageContainer: {
		display: 'flex',
		width: '100%',
		height: '100%',
		overflow: 'hidden',
	},
	dataContainer: {
		display: 'flex',
		flexDirection: 'column',
		width: '85%',
		marginLeft: theme.spacing(1.5),
		marginRight: theme.spacing(1.5),
		marginBottom: theme.spacing(1),
		marginTop: theme.spacing(1),
		overflowX: 'auto',
	},
	secondLineContainer: {
		marginTop: theme.spacing(2),
		display: 'flex',
		justifyContent: 'space-between',
		height: 300,
		width: '100%',
	},
	titleSectionWithExportContainer: {
		marginTop: theme.spacing(1),
	},
	activityHoursPerFloor: {
		marginTop: theme.spacing(4),
		width: '100%',
		height: 250,
		paddingRight: 0,
		paddingLeft: 0,
		marginRight: 0,
		marginLeft: 0,
	},
	unsetHeight: {
		height: 'unset',
	},
	contractorSideMainComponent: {
		minWidth: '240px',
	},
	backArrowContainer: {
		'minHeight': 32,
		'width': 32,
		'cursor': 'pointer',
		'borderRadius': 24,
		'backgroundColor': '#ffffff',
		'display': 'flex',
		'alignItems': 'center',
		'justifyContent': 'center',

		'&:hover': {
			backgroundColor: '#E7E9EB',
		},
	},
	backArrow: {
		height: 24,
		width: 24,
	},
	transform: {
		transform: 'rotate(180deg)',
	},
	professionsFilterContainer: {
		padding: '0 16px',
	},
}));

const ProfessionAnalysisReportPage = (props: { match }) => {
	useRenderMonitoring('ProfessionAnalysisReportPage');
	const dispatch = useDispatch();
	const classes = useStyles();
	const history = useHistory();
	const reportId: string = props.match.params.reportId;
	const tz: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.tz);
	const projectId: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.projectId);
	const [startDate, endDate]: [number, number] = useSelector(
		(state: IRootState) => state.datePickerReducer.visiblePeriodTimestamps
	);
	const roundedProfessionReport: IRoundedProfessionsAnalysisReport = useSelector(
		(state: IRootState) => state.professionReportReducer.roundedProfessionReport
	);
	const activityReportRounded: IActivitiesReport = useSelector(
		(state: IRootState) => state.activityReportReducer.activityReportRounded
	);
	const roundedProductivityReport: IRoundedProductivityReport = useSelector(
		(state: IRootState) => state.productivityReportReducer.roundedProductivityReport
	);

	const [selectedProfession, setSelectedProfession] = useState<IProfession | undefined>();
	const [visibleProfessionReportData, setVisibleProfessionReportData] =
		useState<IRoundedProfessionsAnalysisReportData>({} as IRoundedProfessionsAnalysisReportData);
	const [visibleProfessionActivitiesReport, setVisibleProfessionActivitiesReport] =
		useState<IActivitiesProfessionReport>({} as IActivitiesProfessionReport);
	const [visibleProfessionProductivityReport, setVisibleProfessionProductivityReport] =
		useState<IRoundedProductivityProfessionReport>({} as IRoundedProductivityProfessionReport);

	const { investigationReport: currentReport, isInvestigationReportLoading } =
		useInvestigationReportQuery<IContractorAnalysisReport>(reportId);

	useEffect(() => {
		if (currentReport?.profession) {
			setSelectedProfession(currentReport.profession);
		}
	}, [currentReport]);
	useEffect(() => {
		dispatch(getFloorsList(projectId));
	}, []);

	useWorkingProfessions({
		includeDeleted: false,
	});

	useEffect(() => {
		dispatch(getProfessionPeriodicReport(projectId, startDate, endDate, tz));
		dispatch(getActivityPeriodicReport(projectId, startDate, endDate, tz, true));
		dispatch(getProductivityPeriodicReport(projectId, [startDate, endDate], tz));

		return () => {
			dispatch(stopGettingActivityReport());
			dispatch(stopGettingProductivityReport());
		};
	}, [projectId, startDate, endDate]);

	useEffect(() => {
		if (selectedProfession) {
			setVisibleProfessionReportData(roundedProfessionReport[selectedProfession._id] ?? {});
			setVisibleProfessionActivitiesReport(activityReportRounded[selectedProfession._id] ?? {});
			setVisibleProfessionProductivityReport(roundedProductivityReport[selectedProfession._id] ?? {});
		}
	}, [roundedProfessionReport, selectedProfession, activityReportRounded]);

	const handleBackClick = () => {
		history.goBack();
	};

	const isRtl: boolean = translationService.getIsRtl();

	if (isInvestigationReportLoading) return <Loader />;
	return (
		<div className={classes.wholePageContainer}>
			<div className={classes.pageContainer}>
				<div className={`${SIDE_MAIN_COMPONENT_CLASS_NAME} ${classes.contractorSideMainComponent}`}>
					<SingleProfessionGroupedDropdown
						onChange={(profession: IProfession | null) =>
							setSelectedProfession(profession ? profession : undefined)
						}
						defaultSelectedProfession={currentReport?.profession}
						noBorder
						useWorkingProfessions
					/>
					<PeriodPickerSection source={SummaryTypes.professionAnalysis} />
				</div>
				<div className={classes.dataContainer}>
					<div className={classes.backArrowContainer}>
						<img
							src={arrow}
							alt=""
							className={classNames({
								[classes.transform]: isRtl,
								[classes.backArrow]: true,
							})}
							onClick={handleBackClick}
						/>
					</div>
					<div className={classes.titleSectionWithExportContainer}>
						<TitleSectionWithExportButton
							title={translationService.get('contractorAnalysis')}
							showButton={false}
							onClick={() => {}}
						/>
					</div>
					<TrusstorBox customStyleClass={classes.unsetHeight}>
						<HeaderBox title={translationService.get('dailyResourcesDistribution')}>
							<ActivityDistributionTable
								professionReport={visibleProfessionReportData}
								dateFrom={new Date(startDate)}
								dateTo={new Date(endDate)}
							/>
							<div className={classes.secondLineContainer}>
								<WorkersPerDaySection professionReport={visibleProfessionReportData} />
							</div>
							<TitleColoredBox
								title={`${translationService.get('workHoursPerFloor')}`}
								customRootStyleClass={classes.activityHoursPerFloor}
							>
								<ActivityHoursPerFloorGraph
									professionProductivityReport={visibleProfessionProductivityReport}
								/>
							</TitleColoredBox>
						</HeaderBox>
					</TrusstorBox>
					<TrusstorBox>
						<HeaderBox title={translationService.get('activitiesAnalysis')}>
							<ActivitiesGanttTable
								professionActivitiesReport={visibleProfessionActivitiesReport}
								dateFrom={new Date(startDate)}
								dateTo={new Date(endDate)}
							/>
						</HeaderBox>
					</TrusstorBox>
				</div>
			</div>
		</div>
	);
};

export { ProfessionAnalysisReportPage };
