import { requestService, translationService } from '../../../index';
import { ActionConfirmationDialog } from '../../WorkplanWidgetComponents/ActionConfirmationDialog/ActionConfirmationDialog';
import React from 'react';
import { ActivityDialogDisclaimer } from '../ActivityDialogDisclaimer/ActivityDialogDisclaimer';
import { IActivityActionsEnum } from '../../../interfaces/IActivityActions.enum';
import { IActivitiesGroup } from '../../../interfaces/IActivitiesGroup';
import { HttpStatusCode } from '@src/constants';

interface IActivityStartActionDialogProps {
	show: boolean;
	onClose: () => void;
	activityGroupId: string;
	disclaimerPoints?: string[];
	sendBiEventOnSubmit?: (action: IActivityActionsEnum) => void;
	onActionComplete?: (updatedActivitiesGroup: IActivitiesGroup) => void;
}
export const ActivityStartActionDialog = (props: IActivityStartActionDialogProps) => {
	const startAction = async () => {
		let updatedActivitiesGroup: IActivitiesGroup | undefined;
		try {
			updatedActivitiesGroup = await requestService.put(`/activities/groups/${props.activityGroupId}/start`);
		} catch (error: any) {
			if (error.response?.status === HttpStatusCode.conflict) {
				props.onClose();
				return;
			}
		}
		props.sendBiEventOnSubmit && props.sendBiEventOnSubmit(IActivityActionsEnum.START);
		props.onActionComplete?.(updatedActivitiesGroup!);
		props.onClose();
	};

	const disclaimer: JSX.Element | undefined = !props.disclaimerPoints?.length ? undefined : (
		<ActivityDialogDisclaimer disclaimerPoints={props.disclaimerPoints} />
	);

	return (
		<ActionConfirmationDialog
			show={props.show}
			onClose={props.onClose}
			mainButtonAction={startAction}
			secondaryButtonAction={props.onClose}
			title={translationService.get('startActivity')}
			text={translationService.get('startActivityConfirmation')}
			secondaryText={disclaimer}
		/>
	);
};
