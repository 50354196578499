import React from 'react';
import { InvestigationReportDataListSectionRow } from './ReportTagLocationDataListSectionRow/InvestigationReportDataListSectionRow';
import classes from './styles.module.scss';
import { EmptyStateRow } from '../ReportTagsLocationInfo/EmptyStateRow/EmptyStateRow';
import { translationService } from '../../../../index';
import { Loader } from '@shared/components/Loader/Loader';
import { selectProjectId } from '@store/slices/project.slice';
import { useSelector } from 'react-redux';

interface IInvestigationReportDataListSectionProps {
	header: string;
	data: any[];
	getLabel: (data: any) => string;
	getChipContent: (data: any) => string;
	getChipColor: (data: any, projectId: string) => string;
	shouldShowWarningIcon?: (data) => boolean;
	emptyStateText: string;
	isLoading?: boolean;
	pdfMode?: boolean;
}

export const InvestigationReportDataListSection = (props: IInvestigationReportDataListSectionProps) => {
	const projectId: string = useSelector(selectProjectId)!;
	if (props.isLoading) {
		<Loader />;
	}
	return (
		<div className={classes.InvestigationReportDataListRow}>
			<p className={classes.header}>{props.header}</p>
			{!props.data.length ? (
				<EmptyStateRow text={translationService.get(props.emptyStateText)} />
			) : (
				<div className={classes.dataSection}>
					{props.data.map((data) => (
						<InvestigationReportDataListSectionRow
							label={props.getLabel(data)}
							chipColor={props.getChipColor(data, projectId)}
							chipContent={props.getChipContent(data)}
							showWarningIcon={!props.shouldShowWarningIcon || props.shouldShowWarningIcon(data)}
							pdfMode={props.pdfMode}
						></InvestigationReportDataListSectionRow>
					))}
				</div>
			)}
		</div>
	);
};
