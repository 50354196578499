import React from 'react';
import { BaseNotificationCard } from '../BaseNotificationCard/BaseNotificationCard';
import { batteryLow } from '../../../assets/icons';
import classes from './styles.module.scss';
import { INotification } from '../../../interfaces/INotification';
import { TranslationService } from '../../../services/translation.service';
import { RequestService } from '../../../services/request.service';
import { ITagHealthNotificationMetadata } from '../../../interfaces/ITagHealthNotificationMetadata';
import { UserAccessLevelService } from '../../../services/userAccessLevelService';
import { isTrusstorMobile } from '../../../utils/mobile.utils';

interface ITagHealthCardProps {
	notification: INotification;
	translationService: TranslationService;
	tz: string;
	requester: RequestService;
	handleCardClick: () => void;
	accessLevelService?: UserAccessLevelService<string>;
}

export const getTagHealthTitleElement = (
	metadata: ITagHealthNotificationMetadata,
	translationService: TranslationService,
	isDarkTheme?: boolean
): React.ReactNode => {
	const titleClass: string = `${classes.title} ${isDarkTheme ? classes.titleDarkTheme : ''}`;
	const numberOfTags: number = metadata.unseenTags.length + metadata.lowBatteryTags.length;
	return (
		<div className={titleClass}>
			{translationService.get('thereAre')}{' '}
			<strong>
				{translationService.get('tagsThatNeedAttention', {
					numberOfTags: String(numberOfTags),
				})}
				.
			</strong>
		</div>
	);
};

const RENDER_TAGS_LIMIT: number = 5;

const renderTags = (tags: { tagId: string; tagNick: string }[]): React.ReactNode => {
	return (
		<>
			{tags.slice(0, RENDER_TAGS_LIMIT).map((tag, index) => {
				return (
					<React.Fragment key={tag.tagNick}>
						{index > 0 && ', '}
						{tag.tagNick}
						{index === RENDER_TAGS_LIMIT - 1 && '...'}
					</React.Fragment>
				);
			})}
		</>
	);
};

export const getTagHealthDescriptionElement = (
	metadata: ITagHealthNotificationMetadata,
	translationService: TranslationService,
	isDarkTheme?: boolean
): React.ReactNode => {
	const batteryPercentage: string = '10%';
	const descriptionClass: string = `${classes.description} ${isDarkTheme ? classes.descriptionDarkTheme : ''}`;
	return (
		<div className={descriptionClass}>
			{metadata.lowBatteryTags.length ? (
				<>
					{translationService.get('tags')}:{' '}
					<strong className={classes.tagsText}>{renderTags(metadata.lowBatteryTags)}</strong>
					{', '}
					{translationService.get('haveLessThen')}{' '}
					<strong>
						{batteryPercentage} {translationService.get('betteryLeft')}.
					</strong>
					<br />
					{translationService.get('pleaseRecharge')}.<br />
				</>
			) : (
				''
			)}
			{metadata.unseenTags.length ? (
				<>
					{translationService.get('tags')}:{' '}
					<strong className={classes.tagsText}>{renderTags(metadata.unseenTags)}</strong>
					{', '}
					{translationService.get('haventBeenSeenForMoreThen')}.
				</>
			) : (
				''
			)}
		</div>
	);
};

export const getTagHealthIconElement = (): React.ReactNode => {
	return <img className={classes.icon} src={batteryLow} alt={''} />;
};

export const TagHealthCard = (props: ITagHealthCardProps) => {
	const metadata: ITagHealthNotificationMetadata = props.notification.metadata as ITagHealthNotificationMetadata;

	const handleTagHealthClick = () => {
		if (props.accessLevelService && !props.accessLevelService.hasAccess('systemPagesAccess')) {
			return;
		}
		props.handleCardClick();
	};

	const isCardClickAble: boolean =
		!!props.accessLevelService && props.accessLevelService.hasAccess('systemPagesAccess') && !isTrusstorMobile();

	return (
		<BaseNotificationCard
			icon={getTagHealthIconElement()}
			titleElement={getTagHealthTitleElement(metadata, props.translationService)}
			descriptionElement={getTagHealthDescriptionElement(metadata, props.translationService)}
			createdAt={props.notification.createdAt}
			tz={props.tz}
			translationService={props.translationService}
			requester={props.requester}
			notificationId={props.notification._id}
			handleCardClick={handleTagHealthClick}
			isViewed={props.notification.isViewed}
			isClickable={isCardClickAble}
		/>
	);
};
