import { IUser } from '../interfaces/IUser';

export const getUserInitials = (name: string) => {
	const names: string[] = name.split(' ');
	let initials: string = names[0].substring(0, 1).toUpperCase();
	if (names.length > 1) {
		initials += names[names.length - 1].substring(0, 1).toUpperCase();
	}
	return initials;
};

export const filterUsersByPermittedProfessions = (users: IUser[], professionsIds: string[]): IUser[] => {
	if (!professionsIds.length) {
		return users;
	}

	return users.filter((user) => {
		if (!user.permissions.permittedProfessionsIds?.length) {
			return true;
		}
		return user.permissions.permittedProfessionsIds?.some((professionId) => professionsIds.includes(professionId));
	});
};
