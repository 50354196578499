import React from 'react';
import { infoIcon } from '../../../../../../../assets/reports';
import { TooltipedIcon } from '../../../../../../Tooltips/TooltipedIcon';
import { PRODUCTIVITY_COLUMN_WIDTH } from '../../../../../../../constants';
import { translationService } from '../../../../../../../index';
import classes from './styles.module.scss';

interface IHeaderColumnCell {
	title: string;
	tooltip?: string;
	cellStyleClass: string;
}

interface GeneralHeaderColumnsProps {
	headerColumnCells: IHeaderColumnCell[];
	emptyCellStyleClass: string;
	emptyCellTitle?: string;
	width?: any;
}

const GenericHeaderColumns = (props: GeneralHeaderColumnsProps) => {
	const isRtl: boolean = translationService.getIsRtl();
	return (
		<div
			className={classes.columnContainer}
			style={{
				minWidth: props.width || PRODUCTIVITY_COLUMN_WIDTH,
				maxWidth: props.width || PRODUCTIVITY_COLUMN_WIDTH,
			}}
		>
			<div className={props.emptyCellStyleClass}>
				<p>{props.emptyCellTitle ?? ''}</p>
			</div>
			{props.headerColumnCells.map((headerColumnCell) => (
				<div
					key={headerColumnCell.title}
					className={`${classes.greyHeader} ${headerColumnCell.cellStyleClass}`}
				>
					<p className={classes.title} style={{ textAlign: isRtl ? 'right' : 'left' }}>
						{headerColumnCell.title}
					</p>
					{headerColumnCell.tooltip && (
						<TooltipedIcon tooltipText={headerColumnCell.tooltip}>
							<img src={infoIcon} alt="infoIcon" className={classes.icon} />
						</TooltipedIcon>
					)}
				</div>
			))}
		</div>
	);
};

export { GenericHeaderColumns };
export type { IHeaderColumnCell };
