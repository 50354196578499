import React from 'react';
import { AreaSequenceItemStatus } from '@shared/interfaces/AreaSequenceItemStatus.enum';
import { getDateDisplayFormat } from '@shared/utils/dateUtils';
import { translationService } from '@src/servicesInitializers';
import { useSelector } from 'react-redux';
import { selectTimezone } from '@store/slices/project.slice';
import { isTouchDevice } from '@utils/generalUtils';
import { ActivitySequenceMatrixMode } from '@shared/interfaces/ActivitySequenceMatrixMode.enum';
import { IconColor, IconSize, TrusstorIconShared } from '@shared/components/TrusstorIconShared/TrusstorIconShared';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { useIsFeatureFlagEnabled } from '@shared/hooks/useFeatureFlag';
import { FeatureFlagNamesEnum } from '@shared/constants/featureFlags.enum';
import classes from './styles.module.scss';
import classnames from 'classnames';

interface IActivitySequenceItemCellMainContentProps {
	status: AreaSequenceItemStatus | undefined;
	dates: {
		originalStartDate?: Date;
		endDate?: Date;
	};
	cellIconSrc?: string;
	hoverMode: boolean;
	matrixMode: ActivitySequenceMatrixMode;
}

export const ActivitySequenceItemCellMainContent = (props: IActivitySequenceItemCellMainContentProps) => {
	const tz: string = useSelector(selectTimezone)!;
	const showIcon = !!props.cellIconSrc;

	const shouldDisplayNewContent: boolean = useIsFeatureFlagEnabled(
		FeatureFlagNamesEnum.displayDatesOnProgressTrackerCells
	);

	const getCellTextV2 = (): { text?: string; icon?: JSX.Element } => {
		if (!props.status) {
			return {};
		}

		switch (props.status) {
			case AreaSequenceItemStatus.inProgress:
				return {
					text: props.dates.endDate
						? getDateDisplayFormat(props.dates.endDate, tz, translationService)
						: translationService.get('inProgress'),
					icon: (
						<TrusstorIconShared iconName={IconNames.flag} size={IconSize.SMALL} color={IconColor.Grey600} />
					),
				};
			case AreaSequenceItemStatus.delayedOverdue:
			case AreaSequenceItemStatus.overdue:
				return {
					text: props.dates.endDate
						? getDateDisplayFormat(props.dates.endDate, tz, translationService)
						: translationService.get('overdue'),
					icon: (
						<TrusstorIconShared iconName={IconNames.flag} size={IconSize.SMALL} color={IconColor.White} />
					),
				};
			case AreaSequenceItemStatus.readyForReview:
				return { text: translationService.get('readyForReview') };
			case AreaSequenceItemStatus.delayed:
				return {
					text: props.dates.originalStartDate
						? getDateDisplayFormat(props.dates.originalStartDate, tz, translationService)
						: translationService.get('planned'),
					icon: (
						<TrusstorIconShared
							iconName={IconNames.warningTriangle}
							size={IconSize.SMALL}
							color={IconColor.Grey600}
						/>
					),
				};
			case AreaSequenceItemStatus.planned:
				return {
					text: props.dates.originalStartDate
						? getDateDisplayFormat(props.dates.originalStartDate, tz, translationService)
						: translationService.get('planned'),
				};
			default:
				return {};
		}
	};

	const getCellTextV1 = (): { text?: string; icon?: JSX.Element } => {
		if (!props.status) {
			return {};
		}

		switch (props.status) {
			case AreaSequenceItemStatus.inProgress:
				return {
					text: translationService.get('inProgress'),
				};
			case AreaSequenceItemStatus.delayedOverdue:
			case AreaSequenceItemStatus.overdue:
				return {
					text: translationService.get('overdue'),
				};
			case AreaSequenceItemStatus.readyForReview:
				return { text: translationService.get('readyForReview') };
			case AreaSequenceItemStatus.delayed:
			case AreaSequenceItemStatus.planned:
				return {
					text: translationService.get('planned'),
				};
			default:
				return {};
		}
	};

	const { text: cellText, icon: cellIcon } = shouldDisplayNewContent ? getCellTextV2() : getCellTextV1();

	return showIcon ? (
		<img src={props.cellIconSrc} alt={''} className={classes.icon} />
	) : (
		<div
			className={classnames({
				[classes.hideComponent]:
					props.hoverMode && !isTouchDevice() && props.matrixMode !== ActivitySequenceMatrixMode.schedule,
			})}
		>
			{cellText && (
				<div className={classes.cellTextContainer}>
					{cellIcon}
					<span>{cellText}</span>
				</div>
			)}
		</div>
	);
};
