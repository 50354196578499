import React, { useState } from 'react';
import classes from './styles.module.scss';
import { IMinimalSafetyEventForClient, ISafetyEvent } from '../../../../../interfaces/ISafetyEvent';
import { translationService } from '../../../../../index';
import warning from '@shared/assets/icons/warning.svg';
import { SafetyEventDisplayContent } from '../../../../Safety/SafetyEventDisplayContent/SafetyEventDisplayContent';
import { GeneralDialog } from '../../../../GeneralDialog/GeneralDialog';
import { safetyOrange } from '../../../../../assets/safetyIcons';
import { getHoursMinutesFormat } from '../../../../../utils/dateUtils';
import { useSelector } from 'react-redux';
import { selectTimezone } from '../../../../../store/slices/project.slice';

interface ISafetyCardProps {
	safetyEvent: ISafetyEvent;
}

const getMinimalSafetyEvent = (safetyEvent: ISafetyEvent): IMinimalSafetyEventForClient => {
	return {
		_id: String(safetyEvent._id),
		safetyViolationType: safetyEvent.safetyViolationType,
		profession: safetyEvent.profession,
		isCritical: safetyEvent.isCritical,
		status: safetyEvent.status,
		floorNick: safetyEvent.location?.floorNick || '',
		floorId: safetyEvent.location?.floorId || '',
		trade: safetyEvent.trade || null,
		manager: safetyEvent.manager || null,
		timestampCreated: safetyEvent.timestampCreated,
		timestampClosed: safetyEvent.timestampClosed,
	};
};

export const InvestigationReportSafetyEventCard = (props: ISafetyCardProps) => {
	const [showSafetyEventDialog, setShowSafetyEventDialog] = useState(false);
	const tz: string = useSelector(selectTimezone)!;

	const getBoldSection = (): string => {
		let boldSection: string = '';
		if (props.safetyEvent.location) {
			const locationString: string = `${props.safetyEvent.location.floorNick}, ${
				props.safetyEvent.location.areas?.length > 0 &&
				props.safetyEvent.location.areas.map((area) => area.areaNick).join(', ')
			}`;
			boldSection += locationString;
		}
		const hour: string = getHoursMinutesFormat(props.safetyEvent.timestampCreated, tz);
		return boldSection + ' ' + hour;
	};

	const onClickHandler = () => {
		setShowSafetyEventDialog(!showSafetyEventDialog);
	};

	return (
		<div
			onClick={onClickHandler}
			className={classes.InvestigationReportSafetyEventCard_container}
			data-testid="InvestigationReportSafetyEventCard"
		>
			<img src={warning} />
			<div className={classes.safetyEventText}>
				<div>{`${translationService.get(props.safetyEvent.safetyViolationType)}: `}</div>
				<div className={classes.boldSection}>{getBoldSection()}</div>
			</div>

			{showSafetyEventDialog && (
				<GeneralDialog
					show={true}
					close={onClickHandler}
					title={translationService.get('safetyAlert')}
					titleIcon={safetyOrange}
					rootStyle={classes.dialogRootStyle}
				>
					<div className={classes.safetyContent}>
						<SafetyEventDisplayContent event={props.safetyEvent} />
					</div>
				</GeneralDialog>
			)}
		</div>
	);
};
