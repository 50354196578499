import React, { useMemo, useState } from 'react';
import { ActivitiesHeaderRow } from './ActivitiesHeaderRow/ActivitiesHeaderRow';
import { ProfessionActivitiesRow } from './ProfessionActivitiesRow';
import { IActivitiesProfessionReport, IActivitiesReport } from '../../../../../../interfaces/IActivitiesReport';
import { translationService } from '../../../../../../index';
import { Virtuoso } from 'react-virtuoso';
import classes from './styles.module.scss';

interface IActivitiesTableProps {
	report: IActivitiesReport;
	isDailyReport: boolean;
	startDate: Date;
	endDate?: Date;
	tableHeight?: string;
}

const ActivitiesTable = (props: IActivitiesTableProps) => {
	const [containerWidth, setContainerWidth] = useState<string | number>('100%');

	const reportsArray = useMemo((): [string, IActivitiesProfessionReport][] => {
		if (props.report && Object.keys(props.report).length > 0) {
			return Object.entries(props.report);
		}

		return [];
	}, [props.report]);

	return (
		<div
			className={classes.tableContainerWrapper}
			style={{
				minHeight: props.tableHeight || 'auto',
				maxHeight: props.tableHeight || 'auto',
			}}
		>
			<div className={classes.tableContainer}>
				{reportsArray.length > 0 ? (
					<React.Fragment>
						<ActivitiesHeaderRow
							isDailyReport={props.isDailyReport}
							setWidthToRowContainer={(width) => setContainerWidth(width)}
						/>
						<div className={classes.tableContent}>
							<Virtuoso
								totalCount={reportsArray.length}
								style={{ overflowX: 'hidden', width: containerWidth }}
								itemContent={(index) => {
									const [professionKey, professionReport]: [string, IActivitiesProfessionReport] =
										reportsArray[index];
									return (
										<ProfessionActivitiesRow
											isDailyReport={props.isDailyReport}
											key={`professionActivitiesRow_${professionKey}`}
											activityProfessionReport={professionReport}
											startDate={props.startDate}
											endDate={props.endDate}
										/>
									);
								}}
							/>
						</div>
					</React.Fragment>
				) : (
					<div className={classes.emptyStateContainer}>
						<p className={classes.emptyState}>{translationService.get('activitiesSummaryEmptyState')}</p>
					</div>
				)}
			</div>
		</div>
	);
};

export { ActivitiesTable };
