import React, { useEffect, useId, useRef, useState } from 'react';
import classes from './styles.module.scss';
import { IMilestone } from '@shared/interfaces/IMilestone';
import { TrusstorIcon } from '../TrusstorIcon/TrusstorIcon';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { translationService } from '@src/servicesInitializers';
import { TrusstorIconButtonDeprecated } from '@shared/components/buttons/TrusstorIconButton/TrusstorIconButtonDeprecated';
import { useHistory } from 'react-router-dom';
import { selectProjectId, selectTimezone } from '@store/slices/project.slice';
import { useSelector } from 'react-redux';
import {
	useDeleteMilestoneMutation,
	useProjectMilestonesQuery,
	useUpdateMilestoneMutation,
} from '@src/hooks/queries/milestones.queries.hooks';
import { DateSelectorTrusstorInput } from '@src/Components/DateSelector/DateSelectorTrusstorInput/DateSelectorTrusstorInput';
import { getTimezoneFormattedDate } from '@shared/utils/dateUtils';
import { ISequenceItem } from '@interfaces/ISequenceItem';
import { TrusstorMenu } from '@shared/components/TrusstorMenu/TrusstorMenu';
import { useOutsideClick } from '@shared/hooks/custom.hooks';
import classnames from 'classnames';
import { IconSize } from '@shared/components/TrusstorIconShared/TrusstorIconShared';
import { GeneralDialog } from '@src/Components/GeneralDialog/GeneralDialog';

interface IMilestoneInnerPageHeaderProps {
	currentMilestoneId: string;
	linkedSequenceItems?: ISequenceItem[];
}

export const MilestoneInnerPageHeader = (props: IMilestoneInnerPageHeaderProps) => {
	const projectId: string = useSelector(selectProjectId)!;
	const tz: string = useSelector(selectTimezone)!;
	const [menuElement, setMenuElement] = useState<HTMLElement | null>(null);
	const [showDeleteWarningModal, setShowDeleteWarningModal] = useState<boolean>(false);
	const { projectMilestones } = useProjectMilestonesQuery(projectId);
	const currentMilestone: IMilestone | undefined = projectMilestones.find(
		(milestone) => milestone._id === props.currentMilestoneId
	);
	const [dueDate, setDueDate] = React.useState<Date | undefined>(currentMilestone?.endDate);

	const [showMenu, setShowMenu] = useState(false);
	const currentMilestoneIndex: number = projectMilestones.findIndex(
		(milestone) => milestone._id === props.currentMilestoneId
	);
	const updateMutation = useUpdateMilestoneMutation(projectId);
	const deleteMutation = useDeleteMilestoneMutation(projectId);
	const editTitleEl = useRef<HTMLDivElement>(null);

	useEffect(() => {
		setDueDate(currentMilestone?.endDate);
	}, [currentMilestone?._id]);

	const isRtl: boolean = translationService.getIsRtl();
	const history = useHistory();
	const baseMilestonePath: string = `/${projectId}/system/milestones`;

	const goBack = () => {
		history.push(baseMilestonePath);
	};

	const goToPrevious = () => {
		const previousMilestoneId: string | undefined = projectMilestones[currentMilestoneIndex - 1]?._id;

		if (!previousMilestoneId) {
			return history.push(`${baseMilestonePath}/${projectMilestones[projectMilestones.length - 1]._id}`);
		}
		history.push(`${baseMilestonePath}/${previousMilestoneId}`);
	};

	const goToNext = () => {
		const nextMilestoneId: string | undefined = projectMilestones[currentMilestoneIndex + 1]?._id;
		if (!nextMilestoneId) {
			return history.push(`${baseMilestonePath}/${projectMilestones[0]._id}`);
		}
		history.push(`${baseMilestonePath}/${nextMilestoneId}`);
	};

	useOutsideClick({ current: menuElement }, setShowMenu, false);

	const id: string = 'editableDiv' + useId();

	if (!currentMilestone) {
		return null;
	}

	const handleDateChange = (date: Date | null) => {
		if (!date) return;
		setDueDate(date);
		updateMutation.mutate({
			milestoneId: currentMilestone._id!,
			dueDate: getTimezoneFormattedDate(tz, date),
		});
	};

	const showDeleteMenu = () => {
		setShowMenu((prev) => !prev);
	};

	const handleDelete = () => {
		setShowDeleteWarningModal(true);
	};

	const mainDeleteAction = async () => {
		setShowDeleteWarningModal(false);
		if (props.linkedSequenceItems?.length) {
			return;
		}
		const filteredMilestone = projectMilestones.filter((milestone) => milestone._id !== props.currentMilestoneId);
		const currentMilestoneIndex: number = projectMilestones.findIndex((milestone) => milestone?._id === id);
		const baseMilestonePath: string = `/${projectId}/system/milestones`;
		const nextMilestoneId: string | undefined = filteredMilestone[currentMilestoneIndex + 1]?._id;

		await deleteMutation.mutateAsync(props.currentMilestoneId);
		if (!filteredMilestone.length) {
			return history.push(baseMilestonePath);
		}
		if (!nextMilestoneId) {
			if (!filteredMilestone[0]._id) {
				return history.push(baseMilestonePath);
			}
			return history.push(`${baseMilestonePath}/${filteredMilestone[0]._id}`);
		}
		return history.push(`${baseMilestonePath}/${nextMilestoneId}`);
	};

	const onTitleClick = (e) => {
		e.stopPropagation();
	};

	const updateTitle = () => {
		if (editTitleEl.current!.innerText === currentMilestone.title) return;
		updateMutation.mutate({
			milestoneId: props.currentMilestoneId,
			title: editTitleEl.current!.innerText,
		});
	};

	return (
		<div className={classes.MilestoneInnerPageHeader_container} data-testid="MilestoneInnerPageHeader">
			<div className={classes.back} onClick={goBack}>
				<TrusstorIcon iconName={isRtl ? IconNames.arrowRight : IconNames.arrowLeft} />
				<div>{translationService.get('back')}</div>
			</div>
			<div className={classes.middle}>
				<TrusstorIconButtonDeprecated
					disabled={projectMilestones.length === 1}
					iconElement={<TrusstorIcon iconName={isRtl ? IconNames.arrowRight : IconNames.arrowLeft} />}
					onClick={goToPrevious}
				/>
				<div className={classes.texts}>
					<div className={classes.orderIndex}>{`${translationService.get('milestone')} #${
						projectMilestones.indexOf(currentMilestone) + 1
					}`}</div>
					<div className={classes.titleDescriptionSection}>
						<div className={classes.titleSection}>
							<div
								suppressContentEditableWarning={true}
								className={classes.title}
								onClick={onTitleClick}
								onBlur={updateTitle}
								tabIndex={1}
								ref={editTitleEl}
								id={id}
								contentEditable
							>
								{currentMilestone.title}
							</div>
						</div>
					</div>
				</div>
				<TrusstorIconButtonDeprecated
					disabled={projectMilestones.length === 1}
					iconElement={<TrusstorIcon iconName={isRtl ? IconNames.arrowLeft : IconNames.arrowRight} />}
					onClick={goToNext}
				/>
			</div>
			<div className={classes.end}>
				<DateSelectorTrusstorInput
					width={84}
					minDate={new Date()}
					required
					label={currentMilestone.endDate ? translationService.get('dueDate') : undefined}
					inputPlaceHolder={translationService.get('dueDate')}
					handleTimeChange={handleDateChange}
					iconElement={<TrusstorIcon iconName={IconNames.flag} />}
					selectedDate={dueDate ? new Date(dueDate) : undefined}
					selectDefaultDate={true}
				/>

				{showDeleteWarningModal && (
					<GeneralDialog
						show={showDeleteWarningModal}
						close={() => setShowDeleteWarningModal(false)}
						title={translationService.get('deleteMilestoneWarningTitle')}
						mainButton={{
							text: props.linkedSequenceItems?.length
								? translationService.get('milestone_delete_dialog_ok')
								: translationService.get('milestone_delete_dialog_yes'),
							show: true,
							click: mainDeleteAction,
						}}
						rootStyle={classes.rootDialog}
					>
						<div className={classes.dialogContent}>
							{translationService.get(
								!props.linkedSequenceItems?.length
									? 'milestone_delete_dialog_areYouSure'
									: 'milestone_delete_dialog_thereAreLinkedMilestones'
							)}
						</div>
					</GeneralDialog>
				)}
				<div
					className={classnames(classes.menuContainer, { [classes.showDropdown]: showMenu })}
					onClick={showDeleteMenu}
				>
					<TrusstorIcon iconName={IconNames.dotsHorizontal} size={IconSize.MEDIUM} defaultColor />
				</div>
				{showMenu && (
					<div
						ref={setMenuElement}
						className={classnames({ [classes.isRtl]: isRtl }, classes.dropdownContainer)}
					>
						<TrusstorMenu
							width={70}
							items={[
								{
									title: translationService.get('delete'),
									onClick: handleDelete,
									iconComponent: <TrusstorIcon iconName={IconNames.trash} size={IconSize.SMALL} />,
								},
							]}
						/>
					</div>
				)}
			</div>
		</div>
	);
};
