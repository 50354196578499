import { IScissorsLiftUtility } from '@interfaces/IScissorsLiftUtility';
import { IUtilityMergedTag } from '@interfaces/IUtilityMergedTag';
import { UtilityTypes } from '@interfaces/UtilityTypes.enum';
import { getProfessionBackgroundColor } from '@shared/utils/professions.utils';

export const getGroupByFunctionByUtilityType = (
	utilityType: UtilityTypes
): ((utility: IUtilityMergedTag) => string) => {
	switch (utilityType) {
		case UtilityTypes.scissorsLift:
			return (utility: IUtilityMergedTag) => (utility.linkedUtility as IScissorsLiftUtility).profession?._id;
		default:
			return (utility: IUtilityMergedTag) => utility.linkedUtility?.type;
	}
};

export const getChipBorderColorByUtilityType = (utility: IUtilityMergedTag): string | undefined => {
	const utilityType: UtilityTypes = utility.linkedUtility.type;
	switch (utilityType) {
		case UtilityTypes.scissorsLift:
			return getProfessionBackgroundColor(
				(utility.linkedUtility as IScissorsLiftUtility).profession,
				utility.projectId
			);
		default:
			return undefined;
	}
};

export const getChipDescriptionByUtilityType = (utility: IUtilityMergedTag): string | undefined => {
	const utilityType: UtilityTypes = utility.linkedUtility.type;
	switch (utilityType) {
		case UtilityTypes.scissorsLift:
			return (utility.linkedUtility as IScissorsLiftUtility).profession.specialty;
		default:
			return undefined;
	}
};
