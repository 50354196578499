import { useQuery } from '@tanstack/react-query';
import { IProfession } from '../../interfaces/IProfession';
import { IIssueShared } from '../../interfaces/IIssueShared';
import { useSharedServices } from '../sharedServices.context';

export const useLinkedIssueActivityDescription = (issue: IIssueShared<IProfession>): string | undefined => {
	const { requestService } = useSharedServices();
	const { data: linkedIssueActivityDescription } = useQuery<string | undefined>(
		['linkedIssueActivityDescription', issue._id, issue.linkedActivityGroupId, issue.linkedAreaSequenceItemId],
		async () => {
			const url: string = issue.linkedActivityGroupId
				? `/activities/groups/${issue.linkedActivityGroupId}`
				: `/activities/sequenceItems/areaSequenceItem/${issue.linkedAreaSequenceItemId}`;
			const activity = await requestService.get(url);
			return activity.description;
		},
		{
			enabled: !!(issue._id && (issue.linkedActivityGroupId || issue.linkedAreaSequenceItemId)),
		}
	);
	return linkedIssueActivityDescription;
};
