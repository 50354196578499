import React from 'react';
import classes from './styles.module.scss';
import { whiteCheckIcon } from '../../../assets';
import { professionBackgroundColorToWorkplanChipColorMappings } from '../../../constants/colorMapping.constants';

interface IColorPickerProps {
	title: string;
	chosenColor: string;
	changeColorFunction: (color: string) => void;
}

const colorPickerOptions: string[] = Object.values(professionBackgroundColorToWorkplanChipColorMappings);

const ColorPicker = (props: IColorPickerProps) => {
	const colorPickHandler = (e: any): void => {
		const pickedColor: string = e.target.getAttribute('color');
		props.changeColorFunction(pickedColor);
	};

	return (
		<div className={classes.colorPicker}>
			<p className={classes.title}>{props.title}</p>
			<div className={classes.colorContainer}>
				{colorPickerOptions.map((color) => (
					<div
						className={classes.colorOption}
						key={color}
						onClick={colorPickHandler}
						color={color}
						style={{ backgroundColor: color }}
					>
						{props.chosenColor === color && (
							<img className={classes.checkIcon} alt={`color option: ${color}`} src={whiteCheckIcon} />
						)}
					</div>
				))}
			</div>
		</div>
	);
};

export { ColorPicker };
