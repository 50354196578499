import TrusstorTooltip from '@shared/components/TrusstorTooltip';
import { TrusstorIcon } from '@src/Components/TrusstorIcon/TrusstorIcon';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import React from 'react';
import { IRestrictedAreaTrigger } from '@interfaces/IRestrictedAreaTrigger';
import classes from './styles.module.scss';
import { translationService } from '@src/servicesInitializers';
import { getProfessionDisplayText } from '@utils/professions.utils';
import { useSelector } from 'react-redux';
import { IRootState } from '@store/slices';
import { IProfession } from '@interfaces/index';

interface IRestrictedAreaTitleTooltipProps {
	trigger: IRestrictedAreaTrigger;
}

export const RestrictedAreaTitleTooltip = (props: IRestrictedAreaTitleTooltipProps) => {
	const professions: IProfession[] = useSelector((state: IRootState) => state.professionsReducer.professions);
	const isAreasDefined: boolean = props.trigger.areas?.length! > 0;
	return (
		<div>
			<TrusstorTooltip
				showAlways
				key={props.trigger._id}
				tooltipClasses={classes.tooltip}
				tooltipComponent={
					<div className={classes.wrapper}>
						<div className={classes.oneMoreWrapper}>
							<div className={classes.section}>
								<div className={classes.title}>
									{translationService.get(isAreasDefined ? 'areas' : 'floors')}
								</div>
								<div className={classes.ul}>
									{isAreasDefined
										? props.trigger.areas?.map((area) => {
												return (
													<li key={area.areaId} className={classes.text}>
														{area.areaNick}
													</li>
												);
										  })
										: props.trigger.floors?.map((floor) => {
												return (
													<li key={floor.floorId} className={classes.text}>
														{floor.floorNick}
													</li>
												);
										  })}
								</div>
							</div>
							<div className={classes.section}>
								<div className={classes.title}>{translationService.get('professions')}</div>
								<div className={classes.ul}>
									{props.trigger.profession.professions?.map((profession) => {
										const fullProfessionData: IProfession =
											professions.find((p) => p._id === profession._id) || profession;
										return (
											<li key={profession._id} className={classes.text}>
												{getProfessionDisplayText(fullProfessionData)}
											</li>
										);
									})}
								</div>
							</div>
						</div>
					</div>
				}
			>
				<div>
					<TrusstorIcon iconName={IconNames.information} />
				</div>
			</TrusstorTooltip>
		</div>
	);
};
