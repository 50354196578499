import { handleDropdownKeyDown, scrollIntoGroupOption } from '../../../utils/dropdown.utils';
import React, { useEffect, useState } from 'react';
import { groupBy } from 'lodash';
import { IGroupedDropdownSharedProps } from './dropdown.interface';

export const handleGroupDropdownArrowDown = <T>(
	highlightedIndex: number,
	setHighlightedIndex: React.Dispatch<React.SetStateAction<number>>,
	highlightedGroupIndex: number,
	setHighlightedGroupIndex: React.Dispatch<React.SetStateAction<number>>,
	dropdownRef: React.RefObject<HTMLDivElement>,
	sortedGroupKeys: string[],
	groupedOptions: { [key: string]: T[] },
	enableGroupSelection: boolean = false
): void => {
	setHighlightedIndex((prevIndex) => {
		const highlightedGroupOptions: T[] | undefined = groupedOptions[sortedGroupKeys[highlightedGroupIndex]];
		const isLastOptionInGroup: boolean =
			!!highlightedGroupOptions && highlightedGroupOptions.length - 1 === prevIndex;
		if (isLastOptionInGroup) {
			const isLastGroup: boolean = sortedGroupKeys.length - 1 === highlightedGroupIndex;
			if (isLastGroup) {
				return prevIndex;
			}

			const nextGroupFirstOptionIndex: number = enableGroupSelection ? -1 : 0;
			scrollIntoGroupOption(dropdownRef, highlightedGroupIndex + 1, nextGroupFirstOptionIndex);
			setHighlightedGroupIndex(highlightedGroupIndex + 1);
			return nextGroupFirstOptionIndex;
		}

		const updatedIndex = prevIndex + 1;
		scrollIntoGroupOption(dropdownRef, highlightedGroupIndex, updatedIndex);
		return updatedIndex;
	});
};

export const handleGroupDropdownArrowUp = <T>(
	highlightedIndex: number,
	setHighlightedIndex: React.Dispatch<React.SetStateAction<number>>,
	highlightedGroupIndex: number,
	setHighlightedGroupIndex: React.Dispatch<React.SetStateAction<number>>,
	dropdownRef: React.RefObject<HTMLDivElement>,
	sortedGroupKeys: string[],
	groupedOptions: { [key: string]: T[] },
	enableGroupSelection: boolean = false
): void => {
	setHighlightedIndex((prevIndex) => {
		const updatedIndex = prevIndex - 1;
		const minimumIndex: number = enableGroupSelection ? -1 : 0;
		if (prevIndex === minimumIndex) {
			const isFirstGroup: boolean = highlightedGroupIndex === 0;
			if (isFirstGroup) {
				return prevIndex;
			}

			const prevGroupKey: string = sortedGroupKeys[highlightedGroupIndex - 1];
			const prevGroupOptions: T[] = groupedOptions[prevGroupKey];
			const prevGroupLastOptionIndex: number = prevGroupOptions.length - 1;
			scrollIntoGroupOption(dropdownRef, highlightedGroupIndex - 1, prevGroupLastOptionIndex);
			setHighlightedGroupIndex(highlightedGroupIndex - 1);
			return prevGroupLastOptionIndex;
		}
		scrollIntoGroupOption(dropdownRef, highlightedGroupIndex, updatedIndex);
		return updatedIndex;
	});
};

export const handleGroupDropdownEnter = <T>(
	highlightedIndex: number,
	setHighlightedIndex: React.Dispatch<React.SetStateAction<number>>,
	highlightedGroupIndex: number,
	setHighlightedGroupIndex: React.Dispatch<React.SetStateAction<number>>,
	sortedGroupKeys: string[],
	groupedOptions: { [key: string]: T[] },
	handleOptionClick: (option: T) => void,
	isSelected: (option: T) => boolean,
	enableGroupSelection: boolean = false,
	isMultipleSelection: boolean = false
): void => {
	if (!isMultipleSelection) {
		setHighlightedIndex(-1);
		setHighlightedGroupIndex(0);
	}

	if (highlightedIndex !== -1) {
		const chosenGroupedKey: string = sortedGroupKeys[highlightedGroupIndex];
		const chosenGroupedOptions: T[] = groupedOptions[chosenGroupedKey];
		handleOptionClick(chosenGroupedOptions[highlightedIndex]);
	} else if (enableGroupSelection) {
		const chosenGroupedKey: string = sortedGroupKeys[highlightedGroupIndex];
		const chosenGroupedOptions: T[] = groupedOptions[chosenGroupedKey];
		const isGroupSelected: boolean = chosenGroupedOptions.every((option) => isSelected(option));

		if (isGroupSelected) {
			chosenGroupedOptions.forEach((option) => handleOptionClick(option));
			return;
		}

		chosenGroupedOptions.forEach((option) => {
			if (!isSelected(option)) {
				handleOptionClick(option);
			}
		});
	}
};

export const useGroupDropdownKeyboardNavigation = <T>(
	highlightedIndex: number,
	setHighlightedIndex: React.Dispatch<React.SetStateAction<number>>,
	highlightedGroupIndex: number,
	setHighlightedGroupIndex: React.Dispatch<React.SetStateAction<number>>,
	dropdownRef: React.RefObject<HTMLDivElement>,
	sortedGroupKeys: string[],
	groupedOptions: { [key: string]: T[] },
	handleOptionClick: (option: T) => void,
	isSelected: (option: T) => boolean,
	enableGroupSelection: boolean = false,
	isMultipleSelection: boolean = false
) => {
	return (event: React.KeyboardEvent) => {
		handleDropdownKeyDown(event, {
			arrowDownCallback: () => {
				handleGroupDropdownArrowDown(
					highlightedIndex,
					setHighlightedIndex,
					highlightedGroupIndex,
					setHighlightedGroupIndex,
					dropdownRef,
					sortedGroupKeys,
					groupedOptions,
					enableGroupSelection
				);
			},
			arrowUpCallback: () => {
				handleGroupDropdownArrowUp(
					highlightedIndex,
					setHighlightedIndex,
					highlightedGroupIndex,
					setHighlightedGroupIndex,
					dropdownRef,
					sortedGroupKeys,
					groupedOptions,
					enableGroupSelection
				);
			},
			enterCallback: () => {
				handleGroupDropdownEnter(
					highlightedIndex,
					setHighlightedIndex,
					highlightedGroupIndex,
					setHighlightedGroupIndex,
					sortedGroupKeys,
					groupedOptions,
					handleOptionClick,
					isSelected,
					enableGroupSelection,
					isMultipleSelection
				);
			},
		});
	};
};

export const useGroupDropdown = <T>(
	groupedProps: IGroupedDropdownSharedProps<T>,
	filteredOptions: T[],
	handleInputChange: (value: string) => void,
	handleOptionHover: (index: number) => void
) => {
	const [highlightedGroupIndex, setHighlightedGroupIndex] = useState<number>(0);
	const [groupedOptions, setGroupedOptions] = useState<{ [key: string]: T[] }>({});
	const [sortedGroupKeys, setSortedGroupKeys] = useState<string[]>([]);
	const [topLevelGroups, setTopLevelGroups] = useState<{ [key: string]: string[] } | undefined>(undefined);

	useEffect(() => {
		const groupedOptions: { [key: string]: T[] } = groupBy(filteredOptions, groupedProps.groupByFunction);
		const sortedGroupKeys: string[] = Object.keys(groupedOptions);
		const topLevelGroups: { [key: string]: string[] } | undefined = groupedProps.getTopLevelGroup
			? groupBy(sortedGroupKeys, (key) => groupedProps.getTopLevelGroup!(groupedOptions[key][0]))
			: undefined;
		setSortedGroupKeys(sortedGroupKeys);
		setGroupedOptions(groupedOptions);
		setTopLevelGroups(topLevelGroups);
	}, [filteredOptions]);

	const handleInputChangeCallback = (value: string) => {
		handleInputChange(value);
		setHighlightedGroupIndex(0);
	};

	const handleOptionHoverCallback = (optionIndex: number, groupIndex: number) => {
		handleOptionHover(optionIndex);
		setHighlightedGroupIndex(groupIndex);
	};

	return {
		groupedOptions,
		sortedGroupKeys,
		highlightedGroupIndex,
		topLevelGroups,
		setHighlightedGroupIndex,
		handleInputChangeCallback,
		handleOptionHoverCallback,
	};
};
