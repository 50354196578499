import { useEffect, useState } from 'react';
import { debounce } from 'lodash';

export function useWindowHightSmallerThenN(number: number): boolean {
	const [isNarrowLayout, setIsNarrowLayout] = useState(false);

	useEffect(() => {
		const handleResize = debounce(() => {
			if (window.innerHeight < number && !isNarrowLayout) {
				setIsNarrowLayout(true);
			}
			if (window.innerHeight >= number && isNarrowLayout) {
				setIsNarrowLayout(false);
			}
		}, 800);

		window.addEventListener('resize', handleResize);

		handleResize();

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [isNarrowLayout]);

	return isNarrowLayout;
}
