import { GeneralDialog } from '../../GeneralDialog/GeneralDialog';
import { translationService } from '../../../index';
import React from 'react';
import styles from './styles.module.scss';

interface INoTagsAvailableDialogProps {
	show: boolean;
	onClose: () => void;
}

export const NoTagsAvailableDialog = (props: INoTagsAvailableDialogProps) => {
	return (
		<GeneralDialog
			show={props.show}
			close={props.onClose}
			title={translationService.get('assignTagTitle')}
			mainButton={{
				text: translationService.get('okGotIt'),
				show: true,
			}}
			rootStyle={{ width: 500, height: 120 }}
		>
			<div className={styles.dialogContent}>
				<p>{translationService.get('noTagsAvailableForAssign')}</p>
			</div>
		</GeneralDialog>
	);
};
