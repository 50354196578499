import React from 'react';
import moment from 'moment-timezone';
import classes from './styles.module.scss';
import {
	ITrusstorIconButtonSize,
	TrusstorIconButtonDeprecated,
} from '@shared/components/buttons/TrusstorIconButton/TrusstorIconButtonDeprecated';
import { TrusstorIcon } from '@src/Components/TrusstorIcon/TrusstorIcon';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { translationService } from '@src/servicesInitializers';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectProjectId, selectTimezone } from '@store/slices/project.slice';
import { MAIN_PATH } from '@src/constants';

interface IHeaderSectionProps {
	startDate: Date;
	endDate: Date;
}

export const HeaderSection = (props: IHeaderSectionProps) => {
	const tz: string = useSelector(selectTimezone)!;
	const history = useHistory();
	const projectId: string = useSelector(selectProjectId)!;

	const handleBackClick = () => {
		if (window.location.href.includes('workplan')) {
			history.push(`/${projectId}/workplan`);
			return;
		}
		if (window.location.href.includes('analysisCenter')) {
			history.push(`/${projectId}/analysisCenter`);
			return;
		}
		history.push(`/${projectId}${MAIN_PATH}`);
	};

	const getDates = (): string => {
		const startDateFormat: string = moment.tz(props.startDate, tz).format('MMM D');
		const endDateFormat: string = moment.tz(props.endDate, tz).format('MMM D, YYYY');
		return `${startDateFormat} - ${endDateFormat}`;
	};

	const isRtl: boolean = translationService.getIsRtl();
	return (
		<div className={classes.headerContainer} data-testid="HeaderSection">
			<div className={classes.headerContent}>
				<TrusstorIconButtonDeprecated
					buttonSize={ITrusstorIconButtonSize.SMALL}
					iconElement={<TrusstorIcon iconName={isRtl ? IconNames.arrowRight : IconNames.arrowLeft} />}
					onClick={handleBackClick}
				/>
				<div className={classes.title}>{translationService.get('SequenceProgressAnalysis')}</div>
				<div className={classes.actionTimeSection}>
					<div className={classes.dateSection}>
						<TrusstorIcon iconName={IconNames.calendar} />
						<div className={classes.date}>{getDates()}</div>
					</div>
				</div>
			</div>
		</div>
	);
};
