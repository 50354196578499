import { ITag } from '../../interfaces';
import { ITagsReducerState } from '../../interfaces/ITagsReducerState';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRootState } from '.';

const initialState: ITagsReducerState = {
	tags: [],
};

const tagsSlice = createSlice({
	name: 'tags',
	initialState,
	reducers: {
		updateTags: (state: ITagsReducerState, action: PayloadAction<{ tags: ITag[] }>) => {
			state.tags = action.payload.tags;
		},
		updateTagSuccess: (state: ITagsReducerState, action: PayloadAction<{ tagId: string; updatedTag: ITag }>) => {
			const updatedTags: ITag[] = updateTagInReducerState(action.payload.tagId, action.payload.updatedTag, state);
			state.tags = updatedTags;
		},
	},
});

const updateTagInReducerState = (tagId: string, updatedTag: ITag, state: ITagsReducerState): ITag[] => {
	return state.tags.map((tag) => {
		if (tagId === tag.tagId) {
			return {
				...updatedTag,
			};
		}
		return tag;
	});
};

export const selectTags = (state: IRootState) => state.tagsReducer.tags;
export const { updateTags, updateTagSuccess } = tagsSlice.actions;
export default tagsSlice.reducer;
