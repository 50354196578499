import { IBaseInvestigationReport } from '@shared/interfaces/IBaseInvestigationReport';
import { getRelativeHour } from '@shared/utils/dateUtils';
import React from 'react';
import { useSelector } from 'react-redux';
import { translationService } from '../../../../index';
import { selectTimezone } from '../../../../store/slices/project.slice';
import classes from './styles.module.scss';

interface IRecentReportCardProps {
	redirectToReport: () => void;
	reportId: string;
	investigationReport: IBaseInvestigationReport;
	children: JSX.Element;
	dateGenerated: Date;
}

export const RecentReportCard = (props: IRecentReportCardProps) => {
	const tz: string = useSelector(selectTimezone)!;

	return (
		<div
			onClick={props.redirectToReport}
			className={classes.RecentInvestigationReportCard_container}
			data-testid={`recentInvestigationReportCard_${props.reportId}`}
		>
			<div className={classes.leftSection}>
				<div className={classes.title}>{props.investigationReport.title}</div>
				<div className={classes.bottomLeftPart}>
					<div className={classes.investigationType}>
						{translationService.get(props.investigationReport.investigationType)}
					</div>
					<div className={classes.divSeparator}></div>
					{props.children}
				</div>
			</div>
			<div className={classes.rightSection}>
				<div className={classes.rightInnerSection}>
					<div className={classes.dateGenerated}>
						{getRelativeHour(props.dateGenerated, tz, translationService)}
					</div>
				</div>
			</div>
		</div>
	);
};
