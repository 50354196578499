import { SSE_HEADER_QUERY_PARAMS } from '../constants/headers.constants';
import { IProject } from '../interfaces/IProject';
import { UserService } from './user.service';
import { captureMessage } from '../utils/monitoring.util';

class StreamService {
	private config: any;
	private userService: UserService;
	private openStreams: any;
	private waitingStreams: any;
	private store: any;
	private onOpenStreamInterceptor: any;
	private onErrorStreamInterceptor: any;

	constructor(config, userService, store) {
		this.config = config;
		this.userService = userService;
		this.openStreams = {};
		this.waitingStreams = {};
		this.store = store;
	}

	setOnOpenStreamInterceptor(callback) {
		this.onOpenStreamInterceptor = callback;
	}

	setOnErrorStreamInterceptor(callback) {
		this.onErrorStreamInterceptor = callback;
	}

	async openStream(api, onData, onError?: (event: Event) => void) {
		if (this.waitingStreams[api]) {
			return;
		}
		this.waitingStreams[api] = true;

		if (this.onOpenStreamInterceptor) {
			await this.onOpenStreamInterceptor();
		}

		this.closeStreamIncludes(api.split('?')[0]);

		const eventSource = new EventSource(`${this.config.apiGateway}${api}${this.getAuthQueryParams()}`);
		this.openStreams[api] = eventSource;
		eventSource.onmessage = onData;
		eventSource.onerror = (event: Event) => {
			captureMessage(`StreamService: error on stream ${api}: ${event}`);
			if (onError) {
				onError(event);
			}
			if (this.onErrorStreamInterceptor) {
				this.onErrorStreamInterceptor(api, onData);
			}
		};
		this.waitingStreams[api] = false;
	}

	closeStream(api) {
		if (this.openStreams[api]) {
			this.openStreams[api].close();
			delete this.openStreams[api];
		}
	}

	closeStreamIncludes(api) {
		for (const openStream in this.openStreams) {
			if (openStream.includes(api)) {
				this.closeStream(openStream);
			}
		}
	}

	closeAllStreams() {
		for (const openStream in this.openStreams) {
			this.closeStream(openStream);
		}
	}

	getAuthQueryParams = () => {
		const requestProject: IProject = this.store.getState().projectReducer.workingProject;
		const accessToken = this.userService.getLoggedUserAccessToken();
		const loggedUserDetails = this.userService.getUserDetails();
		return `&${SSE_HEADER_QUERY_PARAMS.accessToken}=${accessToken}\
	&${SSE_HEADER_QUERY_PARAMS.tz}=${requestProject.tz}\
	&${SSE_HEADER_QUERY_PARAMS.projectId}=${requestProject.projectId}\
	&${SSE_HEADER_QUERY_PARAMS.organizationId}=${requestProject.organizationId}\
	&${SSE_HEADER_QUERY_PARAMS.username}=${loggedUserDetails?.username}\
	&${SSE_HEADER_QUERY_PARAMS.isDemo}=${!!requestProject?.isDemo}`;
	};
}

export { StreamService };
