import type { Identifier, XYCoord } from 'dnd-core';
import { useDrag, useDrop } from 'react-dnd';

interface DragItem {
	index: number;
	id: string;
	type: string;
}

export const useMatrixColHeaderDnd = (
	headerElement: HTMLElement | null,
	headerIndex: number,
	headerId: string,
	onDragStart,
	onDragFinish,
	moveCard,
	disableDnd: boolean = false
) => {
	const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
		accept: 'matrixHeader',
		collect(monitor) {
			return {
				handlerId: monitor.getHandlerId(),
			};
		},
		hover(item: DragItem, monitor) {
			if (!headerElement) {
				return;
			}
			const dragIndex = item.index;
			const hoverIndex = headerIndex;

			// Don't replace items with themselves
			if (dragIndex === hoverIndex) {
				return;
			}

			// Determine rectangle on screen
			const hoverBoundingRect = headerElement?.getBoundingClientRect();

			// Get horizontal middle
			const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

			// Determine mouse position
			const clientOffset = monitor.getClientOffset();

			// Get pixels to the left
			const hoverClientX = (clientOffset as XYCoord).x - hoverBoundingRect.left;

			// Only perform the move when the mouse has crossed half of the items height
			// When dragging forwards, only move when the cursor is below 50%
			// When dragging backwords, only move when the cursor is above 50%

			// Dragging forwards
			if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
				return;
			}

			// Dragging backwards
			if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
				return;
			}

			moveCard(dragIndex, hoverIndex);

			// Note: we're mutating the monitor item here!
			// Generally it's better to avoid mutations,
			// but it's good here for the sake of performance
			// to avoid expensive index searches.
			item.index = hoverIndex;
		},
	});

	const [{ isDragging }, drag] = useDrag({
		type: 'matrixHeader',
		item: () => {
			onDragStart(headerIndex);
			return { id: headerId, index: headerIndex };
		},
		collect: (monitor: any) => ({
			isDragging: monitor.isDragging(),
		}),
		end: (item, monitor) => {
			onDragFinish(headerIndex);
		},
		canDrag: () => !disableDnd,
	});

	drag(drop(headerElement));

	return {
		isDragging,
		handlerId,
	};
};
