import { requestService, streamService } from '../../index';
import { IStaticEquipment } from '../../interfaces/IStaticEquipment';
import { IMergedEquipmentWithTypeSum } from '../../interfaces/IMergedEquipmentView';
import { resetEquipment, updateActiveEquipment, updateStaticEquipment } from '../slices/equipment.slice';
import { EquipmentTypes } from '@interfaces/EquipmentTypes.enum';

export const getActiveEquipment = (projectId: string) => async (dispatch) => {
	streamService.openStream(`/location/merged/equipment/stream?projectId=${projectId}`, (message) => {
		const equipment: IMergedEquipmentWithTypeSum = JSON.parse(message.data);
		dispatch(updateActiveEquipment({ activeEquipment: equipment.equipmentData }));
	});
};

export const stopGettingActiveEquipment = () => async (dispatch) => {
	streamService.closeStreamIncludes(`/location/merged/equipment/stream`);
	dispatch(resetEquipment());
};

export const getStaticEquipment = (projectId: string) => async (dispatch) => {
	const equipment: IStaticEquipment[] = await requestService.get(
		`/siteNetIntegrator/static/equipment?projectId=${projectId}`
	);
	const nonUtilityEquipments: IStaticEquipment[] = equipment.filter((eq) => eq.type !== EquipmentTypes.ScissorsLift);
	dispatch(updateStaticEquipment({ staticEquipment: nonUtilityEquipments }));
};
