import { RestrictedAreaCreateDialog } from '@src/Components/NotificationSettingCard/RestrictedArea/Dialogs/RestrictedAreaCreateDialog/RestrictedAreaCreateDialog';
import classes from '@src/Components/NotificationSettingCard/styles.module.scss';
import { IRestrictedAreaTrigger } from '@interfaces/IRestrictedAreaTrigger';
import { INotificationCard } from '@shared/interfaces/NotificationSettings/INotificationCard';
import { getRestrictedAreaCard } from '@utils/notifications.utils';
import { NotificationCard } from '@src/Components/NotificationSettingCard/NotificationCard';
import { translationService } from '@src/servicesInitializers';
import { IBaseNotificationSettings, IUserNotificationSettings } from '@shared/interfaces/INotificationSettings';
import {
	ITrusstorButtonSize,
	ITrusstorButtonType,
	TrusstorButton,
} from '@shared/components/buttons/TrusstorButton/TrusstorButton';
import { TrusstorIcon } from '@src/Components/TrusstorIcon/TrusstorIcon';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectProjectId } from '@store/slices/project.slice';
import { useRestrictedAreaTriggersQuery } from '@src/hooks/queries/notificationEngine.queries.hooks';
import { INotificationTypes } from '@shared/interfaces/NotificationTypes.enum';
import { NotificationChannelTypes } from '@shared/interfaces/NotificationChannelTypes.enum';
import { RestrictedAreaTitleSection } from '@src/Components/NotificationSettingCard/RestrictedArea/RestrictedAreaTitleSection';
import { useWorkingProfessions } from '@src/hooks/useWorkingProfessions.hook';
import { IconSize } from '@shared/components/TrusstorIconShared/TrusstorIconShared';

interface IRestrictedAreaSectionProps {}

interface IRestrictedAreaSectionProps {
	card: INotificationCard;
	isBase?: boolean;
	userSettings?: IUserNotificationSettings;
	baseSettings?: IBaseNotificationSettings;
	handleSettingsChange: (
		value: boolean | string | null,
		notificationType: INotificationTypes,
		channelName?: NotificationChannelTypes,
		frequency?: boolean
	) => Promise<void>;

	handleBaseSettingsChange: (
		notificationType: INotificationTypes,
		recipient?: { username: string; add: boolean },
		value?: boolean | string | null,
		channelName?: NotificationChannelTypes,
		frequency?: boolean
	) => Promise<void>;
}

export const RestrictedAreaSection = (props: IRestrictedAreaSectionProps) => {
	const [showRestrictedAreaDialog, setShowRestrictedAreaDialog] = useState<boolean>(false);
	const handleCreateRestrictedAreaClick = () => {
		setShowRestrictedAreaDialog(true);
	};
	const projectId: string = useSelector(selectProjectId)!;
	const { restrictedAreaTriggers, refetchRestrictedAreaTriggers } = useRestrictedAreaTriggersQuery(projectId);
	useWorkingProfessions({});

	const handleCloseCreateDialog = () => {
		setShowRestrictedAreaDialog(false);
		refetchRestrictedAreaTriggers();
	};

	return (
		<>
			{showRestrictedAreaDialog && <RestrictedAreaCreateDialog handleClose={handleCloseCreateDialog} />}
			{!!restrictedAreaTriggers?.length && <div className={classes.divider} />}
			{restrictedAreaTriggers &&
				restrictedAreaTriggers.map((trigger: IRestrictedAreaTrigger, index: number) => {
					const restrictedAreaCard: INotificationCard = getRestrictedAreaCard(
						`RestrictedArea_${trigger._id}`
					);
					return (
						<>
							<NotificationCard
								key={trigger._id}
								titleElement={
									<RestrictedAreaTitleSection
										trigger={trigger}
										updateTriggersList={refetchRestrictedAreaTriggers}
									/>
								}
								isBase={props.isBase}
								card={restrictedAreaCard}
								baseSettings={props.baseSettings as IBaseNotificationSettings}
								userSettings={props.userSettings as IUserNotificationSettings}
								handleSettingsChange={props.handleSettingsChange}
								handleBaseSettingsChange={props.handleBaseSettingsChange}
							/>
							{index !== restrictedAreaTriggers.length - 1 && <div className={classes.divider} />}
						</>
					);
				})}

			{props.isBase && (
				<>
					<div className={classes.divider} />
					<div className={classes.restrictedAreaSection}>
						<div className={classes.textSection}>
							<div>{translationService.get('RestrictedArea')}</div>
							<div className={classes.description}>
								{translationService.get('restrictedAreaDescription')}
							</div>
						</div>
						<TrusstorButton
							className={classes.createButton}
							iconElement={<TrusstorIcon iconName={IconNames.plus} size={IconSize.SMALL} />}
							text={translationService.get('create')}
							handleClick={handleCreateRestrictedAreaClick}
							buttonType={ITrusstorButtonType.SECONDARY}
						/>
					</div>
				</>
			)}
		</>
	);
};
