import { Dispatch } from 'redux';
import { requestService } from '../../index';
import { getTimezoneFormattedDate } from '../../utils/dateUtils';
import { IProfessionsAnalysisReport } from '../../interfaces/IProfessionReport';
import { resetProfessionReport, updateProfessionReport } from '../slices/professionReport.slice';

export const getProfessionPeriodicReport =
	(projectId: string, startDate: Date | number, endDate: Date | number, tz: string) => async (dispatch: Dispatch) => {
		const serverFormatDateFrom = getTimezoneFormattedDate(tz, startDate);
		const serverFormatDateTo = getTimezoneFormattedDate(tz, endDate);

		const professionsAnalysisReport: IProfessionsAnalysisReport = await requestService.get(
			`/reports/professionsAnalysis?dateFrom=${serverFormatDateFrom}&dateTo=${serverFormatDateTo}&projectId=${projectId}`
		);

		if (Object.keys(professionsAnalysisReport).length > 0) {
			dispatch(updateProfessionReport({ report: professionsAnalysisReport }));
		} else {
			dispatch(resetProfessionReport());
		}
	};
