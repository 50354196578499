import { IconChip } from '../IconChip/IconChip';
import { IconNames } from '../../TrusstorIconShared/IconNames.enum';
import React from 'react';
import { IconColor } from '../../TrusstorIconShared/TrusstorIconShared';
import { COLORS } from '../../../constants/colors.constants';

interface IStatusChipProps {
	managerTradeText: string;
}

export const ManagerChip = (props: IStatusChipProps) => {
	return (
		<IconChip
			backgroundColor={COLORS.grey100}
			iconName={IconNames.manager}
			iconColor={IconColor.Grey600}
			textColor={COLORS.grey600}
			text={props.managerTradeText}
		/>
	);
};
