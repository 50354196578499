import { IScissorsLiftUtility } from '@interfaces/IScissorsLiftUtility';
import { IUtilityMergedTag } from '@interfaces/IUtilityMergedTag';
import { UtilityTypes } from '@interfaces/UtilityTypes.enum';

export const getUtilityNameByType = (utilityTag: IUtilityMergedTag): string => {
	switch (utilityTag.linkedUtility.type) {
		case UtilityTypes.scissorsLift:
			return (utilityTag.linkedUtility as IScissorsLiftUtility).name;
		case UtilityTypes.hoist:
			return utilityTag.tagNick;
	}
};
