import React, { useContext } from 'react';
import classes from './styles.module.scss';
import {
	ITrusstorButtonSize,
	ITrusstorButtonType,
	TrusstorButton,
} from '@shared/components/buttons/TrusstorButton/TrusstorButton';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { selectProjectId } from '@store/slices/project.slice';
import { progressTrackerIcon } from '@src/assets';
import { translationService } from '@src/servicesInitializers';
import {
	ISequenceProgressAnalysisContext,
	SequenceProgressAnalysisContext,
} from '@src/Components/SequenceProgressAnalysis/SequenceProgressAnalysis';

export const ActivitiesProgressTableEmptyState = () => {
	const { selectedMilestoneId }: ISequenceProgressAnalysisContext = useContext(SequenceProgressAnalysisContext)!;
	const projectId: string = useSelector(selectProjectId)!;

	const history = useHistory();
	const handleClick = () => {
		history.push(`/${projectId}/system/milestones/${selectedMilestoneId}`);
	};

	return (
		<div className={classes.emptyState}>
			<img src={progressTrackerIcon} alt="NoSequencesEmptyState" className={classes.image} />
			<p className={classes.mainText}>{translationService.get('activities_progress_empty_state_main_text')}</p>
			<p className={classes.subText}>
				{translationService.get('activities_progress_empty_state_main_description')}
			</p>
			<TrusstorButton
				handleClick={handleClick}
				buttonType={ITrusstorButtonType.OUTLINE}
				buttonSize={ITrusstorButtonSize.SMALL}
				text={translationService.get('activities_progress_empty_state_button_text')}
			/>
		</div>
	);
};
