import React, { useContext, useEffect, useMemo, useState } from 'react';
import classes from './styles.module.scss';
import { GeneralDialog } from '@src/Components/GeneralDialog/GeneralDialog';
import { requestService, translationService } from '@src/servicesInitializers';
import { StarButton } from '@shared/components/buttons/StarButton/StarButton';
import { errorSnackbar } from '@utils/snackbar.utils';
import { useDispatch, useSelector } from 'react-redux';
import { TrusstorTextInput } from '@shared/components/Inputs/TrusstorTextInput/TrusstorTextInput';
import { SingleProfessionGroupedDropdown } from '@src/Components/Dropdowns/Profession/SingleProfessionGroupedDropdown/SingleProfessionGroupedDropdown';
import { IProfession } from '@shared/interfaces/IProfession';
import { GroupedMultipleDropdown } from '@src/Components/Dropdowns/DesignSystem/GroupedMultipleDropdown/GroupedMultipleDropdown';
import { IConfigArea } from '@shared/interfaces/IConfigArea';
import { sortByAreas } from '@shared/utils/sort.utils';
import { SORT_ORDER } from '@shared/constants/constants';
import { selectProjectId } from '@store/slices/project.slice';
import { ISequenceItem } from '@interfaces/ISequenceItem';
import { useProjectAreasBySequenceType } from '@src/hooks/queries/sequenceItem.query';
import { SequenceTypeEnum } from '@shared/interfaces/SequenceType.enum';
import { useAreaSequenceItemsBySequenceItem } from '@src/hooks/queries/areaSequenceItems.query';
import { RadioButton } from '@shared/components/RadioButton/RadioButton';
import { WorkplanContext } from '@src/Components/Pages/Workplan/WorkPlan';

interface IEditSequenceItemDialogProps {
	show: boolean;
	close: () => void;
	onEditSequenceItem: (profession: IProfession, isStarred: boolean, description: string) => void;
	profession: IProfession;
	isStarred: boolean;
	description: string;
	sequenceItemId: string;
	sequenceType: SequenceTypeEnum;
}

export const EditSequenceItemDialog = (props: IEditSequenceItemDialogProps) => {
	const dispatch = useDispatch();
	const projectId: string = useSelector(selectProjectId)!;
	const { areas } = useProjectAreasBySequenceType(projectId, props.sequenceType);
	const { asis, isLoading: isLoadingAsis } = useAreaSequenceItemsBySequenceItem(props.sequenceItemId);
	const [isStarred, setIsStarred] = useState<boolean>(props.isStarred);
	const [description, setDescription] = useState<string>(props.description);
	const [selectedProfession, setSelectedProfession] = useState<IProfession>(props.profession);
	const [isAreaRange, setIsAreaRange] = useState<boolean>(false);
	const [selectedAreas, setSelectedAreas] = useState<IConfigArea[] | undefined>(undefined);
	const [showDescriptionValidationError, setShowDescriptionValidationError] = useState<boolean>(false);
	const { sequenceItems } = useContext(WorkplanContext)!;
	const isAtLeastOneAsiHasBeenUpdated: boolean = !!asis?.some((asi) => asi.lastStatusUpdateDate);

	useEffect(() => {
		if (!asis) {
			return;
		}

		if (asis.length === areas.length) {
			setIsAreaRange(false);
			return;
		}

		setIsAreaRange(true);
		const areasFromAsis: IConfigArea[] = asis.map((asi) => asi.area);
		setSelectedAreas(areasFromAsis);
	}, [asis?.length, areas.length]);

	const resetState = () => {
		setIsStarred(props.isStarred);
		setDescription(props.description);
		setSelectedProfession(props.profession);
		setIsAreaRange(false);
		setSelectedAreas(undefined);
	};

	const handleClose = () => {
		resetState();
		props.close();
	};

	useEffect(() => {
		if (!props.show) {
			resetState();
		}
	}, [props.show]);

	const onEditSequenceItem = async () => {
		try {
			if (isAreaRange && !selectedAreas?.length) {
				return;
			}

			const isDescriptionAlreadyExists: boolean = sequenceItems.some(
				(sequenceItem) => sequenceItem.description === description && sequenceItem._id !== props.sequenceItemId
			);
			if (isDescriptionAlreadyExists) {
				setShowDescriptionValidationError(true);
				return;
			}

			const sequenceItemToUpdate: Partial<ISequenceItem> = {
				isStarred,
				description,
				profession: selectedProfession,
			};

			await requestService.put(`/activities/sequenceItems/${props.sequenceItemId}/areas`, {
				body: {
					sequenceItem: sequenceItemToUpdate,
					hasPartialAreas: isAreaRange,
					areaIds: !isAreaRange ? undefined : selectedAreas?.map((area) => area.areaId),
				},
			});
			props.onEditSequenceItem(selectedProfession, isStarred, description);
		} catch (err) {
			errorSnackbar(dispatch, translationService.get('genericError'));
		} finally {
			handleClose();
		}
	};

	const onStarClickError = () => {
		errorSnackbar(dispatch, translationService.get('genericError'));
	};

	const handleStarClick = async () => {
		setIsStarred((prev) => !prev);
	};

	const handleProfessionChange = (profession?: IProfession | null) => {
		if (!profession) {
			return;
		}
		setSelectedProfession(profession);
	};

	const handleIsAreaRangeChange = () => {
		setIsAreaRange((prev) => !prev);
	};

	const sortedAreas: IConfigArea[] = useMemo(() => {
		return sortByAreas(areas, SORT_ORDER.DESCENDING);
	}, [areas]);

	const handleDescriptionChange = (description: string) => {
		setDescription(description);
		setShowDescriptionValidationError(false);
	};

	return (
		<GeneralDialog
			isLoading={isLoadingAsis}
			show={props.show}
			close={handleClose}
			mainButton={{
				text: translationService.get('edit'),
				click: onEditSequenceItem,
				disabled: description === '' || !selectedProfession || (isAreaRange && !selectedAreas?.length),
			}}
			secondaryButton={{
				text: translationService.get('cancel'),
				click: handleClose,
			}}
		>
			<div className={classes.sequenceItemContainer}>
				<div>
					<StarButton
						isStarred={isStarred}
						handleStarClick={handleStarClick}
						onClickError={onStarClickError}
					/>
					<div className={classes.descriptionContainer}>
						<TrusstorTextInput
							changeFunc={handleDescriptionChange}
							placeholder={`${translationService.get('createSequenceItemDialog_descriptionPlaceholder')}*`}
							rootClassName={classes.inputContainer}
							textClassName={classes.titleInputText}
							autoFocus
							value={description}
						/>
						{showDescriptionValidationError && (
							<div className={classes.errorText}>{translationService.get('nameAlreadyExists')}</div>
						)}
					</div>
				</div>
				<SingleProfessionGroupedDropdown
					defaultSelectedProfession={selectedProfession}
					useWorkingProfessions
					onChange={handleProfessionChange}
					required
				/>
				<div className={classes.areasContainer}>
					<div className={classes.radioButtonContainer}>
						<RadioButton
							isSelected={!isAreaRange}
							onClick={handleIsAreaRangeChange}
							disabled={isAreaRange && isAtLeastOneAsiHasBeenUpdated}
						/>
						<div>{translationService.get('createSequenceItemDialog_allAreas')}</div>
					</div>
					<div className={classes.radioButtonContainer}>
						<RadioButton
							isSelected={isAreaRange}
							onClick={handleIsAreaRangeChange}
							disabled={!isAreaRange && isAtLeastOneAsiHasBeenUpdated}
						/>
						<div>{translationService.get('createSequenceItemDialog_areaRange')}</div>
					</div>
				</div>
			</div>
			{isAreaRange && (
				<GroupedMultipleDropdown<IConfigArea>
					getDisplayOption={(area: IConfigArea) => area.areaNick}
					groupByFunction={(option: IConfigArea) => {
						return option.floorNick;
					}}
					options={sortedAreas}
					onChange={(options) => {
						setSelectedAreas(options);
					}}
					value={selectedAreas}
					optionSelectedKey={'areaId'}
					placeholder={translationService.get('selectLocation')}
					totalSelectedInputText={translationService.get('locations')}
					testId={'areasAutocomplete'}
					required
					disabled={isAtLeastOneAsiHasBeenUpdated}
				/>
			)}
		</GeneralDialog>
	);
};
