import { makeStyles } from '@material-ui/core/styles';
import { IProject } from '@shared/interfaces/IProject';
import React, { createContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '@store/slices';
import { resetDatePicker } from '@store/slices/datePicker.slice';
import { resetSelectedManagers, resetStaticManagers } from '@store/slices/managers.slice';
import { removeAllProfessions } from '@store/slices/professions.slice';
import {
	getActiveEquipment,
	getAllProfessions,
	getDashboardData,
	getSafety,
	getStaticEquipment,
	getStaticManagers,
	stopGettingActiveEquipment,
	stopGettingDashboardData,
	stopGettingSafety,
} from '@store/thunks';
import { MainPageDashboard } from '../MainPageSections/MainPageDashboard/MainPageDashboard';
import { MainView } from '../MainPageSections/MainView';
import { IMainPageWidgetActivityData } from '@interfaces/IMainPageWidgetActivityData';
import { useMainPageWidgetActivities } from '@src/hooks/mainPageWidgetActivities.hook';
import { IIssue } from '@shared/interfaces/IIssue';
import { useMainPageIssues } from '@src/hooks/mainPageIssues.hook';
import { IssuesTabQuickFilterTypes } from '@src/Components/MainPageIssuesTab/MainPageIssuesTab';
import { useRenderMonitoring } from '@shared/hooks/monitoring.hooks';
import { WidgetActivityFilters } from '@shared/interfaces/WidgetActivityFilters';
import { resetUtilities } from '@store/slices/utilities.slice';

const useStyles = makeStyles({
	pageContainer: {
		height: '100%',
		width: '100%',
		display: 'flex',
	},
});

export interface IMainPageContext {
	widgetActivities: IMainPageWidgetActivityData[];
	setWidgetActivities: (widgetActivities: IMainPageWidgetActivityData[]) => void;
	areWidgetActivitiesFetched: boolean;
	widgetActivityFilters: WidgetActivityFilters;
	setWidgetActivityFilters: React.Dispatch<React.SetStateAction<WidgetActivityFilters>>;
	onActivityUpdate: () => void;
	refetchLatestWidgetActivities: () => void;
	issues: IIssue[];
	areIssuesFetched: boolean;
	setIssues: React.Dispatch<React.SetStateAction<IIssue[]>>;
	issuesTabSelectedFilter: IssuesTabQuickFilterTypes;
	setIssuesTabSelectedFilter: React.Dispatch<React.SetStateAction<IssuesTabQuickFilterTypes>>;
	updateIssue: (issueId: string, issue: Partial<IIssue>, updateBackend?: boolean) => Promise<IIssue>;
	deleteIssue: (issueId: string) => Promise<void>;
}
export const MainPageContext = createContext<IMainPageContext | undefined>(undefined);

export const MainPage = () => {
	useRenderMonitoring('MainPage');
	const workingProject: IProject = useSelector((state: IRootState) => state.projectReducer.workingProject)!;
	const {
		onActivityUpdate,
		setWidgetActivities,
		widgetActivities,
		isFetched: areWidgetActivitiesFetched,
		widgetActivityFilters,
		setWidgetActivityFilters,
		refetchLatestWidgetActivities,
	} = useMainPageWidgetActivities();
	const {
		issues,
		isFetched: areIssuesFetched,
		setIssues,
		updateIssue,
		deleteIssue,
		issuesTabSelectedFilter,
		setIssuesTabSelectedFilter,
	} = useMainPageIssues();

	const dispatch = useDispatch();
	const workingProjectId: string = workingProject.projectId;
	const tz: string = workingProject.tz;

	useEffect(() => {
		if (workingProjectId) {
			dispatch(getSafety(workingProjectId));
			dispatch(getStaticEquipment(workingProjectId));
			dispatch(getStaticManagers({ projectId: workingProjectId }));
			dispatch(getActiveEquipment(workingProjectId));
			dispatch(getAllProfessions());
			dispatch(resetDatePicker({ tz }));
			dispatch(getDashboardData(workingProjectId));
			dispatch(getActiveEquipment(workingProjectId));
			return () => {
				dispatch(stopGettingDashboardData());
				dispatch(stopGettingActiveEquipment());
				dispatch(resetDatePicker({ tz }));
				dispatch(resetSelectedManagers());
				dispatch(resetStaticManagers());
				dispatch(removeAllProfessions());
				dispatch(resetUtilities());
				dispatch(stopGettingSafety());
			};
		}
	}, [workingProjectId, tz]);

	const classes = useStyles();

	return (
		<MainPageContext.Provider
			value={{
				widgetActivityFilters,
				setWidgetActivityFilters,
				widgetActivities,
				areWidgetActivitiesFetched,
				setWidgetActivities,
				onActivityUpdate,
				refetchLatestWidgetActivities,
				issues,
				areIssuesFetched,
				setIssues,
				updateIssue,
				deleteIssue,
				issuesTabSelectedFilter,
				setIssuesTabSelectedFilter,
			}}
		>
			<div className={classes.pageContainer}>
				<MainPageDashboard />
				<MainView />
			</div>
		</MainPageContext.Provider>
	);
};
