import { IUser } from '../interfaces/IUser';
import { FullStory, init } from '@fullstory/browser';
import { Environments } from '../constants/environments';

const fullStoryAllowedProjects: string[] = [
	'denya-cebus-shvatim102',
	'denya-cebus-shvatim101',
	'denya-cebus-oranim',
	'yanushevsky-ad-120',
	'shimizu-chiba',
];

const shouldInitFullStory = (currentProject: string): boolean => {
	return fullStoryAllowedProjects.includes(currentProject);
};

export const initFullStory = (fullStoryOrganizationId: string): void => {
	init(
		{
			startCaptureManually: true,
			orgId: fullStoryOrganizationId,
			devMode: !(process.env.REACT_APP_STAGE === Environments.prod),
		},
		({ sessionUrl }) => console.log(`Started session: ${sessionUrl}`)
	);
};

export const initFullStoryByUserData = (userDetails: IUser, currentProject: string): void => {
	if (!shouldInitFullStory(currentProject)) {
		FullStory('shutdown');
		return;
	}
	FullStory('start');
	FullStory('setIdentity', {
		uid: userDetails._id!,
		properties: {
			userId: `user_${userDetails?._id}`,
			displayName: userDetails?.name,
			username: userDetails?.username,
			email: userDetails?.email,
			role: userDetails?.permissions.roleType,
		},
	});
};
