import { IMilestone } from '@shared/interfaces/IMilestone';
import { IMilestoneProgressSummary } from '@interfaces/IMilestoneProgressSummary';
import { clamp, round } from 'lodash';
import moment from 'moment';

export enum ITodayMarkerPosition {
	START = 'START',
	MIDDLE = 'MIDDLE',
	END = 'END',
}

export const getNextMilestoneIndex = (milestones: IMilestone[], tz: string): number => {
	const currentDate = moment.tz(tz);

	const index = milestones.findIndex((milestone) => moment.tz(milestone.endDate, tz).isAfter(currentDate, 'day'));
	if (index === -1) {
		return milestones.length - 1;
	}
	return index;
};

export const getTodayMarkerPosition = (
	milestones: IMilestone[],
	tz: string
):
	| {
			index: number;
			position: ITodayMarkerPosition;
	  }
	| undefined => {
	const milestoneIndex: number = getNextMilestoneIndex(milestones, tz);

	if (milestoneIndex === 0) {
		return undefined;
	}
	if (milestoneIndex === milestones.length - 1) {
		if (moment.tz(new Date(), tz).isBefore(moment.tz(milestones[milestoneIndex].endDate, tz), 'day')) {
			return { index: milestoneIndex, position: ITodayMarkerPosition.START };
		}
		return undefined;
	}

	const prevMilestone: IMilestone = milestones[milestoneIndex - 1];
	const nextMilestone: IMilestone = milestones[milestoneIndex];

	const milestonesDaysDiff: number = moment
		.tz(nextMilestone.endDate, tz)
		.diff(moment.tz(prevMilestone.endDate, tz), 'days');
	const todayDaysDiff: number = moment.tz(new Date(), tz).diff(moment.tz(prevMilestone.endDate, tz), 'days');
	// position between 1 and 3
	// 1 - current milestong with center position
	// 2 - current milestone with end position
	// 3 - next milestone with center position
	const position: number = clamp(round((todayDaysDiff / milestonesDaysDiff) * 3), 1, 3);
	const index: number = position === 3 ? milestoneIndex : milestoneIndex - 1;
	const markerPosition: ITodayMarkerPosition =
		position === 1
			? ITodayMarkerPosition.START
			: position === 2
				? ITodayMarkerPosition.MIDDLE
				: ITodayMarkerPosition.END;
	return { index, position: markerPosition };
};

export const getMilestoneProgressPercentage = (milestoneProgressSummary: IMilestoneProgressSummary): number => {
	if (milestoneProgressSummary.progress.asisLinked === 0) {
		return 0;
	}
	return Math.round(
		(milestoneProgressSummary.progress.asisCompleted / milestoneProgressSummary.progress.asisLinked) * 100
	);
};
