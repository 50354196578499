import React from 'react';
import classes from './styles.module.scss';
import { IconColor, IconSize, TrusstorIconShared } from '../../../../../TrusstorIconShared/TrusstorIconShared';
import { IconNames } from '../../../../../TrusstorIconShared/IconNames.enum';
import classnames from 'classnames';

interface ISmallDropdownButtonProps {
	text: string;
	count?: number;
	setDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
	isDropdownOpen: boolean;
	disabled?: boolean;
}

export const GhostSmallDropdownButton = (props: ISmallDropdownButtonProps) => {
	const onClick = (): void => {
		if (props.disabled) {
			return;
		}

		props.setDropdownOpen((prev) => !prev);
	};

	return (
		<div className={classes.dropdownButtonContainer} onClick={onClick}>
			<div
				className={classnames({
					[classes.text]: true,
					[classes.selected]: props.isDropdownOpen || props.count,
					[classes.disabled]: props.disabled,
				})}
			>
				{props.text}
			</div>
			{props.count && !props.disabled && <div className={classes.count}>{props.count}</div>}
			<TrusstorIconShared
				iconName={props.isDropdownOpen ? IconNames.chevronUp : IconNames.chevronDown}
				color={props.disabled ? IconColor.Grey300 : IconColor.DarkBlue}
				size={IconSize.SMALL}
			/>
		</div>
	);
};
