import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { CustomSelect } from '../CustomSelect/CustomSelect';
import { useDispatch, useSelector } from 'react-redux';
import { changeWorkingProject } from '@store/thunks';
import { COLORS } from '../../../constants';
import classnames from 'classnames';

const useStyles = makeStyles({
	container: {
		display: 'flex',
		alignItems: 'center',
		width: 170,
		height: 38,
	},
	projectsImage: {
		width: 22,
		height: 22,
		marginRight: 16,
	},
	listItemContainer: {
		'display': 'flex',
		'alignItems': 'center',
		'justifyContent': 'space-between',
		'marginBottom': 6,
		'backgroundColor': COLORS.white,
		'&:hover': {
			backgroundColor: COLORS.grey50,
		},
	},
	listItem: {
		fontClass: 'h2',
		color: COLORS.primaryColor,
		fontWeight: 'normal',
		margin: 0,
	},
	bigListItem: {
		fontSize: 20,
		color: COLORS.primaryColor,
		fontWeight: 'normal',
		margin: 0,
	},
	headerTitle: {
		fontSize: '16px',
		fontWeight: '500',
		color: COLORS.black,
		marginLeft: 6,
	},
	bigHeaderTitle: {
		fontSize: 24,
		color: COLORS.black,
		fontWeight: '700',
		marginLeft: 6,
	},
	projectSelect: {
		width: 220,
	},
	bigProjectSelect: {
		width: '100%',
	},
});

const ProjectSelector = ({ bigSelector = false }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const location = history.location.pathname;
	const userProjects = useSelector((state) => state.projectReducer.userProjects);
	const workingProject = useSelector((state) => state.projectReducer.workingProject);

	const ProjectItemComponent = ({ item, selectedItem, changeSelected }) => {
		return (
			<div
				key={item.projectId}
				className={`${classes.listItemContainer} ${classes.text}`}
				onClick={() => (item.name !== selectedItem ? changeSelected(item) : null)}
			>
				<p
					className={classnames({
						[classes.listItem]: !bigSelector,
						[classes.bigListItem]: bigSelector,
					})}
				>
					{item.name}
				</p>
			</div>
		);
	};

	const selectHandler = (projectId) => {
		const path = location.split('/').slice(2).join('/');
		history.replace(`/${projectId}/${path}`);
		dispatch(changeWorkingProject(projectId));
	};

	const customSelectClassName = classnames({
		[classes.projectSelect]: !bigSelector,
		[classes.bigProjectSelect]: bigSelector,
	});

	const renderDropdown = () => {
		if (userProjects.length > 1) {
			return (
				<CustomSelect
					currentValue={workingProject?.name}
					placeholder={workingProject?.name}
					list={userProjects}
					selectHandler={({ projectId }) => {
						selectHandler(projectId);
					}}
					menuItemComponent={ProjectItemComponent}
					minClosedHeight={38}
					rootStyleClass={customSelectClassName}
					bigCustomSelect={bigSelector}
				/>
			);
		}
		const projectNameClassname = classnames({
			[classes.headerTitle]: !bigSelector,
			[classes.bigHeaderTitle]: bigSelector,
		});
		return <p className={projectNameClassname}>{workingProject?.name}</p>;
	};

	return (
		<div
			className={classnames({
				[classes.container]: true,
				[classes.bigProjectSelect]: bigSelector,
			})}
		>
			{renderDropdown()}
		</div>
	);
};

export { ProjectSelector };
