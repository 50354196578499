import React, { useContext, useRef, useState } from 'react';
import classes from './styles.module.scss';
import { IconSize } from '@shared/components/TrusstorIconShared/TrusstorIconShared';
import { TrusstorIcon } from '@src/Components/TrusstorIcon/TrusstorIcon';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { requestService, translationService } from '@src/servicesInitializers';
import { useDispatch, useSelector } from 'react-redux';
import { SequenceTypeEnum } from '@shared/interfaces/SequenceType.enum';
import { WorkplanContext } from '@src/Components/Pages/Workplan/WorkPlan';
import { errorSnackbar } from '@utils/snackbar.utils';
import { addSequenceItemsToTable } from '@utils/sequence.utils';
import { IMergedAreaSequenceItem } from '@interfaces/IMergedAreaSequenceItem';
import { ISequenceItem } from '@interfaces/ISequenceItem';
import {
	ProgressTrackerTabContext,
	TableModeEnum,
} from '@src/Components/Pages/Workplan/ProgressTrackerTab/ProgressTrackerTab';
import { CreateSequenceItemDialog } from '@src/Components/Matrix/cellComponents/MatrixHeader/AddSequenceItem/CreateSequenceItemDialog/CreateSequenceItemDialog';
import { useProjectAreasBySequenceType } from '@src/hooks/queries/sequenceItem.query';
import { selectProjectId } from '@store/slices/project.slice';

interface IAddSequenceItemProps {
	styles?: React.CSSProperties;
}

export const AddSequenceItem = (props: IAddSequenceItemProps) => {
	const {
		selectedSequenceId,
		sequences,
		setSequenceItems,
		sequenceItems,
		matrix: { setTables },
		setAreaSequenceItemsByFloor,
	} = useContext(WorkplanContext)!;
	const { tableView } = useContext(ProgressTrackerTabContext)!;
	const projectId: string = useSelector(selectProjectId)!;

	const [showSequenceItemDialog, setShowSequenceItemDialog] = useState<boolean>(false);
	const dispatch = useDispatch();

	const currentSequenceType: SequenceTypeEnum | undefined = sequences.find(
		(sequence) => sequence._id === selectedSequenceId
	)?.sequenceType;
	const { areas } = useProjectAreasBySequenceType(projectId, currentSequenceType);

	const onCreateSequenceItem = async (newSequenceItem: ISequenceItem) => {
		const currentSequenceItems: ISequenceItem[] = sequenceItems;
		try {
			const allSequenceItems: ISequenceItem[] = [...sequenceItems, newSequenceItem];
			setSequenceItems(allSequenceItems);
			const areaSequenceItemsToAddFromApi: IMergedAreaSequenceItem[] = await requestService.get(
				`/activities/sequenceItems/areaSequenceItem?sequenceId=${selectedSequenceId}`
			);
			addSequenceItemsToTable(
				areaSequenceItemsToAddFromApi,
				setTables,
				setAreaSequenceItemsByFloor,
				allSequenceItems,
				areas
			);
		} catch (e) {
			setSequenceItems(currentSequenceItems);
			console.log(e);
			errorSnackbar(dispatch, translationService.get('genericError'));
		}
	};

	const handleNewButtonClick = (e) => {
		e.stopPropagation();
		setShowSequenceItemDialog(true);
	};

	const ref = useRef(null);

	if (!selectedSequenceId || !currentSequenceType) {
		return null;
	}

	return (
		<div ref={ref}>
			{!!selectedSequenceId && tableView === TableModeEnum.AREAS && (
				<div>
					<div className={classes.addButton} style={props.styles} onClick={handleNewButtonClick}>
						<TrusstorIcon iconName={IconNames.plus} size={IconSize.MEDIUM} />
					</div>
				</div>
			)}
			<CreateSequenceItemDialog
				close={() => setShowSequenceItemDialog(false)}
				show={showSequenceItemDialog}
				nextOrderIndex={sequenceItems.length}
				onCreateSequenceItem={onCreateSequenceItem}
				sequenceId={selectedSequenceId}
				sequenceType={currentSequenceType}
			/>
		</div>
	);
};
