import React, { useEffect, useState } from 'react';
import { resetUserPasswordApi } from '../../../../apis/entities.api';
import user from '../../../../assets/login/user.svg';
import { translationService } from '../../../../index';
import { validateEmail } from '../../../../utils/generalUtils';
import { AuthPage } from '../AuthPage';
import { ResetPasswordConfirmationPage } from '../ResetPasswordPage/ResetPasswordConfirmationPage';
import { TrusstorTextInput } from '@shared/components/Inputs/TrusstorTextInput/TrusstorTextInput';

interface IInvalidSetPasswordTokenPageProps {
	title: string;
}

export const InvalidSetPasswordTokenPage = (props: IInvalidSetPasswordTokenPageProps) => {
	const [email, setEmail] = useState<string>('');
	const [emailError, setEmailError] = useState<string>('');
	const [showConfirmationPage, setShowConfirmationPage] = useState<boolean>(false);

	const onEmailInputChange = (email: string) => {
		setEmailError('');
		setEmail(email);
	};

	const onResetPasswordClick = async () => {
		const isValidEmail = validateEmail(email);
		if (!isValidEmail) {
			return setEmailError(translationService.get('invalidEmailError'));
		}
		await resetUserPasswordApi(email);
		setShowConfirmationPage(true);
	};

	return !showConfirmationPage ? (
		<AuthPage
			buttonText={translationService.get('resetPassword')}
			title={props.title}
			onButtonClick={onResetPasswordClick}
			submitOnEnter={true}
		>
			<div data-testid="enter-email-label">{translationService.get('enterYourEmail')}</div>
			<div>
				<TrusstorTextInput
					placeholder={translationService.get('email')}
					changeFunc={onEmailInputChange}
					startIcon={user}
					isError={emailError.length > 0}
					errorText={emailError}
					testId="email-field"
				/>
			</div>
		</AuthPage>
	) : (
		<ResetPasswordConfirmationPage userEmail={email} />
	);
};
