import { IProjectPackagesFeatureProhibitions } from '../interfaces/IProjectPackagesFeatureProhibitions';
import { ProjectService } from './project.service';
import { ProjectPackageTypeEnum } from '../interfaces/ProjectPackageType.enum';
import { IProject } from '../interfaces/IProject';

export class ProjectAccessLevelService<T extends string> {
	private readonly projectFeaturesPermissionObject: IProjectPackagesFeatureProhibitions<T>;
	private projectService: ProjectService;

	constructor(userFeaturesProhibitedList: IProjectPackagesFeatureProhibitions<T>, projectService: ProjectService) {
		this.projectService = projectService;
		this.projectFeaturesPermissionObject = userFeaturesProhibitedList;
	}

	public hasAccess(featureName: T, project?: IProject): boolean {
		const chosenProject = project || this.projectService.getChosenProject();
		if (!chosenProject) {
			return false;
		}

		const projectPackageType: ProjectPackageTypeEnum = chosenProject.packageType;

		if (!this.projectFeaturesPermissionObject[featureName]) {
			throw new Error(
				`ProjectAccessLevelService: featureName ${featureName} is not defined in the projectFeaturesPermissionObject`
			);
		}
		return !this.projectFeaturesPermissionObject[featureName].includes(projectPackageType);
	}

	public isProjectPackageType(projectPackageType: ProjectPackageTypeEnum): boolean {
		return this.projectService.getChosenProject()!.packageType === projectPackageType;
	}
}
