import React from 'react';
import { map } from 'lodash';
import { ManagerChip } from '../ManagerChip/ManagerChip';
import { IManagerMergedTag } from '../../../interfaces/IManagerMergedTag';

interface IManagerChipsTooltipProps {
	mergedManagers: IManagerMergedTag[];
}

const ManagerChipsTooltip = (props: IManagerChipsTooltipProps) => {
	return (
		<div
			style={{
				display: 'flex',
			}}
		>
			{map(props.mergedManagers, (manager) => {
				return (
					<ManagerChip
						key={`floor_${manager._id}`}
						chipText={manager.manager.name}
						chipBackground={manager.manager.backgroundColor}
					/>
				);
			})}
		</div>
	);
};

export default ManagerChipsTooltip;
