import React from 'react';
import { FilterCard, IFilterCardProps } from '../FilterCard/FilterCard';
import { SequenceTypeDropdown } from '../../SequenceTypeDropDown/SequenceTypeDropdown';
import { SequenceTypeEnum } from '../../../interfaces/SequenceType.enum';
import classes from './styles.module.scss';

interface ISequenceFilterCardProps extends IFilterCardProps {
	name: string;
	sequenceType: SequenceTypeEnum;
}

export const SequenceFilterCard = (props: ISequenceFilterCardProps) => {
	return (
		<FilterCard
			startComponentClass={classes.startComponent}
			{...props}
			startComponent={<SequenceTypeDropdown currentSequenceType={props.sequenceType} readOnly />}
			text={props.name}
		/>
	);
};
