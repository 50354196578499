import { IManager } from '@interfaces/IManager';
import { IProfession } from '@interfaces/IProfession';
import { IWorkersTimesheet } from '@interfaces/IWorkersTimesheets';
import { IMergedTagCarrierWorkerData } from '@shared/interfaces/IMergedTagCarrierWorkerData';
import { MergedTagType } from '@shared/interfaces/IMergedTagType';
import { IProject } from '@shared/interfaces/IProject';
import { IWorkersTimesheetReport } from '@shared/interfaces/IWorkersTimesheetReport';
import { IWorkerTimesheet } from '@shared/interfaces/IWorkerTimesheet';
import { EMPTY_ITEM_CONTENT } from '@src/Components/Pages/AnalysisCenterPages/reports.constants';
import {
	getDayTimesheetByDate,
	getExitTimeString,
	getTimesheetColumns,
	getTotalTimeOnSiteString,
} from '@src/Components/Pages/AnalysisCenterPages/timesheets.utils';
import { exportService, translationService } from '@src/index';
import { IRow, ISheets, ISheetsInfoValues } from '@src/services';
import { compact, sortBy } from 'lodash';
import moment from 'moment';
import {
	getAmPmTimeFormatFromDate,
	getDateRangeFormatted,
	getDateRanges,
	getDayAndMonthLocaleFormat,
	getWeekDayFromDate,
} from './dateUtils';
import { getSpecialtyAndContractorDisplayText } from './professions.utils';
import { getTradeTranslation } from './translations.utils';
import { IMergedTagCarrier } from '@interfaces/IMergedTagCarrier';

const getFormattedWorkersTimesheetReportSheets = (
	workersTimesheet: IWorkersTimesheet,
	tz: string,
	startDate: Date,
	endDate: Date
): ISheets => {
	const dateRange: Date[] = getDateRanges(moment.tz(startDate, tz), moment.tz(endDate, tz));

	const tagsExcelSheets: ISheets = Object.entries(workersTimesheet).reduce(
		(acc, [tagId, { timesheet, tagCarrier }]) => {
			const workersTimesheetRows: IRow[] = getTimesheetRowsForTag(
				timesheet,
				tz,
				dateRange,
				tagId,
				tagCarrier,
				true
			);
			acc[tagId] = workersTimesheetRows;
			return acc;
		},
		{}
	);
	return tagsExcelSheets;
};

export const getRawWorkersTimesheetReportSheets = (
	workersTimesheet: IWorkersTimesheet,
	tz: string,
	startDate: Date,
	endDate: Date
): ISheets => {
	const dateRange: Date[] = getDateRanges(moment.tz(startDate, tz), moment.tz(endDate, tz));

	const tagsExcelSheets: IRow[] = Object.entries(workersTimesheet).reduce(
		(acc, [tagId, { timesheet, tagCarrier }]) => {
			const workersTimesheetRows: IRow[] = getTimesheetRowsForTag(timesheet, tz, dateRange, tagId, tagCarrier);
			return [...acc, ...workersTimesheetRows];
		},
		[] as IRow[]
	);
	return {
		[translationService.get('workerTimesheet')]: tagsExcelSheets,
	};
};

const getTimesheetRowsForTag = (
	timesheet: IWorkerTimesheet[],
	tz: string,
	dateRange: Date[],
	tagId: string,
	tagCarrier?: IMergedTagCarrier,
	isFormattedSheet?: boolean
): IRow[] => {
	return compact(
		dateRange.flatMap((date: Date) => {
			const timesheetsOfDate: IWorkerTimesheet[] = getDayTimesheetByDate(date, timesheet, tz);
			const sortedTimesheetsOfDateByStartTime: IWorkerTimesheet[] = sortBy(
				timesheetsOfDate,
				(timesheet) => timesheet.arrivalTimeTimestamp
			);
			const records: IWorkerTimesheet[] =
				sortedTimesheetsOfDateByStartTime.length === 0
					? ([{ date }] as IWorkerTimesheet[])
					: sortedTimesheetsOfDateByStartTime;
			const timesheetColumns = isFormattedSheet ? getTimesheetColumns(tz) : getRawTimesheetColumns(tz);
			return records.map((record) => {
				return Object.entries(timesheetColumns).reduce((acc, [key, column]) => {
					const columnContent = column.renderTableContentMethod(record, tagCarrier, tagId);
					return {
						...acc,
						[column.title]: {
							value: columnContent || EMPTY_ITEM_CONTENT,
						},
					};
				}, {});
			});
		})
	);
};

const getRawTimesheetColumns = (tz: string) => {
	return [
		{
			title: translationService.get('contractor'),
			renderTableContentMethod: (record: IWorkerTimesheet, tagCarrier?: IMergedTagCarrier) =>
				getProfessionValueToDisplay(tagCarrier),
		},
		{
			title: translationService.get('fullName'),
			renderTableContentMethod: (record: IWorkerTimesheet, tagCarrier?: IMergedTagCarrier) =>
				getNameValueToDisplay(tagCarrier),
		},
		{
			title: translationService.get('tagId'),
			renderTableContentMethod: (record: IWorkerTimesheet, tagCarrier?: IMergedTagCarrier, tagId?: string) =>
				tagId,
		},
		{
			title: translationService.get('date'),
			renderTableContentMethod: (record: IWorkerTimesheet, tagCarrier?: IMergedTagCarrier, tagId?: string) =>
				getDayAndMonthLocaleFormat(record.date, tz),
		},
		{
			title: translationService.get('day'),
			renderTableContentMethod: (record: IWorkerTimesheet, tagCarrier?: IMergedTagCarrier, tagId?: string) =>
				getWeekDayFromDate(record.date, tz, translationService.getDateLocale()),
		},
		{
			title: translationService.get('arrivalTime'),
			renderTableContentMethod: (record: IWorkerTimesheet, tagCarrier?: IMergedTagCarrier, tagId?: string) =>
				record.arrivalTimeTimestamp && getAmPmTimeFormatFromDate(record.arrivalTimeTimestamp, tz),
		},
		{
			title: translationService.get('departureTime'),
			renderTableContentMethod: (record: IWorkerTimesheet, tagCarrier?: IMergedTagCarrier, tagId?: string) =>
				record.exitTimeTimestamp && getExitTimeString(record, tz),
		},
		{
			title: translationService.get('totalHoursOnSite'),
			renderTableContentMethod: (record: IWorkerTimesheet, tagCarrier?: IMergedTagCarrier, tagId?: string) =>
				record.exitTimeTimestamp && getTotalTimeOnSiteString(record, tz),
		},
	];
};
const getSheetsInfoValues = (
	workersTimesheetReport: IWorkersTimesheetReport,
	workersTimesheet: IWorkersTimesheet,
	project: IProject,
	tz: string
): ISheetsInfoValues => {
	const sheetsInfoValues: ISheetsInfoValues = Object.entries(workersTimesheet).reduce(
		(acc: ISheetsInfoValues, [tagId, { tagCarrier, timesheet }]) => {
			const workerLabel: string =
				tagCarrier?.tagType === MergedTagType.Worker
					? translationService.get('worker')
					: translationService.get('manager');
			const workerValue: string = getProfessionValueToDisplay(tagCarrier);
			const fullName: string = getNameValueToDisplay(tagCarrier);
			const IdNumber: string =
				tagCarrier?.tagType === MergedTagType.Worker
					? (tagCarrier.tagData as IMergedTagCarrierWorkerData<IProfession>).idNumber ?? 'N/A'
					: 'N/A';
			acc[tagId] = {
				[workerLabel]: {
					rowNumber: 5,
					columnChar: 'A',
					value: workerValue,
				},
				[translationService.get('project')]: {
					rowNumber: 6,
					columnChar: 'A',
					value: project.name,
				},
				[translationService.get('reportingPeriod')]: {
					rowNumber: 5,
					columnChar: 'D',
					value: getDateRangeFormatted(
						new Date(workersTimesheetReport.fromDate),
						new Date(workersTimesheetReport.toDate),
						tz
					),
				},
				[translationService.get('fullName')]: {
					rowNumber: 6,
					columnChar: 'D',
					value: fullName,
				},
				[translationService.get('IDNumber')]: {
					rowNumber: 7,
					columnChar: 'D',
					value: IdNumber,
				},
				[translationService.get('tagId')]: {
					rowNumber: 8,
					columnChar: 'D',
					value: tagId,
				},
			};
			return acc;
		},
		{}
	);
	return sheetsInfoValues;
};

const getProfessionValueToDisplay = (tagCarrier?: IMergedTagCarrier): string => {
	return tagCarrier?.tagType === MergedTagType.Worker
		? getSpecialtyAndContractorDisplayText(
				(tagCarrier.tagData as IMergedTagCarrierWorkerData<IProfession>).profession
			)
		: getTradeTranslation(tagCarrier?.tagData as IManager);
};

const getNameValueToDisplay = (tagCarrier?: IMergedTagCarrier): string => {
	return tagCarrier?.tagType === MergedTagType.Worker
		? (tagCarrier.tagData as IMergedTagCarrierWorkerData<IProfession>).name ?? 'N/A'
		: (tagCarrier?.tagData as IManager).name;
};

export const exportTimesheetReportToExcel = async (
	workersTimesheetReport: IWorkersTimesheetReport,
	workersTimesheet: IWorkersTimesheet,
	project: IProject,
	tz: string,
	rawReport?: boolean
): Promise<void> => {
	const sheets: ISheets = rawReport
		? getRawWorkersTimesheetReportSheets(
				workersTimesheet,
				tz,
				workersTimesheetReport.fromDate,
				workersTimesheetReport.toDate
			)
		: getFormattedWorkersTimesheetReportSheets(
				workersTimesheet,
				tz,
				workersTimesheetReport.fromDate,
				workersTimesheetReport.toDate
			);
	const fileName: string = `${workersTimesheetReport.title} - ${getDateRangeFormatted(
		new Date(workersTimesheetReport.fromDate),
		new Date(workersTimesheetReport.toDate),
		tz
	)}`;
	await exportService.exportMultipleSheetsToExcel(
		sheets,
		fileName,
		translationService.get('workersTimesheet'),
		getDateRangeFormatted(new Date(workersTimesheetReport.fromDate), new Date(workersTimesheetReport.toDate), tz),
		{ headerRow: 10 },
		undefined,
		getSheetsInfoValues(workersTimesheetReport, workersTimesheet, project, tz)
	);
};
