import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import { IconColor, IconSize, TrusstorIconShared } from '../TrusstorIconShared/TrusstorIconShared';
import { IconNames } from '../TrusstorIconShared/IconNames.enum';
import classes from './styles.module.scss';
import classnames from 'classnames';

const useStyles = makeStyles({
	root: {
		'paddingInlineStart': 0,
		'&:hover': {
			backgroundColor: 'transparent',
		},
	},
});

interface ICheckboxProps {
	checked: boolean;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	disabled?: boolean;
	propagateEvent?: boolean;
	isMobile?: boolean;
}

const CheckedCheckboxIcon = ({
	checked,
	disabled,
	hovered,
}: {
	checked?: boolean;
	disabled?: boolean;
	hovered: boolean;
}) => {
	return (
		<div
			className={classnames({
				[classes.iconContainer]: true,
				[classes.disabled]: disabled,
				[classes.checked]: checked,
				[classes.unchecked]: !checked,
			})}
		>
			{checked && (
				<TrusstorIconShared
					iconName={hovered ? IconNames.minus : IconNames.check}
					color={IconColor.White}
					size={IconSize.SMALL}
				/>
			)}
		</div>
	);
};

const TrusstorCheckbox = (props: ICheckboxProps) => {
	const [hoverState, setHoverState] = React.useState(false);

	const classes = useStyles();

	return (
		<Checkbox
			onMouseEnter={() => setHoverState(true)}
			onMouseLeave={() => setHoverState(false)}
			onClick={(e) => {
				props.onClick?.(e);
				if (props.propagateEvent) return;
				e.stopPropagation();
			}}
			onChange={props.onChange}
			className={classes.root}
			disableRipple
			disabled={props.disabled}
			checkedIcon={
				<CheckedCheckboxIcon checked={true} disabled={props.disabled} hovered={hoverState && !props.isMobile} />
			}
			icon={
				<CheckedCheckboxIcon
					checked={false}
					disabled={props.disabled}
					hovered={hoverState && !props.isMobile}
				/>
			}
			inputProps={{ 'aria-label': 'decorative checkbox' }}
			{...props}
		/>
	);
};

export { TrusstorCheckbox };
