import { IProfession, ITrade } from '../interfaces';
import { translationService } from '../index';
import { IMinimizedProfession } from '../interfaces/IMinimizedProfession';
import { getTradeTranslation } from './translations.utils';

export const getProfessionDisplayText = (
	profession: IProfession | ITrade,
	withoutContractor: boolean = false
): string => {
	if (!profession) {
		return '';
	}
	let displayText: string = getTradeTranslation(profession);
	if ((profession as IProfession).specialty) {
		displayText += `, ${(profession as IProfession).specialty}`;
	}
	if ((profession as IProfession).contractor && !withoutContractor) {
		displayText += ` - ${(profession as IProfession).contractor}`;
	}
	return displayText;
};

export const getContractorAndTradeFromProfession = (profession: IProfession) => {
	const tradeText: string = getTradeTranslation(profession);
	if (!profession || !tradeText) {
		return '';
	}
	const contractorText: string | undefined = profession.contractor;
	if (!contractorText) {
		return tradeText;
	}
	const isRtl: boolean = translationService.getIsRtl();
	if (isRtl) {
		return `${contractorText} - ${tradeText}`;
	}
	return `${tradeText} - ${contractorText}`;
};

export const getMinimizedProfession = (profession: IProfession | undefined): IMinimizedProfession | null => {
	if (!profession) {
		return null;
	}
	return {
		_id: profession._id,
		dateCreated: profession.dateCreated,
		projectId: profession.projectId,
		tradeGroup: profession.tradeGroup,
		tradeId: profession.tradeId,
		organizationId: profession.organizationId,
	};
};

export const getSpecialtyAndContractorDisplayText = (profession: IProfession | ITrade): string => {
	if (!profession) {
		return '';
	}
	const specialty: string = `${(profession as IProfession).specialty ?? ''}`;
	const contractor: string = `${
		(profession as IProfession).contractor
			? `${specialty === '' ? '' : ' - '}${(profession as IProfession).contractor}`
			: ''
	}`;
	return `${specialty}${contractor}`;
};
