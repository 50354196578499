import React from 'react';
import RoadBlock from '../../assets/icons/roadBlock.svg';
import SkyLine from '../../assets/icons/skyLine.svg';
import classes from './styles.module.scss';
import { useSharedServices } from '../../hooks/sharedServices.context';
import { ITrusstorButtonSize, ITrusstorButtonType, TrusstorButton } from '../buttons/TrusstorButton/TrusstorButton';
import { TrusstorIconShared } from '../TrusstorIconShared/TrusstorIconShared';
import { IconNames } from '../TrusstorIconShared/IconNames.enum';

interface ISomethingWentWrongPageProps {
	onRefresh: () => void;
}

export const SomethingWentWrongPage = (props: ISomethingWentWrongPageProps) => {
	const { translationService } = useSharedServices();

	return (
		<div className={classes.somethingWentWrongPageContainer}>
			<div className={classes.infoContainer}>
				<div className={classes.assetsContainer}>
					<img src={SkyLine} alt="SkyLine" className={classes.skyLine} />
					<img src={RoadBlock} alt="RoadBlock" className={classes.roadBlock} />
				</div>
				<div className={classes.texts}>
					<p className={classes.title}>{translationService.get('somethingWentWrong').replaceAll(',', '')}</p>
					<p className={classes.description}>{translationService.get('somethingWentWrongDescription')}</p>
				</div>
				<TrusstorButton
					text={translationService.get('refresh')}
					buttonType={ITrusstorButtonType.OUTLINE}
					buttonSize={ITrusstorButtonSize.LARGE}
					iconElement={<TrusstorIconShared iconName={IconNames.refresh} className={classes.refreshIcon} />}
					handleClick={() => props.onRefresh()}
				></TrusstorButton>
			</div>
		</div>
	);
};
