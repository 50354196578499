import React, { CSSProperties } from 'react';
import { textStyle } from '../../utils/text.utils';
import classes from './styles.module.scss';
import { COLORS } from '../../constants/colors.constants';
import { IconPatchStatuses } from '../../constants/constants';
import { addOpacityToColor } from '../../utils/colors.utils';
import checkIcon from '../../assets/icons/white_check_icon.svg';

interface IconPatchProps {
	imageSource?: string;
	color: string;
	containerHeight?: number;
	containerWidth?: number;
	imgHeight?: number;
	imgWidth?: number;
	patchKey?: string;
	number?: number;
	isManager?: boolean;
	fontClass?: string;
	status?: string;
	isSelected?: boolean;
	vTextOnSelected?: boolean;
	testId?: string;
}

const IconPatch = ({
	imageSource,
	color,
	containerHeight,
	containerWidth,
	imgHeight,
	imgWidth,
	patchKey,
	number,
	isManager = false,
	fontClass = 'body1',
	status = IconPatchStatuses.SELECTED,
	isSelected = false,
	vTextOnSelected = false,
	testId = '',
}: IconPatchProps): JSX.Element => {
	const getBackgroundColor = (color): string => {
		if (!imageSource) {
			if (isSelected) {
				return color;
			}
			if (status === IconPatchStatuses.DEFAULT) {
				return 'transparent';
			}
			if (status === IconPatchStatuses.HOVER) {
				return addOpacityToColor(color, 0.5);
			}
		}
		return 'transparent';
	};
	const containerStyle: CSSProperties = {
		backgroundColor: getBackgroundColor(color),
		borderRadius: 2,
		height: imageSource ? 25 : containerHeight,
		minWidth: imageSource ? 25 : containerWidth,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		margin: 2,
		border: `solid 1px ${color ? color : 'transparent'}`,
	};
	const imgStyle = {
		height: imgHeight || 18,
		width: imgWidth || 18,
		alignSelf: 'center',
	};
	const valueTextStyle = textStyle({
		fontClass,
		color: isManager ? COLORS.darkBlue : COLORS.white,
	});

	return (
		<div style={containerStyle} className={classes.forNarrowClass} data-testId={testId}>
			{imageSource && <img alt="icon" src={imageSource} style={imgStyle} key={patchKey} />}
			{isSelected && vTextOnSelected && <img src={checkIcon} className={classes.checkIcon} />}
			{!imageSource && number !== undefined && number !== null && <span style={valueTextStyle}>{number}</span>}
		</div>
	);
};

export { IconPatch };
