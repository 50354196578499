import React from 'react';
import classnames from 'classnames';
import { FilterCard, IFilterCardProps } from '../FilterCard/FilterCard';
import classes from './styles.module.scss';

interface IAreaFilterCardProps extends IFilterCardProps {
	areaNick: string;
	hasStartMargin?: boolean;
}

export const AreaFilterCard = (props: IAreaFilterCardProps) => {
	return (
		<FilterCard
			{...props}
			text={props.areaNick}
			textClass={classes.areaText}
			indentationLevel={props.hasStartMargin ? 1 : undefined}
		/>
	);
};
