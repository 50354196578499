import React from 'react';
import classes from './styles.module.scss';

interface IFloorHeaderSeparatorProps {
	data?: any;
}

const FloorHeaderSeparator = (props: IFloorHeaderSeparatorProps) => {
	return (
		<div className={classes.container}>
			<div className={classes.fixedText}>{props.data}</div>
		</div>
	);
};

export { FloorHeaderSeparator };
