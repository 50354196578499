import React, { useEffect, useRef, useState } from 'react';
import classes from './styles.module.scss';
import { translationService } from '../../../index';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../store/slices';
import { APP_FILTER_TYPES } from '../../../constants';
import { ArrowUpDown } from '@shared/components/ArrowUpDown/ArrowUpDown';

interface IExpandableComponentProps {
	childrenLength: number;
	headerText: string;
	clearFunction: (ev?: MouseEvent) => void;
	shouldHideClearButton: boolean;
	clearLength: number;
	children?: any;
	type: APP_FILTER_TYPES;
	hideEmptyList?: boolean;
	defaultOpen?: boolean;
	testId?: string;
	alwaysHideList?: boolean;
}

const ExpandableComponent = (props: IExpandableComponentProps) => {
	const activeFilterType: APP_FILTER_TYPES | null = useSelector(
		(state: IRootState) => state.appFilterReducer.activeFilterType
	);

	const isActiveFilterTypeWithChildren: boolean = activeFilterType === props.type && props.childrenLength > 0;

	const [isOpen, setIsOpen] = useState<boolean | undefined>(props.defaultOpen);
	const topSectionEl = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (props.defaultOpen) {
			setIsOpen(props.childrenLength > 0);
			return;
		}
		setIsOpen(isActiveFilterTypeWithChildren);
	}, [props.childrenLength, activeFilterType]);

	const handleClick = (): void => {
		if (props.childrenLength > 0) {
			setIsOpen((prevState) => !prevState);
		}
	};

	const handleClearClick = (ev): void => {
		ev.stopPropagation();
		props.clearFunction();
	};

	const isRtl: boolean = translationService.getIsRtl();

	if (props.alwaysHideList || (props.hideEmptyList && props.childrenLength === 0)) {
		return <></>;
	}

	return (
		<section
			className={classes.expandableContainer}
			style={{
				pointerEvents: props.childrenLength > 0 ? 'all' : 'none',
			}}
			data-testid={`${props.testId}&isOpen=${isOpen}`}
		>
			<section className={classes.topSection} onClick={handleClick} ref={topSectionEl}>
				<ArrowUpDown isOpen={!!isOpen} horizontalClosed useReverseOpenDirection />
				<p className={classes.headerText}>{`${props.headerText} (${props.childrenLength})`}</p>
				{!props.shouldHideClearButton && (
					<div
						onClick={handleClearClick}
						className={`${classes.clearButton} ${isRtl ? classes.right : ''}`}
						data-testid={'clearButton'}
					>
						{translationService.get('clear')}({props.clearLength})
					</div>
				)}
			</section>
			{isOpen && <div className={classes.children}>{props.children}</div>}
		</section>
	);
};

export { ExpandableComponent };
