import React, { useState, useRef } from 'react';
import { map, concat, compact } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { arrowDown, arrowUp, arrowDownWhite, arrowUpWhite } from '@shared/assets/icons/tradesBox';
import { useOutsideClick } from '../../../hooks/custom.hooks';
import { COLORS } from '../../../constants';
import classnames from 'classnames';
import scssClasses from './styles.module.scss';

const useStyles = makeStyles((theme) => ({
	superContainer: ({ minClosedHeight }) => ({
		position: 'relative',
		minHeight: minClosedHeight ? minClosedHeight : 46,
	}),
	headerClosed: ({ isBlackMenu, disabled }) => ({
		'zIndex': 1,
		'display': 'flex',
		'alignItems': 'center',
		'width': '100%',
		'height': '100%',
		'cursor': !disabled && 'pointer',
		'userSelect': 'none',
		'fontSize': 17,
		'borderRadius': 3,
		'backgroundColor': isBlackMenu ? COLORS.black : COLORS.white,
		'position': 'absolute',
		'top': 0,
		'right': 0,
		'&:hover': {
			backgroundColor: '#F4F7F8 !important',
		},
	}),
	headerOpen: ({ isBlackMenu, disabled }) => ({
		zIndex: 11,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
		cursor: !disabled && 'pointer',
		userSelect: 'none',
		fontSize: 17,
		borderRadius: 3,
		boxShadow: '0px 2px 0 0 rgba(0, 0, 0, 0.1)',
		backgroundColor: isBlackMenu ? COLORS.black : COLORS.white,
		position: 'absolute',
		top: 0,
		right: 0,
	}),
	headerTitle: ({ isBlackMenu }) => ({
		fontSize: '16px',
		fontWeight: '500',
		color: isBlackMenu ? '#fffefe' : COLORS.primaryColor,
		marginLeft: 6,
	}),
	bigHeaderTitle: ({ isBlackMenu }) => ({
		fontSize: 20,
		fontWeight: '700',
		color: isBlackMenu ? '#fffefe' : COLORS.primaryColor,
		marginLeft: 6,
	}),
	disabledTextInput: {
		marginLeft: 6,
		fontClass: 'h2',
		height: 17,
		width: '100%',
		borderWidth: 0,
		color: '#7b7b7b',
	},
	arrow: {
		height: 17,
		width: 17,
		marginRight: 6,
	},
	selectContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		width: '100%',
	},
	listContainer: {
		display: 'flex',
		flexDirection: 'column',
		maxHeight: 150,
		overflowY: 'auto',
		marginLeft: 12,
		marginRight: 12,
	},
	headerContainer: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: 4,
		marginTop: 6,
	},
	checkRoot: {
		width: 10,
		height: 10,
	},
	listItemContainer: {
		'display': 'flex',
		'alignItems': 'center',
		'justifyContent': 'flex-start',
		'paddingRight': 4,
		'paddingLeft': 4,
		'marginBottom': 4,
		'height': '48px',
		'&:hover': {
			backgroundColor: '#effbd6',
		},
	},
	listItem: {
		fontClass: 'h2',
		color: '#5d5b5a',
		fontWeight: 'normal',
	},
	errorText: {
		fontClass: 'body1',
		color: COLORS.red400,
		paddingRight: 2,
	},
	errorContainer: {
		height: 15,
		marginBottom: theme.spacing(1),
	},
	container: {
		display: 'flex',
		flexDirection: 'column',
	},
	containerError: {},
	fullWidthContainer: {
		width: '100%',
	},
}));

const CustomSelect = ({
	disabled,
	currentValue,
	placeholder,
	list,
	selectHandler,
	rootStyleClass,
	minClosedHeight,
	isBlackMenu,
	menuItemComponent,
	firstItem = null,
	fieldToDisplay,
	idField,
	errorMessage,
	isError,
	errorEnabled,
	bigCustomSelect = false,
}) => {
	const classes = useStyles({ isBlackMenu, minClosedHeight, disabled });
	const wrapperRef = useRef(null);

	const [listOpen, setListOpen] = useState(false);
	const [selectedItem, setSelectedItem] = useState(currentValue);

	useOutsideClick(wrapperRef, setListOpen, false);

	const openMenuClick = () => {
		setSelectedItem(currentValue);
		return setListOpen(true);
	};
	const closeMenuClick = () => setListOpen(false);

	const changeSelectedItem = (item) => {
		if (item === firstItem || item === placeholder) {
			setSelectedItem(null);
			selectHandler(null);
		} else {
			setSelectedItem(item);
			selectHandler(item);
		}
		setListOpen(false);
	};

	const constructList = () => {
		return !selectedItem ? list : compact(concat(firstItem, list));
	};

	const getMenuItemComponent = (props) => {
		return menuItemComponent ? menuItemComponent(props) : FallbackMenuItem(props);
	};

	const headerTitleClassName = classnames({
		[classes.headerTitle]: !bigCustomSelect,
		[classes.bigHeaderTitle]: bigCustomSelect,
	});

	const FallbackMenuItem = ({ item, selectedItem, changeSelected, index, fieldToDisplay }) => {
		return item === selectedItem ? null : (
			<div
				key={idField ? item[idField] : item}
				className={classes.listItemContainer}
				onClick={() => changeSelected(item)}
			>
				<p className={classes.listItem}>{fieldToDisplay ? item[fieldToDisplay] : item}</p>
			</div>
		);
	};

	return (
		<div
			className={classnames({
				[classes.container]: true,
				[classes.fullWidthContainer]: bigCustomSelect,
			})}
		>
			<div
				className={classnames({
					[classes.superContainer]: true,
					[rootStyleClass]: true,
					[classes.containerError]: isError,
					[classes.fullWidthContainer]: bigCustomSelect,
					[scssClasses.text]: true,
				})}
			>
				{listOpen ? (
					<div ref={wrapperRef} className={classes.headerOpen}>
						<div className={classes.selectContainer}>
							<div className={classes.headerContainer} onClick={closeMenuClick}>
								<img className={classes.arrow} src={isBlackMenu ? arrowUpWhite : arrowUp} alt="<" />
								<div className={headerTitleClassName}>{currentValue || placeholder}</div>
							</div>
							<div className={classes.listContainer}>
								{map(constructList(), (item, index) =>
									getMenuItemComponent({
										index,
										item,
										selectedItem,
										changeSelected: changeSelectedItem,
										isPlaceholder: item === placeholder || item === firstItem,
										idField,
										fieldToDisplay,
									})
								)}
							</div>
						</div>
					</div>
				) : (
					<div className={classes.headerClosed} onClick={!disabled ? openMenuClick : () => {}}>
						{!disabled && (
							<img className={classes.arrow} src={isBlackMenu ? arrowDownWhite : arrowDown} alt=">" />
						)}
						<div className={disabled ? classes.disabledTextInput : headerTitleClassName}>
							{currentValue || placeholder}
						</div>
					</div>
				)}
			</div>
			{errorEnabled && (
				<div className={classes.errorContainer}>
					{isError && <p className={classes.errorText}>{errorMessage}</p>}
				</div>
			)}
		</div>
	);
};

export { CustomSelect };
