import React, { useEffect, useState } from 'react';
import { sortBy } from 'lodash';
import { IFloorData } from '@interfaces/IFloorData';
import { IMergedEquipmentView } from '@interfaces/IMergedEquipmentView';
import { entitiesProperties } from '../../../constants';
import { IManagerMergedTag } from '@interfaces/IManagerMergedTag';
import { IProfessionWithActiveTags } from '@interfaces/IProfessionWithActiveTags';
import classes from './styles.module.scss';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { SpecialFloorResources } from '@src/Components/MainViewFloorSections/SpecialFloorBox/SpecialFloorResources/SpecialFloorResources';
import { getIsOperatorInHoist } from '@src/Components/Hoists/utils/hoist.utils';
import { SpecialFloorInfo } from '@src/Components/MainViewFloorSections/SpecialFloorBox/SpecialFloorInfo/SpecialFloorInfo';
import { SpecialFloorTitleSection } from '@src/Components/MainViewFloorSections/SpecialFloorBox/SpecialFloorTitleSection/SpecialFloorTitleSection';
import { SpecialFloorEmptyState } from '@src/Components/MainViewFloorSections/SpecialFloorBox/SpecialFloorEmptyState/SpecialFloorEmptyState';
import classnames from 'classnames';
import { useHistory, useParams } from 'react-router-dom';
import { setFloorPathForFloorView } from '@utils/floors.utils';

interface ISpecialFloorBoxProps {
	startIconName?: IconNames;
	floorData: IFloorData;
	equipmentData: IMergedEquipmentView[];
	isAdmin: boolean;
	safetyIcon: React.ReactNode;
	showSafetyIcon: boolean;
}

const SpecialFloorBox = (props: ISpecialFloorBoxProps) => {
	const [isEmptyArea, setIsEmptyArea] = useState<boolean>(true);
	const history = useHistory();

	const professionsForFloor: IProfessionWithActiveTags[] = props.floorData
		? sortBy(props.floorData.activeProfessions, [entitiesProperties.sortIndex, entitiesProperties.tradeId])
		: [];
	const managersForFloor: IManagerMergedTag[] = props.floorData
		? sortBy(props.floorData.managers, [entitiesProperties.sortIndex, entitiesProperties.name])
		: [];

	useEffect(() => {
		const isEmptyArea: boolean =
			!props.floorData ||
			(managersForFloor.length === 0 &&
				professionsForFloor.length === 0 &&
				props.equipmentData.length === 0 &&
				!props.floorData.utilities?.length);
		setIsEmptyArea(isEmptyArea);
	}, [props.floorData, managersForFloor.length, professionsForFloor.length, props.equipmentData.length]);

	const isHoist: boolean = props.floorData.isHoist;
	const isOperatorInHoist: boolean = getIsOperatorInHoist(props.floorData);

	const setFloorPath = () => setFloorPathForFloorView(props.floorData.floorId, history);

	const { floorId }: { floorId: string } = useParams();

	const isSelected: boolean = props.floorData.floorId === floorId;
	return (
		<div
			className={classnames(classes.box, { [classes.isSelected]: isSelected })}
			onClick={setFloorPath}
			data-testid={`specialFloor=${props.floorData.floorId}`}
		>
			<SpecialFloorTitleSection isHoist={isHoist} floorData={props.floorData} />
			{isEmptyArea ? (
				<SpecialFloorEmptyState />
			) : (
				<>
					<SpecialFloorResources
						equipments={props.equipmentData}
						managers={managersForFloor}
						professions={professionsForFloor}
						utilities={props.floorData.utilities || []}
					/>
					<SpecialFloorInfo
						isHoist={isHoist}
						isOperatorInHoist={isOperatorInHoist}
						showSafetyIcon={props.showSafetyIcon}
						safetyIcon={props.safetyIcon}
					/>
				</>
			)}
		</div>
	);
};

export { SpecialFloorBox };
