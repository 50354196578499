import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { textStyle } from '@shared/utils/text.utils';
import { TrusstorTooltip } from '../../../../shared/components/TrusstorTooltip/TrusstorTooltip';
import { TooltipProps } from '@material-ui/core/Tooltip';
import { COLORS } from '../../constants';

const useStyles = makeStyles({
	detailTooltip: textStyle({
		color: COLORS.white,
		fontClass: 'body2',
	}),
});

interface ITooltipedIconProps {
	children: ReactElement<any, any>;
	tooltipText?: string;
	tooltipComponent?: ReactElement<any, any>;
	placement?: TooltipProps['placement'];
	onOpen?: () => void;
}

const TooltipedIcon = (props: ITooltipedIconProps) => {
	const classes = useStyles();
	const { placement = 'top' } = props;
	return (
		<TrusstorTooltip
			onOpen={props.onOpen}
			placement={placement}
			showAlways
			cleanStyling
			tooltipComponent={props.tooltipComponent || <p className={classes.detailTooltip}>{props.tooltipText}</p>}
		>
			{props.children}
		</TrusstorTooltip>
	);
};

export { TooltipedIcon };
