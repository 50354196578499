import React, { useEffect, useState } from 'react';
import { IWeekDayActivity } from '../../../interfaces';
import { IMenuItem, TrusstorMenu } from '@shared/components/TrusstorMenu/TrusstorMenu';
import { accessLevelService, translationService } from '../../../index';
import { ActivityGroupStatus } from '@shared/interfaces/ActivityGroupStatus.enum';
import {
	ActivitySequenceItemMenuActions,
	getCreateNewIssueMenuItem,
	getViewIssuesMenuItem,
} from '@src/Components/WorkPlan/ActivityProgressTracker/components/ActivitySequenceMatrix/cellComponents/ActivitySequenceItemCell/activitySequence.menuItems';
import { TrusstorIcon } from '@src/Components/TrusstorIcon/TrusstorIcon';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { IconColor } from '@shared/components/TrusstorIconShared/TrusstorIconShared';

interface IActivityChipMenuProps {
	activity: Pick<IWeekDayActivity, 'status' | 'issues'>;
	width: number;
	openEditDialog: (e) => void;
	openStartDialog: () => void;
	openDeleteDialog: () => void;
	openCompleteDialog: () => void;
	onMenuHeightChange?: () => void;
	openCreateIssueDialog: (actionType: ActivitySequenceItemMenuActions) => Promise<void>;
	openViewIssuesDialog: (actionType: ActivitySequenceItemMenuActions) => Promise<void>;
}

export const ActivityChipMenu = (props: IActivityChipMenuProps) => {
	const userHasAccessToActions: boolean = accessLevelService.hasAccess('activitiesWidgetActions');
	const [currentMenuItems, setCurrentMenuItems] = useState<IMenuItem[]>([]);

	const showEditDetailsItem: IMenuItem = {
		title: translationService.get('edit'),
		iconComponent: <TrusstorIcon iconName={IconNames.clipboard} color={IconColor.Grey400} />,
		onClick: props.openEditDialog,
	};

	const deleteItem: IMenuItem = {
		title: translationService.get('delete'),
		iconComponent: <TrusstorIcon iconName={IconNames.trash} color={IconColor.Grey400} />,
		backgroundColor: '#EEEEEE',
		onClick: props.openDeleteDialog,
	};

	const startActivityItem: IMenuItem = {
		title: translationService.get('startActivity'),
		iconComponent: <TrusstorIcon iconName={IconNames.play} color={IconColor.Grey400} />,
		onClick: props.openStartDialog,
	};

	const markAsCompleteItem: IMenuItem = {
		title: translationService.get('markAsComplete'),
		iconComponent: <TrusstorIcon iconName={IconNames.checkCircle} color={IconColor.Grey400} />,
		borderTop: true,
		onClick: props.openCompleteDialog,
	};

	const createIssue: IMenuItem = getCreateNewIssueMenuItem(props.openCreateIssueDialog);
	const viewIssuesItem: IMenuItem = getViewIssuesMenuItem(props.openViewIssuesDialog, { borderTop: true });
	const viewIssuesIfNeeded: IMenuItem[] = props.activity.issues?.length ? [viewIssuesItem] : [];
	const markAsCompletedIfAllowed: IMenuItem[] = userHasAccessToActions ? [markAsCompleteItem] : [];
	const startActivityIfAllowed: IMenuItem[] = userHasAccessToActions ? [startActivityItem] : [];
	const deleteIfAllowed: IMenuItem[] = userHasAccessToActions ? [deleteItem] : [];
	const editIfAllowed: IMenuItem[] = userHasAccessToActions ? [showEditDetailsItem] : [];
	const createIssueIfAllowed: IMenuItem[] = userHasAccessToActions ? [createIssue] : [];

	const activityStatusToItems: { [status: string]: IMenuItem[] } = {
		[ActivityGroupStatus.planned]: [
			...editIfAllowed,
			...viewIssuesIfNeeded,
			...createIssueIfAllowed,
			...deleteIfAllowed,
		],
		[ActivityGroupStatus.delayed]: [
			...editIfAllowed,
			...startActivityIfAllowed,
			...viewIssuesIfNeeded,
			...createIssueIfAllowed,
			...deleteIfAllowed,
		],
		[ActivityGroupStatus.inProgress]: [
			...editIfAllowed,
			...viewIssuesIfNeeded,
			...createIssueIfAllowed,
			...markAsCompletedIfAllowed,
		],
		[ActivityGroupStatus.overdue]: [
			...editIfAllowed,
			...viewIssuesIfNeeded,
			...createIssueIfAllowed,
			...markAsCompletedIfAllowed,
		],
		[ActivityGroupStatus.complete]: [...editIfAllowed, ...viewIssuesIfNeeded],
	};

	useEffect(() => {
		setCurrentMenuItems(activityStatusToItems[props.activity.status] || []);
	}, [props.activity.status]);

	if (!currentMenuItems.length) {
		return <></>;
	}

	return <TrusstorMenu width={props.width} items={currentMenuItems} onMenuHeightChange={props.onMenuHeightChange} />;
};
