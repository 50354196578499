import React from 'react';
import { IWorkerMergedTag } from '@interfaces/IWorkerMergedTag';
import { Dictionary, groupBy, uniq } from 'lodash';
import { AreaSection } from './AreaSection';
import classes from './styles.module.scss';
import { translationService } from '@src/servicesInitializers';
import { IManagerMergedTag } from '@shared/interfaces/IManagerMergedTag';
import { IMergedEquipmentView } from '@interfaces/IMergedEquipmentView';
import { IMergedFloorView } from '@src/interfaces';
import { IConfigArea } from '@shared/interfaces/IConfigArea';
import { useFloorAreasQuery } from '@src/hooks/queries/areas.queries.hooks';
import { selectProjectId } from '@store/slices/project.slice';
import { useSelector } from 'react-redux';
import { IUtilityMergedTag } from '@interfaces/IUtilityMergedTag';

interface IFloorWorkforceSectionProps {
	floorEquipment: IMergedEquipmentView[];
	workerTags: IWorkerMergedTag[];
	managerTags: IManagerMergedTag[];
	floorUtilities: IUtilityMergedTag[];
	isHoistFloor?: boolean;
	floor: IMergedFloorView;
}

export const FloorWorkforceSection = (props: IFloorWorkforceSectionProps) => {
	const projectId: string = useSelector(selectProjectId)!;
	const floorAreas: IConfigArea[] = useFloorAreasQuery(projectId, props.floor.floorId);

	const areaNicksFromTags: string[] = uniq([
		...[...props.workerTags, ...props.managerTags, ...props.floorUtilities].map(
			(tag) => tag.closestAnchor.areaNick
		),
		...props.floorEquipment.map((eq) => eq.areaNick),
	]);

	const shouldShowAreasSections: boolean = floorAreas.length > 1 || !props.floor.isSpecialFloor;

	const equipmentByAreas = groupBy(props.floorEquipment, (eq) => eq.areaNick);

	const utilityByAreas = groupBy(props.floorUtilities, (eq) => eq.closestAnchor.areaNick);

	const tagsGroupedByAreas: Dictionary<IWorkerMergedTag[]> = groupBy(
		props.workerTags,
		(tag) => tag.closestAnchor.areaNick
	);
	const managerTagsGroupedByAreas: Dictionary<IManagerMergedTag[]> = groupBy(
		props.managerTags,
		(tag) => tag.closestAnchor.areaNick
	);

	const isEmptyState: boolean =
		!props.workerTags.length &&
		!props.managerTags.length &&
		!props.floorEquipment.length &&
		!props.floorUtilities.length;
	return (
		<div>
			{isEmptyState ? (
				<div className={classes.emptyState} data-testid={'personnelEmptyState'}>
					{translationService.get('floorPersonnelEmptyState')}
				</div>
			) : (
				<>
					{!shouldShowAreasSections ? (
						<AreaSection
							equipments={props.floorEquipment}
							isHoistFloor={props.isHoistFloor}
							shouldHideExpandButton
							areaNick={floorAreas[0]?.areaNick}
							workerTags={props.workerTags}
							managerTags={props.managerTags}
							utilities={props.floorUtilities}
						/>
					) : (
						areaNicksFromTags.map((areaNick) => {
							return (
								<AreaSection
									equipments={equipmentByAreas[areaNick]}
									isHoistFloor={props.isHoistFloor}
									areaNick={areaNick}
									workerTags={tagsGroupedByAreas[areaNick]}
									managerTags={managerTagsGroupedByAreas[areaNick]}
									utilities={utilityByAreas[areaNick]}
								/>
							);
						})
					)}
				</>
			)}
		</div>
	);
};
