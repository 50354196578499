import React from 'react';
import classes from './styles.module.scss';
import { IssueStatus } from '../../../interfaces/IIssueShared';
import classnames from 'classnames';
import { IconColor, IconSize, TrusstorIconShared } from '../../TrusstorIconShared/TrusstorIconShared';
import { IconNames } from '../../TrusstorIconShared/IconNames.enum';
import { getIssueStatusMainText } from '../../../utils/issues.utils';
import { useSharedServices } from '../../../hooks/sharedServices.context';

interface IIssueStatusChipPops {
	status: IssueStatus;
}

export const IssueStatusChip = (props: IIssueStatusChipPops) => {
	const { translationService } = useSharedServices();

	const classesRender = classnames(classes.IssueStatusChip_container, {
		[classes.completed]: props.status === IssueStatus.COMPLETED,
		[classes.forReview]: props.status === IssueStatus.FOR_REVIEW,
		[classes.rejected]: props.status === IssueStatus.REJECTED,
	});

	const iconName: IconNames = (() => {
		switch (props.status) {
			case IssueStatus.COMPLETED:
				return IconNames.checkCircle;
			case IssueStatus.FOR_REVIEW:
				return IconNames.workplan;
			case IssueStatus.REJECTED:
				return IconNames.activityRejected;
			default:
				return IconNames.checkCircle;
		}
	})();

	const iconColor: IconColor = (() => {
		switch (props.status) {
			case IssueStatus.COMPLETED:
				return IconColor.FeedbackPositiveDark;
			case IssueStatus.FOR_REVIEW:
				return IconColor.FeedbackHighDark;
			case IssueStatus.REJECTED:
				return IconColor.FeedbackCriticalDark;
			default:
				return IconColor.FeedbackPositiveDark;
		}
	})();

	if (props.status === IssueStatus.PLANNED) return null;
	return (
		<div className={classesRender} data-testid="IssueStatusChip">
			<TrusstorIconShared iconName={iconName} color={iconColor} size={IconSize.SMALL} />
			{getIssueStatusMainText(props.status, translationService)}
		</div>
	);
};
