import React, { useEffect, useMemo } from 'react';
import classes from './styles.module.scss';
import { useQuery } from '@tanstack/react-query';
import { IconNames } from './IconNames.enum';
import { RequestService } from '../../services/request.service';
import { useSharedServices } from '../../hooks/sharedServices.context';
import classnames from 'classnames';

export const useIconQuery = (
	requestService: RequestService,
	iconName: IconNames
): { svgString: string; isInitialLoading: boolean } => {
	const { data: svgString, isInitialLoading } = useQuery<string>(
		['svgString', iconName],
		() => requestService.get(`/storage/images/icon?assetPath=${iconName}.svg`),
		{ useErrorBoundary: false, cacheTime: 1000 * 60 * 60 * 24, staleTime: 1000 * 60 * 60 * 24 }
	);
	return { svgString: svgString || '', isInitialLoading };
};

export enum IconSize {
	EXTRA_SMALL = 'extraSmall',
	SMALL = 'small',
	MEDIUM = 'medium',
	LARGE = 'large',
	EXTRA_LARGE = 'extraLarge',
}

export enum IconColor {
	Green500 = 'green500',
	Grey800 = 'grey800',
	Grey700 = 'grey700',
	Grey600 = 'grey600',
	Grey400 = 'grey400',
	Grey500 = 'grey500',
	Grey300 = 'grey300',
	Grey200 = 'grey200',
	Grey100 = 'grey100',
	Blue = 'blue',
	Blue200 = 'blue200',
	Blue500 = 'blue500',
	White = 'white',
	Red900 = 'red900',
	Red500 = 'red500',
	FeedbackPositiveDark = 'feedbackPositiveDark',
	FeedbackCriticalDark = 'feedbackCriticalDark',
	FeedbackCriticalDefault = 'feedbackCriticalDefault',
	FeedbackHighDark = 'feedbackHighDark',
	Yellow500 = 'yellow500',
	DarkGreen = 'darkGreen',
	DarkBlue = 'darkBlue',
	Black = 'black',
}

export interface ITrusstorIconProps {
	iconName: IconNames;
	size?: IconSize;
	color?: IconColor;
	defaultColor?: boolean;
	onHoverStyleClass?: string;
	className?: string;
	onClick?: (e?: any) => void;
	onEmptySvg?: () => void;
	onLoad?: () => void;
	filledIcon?: boolean;
	disableColorStyling?: boolean;
}

const TrusstorIconShared = React.memo((props: ITrusstorIconProps) => {
	const { requestService } = useSharedServices();
	const [isHovered, setIsHovered] = React.useState(false);
	const size: IconSize = props.size || IconSize.MEDIUM;
	const color: IconColor = props.color || IconColor.Grey800;
	const { svgString, isInitialLoading } = useIconQuery(requestService, props.iconName);

	useEffect(() => {
		if (!isInitialLoading && props.onEmptySvg && svgString === '') {
			props.onEmptySvg();
		}
		if (!isInitialLoading && props.onLoad && svgString !== '') {
			props.onLoad();
		}
	}, [isInitialLoading, svgString]);

	const svgStringAtobFormat = useMemo(() => {
		try {
			return atob(svgString);
		} catch (e) {
			return '';
		}
	}, [svgString]);

	return (
		<div
			onClick={props.onClick && props.onClick}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
			className={classnames(classes.svgDiv, classes[size], props.className, {
				[classes[color]]: !props.disableColorStyling && !props.defaultColor,
				[classes.filledIcon]: props.filledIcon,
				[props.onHoverStyleClass || '']: isHovered,
			})}
			dangerouslySetInnerHTML={{ __html: svgStringAtobFormat }}
		/>
	);
});

export { TrusstorIconShared };
