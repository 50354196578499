import { cM } from '@shared/utils/colors.utils';
import classes from './styles.module.scss';
import { locationTransparentIcon } from '../../../../../assets/locationIcons';
import React, { useState } from 'react';
import { useProjectImageQuery } from '../../../../../hooks/queries/images.queries.hooks';
import { IProject } from '@shared/interfaces/IProject';
import { translationService } from '../../../../../index';
import { setWorkingProject } from '../../../../../store/thunks';
import { useDispatch } from 'react-redux';
import { isMobileRedirectionEnabled } from '@src/config';

interface IProjectInfoTopSectionProps {
	project: IProject;
}

export const ProjectInfoTopSection = (props: IProjectInfoTopSectionProps) => {
	const canRedirect: boolean = props.project.isEnabled && !isMobileRedirectionEnabled;
	const [isHovered, setIsHovered] = useState<boolean>(false);
	const { projectImage } = useProjectImageQuery(props.project.projectId);
	const dispatch = useDispatch();

	const handleMouseEnter = () => {
		if (canRedirect) {
			setIsHovered(true);
		}
	};

	const handleMouseLeave = () => {
		if (canRedirect) {
			setIsHovered(false);
		}
	};

	const handleClick = () => {
		if (canRedirect) {
			dispatch(setWorkingProject(props.project.projectId));
			window.open(`/${props.project.projectId}/main`);
		}
	};

	return (
		<div
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			onClick={handleClick}
			className={cM(classes.topContainer, classes)}
			style={{
				cursor: canRedirect ? 'pointer' : 'default',
				backgroundSize: isHovered ? '110% !important' : '100%',
				backgroundImage: `linear-gradient(180deg, rgba(39, 53, 71, 0) ${
					isHovered ? '0%' : '50%'
				}, #273547 91.04%), url(${projectImage})`,
			}}
		>
			<div></div>
			<div className={cM(classes.redirectSection, classes)}>
				{canRedirect && isHovered && (
					<div className={cM(classes.redirectLink, classes)}>{translationService.get('openProject')}</div>
				)}
			</div>
			<div className={cM(classes.infoSection, classes)}>
				<div className={cM(classes.projectName, classes)}>{props.project.name}</div>
				<div className={cM(classes.projectLocation, classes)}>
					<img
						className={cM(classes.locationIcon, classes)}
						alt="locationIcon"
						src={locationTransparentIcon}
					/>
					<div className={cM(classes.locationName, classes)}>{props.project.city}</div>
				</div>
			</div>
		</div>
	);
};
