import React from 'react';
import classes from './styles.module.scss';
import { ICommentShared } from '../../../interfaces/ICommentShared';
import { TranslationService } from '../../../services/translation.service';
import { IconColor, IconSize, TrusstorIconShared } from '../../TrusstorIconShared/TrusstorIconShared';
import { IconNames } from '../../TrusstorIconShared/IconNames.enum';
import { formatBytes } from '../../../utils/roundNumbers.utils';
import { StorageService } from '../../../services/storage.service';
import { isTrusstorMobile } from '../../../utils/mobile.utils';
import { openFileDesktop, openFileMobile } from '../../../utils/files.utils';

interface ICommentTextDisplayProps {
	issueId: string;
	commentId: string;
	comment: ICommentShared;
	translationService: TranslationService;
	dateCreated: string;
	storageService: StorageService;
}

export const CommentPdfDisplay = (props: ICommentTextDisplayProps) => {
	const pdfName: string = props.comment.content.filename || '';
	const formattedPdfSize: string = formatBytes(props.comment.content.sizeInBytes || 0);
	const isMobile: boolean = isTrusstorMobile();

	const loadPdf = async (): Promise<string> =>
		props.storageService.getIssueCommentPDF(props.issueId, props.commentId);

	const handlePdfClick = async () => {
		const pdfString: string = await loadPdf();

		if (isMobile) {
			openFileMobile(pdfString);
		} else {
			openFileDesktop(pdfString);
		}
	};

	return (
		<div className={classes.greyBlock} key={props.comment.commentId}>
			<div className={classes.sender}>{props.comment.sender.name}</div>
			<div className={classes.pdfViewContainer} onClick={handlePdfClick}>
				<div className={classes.pdfIcon}>
					<TrusstorIconShared
						iconName={IconNames.fileText}
						size={IconSize.MEDIUM}
						color={IconColor.Blue200}
					/>
				</div>
				<div className={classes.pdfDetails}>
					<div className={classes.pdfName}>{pdfName}</div>
					{props.comment.content.sizeInBytes && (
						<div className={classes.pdfSize}>{`${formattedPdfSize} | PDF`}</div>
					)}
				</div>
			</div>
			<div className={classes.footer}>
				<div className={classes.hour}>{props.dateCreated}</div>
			</div>
		</div>
	);
};
