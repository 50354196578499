import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { MIN_LARGE_SCREEN_PIXELS } from '../constants';

export function useLargeScreen() {
	// get the viewport height and width
	const [isLargeScreen, setIsLargeScreen] = useState(false);
	const [windowWidth] = useWindowSize();
	useEffect(() => {
		function updateSize() {
			setIsLargeScreen(windowWidth > MIN_LARGE_SCREEN_PIXELS);
		}
		window.addEventListener('resize', updateSize);
		updateSize();
		return () => window.removeEventListener('resize', updateSize);
	}, [windowWidth]);
	return isLargeScreen;
}

export function useWindowSize() {
	// get the viewport height and width
	const [size, setSize] = useState([0, 0]);
	useEffect(() => {
		function updateSize() {
			setSize([window.innerWidth, window.innerHeight]);
		}
		window.addEventListener('resize', updateSize);
		updateSize();
		return () => window.removeEventListener('resize', updateSize);
	}, []);
	return size;
}

export function useWindowWidth() {
	const [width, setWidth] = useState(window.innerWidth);

	useEffect(() => {
		const onResize = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', onResize);

		return () => {
			window.removeEventListener('resize', onResize);
		};
	}, []);

	return width;
}

export function useKeyPress(targetKey, callback) {
	const [keyPressed, setKeyPressed] = useState(false);

	function handler({ key }) {
		if (key === targetKey) {
			setKeyPressed(true);
			callback();
		}
	}

	useEffect(() => {
		window.addEventListener('keydown', handler);
		return () => {
			window.removeEventListener('keydown', handler);
		};
	});

	return keyPressed;
}

export const useOutsideClick = (ref, setComponentState, componentStateOnClickOutside, ignoreOutsideClicksRefs?) => {
	useOutsideClickWithCallback(
		ref,
		() => setComponentState(componentStateOnClickOutside),
		[setComponentState, componentStateOnClickOutside, ignoreOutsideClicksRefs] as any,
		ignoreOutsideClicksRefs
	);
};

export const useOutsideClickWithCallback = (ref, callback, deps = [], ignoreOutsideClicksRefs?) => {
	useEffect(() => {
		function handleClickOutside(event) {
			if (
				ref.current &&
				!ref.current.contains(event.target) &&
				!ignoreOutsideClicksRefs?.some((ignoreRef) => ignoreRef.current.contains(event.target))
			) {
				callback(event);
			}
		}

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [callback, ref.current, ...deps]);
};

export function usePrevious(value) {
	const ref = useRef();
	useEffect(() => {
		ref.current = value;
	}, [value]);
	return ref.current;
}

export const useHeightOverflow = (ref) => {
	const [isOverflow, setIsOverflow] = useState(false);

	useLayoutEffect(() => {
		const { current } = ref;
		const checkForOverflow = () => setIsOverflow(current.scrollHeight > current.clientHeight);

		if (current) {
			if ('ResizeObserver' in window) {
				new ResizeObserver(checkForOverflow).observe(current);
			}
			checkForOverflow();
		}
	}, [ref]);

	return isOverflow;
};

export function useWidthOverflow(elementRef) {
	const [elementOverflow, setElementOverflow] = useState(false);

	useEffect(() => {
		if (!elementRef.current) {
			return;
		}
		const scrollWidth = elementRef.current.scrollWidth;
		const clientWidth = elementRef.current.clientWidth;
		setElementOverflow(clientWidth < scrollWidth);
	}, [elementRef.current?.scrollWidth, elementRef.current?.clientWidth, setElementOverflow]);

	return elementOverflow;
}

export function useIsNarrowLayout() {
	const [isNarrowLayout, setIsNarrowLayout] = useState(false);

	useEffect(() => {
		if (window.innerWidth < 1600 && !isNarrowLayout) setIsNarrowLayout(true);
		if (window.innerWidth > 1600 && isNarrowLayout) setIsNarrowLayout(false);
	}, [window.innerWidth]);
	return isNarrowLayout;
}
