import { INotificationsReducersState } from '@interfaces/INotificationsReducersState';
import { createSlice } from '@reduxjs/toolkit';
import { INotification } from '@shared/interfaces/INotification';
import { IRootState } from '@store/slices/index';
import { orderBy, uniqBy } from 'lodash';

const initialState: INotificationsReducersState = {
	notifications: undefined,
	snackbarNotifications: [],
	isTabOpen: false,
	isInitialLoad: true,
};

const notificationsSlice = createSlice({
	name: 'notifications',
	initialState: initialState,
	reducers: {
		updateNotifications: (
			state: INotificationsReducersState,
			action: { payload: { notifications: INotification[] } }
		) => {
			const currentNotifications: INotification[] = state.notifications || [];
			const combinedNotifications: INotification[] = [...action.payload.notifications, ...currentNotifications];
			const uniqueCombinedNotifications: INotification[] = uniqBy(combinedNotifications, '_id');
			const visibledNotifications: INotification[] = uniqueCombinedNotifications.filter(
				(notification) => notification.isVisible
			);
			const newNotifications: INotification[] = visibledNotifications.filter(
				(notification) => currentNotifications.findIndex((n) => n._id === notification._id) === -1
			);

			const sortedVisibleNotifications: INotification[] = orderBy(visibledNotifications, 'createdAt', ['desc']);
			if (state.isInitialLoad) {
				state.isInitialLoad = false;
			} else if (newNotifications.length) {
				const uniqCombinedSnackbarNotifications = uniqBy(
					[...newNotifications, ...state.snackbarNotifications],
					'_id'
				);
				state.snackbarNotifications = uniqCombinedSnackbarNotifications;
			}
			state.notifications = sortedVisibleNotifications;
		},
		markNotificationAsViewed: (state: INotificationsReducersState) => {
			if (!state.notifications) {
				return;
			}
			state.notifications = state.notifications.map((notification) => {
				return {
					...notification,
					isViewed: true,
				};
			});
		},
		changeOpenNotificationsTab: (state: INotificationsReducersState) => {
			state.isTabOpen = !state.isTabOpen;
		},
		resetNotifications: (state: INotificationsReducersState) => {
			state.isInitialLoad = true;
			state.notifications = undefined;
			state.snackbarNotifications = [];
		},
		removeSnackbar: (state: INotificationsReducersState, action: { payload: { notificationId: string } }) => {
			const doesContainId: boolean = !!(
				state.notifications &&
				state.notifications.findIndex(
					(notification: INotification) => notification._id === action.payload.notificationId
				) !== -1
			);
			if (doesContainId) {
				state.snackbarNotifications = state.snackbarNotifications.filter(
					(notification: INotification) => notification._id !== action.payload.notificationId
				);
			}
		},
	},
});

export const selectNotificationsIsOpen = (state: IRootState): boolean => state.notificationsReducer.isTabOpen;
export const selectNotifications = (state: IRootState): INotification[] | undefined =>
	state.notificationsReducer.notifications;
export const selectSnackbarNotifications = (state: IRootState): INotification[] =>
	state.notificationsReducer.snackbarNotifications;

export const {
	markNotificationAsViewed,
	changeOpenNotificationsTab,
	updateNotifications,
	resetNotifications,
	removeSnackbar,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
