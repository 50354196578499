import React from 'react';
import { translationService } from '@src/servicesInitializers';
import PlanningEmptyStateIcon from '../../../../assets/planningMenu/planning-empty-state.svg';
import classes from './styles.module.scss';
const PlanningMenuEmptyState = () => {
	return (
		<div className={classes.planningEmptyStateContainer}>
			<div>{translationService.get('planningEmptyStateText')}</div>
			<img src={PlanningEmptyStateIcon} alt="" />
		</div>
	);
};

export { PlanningMenuEmptyState };
