import classes from '@src/Components/NotificationSettingCard/styles.module.scss';
import { accessLevelService, translationService } from '@src/servicesInitializers';
import AutoCompleteDropdown from '@shared/components/Dropdowns/AutoCompleteDropdown/AutoCompleteDropdown';
import { AutocompleteTextInputOutlined } from '@shared/components/Inputs/AutocompleteTextInputOutlined/AutocompleteTextInputOutlined';
import { NotificationChannelSwitch } from '@src/Components/NotificationSettingCard/NotificationChannelSwitch';
import React from 'react';
import { INotificationCard } from '@shared/interfaces/NotificationSettings/INotificationCard';
import { IBaseNotificationSettings, IUserNotificationSettings } from '@shared/interfaces/INotificationSettings';
import { NotificationChannelTypes } from '@shared/interfaces/NotificationChannelTypes.enum';
import { useUsersQuery } from '@src/hooks/queries/users.queries.hooks';
import { preDefinedFrequenciesMapping } from '@shared/constants/notifications.constants';
import { useSelector } from 'react-redux';
import { selectProjectId } from '@store/slices/project.slice';
import { selectLoggedUser } from '@store/slices/login.slice';
import { INotificationTypes, NotificationTypes } from '@shared/interfaces/NotificationTypes.enum';
import { TranslationService } from '@shared/services/translation.service';
import { SingleDropdown } from '@src/Components/Dropdowns/DesignSystem/SingleDropdown/SingleDropdown';
import { IUser } from '@shared/interfaces/IUser';

interface INotificationCardProps {
	card: INotificationCard;
	titleElement?: React.ReactElement;
	isBase?: boolean;
	userSettings?: IUserNotificationSettings;
	baseSettings?: IBaseNotificationSettings;
	handleSettingsChange: (
		value: string | boolean | null,
		notificationType: INotificationTypes,
		channelName?: NotificationChannelTypes,
		frequency?: boolean
	) => Promise<void>;

	handleBaseSettingsChange: (
		notificationType: INotificationTypes,
		recipient?: { username: string; add: boolean },
		value?: string | boolean | null,
		channelName?: NotificationChannelTypes,
		frequency?: boolean
	) => Promise<void>;
}

export const NotificationCard = (props: INotificationCardProps) => {
	const projectId: string = useSelector(selectProjectId)!;
	const loggedUser = useSelector(selectLoggedUser)!;
	const { users } = useUsersQuery(projectId);

	const getRenderRecipientsElement = (options: string[], shouldShow: boolean): React.ReactElement => {
		return shouldShow && options.length > 0 ? (
			<span className={classes.recipientsLength}>{`${options.length} ${translationService.get(
				'recipients'
			)}`}</span>
		) : (
			<></>
		);
	};

	const handleSwitchChange = async (checked: boolean, channelName: NotificationChannelTypes) => {
		props.isBase
			? await props.handleBaseSettingsChange(props.card.type, undefined, checked, channelName)
			: await props.handleSettingsChange(checked, props.card.type, channelName);
	};

	const getFrequencyValue = (translationService: TranslationService, frequency?: string): string => {
		for (const key in preDefinedFrequenciesMapping) {
			if (preDefinedFrequenciesMapping[key] === frequency) {
				return translationService.get(key);
			}
			if (key === frequency) {
				return translationService.get(frequency);
			}
		}
		return frequency || '';
	};

	const handleRecipientsChange = async (users: string[], notificationType: INotificationTypes) => {
		const usersDiff1: string[] =
			users.filter((item) => !props.baseSettings?.settings[props.card.type]?.recipients.includes(item)) || [];
		const usersDiff2: string[] =
			props.baseSettings?.settings[props.card.type]?.recipients!.filter((item) => !users.includes(item)) || [];
		const currentUser: string = usersDiff1.concat(usersDiff2)[0];
		const isAdded: boolean = !props.baseSettings?.settings[props.card.type]?.recipients?.includes(currentUser);
		props.handleBaseSettingsChange(notificationType, { username: currentUser, add: isAdded });
	};

	const getRecipientsOptions = (users: IUser[]): string[] => {
		return users
			.filter((user) =>
				props.card.type === NotificationTypes.DistressButton ||
				props.card.type.includes(NotificationTypes.RestrictedArea)
					? accessLevelService.roleTypeHasAccess(user.permissions.roleType, 'notifications-safety')
					: true
			)
			.map((user) => user.username);
	};

	const handleFrequencyOnChange = (value: string | null) => {
		const prevValue: string = getFrequencyValue(
			translationService,
			props.isBase
				? props.baseSettings && props.baseSettings?.settings[props.card.type]?.frequency
				: props.userSettings && props.userSettings?.settings[props.card.type]?.frequency
		);

		if (prevValue === value) {
			return;
		}

		props.isBase
			? props.handleBaseSettingsChange(props.card.type, undefined, value, undefined, true)
			: props.handleSettingsChange(value, props.card.type, undefined, true);
	};

	return (
		<div className={classes.singleCardSection}>
			<div className={classes.textSection}>
				<div>{props.titleElement || translationService.get(props.card.type)}</div>
				{props.card.description && (
					<div className={classes.description}>{translationService.get(props.card.description)}</div>
				)}
			</div>
			<div className={classes.actionsSection}>
				{props.isBase && (
					<div className={classes.dropdown}>
						<AutoCompleteDropdown
							options={getRecipientsOptions(users)}
							onChange={(value) => handleRecipientsChange(value, props.card.type)}
							direction={translationService.getDirection()}
							getOptionLabel={(option: string) => option}
							isMultipleChoice
							displayCheckbox
							getTextInputElement={(params) => (
								<AutocompleteTextInputOutlined
									params={params}
									label={translationService.get('recipients')}
									value={
										(props.baseSettings &&
											props.baseSettings?.settings[props.card.type]?.recipients) ||
										[]
									}
								/>
							)}
							value={
								(props.baseSettings && props.baseSettings?.settings[props.card.type]?.recipients) || []
							}
							getRenderTagsElement={getRenderRecipientsElement}
							endAdornmentClassname={classes.endAdornment}
							hideClearTextButton
						/>
					</div>
				)}
				{props.card.frequency && (
					<div className={classes.dropdown}>
						<SingleDropdown
							hideClearTextButton
							options={props.card.frequency || []}
							onChange={handleFrequencyOnChange}
							label={translationService.get('frequency')}
							getDisplayOption={(option: string) => getFrequencyValue(translationService, option)}
							value={getFrequencyValue(
								translationService,
								props.isBase
									? props.baseSettings && props.baseSettings?.settings[props.card.type]?.frequency
									: props.userSettings && props.userSettings?.settings[props.card.type]?.frequency
							)}
						/>
					</div>
				)}
				<div className={classes.actions}>
					{props.card.channels?.map((channelName) => (
						<NotificationChannelSwitch
							key={`notificationChannelSwitch_${channelName}`}
							channelName={channelName}
							card={props.card}
							userSettings={props.userSettings}
							baseSettings={props.baseSettings}
							isBase={props.isBase}
							handleSwitchChange={handleSwitchChange}
						/>
					))}
				</div>
			</div>
		</div>
	);
};
