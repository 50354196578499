import React from 'react';
import classes from './styles.module.scss';
import { translationService } from '../../../index';
import { NotificationSettingCard } from '@src/Components/NotificationSettingCard/NotificationSettingCard';
import { getDesktopNotificationsCards } from '@utils/notifications.utils';

const PreferencesPage = () => {
	return (
		<section className={classes.preferencesContainer}>
			<div className={classes.title}>{translationService.get('preferences')}</div>
			<div className={classes.content}>
				{getDesktopNotificationsCards().map((card) => (
					<NotificationSettingCard card={card} />
				))}
			</div>
		</section>
	);
};

export { PreferencesPage };
