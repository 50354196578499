import React, { ReactElement } from 'react';
import classes from './styles.module.scss';
import { ITableComponentProps } from '@interfaces/ITableComponentProps';
import { ISequenceItem } from '@interfaces/ISequenceItem';
import { IMilestone } from '@shared/interfaces/IMilestone';

interface MilestoneLinkedCellProps {
	linkedMilestone?: IMilestone;
}

export const MilestoneLinkedCell = (
	props: ITableComponentProps<MilestoneLinkedCellProps>
): ReactElement<ITableComponentProps<ISequenceItem>> => {
	const milestoneTitle: string | undefined = props.data.linkedMilestone?.title;
	return (
		<>
			{props.data.linkedMilestone && (
				<div className={classes.MilestoneLinkedCell_container} data-testid="MilestoneLinkedCell">
					{milestoneTitle}
				</div>
			)}
		</>
	);
};
