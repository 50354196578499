import {
	IEntityWithTradeGroup,
	IEntityWithTradeId,
	getTradeGroupTranslationShared,
	getTradeTranslationShared,
} from '@shared/utils/translations.utils';
import { translationService } from '../index';

export const getTradeTranslation = (entityWithTradeTranslation: IEntityWithTradeId): string => {
	try {
		return getTradeTranslationShared(entityWithTradeTranslation, translationService);
	} catch (err) {
		return '';
	}
};

export const getTradeGroupTranslation = (entityWithTradeTranslation: IEntityWithTradeGroup): string => {
	try {
		return getTradeGroupTranslationShared(entityWithTradeTranslation, translationService);
	} catch (err) {
		return '';
	}
};

export const getSpaceCharacterIfNeeded = (): string => {
	if (translationService.getIsRtl()) {
		return '';
	}
	return ' ';
};
