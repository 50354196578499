export enum IconNames {
	structure = 'structure',
	apartment = 'apartment',
	upcomingCircleFilled = 'upcoming-circle-filled',
	unlink = 'unlink',
	milestone = 'milestone',
	activityRejected = 'Activity+rejected',
	live = 'live',
	elevator = 'elevator',
	workplan = 'Workplan',
	tag = 'tag',
	drag = 'Drag',
	chart2 = 'chart+2',
	airplayToTv = 'airplay-to-tv',
	annotationDots = 'annotation-dots',
	annotationWarning = 'annotation-warning',
	announcement = 'announcement',
	archive = 'archive',
	activity = 'activity',
	report = 'Report',
	arrowDownLeft = 'arrow-down-left',
	arrowDownRight = 'arrow-down-right',
	arrowDown = 'arrow-down',
	arrowLeft = 'arrow-left',
	arrowRight = 'arrow-right',
	arrowUpLeft = 'arrow-up-left',
	arrowUpRight = 'arrow-up-right',
	arrowUp = 'arrow-up',
	atSign = 'at-sign',
	badge = 'badge',
	bank = 'bank',
	batteryCharging = 'battery-charging',
	batteryFull = 'battery-full',
	batteryLow = 'battery-low',
	batteryMedium = 'battery-medium',
	battery = 'battery',
	bellOff = 'bell-off',
	bell = 'bell',
	book = 'book',
	bookmark = 'bookmark',
	box = 'box',
	briefcase = 'briefcase',
	buildingStore = 'building-store',
	building = 'building',
	calendarMinus = 'calendar-minus',
	calendarPlus = 'calendar-plus',
	calendar = 'calendar',
	cameraOff = 'camera-off',
	camera = 'camera',
	chart = 'chart',
	checkCircle = 'check-circle',
	greenCheckCircle = 'green-check-circle',
	check = 'check',
	chevronDown = 'chevron-down',
	chevronLeft = 'chevron-left',
	chevronRight = 'chevron-right',
	chevronUp = 'chevron-up',
	clipboard = 'clipboard',
	clock = 'clock',
	closeCircle = 'close-circle',
	close = 'close',
	cloud = 'cloud',
	code = 'code',
	columnsHorizontal = 'columns-horizontal',
	columnsVertical = 'columns-vertical',
	columns = 'columns',
	copy = 'copy',
	crop = 'crop',
	cursor = 'cursor',
	dashboard = 'dashboard',
	dollar = 'dollar',
	dotsHorizontal = 'dots-horizontal',
	dotsVertical = 'dots-vertical',
	download = 'download',
	edit = 'edit',
	emojiHappy = 'emoji-happy',
	emojiSad = 'emoji-sad',
	eyeOff = 'eye-off',
	eye = 'eye',
	fastForward = 'fast-forward',
	fileMinus = 'file-minus',
	filePlus = 'file-plus',
	fileText = 'file-text',
	file = 'file',
	film = 'film',
	filter = 'filter',
	flag = 'flag',
	folderMinus = 'folder-minus',
	folderPlus = 'folder-plus',
	folder = 'folder',
	globe = 'globe',
	gridMasonry = 'grid-masonry',
	grid = 'grid',
	hashtag = 'hashtag',
	headphones = 'headphones',
	heart = 'heart',
	helpCircleFull = 'help-circle full',
	helpCircle = 'help-circle',
	help = 'help',
	helmet = 'helmet',
	home = 'home',
	inbox = 'inbox',
	issues = 'issues',
	information = 'information',
	key = 'key',
	link = 'link',
	lockUnlocked = 'lock-unlocked',
	lock = 'lock',
	logIn = 'log-in',
	logOut = 'log-out',
	mail = 'mail',
	map = 'map',
	maximize = 'maximize',
	menu = 'menu',
	message = 'message',
	microphoneMute = 'microphone-mute',
	microphone = 'microphone',
	minimize = 'minimize',
	minusCircle = 'minus-circle',
	minus = 'minus',
	monitor1 = 'monitor-1',
	monitor = 'monitor',
	moon = 'moon',
	music = 'music',
	paintBucket = 'paintbucket',
	pause = 'pause',
	percentage = 'percentage',
	phoneCallCross = 'phone-call-cross',
	phoneCallForward = 'phone-call-forward',
	phoneCallHangUp = 'phone-call-hang-up',
	phoneCallIncoming = 'phone-call-incoming',
	phoneCallOutgoing = 'phone-call-outgoing',
	phoneCall = 'phone-call',
	phone = 'phone',
	photo = 'photo',
	pictureInPicture = 'picture-in-picture',
	pieChart = 'pie-chart',
	pill = 'pill',
	pinTack = 'pin-tack',
	pin = 'pin',
	play = 'play',
	plug = 'plug',
	plusCircle = 'plus-circle',
	plus = 'plus',
	print = 'print',
	projector = 'projector',
	redo = 'redo',
	refresh = 'refresh',
	rewind = 'rewind',
	route = 'Route',
	rows = 'rows',
	search = 'search',
	send = 'send',
	server = 'server',
	settingsSliders = 'settings-sliders',
	settings = 'settings',
	share = 'share',
	shield = 'shield',
	shieldCross = 'shield-cross',
	shieldWarning = 'shield-warning',
	shoppingBag = 'shopping-bag',
	shoppingBasket = 'shopping-basket',
	shoppingCart = 'shopping-cart',
	skipBack = 'skip-back',
	skipForward = 'skip-forward',
	smartphone = 'smartphone',
	speaker = 'speaker',
	star = 'star',
	emptyStar = 'empty-star',
	hoverStar = 'hover-star',
	fullStar = 'full-star',
	sticker = 'sticker',
	stop = 'stop',
	streamToTv = 'stream-to-tv',
	sun = 'sun',
	tableColumns = 'table-columns',
	tableRows = 'table-rows',
	tablet = 'tablet',
	target = 'target',
	ticket = 'ticket',
	trash = 'trash',
	trendingDown = 'trending-down',
	trendingUp = 'trending-up',
	truck = 'truck',
	undo = 'undo',
	upload = 'upload',
	userCheck = 'user-check',
	userCross = 'user-cross',
	userMinus = 'user-minus',
	userPlus = 'user-plus',
	user = 'user',
	userV2 = 'User+v2',
	usersMinus = 'users-minus',
	usersPlus = 'users-plus',
	users = 'users',
	videoCameraOff = 'video-camera-off',
	videoCamera = 'video-camera',
	video = 'video',
	virus = 'virus',
	wallet = 'wallet',
	warningTriangle = 'warning-triangle',
	wifiNoConnection = 'wifi-no-connection',
	wifi = 'wifi',
	zoomIn = 'zoom-in',
	zoomOut = 'zoom-out',
	progress = 'progress',
	floors = 'floors',
	manager = 'manager',
	worker = 'worker',
	checkCircleFull = 'check-circle-full',
	issuesFilled = 'issues-filled',
	numberFilled = 'number-filled',
	pinFilled = 'pin-filled',
	flagFilled = 'flag-filled',
	userFilled = 'user-filled',
	pinMap = 'pin-map',
	tradeAluminum = 'trade-aluminum',
	tradeIronWork = 'trade-ironWork',
	tradeCarpentry = 'trade-carpentry',
	tradeCladding = 'trade-cladding',
	tradePlaster = 'trade-plaster',
	tradeGrouting = 'trade-grouting',
	tradeDaily = 'trade-daily',
	tradeDirtWork = 'trade-dirt-work',
	tradeDoors = 'trade-doors',
	tradeElectrical = 'trade-electrical',
	tradeElevator = 'trade-elevator',
	tradeEpoxy = 'trade-epoxy',
	tradeFireProtection = 'trade-fireProtection',
	tradeFlooring = 'trade-flooring',
	tradeGeneral = 'trade-general',
	tradeGlazier = 'trade-glazier',
	tradeHVAC = 'trade-HVAC',
	tradeLandscaping = 'trade-landscaping',
	tradeMasonry = 'trade-masonry',
	tradePaint = 'trade-paint',
	tradePlumbing = 'trade-plumbing',
	tradePrefab = 'trade-prefab',
	tradeStructure = 'trade-structure',
	tradeSystems = 'trade-systems',
	tradeTractor = 'trade-tractor',
	tradeTransport = 'trade-transport',
	tradeCommunications = 'trade-communications',
	tradeDemolition = 'trade-demolition',
	tradeExcavation = 'trade-excavation',
	tradeFoundations = 'trade-foundations',
	tradeHardscape = 'trade-hardscape',
	tradeInfrastructure = 'trade-infrastructure',
	tradeInsulation = 'trade-insulation',
	tradeDryWall = 'trade-dryWall',
	tradeGeneralWorks = 'trade-generalWorks',
	tradeHoistOperator = 'trade-hoistOperator',
	expand = 'expand',
	scissorsLift = 'scissors-lift',
	bonding = 'bonding',
	optics = 'optics',
	movingLifting = 'moving-lifting',
	welding = 'welding',
	drainage = 'drainage',
	heavy = 'heavy',
	height = 'height',
	drill = 'drill',
	shieldTick = 'shield-tick',
	hardscape = 'hardscape',
	electrical = 'electrical',
	hammer = 'hammer',
	wrench = 'wrench',
}
