import { WorkplanViewTypes } from '@src/constants';

export enum WorkplanPeriodFixedOptionTypes {
	nextWeek = 'nextWeek',
	nextTwoWeeks = 'nextTwoWeeks',
}

interface IWorkPlanLocalStorageData {
	planning: {
		lastPlanningPeriodOption: {
			[projectId: string]: WorkplanPeriodFixedOptionTypes;
		};
	};
	shouldShowSequenceInitialTooltip: boolean;
	workplanViewType: WorkplanViewTypes | null;
	showStarred: boolean;
}

export class WorkPlanLocalStorageService {
	static workplanPath: string = 'workplan';

	public setWorkplanViewType(workplanViewType: WorkplanViewTypes | null): void {
		const oldWorkplanData: IWorkPlanLocalStorageData | null = this.getWorkplanData();

		const updatedWorkplanData: { workplanViewType: WorkplanViewTypes | null } = {
			...oldWorkplanData,
			workplanViewType,
		};
		localStorage.setItem(WorkPlanLocalStorageService.workplanPath, JSON.stringify(updatedWorkplanData));
	}

	public getWorkplanViewType(): WorkplanViewTypes | null {
		const workplanData: IWorkPlanLocalStorageData | null = this.getWorkplanData();

		return workplanData?.workplanViewType || null;
	}

	public setStarredViewType(showStarred: boolean): void {
		const oldWorkplanData: IWorkPlanLocalStorageData | null = this.getWorkplanData();

		const updatedWorkplanData: { showStarred: boolean } = {
			...oldWorkplanData,
			showStarred,
		};
		localStorage.setItem(WorkPlanLocalStorageService.workplanPath, JSON.stringify(updatedWorkplanData));
	}

	public getStarredViewType(): boolean {
		const workplanData: IWorkPlanLocalStorageData | null = this.getWorkplanData();

		return workplanData?.showStarred || false;
	}

	public setLastPlaningPeriodOption(projectId: string, option: WorkplanPeriodFixedOptionTypes): void {
		const oldWorkplanData: IWorkPlanLocalStorageData | null = this.getWorkplanData();

		const updatedWorkplanData: IWorkPlanLocalStorageData = {
			...oldWorkplanData,
			planning: {
				...oldWorkplanData?.planning,
				lastPlanningPeriodOption: {
					...oldWorkplanData?.planning?.lastPlanningPeriodOption,
					[projectId]: option,
				},
			},
		} as IWorkPlanLocalStorageData;
		localStorage.setItem(WorkPlanLocalStorageService.workplanPath, JSON.stringify(updatedWorkplanData));
	}

	public getLastPlaningPeriodOption(projectId: string): WorkplanPeriodFixedOptionTypes | null {
		const workplanData: IWorkPlanLocalStorageData | null = this.getWorkplanData();

		if (!workplanData?.planning) {
			return null;
		}

		return workplanData.planning.lastPlanningPeriodOption[projectId];
	}

	public getShouldShowSequenceInitialTooltip(): boolean | undefined {
		const workplanData: IWorkPlanLocalStorageData | null = this.getWorkplanData();

		return workplanData?.shouldShowSequenceInitialTooltip;
	}

	public setShouldShowSequenceInitialTooltip(shouldShowSequenceInitialTooltip: boolean): void {
		const oldWorkplanData: IWorkPlanLocalStorageData | null = this.getWorkplanData();

		const updatedWorkplanData: IWorkPlanLocalStorageData = {
			...oldWorkplanData,
			shouldShowSequenceInitialTooltip,
		} as IWorkPlanLocalStorageData;
		localStorage.setItem(WorkPlanLocalStorageService.workplanPath, JSON.stringify(updatedWorkplanData));
	}

	public getWorkplanData(): IWorkPlanLocalStorageData | null {
		const workplanDataString: string | null = localStorage.getItem(WorkPlanLocalStorageService.workplanPath);

		if (!workplanDataString) {
			return null;
		}

		return JSON.parse(workplanDataString, (key, value) => {
			if (key === 'startDate' || key === 'endDate') {
				return new Date(value);
			}
			return value;
		});
	}
}
