import React, { useEffect, useState } from 'react';
import { updateUserPassword } from '../../../../apis/user.api';
import { validateSetPasswordToken } from '../../../../apis/auth.api';
import lock from '../../../../assets/login/lock.svg';
import { AuthPage } from '../AuthPage';
import { InvalidSetPasswordTokenPage } from './InvalidSetPasswordTokenPage';
import { SetPasswordConfirmationPage } from './SetPasswordConfirmationPage';
import { TrusstorTextInput } from '@shared/components/Inputs/TrusstorTextInput/TrusstorTextInput';
import classes from '../styles.module.scss';

interface ISetPasswordPageProps {
	location: {
		search: string;
	};
}

export const SetPasswordPage = (props: ISetPasswordPageProps) => {
	const [password, setPassword] = useState<string>('');
	const [passwordConfirmation, setPasswordConfirmation] = useState<string>('');
	const [generalError, setGeneralError] = useState<string>('');
	const [showPasswordSetSuccessfully, setShowPasswordSetSuccessfully] = useState<boolean>(false);
	const [invalidTokenError, setInvalidTokenError] = useState<string | null>(null);

	const params: URLSearchParams = new URLSearchParams(props.location.search);
	const token: string | null = params.get('token');

	const validateUserToken = async () => {
		try {
			await validateSetPasswordToken(token!);
		} catch (e: any) {
			setInvalidTokenError('The link has expired');
		}
	};

	useEffect(() => {
		if (!token || token.length === 0) {
			return setInvalidTokenError('Invalid set password link');
		}
		validateUserToken();
	}, []);

	const onSetNewPasswordClick = async () => {
		if (password.length === 0 || passwordConfirmation.length === 0 || password !== passwordConfirmation) {
			setGeneralError('Passwords do not match');
			return;
		}
		try {
			await updateUserPassword(token!, password);
			setShowPasswordSetSuccessfully(true);
		} catch (e: any) {
			setGeneralError('Password update failed');
		}
	};

	const onPasswordConfirmationInputChange = (username: string) => {
		setPasswordConfirmation(username);
		setGeneralError('');
	};

	const onPasswordInputChange = (password: string) => {
		setPassword(password);
		setGeneralError('');
	};

	return invalidTokenError ? (
		<InvalidSetPasswordTokenPage title={invalidTokenError} />
	) : !showPasswordSetSuccessfully ? (
		<AuthPage
			buttonText={'Set Password'}
			onButtonClick={() => onSetNewPasswordClick()}
			title={'Set Password'}
			errorText={generalError}
			submitOnEnter={true}
		>
			<div className={classes.setPasswordInput}>
				<TrusstorTextInput
					placeholder={'Password'}
					changeFunc={onPasswordInputChange}
					type="password"
					startIcon={lock}
					testId="password-input"
				/>
			</div>
			<div>
				<TrusstorTextInput
					placeholder={'Password Confirmation'}
					changeFunc={onPasswordConfirmationInputChange}
					type="password"
					startIcon={lock}
					testId="password-confirm-input"
				/>
			</div>
		</AuthPage>
	) : (
		<SetPasswordConfirmationPage />
	);
};
