import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IInvestigationReport } from '@shared/interfaces/IInvestigationReport';
import { Loader } from '@shared/components/Loader/Loader';
import classes from './styles.module.scss';
import { AnalysisCenterReportHeader } from '../AnalysisCenterReportHeader/AnalysisCenterReportHeader';
import { InvestigationReportTagInfoCard } from '../InvestigationReportTagInfoCard/InvestigationReportTagInfoCard';
import {
	useArchiveMergedTagQuery,
	useInvestigationReportQuery,
	useInvestigationTagRoutesQuery,
} from '@src/hooks/queries/analysisCenter.queries.hooks';
import { ReportTagRoute } from '../ReportTagRoute/ReportTagRoute';
import { InvestigationReportTypes } from '@shared/interfaces/InvestigationReportTypes.enum';
import { selectProjectId, selectTimezone } from '@store/slices/project.slice';
import { InvestigationReportEmptyPage } from '../InvestigationReportEmptyPage/InvestigationReportEmptyPage';
import { ReportTagsLocationInfo } from '../ReportTagsLocationInfo/ReportTagLocationInfo';
import { FloorSvg } from '../FloorSvg/FloorSvg';
import { uniq } from 'lodash';
import { getTagName } from '@utils/tags.utils';
import { getLocationIndexInvestigated } from '@utils/investigationReports.utils';
import { PdfTagRoutesSection } from './PdfTagRoutesSection';
import { updateInvestigationReport } from '@utils/analysisCenter.utils';
import { useRenderMonitoring } from '@shared/hooks/monitoring.hooks';

export const InvestigationReportPage = (props: { match }) => {
	useRenderMonitoring('InvestigationReportPage');
	const tz: string = useSelector(selectTimezone)!;
	const projectId: string = useSelector(selectProjectId)!;
	const reportId: string = props.match.params.reportId;
	const {
		investigationReport: currentReport,
		isInvestigationReportLoading,
		refetchInvestigationReport,
	} = useInvestigationReportQuery<IInvestigationReport>(reportId);
	const { mergedTag, isInitialLoading: isTagLoading } = useArchiveMergedTagQuery(currentReport);
	const { tagRoutes, isTagRouteLoading } = useInvestigationTagRoutesQuery(currentReport, tz, projectId);
	const [tagLocationIndexSelected, setTagLocationIndexSelected] = useState<number | null>(null);
	const [pdfMode, setPdfMode] = useState<boolean>(false);
	const [isTagWasAssignedToDifferentPerson, setIsTagWasAssignedToDifferentPerson] = useState<boolean>(false);

	const dispatch = useDispatch();

	const updateReport = async (reportId: string, attributesToUpdate: Partial<IInvestigationReport>) => {
		await updateInvestigationReport(reportId, attributesToUpdate, dispatch, refetchInvestigationReport);
	};

	const onSelectTagLocation = (indexSelected: number) => {
		setTagLocationIndexSelected(indexSelected);
	};

	const locationIndexInvestigated: number | null =
		tagRoutes && getLocationIndexInvestigated(tz, currentReport.investigatedTimestamp, tagRoutes);

	const loadingState = isInvestigationReportLoading || isTagLoading || isTagRouteLoading;
	const isEmptyState: boolean =
		!loadingState && (!currentReport || !mergedTag || !tagRoutes || tagRoutes.length === 0);
	const isTagRoutesAndIndexesExist: boolean = !!(
		tagRoutes &&
		tagLocationIndexSelected !== null &&
		locationIndexInvestigated != null
	);

	useEffect(() => {
		if (currentReport && mergedTag) {
			setIsTagWasAssignedToDifferentPerson(getTagName(mergedTag) !== currentReport.tagName);
		}
	}, [currentReport?.tagName, mergedTag]);

	if (loadingState) return <Loader />;
	return (
		<section className={classes.reportPageContainer}>
			<AnalysisCenterReportHeader
				updateReport={updateReport}
				reportId={reportId}
				title={currentReport?.title}
				displayDate={new Date(currentReport?.investigatedTimestamp)}
				description={currentReport?.description}
				disableDownload={isEmptyState}
			/>
			<section className={classes.bodyContainer}>
				{isEmptyState ? (
					<InvestigationReportEmptyPage />
				) : (
					<div className={classes.tagInfoContainer} id={`investigationReportPage_${reportId}`}>
						<InvestigationReportTagInfoCard
							isDailyReport={currentReport!.investigationType === InvestigationReportTypes.dailyRoute}
							tag={mergedTag}
							isTagLoading={isTagLoading}
							investigatedTimestamp={currentReport!.investigatedTimestamp}
							displayDate={new Date(currentReport?.investigatedTimestamp)}
							isTagWasAssignedToDifferentPerson={isTagWasAssignedToDifferentPerson}
						/>
						<div className={classes.bodyContainer}>
							<ReportTagRoute
								tagRoute={tagRoutes}
								investigatedTimestamp={currentReport?.investigatedTimestamp}
								isIncidentReport={
									currentReport?.investigationType === InvestigationReportTypes.incidentReport
								}
								onClick={onSelectTagLocation}
								tagName={getTagName(mergedTag)}
							/>
							<div className={classes.locationInfoContainer}>
								{isTagRoutesAndIndexesExist && (
									<div className={classes.locationInfoAndSvgSection}>
										<div className={classes.tagsLocationInfoSection}>
											<ReportTagsLocationInfo
												locationNumber={tagLocationIndexSelected! + 1}
												tagLocationData={tagRoutes![tagLocationIndexSelected!]}
												locationNumberOfTheIncident={locationIndexInvestigated! + 1}
												isIncidentReport={
													currentReport?.investigationType ===
													InvestigationReportTypes.incidentReport
												}
												pdfMode={pdfMode}
											/>
										</div>
										<FloorSvg
											floorId={tagRoutes![tagLocationIndexSelected!].floorId}
											selectedAreaId={tagRoutes![tagLocationIndexSelected!].areaId}
											areaIds={uniq(
												tagRoutes![tagLocationIndexSelected!].floors
													.map((floor) => floor.areas.map((area) => area.areaId))
													.flat()
											)}
										/>
									</div>
								)}
								{isTagRoutesAndIndexesExist && (
									<PdfTagRoutesSection
										tagRoutes={tagRoutes!}
										tagLocationIndexSelected={tagLocationIndexSelected!}
										pdfMode={pdfMode}
										locationIndexInvestigated={locationIndexInvestigated!}
										currentReport={currentReport}
									/>
								)}
							</div>
						</div>
					</div>
				)}
			</section>
		</section>
	);
};
