export enum Environments {
	prod = 'prod',
	staging = 'staging',
	integration = 'integration',
	demo = 'demo',
	demoLocal = 'demo-local',
	demoIntegration = 'demo-integration',
	dev = 'dev',
	development = 'development',
	qa = 'qa',
	qaLocal = 'qaLocal',
	compose = 'compose',
	ondemand = 'ondemand',
	test = 'test',
}
