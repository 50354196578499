import React, { useContext, useEffect } from 'react';
import { IIssue } from '@shared/interfaces/IIssue';
import { IssuePriority, IssueStatus } from '@shared/interfaces/IIssueShared';
import { useSelector } from 'react-redux';
import { selectLoggedUserDetails } from '@store/slices/login.slice';
import { IUser } from '@shared/interfaces/IUser';
import { translationService } from '@src/servicesInitializers';
import classes from './styles.module.scss';
import { MainPageIssuesList } from '@src/Components/MainPageIssuesTab/MainPageIssuesList/MainPageIssuesList';
import { MainPageContext } from '@src/Components/Pages/MainPage';
import { QuickFilterButton } from '@shared/components/buttons/QuickFilterButton/QuickFilterButton';
import Lottie from 'react-lottie';
import IssuesLottieLoader from '@src/Components/DashboardSections/DashboardLoaders/CardsLoader.json';
import {
	DashboardTabEmptyState,
	EmptyStateResourcesEnum,
} from '@shared/components/DashboardTabEmptyState/DashboardTabEmptyState';
import classnames from 'classnames';

export enum IssuesTabQuickFilterTypes {
	all = 'all',
	forReview = 'forReview',
	critical = 'critical',
	assignedToMe = 'assignedToMe',
}

interface quickFilterButton {
	type: IssuesTabQuickFilterTypes;
	text: string;
	count?: number;
	onClick: () => void;
}

interface IMainPageIssuesTabProps {
	issues: IIssue[];
	emptyStateText: string;
	quickFiltersClassName?: string;
}

export const MainPageIssuesTab = (props: IMainPageIssuesTabProps) => {
	const userDetails: IUser = useSelector(selectLoggedUserDetails)!;
	const [forReviewIssues, setForReviewIssues] = React.useState<IIssue[]>([]);
	const [starredIssues, setStarredIssues] = React.useState<IIssue[]>([]);
	const [assignedToMeIssues, setAssignedToMeIssues] = React.useState<IIssue[]>([]);
	const { setIssuesTabSelectedFilter, issuesTabSelectedFilter, areIssuesFetched } = useContext(MainPageContext)!;

	useEffect(() => {
		const forReviewIssues: IIssue[] = props.issues.filter((issue) => issue.status === IssueStatus.FOR_REVIEW);
		const starredIssues: IIssue[] = props.issues.filter((issue) => issue.priority == IssuePriority.CRITICAL);
		const assignedToMeIssues: IIssue[] = props.issues.filter(
			(issue) => issue.assignee?.username === userDetails.username
		);

		setForReviewIssues(forReviewIssues);
		setStarredIssues(starredIssues);
		setAssignedToMeIssues(assignedToMeIssues);
	}, [props.issues]);

	const getFilteredIssues = (filter: IssuesTabQuickFilterTypes): IIssue[] => {
		switch (filter) {
			case IssuesTabQuickFilterTypes.all:
				return props.issues;
			case IssuesTabQuickFilterTypes.forReview:
				return forReviewIssues;
			case IssuesTabQuickFilterTypes.critical:
				return starredIssues;
			case IssuesTabQuickFilterTypes.assignedToMe:
				return assignedToMeIssues;
		}
	};

	const quickFilterButtons: quickFilterButton[] = [
		{
			type: IssuesTabQuickFilterTypes.all,
			text: translationService.get('mainPage_issuesTab_quickFilter_all'),
			onClick: () => setIssuesTabSelectedFilter(IssuesTabQuickFilterTypes.all),
		},
		{
			type: IssuesTabQuickFilterTypes.forReview,
			text: translationService.get('mainPage_issuesTab_quickFilter_forReview'),
			count: forReviewIssues.length,
			onClick: () => setIssuesTabSelectedFilter(IssuesTabQuickFilterTypes.forReview),
		},
		{
			type: IssuesTabQuickFilterTypes.critical,
			text: translationService.get('mainPage_issuesTab_quickFilter_critical'),
			count: starredIssues.length,
			onClick: () => setIssuesTabSelectedFilter(IssuesTabQuickFilterTypes.critical),
		},
		{
			type: IssuesTabQuickFilterTypes.assignedToMe,
			text: translationService.get('mainPage_issuesTab_quickFilter_assignedToMe'),
			count: assignedToMeIssues.length,
			onClick: () => setIssuesTabSelectedFilter(IssuesTabQuickFilterTypes.assignedToMe),
		},
	];

	if (!areIssuesFetched) {
		return (
			<div className={classes.loaderContainer}>
				<Lottie
					options={{
						loop: true,
						autoplay: true,
						animationData: IssuesLottieLoader,
					}}
				/>
			</div>
		);
	}

	if (props.issues.length === 0) {
		return <DashboardTabEmptyState resource={EmptyStateResourcesEnum.issues} createLink={`/issues`} />;
	}

	return (
		<div className={classes.issuesTabContainer}>
			<div className={classnames(classes.quickFiltersContainer, props.quickFiltersClassName)}>
				{quickFilterButtons.map((button) => {
					const text: string = button.count !== undefined ? `${button.text} (${button.count})` : button.text;
					const isSelected: boolean = button.type === issuesTabSelectedFilter;
					return (
						<QuickFilterButton key={text} text={text} handleClick={button.onClick} selected={isSelected} />
					);
				})}
			</div>
			<MainPageIssuesList issues={getFilteredIssues(issuesTabSelectedFilter)} />
		</div>
	);
};
