import { useQuery } from '@tanstack/react-query';
import { requestService } from '@src/servicesInitializers';
import { ISequenceItemProgressPeriodOverallReport } from '@shared/interfaces/ISequenceItemProgressPeriodOverallReport';
import { ISequenceItemByLocationProgressReport } from '@shared/interfaces/ISequenceItemByLocationProgressReport';
import { ISequenceItemProgressBySequenceItemReport } from '@interfaces/ISequenceItemProgressBySequenceItemReport';

export const useOverallProgressSummaryQuery = (
	projectId: string,
	startDate: string,
	endDate: string
): { isLoading: boolean; overallProgressSummary: ISequenceItemProgressPeriodOverallReport } => {
	const { data: overallProgressSummary, isLoading } = useQuery(
		['overallProgressSummary', projectId, startDate, endDate],
		() =>
			requestService.get(`/reports/sequenceItemPeriodProgressOverallReport`, {
				params: { projectId, startDate, endDate },
			}),
		{
			useErrorBoundary: true,
		}
	);

	return { overallProgressSummary, isLoading };
};

export const useSequenceItemProgressByLocationSummaryQuery = (
	projectId: string,
	startDate: string,
	endDate: string
): { isLoading: boolean; sequenceItemProgressByLocationReport: ISequenceItemByLocationProgressReport[] } => {
	const { data: sequenceItemProgressByLocationReport, isLoading } = useQuery(
		['sequenceItemProgressByLocationReport', projectId, startDate, endDate],
		() =>
			requestService.get(`/reports/sequenceItemProgressByLocationReport`, {
				params: { projectId, startDate, endDate },
			}),
		{
			useErrorBoundary: true,
		}
	);

	return { sequenceItemProgressByLocationReport, isLoading };
};

export const useSequenceItemProgressBySequenceItemQuery = (
	projectId: string,
	endDate: string
): { isLoading: boolean; sequenceItemProgressBySequenceItemReports: ISequenceItemProgressBySequenceItemReport[] } => {
	const { data: sequenceItemProgressBySequenceItemReports, isLoading } = useQuery(
		['sequenceItemProgressBySeqItemReport', projectId, endDate],
		() =>
			requestService.get(`/reports/sequenceItemProgressBySequenceItems`, {
				params: { projectId, endDate },
			}),
		{
			useErrorBoundary: true,
			initialData: [],
		}
	);

	return { sequenceItemProgressBySequenceItemReports, isLoading };
};
