import { parsePhoneNumber, CountryCode } from 'libphonenumber-js/mobile';

export const checkPhoneValidity = (phoneNumber: string, selectedCountryShortName: CountryCode): boolean => {
	try {
		const tel = parsePhoneNumber(phoneNumber, selectedCountryShortName);
		return tel.getType() === 'MOBILE' && tel.isValid();
	} catch (e) {
		return false;
	}
};

export const isValidName = (name) => {
	const pattern = /^[!@#$%&*()_.+=|<>?{}\[\]~\\-]+$/;
	return !pattern.test(name);
};
