import React, { useCallback } from 'react';
import classes from './styles.module.scss';
import { TrusstorBox } from '@src/Components/Boxes/Containers/TrusstorBox';
import { Grid } from '@material-ui/core';
import { TableTitle } from '@src/Components/TableComponents/TableTitle/TableTitle';
import { ITableTitleItems } from '@src/interfaces';
import { ISortingState } from '@interfaces/ISortingState';
import { ISequenceItem } from '@interfaces/ISequenceItem';
import { TableRowTypes } from '@src/constants';
import { MainTableRow } from '@src/Components/TableComponents/TableRow/MainTableRow';
import { IRowProperty } from '@interfaces/IRowProperty';
import { ITableComponentProps } from '@interfaces/ITableComponentProps';
import { ProfessionCell } from './cellComponents/ProfessionCell';
import { SequenceItemNameAndAction } from './cellComponents/SequenceItemNameAndAction/SequenceItemNameAndAction';
import { columnWidthList } from '@src/Components/SequenceItemLinkTable/utils';
import classnames from 'classnames';
import { MilestoneLinkedCell } from '@src/Components/SequenceItemLinkTable/cellComponents/MilestoneLinkedCell/MilestoneLinkedCell';
import { LinkToMilestoneCell } from '@src/Components/SequenceItemLinkTable/cellComponents/LinkToMilestoneCell/LinkToMilestoneCell';
import { useProjectMilestonesQuery } from '@src/hooks/queries/milestones.queries.hooks';
import { IMilestone } from '@shared/interfaces/IMilestone';
import { useSelector } from 'react-redux';
import { selectProjectId } from '@store/slices/project.slice';

interface ISequenceItemLinkTableProps {
	handleSelectSequenceItem: (sequenceItem: ISequenceItem, event: any) => void;
	selectedSequenceItemIds: string[];
	filteredSequenceItems: ISequenceItem[];
	sortingState: ISortingState;
	updateSortingState: (sortingState: Partial<ISortingState>) => void;
	sequenceItemLinkTableTitles: ITableTitleItems;
	handleLinkClick: (sequenceItem: ISequenceItem, isLinkAction: boolean) => void;
	currentMilestoneId: string;
	lastClickedSequenceItemId: string | null;
}

export const SequenceItemLinkTable = (props: ISequenceItemLinkTableProps) => {
	const projectId: string = useSelector(selectProjectId)!;
	const { projectMilestones } = useProjectMilestonesQuery(projectId);

	const getProfessionCellComponent = useCallback((componentProps: ITableComponentProps<ISequenceItem>) => {
		return <ProfessionCell data={componentProps.data} />;
	}, []);

	const rowProperties: IRowProperty[] = [
		{
			component: (componentProps: ITableComponentProps<ISequenceItem>) => (
				<SequenceItemNameAndAction
					data={{
						isSelected: props.selectedSequenceItemIds.includes(componentProps.data._id),
						sequenceItem: componentProps.data,
					}}
				/>
			),
		},
		{
			component: getProfessionCellComponent,
		},
		{
			component: (componentProps: ITableComponentProps<ISequenceItem>) => {
				const linkedMilestone: IMilestone | undefined = projectMilestones.find(
					(milestone) => milestone._id === componentProps.data.linkedMilestoneId
				);
				return (
					<MilestoneLinkedCell
						data={{
							linkedMilestone,
						}}
					/>
				);
			},
		},
		{
			component: (componentProps: ITableComponentProps<ISequenceItem>) => (
				<LinkToMilestoneCell
					data={{
						sequenceItem: componentProps.data,
						handleLinkClick: props.handleLinkClick,
						currentMilestoneId: props.currentMilestoneId,
					}}
				/>
			),
		},
	];

	return (
		<div className={classes.SequenceItemLinkTable_container} data-testid="sequenceItemLinkTableTitles">
			<TrusstorBox customStyleClass={classes.tableWrapper}>
				<Grid container direction="column" className={classes.tableContainer}>
					<Grid container className={classes.listContainer} alignItems="center">
						<div className={classes.headerContainer}>
							<div className={classes.header}>
								{Object.entries(props.sequenceItemLinkTableTitles).map(
									([title, value], index: number) => {
										return (
											<TableTitle
												key={title}
												titleComponent={value.titleComponent}
												columnWidth={columnWidthList[index]}
												sortingState={props.sortingState}
												disableSort={value.disableSort}
												currentColumn={value.title}
												isTextCentered={value.isTextCentered}
												tooltipText={value.tooltipText}
												updateSortingState={props.updateSortingState}
											/>
										);
									}
								)}
							</div>
						</div>

						<div className={classes.rowsContainer}>
							{props.filteredSequenceItems.map((sequenceItem) => {
								return (
									<MainTableRow
										onClick={(event: any) => props.handleSelectSequenceItem(sequenceItem, event)}
										rowContainerClass={classnames(classes.row, {
											[classes.isCurrentSelected]:
												props.lastClickedSequenceItemId === sequenceItem._id,
											[classes.selectedRow]: props.selectedSequenceItemIds.includes(
												sequenceItem._id
											),
										})}
										object={sequenceItem}
										type={TableRowTypes.MILESTONES}
										columnWidthList={columnWidthList}
										rowProperties={rowProperties}
										key={sequenceItem._id}
									/>
								);
							})}
						</div>
					</Grid>
				</Grid>
			</TrusstorBox>
		</div>
	);
};
