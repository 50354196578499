import { ActivityGroupStatus } from '../interfaces/ActivityGroupStatus.enum';
import { COLORS } from './colors.constants';
import { Languages } from './languages';

export const intercomBaseURL: string = 'https://intercom.help/trusstor-d19f78c950cd/en/articles/';

export const intercomBaseHebURL: string = 'https://intercom.help/trusstor-d19f78c950cd/he/articles/';

export const intercomLinks: { userPermissionsLinks: { [key: string]: string } } = {
	userPermissionsLinks: {
		[Languages.HEBREW]: `${intercomBaseHebURL}6876190`,
		[Languages.ENGLISH_US]: `${intercomBaseURL}6876190-user-s-permission`,
	},
};

export enum ActivityStatusTypes {
	AS_PLANNED = 'asPlanned',
	PARTIAL = 'partial',
	DEFICIENT = 'deficient',
	NOT_BEGAN = 'notBegan',
}

export enum SORT_ORDER {
	ASCENDING = 'asc',
	DESCENDING = 'desc',
}

export enum IconPatchStatuses {
	DEFAULT = 'default',
	HOVER = 'hover',
	SELECTED = 'selected',
}

export const equipmentTypes = {
	safety: 'Safety',
	optic: 'Optic',
	electricity: 'Electricity',
	powerTools: 'Power Tools',
	bondingMachinery: 'Bonding Machinery',
	waterDrainage: 'Water & Drainage',
	pavingTiling: 'Paving & Tiling',
	welding: 'Welding',
	movingLifting: 'Moving & lifting',
	height: 'Height',
	heavyEquipment: 'Heavy Equipment',
	scissorsLift: 'Scissors Lift',
};

export enum HEADERS {
	authorization = 'authorization',
	tz = 'tz',
	projectId = 'project-id',
	organizationId = 'organization-id',
	correlationId = 'correlation-id',
	username = 'username',
	workDayHours = 'workDayHours',
	webClientVersion = 'web-client-version',
	isDemo = 'is-demo',
}

export enum ActivityStatusTypesColor {
	asPlanned = '#79a41c',
	partial = '#f9bc14',
	deficient = '#d34e0a',
}

export const activitiesStatusesColors = {
	[ActivityGroupStatus.overdue]: COLORS.red600,
	[ActivityGroupStatus.delayed]: COLORS.red600,
	[ActivityGroupStatus.inProgress]: COLORS.yellow500,
	[ActivityGroupStatus.planned]: COLORS.primaryColor,
	[ActivityGroupStatus.complete]: COLORS.green600,
};
