import React, { createContext, useEffect } from 'react';
import { HeaderSection } from '@src/Components/SequenceProgressAnalysis/HeaderSection/HeaderSection';
import classes from './styles.module.scss';
import { FloorProgress } from '@src/Components/SequenceProgressAnalysis/FloorProgress/FloorProgress';
import { OverallProgress } from '@src/Components/SequenceProgressAnalysis/OverallProgress/OverallProgress';
import { ActivitiesProgress } from '@src/Components/SequenceProgressAnalysis/ActivitiesProgress/ActivitiesProgress';
import { progressAnalysisAccess_BI } from '@utils/bi.utils';
import moment from 'moment/moment';
import { useSelector } from 'react-redux';
import { selectWorkingProject } from '@store/slices/project.slice';
import { IProject } from '@interfaces/index';
import { getWeekStartAndEndDates } from '@shared/utils/dateUtils';
import { useRenderMonitoring } from '@shared/hooks/monitoring.hooks';
import { useIsProgressAnalysisV2 } from '@src/hooks/useIsProgressAnalysisV2.hooks';
import { MilestonesProgress } from './MilestonesProgress/MilestonesProgress';

export interface ISequenceProgressAnalysisContext {
	selectedMilestoneId: string | null;
	setSelectedMilestoneId: (milestoneId: string) => void;
}

export const SequenceProgressAnalysisContext = createContext<ISequenceProgressAnalysisContext | undefined>(undefined);

export const SequenceProgressAnalysis = () => {
	useRenderMonitoring('SequenceProgressAnalysis');
	useEffect(() => {
		progressAnalysisAccess_BI();
	}, []);

	const project: IProject = useSelector(selectWorkingProject)!;
	const lastWeek: Date = moment.tz(project.tz).subtract(1, 'weeks').toDate();
	const { startDate, endDate } = getWeekStartAndEndDates(project, lastWeek);

	const isProgressAnalysisV2: boolean = useIsProgressAnalysisV2();
	const [selectedMilestoneId, setSelectedMilestoneId] = React.useState<string | null>(null);

	return (
		<SequenceProgressAnalysisContext.Provider
			value={{
				selectedMilestoneId,
				setSelectedMilestoneId,
			}}
		>
			<div className={classes.SequenceProgressAnalysis_container}>
				<HeaderSection startDate={startDate} endDate={endDate} />
				<div className={classes.content}>
					<div className={classes.leftSection}>
						<OverallProgress startDate={startDate} endDate={endDate} />
						<FloorProgress startDate={startDate} endDate={endDate} />
					</div>
					<div className={classes.divider}></div>
					<div className={classes.rightSection}>
						{isProgressAnalysisV2 && <MilestonesProgress summaryDate={endDate} />}
						<ActivitiesProgress endDate={endDate} />
					</div>
				</div>
			</div>
		</SequenceProgressAnalysisContext.Provider>
	);
};
