import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { uniq } from 'lodash';
import { IRootState } from '@store/slices/index';
import { IUtilitiesReducerState } from '@interfaces/IUtilitiesReducerState';
import { IUtilityMergedTag } from '@interfaces/IUtilityMergedTag';

const initialState: IUtilitiesReducerState = {
	activeUtilities: [],
	selectedUtilities: [],
};

const utilitiesSlice = createSlice({
	name: 'utilities',
	initialState,
	reducers: {
		addActiveUtilities: (
			state: IUtilitiesReducerState,
			action: PayloadAction<{ utilities: IUtilityMergedTag[] }>
		) => {
			state.activeUtilities = action.payload.utilities;
		},
		addSelectedUtilities: (
			state: IUtilitiesReducerState,
			action: PayloadAction<{ selectedUtilities: IUtilityMergedTag[] }>
		) => {
			const selectedUtilitiesAfterAdd: IUtilityMergedTag[] = uniq(
				state.selectedUtilities.concat(...action.payload.selectedUtilities)
			);
			state.selectedUtilities = selectedUtilitiesAfterAdd;
		},
		removeSelectedUtilities: (
			state: IUtilitiesReducerState,
			action: PayloadAction<{ removedUtilities: IUtilityMergedTag[] }>
		) => {
			const selectedUtilitiesAfterRemove: IUtilityMergedTag[] = state.selectedUtilities.filter(
				(selectedUtility) => {
					return !action.payload.removedUtilities.some((removed) => removed._id === selectedUtility._id);
				}
			);

			state.selectedUtilities = selectedUtilitiesAfterRemove;
		},
		resetSelectedUtilitiesToDefault: (state: IUtilitiesReducerState) => {
			state.selectedUtilities = [];
		},
		resetSelectedUtilities: (state: IUtilitiesReducerState) => {
			state.selectedUtilities = [];
		},
		resetUtilities: (state: IUtilitiesReducerState) => {
			state.selectedUtilities = [];
			state.activeUtilities = [];
		},
	},
});

export const selectSelectedUtilities = (state: IRootState): IUtilityMergedTag[] =>
	state.utilitiesReducer.selectedUtilities;
export const selectActiveUtilities = (state: IRootState): IUtilityMergedTag[] => state.utilitiesReducer.activeUtilities;

export const {
	addSelectedUtilities,
	removeSelectedUtilities,
	resetSelectedUtilitiesToDefault,
	resetSelectedUtilities,
	resetUtilities,
	addActiveUtilities,
} = utilitiesSlice.actions;

export default utilitiesSlice.reducer;
