import classes from './styles.module.scss';
import React, { useRef } from 'react';
import classnames from 'classnames';
import {
	ITrusstorIconButtonSize,
	TrusstorIconButtonDeprecated,
} from '../../buttons/TrusstorIconButton/TrusstorIconButtonDeprecated';
import { closeIcon } from '../../../assets';
import { useSharedServices } from '../../../hooks/sharedServices.context';

export type ChangeFunctionType<T> =
	T extends React.ChangeEvent<any> ? (value: React.ChangeEvent<any>) => void : (value: string) => void;

export interface TrusstorTextInputProps {
	width?: number;
	value?: string;
	placeholder?: string;
	changeFunc?: ChangeFunctionType<any>;
	label?: string;
	required?: boolean;
	disabled?: boolean;
	startIcon?: React.ReactNode | string;
	endIcon?: React.ReactNode | string;
	errorText?: string;
	isError?: boolean;
	testId?: string;
	type?: string;
	autoFocus?: boolean;
	onBlur?: (e: React.ChangeEvent) => void;
	name?: string;
	sendFullEvent?: boolean;
	id?: string;
	innerComponent?: React.ReactNode;
	showClearButton?: boolean;
	rootClassName?: string;
	textClassName?: string;
	handleInputFocusChange?: (bol: boolean) => void;
	handleClearClickCallback?: () => void;
	endComponent?: React.ReactNode;
	focusOnClear?: boolean;
	shouldNotSelectInputTextOnFocus?: boolean;
	isSmall?: boolean;
}

export const TrusstorTextInput = React.forwardRef(
	(props: TrusstorTextInputProps, ref: React.ForwardedRef<HTMLInputElement>) => {
		const { translationService } = useSharedServices();
		const isStartIconElement: boolean = React.isValidElement(props.startIcon);
		const isEndIconElement: boolean = React.isValidElement(props.endIcon);
		const inputRef = React.useRef<HTMLInputElement | null>(null);
		const tempRef = useRef<HTMLDivElement | null>(null);

		const setRefs = (element: HTMLInputElement) => {
			if (ref) {
				tempRef.current = element;
				if (typeof ref === 'function') {
					ref(element);
				} else if (ref) {
					ref.current = element;
				}
			}

			if (inputRef) {
				inputRef.current = element;
			}
		};

		const getPlaceHolder = (): string | undefined => {
			if (props.placeholder && props.required && !props.label) {
				return props.placeholder + ' *';
			}
			return props.placeholder;
		};

		const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
			if (props.sendFullEvent) {
				props.changeFunc?.(event as any);
				return;
			}
			props.changeFunc?.(event.target.value as any);
		};

		const handleClearClick = (e) => {
			if (props.focusOnClear) {
				inputRef.current?.focus();
			}
			props.handleClearClickCallback?.();
			if (props.sendFullEvent) {
				props.changeFunc?.({ ...e, target: { value: '' } });
				return;
			}
			props.changeFunc?.('' as any);
		};

		const containerClasses = classnames(classes.container, props.rootClassName, {
			[classes.hasText]: !!props.value?.length,
			[classes.hasLabel]: props.label,
			[classes.hasPlaceholder]: props.placeholder,
			[classes.hasStartIcon]: props.startIcon,
			[classes.isDisabled]: props.disabled,
			[classes.isSmall]: props.isSmall,
		});

		return (
			<div
				className={classes.root}
				onFocus={() => props.handleInputFocusChange?.(true)}
				onBlur={() => props.handleInputFocusChange?.(false)}
			>
				<div className={containerClasses} data-testid={props.testId ?? null}>
					{props.startIcon && (
						<div className={classnames(classes.icon, classes.startIcon)}>
							{isStartIconElement ? (
								props.startIcon
							) : (
								<img src={props.startIcon as string} alt={'start icon'} />
							)}
						</div>
					)}

					{props.label && (
						<div className={classes.label}>
							<div>{props.label}</div>
							{props.required && <span className={classes.required}>{'*'}</span>}
						</div>
					)}

					{props.innerComponent}

					<input
						ref={setRefs}
						id={props.id}
						onFocus={(e) => (!props.shouldNotSelectInputTextOnFocus ? e.target.select() : null)}
						name={props.name}
						onBlur={props.onBlur}
						disabled={props.disabled}
						autoFocus={props.autoFocus}
						type={props.type ?? 'text'}
						placeholder={getPlaceHolder()}
						onChange={handleChange}
						value={props.value}
						style={props.width ? { width: props.width } : undefined}
						className={classnames(classes.input, props.textClassName ? props.textClassName : '', {
							[classes.isRtl]: translationService.getIsRtl(),
						})}
					/>

					{props.showClearButton && !!props.value?.length && (
						<TrusstorIconButtonDeprecated
							iconSrc={closeIcon}
							onClick={handleClearClick}
							buttonSize={ITrusstorIconButtonSize.SMALL}
						/>
					)}

					{props.endIcon && (
						<div className={classes.icon}>
							{isEndIconElement ? props.endIcon : <img src={props.endIcon as string} alt={'end icon'} />}
						</div>
					)}
				</div>
				<div className={classes.errorContainer} data-testid={'input-error'}>
					{props.isError && <div className={classes.errorText}>{props.errorText || ''}</div>}
				</div>
			</div>
		);
	}
);
