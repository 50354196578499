import { IProfession } from '@interfaces/index';
import { IClientInputActivityShared } from '@shared/interfaces/IClientInputActivity';
import { requestService } from '..';
import { IMergedAreaSequenceItem } from '@interfaces/IMergedAreaSequenceItem';

export const createBulkActivities = async (
	activities: IClientInputActivityShared<IProfession>[],
	areaSequenceItems: IMergedAreaSequenceItem[],
	startDateIso: string,
	endDateIso: string
): Promise<IClientInputActivityShared<IProfession>[]> => {
	return await requestService.post(`/activities/bulk`, {
		body: {
			activities,
			areaSequenceItems,
			startDate: startDateIso,
			endDate: endDateIso,
		},
	});
};
