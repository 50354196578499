import React, { KeyboardEvent, useEffect, useRef, useState } from 'react';
import { isEqual } from 'lodash';
import { scrollIntoView } from '../../../../../utils/dropdown.utils';
import { useOutsideClickWithCallback } from '../../../../../hooks/custom.hooks';
import { SmallDropdownDisplay } from '../../Displays/SmallDropdownDisplay/SmallDropdownDisplay';
import { Placement } from '@popperjs/core';
import { IDropdownSharedProps } from '../../dropdown.interface';

export interface SmallMultipleDropdownProps<T>
	extends Omit<
		IDropdownSharedProps<T>,
		'required' | 'errorText' | 'hideClearTextButton' | 'isError' | 'placeholder'
	> {
	options: T[];
	onChange: (values: T[]) => void;
	value?: T[];
	label: string;
	dropdownWidth?: string;
	popperPlacement?: Placement;
}

function SmallMultipleDropdownShared<T>({
	options,
	onChange,
	getDisplayOption,
	label,
	getSecondDisplayOption,
	dropdownWidth,
	value,
	testId,
	disabled,
	popperPlacement,
}: SmallMultipleDropdownProps<T>) {
	const [selectedOptions, setSelectedOptions] = useState<T[]>(value || []);
	const dropdownRef = useRef<HTMLDivElement>(null);
	const containerRef = useRef<HTMLDivElement>(null);
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [highlightedIndex, setHighlightedIndex] = useState<number>(-1);

	useEffect(() => {
		setSelectedOptions(value || []);
	}, [value]);

	useEffect(() => {
		onChange(selectedOptions);
	}, [selectedOptions]);

	const handleOptionClick = (option: T) => {
		const updatedOptions = selectedOptions.includes(option)
			? selectedOptions.filter((selectedOption) => !isEqual(selectedOption, option))
			: [...selectedOptions, option];
		setSelectedOptions(updatedOptions);
	};

	const handleOptionHover = (index: number) => {
		setHighlightedIndex(index);
	};

	const handleKeyDown = (event: KeyboardEvent) => {
		if (event.key === 'ArrowDown' && highlightedIndex < options.length - 1) {
			setHighlightedIndex((prevIndex) => {
				const updatedIndex = prevIndex + 1;
				scrollIntoView(dropdownRef, updatedIndex);
				return updatedIndex;
			});
		} else if (event.key === 'ArrowUp' && highlightedIndex > 0) {
			setHighlightedIndex((prevIndex) => {
				const updatedIndex = prevIndex - 1;
				scrollIntoView(dropdownRef, updatedIndex);
				return updatedIndex;
			});
		} else if (event.key === 'Enter' && highlightedIndex !== -1) {
			handleOptionClick(options[highlightedIndex]);
		} else if (event.key === 'Backspace' && selectedOptions.length > 0) {
			setSelectedOptions((prev) => {
				const updatedOptions = [...prev];
				updatedOptions.pop();
				return updatedOptions;
			});
		}
	};

	useOutsideClickWithCallback(containerRef, () => {
		setIsDropdownOpen(false);
	});

	return (
		<SmallDropdownDisplay
			showCheckbox
			options={options}
			dropdownRef={dropdownRef}
			containerRef={containerRef}
			isDropdownOpen={isDropdownOpen}
			label={label}
			disabled={disabled}
			setIsDropdownOpen={setIsDropdownOpen}
			handleKeyDown={handleKeyDown}
			handleOptionClick={handleOptionClick}
			handleOptionHover={handleOptionHover}
			getDisplayOption={getDisplayOption}
			getSecondDisplayOption={getSecondDisplayOption}
			testId={testId}
			getIsSelected={(option) => selectedOptions.includes(option)}
			selectedOptionsCount={selectedOptions.length}
			highlightedIndex={highlightedIndex}
			dropdownWidth={dropdownWidth}
			popperPlacement={popperPlacement}
		/>
	);
}

export { SmallMultipleDropdownShared };
