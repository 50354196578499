import { IBaseAreaBaseFloor } from '../../../interfaces/IBaseAreaBaseFloor';
import { IIssueLocation } from '../../../interfaces/IIssueShared';

export const baseLocationToIssueLocation = (baseLocation: IBaseAreaBaseFloor): IIssueLocation => {
	return {
		area: {
			areaId: baseLocation.areaId,
			areaNick: baseLocation.areaNick,
		},
		floor: {
			floorId: baseLocation.floorId,
			floorNick: baseLocation.floorNick,
		},
	};
};
