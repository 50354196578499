import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import moment from 'moment';
import { omit, orderBy } from 'lodash';
import { CalendarDateCell } from '../CalendarDateCell/CalendarDateCell';
import {
	ICalendarSection,
	IFloor,
	IProfession,
	IUser,
	IWeekDayActivity,
	IWorkPlanActivities,
} from '../../../interfaces';
import { useSelector } from 'react-redux';
import { CalendarToggleOptions } from '../../../constants';
import { IRootState } from '../../../store/slices';
import { CalendarRow } from '../CalendarRow/CalendarRow';
import { Loader } from '@shared/components/Loader/Loader';
import { IRoundedWorkPlanActivities } from '../../../interfaces/IRoundedWorkPlanActivities';
import { IActivityLabel } from '../../../interfaces/IActivityLabel';
import classes from './styles.module.scss';
import { selectActivityGroupSelectedStatuses } from '../../../store/slices/ActivityGroupStatuses.slice';
import { IActivityGroupStatusFilterItem } from '../../../interfaces/IActivityGroupStatusFilterItem';
import { CalendarEmptyMode } from '../CalendarEmptyMode/CalendarEmptyMode';
import { getVisibleActivities } from '@utils/workPlan.utils';
import { selectActiveUsers, selectSelectedUsers, selectStaticUsers } from '@store/slices/users.slice';
import { ISequence } from '@shared/interfaces/ISequence';
import { selectSelectedSequences } from '@store/slices/sequences.slice';

interface ICalendarProps {
	activities?: IRoundedWorkPlanActivities;
	sections: ICalendarSection[];
	checkOpenCreateDialog?: (ICalendarSection, Date, boolean) => void;
	handleEditActivityClick?: (isClickable: boolean, activityId: string) => void;
	toggleStateProfessionsFloors: CalendarToggleOptions;
	visibleLabels: IActivityLabel[];
	onEditDialogActionComplete: () => Promise<void>;
}

const Calendar = (props: ICalendarProps) => {
	const tz: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.tz);
	const visibleFloorsList: IFloor[] = useSelector((state: IRootState) => state.floorsListReducer.visibleFloorsList);
	const visibleProfessions: IProfession[] = useSelector(
		(state: IRootState) => state.professionsReducer.visibleProfessions
	);
	const selectedUsers: IUser[] = useSelector(selectSelectedUsers);
	const staticUsers: IUser[] = useSelector(selectStaticUsers);
	const activeUsersLength: number = useSelector(selectActiveUsers).length;
	const visibleDateRangeTimestamps: number[] = useSelector(
		(state: IRootState) => state.datePickerReducer.visibleDateRangeTimestamps
	);
	const selectedSequences: ISequence[] = useSelector(selectSelectedSequences);
	const floorsFilterListLength: number = useSelector(
		(state: IRootState) => state.floorsListReducer.floorsFilterList
	).length;

	const visibleProfessionsLength: number = useSelector(
		(state: IRootState) => state.professionsReducer.visibleProfessions
	).length;
	const selectedActivityGroupStatuses: IActivityGroupStatusFilterItem[] = useSelector(
		selectActivityGroupSelectedStatuses
	);
	const [sortedSections, setSortedSections] = useState<ICalendarSection[]>([]);
	const [isSectionSorted, setIsSectionSorted] = useState<boolean>(false);
	const [visibleActivities, setVisibleActivities] = useState<IWorkPlanActivities | null>(null);

	useEffect(() => {
		if (!props.activities) {
			return;
		}
		const visibleActivities = getVisibleActivities(
			selectedUsers,
			visibleProfessions,
			visibleFloorsList,
			props.visibleLabels,
			selectedActivityGroupStatuses,
			selectedSequences,
			props.activities
		);
		setVisibleActivities(visibleActivities);
	}, [
		selectedUsers.length,
		props.activities,
		isSectionSorted,
		staticUsers.length,
		activeUsersLength,
		visibleProfessionsLength,
		selectedSequences,
		visibleFloorsList.length,
		props.visibleLabels.length,
		selectedActivityGroupStatuses.length,
	]);

	const isToday = (date: moment.MomentInput): boolean => {
		return moment.tz(date, tz).isSame(moment.tz(tz), 'd');
	};

	const isCalendarCellClickable = (day: Date): boolean => {
		return !moment.tz(day, tz).isBefore(moment.tz(tz), 'day');
	};

	const checkOpenCellCreateDialog = (section: ICalendarSection, day: Date): void => {
		if (props.checkOpenCreateDialog) {
			setIsSectionSorted(true);
			props.checkOpenCreateDialog(section, day, isCalendarCellClickable(day));
		}
	};

	const handleOpenCellEditDialog = (day: Date, groupId: string): void => {
		if (props.handleEditActivityClick) {
			props.handleEditActivityClick(isCalendarCellClickable(day), groupId);
		}
	};

	const getIsVisibleSection = (section: ICalendarSection): boolean | null => {
		return (
			visibleActivities &&
			visibleActivities[section.id] &&
			visibleActivities[section.id].some((weekDayActivity: IWeekDayActivity[]) => weekDayActivity.length > 0)
		);
	};

	const doesSectionContainWeekDayActivities = (section: string): boolean => {
		if (!visibleActivities || !visibleActivities[section]) {
			return false;
		}
		return visibleActivities[section].some((weekDayActivities: IWeekDayActivity[]) => weekDayActivities.length > 0);
	};

	const isEmptyWorkPlan = (): boolean => {
		if (!visibleActivities) {
			return false;
		}
		const visibleActivitiesSections: string[] = Object.keys(visibleActivities);
		if (!visibleActivitiesSections.length) {
			return true;
		}
		for (const section of visibleActivitiesSections) {
			if (doesSectionContainWeekDayActivities(section)) {
				return false;
			}
		}
		return true;
	};

	useEffect(() => {
		if (
			!isSectionSorted &&
			props.toggleStateProfessionsFloors === CalendarToggleOptions.PROFESSIONS &&
			props.sections.length > 0 &&
			props.activities
		) {
			const sectionsWithActivitiesCount: Array<ICalendarSection & { activitiesCount: number }> =
				props.sections.map((section: ICalendarSection) => {
					return {
						...section,
						activitiesCount: visibleActivities ? visibleActivities[section.id]?.flat().length ?? 0 : 0,
					};
				});
			const sectionsWithActivities: Array<ICalendarSection & { activitiesCount: number }> =
				sectionsWithActivitiesCount.filter((section) => section.activitiesCount > 0);
			const sectionsWithoutActivities: Array<ICalendarSection & { activitiesCount: number }> =
				sectionsWithActivitiesCount.filter((section) => section.activitiesCount === 0);
			const sortedSectionsWithActivities: Array<ICalendarSection & { activitiesCount: number }> = orderBy(
				sectionsWithActivities,
				'sortIndex',
				'asc'
			);
			const sortedSectionsWithoutActivities: Array<ICalendarSection & { activitiesCount: number }> = orderBy(
				sectionsWithoutActivities,
				'sortIndex',
				'asc'
			);
			const combinedSortedSections: ICalendarSection[] = sortedSectionsWithActivities
				.concat(sortedSectionsWithoutActivities)
				.map((section) => omit(section, 'activitiesCount'));
			setSortedSections(combinedSortedSections);
			setIsSectionSorted(true);
		}
	}, [
		props.sections,
		visibleActivities,
		props.toggleStateProfessionsFloors,
		isSectionSorted,
		activeUsersLength,
		visibleProfessionsLength,
		visibleFloorsList.length,
		props.visibleLabels.length,
	]);

	useEffect(() => {
		setIsSectionSorted(false);
	}, [
		floorsFilterListLength,
		activeUsersLength,
		visibleProfessionsLength,
		props.toggleStateProfessionsFloors,
		props.sections,
		props.visibleLabels.length,
	]);

	useEffect(() => {
		if (
			!isSectionSorted &&
			props.toggleStateProfessionsFloors === CalendarToggleOptions.FLOORS &&
			props.sections.length > 0
		) {
			setSortedSections(props.sections);
			setIsSectionSorted(true);
		}
	}, [props.sections, props.activities, props.toggleStateProfessionsFloors, isSectionSorted]);

	if (!visibleActivities)
		return (
			<div className={classes.fullTableContainer}>
				{' '}
				<Loader big />{' '}
			</div>
		);

	return (
		<div className={classes.tableWrapper}>
			<TableContainer className={classes.tableContainer}>
				<Table className={classes.table}>
					<TableHead>
						<TableRow className={classes.stickyRow}>
							<div className={classes.workPlanEmptyCell}></div>
							{visibleDateRangeTimestamps.map((timestamp: number) => (
								<TableCell className={classes.dateCell} key={`calendar-${timestamp}`}>
									<CalendarDateCell
										dateTimestamp={timestamp}
										isLocked={moment.tz(timestamp, tz).isBefore(moment.tz(tz).subtract(1, 'd'))}
										isMarked={isToday(timestamp)}
									/>
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					{!isEmptyWorkPlan() ? (
						<TableBody>
							{sortedSections.map(
								(section: ICalendarSection) =>
									getIsVisibleSection(section) && (
										<CalendarRow
											key={`${section.id}_calendar_row`}
											checkOpenCreateDialog={checkOpenCellCreateDialog}
											activitiesPerWeek={visibleActivities ? visibleActivities[section.id] : null}
											cellStyleClass={classes.workPlanCell}
											workPlanToggle={props.toggleStateProfessionsFloors}
											section={section}
											checkOpenEditActivityDialog={handleOpenCellEditDialog}
											dateRange={visibleDateRangeTimestamps.map(
												(timestamp) => new Date(timestamp)
											)}
											isToday={isToday}
											isCalendarCellClickable={isCalendarCellClickable}
										/>
									)
							)}
						</TableBody>
					) : (
						<CalendarEmptyMode
							activities={props.activities}
							daysNumber={visibleDateRangeTimestamps.length}
							onEditDialogActionComplete={props.onEditDialogActionComplete}
						/>
					)}
				</Table>
			</TableContainer>
		</div>
	);
};

export { Calendar };
