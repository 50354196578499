import React, { useState } from 'react';
import { UsersTable } from '../../../UsersTableComponents/UsersTable/UsersTable';
import { IUser } from '@shared/interfaces/IUser';
import classes from './styles.module.scss';
import { TrusstorBox } from '../../../Boxes/Containers/TrusstorBox';
import { requestService, translationService } from '../../../../index';
import { Grid, Typography } from '@material-ui/core';
import { TrusstorButton } from '@shared/components/buttons/TrusstorButton/TrusstorButton';
import { selectProjectId } from '../../../../store/slices/project.slice';
import { useDispatch, useSelector } from 'react-redux';
import { getAllProfessions } from '../../../../store/thunks';
import { UserDialog } from '../../../UserDialog/UserDialog';
import { errorSnackbar, successSnackbar } from '../../../../utils/snackbar.utils';
import { userClickAddNewUser_BI } from '../../../../utils/bi.utils';
import { HttpStatusCode } from '../../../../constants';
import { useUsersQuery } from '@src/hooks/queries/users.queries.hooks';
import { useRenderMonitoring } from '@shared/hooks/monitoring.hooks';

interface IUsersListPageProps {}

export const UsersListPage = (props: IUsersListPageProps) => {
	useRenderMonitoring('UsersListPage');
	const [searchInput, setSearchInput] = useState<string>('');
	const projectId: string = useSelector(selectProjectId)!;
	const { users, refetchUsers } = useUsersQuery(projectId);
	const [showUserDialog, setShowUserDialog] = useState<boolean>(false);
	const dispatch = useDispatch();
	dispatch(getAllProfessions());

	const createUser = async (user: Partial<IUser>): Promise<void> => {
		try {
			await requestService.post(`/entities/user/register?shouldSendEmailInvite=true`, { body: user });
			successSnackbar(
				dispatch,
				translationService.get('invitationSent'),
				`${translationService.get('emailWasSent')} ${user.email}`
			);
			refetchUsers();
			setShowUserDialog(false);
		} catch (e: any) {
			let errorMessage: string = '';
			if (e?.response.status === HttpStatusCode.conflict) {
				errorMessage = `${translationService.get('userIsViewerInAnotherProject')}`;
			} else {
				errorMessage = translationService.get('userCreatedFailed');
			}
			errorSnackbar(dispatch, errorMessage);
		}
	};

	const handleAddButtonClick = () => {
		setShowUserDialog(true);
		userClickAddNewUser_BI();
	};

	return (
		<div className={classes.pageContainer}>
			{showUserDialog && (
				<UserDialog
					users={users}
					onClose={() => setShowUserDialog(false)}
					handleSubmitButtonClick={createUser}
				/>
			)}
			<div className={classes.titleContainer}>
				<TrusstorBox customStyleClass={classes.pageTitleBox}>
					<Typography className={classes.title}>{translationService.get('usersPage')}</Typography>
				</TrusstorBox>
				<TrusstorButton
					className={classes.addUserButton}
					text={translationService.get('add')}
					handleClick={handleAddButtonClick}
				/>
			</div>
			<div className={classes.pageContent}>
				<Grid item container direction="column" className={classes.pageTableContainer}>
					<UsersTable
						users={users}
						onSearchInputChange={setSearchInput}
						isSearchInputExist={!!searchInput}
						fetchUsersCallback={refetchUsers}
					/>
				</Grid>
			</div>
		</div>
	);
};
