import { IGroupedDropdownSharedProps, IMultipleDropdownProps } from '../../dropdown.interface';
import { useMultipleSelectionDropdown } from '../multipleSelectionDropdown.utils';
import { useGroupDropdown, useGroupDropdownKeyboardNavigation } from '../../groupDropdown.utils';
import React from 'react';
import { GroupedDropdownDisplay } from '../../Displays/GroupedDropdownDisplay/GroupedDropdownDisplay';
import { DropdownFooter } from '../MultipleDropdownFooter';

export interface IGroupedMultipleDropdownProps<T> extends IGroupedDropdownSharedProps<T>, IMultipleDropdownProps<T> {}

export function GroupedMultipleDropdownShared<T>(props: IGroupedMultipleDropdownProps<T>) {
	const {
		filteredOptions,
		isDropdownOpen,
		dropdownRef,
		highlightedIndex,
		setHighlightedIndex,
		handleInputChange,
		handleOptionClick,
		handleOptionHover,
		setIsDropdownOpen,
		handleInputFocusChange,
		valueForInput,
		optionsContainerRef,
		handleClearClick,
		getIsSelected,
	} = useMultipleSelectionDropdown(props);

	const {
		groupedOptions,
		highlightedGroupIndex,
		setHighlightedGroupIndex,
		topLevelGroups,
		sortedGroupKeys,
		handleInputChangeCallback,
		handleOptionHoverCallback,
	} = useGroupDropdown<T>(props, filteredOptions, handleInputChange, handleOptionHover);

	const handleKeyDown = useGroupDropdownKeyboardNavigation(
		highlightedIndex,
		setHighlightedIndex,
		highlightedGroupIndex,
		setHighlightedGroupIndex,
		optionsContainerRef,
		sortedGroupKeys,
		groupedOptions,
		handleOptionClick,
		getIsSelected,
		true,
		true
	);

	const handleGroupSelect = (groupKey: string, isChecked: boolean) => {
		if (isChecked) {
			const groupOptions = groupedOptions[groupKey];
			const groupOptionsToSelect = groupOptions.filter((option) => !getIsSelected(option));
			groupOptionsToSelect.forEach((option) => handleOptionClick(option));
		} else {
			const groupOptions = groupedOptions[groupKey];
			const groupOptionsToDeselect = groupOptions.filter((option) => getIsSelected(option));
			groupOptionsToDeselect.forEach((option) => handleOptionClick(option));
		}
	};

	const handleSelectAllClick = (isChecked: boolean) => {
		const allOptions: T[] = Object.values(groupedOptions).flat() as T[];
		allOptions.forEach((option) => {
			const isSelected = getIsSelected(option);
			if (isChecked !== isSelected) {
				handleOptionClick(option);
			}
		});
	};

	const handleGroupHover = (groupIndex: number) => {
		setHighlightedGroupIndex(groupIndex);
		setHighlightedIndex(-1);
	};

	return (
		<GroupedDropdownDisplay
			showCheckbox
			shouldNotSelectInputTextOnFocus
			handleClearClickCallback={handleClearClick}
			groupedOptions={groupedOptions}
			sortedGroupKeys={sortedGroupKeys}
			errorText={props.errorText}
			isError={props.isError}
			testId={props.testId}
			dropdownRef={dropdownRef}
			optionsContainerRef={optionsContainerRef}
			isDropdownOpen={isDropdownOpen}
			handleInputChange={handleInputChangeCallback}
			valueForInput={valueForInput}
			placeholder={props.placeholder}
			label={props.label}
			disabled={props.disabled}
			required={props.required}
			handleInputFocusChange={handleInputFocusChange}
			highlightedIndex={highlightedIndex}
			highlightedGroupIndex={highlightedGroupIndex}
			setIsDropdownOpen={setIsDropdownOpen}
			handleKeyDown={handleKeyDown}
			handleOptionClick={handleOptionClick}
			handleOptionHover={handleOptionHoverCallback}
			getDisplayOption={props.getDisplayOption}
			getSecondDisplayOption={props.getSecondDisplayOption}
			getIsSelected={getIsSelected}
			hideClearTextButton={props.hideClearTextButton}
			handleGroupSelect={handleGroupSelect}
			handleGroupHover={handleGroupHover}
			handleSelectAllClick={props.isSelectAllOption ? handleSelectAllClick : undefined}
			inputRef={props.inputRef}
			getGroupDisplayOption={props.getGroupDisplayOption}
			topLevelGroups={topLevelGroups}
			footer={<DropdownFooter setIsDropdownOpen={setIsDropdownOpen} />}
		/>
	);
}
