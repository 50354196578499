import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { countBy, groupBy, orderBy } from 'lodash';
import { TrusstorBox } from '../../../../Boxes/Containers/TrusstorBox';
import { HeaderBox } from '../../../../Boxes/Containers/HeaderBox/HeaderBox';
import { translationService } from '../../../../../index';
import { TitleColoredBox } from '../../../../Boxes/Containers/TitleColoredBox/TitleColoredBox';
import { safetyRed } from '../../../../../assets/safetyIcons';
import { HourlyGraphSafety } from '../Charts/HourlyGraphSafety';
import { getGradientColorBasedOnColor } from '../../../../../utils/generalUtils';
import { textStyle } from '@shared/utils/text.utils';
import { IFloor, IExtendedShortReportSafetyEvent, ISafetyReport, IProfession } from '../../../../../interfaces';
import { DATE_PICKER_TOGGLE, reportsPageConsts } from '../../../../../constants';
import { HorizontalBars } from '../Charts/HorizontalBars';
import { IHorizontalBarData } from '../Charts/interfaces/IHorizontalBarData.interface';
import { convertSafetyReportToSafetyEventsArray } from '../../../../../utils/reportUtils';
import { useSelector } from 'react-redux';
import { filterArrayByAnotherArray } from '../../../../../utils/array.util';
import { IEventsPerHour } from '../../../../../interfaces/IEventsPerHour';
import { IRootState } from '../../../../../store/slices';
import {
	getSafetyDailyReport,
	getSafetyPeriodicReport,
	stopGettingSafetyReport,
} from '../../../../../store/thunks/safetyReport.thunks';
import { getProfessionDisplayText } from '../../../../../utils/professions.utils';
import { SORT_ORDER } from '@shared/constants/constants';
import { getProfessionBackgroundColor } from '@shared/utils/professions.utils';

interface IStyleProps {
	isLongIssuesNumber?: boolean;
}

const useStyles = makeStyles((theme) => ({
	safetyGreyHeader: {
		marginTop: 2,
		marginBottom: theme.spacing(1),
	},
	safetyYellowBox: {
		marginTop: theme.spacing(2),
		height: '48%',
	},
	containersWrapper: {
		display: 'flex',
		height: '100%',
	},
	safetyAlertsNumberContainer: {
		width: '15% !important',
		height: '100%',
	},
	safetyBarsGraphContainer: {
		width: '19% !important',
		height: '100%',
	},
	safetyHourlyGraphContainer: {
		width: '32% !important',
		height: '100%',
	},
	hourlyGraphContainer: {
		width: '100% !important',
		height: '100%',
	},
	issuesNumberContainer: {
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	issuesNumber: ({ isLongIssuesNumber }: IStyleProps) =>
		textStyle({
			marginLeft: theme.spacing(0.5),
			marginBottom: theme.spacing(1),
			lineHeight: 1.7,
			fontSize: isLongIssuesNumber ? '3.5rem' : '6.5rem',
		}),
	barsContainer: {
		backgroundColor: '#ffffff',
	},
}));

interface IShortReportSafetyEventLocal
	extends Omit<IExtendedShortReportSafetyEvent, 'timestampStart' | 'timestampEnd'> {
	timeFrom: string;
	timeTo?: string;
	timeBucket?: string;
}

interface IGraphsData {
	safetyEventsByFloor: IHorizontalBarData[];
	safetyEventsByProfession: IHorizontalBarData[];
	topFrequentEvents: IHorizontalBarData[];
	eventsPerHour: IEventsPerHour;
}

const SafetySection = () => {
	const dispatch = useDispatch();

	const [topFrequentEvents, setTopFrequentEvents] = useState<IHorizontalBarData[]>([]);
	const [safetyEventsByProfession, setSafetyEventsByProfession] = useState<IHorizontalBarData[]>([]);
	const [safetyEventsByFloor, setSafetyEventsByFloor] = useState<IHorizontalBarData[]>([]);
	const [safetyEventsPerHours, setSafetyEventsPerHours] = useState<IEventsPerHour>({
		'06:00': 0,
		'07:00': 0,
		'08:00': 0,
		'09:00': 0,
		'10:00': 0,
		'11:00': 0,
		'12:00': 0,
		'13:00': 0,
		'14:00': 0,
		'15:00': 0,
		'16:00': 0,
		'17:00': 0,
	});

	const projectId: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.projectId);
	const safetyReport: ISafetyReport = useSelector((state: IRootState) => state.safetyReportReducer.safetyReport);
	const visibleFloorsList: IFloor[] = useSelector((state: IRootState) => state.floorsListReducer.visibleFloorsList);
	const visibleProfessions: IProfession[] = useSelector(
		(state: IRootState) => state.professionsReducer.visibleProfessions
	);
	const professions: IProfession[] = useSelector((state: IRootState) => state.professionsReducer.professions);
	const visibleDateTimestamp: number = useSelector(
		(state: IRootState) => state.datePickerReducer.visibleDateTimestamp
	);
	const visiblePeriodTimestamps: [number, number] = useSelector(
		(state: IRootState) => state.datePickerReducer.visiblePeriodTimestamps
	);
	const toggleStateDatePicker: DATE_PICKER_TOGGLE = useSelector(
		(state: IRootState) => state.datePickerReducer.toggleState
	);
	const tz: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.tz);

	const filterVisibleSafetyEventWithProfessionList = (visibleSafetyEvents, visibleProfessions) => {
		const isNotFiltered: boolean = visibleProfessions.length === professions.length;
		if (!visibleProfessions || visibleProfessions.length === 0 || isNotFiltered) {
			return visibleSafetyEvents;
		}
		return visibleSafetyEvents.filter((safetyEvent: any) =>
			visibleProfessions.some((profession: any) => {
				return profession?._id == safetyEvent?.profession?._id;
			})
		);
	};

	const visibleSafetyEvents: IExtendedShortReportSafetyEvent[] = useMemo(() => {
		const safetyEvents: IExtendedShortReportSafetyEvent[] = convertSafetyReportToSafetyEventsArray(safetyReport);

		if (visibleFloorsList.length > 0 || visibleProfessions.length > 0) {
			const visibleSafetyEventsByProfessions = filterVisibleSafetyEventWithProfessionList(
				safetyEvents,
				visibleProfessions
			);
			return filterArrayByAnotherArray(
				visibleSafetyEventsByProfessions,
				visibleFloorsList,
				reportsPageConsts.properties.floorId
			);
		}

		return safetyEvents;
	}, [safetyReport, visibleFloorsList, visibleProfessions]);

	const isLongIssuesNumber: boolean = String(visibleSafetyEvents.length).length > 2;
	const classes = useStyles({ isLongIssuesNumber });

	useEffect(() => {
		toggleStateDatePicker === DATE_PICKER_TOGGLE.date
			? dispatch(getSafetyDailyReport(projectId, visibleDateTimestamp, tz))
			: dispatch(getSafetyPeriodicReport(projectId, visiblePeriodTimestamps, tz));

		return () => {
			dispatch(stopGettingSafetyReport());
		};
	}, [projectId, toggleStateDatePicker, visibleDateTimestamp, visiblePeriodTimestamps]);

	useEffect(() => {
		const { safetyEventsByFloor, safetyEventsByProfession, topFrequentEvents, eventsPerHour }: IGraphsData =
			getGraphsData();
		setSafetyEventsByFloor(safetyEventsByFloor);
		setSafetyEventsByProfession(safetyEventsByProfession);
		setTopFrequentEvents(topFrequentEvents);
		setSafetyEventsPerHours(eventsPerHour);
	}, [visibleSafetyEvents]);

	const getGraphsData = (): IGraphsData => {
		const safetyEventsByProfession: IHorizontalBarData[] = getCountSafetyEvents(
			visibleSafetyEvents,
			reportsPageConsts.properties.profession,
			reportsPageConsts.properties.professionNick,
			reportsPageConsts.NUM_OF_TOP_BARS_SAFETY,
			getGradientColorBasedOnColor,
			true
		);
		const safetyEventsByFloor: IHorizontalBarData[] = getCountSafetyEvents(
			visibleSafetyEvents,
			reportsPageConsts.properties.floorId,
			reportsPageConsts.properties.floorNick,
			reportsPageConsts.NUM_OF_TOP_BARS_SAFETY,
			getGradientColorBasedOnColor
		);
		const topFrequentEvents: IHorizontalBarData[] = getFrequentEventsTypes(visibleSafetyEvents);

		const safetyEventsWithTimeStrings: IShortReportSafetyEventLocal[] =
			convertEventsTimestampsToStrings(visibleSafetyEvents);
		const eventsPerHour: IEventsPerHour = getNumEventsPerHour(safetyEventsWithTimeStrings);

		return {
			safetyEventsByFloor,
			safetyEventsByProfession,
			topFrequentEvents,
			eventsPerHour,
		};
	};

	const getCountSafetyEvents = (
		safetyEventsArray: IExtendedShortReportSafetyEvent[],
		identifierProperty: string,
		nickProperty: string,
		dataLength: number,
		switchColorFunc: (index: number, baseColor: string) => string,
		hasOwnBackgroundColor?: boolean
	): IHorizontalBarData[] => {
		const countByIdentifier: { [identifier: string]: number } = countBy(safetyEventsArray, (safteyEvent) => {
			if (identifierProperty === 'profession') {
				return safteyEvent.profession._id;
			} else {
				return safteyEvent[identifierProperty];
			}
		});

		const safetyEventsByIdentifier: IHorizontalBarData[] = Object.keys(countByIdentifier).map(
			(identifier, index) => {
				const matchingObject: IExtendedShortReportSafetyEvent | undefined = safetyEventsArray.find(
					(safetyEvent) => {
						if (identifierProperty === 'profession') {
							return safetyEvent.profession._id === identifier;
						} else {
							return safetyEvent[identifierProperty] === identifier;
						}
					}
				);
				if (!matchingObject) {
					return {
						label: identifier,
						number: 0,
						backgroundColor: '',
					};
				}
				return {
					label:
						identifierProperty === 'profession'
							? getProfessionDisplayText(matchingObject.profession)
							: (matchingObject[nickProperty] as string),
					number: countByIdentifier[identifier],
					backgroundColor: hasOwnBackgroundColor
						? matchingObject.backgroundColor ||
						  getProfessionBackgroundColor(matchingObject.profession, projectId)
						: switchColorFunc(index, reportsPageConsts.floorSafetyChartBaseColor),
				};
			}
		);
		const sortedDescending: IHorizontalBarData[] = orderBy(
			safetyEventsByIdentifier,
			reportsPageConsts.properties.number,
			reportsPageConsts.DESCENDING as SORT_ORDER
		).map((event, index) => {
			return {
				...event,
				backgroundColor: hasOwnBackgroundColor
					? event.backgroundColor
					: switchColorFunc(index, reportsPageConsts.floorSafetyChartBaseColor),
			};
		});

		return sortedDescending.slice(0, dataLength);
	};

	const getFrequentEventsTypes = (safetyEventsArray: IExtendedShortReportSafetyEvent[]): IHorizontalBarData[] => {
		const eventsGroupedByType = groupBy(safetyEventsArray, reportsPageConsts.properties.type);
		const eventsArray: IHorizontalBarData[] = [];
		for (const type in eventsGroupedByType) {
			eventsArray.push({
				label: translationService.get(type),
				number: eventsGroupedByType[type].length,
				backgroundColor: getGradientColorBasedOnColor(0, reportsPageConsts.floorSafetyChartBaseColor),
			});
		}

		return orderBy(eventsArray, 'number', reportsPageConsts.DESCENDING as SORT_ORDER);
	};

	const getNumEventsPerHour = (safetyEventsArray: IShortReportSafetyEventLocal[]): IEventsPerHour => {
		const safetyEventsArrayModifiedTime: IShortReportSafetyEventLocal[] = safetyEventsArray.map((event) => {
			return {
				...event,
				timeBucket: getBucketizedTimeByHour(event.timeFrom),
			};
		});
		const eventsCountedByHour: IEventsPerHour = countBy(safetyEventsArrayModifiedTime, (event) => event.timeBucket);
		const currentSafetyEventPerHours = {
			...safetyEventsPerHours,
			...eventsCountedByHour,
		};
		return currentSafetyEventPerHours;
	};

	const getBucketizedTimeByHour = (timeString: string): string => {
		const hour: string = timeString.split(':')[0];
		return hour + ':00';
	};

	const convertEventsTimestampsToStrings = (
		safetyEvents: IExtendedShortReportSafetyEvent[]
	): IShortReportSafetyEventLocal[] => {
		return safetyEvents.map((event) => {
			return {
				...event,
				timeFrom: moment.tz(event.timestampStart, tz).format('HH:mm'),
				timeTo: event.timestampEnd ? moment.tz(event.timestampEnd, tz).format('HH:mm') : undefined,
			};
		});
	};

	return (
		<TrusstorBox customStyleClass={classes.safetyYellowBox}>
			<HeaderBox
				title={translationService.get('safety')}
				customHeaderClass={classes.safetyGreyHeader}
				customRootStyles={{ width: '100%', height: '100%', overflow: 'hidden' }}
			>
				<div className={classes.containersWrapper}>
					<TitleColoredBox
						title={`${translationService.get('alertsNumber')}`}
						iconSrc={safetyRed}
						showIcon={true}
						customRootStyleClass={classes.safetyAlertsNumberContainer}
					>
						<div className={classes.issuesNumberContainer}>
							<p className={classes.issuesNumber}>{visibleSafetyEvents.length}</p>
						</div>
					</TitleColoredBox>
					<TitleColoredBox
						title={`${translationService.get('type')}`}
						subtitleFirstPart={`(${translationService.get('commonAlerts')})`}
						customRootStyleClass={classes.safetyBarsGraphContainer}
					>
						<div>
							<HorizontalBars
								endNumberLabel={true}
								data={topFrequentEvents}
								maxNumber={topFrequentEvents[0] ? topFrequentEvents[0].number : 20}
								barHeight={24}
								barsContainerStyleClass={classes.barsContainer}
							/>
						</div>
					</TitleColoredBox>
					<TitleColoredBox
						title={`${translationService.get('involvementFactor')}`}
						subtitleFirstPart={`(${translationService.get('sumAlertsByProfessions')})`}
						customRootStyleClass={classes.safetyBarsGraphContainer}
						displayColumnTitle
					>
						<div>
							<HorizontalBars
								endNumberLabel={true}
								data={safetyEventsByProfession}
								maxNumber={safetyEventsByProfession[0] ? safetyEventsByProfession[0].number : 20}
								barHeight={24}
								barsContainerStyleClass={classes.barsContainer}
							/>
						</div>
					</TitleColoredBox>
					<TitleColoredBox
						title={`${translationService.get('location')}`}
						subtitleFirstPart={`(${translationService.get('sumAlertsByFloors')})`}
						customRootStyleClass={classes.safetyBarsGraphContainer}
					>
						<div>
							<HorizontalBars
								endNumberLabel={true}
								data={safetyEventsByFloor}
								maxNumber={safetyEventsByFloor[0] ? safetyEventsByFloor[0].number : 20}
								barHeight={24}
								barsContainerStyleClass={classes.barsContainer}
							/>
						</div>
					</TitleColoredBox>
					<TitleColoredBox
						title={`${translationService.get('time')}`}
						subtitleFirstPart={`(${translationService.get('sumAlertsByTime')})`}
						customRootStyleClass={classes.safetyHourlyGraphContainer}
					>
						<HourlyGraphSafety dataArray={safetyEventsPerHours} />
					</TitleColoredBox>
				</div>
			</HeaderBox>
		</TrusstorBox>
	);
};

export { SafetySection };
