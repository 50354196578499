import { DATE_PICKER_TOGGLE } from '@shared/interfaces/DatePickerMode';
import { convertDateToDailyStatusDateString, getDateByLocaleFormat, getTimezoneFormattedDate } from '@utils/dateUtils';
import moment from 'moment/moment';
import { exportService, requestService, translationService } from '@src/servicesInitializers';
import { IRow } from '@src/services';
import { getProfessionDisplayText } from '@utils/professions.utils';
import { IProfessionProductivity, IProfessionProductivityArea } from '@interfaces/IProfessionProductivity';
import { compact, sortBy } from 'lodash';
import { IConfigArea } from '@shared/interfaces/IConfigArea';
import { AreaTypeEnum } from '@shared/interfaces/AreaType.enum';
import { IFloor } from '@shared/interfaces/IFloor';

export const exportTagDistributionReportToExcel = async (
	tz: string,
	projectId: string,
	visiblePeriodTimestamps: [number, number],
	visibleDateTimestamp: number,
	toggleStateDatePicker: DATE_PICKER_TOGGLE,
	areas: IConfigArea[],
	floors: IFloor[]
): Promise<void> => {
	const formattedReportDates: string =
		toggleStateDatePicker === DATE_PICKER_TOGGLE.period
			? `${getDateByLocaleFormat(moment.tz(visiblePeriodTimestamps[0], tz), tz)} - ${getDateByLocaleFormat(
					moment.tz(visiblePeriodTimestamps[1], tz),
					tz
				)}`
			: `${getDateByLocaleFormat(moment.tz(visibleDateTimestamp, tz), tz)}`;

	const sheets = {};
	const HEADLINE: string = translationService.get('tagDistributionAdminReport');
	const FILE_NAME: string = `${HEADLINE} ${formattedReportDates}`;
	const isPeriodicPicker: boolean = toggleStateDatePicker === DATE_PICKER_TOGGLE.period;
	const dateFrom: string = getTimezoneFormattedDate(
		tz,
		isPeriodicPicker ? visiblePeriodTimestamps[0] : visibleDateTimestamp
	);
	const dateTo: string = getTimezoneFormattedDate(
		tz,
		isPeriodicPicker ? visiblePeriodTimestamps[1] : visibleDateTimestamp
	);
	const productivityReports: IProfessionProductivity[] = await requestService.get(
		`/reports/professionsProductivityReports?dateFrom=${dateFrom}&dateTo=${dateTo}&projectId=${projectId}`
	);
	sheets[translationService.get('distributionOfTags')] = getTagDistributionRows(
		productivityReports,
		tz,
		areas,
		floors
	);
	await exportService.exportMultipleSheetsToExcel(sheets, FILE_NAME, HEADLINE, formattedReportDates);
};

const getTagDistributionRows = (
	productivityReports: IProfessionProductivity[],
	tz: string,
	areas: IConfigArea[],
	floors: IFloor[]
): IRow[] => {
	const sortedReports: IProfessionProductivity[] = sortBy(
		productivityReports,
		(report: IProfessionProductivity) => report.profession._id,
		(report: IProfessionProductivity) => report.projectDate
	);

	return sortedReports.flatMap((productivityReport: IProfessionProductivity) => {
		const sortedAreas: IProfessionProductivityArea[] = sortBy(
			Object.values(productivityReport.areas),
			(areaProductivity: IProfessionProductivityArea) => areaProductivity.areaNick
		);

		return compact(
			sortedAreas.map((areaProductivity: IProfessionProductivityArea) => {
				const totalWorkHourInArea: string = (areaProductivity.totalTimeMS / 1000 / 60 / 60).toFixed(1);

				if (Number(totalWorkHourInArea) === 0) {
					return null;
				}

				const areaType: AreaTypeEnum | undefined = areas.find(
					(area: IConfigArea) => area.areaId === areaProductivity.areaId
				)?.areaType;
				const floorNick: string | undefined = floors.find(
					(floor: IFloor) => floor.floorId === areaProductivity.floorId
				)?.floorNick;

				return {
					[translationService.get('profession')]: {
						value: getProfessionDisplayText(productivityReport.profession),
					},
					[translationService.get('date')]: {
						value: convertDateToDailyStatusDateString(productivityReport.projectDate, tz),
					},
					[translationService.get('floor')]: {
						value: floorNick || areaProductivity.floorId,
					},
					[translationService.get('area')]: {
						value: areaProductivity.areaNick,
					},
					[translationService.get('tagDistributionReport_areaType')]: {
						value: areaType || '',
					},
					[translationService.get('tagDistributionReport_uniqueWorkersCount')]: {
						value: areaProductivity.tagIds.length,
					},
					[translationService.get('tagDistributionReport_totalWorkHour')]: {
						value: totalWorkHourInArea,
					},
				};
			})
		);
	});
};
