import { cloneDeep, get } from 'lodash';
import { IConfigArea } from '../interfaces/IConfigArea';
import { SORT_ORDER } from '../constants/constants';
import { NestedExtends } from './types.utils';
import { floorTypes } from '../constants/floors.constants';

type FloorData = { floorId: string; isSpecialFloor?: boolean };
type AreaData = { areaSortIndex?: number; areaNick: string } & FloorData;
type IncludesAreaData<T extends object> = NestedExtends<T, AreaData>;
type IncludesFloorData<T extends object> = NestedExtends<T, FloorData>;

const compareNumberStrings = (a: string, b: string, order: SORT_ORDER): number => {
	return order === SORT_ORDER.ASCENDING ? Number(a) - Number(b) : Number(b) - Number(a);
};

const compareFloorIdsWithLetters = (a: string, b: string, order: SORT_ORDER): number => {
	const letterA: string = a.charAt(a.length - 1);
	const letterB: string = b.charAt(b.length - 1);

	if (letterA === letterB) {
		return compareNumberStrings(a.substring(0, a.length - 1), b.substring(0, b.length - 1), order);
	}

	return order === SORT_ORDER.ASCENDING ? letterA.localeCompare(letterB) : letterB.localeCompare(letterA);
};

const getFloorsSeparatedByType = <T extends object>(
	floors: IncludesFloorData<T>[],
	pathToFloorId: string = 'floorId'
): {
	floorsWithOnlyDigits: IncludesFloorData<T>[];
	floorsWithDigitsAndLetters: IncludesFloorData<T>[];
	paFloors: IncludesFloorData<T>[];
	specialFloors: IncludesFloorData<T>[];
} => {
	const floorsWithOnlyDigits: IncludesFloorData<T>[] = [];
	const floorsWithDigitsAndLetters: IncludesFloorData<T>[] = [];
	const paFloors: IncludesFloorData<T>[] = [];
	const specialFloors: IncludesFloorData<T>[] = [];

	const digitsRegex = /^-?\d+$/;

	for (const floor of floors) {
		if (get(floor, pathToFloorId) === floorTypes.pa) {
			paFloors.push(floor);
		} else if (digitsRegex.test(get(floor, pathToFloorId))) {
			floorsWithOnlyDigits.push(floor);
		} else if (get(floor, 'isSpecialFloor')) {
			specialFloors.push(floor);
		} else {
			floorsWithDigitsAndLetters.push(floor);
		}
	}

	return { floorsWithOnlyDigits, floorsWithDigitsAndLetters, paFloors, specialFloors };
};

export function sortFloors<T extends object>(
	floors: IncludesFloorData<T>[],
	order: SORT_ORDER,
	pathToFloorId: string = 'floorId'
): T[] {
	const filteredFloors = floors.filter((floor) => get(floor, pathToFloorId) !== floorTypes.storage);

	const {
		floorsWithOnlyDigits,
		floorsWithDigitsAndLetters,
		paFloors,
		specialFloors,
	}: {
		floorsWithOnlyDigits: IncludesFloorData<T>[];
		floorsWithDigitsAndLetters: IncludesFloorData<T>[];
		paFloors: IncludesFloorData<T>[];
		specialFloors: IncludesFloorData<T>[];
	} = getFloorsSeparatedByType(filteredFloors, pathToFloorId);

	floorsWithOnlyDigits.sort((a: IncludesFloorData<T>, b: IncludesFloorData<T>) =>
		compareNumberStrings(get(a, pathToFloorId), get(b, pathToFloorId), order)
	);

	floorsWithDigitsAndLetters.sort((floorA: IncludesFloorData<T>, floorB: IncludesFloorData<T>) =>
		compareFloorIdsWithLetters(get(floorA, pathToFloorId), get(floorB, pathToFloorId), order)
	);
	paFloors.sort((a: IncludesFloorData<T>, b: IncludesFloorData<T>) =>
		compareFloorIdsWithLetters(get(a, pathToFloorId), get(b, pathToFloorId), order)
	);
	specialFloors.sort((a: IncludesFloorData<T>, b: IncludesFloorData<T>) =>
		compareFloorIdsWithLetters(get(a, pathToFloorId), get(b, pathToFloorId), order)
	);

	return [...floorsWithOnlyDigits, ...floorsWithDigitsAndLetters, ...paFloors, ...specialFloors] as T[];
}

const deprecatedSortAreas = (areaDataA: AreaData, areaDataB: AreaData): number => {
	const regex: RegExp = /(\d+)(\D*)/;

	const [, aAreaNumber, aAreaLetter] = areaDataA.areaNick.match(regex) || [, areaDataA.areaNick, areaDataA.areaNick];
	const [, bAreaNumber, bAreaLetter] = areaDataB.areaNick.match(regex) || [, areaDataB.areaNick, areaDataA.areaNick];

	if (aAreaNumber !== bAreaNumber) {
		return parseInt(aAreaNumber!) - parseInt(bAreaNumber!);
	}

	if (aAreaLetter !== bAreaLetter) {
		return aAreaLetter!.localeCompare(bAreaLetter!) || 0;
	}

	return 0;
};

export const sortByAreas = <T extends object>(
	areaSequenceItems: IncludesAreaData<T>[],
	floorsSortOrder: SORT_ORDER = SORT_ORDER.ASCENDING,
	pathToAreaData = ''
): T[] => {
	const clonedAreaSequenceItems: IncludesAreaData<T>[] = cloneDeep(areaSequenceItems);

	const areasByFloorsSorted: IncludesAreaData<T>[] = sortFloors(
		clonedAreaSequenceItems as unknown as IncludesFloorData<T>[],
		floorsSortOrder,
		`${pathToAreaData.length ? `${pathToAreaData}.` : ''}floorId`
	) as IncludesAreaData<T>[];
	const groupedAreasByFloorId = new Map<string, IncludesAreaData<T>[]>();

	for (const area of areasByFloorsSorted) {
		const areaData: IConfigArea = pathToAreaData === '' ? area : get(area, pathToAreaData);
		const floorId: string = areaData.floorId;
		if (!groupedAreasByFloorId.has(floorId)) {
			groupedAreasByFloorId.set(floorId, []);
		}
		groupedAreasByFloorId.get(floorId)?.push(area);
	}

	const sortedAreas: IncludesAreaData<T>[] = [];

	for (const [floorId, areasInFloor] of groupedAreasByFloorId) {
		const sortedAreasInFloor: IncludesAreaData<T>[] = areasInFloor.sort(
			(a: IncludesAreaData<T>, b: IncludesAreaData<T>) => {
				const areaDataA: AreaData = pathToAreaData === '' ? a : get(a, pathToAreaData);
				const areaDataB: AreaData = pathToAreaData === '' ? b : get(b, pathToAreaData);
				if (Number.isNaN(Number(areaDataA.areaSortIndex)) && Number.isNaN(Number(areaDataB.areaSortIndex))) {
					return deprecatedSortAreas(areaDataA, areaDataB);
				}
				if (Number.isNaN(Number(areaDataA.areaSortIndex))) {
					return 1;
				}
				if (Number.isNaN(Number(areaDataB.areaSortIndex))) {
					return -1;
				}
				return areaDataA.areaSortIndex! - areaDataB.areaSortIndex!;
			}
		);

		sortedAreas.push(...sortedAreasInFloor);
	}

	return sortedAreas as T[];
};

export const getSortedAreaIds = (areas: IConfigArea[], areaIds: string[]): string[] => {
	const sortedAreas: IConfigArea[] = sortByAreas(areas, SORT_ORDER.DESCENDING);

	const areaOrderLookup: { [key: string]: number } = sortedAreas.reduce(
		(acc, area, index) => {
			acc[area.areaId] = index;
			return acc;
		},
		{} as { [key: string]: number }
	);

	const sortedAreaIds: string[] = areaIds.sort(
		(a, b) => (areaOrderLookup[a] || Infinity) - (areaOrderLookup[b] || Infinity)
	);

	return sortedAreaIds;
};
