import React, { useRef } from 'react';
import classes from './styles.module.scss';
import TrusstorTooltip from '../../TrusstorTooltip';

interface IProgressBarProps {
	progressCount: number;
	totalCount: number;
	showTooltip?: boolean;
	tooltipText?: string;
	tooltipIcon?: string;
	showPrecentage?: boolean;
	color?: string;
}

export const ProgressBar = (props: IProgressBarProps) => {
	const progressBarContainerRef = useRef<HTMLDivElement>(null);
	const percentage: number = (props.progressCount / props.totalCount) * 100;

	const tooltipComponent = (
		<div className={classes.progressTooltip} data-testId={'progressTooltip'}>
			{props.tooltipIcon && <img className={classes.icon} src={props.tooltipIcon} alt="" />}
			<div>
				{' '}
				{props.progressCount}/{props.totalCount}
			</div>
			{props.tooltipText && <div className={classes.addtionalTooltipText}>{props.tooltipText}</div>}
		</div>
	);

	return (
		<TrusstorTooltip
			refChildren={progressBarContainerRef}
			cleanStyling
			showAlways={!!props.showTooltip}
			tooltipComponent={tooltipComponent}
		>
			<div className={classes.progressBarContainer}>
				<div className={classes.progressBar} ref={progressBarContainerRef} data-testId={'progressBar'}>
					<div className={classes.progressContainer}>
						<div
							className={classes.progress}
							style={{ width: `${percentage}%`, backgroundColor: props.color }}
						/>
					</div>
				</div>
				{props.showPrecentage && (
					<div className={classes.percentageText} data-testId={'progressPercentage'}>
						{percentage.toFixed(0)}%
					</div>
				)}
			</div>
		</TrusstorTooltip>
	);
};
