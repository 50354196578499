import React from 'react';
import { INotificationCardSection } from '@shared/interfaces/NotificationSettings/INotificationCardSection';
import { useDispatch, useSelector } from 'react-redux';
import { selectProjectId } from '@store/slices/project.slice';
import { selectLoggedUser } from '@store/slices/login.slice';
import { useBaseSettingsQuery, useUserSettingsQuery } from '@src/hooks/queries/notificationEngine.queries.hooks';
import classes from './styles.module.scss';
import { TrusstorIcon } from '@src/Components/TrusstorIcon/TrusstorIcon';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { requestService, translationService } from '../../index';
import { INotificationTypes, NotificationTypes } from '@shared/interfaces/NotificationTypes.enum';
import { NotificationChannelTypes } from '@shared/interfaces/NotificationChannelTypes.enum';
import {
	IBaseNotificationSettings,
	IUserNotificationSetting,
	IUserNotificationSettings,
} from '@shared/interfaces/INotificationSettings';
import { NotificationCardSection } from '@src/Components/NotificationSettingCard/NotificationCardSection';
import { notificationPreferencesEngagement_BI, notificationSettingsEngagement_BI } from '@utils/bi.utils';
import { errorSnackbar } from '@utils/snackbar.utils';
import { notificationsConfiguration, preDefinedFrequenciesMapping } from '@shared/constants/notifications.constants';

interface INotificationSettingCardProps {
	card: INotificationCardSection;
	isBase?: boolean;
}

const NotificationSettingCard = (props: INotificationSettingCardProps) => {
	const projectId: string = useSelector(selectProjectId)!;
	const loggedUser = useSelector(selectLoggedUser)!;
	const dispatch = useDispatch();
	const { userSettings, refetchUserSettings } = useUserSettingsQuery(loggedUser.userDetails.username, projectId);
	const { baseSettings, refetchBaseSettings } = useBaseSettingsQuery(projectId);

	const getSettingsUpdateObject = (
		notificationType: string,
		currentSettings?: IUserNotificationSettings | IBaseNotificationSettings,
		frequency?: boolean,
		channelName?: NotificationChannelTypes,
		value?: string | boolean | null
	): Partial<IUserNotificationSetting> => {
		const settings: Partial<IUserNotificationSetting> = {
			...(frequency && { frequency: preDefinedFrequenciesMapping[value as string] }),
			...(channelName && { channels: { [channelName]: value } }),
		};
		const defaultFrequency: string | undefined = getDefaultFrequency(notificationType as INotificationTypes);
		const shouldUpdateDefaultFrequency: boolean =
			!settings.frequency && !currentSettings?.settings?.[notificationType]?.frequency && !!defaultFrequency;
		if (shouldUpdateDefaultFrequency) {
			settings.frequency = defaultFrequency;
		}
		return settings;
	};

	const handleBaseChange = async (
		notificationType: INotificationTypes,
		recipient?: { username: string; add: boolean },
		value?: string | boolean | null,
		channelName?: NotificationChannelTypes,
		frequency?: boolean
	) => {
		try {
			if (value === null) return;
			const settingsToUpdate: Partial<IUserNotificationSetting> = getSettingsUpdateObject(
				notificationType,
				baseSettings,
				frequency,
				channelName,
				value
			);

			await requestService.post(
				`/notification/settings/base?projectId=${projectId}&notificationType=${notificationType}`,
				{
					body: {
						setting: settingsToUpdate,
						...(recipient && { recipients: [recipient] }),
					},
				}
			);
			const parameterChanged: string | NotificationChannelTypes =
				channelName || frequency ? 'frequency' : 'recipient';
			notificationPreferencesEngagement_BI(notificationType, parameterChanged);
		} catch (error) {
			errorSnackbar(dispatch, translationService.get('failedToChangeNotificationSettings'));
		}
		refetchBaseSettings();
	};

	const getDefaultFrequency = (notificationType: INotificationTypes): string | undefined => {
		if (notificationType.includes(NotificationTypes.RestrictedArea)) {
			return notificationsConfiguration[NotificationTypes.RestrictedArea]?.defaultFrequency;
		}
		return notificationsConfiguration[notificationType]?.defaultFrequency;
	};

	const handleSettingsChange = async (
		value: string | boolean | null,
		notificationType: INotificationTypes,
		channelName?: NotificationChannelTypes,
		frequency?: boolean
	): Promise<void> => {
		try {
			if ((!frequency && !channelName) || value === null) return;
			const settingsToUpdate: Partial<IUserNotificationSetting> = getSettingsUpdateObject(
				notificationType,
				userSettings,
				frequency,
				channelName,
				value
			);
			await requestService.post(
				`/notification/settings/user?username=${loggedUser.userDetails.username}&projectId=${projectId}&notificationType=${notificationType}`,
				{
					body: {
						setting: settingsToUpdate,
					},
				}
			);
			const parameterChanged: string | NotificationChannelTypes = frequency ? 'frequency' : channelName!;
			notificationSettingsEngagement_BI(notificationType, parameterChanged);
		} catch (error) {
			errorSnackbar(dispatch, translationService.get('failedToChangeNotificationSettings'));
		}
		refetchUserSettings();
	};

	return (
		<section className={classes.cardContainer}>
			<div className={classes.cardHeader}>
				<TrusstorIcon iconName={props.card.icon as IconNames} className={classes.cardIcon} />
				<div className={classes.titleText}>{translationService.get(props.card.name)}</div>
			</div>
			<div className={classes.cardBody}>
				{props.card.cards.map((card, index: number) => {
					return (
						<>
							<NotificationCardSection
								index={index}
								isBase={props.isBase}
								card={card}
								baseSettings={baseSettings}
								userSettings={userSettings}
								handleSettingsChange={handleSettingsChange}
								handleBaseSettingsChange={handleBaseChange}
							/>
						</>
					);
				})}
			</div>
		</section>
	);
};

export { NotificationSettingCard };
