import {
	IActivityStaticInfo,
	IBaseManager,
	IManager,
	IMergedFloorView,
	IProfession,
	ITag,
	ITrade,
	IUser,
	IWeekDayActivity,
	IWorkPlanActivity,
} from '../interfaces';
import { BIEventNames } from '../interfaces/BI/BIEventNames.enum';
import { assignmentKinds, hoistOperatorTradeId } from '../constants';
import { biService, requestService } from '../index';
import { getDayAmountBetweenDates, getTimezoneFormattedDate } from './dateUtils';
import { IBIWorkplanEditCreateActivityEventData } from '../interfaces/BI/IBIWorkplanEditCreateActivityEventData';
import { IBIMainViewOpenFloorEventData } from '../interfaces/BI/IBIMainViewOpenFloorEventData';
import { IBIWorkplanToggleFloorsProfessionsEventData } from '../interfaces/BI/IBIWorkplanToggleFloorsProfessionsEventData';
import { IBIWorkplanExcelExportEventData } from '../interfaces/BI/IBIWorkplanExcelExportEventData';
import { IBaseActivityBIEventData } from '../interfaces/IBaseActivityBIEvent';
import { IBISummariesDateSelectionEventData } from '../interfaces/BI/IBISummariesDateSelectionEventData';
import { IBISummariesExportExcelEventData } from '../interfaces/BI/IBISummariesExportExcelEventData';
import { IBIHoistMainViewTooltip } from '../interfaces/BI/IBIHoistMainViewTooltip';
import { IFloorData } from '../interfaces/IFloorData';
import { IWorkerMergedTag } from '../interfaces/IWorkerMergedTag';
import { IBIUpdateTagEventData } from '../interfaces/BI/IBIUpdateTagEventData';
import { isEqual } from 'lodash';
import { IBaseProfession } from '@shared/interfaces/IBaseProfession';
import {
	IBIProfessionCreateEventData,
	IBIProfessionDeleteEventData,
	IBIProfessionUpdateEventData,
} from '../interfaces/BI';
import { IBIManagerCreateUpdateEventData } from '../interfaces/BI/IBIManagerCreateUpdateEventData';
import { getFormattedMinutesPassedFromTimestamp_BI } from '@shared/utils/bi.utils';
import { IBIWorkplanManualActionMadeInput } from '../interfaces/BI/IBIWorkplanManualActionMadeInput';
import { IBIWorkplanManualActionMadeEventData } from '../interfaces/BI/IBIWorkplanManualActionMadeEventData';
import { IBIUserInvitationEventData } from '../interfaces/IBIUserInvitationEventData';
import { IBIUserDeleteEventData } from '../interfaces/IBIUserDeleteEventData';
import { IBIUserSendResetPasswordEventData } from '../interfaces/IBIUserSendResetPasswordEventData';
import { IBIUserUpdateEventData } from '../interfaces/IBIUserUpdateEventData';
import { DashboardTabTypes } from '@interfaces/DashboardTabTypes.enum';
import { IBIAnalysisCenterReportGeneration } from '@interfaces/BI/IBIAnalysisCenterReportGeneration';
import { BiAnalysisCenterReportTypes } from '@src/constants/bi.constants';
import { IInvitedAndLoggedUser } from '@src/Components/UserDialog/UserDialog';
import { getProfessionBackgroundColor } from '@shared/utils/professions.utils';
import { IBIEngagementNotificationSettingsEventData } from '@interfaces/BI/IBIEngagementNotificationSettingsEventData';
import { NotificationChannelTypes } from '@shared/interfaces/NotificationChannelTypes.enum';
import { IBIWorkplanCreateLookAheadEventData } from '@interfaces/BI/IBIWorkplanCreateLookAhead';
import { IRestrictedAreaTrigger } from '@interfaces/IRestrictedAreaTrigger';
import { IBIRestrictedAreaTriggerEventData } from '@interfaces/BI/IBIRestrictedAreaTriggerEventData';
import { IMainPageWidgetActivityData } from '@interfaces/IMainPageWidgetActivityData';
import { AreaSequenceItemStatus } from '@shared/interfaces/AreaSequenceItemStatus.enum';
import { v4 as uuidv4 } from 'uuid';
import { IIssue } from '@shared/interfaces/IIssue';
import { IBIIssuesPageToggleProfessionsManagersEventData } from '../interfaces/BI/IBIIssuesPageToggleProfessionsManagersEventData';
import { getTradeTranslation } from './translations.utils';
import { IssueStatus } from '@shared/interfaces/IIssueShared';
import { IMergedAreaSequenceItem } from '@interfaces/IMergedAreaSequenceItem';

export const progressAnalysisAccess_BI = () => {
	biService.logEvent(BIEventNames.progressAnalysisAccess);
};

export const createIssue_BI = (issue: IIssue) => {
	biService.logEvent(BIEventNames.issueCreate, issue);
};

export const professionAnalysisSelectDate_BI = (time: '7d' | '14d' | '30d' | 'custom', datePeriod: [Date, Date]) => {
	biService.logEvent(BIEventNames.professionAnalysisSelectDate, { time, datePeriod });
};

export const issueComment_BI = (description: string, comment: string, author: string, type: string) => {
	const eventData = {
		description,
		comment,
		author,
		type,
	};
	biService.logEvent(BIEventNames.issueComment, eventData);
};

export const getStringFromArray_BI = (array: any[], fieldName: string) => {
	if (!array) {
		return '';
	}
	return array.map((item) => item[fieldName]).join(', ');
};

export const tabBecameVisible_BI = () => {
	biService.logEvent(BIEventNames.tabBecameVisible);
};

export const weekRangeDateSelection_BI = (source?: string) => {
	sendSummariesDateFilter_BI(7, source);
};

export const datePickerPeriodSelection_BI = (periodTimestamps: [number, number], tz: string, summaryType?: string) => {
	const timeframeDuration: number = getDayAmountBetweenDates(
		new Date(periodTimestamps[0]),
		new Date(periodTimestamps[1]),
		tz
	);
	sendSummariesDateFilter_BI(timeframeDuration, summaryType);
};

export const datePickerDailySelection_BI = (isDefaultSelection: boolean, tz: string, summaryType?: string) => {
	sendSummariesDateFilter_BI(1, summaryType, isDefaultSelection);
};

const sendSummariesDateFilter_BI = (
	timeframeDuration: number,
	summaryType?: string,
	isDefaultSelection: boolean = false
) => {
	if (!summaryType || isDefaultSelection) {
		return;
	}
	const eventData: IBISummariesDateSelectionEventData = {
		summaryType: summaryType,
		timeframeDuration,
	};
	biService.logEvent(BIEventNames.summariesDateFilter, eventData);
};

export const createActivityPlanning_BI = (adHocActivitiesNumber: number, sequenceActivitiesNumber: number): void => {
	const eventData: IBIWorkplanCreateLookAheadEventData = {
		adHocActivitiesNumber,
		sequenceActivitiesNumber,
	};
	biService.logEvent(BIEventNames.workplanCreateLookahed, eventData);
};

export const workplanOpenPlanning_BI = (): void => {
	biService.logEvent(BIEventNames.workplanOpenPlanning);
};

export const workplanCalendarTabAccess_BI = (): void => {
	biService.logEvent(BIEventNames.workplanCalendarTabAccess);
};

export const workplanProgressTrackerTabAccess_BI = (): void => {
	biService.logEvent(BIEventNames.workplanProgressTrackerTabAccess);
};

export const issuesTabAccess_BI = (): void => {
	biService.logEvent(BIEventNames.issuesTabAccess);
};

export const workplanDialogLog_BI = (
	activity: Partial<IWorkPlanActivity>,
	openDialogTimeStamp: number,
	tz: string,
	isFormValid: boolean,
	originalActivity?: IWorkPlanActivity
): void => {
	const isEditMode: boolean = !!originalActivity;
	const eventData: IBIWorkplanEditCreateActivityEventData = {
		groupId: activity.groupId!,
		description: activity.description!,
		isDangerous: !!activity.dangerousActivity,
		floors: getStringFromArray_BI(activity.floors!, 'floorNick'),
		areas: getStringFromArray_BI(activity.areas!, 'areaNick'),
		startDate: getTimezoneFormattedDate(tz, new Date(activity.startDate!)),
		endDate: getTimezoneFormattedDate(tz, new Date(activity.endDate!)),
		activityDuration: getDayAmountBetweenDates(activity.startDate!, activity.endDate!, tz),
		assignee: activity.assignee,
		profession: activity.profession,
		timeDuration: getFormattedMinutesPassedFromTimestamp_BI(openDialogTimeStamp!),
		success: isFormValid,
		label: activity.label?.text,
		...(isEditMode && { oldLabel: originalActivity!.label?.text }),
	};
	biService.logEvent(isEditMode ? BIEventNames.workplanEditActivity : BIEventNames.workplanCreateActivity, eventData);
};

export const floorViewOpenLog_BI = async (
	floor: IMergedFloorView,
	floorActivities: IMainPageWidgetActivityData[],
	selectedFloor: IMergedFloorView
) => {
	try {
		const dangerousActivities: IActivityStaticInfo[] = await requestService.get(
			`/activities/todayDangerousActivities?floorId=${floor.floorId}`
		);
		const eventData: IBIMainViewOpenFloorEventData = {
			floorId: floor.floorId,
			floorNick: floor.floorNick,
			activitiesCountOnFloor: floorActivities.length,
			hasDangerousActivity: dangerousActivities.length > 0,
			workersAtFloor: selectedFloor.activeProfessions.reduce(
				(current, profession) => current + profession.activeTagsIds.length,
				0
			),
		};
		biService.logEvent(BIEventNames.mainViewOpenFloor, eventData);
	} catch (e) {
		console.error(`Failed to send BI log event: ${BIEventNames.mainViewOpenFloor}`, e);
	}
};

export const workplanToggleFloorsProfessions_BI = (newToggleOption: string) => {
	const eventData: IBIWorkplanToggleFloorsProfessionsEventData = {
		sortedBy: newToggleOption,
	};
	biService.logEvent(BIEventNames.workplanToggleFloorsProfessions, eventData);
};

export const issuesPageToggleProfessionsManagers_BI = (newToggleOption: string) => {
	const eventData: IBIIssuesPageToggleProfessionsManagersEventData = {
		sortedBy: newToggleOption,
	};
	biService.logEvent(BIEventNames.issuesPageToggleProfessionsManagers, eventData);
};

export const exportCombinedReportToExcel_BI = (periodTimestamps: [number, number], tz: string, isDailyReport) => {
	const eventData: IBISummariesExportExcelEventData = {
		fileType: 'excel',
		timeframeDuration: isDailyReport
			? 1
			: getDayAmountBetweenDates(new Date(periodTimestamps[0]), new Date(periodTimestamps[1]), tz),
	};
	biService.logEvent(BIEventNames.summariesExport, eventData);
};

export const calendarExportToExcel_BI = (eventName: BIEventNames) => {
	const eventData: IBIWorkplanExcelExportEventData = {
		fileType: 'excel',
	};
	biService.logEvent(eventName, eventData);
};

export const workplanDialogAccess_BI = (activity, tz: string) => {
	const eventData: IBaseActivityBIEventData = getBaseActivityBIData(activity, tz);
	biService.logEvent(BIEventNames.workplanOpenActivityDialog, eventData);
};

export const workplanDeleteActivity_BI = (activity: Partial<IWeekDayActivity>, tz: string) => {
	const eventData: IBaseActivityBIEventData = getBaseActivityBIData(activity, tz);
	biService.logEvent(BIEventNames.workplanDeleteActivity, eventData);
};

const getBaseActivityBIData = (activity: Partial<IWeekDayActivity>, tz: string) => {
	const eventData: IBaseActivityBIEventData = {
		groupId: activity.groupId!,
		profession: activity.profession!,
		description: activity.description!,
		floors: activity.floor!.floorNick,
		startDate: getTimezoneFormattedDate(tz, new Date(activity.startDate!)),
		endDate: getTimezoneFormattedDate(tz, new Date(activity.endDate!)),
		activityDuration: getDayAmountBetweenDates(activity.startDate!, activity.endDate!, tz),
		assignee: activity.assignee,
		isDangerous: !!activity.dangerousActivity,
		label: activity.label?.text,
	};
	return eventData;
};

export const issueStatusChange_BI = (issueUpdated: IIssue, newStatus: IssueStatus, tz: string) => {
	const eventData = {
		issueId: issueUpdated._id,
		...issueUpdated,
		newStatus,
	};
	biService.logEvent(BIEventNames.issueStatusChange, eventData);
};

export const loginToMobileView_BI = (email: string) => {
	biService.logEvent(BIEventNames.loginToMobileView, { email });
};

export const InvestigationReportMainPage_BI = () => {
	biService.logEvent(BIEventNames.analysisCenterMainPageAccess);
};

export const workplanMatrixFloorNavigation_BI = (type: string) => {
	biService.logEvent(BIEventNames.workplanFloorNavigation, { type });
};

export const workplanMatrixInteraction_BI = (event: {
	actionType: string;
	floorId: string;
	areaId: string;
	areaSequenceItemId: string;
	sequenceName?: string;
	sequenceItem: string;
	source: 'Workplan' | 'Widget';
}) => {
	biService.logEvent(BIEventNames.workplanMatrixInteraction, event);
};

export const batchWorkplanMatrixInteraction_BI = (
	status: AreaSequenceItemStatus,
	projectId: string,
	areasSequenceItems: IMergedAreaSequenceItem[],
	sequenceName?: string
) => {
	const batchInteractionId: string = uuidv4();
	areasSequenceItems.map((areaSequenceItem) => {
		biService.logEvent(BIEventNames.batchWorkplanMatrixInteraction, {
			interactionId: batchInteractionId,
			actionType: status,
			sequenceName,
			projectId,
			sequenceItem: areaSequenceItem.description,
			areaSequenceItemId: areaSequenceItem._id,
			areaId: areaSequenceItem.area.areaId,
			floorId: areaSequenceItem.area.floorId,
			count: areasSequenceItems.length,
		});
	});
};

export const projectMonitorMainTabAccess_BI = () => {
	biService.logEvent(BIEventNames.projectMonitorMainTabAccess);
};

export const sendSafetyEvent_BI = async (
	source: string,
	safetyEventId: string,
	eventsOpenedCounter: number
): Promise<void> => {
	try {
		await requestService.post(`/safety/bi/SafetyEventClicked`, {
			body: {
				source,
				safetyEventId,
				eventsOpenedCounter,
			},
		});
	} catch (err) {
		console.error(err);
	}
};

export const openHoistMainViewTooltip_BI = (hoists: IFloorData[], isStatic: boolean): void => {
	const isMultipleHoists: boolean = hoists.length > 1;
	const operatorTag: IWorkerMergedTag | undefined = hoists[0].tags.find(
		(tag) => (tag as IWorkerMergedTag).profession?.tradeId === hoistOperatorTradeId
	) as IWorkerMergedTag;
	const eventData: IBIHoistMainViewTooltip = {
		hoistName: isMultipleHoists ? 'multiple' : hoists[0].floorNick,
		hoistOperatorName: isMultipleHoists ? 'multiple' : operatorTag?.name,
		hoistOperatorTagId: isMultipleHoists ? 'multiple' : operatorTag?.tagId,
		isStatic,
	};

	biService.logEvent(BIEventNames.hoistMainViewTooltip, eventData);
};

/* Tags Page */

const getTagKind = (tag: Partial<ITag>): string => {
	if (tag?.profession && tag.profession.tradeGroup !== 'others') {
		return assignmentKinds.worker;
	}
	if (tag?.linkedUtility) {
		return assignmentKinds.hoist;
	}
	return tag?.manager ? assignmentKinds.manager : assignmentKinds.guest;
};

const getTagUpdateEventData = (
	tag,
	previousTag?: ITag,
	tagKind?: string,
	timeDuration?: string
): IBIUpdateTagEventData => {
	const eventData: IBIUpdateTagEventData = {
		timeDuration,
		entity: tagKind || getTagKind(tag),
		oldEntity: previousTag && getTagKind(previousTag),
		...tag,
		tagOldOwner: previousTag?.name || previousTag?.manager?.name,
		tagOldId: previousTag?.tagId,
	};
	if (previousTag) {
		eventData.certificatesUpdated = !isEqual(previousTag?.certificates, tag.certificates);
	} else {
		eventData.hasCertificates = tag.certificates && tag.certificates?.length > 0;
	}
	return eventData;
};

export const updateTag_BI = (timeDuration: string, tagKind: string, tag: Partial<ITag>, previousTag?: ITag) => {
	const eventData: IBIUpdateTagEventData = getTagUpdateEventData(tag, previousTag, tagKind, timeDuration);
	biService.logEvent(previousTag ? BIEventNames.systemTagUpdate : BIEventNames.systemTagAssignment, eventData);
};

export const adminPanelAccess_BI = () => {
	biService.logEvent(BIEventNames.systemTagPageAccess);
};

export const unassignTag_BI = (tag: ITag) => {
	const eventData: IBIUpdateTagEventData = getTagUpdateEventData(tag);
	biService.logEvent(BIEventNames.systemTagUnassignment, eventData);
};

/* Professions Page */

export const professionsPageAccess_BI = () => {
	biService.logEvent(BIEventNames.systemProfessionsPageAccess);
};

export const createProfession_BI = (profession: IBaseProfession, originalTrade: ITrade, projectId: string) => {
	const eventData: IBIProfessionCreateEventData = {
		...profession,
		colorIsDefault: originalTrade.backgroundColor === getProfessionBackgroundColor(profession, projectId),
	};
	biService.logEvent(BIEventNames.systemProfessionCreate, eventData);
};

export const updateProfession_BI = (profession: IProfession, oldColorCode: string) => {
	const eventData: IBIProfessionUpdateEventData = {
		...profession,
		oldColorCode,
		newColorCode: profession.backgroundColor,
	};
	biService.logEvent(BIEventNames.systemProfessionUpdate, eventData);
};

export const deleteProfession_BI = (profession: IProfession, isTagError?: boolean) => {
	const eventData: IBIProfessionDeleteEventData = {
		...profession,
		tagsAssigned: isTagError,
		activitiesAssigned: !isTagError,
	};
	biService.logEvent(BIEventNames.systemProfessionUpdate, eventData);
};

/* Managers Page */

export const managersPageAccess_BI = () => {
	biService.logEvent(BIEventNames.systemManagersAccess);
};

export const usersPageAccess_BI = () => {
	biService.logEvent(BIEventNames.systemUsersAccess);
};

export const managerCreate_BI = (createManager: IBaseManager) => {
	const eventData: IBIManagerCreateUpdateEventData = {
		...createManager,
		smsCheckbox: !!createManager.receiveNotifications,
		roleType: getTradeTranslation(createManager),
	};
	biService.logEvent(BIEventNames.systemManagersCreate, eventData);
};

export const managerUpdate_BI = (oldManager: IManager, newManager: IManager) => {
	const eventData: IBIManagerCreateUpdateEventData = {
		...newManager,
		smsCheckbox: !!newManager.receiveNotifications,
		oldSmsCheckbox: !!oldManager.receiveNotifications,
		oldPhoneNumber: oldManager.phoneNumber,
		roleType: getTradeTranslation(newManager),
	};
	biService.logEvent(BIEventNames.systemManagersUpdate, eventData);
};

export const deleteManager_BI = (manager: IManager, isTagError?: boolean) => {
	const eventData = {
		...manager,
		tagsAssigned: isTagError,
		activitiesAssigned: !isTagError,
	};
	biService.logEvent(BIEventNames.systemManagersDelete, eventData);
};

export const workplanManualActionMade_BI = (
	actionsSource: 'Workplan' | 'Widget',
	activityActionBiData: IBIWorkplanManualActionMadeInput
) => {
	const eventData: IBIWorkplanManualActionMadeEventData = {
		activityId: activityActionBiData.groupId,
		profession: activityActionBiData.profession,
		description: activityActionBiData.description,
		managerName: activityActionBiData.managerName,
		activityStatus: activityActionBiData.status,
		action: activityActionBiData.actionType,
		actionsSource,
	};
	biService.logEvent(BIEventNames.workplanActivityActions, eventData);
};

/* Users Actions */
export const userInvitation_BI = (
	user: Partial<IUser>,
	invitedUser: IInvitedAndLoggedUser,
	professions?: IProfession[],
	timeDuration?: string
) => {
	const eventData: IBIUserInvitationEventData = {
		...user,
		...invitedUser,
		timeDuration,
		professions,
		user_role: user?.permissions?.roleType,
	};
	biService.logEvent(BIEventNames.userInvitation, eventData);
};

export const userUpdate_BI = (user: Partial<IUser>, newUser: Partial<IUser>, timeDuration?: string) => {
	const eventData: IBIUserUpdateEventData = {
		timeDuration,
		oldName: user.name!,
		oldProfessions: user.permissions?.permittedProfessionsIds,
		oldRole: user.permissions?.roleType!,
		newName: newUser.name!,
		newEmail: newUser.email!,
		newRole: newUser.permissions?.roleType!,
		newProfessions: newUser.permissions?.permittedProfessionsIds,
	};
	biService.logEvent(BIEventNames.userUpdate, eventData);
};

export const userDelete_BI = (user: Partial<IUser>) => {
	const eventData: IBIUserDeleteEventData = {
		...user,
		email: user.email!,
	};
	biService.logEvent(BIEventNames.userDelete, eventData);
};

export const userSendResetPassword_BI = (user: Partial<IUser>) => {
	const eventData: IBIUserSendResetPasswordEventData = {
		...user,
		email: user.email!,
	};
	biService.logEvent(BIEventNames.userSendResetPassword, eventData);
};

export const userClickAddNewUser_BI = () => {
	biService.logEvent(BIEventNames.addUserButtonClicked);
};

export const projectMonitorTabAccess_BI = (tabType: DashboardTabTypes) => {
	biService.logEvent(BIEventNames.projectMonitorTabAccess, { tab: tabType });
};

export const analysisCenterReportGeneration_BI = (reportType: string, source: 'recent' | 'new', dates: Date[]) => {
	const eventData: IBIAnalysisCenterReportGeneration = {
		source,
		reportType: BiAnalysisCenterReportTypes[reportType],
		dates,
	};
	biService.logEvent(BIEventNames.analysisCenterReportGeneration, eventData);
};

export const notificationCenterAccess_BI = () => {
	biService.logEvent(BIEventNames.notificationCenterAccess);
};

export const notificationSettingTabAccess_BI = () => {
	biService.logEvent(BIEventNames.notificationSettingsTabAccess);
};

export const notificationSettingsEngagement_BI = (
	notificationType: NotificationChannelTypes | string,
	parameter: string
) => {
	const eventData: IBIEngagementNotificationSettingsEventData = {
		notificationType,
		parameter,
	};
	biService.logEvent(BIEventNames.notificationSettingsEngagement, eventData);
};

export const notificationPreferencesEngagement_BI = (
	notificationType: NotificationChannelTypes | string,
	parameter: string
) => {
	const eventData: IBIEngagementNotificationSettingsEventData = {
		notificationType,
		parameter,
	};
	biService.logEvent(BIEventNames.notificationPreferencesEngagement, eventData);
};

export const createRestrictedAreaTrigger_BI = (restrictedAreaTrigger: IRestrictedAreaTrigger) => {
	const eventData: IBIRestrictedAreaTriggerEventData = restrictedAreaTrigger;
	biService.logEvent(BIEventNames.createRestrictedAreaTrigger, eventData);
};

export const progressTrackerZoomIn_BI = (value: number) => {
	biService.logEvent(BIEventNames.progressTrackerZoomIn, { value });
};

export const progressTrackerZoomOut_BI = (value: number) => {
	biService.logEvent(BIEventNames.progressTrackerZoomOut, { value });
};

export const progressTrackerExpand_BI = () => {
	biService.logEvent(BIEventNames.progressTrackerExpand);
};

export const progressTrackerMinimize_BI = (source: 'ESCAPE' | 'EXIT' | 'MINIMIZE') => {
	biService.logEvent(BIEventNames.progressTrackerMinimize, { source });
};
