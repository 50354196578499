import { IMinimizedProfession } from './IMinimizedProfession';
import { IBaseArea } from './IBaseArea';
import { IBaseFloor } from './IBaseFloor';
import { ICommentShared } from './ICommentShared';
import { ISelectedPoint } from './ISelectedPoint';

export enum IssuePriority {
	CRITICAL = 'critical',
	REGULAR = 'regular',
}

export enum IssueStatus {
	PLANNED = 'planned',
	FOR_REVIEW = 'forReview',
	REJECTED = 'rejected',
	COMPLETED = 'completed',
}

export interface IAreaWithSelectedPoints extends IBaseArea {
	selectedPoints?: ISelectedPoint[];
}

export interface IIssueLocation {
	area: IAreaWithSelectedPoints;
	floor: IBaseFloor;
}

export interface IIssueAssignee {
	name: string;
	username: string;
}

interface IMinimalUserIdentity {
	name: string;
	username: string;
}

export interface IIssueShared<T extends IMinimizedProfession> {
	_id: string;
	createdBy?: IMinimalUserIdentity;
	statusUpdateUser?: IMinimalUserIdentity;
	projectId: string;
	status: IssueStatus;
	createDate: Date;
	description: string;
	dueDate?: Date;
	profession?: T;
	priority: IssuePriority;
	locations?: IIssueLocation[];
	assignee?: IIssueAssignee | null;
	comments?: ICommentShared[];
	statusUpdateDate?: Date;
	isInitial?: boolean;
	linkedAreaSequenceItemId?: string | null;
	linkedActivityGroupId?: string | null;
	isPrivate?: boolean;
	procoreId?: number | string;
}
