import React from 'react';
import classes from './styles.module.scss';
import { translationService } from '@src/servicesInitializers';
import { MilestonesProgressSummary } from './MilestonesProgressSummary/MilestonesProgressSummary';
import { MilestoneSelectorCarousel } from './MilestoneSelectorCarousel/MilestoneSelectorCarousel';
import { MilestoneProgressStats } from './MilestoneProgressStats/MilestoneProgressStats';
import {
	useMilestonesProgressSummariesQuery,
	useProjectMilestonesQuery,
} from '@src/hooks/queries/milestones.queries.hooks';
import { useSelector } from 'react-redux';
import { selectProjectId, selectTimezone } from '@store/slices/project.slice';
import { IMilestone } from '@shared/interfaces/IMilestone';
import { Loader } from '@shared/components/Loader/Loader';
import { getTimezoneFormattedDate } from '@shared/utils/dateUtils';

interface IMilestonesProgressProps {
	summaryDate: Date;
}

export const MilestonesProgress = (props: IMilestonesProgressProps) => {
	const tz: string = useSelector(selectTimezone)!;
	const projectId: string = useSelector(selectProjectId)!;
	const {
		projectMilestones,
		isFetched: isProjectedMilestonesFechted,
	}: { projectMilestones: IMilestone[]; isFetched: boolean } = useProjectMilestonesQuery(projectId);
	const { milestonesProgressSummary, isFetched: isMilestonesProgressSummariesFetched } =
		useMilestonesProgressSummariesQuery(projectId, getTimezoneFormattedDate(tz, props.summaryDate));
	if (!isProjectedMilestonesFechted || !isMilestonesProgressSummariesFetched) {
		return (
			<div className={classes.loaderContainer}>
				<Loader />
			</div>
		);
	}
	return (
		<div className={classes.milestonesProgressContainer}>
			<p className={classes.headerText}>{translationService.get('progressAnalysis_milestonesProgress')}</p>
			<div className={classes.milestonesProgressSummary}>
				<MilestonesProgressSummary
					projectMilestones={projectMilestones}
					milestonesProgressSummary={milestonesProgressSummary}
				/>
			</div>
			<div className={classes.milestonesProgressSelector}>
				<MilestoneSelectorCarousel
					milestonesProgressSummary={milestonesProgressSummary}
					projectMilestones={projectMilestones}
				/>
			</div>
			<div className={classes.milestoneStats}>
				<MilestoneProgressStats
					milestonesProgressSummary={milestonesProgressSummary}
					projectMilestones={projectMilestones}
				/>
			</div>
		</div>
	);
};
