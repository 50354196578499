import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TrusstorBox } from '../../../../../Boxes/Containers/TrusstorBox';
import { makeStyles } from '@material-ui/core/styles';
import { DATE_PICKER_TOGGLE } from '../../../../../../constants';
import { updateDatePickerToggle, updateVisibleDate, updateVisiblePeriod } from '@store/slices/datePicker.slice';
import { datePickerDailySelection_BI, datePickerPeriodSelection_BI } from '@utils/bi.utils';
import { IRootState } from '@store/slices';
import { TrusstorSlideSwitcher } from '../../../../../TrusstorSlideSwitcher/TrusstorSlideSwitcher';
import { PeriodSelectorTrusstorInput } from '@src/Components/DateSelector/PeriodSelectorTrusstorInput/PeriodSelectorTrusstorInput';
import { DateSelectorTrusstorInput } from '@src/Components/DateSelector/DateSelectorTrusstorInput/DateSelectorTrusstorInput';
import moment from 'moment-timezone';
import classes from './styles.module.scss';
import { translationService } from '@src/servicesInitializers';

interface IDatePeriodPickerSectionProps {
	wrapperClass?: string;
	source?: string;
	toggleState?: DATE_PICKER_TOGGLE;
	fromDate?: Date;
	toDate?: Date;
}

const DatePeriodPickerSection = (props: IDatePeriodPickerSectionProps) => {
	const [selectedDate, setSelectedDate] = useState<Date | undefined>();
	const [selectedPeriod, setSelectedPeriod] = useState<[Date, Date] | undefined>(undefined);
	const tz = useSelector((state: IRootState) => state.projectReducer.workingProject!.tz);
	const dispatch = useDispatch();
	const isDefaultDailySelection = useRef(true);
	const toggleStateDatePicker: DATE_PICKER_TOGGLE = useSelector(
		(state: IRootState) => state.datePickerReducer.toggleState
	);
	const firstTimeRef = useRef(true);

	useEffect(() => {
		if (props.toggleState && firstTimeRef.current) {
			firstTimeRef.current = false;
			toggleDailyPeriodSelection(props.toggleState);
			if (props.toggleState === DATE_PICKER_TOGGLE.date) {
				updateDate(props.fromDate!);
				setSelectedDate(props.fromDate!);
			} else {
				updatePeriod([props.fromDate!, props.toDate!]);
				setSelectedPeriod([props.fromDate!, props.toDate!]);
			}
		}
	}, [props.toggleState, props.fromDate, props.toDate]);

	const toggleDailyPeriodSelection = (option: string) => {
		dispatch(updateDatePickerToggle({ toggleState: option as DATE_PICKER_TOGGLE }));
	};

	const updatePeriod = (period: [Date, Date]) => {
		const periodTimestamps: [number, number] = [period[0].getTime(), period[1].getTime()];
		datePickerPeriodSelection_BI(periodTimestamps, tz, props.source);
		dispatch(updateVisiblePeriod({ visiblePeriodTimestamps: periodTimestamps }));
		setSelectedPeriod(period);
	};

	const updateDate = (date: Date | Date[]) => {
		datePickerDailySelection_BI(isDefaultDailySelection.current, tz, props.source);
		dispatch(updateVisibleDate({ visibleDateTimestamp: (date as Date).getTime() }));
		setSelectedDate(date as Date);
		isDefaultDailySelection.current = false;
	};

	return (
		<TrusstorBox customStyleClass={`${classes.dateYellowBox} ${props.wrapperClass}`}>
			<TrusstorSlideSwitcher
				options={[DATE_PICKER_TOGGLE.date, DATE_PICKER_TOGGLE.period]}
				handleChange={toggleDailyPeriodSelection}
				initialState={props.toggleState}
			/>
			{toggleStateDatePicker === DATE_PICKER_TOGGLE.period ? (
				<PeriodSelectorTrusstorInput
					rootClassName={classes.datePickerClass}
					hideIcon
					showSingleMonth
					updatePeriod={updatePeriod}
					datePeriod={selectedPeriod}
					calendarPosition={translationService.getIsRtl() ? 'bottom-end' : 'bottom-start'}
				/>
			) : (
				<DateSelectorTrusstorInput
					handleTimeChange={updateDate}
					selectedDate={selectedDate || new Date()}
					selectDefaultDate
					maxDate={moment.tz(tz).toDate()}
					hideIcon
					calendarPosition={translationService.getIsRtl() ? 'bottom-end' : 'bottom-start'}
				/>
			)}
		</TrusstorBox>
	);
};

export { DatePeriodPickerSection };
