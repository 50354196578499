import React from 'react';
import classes from './styles.module.scss';
import classnames from 'classnames';
import { GhostSmallDropdownButton } from './SmallDropdownButton/GhostSmallDropdownButton';
import { usePopper } from 'react-popper';
import { Placement } from '@popperjs/core';
import DropdownItemCard from '../DropdownDisplay/DropdownItemCard/DropdownItemCard';

export interface SmallSimpleDropdownContainerProps<T> {
	isDropdownOpen: boolean;
	label?: string;
	disabled?: boolean;
	options: T[];
	selectedOptionsCount?: number;
	shouldShowSelectedOptionAsLabel?: boolean;
	setIsDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
	handleKeyDown: (event: React.KeyboardEvent) => void;
	handleOptionClick: (option: T) => void;
	handleOptionHover: (index: number) => void;
	getDisplayOption: (option: T) => string | React.ReactNode;
	getSecondDisplayOption?: (option: T) => string;
	testId?: string;
	dropdownRef: React.RefObject<HTMLDivElement>;
	containerRef?: React.RefObject<HTMLDivElement>;
	getIsSelected: (option: T) => boolean;
	showCheckbox?: boolean;
	highlightedIndex: number;
	dropdownWidth?: string;
	popperPlacement?: Placement;
}

export function SmallDropdownDisplay<T>(props: SmallSimpleDropdownContainerProps<T>) {
	const [containerRef, setContainerRef] = React.useState<HTMLDivElement | null>(null);
	const [popperElement, setPopperElement] = React.useState<HTMLDivElement | null>(null);
	const { styles, attributes } = usePopper(containerRef, popperElement, {
		placement: props.popperPlacement || 'bottom-start',
		modifiers: [
			{
				name: 'offset',
				options: {
					offset: [0, 8],
				},
			},
		],
	});

	const selectedOption = props.options.find(props.getIsSelected)!;
	const getInputDisplayOption = () => {
		return props.shouldShowSelectedOptionAsLabel && selectedOption
			? (props.getDisplayOption(selectedOption) as string)
			: props.label || '';
	};

	return (
		<div
			className={classes.SimpleDropdown_container}
			onKeyDown={props.handleKeyDown}
			tabIndex={0}
			data-testid={`SimpleDropdown${props.testId}`}
			ref={setContainerRef}
		>
			<div ref={props.containerRef}>
				<GhostSmallDropdownButton
					text={getInputDisplayOption()}
					count={props.selectedOptionsCount}
					setDropdownOpen={props.setIsDropdownOpen}
					isDropdownOpen={props.isDropdownOpen}
					disabled={props.disabled}
				/>
				{props.isDropdownOpen && props.options.length > 0 && (
					<div
						className={classes.popperContainer}
						ref={setPopperElement}
						{...attributes.popper}
						style={styles.popper}
					>
						<div
							className={classnames(classes.SimpleDropdown_options)}
							ref={props.dropdownRef}
							style={props.dropdownWidth ? { width: props.dropdownWidth } : {}}
						>
							{props.options.map((option, index) => (
								<DropdownItemCard
									showCheckbox={props.showCheckbox}
									isSelected={props.getIsSelected(option)}
									option={option}
									index={index}
									handleOptionClick={props.handleOptionClick}
									handleOptionHover={props.handleOptionHover}
									optionDisplayValue={props.getDisplayOption(option)}
									optionSecondDisplayValue={props.getSecondDisplayOption?.(option)}
									inputValue={''}
									isHighlighted={props.highlightedIndex === index}
								/>
							))}
						</div>
					</div>
				)}
			</div>
		</div>
	);
}
