import { IUtilityStaticTag } from '@interfaces/IUtilityStaticTag';
import { IStaticTag } from '@interfaces/IStaticTag';
import { requestService } from '@src/servicesInitializers';
import { UtilityTypes } from '@shared/constants/utilityTypes.enum';

export const getStaticScissorLiftTags = async (projectId: string): Promise<IUtilityStaticTag[]> => {
	const staticProjectTags: IStaticTag[] = await requestService.get(`/siteNetIntegrator/static/tags/${projectId}`);
	return staticProjectTags.filter(
		(tag) => (tag as IUtilityStaticTag).linkedUtility?.type === UtilityTypes.scissorsLift
	) as IUtilityStaticTag[];
};
