import React from 'react';
import { getProjectOverviewReportRoute } from '@utils/analysisCenter.utils';
import { RecentReportCard } from '../RecentReportCard';
import { useSelector } from 'react-redux';
import { selectTimezone } from '../../../../../store/slices/project.slice';
import { useHistory } from 'react-router-dom';
import { analysisCenterReportGeneration_BI } from '@utils/bi.utils';
import { IProjectOverviewReport } from '@shared/interfaces/IProjectOverviewReport';
import { DATE_PICKER_TOGGLE } from '@shared/interfaces/DatePickerMode';
import { getDateRangeFormatted } from '@utils/dateUtils';
import { getDateFormattedWithTimeFromTimestamp } from '@shared/utils/dateUtils';
import { translationService } from '@src/servicesInitializers';
import { Languages } from '@shared/constants/languages';

interface IRecentProjectOverviewReportCardProps {
	projectOverviewReport: IProjectOverviewReport;
}

export const RecentProjectOverviewReportCard = (props: IRecentProjectOverviewReportCardProps) => {
	const tz: string = useSelector(selectTimezone)!;
	const history = useHistory();

	const redirectToProjectOverviewReport = () => {
		history.push(getProjectOverviewReportRoute(props.projectOverviewReport._id!));
		analysisCenterReportGeneration_BI(props.projectOverviewReport.investigationType, 'recent', [
			new Date(props.projectOverviewReport.fromDate),
			...(props.projectOverviewReport.toDate ? [new Date(props.projectOverviewReport.toDate)] : []),
		]);
	};

	return (
		<RecentReportCard
			reportId={props.projectOverviewReport._id!}
			dateGenerated={props.projectOverviewReport.dateGenerated}
			investigationReport={props.projectOverviewReport}
			redirectToReport={redirectToProjectOverviewReport}
		>
			<>
				{props.projectOverviewReport.periodPickerMode === DATE_PICKER_TOGGLE.period
					? getDateRangeFormatted(
							new Date(props.projectOverviewReport.fromDate),
							new Date(props.projectOverviewReport.toDate!),
							tz
					  )
					: getDateFormattedWithTimeFromTimestamp(
							new Date(props.projectOverviewReport.fromDate).valueOf(),
							false,
							translationService.getChosenLanguage() === Languages.HEBREW,
							tz,
							translationService.getDateLocale()
					  )}
			</>
		</RecentReportCard>
	);
};
