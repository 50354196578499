import { INotification } from '@shared/interfaces/INotification';
import { requestService, streamService } from '@src/servicesInitializers';
import {
	markNotificationAsViewed,
	updateNotifications,
	removeSnackbar,
	resetNotifications,
} from '@store/slices/notifications.slice';

export const getNotificationsFromStream = (projectId: string, username: string) => async (dispatch) => {
	streamService.openStream(
		`/notification/notifications/stream?projectId=${projectId}&notificationUsername=${username}`,
		(message) => {
			const { notifications }: { notifications: INotification[] } = JSON.parse(message.data);
			dispatch(updateNotifications({ notifications }));
		}
	);
};

export const stopGettingNotifications = () => async (dispatch) => {
	streamService.closeStreamIncludes(`/notification/notifications/stream`);
	dispatch(resetNotifications());
};

export const markAllNotificationsAsViewed = (projectId: string, username: string) => async (dispatch) => {
	await requestService.put(`/notification/notifications/${username}/markAllAsViewed?projectId=${projectId}`);
	dispatch(markNotificationAsViewed());
};

export const removeSnackbarById = (notificationId: string) => async (dispatch) => {
	dispatch(removeSnackbar({ notificationId }));
};
