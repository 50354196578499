import classes from './styles.module.scss';
import React from 'react';
import { TrusstorCheckbox } from '../../../../../TrusstorCheckbox/TrusstorCheckbox';
import classnames from 'classnames';
import { ArrowUpDown } from '../../../../../ArrowUpDown/ArrowUpDown';

interface IDropdownGroupCardProps<T> {
	title: string;
	isSelected: boolean;
	isHighlighted: boolean;
	getGroupDisplayOption?: () => React.ReactNode | undefined;
	handleGroupHover: () => void;
	handleGroupSelect?: (groupKey: string, isChecked: boolean) => void;
	handleGroupClick?: (groupKey: string, isOpen: boolean) => void;
	showCheckbox?: boolean;
	titleClassName?: string;
	secondaryText?: string;
	secondaryTextClassName?: string;
	noBorders?: boolean;
	arrowOpen?: boolean;
}

export function DropdownGroupCard<T>(props: IDropdownGroupCardProps<T>) {
	if (!props.showCheckbox)
		return <div className={classnames(classes.groupTitle, props.titleClassName)}>{props.title}</div>;

	const handleCardSelect = () => {
		props.handleGroupSelect?.(props.title, !props.isSelected);
	};

	return (
		<div
			className={classnames({
				[classes.groupTitle]: true,
				[classes.groupTitleWithCheckbox]: true,
				[classes.highlighted]: props.isHighlighted,
				[classes.withSecondaryText]: props.secondaryText,
				[classes.noBorders]: props.noBorders,
			})}
			onClick={() =>
				props.handleGroupClick ? props.handleGroupClick(props.title, !!props.arrowOpen) : handleCardSelect()
			}
			onMouseEnter={props.handleGroupHover}
		>
			<div className={classes.titleContainer}>
				{props.showCheckbox && <TrusstorCheckbox checked={props.isSelected} onChange={handleCardSelect} />}
				<div className={classes.groupDisplayOption}>{props.getGroupDisplayOption?.() || props.title}</div>
			</div>
			<div className={classes.detailsContainer}>
				{props.secondaryText && (
					<div
						className={classnames(classes.secondaryText, {
							[props.secondaryTextClassName!]: !!props.secondaryTextClassName,
						})}
					>
						{props.secondaryText}
					</div>
				)}
				{props.handleGroupClick && <ArrowUpDown horizontalClosed isOpen={!!props.arrowOpen} />}
			</div>
		</div>
	);
}
