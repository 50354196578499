import React, { useState } from 'react';
import classes from './styles.module.scss';
import { IMenuItem, TrusstorMenu } from '@shared/components/TrusstorMenu/TrusstorMenu';
import { translationService } from '@src/servicesInitializers';
import { TrusstorIcon } from '@src/Components/TrusstorIcon/TrusstorIcon';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { IconSize } from '@shared/components/TrusstorIconShared/TrusstorIconShared';
import { useOutsideClick } from '@src/hooks/custom.hooks';
import classnames from 'classnames';
import { TrusstorIconButtonDeprecated } from '@shared/components/buttons/TrusstorIconButton/TrusstorIconButtonDeprecated';

interface ISequenceOptionsMenu {
	deleteSequence: () => void;
	createNewSequence: () => void;
}

export const SequenceOptionsMenu = (props: ISequenceOptionsMenu) => {
	const [menuElement, setMenuElement] = useState<HTMLElement | null>(null);

	const [showDropdown, setShowDropdown] = useState<boolean>(false);

	const handleOnClick = (callback: () => void) => {
		setShowDropdown(false);
		callback();
	};

	const deleteMenuItem: IMenuItem = {
		title: translationService.get('delete'),
		iconComponent: <TrusstorIcon iconName={IconNames.trash} size={IconSize.SMALL} />,
		onClick: () => handleOnClick(props.deleteSequence),
		borderTop: true,
	};

	const addNew: IMenuItem = {
		title: translationService.get('addNew'),
		iconComponent: <TrusstorIcon iconName={IconNames.plus} size={IconSize.SMALL} />,
		onClick: () => handleOnClick(props.createNewSequence),
		borderTop: true,
	};

	const isRtl: boolean = translationService.getIsRtl();

	useOutsideClick({ current: menuElement }, setShowDropdown, false);

	return (
		<div style={{ position: 'relative' }}>
			<div
				onClick={() => setShowDropdown((prev) => !prev)}
				className={classnames(classes.SequenceOptionsMenu_container, { [classes.showDropdown]: showDropdown })}
			>
				<TrusstorIconButtonDeprecated
					iconElement={
						<TrusstorIcon iconName={IconNames.dotsHorizontal} size={IconSize.MEDIUM} defaultColor />
					}
				/>
			</div>
			<div
				style={{
					...(isRtl ? { left: 0 } : { right: 0 }),
				}}
				ref={setMenuElement}
				className={classes.dropdownContainer}
			>
				{showDropdown && <TrusstorMenu width={170} items={[deleteMenuItem, addNew]} />}
			</div>
		</div>
	);
};
