import { ITableComponentProps } from '@interfaces/ITableComponentProps';
import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { selectProjectId } from '@store/slices/project.slice';
import classes from '../styles.module.scss';
import { ISequenceItem } from '@interfaces/ISequenceItem';
import { ProfessionDisplayWithTradeIcon } from '@shared/components/ProfessionDisplayWithTradeIcon/ProfessionDisplayWithTradeIcon';

export const ProfessionCell = (
	props: ITableComponentProps<ISequenceItem>
): ReactElement<ITableComponentProps<ISequenceItem>> => {
	const projectId: string = useSelector(selectProjectId)!;
	const sequenceItem: ISequenceItem = props.data;

	return (
		<div className={classes.professionCell}>
			<ProfessionDisplayWithTradeIcon profession={sequenceItem.profession} projectId={projectId} />
		</div>
	);
};
