import React from 'react';
import classes from './styles.module.scss';
import { progressTrackerIcon } from '@src/assets';
import { translationService } from '@src/servicesInitializers';
import {
	ITrusstorButtonSize,
	ITrusstorButtonType,
	TrusstorButton,
} from '@shared/components/buttons/TrusstorButton/TrusstorButton';
import { TrusstorIcon } from '@src/Components/TrusstorIcon/TrusstorIcon';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { useHistory } from 'react-router-dom';
import { selectProjectId } from '@store/slices/project.slice';
import { useSelector } from 'react-redux';

export const NoSequencesEmptyState = () => {
	const projectId: string = useSelector(selectProjectId)!;
	const history = useHistory();

	const handleClick = () => {
		history.push(`/${projectId}/workplan/progressTracker`);
	};

	return (
		<div className={classes.NoSequencesEmptyState_container} data-testid="NoSequencesEmptyState">
			<img src={progressTrackerIcon} alt="NoSequencesEmptyState" className={classes.image} />
			<div className={classes.texts}>
				<div className={classes.title}>{translationService.get('milestone_page_empty_state_title')}</div>
				<div className={classes.description}>
					{translationService.get('milestone_page_empty_state_description')}
				</div>
			</div>
			<TrusstorButton
				handleClick={handleClick}
				buttonType={ITrusstorButtonType.OUTLINE}
				buttonSize={ITrusstorButtonSize.SMALL}
				iconElement={<TrusstorIcon iconName={IconNames.plus} />}
				text={translationService.get('milestone_page_empty_state_add_sequence_button')}
			/>
		</div>
	);
};
