import React from 'react';
import classes from './styles.module.scss';
import { TrusstorCheckbox } from '../../TrusstorCheckbox/TrusstorCheckbox';
import { IconColor, IconSize, TrusstorIconShared } from '../../TrusstorIconShared/TrusstorIconShared';
import { IconNames } from '../../TrusstorIconShared/IconNames.enum';
import classnames from 'classnames';
import TrusstorTooltip from '../../TrusstorTooltip';
import { COLORS } from '../../../constants/colors.constants';

export interface IFilterCardProps {
	isSelected: boolean;
	handleClick?: (e: React.MouseEvent) => void;
	startComponent?: React.ReactNode;
	startComponentClass?: string;
	endComponent?: React.ReactNode;
	icon?: IconNames;
	indentationLevel?: number;
	iconColor?: IconColor;
	text?: string;
	textClass?: string;
	count?: number;
	countColor?: COLORS;
	totalColor?: COLORS;
	countTotal?: number;
	additionalTestId?: string;
}

export const FilterCard = (props: IFilterCardProps) => {
	const [textRef, setTextRef] = React.useState<HTMLDivElement | null>(null);
	const [textContainerRef, setTextContainerRef] = React.useState<HTMLDivElement | null>(null);
	const [isTextOverflowed, setIsTextOverflowed] = React.useState<boolean>(false);

	React.useEffect(() => {
		if (textRef && textContainerRef && !isTextOverflowed) {
			const scrollWidth = textRef.scrollWidth;
			const clientWidth = textContainerRef.clientWidth;
			setIsTextOverflowed(clientWidth < scrollWidth);
		}
	}, [textRef, textContainerRef]);

	return (
		<div
			className={classnames({ [classes.filterCardContainer]: true, [classes.selected]: props.isSelected })}
			onClick={props.handleClick}
			data-testid={`cardClickable&selected=${!!props.isSelected}${
				props.additionalTestId ? `&id=${props.additionalTestId}` : ''
			}`}
		>
			<div
				className={classes.startContainer}
				style={props.indentationLevel ? { paddingInlineStart: `${props.indentationLevel * 32}px` } : {}}
			>
				{props.handleClick && <TrusstorCheckbox checked={props.isSelected} propagateEvent />}
				<div className={classnames(props.startComponentClass)} style={{ overflow: 'hidden' }}>
					{props.startComponent}
				</div>
				{props.icon && (
					<TrusstorIconShared
						iconName={props.icon}
						color={props.iconColor}
						size={IconSize.SMALL}
						className={classes.icon}
					/>
				)}
				{props.text && (
					<div style={{ overflow: 'hidden' }}>
						<TrusstorTooltip text={props.text} showAlways={isTextOverflowed}>
							<div style={{ maxWidth: '100%', overflow: 'hidden' }} ref={setTextContainerRef}>
								<div
									ref={setTextRef}
									className={classnames({
										[classes.filterCardText]: !props.textClass,
										[props.textClass || '']: props.textClass,
										[classes.overflowed]: isTextOverflowed,
									})}
								>
									{props.text}
								</div>
							</div>
						</TrusstorTooltip>
					</div>
				)}
			</div>
			<div className={classes.endContainer}>
				{props.endComponent}
				{props.count !== undefined && (
					<div className={classes.countContainer}>
						<span style={{ color: props.countColor }}>{props.count}</span>
						<span style={{ color: props.totalColor }}>
							{props.countTotal ? `/${props.countTotal}` : ''}
						</span>
					</div>
				)}
			</div>
		</div>
	);
};
