import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { orderBy } from 'lodash';
import { textStyle } from '@shared/utils/text.utils';
import { translationService } from '../../index';
import { TrusstorBox } from '../Boxes/Containers/TrusstorBox';
import { SearchBar } from '@src/Components/Inputs/SearchBar/SearchBar';
import {
	COLORS,
	MANAGERS_TABLE_COLUMN_WIDTH,
	MANAGERS_TABLE_COLUMN_WIDTH_RESPONSIVE,
	TableRowTypes,
} from '../../constants';
import { IManager, ITableTitleItems } from '../../interfaces';
import { CreateManagerDialog } from './CreateManagerDialog';
import { ISortingState } from '../../interfaces/ISortingState';
import { TableTitle } from '../TableComponents/TableTitle/TableTitle';
import { ManagerChip } from '../Chips/ManagerChip/ManagerChip';
import { IRowProperty } from '../../interfaces/IRowProperty';
import { HTTPRequestStatuses } from '@shared/interfaces/HTTPRequestStatuses.enum';
import { useSelector } from 'react-redux';
import { IRootState } from '../../store/slices';
import { MainTableRow } from '../TableComponents/TableRow/MainTableRow';
import { messageIcon } from '../../assets';
import { TrusstorButton } from '@shared/components/buttons/TrusstorButton/TrusstorButton';
import { useIsNarrowLayout } from '../../hooks/custom.hooks';
import { SORT_ORDER } from '@shared/constants/constants';
import { getTradeTranslation } from '@utils/translations.utils';

const useStyles = makeStyles((theme) => ({
	listContainer: {
		width: '100%',
		height: '91%',
		minHeight: '435px',
		borderRadius: 3,
		boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2)',
		backgroundColor: COLORS.white,
		overflow: 'hidden',
	},
	headerContainer: {
		display: 'flex',
		paddingInlineStart: '24px',
		width: '100%',
	},
	header: {
		display: 'flex',
		marginLeft: theme.spacing(1),
		flexWrap: 'nowrap',
	},
	rowsContainer: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		height: 'calc(100% - 42px)',
		overflowY: 'auto',
		flexWrap: 'nowrap',
	},
	tableWrapper: {
		height: `calc(100% - 14px)`,
	},
	searchAndButtonContainer: {
		height: 48,
		width: '100%',
		marginBottom: theme.spacing(1),
	},
	arrow: {
		width: 14,
		height: 14,
	},
	tableContainer: {
		height: '100%',
		flexWrap: 'nowrap',
	},
	placeholderText: textStyle({
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		opacity: 0.5,
		textAlign: 'center',
		fontSize: '20px',
		lineHeight: '29.38px',
	}),
}));

const managersRolesTitles: ITableTitleItems = {
	role: { title: 'role' },
	fullName: { title: 'fullName' },
	phoneNumber: { title: 'phoneNumber' },
	display: { title: 'display', disableSort: true },
};

interface IManagersProps {
	managers: IManager[];
	onSearchInputChange: (text: string) => void;
	isSearchInputExist: boolean;
}

const ManagersListTable = (props: IManagersProps) => {
	const [showManagerCreateDialog, setShowManagerCreateDialog] = useState<boolean>(false);
	const managersRequestStatus: HTTPRequestStatuses = useSelector(
		(state: IRootState) => state.managersReducer.managersRequestStatus
	);
	const classes = useStyles({
		language: translationService.getChosenLanguage(),
	});
	const handleOpenLinkManagerDialog = () => {
		setShowManagerCreateDialog(true);
	};
	const handleCloseLinkManagerDialog = () => {
		setShowManagerCreateDialog(false);
	};
	const isNarrowLayout = useIsNarrowLayout();

	const [sortingState, setSortingState] = useState<ISortingState>({
		isSorted: true,
		columnName: 'role',
		sortOrder: SORT_ORDER.ASCENDING,
	});

	const updateSortingState = (newState: Partial<ISortingState>) => {
		setSortingState((prevState) => ({
			...prevState,
			...newState,
		}));
	};

	const [sortedManagers, setSortedManagers] = useState<IManager[]>([]);
	const [isDefaultSort, setIsDefaultSort] = useState<boolean>(true);

	const sortColumnByOrder = useCallback(() => {
		const sortParameter: string = managersRolesTitles[sortingState.columnName].title;
		const currentSortOrder: ISortingState['sortOrder'] = sortingState.sortOrder;
		const lastSeenSortOrder: ISortingState['sortOrder'] = SORT_ORDER.DESCENDING;

		switch (sortParameter) {
			case managersRolesTitles.role.title:
				setSortedManagers(orderBy(props.managers, [`tradeId`], [currentSortOrder, lastSeenSortOrder]));
				return;
			case managersRolesTitles.fullName.title:
				setSortedManagers(orderBy(props.managers, [`name`], [currentSortOrder, lastSeenSortOrder]));
				return;
			case managersRolesTitles.phoneNumber.title:
				setSortedManagers(
					orderBy(props.managers, [(manager) => Number(manager[sortParameter])], [currentSortOrder])
				);
				return;
			default:
				setSortedManagers(
					orderBy(props.managers, [(manager: IManager) => manager[sortParameter] || ''], [currentSortOrder])
				);
		}
	}, [sortingState, props.managers]);

	useEffect(() => {
		if (props.managers.length === 0) {
			setSortedManagers([]);
			return;
		}

		if (isDefaultSort) {
			const sortedManagers: IManager[] = orderBy(
				props.managers,
				['sortIndex', 'name'],
				[SORT_ORDER.ASCENDING, SORT_ORDER.ASCENDING]
			);
			setSortedManagers(sortedManagers);
			setSortingState({
				isSorted: true,
				columnName: managersRolesTitles.role.title,
				sortOrder: SORT_ORDER.ASCENDING,
			});
			setIsDefaultSort(false);
		} else {
			sortColumnByOrder();
		}
	}, [props.managers, sortColumnByOrder, isDefaultSort]);

	useEffect(() => {
		sortColumnByOrder();
	}, [sortingState, sortColumnByOrder]);

	const rowProperties: IRowProperty[] = [
		{
			getTextToDisplay: (manager: IManager) => {
				return getTradeTranslation(manager);
			},
		},
		{ firstField: 'name' },
		{ firstField: 'phoneNumber' },
	];

	return (
		<React.Fragment>
			<CreateManagerDialog show={showManagerCreateDialog} close={handleCloseLinkManagerDialog} />
			<TrusstorBox customStyleClass={classes.tableWrapper}>
				<Grid container direction="column" className={classes.tableContainer}>
					<Grid
						item
						container
						className={classes.searchAndButtonContainer}
						justify="space-between"
						alignItems="center"
					>
						<div>
							<SearchBar
								handleChangeInput={props.onSearchInputChange}
								placeholder={translationService.get('searchByFullNameRole')}
							/>
						</div>
						<TrusstorButton
							text={translationService.get('addManager')}
							handleClick={handleOpenLinkManagerDialog}
						/>
					</Grid>
					<Grid container className={classes.listContainer}>
						<div className={classes.headerContainer}>
							<div className={classes.header}>
								{Object.entries(managersRolesTitles).map(([title, value]) => {
									return (
										<TableTitle
											key={title}
											columnWidth={
												isNarrowLayout
													? MANAGERS_TABLE_COLUMN_WIDTH_RESPONSIVE
													: MANAGERS_TABLE_COLUMN_WIDTH
											}
											sortingState={sortingState}
											disableSort={value.disableSort}
											currentColumn={value.title}
											updateSortingState={updateSortingState}
										/>
									);
								})}
							</div>
						</div>

						<div className={classes.rowsContainer}>
							{sortedManagers.length > 0
								? sortedManagers.map((manager) => {
										return (
											<MainTableRow
												object={manager}
												type={TableRowTypes.MANAGER}
												rowProperties={rowProperties}
												key={`tableRow-${manager._id}`}
												columnWidth={
													isNarrowLayout
														? MANAGERS_TABLE_COLUMN_WIDTH_RESPONSIVE
														: MANAGERS_TABLE_COLUMN_WIDTH
												}
												lastRowComponent={
													<ManagerChip
														key={`tableChip-${manager._id}`}
														chipText={manager.name}
														chipBackground={manager.backgroundColor}
													/>
												}
												icon={messageIcon}
												shouldDisplayIcon={manager.receiveNotifications}
											/>
										);
								  })
								: managersRequestStatus === HTTPRequestStatuses.success &&
								  props.managers.length === 0 && (
										<p className={classes.placeholderText}>
											{props.isSearchInputExist
												? translationService.get('noMatchingValues')
												: translationService.get('noRolesAssignedMessage')}
										</p>
								  )}
						</div>
					</Grid>
				</Grid>
			</TrusstorBox>
		</React.Fragment>
	);
};

export { ManagersListTable };
