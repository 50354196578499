import { IRoundedProfessionProductivityReport } from '@interfaces/IRoundedProfessionProductivityReport';
import { IFloor } from '@interfaces/index';
import { orderBy } from 'lodash';

export const sumProfessionsProductivityByFloor = (
	reports: IRoundedProfessionProductivityReport[]
): {
	[floorId: string]: {
		totalHours: number;
	};
} => {
	return reports.reduce(
		(acc, report) => {
			Object.entries(report.floors).forEach(([floorId, floorReport]) => {
				if (!acc[floorId]) {
					acc[floorId] = {
						totalHours: 0,
					};
				}
				acc[floorId].totalHours += floorReport.totalHours;
			});
			return acc;
		},
		{} as { [floorId: string]: { totalHours: number } }
	);
};

export const sortFloorsByTotalHours = (
	floors: IFloor[],
	floorsTotalHours: { [floorId: string]: { totalHours: number } }
): IFloor[] => {
	return orderBy(floors, (floor) => floorsTotalHours[floor.floorId]?.totalHours || 0, 'desc');
};
