import { IUniqueTagIdsPerDay } from '../interfaces/IActivitiesReport';
import {
	IBaseProductivityFloorReport,
	IDailyProductivityFloorReport,
	IPeriodicProductivityFloorReport,
	IProductivityProfessionReport,
} from '../interfaces/IProductivityReport';
import { sumBy, uniq } from 'lodash';
import { ILineGraphData } from '../Components/Pages/Reports/ReportsComponents/Charts/interfaces/ILineGraphData';
import { IRoundedProductivityProfessionReport } from '../interfaces/IRoundedProductivityReport';

export const getProfessionAvgWorkersNumber = (productivityProfessionReport: IRoundedProductivityProfessionReport) => {
	const mergedFloorsUniqueTagIdsPerDay: IUniqueTagIdsPerDay = Object.values(
		productivityProfessionReport.floors
	).reduce((mergedFloorsUniqueTagIdsPerDay: IUniqueTagIdsPerDay, floorReport: IBaseProductivityFloorReport) => {
		Object.entries((floorReport as IPeriodicProductivityFloorReport).uniqueTagIdsPerDay).forEach(
			([formattedDate, tagIds]: [string, string[]]) => {
				mergedFloorsUniqueTagIdsPerDay[formattedDate] = uniq([
					...(mergedFloorsUniqueTagIdsPerDay[formattedDate] ?? []),
					...tagIds,
				]);
			}
		);
		return mergedFloorsUniqueTagIdsPerDay;
	}, {});

	return getAvgWorkersFromUniqueTagIdsPerDay(mergedFloorsUniqueTagIdsPerDay);
};

export const getAvgWorkersFromUniqueTagIdsPerDay = (uniqueTagIdsPerDay: IUniqueTagIdsPerDay) => {
	const sumOfWorkersForThisPeriod: number = sumBy(
		Object.values(uniqueTagIdsPerDay),
		(dayTagIds: string[]) => dayTagIds.length
	);
	const workDays: number = Object.keys(uniqueTagIdsPerDay).length;
	return sumOfWorkersForThisPeriod / workDays;
};

export const getTotalUniqueWorkersInProfessionProductivityReport = (
	professionProductivityReport: IProductivityProfessionReport | IRoundedProductivityProfessionReport
) => {
	return uniq(
		Object.values(professionProductivityReport.floors).flatMap(
			(reportFloor) => (reportFloor as IDailyProductivityFloorReport).tagIds
		)
	).length;
};

export const getApexTickAmountOption = (dateGraph: any[], maxGraphTicks: number): number | 'dataPoints' | undefined => {
	if (!dateGraph?.length) {
		return undefined;
	}

	const numberOfPoints = dateGraph.length;
	return numberOfPoints > maxGraphTicks ? maxGraphTicks : 'dataPoints';
};

export const getLastDatePointToShow = (dateGraph: ILineGraphData[], maxGraphTicks: number): undefined | number => {
	if (!dateGraph?.length) {
		return undefined;
	}

	const numberOfPoints = dateGraph.length;
	const lastDatePointToShow: string =
		dateGraph.length > maxGraphTicks ? dateGraph[maxGraphTicks].label : dateGraph[numberOfPoints - 1].label;

	return new Date(lastDatePointToShow).getTime();
};

export const getFirstDatePointToShow = (dateGraph: ILineGraphData[]): undefined | number => {
	if (!dateGraph.length) {
		return undefined;
	}

	return new Date(dateGraph[0].label).getTime();
};

export const getProfessionTotalWorkDays = (
	professionReport: IRoundedProductivityProfessionReport,
	maxTotalDays: number
): number => {
	const floorsUniqueTagIdsPerDay: IUniqueTagIdsPerDay[] = Object.values(professionReport.floors).map(
		(floorReport: IPeriodicProductivityFloorReport | IDailyProductivityFloorReport) =>
			(floorReport as IPeriodicProductivityFloorReport).uniqueTagIdsPerDay
	);
	const mergedUniqueTagIdsPerDay: { [key: string]: string } = {};
	floorsUniqueTagIdsPerDay.forEach((floorUniqueTagIdsPerDay) => {
		Object.keys(floorUniqueTagIdsPerDay).forEach((date) => {
			if (!mergedUniqueTagIdsPerDay[date]) {
				mergedUniqueTagIdsPerDay[date] = 'dayToCount';
			}
		});
	});
	const numberOfWorkDaysInPeriod: number = Object.keys(mergedUniqueTagIdsPerDay).length;
	return Math.min(numberOfWorkDaysInPeriod, maxTotalDays);
};
