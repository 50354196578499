import { IIssuesSortingSettings } from '../interfaces/IIssuesSortingSettings';

export enum IssuesPageViewModes {
	PROFESSIONS = 'professions',
	ASSIGNEES = 'assignees',
}

type ViewModeToSortingKeyType = {
	[key in IssuesPageViewModes]: keyof Pick<IIssuesSortingSettings, 'byProfession' | 'byAssignee'>;
};

export const ViewModeToSortingKey: ViewModeToSortingKeyType = {
	[IssuesPageViewModes.PROFESSIONS]: 'byProfession',
	[IssuesPageViewModes.ASSIGNEES]: 'byAssignee',
};
