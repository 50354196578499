import React, {
	createContext,
	SetStateAction,
	useCallback,
	useEffect,
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
	accessLevelService,
	requestService,
	streamService,
	translationService,
	workplanLocalStorageService,
} from '../../../index';
import { DatePickerSection } from './DatePickerSection/DatePickerSection';
import { WorkPlanDialog } from '../../WorkPlan/WorkPlanDialog/WorkPlanDialog';
import { FloorsSelectorSection } from '../Reports/ReportsComponents/ReportsSections/FloorsSelectorSection';
import { updateVisibleWeek } from '../../../store/slices/datePicker.slice';
import {
	ICalendarSection,
	IProfession,
	IUser,
	IWeekDayActivity,
	IWorkPlanActivities,
	IWorkPlanActivity,
} from '@interfaces/index';
import { ToggleSection } from '../../WorkPlan/ToggleSection';
import {
	APP_FILTER_TYPES,
	CalendarToggleOptions,
	SIDE_MAIN_COMPONENT_CLASS_NAME,
	WEEKLY_WORK_PLAN_FILE_NAME,
	WorkplanViewTypes,
} from '../../../constants';
import { GeneralAlertDialog } from '../../GeneralAlertDialog/GeneralAlertDialog';
import { useCalendarSectionsState } from '../../../hooks/calendar.hooks';
import { exportCalendarToExcel, getLabelsFromWorkPlanActivities } from '@utils/calendar.utils';
import { Calendar } from '../../Calendar/Calendar/Calendar';
import { generateTimezoneMultipleWeekDatesRange, getTimezoneFormattedDate } from '@utils/dateUtils';
import { IRootState } from '@store/slices';
import { ProfessionsFilterSection } from '../../FilterSections/ProfessionsFilterSection';
import { HTTPRequestStatuses } from '@shared/interfaces/HTTPRequestStatuses.enum';
import { useWorkplanFirstAccess_BI } from '../../../hooks/bi.hooks';
import {
	calendarExportToExcel_BI,
	workplanCalendarTabAccess_BI,
	workplanOpenPlanning_BI,
	workplanProgressTrackerTabAccess_BI,
	workplanToggleFloorsProfessions_BI,
} from '@utils/bi.utils';
import { BIEventNames } from '@interfaces/BI/BIEventNames.enum';
import { IActivityLabel } from '@interfaces/IActivityLabel';
import { useHistory } from 'react-router-dom';
import { IFloor } from '@shared/interfaces/IFloor';
import { ActivityGroupStatusesFilterSection } from '../../FilterSections/ActivityGroupStatusesFilterSection';
import plusIcon from '@shared/assets/icons/plus.svg';
import calendarIcon from '@assets/workplanIcons/calendarIcon.svg';
import progressTrackerIcon from '@assets/workplanIcons/progressTrackerIcon.svg';
import planningIcon from '@assets/workplanIcons/planningIcon.svg';
import classes from './styles.module.scss';
import {
	useProjectAreasBySequenceType,
	useSequenceSequenceItemsByProjectQuery,
} from '@src/hooks/queries/sequenceItem.query';
import {
	ProgressTrackerTab,
	TableModeEnum,
} from '@src/Components/Pages/Workplan/ProgressTrackerTab/ProgressTrackerTab';
import { PlanningMenu } from '@src/Components/Pages/Workplan/PlanningMenu/PlanningMenu';
import { IMergedAreaSequenceItem } from '@interfaces/IMergedAreaSequenceItem';
import { ISequenceMatrixTableData } from '../../WorkPlan/ActivityProgressTracker/components/ActivitySequenceMatrix/ActivitySequenceMatrix';
import { compact, debounce, Dictionary, flatten, groupBy, mapValues, sortBy, uniqBy } from 'lodash';
import { IConfigFloor } from '../../../interfaces/IConfigFloor';
import { countAreaSequenceItemsDoneFromRow } from '../../../utils/sequence.utils';
import { sortByAreas, sortFloors } from '../../../../../shared/utils/sort.utils';
import { IConfigArea } from '../../../interfaces/IConfigArea';
import { IActivitySequenceItemCellData } from '../../WorkPlan/ActivityProgressTracker/components/ActivitySequenceMatrix/cellComponents/ActivitySequenceItemCell/ActivitySequenceItemCell';
import { useAreaSequenceItems } from '@src/Components/WorkPlan/areaSequenceItems.hook';
import {
	ITrusstorButtonSize,
	ITrusstorButtonType,
	TrusstorButton,
} from '@shared/components/buttons/TrusstorButton/TrusstorButton';
import { ExportSection } from './ProgressTrackerTab/ExportSection/ExportSection';
import { ISequence } from '@shared/interfaces/ISequence';
import { SequenceTypeEnum } from '@shared/interfaces/SequenceType.enum';
import { useProjectSequencesQuery } from '@src/hooks/queries/sequences.hooks';
import { ISequenceItem } from '@interfaces/ISequenceItem';
import { selectActivityGroupSelectedStatuses } from '@store/slices/ActivityGroupStatuses.slice';
import { useOutsideClick } from '@src/hooks/custom.hooks';
import { IActivityGroupStatusFilterItem } from '@interfaces/IActivityGroupStatusFilterItem';
import { TrusstorMenu } from '@shared/components/TrusstorMenu/TrusstorMenu';
import { errorSnackbar, successSnackbar } from '@utils/snackbar.utils';
import { FiltersToggle } from '@src/Components/Pages/Workplan/FiltersToggle/FiltersToggle';
import { useFilters } from '@src/hooks/useFilter.hook';
import classNames from 'classnames';
import classnames from 'classnames';
import { isAsiComplete } from '@shared/utils/asi.utils';
import { useRenderMonitoring } from '@shared/hooks/monitoring.hooks';
import { SORT_ORDER } from '@shared/constants/constants';
import { UsersFilterSection } from '@src/Components/FilterSections/UsersFilterSection';
import {
	selectActiveUsers,
	selectSelectedUsers,
	selectStaticUsers,
	updateActiveUsers,
} from '@store/slices/users.slice';
import { IIssueAssignee } from '@shared/interfaces/IIssueShared';
import { selectWorkingProfessions } from '@store/slices/professions.slice';
import { SequencesFilterSection } from '@src/Components/FilterSections/SequencesFilterSection';
import { addActiveSequences, selectSelectedSequences } from '@store/slices/sequences.slice';
import { useSetActiveFilterType } from '@src/hooks/useSetActiveFilterType.hooks';
import { progressAnalysis } from '@shared/assets/icons';
import { useIsFeatureFlagEnabled } from '@shared/hooks/useFeatureFlag';
import { FeatureFlagNamesEnum } from '@shared/constants/featureFlags.enum';
import { Loader } from '@shared/components/Loader/Loader';
import { getFakeDisabledAsi } from '@utils/areaSequenceItem.utils';

export interface IWorkplanContext {
	hasProfessionFilter: boolean;
	setShowFilters: React.Dispatch<SetStateAction<boolean>>;
	showFilters: boolean;
	selectedSequenceId?: string;
	setSelectedSequenceId: React.Dispatch<SetStateAction<string | undefined>>;
	isTableFinishToRender: boolean;
	matrix: {
		tables: ISequenceMatrixTableData[] | undefined;
		setTables: React.Dispatch<SetStateAction<ISequenceMatrixTableData[] | undefined>>;
		recreateTable: (floorId: string, areaSequenceItem: IMergedAreaSequenceItem[]) => void;
		createTableData: (
			floor: IConfigFloor,
			areaSequenceItems?: IMergedAreaSequenceItem[]
		) => ISequenceMatrixTableData;
		floorsList: IConfigArea[];
		areasList: IConfigArea[];
	};
	areaSequenceItemsByFloor: Dictionary<IMergedAreaSequenceItem[] | null> | undefined;
	setAreaSequenceItemsByFloor: (areaSequenceItems: IMergedAreaSequenceItem[]) => void;
	updateAreaSequenceItem: (areaSequenceItem: IMergedAreaSequenceItem) => void;
	shouldMatrixFetchAllFloors: boolean;
	setShouldMatrixFetchAllFloors: React.Dispatch<SetStateAction<boolean>>;
	fetchMoreAreaSequenceItems: (currentFloorId: string) => Promise<void>;
	onActivityUpdate: (updatedActivity: IWeekDayActivity, isDeleted?: boolean) => void;
	sequenceItems: ISequenceItem[];
	setSequenceItems: React.Dispatch<SetStateAction<ISequenceItem[]>>;
	sequences: ISequence[];
	setSequences: React.Dispatch<React.SetStateAction<ISequence[]>>;
	isInitialLoading: boolean;
	refetchSequenceItems: () => void;
}

export type ScreenTopScrollPosition = {
	[key in TableModeEnum]?: number;
};

interface IPlanningContext {
	areaSequenceItemsSelected: IMergedAreaSequenceItem[];
	setAreaSequenceItemsSelected: React.Dispatch<SetStateAction<IMergedAreaSequenceItem[]>>;
	matrixTopScrollPosition: ScreenTopScrollPosition | undefined;
	setMatrixTopScrollPosition: React.Dispatch<SetStateAction<ScreenTopScrollPosition | undefined>>;
}

export const WorkplanContext = createContext<IWorkplanContext | undefined>(undefined);
export const PlanningContext = createContext<IPlanningContext | undefined>(undefined);

const WorkPlan = ({ match }) => {
	useRenderMonitoring('WorkPlan');
	const dispatch = useDispatch();
	const [showFilters, setShowFilters] = useState<boolean>(false);
	const [nonClickableCellDialogOpen, setNonClickableCellDialogOpen] = useState<boolean>(false);
	const [deletedProfessionDialogOpen, setDeletedProfessionDialogOpen] = useState<boolean>(false);
	const [areaSequenceItemsSelected, setAreaSequenceItemsSelected] = useState<IMergedAreaSequenceItem[]>([]);
	const [matrixTopScrollPosition, setMatrixTopScrollPosition] = useState<ScreenTopScrollPosition | undefined>(
		undefined
	);
	const [areaSequenceItemsByFloor, setAreaSequenceItemsByFloor] = useState<
		Dictionary<IMergedAreaSequenceItem[] | null> | undefined
	>(undefined);
	const [tables, setTables] = useState<ISequenceMatrixTableData[] | undefined>(undefined);

	const [nonManagersDialogOpen, setNonManagersDialogOpen] = useState<boolean>(false);
	const [deletedProfessionAndManagerDialogOpen, setDeletedProfessionAndManagerDialogOpen] = useState<boolean>(false);
	const [toggleStateProfessionsFloors, setToggleStateProfessionsFloors] = useState<CalendarToggleOptions>(
		CalendarToggleOptions.PROFESSIONS
	);
	const [workplanViewType, setWorkplanViewType] = useState<WorkplanViewTypes>(
		workplanLocalStorageService.getWorkplanViewType() || WorkplanViewTypes.CALENDAR
	);
	const matchParamsDateFrom: string | undefined = match.params.dateFrom;

	const matchParamsCalendarViewType: string | undefined = match.params.viewType;

	useEffect(() => {
		if (!matchParamsCalendarViewType || matchParamsCalendarViewType === '') {
			return;
		}
		if (matchParamsCalendarViewType) {
			setWorkplanViewType(WorkplanViewTypes.PROGRESS_TRACKER);
		} else {
			setWorkplanViewType(WorkplanViewTypes.CALENDAR);
		}
	}, [matchParamsCalendarViewType]);

	useEffect(() => {
		workplanLocalStorageService.setWorkplanViewType(workplanViewType);
	}, [workplanViewType]);

	const projectId: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.projectId);
	const tz: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.tz);
	const { sequences: fetchedSequences, isInitialLoading, refetchSequences } = useProjectSequencesQuery(projectId);
	const [sequences, setSequences] = useState<ISequence[]>(fetchedSequences);
	const [selectedSequenceId, setSelectedSequenceId] = useState<string>();
	const { sequenceItems: fetchedSequenceItems, refetchSequenceItems } = useSequenceSequenceItemsByProjectQuery(
		projectId,
		selectedSequenceId
	);
	const [areasSequenceItemsFloors, setAreasSequenceItemsFloors] = useState<IConfigArea[]>([]);
	const [sequenceItems, setSequenceItems] = useState<ISequenceItem[]>(fetchedSequenceItems);
	const selectedSequenceType: SequenceTypeEnum | undefined = sequences.find(
		(item) => item._id === selectedSequenceId
	)?.sequenceType;

	const { areas: areaSequenceItemsAreas } = useProjectAreasBySequenceType(
		projectId,
		selectedSequenceType || sequenceItems[0]?.sequenceType
	);
	const [workPlanActivities, setWorkPlanActivities] = useState<IWorkPlanActivities | undefined>();
	const [isFirstTimeLoadedActivities, setIsFirstTimeLoadedActivities] = useState<boolean>(false);
	const floorsList: IFloor[] = useSelector((state: IRootState) => state.floorsListReducer.floorsList);
	const professions: IProfession[] = useSelector(selectWorkingProfessions);
	const visibleDateRangeTimestamps: number[] = useSelector(
		(state: IRootState) => state.datePickerReducer.visibleDateRangeTimestamps
	);
	const selectedUsers: IUser[] = useSelector(selectSelectedUsers);
	const staticUsers: IUser[] = useSelector(selectStaticUsers);
	const activeUsers: IUser[] = useSelector(selectActiveUsers);
	const selectedSequences: ISequence[] = useSelector(selectSelectedSequences);
	const floorsFilterList: IFloor[] = useSelector((state: IRootState) => state.floorsListReducer.floorsFilterList);
	const workingProfessionRequestStatus: HTTPRequestStatuses = useSelector(
		(state: IRootState) => state.professionsReducer.workingProfessionsRequestStatus
	);
	const workingProfessionRequestCount: number = useSelector(
		(state: IRootState) => state.professionsReducer.workingProfessionsRequestCount
	);
	const visibleFloorsList: IFloor[] = useSelector((state: IRootState) => state.floorsListReducer.visibleFloorsList);
	const visibleProfessions: IProfession[] = useSelector(
		(state: IRootState) => state.professionsReducer.visibleProfessions
	);
	const selectedActivityGroupStatuses: IActivityGroupStatusFilterItem[] = useSelector(
		selectActivityGroupSelectedStatuses
	);
	const [numberOfWeeks, setNumberOfWeeks] = useState<number>(1);
	const [isActivityDialogOpen, setIsActivityDialogOpen] = useState<boolean>(false);
	const [isPlanningDialogOpen, setIsPlanningDialogOpen] = useState<boolean>(false);
	const [isPlanningDialogMinimized, setIsPlanningDialogMinimized] = useState<boolean>(false);
	const [isPlanningMode, setIsPlanningMode] = useState<boolean>(false);
	const [currentActivity, setCurrentActivity] = useState<IWorkPlanActivity>();
	const [activitySection, setActivitySection] = useState<{
		key: string;
		value: any;
	}>();
	const [activityStartDate, setActivityStartDate] = useState<Date>();
	const [visibleLabels, setVisibleLabels] = useState<IActivityLabel[]>([]);
	const [matchDateFrom, setMatchDateFrom] = useState<Date | undefined>(undefined);
	const [shouldMatrixFetchAllFloors, setShouldMatrixFetchAllFloors] = useState<boolean>(false);
	const [showExportOptionsDropdown, setShowExportOptionsDropdown] = useState<boolean>(false);

	const hasProfessionFilter: boolean = useMemo(() => {
		return visibleProfessions.length !== professions.length;
	}, [visibleProfessions.length, professions.length]);

	useEffect(() => {
		setSequences(fetchedSequences);
	}, [fetchedSequences]);

	useEffect(() => {
		setSequenceItems(fetchedSequenceItems);
	}, [fetchedSequenceItems]);

	useEffect(() => {
		setTables(undefined);
	}, [areaSequenceItemsAreas.length]);

	useEffect(() => {
		if (fetchedSequences.length > 0 && !selectedSequenceId) {
			const sequenceToSelect: ISequence =
				fetchedSequences.find((sequence: ISequence) => sequence.sequenceType === SequenceTypeEnum.APARTMENT) ||
				fetchedSequences[0];

			setSelectedSequenceId(sequenceToSelect._id);
		}
	}, [fetchedSequences]);

	useEffect(() => {
		if (fetchedSequences.length > 0) {
			const sequenceToSelect: ISequence =
				fetchedSequences.find((sequence: ISequence) => sequence.sequenceType === SequenceTypeEnum.APARTMENT) ||
				fetchedSequences[0];

			setSelectedSequenceId(sequenceToSelect._id);
		}
	}, [fetchedSequences?.[0]?.projectId]);

	const { fetchMore, updateAreaSequenceItem, isTableFinishToRender, setAreaSequenceItemsByFloorWithRef } =
		useAreaSequenceItems(
			areasSequenceItemsFloors,
			areaSequenceItemsByFloor,
			setAreaSequenceItemsByFloor,
			shouldMatrixFetchAllFloors,
			selectedSequenceId
		);

	const history = useHistory();

	const didRequestsSucceed = workingProfessionRequestStatus === HTTPRequestStatuses.success;

	useWorkplanFirstAccess_BI(tz, workPlanActivities);

	useSetActiveFilterType(APP_FILTER_TYPES.status);

	useEffect(() => {
		return () => {
			onClosePlanningMode();
			setToggleStateProfessionsFloors(CalendarToggleOptions.PROFESSIONS);
		};
	}, []);

	useFilters({ useVisibleWeek: true });

	const workPlanSections: ICalendarSection[] = useCalendarSectionsState({
		toggleStateProfessionsFloors,
		floorsList,
		professions,
		projectId,
	});

	useEffect(() => {
		if (
			!visibleDateRangeTimestamps ||
			!visibleDateRangeTimestamps.length ||
			workplanViewType !== WorkplanViewTypes.CALENDAR
		) {
			return;
		}
		const firstVisibleDayTimestamp: number = visibleDateRangeTimestamps[0];
		const lastVisibleDayTimestamp: number = visibleDateRangeTimestamps[visibleDateRangeTimestamps.length - 1];
		getWorkPlanActivities(
			projectId,
			getTimezoneFormattedDate(tz, firstVisibleDayTimestamp),
			getTimezoneFormattedDate(tz, lastVisibleDayTimestamp),
			toggleStateProfessionsFloors
		);

		return () => {
			stopGettingActivities();
		};
	}, [projectId, visibleDateRangeTimestamps, toggleStateProfessionsFloors, dispatch, workplanViewType]);

	useEffect(() => {
		if (matchParamsDateFrom === 'undefined' || !matchParamsDateFrom) {
			return;
		}
		setWorkplanViewType(WorkplanViewTypes.CALENDAR);
		const dateFrom: Date = moment.tz(matchParamsDateFrom, tz).toDate();
		setMatchDateFrom(dateFrom);
	}, [matchParamsDateFrom]);

	const handleToggleStateProfessionsFloors = (newToggleOption: CalendarToggleOptions): void => {
		setToggleStateProfessionsFloors(newToggleOption);
	};

	const stopGettingActivities = () => {
		streamService.closeStreamIncludes('/activities/stream/v2');
	};

	const closeCreateWorkplanDialog = () => {
		setIsActivityDialogOpen(false);
		setActivityStartDate(undefined);
		setActivitySection(undefined);
		setCurrentActivity(undefined);
	};

	const openCreateActivityDialog = (workPlanDialogStartDate: Date, sectionFullValue: IProfession | IFloor) => {
		if (moment.tz(workPlanDialogStartDate, tz).isBefore(moment.tz(tz), 'day')) {
			return;
		}
		setActivityStartDate(workPlanDialogStartDate);

		const sectionKeyName: string =
			toggleStateProfessionsFloors === CalendarToggleOptions.FLOORS ? 'floor' : 'profession';
		setActivitySection({ key: sectionKeyName, value: sectionFullValue });
		setIsActivityDialogOpen(true);
	};

	const openEditActivityDialog = async (groupId: string) => {
		const currentActivity: IWorkPlanActivity = await requestService.get(`/activities/groups/${groupId}`);

		currentActivity && setCurrentActivity(currentActivity);
		setIsActivityDialogOpen(true);
	};

	const handleExportToExcel = (isFormatted = false) => {
		try {
			setShowExportOptionsDropdown(false);
			calendarExportToExcel_BI(BIEventNames.workplanExportToExcel);
			exportCalendarToExcel(
				isFormatted,
				projectId,
				workPlanSections,
				visibleDateRangeTimestamps,
				toggleStateProfessionsFloors,
				translationService.get(WEEKLY_WORK_PLAN_FILE_NAME),
				tz,
				selectedUsers,
				visibleProfessions,
				visibleFloorsList,
				visibleLabels,
				selectedActivityGroupStatuses,
				selectedSequences
			)
				.then(() => {
					successSnackbar(dispatch, translationService.get('exportSuccess'));
				})
				.catch((e) => {
					console.log(e, '-> exportError');
					errorSnackbar(dispatch, translationService.get('exportError'));
				});
		} catch (error) {
			console.error(error);
		}
	};

	const openNonClickableCellDialog = () => {
		setNonClickableCellDialogOpen(true);
	};

	const openDeletedManagersAndProfessionDialog = () => {
		setDeletedProfessionAndManagerDialogOpen(true);
	};

	const openDeletedProfessionDialog = () => {
		setDeletedProfessionDialogOpen(true);
	};

	const closeDeletedManagersAndProfessionDialog = () => {
		setDeletedProfessionAndManagerDialogOpen(false);
	};
	const closeNonClickableCellDialog = () => {
		setNonClickableCellDialogOpen(false);
	};

	const closeDeletedProfessionDialog = () => {
		setDeletedProfessionDialogOpen(false);
	};

	const closeNonManagersDialog = () => {
		setNonManagersDialogOpen(false);
	};

	const goToSystemProfessions = () => {
		history.push('/system/professions');
	};

	const goToSystemManagement = () => {
		history.push('/system/management');
	};

	const checkOpenCreateActivityDialog = (
		workplanSection: ICalendarSection,
		dayClicked: Date,
		isCalendarCellClickable: boolean
	) => {
		const sectionFullValue: IProfession | IFloor | undefined =
			toggleStateProfessionsFloors === CalendarToggleOptions.FLOORS
				? floorsList.find((floor) => floor.floorId === workplanSection.id)
				: professions.find((profession) => profession._id === workplanSection.id);

		if (isCalendarCellClickable && sectionFullValue) {
			if ((sectionFullValue as IProfession).dateDeleted) {
				openDeletedManagersAndProfessionDialog();
				return;
			}
			if ((sectionFullValue as IProfession).dateDeleted) {
				openDeletedProfessionDialog();
				return;
			}
			openCreateActivityDialog(moment(new Date(dayClicked)).toDate(), sectionFullValue);
			return;
		}
		openNonClickableCellDialog();
	};

	const createTableData = (floor: IConfigFloor): ISequenceMatrixTableData => {
		const commonHeader: string = floor.floorNick;
		const id: string = floor.floorId;
		const areaSequenceItemsOfFloor: IMergedAreaSequenceItem[] | undefined | null =
			areaSequenceItemsByFloor?.[floor.floorId];

		const floorAreas: IConfigArea[] = areaSequenceItemsAreas.filter(
			(area: IConfigArea) => area.floorId === floor.floorId
		);
		const sortedAreas: IConfigArea[] = sortByAreas(floorAreas, SORT_ORDER.ASCENDING);
		const rowHeadersWithoutCounters: { rowTitle: string; doneItems?: number; totalItems?: number }[] =
			sortedAreas.map((area: IConfigArea) => ({
				rowTitle: area.areaNick,
			}));

		if ((!areaSequenceItemsOfFloor && areaSequenceItemsOfFloor !== null) || !sequenceItems.length) {
			return {
				isEmpty: true,
				rows: rowHeadersWithoutCounters.map(() => []),
				rowHeaders: rowHeadersWithoutCounters,
				commonHeader,
				id,
			};
		}

		const groupedAreaSequenceItems: Dictionary<IMergedAreaSequenceItem[]> = groupBy(
			areaSequenceItemsOfFloor,
			'area.areaNick'
		);
		const rowHeadersWithCounters: { rowTitle: string; doneItems?: number; totalItems: number }[] =
			rowHeadersWithoutCounters.map(({ rowTitle }) => {
				return countAreaSequenceItemsDoneFromRow(rowTitle, groupedAreaSequenceItems);
			});

		const rows: IActivitySequenceItemCellData[][] = sortedAreas.map((currArea: IConfigArea) => {
			const rowItems: IMergedAreaSequenceItem[] | undefined = groupedAreaSequenceItems[currArea.areaNick];

			if (rowItems?.length === sequenceItems.length) {
				return rowItems;
			}

			const sortedSequenceItems: ISequenceItem[] = sortBy(
				sequenceItems,
				(seqItem: ISequenceItem) => seqItem.orderIndex
			);
			const rowItemsWithDisabledCells = sortedSequenceItems.map((seqItem: ISequenceItem) => {
				const matchingAsi: IMergedAreaSequenceItem | undefined = rowItems?.find(
					(asi: IMergedAreaSequenceItem) => asi.sequenceItemId === seqItem._id
				);
				if (matchingAsi) {
					return matchingAsi;
				}

				return getFakeDisabledAsi(seqItem, currArea);
			});

			return rowItemsWithDisabledCells;
		});
		return { rows, rowHeaders: rowHeadersWithCounters, commonHeader, id };
	};

	const recreateTable = useCallback(
		(floorId: string, updatedAreaSequenceItems: IMergedAreaSequenceItem[]) => {
			const areaSequenceItemsOfFloor: IMergedAreaSequenceItem[] | undefined | null =
				areaSequenceItemsByFloor?.[floorId];
			if (!areaSequenceItemsOfFloor) {
				return;
			}

			const prevTable: ISequenceMatrixTableData | undefined = tables?.find(
				(table: ISequenceMatrixTableData) => table.id === floorId
			);
			if (!prevTable) {
				return;
			}

			setTables((prevTables) => {
				return [
					...prevTables!.map((table: ISequenceMatrixTableData) => {
						if (table.id === floorId) {
							const rowIndexToAmountToAddToDoneCounter: Dictionary<number> = {};
							const updatedRows: IActivitySequenceItemCellData[][] = table?.rows?.map(
								(row: IMergedAreaSequenceItem[], rowIndex: number) => {
									let amountToAddToDoneCounterOfRow: number = 0;
									const updatedRow = row.map((item: IMergedAreaSequenceItem) => {
										const updatedItem = updatedAreaSequenceItems.find(
											(updatedItem: IMergedAreaSequenceItem) => updatedItem._id === item._id
										);
										if (!updatedItem) {
											return item;
										}

										if (isAsiComplete(updatedItem.status) && !isAsiComplete(item.status)) {
											amountToAddToDoneCounterOfRow++;
										}
										if (!isAsiComplete(updatedItem.status) && isAsiComplete(item.status)) {
											amountToAddToDoneCounterOfRow--;
										}

										return updatedItem;
									});

									rowIndexToAmountToAddToDoneCounter[rowIndex] = amountToAddToDoneCounterOfRow;
									return updatedRow;
								}
							);

							const updatedRowHeaders: { rowTitle: string; doneItems?: number; totalItems?: number }[] =
								table?.rowHeaders?.map(
									(
										rowHeader: { rowTitle: string; doneItems?: number; totalItems?: number },
										rowIndex: number
									) => {
										const amountToAddToDoneCounterOfRow: number =
											rowIndexToAmountToAddToDoneCounter[rowIndex] || 0;
										return {
											...rowHeader,
											doneItems: (rowHeader.doneItems || 0) + amountToAddToDoneCounterOfRow,
										};
									}
								);

							const newTable: ISequenceMatrixTableData = {
								...table!,
								rows: updatedRows,
								rowHeaders: updatedRowHeaders,
							};
							return newTable;
						}
						return table;
					}),
				];
			});
		},
		[areasSequenceItemsFloors, areaSequenceItemsByFloor, tables, setTables]
	);

	useEffect(() => {
		const areasGroupedByFloors: Dictionary<IConfigArea[]> = groupBy(
			areaSequenceItemsAreas,
			(area: IConfigArea) => area.floorId
		);
		const floorsList: IConfigArea[] = Object.values(areasGroupedByFloors).map((areas: IConfigArea[]) => areas[0]);
		const sortedFloorList: IConfigArea[] = sortFloors(floorsList, SORT_ORDER.ASCENDING);
		setAreasSequenceItemsFloors(sortedFloorList);
	}, [areaSequenceItemsAreas]);

	const openCreateActivityDialogCleanState = () => {
		setActivityStartDate(new Date());
		setIsActivityDialogOpen(true);
	};

	const handleEditActivityClick = (isCalendarCellClickable: boolean, groupId: string) => {
		openEditActivityDialog(groupId);
	};

	const shouldDisplayEmptyState = () =>
		workingProfessionRequestCount === 1 && didRequestsSucceed && professions.length === 0;

	const shouldDisplayWorkplan = () => workingProfessionRequestCount > 1 || professions.length > 0;

	const activityLabels: IActivityLabel[] = useMemo(() => {
		return getLabelsFromWorkPlanActivities<IWeekDayActivity>(workPlanActivities ?? {});
	}, [workPlanActivities]);

	const getWorkPlanActivities = (projectId: string, fromDate: string, toDate: string, sortBy: string) => {
		streamService.closeStreamIncludes('/activities/stream/v2');
		const activitiesProfessionCount: number | undefined = !workPlanActivities
			? undefined
			: Object.keys(workPlanActivities).length;
		openFetchActivitiesStream(activitiesProfessionCount, projectId, fromDate, toDate, sortBy);
	};

	const areStreamUpdatesEnabled = useRef<boolean>(true);
	const debounceEnabledStreamUpdates = useMemo(() => {
		return debounce(() => {
			areStreamUpdatesEnabled.current = true;
		}, 60000);
	}, []);

	const openFetchActivitiesStream = (
		prevActivitiesProfessionsLength: number | undefined,
		projectId: string,
		fromDate: string,
		toDate: string,
		sortBy: string
	) => {
		const projectIdFilter: string = `projectId=${projectId}`;
		const dateFilter: string = `fromDate=${fromDate}&toDate=${toDate}`;
		const sortByFilter: string = `sortBy=${sortBy}`;
		areStreamUpdatesEnabled.current = true;
		streamService.openStream(
			`/activities/stream/v2?${projectIdFilter}&${dateFilter}&${sortByFilter}`,
			(res: MessageEvent) => {
				if (!areStreamUpdatesEnabled.current) {
					return;
				}

				const activities = JSON.parse(res.data, (key: string, value: string) =>
					key === 'startDate' || key === 'endDate' ? new Date(value) : value
				);
				setWorkPlanActivities(activities);
				handleIsFirstTimeLoadedActivities(prevActivitiesProfessionsLength);
			}
		);
	};

	const onActivityUpdate = (updatedActivity: IWeekDayActivity, isDeleted?: boolean): void => {
		areStreamUpdatesEnabled.current = false;
		debounceEnabledStreamUpdates();
		setWorkPlanActivities((prevActivities: IWorkPlanActivities | undefined) => {
			if (!prevActivities) {
				return prevActivities;
			}

			const workplanActivities: IWorkPlanActivities = mapValues(
				prevActivities,
				(sectionActivities: IWeekDayActivity[][]) => {
					const updatedSectionActivities = sectionActivities.map((dayActivities: IWeekDayActivity[]) => {
						if (isDeleted) {
							return dayActivities.filter((activity: IWeekDayActivity) => {
								return activity.groupId !== updatedActivity.groupId;
							});
						}

						return dayActivities.map((activity: IWeekDayActivity) => {
							return activity.groupId === updatedActivity.groupId ? updatedActivity : activity;
						});
					});

					return updatedSectionActivities;
				}
			);

			return workplanActivities;
		});
	};

	const handleIsFirstTimeLoadedActivities = (prevActivitiesProfessionsLength): void => {
		if (isFirstTimeLoadedActivities) {
			setIsFirstTimeLoadedActivities(false);
			return;
		}

		if (!prevActivitiesProfessionsLength && prevActivitiesProfessionsLength !== 0) {
			setIsFirstTimeLoadedActivities(true);
		}
	};

	const handleProgressTrackerViewTypeClick = useCallback(() => {
		workplanProgressTrackerTabAccess_BI();
		setWorkplanViewType(WorkplanViewTypes.PROGRESS_TRACKER);
	}, []);

	const handleCalendarViewTypeClick = useCallback(() => {
		workplanCalendarTabAccess_BI();
		setWorkplanViewType(WorkplanViewTypes.CALENDAR);
	}, []);

	const handleOpenPlanningDialog = useCallback(() => {
		if (!isPlanningMode) {
			setAreaSequenceItemsSelected([]);
			workplanOpenPlanning_BI();
			setIsPlanningMode(true);
		}
		if (workplanViewType !== WorkplanViewTypes.PROGRESS_TRACKER) {
			setWorkplanViewType(WorkplanViewTypes.PROGRESS_TRACKER);
		}
		setIsPlanningDialogOpen(true);
		setIsPlanningDialogMinimized(false);
	}, [isPlanningMode, workplanViewType]);

	const onMinimizingPlanningMenu = useCallback(() => {
		setIsPlanningDialogMinimized(true);
	}, []);

	const onClosePlanningMode = useCallback(() => {
		setIsPlanningMode(false);
		setIsPlanningDialogOpen(false);
		setIsPlanningDialogMinimized(false);
	}, []);

	const getDefaultValueByActivitiesProfessionLength = (): string | undefined => {
		if (!isFirstTimeLoadedActivities) {
			return undefined;
		}
		const shouldDisplayFloorView: boolean | undefined =
			workPlanActivities &&
			Object.keys(workPlanActivities).length <= 3 &&
			Object.keys(workPlanActivities).length !== 0;

		if (shouldDisplayFloorView) {
			return CalendarToggleOptions.FLOORS;
		}
	};

	const setDateToToday = () => {
		const today: Date = moment.tz(tz).toDate();
		const todayDateRange: Date[] = generateTimezoneMultipleWeekDatesRange(today, tz, numberOfWeeks);
		dispatch(
			updateVisibleWeek({
				visibleDateRangeTimestamps: todayDateRange.map((date) => date.getTime()),
			})
		);
	};

	const isCalendarViewType: boolean = workplanViewType === WorkplanViewTypes.CALENDAR;
	const isProgressTrackerViewType: boolean = workplanViewType === WorkplanViewTypes.PROGRESS_TRACKER;
	const isSequenceProgressAnalysisEnabled: boolean = useIsFeatureFlagEnabled(
		FeatureFlagNamesEnum.sequenceProgressAnalysis
	);

	const fetchWorkplanActivities = async (
		projectId: string,
		fromDate: string,
		toDate: string,
		sortBy: string
	): Promise<void> => {
		const projectIdFilter: string = `projectId=${projectId}`;
		const dateFilter: string = `fromDate=${fromDate}&toDate=${toDate}`;
		const sortByFilter: string = `sortBy=${sortBy}`;
		const workplanActivities: IWorkPlanActivities = await requestService.get(
			`/activities/v2?${projectIdFilter}&${dateFilter}&${sortByFilter}`
		);
		const activities = JSON.parse(JSON.stringify(workplanActivities), (key: string, value: string) =>
			key === 'startDate' || key === 'endDate' ? new Date(value) : value
		);
		setWorkPlanActivities(activities);
	};

	const handleActivityDialogChange = async (): Promise<void> => {
		areStreamUpdatesEnabled.current = false;
		debounceEnabledStreamUpdates();

		const firstVisibleDayTimestamp: number = visibleDateRangeTimestamps[0];
		const lastVisibleDayTimestamp: number = visibleDateRangeTimestamps[visibleDateRangeTimestamps.length - 1];
		await fetchWorkplanActivities(
			projectId,
			getTimezoneFormattedDate(tz, firstVisibleDayTimestamp),
			getTimezoneFormattedDate(tz, lastVisibleDayTimestamp),
			toggleStateProfessionsFloors
		);
	};

	const isRtl: boolean = translationService.getIsRtl();
	const exportMenuWrapperRef = useRef(null);
	useOutsideClick(exportMenuWrapperRef, setShowExportOptionsDropdown, false);

	const hasActiveFilters: boolean =
		selectedActivityGroupStatuses.length > 0 ||
		hasProfessionFilter ||
		activeUsers.length !== staticUsers.length ||
		!!floorsFilterList.length;

	const setFilteredAssignees = (users: IIssueAssignee[]) => {
		dispatch(
			updateActiveUsers({
				activeUsers: users,
			})
		);
	};

	const activeAssignees: IUser[] = useMemo(() => {
		const activities: IWeekDayActivity[] = flatten(Object.values(workPlanActivities || {})).flat();
		const activitiesAssignees = uniqBy(activities, (activity) => activity.assignee).map((issue) =>
			staticUsers.find((user) => user.username === issue.assignee)
		);
		return compact(activitiesAssignees);
	}, [workPlanActivities, staticUsers]);

	const goToSequenceProgressAnalysis = () => {
		history.push(`/${projectId}/workplan/projectProgressAnalysis`);
	};

	useEffect(() => {
		if (activeAssignees.length > 0) {
			setFilteredAssignees(activeAssignees);
		}
	}, [activeAssignees]);

	const activeSequences: ISequence[] = useMemo(() => {
		const activities: IWeekDayActivity[] = flatten(Object.values(workPlanActivities || {})).flat();
		const activeSequences: (ISequence | undefined)[] = uniqBy(
			activities,
			(activity) => activity.linkedSequenceId
		).map((activity) => sequences.find((sequence) => sequence._id === activity.linkedSequenceId));
		return compact(activeSequences);
	}, [workPlanActivities, sequences]);

	useLayoutEffect(() => {
		dispatch(addActiveSequences({ sequences: activeSequences }));
	}, [activeSequences]);

	return (
		<React.Fragment>
			<WorkplanContext.Provider
				value={{
					hasProfessionFilter,
					setShowFilters,
					showFilters,
					isInitialLoading,
					setSequences,
					sequences,
					refetchSequenceItems,
					sequenceItems,
					setSequenceItems,
					selectedSequenceId,
					setSelectedSequenceId,
					isTableFinishToRender,
					matrix: {
						tables,
						setTables,
						recreateTable,
						areasList: areaSequenceItemsAreas,
						floorsList: areasSequenceItemsFloors,
						createTableData,
					},
					areaSequenceItemsByFloor,
					setAreaSequenceItemsByFloor: setAreaSequenceItemsByFloorWithRef,
					updateAreaSequenceItem,
					shouldMatrixFetchAllFloors,
					setShouldMatrixFetchAllFloors,
					fetchMoreAreaSequenceItems: fetchMore,
					onActivityUpdate,
				}}
			>
				<PlanningContext.Provider
					value={{
						areaSequenceItemsSelected,
						setAreaSequenceItemsSelected,
						matrixTopScrollPosition,
						setMatrixTopScrollPosition,
					}}
				>
					<div className={classes.container}>
						{isActivityDialogOpen && (
							<WorkPlanDialog
								closeDialog={closeCreateWorkplanDialog}
								onChangeData={handleActivityDialogChange}
								activity={currentActivity}
								section={activitySection!}
								activityStartDate={activityStartDate!}
								areaSequenceItemMode={!!currentActivity?.linkedSequenceType}
							/>
						)}
						{isPlanningDialogOpen && (
							<PlanningMenu
								isMinimized={isPlanningDialogMinimized}
								onMinimize={onMinimizingPlanningMenu}
								onClose={onClosePlanningMode}
							/>
						)}

						{shouldDisplayWorkplan() && (
							<div className={classes.bottomContainer}>
								<div className={classes.tableContainer}>
									<div className={classes.workplanHeader}>
										<div className={classes.upperSide}>
											<div className={classes.workplanMenu}>
												<div className={classes.workplanText}>
													{translationService.get('workPlanTitle')}
												</div>
												<div
													className={`${classes.menuItem} ${
														isCalendarViewType ? classes.selected : ''
													}`}
													onClick={handleCalendarViewTypeClick}
												>
													<img
														src={calendarIcon}
														alt="calendar"
														className={isCalendarViewType ? '' : classes.unSelectedIcon}
													/>
													<div
														className={`${classes.menuItemText} ${
															isCalendarViewType ? classes.selected : ''
														}`}
													>
														{translationService.get('calendar')}
													</div>
												</div>
												<div
													className={`${classes.menuItem} ${
														isProgressTrackerViewType ? classes.selected : ''
													}`}
													onClick={handleProgressTrackerViewTypeClick}
												>
													<img
														src={progressTrackerIcon}
														alt="progress"
														className={
															isProgressTrackerViewType ? '' : classes.unSelectedIcon
														}
													/>
													<div
														className={`${classes.menuItemText} ${
															isProgressTrackerViewType ? classes.selected : ''
														}`}
													>
														{translationService.get('progressTracker')}
													</div>
												</div>
											</div>

											<div className={classes.wokrplanActions}>
												{isSequenceProgressAnalysisEnabled &&
													accessLevelService.hasAccess('projectProgressAnalysisAccess') && (
														<div
															className={classes.sequenceAnalysisButton}
															onClick={goToSequenceProgressAnalysis}
														>
															<img className={classes.image} src={progressAnalysis} />
															{translationService.get('seeProjectProgressAnalysis')}
														</div>
													)}
												{professions.length > 0 &&
													accessLevelService.hasAccess('workplanActivityActions') && (
														<div className={classes.createActivityText}>
															<TrusstorButton
																handleClick={openCreateActivityDialogCleanState}
																text={translationService.get('createAdHocActivity')}
																startIcon={plusIcon}
																iconClassName={classes.createActivityIcon}
																className={classes.createActivityButton}
																buttonType={ITrusstorButtonType.SECONDARY}
																testId="activity-create-btn"
																buttonSize={ITrusstorButtonSize.SMALL}
															/>
														</div>
													)}
												{sequenceItems.length > 0 &&
													accessLevelService.hasAccess('workplanActivityActions') &&
													(isPlanningMode ? (
														<div
															className={classes.planningCounter}
															onClick={handleOpenPlanningDialog}
														>
															{areaSequenceItemsSelected.length}
														</div>
													) : (
														<TrusstorButton
															handleClick={handleOpenPlanningDialog}
															text={translationService.get('planning')}
															startIcon={planningIcon}
															className={classes.planningButton}
															testId="planning-btn"
															buttonSize={ITrusstorButtonSize.SMALL}
														/>
													))}
											</div>
										</div>
										{!isProgressTrackerViewType && (
											<div className={classes.bottomSide}>
												<div className={classes.bottomSideLeft}>
													<FiltersToggle
														hasActiveFilters={hasActiveFilters}
														isOpen={showFilters}
														toggleFilterSection={() => setShowFilters((prev) => !prev)}
													/>
													<div
														className={classes.today}
														onClick={() => setDateToToday()}
														data-testid={'todayButton'}
													>
														{translationService.get('today')}
													</div>
													<DatePickerSection
														week
														numberOfWeeks={numberOfWeeks}
														startDate={matchDateFrom}
													/>
													<div
														className={classnames(classes.oneTwoWeekContainer, {
															[classes.isRTL]: isRtl,
														})}
													>
														<div
															onClick={() => setNumberOfWeeks(1)}
															data-testid={'workplan-one-week-range'}
															className={classnames(classes.weekText, classes.first, {
																[classes.selected]: numberOfWeeks === 1,
																[classes.isRTL]: isRtl,
															})}
														>
															{translationService.get('oneWeek')}
														</div>

														<div
															onClick={() => setNumberOfWeeks(2)}
															data-testid={'workplan-two-week-range'}
															className={classnames(classes.weekText, classes.second, {
																[classes.selected]: numberOfWeeks === 2,
																[classes.isRTL]: isRtl,
															})}
														>
															2 {translationService.get('weeks')}
														</div>
														<div
															onClick={() => setNumberOfWeeks(4)}
															data-testid={'workplan-four-week-range'}
															className={classnames(
																classes.weekText,
																classes.third,
																classes.isLast,
																{
																	[classes.selected]: numberOfWeeks === 4,
																	[classes.isRTL]: isRtl,
																}
															)}
														>
															4 {translationService.get('weeks')}
														</div>
													</div>
												</div>
												<div
													className={classNames({
														[classes.absoluteCenterLtr]: !translationService.getIsRtl(),
														[classes.absoluteCenterRtl]: translationService.getIsRtl(),
													})}
												>
													<ToggleSection
														value={
															getDefaultValueByActivitiesProfessionLength() ||
															toggleStateProfessionsFloors
														}
														firstOption={CalendarToggleOptions.PROFESSIONS}
														secondOption={CalendarToggleOptions.FLOORS}
														firstAction={() =>
															handleToggleStateProfessionsFloors(
																CalendarToggleOptions.PROFESSIONS
															)
														}
														secondAction={() =>
															handleToggleStateProfessionsFloors(
																CalendarToggleOptions.FLOORS
															)
														}
														biEventOnClick={workplanToggleFloorsProfessions_BI}
													/>
												</div>

												<ExportSection
													disableSnackbars
													onExport={async () => setShowExportOptionsDropdown(true)}
												/>
												<div
													ref={exportMenuWrapperRef}
													className={classes.exportMenuWrapper}
													style={{
														left: isRtl ? 0 : 'initial',
														right: isRtl ? 'initial' : 0,
													}}
												>
													{showExportOptionsDropdown && (
														<TrusstorMenu
															width={170}
															items={[
																{
																	title: translationService.get(
																		'exportFormattedCalendar'
																	),
																	onClick: () => handleExportToExcel(true),
																	testId: 'exportFormattedCalendar',
																},
																{
																	title: translationService.get('exportRawCalendar'),
																	onClick: () => handleExportToExcel(false),
																	testId: 'exportRawCalendar',
																},
															]}
														/>
													)}
												</div>
											</div>
										)}
									</div>
									<div className={classes.bottomContainer}>
										{isCalendarViewType && (
											<div style={{ display: 'flex', width: '100%' }}>
												{showFilters && (
													<div
														className={`${SIDE_MAIN_COMPONENT_CLASS_NAME}`}
														style={{
															boxShadow: 'none',
															overflow: 'scroll',
														}}
													>
														{!workPlanActivities ? (
															<Loader />
														) : (
															<>
																<ActivityGroupStatusesFilterSection />
																<SequencesFilterSection />
																<ProfessionsFilterSection
																	dontResetSelectedProfessions
																/>
																<UsersFilterSection />
																<FloorsSelectorSection
																	floors={floorsList}
																	handleResetClick={() => {}}
																	currentFilter={[]}
																	shouldUseReducer
																/>
															</>
														)}
													</div>
												)}
												{workplanViewType === WorkplanViewTypes.CALENDAR && (
													<Calendar
														onEditDialogActionComplete={handleActivityDialogChange}
														activities={workPlanActivities}
														sections={workPlanSections}
														handleEditActivityClick={handleEditActivityClick}
														checkOpenCreateDialog={checkOpenCreateActivityDialog}
														toggleStateProfessionsFloors={toggleStateProfessionsFloors}
														visibleLabels={visibleLabels}
													/>
												)}
											</div>
										)}

										{workplanViewType === WorkplanViewTypes.PROGRESS_TRACKER && (
											<ProgressTrackerTab planningMode={isPlanningMode} />
										)}
									</div>
								</div>
							</div>
						)}
						{shouldDisplayEmptyState() && (
							<div className={classes.noContentSection}>
								<p className={classes.noContentMessage}>
									{translationService.get('noContentWorkplanMessage')}
								</p>
								<img className={classes.icon} src={calendarIcon} alt="calendarIcon" />
							</div>
						)}
					</div>
					<GeneralAlertDialog
						show={deletedProfessionAndManagerDialogOpen}
						close={closeDeletedManagersAndProfessionDialog}
						onSubmitButtonClick={closeDeletedManagersAndProfessionDialog}
						title={translationService.get('cantCreateActivity')}
						message={''}
						closeButtonText={translationService.get('okGotIt')}
					>
						{
							<p className={classes.alertDialogText}>
								{translationService.get('managersAndProfessionWereDeletedAlertP1')}{' '}
								<span onClick={goToSystemProfessions} className={classes.alertDialogTextLink}>
									{translationService.get('systemManagement')}
								</span>{' '}
								{translationService.get('managersAndProfessionWereDeletedAlertP2')}
							</p>
						}
					</GeneralAlertDialog>
					<GeneralAlertDialog
						show={deletedProfessionDialogOpen}
						close={closeDeletedProfessionDialog}
						onSubmitButtonClick={closeDeletedProfessionDialog}
						title={translationService.get('cantCreateActivity')}
						message={''}
						closeButtonText={translationService.get('okGotIt')}
					>
						{
							<p className={classes.alertDialogText}>
								{translationService.get('deletedProfessionDialogMessageP1')}{' '}
								<span onClick={goToSystemProfessions} className={classes.alertDialogTextLink}>
									{translationService.get('professionsModule')}
								</span>{' '}
								{translationService.get('deletedProfessionDialogMessageP2')}
							</p>
						}
					</GeneralAlertDialog>
					<GeneralAlertDialog
						show={nonManagersDialogOpen}
						close={closeNonManagersDialog}
						onSubmitButtonClick={closeNonManagersDialog}
						title={translationService.get('cantCreateActivity')}
						message={''}
						closeButtonText={translationService.get('okGotIt')}
					>
						{
							<p className={classes.alertDialogText}>
								{translationService.get('managersWereDeletedAlertP1')}{' '}
								<span onClick={goToSystemManagement} className={classes.alertDialogTextLink}>
									{translationService.get('managementModule')}
								</span>{' '}
								{translationService.get('managersWereDeletedAlertP2')}
							</p>
						}
					</GeneralAlertDialog>
					<GeneralAlertDialog
						show={nonClickableCellDialogOpen}
						close={closeNonClickableCellDialog}
						onSubmitButtonClick={closeNonClickableCellDialog}
						title={translationService.get('activityCreation')}
						message={translationService.get('nonClickableWorkplanCellDialogMessage')}
						closeButtonText={translationService.get('okGotIt')}
					/>
				</PlanningContext.Provider>
			</WorkplanContext.Provider>
		</React.Fragment>
	);
};

export { WorkPlan };
