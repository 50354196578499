import { COLORS } from '@shared/constants/colors.constants';

export const sideMainComponentBoxShadow: string =
	'-2px 0px 6px 1px rgba(128, 128, 128, 0.2), 0px -8px 4px 1px rgba(128, 128, 128, 0.2)';
export const sideMainComponentBorder: string = '2px solid rgba(16, 48, 69, 0.2)';
export const regularBorder: string = `0.2px solid ${COLORS.grey100}`;
export const svgSelectedStyle = {
	opacity: '0.8',
	fillOpacity: '0.6',
	fill: '#C8E5F9',
};
