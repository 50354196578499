import React from 'react';
import { BaseNotificationCard } from '../BaseNotificationCard/BaseNotificationCard';
import classes from './styles.module.scss';
import { INotification } from '../../../interfaces/INotification';
import { TranslationService } from '../../../services/translation.service';
import { RequestService } from '../../../services/request.service';
import { IIssueCommentCreatedNotificationMetadata } from '../../../interfaces/IIssueCommentCreatedNotificationMetadata';
import { getUserInitials } from '../../../utils/users.utils';
import { isTrusstorMobile } from '../../../utils/mobile.utils';
import { NotificationPhotoDisplay } from '../NotificationPhotoDisplay/NotificationPhotoDisplay';
import { IssueCommentType } from '../../../interfaces/ICommentShared';
import { StorageService } from '../../../services/storage.service';

interface ICommentAddedCardProps {
	notification: INotification;
	storageService: StorageService;
	translationService: TranslationService;
	tz: string;
	requester: RequestService;
	handleCardClick: (id: string) => void;
}

export const getIssueCommentCreatedTitleElement = (
	metadata: IIssueCommentCreatedNotificationMetadata,
	translationService: TranslationService,
	isDarkTheme?: boolean
): React.ReactNode => {
	const titleClass = `${classes.title} ${isDarkTheme ? classes.titleDarkTheme : ''}`;
	return (
		<div className={titleClass}>
			<strong>{metadata.userDetails.name}</strong>
			{` ${translationService.get('commentedOn')}`} {translationService.get('issue')}
			<strong> {metadata.issueDetails.description}</strong>
		</div>
	);
};

export const getIssuesCommentCreatedDescriptionElement = (
	metadata: IIssueCommentCreatedNotificationMetadata,
	storageService: StorageService,
	isSnackbar?: boolean
): React.ReactNode => {
	const descriptionClass = `${classes.description} ${isSnackbar ? classes.descriptionSnackbar : ''}`;
	if (metadata.comment.content.type === IssueCommentType.IMAGE) {
		return (
			<NotificationPhotoDisplay
				commentId={metadata.comment.commentId}
				issueId={metadata.issueDetails.issueId}
				storageService={storageService}
			/>
		);
	}
	return <div className={descriptionClass}>“{metadata.comment.content.value}”</div>;
};

export const getIssuesCommentCreatedIconElement = (
	metadata: IIssueCommentCreatedNotificationMetadata
): React.ReactNode => {
	return <div className={classes.icon}>{getUserInitials(metadata.userDetails.name)}</div>;
};

export const IssueCommentCreatedCard = (props: ICommentAddedCardProps) => {
	const metadata: IIssueCommentCreatedNotificationMetadata = props.notification
		.metadata as IIssueCommentCreatedNotificationMetadata;

	const handleCommentClick = () => {
		const issueId: string = metadata.issueDetails.issueId;
		if (!issueId || issueId === 'undefined') {
			return;
		}
		props.handleCardClick(issueId);
	};

	return (
		<BaseNotificationCard
			icon={getIssuesCommentCreatedIconElement(metadata)}
			titleElement={getIssueCommentCreatedTitleElement(metadata, props.translationService)}
			descriptionElement={getIssuesCommentCreatedDescriptionElement(metadata, props.storageService)}
			createdAt={props.notification.createdAt}
			tz={props.tz}
			translationService={props.translationService}
			requester={props.requester}
			notificationId={props.notification._id}
			handleCardClick={handleCommentClick}
			isViewed={props.notification.isViewed}
			isClickable={
				!isTrusstorMobile() && !!metadata.issueDetails.issueId && metadata.issueDetails.issueId !== 'undefined'
			}
		/>
	);
};
