import React, { useEffect, useMemo, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SingleVerticalBar } from './SingleVerticalBar';
import { max } from 'lodash';
import { ISingleVerticalBarData } from './interfaces/ISingleVerticalBarData';
import { translationService } from '../../../../../index';
import { textStyle } from '@shared/utils/text.utils';
import { COLORS } from '@shared/constants/colors.constants';

const BAR_MARGIN_LEFT: number = 16;

interface IStyleProps {
	baseLineWidth?: number;
	barWidth?: number;
	marginBetweenBars?: number;
	barsInEdge?: boolean;
	tickInCenter?: boolean;
}

const useStyle = makeStyles((theme) => ({
	multipleBarsContainer: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
	},
	barsLine: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'start',
		alignItems: 'start',
		width: '100%',
		height: '100%',
	},
	singleVerticalBarContainerCustom: ({ barWidth, marginBetweenBars }: IStyleProps) => ({
		marginLeft: marginBetweenBars ?? BAR_MARGIN_LEFT,
		width: barWidth,
		minWidth: barWidth,
	}),
	baseLine: ({ baseLineWidth, barsInEdge, marginBetweenBars }: IStyleProps) => ({
		backgroundColor: COLORS.grey500,
		minWidth: baseLineWidth ?? 'unset',
		height: 1,
		marginTop: 4,
		...(barsInEdge && {
			marginLeft: marginBetweenBars ?? BAR_MARGIN_LEFT,
			marginRight: 17,
		}),
	}),
	categoriesLine: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'start',
		alignItems: 'start',
		width: '100%',
	},
	category: ({ barWidth, tickInCenter, marginBetweenBars }: IStyleProps) =>
		textStyle({
			fontClass: 'body3',
			direction: translationService.getDirection(),
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'center',
			position: 'relative',
			width: '1px !important',
			minWidth: '1px !important',
			background: COLORS.grey500,
			lineHeight: '3.28',
			height: '8px',
			whiteSpace: 'nowrap',
			marginRight: tickInCenter ? barWidth! / 2 : barWidth! - 1,
			...(tickInCenter && { marginLeft: barWidth! / 2 - 1 + (marginBetweenBars ?? BAR_MARGIN_LEFT) }),
		}),
	hiddenCategory: {
		backgroundColor: 'transparent !important',
	},
}));

interface IMultipleVerticalBarsProps {
	dataList: ISingleVerticalBarData[];
	barWidth: number;
	barMinHeight?: number;
	barsContainerStyleClass?: string;
	labelStyleClass?: string;
	tooltipMessage?: string;
	categories?: string[];
	marginBetweenBars?: number;
	barsInEdge?: boolean;
	visibleTickCoefficient?: number;
	numberLabelVisible?: boolean;
	tickInCenter?: boolean;
	baseLineFixWidth?: boolean;
}

const MultipleVerticalBars = (props: IMultipleVerticalBarsProps) => {
	const { visibleTickCoefficient = 1 } = props;
	const singleVerticalBarEl = useRef<HTMLDivElement>(null);
	const [baseLineWidth, setBaseLineWidth] = useState<number>();
	const classes = useStyle({
		barWidth: props.barWidth,
		baseLineWidth: baseLineWidth,
		marginBetweenBars: props.marginBetweenBars,
		barsInEdge: props.barsInEdge,
		tickInCenter: props.tickInCenter,
	});
	const barMaximum: number = useMemo(() => {
		return props.dataList.length > 0
			? Number(max(props.dataList.map((data: ISingleVerticalBarData) => data.barNumber))) || 1
			: 1;
	}, [props.dataList]);

	useEffect(() => {
		if (singleVerticalBarEl.current?.clientWidth && !props.baseLineFixWidth) {
			setBaseLineWidth(
				(singleVerticalBarEl.current?.clientWidth + BAR_MARGIN_LEFT) * props.dataList.length + BAR_MARGIN_LEFT
			);
		}
	}, [props.dataList.length, singleVerticalBarEl.current?.clientWidth]);

	return (
		<div className={`${classes.multipleBarsContainer} ${props.barsContainerStyleClass}`}>
			<div className={classes.barsLine}>
				{props.dataList.map((barData: ISingleVerticalBarData, index) => {
					return (
						<SingleVerticalBar
							key={`multipleVerticalBars_${index}`}
							ref={singleVerticalBarEl}
							barMaximum={barMaximum}
							barWidth={props.barWidth}
							minBarHeight={props.barMinHeight}
							barDataList={[barData]}
							numberLabel={props.numberLabelVisible}
							singleVerticalBarContainerStyleClass={classes.singleVerticalBarContainerCustom}
						/>
					);
				})}
			</div>
			<div className={classes.baseLine} />
			<div className={classes.categoriesLine}>
				{props.categories?.map((category: string, index: number) => {
					const isTickVisible: boolean = index % visibleTickCoefficient === 0;
					return (
						<div
							className={`${classes.singleVerticalBarContainerCustom} ${classes.category} ${
								isTickVisible ? '' : classes.hiddenCategory
							}`}
							key={`multipleVerticalBarsCategories_${category}`}
						>
							{isTickVisible && category}
						</div>
					);
				})}
			</div>
		</div>
	);
};

export { MultipleVerticalBars };
