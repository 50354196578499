export enum ProfessionSettingType {
	TEXT = 'text',
	ICON = 'icon',
}

export interface IProfessionDisplaySettings {
	trade?: ProfessionSettingType;
	specialty?: ProfessionSettingType.TEXT;
	contractor?: ProfessionSettingType.TEXT;
}
