import React, { useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { requestService, translationService } from '../../../../index';
import { GeneralDialog } from '../../../GeneralDialog/GeneralDialog';
import { textStyle } from '@shared/utils/text.utils';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../../../store/slices';
import { IBaseProfession } from '@shared/interfaces/IBaseProfession';
import { COLORS, HttpStatusCode } from '../../../../constants';
import { createProfession } from '../../../../store/thunks/professions.thunks';
import { IProject, ITrade } from '../../../../interfaces';
import { resetProfessionCreateStatus } from '../../../../store/slices/professions.slice';
import { HTTPRequestStatuses } from '@shared/interfaces/HTTPRequestStatuses.enum';
import { IRequestStatus } from '../../../../interfaces/IRequestStatus';
import GroupedAutoCompleteDropdown from '../../../../../../shared/components/Dropdowns/GroupedAutoCompleteDropdown/GroupedAutoCompleteDropdown';
import { orderBy } from 'lodash';
import { ColorPicker } from '../../../Inputs/ColorPicker/ColorPicker';
import { createProfession_BI } from '../../../../utils/bi.utils';
import { errorSnackbar } from '../../../../utils/snackbar.utils';
import { selectProjectId } from '@store/slices/project.slice';
import { TrusstorTextInput } from '@shared/components/Inputs/TrusstorTextInput/TrusstorTextInput';
import { getTradeGroupTranslation, getTradeTranslation } from '@utils/translations.utils';

const useStyles = makeStyles((theme: Theme) => ({
	selectContainer: {
		width: '100%',
	},
	dialogItem: {
		width: '100%',
	},
	descriptionContainer: {
		color: COLORS.black,
	},
	dropdownInputRootClass: {
		'backgroundColor': COLORS.white,
		'borderRadius': 3,
		'boxShadow': '0px 2px 0 0 rgba(0, 0, 0, 0.1)',
		'& .Mui-focused.MuiFormLabel-root': {
			color: '#b3b2b2 !important',
		},
	},
	inputRootClass: {
		'backgroundColor': COLORS.white,
		'borderRadius': 3,
		'boxShadow': '0px 2px 0 0 rgba(0, 0, 0, 0.1)',
		'& .Mui-focused': {
			color: '#b3b2b2 !important',
		},
	},
	descriptionText: textStyle({
		color: '#000000 !important',
		borderBottom: 'none !important',
	}),
	shrinkLabel: {
		color: '#b3b2b2 !important',
	},
	errorWrapper: {
		height: 15,
		marginTop: theme.spacing(0.5),
		marginBottom: theme.spacing(0.5),
	},
	errorText: textStyle({
		fontClass: 'body1',
		color: COLORS.red400,
	}),
	containerError: {},
}));

interface ICreateProfessionProps {
	close: () => void;
}

interface IValidation {
	[fieldName: string]: boolean;
}

interface INewProfessionInput {
	trade: ITrade;
	specialty?: string;
	contractor?: string;
}

const CreateProfessionDialog = (props: ICreateProfessionProps) => {
	const projectId: string = useSelector(selectProjectId)!;
	const [newProfessionInput, setNewProfessionInput] = useState<Partial<INewProfessionInput>>({});
	const [chosenBackgroundColor, setChosenBackgroundColor] = useState<string>('');
	const [validations, setValidations] = useState<IValidation>({});
	const [workersTrades, setWorkersTrades] = useState<ITrade[]>();
	const project: IProject = useSelector((state: IRootState) => state.projectReducer.workingProject!);
	const professionCreateStatus: IRequestStatus = useSelector(
		(state: IRootState) => state.professionsReducer.professionCreateStatus
	);
	const classes = useStyles();
	const dispatch = useDispatch();

	useEffect(() => {
		if (professionCreateStatus.status === HTTPRequestStatuses.success) {
			props.close();
			resetNewProfessionInput();
			dispatch(resetProfessionCreateStatus());
		}
		if (professionCreateStatus.status === HTTPRequestStatuses.failed) {
			errorSnackbar(
				dispatch,
				professionCreateStatus.code === HttpStatusCode.conflict
					? translationService.get('createProfessionDuplicatedError')
					: translationService.get('generalSnackbarError')
			);
			dispatch(resetProfessionCreateStatus());
		}
	}, [professionCreateStatus.status, professionCreateStatus.code]);

	const validateFormFields = (profession: Partial<INewProfessionInput>): boolean => {
		let isProfessionValid = true;
		if (!profession.trade) {
			isProfessionValid = false;
			setValidations((prevState) => ({ ...prevState, trade: true }));
		}
		return isProfessionValid;
	};

	const resetNewProfessionInput = (): void => {
		setValidations({});
		setNewProfessionInput({});
	};

	const handleSubmitButtonClick = (): void => {
		const areFormFieldsValid = validateFormFields(newProfessionInput);
		if (areFormFieldsValid) {
			const fullProfession: IBaseProfession = {
				...newProfessionInput.trade!,
				backgroundColor: chosenBackgroundColor,
				projectId: project.projectId,
				organizationId: project.organizationId,
				activeProjects: {
					[project.projectId]: {
						backgroundColor: chosenBackgroundColor,
					},
				},
			};
			if (newProfessionInput.contractor) {
				fullProfession.contractor = newProfessionInput.contractor;
			}
			if (newProfessionInput.specialty) {
				fullProfession.specialty = newProfessionInput.specialty;
			}
			createProfession_BI(fullProfession, newProfessionInput.trade!, projectId);
			dispatch(createProfession(fullProfession));
		}
	};

	const onTradeChange = (value): void => {
		if (value) {
			setValidations((prevState) => ({ ...prevState, trade: false }));
			setNewProfessionInput((prevState) => ({ ...prevState, trade: value }));
			if (!chosenBackgroundColor) {
				setChosenBackgroundColor(value.backgroundColor);
			}
			return;
		}
		setValidations((prevState) => ({ ...prevState, trade: true }));
		setNewProfessionInput((prevState) => ({ ...prevState, trade: undefined }));
	};

	const changeColorFunction = (color: string): void => {
		setChosenBackgroundColor(color);
	};

	const onSpecialtyChange = (value: string): void => {
		setValidations((prevState) => ({ ...prevState, specialty: false }));
		setNewProfessionInput((prevState) => ({ ...prevState, specialty: value }));
	};

	const onContractorChange = (value: string): void => {
		setValidations((prevState) => ({ ...prevState, contractor: false }));
		setNewProfessionInput((prevState) => ({ ...prevState, contractor: value }));
	};

	const handleClose = (): void => {
		props.close();
		resetNewProfessionInput();
	};

	useEffect(() => {
		(async () => {
			const workersTradesResponse: ITrade[] = await requestService.get(`/entities/trades/workers`);
			setWorkersTrades(workersTradesResponse);
		})();
	}, []);

	return (
		<GeneralDialog
			show={true}
			close={handleClose}
			title={translationService.get('addProfession')}
			mainButton={{
				text: translationService.get('create'),
				click: handleSubmitButtonClick,
				disabled: !newProfessionInput.trade,
			}}
			secondaryButton={{
				text: translationService.get('cancel'),
				click: handleClose,
			}}
		>
			<Grid container direction="column" spacing={1}>
				<Grid item className={classes.dialogItem}>
					{workersTrades && (
						<GroupedAutoCompleteDropdown
							required
							value={newProfessionInput?.trade}
							optionKey={'_id'}
							groupByFunction={(option: ITrade) => getTradeGroupTranslation(option)}
							optionGroupProperty={'tradeGroup'}
							getOptionLabel={(trade: ITrade) => {
								return getTradeTranslation(trade);
							}}
							optionsAreSorted={true}
							options={orderBy(
								workersTrades,
								[(trade: ITrade) => trade.sortIndex, (trade: ITrade) => trade.tradeId],
								['asc', 'asc']
							)}
							onChange={onTradeChange}
							errorText={translationService.get('professionTradeErrorMessage')}
							isError={validations.trade}
							label={translationService.get('trade')}
							translationService={translationService}
							hideClearTextButton
						/>
					)}
				</Grid>
				<Grid item className={classes.dialogItem}>
					<TrusstorTextInput
						value={newProfessionInput?.specialty || ''}
						label={translationService.get('specialty')}
						changeFunc={onSpecialtyChange}
					/>
				</Grid>
				<Grid item className={classes.dialogItem}>
					<TrusstorTextInput
						value={newProfessionInput?.contractor || ''}
						label={translationService.get('contractor')}
						changeFunc={onContractorChange}
					/>
				</Grid>
				<Grid item className={classes.dialogItem}>
					<ColorPicker
						changeColorFunction={changeColorFunction}
						chosenColor={chosenBackgroundColor}
						title={translationService.get('professionsColor')}
					/>
				</Grid>
			</Grid>
		</GeneralDialog>
	);
};

export { CreateProfessionDialog };
