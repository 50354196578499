import React from 'react';
import { EquipmentIcon } from '../EquipmentIcon';
import { EquipmentTypes } from '@interfaces/index';
import { IMergedEquipmentView } from '@interfaces/IMergedEquipmentView';
import classes from './styles.module.scss';
import { UtilityTypes } from '@interfaces/UtilityTypes.enum';
import { IUtilityMergedTag } from '@interfaces/IUtilityMergedTag';

interface IMainViewEquipmentIconProps {
	type: EquipmentTypes | UtilityTypes;
	elementsArray: IMergedEquipmentView[] | IUtilityMergedTag[];
	chipBorderColor?: string;
	click?: () => void;
}

export const FloorViewEquipmentIcon = (props: IMainViewEquipmentIconProps) => {
	return (
		<div className={classes.iconAvatarWrapper} onClick={props.click}>
			<div className={classes.iconContainer}>
				<EquipmentIcon type={props.type} borderColor={props.chipBorderColor} />
			</div>
			{props.elementsArray.length > 1 && (
				<div className={classes.avatar}>
					<p className={classes.eqIconText}>{props.elementsArray.length}</p>
				</div>
			)}
		</div>
	);
};
