import React from 'react';
import classes from './styles.module.scss';
import { IconNames } from '../TrusstorIconShared/IconNames.enum';
import { IconColor, IconSize, TrusstorIconShared } from '../TrusstorIconShared/TrusstorIconShared';
import classnames from 'classnames';

interface IRadioButtonProps {
	isSelected: boolean;
	onClick: () => void;
	disabled?: boolean;
}

export const RadioButton = (props: IRadioButtonProps) => {
	const handleClick = () => {
		if (!props.isSelected && !props.disabled) {
			props.onClick();
		}
	};

	return (
		<div
			className={classnames(classes.baseRadioButton, {
				[classes.checkedRadioButton]: props.isSelected,
				[classes.hover]: !props.isSelected && !props.disabled,
				[classes.disabled]: !!props.disabled,
			})}
			onClick={handleClick}
		>
			{props.isSelected && (
				<TrusstorIconShared iconName={IconNames.check} size={IconSize.EXTRA_SMALL} color={IconColor.White} />
			)}
		</div>
	);
};
