import { TableCell, TableRow } from '@material-ui/core';
import React from 'react';
import { accessLevelService, translationService } from '../../../index';
import { CalendarCell } from '../CalendarCell/CalendarCell';
import classes from './styles.module.scss';
import CalendarBlankIcon from '@shared/assets/icons/calendar_blank.svg';
import { ITrusstorButtonType, TrusstorButton } from '@shared/components/buttons/TrusstorButton/TrusstorButton';
import { WorkPlanDialog } from '@src/Components/WorkPlan/WorkPlanDialog/WorkPlanDialog';
import moment from 'moment/moment';
import { isEmpty } from 'lodash';
import { IRoundedWorkPlanActivities } from '@interfaces/IRoundedWorkPlanActivities';

interface ICalendarEmptyModeProps {
	activities?: IRoundedWorkPlanActivities;
	daysNumber: number;
	onEditDialogActionComplete: () => Promise<void>;
}

export const CalendarEmptyMode = (props: ICalendarEmptyModeProps) => {
	const [isCreateWorkplanDialogOpen, setIsCreateWorkplanDialogOpen] = React.useState(false);

	return (
		<TableRow className={classes.emptyRow}>
			<TableCell className={classes.emptyFirstCell}>
				<CalendarCell isClickable={false} />
			</TableCell>
			<TableCell colSpan={props.daysNumber - 1}>
				<div className={classes.emptyWorkplanContainer}>
					<div className={classes.iconContainer}>
						<img className={classes.emptyWorkplanIcon} alt="calendar empty icon" src={CalendarBlankIcon} />
					</div>
					<p className={classes.noMatchText}>{translationService.get('noActivitiesMatch')}</p>
					{isEmpty(props.activities) && accessLevelService.hasAccess('workplanActivityActions') && (
						<TrusstorButton
							handleClick={() => setIsCreateWorkplanDialogOpen(true)}
							text={translationService.get('createAdHocActivity')}
							buttonType={ITrusstorButtonType.SECONDARY}
							testId="activity-create-btn"
						/>
					)}
					{isCreateWorkplanDialogOpen && (
						<WorkPlanDialog
							activityStartDate={moment(new Date()).toDate()}
							closeDialog={() => setIsCreateWorkplanDialogOpen(false)}
							onChangeData={props.onEditDialogActionComplete}
						/>
					)}
				</div>
			</TableCell>
		</TableRow>
	);
};
