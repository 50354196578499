import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import { useHeightOverflow } from '../../../hooks/custom.hooks';
import classes from '../styles.module.scss';
import { ICommentShared } from '../../../interfaces/ICommentShared';
import { TranslationService } from '../../../services/translation.service';

interface ICommentTextDisplayProps {
	comment: ICommentShared;
	translationService: TranslationService;
	dateCreated: string;
}

export const CommentTextDisplay = (props: ICommentTextDisplayProps) => {
	const [showMore, setShowMore] = useState<boolean>(false);
	const textRef = useRef(null);
	const isOverflow: boolean = useHeightOverflow(textRef);

	return (
		<div className={classes.greyBlock} key={props.comment.commentId}>
			<div className={classes.sender}>{props.comment.sender.name}</div>
			<div
				className={classNames({
					[classes.textComment]: true,
					[classes.showLess]: !showMore,
				})}
				ref={textRef}
			>
				{props.comment.content.value}
			</div>
			<div className={classes.footer}>
				<div className={classes.hour}>{props.dateCreated}</div>
				{isOverflow && (
					<div onClick={() => setShowMore((prevState) => !prevState)} className={classes.showMore}>
						{props.translationService.get('showMore')}
					</div>
				)}
			</div>
		</div>
	);
};
