import React from 'react';
import { connect } from 'react-redux';
import { closeSafetyEventById } from '../../store/thunks/safetyEvents.thunks';
import { SafetyEventPopup } from './SafetyEventPopup/SafetyEventPopup';

const SafetyEventDialogComponent = ({ handleClose, closeSafetyEventById, allVisibleEvents, allSafetyEvents }) => {
	const handleDoneClick = (doneEvent, shouldCloseDialog) => {
		closeSafetyEventById(doneEvent._id);
		shouldCloseDialog && handleClose();
	};

	return (
		<SafetyEventPopup
			minimalSafetyEvents={allVisibleEvents}
			storeSafetyEvents={allSafetyEvents}
			handleClose={handleClose}
			handleDoneClick={handleDoneClick}
		/>
	);
};

const mapStateToProps = (state) => {
	return {
		allVisibleEvents: state.safetyEventsReducer.visibleSafetyEvents,
		allActiveEvents: state.safetyEventsReducer.activeEvents,
		allSafetyEvents: state.safetyEventsReducer.safetyEvents,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		closeSafetyEventById: (id) => dispatch(closeSafetyEventById(id)),
	};
};

const SafetyEventDialog = connect(mapStateToProps, mapDispatchToProps)(SafetyEventDialogComponent);

export { SafetyEventDialog };
