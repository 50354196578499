import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { uniq } from 'lodash';
import { IRootState } from '.';
import { IssuePriority } from '@shared/interfaces/IIssueShared';
import { IIssuePrioritiesReducerState } from '@interfaces/IIssuePrioritiesReducerState';

const initialState: IIssuePrioritiesReducerState = {
	issuePriorities: [IssuePriority.CRITICAL, IssuePriority.REGULAR],
	selectedIssuePriorities: [],
};

const issuesPrioritiesSlice = createSlice({
	name: 'issuesPriorities',
	initialState,
	reducers: {
		addSelectedIssuePriority: (
			state: IIssuePrioritiesReducerState,
			action: PayloadAction<{ selectedIssuePriority: IssuePriority }>
		) => {
			const selectedIssuesPriorityAfterAdd: IssuePriority[] = uniq(
				state.selectedIssuePriorities.concat(action.payload.selectedIssuePriority)
			);
			state.selectedIssuePriorities = selectedIssuesPriorityAfterAdd;
		},
		removeSelectedIssuePriority: (
			state: IIssuePrioritiesReducerState,
			action: PayloadAction<{ removeIssuePriority: IssuePriority }>
		) => {
			const selectedActivityGroupsStatusesAfterRemove: IssuePriority[] = state.selectedIssuePriorities.filter(
				(selectedIssuePriority) => {
					return action.payload.removeIssuePriority !== selectedIssuePriority;
				}
			);

			state.selectedIssuePriorities = selectedActivityGroupsStatusesAfterRemove;
		},
		resetSelectedIssuePriorities: (state: IIssuePrioritiesReducerState) => {
			state.selectedIssuePriorities = [];
		},
	},
});

export const selectSelectedIssuePriorities = (state: IRootState): IssuePriority[] =>
	state.issuesPrioritiesReducer.selectedIssuePriorities;

export const selectIssuePriorities = (state: IRootState): IssuePriority[] =>
	state.issuesPrioritiesReducer.issuePriorities;

export const { addSelectedIssuePriority, removeSelectedIssuePriority, resetSelectedIssuePriorities } =
	issuesPrioritiesSlice.actions;
export default issuesPrioritiesSlice.reducer;
