import React from 'react';
import classes from './styles.module.scss';
import { IProject } from '@shared/interfaces/IProject';
import { cM } from '@shared/utils/colors.utils';
import { MetricNames } from '@shared/interfaces/MetricNames.enum';
import { IProjectMetrics } from '@shared/interfaces/IProjectMetrics';
import { METRICS_GAP } from '@shared/constants/metric.constants';
import { IScoreBar } from '@interfaces/IScoreBar';
import { EfficiencyHeader } from './EfficiencyHeader/EfficiencyHeader';
import { EfficiencyOverall } from './EfficiencyOverall/EfficiencyOverall';
import { EfficiencyTab } from './EfficiencyTab/EfficiencyTab';
import helmetIcon from '../../../../assets/milestonesIcons/helmet.svg';
import whiteCheckboxIcon from '../../../../assets/milestonesIcons/whiteCheckbox.svg';
import { translationService } from '../../../../index';
import { ScoreBar } from '../../../Bars/ScoreBar/ScoreBar';
import { useOverallProjectMetricsScore, useTodayProjectMetrics } from '@src/hooks/queries/executiveView.queries.hooks';

interface IProjectEfficiencySectionProps {
	project: IProject;
}

const ProjectEfficiencySection = (props: IProjectEfficiencySectionProps) => {
	const projectMetrics: IProjectMetrics = useTodayProjectMetrics(props.project.projectId, props.project.tz);
	const projectOverallScore: { [key: string]: number } = useOverallProjectMetricsScore(props.project.projectId);

	const expectedDailyWorkforce: number = props.project.expectedDailyWorkforce;
	const topWorkforceLimit: number = Math.round(expectedDailyWorkforce * (1 + METRICS_GAP));
	const bottomWorkforceLimit: number = Math.round(expectedDailyWorkforce * (1 - METRICS_GAP));
	const currentWorkforce: number = Math.round(Number(projectMetrics?.metrics?.[MetricNames.WORKFORCE] || 0));

	const getWorkforceDescription = (): string => {
		if (currentWorkforce > topWorkforceLimit) {
			return translationService.get('aboveWorkforce');
		} else if (currentWorkforce < bottomWorkforceLimit) {
			return translationService.get('belowWorkforce');
		}
		return translationService.get('idealWorkforce', {
			topLimit: String(topWorkforceLimit),
			bottomLimit: String(bottomWorkforceLimit),
		});
	};

	const getWorkforceGraph = (): JSX.Element => {
		const workforceBars: IScoreBar[] = [
			{
				color: '#B7BCC2',
				startText: '0',
				isChosenBar: currentWorkforce < bottomWorkforceLimit,
				tooltipTextColor: 'black',
				tooltipText: currentWorkforce.toString(),
				tooltipBackgroundColor: 'white',
			},
			{
				color: '#1F8AEC',
				startText: String(bottomWorkforceLimit),
				endText: String(topWorkforceLimit),
				isChosenBar: currentWorkforce >= bottomWorkforceLimit && currentWorkforce <= topWorkforceLimit,
				tooltipTextColor: '#1F8AEC',
				tooltipText: currentWorkforce.toString(),
				tooltipBackgroundColor: 'white',
			},
			{
				color: '#B7BCC2',
				isChosenBar: currentWorkforce > topWorkforceLimit,
				tooltipTextColor: '#576270',
				tooltipText: currentWorkforce.toString(),
				tooltipBackgroundColor: 'white',
			},
		];
		return <ScoreBar bars={workforceBars} />;
	};

	const getCompletionRateOnTimeGraph = (): JSX.Element => {
		const activitiesCompletionRateOnTime: number | undefined =
			projectMetrics?.metrics?.[MetricNames.ACTIVITIES_COMPLETION_RATE_ON_TIME];
		const activitiesTrend: number =
			projectMetrics?.metrics?.[MetricNames.ACTIVITIES_COMPLETION_RATE_ON_TIME_TREND] || 0;
		const trendColor: string = activitiesTrend >= 0 ? '#7AA418' : '#E85F6D';
		const toolTipTrendEl: JSX.Element = (
			<span style={{ color: trendColor }}>
				{activitiesTrend >= 0 ? '+' : ''}
				{Math.round(activitiesTrend * 100)}%
			</span>
		);
		const completionRateOnTimeBars: IScoreBar[] = [
			{
				color: '#E7E9EB',
				startText: '0',
				endText: '50%',
				isChosenBar: activitiesCompletionRateOnTime < 0.5,
				tooltipTextColor: 'black',
				tooltipText: `${Math.round(Number(activitiesCompletionRateOnTime) * 100)}%`,
				tooltipBackgroundColor: 'white',
				extraTooltipEl: toolTipTrendEl,
			},
			{
				color: '#68B6FD',
				isChosenBar: activitiesCompletionRateOnTime >= 0.5 && activitiesCompletionRateOnTime < 0.75,
				tooltipTextColor: '#68B6FD',
				tooltipText: `${Math.round(Number(activitiesCompletionRateOnTime) * 100)}%`,
				tooltipBackgroundColor: 'white',
				extraTooltipEl: toolTipTrendEl,
			},
			{
				color: '#1F8AEC',
				startText: '75%',
				isChosenBar: activitiesCompletionRateOnTime >= 0.75,
				tooltipTextColor: '#1F8AEC',
				tooltipText: `${Math.round(Number(activitiesCompletionRateOnTime) * 100)}%`,
				tooltipBackgroundColor: 'white',
				extraTooltipEl: toolTipTrendEl,
			},
		];
		return <ScoreBar bars={completionRateOnTimeBars} />;
	};

	return (
		<section className={cM(classes.projectEfficiencyContainer, classes)}>
			<EfficiencyHeader />
			<EfficiencyOverall overallScore={projectOverallScore?.overallScore} />
			<EfficiencyTab
				icon={helmetIcon}
				title={translationService.get('workforce')}
				description={getWorkforceDescription()}
			>
				{getWorkforceGraph()}
			</EfficiencyTab>
			<div className={classes.breakLine} />
			<EfficiencyTab
				icon={whiteCheckboxIcon}
				title={translationService.get('taskCompletion')}
				description={translationService.get('taskCompletionDescription')}
			>
				{getCompletionRateOnTimeGraph()}
			</EfficiencyTab>
		</section>
	);
};

export { ProjectEfficiencySection };
