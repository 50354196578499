import React from 'react';
import classes from './styles.module.scss';
import { translationService } from '@src/servicesInitializers';
import {
	ITrusstorButtonSize,
	ITrusstorButtonType,
	TrusstorButton,
} from '@shared/components/buttons/TrusstorButton/TrusstorButton';
import { TrusstorIcon } from '@src/Components/TrusstorIcon/TrusstorIcon';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { IconColor } from '@shared/components/TrusstorIconShared/TrusstorIconShared';

interface IMilestonesPageHeaderProps {
	milestonesAmount: number;
	createButtonClick: () => void;
	isCreateDisabled?: boolean;
}

export const MilestonesPageHeader = (props: IMilestonesPageHeaderProps) => {
	return (
		<div className={classes.MilestonesPageHeader_container} data-testid="MilestonesPageHeader">
			<div>{`${translationService.get('milestones_pageTitle')} (${props.milestonesAmount})`}</div>
			<TrusstorButton
				handleClick={props.createButtonClick}
				disabled={props.isCreateDisabled}
				buttonType={ITrusstorButtonType.OUTLINE}
				buttonSize={ITrusstorButtonSize.SMALL}
				iconElement={
					<TrusstorIcon
						iconName={IconNames.plus}
						color={props.isCreateDisabled ? IconColor.Grey100 : undefined}
					/>
				}
				text={translationService.get('add_milestone_button')}
			/>
		</div>
	);
};
