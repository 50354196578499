import { IConfigArea } from '@shared/interfaces/IConfigArea';
import { InvestigationReportTypes } from '@shared/interfaces/InvestigationReportTypes.enum';
import moment from 'moment-timezone';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { requestService, translationService } from '../../../../../index';
import { IUser } from '@shared/interfaces/IUser';
import { selectProjectId, selectTimezone } from '@store/slices/project.slice';
import { errorSnackbar } from '@utils/snackbar.utils';
import { ReportCard } from '../ReportCard';
import { useProjectAreasQuery } from '@src/hooks/queries/areas.queries.hooks';
import { groupBy } from 'lodash';
import { ICreateObserveLocationReport } from '@shared/interfaces/ICreateObserveLocationReport';
import { IBaseAreaBaseFloor } from '@shared/interfaces/IBaseAreaBaseFloor';
import { analysisCenterReportGeneration_BI } from '@utils/bi.utils';
import classes from './styles.module.scss';
import { selectLoggedUserDetails } from '@store/slices/login.slice';
import { DateSelectorTrusstorInput } from '@src/Components/DateSelector/DateSelectorTrusstorInput/DateSelectorTrusstorInput';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { GroupedSingleDropdown } from '@src/Components/Dropdowns/DesignSystem/GroupedSingleDropdown/GroupedSingleDropdown';
import { sortByAreas } from '@shared/utils/sort.utils';

interface IInvestigationReportCardReportCardProps {
	isSelected: boolean;
	icon: IconNames;
	title: string;
	description: string;
	name: InvestigationReportTypes;
	setSelectedCard: (name: string) => void;
}

interface IConfigFloorArea extends IConfigArea {
	isFloor?: boolean;
}

const ObserveLocationCard = (props: IInvestigationReportCardReportCardProps) => {
	const dispatch = useDispatch();
	const projectId: string = useSelector(selectProjectId)!;
	const tz: string = useSelector(selectTimezone)!;
	const yesterday: Date = moment.tz(tz).subtract(1, 'days').toDate();
	const [selectedDate, setSelectedDate] = useState<Date | null>(yesterday);
	const [selectedArea, setSelectedArea] = useState<IConfigFloorArea | null>(null);
	const projectAreas: IConfigFloorArea[] = useProjectAreasQuery(projectId);
	const loggedUserDetails: IUser = useSelector(selectLoggedUserDetails)!;

	const history = useHistory();

	const groupByFunction = (option: IConfigFloorArea) => {
		return option.floorNick;
	};

	const areasWithFloors: IConfigFloorArea[] = useMemo(() => {
		const sortedAreas: IConfigFloorArea[] = sortByAreas(projectAreas);
		const groupedAreas: { [key: string]: IConfigFloorArea[] } = groupBy(sortedAreas, groupByFunction);
		for (const floor in groupedAreas) {
			if (groupedAreas[floor].length === 1) continue;
			const floorExample: IConfigFloorArea = groupedAreas[floor][0];
			groupedAreas[floor].unshift({
				areaNick: floorExample.floorNick,
				areaId: floorExample.floorId,
				floorNick: floorExample.floorNick,
				floorId: floorExample.floorId,
				projectId: floorExample.projectId,
				isHoist: floorExample.isHoist,
				isGroundFloor: floorExample.isGroundFloor,
				isMovingFloor: floorExample.isMovingFloor,
				isSpecialFloor: floorExample.isSpecialFloor,
				shortFloorNick: floorExample.shortFloorNick,
				areaSortIndex: floorExample.areaSortIndex,
				isFloor: true,
			});
		}
		return Object.values(groupedAreas).flat(2);
	}, [projectAreas]);

	useEffect(() => {
		return () => {
			setSelectedDate(null);
			setSelectedArea(null);
		};
	}, []);

	const isGenerateDisabled = (): boolean => {
		return !selectedArea?.areaNick || !selectedDate;
	};

	const handleAreaChange = (value: IConfigFloorArea | null): void => {
		const selectedArea: IConfigFloorArea = areasWithFloors.find((area) => area?.areaId === value?.areaId)!;
		setSelectedArea(selectedArea);
	};

	const handleTimeChange = (date: Date) => {
		setSelectedDate(date);
	};
	const getOptionLabel = (option: IConfigFloorArea) => {
		return option.areaNick || '';
	};
	const generateLocations = (area: IConfigFloorArea): IBaseAreaBaseFloor[] => {
		if (area.isFloor) {
			return projectAreas
				.filter((a) => a.floorId === area.floorId && !a.isFloor)
				.map((configArea) => getLocation(configArea));
		}
		return [getLocation(area)];
	};

	const getLocation = (area: IConfigFloorArea): IBaseAreaBaseFloor => ({
		areaNick: area.areaNick,
		areaId: area.areaId,
		floorNick: area.floorNick,
		floorId: area.floorId,
	});

	const getInvestigationReport = (): ICreateObserveLocationReport | null => {
		if (isGenerateDisabled()) return null;

		return {
			userId: loggedUserDetails._id!,
			investigatedTimestamp: selectedDate!.getTime(),
			investigationType: props.name,
			title: props.title,
			description: props.description,
			projectId: projectId,
			location: generateLocations(selectedArea!),
		};
	};

	const generateInvestigationReport = async () => {
		try {
			const investigationReport: ICreateObserveLocationReport | null = getInvestigationReport();
			if (!investigationReport) return;
			const reportId = await requestService.post(`/archive/investigationReports/`, {
				body: {
					investigationReport,
				},
			});
			analysisCenterReportGeneration_BI(props.name, 'new', [new Date(investigationReport.investigatedTimestamp)]);
			history.push(`/analysisCenter/${investigationReport.investigationType}/${reportId}`);
		} catch (e) {
			errorSnackbar(dispatch, translationService.get('failedToGenerateReport'));
			console.error('Failed to generate report', e);
		}
	};

	const marchFirstDate: Date = moment.tz('2023-03-01', tz).toDate(); //The date from when data started being accumulated in the archive microservice
	return (
		<ReportCard
			name={props.name}
			isSelected={props.isSelected}
			icon={props.icon}
			title={props.title}
			description={props.description}
			generateReport={generateInvestigationReport}
			isGenerateReportDisabled={isGenerateDisabled()}
			setSelectedCard={props.setSelectedCard}
		>
			<>
				<section className={classes.searchLocationContainer}>
					<GroupedSingleDropdown
						getDisplayOption={getOptionLabel}
						onChange={handleAreaChange}
						options={areasWithFloors}
						label={translationService.get('enterLocation')}
						value={selectedArea ?? undefined}
						groupByFunction={groupByFunction}
					/>
				</section>
				<DateSelectorTrusstorInput
					handleTimeChange={handleTimeChange}
					selectedDate={selectedDate}
					selectDefaultDate
					setThirtyMinutesGap
					maxDate={moment.tz(tz).toDate()}
					minDate={marchFirstDate}
					calendarPosition={translationService.getIsRtl() ? 'top-start' : 'bottom-end'}
				/>
			</>
		</ReportCard>
	);
};

export { ObserveLocationCard };
