import React, { useEffect } from 'react';
import { IInvestigationReport } from '@shared/interfaces/IInvestigationReport';
import { IProject } from '@shared/interfaces/IProject';
import { AnalysisCenterReportHeader } from '../AnalysisCenterReportHeader/AnalysisCenterReportHeader';
import { useDispatch, useSelector } from 'react-redux';
import { useTagsTimesheetQuery } from '../../../../hooks/queries/analysisCenter.queries.hooks';
import { updateInvestigationReport } from '../../../../utils/analysisCenter.utils';
import { IWorkersTimesheetReport } from '@shared/interfaces/IWorkersTimesheetReport';
import { WorkersListSection } from './WorkersSectionComponents/WorkersListSection/WorkersListSection';
import { WorkersTimesheetTablesSection } from './WorkersTimesheetSectionComponents/WorkersTimesheetTablesSection/WorkersTimesheetTablesSection';
import { cM } from '@shared/utils/colors.utils';
import classes from './styles.module.scss';
import { RoleType } from '@shared/interfaces/RoleType.enum';
import { getStaticManagers, getStaticTags } from '@store/thunks';
import { selectProjectId, selectTimezone } from '@store/slices/project.slice';
import { IRootState } from '@store/slices';
import { Loader } from '@shared/components/Loader/Loader';
import { isEmpty } from 'lodash';
import { InvestigationReportEmptyPage } from '../InvestigationReportEmptyPage/InvestigationReportEmptyPage';
import { translationService } from '../../../../index';
import { ITimesheetByTagType } from '../ITimesheetByTagType';
import { getTimesheetByTagType } from '../timesheets.utils';
import { exportTimesheetReportToExcel } from '@utils/workersTimesheetReport.utils';
import { useInvestigationReportQuery } from '@src/hooks/queries/analysisCenter.queries.hooks';
import { IManager } from '@shared/interfaces/IManager';
import { useRenderMonitoring } from '@shared/hooks/monitoring.hooks';

export const TimesheetReportPage = (props: { match }) => {
	useRenderMonitoring('TimesheetReportPage');
	const project: IProject = useSelector((state: IRootState) => state.projectReducer.workingProject!);
	const projectId: string = useSelector(selectProjectId)!;
	const tz: string = useSelector(selectTimezone)!;
	const dispatch = useDispatch();
	let reportId: string = props.match.params.reportId;
	const { investigationReport, isInvestigationReportLoading, refetchInvestigationReport } =
		useInvestigationReportQuery<IWorkersTimesheetReport>(reportId);
	const { tagsTimesheet, isTagsTimesheetLoading } = useTagsTimesheetQuery(investigationReport, projectId, tz);
	const isAdmin: boolean =
		useSelector((state: IRootState) => state.loginReducer.loggedUser?.userDetails.permissions.roleType) ===
		RoleType.TRUSSTOR_ADMIN;
	const staticManagers: IManager[] = useSelector((state: IRootState) => state.managersReducer.staticManagers);

	useEffect(() => {
		dispatch(getStaticTags(projectId, isAdmin));
		dispatch(getStaticManagers({ projectId }));
	}, [projectId, isAdmin]);

	const updateReport = async (reportId: string, attributesToUpdate: Partial<IInvestigationReport>) => {
		await updateInvestigationReport(reportId, attributesToUpdate, dispatch, refetchInvestigationReport);
	};

	const timesheetByTagType: ITimesheetByTagType = getTimesheetByTagType(tagsTimesheet || {}, staticManagers);

	const isLoadingState: boolean = isInvestigationReportLoading || isTagsTimesheetLoading;
	const isPageEmptyState: boolean = !isLoadingState && (!investigationReport || isEmpty(timesheetByTagType));

	return (
		<div className={cM(classes.pageContainer, classes)}>
			<>
				{investigationReport && (
					<AnalysisCenterReportHeader
						updateReport={updateReport}
						reportId={investigationReport._id!}
						title={investigationReport.title}
						description={investigationReport.description}
						displayDate={investigationReport.fromDate}
						secondDate={investigationReport.toDate}
						disableDownload={false}
						handleDownloadReport={async (rawReport?: boolean) => {
							exportTimesheetReportToExcel(
								investigationReport,
								tagsTimesheet || {},
								project,
								tz,
								rawReport
							);
						}}
					/>
				)}
				{isLoadingState && <Loader />}
				{isPageEmptyState ? (
					<InvestigationReportEmptyPage text={translationService.get('tagsHaveNoData')} />
				) : (
					<div className={cM(classes.bodyContainer, classes)}>
						{!isLoadingState && (
							<>
								<WorkersListSection timesheetByTagType={timesheetByTagType} />
								<WorkersTimesheetTablesSection
									timesheetByTagType={timesheetByTagType}
									startDate={investigationReport?.fromDate}
									endDate={investigationReport?.toDate}
								/>
							</>
						)}
					</div>
				)}
			</>
		</div>
	);
};
