import { useCallback, useState } from 'react';

export const useResizeObserver = () => {
	const [contentBoxSize, setContentBoxSize] = useState<ResizeObserverSize | null>(null);
	const [borderBoxSize, setBorderBoxSize] = useState<ResizeObserverSize | null>(null);

	const handleResize = useCallback((entries: ResizeObserverEntry[]) => {
		for (const entry of entries) {
			if (entry.contentBoxSize) {
				setContentBoxSize(entry.contentBoxSize[0]);
			}
			if (entry.borderBoxSize) {
				setBorderBoxSize(entry.borderBoxSize[0]);
			}
		}
	}, []);

	const observeElement = useCallback(
		(element: HTMLElement) => {
			const resizeObserver = new ResizeObserver(handleResize);
			resizeObserver.observe(element);

			return () => {
				resizeObserver.disconnect();
			};
		},
		[handleResize]
	);

	return { observeElement, contentBoxSize, borderBoxSize };
};
