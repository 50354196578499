import {
	authService,
	biService,
	requestService,
	streamService,
	userService,
	versionService,
} from '@src/servicesInitializers';
import { IUser } from '@shared/interfaces/IUser';
import { config } from '@src/config';
import { errorInterceptor } from '@utils/request.utils';
import { ILocalStorageUserData } from '@shared/interfaces/IUserService';
import { store } from '@store/store';
import { logout } from '@store/thunks';
import { initPostHog, initPostHogByUserData } from '@shared/utils/posthog.utils';
import { Environments } from '@shared/constants/environments';
import { initMonitoringService, initSentryUser } from '@shared/utils/monitoring.util';
import { initFullStory, initFullStoryByUserData } from '@shared/utils/fullstory.utils';

export const initializeBootstrapUtilities = (): void => {
	initRequestService();
	handleUserLoggedOut();
	initUserData(); // important to be after initRequestService and handleUserLoggedOut
	initMonitoringService(
		config.sentryDsn,
		process.env.REACT_APP_STAGE as string,
		process.env.REACT_APP_DASHBOARD_VERSION as string,
		config.sentryOrganization,
		config.sentryProjectId
	);
	initStreamService();
	initFullStory(config.fullStoryOrganizationId);
	initPostHog(config.posthogAPIKey, process.env.REACT_APP_STAGE as Environments);
	initAppVersion();
};

const handleUserLoggedOut = (): void => {
	const lsUserData: ILocalStorageUserData | null = userService.getLSUserData();
	const isLoggedOut: string | null = new URL(window.location.href).searchParams.get('loggedOut');

	if (!lsUserData?.accessToken || !lsUserData?.refreshToken || isLoggedOut) {
		store.dispatch(logout());
	}
};

const initUserData = async (): Promise<void> => {
	try {
		await authService.refreshUserToken();
	} catch (e) {
		store.dispatch(logout());
		window.location.reload();
	}
};

const initAppVersion = (): void => {
	const currentVersion: string | null = versionService.getVersion();

	if (!currentVersion) {
		versionService.setVersion(process.env.REACT_APP_DASHBOARD_VERSION || 'local');
	}
};

const initRequestService = (): void => {
	requestService.addResponseErrorInterceptor(errorInterceptor(config));
};

const initStreamService = (): void => {
	streamService.setOnOpenStreamInterceptor(async () => {
		const isTokenExpired: boolean = await authService.getIsTokenExpired();
		if (isTokenExpired) {
			await authService.refreshUserToken();
		}
	});
};

export const initServicesDependentByUserData = (userDetails: IUser, projectId: string): void => {
	initPostHogByUserData(userDetails, projectId, process.env.REACT_APP_STAGE as Environments);
	initFullStoryByUserData(userDetails, projectId);
	initBiByUserData(userDetails);
	initSentryUser(userDetails);
};

const initBiByUserData = (userDetails: IUser): void => {
	biService.initUserIdentify({
		userId: userDetails._id,
		username: userDetails.username,
		roleType: userDetails.permissions.roleType,
	});
};
