import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { TrusstorBox } from '../Boxes/Containers/TrusstorBox';
import { Grid } from '@material-ui/core';
import { textStyle } from '@shared/utils/text.utils';
import { COLORS } from '../../constants';
import { TrusstorSlideSwitcher } from '../TrusstorSlideSwitcher/TrusstorSlideSwitcher';

const useStyles = makeStyles((theme: Theme) => ({
	toggleText: textStyle({
		fontClass: 'body1',
		fontWeight: 'normal',
	}),
	toggleContainer: {
		height: '100%',
	},
	switchBase: {
		'color': COLORS.white,
		'&$checked': {
			color: COLORS.white,
		},
		'&$checked + $track': {
			backgroundColor: '#77a705',
		},
		'& + $track': {
			backgroundColor: '#77a705',
		},
	},
	track: {},
	checked: {},
}));

interface ISingleToggleSectionProps {
	firstOption: string;
	secondOption: string;
	firstAction: any;
	secondAction: any;
	value?: string;
	initialState?: string;
	width?: string;
	height?: string;
	biEventOnClick?: (option: string) => void;
}

const ToggleSection = ({
	firstOption,
	secondOption,
	firstAction,
	secondAction,
	value,
	initialState,
	width,
	height,
	biEventOnClick,
}: ISingleToggleSectionProps) => {
	const classes = useStyles();

	const toggleChecked = (option) => {
		option === firstOption ? firstAction(option) : secondAction(option);
	};

	return (
		<TrusstorBox>
			<div className={classes.toggleContainer} data-testid="switchToggleContainer">
				<Grid item>
					<TrusstorSlideSwitcher
						options={[firstOption, secondOption]}
						handleChange={toggleChecked}
						value={value}
						width={width}
						height={height}
						biEventOnClick={biEventOnClick}
						initialState={initialState}
					/>
				</Grid>
			</div>
		</TrusstorBox>
	);
};

export { ToggleSection };
