import { IMergedAreaSequenceItem } from '@interfaces/IMergedAreaSequenceItem';
import { ISequenceMatrixTableData } from '@src/Components/WorkPlan/ActivityProgressTracker/components/ActivitySequenceMatrix/ActivitySequenceMatrix';
import { IActivitySequenceItemCellData } from '@src/Components/WorkPlan/ActivityProgressTracker/components/ActivitySequenceMatrix/cellComponents/ActivitySequenceItemCell/ActivitySequenceItemCell';
import { Dictionary, groupBy, sortBy, uniqBy } from 'lodash';
import { exportService, requestService, translationService } from '@src/servicesInitializers';
import moment, { Moment } from 'moment/moment';
import { store } from '@store/store';
import { IRow, ISheets, ISheetsInfoValues } from '@src/services';
import { ISequenceItemsBySequenceIds } from '@interfaces/ISequenceItemsBySequenceIds';
import { COLORS } from '@shared/constants/colors.constants';
import { IConfigArea } from '@shared/interfaces/IConfigArea';
import { sortByAreas } from '@shared/utils/sort.utils';
import { IProject } from '@shared/interfaces/IProject';
import { ISequenceItem } from '@interfaces/ISequenceItem';
import { AreaSequenceItemStatus } from '@shared/interfaces/AreaSequenceItemStatus.enum';
import React from 'react';
import { SequenceTypeEnum } from '@shared/interfaces/SequenceType.enum';
import { ISequence } from '@shared/interfaces/ISequence';
import { IProfession } from '@shared/interfaces/IProfession';

import { isAsiComplete } from '@shared/utils/asi.utils';
import { SORT_ORDER } from '@shared/constants/constants';
import { getFakeDisabledAsi } from '@utils/areaSequenceItem.utils';

export const generateUniqueSequenceItemDescription = (currentSequenceItems: ISequenceItem[]) => {
	const newItemDescription: string = translationService.get('newItem');
	const descriptions: string[] = currentSequenceItems.map((item) => item.description);

	if (!descriptions.includes(newItemDescription)) {
		return newItemDescription;
	}

	const lastOrderIndex: number = currentSequenceItems[currentSequenceItems.length - 1]?.orderIndex;

	if (lastOrderIndex !== undefined) {
		const nextOrderIndex: number = lastOrderIndex + 1;
		const newItemWithNextOrderIndex: string = `${newItemDescription} ${nextOrderIndex}`;

		if (!descriptions.includes(newItemWithNextOrderIndex)) {
			return newItemWithNextOrderIndex;
		}
	}

	let index: number = 1;
	while (true) {
		const newDescription: string = `${newItemDescription} ${index}`;
		if (!descriptions.includes(newDescription)) {
			return newDescription;
		}
		index++;
	}
};

export const changePropertyOnAreaSequenceItems = (
	sequenceItemId: string,
	updateObj: { description?: string; profession?: IProfession },
	setTables: React.Dispatch<React.SetStateAction<ISequenceMatrixTableData[] | undefined>>
) => {
	setTables((prevTables) => {
		if (!prevTables?.length) {
			return prevTables;
		}

		return prevTables.map((table) => {
			const updatedRows = table.rows.map((row) => {
				const updatedItemInRow = row.find((item) => item.sequenceItemId === sequenceItemId);

				if (!updatedItemInRow) {
					return row;
				}

				const updatedRow = row.map((item) => {
					if (item.sequenceItemId === sequenceItemId) {
						return { ...item, ...updateObj };
					}
					return item;
				});

				return updatedRow;
			});

			return { ...table, rows: updatedRows };
		});
	});
};

export const addSequenceItemsToTable = (
	allAreaSequenceItems: IMergedAreaSequenceItem[],
	setTables: React.Dispatch<React.SetStateAction<ISequenceMatrixTableData[] | undefined>>,
	setAreaSequenceItemsByFloor: (areaSequenceItems: IMergedAreaSequenceItem[]) => void,
	sequenceItems: ISequenceItem[],
	sequenceAreas: IConfigArea[]
) => {
	if (!allAreaSequenceItems) {
		return;
	}

	const disabledAsis: IActivitySequenceItemCellData[] = sequenceItems.flatMap((sequenceItem) => {
		const sequenceItemAsis = allAreaSequenceItems.filter((asi) => asi.sequenceItemId === sequenceItem._id);

		if (sequenceItemAsis.length === sequenceAreas.length) {
			return [];
		}

		const areasWithoutAsis: IConfigArea[] = sequenceAreas.filter((area) => {
			return !sequenceItemAsis.find((asi) => asi.area.areaId === area.areaId);
		});
		return areasWithoutAsis.map((area) => {
			return getFakeDisabledAsi(sequenceItem, area);
		});
	});

	const allAsisWithDisabled: IActivitySequenceItemCellData[] = sortBy(
		[...allAreaSequenceItems, ...disabledAsis] as IActivitySequenceItemCellData[],
		(area: IActivitySequenceItemCellData) => area.orderIndex
	);

	setTables((prevTables) => {
		if (!prevTables?.length) {
			return prevTables;
		}
		setAreaSequenceItemsByFloor(allAsisWithDisabled);
		if (prevTables?.[0].isEmpty) {
			const areaSequenceItemByFloors = groupBy(allAsisWithDisabled, 'area.floorId');
			return prevTables!.map((table: ISequenceMatrixTableData) => {
				const floorId: string = table.id;
				const areaSequenceItemsOfFloor = areaSequenceItemByFloors[floorId];
				const updatedRows = table.rows.map((row: IMergedAreaSequenceItem[], index: number) => {
					const areaNick = table.rowHeaders[index].rowTitle;
					const areaSequenceItemOfArea = areaSequenceItemsOfFloor.find(
						(item) => item.area.areaNick === areaNick
					);
					if (!areaSequenceItemOfArea) {
						return row;
					}
					return [areaSequenceItemOfArea];
				});
				return {
					...table,
					rows: updatedRows,
					isEmpty: false,
				};
			});
		}
		const areaSequenceItemByArea = groupBy(allAsisWithDisabled, 'area.areaId');
		return prevTables!.map((table: ISequenceMatrixTableData) => {
			const updatedRows: IActivitySequenceItemCellData[][] = table?.rows?.map(
				(row: IMergedAreaSequenceItem[]) => {
					const areaId = row[0]?.area?.areaId;
					const updatedItemsInArea: IMergedAreaSequenceItem[] | undefined = areaSequenceItemByArea[areaId];
					return updatedItemsInArea;
				}
			);
			return {
				...table,
				rows: updatedRows,
			};
		});
	});
};

export const removeSequenceItemFromTable = (
	sequenceItemId: string,
	setTables: React.Dispatch<React.SetStateAction<ISequenceMatrixTableData[] | undefined>>,
	setAreaSequenceItemsByFloor: (areaSequenceItem: IMergedAreaSequenceItem[]) => void
) => {
	if (!sequenceItemId) {
		return;
	}

	setTables((prevTables) => {
		const areaSequenceItemToKeep: IMergedAreaSequenceItem[] = [];
		const newTables: ISequenceMatrixTableData[] = prevTables!.map((table: ISequenceMatrixTableData) => {
			const updatedRows: IActivitySequenceItemCellData[][] = table?.rows?.map(
				(row: IMergedAreaSequenceItem[]) => {
					const newRow = row.filter((item) => {
						return item.sequenceItemId !== sequenceItemId;
					});

					areaSequenceItemToKeep.push(...newRow);
					return newRow;
				}
			);
			const isEmpty: boolean = updatedRows.every((row) => !row.length);
			return {
				...table,
				rows: updatedRows,
				isEmpty,
			};
		});
		setAreaSequenceItemsByFloor(areaSequenceItemToKeep);
		return newTables;
	});
};

export const removeSequenceItemsFromTable = (
	removedAreaSequenceItems: IMergedAreaSequenceItem[],
	setTables: React.Dispatch<React.SetStateAction<ISequenceMatrixTableData[] | undefined>>
) => {
	if (!removedAreaSequenceItems) {
		return;
	}

	setTables((prevTables) => {
		return prevTables!.map((table: ISequenceMatrixTableData) => {
			const updatedRows: IActivitySequenceItemCellData[][] = table?.rows?.map(
				(row: IMergedAreaSequenceItem[]) => {
					const areaId = row[0]?.area?.areaId;
					const removedItemsInArea = removedAreaSequenceItems.filter(
						(item: IMergedAreaSequenceItem) => item.area.areaId === areaId
					);

					if (!removedItemsInArea.length) {
						return row;
					}

					const newRow = row.filter((item) => {
						return !removedItemsInArea.some((removedItem) => removedItem._id === item._id);
					});

					return newRow;
				}
			);

			return { ...table, rows: updatedRows };
		});
	});
};

const updateSequences = (sequence: ISequence, sequences: ISequence[]) => {
	return sequences.map((sequenceToUpdate) => {
		if (sequenceToUpdate._id === sequence._id) {
			return sequence;
		}
		return sequenceToUpdate;
	});
};

export const updateSequence = async (
	sequenceId: string,
	{ name, sequenceType }: { name?: string; sequenceType?: SequenceTypeEnum },
	setSequences: React.Dispatch<React.SetStateAction<ISequence[]>>
) => {
	try {
		const updatedSequence = await requestService.put(`/activities/sequences/${sequenceId}`, {
			body: {
				name,
				sequenceType,
			},
		});
		setSequences((prev) => updateSequences(updatedSequence, prev!));
	} catch (e) {
		console.log(e);
	}
};

export const countAreaSequenceItemsDoneFromSequenceItem = (
	sequenceItem: ISequenceItem,
	allAreaSequenceItems: (IMergedAreaSequenceItem | null)[]
): { doneItems?: number; totalItems: number } => {
	const filteredAreaSequenceItems: IMergedAreaSequenceItem[] | undefined = allAreaSequenceItems.filter(
		(areaSequenceItem: IMergedAreaSequenceItem | null) => {
			return areaSequenceItem?.sequenceItemId === sequenceItem._id;
		}
	) as IMergedAreaSequenceItem[] | undefined;
	const doneItems: number | undefined = filteredAreaSequenceItems?.filter(
		(areaSequenceItem: IMergedAreaSequenceItem) => isAsiComplete(areaSequenceItem.status)
	).length;

	return {
		doneItems,
		totalItems: filteredAreaSequenceItems?.length ?? 0,
	};
};

export const countAreaSequenceItemsDoneFromRow = (
	rowTitleKey: string,
	groupedAreaSequenceItemsMap: Dictionary<IMergedAreaSequenceItem[]>
): { rowTitle: string; doneItems?: number; totalItems: number } => {
	const filteredAreaSequenceItems: IMergedAreaSequenceItem[] | undefined = groupedAreaSequenceItemsMap[rowTitleKey];
	const doneItems: number | undefined = filteredAreaSequenceItems?.filter(
		(areaSequenceItem: IMergedAreaSequenceItem) => isAsiComplete(areaSequenceItem.status)
	).length;

	return {
		rowTitle: rowTitleKey,
		doneItems,
		totalItems: filteredAreaSequenceItems?.length ?? 0,
	};
};

export const getExcelColorFromStatus = (status: AreaSequenceItemStatus) => {
	switch (status) {
		case AreaSequenceItemStatus.delayedOverdue:
		case AreaSequenceItemStatus.overdue:
			return COLORS.red400;
		case AreaSequenceItemStatus.inProgress:
			return COLORS.yellow500;
		case AreaSequenceItemStatus.complete:
			return COLORS.green400;
		case AreaSequenceItemStatus.delayed:
		case AreaSequenceItemStatus.planned:
			return COLORS.grey100;
		case AreaSequenceItemStatus.readyForReview: {
			return COLORS.yellow600;
		}
	}
};

const getExcelColorFromPercentage = (percentage: number): string => {
	if (percentage === 0) {
		return COLORS.white;
	}
	return percentage < 100 ? COLORS.yellow500 : COLORS.green400;
};

export const exportSequenceItemsToExcel = async (project: IProject, selectedProfessions: IProfession[]) => {
	const projectId: string = project.projectId;
	const projectName: string = project.name;
	const tz: string = project.tz;

	const date: Moment = moment.tz(new Date(), tz);

	const excelTime: string = `${date.format('MMM Do, YYYY')}`;
	const excelName: string = `${translationService.get('sequenceItems')} - ${
		store.getState().projectReducer?.workingProject?.name
	} - ${excelTime}`;

	const projectSequenceItemsBySequenceIds: ISequenceItemsBySequenceIds[] = await requestService.get(
		`/activities/sequenceItems/byProject?projectId=${projectId}`
	);

	const sheets: ISheets = {};
	const sheetsInfoValues: ISheetsInfoValues = {};

	for (const sequenceItemsBySequenceId of projectSequenceItemsBySequenceIds) {
		const areaSheetName: string = `${sequenceItemsBySequenceId.sequenceName} - ${translationService.get(
			'sequenceExcelExport_areas'
		)}`;
		const floorSheetName: string = `${sequenceItemsBySequenceId.sequenceName} - ${translationService.get(
			'sequenceExcelExport_floors'
		)}`;
		sheetsInfoValues[areaSheetName] = generateSheetInfoValues(
			projectName,
			excelTime,
			sequenceItemsBySequenceId.sequenceName
		);
		sheetsInfoValues[floorSheetName] = generateSheetInfoValues(
			projectName,
			excelTime,
			sequenceItemsBySequenceId.sequenceName
		);

		try {
			const url: string = `/activities/sequenceItems/areaSequenceItem?sequenceId=${sequenceItemsBySequenceId.sequenceId}`;
			const areaSequenceItems: IMergedAreaSequenceItem[] = await requestService.get(url);
			const filteredAreaSequenceItems: IMergedAreaSequenceItem[] =
				selectedProfessions.length === 0
					? areaSequenceItems
					: areaSequenceItems.filter((item) =>
							selectedProfessions.some((profession) => item.profession._id === profession._id)
						);
			sheets[areaSheetName] = getAreasSheetData(
				filteredAreaSequenceItems,
				sequenceItemsBySequenceId.sequenceItems
			);
			sheets[floorSheetName] = getFloorsSheetData(
				filteredAreaSequenceItems,
				sequenceItemsBySequenceId.sequenceItems
			);
		} catch (e) {
			console.log(e);
		}
	}

	await exportService.exportMultipleSheetsToExcel(
		sheets,
		excelName,
		undefined,
		undefined,
		{ cellColor: 'FFFFFF', headerColor: '103045', columnWidth: 20, headerRow: 8 },
		undefined,
		sheetsInfoValues
	);
};

const generateSheetInfoValues = (projectName: string, time: string, sequenceName: string) => {
	return {
		[translationService.get('project')]: { rowNumber: 5, columnChar: 'A', value: projectName },
		[translationService.get('sequenceName')]: {
			rowNumber: 6,
			columnChar: 'A',
			value: sequenceName,
		},
		[translationService.get('date')]: {
			rowNumber: 5,
			columnChar: 'D',
			value: time,
		},
	};
};

const getAreasSheetData = (areaSequenceItems: IMergedAreaSequenceItem[], sequenceItems: ISequenceItem[]): IRow[] => {
	const rows: IRow[] = [];
	const areasFromAsis: IConfigArea[] = uniqBy(
		areaSequenceItems.map((item) => item.area),
		(area) => area.areaId
	);
	const sortedAreasFromAsis: IConfigArea[] = sortByAreas(areasFromAsis, SORT_ORDER.ASCENDING);

	for (let i = 0; i < sortedAreasFromAsis.length; i++) {
		const area: IConfigArea = sortedAreasFromAsis[i];
		const areaSequenceItemsByAreaId = areaSequenceItems.filter((item) => item.area.areaId === area.areaId);

		if (i === 0 || area.floorId !== sortedAreasFromAsis[i - 1].floorId) {
			rows.push(getBlankRow(areaSequenceItemsByAreaId, sequenceItems));
		}

		const row: IRow = {};
		row[translationService.get('floor')] = { value: area.shortFloorNick };
		row[translationService.get('area')] = { value: area.areaNick };

		for (const item of sequenceItems) {
			const currentAsi: IMergedAreaSequenceItem | undefined = areaSequenceItemsByAreaId.find(
				(asi) => asi.sequenceItemId === item._id
			);

			if (!currentAsi) {
				row[item.description] = {
					value: '',
					color: COLORS.grey100,
				};
				continue;
			}

			const colValue: string =
				currentAsi.status === AreaSequenceItemStatus.unplanned
					? ''
					: translationService.get(isAsiComplete(currentAsi.status) ? 'isDone' : currentAsi.status || '');
			row[currentAsi.description] = {
				value: colValue,
				color: getExcelColorFromStatus(currentAsi.status),
			};
		}
		rows.push(row);
	}
	return rows;
};

const getBlankRow = (areaSequenceItemsByAreaId: IMergedAreaSequenceItem[], sequenceItems: ISequenceItem[]): IRow => {
	const blankRow: IRow = {};
	blankRow[translationService.get('floor')] = {
		value: areaSequenceItemsByAreaId[0].area.floorNick,
		color: COLORS.grey100,
	};
	blankRow[translationService.get('area')] = {
		value: '',
		color: COLORS.grey100,
	};
	const sortedSequenceItems: ISequenceItem[] = sortBy(sequenceItems, (item) => item.orderIndex);
	for (const item of sortedSequenceItems) {
		blankRow[item.description] = {
			value: '',
			color: COLORS.grey100,
		};
	}
	return blankRow;
};

export const getFloorsSheetData = (
	areaSequenceItems: IMergedAreaSequenceItem[],
	sequenceItems: ISequenceItem[]
): IRow[] => {
	const rows: IRow[] = [];
	const asiSortedByFloor: IMergedAreaSequenceItem[] = sortByAreas(areaSequenceItems, SORT_ORDER.ASCENDING, 'area');
	const asiGroupedByFloor: Dictionary<IMergedAreaSequenceItem[]> = groupBy(asiSortedByFloor, 'area.floorId');
	Object.values(asiGroupedByFloor).forEach((floorAreaSequenceItems: IMergedAreaSequenceItem[]) => {
		const row: IRow = {};
		row[translationService.get('floor')] = { value: floorAreaSequenceItems[0].area.floorNick };

		const groupedBySequenceItemId: Dictionary<IMergedAreaSequenceItem[]> = groupBy(
			floorAreaSequenceItems,
			(areaSequenceItem: IMergedAreaSequenceItem) => areaSequenceItem.sequenceItemId
		);
		sequenceItems.map((seqItem: ISequenceItem) => {
			const asis: IMergedAreaSequenceItem[] = groupedBySequenceItemId[seqItem._id];

			if (!asis) {
				row[seqItem.description] = {
					value: ``,
					color: COLORS.grey100,
				};
				return;
			}
			const numberOfDoneSequenceItems: number = asis.filter((sequenceItem: IMergedAreaSequenceItem) =>
				isAsiComplete(sequenceItem.status)
			).length;
			const percentageDoneSequenceItems: number = Math.round((numberOfDoneSequenceItems / asis.length) * 100);
			row[seqItem.description] = {
				value: `${percentageDoneSequenceItems}%`,
				color: getExcelColorFromPercentage(percentageDoneSequenceItems),
			};
		});
		rows.push(row);
	});
	return rows;
};
