import React, { useMemo, useState } from 'react';
import { ITag } from '@interfaces/ITag';
import { getTradeGroupTranslation, getTradeTranslation } from '@utils/translations.utils';
import { GroupedMultipleDropdown } from '../../DesignSystem/GroupedMultipleDropdown/GroupedMultipleDropdown';
import { translationService } from '@src/servicesInitializers';
import { getTagName } from '@utils/tags.utils';
import { getProfessionDisplayText } from '@utils/professions.utils';
import { ProfessionDisplayWithTradeIcon } from '@shared/components/ProfessionDisplayWithTradeIcon/ProfessionDisplayWithTradeIcon';
import { useSelector } from 'react-redux';
import { selectProjectId } from '@store/slices/project.slice';
import { othersTradeGroup } from '@shared/constants/professions.constants';

interface IMultiTagsGroupedByTradeDropdownProps {
	staticTags: ITag[];
	onChange: (tags: ITag[]) => void;
}

export const MultiTagsGroupedByTradeDropdown = (props: IMultiTagsGroupedByTradeDropdownProps) => {
	const [selectedTags, setSelectedTags] = useState<ITag[] | undefined>([]);
	const projectId: string = useSelector(selectProjectId)!;

	const workersAndManagers: ITag[] = useMemo(() => {
		return props.staticTags
			.filter((tag) => (tag.profession || tag.manager) && tag.profession?.tradeGroup !== othersTradeGroup)
			.sort((a, b) => {
				if (a.manager && b.manager) {
					return a.manager.name.toLowerCase().localeCompare(b.manager.name.toLowerCase());
				}
				if (a.profession && b.profession) {
					return getTradeTranslation(a.profession)
						.toLowerCase()
						.localeCompare(getTradeTranslation(b.profession).toLowerCase());
				}
				if (a.manager) {
					return -1;
				}
				return 1;
			});
	}, [props.staticTags]);

	const onTagChange = (value: ITag[]) => {
		setSelectedTags(value);
		props.onChange(value);
	};

	const getOptionLabel = (tag: ITag): React.ReactNode => {
		return `${tag.tagNick} - ${getTagName(tag)}`;
	};

	const groupByFunction = (option: ITag) => {
		if (option.profession) {
			return getProfessionDisplayText(option.profession);
		}
		return translationService.get('Managers');
	};

	return (
		<GroupedMultipleDropdown<ITag>
			options={workersAndManagers}
			value={selectedTags}
			onChange={onTagChange}
			placeholder={translationService.get('nameOrTagId')}
			required
			getDisplayOption={getOptionLabel}
			optionSelectedKey={'tagId'}
			testId={'multiProfessionGroupedDropdown'}
			groupByFunction={groupByFunction}
			totalSelectedInputText={translationService.get('tags')}
			getGroupDisplayOption={(option: ITag) => {
				if (option.profession) {
					return <ProfessionDisplayWithTradeIcon profession={option.profession} projectId={projectId} />;
				}
			}}
			getTopLevelGroup={(option: ITag) => {
				if (option.profession) {
					return getTradeGroupTranslation(option.profession);
				}
				return '';
			}}
		/>
	);
};
