import { ISafetyReport, ISafetyReportReducerState } from '../../interfaces';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: ISafetyReportReducerState = {
	safetyReport: {},
};

const safetyReportSlice = createSlice({
	name: 'safetyReport',
	initialState,
	reducers: {
		updateSafetyReport: (state: ISafetyReportReducerState, action: PayloadAction<{ report: ISafetyReport }>) => {
			state.safetyReport = action.payload.report;
		},
		resetSafetyReport: (state: ISafetyReportReducerState) => {
			state.safetyReport = {};
		},
	},
});

export const { updateSafetyReport, resetSafetyReport } = safetyReportSlice.actions;
export default safetyReportSlice.reducer;
