import { IFloor, IManager, IProfession } from '../interfaces';
import {
	IActivitiesFloorReport,
	IActivitiesProfessionReport,
	IActivitiesReport,
	IActivityReport,
	IRoundedActivityReport,
} from '../interfaces/IActivitiesReport';
import { mapValues, pickBy } from 'lodash';
import { IActivityLabel } from '../interfaces/IActivityLabel';

export const getVisibleActivityReportByFloors = (
	visibleFloors: IFloor[],
	currentVisibleActivityReport: IActivitiesReport
): IActivitiesReport => {
	if (visibleFloors.length === 0) {
		return currentVisibleActivityReport;
	}

	const reportWithFilteredFloorsData = mapValues(
		currentVisibleActivityReport,
		(visibleProfessionReport: IActivitiesProfessionReport) => {
			const floorsForProfession = pickBy(
				visibleProfessionReport.floors,
				(floorValue: IActivitiesFloorReport, floorKey: string) => {
					return visibleFloors.some((floorToFilterBy: IFloor) => floorToFilterBy.floorId === floorKey);
				}
			);
			return {
				...visibleProfessionReport,
				floors: floorsForProfession,
			};
		}
	);

	const finalReport: IActivitiesReport = pickBy(
		reportWithFilteredFloorsData,
		(professionReport: IActivitiesProfessionReport) => {
			return Object.keys(professionReport.floors).length > 0;
		}
	);

	return finalReport;
};

export const getVisibleActivityReportByProfessions = (
	visibleProfessions: IProfession[],
	currentActivityReport: IActivitiesReport
): IActivitiesReport => {
	if (visibleProfessions.length === 0) {
		return currentActivityReport;
	}

	const reportWithFilteredProfessionsData: IActivitiesReport = pickBy(
		currentActivityReport,
		(professionReport: IActivitiesProfessionReport, professionKey: string) => {
			return visibleProfessions.some(
				(professionToFilterBy: IProfession) => professionToFilterBy._id === professionKey
			);
		}
	);

	return reportWithFilteredProfessionsData;
};

export const getVisibleActivityReportByManagers = (
	visibleManagers: IManager[],
	currentVisibleActivityReport: IActivitiesReport,
	shouldFilterByManager: boolean
): IActivitiesReport => {
	if (visibleManagers.length === 0 || !shouldFilterByManager) {
		return currentVisibleActivityReport;
	}
	return getVisibleActivityReportByActivityField(currentVisibleActivityReport, (activity: IActivityReport) =>
		visibleManagers.some((manager: IManager) => manager._id === activity.manager?._id)
	);
};

export const getVisibleActivityReportByLabels = (
	visibleLabels: IActivityLabel[],
	currentVisibleActivityReport: IActivitiesReport
): IActivitiesReport => {
	if (visibleLabels.length === 0) {
		return currentVisibleActivityReport;
	}

	return getVisibleActivityReportByActivityField(currentVisibleActivityReport, (activity: IActivityReport) =>
		visibleLabels.some((label: IActivityLabel) => label._id === activity.label?._id)
	);
};

export const getVisibleActivityReportByActivityField = (
	currentVisibleActivityReport: IActivitiesReport,
	filterActivityCallback: (activity: IActivityReport) => boolean
): IActivitiesReport => {
	const reportWithFilteredData: IActivitiesReport = mapValues(
		currentVisibleActivityReport,
		(visibleProfessionReport: IActivitiesProfessionReport) => {
			const filteredFloorsActivityReport = mapValues(
				visibleProfessionReport.floors,
				(visibleFloorReport: IActivitiesFloorReport) => {
					const filteredActivityReport: IRoundedActivityReport[] =
						visibleFloorReport.activities.filter(filterActivityCallback);

					return {
						...visibleFloorReport,
						activities: filteredActivityReport,
					};
				}
			);

			return {
				...visibleProfessionReport,
				floors: filteredFloorsActivityReport,
			};
		}
	);

	const reportFilteredWithEmptyFloors: IActivitiesReport = mapValues(
		reportWithFilteredData,
		(professionReport: IActivitiesProfessionReport) => {
			const report: { [floorId: string]: IActivitiesFloorReport } = pickBy(
				professionReport.floors,
				(floorReport: IActivitiesFloorReport) => {
					return floorReport.activities.length > 0;
				}
			);

			return {
				...professionReport,
				floors: report,
			};
		}
	);

	const finalReport: IActivitiesReport = pickBy(
		reportFilteredWithEmptyFloors,
		(professionReport: IActivitiesProfessionReport) => {
			return Object.keys(professionReport.floors).length > 0;
		}
	);

	return finalReport;
};
