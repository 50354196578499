import React, { useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { IFloor } from '../../../../../interfaces';
import { MultipleVerticalBars } from './MultipleVerticalBars';
import { sortBy } from 'lodash';
import { ISingleVerticalBarData } from './interfaces/ISingleVerticalBarData';
import { IPeriodicProductivityFloorReport } from '../../../../../interfaces/IProductivityReport';
import { IRootState } from '../../../../../store/slices';
import { IRoundedProductivityProfessionReport } from '../../../../../interfaces/IRoundedProductivityReport';

const useStyles = makeStyles((theme: Theme) => ({
	graphContainer: {
		width: 'auto',
		height: '100%',
		overflowX: 'auto',
		overflowY: 'hidden',
		paddingBottom: 16,
	},
}));

interface IActivityHoursPerFloorGraphProps {
	professionProductivityReport: IRoundedProductivityProfessionReport;
}

const ActivityHoursPerFloorGraph = (props: IActivityHoursPerFloorGraphProps) => {
	const floors: IFloor[] = useSelector((state: IRootState) => state.floorsListReducer.floorsList);
	const [barDataList, setBarDataList] = useState<ISingleVerticalBarData[]>([]);
	const [floorsToHoursObject, setFloorsToHoursObject] = useState<{
		[floorId: string]: number;
	}>();

	useEffect(() => {
		if (!props.professionProductivityReport.floors) {
			setFloorsToHoursObject({});
			return;
		}

		const floorsToHoursObject: { [floorId: string]: number } = floors.reduce(
			(floorsToHoursObject: { [floorId: string]: number }, floor: IFloor) => {
				if (props.professionProductivityReport.floors[floor.floorId]) {
					floorsToHoursObject[floor.floorId] = (
						props.professionProductivityReport.floors[floor.floorId] as IPeriodicProductivityFloorReport
					).totalHours;
				} else {
					floorsToHoursObject[floor.floorId] = 0;
				}

				return floorsToHoursObject;
			},
			{}
		);
		setFloorsToHoursObject(floorsToHoursObject);
	}, [floors.length, props.professionProductivityReport.floors]);

	const categories = sortBy(floors, (floor: IFloor) => Number(floor.floorId)).map(
		(floor: IFloor) => floor.shortFloorNick
	);

	useEffect(() => {
		if (!floorsToHoursObject) {
			setBarDataList([]);
			return;
		}

		const sortedFloorsToHourTupleList: [string, number][] = sortBy(
			Object.entries(floorsToHoursObject),
			([floorId, hours]: [string, number]) => Number(floorId)
		);
		const barDataList: ISingleVerticalBarData[] = sortedFloorsToHourTupleList.length
			? sortedFloorsToHourTupleList.map(([floorId, hours]: [string, number]) => ({
					barNumber: hours || 0,
					backgroundColor: hours ? '#208ffb' : '#d4d4d4',
			  }))
			: categories.map(() => ({
					barNumber: 0,
					backgroundColor: '#d4d4d4',
			  }));
		setBarDataList(barDataList);
	}, [floorsToHoursObject]);

	const classes = useStyles();

	return (
		<div className={classes.graphContainer}>
			<MultipleVerticalBars
				dataList={barDataList}
				barWidth={40}
				barMinHeight={7}
				numberLabelVisible={true}
				tickInCenter={true}
				categories={categories}
			/>
		</div>
	);
};

export { ActivityHoursPerFloorGraph };
