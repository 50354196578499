import React from 'react';
import classes from './styles.module.scss';
import classnames from 'classnames';
import { DropdownTextInput } from '../../../../Inputs/TrusstorTextInput/DropdownTextInput/DropdownTextInput';
import DropdownItemCard from '../DropdownDisplay/DropdownItemCard/DropdownItemCard';
import { IDropdownDisplayProps } from '../DropdownDisplay/DropdownDisplay';
import { IGroupedDropdownDisplayProps } from '../../dropdown.interface';
import { DropdownGroupCard } from '../DropdownDisplay/DropdownGroupCard/DropdownGroupCard';
import { useSharedServices } from '../../../../../hooks/sharedServices.context';
import { getTopLevelGroup } from './utils';

export interface IGroupedSingleDropdownDisplayProps<T>
	extends Omit<IDropdownDisplayProps<T>, 'handleOptionHover' | 'filteredOptions'>,
		IGroupedDropdownDisplayProps<T> {
	handleGroupSelect?: (groupKey: string, isChecked: boolean) => void;
	handleGroupHover?: (groupIndex: number) => void;
	handleSelectAllClick?: (isChecked: boolean) => void;
}

function selectAllOption<T>(props: IGroupedSingleDropdownDisplayProps<T>) {
	const { translationService } = useSharedServices();
	const allOptions: T[] = Object.values(props.groupedOptions).flat() as T[];
	const areAllOptionsChecked: boolean = allOptions.every((option) => props.getIsSelected(option));
	return (
		<div className={classes.groupContainer}>
			<DropdownGroupCard
				title={translationService.get('selectAll')}
				showCheckbox
				isHighlighted={props.highlightedGroupIndex === -1 && props.highlightedIndex === -1}
				isSelected={areAllOptionsChecked}
				handleGroupSelect={(groupKey, isChecked) => props.handleSelectAllClick!(isChecked)}
				handleGroupHover={() => props.handleGroupHover?.(-1)}
				titleClassName={classes.selectAllTitle}
				secondaryText={`${allOptions.length}`}
				secondaryTextClassName={classes.selectAllSecondaryText}
			/>
		</div>
	);
}

function topLevelGroupOption<T>(
	props: IGroupedSingleDropdownDisplayProps<T>,
	groupKey: string,
	alreadyShownTopLevelGroups: string[]
): JSX.Element | null {
	if (!props.topLevelGroups) {
		return null;
	}
	const topLevelGroup: string | undefined = getTopLevelGroup(props.topLevelGroups, groupKey);
	if (topLevelGroup) {
		const isInAlreadyShownTopLevelGroup: boolean = alreadyShownTopLevelGroups.includes(topLevelGroup);
		if (!isInAlreadyShownTopLevelGroup) {
			alreadyShownTopLevelGroups.push(topLevelGroup);
		} else {
			return null;
		}
	} else {
		return null;
	}
	return (
		<div className={classes.topLevelGroupContainer}>
			<div className={classes.topLevelGroupTitle}>
				<DropdownGroupCard
					title={topLevelGroup}
					showCheckbox={false}
					isHighlighted={false}
					isSelected={false}
					handleGroupHover={() => {}}
					titleClassName={classes.topLevelGroupTitle}
				/>
			</div>
		</div>
	);
}

export function GroupedDropdownDisplay<T>(props: IGroupedSingleDropdownDisplayProps<T>) {
	const alreadyShownTopLevelGroups: string[] = [];
	const [openedGroupKeys, setOpenedGroupKeys] = React.useState<string[]>([]);

	const handleGroupClick = (groupKey: string, isOpen: boolean) => {
		if (isOpen) {
			setOpenedGroupKeys((prev) => prev.filter((key) => key !== groupKey));
		} else {
			setOpenedGroupKeys((prev) => [...prev, groupKey]);
		}
	};
	return (
		<div
			className={classes.dropdownContainer}
			onKeyDown={props.handleKeyDown}
			data-testid={props.testId}
			ref={props.dropdownRef}
			tabIndex={0}
		>
			<DropdownTextInput
				ref={props.inputRef}
				sendFullEvent={props.sendFullEvent}
				handleClearClickCallback={props.handleClearClickCallback}
				setDropdownOpen={props.setIsDropdownOpen}
				isDropdownOpen={props.isDropdownOpen}
				changeFunc={props.handleInputChange}
				value={props.valueForInput}
				placeholder={props.placeholder}
				label={props.label}
				disabled={props.disabled}
				required={props.required}
				handleInputFocusChange={props.handleInputFocusChange}
				hideClearTextButton={props.hideClearTextButton}
				isError={props.isError}
				errorText={props.errorText}
				shouldNotSelectInputTextOnFocus={props.shouldNotSelectInputTextOnFocus}
				inputContainerClassName={props.inputContainerClassName}
			/>
			{props.isDropdownOpen && props.sortedGroupKeys.length > 0 && (
				<div className={classnames(classes.dropdownOptions)} ref={props.optionsContainerRef}>
					{props.handleSelectAllClick && selectAllOption(props)}
					{props.sortedGroupKeys.map((groupKey, groupIndex) => {
						const isGroupSelected: boolean = props.groupedOptions[groupKey].every((option) =>
							props.getIsSelected(option)
						);

						return (
							<div className={classes.groupContainerWithTopLevelGroup}>
								{props.topLevelGroups &&
									topLevelGroupOption(props, groupKey, alreadyShownTopLevelGroups)}
								<div key={groupKey} className={classes.groupContainer}>
									<DropdownGroupCard
										title={groupKey}
										showCheckbox={props.showCheckbox}
										isHighlighted={
											props.highlightedGroupIndex === groupIndex && props.highlightedIndex === -1
										}
										isSelected={isGroupSelected}
										handleGroupSelect={props.handleGroupSelect}
										handleGroupHover={() => props.handleGroupHover?.(groupIndex)}
										getGroupDisplayOption={() => {
											return props.getGroupDisplayOption?.(props.groupedOptions[groupKey][0]);
										}}
										noBorders={
											props.topLevelGroups && !!getTopLevelGroup(props.topLevelGroups, groupKey)
										}
										handleGroupClick={props.topLevelGroups && handleGroupClick}
										arrowOpen={props.topLevelGroups && openedGroupKeys.includes(groupKey)}
										secondaryText={
											props.topLevelGroups && props.groupedOptions[groupKey].length.toString()
										}
									/>
									{(!props.topLevelGroups || openedGroupKeys.includes(groupKey)) &&
										props.groupedOptions[groupKey].map((option, index) => (
											<div
												className={classnames({
													[classes.indentation]: props.showCheckbox,
													[classes.moreIdentation]:
														props.topLevelGroups &&
														!!getTopLevelGroup(props.topLevelGroups, groupKey),
												})}
											>
												<DropdownItemCard
													showCheckbox={props.showCheckbox}
													isSelected={props.getIsSelected(option)}
													isHighlighted={
														props.highlightedIndex === index &&
														props.highlightedGroupIndex === groupIndex
													}
													option={option}
													index={index}
													handleOptionClick={props.handleOptionClick}
													handleOptionHover={() => props.handleOptionHover(index, groupIndex)}
													inputValue={props.valueForInput}
													optionDisplayValue={props.getDisplayOption(option)}
													optionSecondDisplayValue={props.getSecondDisplayOption?.(option)}
												/>
											</div>
										))}
								</div>
							</div>
						);
					})}
					{props.footer && <div className={classes.footer}>{props.footer}</div>}
				</div>
			)}
		</div>
	);
}
