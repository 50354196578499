import { ITableComponentProps } from '@interfaces/ITableComponentProps';
import { ISequenceItemProgressBySequenceItemReport } from '@interfaces/ISequenceItemProgressBySequenceItemReport';
import React, { ReactElement } from 'react';
import classes from '@src/Components/SequenceProgressAnalysis/ActivitiesProgress/ActivitiesProgressTable/styles.module.scss';
import { translationService } from '@src/servicesInitializers';
import {
	getDemoAverageWeeklyProgressRate,
	isProgressReportCompleted,
	isRateBelowThreshold,
} from '@src/Components/SequenceProgressAnalysis/ActivitiesProgress/ActivitiesProgressTable/utils';
import { IProject } from '@interfaces/index';
import { useSelector } from 'react-redux';
import { selectWorkingProject } from '@store/slices/project.slice';
import { TrendCell } from '@src/Components/SequenceProgressAnalysis/ActivitiesProgress/ActivitiesProgressTable/cellComponents/TrendCell';

export const AverageWeeklyProgressRateCell = (
	props: ITableComponentProps<ISequenceItemProgressBySequenceItemReport>
): ReactElement<ITableComponentProps<ISequenceItemProgressBySequenceItemReport>> => {
	const report: ISequenceItemProgressBySequenceItemReport = props.data;
	const isCompleted: boolean = isProgressReportCompleted(report);
	const project: IProject = useSelector(selectWorkingProject)!;
	const isDemoProject: boolean = !!project.isDemo;

	if (isCompleted) {
		return <div />;
	}

	if (isRateBelowThreshold(report) && !isDemoProject) {
		return <div className={classes.averageWeeklyProgressRateEmptyState}>{translationService.get('TBD')}</div>;
	}

	const roundedRate: number = Number(report.avgWeeklyProgressRate.toFixed(1));
	const rateToShow: number = roundedRate < 0 ? 0 : roundedRate;

	return (
		<div className={classes.averageWeeklyProgressRateCellContainer}>
			<div className={classes.averageWeeklyProgressRateCell}>{rateToShow.toString()}</div>
			<TrendCell report={props.data} />
		</div>
	);
};
