import React, { useContext, useEffect } from 'react';
import { IDashboardTabItem } from '@interfaces/IDashboardTabItem';
import { DashboardTabTypes } from '@interfaces/DashboardTabTypes.enum';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '@store/slices';
import classes from './styles.module.scss';
import { DashboardTabsSection } from '../../DashboardSections/DashboardTabsSection/DashboardTabsSection';
import WorkForceTab from '../../DashboardSections/DashboardTabsSection/WorkForceTab/WorkForceTab';
import { WorkplanWidget } from '@src/Components/WorkplanWidgetComponents/WeeklyWidget/WorkplanWidget/WorkplanWidget';
import { Alerts } from '../../Safety/Alerts';
import { IMinimalSafetyEventForClient } from '@interfaces/ISafetyEvent';
import { ProjectSelector } from '@src/Components/Dropdowns/ProjectSelector/ProjectSelector';
import { IActiveManager } from '@store/slices/managers.slice';
import { IMergedFloorView, IProject } from '@src/interfaces';
import { getDashboardTabsItems } from '@src/Components/MainPageSections/utils';
import { FloorViewWrapper } from '@src/Components/MainPageSections/FloorView/FloorViewWrapper/FloorViewWrapper';
import { updateMainPageLayout } from '@store/thunks/mainPageLayout.thunks';
import { selectActiveMainPageLayout } from '@store/slices/mainPageLayout.slice';
import { getProjectInitialSelectedTab } from '@utils/projectAccessLevel.utils';
import { MainPageContext } from '@src/Components/Pages/MainPage';
import { MainPageIssuesTab } from '@src/Components/MainPageIssuesTab/MainPageIssuesTab';
import { translationService } from '@src/servicesInitializers';
import { getOngoingActivities } from '@shared/utils/mainPageActivities.utils';
import { HTTPRequestStatuses } from '@shared/interfaces/HTTPRequestStatuses.enum';
import { selectIsLoadingDashboardData } from '@store/slices/dashboard.slice';

export const MainPageDashboard = () => {
	const dispatch = useDispatch();
	const selectedFloor: IMergedFloorView | undefined = useSelector(
		(state: IRootState) => state.floorsViewReducer.selectedFloor
	);
	const safetyEvents: IMinimalSafetyEventForClient[] = useSelector(
		(state: IRootState) => state.safetyEventsReducer.safetyEvents
	);
	const safetyEventsRequestStatus: HTTPRequestStatuses = useSelector(
		(state: IRootState) => state.safetyEventsReducer.safetyEventsRequestStatus
	);
	const isLoadingSafetyEvents: boolean = safetyEventsRequestStatus !== HTTPRequestStatuses.success;

	const project: IProject | undefined = useSelector((state: IRootState) => state.projectReducer.workingProject);
	const activeManagers: IActiveManager[] = useSelector((state: IRootState) => state.managersReducer.activeManagers);
	const currentActiveMainPageLayout: DashboardTabTypes | null = useSelector(selectActiveMainPageLayout);
	const selectedTabType: DashboardTabTypes = getProjectInitialSelectedTab(currentActiveMainPageLayout, project);
	const isFetchingDashboardPersonnelData: boolean = useSelector(selectIsLoadingDashboardData);
	const workersOnSite: number = useSelector((state: IRootState) => state.dashboardReducer.workersOnSite).length;
	const { widgetActivities, issues, areIssuesFetched, areWidgetActivitiesFetched } = useContext(MainPageContext)!;

	useEffect(() => {
		if (!currentActiveMainPageLayout) {
			updateMainPageLayout(selectedTabType)(dispatch);
		}
	}, [currentActiveMainPageLayout]);
	const handleTabClick = (selected: DashboardTabTypes) => {
		updateMainPageLayout(selected)(dispatch);
	};

	const getTabContent = (): JSX.Element => {
		switch (selectedTabType) {
			case DashboardTabTypes.workForce:
				return <WorkForceTab />;
			case DashboardTabTypes.activities:
				return <WorkplanWidget widgetActivities={widgetActivities} widgetHeaderClass={classes.widgetHeader} />;
			case DashboardTabTypes.issues:
				return (
					<MainPageIssuesTab
						issues={issues}
						emptyStateText={translationService.get('mainPage_issuesTab_emptyState')}
						quickFiltersClassName={classes.widgetHeader}
					/>
				);
			case DashboardTabTypes.safetyEvents:
				return <Alerts />;
		}
	};

	const dashboardItems: IDashboardTabItem[] = getDashboardTabsItems(
		isFetchingDashboardPersonnelData ? undefined : workersOnSite,
		isFetchingDashboardPersonnelData ? undefined : activeManagers.length,
		areWidgetActivitiesFetched ? getOngoingActivities(widgetActivities).length : undefined,
		isLoadingSafetyEvents ? undefined : safetyEvents.length,
		areIssuesFetched ? issues.length : undefined,
		project
	);
	return (
		<div className={classes.MainPageDashboardContainer}>
			<div className={classes.projectSelectorContainer}>
				<ProjectSelector bigSelector />
			</div>
			<FloorViewWrapper floor={selectedFloor} />
			<DashboardTabsSection dashboardItems={dashboardItems} onTabClick={handleTabClick} />
			<div className={classes.contentSection}>{getTabContent()}</div>
		</div>
	);
};
