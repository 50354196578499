import { useEffect, useMemo, useState } from 'react';
import {
	getCompletedActivities,
	getInProgressActivities,
	getNoProgressActivities,
	getNotOverdueActivities,
	getOngoingActivities,
	getOverdueActivities,
	getTodayProgressActivities,
	getWidgetActivitiesFiltered,
	getWidgetForReviewActivities,
} from '../utils/mainPageActivities.utils';
import { IMainPageWidgetActivityData as IMainPageWidgetActivityDataShared } from '../interfaces/IMainPageWidgetActivityData';
import { IProfession } from '../interfaces/IProfession';
import { WidgetActivityFilters } from '../interfaces/WidgetActivityFilters';

type IMainPageWidgetActivityData = IMainPageWidgetActivityDataShared<IProfession>;

export const useFilteredMainPageActivities = (
	activities: IMainPageWidgetActivityData[],
	selectedFilter: WidgetActivityFilters,
	tz: string
): {
	selectedActivities: IMainPageWidgetActivityData[] | undefined;
	overdueActivities: IMainPageWidgetActivityData[] | undefined;
	noProgressActivities: IMainPageWidgetActivityData[] | undefined;
	dueInSevenDaysActivities: IMainPageWidgetActivityData[] | undefined;
	futureActivities: IMainPageWidgetActivityData[] | undefined;
	forReviewActivities: IMainPageWidgetActivityData[] | undefined;
	completedActivities: IMainPageWidgetActivityData[] | undefined;
	inProgressActivities: IMainPageWidgetActivityData[] | undefined;
	dueTodayActivities: IMainPageWidgetActivityData[] | undefined;
	todayProgressActivities: IMainPageWidgetActivityData[] | undefined;
	toDoActivitiesCount: number;
} => {
	const [selectedActivities, setSelectedActivities] = useState<IMainPageWidgetActivityData[] | undefined>(undefined);

	const overdueActivities: IMainPageWidgetActivityData[] | undefined = useMemo(
		() => getOverdueActivities(activities, tz),
		[activities, tz]
	);

	const noProgressActivities: IMainPageWidgetActivityData[] | undefined = useMemo(
		() => getNoProgressActivities(activities),
		[activities]
	);

	const inProgressActivities: IMainPageWidgetActivityData[] | undefined = useMemo(() => {
		return getInProgressActivities(activities);
	}, [activities]);

	const todayProgressActivities: IMainPageWidgetActivityData[] | undefined = useMemo(() => {
		return getTodayProgressActivities(activities, tz);
	}, [activities]);

	const { dueInSevenDaysActivities, futureActivities, dueTodayActivities } = useMemo(() => {
		return getNotOverdueActivities(activities, tz);
	}, [activities]);

	const forReviewActivities: IMainPageWidgetActivityData[] | undefined = useMemo(
		() => getWidgetForReviewActivities(activities),
		[activities]
	);

	const completedActivities: IMainPageWidgetActivityData[] | undefined = useMemo(
		() => getCompletedActivities(activities),
		[activities]
	);

	const toDoActivitiesCount: number = getOngoingActivities(activities).length;

	useEffect(() => {
		if (!activities || !selectedFilter) {
			return;
		}
		const filteredFloorWidgetActivities: IMainPageWidgetActivityData[] = getWidgetActivitiesFiltered(
			activities,
			selectedFilter,
			tz
		);
		setSelectedActivities(filteredFloorWidgetActivities);
	}, [selectedFilter, activities]);

	return {
		selectedActivities,
		overdueActivities,
		noProgressActivities,
		dueInSevenDaysActivities,
		futureActivities,
		forReviewActivities,
		completedActivities,
		toDoActivitiesCount,
		inProgressActivities,
		dueTodayActivities,
		todayProgressActivities,
	};
};
