import React from 'react';
import { FilterCard, IFilterCardProps } from '../FilterCard/FilterCard';
import { ManagerChip } from '../../Chips/ManagerChip/ManagerChip';

interface IManagerFilterCardProps extends IFilterCardProps {
	name: string;
	managerTradeText: string;
}

export const ManagerFilterCard = (props: IManagerFilterCardProps) => {
	return (
		<FilterCard
			{...props}
			text={props.name}
			endComponent={<ManagerChip managerTradeText={props.managerTradeText} />}
		/>
	);
};
