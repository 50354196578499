import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TrusstorBox } from '../../../../../Boxes/Containers/TrusstorBox';
import { updateVisiblePeriod } from '@store/slices/datePicker.slice';
import { datePickerPeriodSelection_BI } from '@utils/bi.utils';
import { IRootState } from '@store/slices';
import classes from './styles.module.scss';
import { PeriodSelectorTrusstorInput } from '@src/Components/DateSelector/PeriodSelectorTrusstorInput/PeriodSelectorTrusstorInput';
import { translationService } from '@src/servicesInitializers';

interface IDatePeriodPickerSectionProps {
	source?: string;
}

const PeriodPickerSection = (props: IDatePeriodPickerSectionProps) => {
	const tz = useSelector((state: IRootState) => state.projectReducer.workingProject!.tz);
	const [selectedPeriod, setSelectedPeriod] = React.useState<[Date, Date] | undefined>(undefined);

	const dispatch = useDispatch();

	const updatePeriod = (period: [Date, Date]) => {
		const periodTimestamps: [number, number] = [period[0].getTime(), period[1].getTime()];
		datePickerPeriodSelection_BI(periodTimestamps, tz, props.source);
		dispatch(updateVisiblePeriod({ visiblePeriodTimestamps: periodTimestamps }));
		setSelectedPeriod(period);
	};

	return (
		<TrusstorBox customStyleClass={`${classes.dateYellowBox}`}>
			<PeriodSelectorTrusstorInput
				rootClassName={classes.datePickerRoot}
				hideIcon
				showSingleMonth
				updatePeriod={updatePeriod}
				datePeriod={selectedPeriod}
				calendarPosition={translationService.getIsRtl() ? 'bottom-end' : 'bottom-start'}
			/>
		</TrusstorBox>
	);
};

export { PeriodPickerSection };
