import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISnackbarReducerState } from 'src/interfaces/ISanckbarReducerState';
import { alertType } from 'src/interfaces/SnackbarAlertTypes';
import { IRootState } from './index';

const initialState: ISnackbarReducerState = {
	open: false,
	snackbarSecondaryMessage: '',
	snackbarMessage: '',
	vertical: 'top',
	horizontal: 'center',
	backgroundColor: undefined,
	color: undefined,
	id: '',
};

const snackbarSlice = createSlice({
	name: 'snackbar',
	initialState,
	reducers: {
		showSnackbar: (
			state: ISnackbarReducerState,
			action: PayloadAction<{
				message: string;
				secondaryMessage?: string;
				alertType?: alertType;
				vertical: 'top' | 'bottom';
				horizontal: 'left' | 'center' | 'right';
				color?: string;
				backgroundColor: string;
				id: string;
			}>
		) => {
			state = Object.assign(state, initialState);
			state.open = true;
			state.snackbarMessage = action.payload.message;
			state.snackbarSecondaryMessage = action.payload.secondaryMessage;
			state.alertType = action.payload.alertType;
			state.vertical = action.payload.vertical;
			state.horizontal = action.payload.horizontal;
			state.backgroundColor = action.payload.backgroundColor;
			state.color = action.payload.color;
			state.id = action.payload.id;
		},
		setSnackbarMessage: (state: ISnackbarReducerState, action: PayloadAction<{ message: string }>) => {
			state.snackbarMessage = action.payload.message;
		},
		hideSnackbar: (state: ISnackbarReducerState) => {
			state.open = false;
		},
	},
});

export const selectSnackbarState = (state: IRootState): ISnackbarReducerState => state.snackbarReducer;
export const { hideSnackbar, setSnackbarMessage, showSnackbar } = snackbarSlice.actions;
export default snackbarSlice.reducer;
