import React, { useCallback, useMemo, useState } from 'react';
import classes from './styles.module.scss';
import { MatrixRow } from '@src/Components/Matrix/MatrixRow/MatrixRow';
import { IMatrixOptions } from '@src/Components/Matrix/interfaces/IMatrixOptions';
import { IMatrixTable } from '@src/Components/Matrix/interfaces/IMatrixTable';
import { IMatrixStylesOptions } from '@src/Components/Matrix/interfaces/IMatrixStylesOptions';
import { MatrixHeader } from '@src/Components/Matrix/cellComponents/MatrixHeader/MatrixHeader';
import { useElementIntersecting } from '@shared/hooks/useElementIntersecting.hooks';
import { translationService } from '@src/index';
import classnames from 'classnames';
import { compareProps } from '../../../utils/generalUtils';
import { at, without } from 'lodash';

export interface IMatrixTableProps {
	table: IMatrixTable;
	options: IMatrixOptions;
	styleOptions?: IMatrixStylesOptions;
	onTableIntersectingChange?: (tableId: string, isIntersecting: boolean, tableIndex: number) => void;
	matrixElement: HTMLDivElement | null;
	separatorHeight: number;
	isTheLastTable?: boolean;
	hideRowHeader?: boolean;
	showEmptyRows?: boolean;
	tableIndex: number;
	rowLength: number;
	columnsIndexesToShow?: number[];
	hoveredRowId?: string;
	onMouseLeave?: () => void;
}

export const MatrixTable = React.memo((props: IMatrixTableProps) => {
	const [headerElement, setHeaderElement] = useState<HTMLElement | null>(null);
	const [showTable, setShowTable] = useState<boolean>(false);

	const intersectionCallback = useCallback(
		(isIntersecting: boolean) => {
			if (!isIntersecting) {
				return;
			}
			props.onTableIntersectingChange?.(props.table.id, isIntersecting, props.tableIndex);
		},
		[props.onTableIntersectingChange, props.table.id, props.tableIndex]
	);

	const intersectionOptions: IntersectionObserverInit = useMemo(() => {
		const matrixHeight: number = props.matrixElement?.getBoundingClientRect().height || 0;
		const colHeaderHeight: number = parseInt(props.styleOptions?.colHeaderHeight?.split('px')[0] || '0');
		const targetHeaderHeight: number = headerElement?.getBoundingClientRect().height || 0;
		const bottomRootMargin: number = -matrixHeight + colHeaderHeight + targetHeaderHeight;

		return {
			threshold: 0,
			rootMargin: `-${colHeaderHeight}px 0px ${bottomRootMargin}px 0px`,
			root: props.matrixElement,
		};
	}, [props.matrixElement, props.styleOptions?.colHeaderHeight, headerElement]);

	useElementIntersecting(headerElement, intersectionCallback, intersectionOptions);

	const showTableIntersectingCallback = useCallback(
		(isIntersecting: boolean) => {
			setShowTable(isIntersecting);
		},
		[setShowTable]
	);

	const showTableIntersectionOptions: IntersectionObserverInit = useMemo(() => {
		return {
			threshold: 0,
			rootMargin: `400px 0px 800px 0px`,
			root: props.matrixElement,
		};
	}, [props.matrixElement]);

	useElementIntersecting(headerElement, showTableIntersectingCallback, showTableIntersectionOptions);

	const getBlankDivHeight = (): number => {
		const cellHeight: number = parseInt(props.styleOptions?.cellHeight || '50');
		const colHeaderHeight: number = parseInt(props.styleOptions?.colHeaderHeight || '120');
		const matrixHeight: number = props.matrixElement?.getBoundingClientRect().height || 0;
		const blankHeight: number =
			matrixHeight -
			colHeaderHeight -
			cellHeight * props.table.rows.length -
			(headerElement?.getBoundingClientRect().height || 0) * 1.5;
		if (blankHeight > matrixHeight) return 0;
		return blankHeight;
	};

	const scrollMarginTop: number = parseInt(props.styleOptions?.colHeaderHeight || '0') + props.separatorHeight;

	const getIsRowHovered = (row) => {
		return props.hoveredRowId !== undefined && props.hoveredRowId === row[0]?.area?.areaId;
	};

	return (
		<div onMouseLeave={props.onMouseLeave}>
			{!props.hideRowHeader && (
				<div
					className={classes.commonHeader}
					ref={setHeaderElement}
					style={{
						height: props.separatorHeight,
						top: props.styleOptions?.colHeaderHeight
							? `calc(${props.styleOptions?.colHeaderHeight} - 3px`
							: 'unset',
					}}
				>
					{!props.options.commonHeaderComponent ? (
						<MatrixHeader text={props.table.commonHeader} />
					) : (
						<props.options.commonHeaderComponent data={props.table.commonHeader} />
					)}
				</div>
			)}
			<thead
				id={props.table.id}
				className={classnames({
					[classes.rtlTable]: translationService.getIsRtl(),
					[classes.ltrTable]: !translationService.getIsRtl(),
				})}
				style={{ scrollMarginTop: scrollMarginTop }}
			>
				{props.table.rows.map((row, rowIndex: number) => {
					const rowToShow: number[] = props.columnsIndexesToShow
						? without(at(row, props.columnsIndexesToShow), undefined, null, false)
						: row;
					return (
						<MatrixRow
							isRowHovered={getIsRowHovered(row)}
							rowLength={props.rowLength}
							key={rowIndex}
							row={(showTable || props.hideRowHeader) && !props.table.isEmpty ? rowToShow : []}
							rowHeaderData={props.table.rowHeaders[rowIndex]}
							options={props.options}
							styleOptions={props.styleOptions}
						/>
					);
				})}
				{props.isTheLastTable && <div style={{ minHeight: `${getBlankDivHeight()}px` }} />}
			</thead>
		</div>
	);
}, compareProps);
