import React from 'react-dom';
import { IProfession } from '@shared/interfaces/IProfession';
import TrusstorTooltip from '@shared/components/TrusstorTooltip';
import classes from './styles.module.scss';
import { useId, useRef } from 'react';
import { useSelector } from 'react-redux';
import { selectProjectId } from '@store/slices/project.slice';
import { getTradeTranslation } from '@utils/translations.utils';

interface IProfessionSectionProps {
	profession?: IProfession;
	sequenceItemId?: string;
	handleProfessionUpdate?: (sequenceId: string, profession: IProfession) => void;
}

export const ProfessionSection = (props: IProfessionSectionProps) => {
	const projectId: string = useSelector(selectProjectId)!;
	const ref = useRef(null);
	const id = useId();
	const professionBackgroundColor: string | undefined =
		props.profession?.activeProjects?.[projectId]?.backgroundColor;
	const colorElementRef = useRef(null);

	return (
		<div ref={ref} style={{ display: 'contents' }}>
			{props.profession && (
				<TrusstorTooltip
					showAlways={true}
					idToOverflow={id}
					tooltipComponent={
						<div className={classes.tooltipContainer}>
							{props.profession.contractor && <div>{props.profession.contractor}</div>}
							<div className={classes.bottomPart}>
								<div style={{ background: professionBackgroundColor }} className={classes.bottomItem}>
									{getTradeTranslation(props.profession)}
								</div>
								{props.profession.specialty && (
									<div className={classes.bottomItem}>{props.profession.specialty}</div>
								)}
							</div>
						</div>
					}
				>
					<div
						ref={colorElementRef}
						className={classes.professionColor}
						style={{
							backgroundColor: professionBackgroundColor,
						}}
					></div>
				</TrusstorTooltip>
			)}
		</div>
	);
};
