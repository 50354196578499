import React, { useState } from 'react';
import classes from './styles.module.scss';
import { IconColor, IconSize, TrusstorIconShared } from '../../TrusstorIconShared/TrusstorIconShared';
import { IconNames } from '../../TrusstorIconShared/IconNames.enum';
import { ProfessionDisplayWithIcon } from '../../ProfessionDisplayWithIcon/ProfessionDisplayWithIcon';
import { ITrusstorButtonType, TrusstorButton } from '../../buttons/TrusstorButton/TrusstorButton';
import { useSharedServices } from '../../../hooks/sharedServices.context';
import { IIssue } from '../../../interfaces/IIssue';
import { GeneralDialogShared } from '../../GeneralDialogShared/GeneralDialogShared';
import { PlainTextCommentInput } from '../../Inputs/CommentInput/PlainTextCommentInput/PlainTextCommentInput';
import { useLinkedIssueActivityDescription } from '../../../hooks/queries/issue.queries.hook';
import classnames from 'classnames';

interface IIssueReviewDialogProps {
	issue: IIssue;
	handleRejectClick: (comment: string) => void;
	handleApproveClick: () => void;
	onClose: () => void;
	isMobile?: boolean;
}

export const IssueReviewDialog = (props: IIssueReviewDialogProps) => {
	const [isRejectedClicked, setIsRejectedClicked] = useState(false);
	const [isApprovedClicked, setIsApprovedClicked] = useState(false);
	const activityDescription: string | undefined = useLinkedIssueActivityDescription(props.issue);

	const [comment, setComment] = useState<string | undefined>();
	const { translationService } = useSharedServices();

	const handleRejectClick = (e) => {
		e.stopPropagation();
		setIsRejectedClicked((prev) => !prev);
		setIsApprovedClicked(false);
	};

	const handleApproveClick = (e) => {
		e.stopPropagation();
		setIsApprovedClicked((prev) => !prev);
		setIsRejectedClicked(false);
	};

	const handleSubmit = () => {
		if (isRejectedClicked) {
			props.handleRejectClick(comment!);
			return;
		}
		props.handleApproveClick();
	};

	const isSubmitDisabled: boolean = (!isRejectedClicked && !isApprovedClicked) || (isRejectedClicked && !comment);

	const handleCommentChange = (value: string) => {
		setComment(value);
	};
	return (
		<GeneralDialogShared
			title={translationService.get('issueReviewDialog_title')}
			show={true}
			close={props.onClose}
			mainButton={{
				text: translationService.get('issueReviewDialog_submitReviewCTA'),
				click: handleSubmit,
				disabled: isSubmitDisabled,
			}}
			secondaryButton={{
				text: translationService.get('cancel'),
				click: props.onClose,
			}}
		>
			<div className={classes.IssueReviewDialog_container} data-testid="IssueReviewDialog">
				<div className={classes.item}>
					<TrusstorIconShared iconName={IconNames.issues} size={IconSize.SMALL} color={IconColor.Grey600} />
					<div className={classes.itemText}>{props.issue.description}</div>
				</div>
				{activityDescription && (
					<div className={classes.item}>
						<TrusstorIconShared
							iconName={IconNames.activity}
							size={IconSize.SMALL}
							color={IconColor.Grey600}
						/>
						<div className={classes.itemText}>{activityDescription}</div>
					</div>
				)}
				{!!props.issue.locations?.length && (
					<div className={classes.item}>
						<TrusstorIconShared
							iconName={IconNames.floors}
							size={IconSize.SMALL}
							color={IconColor.Grey600}
						/>
						<div className={classes.itemText}>
							<span>
								{props.issue.locations
									.map((location) => {
										return location.floor.floorNick + ', ' + location.area.areaNick;
									})
									.join('| ')}
							</span>
						</div>
					</div>
				)}
				{props.issue.profession && (
					<ProfessionDisplayWithIcon profession={props.issue.profession} projectId={props.issue.projectId} />
				)}
			</div>
			<div className={classnames(classes.buttons, { [classes.isMobile]: props.isMobile })}>
				<TrusstorButton
					handleClick={handleRejectClick}
					className={classes.button}
					selected={isRejectedClicked}
					buttonType={ITrusstorButtonType.OUTLINE_NEGATIVE}
					iconElement={
						<TrusstorIconShared
							iconName={IconNames.issues}
							color={isRejectedClicked ? IconColor.FeedbackCriticalDark : IconColor.Red500}
						/>
					}
					text={translationService.get('issueReviewDialogCTA_reject')}
				/>
				<TrusstorButton
					className={classes.button}
					handleClick={handleApproveClick}
					buttonType={ITrusstorButtonType.OUTLINE_POSITIVE}
					selected={isApprovedClicked}
					iconElement={
						<TrusstorIconShared
							iconName={IconNames.check}
							color={isApprovedClicked ? IconColor.FeedbackPositiveDark : IconColor.Green500}
						/>
					}
					text={translationService.get('issueReviewDialogCTA_approve')}
				/>
			</div>
			{isRejectedClicked && (
				<PlainTextCommentInput isMobile={props.isMobile} onCommentChange={handleCommentChange} />
			)}
		</GeneralDialogShared>
	);
};
