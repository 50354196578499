export enum NotificationTypes {
	DistressButton = 'DistressButton',
	DailyReport = 'DailyReport',
	TagHealth = 'TagHealth',
	PlanningCreated = 'PlanningCreated',
	RestrictedArea = 'RestrictedArea',
	CreatedIssues = 'CreatedIssues',
	AssignedIssue = 'AssignedIssue',
	IssueStatusUpdated = 'IssueStatusUpdated',
	IssueCommentCreated = 'IssueCommentCreated',
	SequenceProgressAnalysisReport = 'SequenceProgressAnalysisReport',
	AreaSequenceItemStatusUpdate = 'AreaSequenceItemStatusUpdate',
}

export type RestrictedAreaType = `${NotificationTypes.RestrictedArea}_${string}`;

export type INotificationTypes = NotificationTypes | RestrictedAreaType;

export type CustomizedNotificationTypes = NotificationTypes.RestrictedArea;

export const customizedNotificationValues: CustomizedNotificationTypes[] = [NotificationTypes.RestrictedArea];
