export enum EquipmentTypes {
	Optics = 'Optics',
	Electricity = 'Electricity',
	PowerTools = 'Power Tools',
	BondingMachinery = 'Bonding Machinery',
	WaterDrainage = 'Water & Drainage',
	PavingTiling = 'Paving & Tiling',
	Welding = 'Welding',
	MovingLifting = 'Moving & lifting',
	Height = 'Height',
	HeavyEquipment = 'Heavy Equipment',
	Safety = 'Safety',
	ScissorsLift = 'Scissors Lift',
}
