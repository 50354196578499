import React from 'react';
import exportIcon from '../../../../../../assets/workplanIcons/exportIcon.svg';
import classes from './styles.module.scss';

interface ITitleSectionWithExportButtonProps {
	onClick: () => any;
	title: string;
	showButton?: boolean;
	children?: React.ReactNode;
}

const TitleSectionWithExportButton = (props: ITitleSectionWithExportButtonProps) => {
	const { showButton = true } = props;

	return (
		<div className={classes.boxWithTitle}>
			<p className={classes.titleText}>{props.title}</p>
			<div className={classes.buttonsContainer} onClick={props.onClick}>
				{showButton && <img src={exportIcon} alt="" />}
				{props.children}
			</div>
		</div>
	);
};

export { TitleSectionWithExportButton };
