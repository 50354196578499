import React, { CSSProperties } from 'react';
import { Loader } from '@shared/components/Loader/Loader';
import { HeaderBox } from '@src/Components/Boxes/Containers/HeaderBox/HeaderBox';
import { translationService } from '@src/index';
import { ISafetyEvent } from '@interfaces/ISafetyEvent';
import { MinimalSafetyRow } from '@src/Components/MinimalSafetyRow/MinimalSafetyRow';
import classes from './styles.module.scss';

interface ILocationMenuSafetyEventsProps {
	safetyEvents: ISafetyEvent[];
}

const LocationMenuSafetyEvents = (props: ILocationMenuSafetyEventsProps) => {
	const styles: CSSProperties = {
		maxHeight: '300px',
		overflowY: 'auto',
	};
	const getSafetyEventsContent = () => {
		if (!props.safetyEvents) {
			return <Loader />;
		}

		return props.safetyEvents?.length ? (
			props.safetyEvents.map((safetyEvent) => <MinimalSafetyRow safetyEvent={safetyEvent} />)
		) : (
			<div className={classes.noData}>{translationService.get('noSafetyEventsFoundInFloor')}</div>
		);
	};
	return (
		<HeaderBox boldTitle title={translationService.get('safetyEvents')} customRootStyles={styles}>
			{getSafetyEventsContent()}
		</HeaderBox>
	);
};

export { LocationMenuSafetyEvents };
