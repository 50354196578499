import React from 'react';
import classes from './styles.module.scss';
import { IIssue } from '../../../interfaces/IIssue';
import { getNumberDaysAgo, getRelativeHour } from '../../../utils/dateUtils';
import { useSharedServices } from '../../../hooks/sharedServices.context';
import { IssueStatus } from '../../../interfaces/IIssueShared';

interface IIssueMetadataSectionProps {
	issue: IIssue;
	tz: string;
}

export const IssueMetadataSection = (props: IIssueMetadataSectionProps) => {
	const { translationService } = useSharedServices();
	const createdDaysAgo: number = getNumberDaysAgo(props.issue.createDate, props.tz);
	const updatedDaysAgo: number | undefined = props.issue.statusUpdateDate
		? getNumberDaysAgo(props.issue.statusUpdateDate, props.tz)
		: undefined;

	const dateCreatedFormatted: string = getRelativeHour(props.issue.createDate, props.tz, translationService);
	const dateUpdatedFormatted: string | undefined = props.issue.statusUpdateDate
		? getRelativeHour(props.issue.statusUpdateDate, props.tz, translationService)
		: undefined;

	const showCompletedDetails: boolean = !!(
		props.issue.status === IssueStatus.COMPLETED &&
		props.issue.statusUpdateUser?.name &&
		updatedDaysAgo !== undefined
	);
	return (
		<div className={classes.IssueMetadataSection_container} data-testid="IssueMetadataSection">
			{!!props.issue.createdBy?.name && (
				<span>
					{translationService.get('issue_metadata_createdBy')}{' '}
					<span className={classes.bold}>{props.issue.createdBy?.name}</span>{' '}
					{createdDaysAgo === 0 || createdDaysAgo === 1
						? ''
						: translationService.get('issue_metadata_on').toLowerCase()}{' '}
					{dateCreatedFormatted}
				</span>
			)}
			{showCompletedDetails && (
				<span>
					{translationService.get('issue_metadata_completedBy')}{' '}
					<span className={classes.bold}> {props.issue.statusUpdateUser?.name}</span>{' '}
					{updatedDaysAgo === 0 || updatedDaysAgo === 1
						? ''
						: translationService.get('issue_metadata_on').toLowerCase()}{' '}
					{dateUpdatedFormatted}
				</span>
			)}
		</div>
	);
};
