import { accessLevelService, requestService, streamService } from '../../index';
import {
	endCriticalEvent,
	resetSafety,
	updateSafetyEvents,
	updateSafetyEventsRequestStatus,
} from '../slices/safetyEvents.slice';
import { IMinimalSafetyEventForClient } from '../../interfaces/ISafetyEvent';
import { IRootState } from '../slices';
import { HTTPRequestStatuses } from '@shared/interfaces/HTTPRequestStatuses.enum';

export const closeSafetyEventById = (safetyEventId: string) => async (dispatch, getState: () => IRootState) => {
	const updatedSafetyEvent: IMinimalSafetyEventForClient = await requestService.put(
		`/safety/closeSafetyEvent?safetyEventId=${safetyEventId}`
	);
	dispatch(updateSafetyEvents({ safetyEvents: [updatedSafetyEvent] }));
};

export const closeCriticalEventById = (safetyEventId: string) => async (dispatch, getState: () => IRootState) => {
	await requestService.put(`/safety/closeSafetyEvent?safetyEventId=${safetyEventId}`);
	dispatch(endCriticalEvent({ safetyEventId }));
};

export const getSafety = (projectId) => async (dispatch) => {
	if (!accessLevelService.hasAccess('projectMonitor-safety')) {
		dispatch(
			updateSafetyEvents({
				safetyEvents: [],
			})
		);
		return;
	}
	dispatch(updateSafetyEventsRequestStatus({ status: HTTPRequestStatuses.pending }));
	streamService.openStream(
		`/safety/stream?projectId=${projectId}`,
		(message) => {
			dispatch(updateSafetyEventsRequestStatus({ status: HTTPRequestStatuses.success }));
			const { safetyEvents }: { safetyEvents: IMinimalSafetyEventForClient[] } = JSON.parse(message.data);
			dispatch(
				updateSafetyEvents({
					safetyEvents,
				})
			);
		},
		() => {
			dispatch(updateSafetyEventsRequestStatus({ status: HTTPRequestStatuses.failed }));
		}
	);
};

export const stopGettingSafety = () => async (dispatch) => {
	streamService.closeStreamIncludes(`/safety/stream`);
	dispatch(resetSafety());
};
