import amplitude, { AmplitudeClient } from 'amplitude-js';
import { getHoursMinutesFormat, getTimezoneFormattedDate } from '../utils/dateUtils';
import { Environments } from '../constants/environments';

interface IBIServiceGlobalData {
	projectId: string;
	tz: string;
}

interface IBIBasicEventData {
	projectId: string;
	projectLocalDate: string;
	projectLocalTime: string;
}

class BIService<EventNames> {
	private config: any;
	private instance: AmplitudeClient;
	private globalData: IBIServiceGlobalData | undefined;
	private readonly shouldSendEvent: boolean;
	private environmentsToSend: string[] = [Environments.prod, Environments.qa];

	constructor(config, versionService) {
		this.config = config;
		this.instance = amplitude.getInstance();
		this.instance.init(this.config.amplitudeApiKey);
		this.instance.setVersionName(versionService.getVersion() || '');
		this.shouldSendEvent = this.environmentsToSend.includes(config.environment);
	}

	public initUserIdentify(userData) {
		const identify = new amplitude.Identify();
		identify.set('username', userData.username);
		identify.set('roleType', userData.roleType);
		this.instance.setUserId(`user_${userData.userId}`);
		this.instance.identify(identify);
	}

	public setGlobalData(data: IBIServiceGlobalData) {
		this.globalData = data;
	}

	public logEvent(eventName: EventNames, eventData?: any) {
		try {
			const fullData: IBIBasicEventData = {
				...eventData,
				projectId: this.globalData!.projectId,
				projectLocalDate: getTimezoneFormattedDate(this.globalData!.tz, new Date()),
				projectLocalTime: getHoursMinutesFormat(new Date(), this.globalData!.tz),
			};
			if (!this.shouldSendEvent) {
				return;
			}
			this.instance.logEvent(eventName, fullData);
		} catch (error) {
			console.error(error);
		}
	}
}

export { BIService };
