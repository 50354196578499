export const getProductRoundedHoursFromMs = (ms: number): number => {
	const hours: number = ms / 1000 / 60 / 60;
	return getProductRoundedHoursFromHours(hours);
};

export const getProductRoundedHoursFromHours = (hours: number): number => {
	if (hours < 10) {
		return roundByStep(hours, 0.5);
	}
	return roundByStep(hours, 1);
};

export const getRoundedHoursFromMsByStep = (ms: number, step: number): number => {
	const hours: number = ms / 1000 / 60 / 60;
	return roundByStep(hours, step);
};

export function roundByStep(value: number, step: number) {
	const invert: number = 1.0 / step;
	return Math.round(value * invert) / invert;
}

export const formatBytes = (bytes: number, decimals: number = 0) => {
	if (!+bytes) return '0 Bytes';

	const kilobytes: number = 1000;
	const positiveDecimals: number = decimals < 0 ? 0 : decimals;
	const sizes: string[] = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

	const sizeIndex: number = Math.min(Math.floor(Math.log(bytes) / Math.log(kilobytes)), sizes.length - 1);

	return `${parseFloat((bytes / Math.pow(kilobytes, sizeIndex)).toFixed(positiveDecimals))} ${sizes[sizeIndex]}`;
};
