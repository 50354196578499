import { COLORS } from '../constants/colors.constants';
import { ActivityGroupStatus } from '../interfaces/ActivityGroupStatus.enum';
import { ThemeModes } from '../interfaces/UiThemes.type';

const checkIfColorInRgbFormat = (color) => {
	const matchColors: RegExp = /rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)/;
	return !!matchColors.exec(color);
};

const getRgbFromHex = (hexColor: string): string => {
	const rgb: string[] = hexColor.replace('#', '').split('');
	return `rgb(${parseInt(rgb[0] + rgb[1], 16)}, ${parseInt(rgb[2] + rgb[3], 16)}, ${parseInt(rgb[4] + rgb[5], 16)})`;
};

const addingOpacityToRGB = (rgbColor: string, opacity: number = 0.5): string => {
	if (!checkIfColorInRgbFormat(rgbColor)) {
		console.log(`${rgbColor} is not a valid color`);
		return 'rgb(128,128,128, 0.5)';
	}
	const rgb: string[] = rgbColor.replace('rgb(', '').replace(')', '').split(',');
	return `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${opacity})`;
};

export const addOpacityToColor = (color: string, opacity: number = 0.5): string => {
	if (color.charAt(0).toString() !== '#') {
		return addingOpacityToRGB(color, opacity);
	}
	const rgbColorFromHex: string = getRgbFromHex(color);
	return addingOpacityToRGB(rgbColorFromHex, opacity);
};

export const cM = (componentClasses: string, classes: { [key: string]: string }, mode: ThemeModes = 'dark') => {
	return `${componentClasses} ${classes[mode]}`;
};

export const getColorFromActivityGroupStatus = (status?: ActivityGroupStatus) => {
	switch (status) {
		case ActivityGroupStatus.overdue:
			return COLORS.red100;
		case ActivityGroupStatus.delayed:
			return COLORS.red100;
		case ActivityGroupStatus.inProgress:
			return COLORS.yellow100;
		case ActivityGroupStatus.deleted:
		case ActivityGroupStatus.complete:
			return COLORS.green100;
		case ActivityGroupStatus.planned:
			return COLORS.white;
	}
};
