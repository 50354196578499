import React from 'react';
import classes from './styles.module.scss';
import { PeriodSelectorTrusstorInput } from '@src/Components/DateSelector/PeriodSelectorTrusstorInput/PeriodSelectorTrusstorInput';
import classnames from 'classnames';
import { translationService } from '@src/servicesInitializers';
import moment from 'moment';
import { professionAnalysisSelectDate_BI } from '@utils/bi.utils';
import { useSelector } from 'react-redux';
import { IRootState } from '@store/slices';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { TrusstorIcon } from '@src/Components/TrusstorIcon/TrusstorIcon';

interface IContractorAnalysisDatePickerSectionProps {
	updatePeriod: (period: [Date, Date]) => void;
}

export const ContractorAnalysisDatePickerSection = (props: IContractorAnalysisDatePickerSectionProps) => {
	const [startDate, endDate]: [number, number] = useSelector(
		(state: IRootState) => state.datePickerReducer.visiblePeriodTimestamps
	);
	const datePeriod: [Date, Date] = [new Date(startDate), new Date(endDate)];

	const today: Date = new Date();
	const thirtyDaysBack: Date = moment(today).subtract(30, 'days').add(1, 'day').toDate();

	const isThirtyDaysSelected: boolean =
		datePeriod[0].getDate() === thirtyDaysBack.getDate() && datePeriod[1].getDate() === today.getDate();

	const setThirtyDaysBack = () => {
		const period: [Date, Date] = [thirtyDaysBack, today];
		props.updatePeriod(period);
		professionAnalysisSelectDate_BI('30d', period);
	};

	const setNumberOfWeeks = (weeks: number) => {
		const startDate: Date = moment(today).subtract(weeks, 'weeks').add(1, 'day').toDate();
		const period: [Date, Date] = [startDate, today];
		props.updatePeriod(period);
		professionAnalysisSelectDate_BI(weeks === 1 ? '7d' : '14d', period);
	};

	const isRtl: boolean = translationService.getIsRtl();

	const isWeekAmountSelected = (weeks: number): boolean => {
		const startDate: Date = moment(today).subtract(weeks, 'weeks').add(1, 'day').toDate();
		return moment(datePeriod[0]).isSame(startDate, 'day') && moment(datePeriod[1]).isSame(today, 'day');
	};

	return (
		<div
			className={classes.ContractorAnalysisDatePickerSection_container}
			data-testid="ContractorAnalysisDatePickerSection"
		>
			<div className={classes.title}>
				<TrusstorIcon iconName={IconNames.worker} />
				<div>{translationService.get('workersVsHoursGraph_personnel_Title')}</div>
			</div>
			<div
				className={classnames(classes.oneTwoWeekContainer, {
					[classes.isRTL]: isRtl,
				})}
			>
				<div
					onClick={() => setNumberOfWeeks(1)}
					className={classnames(classes.weekText, classes.first, {
						[classes.selected]: isWeekAmountSelected(1),
						[classes.isRTL]: isRtl,
					})}
				>
					{translationService.get('date_period_picker_7days')}
				</div>

				<div
					onClick={() => setNumberOfWeeks(2)}
					className={classnames(classes.weekText, classes.second, {
						[classes.selected]: isWeekAmountSelected(2),
						[classes.isRTL]: isRtl,
					})}
				>
					{translationService.get('date_period_picker_14days')}
				</div>
				<div
					onClick={() => setThirtyDaysBack()}
					className={classnames(classes.weekText, classes.third, classes.isLast, {
						[classes.selected]: isThirtyDaysSelected,
						[classes.isRTL]: isRtl,
					})}
				>
					{translationService.get('date_period_picker_30days')}
				</div>
			</div>
			<PeriodSelectorTrusstorInput
				isSmall
				updatePeriod={(period) => {
					props.updatePeriod(period);
					professionAnalysisSelectDate_BI('custom', period);
				}}
				datePeriod={datePeriod}
				showSingleMonth
				maximumDaysForPeriod={31}
			/>
		</div>
	);
};
