/*
	When using a div and not an input you dont have the select() method, you can use this function to select the content of the div.
 */
export function selectElementContents(el: HTMLElement) {
	const range: Range = document.createRange();
	range.selectNodeContents(el);
	const sel: Selection | null = window.getSelection();
	if (!sel) {
		return;
	}
	sel.removeAllRanges();
	sel.addRange(range);
}
