import React, { useState } from 'react';
import { searchIcon } from '@assets/adminPanel';
import { debounce } from 'lodash';
import { TrusstorTextInput } from '@shared/components/Inputs/TrusstorTextInput/TrusstorTextInput';
import classes from './styles.module.scss';

interface ITextInputFieldProps {
	placeholder: string;
	handleChangeInput: (value: string) => void;
	testId?: string;
}

const SearchBar = (props: ITextInputFieldProps) => {
	const [inputValue, setInputValue] = useState<string>('');
	const debouncedPropsHandleChangeInput = debounce(props.handleChangeInput, 500);

	const handleInputChange = (e) => {
		debouncedPropsHandleChangeInput(e.target.value);
		setInputValue(e.target.value);
	};

	return (
		<TrusstorTextInput
			rootClassName={classes.textInput}
			startIcon={searchIcon}
			placeholder={props.placeholder}
			type="text"
			changeFunc={handleInputChange}
			sendFullEvent
			value={inputValue}
			testId={props.testId}
			showClearButton
		/>
	);
};

export { SearchBar };
