import { SimpleChip } from '../../../../shared/components/SimpleChip/SimpleChip';
import React from 'react';
import { IManager, ITag } from '../../interfaces';
import { getTagBackgroundColor, getTagChipDisplayText } from '../../utils/adminPanelTags.utils';
import { selectProjectId } from '@store/slices/project.slice';
import { useSelector } from 'react-redux';

interface ITagEntityChipProps {
	tag: ITag;
}

export function TagEntityChip(props: ITagEntityChipProps) {
	const projectId: string = useSelector(selectProjectId)!;

	return (
		<SimpleChip
			chipText={getTagChipDisplayText(props.tag)}
			chipColor={getTagBackgroundColor(props.tag, projectId)}
			margin="0px"
			maxChipWidth="200px"
			fontClass="body2"
		/>
	);
}
