import { ITableComponentProps } from '@interfaces/ITableComponentProps';
import { ISequenceItemProgressBySequenceItemReport } from '@interfaces/ISequenceItemProgressBySequenceItemReport';
import React, { ReactElement } from 'react';
import classes from '@src/Components/SequenceProgressAnalysis/ActivitiesProgress/ActivitiesProgressTable/styles.module.scss';
import { TrusstorIcon } from '@src/Components/TrusstorIcon/TrusstorIcon';
import classnames from 'classnames';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { IconColor, IconSize } from '@shared/components/TrusstorIconShared/TrusstorIconShared';

export const NameCell = (
	props: ITableComponentProps<ISequenceItemProgressBySequenceItemReport>
): ReactElement<ITableComponentProps<ISequenceItemProgressBySequenceItemReport>> => {
	const report: ISequenceItemProgressBySequenceItemReport = props.data;

	return (
		<div className={classes.nameCell}>
			<div className={classes.description}>{report.description}</div>
			<TrusstorIcon
				className={classnames({
					[classes.filledIcon]: report.isStarred,
					[classes.hideIcon]: !report.isStarred,
				})}
				iconName={IconNames.star}
				color={IconColor.Black}
				size={IconSize.SMALL}
			/>
		</div>
	);
};
