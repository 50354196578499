import { IWorkerTimesheet } from '@shared/interfaces/IWorkerTimesheet';
import React from 'react';
import classes from './styles.module.scss';
import { cM } from '@shared/utils/colors.utils';
import { WorkerTimesheetHeader } from '../WorkerTimesheetHeader/WorkerTimesheetHeader';
import { WorkerTimesheetTable } from '../WorkerTimesheetTable/WorkerTimesheetTable';
import { getWorkDaysNumber } from '@utils/dateUtils';
import { useSelector } from 'react-redux';
import { selectTimezone, selectWorkDayHours } from '@store/slices/project.slice';
import { IWorkDayHours } from '@shared/interfaces/IProject';

interface IWorkerTimesheetProps {
	timesheet: IWorkerTimesheet[];
	tagId: string;
	startDate: Date;
	endDate: Date;
}

export const WorkerTimesheet = (props: IWorkerTimesheetProps) => {
	const workDaysHours: IWorkDayHours[] = useSelector(selectWorkDayHours)!;
	const tz: string = useSelector(selectTimezone)!;
	const totalWorkingHours: number = props.timesheet.reduce((acc, curr) => {
		if (!curr.exitTimeTimestamp) return acc;
		const workingHours: number = (curr.exitTimeTimestamp - curr.arrivalTimeTimestamp) / (1000 * 60 * 60);
		return acc + workingHours;
	}, 0);
	const workingDaysAmount: number = getWorkDaysNumber(props.startDate, props.endDate, workDaysHours, tz);
	const averageDailyHours: number = totalWorkingHours / workingDaysAmount;

	const isEmptyState: boolean = props.timesheet.length === 0;
	return (
		<div className={cM(classes.timesheetContainer, classes)}>
			<WorkerTimesheetHeader
				tagId={props.tagId}
				isEmptyState={isEmptyState}
				totalWorkingHours={totalWorkingHours}
				averageDailyHours={averageDailyHours}
				workingDaysAmount={workingDaysAmount}
			/>
			<WorkerTimesheetTable
				timesheet={props.timesheet}
				isEmptyState={isEmptyState}
				startDate={props.startDate}
				endDate={props.endDate}
			/>
		</div>
	);
};
