import { useQuery } from '@tanstack/react-query';
import { IUser } from '@shared/interfaces/IUser';
import { requestService } from '@src/index';
import { IUserPreferences } from '@shared/interfaces/IUserPreferences';

export const useUsersQuery = (projectId: string) => {
	const {
		data: users,
		refetch: refetchUsers,
		isLoading,
	} = useQuery<IUser[]>(
		['users', projectId],
		() => requestService.get(`/entities/user/all/${projectId}?withoutAdmin=true`),
		{
			useErrorBoundary: true,
			initialData: [],
		}
	);
	return { users, refetchUsers, isLoading };
};

export const useUserPreferencesQuery = (username?: string) => {
	if (!username) {
		return { userPreferences: undefined };
	}
	const { data: userPreferences } = useQuery<IUserPreferences>(
		['userPreferences', username],
		() => requestService.get(`/entities/userPreferences/${username}`),
		{
			useErrorBoundary: true,
		}
	);
	return { userPreferences };
};
