import React from 'react';
import classes from './styles.module.scss';
import { MultipleVerticalBars } from '@src/Components/Pages/Reports/ReportsComponents/Charts/MultipleVerticalBars';
import { ISingleVerticalBarData } from '@src/Components/Pages/Reports/ReportsComponents/Charts/interfaces/ISingleVerticalBarData';
import { IObserveLocationHistogramApiResult } from '@shared/interfaces/IObserveLocationApiResult';
import { sortBy } from 'lodash';
import { getFullDayHoursArray } from '@utils/dateUtils';
import { useIsNarrowLayout } from '@src/hooks/custom.hooks';
import { translationService } from '@src/servicesInitializers';
import { Languages } from '@shared/constants/languages';

interface IHistogramProps {
	data: IObserveLocationHistogramApiResult;
}

const LocationHistogram = (props: IHistogramProps) => {
	const isNarrowLayout: boolean = useIsNarrowLayout();
	const ticks: string[] = getFullDayHoursArray(
		1,
		true,
		false,
		translationService.getChosenLanguage() === Languages.HEBREW
	);
	const BAR_COLOR: string = '#3582FF';
	const sortedKeys: string[] = sortBy(Object.keys(props.data), (hour) => hour);
	const barDataList: ISingleVerticalBarData[] = sortedKeys.map((hour) => {
		return {
			barNumber: props.data[hour].length,
			backgroundColor: BAR_COLOR,
		};
	});

	return (
		<div className={classes.container}>
			<div className={classes.histogramContainer}>
				<MultipleVerticalBars
					dataList={barDataList}
					barWidth={isNarrowLayout ? 14 : 18}
					barMinHeight={0}
					categories={ticks}
					marginBetweenBars={isNarrowLayout ? 7 : 9}
					visibleTickCoefficient={4}
					barsInEdge
					baseLineFixWidth
				/>
			</div>
		</div>
	);
};

export { LocationHistogram };
