import { ISequence } from '@shared/interfaces/ISequence';
import { useQuery } from '@tanstack/react-query';
import { requestService } from '../../index';

export const useProjectSequencesQuery = (
	projectId: string
): {
	sequences: ISequence[];
	isInitialLoading: boolean;
	isFetching: boolean;
	isFetched: boolean;
	refetchSequences: () => void;
} => {
	const {
		data: sequences,
		isInitialLoading,
		isFetching,
		refetch: refetchSequences,
		isFetched,
	} = useQuery<ISequence[]>(
		['projectSequences', projectId],
		() => requestService.get(`/activities/sequences?projectId=${projectId}`),
		{
			useErrorBoundary: true,
			initialData: [],
		}
	);
	return { sequences, isInitialLoading, refetchSequences, isFetching, isFetched };
};
