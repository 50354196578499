import { IconNames } from '../../TrusstorIconShared/IconNames.enum';
import classes from './styles.module.scss';
import { IconColor, IconSize, TrusstorIconShared } from '../../TrusstorIconShared/TrusstorIconShared';
import React from 'react';
import classnames from 'classnames';

interface IIconChipProps {
	backgroundColor: string;
	iconName?: IconNames;
	iconColor?: IconColor;
	text: string;
	textColor?: string;
}

export const IconChip = (props: IIconChipProps) => {
	return (
		<div className={classes.chipContainer}>
			<div
				className={classes.iconContainer}
				style={{ backgroundColor: props.backgroundColor, color: props.textColor }}
			>
				{props.iconName && (
					<TrusstorIconShared iconName={props.iconName} color={props.iconColor} size={IconSize.SMALL} />
				)}
				<div className={classnames({ [classes.noIconText]: !props.iconName })}>{props.text}</div>
			</div>
		</div>
	);
};
