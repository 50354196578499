import React from 'react';
import { cM } from '@shared/utils/colors.utils';
import classes from './styles.module.scss';
import { adminPanelIconWhite } from '@assets/sideBar';
import { useSelector } from 'react-redux';
import { IRootState } from '@store/slices';
import { ITag } from '@interfaces/ITag';
import { getTagName } from '../../../../../../utils/tags.utils';
import { getProfessionDisplayText } from '@shared/utils/professions.utils';
import { translationService } from '../../../../../../index';
import { EMPTY_ITEM_CONTENT } from '../../../reports.constants';
import { getHoursMinutesHoursFormatFromHours } from '@utils/dateUtils';
import { getWorkerTimesheetElementId } from '../../../timesheets.utils';
import { HeaderStats, IHeaderStat } from '@src/Components/Pages/AnalysisCenterPages/HeaderStats/HeaderStats';
import { getTradeTranslation } from '@utils/translations.utils';

interface IWorkerHeaderProps {
	totalWorkingHours: number;
	workingDaysAmount: number;
	averageDailyHours: number;
	tagId: string;
	isEmptyState: boolean;
}

export const WorkerTimesheetHeader = (props: IWorkerHeaderProps) => {
	const staticTag: ITag | undefined = useSelector((state: IRootState) =>
		state.tagsReducer.tags.find((tag) => tag.tagId === props.tagId)
	);
	const tagNick: string | undefined = staticTag?.tagNick;

	const getTagEntityText = () => {
		if (staticTag?.profession) {
			return getProfessionDisplayText(staticTag.profession, translationService);
		}
		if (staticTag?.manager) {
			const managerTradeTranslation: string = getTradeTranslation(staticTag.manager);
			return managerTradeTranslation;
		}
	};

	const getTotalWorkingHours = (): string => {
		if (props.isEmptyState || props.totalWorkingHours === 0) {
			return EMPTY_ITEM_CONTENT;
		}
		return getHoursMinutesHoursFormatFromHours(props.totalWorkingHours);
	};

	const getAverageDailyHours = (): string => {
		if (props.isEmptyState || props.averageDailyHours === 0) {
			return EMPTY_ITEM_CONTENT;
		}
		return getHoursMinutesHoursFormatFromHours(props.averageDailyHours);
	};

	const getStats = (): IHeaderStat[] => {
		return [
			{
				title: translationService.get('avgDailyHours'),
				stat: getAverageDailyHours(),
			},
			{
				title: translationService.get('workingDays'),
				stat: props.isEmptyState ? EMPTY_ITEM_CONTENT : props.workingDaysAmount.toString(),
			},
			{
				title: translationService.get('totalWorkingHours'),
				stat: getTotalWorkingHours(),
			},
		];
	};
	return (
		<div className={cM(classes.workerTimesheetHeader, classes)} id={getWorkerTimesheetElementId(props.tagId)}>
			<div className={cM(classes.topWrapper, classes)}>
				<div className={cM(classes.workerIdSection, classes)}>
					<img src={adminPanelIconWhite} alt={'adminIcon'} />
					<div className={cM(classes.tagNickText, classes)}>{tagNick}</div>
				</div>
				{staticTag && (
					<>
						<div className={cM(classes.workerNameSection, classes)}>
							<div className={cM(classes.workerNameText, classes)}>{getTagName(staticTag)}</div>
						</div>
						<div>
							<div className={cM(classes.entityText, classes)}>{getTagEntityText()}</div>
						</div>
					</>
				)}
			</div>
			<HeaderStats stats={getStats()} rootStyle={{ padding: '38px 0' }} />
		</div>
	);
};
