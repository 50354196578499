import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { TrusstorBox } from '../../../../Boxes/Containers/TrusstorBox';
import { HeaderBox } from '../../../../Boxes/Containers/HeaderBox/HeaderBox';
import { translationService } from '../../../../../index';
import { ActivitiesTable } from '../Tables/ActivitiesTable/ActivitiesTable';
import {
	getActivityDailyReport,
	getActivityPeriodicReport,
	stopGettingActivityReport,
} from '../../../../../store/thunks/activityReport.thunks';
import { IManager, IProfession } from '../../../../../interfaces';
import { DATE_PICKER_TOGGLE } from '../../../../../constants';
import { IActivitiesReport } from '../../../../../interfaces/IActivitiesReport';
import {
	getVisibleActivityReportByFloors,
	getVisibleActivityReportByLabels,
	getVisibleActivityReportByManagers,
	getVisibleActivityReportByProfessions,
} from '../../../../../utils/filters.utils';
import { IRootState } from '../../../../../store/slices';
import { IActivityLabel } from '../../../../../interfaces/IActivityLabel';
import { IFloor } from '@shared/interfaces/IFloor';

const useStyles = makeStyles((theme) => ({
	reportsGreyHeader: {
		marginTop: theme.spacing(0.25),
	},
	activitiesSection: {
		marginTop: theme.spacing(2),
	},
}));

interface IActivitiesSectionProps {
	tableHeight?: string;
	visibleLabels?: IActivityLabel[];
}

const ActivitiesSection = (props: IActivitiesSectionProps) => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const [isDailyReport, setIsDailyReport] = useState<boolean>(true);

	const projectId: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.projectId);
	const activityReportRounded: IActivitiesReport = useSelector(
		(state: IRootState) => state.activityReportReducer.activityReportRounded
	);
	const visibleFloorsList: IFloor[] = useSelector((state: IRootState) => state.floorsListReducer.visibleFloorsList);
	const visibleProfessions: IProfession[] = useSelector(
		(state: IRootState) => state.professionsReducer.visibleProfessions
	);
	const visibleStaticManagers: IManager[] = useSelector((state: IRootState) => state.managersReducer.visibleManagers);
	const selectedManagers: IManager[] = useSelector((state: IRootState) => state.managersReducer.selectedManagers);
	const tz: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.tz);
	const visibleDateTimestamp: number = useSelector(
		(state: IRootState) => state.datePickerReducer.visibleDateTimestamp
	);
	const visiblePeriodTimestamps: [number, number] = useSelector(
		(state: IRootState) => state.datePickerReducer.visiblePeriodTimestamps
	);
	const toggleStateDatePicker: DATE_PICKER_TOGGLE = useSelector(
		(state: IRootState) => state.datePickerReducer.toggleState
	);

	useEffect(() => {
		toggleStateDatePicker === DATE_PICKER_TOGGLE.date
			? dispatch(getActivityDailyReport(projectId, visibleDateTimestamp, tz))
			: dispatch(
					getActivityPeriodicReport(projectId, visiblePeriodTimestamps[0], visiblePeriodTimestamps[1], tz)
			  );
		return () => {
			dispatch(stopGettingActivityReport());
		};
	}, [projectId, toggleStateDatePicker, visibleDateTimestamp, visiblePeriodTimestamps]);

	const visibleActivityReport = useMemo(() => {
		if (visibleFloorsList.length > 0 || visibleProfessions.length > 0) {
			const filteredActivityByProfession = getVisibleActivityReportByProfessions(
				visibleProfessions,
				activityReportRounded
			);
			const filteredActivityByFloors = getVisibleActivityReportByFloors(
				visibleFloorsList,
				filteredActivityByProfession
			);
			const filteredActivityByLabels = getVisibleActivityReportByLabels(
				props.visibleLabels || [],
				filteredActivityByFloors
			);
			return getVisibleActivityReportByManagers(
				visibleStaticManagers,
				filteredActivityByLabels,
				selectedManagers.length > 0
			);
		}

		return activityReportRounded;
	}, [activityReportRounded, visibleProfessions, visibleFloorsList, visibleStaticManagers, props.visibleLabels]);

	useEffect(() => {
		setIsDailyReport(toggleStateDatePicker === DATE_PICKER_TOGGLE.date);
	}, [activityReportRounded]);

	return (
		<TrusstorBox customStyleClass={classes.activitiesSection}>
			<HeaderBox
				title={translationService.get('activities')}
				customHeaderClass={classes.reportsGreyHeader}
				customRootStyles={{ width: '100%', height: '100%' }}
			>
				<ActivitiesTable
					tableHeight={props.tableHeight}
					report={visibleActivityReport}
					isDailyReport={isDailyReport}
					startDate={new Date(isDailyReport ? visibleDateTimestamp : visiblePeriodTimestamps[0])}
					endDate={new Date(visiblePeriodTimestamps[1])}
				/>
			</HeaderBox>
		</TrusstorBox>
	);
};

export { ActivitiesSection };
