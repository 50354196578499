import React from 'react';
import classes from './styles.module.scss';
import { IMainPageWidgetActivityData } from '../../../../../interfaces/IMainPageWidgetActivityData';
import { IMainPageWidgetFloor } from '../../../../../../../shared/interfaces/IMainPageWidgetFloor';
import { getProductRoundedHoursFromMs } from '../../../../../../../shared/utils/roundNumbers.utils';
import { translationService } from '../../../../../servicesInitializers';
import { SORT_ORDER } from '../../../../../../../shared/constants/constants';
import { ActivityGroupStatus } from '../../../../../../../shared/interfaces/ActivityGroupStatus.enum';
import { sortFloors } from '@shared/utils/sort.utils';

interface IWeeklyWidgetCardAdHocFloorsProps {
	activity: IMainPageWidgetActivityData;
}

export const WeeklyWidgetCardAdHocFloors = (props: IWeeklyWidgetCardAdHocFloorsProps) => {
	const sortedFloors: IMainPageWidgetFloor[] = sortFloors(props.activity.floors, SORT_ORDER.ASCENDING, 'floorId');
	const isCompleted: boolean = props.activity.groupStatus === ActivityGroupStatus.complete;

	const getFloorTitle = (floor: IMainPageWidgetFloor): string => {
		return (
			floor.shortFloorNick ||
			props.activity.areas.find((area) => area.floorId === floor.floorId)?.shortFloorNick ||
			floor.floorNick
		);
	};

	return (
		<div className={classes.adhoCardsContainer}>
			{sortedFloors.map((floor: IMainPageWidgetFloor) => {
				return (
					<div className={classes.floorCard} key={floor.floorId} data-testid={'floorCard'}>
						<div className={classes.floorText}>{getFloorTitle(floor)}</div>
						{!isCompleted && (
							<div className={classes.floorHours}>
								{getProductRoundedHoursFromMs(floor.accumulatedWorkTime)}
								{translationService.get('h')}
							</div>
						)}
					</div>
				);
			})}
		</div>
	);
};
