import React, { useMemo } from 'react';
import {
	IActivitiesFloorReport,
	IActivitiesProfessionReport,
	IActivityReport,
	IRoundedActivityReport,
} from '../../../../../../interfaces/IActivitiesReport';
import { translationService } from '../../../../../../index';
import { makeStyles } from '@material-ui/core/styles';
import { textStyle } from '@shared/utils/text.utils';
import { ACTIVITIES_COUNT_COLUMN_WIDTH, ACTIVITIES_PROFESSION_COLUMN_WIDTH, COLORS } from '../../../../../../constants';
import { ActivityRow } from './ActivityRow/ActivityRow';
import { ICounterDonutChartDataItem } from '../../Charts/interfaces/ICounterDonutChartDataItem';
import { groupBy, sumBy } from 'lodash';
import { getProfessionDisplayText } from '../../../../../../utils/professions.utils';
import { getProfessionBackgroundColor } from '@shared/utils/professions.utils';
import { selectProjectId } from '@store/slices/project.slice';
import { useSelector } from 'react-redux';

interface IStyleProps {
	backgroundColor?: string;
}

const useStyles = makeStyles((theme) => ({
	professionRowContainer: {
		display: 'flex',
		flexDirection: 'row',
		height: 'auto',
		width: '100%',
		borderBottom: `2px solid ${COLORS.white}`,
	},
	professionCell: ({ backgroundColor }: IStyleProps) => ({
		minWidth: ACTIVITIES_PROFESSION_COLUMN_WIDTH,
		display: 'flex',
		flexDirection: 'column',
		position: 'relative',
		padding: theme.spacing(1),
		border: '2px solid #F0F0F0',
		borderTop: 'unset',
		borderInlineStart: `2px solid ${backgroundColor}`,
	}),
	professionNameContainer: textStyle({
		fontClass: 'h1',
	}),
	colorContainer: {
		right: theme.spacing(1),
		top: theme.spacing(1),
		position: 'absolute',
	},
	professionCounter: {
		'display': 'flex',
		'flexDirection': 'column',
		'justifyContent': 'center',
		'alignItems': 'center',
		'width': ACTIVITIES_COUNT_COLUMN_WIDTH,
		'& p': {
			margin: 0,
		},
	},
	activitiesContainer: {
		display: 'flex',
		flexDirection: 'column',
	},
	contractor: textStyle({
		fontClass: 'h2',
		margin: 0,
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		fontWeight: 300,
		fontSize: 12,
		color: COLORS.grey700,
	}),
	profession: textStyle({
		fontClass: 'h2',
		margin: 0,
		maxWidth: 200,
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		fontSize: 18,
		fontWeight: 500,
	}),
	specialty: textStyle({
		fontClass: 'h2Light',
		margin: 0,
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	}),
	activityFloorContainer: {
		display: 'flex',
	},
	floorTextContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: 180,
		fontWeight: 500,
		borderBottom: '2px solid #F0F0F0',
		borderRight: '2px solid #F0F0F0',
	},
	activitiesRowsContainer: {
		display: 'flex',
		flexDirection: 'column',
	},
	activitiesCountContainer: {
		display: 'flex',
		borderRight: '2px solid #F0F0F0',
		borderBottom: '2px solid #F0F0F0',
	},
}));

interface IActivityRowProps {
	activityProfessionReport: IActivitiesProfessionReport;
	isDailyReport: boolean;
	startDate: Date;
	endDate?: Date;
}

const ProfessionActivitiesRow = (props: IActivityRowProps) => {
	const projectId: string = useSelector(selectProjectId)!;
	const classes = useStyles({
		backgroundColor: getProfessionBackgroundColor(props.activityProfessionReport.profession, projectId),
	});

	const activities: IRoundedActivityReport[] = Object.values(props.activityProfessionReport.floors).reduce(
		(activities: IRoundedActivityReport[], floorReport: IActivitiesFloorReport) => {
			return [...activities, ...floorReport.activities];
		},
		[] as IRoundedActivityReport[]
	);

	const professionActivitiesCount: number = useMemo(() => {
		let counter: number = 0;
		Object.values(props.activityProfessionReport.floors).forEach((floorReport: IActivitiesFloorReport) => {
			counter += floorReport.activities.length;
		});
		return counter;
	}, [props.activityProfessionReport.floors]);

	return (
		<div className={classes.professionRowContainer}>
			<div className={classes.professionCell}>
				<div className={classes.professionNameContainer}>
					<p className={classes.contractor}>{props.activityProfessionReport.profession.contractor}</p>
					<p className={classes.profession}>
						{getProfessionDisplayText(props.activityProfessionReport.profession, true)}
					</p>
				</div>
			</div>
			<div className={classes.activitiesCountContainer}>
				<div className={classes.professionCounter}>
					<p>{professionActivitiesCount}</p>
				</div>
			</div>
			<div className={classes.activitiesContainer}>
				{activities &&
					Object.entries(groupBy(activities, (activity) => activity.floor.floorNick)).map(
						([floorNick, floorActivities], index) => (
							<div className={classes.activityFloorContainer}>
								<div
									style={{ height: `${57 * floorActivities.length}px` }}
									className={classes.floorTextContainer}
								>
									{floorNick}
								</div>
								<div className={classes.activitiesRowsContainer}>
									{floorActivities.map((activityReport) => (
										<ActivityRow
											key={`activityRow_${activityReport.manager?.name}_${index}`}
											activityReport={activityReport}
											isDailyReport={props.isDailyReport}
										/>
									))}
								</div>
							</div>
						)
					)}
			</div>
		</div>
	);
};

export { ProfessionActivitiesRow };
