import React, { useMemo } from 'react';
import { orderBy, sumBy } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { getChartBackgroundColorByPercentage } from '../../../../../../utils/generalUtils';
import {
	IPeriodicProductivityFloorReport,
	IBaseProductivityFloorReport,
	IDailyProductivityFloorReport,
} from '../../../../../../interfaces/IProductivityReport';
import {
	NUMBER_OF_TOP_FLOOR_TO_SHOW,
	PRODUCTIVITY_COLUMN_WIDTH,
	PRODUCTIVITY_HEADER_HEIGHT,
	PRODUCTIVITY_LOCATION_CELL_HEIGHT,
	PRODUCTIVITY_TOTAL_COLUMN_COLOR,
	PRODUCTIVITY_WORK_HOURS_CELL_HEIGHT_DAILY,
	PRODUCTIVITY_WORK_HOURS_CELL_HEIGHT_PERIOD,
	PRODUCTIVITY_WORKERS_QUANTITY_CELL_HEIGHT,
} from '../../../../../../constants';
import { HorizontalBars } from '../../Charts/HorizontalBars';
import { translationService } from '../../../../../../index';
import { getMaximumValueByField, sumByField } from '../../../../../../utils/array.util';
import { IHorizontalBarData } from '../../Charts/interfaces/IHorizontalBarData.interface';
import { SingleVerticalBar } from '../../Charts/SingleVerticalBar';
import { getProfessionTotalWorkDays } from '../../../../../../utils/reports.utils';
import { ProductivityColumnHeader } from './ProductivityColumnHeader/ProductivityColumnHeader';
import { DonutChart } from '../../Charts/DonutChart/DonutChart';
import {
	IRoundedProductivityProfessionReport,
	IRoundedProductivityReport,
} from '../../../../../../interfaces/IRoundedProductivityReport';
import { textStyle } from '@shared/utils/text.utils';
import { regularBorder } from '../../../../../../constants/genericStyles.constants';
import { ActivityStatusTypesColor } from '@shared/constants/constants';
import { IUniqueTagIdsPerDay } from '@src/interfaces';
import { getHoursFromTotalHoursPercentage } from '@src/Components/Pages/Reports/ReportsComponents/Tables/CombinedProductivityTable/utils';

interface IStylesProps {
	isDailyReport?: boolean;
}

const useStyles = makeStyles((theme) => ({
	columnContainer: {
		'display': 'flex',
		'flexDirection': 'column',
		'minWidth': PRODUCTIVITY_COLUMN_WIDTH,
		'maxWidth': PRODUCTIVITY_COLUMN_WIDTH,
		'& > *': {
			border: regularBorder,
		},
	},
	title: textStyle({
		fontClass: 'h2Light',
		margin: 12,
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	}),
	headerContainer: {
		height: PRODUCTIVITY_HEADER_HEIGHT,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		border: regularBorder,
	},
	workerQuantity: {
		height: PRODUCTIVITY_WORKERS_QUANTITY_CELL_HEIGHT,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	workHours: ({ isDailyReport }: IStylesProps) => ({
		height: isDailyReport ? PRODUCTIVITY_WORK_HOURS_CELL_HEIGHT_DAILY : PRODUCTIVITY_WORK_HOURS_CELL_HEIGHT_PERIOD,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	}),
	workHoursText: textStyle({
		fontClass: 'h2Light',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		fontWeight: 500,
	}),
	workResourcesLocation: {
		height: PRODUCTIVITY_LOCATION_CELL_HEIGHT,
		minHeight: PRODUCTIVITY_LOCATION_CELL_HEIGHT,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	horizontalBarsContainer: {
		width: '90%',
		height: '90%',
	},
}));

interface IWorkResourcesColumnProps {
	professionReports: IRoundedProductivityReport;
	maxWorkers: number;
	workTimeUnit: number;
	workersNumber: number;
	isDailyReport: boolean;
}

export const ProductivityTotalColumn = (props: IWorkResourcesColumnProps) => {
	const classes = useStyles({ isDailyReport: props.isDailyReport });

	const totalWorkHoursOnSite: number = useMemo(() => {
		return sumBy(Object.values(props.professionReports), (professionReport) => {
			return professionReport.totalHoursOnSite || 0;
		});
	}, [props.professionReports, props.workTimeUnit, props.isDailyReport]);

	const totalWorkTimeUnit: number = useMemo(() => {
		if (props.isDailyReport) {
			return Object.values(props.professionReports).reduce((acc, professionReport) => {
				return acc + sumByField(Object.values(professionReport.floors), 'totalHours');
			}, 0);
		} else {
			return Object.values(props.professionReports).reduce((acc, professionReport) => {
				return acc + getProfessionTotalWorkDays(professionReport, props.workTimeUnit);
			}, 0);
		}
	}, [props.isDailyReport, props.professionReports, props.workTimeUnit]);

	const computedFloorsReport: IBaseProductivityFloorReport[] = useMemo(() => {
		const uniqueFloorsReport: (IDailyProductivityFloorReport | IPeriodicProductivityFloorReport)[] = [];
		Object.values(props.professionReports).forEach((professionReport) => {
			Object.values(professionReport.floors).forEach((floorReport) => {
				const floorIndex: number = uniqueFloorsReport.findIndex(
					(floor) => floor.floorId === floorReport.floorId
				);
				const existentFloor: IDailyProductivityFloorReport | IPeriodicProductivityFloorReport =
					uniqueFloorsReport[floorIndex];
				if (!existentFloor) {
					uniqueFloorsReport.push(floorReport);
					return;
				}
				uniqueFloorsReport[floorIndex] = {
					...existentFloor,
					...floorReport,
					totalHours: existentFloor.totalHours + floorReport.totalHours,
				};
				if (!props.isDailyReport) {
					(uniqueFloorsReport[floorIndex] as IPeriodicProductivityFloorReport).totalDays =
						(existentFloor as IPeriodicProductivityFloorReport).totalDays +
						(floorReport as IPeriodicProductivityFloorReport).totalDays;
				}
			});
		});

		const sortedFloorsReports: (IDailyProductivityFloorReport | IPeriodicProductivityFloorReport)[] = orderBy(
			Object.values(uniqueFloorsReport),
			['totalHours'],
			['desc']
		);
		const topActivatedFloors: (IDailyProductivityFloorReport | IPeriodicProductivityFloorReport)[] =
			sortedFloorsReports.slice(0, NUMBER_OF_TOP_FLOOR_TO_SHOW);
		const restFloorsReport: IDailyProductivityFloorReport & IPeriodicProductivityFloorReport = {
			floorId: 'other',
			floorNick: translationService.get('other'),
			shortFloorNick: translationService.get('other'),
			tagIds: [],
			uniqueTagIdsPerDay: {},
			totalHours: sumByField(sortedFloorsReports.slice(NUMBER_OF_TOP_FLOOR_TO_SHOW), 'totalHours'),
			totalDays: !props.isDailyReport
				? sumByField(sortedFloorsReports.slice(NUMBER_OF_TOP_FLOOR_TO_SHOW), 'totalDays')
				: 1,
		};
		const mergedFloorsReport: IBaseProductivityFloorReport[] =
			restFloorsReport.totalHours > 0 ? [...topActivatedFloors, restFloorsReport] : topActivatedFloors;

		return mergedFloorsReport;
	}, [props.isDailyReport, props.professionReports]);

	const totalActiveWorkHours: number = useMemo(() => {
		return Object.values(props.professionReports).reduce((acc, professionReport) => {
			return acc + sumByField(Object.values(professionReport.floors), 'totalHours');
		}, 0);
	}, [props.professionReports]);

	const horizontalBarsDataList: IHorizontalBarData[] = useMemo(() => {
		const floorsBarsDataList: IHorizontalBarData[] = computedFloorsReport.map(
			(floorReport: IBaseProductivityFloorReport) => {
				return {
					number: getHoursFromTotalHoursPercentage(floorReport.totalHours, totalActiveWorkHours),
					backgroundColor: PRODUCTIVITY_TOTAL_COLUMN_COLOR,
					label: floorReport.floorNick,
				} as IHorizontalBarData;
			}
		);

		return floorsBarsDataList;
	}, [props.professionReports, computedFloorsReport]);

	const donutBackgroundColor: ActivityStatusTypesColor = useMemo(() => {
		return getChartBackgroundColorByPercentage(totalWorkTimeUnit, props.workTimeUnit);
	}, [totalWorkTimeUnit, props.workTimeUnit]);

	const daysWithAtLeastOneProfession: number = useMemo(() => {
		const uniqueDays: string[] = Object.values(props.professionReports).reduce(
			(accumulatedDays: string[], report: IRoundedProductivityProfessionReport) => {
				Object.values(report.floors).forEach((floorReport) => {
					const uniqueTagIdsPerDay: IUniqueTagIdsPerDay = (floorReport as IPeriodicProductivityFloorReport)
						?.uniqueTagIdsPerDay;

					if (!uniqueTagIdsPerDay) {
						return;
					}

					const reportDays: string[] = Object.keys(uniqueTagIdsPerDay);
					reportDays.forEach((day) => {
						if (!accumulatedDays.includes(day)) {
							accumulatedDays.push(day);
						}
					});
				});

				return accumulatedDays;
			},
			[]
		);

		return Math.min(uniqueDays.length, props.workTimeUnit);
	}, [props.professionReports]);

	const shouldShowWorkDistributionChart: boolean = totalActiveWorkHours > 0;

	return (
		<div className={classes.columnContainer}>
			<ProductivityColumnHeader text={translationService.get('total')} />
			<div className={classes.workerQuantity}>
				{props.workersNumber > 0 && (
					<SingleVerticalBar
						barDataList={[
							{
								barNumber: props.workersNumber,
								backgroundColor: PRODUCTIVITY_TOTAL_COLUMN_COLOR,
							},
						]}
						numberLabel={true}
						barMaximum={props.maxWorkers}
						barWidth={40}
					/>
				)}
			</div>
			<div className={classes.workHours}>
				{props.isDailyReport ? (
					<p className={classes.workHoursText}>{!!totalWorkHoursOnSite && `${totalWorkHoursOnSite}h`}</p>
				) : (
					<DonutChart
						fragment={daysWithAtLeastOneProfession}
						maxNumber={props.workTimeUnit}
						backgroundColor={donutBackgroundColor}
						size={'70%'}
					/>
				)}
			</div>
			<div className={classes.workResourcesLocation}>
				<div className={classes.horizontalBarsContainer}>
					{shouldShowWorkDistributionChart && (
						<div>
							<HorizontalBars
								endNumberLabel={false}
								data={horizontalBarsDataList}
								maxNumber={100}
								barHeight={18}
								tooltipMessage={translationService.get('totalHours')}
								showPercentage
							/>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
