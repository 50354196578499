import { AreaSequenceItemStatus } from '../interfaces/AreaSequenceItemStatus.enum';
import classnames from 'classnames';
import { ActivitySequenceMatrixMode } from '../interfaces/ActivitySequenceMatrixMode.enum';

export const getAreaSequenceItemCellColorClassName = (
	status: AreaSequenceItemStatus | undefined,
	isCheckedForActivityCreation: boolean,
	disableHover: boolean,
	classes: any,
	mode: ActivitySequenceMatrixMode,
	isDone: boolean,
	isReadyForReview: boolean
): string => {
	if (isCheckedForActivityCreation) {
		return classes.checkedForActivityCreation;
	}

	if (isDone) {
		return classnames({
			[classes.done]: true,
			[classes.disableHover]: disableHover,
		});
	}

	if (isReadyForReview) {
		return classnames({
			[classes.readyForReview]: true,
			[classes.disableHover]: disableHover,
		});
	}

	if (!status) {
		if (mode === ActivitySequenceMatrixMode.schedule) return classes.scheduleModeUnscheduled;
		return classes.unscheduled;
	}
	if (status === AreaSequenceItemStatus.unplanned) {
		if (mode === ActivitySequenceMatrixMode.schedule) return classes.scheduleModeUnscheduled;
		return classes.unscheduled;
	}

	switch (status) {
		case AreaSequenceItemStatus.inProgress:
			return classnames({
				[classes.inProgress]: true,
				[classes.disableHover]: disableHover,
			});
		case AreaSequenceItemStatus.delayedOverdue:
		case AreaSequenceItemStatus.overdue:
			return classnames({
				[classes.overdue]: true,
				[classes.disableHover]: disableHover,
			});
		case AreaSequenceItemStatus.delayed:
		case AreaSequenceItemStatus.planned:
			return classnames({
				[classes.upComing]: true,
				[classes.disableHover]: disableHover,
			});
		default:
			return '';
	}
};
