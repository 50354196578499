import React, { useContext, useEffect, useState } from 'react';
import { CalendarToggleOptions } from '../../../constants';
import { IWeekDayActivity } from '../../../interfaces';
import { getColorFromActivityGroupStatus } from '../../../utils/generalUtils';
import { CalendarCellChipContent } from '../CalendarCellChipContent/CalendarCellChipContent';
import { ActivityChipMenu } from '../ActivityChipMenu/ActivityChipMenu';
import classes from './styles.module.scss';
import { accessLevelService, translationService } from '../../../index';
import { useElementDistanceFromBottom, useElementDistanceFromEnd, useOutsideClick } from '@shared/hooks/custom.hooks';
import { ActivityStartActionDialog } from '../../ActivityDialogs/ActivityStartActionDialog/ActivityStartActionDialog';
import { ActivityMarkAsCompleteActionDialog } from '../../ActivityDialogs/ActivityMarkAsCompleteActionDialog/ActivityMarkAsCompleteActionDialog';
import { ActivityDeleteActionDialog } from '../../ActivityDialogs/ActivityDeleteActionDialog/ActivityDeleteActionDialog';
import { workplanDeleteActivity_BI, workplanManualActionMade_BI } from '../../../utils/bi.utils';
import { selectTimezone } from '../../../store/slices/project.slice';
import { useSelector } from 'react-redux';
import { IActivityActionsEnum } from 'src/interfaces/IActivityActions.enum';
import { getWeekDayActivityFromActivitiesGroup } from '../../../utils/workPlan.utils';
import { IActivitiesGroup } from '../../../interfaces/IActivitiesGroup';
import { WorkplanContext } from '../../Pages/Workplan/WorkPlan';
import { IssueCreateDialog } from '@src/Components/IssueCreateDialog/IssueCreateDialog';
import { IConfigArea } from '@shared/interfaces/IConfigArea';
import { IIssue } from '@shared/interfaces/IIssue';
import { ActivitySequenceItemMenuActions } from '@src/Components/WorkPlan/ActivityProgressTracker/components/ActivitySequenceMatrix/cellComponents/ActivitySequenceItemCell/activitySequence.menuItems';
import { MultiIssuesViewDialog } from '@src/Components/MultiIssuesViewDialog/MultiIssuesViewDialog';

interface ICalendarCellChipsProps {
	activity: IWeekDayActivity;
	workPlanToggle: CalendarToggleOptions;
	openEditDialog?: (activityId: string) => void;
	chipWidth: number;
	maxContentWidth: number;
}

const getChipColor = (activity: IWeekDayActivity): string => {
	return getColorFromActivityGroupStatus(activity.status);
};

export const CalendarCellChip = (props: ICalendarCellChipsProps) => {
	const [showStartActivityDialog, setShowStartActivityDialog] = useState<boolean>(false);
	const [showCompleteActivityDialog, setShowCompleteActivityDialog] = useState<boolean>(false);
	const [showDeleteActivityDialog, setShowDeleteActivityDialog] = useState<boolean>(false);
	const [showCreateIssueDialog, setShowCreateIssueDialog] = useState<boolean>(false);
	const [isViewIssuesDialogOpen, setIsViewIssuesDialogOpen] = useState<boolean>(false);
	const [showMenu, setShowMenu] = useState<boolean>(false);
	const [menuElement, setMenuElement] = useState<HTMLElement | null>(null);
	const [chipElement, setChipElement] = useState<HTMLElement | null>(null);
	const [menuHeight, setMenuHeight] = useState<number>();
	const tz: string = useSelector(selectTimezone)!;

	const distanceFromChipEndToWindowEnd: number | undefined = useElementDistanceFromEnd(
		chipElement,
		translationService.getDirection()
	);
	const distanceFromChipBottomToWindowBottom: number | undefined = useElementDistanceFromBottom(chipElement);
	useOutsideClick({ current: menuElement }, setShowMenu, false, [{ current: chipElement }]);

	const menuWidth: number = 150;

	const menuRelativeStartPointFromChipEnd: number = 24;
	const neededDistanceForMenu: number = menuWidth - menuRelativeStartPointFromChipEnd;
	const paddingFromWindowEnd: number = 40;
	const menuDefaultIndentationLength: number = props.chipWidth + menuWidth - menuRelativeStartPointFromChipEnd;
	const [menuHorizontalIndentationPixels, setMenuHorizontalIndentationPixels] =
		useState<number>(menuDefaultIndentationLength);
	const verticalMarginFromChip: number = 8;
	const menuDefaultVerticalIndentationLength: number = 28;
	const [menuVerticalIndentationPixels, setMenuVerticalIndentationPixels] = useState<number>(
		menuDefaultVerticalIndentationLength
	);
	const { onActivityUpdate } = useContext(WorkplanContext)!;

	useEffect(() => {
		if (distanceFromChipEndToWindowEnd === undefined) {
			return;
		}

		if (distanceFromChipEndToWindowEnd < neededDistanceForMenu) {
			setMenuHorizontalIndentationPixels(
				menuDefaultIndentationLength -
					(neededDistanceForMenu - distanceFromChipEndToWindowEnd) -
					paddingFromWindowEnd
			);
			return;
		}

		setMenuHorizontalIndentationPixels(menuDefaultIndentationLength);
	}, [distanceFromChipEndToWindowEnd]);

	useEffect(() => {
		if (distanceFromChipBottomToWindowBottom === undefined || !menuHeight) {
			return;
		}

		if (distanceFromChipBottomToWindowBottom < menuHeight) {
			setMenuVerticalIndentationPixels(-1 * (verticalMarginFromChip + menuHeight));
			return;
		}

		setMenuVerticalIndentationPixels(menuDefaultVerticalIndentationLength);
	}, [distanceFromChipBottomToWindowBottom, menuHeight]);

	const openActivityDialog = (event: React.MouseEvent) => {
		// prevent the onclick of the parent div when clicking the chip
		event.stopPropagation();
		props.openEditDialog && props.activity.groupId && props.openEditDialog(props.activity.groupId);
	};

	const onChipClick = (event: React.MouseEvent) => {
		setShowMenu(false);
		openActivityDialog(event);
	};

	const shouldShowDisclaimerTextForComplete = (): boolean => {
		return props.activity.floors.some((floor) => floor.workTime <= 0);
	};

	const dialogsDisclaimerPoints: string[] | undefined =
		props.workPlanToggle === CalendarToggleOptions.FLOORS
			? [translationService.get('actionAppliesToAllFloors')]
			: undefined;

	const sendActionBiEvent = (action: IActivityActionsEnum) => {
		workplanManualActionMade_BI('Workplan', {
			actionType: action,
			description: props.activity.description,
			groupId: props.activity.groupId!,
			profession: props.activity.profession,
			managerName: props.activity.assignee,
			status: props.activity.status,
		});
	};

	const chipColor: string = getChipColor(props.activity);

	const onActionComplete = (updatedActivitiesGroup: IActivitiesGroup): void => {
		const updatedWeekDayActivity: IWeekDayActivity = getWeekDayActivityFromActivitiesGroup(
			props.activity,
			updatedActivitiesGroup
		);
		onActivityUpdate(updatedWeekDayActivity);
	};

	const onDeleteActionComplete = (): void => {
		onActivityUpdate(props.activity, true);
	};

	const openCreateIssueDialog = async (actionType: ActivitySequenceItemMenuActions) => {
		setShowCreateIssueDialog(true);
	};

	const onCloseCreateDialog = () => {
		setShowCreateIssueDialog(false);
	};

	const onCreateIssue = (createdIssue: IIssue) => {
		const updatedActivity: IWeekDayActivity = {
			...props.activity,
			issues: [...(props.activity.issues ? props.activity.issues : []), createdIssue],
		};

		onActivityUpdate(updatedActivity);
		setShowCreateIssueDialog(false);
	};

	const openViewIssuesDialog = async (actionType: ActivitySequenceItemMenuActions) => {
		setIsViewIssuesDialogOpen(true);
	};

	const onUpdateIssue = (updatedIssue: IIssue, deleted?: boolean) => {
		const updatedIssues: IIssue[] = deleted
			? props.activity.issues!.filter((issue) => issue._id !== updatedIssue._id)
			: props.activity.issues!.map((issue) => {
					if (issue._id === updatedIssue._id) {
						return updatedIssue;
					}
					return issue;
				});

		const updatedActivity: IWeekDayActivity = {
			...props.activity,
			issues: updatedIssues,
		};

		onActivityUpdate(updatedActivity);
	};

	return (
		<div>
			<ActivityStartActionDialog
				show={showStartActivityDialog}
				activityGroupId={props.activity.groupId!}
				onClose={() => {
					setShowStartActivityDialog(false);
				}}
				disclaimerPoints={dialogsDisclaimerPoints}
				sendBiEventOnSubmit={sendActionBiEvent}
				onActionComplete={onActionComplete}
			/>
			<ActivityMarkAsCompleteActionDialog
				show={showCompleteActivityDialog}
				activityGroupId={props.activity.groupId!}
				onClose={() => {
					setShowCompleteActivityDialog(false);
				}}
				showNoWorkDetectedDisclaimerText={shouldShowDisclaimerTextForComplete()}
				disclaimerPoints={dialogsDisclaimerPoints}
				sendBiEventOnSubmit={sendActionBiEvent}
				onActionComplete={onActionComplete}
			/>
			<ActivityDeleteActionDialog
				sendBiEventOnSubmit={sendActionBiEvent}
				show={showDeleteActivityDialog}
				activityGroupId={props.activity.groupId!}
				onClose={() => {
					setShowDeleteActivityDialog(false);
				}}
				disclaimerPoints={dialogsDisclaimerPoints}
				onDeleteClick={() => {
					workplanDeleteActivity_BI(props.activity, tz);
				}}
				onActionComplete={onDeleteActionComplete}
			/>
			<IssueCreateDialog
				showCreateDialog={showCreateIssueDialog}
				onClose={onCloseCreateDialog}
				onCreateIssue={onCreateIssue}
				defaultProfession={props.activity.profession}
				defaultAreas={props.activity.areas as IConfigArea[]}
				defaultFloors={props.activity.floors}
				linkedActivityGroupId={props.activity.groupId}
			/>
			<MultiIssuesViewDialog
				onUpdateIssue={onUpdateIssue}
				show={isViewIssuesDialogOpen && !!props.activity.issues?.length}
				close={() => setIsViewIssuesDialogOpen(false)}
				issues={props.activity.issues || []}
				headerTitle={props.activity.description}
				headerInfo={{
					value: translationService.get(props.activity.status),
					backgroundColor: chipColor,
				}}
			/>
			<div className={classes.cellChipContainer} ref={setChipElement as any}>
				<CalendarCellChipContent
					onThreeDotsClick={(e) => {
						e.stopPropagation();
						setShowMenu((prev) => !prev);
					}}
					maxChipWidth={props.maxContentWidth}
					activity={props.activity}
					workPlanToggle={props.workPlanToggle}
					disableTooltip={showMenu}
					maxContentWidth={props.maxContentWidth}
					click={onChipClick}
					chipWidth={props.chipWidth}
				/>
			</div>
			{showMenu && accessLevelService.hasAccess('workplanActivityActions') && (
				<div
					ref={setMenuElement as any}
					className={classes.chipMenu}
					style={{
						...(translationService.getIsRtl()
							? { left: `-${menuHorizontalIndentationPixels}px` }
							: { right: `-${menuHorizontalIndentationPixels}px` }),
						top: `${menuVerticalIndentationPixels}px`,
					}}
				>
					<ActivityChipMenu
						width={menuWidth}
						activity={props.activity}
						openEditDialog={openActivityDialog}
						openCompleteDialog={() => {
							setShowCompleteActivityDialog(true);
						}}
						openStartDialog={() => {
							setShowStartActivityDialog(true);
						}}
						openDeleteDialog={() => {
							setShowDeleteActivityDialog(true);
						}}
						onMenuHeightChange={setMenuHeight as any}
						openCreateIssueDialog={openCreateIssueDialog}
						openViewIssuesDialog={openViewIssuesDialog}
					/>
				</div>
			)}
		</div>
	);
};
