import { ICreateIssue, IIssue } from '@shared/interfaces/IIssue';
import { requestService } from '@src/servicesInitializers';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { IIssuesSortingSettings } from '@shared/interfaces/IIssuesSortingSettings';
import { QueryKeys } from '@shared/constants/QueryKeys.enum';

interface IssuesQueryParams {
	notCompleted?: boolean;
	username?: string;
}

export const useProjectIssuesQuery = (projectId: string, queryParams?: IssuesQueryParams) => {
	const {
		data: issues,
		isError,
		refetch: refetchIssues,
		isFetched,
	} = useQuery<IIssue[]>(['getIssues', projectId, queryParams?.notCompleted, queryParams?.username], () =>
		requestService.get(`/issues/byProjectId/${projectId}`, { params: queryParams })
	);
	return { issues, refetchIssues, isFetched: isFetched || isError };
};

export const useIssuesSortingSettingsQuery = (projectId: string) => {
	const { data: sortingSettings, isLoading: isIssuesSortingSettingsLoading } = useQuery<IIssuesSortingSettings>(
		[QueryKeys.getIssuesSortingSettings, projectId],
		() => requestService.get(`/issues/sortingSettings/${projectId}`)
	);
	return { sortingSettings, isIssuesSortingSettingsLoading };
};

export const useUpdateIssuesSortingSettingsMutation = (projectId: string) => {
	const queryClient = useQueryClient();

	return useMutation(
		async (sortingSettings: Omit<IIssuesSortingSettings, 'projectId'>) => {
			return requestService.put(`/issues/sortingSettings/${projectId}`, {
				body: sortingSettings,
			});
		},
		{
			onMutate: async () => {
				await queryClient.cancelQueries([QueryKeys.getIssuesSortingSettings, projectId]);
			},
			onSuccess: async (data) => {
				await queryClient.invalidateQueries([QueryKeys.getIssuesSortingSettings, projectId]);
				return data;
			},
		}
	);
};
