import React, { useState, useEffect } from 'react';
import { TranslationService } from '../../../services/translation.service';
import { TrusstorTextInput } from '../TrusstorTextInput/TrusstorTextInput';

interface IEmailInputFieldProps {
	translationService: TranslationService;
	required?: boolean;
	isError?: boolean;
	errorMessage?: string;
	changeFunction: (email: string) => void;
	defaultValue?: string;
	setIsValidEmail?: (isValidEmail: boolean) => void;
	disabled?: boolean;
}

export const EmailInputField = (props: IEmailInputFieldProps) => {
	const [email, setEmail] = useState<string>(props.defaultValue || '');
	const [emailError, setEmailError] = useState<boolean>(!!props.isError);

	useEffect(() => {
		setEmailError(!!props.isError);
	}, [props.isError]);

	const handleEmailVerification = (e) => {
		if (props.isError) return;
		if (e.target.value === '') {
			props.setIsValidEmail?.(false);
			setEmailError(false);
			return;
		}
		const email = e.target.value;
		const emailRegex =
			/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
		const isEmailNotValid: boolean = !emailRegex.test(email);
		setEmailError(isEmailNotValid);
		props.setIsValidEmail?.(!isEmailNotValid);
	};

	const handleEmailChange = (value: string) => {
		if (email && emailError) {
			setEmailError(false);
			props.setIsValidEmail?.(true);
		}
		props.changeFunction(value);
		setEmail(value);
	};

	return (
		<div data-testid="EmailInputField">
			<TrusstorTextInput
				required={props.required}
				value={props.defaultValue}
				disabled={props.disabled}
				label={props.translationService.get('email')}
				isError={emailError}
				type="email"
				onBlur={handleEmailVerification}
				errorText={props.isError ? props.errorMessage : props.translationService.get('invalidEmailError')}
				changeFunc={handleEmailChange}
			/>
		</div>
	);
};
