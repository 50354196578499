import { compact } from 'lodash';
import { entitiesProperties } from '../../constants';
import { filterArrayByAnotherArray } from '../../utils/array.util';
import { IEquipmentReducerState } from '../../interfaces/IEquipmentReducerState';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IStaticEquipment } from '../../interfaces';
import { IMergedEquipmentView } from '../../interfaces/IMergedEquipmentView';

const initialState: IEquipmentReducerState = {
	staticEquipment: [],
	activeEquipment: [],
	selectedStaticEquipment: [],
	visibleActiveEquipment: [],
};

const equipmentSlice = createSlice({
	name: 'equipment',
	initialState,
	reducers: {
		updateStaticEquipment: (
			state: IEquipmentReducerState,
			action: PayloadAction<{ staticEquipment: IStaticEquipment[] }>
		) => {
			state.staticEquipment = action.payload.staticEquipment;
		},
		updateActiveEquipment: (
			state: IEquipmentReducerState,
			action: PayloadAction<{ activeEquipment: IMergedEquipmentView[] }>
		) => {
			const visibleActiveEquipment: IMergedEquipmentView[] = filterArrayByAnotherArray(
				action.payload.activeEquipment,
				state.selectedStaticEquipment,
				entitiesProperties.type
			);
			state.activeEquipment = action.payload.activeEquipment;
			state.visibleActiveEquipment = visibleActiveEquipment;
		},
		addOneSelectedEquipmentType: (
			state: IEquipmentReducerState,
			action: PayloadAction<{ selectedEquipmentType: string }>
		) => {
			const equipmentToAdd: IStaticEquipment[] = state.staticEquipment.filter(
				(equipment: IStaticEquipment) => equipment.type === action.payload.selectedEquipmentType
			);
			const selectedEquipmentAfterAdd: IStaticEquipment[] = compact(
				state.selectedStaticEquipment.concat(equipmentToAdd)
			);
			const visibleActiveEquipment: IMergedEquipmentView[] = filterArrayByAnotherArray(
				state.activeEquipment,
				selectedEquipmentAfterAdd,
				entitiesProperties.type
			);
			state.selectedStaticEquipment = selectedEquipmentAfterAdd;
			state.visibleActiveEquipment = visibleActiveEquipment;
		},
		removeOneSelectedEquipmentType: (
			state: IEquipmentReducerState,
			action: PayloadAction<{ removedEquipmentType: string }>
		) => {
			const selectedEquipmentAfterRemove: IStaticEquipment[] = state.selectedStaticEquipment.filter(
				(selectedEq) => {
					return action.payload.removedEquipmentType !== selectedEq.type;
				}
			);
			const visibleActiveEquipment: IMergedEquipmentView[] = filterArrayByAnotherArray(
				state.activeEquipment,
				selectedEquipmentAfterRemove,
				entitiesProperties.type
			);
			state.selectedStaticEquipment = selectedEquipmentAfterRemove;
			state.visibleActiveEquipment = visibleActiveEquipment;
		},
		resetSelectedEquipment: (state: IEquipmentReducerState) => {
			state.selectedStaticEquipment = [];
			state.visibleActiveEquipment = state.activeEquipment;
		},
		resetEquipment: (state: IEquipmentReducerState) => {
			state.staticEquipment = [];
			state.activeEquipment = [];
			state.selectedStaticEquipment = [];
			state.visibleActiveEquipment = [];
		},
	},
});

export const {
	updateStaticEquipment,
	updateActiveEquipment,
	resetEquipment,
	resetSelectedEquipment,
	addOneSelectedEquipmentType,
	removeOneSelectedEquipmentType,
} = equipmentSlice.actions;
export default equipmentSlice.reducer;
