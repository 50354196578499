import React, { useEffect, useMemo, useState } from 'react';
import { getTimezoneDateRanges, getTimezoneFormattedDate } from '../../../../../../utils/dateUtils';
import { IActivitiesFloorReport, IActivitiesProfessionReport } from '../../../../../../interfaces/IActivitiesReport';
import { makeStyles } from '@material-ui/core/styles';
import { textStyle } from '@shared/utils/text.utils';
import { ACTIVITIES_GANTT_HEADERS_ROW_HEIGHT, ACTIVITIES_GANTT_ROW_HEIGHT } from '../../../../../../constants';
import { ActivitiesGanttRowHeaders } from './ActivitiesGanttRowHeaders';
import moment from 'moment';
import { FloorGanttRow } from './FloorGanttRow';
import { COLORS } from '@shared/constants/colors.constants';
import { translationService } from '../../../../../../index';
import { IRootState } from '../../../../../../store/slices';
import { useSelector } from 'react-redux';
import { SORT_ORDER } from '@shared/constants/constants';
import { sortFloors } from '@shared/utils/sort.utils';

const useStyles = makeStyles((theme) => ({
	tableContainerWrapper: {
		display: 'flex',
		backgroundColor: COLORS.white,
		marginTop: theme.spacing(1),
	},
	tableContainer: {
		width: '100%',
		overflowX: 'auto',
		overflowY: 'hidden',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		backgroundColor: COLORS.white,
	},
	emptyHeaderCell: {
		height: ACTIVITIES_GANTT_HEADERS_ROW_HEIGHT,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		width: '95%',
		marginTop: theme.spacing(1),
		marginLeft: theme.spacing(1),
	},
	title: textStyle({
		fontClass: 'body1',
		margin: theme.spacing(1.5),
	}),
	floorHeaderCell: {
		minHeight: ACTIVITIES_GANTT_ROW_HEIGHT,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	emptyStateContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100%',
	},
	emptyState: textStyle({
		fontClass: 'h1',
	}),
}));

interface IActivitiesGanttTableProps {
	professionActivitiesReport: IActivitiesProfessionReport;
	dateFrom: Date;
	dateTo: Date;
}

const ActivitiesGanttTable = (props: IActivitiesGanttTableProps) => {
	const classes = useStyles();
	const [numberOfReportDays, setNumberOfReportDays] = useState<number>(0);
	const tz: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.tz);

	useEffect(() => {
		setNumberOfReportDays(moment(props.dateTo).diff(moment(props.dateFrom), 'days') + 1);
	}, [props.dateTo.getTime(), props.dateFrom.getTime()]);

	const reportDates: string[] = useMemo(() => {
		return getTimezoneDateRanges(props.dateFrom, props.dateTo, tz).map((date: Date) =>
			getTimezoneFormattedDate(tz, date)
		);
	}, [props.dateFrom.getTime(), props.dateTo.getTime(), tz]);

	return (
		<div className={classes.tableContainerWrapper}>
			<div className={classes.tableContainer}>
				{props.professionActivitiesReport && Object.keys(props.professionActivitiesReport).length > 0 ? (
					<React.Fragment>
						<ActivitiesGanttRowHeaders dateRanges={reportDates} />
						{props.professionActivitiesReport.floors &&
							sortFloors<IActivitiesFloorReport>(
								Object.values(props.professionActivitiesReport.floors),
								SORT_ORDER.DESCENDING
							).map((floorReport: IActivitiesFloorReport) => (
								<FloorGanttRow
									key={`gantTable_${floorReport.floorId}`}
									ganttStartDate={props.dateFrom}
									ganttEndDate={props.dateTo}
									numberOfDays={numberOfReportDays}
									floorActivitiesReport={floorReport}
								/>
							))}
					</React.Fragment>
				) : (
					<div className={classes.emptyStateContainer}>
						<p className={classes.emptyState}>{translationService.get('emptyStateActivitiesGantt')}</p>
					</div>
				)}
			</div>
		</div>
	);
};

export { ActivitiesGanttTable };
