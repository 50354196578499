import { getIssueStatusMainText } from '../../../utils/issues.utils';
import { TranslationService } from '../../../services/translation.service';
import { IIssueStatusUpdatedNotificationMetadata } from '../../../interfaces/IIssueStatusUpdatedNotificationMetadata';

export const getIssueStatusUpdatedTitleText = (
	translationService: TranslationService,
	metadata: IIssueStatusUpdatedNotificationMetadata
): string => {
	return translationService.get('issue_updatedToStatus_notification', {
		description: metadata.description,
		new_status: getIssueStatusMainText(metadata.newStatus, translationService),
	});
};
