import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getTimezoneFormattedDate } from '../utils/dateUtils';
import { getWorkingProfessions } from '../store/thunks/professions.thunks';
import { IRootState } from '../store/slices';
import { IDateRangesHookGetOptions } from '../interfaces';
import { resetWorkingProfessionsRequestCount } from '../store/slices/professions.slice';

export const useWorkingProfessions = ({
	withoutDateRanges,
	useVisibleWeek,
	useOnlyStartDate,
	includeDeleted,
	includeInactive,
}: IDateRangesHookGetOptions) => {
	const projectId: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.projectId);
	const tz: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.tz);
	const visibleDateRangeTimestamps: number[] = useSelector(
		(state: IRootState) => state.datePickerReducer.visibleDateRangeTimestamps
	);
	const visibleDateTimestamp: number = useSelector(
		(state: IRootState) => state.datePickerReducer.visibleDateTimestamp
	);
	const visiblePeriodTimestamps: [number, number] = useSelector(
		(state: IRootState) => state.datePickerReducer.visiblePeriodTimestamps
	);

	const dispatch = useDispatch();

	const visibleWeekStartDate: string = getTimezoneFormattedDate(tz, new Date(visibleDateRangeTimestamps[0]));
	const visibleWeekEndDate: string = getTimezoneFormattedDate(
		tz,
		new Date(visibleDateRangeTimestamps[visibleDateRangeTimestamps.length - 1])
	);
	const visibleDate: string = getTimezoneFormattedDate(tz, new Date(visibleDateTimestamp));
	const visiblePeriodStartDate: string = getTimezoneFormattedDate(tz, new Date(visiblePeriodTimestamps[0]));
	const visiblePeriodEndDate: string = getTimezoneFormattedDate(tz, new Date(visiblePeriodTimestamps[1]));

	useEffect(() => {
		return () => {
			dispatch(resetWorkingProfessionsRequestCount());
		};
	}, []);

	useEffect(() => {
		if (withoutDateRanges) {
			dispatch(getWorkingProfessions({ projectId, includeInactive }));
			return;
		}
		if (useVisibleWeek) {
			dispatch(
				getWorkingProfessions({
					projectId,
					startDate: visibleWeekStartDate,
					endDate: visibleWeekEndDate,
					includeDeleted,
					includeInactive,
				})
			);
		} else if (useOnlyStartDate) {
			dispatch(
				getWorkingProfessions({
					projectId,
					startDate: visibleDate,
					includeDeleted,
					includeInactive,
				})
			);
		} else {
			dispatch(
				getWorkingProfessions({
					projectId,
					startDate: visiblePeriodStartDate,
					endDate: visiblePeriodEndDate,
					includeDeleted,
					includeInactive,
				})
			);
		}
	}, [
		visibleDateRangeTimestamps[0],
		visibleDateRangeTimestamps[visibleDateRangeTimestamps.length - 1],
		visibleDateTimestamp,
		visiblePeriodTimestamps[0],
		visiblePeriodTimestamps[1],
		projectId,
		includeInactive,
		includeDeleted,
		useVisibleWeek,
		useOnlyStartDate,
	]);
};
