import { groupBy } from 'lodash';
import React, { useState } from 'react';
import { useFloorViewDataQuery } from '../../hooks/queries/floors.queries.hooks';
import { useSharedServices } from '../../hooks/sharedServices.context';
import { IIssueLocation } from '../../interfaces/IIssueShared';
import { ISelectedPointWithAreaId } from '../../interfaces/ISelectedPoint';
import { BackForwardSection } from '../BackNextSection/BackForwardSection';
import { FloorPlanDisplay } from '../FloorPlanDisplay/FloorPlanDisplay';
import { Loader } from '../Loader/Loader';
import classes from './styles.module.scss';
import { getSelectedPointWithAreaIdFromIssueLocations } from '../Issue/issues.utils';

interface IMultiFloorPlanDisplayProps {
	projectId: string;
	issueLocations: IIssueLocation[];
}

export const MultiFloorPlanDisplay = (props: IMultiFloorPlanDisplayProps) => {
	const { requestService } = useSharedServices();
	const locationsWithSelectedPoints: IIssueLocation[] = props.issueLocations.filter(
		(location: IIssueLocation) => location.area.selectedPoints?.length
	);
	const [floorIndex, setFloorIndex] = useState<number>(0);
	const mergedByFloor: { [floorId: string]: IIssueLocation[] } = groupBy(
		locationsWithSelectedPoints,
		(location: IIssueLocation) => location.floor.floorId
	);
	const numberOfFloors: number = Object.keys(mergedByFloor).length;
	const currentFloorLocations: IIssueLocation[] = mergedByFloor[Object.keys(mergedByFloor)[floorIndex]];
	const { floorViewSvg, isLoading } = useFloorViewDataQuery(
		requestService,
		props.projectId,
		currentFloorLocations[0].floor.floorId
	);
	const currentFloorSelectedPoints: ISelectedPointWithAreaId[] =
		getSelectedPointWithAreaIdFromIssueLocations(currentFloorLocations);

	return (
		<div className={classes.multiFloorPlanDisplayContainer} onClick={(e) => e.stopPropagation()}>
			<BackForwardSection totalCount={numberOfFloors} onIndexChange={setFloorIndex} />
			{isLoading && (
				<div className={classes.loaderContainer}>
					<Loader />
				</div>
			)}
			{floorViewSvg && (
				<div className={classes.floorPlanContainer}>
					<p className={classes.floorNickText}>{currentFloorLocations[0].floor.floorNick}</p>
					<FloorPlanDisplay pinCoordinates={currentFloorSelectedPoints} floorPlanSvg={floorViewSvg} />
				</div>
			)}
		</div>
	);
};
