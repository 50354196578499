import classnames from 'classnames';
import { IProfession } from '@interfaces/IProfession';
import { IProfessionDisplaySettings } from '@shared/interfaces/IProfessionDisplaySettings';
import { TrusstorCheckboxWithText } from '@src/Components/Inputs/TrusstorCheckboxWithText/TrusstorCheckboxWithText';
import { translationService } from '@src/servicesInitializers';
import React from 'react';
import classes from './styles.module.scss';

interface IDisplaySettingsPickerProps {
	isPropertyChecked: (propertyName: keyof IProfessionDisplaySettings) => boolean;
	isDisabled: (propertyName: keyof IProfessionDisplaySettings) => boolean;
	onCheckboxChange: (isChecked: boolean, propertyName: keyof IProfessionDisplaySettings) => void;
	hideSpecialty?: boolean;
	hideContractor?: boolean;
	hideBorder?: boolean;
}

export const DisplaySettingsPicker = (props: IDisplaySettingsPickerProps) => {
	const settingSectionClassName: string = classnames(classes.settingSection, {
		[classes.noBorder]: props.hideBorder,
	});

	return (
		<div className={classes.settingsPickerContainer}>
			<p className={classes.displayProfessionText}>{translationService.get('displayProfession')}</p>
			<div className={classes.displayCheckboxSection}>
				<div className={settingSectionClassName}>
					<TrusstorCheckboxWithText
						text={translationService.get('trade')}
						checked={props.isPropertyChecked('trade')}
						disabled={props.isDisabled('trade')}
						onChange={(isChecked: boolean) => props.onCheckboxChange(isChecked, 'trade')}
					/>
				</div>
				{!props.hideSpecialty && (
					<div className={settingSectionClassName}>
						<TrusstorCheckboxWithText
							text={translationService.get('specialty')}
							checked={props.isPropertyChecked('specialty')}
							disabled={props.isDisabled('specialty')}
							onChange={(isChecked: boolean) => props.onCheckboxChange(isChecked, 'specialty')}
						/>
					</div>
				)}
				{!props.hideContractor && (
					<div className={settingSectionClassName}>
						<TrusstorCheckboxWithText
							text={translationService.get('contractor')}
							checked={props.isPropertyChecked('contractor')}
							disabled={props.isDisabled('contractor')}
							onChange={(isChecked: boolean) => props.onCheckboxChange(isChecked, 'contractor')}
						/>
					</div>
				)}
			</div>
		</div>
	);
};
