import classes from './styles.module.scss';
import React, { useEffect } from 'react';
import { COLORS } from '../../constants/colors.constants';
import { sum } from 'lodash';

interface ITrusstorMenuProps {
	items: IMenuItem[];
	width?: number;
	onMenuHeightChange?: (height) => void;
	menuTitle?: string;
}

export interface IMenuItem {
	iconSrc?: string;
	iconComponent?: React.ReactElement;
	title: string;
	onClick: (e) => void;
	circleColor?: string;
	backgroundColor?: string;
	borderTop?: boolean;
	borderBottom?: boolean;
	key?: any;
	testId?: string;
}

export const TrusstorMenu = (props: ITrusstorMenuProps) => {
	const borderHeight: number = 1;
	const borderStyle: string = `${borderHeight}px solid ${COLORS.white}`;
	const menuItemHeight: number = 35;

	useEffect(() => {
		if (!props.onMenuHeightChange) {
			return;
		}

		const menuHeight: number = sum(
			props.items.map((item) => {
				const borderTopHeight: number = item.borderTop ? borderHeight : 0;
				const borderBottomHeight: number = item.borderBottom ? borderHeight : 0;
				return menuItemHeight + borderBottomHeight + borderTopHeight;
			})
		);

		props.onMenuHeightChange(menuHeight);
	}, [props.items.length]);

	return (
		<div className={classes.menu} style={{ minWidth: props.width || 'initial' }}>
			{props.menuTitle && (
				<div
					onClick={(event) => {
						event.stopPropagation();
					}}
					className={classes.menuTitle}
				>
					{props.menuTitle}
				</div>
			)}
			{props.items.map((item: IMenuItem) => (
				<div
					key={item.title}
					onClick={(e) => {
						e.stopPropagation();
						item.onClick(e);
					}}
					className={classes.item}
					style={{
						...(item.backgroundColor && {
							backgroundColor: item.backgroundColor,
						}),
						borderTop: item.borderTop ? borderStyle : 'initial',
						borderBottom: item.borderBottom ? borderStyle : 'initial',
						height: `${menuItemHeight}px`,
					}}
					data-testid={item.testId}
				>
					{item.circleColor && (
						<div className={classes.circle} style={{ backgroundColor: item.circleColor }}></div>
					)}
					{item.iconSrc && <img className={classes.icon} alt={''} src={item.iconSrc} />}
					{item.iconComponent && <div className={classes.icon}>{item.iconComponent}</div>}
					<div className={classes.title}>{item.title}</div>
				</div>
			))}
		</div>
	);
};
