import { requestService } from '..';
import { IMergedAreaSequenceItem } from '@interfaces/IMergedAreaSequenceItem';
import { AreaSequenceItemStatus } from '@shared/interfaces/AreaSequenceItemStatus.enum';
import { IRequestHeaders } from '@shared/interfaces/IRequsetHeaders';
import { isAsiComplete } from '@shared/utils/asi.utils';

export const updateAreaSequenceItemStatus = async (
	areaSequenceItemId: string,
	status: AreaSequenceItemStatus
): Promise<IMergedAreaSequenceItem> => {
	return await requestService.put(`/activities/sequenceItems/areaSequenceItem/${areaSequenceItemId}/status`, {
		body: { status },
	});
};

export const updateAreaSequenceItemReadyForReviewStatus = async (
	areaSequenceItemId: string,
	updateToReadyForReview: boolean
): Promise<IMergedAreaSequenceItem> => {
	return await requestService.put(`/activities/sequenceItems/areaSequenceItem/${areaSequenceItemId}/readyForReview`, {
		body: { isReadyForReview: updateToReadyForReview },
	});
};

export const updateBulkAreaSequenceItems = async (
	ids: string[],
	status: AreaSequenceItemStatus,
	requestHeaders?: IRequestHeaders
): Promise<{
	failedIds: string[];
	areaSequenceItems: IMergedAreaSequenceItem[];
}> => {
	return await requestService.put(`/activities/sequenceItems/areaSequenceItem/status/bulk`, {
		body: { ids, status },
		headers: requestHeaders,
	});
};
