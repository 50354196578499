import { IUserService, ILocalStorageUserData } from '../interfaces/IUserService';
import { IUser } from '../interfaces/IUser';
import { RoleType } from '../interfaces/RoleType.enum';

class UserService implements IUserService {
	static loggedUserAtt: string = 'loggedUser';
	static previousLoginExistsAtt: string = 'previousLoginExists';
	private user: IUser | undefined = undefined;

	public setUserDetails = (user: IUser): void => {
		this.user = user;
	};

	public setLSUserData(userData: ILocalStorageUserData): void {
		localStorage.setItem(UserService.loggedUserAtt, JSON.stringify(userData));
	}

	public setPreviousLoginExistsAtt = (isFirstLogin: boolean) => {
		localStorage.setItem(UserService.previousLoginExistsAtt, `${isFirstLogin}`);
	};

	public getPreviousLoginExistsAtt = (): boolean => {
		const previousLoginExists: string | null = localStorage.getItem(UserService.previousLoginExistsAtt);
		return previousLoginExists === 'true';
	};

	public getUserDetails(): IUser | null {
		return this.user ?? null;
	}

	public getLSUserData(): ILocalStorageUserData | null {
		const userDataString: string | null = localStorage.getItem(UserService.loggedUserAtt);

		if (!userDataString) {
			return null;
		}

		return JSON.parse(userDataString);
	}

	public removeLoggedUser(): void {
		localStorage.removeItem(UserService.loggedUserAtt);
		localStorage.removeItem(UserService.previousLoginExistsAtt);
		this.user = undefined;
	}

	public getLoggedUserAccessToken(): string | null {
		const loggedUser: ILocalStorageUserData | null = this.getLSUserData();
		return loggedUser?.accessToken ?? null;
	}

	public getLoggedUserRefreshToken(): string | null {
		const loggedUser: ILocalStorageUserData | null = this.getLSUserData();
		return loggedUser?.refreshToken ?? null;
	}

	public getUserRoleType(): RoleType | undefined {
		return this.user?.permissions.roleType;
	}
}

export { UserService };
