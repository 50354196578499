import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import classes from './styles.module.scss';
import { TranslationService } from '../../../services/translation.service';
import { IIssueShared, IssueStatus } from '../../../interfaces/IIssueShared';
import { IProfession } from '../../../interfaces/IProfession';
import { StorageService } from '../../../services/storage.service';
import { IssueDesktopViewSection } from './IssueDesktopViewSection';
import { RequestService } from '../../../services/request.service';

interface IssueDesktopDialogProps {
	show: boolean;
	close: (e: React.MouseEvent) => void;
	handlePriority: () => void;
	priorityIcon: string;
	activityDescription?: string;
	translationService: TranslationService;
	storageService: StorageService;
	issue: IIssueShared<IProfession>;
	tz: string;
	projectId: string;
	updateIssue: (
		issueId: string,
		issueToUpdate: Partial<IIssueShared<IProfession>>,
		updateBackend?: boolean
	) => Promise<IIssueShared<IProfession>>;
	deleteIssue: (e: React.MouseEvent) => void;
	saveComment: (comment: string) => Promise<void>;
	saveImage?: (file: File) => Promise<void>;
	requestService: RequestService;
	openEditFunction: (e) => void;
	handleReviewCallback: () => void;
	handleStatusChange: (updatedStatus: IssueStatus, issueId: string) => Promise<void>;
	isLimitedUser: boolean;
}

export const IssueDesktopDialog = (props: IssueDesktopDialogProps) => {
	const handlePriority = () => {
		props.handlePriority();
	};

	return (
		<Dialog
			open={props.show}
			onClose={props.close}
			classes={{
				paper: `${classes.dialog}`,
			}}
		>
			<IssueDesktopViewSection
				isLimitedUser={props.isLimitedUser}
				openEditFunction={props.openEditFunction}
				requestService={props.requestService}
				deleteIssue={props.deleteIssue}
				close={props.close}
				issue={props.issue}
				translationService={props.translationService}
				tz={props.tz}
				projectId={props.projectId}
				priorityIcon={props.priorityIcon}
				handlePriority={handlePriority}
				storageService={props.storageService}
				updateIssue={props.updateIssue}
				saveComment={props.saveComment}
				saveImage={props.saveImage}
				activityDescription={props.activityDescription}
				handleReviewCallback={props.handleReviewCallback}
				handleStatusChange={props.handleStatusChange}
			/>
		</Dialog>
	);
};
