import { DashboardTabTypes } from '@interfaces/DashboardTabTypes.enum';
import { IDashboardTabItem } from '@interfaces/IDashboardTabItem';
import { projectMonitorTabAccess_BI } from '@utils/bi.utils';
import React from 'react';
import { DashboardTab } from './DashboardTab/DashboardTab';
import classes from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { selectActiveMainPageLayout } from '@store/slices/mainPageLayout.slice';
import { updateMainPageLayout } from '@store/thunks/mainPageLayout.thunks';

interface IDashboardTabsSectionProps {
	dashboardItems: IDashboardTabItem[];
	onTabClick: (selected: DashboardTabTypes) => void;
}

const DashboardTabsSection = (props: IDashboardTabsSectionProps) => {
	const dispatch = useDispatch();
	const currentActiveMainPageLayout: DashboardTabTypes | null = useSelector(selectActiveMainPageLayout);

	const getSelectedTabIndex = (): number => {
		if (currentActiveMainPageLayout) {
			const currentDashboardItem: number = props.dashboardItems.findIndex(
				(item) => item.type === currentActiveMainPageLayout
			);
			if (currentDashboardItem !== -1) {
				return currentDashboardItem;
			}
		}
		updateMainPageLayout(props.dashboardItems[0]?.type)(dispatch);
		return 0;
	};
	const selectedTabIndex: number = getSelectedTabIndex();

	const handleTabClick = (index: number) => {
		props.onTabClick(props.dashboardItems[index].type);
		if (index !== selectedTabIndex) {
			projectMonitorTabAccess_BI(props.dashboardItems[index].type);
		}
	};

	return (
		<div className={classes.dashboardTabsSectionContainer} data-testid={'dashboardTabSection'}>
			{props.dashboardItems.map((item, index) => {
				return (
					<DashboardTab
						key={index}
						isSelected={selectedTabIndex === index}
						selectTab={() => handleTabClick(index)}
						{...item}
					/>
				);
			})}
		</div>
	);
};

export { DashboardTabsSection };
