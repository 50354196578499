import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUser } from '../../interfaces';
import { compact } from 'lodash';
import { HTTPRequestStatuses } from '@shared/interfaces/HTTPRequestStatuses.enum';
import { IUsersReducerState } from '@interfaces/IUsersReducerState';
import { getUsers } from '../thunks/users.thunks';
import { IRootState } from '@store/slices/index';

const initialState: IUsersReducerState = {
	activeUsers: [],
	staticUsers: [],
	selectedUsers: [],
	usersRequestStatus: HTTPRequestStatuses.idle,
};

const usersSlice = createSlice({
	name: 'users',
	initialState,
	reducers: {
		updateActiveUsers: <T extends { username: string }>(
			state: IUsersReducerState,
			action: PayloadAction<{ activeUsers: T[] }>
		) => {
			state.activeUsers = state.staticUsers.filter((user) =>
				action.payload.activeUsers.some((activeUser) => user.username === activeUser.username)
			);
		},
		removeAllUsers: (state: IUsersReducerState) => {
			state = initialState;
		},
		addSelectedUser: (state: IUsersReducerState, action: PayloadAction<{ selectedUser: IUser }>) => {
			const selectedUsersAfterAdd: IUser[] = compact(state.selectedUsers.concat(action.payload.selectedUser));
			state.selectedUsers = selectedUsersAfterAdd;
		},
		removeSelectedUser: (state: IUsersReducerState, action: PayloadAction<{ removedUser: IUser }>) => {
			const selectedUsersAfterRemove: IUser[] = state.selectedUsers.filter((selectedManager) => {
				return action.payload.removedUser._id !== selectedManager._id;
			});

			state.selectedUsers = selectedUsersAfterRemove;
		},
		resetSelectedUsers: (state: IUsersReducerState) => {
			state.selectedUsers = [];
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getUsers.pending, (state: IUsersReducerState) => {
				state.usersRequestStatus = HTTPRequestStatuses.pending;
			})
			.addCase(getUsers.fulfilled, (state: IUsersReducerState, action) => {
				state.usersRequestStatus = HTTPRequestStatuses.success;
				state.staticUsers = action.payload.users;
			})
			.addCase(getUsers.rejected, (state: IUsersReducerState) => {
				state.usersRequestStatus = HTTPRequestStatuses.failed;
			});
	},
});

export const selectSelectedUsers = (state: IRootState): IUser[] => state.usersReducer.selectedUsers;

export const selectStaticUsers = (state: IRootState): IUser[] => state.usersReducer.staticUsers;

export const selectActiveUsers = (state: IRootState): IUser[] => state.usersReducer.activeUsers;

export const { resetSelectedUsers, addSelectedUser, removeAllUsers, removeSelectedUser, updateActiveUsers } =
	usersSlice.actions;

export default usersSlice.reducer;
