import { ITag } from '../interfaces';
import { IMergedTag } from '../interfaces/IMergedTag';

export const getTagName = (tag: IMergedTag | ITag | null): string => {
	if (!tag) {
		return '';
	}
	if (tag['manager']) {
		return tag['manager'].name;
	}
	return tag['name'] || tag.tagNick;
};
