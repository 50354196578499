import React from 'react';
import classes from './styles.module.scss';
import { InvestigationReportTypes } from '@shared/interfaces/InvestigationReportTypes.enum';
import { ITagRouteWithLocationData } from '@shared/interfaces/ITagRouteWithLocationData';
import { ReportTagsLocationInfo } from '../ReportTagsLocationInfo/ReportTagLocationInfo';
import { IInvestigationReport } from '@shared/interfaces/IInvestigationReport';
import { FloorSvg } from '../FloorSvg/FloorSvg';
import { uniq } from 'lodash';

interface IPdfTagRoutesSectionProps {
	tagRoutes: ITagRouteWithLocationData[];
	tagLocationIndexSelected: number;
	locationIndexInvestigated: number;
	pdfMode: boolean;
	currentReport?: IInvestigationReport;
}

const PdfTagRoutesSection = (props: IPdfTagRoutesSectionProps) => {
	return (
		<>
			{props.tagRoutes!.map((tagRoute, index) => {
				if (index !== props.tagLocationIndexSelected) {
					return (
						<>
							<div
								className={classes.locationInfoAndSvgSection}
								style={{ display: props.pdfMode ? 'flex' : 'none' }}
							>
								<div className={classes.tagsLocationInfoSection}>
									<ReportTagsLocationInfo
										locationNumber={index + 1}
										tagLocationData={props.tagRoutes![index]}
										locationNumberOfTheIncident={props.locationIndexInvestigated! + 1}
										isIncidentReport={
											props.currentReport?.investigationType ===
											InvestigationReportTypes.incidentReport
										}
										pdfMode={props.pdfMode}
									/>
								</div>
								<FloorSvg
									floorId={props.tagRoutes![index].floorId}
									selectedAreaId={props.tagRoutes![index].areaId}
									areaIds={uniq(
										props
											.tagRoutes![index].floors.map((floor) =>
												floor.areas.map((area) => area.areaId)
											)
											.flat()
									)}
								/>
							</div>
						</>
					);
				}
			})}
		</>
	);
};

export { PdfTagRoutesSection };
