import React, { useRef } from 'react';
import classNames from 'classnames';
import threeDots from '../../../assets/icons/threeDots.svg';
import { IMenuItem, TrusstorMenu } from '../../TrusstorMenu/TrusstorMenu';
import { TranslationService } from '../../../services/translation.service';
import { useOutsideClick } from '../../../hooks/custom.hooks';
import classes from './styles.module.scss';
import {
	ITrusstorIconButtonSize,
	TrusstorIconButtonDeprecated,
} from '../../buttons/TrusstorIconButton/TrusstorIconButtonDeprecated';
import { IconSize, TrusstorIconShared } from '../../TrusstorIconShared/TrusstorIconShared';
import { RequestService } from '../../../services/request.service';
import { IconNames } from '../../TrusstorIconShared/IconNames.enum';

interface IIssueMenuProps {
	requestService: RequestService;
	translationService: TranslationService;
	deleteFunction: (e) => void;
	openEditFunction?: (e) => void;
}

export const IssueMenu = (props: IIssueMenuProps) => {
	const [showDeleteDialog, setShowDeleteDialog] = React.useState<boolean>(false);
	const isRtl: boolean = props.translationService.getIsRtl();
	const outerRef = useRef(null);
	const ref = useRef(null);

	const handleDelete = (e) => {
		props.deleteFunction(e);
		setShowDeleteDialog(false);
	};

	const editItem: IMenuItem = {
		title: props.translationService.get('edit'),
		onClick: props.openEditFunction!,
		borderBottom: true,
		iconComponent: <TrusstorIconShared iconName={IconNames.edit} size={IconSize.SMALL} />,
	};

	const deleteItem: IMenuItem = {
		title: props.translationService.get('delete'),
		onClick: handleDelete,
		iconComponent: <TrusstorIconShared iconName={IconNames.trash} size={IconSize.SMALL} />,
	};

	const items: IMenuItem[] = [...(props.openEditFunction ? [editItem] : []), deleteItem];

	useOutsideClick(ref, setShowDeleteDialog, false, [outerRef]);
	return (
		<div className={classes.wrapper} ref={outerRef}>
			<div className={classes.image} onClick={() => setShowDeleteDialog((prev) => !prev)}>
				<TrusstorIconButtonDeprecated buttonSize={ITrusstorIconButtonSize.SMALL} iconSrc={threeDots} />
			</div>
			{showDeleteDialog && (
				<div className={classNames(classes.menuWrapper, { [classes.isRtl]: isRtl })} ref={ref}>
					<TrusstorMenu items={items} />
				</div>
			)}
		</div>
	);
};
