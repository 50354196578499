import { IIssueLocation, IIssueShared } from '../../interfaces/IIssueShared';
import { IProfession } from '../../interfaces/IProfession';
import { IUser } from '../../interfaces/IUser';
import { RequestService } from '../../services/request.service';
import moment from 'moment-timezone';
import { ISelectedPointWithAreaId } from '../../interfaces/ISelectedPoint';
import { compact } from 'lodash';
import { IssueCommentType } from '../../interfaces/ICommentShared';

export const isIssueOverdue = (issue: IIssueShared<IProfession>, tz: string): boolean => {
	return !!issue.dueDate && moment.tz(issue.dueDate, tz).isBefore(moment.tz(tz).startOf('day'));
};

export const saveIssueImage = async (
	issueId: string,
	user: IUser,
	requestService: RequestService,
	image: File
): Promise<IIssueShared<IProfession> | undefined> => {
	const sizeLimit: number = 1e7; // 10MB
	if (image.size > sizeLimit) {
		return;
	}
	const formData = new FormData();
	formData.append('value', image);
	formData.append('name', user.name);
	formData.append('username', user.username);
	formData.append('type', image.type.includes('pdf') ? IssueCommentType.PDF : IssueCommentType.IMAGE);
	formData.append('size', image.size.toString());
	formData.append('filename', image.name);

	const issueUpdated: IIssueShared<IProfession> = await requestService.put(`/issues/${issueId}/comments`, {
		body: formData,
	});
	return issueUpdated;
};

export const saveIssueComment = async (
	issueId: string,
	user: IUser,
	requestService: RequestService,
	comment: string
) => {
	const formData = new FormData();
	formData.append('name', user.name);
	formData.append('username', user.username);
	formData.append('value', comment);
	formData.append('type', 'text');

	const issueUpdated: IIssueShared<IProfession> = await requestService.put(`/issues/${issueId}/comments`, {
		body: formData,
	});
	return issueUpdated;
};

export const getSelectedPointWithAreaIdFromIssueLocations = (
	issueLocations: IIssueLocation[]
): ISelectedPointWithAreaId[] =>
	compact(
		issueLocations.flatMap((location: IIssueLocation) =>
			location.area.selectedPoints?.map((point) => ({ ...point, areaId: location.area.areaId }))
		)
	);
