import moment, { Moment, MomentInput } from 'moment-timezone';
import { cloneDeep } from 'lodash';
import { dateFormats, ISO_DATE_FORMAT, SERVER_DATE_FORMAT, TIME_FORMAT_24 } from '../constants/formats.constants';
import { TranslationService } from '../services/translation.service';
import { Languages } from '../constants/languages';
import { IProject, IWorkDayHours } from '../interfaces/IProject';

export const getHoursMinutesFormat = (date, tz) => {
	return moment.tz(date, tz).format('HH:mm');
};

export const isValidTimezone = (tz: string): boolean => {
	return !!moment.tz.zone(tz);
};

export const doesDateMatchFormat = (date: string, format: string): boolean => {
	return moment(date, format).format(format) === date;
};

export const getTimezoneStartOfDate = (tz: string, date?: string): Date => {
	if (!isValidTimezone(tz)) {
		throw new Error('The provided timezone is not valid');
	}

	if (date) {
		if (!doesDateMatchFormat(date, SERVER_DATE_FORMAT)) {
			throw new Error(
				`The provided date does not match backend's format - date: ${date}, server format: ${SERVER_DATE_FORMAT}`
			);
		}

		return moment.tz(date, SERVER_DATE_FORMAT, tz).toDate();
	}

	return moment.tz(tz).startOf('d').toDate();
};

export const getTimezoneStartOfDateByDate = (tz: string, date): Date => {
	const formattedDate: string = getTimezoneFormattedDate(tz, date);
	return getTimezoneStartOfDate(tz, formattedDate);
};

export const getDateInSpecificDayAndWeekRelatively = (dayIndex: number, weekIndex: number, tz: string): Date => {
	const today = moment.tz(tz);
	const desiredDate = today.clone().day(dayIndex).add(weekIndex, 'weeks').toDate();
	return getTimezoneStartOfDateByDate(tz, desiredDate);
};

export const getTimezoneFormattedDate = (tz: string, dateValue?: MomentInput, format?: string): string => {
	if (typeof dateValue === 'string') {
		throw Error("dateValue can't be string");
	}
	if (!isValidTimezone(tz)) {
		throw Error('The provided timezone is not valid');
	}
	return moment.tz(dateValue, tz).format(format || SERVER_DATE_FORMAT);
};

export const getFormattedDate = (dateValue?: MomentInput): string => {
	return moment(dateValue).format(SERVER_DATE_FORMAT);
};

export const getDateStringByLocale = (date: Date, tz: string, locale: string): string => {
	const DATE_FORMAT: string = 'LL';
	return moment.tz(date, tz).locale(locale).format(DATE_FORMAT);
};

export const getWeekPeriodFromDate = (date: Date, tz: string): Date[] => {
	const dateRanges: Date[] = [];
	const startDay: Date = moment.tz(date, tz).toDate();
	for (let i: number = 0; i < 7; i++) {
		dateRanges.push(moment(startDay).add(i, 'days').toDate());
	}
	return dateRanges;
};

export const getTimezoneEndOfDate = (tz: string, date: string): Date => {
	return moment.tz(date, SERVER_DATE_FORMAT, tz).endOf('d').toDate();
};

export const getDaysDiffBetweenDates = (startDate: MomentInput, endDate: MomentInput, tz: string): number => {
	return moment.tz(endDate, tz).diff(startDate, 'days');
};

export const getNumberDaysAgo = (date: Date, tz: string): number => {
	const lastSeenDate = moment.tz(date, tz);
	const todayDate = moment.tz(tz);
	return todayDate.startOf('d').diff(lastSeenDate.startOf('d'), 'days');
};

export const getDayAmountBetweenDates = (startDate: Date, endDate: Date, tz: string): number => {
	return moment.tz(endDate, tz).diff(moment.tz(startDate, tz), 'days') + 1;
};

export const getRelativeHour = (date: Date, tz: string, translationService: TranslationService): string => {
	const daysAgo: number = getNumberDaysAgo(date, tz);
	const chosenLanguage: string = translationService.getChosenLanguage();
	const timeFormat: string = chosenLanguage === Languages.HEBREW ? TIME_FORMAT_24 : 'LT';
	const time = moment.tz(date, tz).format(timeFormat);
	if (daysAgo === 0) {
		return `${translationService.get('todayAt')} ${time}`;
	}
	if (daysAgo === 1) {
		return `${translationService.get('yesterdayAt')} ${time}`;
	}
	const earlyDate: string = moment.tz(date, tz).format(translationService.getDateFormat()).replaceAll('-', '/');
	return `${earlyDate}, ${time}`;
};

export const getRelativeDateFormattedToDisplay = (
	date: Date,
	tz: string,
	translationService: TranslationService
): string => {
	if (isDateYesterday(date, tz) || isDateToday(date, tz)) {
		const chosenLanguage: string = translationService.getChosenLanguage();
		const timeFormat: string = chosenLanguage === Languages.HEBREW ? TIME_FORMAT_24 : 'LT';
		return moment.tz(date, tz).format(timeFormat);
	}

	return getRelativeHour(date, tz, translationService);
};

export const isDateToday = (date: Date, tz: string): boolean => {
	return moment.tz(date, tz).isSame(moment.tz(tz), 'd');
};
export const isDateYesterday = (date: Date, tz: string): boolean => {
	return moment.tz(date, tz).isSame(moment.tz(tz).subtract(1, 'day'), 'd');
};

function isIsoDate(str: string): boolean {
	return moment(str, ISO_DATE_FORMAT, true).isValid();
}

export const convertISODateFieldsToDateObject = (originalObj: any): any => {
	const convertFunction = (obj) => {
		for (const key in obj) {
			if (!obj.hasOwnProperty(key)) {
				continue;
			}

			if (typeof obj[key] === 'object' && obj[key] !== null) {
				convertFunction(obj[key]);
				continue;
			}

			if (typeof obj[key] === 'string' && isIsoDate(obj[key])) {
				obj[key] = new Date(obj[key]);
			}
		}
	};

	const copyObj: any = cloneDeep(originalObj);
	convertFunction(copyObj);
	return copyObj;
};

export const getDateFormattedWithTimeFromTimestamp = (
	timestamp: number,
	shouldShowTime: boolean,
	isHebrew: boolean,
	tz: string,
	locale: string
) => {
	const date = new Date(timestamp);
	const momentDate = moment.tz(date, tz).locale(locale);
	const timeFormat: string = isHebrew ? TIME_FORMAT_24 : 'LT';
	const month = momentDate.format('MMM');
	const day = momentDate.format('Do');
	const year = momentDate.format('YYYY');
	const time = momentDate.format(timeFormat);
	const dateFormatted = isHebrew ? momentDate.format('DD/MM/YYYY') : `${month} ${day}, ${year}`;
	return dateFormatted + (shouldShowTime ? ` - ${time}` : '');
};

export const getHourseMinuteRangeFormat = (
	fromTime: MomentInput,
	toTime: MomentInput,
	tz: string,
	isRtl: boolean = false
): string =>
	isRtl
		? `${moment.tz(toTime, tz).format('HH:mm')}-${moment.tz(fromTime, tz).format('HH:mm')}`
		: `${moment.tz(fromTime, tz).format('HH:mm')}-${moment.tz(toTime, tz).format('HH:mm')}`;

export const getTimezoneDateRanges = (startDate: Date, endDate: Date, tz: string): Date[] => {
	const dates: Date[] = [];

	if (!startDate) {
		throw Error('Start date is undefined');
	}

	if (!endDate) {
		throw Error('End date is undefined');
	}

	const formattedStartDate: string = getTimezoneFormattedDate(tz, startDate);
	const currRunningDate: Moment = moment.tz(getTimezoneStartOfDate(tz, formattedStartDate), tz);

	const formattedEndDate: string = getTimezoneFormattedDate(tz, endDate);
	const lastDate: Date = getTimezoneStartOfDate(tz, formattedEndDate);

	while (currRunningDate.isSameOrBefore(lastDate, 'd')) {
		dates.push(currRunningDate.clone().toDate());
		currRunningDate.add(1, 'd');
	}

	return dates;
};

export const getHoursMinutesDiffFormat = (
	fromDate: MomentInput,
	toDate: MomentInput,
	translationService: TranslationService
): string => {
	const duration = moment.duration(moment(toDate).diff(moment(fromDate)));
	const diffInMinutes = duration.asMinutes();
	const minutesDifference = moment(fromDate).minutes() - moment(toDate).minutes();
	const minutesDifferenceRounded = Math.abs(minutesDifference - 60) % 60;

	if (diffInMinutes < 59) {
		return `${minutesDifferenceRounded} ${translationService.get('mins')}`;
	}
	const diffInHours = moment.duration(moment(toDate).diff(moment(fromDate))).asHours();
	const hours = Math.floor(diffInHours);
	const minutes = minutesDifferenceRounded;
	return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${translationService.get(
		'hours'
	)}`;
};

export const getDateRangeString = (
	startDate: Date | undefined,
	endDate: Date | undefined,
	tz: string,
	locale: string
): string => {
	if (!startDate) {
		return '';
	}

	const startDateLabel: string = moment.tz(startDate, tz).locale(locale).format('MMM DD');
	const endDateLabel: string = !endDate ? '' : moment.tz(endDate, tz).locale(locale).format('MMM DD');
	return !endDateLabel ? startDateLabel : `${startDateLabel} - ${endDateLabel}`;
};

const getFirstWorkDayOfWeek = (workDayHours: IWorkDayHours[]): number => {
	const firstWorkingDayOfWeek: number = workDayHours.findIndex((workDay) => workDay.dailyWorkerHours > 0);
	return firstWorkingDayOfWeek;
};

const getLastWorkDayOfWeek = (workDayHours: IWorkDayHours[]): number => {
	const lastWorkingDayOfWeekIndex: number = workDayHours.findLastIndex((workDay) => workDay.dailyWorkerHours > 0);
	return lastWorkingDayOfWeekIndex;
};

export const getProjectStartOfWeekDate = (project: IProject, dateInWeek: Moment): Date => {
	const firstWorkingDayOfWeek: number = getFirstWorkDayOfWeek(project.workDayHours);
	const date: Moment = moment.tz(dateInWeek, project.tz).day(firstWorkingDayOfWeek);
	return date.toDate();
};

export const getProjectEndOfWeekDate = (project: IProject, dateInWeek: Moment): Date => {
	const lastWorkingDayOfWeek: number = getLastWorkDayOfWeek(project.workDayHours);
	const date: Moment = moment.tz(dateInWeek, project.tz).day(lastWorkingDayOfWeek);
	return date.toDate();
};

export const getWeekStartAndEndDates = (project: IProject, referenceDate: Date) => {
	const referenceWithTZ: Moment = moment.tz(referenceDate, project.tz);
	const startDate: Date = getProjectStartOfWeekDate(project, referenceWithTZ);
	const endDate: Date = getProjectEndOfWeekDate(project, referenceWithTZ);
	return { startDate, endDate };
};

export const getNextWeekProjectStartWorkingDay = (projectWorkDayHours: IWorkDayHours[], tz: string): Date => {
	const workingDay: Date = (() => {
		if (projectWorkDayHours[0].dailyWorkerHours > 0) {
			return moment.tz(tz).day(7).toDate();
		}

		return moment.tz(tz).day(8).toDate();
	})();

	return getTimezoneStartOfDateByDate(tz, workingDay);
};

export const getNextWeekProjectEndWorkingDay = (project: IProject): Date => {
	const endWeekDate: Date = getProjectEndOfWeekDate(project, moment.tz(project.tz));
	const nextWeekEndDate: Date = moment(endWeekDate).add(1, 'w').toDate();
	return getTimezoneEndOfDate(project.tz, getTimezoneFormattedDate(project.tz, nextWeekEndDate));
};

export const getNextWeekStartEndDates = (
	projectWorkDayHours: IWorkDayHours[],
	tz: string,
	numberOfWeeks: number
): { startDate: Date; endDate: Date } => {
	const startDate: Date = getNextWeekProjectStartWorkingDay(projectWorkDayHours, tz);
	const endDate: Date = moment.tz(tz).day(7).add(numberOfWeeks, 'w').subtract(1, 'd').toDate();
	return { startDate, endDate: getTimezoneStartOfDateByDate(tz, endDate) };
};

export const getProjectDateWithBrowserTZOffset = (projectTimezone: string, date?: Date | number | null): Date => {
	const formattedDateInProjectTimezone = date
		? moment.tz(date, projectTimezone).format()
		: moment.tz(projectTimezone).format();
	const formattedDateInBrowserTimezone = date ? moment(date).format() : moment().format();

	const tzOffsetPattern: RegExp = /[+|-]\w{2}[:]\w{2}/;
	const tzOffsetIndex: number = -6;
	return new Date(
		formattedDateInProjectTimezone.replace(tzOffsetPattern, formattedDateInBrowserTimezone.substr(tzOffsetIndex))
	);
};

export const convertDateObjectToServerFormat = (dateObject: Date | number) => {
	return moment(dateObject).format(dateFormats.DMY);
};

export const getDateDisplayFormat = (
	date: Date,
	tz: string,
	translationService: TranslationService,
	mode: 'short' | 'full' | 'monthAndYear' = 'short'
): string => {
	let format: string = translationService.getShortDateDisplayFormat();

	if (mode === 'full') {
		format = translationService.getFullDateDisplayFormat();
	}
	if (mode === 'monthAndYear') {
		format = translationService.getMonthAndYearDisplayFormat();
	}

	return moment.tz(date, tz).locale(translationService.getDateLocale()).format(format);
};
