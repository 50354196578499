import { isMobile as isMobileUserAgent } from 'react-device-detect';
import { ScreenWidthPixelsBreakpoints } from '../constants/breakpoints.constants';

export const isTrusstorMobile = (): boolean => {
	const mobileResolution: ScreenWidthPixelsBreakpoints = ScreenWidthPixelsBreakpoints.mobile;
	const isBrowserInMobileResolution: boolean = window.innerWidth <= mobileResolution;

	return isMobileUserAgent && isBrowserInMobileResolution;
};

export const getIsTouchDevice = (): boolean => {
	return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
};
