import React, { useEffect, useState } from 'react';
import { IActivitiesFloorReport, IActivityReport } from '../../../../../../interfaces/IActivitiesReport';
import { makeStyles } from '@material-ui/core/styles';
import {
	ACTIVITIES_GANTT_COL_HEADERS_WIDTH,
	ACTIVITIES_GANTT_COL_WIDTH,
	ACTIVITIES_GANTT_DASHED_BORDER_WIDTH,
	ACTIVITIES_GANTT_ROW_HEIGHT,
} from '../../../../../../constants';
import { textStyle } from '@shared/utils/text.utils';
import { sortBy, times } from 'lodash';
import moment from 'moment';
import { GanttBar } from './GanttBar';
import { useSelector } from 'react-redux';
import { getTimezoneFormattedDate, getTimezoneStartOfDate } from '../../../../../../utils/dateUtils';
import { IRootState } from '../../../../../../store/slices';

const GANTT_BAR_HEIGHT: number = 30;
const GANTT_CONTAINER_HEIGHT: number = GANTT_BAR_HEIGHT + 30;

interface IStyleProps {
	rowHeight?: number;
}

const useStyles = makeStyles(() => ({
	rowContainer: ({ rowHeight }: IStyleProps) => ({
		display: 'flex',
		flexDirection: 'row',
		width: 'fit-content',
		minHeight: ACTIVITIES_GANTT_ROW_HEIGHT,
		height: rowHeight,
		position: 'sticky',
		top: 0,
		overflow: 'hidden',
		borderBottom: '1px solid #F0F0F0',
		borderTop: '1px solid #F0F0F0',
	}),
	floorName: textStyle({
		fontClass: 'h1',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		overflow: 'hidden',
		width: ACTIVITIES_GANTT_COL_HEADERS_WIDTH,
		textAlign: 'center',
		borderRight: '1px dashed #f2f2f2',
	}),
	emptyCell: {
		minHeight: ACTIVITIES_GANTT_ROW_HEIGHT,
		width: ACTIVITIES_GANTT_COL_WIDTH,
		pointerEvents: 'none',
		borderRight: '1px dashed #f2f2f2',
	},
}));

interface IFloorGantRowProps {
	ganttStartDate: Date;
	ganttEndDate: Date;
	numberOfDays: number;
	floorActivitiesReport: IActivitiesFloorReport;
}

const FloorGanttRow = (props: IFloorGantRowProps) => {
	const [rowHeight, setRowHeight] = useState<number>();
	const tz: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.tz);

	const classes = useStyles({
		rowHeight: rowHeight ?? 0,
	});

	useEffect(() => {
		setRowHeight(props.floorActivitiesReport.activities.length * GANTT_CONTAINER_HEIGHT);
	}, [props.floorActivitiesReport.activities.length]);

	const getVisibleActivityBarDays = (
		activityDaysLength: number,
		daysPassedFromReportStartDate: number,
		daysLeftToReportEndDate: number
	) => {
		let visibleActivityBarDays = activityDaysLength;

		if (daysPassedFromReportStartDate < 0) {
			visibleActivityBarDays += daysPassedFromReportStartDate;
		}
		if (daysLeftToReportEndDate < 0) {
			visibleActivityBarDays += daysLeftToReportEndDate;
		}

		return visibleActivityBarDays;
	};

	return (
		<div className={classes.rowContainer}>
			<p className={`${classes.floorName}`}>{props.floorActivitiesReport.floorNick}</p>
			{times(props.numberOfDays, (index: number) => (
				<div
					key={`gantRowEmptyCell_${props.floorActivitiesReport.floorId}_${index}`}
					className={classes.emptyCell}
				/>
			))}
			{sortBy(
				props.floorActivitiesReport.activities,
				(activityReport: IActivityReport) => activityReport.startDate
			).map((activityReport: IActivityReport, index: number) => {
				const activityDaysLength =
					moment.tz(activityReport.endDate, tz).diff(moment.tz(activityReport.startDate, tz), 'days') + 1;
				const numberDaysPassedFromReportStartDate: number = moment
					.tz(activityReport.startDate, tz)
					.diff(getTimezoneStartOfDate(tz, getTimezoneFormattedDate(tz, props.ganttStartDate)), 'days');
				const numberDaysLeftToReportEndDate: number = moment
					.tz(props.ganttEndDate, tz)
					.diff(activityReport.endDate, 'days');
				const barWidth =
					getVisibleActivityBarDays(
						activityDaysLength,
						numberDaysPassedFromReportStartDate,
						numberDaysLeftToReportEndDate
					) * ACTIVITIES_GANTT_COL_WIDTH;
				const marginFromDatesStartLine: number =
					numberDaysPassedFromReportStartDate < 0
						? 0
						: numberDaysPassedFromReportStartDate * ACTIVITIES_GANTT_COL_WIDTH;
				const marginFromStartOfTheTable: number =
					marginFromDatesStartLine +
					ACTIVITIES_GANTT_COL_HEADERS_WIDTH +
					ACTIVITIES_GANTT_DASHED_BORDER_WIDTH;

				return (
					<GanttBar
						key={`floorGantt_${activityReport._id}_${props.floorActivitiesReport.floorId}`}
						marginFromTop={index * GANTT_CONTAINER_HEIGHT}
						marginFromSide={marginFromStartOfTheTable}
						title={activityReport.description}
						barWidth={barWidth}
						barHeight={GANTT_BAR_HEIGHT}
						ganttContainerHeight={GANTT_CONTAINER_HEIGHT}
						shouldRoundBarEnd={numberDaysLeftToReportEndDate >= 0}
						dailyAccumulatedWorkTime={activityReport.dailyAccumulatedWorkTime}
					/>
				);
			})}
		</div>
	);
};

export { FloorGanttRow };
