import React from 'react';
import { find } from 'lodash';
import { HoistIcon } from '../Hoists/HoistIcon';
import { makeStyles } from '@material-ui/core';
import { compareProps } from '../../utils/generalUtils';
import { IFloorData } from '../../interfaces/IFloorData';
import { IBuildingSvgPoints } from '../../interfaces/IBuildingSvgPoints';
import { ISvgElementPoint } from '../../interfaces/ISvgElementPoint';

const useStyles = makeStyles({
	elevatorWrapper: {
		position: 'absolute',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
});

interface IFloorElevatorsProps {
	elevators: IFloorData[];
	svgPoints: IBuildingSvgPoints;
}

const FloorElevators = React.memo((props: IFloorElevatorsProps) => {
	const classes = useStyles();

	const elevatorPointForFloor: ISvgElementPoint | undefined = find(props.svgPoints.elevatorPointsArray, {
		floorId: props.elevators?.[0]?.matchingFixedFloorId,
	});

	if (!elevatorPointForFloor) {
		return null;
	}

	return (
		<div
			key={`floor${props.elevators[0].floorId}_elevatorsContainer`}
			className={classes.elevatorWrapper}
			style={{
				top: elevatorPointForFloor.top,
				left: elevatorPointForFloor.left,
				right: elevatorPointForFloor.right,
			}}
		>
			<HoistIcon hoists={props.elevators} />
		</div>
	);
}, compareProps);

export { FloorElevators };
