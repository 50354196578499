import { INotificationMetadata } from '@shared/interfaces/INotificationMetadata';
import {
	accessLevelService,
	projectAccessLevelService,
	requestService,
	storageService,
	translationService,
} from '../index';
import {
	getPlanningCreatedIconElement,
	getPlanningCreatedTitleElement,
} from '@shared/components/NotificationCard/PlanningCreatedCard/PlanningCreatedCard';
import { IPlanningCreatedNotificationMetadata } from '@shared/interfaces/IPlanningCreatedNotificationMetadata';
import {
	getTagHealthDescriptionElement,
	getTagHealthIconElement,
	getTagHealthTitleElement,
} from '@shared/components/NotificationCard/TagHealthCard/TagHealthCard';
import { ITagHealthNotificationMetadata } from '@shared/interfaces/ITagHealthNotificationMetadata';
import {
	getDistressButtonDescriptionElement,
	getDistressButtonTitleElement,
	getShieldIconElement,
} from '@shared/components/NotificationCard/DistressButtonCard/DistressButtonCard';
import { IDistressButtonNotificationMetadata } from '@shared/interfaces/IDistressButtonNotificationMetadata';
import {
	getDailyReportIconElement,
	getDailyReportTitleElement,
} from '@shared/components/NotificationCard/DailyReportCard/DailyReportCard';
import { NotificationTypes, RestrictedAreaType } from '@shared/interfaces/NotificationTypes.enum';
import { notificationsConfiguration, PreDefinedFrequencies } from '@shared/constants/notifications.constants';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { INotificationCardSection } from '@shared/interfaces/NotificationSettings/INotificationCardSection';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
	getRestrictedAreaDescriptionElement,
	getRestrictedAreaTitleElement,
} from '@shared/components/NotificationCard/RestrictedAreaNotificationCard/RestrictedAreaNotificationCard';
import { INotification } from '@shared/interfaces/INotification';
import {
	getCreatedIssuesIconElement,
	getCreatedIssuesTitleElement,
} from '@shared/components/NotificationCard/CreatedIssuesCard/CreatedIssuesCard';
import { ICreatedIssuesNotificationMetadata } from '@shared/interfaces/ICreatedIssuesNotificationMetadata';
import {
	getIssueCommentCreatedTitleElement,
	getIssuesCommentCreatedDescriptionElement,
	getIssuesCommentCreatedIconElement,
} from '@shared/components/NotificationCard/IssueCommentCreatedCard/IssueCommentCreatedCard';
import { IIssueCommentCreatedNotificationMetadata } from '@shared/interfaces/IIssueCommentCreatedNotificationMetadata';
import { getNotificationRouteByType } from '@shared/utils/notifications.utils';
import {
	getButton,
	getProgressAnalysisDescriptionElement,
	getProgressAnalysisIconElement,
	getProgressAnalysisTitleElement,
} from '@shared/components/NotificationCard/WeeklyProgressAnalysisReportCard/WeeklyProgressAnalysisReportCard';
import {
	getIssueAssignedCardTitleElement,
	getIssuesIssueAssignedCardDescriptionElement,
	getIssuesIssueAssignedCardIconElement,
} from '@shared/components/NotificationCard/IssueAssignedCard/IssueAssignedCard';
import { IIssueAssigneeUpdatedNotificationMetadata } from '@shared/interfaces/IIssueAssigneeUpdatedNotificationMetadata';
import {
	getIssueStatusUpdatedDescriptionElement,
	getIssueStatusUpdatedIconElement,
	getIssueStatusUpdateTitleElement,
} from '@shared/components/NotificationCard/IssueStatusUpdatedCard.tsx/IssueStatusUpdatedCard';
import { IIssueStatusUpdatedNotificationMetadata } from '@shared/interfaces/IIssueStatusUpdatedNotificationMetadata';
import {
	getAreaSequenceItemStatusUpdateCardDescriptionElement,
	getAreaSequenceItemStatusUpdateCardIconElement,
	getAreaSequenceItemStatusUpdateCardTitleElement,
} from '@shared/components/NotificationCard/AreaSequenceItemStatusUpdateCard/AreaSequenceItemStatusUpdateCard';
import { IAreaSequenceItemStatusUpdateNotificationMetadata } from '@shared/interfaces/IAreaSequenceItemStatusUpdateNotificationMetadata';

export const getSnackbarNotificationCardsElements = (
	metaData: INotificationMetadata,
	type: NotificationTypes,
	notification: INotification,
	tz: string,
	history?: RouteComponentProps['history'],
	isSequenceProgressAnalysisEnabled?: boolean
): {
	titleElement: React.ReactNode;
	iconElement: React.ReactNode;
	descriptionElement?: React.ReactNode;
	buttonElement?: React.ReactNode;
	onClick?: () => void;
} | null => {
	if (type === NotificationTypes.SequenceProgressAnalysisReport && isSequenceProgressAnalysisEnabled) {
		return {
			titleElement: getProgressAnalysisTitleElement(translationService),
			descriptionElement: getProgressAnalysisDescriptionElement(translationService, true),
			buttonElement: getButton(translationService),
			iconElement: getProgressAnalysisIconElement(true),
			onClick: () => history?.push(getNotificationRouteByType(notification.type)),
		};
	}
	if (type === NotificationTypes.IssueCommentCreated) {
		return {
			titleElement: getIssueCommentCreatedTitleElement(
				metaData as IIssueCommentCreatedNotificationMetadata,
				translationService,
				true
			),
			iconElement: getIssuesCommentCreatedIconElement(metaData as IIssueCommentCreatedNotificationMetadata),
			descriptionElement: getIssuesCommentCreatedDescriptionElement(
				metaData as IIssueCommentCreatedNotificationMetadata,
				storageService,
				true
			),
			onClick: () =>
				history?.push(
					getNotificationRouteByType(
						notification.type,
						(metaData as IIssueCommentCreatedNotificationMetadata).issueDetails.issueId
					)
				),
		};
	}
	if (type === NotificationTypes.AssignedIssue) {
		return {
			titleElement: getIssueAssignedCardTitleElement(
				metaData as IIssueAssigneeUpdatedNotificationMetadata,
				translationService,
				true
			),
			iconElement: getIssuesIssueAssignedCardIconElement(),
			descriptionElement: getIssuesIssueAssignedCardDescriptionElement(
				metaData as IIssueAssigneeUpdatedNotificationMetadata,
				true
			),
			onClick: () =>
				history?.push(
					getNotificationRouteByType(
						notification.type,
						(metaData as IIssueAssigneeUpdatedNotificationMetadata).issueDetails.issueId
					)
				),
		};
	}
	if (type === NotificationTypes.IssueStatusUpdated) {
		return {
			titleElement: getIssueStatusUpdateTitleElement(
				metaData as IIssueStatusUpdatedNotificationMetadata,
				translationService,
				true
			),
			iconElement: getIssueStatusUpdatedIconElement(),
			descriptionElement: getIssueStatusUpdatedDescriptionElement(
				metaData as IIssueStatusUpdatedNotificationMetadata
			),
			onClick: () =>
				history?.push(
					getNotificationRouteByType(
						notification.type,
						(metaData as IIssueStatusUpdatedNotificationMetadata).issueId
					)
				),
		};
	}
	if (type === NotificationTypes.AreaSequenceItemStatusUpdate) {
		return {
			titleElement: getAreaSequenceItemStatusUpdateCardTitleElement(
				metaData as IAreaSequenceItemStatusUpdateNotificationMetadata,
				translationService,
				true
			),
			iconElement: getAreaSequenceItemStatusUpdateCardIconElement(requestService),
			descriptionElement: getAreaSequenceItemStatusUpdateCardDescriptionElement(
				translationService,
				metaData as IAreaSequenceItemStatusUpdateNotificationMetadata,
				true
			),
			onClick: () => history?.push(getNotificationRouteByType(notification.type)),
		};
	}
	if (type === NotificationTypes.CreatedIssues) {
		return {
			titleElement: getCreatedIssuesTitleElement(
				metaData as ICreatedIssuesNotificationMetadata,
				translationService,
				true
			),
			iconElement: getCreatedIssuesIconElement(requestService),
		};
	}
	if (type === NotificationTypes.PlanningCreated) {
		return {
			titleElement: getPlanningCreatedTitleElement(
				metaData as IPlanningCreatedNotificationMetadata,
				translationService,
				true
			),
			iconElement: getPlanningCreatedIconElement(),
		};
	}
	if (type === NotificationTypes.TagHealth) {
		return {
			titleElement: getTagHealthTitleElement(
				metaData as ITagHealthNotificationMetadata,
				translationService,
				true
			),
			iconElement: getTagHealthIconElement(),
			descriptionElement: getTagHealthDescriptionElement(
				metaData as ITagHealthNotificationMetadata,
				translationService,
				true
			),
		};
	}
	if (type === NotificationTypes.DistressButton) {
		return {
			titleElement: getDistressButtonTitleElement(translationService, true),
			iconElement: getShieldIconElement(),
			descriptionElement: getDistressButtonDescriptionElement(
				metaData as IDistressButtonNotificationMetadata,
				translationService,
				true
			),
		};
	}
	if (type === NotificationTypes.RestrictedArea) {
		return {
			titleElement: getRestrictedAreaTitleElement(translationService, true),
			iconElement: getShieldIconElement(),
			descriptionElement: getRestrictedAreaDescriptionElement(notification, translationService, tz, true),
		};
	}
	if (type === NotificationTypes.DailyReport) {
		return {
			titleElement: getDailyReportTitleElement(translationService, true),
			iconElement: getDailyReportIconElement(),
		};
	}
	return null;
};

export const getRestrictedAreaCard = (type: RestrictedAreaType) => ({
	type,
	channels: notificationsConfiguration.RestrictedArea.channels,
});

export const NotificationCardSection = {
	safetyAlerts: 'safetyAlerts',
	reports: 'reports',
	activities: 'activities',
	issues: 'issues',
};

export const getDesktopNotificationsCards = (): INotificationCardSection[] => [
	...(projectAccessLevelService.hasAccess('notifications-safety') &&
	accessLevelService.hasAccess('notifications-safety')
		? [
				{
					name: NotificationCardSection.safetyAlerts,
					icon: IconNames.shield,
					cards: [
						{
							type: NotificationTypes.DistressButton,
							description: 'notificationDistressButtonDescription',
							channels: notificationsConfiguration.DistressButton.channels,
						},
						{
							type: NotificationTypes.RestrictedArea,
							description: 'restrictedAreaDescription',
							channels: notificationsConfiguration.RestrictedArea.channels,
						},
					],
				},
		  ]
		: []),
	...(projectAccessLevelService.hasAccess('notifications-reports')
		? [
				{
					name: NotificationCardSection.reports,
					icon: IconNames.report,
					cards: [
						{
							type: NotificationTypes.DailyReport,
							description: 'notificationDailyReportDescription',
							channels: notificationsConfiguration.DailyReport.channels,
						},
						{
							type: NotificationTypes.TagHealth,
							description: 'notificationTagHealthDescription',
							channels: notificationsConfiguration.TagHealth.channels,
							frequency: [PreDefinedFrequencies.DAILY, PreDefinedFrequencies.WEEKLY],
						},
					],
				},
		  ]
		: []),
	...(projectAccessLevelService.hasAccess('notifications-activities')
		? [
				{
					name: NotificationCardSection.activities,
					icon: IconNames.activity,
					cards: [
						{
							type: NotificationTypes.PlanningCreated,
							description: 'notificationPlanningCreatedDescription',
							channels: notificationsConfiguration.PlanningCreated.channels,
						},
						{
							type: NotificationTypes.AreaSequenceItemStatusUpdate,
							description: 'settings_notification_AreaSequenceItemStatusUpdate_description',
							channels: notificationsConfiguration.AreaSequenceItemStatusUpdate.channels,
						},
					],
				},
		  ]
		: []),
	...(projectAccessLevelService.hasAccess('notifications-issues')
		? [
				{
					name: NotificationCardSection.issues,
					icon: IconNames.issues,
					cards: [
						{
							type: NotificationTypes.CreatedIssues,
							description: 'notificationCreatedIssuesDescription',
							channels: notificationsConfiguration.CreatedIssues.channels,
							frequency: [PreDefinedFrequencies.DAILY, PreDefinedFrequencies.WEEKLY],
						},
						{
							type: NotificationTypes.IssueCommentCreated,
							description: 'notificationIssueCommentedAddedDescription',
							channels: notificationsConfiguration.IssueCommentCreated.channels,
						},
						{
							type: NotificationTypes.AssignedIssue,
							description: 'notificationIssueAssignedDescription',
							channels: notificationsConfiguration.AssignedIssue.channels,
						},
						{
							type: NotificationTypes.IssueStatusUpdated,
							description: 'notificationIssueStatusUpdatedDescription',
							channels: notificationsConfiguration.IssueStatusUpdated.channels,
						},
					],
				},
		  ]
		: []),
];
