import React, { useEffect, useState } from 'react';
import { storageService } from '../../../../index';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../../store/slices';
import { svgSelectedStyle } from '../../../../constants/genericStyles.constants';
import classes from './styles.module.scss';
import { setElementStyle } from '@shared/utils/svg.utils';

interface IMarkedFloorSvgProps {
	floorId: string;
	areaIds: string[];
	selectedAreaId: string;
}

const FloorSvg = (props: IMarkedFloorSvgProps) => {
	const [floorSvg, setFloorSvg] = useState<string>('');
	const workingProjectId: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.projectId);
	const areasLayoutId: string = 'areasLayout';

	useEffect(() => {
		const loadAssets = async () => {
			try {
				const svgFile: string = await storageService.getFloorSvg(workingProjectId, props.floorId);
				const editedSvgFile: string = svgFile.replace(
					'<svg',
					`<svg id="${areasLayoutId}" class="${classes.fullSize}" `
				);
				setFloorSvg(editedSvgFile);
			} catch (err) {
				setFloorSvg('');
			}
		};

		if (workingProjectId) {
			loadAssets();
		}
	}, [workingProjectId, props.floorId]);

	useEffect(() => {
		renderSelectedArea();
	}, [floorSvg]);

	const renderSelectedArea = (): void => {
		props.areaIds.forEach((areaId) => {
			const withFloorId: string = `f${props.floorId}area${areaId}`;
			const withoutFloorId: string = `area${areaId}`;
			setElementStyle(
				document.getElementById(withFloorId) ? withFloorId : withoutFloorId,
				areaId === props.selectedAreaId
					? svgSelectedStyle
					: {
							opacity: '',
							fillOpacity: '',
							fill: '',
							stroke: '',
							strokeWidth: '',
					  }
			);
		});
	};

	return (
		<div className={classes.svgParentContainer}>
			<div className={classes.fullSize} dangerouslySetInnerHTML={{ __html: floorSvg }} />
		</div>
	);
};

export { FloorSvg };
