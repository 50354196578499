import { ITrade } from '@interfaces/ITrade';
import { requestService } from '@src/servicesInitializers';
import { useQuery } from '@tanstack/react-query';

export const getAllTradesQuery = (): {
	allTrades: ITrade[] | undefined;
	isLoading: boolean;
} => {
	const { data: allTrades, isLoading } = useQuery<ITrade[]>(['trades'], () => requestService.get(`/entities/trades`));
	return { allTrades, isLoading };
};
