import React from 'react';
import { IProject } from '@shared/interfaces/IProject';
import { ProjectInfoTopSection } from './ProjectInfoTopSection/ProjectInfoTopSection';
import { ProjectManagerSection } from './ProjectManagerSection/ProjectManagerSection';

interface IProjectInfoSectionProps {
	project: IProject;
}

function ProjectInfoSection(props: IProjectInfoSectionProps) {
	return (
		<div>
			<ProjectInfoTopSection project={props.project} />
			<ProjectManagerSection responsibleManager={props.project.responsibleManager} />
		</div>
	);
}

export { ProjectInfoSection };
