import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import classes from './styles.module.scss';
import { IMainPageWidgetActivityData } from '@interfaces/IMainPageWidgetActivityData';
import { debounce, Dictionary, groupBy } from 'lodash';
import { ProfessionActivitiesChip } from '@src/Components/Chips/ProfessionActivitiesChip/ProfessionActivitiesChip';
import { getProfessionBackgroundColor } from '@shared/utils/professions.utils';
import { IProfession } from '@interfaces/index';
import { useSelector } from 'react-redux';
import { selectProjectId, selectTimezone } from '@store/slices/project.slice';
import {
	getActivitiesProgressPercentage,
	isActivityGroupOverdue,
	isOneOfActivitiesOverdue,
} from '@utils/activities.utils';
import { ProfessionActivitiesChipTooltip } from '@src/Components/Chips/ProfessionActivitiesChip/ProfessionActivitiesChipTooltip/ProfessionActivitiesChipTooltip';
import { ShowMoreActivitiesChip } from '@src/Components/Chips/ShowMoreActivitiesChip/ShowMoreActivitiesChip';
import { keepKeysInObject } from '@utils/generalUtils';
import { FLOOR_ACTIVITY_CHIP_HEIGHT, FLOOR_ACTIVITY_CHIP_HEIGHT_SMALL } from '@src/constants';
import { MainPageContext } from '@src/Components/Pages/MainPage';
import { getWidgetActivitiesFiltered } from '@shared/utils/mainPageActivities.utils';

const ACTIVITIES_CHIP_GAP: number = 8;

interface IActivitiesFloorEntitiesProps {
	floorWidgetActivities: IMainPageWidgetActivityData[];
	floorId: string;
	chipsGap?: number;
}

export const ActivitiesFloorEntities = React.memo((props: IActivitiesFloorEntitiesProps) => {
	const { widgetActivityFilters } = useContext(MainPageContext)!;

	const projectId: string = useSelector(selectProjectId)!;
	const tz: string = useSelector(selectTimezone)!;
	const containerRef = useRef<HTMLDivElement>(null);
	const [overlappedIndex, setOverlappedIndex] = useState<number | undefined>();
	const [chipHeight, setChipHeight] = useState<number>(FLOOR_ACTIVITY_CHIP_HEIGHT);
	const chipsGap: number = props.chipsGap || ACTIVITIES_CHIP_GAP;

	const filteredFloorWidgetActivities: IMainPageWidgetActivityData[] = getWidgetActivitiesFiltered(
		props.floorWidgetActivities,
		widgetActivityFilters,
		tz
	);

	const sortedFloorWidgetActivities: IMainPageWidgetActivityData[] = filteredFloorWidgetActivities.sort(
		(activity1: IMainPageWidgetActivityData, activity2: IMainPageWidgetActivityData) => {
			if (isActivityGroupOverdue(activity1, tz) && !isActivityGroupOverdue(activity2, tz)) {
				return -1;
			}
			if (!isActivityGroupOverdue(activity1, tz) && isActivityGroupOverdue(activity2, tz)) {
				return 1;
			}
			return activity1.profession._id.localeCompare(activity2.profession._id);
		}
	);

	const activitiesGroupedByProfession: Dictionary<IMainPageWidgetActivityData[]> = groupBy(
		sortedFloorWidgetActivities,
		(activity: IMainPageWidgetActivityData) => activity.profession._id
	);

	const updateChipsOverlappingIfNeeded = () => {
		const activitiesContainer: HTMLElement | null = containerRef.current;
		if (!activitiesContainer || !activitiesContainer.parentElement) {
			return;
		}
		if (activitiesContainer.parentElement.offsetHeight < 24) {
			setChipHeight(FLOOR_ACTIVITY_CHIP_HEIGHT_SMALL);
		}
		const activitiesContainerWidthIsGreaterThanContainerWidth: boolean =
			activitiesContainer.parentElement.offsetWidth < activitiesContainer.offsetWidth;
		if (activitiesContainerWidthIsGreaterThanContainerWidth && !overlappedIndex) {
			const moreChipsWidth: number = 22;
			const chipsElements: NodeListOf<ChildNode> = activitiesContainer.childNodes;
			let overlappedIndex: number = 0;
			let totalWidth: number = 0;
			const containerWidth: number = activitiesContainer.parentElement.offsetWidth - moreChipsWidth - chipsGap;
			for (let i = 0; i < chipsElements.length; i++) {
				const chipElement: HTMLElement = chipsElements[i] as HTMLElement;
				totalWidth += chipElement.offsetWidth + chipsGap;
				if (totalWidth > containerWidth) {
					overlappedIndex = i;
					break;
				}
			}
			setOverlappedIndex(overlappedIndex);
		}
	};

	const getProfessionActivitiesChipTooltip = (professionActivities: Dictionary<IMainPageWidgetActivityData[]>) => {
		return <ProfessionActivitiesChipTooltip floorId={props.floorId} activitiesData={professionActivities} />;
	};

	useEffect(() => {
		updateChipsOverlappingIfNeeded();
	}, [props.floorWidgetActivities]);

	const debouneResizeEffect = debounce(() => {
		setOverlappedIndex(undefined);
		updateChipsOverlappingIfNeeded();
	}, 400);

	useLayoutEffect(() => {
		window.addEventListener('resize', () => {
			debouneResizeEffect();
		});

		return () => {
			window.removeEventListener('resize', () => {
				updateChipsOverlappingIfNeeded();
			});
		};
	}, []);

	if (!filteredFloorWidgetActivities.length) {
		return null;
	}

	const fullModeChipsIds: string[] =
		overlappedIndex !== undefined
			? Object.keys(activitiesGroupedByProfession).slice(0, overlappedIndex)
			: Object.keys(activitiesGroupedByProfession);
	const minimizedModeChipsIds: string[] =
		overlappedIndex !== undefined ? Object.keys(activitiesGroupedByProfession).slice(overlappedIndex) : [];
	const minimizedModeChipsData: Dictionary<IMainPageWidgetActivityData[]> = keepKeysInObject(
		activitiesGroupedByProfession,
		minimizedModeChipsIds
	) as Dictionary<IMainPageWidgetActivityData[]>;
	return (
		<div
			className={classes.activitiesFloorEntitiesContainer}
			ref={containerRef}
			style={{ gap: `4px ${chipsGap}px` }}
		>
			{fullModeChipsIds.map((professionId: string) => {
				const professionActivities: IMainPageWidgetActivityData[] = activitiesGroupedByProfession[professionId];
				const profession: IProfession = professionActivities[0].profession;
				const progressPercentage: number = getActivitiesProgressPercentage(professionActivities, props.floorId);
				return (
					<ProfessionActivitiesChip
						key={`floor_${props.floorId}_profession_${professionId}`}
						backgroundColor={getProfessionBackgroundColor(profession, projectId)}
						numberOfActivities={activitiesGroupedByProfession[professionId].length}
						progressPercentage={progressPercentage}
						isOverdue={isOneOfActivitiesOverdue(professionActivities, tz)}
						getToolTipComponent={() =>
							getProfessionActivitiesChipTooltip({ professionId: professionActivities })
						}
						height={chipHeight}
					/>
				);
			})}
			{minimizedModeChipsIds.length > 0 &&
				(minimizedModeChipsIds.length > 1 ? (
					<ShowMoreActivitiesChip
						activitiesGroupedByProfession={minimizedModeChipsData}
						floorId={props.floorId}
						height={`${chipHeight}px`}
					/>
				) : (
					<ProfessionActivitiesChip
						key={`floor_${props.floorId}_profession_${minimizedModeChipsIds[0]}`}
						backgroundColor={getProfessionBackgroundColor(
							minimizedModeChipsData[minimizedModeChipsIds[0]][0].profession,
							projectId
						)}
						numberOfActivities={activitiesGroupedByProfession[minimizedModeChipsIds[0]].length}
						getToolTipComponent={() => getProfessionActivitiesChipTooltip(minimizedModeChipsData)}
						isMinimized={true}
						height={chipHeight}
					/>
				))}
		</div>
	);
});
