import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { translationService } from '@src/servicesInitializers';
import { ExpandableComponent } from '@src/Components/MainPageSections/ExpandableComponent/ExpandableComponent';
import { APP_FILTER_TYPES } from '@src/constants';
import {
	addSelectedUser,
	removeSelectedUser,
	resetSelectedUsers,
	selectActiveUsers,
	selectSelectedUsers,
} from '@store/slices/users.slice';
import { IUser } from '@shared/interfaces/IUser';
import { AssigneeFilterCard } from '@shared/components/Filters/AssigneeFilterCard/AssigneeFilterCard';

interface IUsersFilterSectionProps {
	usersCountObj?: { [username: string]: number };
	defaultOpen?: boolean;
}

export const UsersFilterSection = (props: IUsersFilterSectionProps) => {
	const dispatch = useDispatch();

	const users: IUser[] = useSelector(selectActiveUsers);
	const selectedUsers: IUser[] = useSelector(selectSelectedUsers);

	const checkShouldHideClearButton = (): boolean => {
		return selectedUsers.length === 0;
	};

	const handleResetUsers = (): void => {
		dispatch(resetSelectedUsers());
	};

	const addUser = (selectedUser: IUser): void => {
		dispatch(addSelectedUser({ selectedUser }));
	};

	const removeUser = (removedUser: IUser): void => {
		dispatch(removeSelectedUser({ removedUser }));
	};

	useEffect(() => {
		dispatch(resetSelectedUsers());
	}, []);

	return (
		<ExpandableComponent
			headerText={translationService.get('assignees')}
			childrenLength={users.length}
			clearFunction={handleResetUsers}
			type={APP_FILTER_TYPES.users}
			shouldHideClearButton={checkShouldHideClearButton()}
			clearLength={selectedUsers.length}
			hideEmptyList
			testId={'usersFilterSection'}
			defaultOpen={props.defaultOpen}
		>
			{users.map((user: IUser) => {
				const isSelected = selectedUsers.some((selectedUser) => selectedUser.username === user.username);
				return (
					<AssigneeFilterCard
						key={user.username}
						handleClick={isSelected ? () => removeUser(user) : () => addUser(user)}
						name={user.name}
						isSelected={isSelected}
						count={props.usersCountObj?.[user.username]}
					/>
				);
			})}
		</ExpandableComponent>
	);
};
