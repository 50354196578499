import React from 'react';
import classes from './styles.module.scss';

interface IEfficiencyTabProps {
	icon: string;
	title: string;
	description: string;
	children: JSX.Element;
}

const EfficiencyTab = (props: IEfficiencyTabProps) => {
	return (
		<section className={classes.tabContainer}>
			<div className={classes.descriptionSide}>
				<div className={classes.titleSection}>
					<img src={props.icon} alt="" />
					{props.title}
				</div>
				<div className={classes.descriptionText} dangerouslySetInnerHTML={{ __html: props.description }}></div>
			</div>
			<div className={classes.graphSide}>{props.children}</div>
		</section>
	);
};

export { EfficiencyTab };
