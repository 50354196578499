import React, { useRef } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { textStyle } from '@shared/utils/text.utils';
import { TrusstorTooltip } from '@shared/components/TrusstorTooltip/TrusstorTooltip';
import { getRoundedHoursFromMsByStep } from '@shared/utils/roundNumbers.utils';
import { COLORS } from '../../../../../../constants';

interface IStyleProps {
	barWidth?: number;
	barHeight?: number;
	marginFromTop?: number;
	marginFromSide?: number;
	ganttContainerHeight?: number;
	shouldRoundBarEnd?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
	ganttContainer: ({ marginFromTop, marginFromSide, ganttContainerHeight, barWidth }: IStyleProps) => ({
		position: 'absolute',
		top: marginFromTop,
		left: marginFromSide,
		height: ganttContainerHeight,
		width: barWidth,
	}),
	activityDayWorkHoursText: {
		width: 60,
		textAlign: 'center',
	},
	bar: ({ shouldRoundBarEnd, barHeight }: IStyleProps) => ({
		border: '1px solid black',
		width: '100%',
		height: barHeight,
		borderRadius: shouldRoundBarEnd ? '0 15px 15px 0' : '0 0 0 0',
		display: 'flex',
		alignItems: 'center',
	}),
	title: () =>
		textStyle({
			fontClass: 'h2',
			fontWeight: 550,
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
			overflow: 'hidden',
			color: COLORS.white,
		}),
}));

interface IGanttBarProps {
	title: string;
	barWidth: number;
	barHeight: number;
	marginFromTop: number;
	marginFromSide: number;
	ganttContainerHeight: number;
	shouldRoundBarEnd: boolean;
	dailyAccumulatedWorkTime: number[] | undefined;
}

const GanttBar = (props: IGanttBarProps) => {
	const classes = useStyles({
		barWidth: props.barWidth,
		barHeight: props.barHeight,
		marginFromTop: props.marginFromTop,
		marginFromSide: props.marginFromSide,
		ganttContainerHeight: props.ganttContainerHeight,
		shouldRoundBarEnd: props.shouldRoundBarEnd,
	});
	const titleEl = useRef<HTMLDivElement>(null);
	return (
		<div className={classes.ganttContainer}>
			<TrusstorTooltip
				tooltipComponent={<div className={classes.title}>{props.title}</div>}
				cleanStyling
				refChildren={titleEl}
			>
				<div ref={titleEl} className={classes.title}>
					{props.title}
				</div>
			</TrusstorTooltip>
			<div className={classes.bar}>
				{props.dailyAccumulatedWorkTime &&
					props.dailyAccumulatedWorkTime.map((accumulatedWorkTime) => (
						<p className={classes.activityDayWorkHoursText}>
							{getRoundedHoursFromMsByStep(accumulatedWorkTime, 1)}h
						</p>
					))}
			</div>
		</div>
	);
};

export { GanttBar };
