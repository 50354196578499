import moment, { Moment, MomentInput } from 'moment-timezone';
import { translationService } from '../index';
import { sumBy } from 'lodash';
import { convertMsToRoundedTimeFormat, convertMsToRoundedDays, convertMsToRoundedMinutes } from './generalUtils';
import { dailyStatusDateFormat, dateFormats, SERVER_DATE_FORMAT } from '@shared/constants/formats.constants';
import { IWorkDayHours } from '@shared/interfaces/IProject';
import { getProductRoundedHoursFromMs, getRoundedHoursFromMsByStep } from '@shared/utils/roundNumbers.utils';
import {
	convertDateObjectToServerFormat as convertDateObjectToServerFormatShared,
	getDateFormattedWithTimeFromTimestamp,
	getProjectDateWithBrowserTZOffset as getProjectDateWithBrowserTZOffsetShared,
} from '@shared/utils/dateUtils';
import { Languages } from '@shared/constants/languages';
import { DIRECTIONS } from '@shared/constants/directions';
import {
	getTimezoneStartOfDate as getTimezoneStartOfDateShared,
	getTimezoneDateRanges as getTimezoneDateRangesShared,
} from '@shared/utils/dateUtils';

export const getWeekDayFromDate = (date: Date, tz: string, locale: string) => {
	return moment(date).locale(locale).tz(tz).format('dddd');
};

export const getAmPmTimeFormatFromDate = (timestamp: number, tz: string): string => {
	const time: Moment = moment.tz(timestamp, tz);
	return time.format(translationService.getChosenLanguage() === Languages.ENGLISH_US ? 'hh:mm A' : 'HH:mm');
};

export const calculateHoursOnSite = (arrivalTime: number, exitTime: number): string => {
	const hoursOnSite = (exitTime - arrivalTime) / (60 * 60 * 1000);
	return getHoursMinutesHoursFormatFromHours(hoursOnSite);
};

export const convertDateToDailyStatusDateString = (date: Date, tz: string): string => {
	return moment.tz(date, tz).format(dailyStatusDateFormat);
};

export const convertDailyStatusDateStringToDate = (dateString: string, tz: string): Date => {
	return moment.tz(dateString, dailyStatusDateFormat, tz).toDate();
};

export const convertTimeStampToDateByFormatAndTz = (date: MomentInput, tz: string, format: string) => {
	return moment.tz(date, tz).format(format);
};

export const getTimezoneDateRanges = (startDate: Date, endDate: Date, tz: string): Date[] => {
	return getTimezoneDateRangesShared(startDate, endDate, tz);
};

export const getTimezoneFormattedDate = (tz: string, dateValue?: MomentInput): string => {
	if (typeof dateValue === 'string') {
		throw Error("dateValue can't be string");
	}
	if (!isValidTimezone(tz)) {
		throw Error('The provided timezone is not valid');
	}
	return moment.tz(dateValue, tz).format(SERVER_DATE_FORMAT);
};

export const buildTimeDifferenceString = ({ hours, minutes }) => {
	if (hours === 0) {
		return `${minutes} ${translationService.get('minutes')}`;
	}
	return `${hours} ${translationService.get('hours')}, ${minutes} ${translationService.get('minutes')}`;
};

export const getWeekDay = (date, tz) => {
	const weekdays = [
		translationService.get('sunday'),
		translationService.get('monday'),
		translationService.get('tuesday'),
		translationService.get('wednesday'),
		translationService.get('thursday'),
		translationService.get('friday'),
		translationService.get('saturday'),
	];
	return weekdays[moment.tz(date, tz).day()];
};
export const getTimerFromMilliseconds = (milliseconds) => {
	return moment(milliseconds).format('HH:mm:ss');
};

export const getNumberDaysAgo = (date: Date, tz: string): number => {
	if (!date) {
		return Infinity;
	}
	const lastSeenDate: Moment = moment.tz(date, tz);
	const todayDate: Moment = moment.tz(tz);
	return todayDate.startOf('d').diff(lastSeenDate.startOf('d'), 'days');
};

export const getDayAmountBetweenDates = (startDate: Date, endDate: Date, tz: string): number => {
	return moment.tz(endDate, tz).diff(moment.tz(startDate, tz), 'days') + 1;
};

export const getTimezoneDateByLocaleFormat = (tz: string, date: Date, langDirection: string): string => {
	const momentDate: moment.Moment = moment.tz(date, tz);
	const dateFormat: string = langDirection === DIRECTIONS.LTR ? dateFormats.MD : dateFormats.DM;
	return momentDate.format(dateFormat);
};

export const getTimezoneDateStringFromDateObject = (date: Date, tz: string, shortDate = false) => {
	const dateToStringify: Moment = moment.tz(date, tz);
	const format: string = shortDate ? translationService.getDayAndMonthFormat() : translationService.getDateFormat();
	return dateToStringify.format(format);
};

export const getDateByLocaleFormat = (date, tz) => {
	return moment.tz(date, tz).format(translationService.getDateFormat());
};

export const getDayAndMonthLocaleFormat = (date, tz) => {
	return moment.tz(date, tz).format(translationService.getDayAndMonthFormat());
};

export const convertDateStringToAnotherFormat = (
	dateString: string,
	originFormat: string,
	anotherFormat: string
): string => {
	return moment(dateString, originFormat).format(anotherFormat);
};

export const convertDateObjectToServerFormat = (dateObject: Date | number) => {
	return convertDateObjectToServerFormatShared(dateObject);
};
export const getDate = (formatDate?) => {
	if (formatDate) {
		return moment(formatDate, translationService.getDateFormat()).toDate();
	}
	return moment().toDate();
};

export const getDateFromFormat = (date: MomentInput, format: string) => {
	return moment(date, format);
};

export const getDateAndHourFromFormat = (date: MomentInput, format: string, tz: string) => {
	return `${convertTimeStampToDateByFormatAndTz(date, tz, format)}, ${getHoursMinutesFormat(date, tz)}`;
};

export const getDateFromFormatTZ = (date: string, format: string, tz: string): Moment => {
	return moment.tz(date, format, tz);
};

export const getHoursMinutesFormat = (date: MomentInput, tz: string) => {
	return moment.tz(date, tz).format('HH:mm');
};

export const getHoursMinutesHoursFormatFromHours = (numHours: number): string => {
	const totalMinutes: number = numHours * 60;

	const hours: number = Math.floor(totalMinutes / 60);
	const minutes: number = Math.floor(totalMinutes % 60);

	return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
};

export const generateWeekDatesRange = (date: Date): Date[] => {
	return new Array(7).fill(undefined).map((_, i) => {
		const d = new Date(moment(date).startOf('week').toDate());
		d.setDate(d.getDate() + i);
		return d;
	});
};

export const generateTimezoneWeekDatesRange = (date: Date, tz: string) => {
	const startOfWeek: Date = moment.tz(date, tz).startOf('week').toDate();
	const endOfWeek: Date = moment.tz(date, tz).endOf('week').toDate();
	return getTimezoneDateRanges(startOfWeek, endOfWeek, tz);
};

export const generateTimezoneMultipleWeekDatesRange = (date: Date, tz: string, weeks: number): Date[] => {
	const startOfWeek: Date = moment.tz(date, tz).startOf('week').toDate();
	const endOfWeek: Date = moment
		.tz(date, tz)
		.endOf('week')
		.add(weeks - 1, 'w')
		.toDate();
	return getTimezoneDateRanges(startOfWeek, endOfWeek, tz);
};

export const getDateRanges = (startDate: MomentInput, endDate: MomentInput): Date[] => {
	const dates: Date[] = [];
	const currRunningDate = moment(startDate).startOf('day');
	const lastDate = moment(endDate).startOf('day');

	while (currRunningDate.isSameOrBefore(lastDate, 'd')) {
		dates.push(currRunningDate.clone().toDate());
		currRunningDate.add(1, 'd');
	}

	return dates;
};

export const getShortedWeekDayName = (dateTimestamp: number, tz: string): string => {
	const weekDay: number = moment.tz(dateTimestamp, tz).get('day');
	switch (weekDay) {
		case 0:
			return translationService.get('datepicker_weekday_Sunday');
		case 1:
			return translationService.get('datepicker_weekday_Monday');
		case 2:
			return translationService.get('datepicker_weekday_Tuesday');
		case 3:
			return translationService.get('datepicker_weekday_Wednesday');
		case 4:
			return translationService.get('datepicker_weekday_Thursday');
		case 5:
			return translationService.get('datepicker_weekday_Friday');
		case 6:
			return translationService.get('datepicker_weekday_Saturday');
		default:
			return '';
	}
};

export const getWorkDaysNumber = (startDate: Date, endDate: Date, workDayHours: IWorkDayHours[], tz: string) => {
	const dateRanges: Date[] = getDateRanges(startDate, endDate);
	return dateRanges.filter((date) => workDayHours[moment.tz(date, tz).day()].dailyWorkerHours > 0).length;
};

export const getMaxDate = (firstDate: Date, secondDate: Date): Date => {
	return firstDate.getTime() > secondDate.getTime() ? firstDate : secondDate;
};

export const getMinDate = (firstDate: Date, secondDate: Date): Date => {
	return firstDate.getTime() < secondDate.getTime() ? firstDate : secondDate;
};

export const getFormattedDate = (dateValue?: MomentInput): string => {
	return moment(dateValue).format(SERVER_DATE_FORMAT);
};

export const isDateInRange = (date: moment.MomentInput, startRangeDate: Date, endRangeDate: Date): boolean => {
	const momentDate: Moment = moment(date);
	return momentDate.isSameOrBefore(endRangeDate) && momentDate.isSameOrAfter(startRangeDate);
};

export const getProjectDateWithBrowserTZOffset = (projectTimezone: string, date?: Date | number | null): Date => {
	return getProjectDateWithBrowserTZOffsetShared(projectTimezone, date);
};

export const getTimezoneTimestamp = (date: Date | number, tz: string): number => {
	return moment.tz(moment(date).format('YYYY-MM-DDTHH:mm:ss'), tz).valueOf();
};

export const getNumberOfDaysWithoutRestDays = (
	startDate: Date | number,
	endDate: Date | number,
	workDayHours: IWorkDayHours[]
): number => {
	const dateRanges: Date[] = getDateRanges(startDate, endDate);
	return sumBy(dateRanges, (date: Date) => {
		const currentDayIndex = moment(date).day();
		return workDayHours.length > currentDayIndex && workDayHours[currentDayIndex].dailyWorkerHours > 0 ? 1 : 0;
	});
};

export const getTimezoneStartOfDate = (tz: string, date?: string): Date => {
	return getTimezoneStartOfDateShared(tz, date);
};

export const getTimezoneEndOfDate = (tz: string, date?: string): Date => {
	return moment.tz(getTimezoneStartOfDate(tz, date), tz).endOf('d').toDate();
};

export const getTimezoneStartOfWeek = (tz: string, date?: string): Date => {
	return moment.tz(getTimezoneStartOfDate(tz, date), tz).startOf('week').toDate();
};

export const getTimezoneEndOfWeek = (tz: string, date?: string) => {
	return moment.tz(getTimezoneStartOfDate(tz, date), tz).endOf('week').toDate();
};

export const isValidTimezone = (tz: string): boolean => {
	return !!moment.tz.zone(tz);
};

export const getDateFromWeekDay = (tz: string, dayIndex: number): Date => {
	const date: Date = new Date();
	const dayDiff: number = dayIndex - date.getDay();
	date.setDate(date.getDate() + dayDiff);
	return getTimezoneStartOfDate(tz, getTimezoneFormattedDate(tz, date));
};

export const getPastTimeFrom = (pastDate: Date | Moment): string => {
	const differenceInMs: number = moment().diff(pastDate);
	return msToTimeString(differenceInMs);
};

export const msToTimeString = (durationMs: number, isMinimized?: boolean): string => {
	const minutes = convertMsToRoundedMinutes(durationMs);
	const hours = getProductRoundedHoursFromMs(durationMs);
	const days = convertMsToRoundedDays(durationMs);

	if (days) {
		return `${days} ${days > 1 ? translationService.get('days') : translationService.get('day')}`;
	}
	if (hours) {
		return `${hours} ${hours > 1 ? translationService.get('hours') : translationService.get('hour')}`;
	}
	return `${minutes} ${
		minutes > 1
			? translationService.get(isMinimized ? 'Mins' : 'minutes')
			: translationService.get(isMinimized ? 'min' : 'minute')
	}`;
};

export const msToHoistTimeString = (durationMs: number): string => {
	const minutes: number = convertMsToRoundedMinutes(durationMs) % 60;
	const hours: number = getRoundedHoursFromMsByStep(durationMs, 1.0);
	if (hours) {
		return `${convertMsToRoundedTimeFormat(durationMs)} ${translationService.get('hours')}`;
	}
	return `${minutes} ${minutes > 1 ? translationService.get('minutes') : translationService.get('minute')}`;
};

export const getDaysDiffBetweenDateAndToday = (date: Date, tz: string): number => {
	const now = moment.tz();
	const dateMoment = moment.tz(date, tz);
	return now.diff(dateMoment, 'days');
};

export const getFullDayHoursArray = (
	jump: number,
	closeClockCircle = true,
	uppercase: boolean = true,
	twentyFourHoursFormat = false
): string[] => {
	const hoursArray: string[] = [
		'12 AM',
		'1 AM',
		'2 AM',
		'3 AM',
		'4 AM',
		'5 AM',
		'6 AM',
		'7 AM',
		'8 AM',
		'9 AM',
		'10 AM',
		'11 AM',
		'12 PM',
		'1 PM',
		'2 PM',
		'3 PM',
		'4 PM',
		'5 PM',
		'6 PM',
		'7 PM',
		'8 PM',
		'9 PM',
		'10 PM',
		'11 PM',
	];
	const arrayResult: string[] = hoursArray.filter((hour, index) => index % jump === 0);
	if (closeClockCircle) {
		arrayResult.push('12 AM');
	}
	if (twentyFourHoursFormat) {
		return arrayResult.map((hour: string) => {
			const parts: string[] = hour.split(' ');
			const time: string = parts[0];
			const period: string = parts[1];
			let hourValue: number = parseInt(time);
			if (period === 'PM' && hourValue !== 12) {
				hourValue += 12;
			} else if (period === 'AM' && hourValue === 12) {
				hourValue = 0;
			}
			return hourValue.toString().padStart(2, '0') + ':00';
		});
	}
	if (!uppercase) {
		return arrayResult.map((hour) => hour.toLowerCase());
	}
	return arrayResult;
};

export const getDateRangeFormatted = (startDate: Date, endDate: Date, tz: string): string => {
	const formattedStartDate: string = getDateFormattedWithTimeFromTimestamp(
		startDate.getTime(),
		false,
		translationService.getChosenLanguage() === Languages.HEBREW,
		tz,
		translationService.getDateLocale()
	);

	const formattedEndDate: string = getDateFormattedWithTimeFromTimestamp(
		endDate.getTime(),
		false,
		translationService.getChosenLanguage() === Languages.HEBREW,
		tz,
		translationService.getDateLocale()
	);

	const reportDateRangeText: string =
		formattedStartDate === formattedEndDate ? formattedStartDate : `${formattedStartDate} - ${formattedEndDate}`;

	return reportDateRangeText;
};
