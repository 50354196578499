import { IRoundedProfessionProductivityReport } from '@interfaces/IRoundedProfessionProductivityReport';
import { sum, uniq } from 'lodash';
import { IRoundedProfessionsAnalysisReport } from '@interfaces/IRoundedProfessionsAnalysisReport';

export const getActiveLocationsFromReports = (
	professionsProductivityReports: IRoundedProfessionProductivityReport[]
): number => {
	const activeFloors: string[] = professionsProductivityReports.reduce(
		(acc: string[], report: IRoundedProfessionProductivityReport) => {
			const reportActiveFloors: string[] = Object.keys(report.floors).filter(
				(floor: string) => report.floors[floor].totalHours > 0
			);
			return [...acc, ...reportActiveFloors];
		},
		[]
	);
	return uniq(activeFloors).length;
};

export const getTotalTimeFromProfessionAnalysisReport = (
	professionsProductivityReport: IRoundedProfessionsAnalysisReport | undefined
): number => {
	if (!professionsProductivityReport) return 0;

	return sum(
		Object.values(professionsProductivityReport).flatMap((report) =>
			Object.values(report.dates).map((date) => date.totalWorkHours)
		)
	);
};
