import React, { useEffect, useState } from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import { Weather } from './Weather';
import { ProjectSelector } from '../../Dropdowns/ProjectSelector/ProjectSelector';
import { storageService, translationService } from '../../../index';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../store/slices';
import classes from './styles.module.scss';
import { RoleType } from '@shared/interfaces/RoleType.enum';
import classnames from 'classnames';

interface TopBarProps {
	projectId: string;
	hideProjectSelector?: boolean;
}

const TopBar = (props: TopBarProps) => {
	const [projectLogo, setProjectLogo] = useState<string>('');
	const isProjectLtr: boolean = !translationService.getIsRtl();
	const loggedUserRole: string = useSelector(
		(state: IRootState) => state.loginReducer.loggedUser!.userDetails.permissions.roleType
	);

	useEffect(() => {
		async function getLogo() {
			const logoData = await storageService.getProjectLogo(props.projectId);
			logoData && setProjectLogo(logoData);
		}

		setProjectLogo('');
		getLogo();
	}, [props.projectId]);

	return (
		<Toolbar
			className={classnames({
				[classes.toolbar]: true,
				[classes.right]: isProjectLtr,
				[classes.left]: !isProjectLtr,
				[classes.noProjectSelector]: props.hideProjectSelector,
			})}
		>
			{!props.hideProjectSelector && (
				<div className={classes.projectsSelectorContainer}>
					<ProjectSelector />
				</div>
			)}
			<div className={classes.versionWeather}>
				{loggedUserRole === RoleType.TRUSSTOR_ADMIN && (
					<div className={classes.versionText}>
						Version: {process.env.REACT_APP_DASHBOARD_VERSION || 'Not defined'}
					</div>
				)}
			</div>
			<div className={classes.logosContainer}>
				{projectLogo && <img src={`${projectLogo}`} alt="Project logo" className={classes.projectLogo} />}
			</div>
		</Toolbar>
	);
};

export { TopBar };
