export enum QueryKeys {
	mergedEquipmentInLocation = 'mergedEquipmentInLocation',
	professionAllTimeStats = 'professionAllTimeStats',
	projectAreas = 'projectAreas',
	floorAreasData = 'floorAreasData',
	mobileMainPageActivities = 'mobileMainPageActivities',
	mobileLocationActivities = 'mobileLocationActivities',
	professionsProductivityReports = 'professionsProductivityReports',
	getIssuesSortingSettings = 'getIssuesSortingSettings',
	countResourcesByLocation = 'countResourcesByLocation',
	areaByAreaId = 'areaByAreaId',
	areaSequenceItemsByAreaId = 'areaSequenceItemsByAreaId',
	projectSequences = 'projectSequences',
	projectSequenceItemsByProject = 'projectSequenceItemsByProject',
	areaSequenceItemsBySequenceItemId = 'areaSequenceItemsBySequenceItemId',
	areaSequenceItemsCountBySequenceType = 'areaSequenceItemsCountBySequenceType',
	areaSequenceItemsCountByFloorId = 'areaSequenceItemsCountByFloorId',
}
