import { AreaSequenceItemStatus } from '../interfaces/AreaSequenceItemStatus.enum';
import { orderBy } from 'lodash';
import moment from 'moment';
import { ActivityGroupStatus } from '../interfaces/ActivityGroupStatus.enum';
import { IMainPageWidgetActivityData as IMainPageWidgetActivityDataShared } from '../interfaces/IMainPageWidgetActivityData';
import { IProfession } from '../interfaces/IProfession';
import { isAsiComplete } from './asi.utils';
import { WidgetActivityFilters } from '../interfaces/WidgetActivityFilters';
import { getTimezoneStartOfDate } from './dateUtils';

type IMainPageWidgetActivityData = IMainPageWidgetActivityDataShared<IProfession>;

export const getSingleActivityProgressPercentage = (activity: IMainPageWidgetActivityData): number => {
	const totalLocationsNumber: number = getLocationsCountFromActivitiesGroup(activity);
	const completedLocationsNumber: number = getCompletedLocationsCountFromActivitiesGroup(activity);
	const progressPercentage: number =
		totalLocationsNumber === 0 ? 0 : Math.round((completedLocationsNumber / totalLocationsNumber) * 100);

	return progressPercentage;
};
export const getCompletedLocationsCountFromActivitiesGroup = (activitiesGroup: IMainPageWidgetActivityData): number => {
	if (activitiesGroup.areaSequenceItems.length > 0) {
		return activitiesGroup.areaSequenceItems.filter((areaSequenceItem) => isAsiComplete(areaSequenceItem.status))
			.length;
	}

	if (activitiesGroup.groupStatus === ActivityGroupStatus.complete) {
		return activitiesGroup.floors.length;
	}

	return 0;
};
export const getLocationsCountFromActivitiesGroup = (activitiesGroup: IMainPageWidgetActivityData): number => {
	if (activitiesGroup.areaSequenceItems.length > 0) {
		return activitiesGroup.areaSequenceItems.length;
	}

	return activitiesGroup.floors.length;
};
export const getCompletedActivities = (activities: IMainPageWidgetActivityData[]): IMainPageWidgetActivityData[] => {
	if (!activities) {
		return [];
	}

	const completedActivities: IMainPageWidgetActivityData[] = activities.filter(
		(activity) => activity.groupStatus === ActivityGroupStatus.complete
	);

	const sortedCompletedActivities: IMainPageWidgetActivityData[] = orderBy(
		completedActivities,
		(activity) => activity.endDate,
		'desc'
	);

	return sortedCompletedActivities;
};
export const getWidgetActivitiesFiltered = (
	activities: IMainPageWidgetActivityData[],
	widgetActivityFilters: WidgetActivityFilters,
	tz: string
): IMainPageWidgetActivityData[] => {
	const ongoingActivities: IMainPageWidgetActivityData[] = getOngoingActivities(activities);

	switch (widgetActivityFilters) {
		case WidgetActivityFilters.toDo:
			return ongoingActivities;
		case WidgetActivityFilters.forReview:
			return getWidgetForReviewActivities(activities);
		case WidgetActivityFilters.noProgress:
			return getNoProgressActivities(activities);
		case WidgetActivityFilters.inProgress:
			return getInProgressActivities(activities);
		case WidgetActivityFilters.todayProgress:
			return getTodayProgressActivities(activities, tz);
		case WidgetActivityFilters.completed:
			return activities.filter((activity) => activity.groupStatus === ActivityGroupStatus.complete);
	}
};

const isActivitiesGroupWithPastEndDate = (activity: IMainPageWidgetActivityData, tz: string): boolean => {
	const isDueDatePassed: boolean = moment.tz(activity.originalEndDate, tz).isBefore(moment.tz(tz), 'day');
	return isDueDatePassed;
};

export const getOverdueActivities = (
	activities: IMainPageWidgetActivityData[],
	tz: string
): IMainPageWidgetActivityData[] => {
	const ongoingActivities: IMainPageWidgetActivityData[] = getOngoingActivities(activities);
	const overdueActivities: IMainPageWidgetActivityData[] = ongoingActivities.filter((act) =>
		isActivitiesGroupWithPastEndDate(act, tz)
	);
	const sortedOverdueActivities: IMainPageWidgetActivityData[] = orderBy(
		overdueActivities,
		(activity) => `${activity.originalEndDate.toString()}${activity.description}`,
		'desc'
	);
	return sortedOverdueActivities;
};

export const getNoProgressActivities = (activities: IMainPageWidgetActivityData[]): IMainPageWidgetActivityData[] => {
	const noProgressActivities = activities.filter((activity) => activity.groupStatus === ActivityGroupStatus.delayed);
	const sortedNoProgressActivities: IMainPageWidgetActivityData[] = orderBy(
		noProgressActivities,
		(activity) => activity.originalEndDate,
		'asc'
	);
	return sortedNoProgressActivities;
};

export const getInProgressActivities = (activities: IMainPageWidgetActivityData[]): IMainPageWidgetActivityData[] => {
	const inProgressActivities = activities.filter(
		(activity) =>
			activity.groupStatus === ActivityGroupStatus.overdue ||
			activity.groupStatus === ActivityGroupStatus.inProgress
	);
	const sortedInProgressActivities: IMainPageWidgetActivityData[] = orderBy(
		inProgressActivities,
		(activity) => activity.originalEndDate,
		'asc'
	);
	return sortedInProgressActivities;
};

export const getTodayProgressActivities = (
	activities: IMainPageWidgetActivityData[],
	tz: string
): IMainPageWidgetActivityData[] => {
	const progressStatuses: AreaSequenceItemStatus[] = [
		AreaSequenceItemStatus.complete,
		AreaSequenceItemStatus.inProgress,
	];
	const todayStartDate: Date = getTimezoneStartOfDate(tz);
	const todayProgressActivities = activities.filter((activity) =>
		activity.areaSequenceItems.some(
			(asi) =>
				progressStatuses.includes(asi.status) &&
				asi.lastStatusUpdateDate &&
				new Date(asi.lastStatusUpdateDate).getTime() >= todayStartDate.getTime()
		)
	);
	const sortedTodayProgressActivities: IMainPageWidgetActivityData[] = orderBy(
		todayProgressActivities,
		(activity) => activity.originalEndDate,
		'asc'
	);
	return sortedTodayProgressActivities;
};

export const getOngoingActivities = (activities: IMainPageWidgetActivityData[]): IMainPageWidgetActivityData[] => {
	const ongoingActivities: IMainPageWidgetActivityData[] = activities.filter(
		(activity) => activity.groupStatus !== ActivityGroupStatus.complete
	);

	const sortedOngoingActivities: IMainPageWidgetActivityData[] = orderBy(
		ongoingActivities,
		(activity) => activity.originalStartDate,
		'asc'
	);

	return sortedOngoingActivities;
};

export const getWidgetForReviewActivities = (
	widgetActivities: IMainPageWidgetActivityData[]
): IMainPageWidgetActivityData[] => {
	if (!widgetActivities) {
		return [];
	}

	const getHasReadyForReviewItems = (activity: IMainPageWidgetActivityData): boolean => {
		return activity.areaSequenceItems.some(
			(areaSequenceItem) => areaSequenceItem.status === AreaSequenceItemStatus.readyForReview
		);
	};

	const forReviewActivities: IMainPageWidgetActivityData[] = widgetActivities
		.filter(getHasReadyForReviewItems)
		.map((activity) => {
			const areaSequenceItems = activity.areaSequenceItems.filter(
				(areaSequenceItem) => areaSequenceItem.status === AreaSequenceItemStatus.readyForReview
			);
			return {
				...activity,
				areaSequenceItems,
			};
		});
	const sortedForReviewActivities: IMainPageWidgetActivityData[] = orderBy(
		forReviewActivities,
		(activity) => activity.originalEndDate,
		'asc'
	);
	return sortedForReviewActivities;
};

export const getNotOverdueActivities = (
	activities: IMainPageWidgetActivityData[],
	tz: string
): {
	dueInSevenDaysActivities: IMainPageWidgetActivityData[] | undefined;
	futureActivities: IMainPageWidgetActivityData[] | undefined;
	dueTodayActivities: IMainPageWidgetActivityData[] | undefined;
} => {
	if (!activities) {
		return {
			dueInSevenDaysActivities: undefined,
			futureActivities: undefined,
			dueTodayActivities: undefined,
		};
	}
	const ongoingActivities: IMainPageWidgetActivityData[] = getOngoingActivities(activities);

	const notOverdueActivities: IMainPageWidgetActivityData[] = ongoingActivities.filter(
		(activity) => !isActivitiesGroupWithPastEndDate(activity, tz)
	);

	const sortedOngoingNonOverduedActivities: IMainPageWidgetActivityData[] = orderBy(
		notOverdueActivities,
		(activity) => activity.originalStartDate,
		'asc'
	);

	const { dueInSevenDaysActivities, futureActivities, dueTodayActivities } = getCurrentWeekAndFutureActivities(
		sortedOngoingNonOverduedActivities,
		tz
	);

	return { dueInSevenDaysActivities, futureActivities, dueTodayActivities };
};

const getCurrentWeekAndFutureActivities = (
	activities: IMainPageWidgetActivityData[],
	tz: string
): {
	dueTodayActivities: IMainPageWidgetActivityData[] | undefined;
	dueInSevenDaysActivities: IMainPageWidgetActivityData[] | undefined;
	futureActivities: IMainPageWidgetActivityData[] | undefined;
} => {
	const { dueInSevenDaysActivities, futureActivities, dueTodayActivities } = activities.reduce<{
		dueInSevenDaysActivities: IMainPageWidgetActivityData[];
		futureActivities: IMainPageWidgetActivityData[];
		dueTodayActivities: IMainPageWidgetActivityData[];
	}>(
		(acc, activity: IMainPageWidgetActivityData) => {
			if (moment.tz(activity.originalEndDate, tz).isSame(moment.tz(tz), 'day')) {
				acc.dueTodayActivities.push(activity);
				return acc;
			}
			const sevenDaysFromNow: Date = moment.tz(tz).add(7, 'days').toDate();
			if (
				moment.tz(tz).isSameOrBefore(moment.tz(activity.originalEndDate, tz), 'day') &&
				moment.tz(activity.originalEndDate, tz).isBefore(sevenDaysFromNow)
			) {
				acc.dueInSevenDaysActivities.push(activity);
			} else {
				acc.futureActivities.push(activity);
			}
			return acc;
		},
		{
			dueInSevenDaysActivities: [],
			futureActivities: [],
			dueTodayActivities: [],
		}
	);
	return {
		dueInSevenDaysActivities,
		futureActivities,
		dueTodayActivities,
	};
};
