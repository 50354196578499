import { ITagCertificate } from '../../../interfaces/ITagCertificate';
import { translationService } from '../../../index';
import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import { getTimezoneDateStringFromDateObject } from '../../../utils/dateUtils';
import { IRootState } from '../../../store/slices';
import { useSelector } from 'react-redux';
import { isCertificateExpired } from '../../../utils/adminPanelTags.utils';
import { cancelIcon, checkIcon } from '../../../assets';
import { sortBy } from 'lodash';

interface ITagCertificatesTooltipTableProps {
	certificates: ITagCertificate[] | undefined;
}

export const TagCertificatesTooltipTable = (props: ITagCertificatesTooltipTableProps) => {
	const tz: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.tz);
	const [sortedCertificates, setSortedCertificates] = React.useState<ITagCertificate[]>([]);

	useEffect(() => {
		const sortedCertificatesByDate: ITagCertificate[] = sortBy(props.certificates, 'expirationDate');
		setSortedCertificates(sortedCertificatesByDate);
	}, [props.certificates]);

	if (!props.certificates) {
		return null;
	}
	return (
		<div className={styles.table}>
			<div className={styles.tableHeadersRow}>
				<div className={styles.certificateNameHeader}>
					{translationService.get('certificateName').toUpperCase()}
				</div>
				<div className={styles.expirationDateHeader}>
					{translationService.get('expirationDate').toUpperCase()}
				</div>
			</div>
			<div className={styles.tableBodyRow}>
				{sortedCertificates.map((certificate) => {
					const isExpired: boolean = isCertificateExpired(certificate, tz);

					return (
						<div className={styles.tableRow} key={certificate.name}>
							<div className={styles.certificateNameCell}>
								<img
									className={styles.statusIcon}
									src={isExpired ? cancelIcon : checkIcon}
									alt="link"
								/>
								<div>{certificate.name}</div>
							</div>
							<div className={isExpired ? styles.expiredExpirationDateCell : styles.expirationDateCell}>
								{getTimezoneDateStringFromDateObject(certificate.expirationDate, tz)}
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};
