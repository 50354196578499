export enum AreaSequenceItemStatus {
	unplanned = 'unplanned',
	planned = 'planned',
	delayed = 'delayed',
	delayedOverdue = 'delayedOverdue',
	inProgress = 'inProgress',
	overdue = 'overdue',
	readyForReview = 'readyForReview',
	complete = 'complete',
}
