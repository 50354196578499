import { IDashboardTabItem } from '@interfaces/IDashboardTabItem';
import { accessLevelService, projectAccessLevelService, translationService } from '@src/servicesInitializers';
import { DashboardTabTypes } from '@interfaces/DashboardTabTypes.enum';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { IMainPageWidgetActivityData } from '@interfaces/IMainPageWidgetActivityData';
import { IProject } from '@shared/interfaces/IProject';

export const filterFloorActivities = (
	widgetActivities: IMainPageWidgetActivityData[],
	floorId: string
): IMainPageWidgetActivityData[] => {
	const floorActivities: IMainPageWidgetActivityData[] | undefined = widgetActivities
		?.filter((activity) => {
			return activity.floors.find((floor) => floor.floorId === floorId);
		})
		.map((activity) => {
			return {
				...activity,
				areaSequenceItems: activity.areaSequenceItems.filter((asi) => asi.area.floorId === floorId),
			};
		});

	return floorActivities || [];
};

export const getDashboardTabsItems = (
	workersOnSite?: number,
	activeManagers?: number,
	widgetActivitiesCount?: number,
	safetyEventsCount?: number,
	issuesCount?: number,
	project?: IProject
): IDashboardTabItem[] => {
	const personnelValue: number | undefined =
		workersOnSite !== undefined || activeManagers !== undefined
			? (workersOnSite || 0) + (activeManagers || 0)
			: undefined;
	const personnelTab: IDashboardTabItem = {
		label: translationService.get('personnelDashboardTab'),
		type: DashboardTabTypes.workForce,
		items: [
			{
				icon: IconNames.users,
				value: personnelValue,
			},
		],
	};

	const activitiesTab: IDashboardTabItem = {
		label: translationService.get('dashboardTab-activities'),
		type: DashboardTabTypes.activities,
		items: [
			{
				icon: IconNames.workplan,
				value: widgetActivitiesCount,
			},
		],
	};

	const issuesTab: IDashboardTabItem = {
		label: translationService.get('dashboardTab-issues'),
		type: DashboardTabTypes.issues,
		items: [
			{
				icon: IconNames.issues,
				value: issuesCount,
			},
		],
	};

	const safetyTab: IDashboardTabItem = {
		label: translationService.get('dashboardTab-safety'),
		type: DashboardTabTypes.safetyEvents,
		items: [
			{
				icon: IconNames.shield,
				value: safetyEventsCount,
			},
		],
	};

	const shouldShowPersonnelTab: boolean = projectAccessLevelService.hasAccess('projectMonitor-personnel', project);
	const shouldShowActivitiesTab: boolean = projectAccessLevelService.hasAccess('projectMonitor-activities', project);
	const shouldShowIssuesTab: boolean = projectAccessLevelService.hasAccess('projectMonitor-issues', project);
	const shouldShowSafetyTab: boolean =
		projectAccessLevelService.hasAccess('projectMonitor-safety', project) &&
		accessLevelService.hasAccess('projectMonitor-safety');

	return [
		...(shouldShowPersonnelTab ? [personnelTab] : []),
		...(shouldShowActivitiesTab ? [activitiesTab] : []),
		...(shouldShowIssuesTab ? [issuesTab] : []),
		...(shouldShowSafetyTab ? [safetyTab] : []),
	];
};

export const getInitialSelectedDashboardTab = (): DashboardTabTypes => {
	if (projectAccessLevelService.hasAccess('projectMonitor-personnel')) {
		return DashboardTabTypes.workForce;
	}
	if (projectAccessLevelService.hasAccess('projectMonitor-activities')) {
		return DashboardTabTypes.activities;
	}
	if (projectAccessLevelService.hasAccess('projectMonitor-issues')) {
		return DashboardTabTypes.issues;
	}
	return DashboardTabTypes.safetyEvents;
};
