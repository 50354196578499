import React, { useEffect } from 'react';
import classes from './styles.module.scss';
import { translationService } from '@src/servicesInitializers';
import { NotificationSettingCard } from '@src/Components/NotificationSettingCard/NotificationSettingCard';
import { notificationSettingTabAccess_BI } from '@utils/bi.utils';
import { getDesktopNotificationsCards } from '@utils/notifications.utils';
import { useRenderMonitoring } from '@shared/hooks/monitoring.hooks';

interface INotificationSettingsPageProps {}

const NotificationSettingsPage = (props: INotificationSettingsPageProps) => {
	useRenderMonitoring('NotificationSettingsPage');
	useEffect(() => {
		notificationSettingTabAccess_BI();
	}, []);

	return (
		<section className={classes.notificationPageContainer}>
			<div className={classes.title}>{translationService.get('alertsHub')}</div>
			<div className={classes.content}>
				{getDesktopNotificationsCards().map((card) => (
					<NotificationSettingCard card={card} isBase />
				))}
			</div>
		</section>
	);
};

export { NotificationSettingsPage };
