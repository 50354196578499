import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { IRowProperty } from '@interfaces/IRowProperty';
import { ManagerTableRowButtons } from '../../ManagersListComponents/ManagerTableRowButtons/ManagerTableRowButtons';
import classes from './styles.module.scss';
import { ProfessionTableRowButtons } from '../../ProfessionsListComponents/ProfessionTableRowButtons/ProfessionTableRowButtons';
import { TableRowTypes } from '@src/constants';
import { UsersTableRowButtons } from '../../UsersTableComponents/UsersTableRowButtons/UsersTableRowButtons';
import { translationService } from '../../../index';
import { IUser } from '@shared/interfaces/IUser';
import classnames from 'classnames';

interface ITableRowProps {
	object: any;
	rowProperties: IRowProperty[];
	columnWidth?: string;
	columnWidthList?: string[];
	type: string;
	firstRowComponent?: React.ReactNode;
	lastRowComponent?: React.ReactNode;
	buttonsCmp?: React.ReactNode;
	icon?: React.ReactNode;
	shouldDisplayIcon?: boolean;
	tooltipText?: string;
	onExitDialog?: () => void;
	additionalObjects?: any[];
	rowContainerClass?: string;
	onClick?: (event: any) => void;
}

const MainTableRow = (props: ITableRowProps) => {
	const [showRowButtons, setShowRowButtons] = useState(false);

	const displayRowButtons = () => {
		setShowRowButtons(true);
	};

	const onExitDialog = () => {
		hideRowButtons();
		props.onExitDialog?.();
	};

	const hideRowButtons = () => {
		setShowRowButtons(false);
	};

	const getButtonsComponent = () => {
		if (props.type === TableRowTypes.MANAGER)
			return <ManagerTableRowButtons manager={props.object} onExitDialog={onExitDialog} />;
		if (props.type === TableRowTypes.USER)
			return (
				<UsersTableRowButtons
					users={props.additionalObjects as IUser[]}
					user={props.object}
					onExitDialog={onExitDialog}
				/>
			);
		if (props.type === TableRowTypes.ACTIVITIES_PROGRESS || props.type === TableRowTypes.MILESTONES) {
			return null;
		}
		return <ProfessionTableRowButtons profession={props.object} onExitDialog={onExitDialog} />;
	};

	const getTextToDisplay = (property: IRowProperty): string => {
		if (property.getTextToDisplay) return property.getTextToDisplay(props.object);
		if (!property.firstField) return '';
		let textToDisplay: string = props.object[property.firstField] ? props.object[property.firstField] : '';
		if (property.secondField) {
			textToDisplay = props.object[property.firstField][property.secondField];
		}
		return !property.useTranslationService ? textToDisplay : translationService.get(textToDisplay);
	};

	return (
		<div
			className={classnames(classes.rowContainer, props.rowContainerClass)}
			onClick={props.onClick}
			onMouseEnter={displayRowButtons}
			onMouseLeave={hideRowButtons}
		>
			<div className={classes.rowWrapper}>
				{props.firstRowComponent && (
					<Grid
						item
						className={`${classes.headerTextWrapper}`}
						alignItems="center"
						style={{ minWidth: 150, maxWidth: 150 }}
					>
						{props.firstRowComponent}
					</Grid>
				)}
				{props.rowProperties.map((property, index) => {
					return (
						<Grid
							item
							key={property.firstField ? property.firstField + property.secondField : index}
							className={`${classes.headerTextWrapper}`}
							alignItems="center"
							style={{
								minWidth: props.columnWidthList?.[index] || props.columnWidth,
								maxWidth: props.columnWidthList?.[index] || props.columnWidth,
							}}
						>
							<div className={`${classes.cellContainer} ${property.center ? classes.cellCenter : ''}`}>
								{property.component ? (
									<property.component data={props.object} />
								) : (
									<>
										<p className={classes.tableText}>{getTextToDisplay(property)}</p>
										{property.getSubText && (
											<p className={`${classes.tableText} ${classes.tableSubText}`}>
												{property.getSubText(props.object)}
											</p>
										)}
									</>
								)}
							</div>
						</Grid>
					);
				})}
				{props.lastRowComponent && (
					<Grid
						item
						className={`${classes.headerTextWrapper}`}
						alignItems="center"
						style={{ minWidth: props.columnWidth, maxWidth: props.columnWidth }}
					>
						{props.lastRowComponent}
					</Grid>
				)}
			</div>
			{showRowButtons && getButtonsComponent()}
		</div>
	);
};

export { MainTableRow };
