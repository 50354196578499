export enum SequenceTypeEnum {
	'APARTMENT' = 'apartment',
	'GARAGE' = 'garage',
	'CORE' = 'core',
	'LOBBY' = 'lobby',
	'STRUCTURE' = 'structure',
	'OFFICES' = 'offices',
	'HOTEL_ROOMS' = 'hotelRooms',
	'PRODUCTION_LINES' = 'productionLines',
	'COMMERCIALS' = 'commercials',
	'WET_ROOMS' = 'wetRooms',
	'MECHANICAL_ROOMS' = 'mechanicalRooms',
	'FACADE' = 'facade',
	'ELEVATORS' = 'elevators',
	'STAIRS' = 'stairs',
	'Kitchens' = 'kitchens',
	'Toilets' = 'toilets',
}
