import { useSelector } from 'react-redux';
import { IRootState } from '../../store/slices';
import { translationService } from '../../index';
import helmet from '../../assets/investigationReport/helmet.svg';
import warningCircle from '../../assets/investigationReport/warningCircle.svg';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import classes from './styles.module.scss';
import { ITimelineData } from '../../interfaces/ITimelineData';
import { getHoursMinutesDiffFormat } from '@shared/utils/dateUtils';

interface ITimelineItemProps {
	data: ITimelineData;
	index: number;
	lastIndex: number;
	indexMarked?: number;
	onClick?: (index: number) => void;
	isSelected?: boolean;
}

export const TimelineItem = (props: ITimelineItemProps) => {
	const tz: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.tz);
	const [isHover, setIsHover] = useState<boolean>(false);

	const onItemClick = (index?: number) => {
		if (props.onClick) {
			const targetIndex: number = index ?? props.index;
			props.onClick(targetIndex);
		}
	};

	const onKeyDownHandler = (event) => {
		const shouldGoUp: boolean = event.key === 'ArrowUp' && props.index !== 0;
		const shouldGoDown: boolean = event.key === 'ArrowDown' && props.index !== props.lastIndex;
		if (shouldGoDown) {
			onItemClick(props.index + 1);
		}
		if (shouldGoUp) {
			onItemClick(props.index - 1);
		}
	};

	useEffect(() => {
		if (!props.isSelected) return;
		window.addEventListener('keydown', onKeyDownHandler);
		return () => {
			window.removeEventListener('keydown', onKeyDownHandler);
		};
	}, [props.isSelected]);

	const isRtl: boolean = translationService.getIsRtl();
	return (
		<div
			className={`${isRtl ? classes.timelineItemRTL : classes.timelineItem}`}
			onClick={() => onItemClick()}
			onMouseEnter={() => setIsHover(true)}
			onMouseLeave={() => setIsHover(false)}
		>
			<div
				className={`${classes.timelineItemContent} ${
					props.index === props.lastIndex ? classes.noBorderBottom : ''
				} ${props.isSelected ? classes.selectedBackground : ''}`}
			>
				<div className={`${classes.line} ${classes.bigBoldText}`}>
					<div>{props.data.floorNick}</div>
					<div className={classes.lineUnit}>
						<img src={helmet} alt={''} />
						<div>{props.data.peopleWith}</div>
					</div>
				</div>
				<div className={classes.line}>
					<div className={classes.lightText}>{props.data.areaNick}</div>
					<div className={classes.lineUnit}>
						<span className={classes.rangeTime}>
							{isRtl
								? `${moment.tz(props.data.departureTime, tz).format('HH:mm')}-${moment
										.tz(props.data.arrivalTime, tz)
										.format('HH:mm')}`
								: `${moment.tz(props.data.arrivalTime, tz).format('HH:mm')}-${moment
										.tz(props.data.departureTime, tz)
										.format('HH:mm')}`}
						</span>
						<div className={classes.boldText}>
							{getHoursMinutesDiffFormat(
								props.data.arrivalTime,
								props.data.departureTime,
								translationService
							)}
						</div>
					</div>
				</div>
				<div
					className={`${isRtl ? classes.circleRTL : classes.circle} ${isHover ? classes.circleDark : ''} ${
						props.isSelected ? classes.circleSelected : ''
					}`}
				>
					{props.indexMarked === props.index ? (
						<img src={warningCircle} alt={'warningCircle'} />
					) : (
						props.index + 1
					)}
				</div>
			</div>
		</div>
	);
};
