import { Loader } from '@shared/components/Loader/Loader';
import { ITagRouteWithLocationData } from '@shared/interfaces/ITagRouteWithLocationData';
import React from 'react';
import classes from './styles.module.scss';
import { TagOnLocationRow } from './TagOnLocationRow/TagOnLocationRow';
import { EmptyStateRow } from '../ReportTagsLocationInfo/EmptyStateRow/EmptyStateRow';
import { translationService } from '../../../../index';
import { getTagsWorkPeriodsFromMergedFloors } from '@shared/utils/mergedFloors.utils';
import { IMergedTag } from '@interfaces/IMergedTag';
import { ITagWorkPeriods } from '@shared/interfaces/ITagWorkPeriods';

interface IReportPersonnelOnLocationProps {
	tagLocationData: ITagRouteWithLocationData | null;
}

export const ReportPersonnelOnLocation = (props: IReportPersonnelOnLocationProps) => {
	if (!props.tagLocationData) {
		return <Loader />;
	}

	const tagsWorkPeriods: ITagWorkPeriods[] = getTagsWorkPeriodsFromMergedFloors(props.tagLocationData.floors, [
		props.tagLocationData!.areaId,
	]);
	const otherTagWorkPeriods: ITagWorkPeriods[] = tagsWorkPeriods.filter(
		(tagWorkPeriods: ITagWorkPeriods) => tagWorkPeriods.tag.tagId !== props.tagLocationData!.tagId
	);

	return (
		<div className={classes.locationPersonnelSection}>
			<p className={classes.personnelOnTheAreaHeader}>{translationService.get('personnelOnTheArea')}</p>
			{!otherTagWorkPeriods.length ? (
				<EmptyStateRow text={translationService.get('noPersonnelFound')} />
			) : (
				otherTagWorkPeriods.map((tagWorkPeriods: ITagWorkPeriods, index) => {
					return (
						<TagOnLocationRow
							key={index}
							tagId={tagWorkPeriods.tag.tagId}
							mergedTagInfo={tagWorkPeriods.tag as IMergedTag}
							timeRanges={tagWorkPeriods.workPeriods}
							shouldHideBorder={index === otherTagWorkPeriods.length - 1}
						/>
					);
				})
			)}
		</div>
	);
};
