import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { APP_FILTER_TYPES } from '../../constants';
import { translationService } from '../../index';
import { IActivityGroupStatusFilterItem } from '../../interfaces/IActivityGroupStatusFilterItem';
import {
	addSelectedActivityGroupStatus,
	defaultSelectedActivityGroupStatus,
	removeSelectedActivityGroupStatus,
	resetSelectedActivityGroupStatus,
	selectActivityGroupSelectedStatuses,
	selectActivityGroupStatuses,
} from '../../store/slices/ActivityGroupStatuses.slice';
import { ExpandableComponent } from '../MainPageSections/ExpandableComponent/ExpandableComponent';
import { ActivityStatusFilterCard } from '@shared/components/Filters/StatusFilterCard/ActivityStatusFilterCard';

export const ActivityGroupStatusesFilterSection = () => {
	const dispatch = useDispatch();

	const activityGroupStatuses: IActivityGroupStatusFilterItem[] = useSelector(selectActivityGroupStatuses);
	const selectedActivityGroupStatuses: IActivityGroupStatusFilterItem[] = useSelector(
		selectActivityGroupSelectedStatuses
	);

	const checkShouldHideClearButton = (): boolean => {
		return selectedActivityGroupStatuses.length === 0;
	};

	const handleResetActivityGroupStatuses = (): void => {
		dispatch(resetSelectedActivityGroupStatus());
	};

	const addActivityGroupStatus = (activityGroupStatus: IActivityGroupStatusFilterItem): void => {
		dispatch(addSelectedActivityGroupStatus({ selectedStatus: activityGroupStatus }));
	};

	const removeActivityGroupStatus = (acivityGroupStatus: IActivityGroupStatusFilterItem): void => {
		dispatch(removeSelectedActivityGroupStatus({ removedStatus: acivityGroupStatus }));
	};

	useEffect(() => {
		dispatch(defaultSelectedActivityGroupStatus());
	}, []);

	return (
		<ExpandableComponent
			headerText={translationService.get('status')}
			childrenLength={activityGroupStatuses.length}
			clearFunction={handleResetActivityGroupStatuses}
			type={APP_FILTER_TYPES.status}
			shouldHideClearButton={checkShouldHideClearButton()}
			clearLength={selectedActivityGroupStatuses.length}
			hideEmptyList
			testId={'statusFilterSection'}
		>
			{activityGroupStatuses.map((activityGroupStatus: IActivityGroupStatusFilterItem) => {
				const isSelected = selectedActivityGroupStatuses.includes(activityGroupStatus);
				return (
					<ActivityStatusFilterCard
						key={activityGroupStatus.status}
						handleClick={
							isSelected
								? () => removeActivityGroupStatus(activityGroupStatus)
								: () => addActivityGroupStatus(activityGroupStatus)
						}
						isSelected={isSelected}
						status={activityGroupStatus.status}
					/>
				);
			})}
		</ExpandableComponent>
	);
};
