import { compact, orderBy } from 'lodash';
import React from 'react';
import { connect, useSelector } from 'react-redux';
import { APP_FILTER_TYPES, entitiesProperties } from '../../../../constants';
import { useSetActiveFilterType } from '../../../../hooks/useSetActiveFilterType.hooks';
import { translationService } from '../../../../index';
import {
	addOneSelectedProfession,
	removeOneSelectedProfession,
	resetSelectedProfessions,
	setProfessionsFilter,
} from '../../../../store/slices/professions.slice';
import { filterArrayByAnotherArray } from '../../../../utils/array.util';
import { checkForInitialValues } from '../../../../utils/generalUtils';
import { ManagersFilterSection } from '../../../FilterSections/ManagersFilterSection';
import { ExpandableComponent } from '../../../MainPageSections/ExpandableComponent/ExpandableComponent';
import { EquipmentTypeFilterSection } from '../../EquipmentTypeFilterSection';
import classes from './styles.module.scss';
import { selectProjectId } from '@store/slices/project.slice';
import { IProfession } from '@interfaces/index';
import { getTradeTranslation } from '@utils/translations.utils';
import { ProfessionFilterCard } from '@shared/components/Filters/ProfessionFilterCard/ProfessionFilterCard';
import Lottie from 'react-lottie';
import PersonnelLottieLoader from '../../DashboardLoaders/TabListLoader.json';
import PersonnelLottieLoaderRTL from '../../DashboardLoaders/TabListLoaderRTL.json';
import { IActiveManager } from '@store/slices/managers.slice';
import { IRootState } from '@store/slices';
import { selectIsLoadingDashboardData } from '@store/slices/dashboard.slice';
import {
	DashboardTabEmptyState,
	EmptyStateResourcesEnum,
} from '@shared/components/DashboardTabEmptyState/DashboardTabEmptyState';
import { IUtilityMergedTag } from '@interfaces/IUtilityMergedTag';

const WorkForceTab = ({
	handleResetProfessionsClick,
	currentProfessionsFilter,
	filteredProfessions,
	visibleProfessions,
	selectedProfessions,
	addProfession,
	removeProfession,
}) => {
	useSetActiveFilterType(APP_FILTER_TYPES.professionFilter);
	const projectId: string = useSelector(selectProjectId)!;
	const isFetchingDashboardPersonnelData: boolean = useSelector(selectIsLoadingDashboardData);
	const activeManagers: IActiveManager[] = useSelector((state: IRootState) => state.managersReducer.activeManagers);
	const activeEquipment = useSelector((state: IRootState) => state.equipmentReducer.activeEquipment);
	const utilitiesOnSite: IUtilityMergedTag[] = useSelector((state: IRootState) => state.dashboardReducer.utilities);
	const isRtl: boolean = translationService.getIsRtl();

	const addValuesToProfessions = (professions): any[] =>
		compact(
			professions.map((profession) => {
				if (!profession.activeTagsIds) return undefined;
				return {
					...profession,
					value: profession.activeTagsIds.length,
				};
			})
		);

	if (isFetchingDashboardPersonnelData) {
		return (
			<div className={classes.loaderContainer}>
				<Lottie
					options={{
						loop: true,
						autoplay: false,
						animationData: isRtl ? PersonnelLottieLoaderRTL : PersonnelLottieLoader,
					}}
				/>
			</div>
		);
	}

	if (!filteredProfessions.length && !activeManagers.length && !activeEquipment.length && !utilitiesOnSite.length) {
		return <DashboardTabEmptyState resource={EmptyStateResourcesEnum.personnel} />;
	}

	return (
		<div className={classes.professionsManagersContainer} data-testid={'workForceContainer'}>
			<ExpandableComponent
				headerText={translationService.get('professions')}
				childrenLength={filteredProfessions.length}
				shouldHideClearButton={checkForInitialValues(selectedProfessions, currentProfessionsFilter)}
				clearFunction={handleResetProfessionsClick}
				clearLength={visibleProfessions.length}
				type={APP_FILTER_TYPES.professionFilter}
				defaultOpen
				testId={'professionFilterSection'}
			>
				{orderBy(
					addValuesToProfessions(filteredProfessions),
					[
						(entitiesProperties) => entitiesProperties.activeTagsIds.length,
						(entitiesProperties) => getTradeTranslation(entitiesProperties).toLowerCase(),
					],
					['desc', 'asc']
				).map((entity: IProfession & { activeTagsIds: string[] }) => {
					const isSelected: boolean = selectedProfessions.some(
						(selectedEntity) => entity._id === selectedEntity._id
					);
					return (
						<ProfessionFilterCard
							key={entity._id}
							handleClick={isSelected ? () => removeProfession(entity) : () => addProfession(entity)}
							count={entity.activeTagsIds.length}
							isSelected={isSelected}
							profession={entity}
							projectId={projectId}
							additionalTestId={entity._id}
						/>
					);
				})}
			</ExpandableComponent>
			<ManagersFilterSection useActiveManagers defaultOpen />
			<EquipmentTypeFilterSection />
		</div>
	);
};

const mapStateToProps = ({ professionsReducer }) => ({
	activeProfessions: professionsReducer.activeProfessions,
	visibleProfessions: professionsReducer.visibleProfessions,
	currentProfessionsFilter: professionsReducer.filterList,
	selectedProfessions: professionsReducer.selectedProfessions,
	filteredProfessions: filterArrayByAnotherArray(
		professionsReducer.activeProfessions,
		professionsReducer.filteredProfessions,
		entitiesProperties.tradeGroup
	),
});

const mapDispatchToProps = (dispatch) => ({
	updateProfessionsFilter: (filterOption) => {
		dispatch(setProfessionsFilter({ filterList: filterOption }));
	},
	addProfession: (profession) => {
		dispatch(addOneSelectedProfession({ selectedProfession: profession }));
	},
	removeProfession: (profession) => {
		dispatch(removeOneSelectedProfession({ removedProfession: profession }));
	},
	handleResetProfessionsClick: () => {
		dispatch(resetSelectedProfessions());
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkForceTab);
