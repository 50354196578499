import { NotificationChannelTypes } from '../interfaces/NotificationChannelTypes.enum';
import { NotificationConfiguration } from '../interfaces/NotificationConfiguration';
import { NotificationTypes } from '../interfaces/NotificationTypes.enum';
import { sequenceProjectAnalysisProhibitedRoles } from './permissions.constants';
import { RoleType } from '../interfaces/RoleType.enum';

export enum PreDefinedFrequencies {
	HOURLY = 'HOURLY',
	DAILY = 'DAILY',
	WEEKLY = 'WEEKLY',
	DAILY_END_OF_DAY = 'DAILY_END_OF_DAY',
	WEEKLY_END_OF_DAY = 'WEEKLY_END_OF_DAY',
}

export const preDefinedFrequenciesMapping: { [key in PreDefinedFrequencies]: string } = {
	HOURLY: '0 * * * *',
	DAILY: '0 1 * * *',
	WEEKLY: '0 1 * * 0',
	DAILY_END_OF_DAY: '0 -1 * * *',
	WEEKLY_END_OF_DAY: '0 -1 * * 0',
};

export const notificationsConfiguration: { [key in NotificationTypes]: NotificationConfiguration } = {
	[NotificationTypes.DistressButton]: {
		notificationType: NotificationTypes.DistressButton,
		channels: [NotificationChannelTypes.IN_APP, NotificationChannelTypes.SMS, NotificationChannelTypes.PUSH],
		isDefaultEnabled: true,
		prohibitedRoles: [RoleType.VIEWER, RoleType.EXECUTIVE],
	},
	[NotificationTypes.SequenceProgressAnalysisReport]: {
		notificationType: NotificationTypes.SequenceProgressAnalysisReport,
		channels: [NotificationChannelTypes.IN_APP, NotificationChannelTypes.PUSH],
		defaultFrequency: preDefinedFrequenciesMapping.WEEKLY,
		isBanner: true,
		isDefaultEnabled: true,
		prohibitedRoles: sequenceProjectAnalysisProhibitedRoles,
	},
	[NotificationTypes.CreatedIssues]: {
		notificationType: NotificationTypes.CreatedIssues,
		channels: [NotificationChannelTypes.IN_APP, NotificationChannelTypes.PUSH],
		defaultFrequency: preDefinedFrequenciesMapping.DAILY_END_OF_DAY,
	},
	[NotificationTypes.IssueStatusUpdated]: {
		notificationType: NotificationTypes.IssueStatusUpdated,
		channels: [NotificationChannelTypes.IN_APP, NotificationChannelTypes.PUSH],
		isDefaultEnabled: true,
	},
	[NotificationTypes.AssignedIssue]: {
		notificationType: NotificationTypes.AssignedIssue,
		channels: [NotificationChannelTypes.IN_APP, NotificationChannelTypes.PUSH],
		isDefaultEnabled: true,
	},
	[NotificationTypes.DailyReport]: {
		notificationType: NotificationTypes.DailyReport,
		channels: [NotificationChannelTypes.IN_APP, NotificationChannelTypes.PUSH],
		defaultFrequency: preDefinedFrequenciesMapping.DAILY_END_OF_DAY,
	},
	[NotificationTypes.TagHealth]: {
		notificationType: NotificationTypes.TagHealth,
		channels: [NotificationChannelTypes.IN_APP, NotificationChannelTypes.PUSH],
		defaultFrequency: preDefinedFrequenciesMapping.WEEKLY_END_OF_DAY,
	},
	[NotificationTypes.AreaSequenceItemStatusUpdate]: {
		notificationType: NotificationTypes.AreaSequenceItemStatusUpdate,
		channels: [NotificationChannelTypes.IN_APP, NotificationChannelTypes.PUSH],
		defaultFrequency: preDefinedFrequenciesMapping.HOURLY,
		isDefaultEnabled: true,
	},
	[NotificationTypes.PlanningCreated]: {
		notificationType: NotificationTypes.PlanningCreated,
		channels: [NotificationChannelTypes.IN_APP, NotificationChannelTypes.PUSH],
	},
	[NotificationTypes.IssueCommentCreated]: {
		notificationType: NotificationTypes.IssueCommentCreated,
		channels: [NotificationChannelTypes.IN_APP, NotificationChannelTypes.PUSH],
		isDefaultEnabled: true,
	},
	[NotificationTypes.RestrictedArea]: {
		notificationType: NotificationTypes.RestrictedArea,
		channels: [NotificationChannelTypes.IN_APP, NotificationChannelTypes.SMS, NotificationChannelTypes.PUSH],
	},
};
