import classes from './styles.module.scss';
import { ActivitySequenceMatrixMode } from '@shared/interfaces/ActivitySequenceMatrixMode.enum';
import { IMenuItem, TrusstorMenu } from '@shared/components/TrusstorMenu/TrusstorMenu';
import { ActivitySequenceMatrix } from '@src/Components/WorkPlan/ActivityProgressTracker/components/ActivitySequenceMatrix/ActivitySequenceMatrix';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { accessLevelService, requestService, translationService, workplanLocalStorageService } from '@src/index';
import { useDispatch, useSelector } from 'react-redux';
import { selectProjectId } from '@store/slices/project.slice';
import { ISequence } from '@shared/interfaces/ISequence';
import { groupBy, uniq } from 'lodash';
import { WorkplanContext } from '../WorkPlan';
import { IMergedAreaSequenceItem } from '@interfaces/IMergedAreaSequenceItem';
import { SimpleToggle } from '@src/Components/SimpleToggle/SimpleToggle';
import { OverviewTable } from './OverviewTable/OverviewTable';
import { ExportSection } from '@src/Components/Pages/Workplan/ProgressTrackerTab/ExportSection/ExportSection';
import { TrusstorIcon } from '@src/Components/TrusstorIcon/TrusstorIcon';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import {
	ActivitySequenceItemMenuActions,
	getCellMenuItemsByStatus,
	getMultiSelectedCellsMenuItems,
} from '@src/Components/WorkPlan/ActivityProgressTracker/components/ActivitySequenceMatrix/cellComponents/ActivitySequenceItemCell/activitySequence.menuItems';
import { AreaSequenceItemStatus } from '@shared/interfaces/AreaSequenceItemStatus.enum';
import { IActivitySequenceItemCellData } from '@src/Components/WorkPlan/ActivityProgressTracker/components/ActivitySequenceMatrix/cellComponents/ActivitySequenceItemCell/ActivitySequenceItemCell';
import { updateBulkAreaSequenceItems } from '@src/apis/areaSequenceItems.api';
import { errorSnackbar } from '@utils/snackbar.utils';
import { ActionConfirmationDialog } from '@src/Components/WorkplanWidgetComponents/ActionConfirmationDialog/ActionConfirmationDialog';
import { ActivityGroupStatus } from '@shared/interfaces/ActivityGroupStatus.enum';
import { exportSequenceItemsToExcel } from '@utils/sequence.utils';
import { ILoggedUser, IProfession, IProject } from '@interfaces/index';
import { IRootState } from '@store/slices';
import { SequenceSelectionSection } from '@src/Components/Pages/Workplan/ProgressTrackerTab/SequenceSelectionSection/SequenceSelectionSection';
import {
	batchWorkplanMatrixInteraction_BI,
	progressTrackerExpand_BI,
	progressTrackerMinimize_BI,
	progressTrackerZoomIn_BI,
	progressTrackerZoomOut_BI,
} from '@utils/bi.utils';
import { ProfessionsFilterSection } from '@src/Components/FilterSections/ProfessionsFilterSection';
import { SIDE_MAIN_COMPONENT_CLASS_NAME } from '@src/constants';
import { ToggleSection } from '@src/Components/WorkPlan/ToggleSection';
import { FiltersToggle } from '@src/Components/Pages/Workplan/FiltersToggle/FiltersToggle';
import { IconSize } from '@shared/components/TrusstorIconShared/TrusstorIconShared';
import { SequenceOptionsMenu } from '@src/Components/SequenceOptionsMenu/SequenceOptionsMenu';
import { useIsFeatureFlagEnabled } from '@shared/hooks/useFeatureFlag';
import { useHistory, useLocation } from 'react-router-dom';
import { FeatureFlagNamesEnum } from '@shared/constants/featureFlags.enum';
import { useOutsideClickWithCallback } from '@shared/hooks/custom.hooks';
import { selectLoggedUser } from '@store/slices/login.slice';
import { IRequestHeaders } from '@shared/interfaces/IRequsetHeaders';
import { useAreaSequenceItemsStatusUpdate } from '@src/hooks/AreaSequenceItemsStatusUpdate.hooks';
import { ZoomController } from '@src/Components/ZoomController/ZoomController';
import classnames from 'classnames';
import { ITrusstorIconButtonStyle } from '@shared/components/buttons/TrusstorIconButton/TrusstorIconButtonDeprecated';
import { usePrevious } from '@src/hooks/custom.hooks';
import { TrusstorIconButtonV2 } from '@shared/components/buttons/TrusstorIconButtonV2/TrusstorIconButtonV2';

export enum TableModeEnum {
	AREAS = 'areas',
	FLOORS = 'floors',
}

const DEFAULT_ZOOM_PERCENTAGE: number = 100;
const DEFAULT_ZOOM_PERCENTAGE_ON_EXPANDED: number = 80;
const ZOOM_STEP: number = 10;
const MAX_NUMBER_OF_SINGLE_UPDATE_CELLS: number = 300;

interface IProgressTrackerTabProps {
	planningMode: boolean;
}

interface IProgressTrackerTabContext {
	showOnlyStarred: boolean;
	multiSelectedCells: IActivitySequenceItemCellData[];
	setMultiSelectedCells: (value: IActivitySequenceItemCellData[]) => void;
	updateAndRecreateTable: (updatedAreaSequenceItems: IMergedAreaSequenceItem[]) => void;
	tableView: TableModeEnum;
	setTableView: (value: TableModeEnum) => void;
	scheduleAreaSequenceItemsForStatusUpdate: (
		newStatus: AreaSequenceItemStatus,
		cells: IActivitySequenceItemCellData[]
	) => void;
	sendCurrentBulkStatusUpdates: () => void;
}

export const ProgressTrackerTabContext = createContext<IProgressTrackerTabContext | undefined>(undefined);

export const ProgressTrackerTab = (props: IProgressTrackerTabProps) => {
	const {
		selectedSequenceId,
		setSelectedSequenceId,
		areaSequenceItemsByFloor,
		matrix: { recreateTable, setTables, tables },
		fetchMoreAreaSequenceItems,
		updateAreaSequenceItem,
		isTableFinishToRender,
		setShouldMatrixFetchAllFloors,
		sequences,
		setSequences,
		isInitialLoading,
		setShowFilters,
		showFilters,
		hasProfessionFilter,
	} = useContext(WorkplanContext)!;

	const location = useLocation();

	const isFullPage: boolean = location.pathname.includes('fullPage');

	const getDefaultZoomValue = (fullPageMode: boolean): number =>
		fullPageMode ? DEFAULT_ZOOM_PERCENTAGE_ON_EXPANDED : DEFAULT_ZOOM_PERCENTAGE;

	const project: IProject = useSelector((state: IRootState) => state.projectReducer.workingProject)!;
	const projectId: string = useSelector(selectProjectId)!;
	const dispatch = useDispatch();
	const [tableView, setTableView] = useState<TableModeEnum>(TableModeEnum.AREAS);
	const [multiSelectedCells, setMultiSelectedCells] = useState<IActivitySequenceItemCellData[]>([]);
	const [showOnlyStarred, setShowOnlyStarred] = useState<boolean>(workplanLocalStorageService.getStarredViewType());
	const [showWarningDialog, setShowWarningDialog] = useState<boolean>(false);
	const [targetUpdateStatus, setTargetUpdateStatus] = useState<AreaSequenceItemStatus | undefined>(undefined);
	const [isExpanded, setIsExpanded] = useState<boolean>(isFullPage);
	const [zoomPercentage, setZoomPercentage] = useState<number>(getDefaultZoomValue(isFullPage));
	const tableRef = useRef(null);
	const [showDeleteSequenceConfirmation, setShowDeleteSequenceConfirmationDialog] = useState<boolean>(false);
	const [isCreatingNewSequence, setIsCreatingNewSequence] = useState<boolean>(false);
	const selectedProfessions: IProfession[] = useSelector(
		(state: IRootState) => state.professionsReducer.selectedProfessions
	);
	const prevMultiSelectedCellsCount = usePrevious(multiSelectedCells.length);

	const batchedCellsStatusUpdateCallback = async (
		selectedCells: IActivitySequenceItemCellData[],
		status: AreaSequenceItemStatus
	) => {
		await multiCellsStatusUpdateAction(selectedCells, status);
	};

	const { scheduleAreaSequenceItemsForStatusUpdate, sendCurrentBulkStatusUpdates } = useAreaSequenceItemsStatusUpdate(
		batchedCellsStatusUpdateCallback
	);

	const loggedUser: ILoggedUser | null = useSelector(selectLoggedUser);
	const loggedUserRequestHeadersRef = useRef<IRequestHeaders>({});

	useEffect(() => {
		setIsExpanded(isFullPage);
		setZoomPercentage(getDefaultZoomValue(isFullPage));
	}, [isFullPage]);

	useEffect(() => {
		if (props.planningMode) {
			onClearSelectedCells();
		}
	}, [props.planningMode]);

	useEffect(() => {
		if (loggedUser) {
			loggedUserRequestHeadersRef.current = requestService.getDefaultHeaders();
		}
	}, [loggedUser, projectId]);

	useEffect(() => {
		setShouldMatrixFetchAllFloors(tableView === TableModeEnum.FLOORS);
	}, [tableView]);

	const handleSequenceIdChange = useCallback((sequence: ISequence) => {
		setSelectedSequenceId(sequence._id);
	}, []);

	useEffect(() => {
		const shouldShowMultiSelectedCells: boolean =
			multiSelectedCells.length > MAX_NUMBER_OF_SINGLE_UPDATE_CELLS &&
			(!prevMultiSelectedCellsCount || prevMultiSelectedCellsCount <= MAX_NUMBER_OF_SINGLE_UPDATE_CELLS);
		if (shouldShowMultiSelectedCells) {
			showMultiSelectedCellsWarningSnackbar();
		}
	}, [multiSelectedCells.length]);

	const showMultiSelectedCellsWarningSnackbar = () => {
		errorSnackbar(dispatch, translationService.get('multiSelectedCellsWarning'));
	};

	const selectedStatuses: AreaSequenceItemStatus[] = useMemo(
		() => uniq(multiSelectedCells.map((cell) => cell.status)),
		[multiSelectedCells]
	);

	const handleStarredToggleChange = (): void => {
		workplanLocalStorageService.setStarredViewType(!showOnlyStarred);
		setShowOnlyStarred((prevState) => !prevState);
	};

	const selectedSequence: ISequence | undefined = sequences.find(
		(sequence: ISequence) => sequence._id == selectedSequenceId
	);

	const onClearSelectedCells = () => {
		if (showWarningDialog) {
			return;
		}
		if (multiSelectedCells.length > 0) {
			setMultiSelectedCells([]);
		}
	};
	const MultiSelectCellsHeader = ({ numberOfSelectedCells }: { numberOfSelectedCells: number }) => {
		const isMaxNumberExceeded: boolean = useMemo(
			() => numberOfSelectedCells > MAX_NUMBER_OF_SINGLE_UPDATE_CELLS,
			[numberOfSelectedCells]
		);
		const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
		return (
			<div className={classes.multiSelectHeaderContainer}>
				<div className={classes.selectedTextSection}>
					<p
						className={classnames(classes.selectedText, {
							[classes.exceededThreshold]: isMaxNumberExceeded,
						})}
					>
						{numberOfSelectedCells}/{MAX_NUMBER_OF_SINGLE_UPDATE_CELLS} {translationService.get('selected')}
					</p>
					<div className={classes.closeIconContainer} onClick={() => onClearSelectedCells()}>
						<TrusstorIcon iconName={IconNames.close} size={IconSize.SMALL} />
					</div>
				</div>
				<div
					className={classnames(classes.statusSelect, { [classes.disabled]: isMaxNumberExceeded })}
					onClick={() => setIsMenuOpen((prevValue: boolean) => !prevValue)}
				>
					{translationService.get('status')}
					<TrusstorIcon iconName={IconNames.chevronDown} />
					{isMenuOpen && (
						<div className={classes.statusUpdateMenu}>
							<TrusstorMenu
								width={150}
								items={getMultiSelectedCellsMenuItems(selectedStatuses, multiCellsStatusUpdate)}
							/>
						</div>
					)}
				</div>
			</div>
		);
	};

	const recreateUpdatedStatusTables = (updatedAreaSequenceItems: IMergedAreaSequenceItem[]) => {
		const updatedAreaSequenceItemsByFloor = groupBy(
			updatedAreaSequenceItems,
			(updatedAreaSequenceItem: IMergedAreaSequenceItem) => {
				return updatedAreaSequenceItem.area.floorId;
			}
		);

		Object.keys(updatedAreaSequenceItemsByFloor).forEach((floorId: string) => {
			recreateTable(floorId, updatedAreaSequenceItemsByFloor[floorId]);
		});
	};

	const updateAndRecreateTable = (updatedAreaSequenceItems: IMergedAreaSequenceItem[]) => {
		updatedAreaSequenceItems.forEach((updatedAreaSequenceItem: IMergedAreaSequenceItem) => {
			updateAreaSequenceItem(updatedAreaSequenceItem);
		});

		recreateUpdatedStatusTables(updatedAreaSequenceItems);
	};
	const setOptimisticMultiCellsUpdate = (
		updatedCellsData: IActivitySequenceItemCellData[],
		status: AreaSequenceItemStatus
	) => {
		const updatedAreaSequenceItems: IMergedAreaSequenceItem[] = updatedCellsData.map(
			(cellData: IActivitySequenceItemCellData) => {
				return {
					...cellData,
					status,
				};
			}
		);

		updateAndRecreateTable(updatedAreaSequenceItems);
	};

	const isCellUpdatable = (
		areaSequenceItem: IMergedAreaSequenceItem,
		updatedStatus: AreaSequenceItemStatus
	): boolean => {
		const cellStatus: AreaSequenceItemStatus = areaSequenceItem.status;
		const cellMenuItems: IMenuItem[] = getCellMenuItemsByStatus(cellStatus);
		const isCellStatusUpdatable: boolean = cellMenuItems.some(
			(menuItem: IMenuItem) => menuItem.key === updatedStatus
		);
		return isCellStatusUpdatable;
	};

	const shouldShowWarningDialogForMultiCellsUpdate = (updatedStatus?: AreaSequenceItemStatus): boolean => {
		const shouldShowWarningDialog: boolean = multiSelectedCells.some((cell: IActivitySequenceItemCellData) => {
			if (
				updatedStatus !== AreaSequenceItemStatus.complete &&
				updatedStatus !== AreaSequenceItemStatus.inProgress &&
				cell.groupStatus === ActivityGroupStatus.complete
			) {
				return true;
			}
		});
		return shouldShowWarningDialog;
	};

	const scheduleMultiSelectedCellsForStatusUpdate = (status?: AreaSequenceItemStatus) => {
		if (!status) {
			return;
		}
		const multiSelectedCellsCopy: IActivitySequenceItemCellData[] = [...multiSelectedCells];
		const updatableCellsData: IActivitySequenceItemCellData[] = multiSelectedCells.filter((selectedCell) =>
			isCellUpdatable(selectedCell, status)
		);
		setOptimisticMultiCellsUpdate(updatableCellsData, status);
		setMultiSelectedCells([]);
		scheduleAreaSequenceItemsForStatusUpdate(status, multiSelectedCellsCopy);
	};

	const multiCellsStatusUpdate = async (
		actionType: ActivitySequenceItemMenuActions,
		status?: AreaSequenceItemStatus
	) => {
		const shouldShowWarningDialog: boolean = shouldShowWarningDialogForMultiCellsUpdate(status);
		if (shouldShowWarningDialog) {
			setShowWarningDialog(true);
			setTargetUpdateStatus(status);
			return;
		}
		scheduleMultiSelectedCellsForStatusUpdate(status);
	};

	const multiCellsStatusUpdateAction = async (
		multiSelectedCells: IActivitySequenceItemCellData[],
		status?: AreaSequenceItemStatus
	) => {
		if (!status) {
			return;
		}
		const currentCellsStatuses: { [key: string]: AreaSequenceItemStatus } = multiSelectedCells.reduce(
			(acc: { [key: string]: AreaSequenceItemStatus }, cell: IActivitySequenceItemCellData) => {
				acc[cell._id!] = cell.status;
				return acc;
			},
			{}
		);
		const updatableCellsData: IActivitySequenceItemCellData[] = multiSelectedCells.filter((selectedCell) =>
			isCellUpdatable(selectedCell, status)
		);
		const multiSelectedCellsIds: string[] = updatableCellsData.map(
			(cell: IActivitySequenceItemCellData) => cell._id!
		);
		setOptimisticMultiCellsUpdate(updatableCellsData, status);

		let failedIds: string[] = [];
		try {
			const result: {
				failedIds: string[];
				areaSequenceItems: IMergedAreaSequenceItem[];
			} = await updateBulkAreaSequenceItems(multiSelectedCellsIds, status, loggedUserRequestHeadersRef.current);
			failedIds = result.failedIds;
			if (failedIds.length < multiSelectedCellsIds.length) {
				const selectedSequence: ISequence | undefined = sequences.find(
					(sequence: ISequence) => sequence._id == selectedSequenceId
				);
				batchWorkplanMatrixInteraction_BI(status, projectId, updatableCellsData, selectedSequence?.name);
			}
			updateAndRecreateTable(result.areaSequenceItems);
		} catch (err) {
			failedIds = multiSelectedCellsIds;
		}
		if (failedIds.length > 0) {
			errorSnackbar(dispatch, translationService.get('statusUpdateFailed'));
			//revert failed cells to previous statuses
			const updatedAreaSequenceItems: IMergedAreaSequenceItem[] = updatableCellsData.map(
				(cellData: IActivitySequenceItemCellData) => {
					return {
						...cellData,
						status: currentCellsStatuses[cellData._id!],
					};
				}
			);

			updateAndRecreateTable(updatedAreaSequenceItems);
		}
		setShowWarningDialog(false);
	};

	const handleKeyPress = (event) => {
		if (event.key === 'Escape') {
			if (multiSelectedCells.length) {
				onClearSelectedCells();
			} else if (isExpanded) {
				onExpandClick('ESCAPE');
			}
		}
	};

	useOutsideClickWithCallback(tableRef, onClearSelectedCells);

	const deleteSequence = async () => {
		const sequencesFromState = sequences;
		const selectedSequenceIdFromState = selectedSequenceId;
		setSequences((prevState) => prevState.filter((sequence) => sequence._id !== selectedSequenceIdFromState));
		setSelectedSequenceId(
			(sequencesFromState &&
				sequencesFromState.find((sequence) => sequence._id !== selectedSequenceIdFromState)?._id) ||
				undefined
		);
		try {
			await requestService.delete(`/activities/sequences/${selectedSequenceIdFromState}`);
		} catch (e) {
			setSequences(sequencesFromState);
			setSelectedSequenceId(selectedSequenceIdFromState);
			console.log(e);
			errorSnackbar(dispatch, translationService.get('deleteSequenceFailed'));
		}
	};

	const toggleFilterSection = () => {
		setShowFilters((prev) => !prev);
	};

	const hasActiveFilters: boolean = hasProfessionFilter || showOnlyStarred;

	const history = useHistory();

	const onExpandClick = (source: 'ESCAPE' | 'EXIT' | 'MINIMIZE') => {
		const prevIsExpanded: boolean = isExpanded;
		setIsExpanded((prev) => !prev);
		setZoomPercentage(getDefaultZoomValue(!prevIsExpanded));
		prevIsExpanded ? progressTrackerMinimize_BI(source) : progressTrackerExpand_BI();
		history.push(`/${projectId}/workplan/${prevIsExpanded ? '' : 'fullPage'}`);
	};

	const onZoomIn = () => {
		progressTrackerZoomIn_BI(zoomPercentage);
		setZoomPercentage((prev) => prev + ZOOM_STEP);
	};

	const onZoomOut = () => {
		progressTrackerZoomOut_BI(zoomPercentage);
		setZoomPercentage((prev) => prev - ZOOM_STEP);
	};

	const isZoomControllerEnabled: boolean = useIsFeatureFlagEnabled(
		FeatureFlagNamesEnum.progressTrackerZoomController
	);

	return (
		<ProgressTrackerTabContext.Provider
			value={{
				showOnlyStarred,
				setTableView,
				multiSelectedCells,
				setMultiSelectedCells,
				updateAndRecreateTable,
				tableView,
				scheduleAreaSequenceItemsForStatusUpdate,
				sendCurrentBulkStatusUpdates,
			}}
		>
			<div
				className={classnames(classes.progressTrackerContainer, { [classes.fullPage]: isExpanded })}
				tabIndex={0}
				onKeyDown={(event) => handleKeyPress(event)}
				ref={tableRef}
			>
				<section className={classes.progressTrackerTopSection}>
					<div className={classes.sequencesSelection}>
						{isExpanded && (
							<TrusstorIconButtonV2
								onClick={() => onExpandClick('EXIT')}
								style={ITrusstorIconButtonStyle.GHOST}
								iconName={IconNames.close}
							/>
						)}
						<FiltersToggle
							isOpen={showFilters}
							hasActiveFilters={hasActiveFilters}
							toggleFilterSection={toggleFilterSection}
						/>
						{multiSelectedCells.length > 0 &&
						getMultiSelectedCellsMenuItems(selectedStatuses, multiCellsStatusUpdate).length ? (
							<MultiSelectCellsHeader numberOfSelectedCells={multiSelectedCells.length} />
						) : (
							<SequenceSelectionSection
								setIsCreatingNewSequence={setIsCreatingNewSequence}
								isCreatingNewSequence={isCreatingNewSequence}
								onChange={handleSequenceIdChange}
								sequences={sequences}
								selectedSequence={selectedSequence}
							/>
						)}
					</div>
					<div className={classes.absoluteCenter}>
						<ToggleSection
							value={tableView}
							firstOption={TableModeEnum.AREAS}
							firstAction={() => {
								setTableView(TableModeEnum.AREAS);
							}}
							secondOption={TableModeEnum.FLOORS}
							secondAction={() => {
								setTableView(TableModeEnum.FLOORS);
							}}
							width={'160px'}
							height={'32px'}
						/>
					</div>
					<div className={classes.endHeaderSection}>
						<div className={classes.endHeaderSection}>
							{isZoomControllerEnabled && (
								<ZoomController
									isExpanded={isExpanded}
									onExpand={() => onExpandClick('MINIMIZE')}
									zoomPercentage={zoomPercentage}
									onZoomIn={onZoomIn}
									onZoomOut={onZoomOut}
									maxZoom={100}
									minZoom={50}
								/>
							)}
							<ExportSection
								onExport={async () => exportSequenceItemsToExcel(project, selectedProfessions)}
							/>
							{tableView === TableModeEnum.AREAS && (
								<>
									{accessLevelService.hasAccess('workplanActivityActions') && (
										<SequenceOptionsMenu
											createNewSequence={() => setIsCreatingNewSequence(true)}
											deleteSequence={() => setShowDeleteSequenceConfirmationDialog(true)}
										/>
									)}
								</>
							)}
						</div>
					</div>
				</section>

				<div
					className={classes.progressTrackerBody}
					style={{
						transform: `scale(${zoomPercentage / 100})`,
						transformOrigin: translationService.getIsRtl() ? '100% 0' : '0 0',
						width: `calc(100% / ${zoomPercentage / 100}`,
						minHeight: `calc((100% - 50px) / ${zoomPercentage / 100})`,
					}}
					id={'progressTrackerBodyId'}
				>
					{showFilters && (
						<div className={`${SIDE_MAIN_COMPONENT_CLASS_NAME}`} style={{ boxShadow: 'none' }}>
							{tableView === TableModeEnum.AREAS && (
								<div className={classes.starredSection}>
									<div className={classes.starredText}>
										{translationService.get('onlyStarredTasks')}
									</div>
									<SimpleToggle checked={showOnlyStarred} onChange={handleStarredToggleChange} />
								</div>
							)}
							<ProfessionsFilterSection defaultOpen={true} dontResetSelectedProfessions />
						</div>
					)}

					{tableView === TableModeEnum.AREAS ? (
						<ActivitySequenceMatrix
							hasProfessionFilter={hasProfessionFilter}
							areaSequenceItemsByFloor={areaSequenceItemsByFloor}
							updateAreaSequenceItem={updateAreaSequenceItem}
							fetchMoreAreaSequenceItemsByCurrentFloor={fetchMoreAreaSequenceItems}
							mode={
								props.planningMode
									? ActivitySequenceMatrixMode.schedule
									: ActivitySequenceMatrixMode.display
							}
							isTableFinishToRender={isTableFinishToRender}
							showOnlyStarred={showOnlyStarred}
							showToc={!isExpanded && zoomPercentage === 100}
						/>
					) : (
						<OverviewTable
							hasProfessionFilter={hasProfessionFilter}
							areaSequenceItemsByFloor={areaSequenceItemsByFloor}
							isInitialLoading={isInitialLoading}
						/>
					)}
				</div>
			</div>
			<ActionConfirmationDialog
				title={translationService.get('areYouSure')}
				text={translationService.get('markDoneWarning')}
				mainButtonAction={() => {
					setShowWarningDialog(false);
					scheduleMultiSelectedCellsForStatusUpdate(targetUpdateStatus);
				}}
				secondaryButtonAction={() => setShowWarningDialog((prev) => !prev)}
				show={showWarningDialog}
				onClose={() => setShowWarningDialog((prev) => !prev)}
			/>
			<ActionConfirmationDialog
				title={translationService.get('deleteSequenceConfirmation')}
				secondaryButtonAction={() => {
					setShowDeleteSequenceConfirmationDialog(false);
				}}
				onClose={() => setShowWarningDialog(false)}
				show={showDeleteSequenceConfirmation}
				mainButtonAction={() => {
					setShowDeleteSequenceConfirmationDialog(false);
					deleteSequence();
				}}
			/>
		</ProgressTrackerTabContext.Provider>
	);
};
