import React from 'react';
import { ITrusstorButtonType, TrusstorButton } from '@shared/components/buttons/TrusstorButton/TrusstorButton';
import logoLoginPage from '../../../assets/login/logoLoginPage.svg';
import classes from './styles.module.scss';
import classnames from 'classnames';

interface IAuthPageProps {
	children: React.ReactNode;
	title: string;
	showLoaderButton?: boolean;
	buttonText: string;
	subLink?: {
		text: string;
		onClick: (...args: any[]) => void;
	};
	errorText?: string;
	onButtonClick: (...args: any[]) => void;
	submitOnEnter?: boolean;
}

export const AuthPage = (props: IAuthPageProps) => {
	const onKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (event.key === 'Enter') {
			props.onButtonClick();
		}
	};

	return (
		<div className={classnames(classes.loginPage)} onKeyDown={onKeyPress}>
			<img alt="" className={classes.headline} src={logoLoginPage} />
			<div className={classes.inputSection}>
				<div className={classes.inputSectionData}>
					<div className={classes.subHeadlines}>
						<div className={classes.firstSubHeadline} data-testid="auth-title">
							{props.title}
						</div>
					</div>
					<div className={classes.inputs}>{props.children}</div>
					<div data-testid="auth-btn">
						<TrusstorButton
							text={props.buttonText}
							handleClick={props.onButtonClick}
							className={classes.formButton}
							showLoader={props.showLoaderButton}
							buttonType={ITrusstorButtonType.PRIMARY}
						/>
						{props.errorText && (
							<div className={classes.ErrorContainer}>
								<p className={classes.ErrorText} data-testid="auth-error-msg">
									{props.errorText}
								</p>
							</div>
						)}
					</div>
					{props.subLink && (
						<p onClick={props.subLink.onClick} className={classes.subText} data-testid="auth-sub-text">
							{props.subLink.text}
						</p>
					)}
				</div>
			</div>
		</div>
	);
};
