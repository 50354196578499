import React from 'react';
import classes from './styles.module.scss';
import { TagsAnalysisVsHours } from '@src/Components/Pages/Reports/ProfessionAnalysisReportPageV2/Components/TagsAnalysisVsHours/TagsAnalysisVsHours';
import { TagsLocationDistribution } from '../TagsLocationDistribution/TagsLocationDistribution';

interface ITagAnalysisSectionProps {
	currentProfessionId: string;
	updatePeriod: (period: [Date, Date]) => void;
}

export const TagsAnalysisSection = (props: ITagAnalysisSectionProps) => {
	return (
		<div className={classes.graphsContainerWrapper}>
			<div className={classes.graphsContainer}>
				<div className={classes.ContractorAnalysisGraphCard_container}>
					<TagsAnalysisVsHours
						currentProfessionId={props.currentProfessionId}
						updatePeriod={props.updatePeriod}
					/>
				</div>
				<div className={classes.ContractorAnalysisGraphCard_container}>
					<TagsLocationDistribution professionId={props.currentProfessionId} />
				</div>
			</div>
		</div>
	);
};
