import {
	IActivitiesFloorReport,
	IActivitiesProfessionReport,
	IActivitiesReport,
	IDailyProductivityFloorReport,
	IExtendedShortReportSafetyEvent,
	IPeriodicProductivityFloorReport,
	IProductivityProfessionReport,
	IProductivityReport,
	IProfession,
	IProfessionProductivityAnalysisReportData,
	ISafetyReport,
} from '../../../interfaces';
import { exportService, requestService, translationService } from '../../../index';
import { DATE_PICKER_TOGGLE, reportsPageConsts } from '../../../constants';
import {
	IDateProfessionProductivityAnalysisData,
	IProfessionsAnalysisReport,
} from '../../../interfaces/IProfessionReport';
import {
	convertTimeStampToDateByFormatAndTz,
	getDateByLocaleFormat,
	getMinDate,
	getNumberOfDaysWithoutRestDays,
	getProjectDateWithBrowserTZOffset,
	getTimezoneFormattedDate,
	getTimezoneStartOfDate,
	getWeekDay,
	getWorkDaysNumber,
} from '../../../utils/dateUtils';
import moment from 'moment';
import { IRow, ISheets } from '../../../services';
import { convertSafetyReportToSafetyEventsArray } from '../../../utils/reportUtils';
import { filterArrayByAnotherArray, sumByField } from '../../../utils/array.util';
import { getProfessionDisplayText } from '../../../utils/professions.utils';
import { IEventsPerHour } from '../../../interfaces/IEventsPerHour';
import { compact, countBy, groupBy, isEmpty, sumBy, uniq } from 'lodash';
import { othersTradeGroup } from '@shared/constants/professions.constants';
import {
	getAvgWorkersFromUniqueTagIdsPerDay,
	getProfessionAvgWorkersNumber,
	getProfessionTotalWorkDays,
	getTotalUniqueWorkersInProfessionProductivityReport,
} from '../../../utils/reports.utils';
import { exportCombinedReportToExcel_BI } from '../../../utils/bi.utils';
import {
	IRoundedProductivityAreaReport,
	IRoundedProductivityReport,
} from '../../../interfaces/IRoundedProductivityReport';
import { dateFormats } from '@shared/constants/formats.constants';
import { IFloor } from '@shared/interfaces/IFloor';
import { getProductRoundedHoursFromHours, getProductRoundedHoursFromMs } from '@shared/utils/roundNumbers.utils';

export const getProfessionDisplayTextFromProfessionId = (professionId: string, professions: IProfession[]): string => {
	const fullProfession: IProfession = professions.find((stateProfession) => stateProfession._id === professionId)!;
	return getProfessionDisplayText(fullProfession);
};

export const exportCombinedReportToExcel = async (
	projectTZ,
	toggleStateDatePicker,
	visibleDateTimestamp,
	visiblePeriodTimestamps,
	productivityReport,
	activityReportRounded,
	workDayHours
): Promise<void> => {
	exportCombinedReportToExcel_BI(
		visiblePeriodTimestamps,
		projectTZ,
		toggleStateDatePicker === DATE_PICKER_TOGGLE.date
	);

	const formattedReportDates: string =
		toggleStateDatePicker === DATE_PICKER_TOGGLE.date
			? getDateByLocaleFormat(moment.tz(visibleDateTimestamp, projectTZ), projectTZ)
			: `${getDateByLocaleFormat(
					moment.tz(visiblePeriodTimestamps[0], projectTZ),
					projectTZ
			  )} - ${getDateByLocaleFormat(moment.tz(visiblePeriodTimestamps[1], projectTZ), projectTZ)}`;
	const sheets: ISheets = {};
	const reportDaysWithoutRestDays: number = getNumberOfDaysWithoutRestDays(
		visiblePeriodTimestamps[0],
		visiblePeriodTimestamps[1] ?? visibleDateTimestamp[0],
		workDayHours
	);
	const FILE_NAME: string = `${translationService.get('executiveSummary')} ${formattedReportDates} - Trusstor`;
	const HEADLINE: string = translationService.get('executiveSummary');
	const professionsSheetRows: IRow[] = generateProfessionsSheetRows(
		productivityReport,
		reportDaysWithoutRestDays,
		toggleStateDatePicker
	);
	const floorsSheetRows: IRow[] = generateFloorsSheetRows(productivityReport, toggleStateDatePicker);
	const activitiesSheetRows: IRow[] = generateActivitiesSheetRows(projectTZ, activityReportRounded, workDayHours);
	sheets[translationService.get('analyzeProductivityWorkers')] = professionsSheetRows;
	sheets[translationService.get('analyzeProductivitySpread')] = floorsSheetRows;
	sheets[translationService.get('activities')] = activitiesSheetRows;
	await exportService.exportMultipleSheetsToExcel(sheets, FILE_NAME, HEADLINE, formattedReportDates);
};

export const exportAdminReportToExcel = async (
	tz: string,
	projectId,
	visiblePeriodTimestamps,
	visibleDateTimestamp,
	toggleStateDatePicker,
	roundedProductivityReport,
	activityReportRounded,
	visibleProfessions,
	floorsList,
	workingProfessions,
	safetyReport,
	professionReport
): Promise<void> => {
	const formattedReportDates: string =
		toggleStateDatePicker === DATE_PICKER_TOGGLE.period
			? `${getDateByLocaleFormat(moment.tz(visiblePeriodTimestamps[0], tz), tz)} - ${getDateByLocaleFormat(
					moment.tz(visiblePeriodTimestamps[1], tz),
					tz
			  )}`
			: `${getDateByLocaleFormat(moment.tz(visibleDateTimestamp, tz), tz)}`;

	const sheets = {};
	const numberOfReportDays: number =
		toggleStateDatePicker === DATE_PICKER_TOGGLE.date
			? 0
			: moment(visiblePeriodTimestamps[1]).diff(moment(visiblePeriodTimestamps[0]), 'd');
	const HEADLINE: string = translationService.get('combinedAdminReport');
	const FILE_NAME: string = `${HEADLINE} ${formattedReportDates}`;
	const isPeriodicPicker: boolean = toggleStateDatePicker === DATE_PICKER_TOGGLE.period;

	const totalActualWorkHoursRows: IRow[] = generateTotalWorkingHoursRows(
		roundedProductivityReport,
		numberOfReportDays
	);
	const totalWorkingHoursPerTradeAndFloorRows: IRow[] = getTotalWorkingHoursPerTradeAndFloor(
		roundedProductivityReport,
		floorsList
	);
	const workersPerProfessionPerDateRows: IRow[] = await getWorkersPerProfessionPerDateRows(
		projectId,
		tz,
		visiblePeriodTimestamps,
		visibleDateTimestamp,
		isPeriodicPicker,
		workingProfessions
	);
	const startAndEndTimeRows: IRow[] = await getDailyProductivityReportsForPeriodRows(
		tz,
		projectId,
		professionReport,
		workingProfessions,
		visiblePeriodTimestamps
	);
	const activitiesNumberPerProfessionRow = getActivitiesNumberPerProfessionRow(activityReportRounded);
	const dailyActivitiesRows = await getDailyActivitiesRows(
		tz,
		projectId,
		visiblePeriodTimestamps,
		workingProfessions,
		visibleDateTimestamp,
		isPeriodicPicker
	);
	const safetyEventsByFloors = getSafetyEventsByFloorsRows(safetyReport, visibleProfessions, floorsList);
	const safetyEventsByProfession = await getSafetyEventsByProfessionsRows(
		safetyReport,
		projectId,
		visibleProfessions,
		floorsList,
		workingProfessions
	);
	const safetyEventsByHour = getSafetyEventsByHourRows(safetyReport, visibleProfessions, floorsList);
	sheets[translationService.get('totalWorkHoursForProfessionInPeriod')] = totalActualWorkHoursRows;
	sheets[translationService.get('totalWorkingHoursPerProfessionAndFloor')] = totalWorkingHoursPerTradeAndFloorRows;
	sheets[translationService.get('workersPerProfessionPerDate')] = workersPerProfessionPerDateRows;
	sheets[translationService.get('startAndEndTime')] = startAndEndTimeRows;
	sheets[translationService.get('activitiesNumberPerProfession')] = activitiesNumberPerProfessionRow;
	sheets[translationService.get('dailyActivities')] = dailyActivitiesRows;
	sheets[translationService.get('safetyEventsByFloor')] = safetyEventsByFloors;
	sheets[translationService.get('safetyEventsByProfessions')] = safetyEventsByProfession;
	sheets[translationService.get('safetyEventsByHour')] = safetyEventsByHour;
	await exportService.exportMultipleSheetsToExcel(sheets, FILE_NAME, HEADLINE, formattedReportDates);
};

const filterSafetyEventReportByVisibleProfessions = (
	safetyReports: IExtendedShortReportSafetyEvent[],
	visibleProfessions: IProfession[]
) => {
	if (!safetyReports || visibleProfessions.length === 0) {
		return safetyReports;
	}

	return safetyReports.filter((safetyReport: any) =>
		visibleProfessions.some((profession: any) => {
			return profession.tradeId === safetyReport.profession.tradeId;
		})
	);
};

const getVisibleEvents = (safetyReport: ISafetyReport, visibleProfessions: IProfession[], floorsList: IFloor[]) => {
	const safetyEventsReport: IExtendedShortReportSafetyEvent[] = convertSafetyReportToSafetyEventsArray(safetyReport);
	const visibleSafetyEventsByProfessions = filterSafetyEventReportByVisibleProfessions(
		safetyEventsReport,
		visibleProfessions
	);
	return filterArrayByAnotherArray(
		visibleSafetyEventsByProfessions,
		floorsList,
		reportsPageConsts.properties.floorId
	);
};

const getBucketizedTimeByHour = (timeString: string): string => {
	const hour: string = timeString.split(':')[0];
	return hour + ':00';
};

const safetyEventsHours = {
	'06:00': 0,
	'07:00': 0,
	'08:00': 0,
	'09:00': 0,
	'10:00': 0,
	'11:00': 0,
	'12:00': 0,
	'13:00': 0,
	'14:00': 0,
	'15:00': 0,
	'16:00': 0,
	'17:00': 0,
};

export const convertEventsTimestampsToStrings = (safetyEvents: IExtendedShortReportSafetyEvent[]): any[] => {
	return safetyEvents.map((event) => {
		return {
			...event,
			timeFrom: moment(event.timestampStart).format('HH:mm'),
			timeTo: event.timestampEnd ? moment(event.timestampEnd).format('HH:mm') : undefined,
		};
	});
};

export const getSafetyEventsByHourRows = (
	safetyReport: ISafetyReport,
	visibleProfessions: IProfession[],
	floorsList: IFloor[]
): IRow[] => {
	const safetyEvents = getVisibleEvents(safetyReport, visibleProfessions, floorsList);
	const safetyEventsWithTimeStrings = convertEventsTimestampsToStrings(safetyEvents);
	const safetyEventsArrayModifiedTime: any[] = safetyEventsWithTimeStrings.map((event) => {
		return { ...event, timeBucket: getBucketizedTimeByHour(event.timeFrom) };
	});
	const eventsCountedByHour: IEventsPerHour = countBy(safetyEventsArrayModifiedTime, (event) => event.timeBucket);
	const currentSafetyEventPerHours = {
		...safetyEventsHours,
		...eventsCountedByHour,
	};
	return Object.entries(currentSafetyEventPerHours).map(([hour, count]) => ({
		Hour: { value: hour },
		Events: { value: count },
	}));
};

export const getSafetyEventsByProfessionsRows = async (
	safetyReport: ISafetyReport,
	projectId: string,
	visibleProfessions: IProfession[],
	floorsList: IFloor[],
	workingProfessions: IProfession[]
): Promise<IRow[]> => {
	const safetyEvents = getVisibleEvents(safetyReport, visibleProfessions, floorsList);
	const safetyEventsByProfession = groupBy(safetyEvents, 'profession._id');
	const finalArray: IRow[] = [];
	Object.entries(safetyEventsByProfession).forEach(([profession, events]) => {
		const professionNick = getProfessionDisplayTextFromProfessionId(profession, workingProfessions);
		finalArray.push({
			Profession: { value: professionNick },
			count: { value: events.length === 0 ? 0 : events.length },
		});
	});
	return finalArray;
};

export const getSafetyEventsByFloorsRows = (
	safetyReport: ISafetyReport,
	visibleProfessions: IProfession[],
	floorsList: IFloor[]
): IRow[] => {
	const safetyEvents = getVisibleEvents(safetyReport, visibleProfessions, floorsList);
	const safetyEventsByFloor = groupBy(safetyEvents, 'floorId');
	const finalArray: IRow[] = [];
	Object.entries(safetyEventsByFloor).forEach(([floor, events]) => {
		finalArray.push({
			Floor: { value: floor },
			count: { value: events.length === 0 ? 0 : events.length },
		});
	});
	return finalArray;
};

export const generateTotalWorkingHoursRows = (
	productivityReport: IRoundedProductivityReport,
	reportDaysWithoutRestDays: number
): IRow[] => {
	return Object.values(productivityReport).map((professionProductivityReport) => ({
		[translationService.get('profession')]: {
			value: getProfessionDisplayText(professionProductivityReport.profession),
		},
		[translationService.get('totalWorkHours')]: {
			value: getProductRoundedHoursFromHours(
				sumBy(
					Object.values(professionProductivityReport.floors).map((productivityReportByFloor) => ({
						...productivityReportByFloor,
						totalHours: productivityReportByFloor.totalHours,
					})),
					'totalHours'
				)
			),
		},
	}));
};

export const getActivitiesNumberPerProfessionRow = (activityReportRounded: IActivitiesReport): IRow[] => {
	return Object.values(activityReportRounded).map((professionReport: IActivitiesProfessionReport) => {
		const activitiesTotalNumber = Object.values(professionReport.floors).reduce(
			(numActivities: number, floor: IActivitiesFloorReport) => {
				return numActivities + floor.activities.length;
			},
			0
		);
		return {
			Profession: {
				value: getProfessionDisplayText(professionReport.profession),
			},
			TotalActivities: { value: activitiesTotalNumber },
		};
	});
};

export const getDailyActivitiesRows = async (
	tz: string,
	projectId: string,
	visiblePeriodTimestamps: [number, number],
	professions: IProfession[],
	visibleDateTimeStamp: number,
	isPeriodPicker: boolean
): Promise<IRow[]> => {
	const dateFrom: string = convertTimeStampToDateByFormatAndTz(
		isPeriodPicker ? visiblePeriodTimestamps[0] : visibleDateTimeStamp,
		tz,
		dateFormats.DMY
	);
	const dateTo: string = convertTimeStampToDateByFormatAndTz(
		isPeriodPicker ? visiblePeriodTimestamps[1] : visibleDateTimeStamp,
		tz,
		dateFormats.DMY
	);
	const activities: any = await requestService.get(
		`/activities/activitiesBetweenDates?projectId=${projectId}&dateFrom=${dateFrom}&dateTo=${dateTo}&tz=${tz}`
	);
	const finalArray: IRow[] = [];
	Object.entries(activities).forEach(([professionId, report]: any) => {
		const professionNick: string = getProfessionDisplayTextFromProfessionId(professionId, professions);
		const res = { Profession: { value: professionNick } };
		let activityDayCount = 0;
		Object.entries(report).forEach(([key, activity]: any) => {
			const startOfDateRange = getTimezoneStartOfDate(
				tz,
				getTimezoneFormattedDate(tz, visiblePeriodTimestamps[0])
			);
			const endOfDateRange = getTimezoneStartOfDate(tz, getTimezoneFormattedDate(tz, visiblePeriodTimestamps[1]));
			const startDateAct = new Date(activity.startDate);
			const endDateAct = new Date(activity.endDate);
			const startDate = moment.tz(Math.max(startDateAct.getTime(), startOfDateRange.getTime()), tz);
			const endDate = moment.tz(Math.min(endDateAct.getTime(), endOfDateRange.getTime()), tz).add(1, 'd');
			activityDayCount = activityDayCount + endDate.diff(startDate, 'days');
		});
		res['dailyActivities'] = { value: activityDayCount };
		finalArray.push(res);
	});
	return finalArray;
};

export const getDailyProductivityReportsForPeriodRows = async (
	tz: string,
	projectId: string,
	professionsReport: IProfessionsAnalysisReport,
	workingProfessions: IProfession[],
	visibleDateTimeStamp: number
): Promise<IRow[]> => {
	if (isEmpty(professionsReport)) {
		return [{ NoData: { value: 'No Data' } }];
	}
	const dates: (string | IDateProfessionProductivityAnalysisData)[] = Object.entries(
		Object.values(professionsReport)[0].dates
	).map((date) => {
		return Object.values(date)[0];
	});

	const getWorkerHour = (dateProfessionReport, hoursFieldName): number => {
		return dateProfessionReport[hoursFieldName] ? moment.tz(dateProfessionReport[hoursFieldName], tz).hours() : 0;
	};

	const finalArray: any[] = [];
	dates.forEach((date) => {
		const tmpObj = { Date: { value: date } };
		Object.entries(professionsReport).forEach(
			([profession, report]: [string, IProfessionProductivityAnalysisReportData]) => {
				const fullProfession: IProfession | undefined = workingProfessions.find(
					(requestProfession) => requestProfession._id === profession
				);
				if (!fullProfession || fullProfession.tradeGroup === othersTradeGroup) {
					return;
				}
				Object.entries(report.dates).forEach(
					([reportDate, dateProfessionReport]: [string, IDateProfessionProductivityAnalysisData]) => {
						if (reportDate === date) {
							const firstWorkHour = getWorkerHour(dateProfessionReport, 'dayFirstWorkDateTime');
							const lastWorkHour = getWorkerHour(dateProfessionReport, 'dayLastWorkDateTime');
							const workDay = lastWorkHour - firstWorkHour;
							const totalWorkHours = getProductRoundedHoursFromMs(dateProfessionReport.totalWorkTimeMS);
							const professionNick: string = getProfessionDisplayTextFromProfessionId(
								profession,
								workingProfessions
							);
							tmpObj[`Start - ${professionNick}`] = {
								value: `${String(firstWorkHour).padStart(2, '0')}:00`,
							};
							tmpObj[`End - ${professionNick}`] = {
								value: `${String(lastWorkHour).padStart(2, '0')}:00`,
							};
							tmpObj[`Work Day - ${professionNick}`] = { value: workDay };
							tmpObj[`Total Work Hours - ${professionNick}`] = {
								value: totalWorkHours,
							};
							return;
						}
					}
				);
			}
		);
		finalArray.push(tmpObj);
	});
	return finalArray;
};

export const getWorkersPerProfessionPerDateRows = async (
	projectId: string,
	tz: string,
	visiblePeriodTimestamps: [number, number],
	visibleDateTimeStamp: number,
	isPeriodPicker: boolean,
	workingProfessions: IProfession[]
): Promise<IRow[]> => {
	const dateFrom: string = convertTimeStampToDateByFormatAndTz(
		isPeriodPicker ? visiblePeriodTimestamps[0] : visibleDateTimeStamp,
		tz,
		dateFormats.DMY
	);
	const dateTo: string = convertTimeStampToDateByFormatAndTz(
		isPeriodPicker ? visiblePeriodTimestamps[1] : visibleDateTimeStamp,
		tz,
		dateFormats.DMY
	);
	const result: {
		dates: { date: string; dateFormatted }[];
		reports: IProductivityReport[];
	} = await requestService.get(
		`/reports/dailyProfessionsProductivityForPeriodReport?dateFrom=${dateFrom}&dateTo=${dateTo}&projectId=${projectId}&tz=${tz}`
	);
	const dates = result.dates.map((date) => {
		return {
			date: date.dateFormatted,
			day: getWeekDay(new Date(date.date), tz),
		};
	});

	const allReportsProfessionsDisplayText: string[] = uniq(
		result.reports.flatMap((dayReport) => {
			return Object.entries(dayReport).map(
				([professionId, reportData]: [string, IProductivityProfessionReport]) => {
					return getProfessionDisplayText(reportData.profession);
				}
			);
		})
	);

	const initialProfessionWorkersValues: {
		[professionText: string]: { value: number };
	} = allReportsProfessionsDisplayText.reduce((acc, professionDisplayText) => {
		return { ...acc, [professionDisplayText]: { value: 0 } };
	}, {});

	return result.reports.map((report: IProductivityReport, index: number) => {
		const row: IRow = {
			date: { value: dates[index].date },
			day: { value: dates[index].day },
			...initialProfessionWorkersValues,
		};
		if (isEmpty(report)) {
			workingProfessions.forEach((profession) => {
				row[getProfessionDisplayText(profession)] = { value: 0 };
			});
			return row;
		}

		Object.entries(report).forEach(([professionId, professionReport]: [string, IProductivityProfessionReport]) => {
			const isWorkingProfession: boolean = workingProfessions.some(
				(workingProfession) => workingProfession._id === professionId
			);
			if (!isWorkingProfession) {
				return;
			}
			const uniqueTags: number = getTotalUniqueWorkersInProfessionProductivityReport(professionReport) || 0;
			const professionNick: string = getProfessionDisplayTextFromProfessionId(professionId, workingProfessions);
			row[professionNick] = { value: uniqueTags };
		});
		return row;
	});
};

export const getTotalWorkingHoursPerTradeAndFloor = (productivityReport, floorsList): IRow[] => {
	const rows: IRow[] = [];
	floorsList.forEach((floor) => {
		const professionObjects: any = { floor: { value: floor.floorNick } };
		for (let report in productivityReport) {
			const reportFloors = productivityReport[report].floors;
			const reportFloor = reportFloors[floor.floorId];
			const professionNick = getProfessionDisplayText(productivityReport[report].profession);
			if (reportFloor) {
				const workHours = reportFloor.totalHours.toFixed(1);
				professionObjects[professionNick] = { value: workHours };
			} else {
				professionObjects[professionNick] = { value: 0 };
			}
		}
		rows.push(professionObjects);
	});
	return rows;
};

export const generateProfessionsSheetRows = (
	productivityReport: IRoundedProductivityReport,
	reportDaysWithoutRestDays: number,
	toggleStateDatePicker: DATE_PICKER_TOGGLE
): IRow[] => {
	return compact(
		Object.values(productivityReport).map((professionProductivityReport) => {
			if (toggleStateDatePicker === DATE_PICKER_TOGGLE.date && !professionProductivityReport.totalHoursOnSite) {
				return null;
			}

			const professionRow: IRow = {
				[translationService.get('profession')]: {
					value: getProfessionDisplayText(professionProductivityReport.profession),
				},
				[translationService.get('totalWorkDays')]: {
					value:
						toggleStateDatePicker === DATE_PICKER_TOGGLE.date
							? 1
							: getProfessionTotalWorkDays(professionProductivityReport, reportDaysWithoutRestDays),
				},
				[translationService.get(
					toggleStateDatePicker === DATE_PICKER_TOGGLE.date ? 'workersQuantity' : 'avgNumOfWorkersInPeriod'
				)]: {
					value:
						toggleStateDatePicker === DATE_PICKER_TOGGLE.date
							? getTotalUniqueWorkersInProfessionProductivityReport(professionProductivityReport)
							: Math.ceil(getProfessionAvgWorkersNumber(professionProductivityReport)),
				},
			};

			if (toggleStateDatePicker === DATE_PICKER_TOGGLE.date) {
				professionRow[translationService.get('totalTimeOnSite')] = {
					value: getProductRoundedHoursFromHours(professionProductivityReport.totalHoursOnSite!),
				};
			}

			return professionRow;
		})
	);
};

export const generateFloorsSheetRows = (
	productivityReport: IRoundedProductivityReport,
	toggleStateDatePicker: DATE_PICKER_TOGGLE
): IRow[] => {
	return compact(
		Object.values(productivityReport).flatMap((professionProductivityReport) => {
			if (toggleStateDatePicker === DATE_PICKER_TOGGLE.date && !professionProductivityReport.totalHoursOnSite) {
				return null;
			}

			const totalActiveHoursOfProfession: number = sumByField(
				Object.values(professionProductivityReport.floors),
				'totalHours'
			);

			return Object.values(professionProductivityReport.floors).map((professionFloorProductivityReport) => {
				const areasInFloor: IRoundedProductivityAreaReport[] = Object.values(
					professionProductivityReport.areas
				).filter((area) => area.floorId === professionFloorProductivityReport.floorId);
				const floorHoursPercentage: string = (
					(professionFloorProductivityReport.totalHours / totalActiveHoursOfProfession) *
					100
				)
					.toFixed(1)
					.replace(/\.0$/, '');

				return {
					[translationService.get('profession')]: {
						value: getProfessionDisplayText(professionProductivityReport.profession),
					},
					[translationService.get('floor')]: {
						value: `${professionFloorProductivityReport.floorNick} ${
							areasInFloor.length > 0 ? `(${areasInFloor.map((area) => area.areaNick).join(', ')})` : ''
						} `,
					},
					[translationService.get('workResourcesLocation')]: {
						value: `${floorHoursPercentage}%`,
					},
					[translationService.get(
						toggleStateDatePicker === DATE_PICKER_TOGGLE.date
							? 'workersQuantity'
							: 'avgNumOfWorkersInPeriod'
					)]: {
						value:
							toggleStateDatePicker === DATE_PICKER_TOGGLE.date
								? (professionFloorProductivityReport as IDailyProductivityFloorReport).tagIds.length
								: Math.ceil(
										getAvgWorkersFromUniqueTagIdsPerDay(
											(professionFloorProductivityReport as IPeriodicProductivityFloorReport)
												.uniqueTagIdsPerDay
										)
								  ),
					},
				};
			});
		})
	);
};

const generateActivitiesSheetRows = (tz: string, activitiesReport: IActivitiesReport, workDayHours): IRow[] => {
	return Object.values(activitiesReport).flatMap((professionActivitiesReport) =>
		Object.values(professionActivitiesReport.floors).flatMap((professionFloorActivitiesReport) =>
			professionFloorActivitiesReport.activities.map((activityReport) => {
				const momentActivityStartDateInBrowserTZ: moment.Moment = moment(
					getTimezoneFormattedDate(tz, new Date(activityReport.startDate)),
					dateFormats.DMY
				);
				const momentActivityEndDateInBrowserTZ: moment.Moment = moment(
					getTimezoneFormattedDate(tz, new Date(activityReport.endDate)),
					dateFormats.DMY
				);
				const workDaysSoFar: number = getWorkDaysNumber(
					momentActivityStartDateInBrowserTZ.toDate(),
					getMinDate(getProjectDateWithBrowserTZOffset(tz), momentActivityEndDateInBrowserTZ.toDate()),
					workDayHours,
					tz
				);
				return {
					[translationService.get('profession')]: {
						value: getProfessionDisplayText(professionActivitiesReport.profession),
					},
					[translationService.get('floor')]: {
						value: professionFloorActivitiesReport.floorNick,
					},
					[translationService.get('activity')]: {
						value: activityReport.description,
					},
					[translationService.get('activityStartDate')]: {
						value: momentActivityStartDateInBrowserTZ.format(translationService.getDateFormat()),
					},
					[translationService.get('activityEndDate')]: {
						value: momentActivityEndDateInBrowserTZ.format(translationService.getDateFormat()),
					},
					[translationService.get('workPlanManager')]: {
						value: activityReport.manager?.name,
					},
					[translationService.get('numberOfWorkDaysDefinedForActivity')]: {
						value: getWorkDaysNumber(
							momentActivityStartDateInBrowserTZ.toDate(),
							momentActivityEndDateInBrowserTZ.toDate(),
							workDayHours,
							tz
						),
					},
					[translationService.get('numberOfWorkDaysSoFar')]: {
						value: workDaysSoFar,
					},
					[translationService.get('actualAvgNumberOfWorkers')]: {
						value: activityReport.roundedAverageWorkHoursPerDay,
					},
					[translationService.get('totalActualWorkHours')]: {
						value: activityReport.roundedActualWorkTime,
					},
				};
			})
		)
	);
};
