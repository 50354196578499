import React from 'react';
import { DateFlagDisplay } from '@shared/components/DateFlagDisplay/DateFlagDisplay';
import classes from './styles.module.scss';
import classnames from 'classnames';
import { IProfession } from '@interfaces/index';
import { IMainPageWidgetActivityData } from '@interfaces/IMainPageWidgetActivityData';
import { useSelector } from 'react-redux';
import { selectProjectId, selectTimezone } from '@store/slices/project.slice';
import { TrusstorIcon } from '@src/Components/TrusstorIcon/TrusstorIcon';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { IconColor, IconSize } from '@shared/components/TrusstorIconShared/TrusstorIconShared';
import {
	getCompletedLocationsCountFromActivitiesGroupOnFloor,
	getLocationsCountFromActivitiesGroupOnFloor,
	isActivityGroupOverdue,
} from '@utils/activities.utils';
import { LineDelimiter } from '@src/Components/LineDelimiter/LineDelimiter';
import { COLORS } from '@src/constants';
import { Dictionary } from 'lodash';
import { ProfessionDisplayWithTradeIcon } from '@shared/components/ProfessionDisplayWithTradeIcon/ProfessionDisplayWithTradeIcon';

interface IProfessionActivitiesChipTooltipProps {
	floorId: string;
	activitiesData: Dictionary<IMainPageWidgetActivityData[]>;
}

export const ProfessionActivitiesChipTooltip = (props: IProfessionActivitiesChipTooltipProps) => {
	const projectId: string = useSelector(selectProjectId)!;
	const tz: string = useSelector(selectTimezone)!;
	return (
		<div className={classes.chipTooltipContainer}>
			{Object.keys(props.activitiesData).map((professionId: string, index: number) => {
				const isFirstProfession: boolean = index === 0;
				const profession: IProfession = props.activitiesData[professionId][0].profession;
				return (
					<div className={classes.professionTooltipContainer} key={`tooltip-profession-${professionId}`}>
						<div
							className={classnames(classes.topProfessionDetails, {
								[classes.topBorder]: !isFirstProfession,
							})}
						>
							<TrusstorIcon
								iconName={IconNames.activity}
								color={IconColor.Grey300}
								size={IconSize.SMALL}
							/>
							<div>
								<ProfessionDisplayWithTradeIcon
									contractorTextClassname={classes.detailsText}
									profession={profession}
									projectId={projectId}
								/>
							</div>
						</div>
						<div className={classes.professionActivitiesChipTooltipContainer}>
							{props.activitiesData[professionId].map(
								(activityData: IMainPageWidgetActivityData, index: number) => {
									const isOverdue: boolean = isActivityGroupOverdue(activityData, tz);
									const isLastActivity: boolean =
										index === props.activitiesData[professionId].length - 1;
									return (
										<div
											className={classnames(classes.activityDataContainer, {
												[classes.noBottomBorder]: isLastActivity,
											})}
											key={`tooltip-profession-${professionId}-activity-${activityData.description}`}
										>
											<p className={classes.activityDescription}>{activityData.description}</p>
											<div className={classes.activityInfo}>
												<DateFlagDisplay
													date={activityData.originalEndDate}
													isOverdue={isOverdue}
													tz={tz}
													isDarkTheme={true}
												/>
												<LineDelimiter height={16} color={COLORS.grey300} />
												<div className={classes.iconText}>
													<TrusstorIcon
														iconName={IconNames.pinFilled}
														color={IconColor.Grey300}
													/>
													{getLocationsCountFromActivitiesGroupOnFloor(
														activityData,
														props.floorId
													)}
												</div>
												<LineDelimiter height={16} color={COLORS.grey300} />
												<div className={classes.iconText}>
													<TrusstorIcon
														iconName={IconNames.checkCircleFull}
														color={IconColor.Grey300}
													/>
													{getCompletedLocationsCountFromActivitiesGroupOnFloor(
														activityData,
														props.floorId
													)}
													/
													{getLocationsCountFromActivitiesGroupOnFloor(
														activityData,
														props.floorId
													)}
												</div>
											</div>
										</div>
									);
								}
							)}
						</div>
					</div>
				);
			})}
		</div>
	);
};
