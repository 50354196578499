import { sortBy } from 'lodash';
import { ISequenceItem } from '@interfaces/ISequenceItem';
import { HealthScoreEnum } from '@shared/interfaces/HealthScoreEnum';
import { translationService } from '@src/servicesInitializers';
import { getSpaceCharacterIfNeeded } from '@utils/translations.utils';

export interface IMilestoneSummaryFields {
	title: string;
	sequenceItems: ISequenceItem[];
	dueDate: Date;
}

export const getSummaryHeaderMessage = (
	milestones: IMilestoneSummaryFields[],
	healthScore: HealthScoreEnum
): string => {
	const alertMessage: string =
		healthScore === HealthScoreEnum.MAJOR_RISK
			? translationService.get('progressAnalysis_major_risk_alert')
			: translationService.get('progressAnalysis_minor_risk_alert');

	const initialMessage: string =
		milestones.length > 1
			? `${alertMessage}! ${translationService.get('progressAnalysis_thereAreMilestones', {
					count: `${milestones.length}`,
				})} ${translationService.get(healthScore)}`
			: `${alertMessage}!`;

	if (milestones.length >= 4) {
		// in case there are more than 4 milestones we display their titles
		const milestonesTitles: string = milestones.map((milestone) => `"${milestone.title}"`).join(', ');

		return `${initialMessage}: ${milestonesTitles}.`;
	}
	return initialMessage;
};

export const getMultipleMilestonesSummaryText = (milestones: IMilestoneSummaryFields[]): string[] => {
	if (milestones.length >= 4) {
		// in case there are more than 4 milestones we only show the nearest one
		const firstDueMilestone = sortBy(milestones, (milestone) => milestone.dueDate, 'asc')[0];
		return [
			`${translationService.get('progressAnalysis_theNearestOneIsMilestone')} "${
				firstDueMilestone.title
			}" ${translationService.get(
				'progressAnalysis_whichIsAtRiskDueTo'
			)}${getSpaceCharacterIfNeeded()}${getActivitiesText(firstDueMilestone.sequenceItems)}`,
		];
	}
	return milestones.map((milestone) => {
		return getMilestoneSummaryText(milestone, milestone.sequenceItems);
	});
};

export const getMilestoneSummaryText = (milestone: IMilestoneSummaryFields, sequenceItems: ISequenceItem[]): string => {
	return `${milestone.title} ${translationService.get(
		'progressAnalysis_isAtRiskDueTo'
	)}${getSpaceCharacterIfNeeded()}${getActivitiesText(sequenceItems)}`;
};

export const getActivitiesText = (sequenceItems: ISequenceItem[]): string => {
	const activitiesDescription: string[] = sequenceItems.map((item) => item.description);

	if (activitiesDescription.length === 1) {
		return `${translationService.get('progressAnalysis_activity')} "${activitiesDescription[0]}"`;
	}

	return `${translationService.get('progressAnalysis_activities')}: ${combineMultipleTexts(activitiesDescription)}`;
};

const combineMultipleTexts = (texts: string[]): string => {
	const MAX_TEXTS_COUNT: number = 2;
	if (texts.length === 1) {
		return texts[0];
	}

	if (texts.length <= MAX_TEXTS_COUNT) {
		const beforeLastNameText: string = texts
			.slice(0, -1)
			.map((text) => `"${text}"`)
			.join(', ');

		return `${beforeLastNameText} ${translationService.get('and')} "${texts.slice(-1)[0]}"`;
	}

	const maxTexts: string = texts
		.slice(0, MAX_TEXTS_COUNT)
		.map((text) => `"${text}"`)
		.join(', ');

	return `${maxTexts} ${translationService.get('and')} ${texts.length - MAX_TEXTS_COUNT} ${translationService.get(
		'progressAnalysis_moreActivities'
	)}`;
};
