import React, { useEffect, useState } from 'react';
import DatePicker, { CalendarContainer } from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import '../DateSelectorWithBackAndForward/styles.module.css';
import {
	convertDateObjectToServerFormat,
	generateTimezoneWeekDatesRange,
	generateWeekDatesRange,
	getProjectDateWithBrowserTZOffset,
	getTimezoneFormattedDate,
	getTimezoneStartOfDate,
} from '@utils/dateUtils';
import { useSelector } from 'react-redux';
import { IRootState } from '@store/slices';
import classes from './styles.module.scss';
import { getDateRangeString } from '@shared/utils/dateUtils';
import { DatePickerHeader } from '@shared/components/DatePickerHeader/DatePickerHeader';
import { translationService } from '@src/servicesInitializers';
import { PopperPlacement } from '@src/Components/DateSelector/DateAndTimeSelector/DateAndTimeSelector';

export interface IDateMenuItem {
	id: string;
	label: string;
	value: {
		startDate: Date;
		endDate: Date;
	};
	onClickSideEffect?: (data?: any) => void;
}

interface IPeriodSelectorProps {
	updatePeriod: (period: [Date, Date]) => void;
	itemsAbove?: IDateMenuItem[];
	monthsShown?: number;
	defaultStartDate?: Date;
	defaultEndDate?: Date;
	minDate?: Date;
	maxDate?: Date;
	isPossibleToSelectSingleDay?: boolean;
	placeHolderText?: string;
	textInputClassName?: string;
	datePickerClassName?: string;
	containerDivAdditionalClassName?: string;
	isDefaultCalendarStyle?: boolean;
	setDefaultDates?: boolean;
	inline?: boolean;
	customInput?: React.ReactNode;
	calendarPosition?: PopperPlacement;
	maximumDaysForPeriod?: number;
}

const PeriodSelector = (props: IPeriodSelectorProps) => {
	const tz: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.tz);
	const now: Date = getTimezoneStartOfDate(tz, getTimezoneFormattedDate(tz));
	const [shouldCloseCalendar, setShouldCloseCalendar] = useState<boolean>(false);
	const [startDate, setStartDate] = useState<Date | undefined>(props.setDefaultDates ? props.defaultStartDate : now);
	const [endDate, setEndDate] = useState<Date | undefined>(props.setDefaultDates ? props.defaultEndDate : now);
	const [maxDateByMaxDays, setMaxDateByMaxDays] = useState<Date | undefined>(undefined);
	const datePickerRef: React.MutableRefObject<DatePicker | null> = React.useRef(null);

	useEffect(() => {
		if (!props.setDefaultDates) {
			return;
		}
		setStartDate(props.defaultStartDate);
		setEndDate(props.defaultEndDate);
	}, [props.defaultStartDate, props.defaultEndDate]);

	useEffect(() => {
		if (props.setDefaultDates && props.defaultStartDate && props.defaultEndDate) {
			return;
		}
		if (moment.tz(now, tz).day() === 0) {
			const weekDaysRange: Date[] = generateWeekDatesRange(moment.tz(now, tz).subtract(1, 'w').toDate());
			setStartDate(weekDaysRange[0]);
			setEndDate(weekDaysRange[6]);
			props.updatePeriod([weekDaysRange[0], weekDaysRange[6]]);
		} else {
			const weekDaysRange = generateTimezoneWeekDatesRange(now, tz);
			setStartDate(weekDaysRange[0]);
			setEndDate(weekDaysRange[moment.tz(now, tz).day()]);
			props.updatePeriod([weekDaysRange[0], weekDaysRange[moment.tz(now, tz).day()]]);
		}
	}, []);

	const handleMaximumDaysForPeriod = (startDate: Date | undefined, endDate: Date | undefined) => {
		if (startDate && endDate) {
			setMaxDateByMaxDays(undefined);
		}

		if (startDate && !endDate && props.maximumDaysForPeriod) {
			const maxDateByMaxDays: Date = moment
				.tz(startDate, tz)
				.add(props.maximumDaysForPeriod - 1, 'days')
				.toDate();
			setMaxDateByMaxDays(maxDateByMaxDays);
		}
	};

	const onChangeDate = (dates: [Date, Date]) => {
		const [start, end] = dates;

		handleMaximumDaysForPeriod(start, end);
		const startDateInProjectTZ: Date = getTimezoneStartOfDate(tz, convertDateObjectToServerFormat(start));
		const endDateInProjectTZ: Date = getTimezoneStartOfDate(tz, convertDateObjectToServerFormat(end));

		if (props.isPossibleToSelectSingleDay && moment(startDateInProjectTZ).isAfter(endDateInProjectTZ)) {
			setStartDate(endDateInProjectTZ);
			setEndDate(endDateInProjectTZ);
			props.updatePeriod([startDateInProjectTZ, endDateInProjectTZ]);
			return;
		}

		setStartDate(startDateInProjectTZ);
		moment.tz(endDateInProjectTZ, tz).isValid() ? setEndDate(endDateInProjectTZ) : setEndDate(end);

		if (moment.tz(startDateInProjectTZ, tz).isValid() && moment.tz(endDateInProjectTZ, tz).isValid()) {
			props.updatePeriod([startDateInProjectTZ, endDateInProjectTZ]);
		}

		if (!moment.tz(endDateInProjectTZ, tz).isValid()) {
			setShouldCloseCalendar(true);
		}
	};

	const onCalendarOpen = () => {
		setShouldCloseCalendar(false);
	};

	const handleItemClick = (item: IDateMenuItem) => {
		setStartDate(item.value.startDate);
		setEndDate(item.value.endDate);
		props.updatePeriod([item.value.startDate, item.value.endDate]);
		item.onClickSideEffect?.();
		setShouldCloseCalendar(true);
		datePickerRef.current?.setOpen(false);
	};

	const MyContainer = ({ className, children }) => {
		return (
			<CalendarContainer className={`${className} ${classes.calendarContainer}`}>
				{props.itemsAbove?.map((item, index) => {
					return (
						<div key={index} className={classes.item} onClick={() => handleItemClick(item)}>
							<div className={classes.label}>{item.label}</div>
							<div className={classes.value}>
								{getDateRangeString(
									item.value.startDate,
									item.value.endDate,
									tz,
									translationService.getDateLocale()
								)}
							</div>
						</div>
					);
				})}
				<div className={classes.calendar}>{children}</div>
			</CalendarContainer>
		);
	};

	const getMaxDate = (): Date | undefined => {
		const maxDateFromProps: Date | undefined = props.maxDate
			? getProjectDateWithBrowserTZOffset(tz, props.maxDate)
			: getProjectDateWithBrowserTZOffset(tz, now);

		if (!maxDateByMaxDays) {
			return maxDateFromProps;
		}

		return new Date(Math.min(maxDateFromProps.getTime(), maxDateByMaxDays.getTime()));
	};

	return (
		<div className={`${classes.containerDiv} ${props.containerDivAdditionalClassName ?? ''}`}>
			<div className={classes.floatingHeaderContainer}>
				<DatePicker
					renderCustomHeader={({
						monthDate,
						decreaseMonth,
						increaseMonth,
						prevMonthButtonDisabled,
						nextMonthButtonDisabled,
						customHeaderCount,
					}) => (
						<DatePickerHeader
							monthDate={monthDate}
							decreaseMonth={decreaseMonth}
							increaseMonth={increaseMonth}
							prevMonthButtonDisabled={prevMonthButtonDisabled}
							nextMonthButtonDisabled={nextMonthButtonDisabled}
							customHeaderCount={customHeaderCount}
							monthsShown={props.monthsShown}
						/>
					)}
					popperPlacement={props.calendarPosition}
					formatWeekDay={(nameOfDay) => translationService.get(`datepicker_weekday_${nameOfDay}`)}
					ref={datePickerRef}
					calendarContainer={MyContainer}
					selected={getProjectDateWithBrowserTZOffset(tz, startDate)}
					onChange={onChangeDate}
					startDate={startDate ? getProjectDateWithBrowserTZOffset(tz, startDate) : startDate}
					endDate={endDate ? getProjectDateWithBrowserTZOffset(tz, endDate) : endDate}
					maxDate={getMaxDate()}
					minDate={props.minDate}
					wrapperClassName={classes.datePickerWrapper}
					popperClassName={classes.datePopper}
					dayClassName={() => (props.isDefaultCalendarStyle ? null : classes.calendarDay)}
					weekDayClassName={() => (props.isDefaultCalendarStyle ? null : classes.weekDay)}
					calendarClassName={props.datePickerClassName ?? classes.datePicker}
					className={classes.calenderWeekHover}
					customInput={props.customInput}
					onCalendarOpen={onCalendarOpen}
					selectsRange
					shouldCloseOnSelect={shouldCloseCalendar}
					monthsShown={props.monthsShown}
					inline={props.inline}
					popperModifiers={[
						{
							name: 'flip',
						},
					]}
				/>
			</div>
		</div>
	);
};

export { PeriodSelector };
