import { store } from './store/store';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { jssPreset, StylesProvider } from '@material-ui/core/styles';
import './styles/main.scss';
import App from './Components/App';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { initializeBootstrapUtilities } from '@utils/bootstrap.utils';
import { SnackbarProvider } from 'notistack';
import { PostHogProvider } from 'posthog-js/react';
import posthog from 'posthog-js';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ISharedServicesContext, SharedServicesContext } from '../../shared/hooks/sharedServices.context';
import { requestService, streamService, translationService } from './servicesInitializers';

const queryClient = new QueryClient();

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

initializeBootstrapUtilities();

const rootElement: HTMLElement | null = document.getElementById('root');
if (!rootElement) {
	throw new Error('Failed to find the root element');
}

const root = createRoot(rootElement);

const sharedServiceContext: ISharedServicesContext = {
	requestService,
	translationService,
};

window.onbeforeunload = function () {
	streamService.closeAllStreams();
};

root.render(
	<QueryClientProvider client={queryClient}>
		<Provider store={store}>
			<SharedServicesContext.Provider value={sharedServiceContext}>
				<SnackbarProvider maxSnack={3}>
					<DndProvider backend={HTML5Backend}>
						<Router>
							<StylesProvider jss={jss}>
								<PostHogProvider client={posthog}>
									<App />
								</PostHogProvider>
							</StylesProvider>
						</Router>{' '}
					</DndProvider>
				</SnackbarProvider>
			</SharedServicesContext.Provider>
		</Provider>
	</QueryClientProvider>
);

export * from './servicesInitializers';
