import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { requestService } from '@src/servicesInitializers';
import { ICreateMilestone, IMilestone } from '@shared/interfaces/IMilestone';
import { getTimezoneStartOfDate } from '@shared/utils/dateUtils';
import { useSelector } from 'react-redux';
import { selectTimezone } from '@store/slices/project.slice';
import { IMilestoneProgressSummary } from '@interfaces/IMilestoneProgressSummary';

export const useProjectMilestonesQuery = (
	projectId: string
): { projectMilestones: IMilestone[]; isFetched: boolean } => {
	const { data: projectMilestones, isFetched } = useQuery(
		['projectMilestones', projectId],
		() => requestService.get(`/activities/milestones?projectId=${projectId}`),
		{
			useErrorBoundary: true,
			initialData: [],
		}
	);

	return { projectMilestones, isFetched };
};

export const useAddMilestonesMutation = (projectId: string, tz: string) => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (milestone: ICreateMilestone) =>
			requestService.post(`/activities/milestones/`, { body: milestone }),
		onMutate: async (milestone: ICreateMilestone) => {
			await queryClient.cancelQueries(['projectMilestones', projectId]);
			const previousMilestones: IMilestone[] | undefined = queryClient.getQueryData([
				'projectMilestones',
				projectId,
			]);
			const newMilestone: IMilestone = {
				...milestone,
				endDate: getTimezoneStartOfDate(tz, milestone.endDate),
				_id: 'temp',
			};
			const newMilestones: IMilestone[] = previousMilestones
				? [...previousMilestones, newMilestone]
				: [newMilestone];
			queryClient.setQueryData(['projectMilestones', projectId], newMilestones);
			return { previousMilestones };
		},
		onError: (error, newMilestone, context) => {
			queryClient.setQueryData(['projectMilestones', projectId], context?.previousMilestones);
		},
		onSettled: () => {
			queryClient.invalidateQueries(['projectMilestones', projectId]);
		},
	});
};

export const useUpdateMilestoneMutation = (projectId: string) => {
	const queryClient = useQueryClient();
	const tz: string = useSelector(selectTimezone)!;

	interface IUpdateParams {
		milestoneId: string;
		title?: string;
		dueDate?: string;
	}

	return useMutation({
		mutationFn: async ({ milestoneId, title, dueDate }: IUpdateParams) =>
			requestService.put(`/activities/milestones/${milestoneId}`, {
				body: {
					...(title && { title }),
					...(dueDate && { endDate: dueDate }),
				},
			}),
		onMutate: async ({ milestoneId, title, dueDate }: IUpdateParams) => {
			await queryClient.cancelQueries(['projectMilestones', projectId]);
			const previousMilestones: IMilestone[] | undefined = queryClient.getQueryData([
				'projectMilestones',
				projectId,
			]);
			const newMilestones: IMilestone[] =
				previousMilestones?.map((milestone) =>
					milestone._id === milestoneId
						? {
								...milestone,
								title: title || milestone.title,
								endDate: dueDate ? getTimezoneStartOfDate(tz, dueDate) : milestone.endDate,
							}
						: milestone
				) || [];

			queryClient.setQueryData(['projectMilestones', projectId], newMilestones);
			return { previousMilestones };
		},
		onError: (error, newMilestone, context) => {
			queryClient.setQueryData(['projectMilestones', projectId], context?.previousMilestones);
		},
		onSettled: () => {
			queryClient.invalidateQueries(['projectMilestones', projectId]);
		},
	});
};

export const useDeleteMilestoneMutation = (projectId: string) => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (milestoneId: string) =>
			requestService.delete(`/activities/milestones/${milestoneId}?projectId=${projectId}`),
		onSuccess: () => {
			queryClient.invalidateQueries(['projectMilestones', projectId]);
		},
	});
};

export const useMilestonesProgressSummariesQuery = (projectId: string, date?: string) => {
	const { data: milestonesProgressSummary, isFetched } = useQuery<IMilestoneProgressSummary[]>(
		['milestonesProgressSummary', projectId, date],
		() =>
			requestService.get('/reports/milestonesProgressSummary', {
				params: { projectId, date },
			}),
		{
			useErrorBoundary: true,
			initialData: [],
		}
	);

	return { milestonesProgressSummary, isFetched };
};
