import React from 'react';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { TrusstorIcon } from '@src/Components/TrusstorIcon/TrusstorIcon';
import classes from './styles.module.scss';

interface ITrusstorCloseButtonProps {
	click: () => void;
}

export const TrusstorCloseButton = (props: ITrusstorCloseButtonProps) => {
	return <TrusstorIcon className={classes.button} iconName={IconNames.close} onClick={props.click} />;
};
