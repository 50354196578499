import React from 'react';
import classes from './styles.module.scss';
import { selectProjectId, selectTimezone } from '@store/slices/project.slice';
import { useSelector } from 'react-redux';
import { useProfessionAllTimeStatsQuery } from '@src/hooks/queries/analysisCenter.queries.hooks';
import { AllTimeStatsCard } from '@src/Components/Pages/Reports/ProfessionAnalysisReportPageV2/Components/AllTimeStatsCard/AllTimeStatsCard';
import { translationService } from '@src/servicesInitializers';
import { ProfessionDisplayWithTradeIcon } from '@shared/components/ProfessionDisplayWithTradeIcon/ProfessionDisplayWithTradeIcon';
import { IProfession } from '@shared/interfaces/IProfession';
import { Loader } from '@shared/components/Loader/Loader';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { TrusstorIcon } from '@src/Components/TrusstorIcon/TrusstorIcon';
import moment from 'moment';

interface IContractorAnalysisAllTimeStatsProps {
	profession: IProfession;
}

export const ContractorAnalysisAllTimeStats = (props: IContractorAnalysisAllTimeStatsProps) => {
	const projectId: string = useSelector(selectProjectId)!;
	const tz: string = useSelector(selectTimezone)!;

	const { professionAllTimeStats, isLoading } = useProfessionAllTimeStatsQuery(projectId, props.profession?._id);
	console.log({ professionAllTimeStats });

	if (!professionAllTimeStats) return null;
	if (isLoading) return <Loader />;

	const { firstWorkingDay, totalWorkHours, avgDailyWorkHours, avgDailyWorkers } = professionAllTimeStats;

	const getFormattedFirstWorkingDay = () => {
		const formattedDate: string = moment.tz(firstWorkingDay, tz).format('MMM YYYY');
		return formattedDate;
	};

	const cards: {
		title: string;
		component: JSX.Element;
	}[] = [
		{
			title: translationService.get('contractorAnalysis_all_time_card_trade'),
			component: (
				<div className={classes.cardContent}>
					<ProfessionDisplayWithTradeIcon
						profession={props.profession}
						projectId={projectId}
						isNarrow
						showTradeIcon
						hideContractor
					/>
				</div>
			),
		},
		{
			title: translationService.get('contractorAnalysis_all_time_card_workingSince'),
			component: (
				<div className={classes.cardContent}>
					{firstWorkingDay ? (
						<>
							<TrusstorIcon iconName={IconNames.calendar} />
							<div>{getFormattedFirstWorkingDay()}</div>
						</>
					) : (
						translationService.get('contractorAnalysis_all_time_card_noData')
					)}
				</div>
			),
		},
		{
			title: translationService.get('contractorAnalysis_all_time_card_totalWorkHours'),
			component: (
				<div className={classes.cardContent}>
					<TrusstorIcon iconName={IconNames.clock} />
					<div>{`${totalWorkHours}${translationService.get('h')}`}</div>
				</div>
			),
		},
		{
			title: translationService.get('contractorAnalysis_all_time_card_avgDailyWorkers'),
			component: (
				<div className={classes.cardContent}>
					<TrusstorIcon iconName={IconNames.worker} />
					<div>{avgDailyWorkers === 0 ? 0 : avgDailyWorkers.toFixed(2)}</div>
				</div>
			),
		},
		{
			title: translationService.get('contractorAnalysis_all_time_card_avgDailyWorkHours'),
			component: (
				<div className={classes.cardContent}>
					<TrusstorIcon iconName={IconNames.clock} />
					<div>{avgDailyWorkHours === 0 ? 0 : avgDailyWorkHours.toFixed(2)}</div>
				</div>
			),
		},
	];

	return (
		<div className={classes.ContractorAnalysisAllTimeStats_container} data-testid="ContractorAnalysisAllTimeStats">
			{cards.map((card, index) => (
				<AllTimeStatsCard title={card.title} component={card.component} key={index} />
			))}
		</div>
	);
};
