import { IssuePriority } from '@shared/interfaces/IIssueShared';
import {
	addSelectedIssuePriority,
	removeSelectedIssuePriority,
	resetSelectedIssuePriorities,
	selectIssuePriorities,
	selectSelectedIssuePriorities,
} from '@store/slices/issuePriorities.slice';
import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { APP_FILTER_TYPES } from '../../constants';
import { translationService } from '../../index';

import { ExpandableComponent } from '../MainPageSections/ExpandableComponent/ExpandableComponent';
import { IssuePriorityFilterCard } from '@shared/components/Filters/IssuePriorityFilterCard/IssuePriorityFilterCard';

interface IIssuePrioritiesFilterSectionProps {
	prioritiesCountObj?: { [issuePriorityId: string]: number };
	defaultOpen?: boolean;
}

export const IssuePrioritiesFilterSection = (props: IIssuePrioritiesFilterSectionProps) => {
	const dispatch = useDispatch();

	const issuePriorities: IssuePriority[] = useSelector(selectIssuePriorities);
	const selectedIssuePriorities: IssuePriority[] = useSelector(selectSelectedIssuePriorities);

	const checkShouldHideClearButton = (): boolean => {
		return selectedIssuePriorities.length === 0;
	};

	const handleResetIssuePriorities = (): void => {
		dispatch(resetSelectedIssuePriorities());
	};

	const addIssuePriority = (selectedIssuePriority: IssuePriority): void => {
		dispatch(addSelectedIssuePriority({ selectedIssuePriority }));
	};

	const removeIssuePriority = (removeIssuePriority: IssuePriority): void => {
		dispatch(removeSelectedIssuePriority({ removeIssuePriority }));
	};

	useEffect(() => {
		dispatch(resetSelectedIssuePriorities());
	}, []);

	return (
		<ExpandableComponent
			headerText={translationService.get('priority')}
			childrenLength={issuePriorities.length}
			clearFunction={handleResetIssuePriorities}
			type={APP_FILTER_TYPES.issuesPriorities}
			shouldHideClearButton={checkShouldHideClearButton()}
			clearLength={selectedIssuePriorities.length}
			hideEmptyList
			testId={'issuePrioritiesFilterSection'}
			defaultOpen={props.defaultOpen}
		>
			{issuePriorities.map((issuePriority: IssuePriority) => {
				const isSelected = selectedIssuePriorities.includes(issuePriority);
				return (
					<IssuePriorityFilterCard
						key={issuePriority}
						handleClick={
							isSelected
								? () => removeIssuePriority(issuePriority)
								: () => addIssuePriority(issuePriority)
						}
						isSelected={isSelected}
						count={props.prioritiesCountObj?.[issuePriority]}
						priority={issuePriority}
					/>
				);
			})}
		</ExpandableComponent>
	);
};
