import React from 'react';
import classes from './styles.module.scss';
import { TrusstorIcon } from '@src/Components/TrusstorIcon/TrusstorIcon';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { IconColor, IconSize } from '@shared/components/TrusstorIconShared/TrusstorIconShared';
import moment from 'moment/moment';
import { IMainPageWidgetActivityData } from '@interfaces/IMainPageWidgetActivityData';
import { useSelector } from 'react-redux';
import { selectTimezone } from '@store/slices/project.slice';
import { getTimezoneStartOfDate } from '@utils/dateUtils';
import { accessLevelService } from '@src/servicesInitializers';
import {
	ITrusstorIconButtonSize,
	TrusstorIconButtonDeprecated,
} from '@shared/components/buttons/TrusstorIconButton/TrusstorIconButtonDeprecated';
import { closeIcon } from '@shared/assets';
import { DateFlagDisplay } from '@shared/components/DateFlagDisplay/DateFlagDisplay';

interface IWidgetActivityCardDetailsHeaderProps {
	activity: IMainPageWidgetActivityData;
	setShowActivityMenu: () => void;
	closeAction?: () => void;
}
export const WidgetActivityCardDetailsHeader = (props: IWidgetActivityCardDetailsHeaderProps) => {
	const tz: string = useSelector(selectTimezone)!;

	const isDueDatePassed: boolean = moment.tz(props.activity.originalEndDate, tz).isBefore(getTimezoneStartOfDate(tz));
	const isAllowedToActivityActions: boolean = accessLevelService.hasAccess('workplanActivityActions');

	return (
		<div className={classes.header} data-testid="WidgetActivityCardDetailsHeader">
			<div className={classes.description} data-testid={'description'}>
				{props.activity.description}
			</div>
			<div className={classes.headerEndSection}>
				<DateFlagDisplay
					date={props.activity.originalEndDate}
					isOverdue={isDueDatePassed}
					tz={tz}
					showRedBackground
				/>
				{isAllowedToActivityActions && (
					<div
						onClick={(e) => {
							e.stopPropagation();
							props.setShowActivityMenu();
						}}
						className={classes.menuIcon}
						data-testid={'menuButton'}
					>
						<TrusstorIcon
							iconName={IconNames.dotsHorizontal}
							size={IconSize.MEDIUM}
							color={IconColor.Grey600}
						/>
					</div>
				)}
				{props.closeAction && (
					<TrusstorIconButtonDeprecated
						buttonSize={ITrusstorIconButtonSize.SMALL}
						iconSrc={closeIcon}
						onClick={props.closeAction}
					/>
				)}
			</div>
		</div>
	);
};
