import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SingleHorizontalBar } from './SingleHorizontalBar';
import { IHorizontalBarData } from './interfaces/IHorizontalBarData.interface';

const useStyle = makeStyles((theme) => ({
	barsContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		alignItems: 'start',
		width: '100%',
		height: '100%',
	},
}));

interface IHorizontalBarsProps {
	data: IHorizontalBarData[];
	maxNumber: number;
	endNumberLabel: boolean;
	barHeight: number;
	barsContainerStyleClass?: string;
	labelStyleClass?: string;
	tooltipMessage?: string;
	showPercentage?: boolean;
}

const HorizontalBars = (props: IHorizontalBarsProps) => {
	const classes = useStyle();

	const getTooltipMessage = (barData: IHorizontalBarData): string => {
		const barDataStrWithoutTrailingZeros: string = barData.number.toFixed(1).replace(/\.0$/, '');
		const percentageStr: string = props.showPercentage ? '%' : '';

		return `${props.tooltipMessage} : ${barDataStrWithoutTrailingZeros}${percentageStr}`;
	};

	return (
		<div className={`${classes.barsContainer} ${props.barsContainerStyleClass || ''}`}>
			{props.data.map((barData: IHorizontalBarData, index) => {
				const barWidthPercentage = isNaN((barData.number / props.maxNumber) * 100)
					? 100
					: (barData.number / props.maxNumber) * 100;
				return (
					<SingleHorizontalBar
						endNumberLabel={props.endNumberLabel}
						barData={barData}
						barHeight={props.barHeight}
						barWidthPercentage={barWidthPercentage}
						key={`${barData.label}_horizontal_bar_label_${index}`}
						labelStyleClass={props.labelStyleClass}
						toolTipMessage={props.tooltipMessage && getTooltipMessage(barData)}
					/>
				);
			})}
		</div>
	);
};

export { HorizontalBars };
