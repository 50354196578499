export const FONTS = {
	h1: {
		fontSize: 16,
		fontWeight: 500,
	},
	h1Light: {
		fontSize: 16,
		fontWeight: 400,
	},
	h2: {
		fontSize: 14,
		fontWeight: 500,
	},
	h2Light: {
		fontSize: 13,
		fontWeight: 400,
	},
	body1: {
		fontSize: 12,
		fontWeight: 500,
	},
	body2: {
		fontSize: 12,
		fontWeight: 400,
	},
	body3: {
		fontSize: 10,
		fontWeight: 400,
	},
};
