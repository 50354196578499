import React from 'react';
import { IProfession } from '@interfaces/index';
import classes from './styles.module.scss';
import { IssueCreateButton } from '@src/Components/Pages/IssuesPage/IssueCreateButton/IssueCreateButton';
import { useSelector } from 'react-redux';
import { selectProjectId } from '@store/slices/project.slice';
import { translationService } from '../../../../../servicesInitializers';
import { IssuesPageViewModes } from '@shared/constants/issues.contants';
import { IIssueAssignee } from '@shared/interfaces/IIssueShared';
import { selectIsLimitedUser } from '@store/slices/login.slice';
import { ProfessionDisplayWithTradeIcon } from '@shared/components/ProfessionDisplayWithTradeIcon/ProfessionDisplayWithTradeIcon';

interface IIssueColumnHeaderProps {
	assignee?: IIssueAssignee;
	profession?: IProfession;
	viewMode: IssuesPageViewModes;
	count: number;
	onPlusClick: () => void;
}

export const IssueColumnHeader = (props: IIssueColumnHeaderProps) => {
	const projectId: string = useSelector(selectProjectId)!;
	const isLimitedUser: boolean = useSelector(selectIsLimitedUser);
	const professionComponentToRender = props.profession ? (
		<div>
			<ProfessionDisplayWithTradeIcon
				contractorTextClassname={classes.headerText}
				profession={props.profession}
				projectId={projectId}
			/>
		</div>
	) : (
		<p className={classes.headerText}>{translationService.get('general')}</p>
	);

	const assigneeComponentToRender = props.assignee ? (
		<p className={classes.headerText}>{props.assignee.name}</p>
	) : (
		<p className={classes.headerText}>{translationService.get('unassigned')}</p>
	);

	return (
		<div className={classes.headerContainer}>
			<div className={classes.count}>
				{props.viewMode === IssuesPageViewModes.PROFESSIONS
					? professionComponentToRender
					: assigneeComponentToRender}
				<section>({props.count})</section>
			</div>
			{!isLimitedUser && <IssueCreateButton onClick={props.onPlusClick} />}
		</div>
	);
};
