import { IMatrixCellComponentProps } from '@src/Components/Matrix/interfaces/IMatrixCellComponentProps';
import { COLORS } from '@src/constants';
import React from 'react';
import classes from './styles.module.scss';

interface IOverviewTableCellProps extends IMatrixCellComponentProps {
	data?: number;
}

const getCellStyle = (
	percentage: number
): {
	backgroundColor: string;
	color?: string;
} => {
	if (percentage === 0) {
		return {
			backgroundColor: COLORS.white,
		};
	}
	return percentage < 100
		? {
				backgroundColor: COLORS.yellow500,
				color: COLORS.black,
			}
		: {
				backgroundColor: COLORS.green400,
				color: COLORS.white,
			};
};

export const OverviewTableCell = (props: IOverviewTableCellProps) => {
	const isDisabled: boolean = props.data === -1;
	const cellText: string = (props.data || 0) > 0 ? `${props.data}%` : '';
	const cellStyle = getCellStyle(props.data!);

	if (isDisabled) {
		return <div className={classes.stripes} />;
	}

	return (
		<div className={classes.overviewTableCell} style={cellStyle}>
			{cellText}
		</div>
	);
};
