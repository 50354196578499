import React from 'react';
import { BaseNotificationCard } from '../BaseNotificationCard/BaseNotificationCard';
import { calendarPlus } from '../../../assets/icons';
import classes from './styles.module.scss';
import { INotification } from '../../../interfaces/INotification';
import { TranslationService } from '../../../services/translation.service';
import { RequestService } from '../../../services/request.service';
import { IPlanningCreatedNotificationMetadata } from '../../../interfaces/IPlanningCreatedNotificationMetadata';
import { isTrusstorMobile } from '../../../utils/mobile.utils';

interface IPlanningCreatedCardProps {
	notification: INotification;
	translationService: TranslationService;
	tz: string;
	requester: RequestService;
	handleCardClick: (dateFrom: Date) => void;
}

export const getPlanningCreatedTitleElement = (
	metadata: IPlanningCreatedNotificationMetadata,
	translationService: TranslationService,
	isDarkTheme?: boolean
): React.ReactNode => {
	const titleClass: string = `${classes.title} ${isDarkTheme ? classes.titleDarkTheme : ''}`;

	return (
		<div className={titleClass}>
			<strong>{metadata.userDetails.name}</strong> {translationService.get('updatedThe')}
			<strong> {translationService.get('mPlanning')}</strong>.
		</div>
	);
};

export const getPlanningCreatedIconElement = (): React.ReactNode => {
	return <img className={classes.icon} src={calendarPlus} alt={''} />;
};

export const PlanningCreatedCard = (props: IPlanningCreatedCardProps) => {
	const metadata: IPlanningCreatedNotificationMetadata = props.notification
		.metadata as IPlanningCreatedNotificationMetadata;

	const handlePlanningClick = () => {
		const dateFromPlanning: Date = metadata.dateFrom!;
		props.handleCardClick(dateFromPlanning);
	};

	return (
		<BaseNotificationCard
			icon={getPlanningCreatedIconElement()}
			titleElement={getPlanningCreatedTitleElement(metadata, props.translationService)}
			createdAt={props.notification.createdAt}
			tz={props.tz}
			translationService={props.translationService}
			requester={props.requester}
			notificationId={props.notification._id}
			handleCardClick={handlePlanningClick}
			isViewed={props.notification.isViewed}
			isClickable={!isTrusstorMobile()}
		/>
	);
};
