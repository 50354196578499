import React from 'react';
import classes from './styles.module.scss';
import classnames from 'classnames';
import greyFloorsIcon from '../../assets/workplanIcons/greyFloorsIcon.svg';
import { TocOption } from '@src/Components/VerticalTOC/TocOption/TocOption';
import { useEffect, useRef, useState } from 'react';
import { compareProps } from '../../utils/generalUtils';
import { maxBy } from 'lodash';

interface IVerticalTOCProps {
	options: any[];
	selectedOption: any;
	idField?: string;
	displayField?: string;
	onChange: (option: any) => void;
	optionsWithDots?: any[];
	rootClassName?: string;
}

export const VerticalTOC = React.memo((props: IVerticalTOCProps) => {
	const tocRef = useRef<HTMLDivElement | null>(null);
	const [enableScrollIntoOption, setEnableScrollIntoOption] = useState<boolean>(true);

	const isOptionSelected = (option: any) => {
		const optionValue: any = props.idField ? option[props.idField] : option;
		const selectedOptionValue: any =
			props.idField && props.selectedOption ? props.selectedOption[props.idField] : props.selectedOption;
		return optionValue === selectedOptionValue;
	};

	const getShouldShowDot = (option: any): boolean => {
		return !!props.optionsWithDots?.includes(option);
	};

	useEffect(() => {
		tocRef.current?.addEventListener('scroll', (e) => {
			setEnableScrollIntoOption(false);
		});

		return () => {
			tocRef.current?.removeEventListener('scroll', () => {});
		};
	}, []);

	useEffect(() => {
		setEnableScrollIntoOption(true);
	}, [props.selectedOption]);

	const changeSelectedOption = (option: any) => {
		setEnableScrollIntoOption(true);
		props.onChange(option);
	};

	const longestOption = maxBy(props.options, (option) =>
		props.displayField ? option[props.displayField].length : null
	);
	const isLongerThenContainer: boolean = props.displayField ? longestOption[props.displayField].length > 2 : false;
	return (
		<div
			className={classnames(classes.tocContainer, props.rootClassName)}
			style={{ minWidth: isLongerThenContainer ? 65 : 48 }}
			ref={tocRef}
		>
			<img className={classes.icon} src={greyFloorsIcon} alt={'icon'} />
			{props.options.map((option: any) => {
				const isSelected: boolean = isOptionSelected(option);
				const shouldShowDot: boolean = getShouldShowDot(option);
				return (
					<TocOption
						isLongerThenContainer={isLongerThenContainer}
						key={!props.idField ? option : option[props.idField]}
						option={option}
						isSelected={isSelected}
						idField={props.idField}
						displayField={props.displayField}
						onChange={changeSelectedOption}
						showDot={shouldShowDot}
						enableScrollIntoOption={enableScrollIntoOption}
					/>
				);
			})}
		</div>
	);
}, compareProps);
