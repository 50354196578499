import React, { useEffect, useState } from 'react';
import classes from './styles.module.scss';
import { GeneralDialog } from '../GeneralDialog/GeneralDialog';
import { translationService } from '../../servicesInitializers';
import { IUser, IUserWithLanguage } from '@shared/interfaces/IUser';
import { EmailInputField } from '@shared/components/Inputs/EmailInputField/EmailInputField';
import { IUserPermissions } from '@shared/interfaces/IUserPermissions';
import { PhoneInputField } from '../Inputs/PhoneInputField/PhoneInputField';
import { RadioButtonComponent } from '../RadioButtonComponent/RadioButtonComponent';
import { RadioButtonTopSection } from '../RadioButtonTopSection/RadioButtonTopSection';
import { IProfession } from '@shared/interfaces/IProfession';
import { useSelector } from 'react-redux';
import { getProfessionDisplayText } from '../../utils/professions.utils';
import { isEqual, orderBy, sortBy, uniq } from 'lodash';
import { RoleType } from '@shared/interfaces/RoleType.enum';
import { intercomLinks } from '@shared/constants/constants';
import { WarningAttentionPoint } from '@shared/components/AttentionPoints/WarningAttentionPoint/WarningAttentionPoint';
import { Link } from 'react-router-dom';
import { COLORS } from '@shared/constants/colors.constants';
import { userInvitation_BI, userUpdate_BI } from '@utils/bi.utils';
import { othersTradeGroup } from '@shared/constants/professions.constants';
import { selectLanguage, selectOrganizationId, selectProjectId } from '../../store/slices/project.slice';
import { selectProfessions } from '@store/slices/professions.slice';
import { ILanguageAndDisplayName, Languages } from '@shared/constants/languages';
import { selectLoggedUserDetails } from '@store/slices/login.slice';
import { TrusstorTextInput } from '@shared/components/Inputs/TrusstorTextInput/TrusstorTextInput';
import { useUserPreferencesQuery } from '@src/hooks/queries/users.queries.hooks';
import { getTradeGroupTranslation } from '@utils/translations.utils';
import { SingleDropdown } from '@src/Components/Dropdowns/DesignSystem/SingleDropdown/SingleDropdown';

interface IUserDialogProps {
	user?: IUser;
	handleSubmitButtonClick: (user: Partial<IUser>, timeDuration?: string) => void;
	onClose: () => void;
	users: IUser[];
}

export interface IInvitedAndLoggedUser {
	invitedPermissions: IUserPermissions | undefined;
	invitedUsername: string | undefined;
	username: string;
}

export const UserDialog = (props: IUserDialogProps) => {
	const professions: IProfession[] = useSelector(selectProfessions).filter(
		(profession) => profession.tradeGroup !== othersTradeGroup
	);

	const projectId: string = useSelector(selectProjectId)!;
	const organizationId: string = useSelector(selectOrganizationId)!;
	const currentLanguage: string = useSelector(selectLanguage)!;
	const loggedUserDetails: IUser = useSelector(selectLoggedUserDetails)!;
	const [email, setEmail] = useState(props.user?.email || '');
	const [fullName, setFullName] = useState(props.user?.name || '');
	const [phoneNumberInput, setPhoneNumberInput] = useState<string>(props.user?.phoneNumber || '');
	const [isEmailValid, setIsEmailValid] = useState(true);
	const [isValidPhoneNumber, setIsValidPhoneNumber] = useState<boolean>(true);
	const [countryCode, setCountryCode] = useState<string>();
	const [language, setLanguage] = useState<string | undefined>(undefined);
	const languagesWithDisplayNames: ILanguageAndDisplayName[] = translationService.getLanguagesAndDisplayNames();
	const languages: Languages[] = languagesWithDisplayNames.map((language) => language.language);
	const userAddedPhoneNumber: boolean =
		!!phoneNumberInput && !!countryCode && phoneNumberInput.length > countryCode.length;

	const { userPreferences } = useUserPreferencesQuery(props.user?.username);

	const getInitialSelectedProfessions = (): IProfession[] | undefined => {
		return props.user?.permissions.permittedProfessionsIds?.map(
			(id) => professions.find((profession) => profession._id === id)!
		);
	};

	useEffect(() => {
		if (userPreferences) {
			setLanguage(userPreferences.language);
		}
	}, [userPreferences]);

	const [selectedProfessions, setSelectedProfessions] = useState<IProfession[] | undefined>(
		getInitialSelectedProfessions()
	);

	const [selectedRadioOptionValue, setSelectedRadioOptionValue] = useState<string | undefined>(
		props.user?.permissions.roleType
	);

	const getDoesEmailAlreadyExists = (): boolean => {
		const existingUserByEmail = props.users.find((user) => user.email === email);
		if (existingUserByEmail?.email === '') return false;
		return !!existingUserByEmail;
	};

	const isPhoneNumberEqual = (): boolean => {
		const userPhoneNumber: string | undefined = props.user?.phoneNumber;
		return phoneNumberInput && userPhoneNumber
			? phoneNumberInput.toLowerCase() === userPhoneNumber.toLowerCase()
			: (!phoneNumberInput && !userPhoneNumber) ||
					(phoneNumberInput === '' && !userPhoneNumber) ||
					(userPhoneNumber === '' && !phoneNumberInput);
	};

	const isLanguageEqual = (): boolean => {
		return language === userPreferences?.language;
	};

	const isProfessionSelectionValid = (): boolean => {
		return !isProfessionSelectionSameAsBefore() && !!selectedProfessions?.length;
	};

	const checkRoleTypeInputToEnableUpdate = (): boolean => {
		if (selectedRadioOptionValue !== props.user?.permissions.roleType && selectedRadioOptionValue) {
			if (selectedRadioOptionValue === RoleType.VIEWER) {
				return isProfessionSelectionValid();
			}
			return true;
		}

		return false;
	};

	const getPhoneNumberInputValidation = (): boolean => {
		return userAddedPhoneNumber ? isValidPhoneNumber : true;
	};

	const checkProfessionInputIfViewer = (): boolean => {
		if (selectedRadioOptionValue === RoleType.VIEWER) {
			return isProfessionSelectionValid();
		}
		return false;
	};

	const shouldEditUserBeEnabled = (): boolean => {
		return (
			(email !== props.user?.email ||
				fullName !== props.user?.name ||
				checkRoleTypeInputToEnableUpdate() ||
				!isPhoneNumberEqual() ||
				checkProfessionInputIfViewer() ||
				!isLanguageEqual()) &&
			getPhoneNumberInputValidation()
		);
	};

	const getAddUserShouldBeEnabled = (): boolean => {
		return !!(
			getPhoneNumberInputValidation() &&
			email &&
			isEmailValid &&
			!getDoesEmailAlreadyExists() &&
			fullName &&
			checkRoleTypeInputToEnableUpdate() &&
			language
		);
	};

	const sortProfessions = (professions: IProfession[]): IProfession[] => {
		return sortBy(professions, ['_id']);
	};

	const isProfessionSelectionSameAsBefore = (): boolean => {
		return isEqual(
			sortProfessions(getInitialSelectedProfessions() || []),
			sortProfessions(selectedProfessions || [])
		);
	};

	const getIsMainButtonEnabled = (): boolean => {
		if (props.user) {
			return shouldEditUserBeEnabled();
		}
		return getAddUserShouldBeEnabled();
	};

	const submitUser = (e: any, timeDuration?: string | undefined) => {
		const finalUser: Partial<IUserWithLanguage> = {
			email: email.toLowerCase(),
			username: props.user?.username.toLowerCase() || email.toLowerCase(),
			name: fullName,
			phoneNumber: userAddedPhoneNumber ? phoneNumberInput : undefined,
			permissions: {
				roleType: selectedRadioOptionValue as RoleType,
			},
			organizationIds: [organizationId],
			projectIds: Array.isArray(props.user?.projectIds)
				? uniq([...props.user!.projectIds, projectId])
				: [projectId],
			language: language! as Languages,
		};
		if (selectedRadioOptionValue === RoleType.VIEWER) {
			finalUser.permissions!.permittedProfessionsIds = selectedProfessions?.map((profession) => profession._id);
		}
		if (props.user) {
			userUpdate_BI(props.user, finalUser, timeDuration);
		} else {
			const invitedUser: IInvitedAndLoggedUser = {
				username: loggedUserDetails.username.toLowerCase() || '',
				invitedUsername: finalUser.username,
				invitedPermissions: finalUser.permissions,
			};
			userInvitation_BI(finalUser, invitedUser, selectedProfessions, timeDuration);
		}

		props.handleSubmitButtonClick(finalUser);
	};

	return (
		<div className={classes.UserDialog_container} data-testid="UserDialog">
			<GeneralDialog
				rootStyle={classes.UserDialog}
				show={true}
				title={translationService.get(props.user ? 'editUser' : 'createUser')}
				close={props.onClose}
				mainButton={{
					text: props.user
						? translationService.get('updateUser')
						: translationService.get('inviteUserAction'),
					click: submitUser,
					disabled: !getIsMainButtonEnabled(),
				}}
				secondaryButton={{
					text: translationService.get('cancel'),
					click: props.onClose,
				}}
			>
				<TrusstorTextInput
					value={fullName}
					changeFunc={setFullName}
					label={translationService.get('fullName')}
					required
				/>
				<div className={classes.topMargin}>
					<EmailInputField
						changeFunction={setEmail}
						translationService={translationService}
						required
						setIsValidEmail={setIsEmailValid}
						disabled={!!props.user}
						defaultValue={email}
						isError={!isEmailValid || (!props.user && getDoesEmailAlreadyExists())}
						errorMessage={translationService.get(
							!isEmailValid ? 'invalidEmailError' : 'emailAlreadyExists'
						)}
					/>
				</div>
				<div className={classes.topMargin}>
					<PhoneInputField
						setIsValidPhoneNumber={setIsValidPhoneNumber}
						displayError={userAddedPhoneNumber && !isValidPhoneNumber}
						phoneNumber={phoneNumberInput}
						setCountryCode={setCountryCode}
						onChangeFunction={setPhoneNumberInput}
					/>
				</div>
				<RadioButtonTopSection
					title={translationService.get('selectUserRole')}
					intercomLinkSection={{
						linkPath:
							intercomLinks.userPermissionsLinks[currentLanguage] ||
							intercomLinks.userPermissionsLinks[Languages.ENGLISH_US],
						linkText: translationService.get('learnMoreAboutRoles'),
					}}
				/>
				<RadioButtonComponent
					selectedOptionValue={selectedRadioOptionValue}
					initialDropdownValue={selectedProfessions}
					emptyDropdownStateComponent={
						<WarningAttentionPoint
							text={
								<div className={classes.warningText}>
									{translationService.get('noProfessionsAvailable1stPart')}{' '}
									<Link style={{ color: COLORS.primaryColor }} to={'/system/professions'}>
										{translationService.get('here')}
									</Link>{' '}
									{translationService.get('noProfessionsAvailable2ndPart')}
								</div>
							}
						/>
					}
					options={[
						{
							title: translationService.get(RoleType.PROJECT_ADMIN),
							description: translationService.get('adminDescription'),
							value: RoleType.PROJECT_ADMIN,
						},
						{
							title: translationService.get(RoleType.MANAGER),
							description: translationService.get('projectManagerDescription'),
							value: RoleType.MANAGER,
						},
						{
							title: translationService.get('viewerUser'),
							description: translationService.get('viewerUserDescription'),
							value: RoleType.VIEWER,
							dropdownProps: {
								translationService,
								required: true,
								isMultipleChoice: true,
								displayCheckbox: true,
								value: selectedProfessions,
								optionKey: '_id',
								secondaryOptionKey: currentLanguage,
								groupByFunction: (option: IProfession) => getTradeGroupTranslation(option),
								optionGroupProperty: 'tradeGroup',
								getOptionLabel: getProfessionDisplayText,
								optionsAreSorted: true,
								options: orderBy(
									professions,
									[
										(profession: IProfession) => profession.sortIndex,
										(profession: IProfession) => profession.tradeId,
									],
									['asc', 'asc']
								),
								errorText: translationService.get('professionTradeErrorMessage'),
								label: translationService.get('professionProvideAccessTo'),
								isSelectAllOption: true,
							},
						},
					]}
					setSelectedOption={(option) => {
						setSelectedRadioOptionValue(option.option);
						setSelectedProfessions(option.dropdownValue);
					}}
				/>
				<div className={classes.selectLanguageContainer}>
					<p className={classes.systemLanguageText}>{translationService.get('systemLanguage')}</p>
					<SingleDropdown<Languages>
						value={language as Languages}
						options={languages}
						onChange={(value) => {
							setLanguage(value!);
						}}
						placeholder={translationService.get('selectLanguage')}
						required
						hideClearTextButton
						getDisplayOption={(option) =>
							languagesWithDisplayNames.find((language) => language.language === option)!.displayName
						}
					/>
				</div>
			</GeneralDialog>
		</div>
	);
};
