import React, { useRef, useEffect } from 'react';
import classes from './styles.module.scss';
import { useSharedServices } from '../../../../hooks/sharedServices.context';

interface ICommentInputProps {
	onCommentChange: (comment: string) => void;
	rootStyle?: React.CSSProperties;
	isMobile?: boolean;
}

export const PlainTextCommentInput = (props: ICommentInputProps) => {
	const { translationService } = useSharedServices();
	const [commentText, setCommentText] = React.useState('');

	useEffect(() => {
		props.onCommentChange(commentText);
	}, [commentText]);

	const textareaRef: React.MutableRefObject<HTMLTextAreaElement | null> = useRef(null);

	useEffect(() => {
		const textarea = textareaRef.current;
		if (!textarea) return;
		if (textarea.value.trim() === '') {
			textarea.style.height = 'auto';
			textarea.style.height = props.isMobile ? '20px' : '16px';
		}
		if (textarea.scrollHeight < 40) return;
		textarea.style.height = 'auto';
		textarea.style.height = `${textarea.scrollHeight}px`;
	}, [commentText]);

	return (
		<div className={classes.textInputWrapper} style={props.rootStyle}>
			<div className={`${classes.inputBox}`}>
				<textarea
					ref={textareaRef}
					autoFocus
					value={commentText}
					onChange={(e) => setCommentText(e.target.value)}
					placeholder={translationService.get('writeYourComments')}
				/>
			</div>
		</div>
	);
};
