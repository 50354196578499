import React, { useEffect, useState } from 'react';
import classes from './styles.module.scss';
import { cM } from '@shared/utils/colors.utils';
import trusstorLogoWhite from '../../../assets/images/TopBar/trusstorLogoWhite.svg';
import trusstorLogoTextWhite from '../../../assets/images/TopBar/trusstorLogoTextWhite.svg';
import { streamService, translationService, userService } from '../../../index';
import { IProject } from '@shared/interfaces/IProject';
import { useOrganizationLogoQuery } from '../../../hooks/queries/images.queries.hooks';
import { IUser } from '@shared/interfaces/IUser';
import { ProjectCard } from './ProjectCard/ProjectCard';
import { uniqBy } from 'lodash';
import { IProjectMilestones } from '@shared/interfaces/IProjectMilestones';
import { useExecutiveViewEnabledProjectsQuery } from '@src/hooks/queries/executiveView.queries.hooks';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectLoggedUserDetails } from '@store/slices/login.slice';
import { useRenderMonitoring } from '@shared/hooks/monitoring.hooks';

const ExecutiveViewPage = () => {
	useRenderMonitoring('ExecutiveViewPage');
	const loggedUserDetails: IUser = useSelector(selectLoggedUserDetails)!;
	const organizationId: string | undefined = loggedUserDetails?.organizationIds[0];
	const { organizationLogo } = useOrganizationLogoQuery(organizationId);
	const { projects }: { projects: IProject[] } = useExecutiveViewEnabledProjectsQuery(organizationId);
	const [projectsMilestones, setProjectsMilestones] = useState<IProjectMilestones[]>([]);
	const history = useHistory();

	useEffect(() => {
		if (!window.location.href.includes('projects')) {
			history.push(`/projects`);
		}
		userService.setPreviousLoginExistsAtt(true);
	}, [window.location.href]);

	useEffect(() => {
		if (projects?.length) {
			openMilestonesStream();
		}
		return () => {
			closeMilestoneStream();
		};
	}, [projects?.length]);

	const openMilestonesStream = () => {
		const projectIds: string = projects.map((project) => project.projectId).join(',');
		let totalProjectMilestones: IProjectMilestones[] = [];
		streamService.openStream(
			`/activities/milestones/projectsMilestones/stream?organizationId=${projects?.[0].organizationId}&projectIds=${projectIds}`,
			(data: any) => {
				const milestonesStreamData: IProjectMilestones[] = JSON.parse(data.data);
				totalProjectMilestones = uniqBy([...milestonesStreamData, ...totalProjectMilestones], 'projectId');
				setProjectsMilestones(totalProjectMilestones);
			}
		);
	};

	const closeMilestoneStream = () => {
		streamService.closeStreamIncludes(`/activities/milestones`);
	};

	const getProjectMilestonesByProjectId = (projectId: string): IProjectMilestones | undefined => {
		return projectsMilestones.find(
			(projectMilestones: IProjectMilestones) => projectMilestones.projectId === projectId
		);
	};

	return (
		<div className={cM(classes.executivePageContainer, classes)}>
			<div className={cM(classes.topBar, classes)}>
				<div className={cM(classes.trusstorLogoContainer, classes)}>
					<img src={trusstorLogoWhite} alt="Trusstor logo" className={classes.trusstorLogo} />
					<img src={trusstorLogoTextWhite} alt="Trusstor logo text" className={classes.trusstorLogoText} />
				</div>
				<div className={classes.companyLogoContainer}>
					<img src={organizationLogo} className={classes.companyLogo} />
				</div>
			</div>
			<div className={cM(classes.contentContainer, classes)}>
				<div className={cM(classes.title, classes)}>{`${translationService.get('welcome')} ${
					loggedUserDetails?.name
				}`}</div>
				<div className={cM(classes.content, classes)}>
					<div className={cM(classes.contentTitle, classes)}>{translationService.get('yourProjects')}</div>
					<div className={cM(classes.cardsSection, classes)}>
						{projects?.map((project: IProject) => (
							<ProjectCard
								project={project}
								key={project.projectId}
								projectMilestones={getProjectMilestonesByProjectId(project.projectId)}
							/>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export { ExecutiveViewPage };
