import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { IIssue } from '@shared/interfaces/IIssue';
import { deleteIssueApi, updateIssueApi } from '@src/apis/issues.api';
import { requestService, storageService, translationService } from '@src/servicesInitializers';
import { useDispatch, useSelector } from 'react-redux';
import { selectProjectId, selectTimezone } from '@store/slices/project.slice';
import emptyStarIcon from '@shared/assets/icons/emptyStar.svg';
import { IIssueShared, IssuePriority, IssueStatus } from '@shared/interfaces/IIssueShared';
import fullStartIcon from '@shared/assets/icons/fullStar.svg';
import { IProfession } from '@shared/interfaces/IProfession';
import { errorSnackbar, successSnackbar } from '@utils/snackbar.utils';
import { IUser } from '@shared/interfaces/IUser';
import { selectLoggedUserDetails } from '@store/slices/login.slice';
import { IssueDesktopViewSection } from '@shared/components/Issue/IssueDesktopDialog/IssueDesktopViewSection';
import classes from './styles.module.scss';
import { TrusstorIcon } from '@src/Components/TrusstorIcon/TrusstorIcon';
import helmet from '@shared/assets/icons/helmet.svg';
import { closeIcon } from '@shared/assets';
import classNames from 'classnames';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { IconColor } from '@shared/components/TrusstorIconShared/TrusstorIconShared';
import {
	ITrusstorIconButtonSize,
	ITrusstorIconButtonStyle,
	TrusstorIconButtonDeprecated,
} from '@shared/components/buttons/TrusstorIconButton/TrusstorIconButtonDeprecated';
import { IssueDesktopEditDialog } from '@src/Components/Pages/IssuesPage/IssueDesktopEditDialog/IssueDesktopEditDialog';
import { IConfigArea } from '@shared/interfaces/IConfigArea';
import { useProjectAreasQuery } from '@src/hooks/queries/areas.queries.hooks';
import { issueStatusChange_BI } from '@utils/bi.utils';
import { IssueReviewDialog } from '@shared/components/Issue/IssueReviewDialog/IssueReviewDialog';
import { ProfessionDisplayWithTradeIcon } from '@shared/components/ProfessionDisplayWithTradeIcon/ProfessionDisplayWithTradeIcon';

interface IMultiIssuesViewDialogProps {
	show: boolean;
	close: () => void;
	issues: IIssue[];
	headerInfo: {
		value: string;
		classname?: string;
		backgroundColor?: string;
	};
	headerTitle: string;
	onUpdateIssue: (updatedIssue: IIssue, deleted?: boolean) => void;
}

export const MultiIssuesViewDialog = (props: IMultiIssuesViewDialogProps) => {
	const tz: string = useSelector(selectTimezone)!;
	const projectId: string = useSelector(selectProjectId)!;
	const user: IUser = useSelector(selectLoggedUserDetails)!;
	const isLimitedUser: boolean = user.permissions.roleType === 'VIEWER';
	const [showEditDialog, setShowEditDialog] = useState<boolean>(false);
	const [displayIndex, setDisplayIndex] = useState<number>(0);
	const [issuesToDisplay, setIssuesToDisplay] = useState<IIssue[]>(props.issues);
	const [starIcon, setStarIcon] = useState<string>(
		issuesToDisplay[displayIndex]?.priority === IssuePriority.CRITICAL ? fullStartIcon : emptyStarIcon
	);
	const [showReviewDialog, setShowReviewDialog] = useState<boolean>(false);
	const dispatch = useDispatch();
	const language: string = translationService.getChosenLanguage();
	const currentIssue: IIssue = issuesToDisplay[displayIndex];
	const issuesProfession: IProfession | undefined = issuesToDisplay[0]?.profession;
	const projectAreas: IConfigArea[] = useProjectAreasQuery(projectId);

	useEffect(() => {
		setIssuesToDisplay(props.issues);
	}, [props.issues.length]);

	useEffect(() => {
		setStarIcon(currentIssue?.priority === IssuePriority.CRITICAL ? fullStartIcon : emptyStarIcon);
	}, [displayIndex, issuesToDisplay]);
	const handleMouseLeave = (event: React.MouseEvent<HTMLDivElement>) => {
		event.stopPropagation();
	};
	const handleMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
		event.stopPropagation();
	};

	const deleteIssue = async () => {
		const issueId: string = currentIssue._id;
		const issuesBeforeDelete: IIssue[] = [...issuesToDisplay];
		const indexToDisplayBeforeDelete: number = displayIndex;
		try {
			setDisplayIndex((prevState) => (prevState === 0 ? 0 : prevState - 1));
			setIssuesToDisplay((prevIssues) => prevIssues!.filter((issue) => issue._id !== issueId));
			await deleteIssueApi(issueId);
			props.onUpdateIssue(issuesBeforeDelete.find((issue) => issue._id === issueId)!, true);
			if (issuesBeforeDelete.length === 1) {
				props.close();
			} else {
				setDisplayIndex((prevState) => (prevState === 0 ? 0 : prevState - 1));
			}
			successSnackbar(dispatch, translationService.get('issueDeletedSuccessfully'));
		} catch (e) {
			setIssuesToDisplay(issuesBeforeDelete);
			setDisplayIndex(indexToDisplayBeforeDelete);
			console.error(e);
			errorSnackbar(dispatch, translationService.get('failedToDelete'));
		}
	};

	const updateIssue = async (
		issueId: string,
		issueToUpdate: Partial<IIssue>,
		updateBackend: boolean = true
	): Promise<IIssue> => {
		const issueBeforeUpdate: IIssue = issuesToDisplay.find((issue) => issue._id === issueId)!;
		const statusUpdatedData: Partial<IIssue> = {
			statusUpdateDate: new Date(),
			statusUpdateUser: {
				name: user.name,
				username: user.username,
			},
		};
		const isStatusUpdated: boolean = !!(issueToUpdate.status && issueToUpdate.status !== issueBeforeUpdate.status);

		const optimisticUpdatedIssue: IIssue = {
			...issueBeforeUpdate,
			...issueToUpdate,
			...(isStatusUpdated && statusUpdatedData),
			isInitial: false,
		};

		setIssuesToDisplay((prevIssues) => {
			return prevIssues.map((issue) => {
				if (issue._id === issueId) {
					return {
						...issue,
						...optimisticUpdatedIssue,
					};
				}
				return issue;
			});
		});

		props.onUpdateIssue(optimisticUpdatedIssue);

		if (!updateBackend) {
			return optimisticUpdatedIssue;
		}

		try {
			const updatedIssue: IIssue = await updateIssueApi(issueId, issueToUpdate);
			return updatedIssue;
		} catch (e) {
			console.error(e);
			errorSnackbar(dispatch, translationService.get('failedToUpdateGeneric'));
			setIssuesToDisplay((prevIssues) => {
				return prevIssues!.map((issue) => {
					if (issue._id === issueId) {
						return {
							...issue,
							...issueBeforeUpdate,
						};
					}
					return issue;
				});
			});
			return {
				...issueBeforeUpdate,
			};
		}
	};

	const handleSizeLimitImageError = () => {
		errorSnackbar(dispatch, translationService.get('imageAboveTheSizeLimitError'));
	};

	const toggleStarIcon = () => {
		setStarIcon((prev) => (prev === fullStartIcon ? emptyStarIcon : fullStartIcon));
	};

	const handlePriority = async (issueId: string) => {
		toggleStarIcon();
		const updatedPriority: IssuePriority =
			currentIssue.priority === IssuePriority.CRITICAL ? IssuePriority.REGULAR : IssuePriority.CRITICAL;
		await updateIssue(issueId, { priority: updatedPriority });
	};

	const handleStatusChange = async (updatedStatus: IssueStatus, issueId: string) => {
		try {
			await updateIssue(issueId, {
				status: updatedStatus,
			});
			issueStatusChange_BI(currentIssue, updatedStatus, tz);
		} catch (e) {
			console.log(e);
		}
	};

	const saveImage = async (image: File) => {
		const sizeLimit: number = 1e7; // 10MB
		if (image.size > sizeLimit) {
			handleSizeLimitImageError();
			return;
		}
		const formData = new FormData();
		formData.append('value', image);
		formData.append('name', user.name);
		formData.append('username', user.username);
		formData.append('type', 'image');

		const issueUpdated: IIssueShared<IProfession> = await requestService.put(
			`/issues/${currentIssue._id}/comments`,
			{ body: formData }
		);
		await updateIssue(currentIssue._id, issueUpdated, false);
	};

	const saveComment = async (comment: string) => {
		const formData = new FormData();
		formData.append('name', user.name);
		formData.append('username', user.username);
		formData.append('value', comment);
		formData.append('type', 'text');

		const issueUpdated: IIssueShared<IProfession> = await requestService.put(
			`/issues/${currentIssue._id}/comments`,
			{
				body: formData,
			}
		);

		await updateIssue(currentIssue._id, issueUpdated, false);
	};

	const displayNextIssue = () => {
		if (displayIndex < issuesToDisplay.length - 1) {
			setDisplayIndex((prevState) => prevState + 1);
		}
	};

	const displayPreviousIssue = () => {
		if (displayIndex > 0) {
			setDisplayIndex((prevState) => prevState - 1);
		}
	};

	const isRtl: boolean = translationService.getIsRtl();

	if (!issuesToDisplay.length) {
		return null;
	}

	const openEditFunction = () => {
		setShowEditDialog(true);
	};

	const closeEdit = () => {
		setShowEditDialog(false);
	};

	const handleReviewCallback = () => {
		setShowReviewDialog(true);
	};

	const handleApproveClick = async () => {
		setShowReviewDialog(false);
		await updateIssue(currentIssue._id, {
			status: IssueStatus.COMPLETED,
		});
	};

	const handleRejectClick = async (comment: string) => {
		setShowReviewDialog(false);
		await saveComment(comment);
		await updateIssue(currentIssue._id, {
			status: IssueStatus.REJECTED,
		});
	};

	return (
		<>
			{showReviewDialog && (
				<IssueReviewDialog
					issue={currentIssue}
					handleRejectClick={handleRejectClick}
					handleApproveClick={handleApproveClick}
					onClose={() => setShowReviewDialog(false)}
				/>
			)}
			{showEditDialog ? (
				<IssueDesktopEditDialog
					projectAreas={projectAreas}
					closeDialog={closeEdit}
					issue={currentIssue}
					updateIssue={updateIssue}
					translationService={translationService}
				/>
			) : (
				<Dialog
					onMouseLeave={handleMouseLeave}
					onMouseEnter={handleMouseEnter}
					onClose={props.close}
					maxWidth={false}
					open={props.show}
					classes={{
						paper: classes.dialog,
					}}
				>
					<div className={classes.header}>
						<div className={classes.headerTab}>
							<div
								style={
									props.headerInfo.backgroundColor
										? { backgroundColor: props.headerInfo.backgroundColor }
										: {}
								}
								className={classNames(props.headerInfo.classname, classes.headerInfo)}
							>
								{props.headerInfo.value}
							</div>
							<TrusstorIconButtonDeprecated
								buttonSize={ITrusstorIconButtonSize.SMALL}
								iconSrc={closeIcon}
								onClick={props.close}
							/>
						</div>
						<div className={classes.infoSection}>
							<div className={classes.description}>{props.headerTitle}</div>
							{issuesProfession && (
								<div className={classes.professionSection}>
									<img src={helmet} alt="" />
									<div>
										<ProfessionDisplayWithTradeIcon
											profession={issuesProfession}
											projectId={projectId}
										/>
									</div>
								</div>
							)}
						</div>
					</div>
					<div className={classes.issuesContainer}>
						<div className={classes.issuesHeader}>
							<TrusstorIconButtonDeprecated
								onClick={displayPreviousIssue}
								style={ITrusstorIconButtonStyle.OUTLINE}
								iconClassName={classes.arrow}
								disabled={displayIndex === 0}
								iconElement={
									<TrusstorIcon
										color={displayIndex === 0 ? IconColor.Grey100 : IconColor.DarkBlue}
										iconName={isRtl ? IconNames.chevronRight : IconNames.chevronLeft}
									/>
								}
							/>
							{`${translationService.get('issues')} ${
								issuesToDisplay.length > 1 ? `${displayIndex + 1}/${issuesToDisplay.length}` : ''
							}`}
							<TrusstorIconButtonDeprecated
								onClick={displayNextIssue}
								style={ITrusstorIconButtonStyle.OUTLINE}
								iconClassName={classes.arrow}
								disabled={displayIndex === issuesToDisplay.length - 1}
								iconElement={
									<TrusstorIcon
										color={
											displayIndex === issuesToDisplay.length - 1
												? IconColor.Grey100
												: IconColor.DarkBlue
										}
										iconName={isRtl ? IconNames.chevronLeft : IconNames.chevronRight}
									/>
								}
							/>
						</div>
						<div className={classes.issue}>
							<IssueDesktopViewSection
								handleReviewCallback={handleReviewCallback}
								isLimitedUser={isLimitedUser}
								openEditFunction={openEditFunction}
								requestService={requestService}
								deleteIssue={deleteIssue}
								close={props.close}
								issue={currentIssue}
								translationService={translationService}
								tz={tz}
								projectId={projectId}
								priorityIcon={starIcon}
								handlePriority={handlePriority}
								storageService={storageService}
								updateIssue={updateIssue}
								saveComment={saveComment}
								saveImage={saveImage}
								hideCloseButton
								hideProfession
								handleStatusChange={handleStatusChange}
							/>
						</div>
					</div>
				</Dialog>
			)}{' '}
		</>
	);
};
