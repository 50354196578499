import React from 'react';
import { IObserveLocationApiResult } from '@shared/interfaces/IObserveLocationApiResult';
import tagIcon from '@shared/assets/icons/tag.svg';
import { getFullDayHoursArray } from '@utils/dateUtils';
import { Loader } from '@shared/components/Loader/Loader';
import { translationService } from '@src/index';
import { IPeriod, IPeriodGraphRowData, PeriodsGraph } from '@src/Components/PeriodsGraph/PeriodsGraph';
import { getTagName } from '@utils/tags.utils';
import { IMergedTag } from '@interfaces/IMergedTag';
import { getRoundedHoursFromMsByStep } from '@shared/utils/roundNumbers.utils';
import { ITagWorkPeriods } from '@shared/interfaces/ITagWorkPeriods';
import { IWorkerMergedTag } from '@interfaces/IWorkerMergedTag';
import { IManagerMergedTag } from '@shared/interfaces/IManagerMergedTag';
import { COLORS } from '@shared/constants/colors.constants';
import { getProfessionBackgroundColor } from '@shared/utils/professions.utils';
import { useSelector } from 'react-redux';
import { selectProjectId } from '@store/slices/project.slice';
import { getTradeTranslation } from '@utils/translations.utils';

interface ILocationPersonnelProps {
	observeLocationReport: ITagWorkPeriods[];
}

const LocationPersonnel = (props: ILocationPersonnelProps) => {
	const projectId: string = useSelector(selectProjectId)!;
	const chosenLanguage: string = translationService.getChosenLanguage();

	const getTagTotalTime = (personnel: ITagWorkPeriods): string => {
		const totalWorkPeriodTimeInMs: number = personnel.workPeriods.reduce((acc, workPeriod) => {
			return acc + (workPeriod.endTimestamp - workPeriod.startTimestamp);
		}, 0);
		return `${getRoundedHoursFromMsByStep(totalWorkPeriodTimeInMs, 0.1)}h`;
	};

	const getTagPhoneNumber = (personnel: ITagWorkPeriods): string | undefined => {
		const { tag } = personnel;
		if ((tag as IManagerMergedTag).manager) {
			return (tag as IManagerMergedTag).manager.phoneNumber || '';
		}
		return (tag as IWorkerMergedTag).phoneNumber;
	};

	const getPeriodGraphData = (): IPeriodGraphRowData[] => {
		if (!props.observeLocationReport) return [];
		return props.observeLocationReport.map((personnel: ITagWorkPeriods) => {
			return {
				periods: personnel.workPeriods as IPeriod[],
				titleText: personnel.tag.tagNick,
				extraTitleText: getTagName(personnel.tag as IMergedTag),
				secondTitleText: getTagTotalTime(personnel),
				titleInfoText: getTradeTranslation((personnel.tag as IWorkerMergedTag)?.profession),
				secondTitleInfoText: getTagPhoneNumber(personnel),
				color:
					getProfessionBackgroundColor((personnel.tag as IWorkerMergedTag)?.profession, projectId) ||
					COLORS.primaryColor,
			};
		});
	};

	if (!props.observeLocationReport) {
		return <Loader />;
	}
	return (
		<PeriodsGraph
			graphTitles={getFullDayHoursArray(2)}
			graphCubes={24}
			icon={tagIcon}
			mainTitle={translationService.get('personnel')}
			subTitle={translationService.get('totalTime')}
			periodGraphRowData={getPeriodGraphData()}
		/>
	);
};

export { LocationPersonnel };
