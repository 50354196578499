import { useQuery } from '@tanstack/react-query';
import { requestService } from '../../index';

export const useBuildingSvgQuery = (projectId: string): { buildingSvg: string; isLoadingSvg: boolean } => {
	const {
		data: buildingSvg,
		isLoading: isLoadingSvg,
		isFetching,
	} = useQuery<string>(
		['buildingSvg', projectId],
		() => requestService.get(`/storage/projects/${projectId}/buildingView`),
		{
			useErrorBoundary: false,
			initialData: '',
			cacheTime: 1000 * 60 * 60 * 24,
		}
	);
	return { buildingSvg, isLoadingSvg: isLoadingSvg || isFetching };
};
