import { cM } from '@shared/utils/colors.utils';
import React from 'react';
import classes from './styles.module.scss';
import { ITag } from '@interfaces/ITag';
import { IWorkerTimesheet } from '@shared/interfaces/IWorkerTimesheet';
import { WorkerCard } from '../WorkerCard/WorkerCard';
import { useSelector } from 'react-redux';
import { selectTags } from '@store/slices/tags.slice';

interface IWorkersSectionProps {
	tradeName: string;
	timesheetList: { tagId: string; timesheet: IWorkerTimesheet[] }[];
	handleTagClick: (tagId: string) => void;
	selectedWorkerId: string | null;
}

export const WorkersGroupedSection = (props: IWorkersSectionProps) => {
	const tags = useSelector(selectTags);

	return (
		<div>
			<div className={cM(classes.tradeTitle, classes)}>{props.tradeName}</div>
			<div className={cM(classes.workerList, classes)}>
				{props.timesheetList.map((tagTimesheet) => {
					const fullTag: ITag | undefined = tags.find((tag) => tag.tagId === tagTimesheet.tagId);
					if (!fullTag) return;
					return (
						<WorkerCard
							tag={fullTag}
							isSelected={props.selectedWorkerId === fullTag.tagId}
							handleTagClick={props.handleTagClick}
						/>
					);
				})}
			</div>
		</div>
	);
};
