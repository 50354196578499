import React, { useCallback, useContext } from 'react';
import { IAssignedActivityArea } from '@shared/interfaces/IAssignedActivityArea';
import classes from './styles.module.scss';
import { IMainPageWidgetActivityData } from '../../../../../interfaces/IMainPageWidgetActivityData';
import { WidgetAreaCard } from './WidgetAreaCard/WidgetAreaCard';
import { sortByAreas } from '../../../../../../../shared/utils/sort.utils';
import { WidgetContext } from '../../widgetContext.context';
import { IAreaSequenceItem } from '@shared/interfaces/IAreaSequenceItem';
import { AreaSequenceItemStatus } from '@shared/interfaces/AreaSequenceItemStatus.enum';
import { SORT_ORDER } from '@shared/constants/constants';
import { Dictionary, groupBy } from 'lodash';

interface IWeeklyWidgetCardSequenceAreasProps {
	activity: IMainPageWidgetActivityData;
}

const getWidgetCardWidth = (asis: IAreaSequenceItem[]): number => {
	if (asis.length >= 5) return 86;
	if (asis.length < 4) return 150;
	return 110;
};

export const WeeklyWidgetCardSequenceAreas = (props: IWeeklyWidgetCardSequenceAreasProps) => {
	const { onActivityChange } = useContext(WidgetContext)!;

	const sortedAreaSequenceItems: IAreaSequenceItem[] = sortByAreas(
		props.activity.areaSequenceItems,
		SORT_ORDER.ASCENDING,
		'area'
	);

	const groupedAreaSequenceItemsByFloorNick: Dictionary<IAreaSequenceItem[]> = groupBy(
		sortedAreaSequenceItems,
		(areaSequenceItem) => areaSequenceItem.area.floorNick
	);

	const onStatusChange = useCallback(
		(isDone: boolean, areaSequenceItem: IAreaSequenceItem, statusToUpdate?: AreaSequenceItemStatus): void => {
			const areaSequenceItems: IAreaSequenceItem[] = props.activity.areaSequenceItems.map(
				(areaSequenceItemToMap) => {
					if (areaSequenceItemToMap._id === areaSequenceItem._id) {
						const finalStatus: AreaSequenceItemStatus =
							statusToUpdate ||
							(isDone ? AreaSequenceItemStatus.complete : AreaSequenceItemStatus.delayed);
						return {
							...areaSequenceItemToMap,
							status: finalStatus,
						};
					}
					return areaSequenceItemToMap;
				}
			);

			onActivityChange?.({ ...props.activity, areaSequenceItems });
		},
		[props.activity, onActivityChange]
	);

	return (
		<div className={classes.sequenceProgressCardsContainer}>
			{Object.entries(groupedAreaSequenceItemsByFloorNick).map(([floorNick, asis]) => {
				const widgetCardWidth: number = getWidgetCardWidth(asis);
				const completedCount: number = asis.filter(
					(asi: IAreaSequenceItem) => asi?.status === AreaSequenceItemStatus.complete
				).length;
				return (
					<div className={classes.floorAsisWrapper}>
						<div className={classes.floorTitleSection}>
							<div className={classes.floorNick}>{floorNick}</div>
							<div className={classes.count}>{`${completedCount}/${asis.length}`}</div>
						</div>
						<div className={classes.floorAsis}>
							{asis.map((areaSequenceItem: IAreaSequenceItem) => {
								const area: IAssignedActivityArea | undefined = props.activity.areas.find(
									(area: IAssignedActivityArea) => area.areaSequenceItemId === areaSequenceItem._id
								);
								if (!area) return null;
								return (
									<div className={classes.widgetCard} style={{ width: widgetCardWidth }}>
										<WidgetAreaCard
											description={props.activity.description}
											profession={props.activity.profession}
											key={area.areaId}
											areaSequenceItem={areaSequenceItem}
											area={area}
											onStatusChange={onStatusChange}
										/>
									</div>
								);
							})}
						</div>
					</div>
				);
			})}
		</div>
	);
};
