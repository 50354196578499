import React, { useEffect, useRef, useState } from 'react';
import classes from './styles.module.scss';
import { translationService, workplanLocalStorageService } from '@src/index';
import calendarPlusIconBlack from '@assets/investigationReport/calendarPlusIconBlack.svg';
import { WorkplanPlanningDateMenu } from '@src/Components/Pages/Workplan/WorkplanPlanningDialog/WorkplanPlanningPeriods/WorkplanPlanningDateMenu/WorkplanPlanningDateMenu';
import { useSelector } from 'react-redux';
import { selectProjectId, selectTimezone } from '@store/slices/project.slice';
import { getNextWeekStartEndDates } from '@shared/utils/dateUtils';
import { IWorkDayHours } from '@shared/interfaces/IWorkDayHours';
import { IRootState } from '@store/slices';
import { WorkplanPeriodFixedOptionTypes } from '@src/services/workplanLocalStorage.service';

interface IWorkplanPlanningPeriodsProps {
	onChange: (startDate: Date, endDate: Date) => void;
}

export const WorkplanPlanningPeriods = (props: IWorkplanPlanningPeriodsProps) => {
	const tz: string = useSelector(selectTimezone)!;
	const projectId: string = useSelector(selectProjectId)!;
	const defaultPlanningPeriod: WorkplanPeriodFixedOptionTypes | null =
		workplanLocalStorageService.getLastPlaningPeriodOption(projectId);
	const workDayHours: IWorkDayHours[] = useSelector(
		(state: IRootState) => state.projectReducer.workingProject!.workDayHours
	);
	const oneWeekStartEndDates: { startDate: Date; endDate: Date } = getNextWeekStartEndDates(workDayHours, tz, 1);
	const twoWeekStartEndDates: { startDate: Date; endDate: Date } = getNextWeekStartEndDates(workDayHours, tz, 2);

	const getDefaultStartDate = (): Date => {
		if (defaultPlanningPeriod === WorkplanPeriodFixedOptionTypes.nextWeek) {
			return oneWeekStartEndDates.startDate;
		}
		if (defaultPlanningPeriod === WorkplanPeriodFixedOptionTypes.nextTwoWeeks) {
			return twoWeekStartEndDates.startDate;
		}
		return oneWeekStartEndDates.startDate;
	};

	const getDefaultEndDate = (): Date => {
		if (defaultPlanningPeriod === WorkplanPeriodFixedOptionTypes.nextWeek) {
			return oneWeekStartEndDates.endDate;
		}
		if (defaultPlanningPeriod === WorkplanPeriodFixedOptionTypes.nextTwoWeeks) {
			return twoWeekStartEndDates.endDate;
		}
		return oneWeekStartEndDates.endDate;
	};
	const [startDate, setStartDate] = useState<Date | undefined>(getDefaultStartDate());
	const [endDate, setEndDate] = useState<Date | undefined>(getDefaultEndDate());

	const handleDateChosen = (fixedOptionItemId?: WorkplanPeriodFixedOptionTypes) => {
		if (fixedOptionItemId) {
			workplanLocalStorageService.setLastPlaningPeriodOption(projectId, fixedOptionItemId);
		}
	};

	const onDateChange = (startDate?: Date, endDate?: Date, fixedOptionItemId?: string) => {
		startDate && setStartDate(startDate);
		endDate && setEndDate(endDate);

		handleDateChosen(fixedOptionItemId as WorkplanPeriodFixedOptionTypes);
	};

	useEffect(() => {
		if (startDate && endDate) {
			props.onChange(startDate, endDate);
		}
	}, [startDate, endDate]);

	return (
		<>
			<div className={classes.periodContainer}>
				<div className={classes.textSection}>
					<img className={classes.calendarIcon} src={calendarPlusIconBlack} alt={''} />
					<div className={classes.title}>{translationService.get('planningFor')}</div>
				</div>
				<div className={classes.inputContainer}>
					<WorkplanPlanningDateMenu
						onChange={onDateChange}
						oneWeekDateRanges={oneWeekStartEndDates}
						twoWeekDateRanges={twoWeekStartEndDates}
						startDate={startDate}
						endDate={endDate}
					/>
				</div>
			</div>
		</>
	);
};
