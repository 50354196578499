import React from 'react';
import classes from './styles.module.scss';
import classnames from 'classnames';
import { translationService } from '@src/servicesInitializers';

export const ManagerDisplayCard = () => {
	return (
		<div className={classes.ProfessionDisplayCard_container} data-testid="professionDisplayCard">
			<div className={classes.headerWrapper}>
				<div className={classnames(classes.item, classes.trade)}>{translationService.get('Managers')}</div>
			</div>
		</div>
	);
};
