import React, { useContext, useState } from 'react';
import classes from './styles.module.scss';
import radioButtonIcon from '../../../../assets/radio-button.svg';
import radioButtonSelectedIcon from '../../../../assets/radio-button-selected.svg';
import radioButtonHoveredIcon from '../../../../assets/radio-button-hover.svg';
import arrowLeft from '@src/assets/arrow-left.svg';
import arrowRight from '@src/assets/arrow-right.svg';
import { translationService } from '../../../../index';
import { TrusstorIcon } from '@src/Components/TrusstorIcon/TrusstorIcon';
import { ITrusstorButtonType, TrusstorButton } from '@shared/components/buttons/TrusstorButton/TrusstorButton';
import { AnalysisCenterPageContext } from '@src/Components/Pages/AnalysisCenterPages/AnalysisCenterPage/AnalysisCenterPage';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { IconSize } from '@shared/components/TrusstorIconShared/TrusstorIconShared';

interface IReportCardProps {
	name: string;
	isSelected: boolean;
	isLoading?: boolean;
	buttonText?: string;
	icon: IconNames;
	title: string;
	description: string;
	setSelectedCard: (name: string) => void;
	children?: React.ReactNode;
	generateReport: () => void;
	isGenerateReportDisabled: boolean;
}

export const ReportCard = (props: IReportCardProps) => {
	const isRtl: boolean = translationService.getIsRtl();
	const [isCardHover, setIsCardHover] = useState<boolean>(false);

	const handleMouseEnter = () => {
		!props.isSelected && setIsCardHover(true);
	};

	const handleMouseLeave = () => {
		!props.isSelected && setIsCardHover(false);
	};

	const handleCardClick = (): void => {
		setIsCardHover(false);
		props.setSelectedCard(props.name);
	};

	const radioButtonIconToDisplay = props.isSelected
		? radioButtonSelectedIcon
		: isCardHover
			? radioButtonHoveredIcon
			: radioButtonIcon;

	return (
		<div style={props.isSelected ? { zIndex: 10 } : {}} className={classes.reportCardWrapper}>
			<section
				className={`${classes.reportCardContainer} ${props.isSelected ? classes.selectedCard : ''} ${
					isCardHover ? classes.hoveredCard : ''
				}`}
				onClick={handleCardClick}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
			>
				<div className={classes.header}>
					<div className={classes.title}>
						<TrusstorIcon iconName={props.icon} size={IconSize.LARGE} />
						<div className={classes.titleText}>{props.title}</div>
					</div>
					<img className={classes.topCircle} src={radioButtonIconToDisplay} alt="" />
				</div>
				{!props.isSelected ? (
					<section className={classes.descriptionContainer}>
						<div className={classes.descriptionText}>{props.description}</div>
					</section>
				) : (
					<section className={classes.inputsContainer}>
						<div className={classes.input}>{props.children}</div>
						<TrusstorButton
							showLoader={props.isLoading}
							text={props.buttonText || translationService.get('create')}
							buttonType={ITrusstorButtonType.PRIMARY}
							handleClick={props.generateReport}
							endIcon={isRtl ? arrowLeft : arrowRight}
							className={classes.createButton}
							disabled={props.isGenerateReportDisabled}
						/>
					</section>
				)}
			</section>
		</div>
	);
};
