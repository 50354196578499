import { IDashboardTabItem } from '@interfaces/IDashboardTabItem';
import React from 'react';
import classes from './styles.module.scss';
import { TooltipedIcon } from '../../../Tooltips/TooltipedIcon';
import { TrusstorIcon } from '../../../TrusstorIcon/TrusstorIcon';

interface IDashboardTabProps extends IDashboardTabItem {
	isSelected: boolean;
	selectTab: () => void;
}

export const DashboardTab = (props: IDashboardTabProps) => {
	return (
		<div
			className={`${classes.tabContainer} ${props.isSelected ? classes.selectedItem : ''}`}
			onClick={props.selectTab}
			data-testid={`dashboardTab&selected=${props.isSelected}&tabType=${props.type}`}
		>
			<div className={classes.itemsContainer}>
				{props.items.map((item, index) => {
					return (
						<div key={index}>
							<div className={classes.item}>
								<div
									className={`${classes.iconValueContainer} ${
										props.isSelected ? classes.selectedItemText : ''
									}`}
								>
									{item.tooltipText ? (
										<TooltipedIcon tooltipText={item.tooltipText}>
											<div>
												<TrusstorIcon iconName={item.icon} />
											</div>
										</TooltipedIcon>
									) : (
										<TrusstorIcon iconName={item.icon} />
									)}
									{item.value !== undefined && (
										<p
											className={classes.valueText}
											data-testid={`tabValueText${
												item.tooltipText ? '&text=' + item.tooltipText : ''
											}`}
										>
											{item.value}
										</p>
									)}
								</div>
							</div>
							{index !== props.items.length - 1 && <div className={classes.divider} />}
						</div>
					);
				})}
			</div>
			<p className={classes.tabLabel} data-testid={'tabLabel'}>
				{props.label}
			</p>
		</div>
	);
};
