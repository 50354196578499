import { IProfession } from '@interfaces/index';
import { IProfessionDisplaySettings, ProfessionSettingType } from '@shared/interfaces/IProfessionDisplaySettings';

export const getDefaultProfessionDisplaySettings = (
	profession: IProfession,
	projectId: string
): IProfessionDisplaySettings => {
	if (!profession.activeProjects[projectId]?.displaySettings) {
		return {
			trade: ProfessionSettingType.TEXT,
			...(profession.specialty ? { specialty: ProfessionSettingType.TEXT } : {}),
			...(profession.contractor ? { contractor: ProfessionSettingType.TEXT } : {}),
		};
	}
	return profession.activeProjects[projectId].displaySettings as IProfessionDisplaySettings;
};
