import React from 'react';
import { WeeklyWidgetCardSequenceAreas } from '@src/Components/WorkplanWidgetComponents/WeeklyWidget/WeeklyWidgetActivityCard/WeeklyWidgetCardSequenceAreas/WeeklyWidgetCardSequenceAreas';
import { WeeklyWidgetCardAdHocFloors } from '@src/Components/WorkplanWidgetComponents/WeeklyWidget/WeeklyWidgetActivityCard/WeeklyWidgetCardAdHocFloors/WeeklyWidgetCardAdHocFloors';
import { IMainPageWidgetActivityData } from '@interfaces/IMainPageWidgetActivityData';

interface IWidgetDialogContentProps {
	activity: IMainPageWidgetActivityData;
}

export const WidgetDialogContent = (props: IWidgetDialogContentProps) => {
	return (
		<div data-testid="WidgetDialogContent">
			{props.activity.areaSequenceItems.length ? (
				<WeeklyWidgetCardSequenceAreas activity={props.activity} />
			) : (
				<WeeklyWidgetCardAdHocFloors activity={props.activity} />
			)}
		</div>
	);
};
