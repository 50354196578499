import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { requestService, translationService } from '../../index';
import { GeneralDialog } from '../GeneralDialog/GeneralDialog';
import { textStyle } from '@shared/utils/text.utils';
import { assignmentKinds, COLORS, DIALOG_TEXT_GRAY_COLOR, hoistTradeId, unassignedTradeId } from '../../constants';
import { IManager, IProfession, ITag, ITrade } from '../../interfaces';
import { updateTag } from '../../store/thunks/tags.thunks';
import { isValidName } from '@shared/utils/validation.util';
import { IRootState } from '../../store/slices';
import { guestTradeId, scissorsLiftTradeId } from '@shared/constants/professions.constants';
import { isEqual } from 'lodash';
import { CreateManagerDialog } from '../ManagersListComponents/CreateManagerDialog';
import { CreateProfessionDialog } from '../ProfessionsListComponents/Dialogs/CreateProfessionDialog/CreateProfessionDialog';
import { CertificateSection } from './CertificateSection/CertificateSection';
import { ITagCertificate } from '../../interfaces/ITagCertificate';
import { ICertificateValidationError } from '../../interfaces/ICertificateValidationError';
import { ITagEditedCertificate } from '../../interfaces/ITagEditedCertificate';
import { IUtility } from '../../interfaces/IUtility';
import { getFloorsList } from '../../store/thunks';
import { PhoneInputField } from '../Inputs/PhoneInputField/PhoneInputField';
import { updateTag_BI } from '../../utils/bi.utils';
import { IFloor } from '@shared/interfaces/IFloor';
import { TrusstorTextInput } from '@shared/components/Inputs/TrusstorTextInput/TrusstorTextInput';
import { ManagersAutocompleteDropdown } from '@src/Components/Dropdowns/Manager/ManagersAutocompleteDropdown/ManagersAutocompleteDropdown';
import { SingleDropdown } from '@src/Components/Dropdowns/DesignSystem/SingleDropdown/SingleDropdown';
import { SingleProfessionGroupedDropdown } from '../Dropdowns/Profession/SingleProfessionGroupedDropdown/SingleProfessionGroupedDropdown';
import { getProjectUtilitiesQuery } from '@src/hooks/queries/utilities.queries.hooks';
import { errorSnackbar } from '@utils/snackbar.utils';
import { getAllTradesQuery } from '@src/hooks/queries/trades.queries.hooks';
import { UtilityTypes } from '@interfaces/UtilityTypes.enum';
import { createUtilityApi } from '@src/apis/trades.api';
import { IScissorsLiftUtility } from '@interfaces/IScissorsLiftUtility';
import { IMergedTag } from '@interfaces/IMergedTag';

const useStyles = makeStyles((theme: Theme) => ({
	contentText: textStyle({
		fontWeight: 'bold',
		fontClass: 'h2',
		color: DIALOG_TEXT_GRAY_COLOR,
	}),
	linkToAddWrapper: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: COLORS.white,
		flexDirection: 'column',
	},
	linkToAddParagraph: textStyle({
		margin: 0,
		fontWeight: 300,
	}),
	link: textStyle({
		marginTop: theme.spacing(1),
		textDecoration: 'underline',
		cursor: 'pointer',
	}),
	button: {
		borderRadius: 3,
		height: 40,
		backgroundColor: COLORS.white,
		boxShadow: 'unset',
		width: 170,
		marginTop: 5,
		marginBottom: 10,
		color: DIALOG_TEXT_GRAY_COLOR,
		fontWeight: 'bold',
	},
	greenButton: {
		backgroundColor: '#95c62a',
		color: '#ffffff',
	},
	kindsContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	phoneNumber: {
		direction: translationService.getDirection(),
		textAlign: 'left',
		marginTop: theme.spacing(1),
		height: 17,
		width: '100%',
		marginBottom: theme.spacing(1),
		borderRadius: 3,
		backgroundColor: COLORS.white,
		borderWidth: 0,
		boxShadow: '0px 2px 0 0 rgba(0, 0, 0, 0.1)',
	},
	textInput: textStyle({
		height: 'auto',
		width: '100%',
		borderRadius: 3,
		backgroundColor: COLORS.white,
		borderWidth: 0,
		boxShadow: '0px 2px 0 0 rgba(0, 0, 0, 0.1)',
	}),
	disabledTextInput: textStyle({
		marginTop: theme.spacing(1),
		fontClass: 'h2',
		height: 17,
		width: '100%',
		borderRadius: 3,
		borderWidth: 0,
		boxShadow: '0px 2px 0 0 rgba(0, 0, 0, 0.1)',
		backgroundColor: '#d4d4d4',
		color: '#7b7b7b',
	}),
	selectContainer: {
		width: '100%',
		height: 50,
	},
	errorWrapper: {
		height: 20,
		marginTop: theme.spacing(0.5),
		marginBottom: theme.spacing(0.5),
	},
	errorText: textStyle({
		fontClass: 'body1',
		color: COLORS.red400,
	}),
	hoistDefaultContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		alignSelf: 'center',
		maxWidth: '77%',
	},
	hoistDefaultTitle: textStyle({}),
	hoistDefaultSubtitle: textStyle({
		fontWeight: 300,
		textAlign: 'center',
	}),
	icon: {
		width: 31,
		height: 28,
	},
	topSection: {
		display: 'flex',
		flexDirection: 'row',
		gap: 19,
	},
	idAndNameSection: {
		display: 'flex',
		flexDirection: 'row',
		gap: 19,
	},
	managerDropdown: {
		marginBottom: '10px',
	},
}));

interface AssignTagDialogProps {
	close: () => void;
	tag?: ITag;
}

const AssignTagDialog = (props: AssignTagDialogProps) => {
	const guestProfession: IProfession | undefined = useSelector(
		(state: IRootState) => state.professionsReducer.guestProfession
	);
	const professionsWithoutGuest: IProfession[] = useSelector(
		(state: IRootState) => state.professionsReducer.professions
	).filter((profession) => profession.tradeId !== guestTradeId);
	const managers: IManager[] = useSelector((state: IRootState) => state.managersReducer.staticManagers);
	const staticTags: ITag[] = useSelector((state: IRootState) => state.tagsReducer.tags);
	const projectId: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.projectId);
	const floorsList: IFloor[] = useSelector((state: IRootState) => state.floorsListReducer.floorsList);
	const tagNicks: string[] = useMemo(
		() => staticTags.filter((tag) => tag?.profession?.tradeId === unassignedTradeId).map((t) => t.tagNick),
		[staticTags]
	);
	const [hoistInput, setHoistInput] = useState<IUtility | undefined>();
	const [workerProfessionInput, setWorkerProfessionInput] = useState<IProfession | undefined>();
	const [managerInput, setManagerInput] = useState<IManager | undefined>(props.tag?.manager);
	const [nameInput, setNameInput] = useState<string | undefined>();
	const [idNumberInput, setIdNumberInput] = useState<string | undefined>();
	const [phoneNumberInput, setPhoneNumberInput] = useState<string | undefined>();
	const [countryCode, setCountryCode] = useState<string>();
	const [isValidPhoneNumber, setIsValidPhoneNumber] = useState<boolean>();
	const [displayPhoneNumberError, setDisplayPhoneNumberError] = useState<boolean>();
	const [tagNickInput, setTagNickInput] = useState<string>(props.tag?.tagNick || '');
	const [selectedTagKind, setSelectedTagKind] = useState<string>('');
	const [hasTagChanges, setHasTagChanges] = useState(false);
	const [shouldHideLinkTagBtn, setShouldHideLinkTagBtn] = useState(false);
	const [showManagerCreateDialog, setShowManagerCreateDialog] = useState<boolean>(false);
	const [showProfessionCreateDialog, setShowProfessionCreateDialog] = useState<boolean>(false);
	const [certificateError, setCertificateError] = useState<ICertificateValidationError | null>(null);
	const [currentCertificate, setCurrentCertificate] = useState<ITagEditedCertificate | null>(null);
	const [certificates, setCertificates] = useState<ITagCertificate[]>(
		props.tag?.certificates || ([] as ITagCertificate[])
	);
	const [hoistUtility, setHoistUtility] = useState<IUtility | undefined>();
	const [duplicateManagerTagError, setDuplicateManagerTagError] = useState<boolean>(false);
	const userAddedPhoneNumber: boolean =
		!!phoneNumberInput && !!countryCode && phoneNumberInput.length > countryCode.length;

	const isTagAssigned: boolean = !!(props.tag && props.tag?.profession?.tradeId !== unassignedTradeId);
	const isAssigningTagToGuest =
		selectedTagKind === assignmentKinds.guest && workerProfessionInput?.tradeId !== guestTradeId;
	const isAssigningTagToHoist = selectedTagKind === assignmentKinds.hoist && hoistInput?.tradeId !== hoistTradeId;
	const shouldShowProfessionDropdown: boolean =
		selectedTagKind === assignmentKinds.worker || selectedTagKind === assignmentKinds.scissorsLift;
	const isScissorsLift: boolean = selectedTagKind === assignmentKinds.scissorsLift;
	const { utilities: projectUtilities, isLoading: areUtilitiesLoading } = getProjectUtilitiesQuery();
	const { allTrades, isLoading: areTradesLoading } = getAllTradesQuery();

	useEffect(() => {
		if (selectedTagKind === assignmentKinds.manager) {
			setShouldHideLinkTagBtn(!managers.length);
		} else if (selectedTagKind === assignmentKinds.worker) {
			setShouldHideLinkTagBtn(!professionsWithoutGuest.length);
		} else if (selectedTagKind === assignmentKinds.hoist) {
			const elevatorFloor: IFloor | undefined = floorsList?.find((floor) => floor.isHoist);
			setShouldHideLinkTagBtn(!elevatorFloor);
		} else {
			setShouldHideLinkTagBtn(false);
		}
	}, [managers.length, professionsWithoutGuest.length, floorsList.length, selectedTagKind]);

	const dispatch = useDispatch();
	const classes = useStyles();
	const initialTagRef: any = useRef(null);

	const getFullProfession = useCallback(
		(profession: IProfession | undefined): IProfession | undefined => {
			if (!profession) {
				return;
			}

			return professionsWithoutGuest.find((profession) => profession._id === profession._id);
		},
		[managers, professionsWithoutGuest]
	);

	const resetProfession = useCallback(() => {
		if (props.tag) {
			if (props.tag.profession && props.tag.profession.tradeId === guestTradeId) {
				setWorkerProfessionInput(props.tag.profession);
				setNameInput(undefined);
				setPhoneNumberInput(undefined);
				setManagerInput(undefined);
				return;
			}
			if (props.tag.linkedUtility?.tradeId === hoistTradeId) {
				setHoistInput(props.tag.linkedUtility);
				setNameInput(undefined);
				setPhoneNumberInput(undefined);
				setManagerInput(undefined);
				return;
			}
			if (props.tag.linkedUtility?.type === UtilityTypes.scissorsLift) {
				setWorkerProfessionInput((props.tag.linkedUtility as IScissorsLiftUtility).profession);
				setNameInput((props.tag.linkedUtility as IScissorsLiftUtility).name);
				setPhoneNumberInput(undefined);
				setManagerInput(undefined);
				return;
			}
			if (props.tag.manager) {
				setWorkerProfessionInput(undefined);
				setManagerInput(props.tag.manager);
			} else {
				setWorkerProfessionInput(
					props.tag.profession?.tradeId === unassignedTradeId ? undefined : props.tag.profession
				);
				setManagerInput(undefined);
			}
		}
	}, [props.tag]);

	const resetForm = useCallback(() => {
		setNameInput(props.tag?.name ? props.tag.name : '');
		setIdNumberInput(props.tag?.idNumber ? props.tag.idNumber : '');
		setPhoneNumberInput(props.tag?.phoneNumber ? props.tag.phoneNumber : '');
		resetProfession();
	}, [getFullProfession, props.tag?.tagId, resetProfession]);

	const resetKindSelection = () => {
		if (props.tag?.manager) {
			setSelectedTagKind(assignmentKinds.manager);
			return;
		}
		if (props.tag?.profession?.tradeId === guestTradeId) {
			setSelectedTagKind(assignmentKinds.guest);
			return;
		}

		if (props.tag?.linkedUtility?.tradeId === hoistTradeId) {
			setSelectedTagKind(assignmentKinds.hoist);
			return;
		}
		if (props.tag?.linkedUtility?.type === UtilityTypes.scissorsLift) {
			setSelectedTagKind(assignmentKinds.scissorsLift);
			return;
		}
		setSelectedTagKind(assignmentKinds.worker);
	};

	useEffect(() => {
		if (selectedTagKind === assignmentKinds.hoist) {
			dispatch(getFloorsList(projectId));
			(async () => await getProjectHoistUtility())();
		}
		resetForm();
	}, [selectedTagKind]);

	useEffect(() => {
		initialTagRef.current = {
			workerProfessionInputId: workerProfessionInput?._id,
			hoistInputId: hoistInput?._id,
			nameInput: props.tag?.name ? props.tag.name : '',
			idNumberInput: props.tag?.idNumber ? props.tag.idNumber : '',
			phoneNumberInput: props.tag?.phoneNumber ? props.tag.phoneNumber : '',
			managerInputId: managerInput?._id,
			tagNickInput: props.tag?.tagNick ? props.tag.tagNick : '',
			certificates,
			currentCertificate,
		};
	}, [selectedTagKind]);

	useEffect(() => {
		const newTagObj = {
			workerProfessionInputId: workerProfessionInput?._id,
			hoistInputId: hoistInput?._id,
			nameInput: nameInput || '',
			idNumberInput: idNumberInput || '',
			phoneNumberInput: phoneNumberInput || '',
			managerInputId: managerInput?._id,
			tagNickInput: tagNickInput || '',
			certificates,
			currentCertificate,
		};

		const isObjectsEqual = isEqual(newTagObj, initialTagRef.current);
		if (isAssigningTagToGuest || isAssigningTagToHoist) {
			return setHasTagChanges(true);
		}
		setHasTagChanges(!isObjectsEqual);
	}, [
		workerProfessionInput,
		nameInput,
		idNumberInput,
		phoneNumberInput,
		hoistInput,
		managerInput,
		tagNickInput,
		selectedTagKind,
		certificates,
		currentCertificate,
		initialTagRef.current,
	]);

	useEffect(() => {
		resetKindSelection();
		setCurrentCertificate(null);
	}, [props.tag?.tagId]);

	const getProjectHoistUtility = async (): Promise<IUtility | undefined> => {
		const hoistUtility: IUtility | undefined = projectUtilities.find((utility) => utility.tradeId === hoistTradeId);
		setHoistUtility(hoistUtility);
		return hoistUtility;
	};

	const getProjectScissorsLiftUtility = (profession: IProfession): IUtility | undefined => {
		const scissorsLiftUtility: IUtility | undefined = projectUtilities.find(
			(utility) => utility.tradeId === scissorsLiftTradeId
		);
		if (!scissorsLiftUtility) {
			return undefined;
		}
		return {
			...scissorsLiftUtility,
			profession,
			name: nameInput,
		};
	};

	const handleAssignmentKindButtonClick = (kind: string | null) => {
		if (kind === null) {
			return;
		}
		if (!isTagAssigned) {
			resetForm();
			if (kind !== '') {
				setSelectedTagKind(kind);
			}
		}
	};

	const checkValidation = () => {
		let isError = false;

		if (selectedTagKind === assignmentKinds.worker) {
			if (!workerProfessionInput) {
				isError = true;
			}
			if (nameInput && !isValidName(nameInput)) {
				isError = true;
			}
			if (!isValidPhoneNumber && userAddedPhoneNumber) {
				setDisplayPhoneNumberError(true);
				isError = true;
			}
		}
		if (selectedTagKind === assignmentKinds.scissorsLift) {
			if (!workerProfessionInput) {
				isError = true;
			}
			if (nameInput && !isValidName(nameInput)) {
				isError = true;
			}
		}
		if (selectedTagKind === assignmentKinds.manager) {
			if (!managerInput) {
				isError = true;
			}
			if (
				managerInput &&
				staticTags.some((tag) => tag.manager?._id === managerInput._id && tag.tagId !== props.tag?.tagId)
			) {
				setDuplicateManagerTagError(true);
				isError = true;
			}
		}
		if (!tagNickInput) {
			isError = true;
		}

		if ((currentCertificate?.name || currentCertificate?.expirationDate) && checkCurrentCertificateValidation()) {
			isError = true;
		}
		return isError;
	};

	const checkCurrentCertificateValidation = () => {
		let isError = false;
		if (currentCertificate) {
			const certificateErrorObject: ICertificateValidationError = {
				name: '',
				date: false,
			};

			if (!currentCertificate.name) {
				certificateErrorObject.name = translationService.get('pleaseTypeCertificateName');
				isError = true;
			}
			if (!currentCertificate.expirationDate) {
				certificateErrorObject.date = true;
				isError = true;
			}
			if (currentCertificate.name) {
				const isNameAlreadyExist: boolean = certificates.some(
					(certificate) => certificate.name?.trim() === currentCertificate.name?.trim()
				);
				if (isNameAlreadyExist) {
					certificateErrorObject.name = translationService.get('certificateNameWasAlreadyChosen');
					isError = true;
				}
			}
			setCertificateError(certificateErrorObject);
		}

		return isError;
	};

	const getUpdatedTagAttributes = (): Partial<ITag> => {
		if (selectedTagKind === assignmentKinds.guest) {
			return {
				profession: guestProfession,
				phoneNumber: '',
				idNumber: '',
				name: '',
			};
		}

		if (selectedTagKind === assignmentKinds.hoist) {
			return {
				linkedUtility: hoistUtility,
				certificates: [],
			};
		}

		if (selectedTagKind === assignmentKinds.scissorsLift) {
			const scissorsLiftUtility: IUtility | undefined = getProjectScissorsLiftUtility(workerProfessionInput!);
			return {
				linkedUtility: scissorsLiftUtility,
				certificates: [],
			};
		}

		const allCertificates: ITagCertificate[] = [...certificates];
		if (currentCertificate && currentCertificate.name && currentCertificate.expirationDate) {
			allCertificates.push(currentCertificate as ITagCertificate);
			setCertificates((prevCertificates: ITagCertificate[]) => [
				...prevCertificates,
				currentCertificate as ITagCertificate,
			]);
			setCurrentCertificate(null);
		}

		if (selectedTagKind === assignmentKinds.worker) {
			const updatedTag: Partial<ITag> = {
				idNumber: idNumberInput,
				name: nameInput,
				profession: workerProfessionInput,
				certificates: allCertificates,
			};
			if (userAddedPhoneNumber) {
				updatedTag.phoneNumber = phoneNumberInput;
			} else {
				updatedTag.phoneNumber = '';
			}
			return updatedTag;
		}
		return { manager: managerInput, certificates: allCertificates };
	};

	const handlePhoneNumberChange = (value: string) => {
		setPhoneNumberInput(value);
		setDisplayPhoneNumberError(false);
	};

	const handleIDNumberChange = (value: string) => {
		setIdNumberInput(value);
	};

	const handleManagementChange = (value?: IManager) => {
		setManagerInput(value);
		setDuplicateManagerTagError(false);
	};

	const handleTagIdChange = (value: string | null) => {
		if (value === null) {
			return;
		}
		setTagNickInput(value);
	};

	const handleWorkerProfessionChange = (value?: IProfession | null) => {
		if (!value) {
			setWorkerProfessionInput(undefined);
			return;
		}
		setWorkerProfessionInput(value);
	};

	const handleNameChange = (value: string | React.ChangeEvent) => {
		const stringValue: string = value as string;
		if (!isValidName(stringValue)) {
			if (stringValue.length === 0) {
				setNameInput(stringValue);
			}
			return;
		}
		setNameInput(stringValue);
	};

	const createScissorsLiftUtility = async (): Promise<IUtility | undefined> => {
		const scissorsLiftTrade: ITrade | undefined = allTrades?.find((trade) => trade.tradeId === scissorsLiftTradeId);
		if (!scissorsLiftTrade) {
			return;
		}
		const newScissorsLiftUtility: IUtility = await createUtilityApi({
			...scissorsLiftTrade,
			projectId,
			type: UtilityTypes.scissorsLift,
		});
		return newScissorsLiftUtility;
	};

	const handleSubmitButtonClick = async (e, timeDuration) => {
		const isError = checkValidation();
		if (isError) {
			errorSnackbar(dispatch, translationService.get('failedToAssignTag'));
			return;
		}

		if (tagNickInput) {
			const tagIdToUpdate: string =
				props.tag?.tagId ||
				(staticTags.find((staticTag) => staticTag.tagNick === tagNickInput)?.tagId as string);
			const attributesToUpdate = getUpdatedTagAttributes();
			if (selectedTagKind === assignmentKinds.scissorsLift && !attributesToUpdate.linkedUtility) {
				const scissorsLiftUtility: IUtility | undefined = await createScissorsLiftUtility();
				if (!scissorsLiftUtility) {
					errorSnackbar(dispatch, translationService.get('failedToAssignTag'));
					return;
				}
				attributesToUpdate.linkedUtility = {
					...scissorsLiftUtility,
					profession: workerProfessionInput,
					name: nameInput,
				};
			}

			try {
				const mergedTag: IMergedTag | undefined = await requestService.get(
					`/siteNetIntegrator/mergedTags/${tagIdToUpdate}`
				);

				if (!mergedTag) {
					errorSnackbar(dispatch, translationService.get('assignTagDialog_desktop_404_error'));
					return;
				}

				await dispatch(updateTag(tagIdToUpdate, attributesToUpdate));
				updateTag_BI(timeDuration, selectedTagKind, attributesToUpdate, props.tag);
				props.close();
			} catch (err: any) {
				errorSnackbar(dispatch, translationService.get('assignTagDialog_desktop_general_error'));
			}
		}
	};

	const shouldShowCertificateSection = (): boolean => {
		if (
			selectedTagKind === assignmentKinds.guest ||
			selectedTagKind === assignmentKinds.hoist ||
			selectedTagKind === assignmentKinds.scissorsLift
		) {
			return false;
		}
		if (selectedTagKind === assignmentKinds.manager) {
			return !!managers.length;
		}
		if (selectedTagKind === assignmentKinds.worker) {
			return !!professionsWithoutGuest.length;
		}
		return true;
	};

	if (showManagerCreateDialog) {
		return <CreateManagerDialog show={true} close={() => setShowManagerCreateDialog(false)} />;
	}

	if (showProfessionCreateDialog) {
		return <CreateProfessionDialog close={() => setShowProfessionCreateDialog(false)} />;
	}

	const isDisabled = () => {
		if (!hasTagChanges || !tagNickInput) {
			return true;
		}
		if (selectedTagKind === assignmentKinds.worker) {
			if (!workerProfessionInput) {
				return true;
			}
		}
		if (selectedTagKind === assignmentKinds.scissorsLift) {
			if (!workerProfessionInput || !nameInput) {
				return true;
			}
		}
		if (selectedTagKind === assignmentKinds.manager) {
			if (!managerInput) {
				return true;
			}
		}
		return false;
	};

	const isLoading: boolean =
		areUtilitiesLoading ||
		(areTradesLoading &&
			(selectedTagKind === assignmentKinds.scissorsLift || selectedTagKind === assignmentKinds.hoist));

	return (
		<GeneralDialog
			show={true}
			close={props.close}
			title={props.tag ? translationService.get('editTag') : translationService.get('assignTagTitle')}
			isLoading={isLoading}
			mainButton={{
				text: props.tag ? translationService.get('editTagAction') : translationService.get('assignTagAction'),
				click: handleSubmitButtonClick,
				disabled: isDisabled(),
				show: !shouldHideLinkTagBtn,
			}}
		>
			<div className={classes.topSection}>
				<SingleDropdown
					options={Object.values(assignmentKinds)}
					onChange={(kind) => handleAssignmentKindButtonClick(kind)}
					getDisplayOption={(option) => translationService.get(option)}
					label={translationService.get('type')}
					required
					hideClearTextButton
					value={selectedTagKind}
					disabled={isTagAssigned}
					testId={'tagTypeDropdown'}
				/>
				<SingleDropdown
					getDisplayOption={(option: string) => option}
					disabled={!!props.tag}
					options={tagNicks}
					hideClearTextButton
					value={tagNickInput}
					required
					onChange={handleTagIdChange}
					label={translationService.get('tagID')}
					testId={'tagIdDropdown'}
				/>
			</div>
			{shouldHideLinkTagBtn && selectedTagKind === assignmentKinds.hoist && (
				<div className={classes.hoistDefaultContainer}>
					<p className={classes.hoistDefaultTitle}>{translationService.get('noDefinedHoist')}</p>
					<p className={classes.hoistDefaultSubtitle}>{translationService.get('contactSupportToAddHoist')}</p>
				</div>
			)}

			{shouldShowProfessionDropdown && (
				<>
					{(
						<SingleProfessionGroupedDropdown
							onChange={handleWorkerProfessionChange}
							defaultSelectedProfession={props.tag?.profession}
						/>
					) || (
						<div className={classes.linkToAddWrapper}>
							<p className={classes.linkToAddParagraph}>{translationService.get('addEmployeeP1')}</p>
							<p className={classes.linkToAddParagraph}>{translationService.get('addEmployeeP2')}</p>
							<a className={classes.link} onClick={() => setShowProfessionCreateDialog(true)}>
								{translationService.get('goToAddProfession')}
							</a>
						</div>
					)}
				</>
			)}
			{selectedTagKind === assignmentKinds.manager && (
				<>
					{managers.length > 0 ? (
						<ManagersAutocompleteDropdown
							disabled={!!props.tag}
							inputContainerClassName={classes.managerDropdown}
							onChange={handleManagementChange}
							defaultManager={managerInput}
							required
						/>
					) : (
						<div className={classes.linkToAddWrapper}>
							<p className={classes.linkToAddParagraph}>{translationService.get('addManagerP1')}</p>
							<p className={classes.linkToAddParagraph}>{translationService.get('addManagerP2')}</p>
							<a className={classes.link} onClick={() => setShowManagerCreateDialog(true)}>
								{translationService.get('goToAddManager')}{' '}
							</a>
						</div>
					)}
				</>
			)}
			{selectedTagKind !== assignmentKinds.guest && selectedTagKind !== assignmentKinds.hoist && (
				<>
					{selectedTagKind !== assignmentKinds.manager && professionsWithoutGuest.length > 0 && (
						<>
							<div className={classes.idAndNameSection}>
								<TrusstorTextInput
									value={nameInput}
									label={
										isScissorsLift
											? translationService.get('assignTagDialog_nickname')
											: translationService.get(`fullName`)
									}
									changeFunc={handleNameChange}
									testId={'nameInput'}
								/>
								{!isScissorsLift && (
									<TrusstorTextInput
										value={idNumberInput}
										label={translationService.get('IDNumber')}
										changeFunc={handleIDNumberChange}
										testId={'idNumberInput'}
									/>
								)}
							</div>
							{!isScissorsLift && (
								<PhoneInputField
									phoneNumber={phoneNumberInput}
									onChangeFunction={handlePhoneNumberChange}
									displayError={!!displayPhoneNumberError}
									setCountryCode={setCountryCode}
									setIsValidPhoneNumber={setIsValidPhoneNumber}
								/>
							)}
						</>
					)}
				</>
			)}
			{shouldShowCertificateSection() && (
				<CertificateSection
					certificates={certificates}
					currentCertificate={currentCertificate}
					setCurrentCertificate={setCurrentCertificate}
					setCertificates={setCertificates}
					isError={certificateError}
					setCertificateError={setCertificateError}
					checkCurrentCertificateValidation={checkCurrentCertificateValidation}
					selectedTagKind={selectedTagKind || assignmentKinds.worker}
				/>
			)}
		</GeneralDialog>
	);
};

export { AssignTagDialog };
