import React from 'react';
import classes from './styles.module.scss';

interface ILineDividerWithTextProps {
	text: string;
}

const LineDividerWithText = (props: ILineDividerWithTextProps) => {
	return (
		<div className={classes.emptyStateContainer} key={`${props.text}_date`}>
			<span className={classes.emptyStateText}>{props.text}</span>
		</div>
	);
};

export { LineDividerWithText };
