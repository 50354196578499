import { IFloor } from '@shared/interfaces/IFloor';
import { useQuery } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import { requestService } from '../../index';

export const useFloorDataQuery = (projectId: string): IFloor[] => {
	const history = useHistory();
	const { data: floors } = useQuery<IFloor[]>(
		['floorsData', projectId],
		() => requestService.get(`/projectConfig/floor?projectId=${projectId}`),
		{
			useErrorBoundary: true,
			initialData: [],
			onSuccess: (data) => {
				if (data.length === 0) {
					history.push('/projectNotReady');
				}
			},
		}
	);
	return floors;
};
