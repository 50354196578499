import React from 'react';
import classnames from 'classnames';
import classes from './styles.module.scss';
import FloorPlanSvgDisplay from '@src/Components/FloorPlanSvgDisplay/FloorPlanSvgDisplay';
import { DashboardTabTypes } from '@interfaces/DashboardTabTypes.enum';
import { ISelectedPointWithAreaId } from '@shared/interfaces/ISelectedPoint';
import { Dialog } from '@material-ui/core';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { TrusstorIconButtonV2 } from '@shared/components/buttons/TrusstorIconButtonV2/TrusstorIconButtonV2';
import { translationService } from '@src/servicesInitializers';

interface IFloorViewExpandDialogProps {
	currentActiveMainPageLayout: DashboardTabTypes | null;
	floorId: string;
	visibleAreasWithVisibleTrades: any[];
	visibleWorkersOnSite: any[];
	issuesCurrentFloorSelectedPoints: ISelectedPointWithAreaId[];
	show: boolean;
	closeDialog: () => void;
	isSpecialFloor: boolean;
}

const SVG_EXPANDED_DIALOG_ID: string = 'svgExpandedDialogId';

export const FloorViewExpandDialog = (props: IFloorViewExpandDialogProps) => {
	const isRtl: boolean = translationService.getIsRtl();
	return (
		<Dialog
			open={props.show}
			onClose={props.closeDialog}
			classes={{
				paper: `${classes.dialog}`,
			}}
		>
			<FloorPlanSvgDisplay
				isSpecialFloor={props.isSpecialFloor}
				currentActiveMainPageLayout={props.currentActiveMainPageLayout}
				svgId={SVG_EXPANDED_DIALOG_ID}
				floorId={props.floorId}
				visibleAreasWithVisibleTrades={props.visibleAreasWithVisibleTrades}
				visibleWorkersOnSite={props.visibleWorkersOnSite}
				issuesCurrentFloorSelectedPoints={props.issuesCurrentFloorSelectedPoints}
			/>
			<div className={classnames(classes.closeIcon, { [classes.isRtl]: isRtl })}>
				<TrusstorIconButtonV2 iconName={IconNames.close} onClick={props.closeDialog} />
			</div>
		</Dialog>
	);
};
