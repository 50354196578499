import React from 'react';
import classes from './styles.module.scss';
import {
	getProfessionBackgroundColor,
	getProfessionDisplayTextFromDisplaySettings,
} from '@shared/utils/professions.utils';
import { translationService } from '@src/servicesInitializers';
import { ChipWithNumber } from '@src/Components/Chips/ChipWithNumber';
import { ManagerChip } from '@src/Components/Chips/ManagerChip/ManagerChip';
import { IProfessionWithActiveTags } from '@interfaces/IProfessionWithActiveTags';
import { IManagerMergedTag } from '@shared/interfaces/IManagerMergedTag';
import { IMergedEquipmentView } from '@interfaces/IMergedEquipmentView';
import { useSelector } from 'react-redux';
import { selectProjectId } from '@store/slices/project.slice';
import { Dictionary, groupBy } from 'lodash';
import { EquipmentTypes } from '@shared/constants/equipment.const';
import { IUtilityMergedTag } from '@interfaces/IUtilityMergedTag';
import { FloorViewEquipmentIcon } from '@src/Components/Equipment/FloorViewEquipmentIcon/FloorViewEquipmentIcon';
import { getChipBorderColorByUtilityType, getGroupByFunctionByUtilityType } from '@utils/utilities.utils';
import { UtilityTypes } from '@shared/constants/utilityTypes.enum';
import { EquipmentChip } from '@src/Components/Chips/EquipmentChip/EquipmentChip';

interface ISpecialFloorResourcesProps {
	professions: IProfessionWithActiveTags[];
	managers: IManagerMergedTag[];
	equipments: IMergedEquipmentView[];
	utilities: IUtilityMergedTag[];
}

export const SpecialFloorResources = (props: ISpecialFloorResourcesProps) => {
	const projectId: string = useSelector(selectProjectId)!;
	const equipmentByType: Dictionary<IMergedEquipmentView[]> = groupBy(props.equipments, 'type');
	const utilitiesByType: Dictionary<IUtilityMergedTag[]> = groupBy(
		props.utilities,
		(utility) => utility.linkedUtility.type
	);

	return (
		<div className={classes.SpecialFloorResources_container} data-testid="SpecialFloorResources">
			{props.professions.map((profession) => {
				const chipText: string = getProfessionDisplayTextFromDisplaySettings(
					profession,
					translationService,
					projectId
				);
				return (
					<ChipWithNumber
						chipText={chipText}
						number={profession.activeTagsIds.length}
						chipColor={getProfessionBackgroundColor(profession, projectId)}
						key={`${profession._id}`}
					/>
				);
			})}
			{props.managers.map((manager) => {
				return (
					<ManagerChip
						chipText={manager.manager.name}
						key={manager._id}
						chipBackground={manager.manager.backgroundColor}
					/>
				);
			})}
			{Object.entries(equipmentByType).map(([type, equipmentFamily]) => {
				return (
					<div className={classes.equipmentChip}>
						<EquipmentChip type={type as EquipmentTypes} number={equipmentFamily.length} />
					</div>
				);
			})}
			{Object.entries(utilitiesByType).map(([type, utilitiesOfType]) => {
				const utilitiesGroupByFunction = groupBy(
					utilitiesOfType,
					getGroupByFunctionByUtilityType(type as UtilityTypes)
				);
				return Object.values(utilitiesGroupByFunction).map((utilities) => {
					const chipBorderColor: string | undefined = getChipBorderColorByUtilityType(utilities[0]);
					return (
						<div className={classes.equipmentChip}>
							<FloorViewEquipmentIcon
								type={type as EquipmentTypes}
								elementsArray={utilities}
								chipBorderColor={chipBorderColor}
							/>
						</div>
					);
				});
			})}
		</div>
	);
};
