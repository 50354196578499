import { uniqBy } from 'lodash';
import isEqual from 'react-fast-compare';
import moment from 'moment';
import { COLORS, entitiesProperties, heavyMachineryTypes, MAIN_PATH } from '../constants';
import { steamRollerChip, steamRollerIcon, tractorChip, tractorIcon, truckIcon } from '../assets/heavyMachinery';
import { IManager, IProfession, ITradeFilter } from '../interfaces';
import { RefObject } from 'react';
import { IProfessionWithActiveTags } from '../interfaces/IProfessionWithActiveTags';
import { ITrade } from '../interfaces/ITrade';
import { IManagerMergedTag } from '../interfaces/IManagerMergedTag';
import { ISvgElementPoint } from '../interfaces/ISvgElementPoint';
import { ActivityStatusTypes, ActivityStatusTypesColor } from '@shared/constants/constants';
import { getProductRoundedHoursFromMs } from '@shared/utils/roundNumbers.utils';
import { ActivityGroupStatus } from '@shared/interfaces/ActivityGroupStatus.enum';
import { IUtilityMergedTag } from '@interfaces/IUtilityMergedTag';
import { filterArrayByAnotherArray } from '@utils/array.util';

export const isTouchDevice = (): boolean => {
	return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
};
export const getMainPagePath = (floorId?: string): string => {
	return `${MAIN_PATH}${floorId ? `/floors/${floorId}` : ''}`;
};

export const filterVisibleProfessions = (
	professions: IProfessionWithActiveTags[],
	visibleProfessions: IProfession[]
): IProfessionWithActiveTags[] =>
	professions.filter((entity) => {
		return visibleProfessions.find(
			(visibleProfession) => (entity as IProfessionWithActiveTags)._id === visibleProfession._id
		);
	});

export const calculatePositionForChipsContainer = (
	equipmentPrefixContainer: RefObject<HTMLDivElement>,
	positions: ISvgElementPoint
): { left: number | undefined; right: number | undefined } => {
	const equipmentContainerWidth: number = equipmentPrefixContainer.current
		? equipmentPrefixContainer.current.clientWidth
		: 0;
	return positions.left
		? {
				left: positions.left + equipmentContainerWidth,
				right: positions.right,
			}
		: {
				left: positions.left,
				right: (positions.right ?? 0) + equipmentContainerWidth,
			};
};

export const compareProps = (prevProps: any, nextProps: any): boolean => {
	return isEqual(prevProps, nextProps);
};

export const getColorFromActivityGroupStatus = (status?: ActivityGroupStatus): string => {
	switch (status) {
		case ActivityGroupStatus.overdue:
			return COLORS.red100;
		case ActivityGroupStatus.delayed:
			return COLORS.red100;
		case ActivityGroupStatus.inProgress:
			return COLORS.yellow100;
		case ActivityGroupStatus.deleted:
		case ActivityGroupStatus.complete:
			return COLORS.green100;
		case ActivityGroupStatus.planned:
			return COLORS.white;
		default:
			return COLORS.white;
	}
};

export const getHoverColorFromActivityGroupStatus = (status?: ActivityGroupStatus) => {
	switch (status) {
		case ActivityGroupStatus.overdue:
			return COLORS.red300;
		case ActivityGroupStatus.delayed:
			return COLORS.red300;
		case ActivityGroupStatus.inProgress:
			return COLORS.yellow300;
		case ActivityGroupStatus.deleted:
		case ActivityGroupStatus.complete:
			return COLORS.green300;
		case ActivityGroupStatus.planned:
			return COLORS.blue100;
	}
};

export const checkForInitialValues = (
	selectedEntities: IProfession[] | ITrade[],
	currentFilter: ITradeFilter[]
): boolean => {
	return selectedEntities.length === 0 && currentFilter.length === 0;
};

export const getProfessionsFilterData = (professions: IProfession[]): ITradeFilter[] =>
	uniqBy(professions, entitiesProperties.tradeGroup);

export const getTimeDifference = (timeFrom: Date, timeTo: Date) => {
	const timeFromMoment = moment(timeFrom);
	const timeToMoment = moment(timeTo);
	const hoursDif = timeToMoment.diff(timeFromMoment, 'hours');
	let minutesDif = timeToMoment.diff(timeFromMoment, 'minutes');

	if (hoursDif > 0) {
		minutesDif = minutesDif % 60;
	}

	return {
		hours: hoursDif,
		minutes: minutesDif,
	};
};

export const filterFloorVisibleManagers = (
	mergedManagers: IManagerMergedTag[],
	visibleManagers: IManager[]
): IManagerMergedTag[] => {
	return mergedManagers?.filter((mergedManager: IManagerMergedTag) =>
		visibleManagers.some((visibleManager: IManager) => visibleManager?._id === mergedManager?.manager?._id)
	);
};

export const filterVisibleUtilitiesTags = (
	utilities: IUtilityMergedTag[],
	selectedUtilities: IUtilityMergedTag[]
): IUtilityMergedTag[] => {
	return filterArrayByAnotherArray(utilities, selectedUtilities, entitiesProperties._id);
};

export const convertMsToRoundedTimeFormat = (ms: number): string => {
	const hours: number = getProductRoundedHoursFromMs(ms);
	const minutes: number = convertMsToRoundedMinutes(ms) % 60;
	return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
};

export const convertMsToRoundedMinutes = (ms: number): number => {
	return Math.round(ms / (1000 * 60));
};

export const convertMsToRoundedDays = (ms: number): number => {
	return Math.round(ms / (1000 * 60 * 60 * 24));
};

export const getChartBackgroundColorByPercentage = (
	fragmentNumber: number,
	totalNumber: number
): ActivityStatusTypesColor => {
	if (totalNumber === 0) {
		return ActivityStatusTypesColor[ActivityStatusTypes.DEFICIENT];
	}

	const percentage = fragmentNumber / totalNumber;
	if (percentage < 0.5) {
		return ActivityStatusTypesColor[ActivityStatusTypes.DEFICIENT];
	}
	if (percentage < 0.8) {
		return ActivityStatusTypesColor[ActivityStatusTypes.PARTIAL];
	}

	return ActivityStatusTypesColor[ActivityStatusTypes.AS_PLANNED];
};

export const getGradientColorBasedOnColor = (seriesIndex: number, baseColor: string): string => {
	switch (seriesIndex) {
		case 0:
			return baseColor + 'ff';
		case 1:
			return baseColor + 'dd';
		case 2:
			return baseColor + 'bb';
		case 3:
			return baseColor + '99';
		case 4:
			return baseColor + '77';
		default:
			return baseColor + 'ff';
	}
};

export const getHeavyMachineryIconByProfession = (profession: string | undefined): string => {
	switch (profession) {
		case heavyMachineryTypes.tractor:
			return tractorIcon;
		case heavyMachineryTypes.truck:
			return truckIcon;
		case heavyMachineryTypes.steamRoller:
			return steamRollerIcon;
		default:
			return tractorIcon;
	}
};

export const getHeavyMachineryMapIconByProfession = (profession: string | undefined): string => {
	// TODO: these are the transparent icons - should replace these with map icons once we have them
	switch (profession) {
		case heavyMachineryTypes.tractor:
			return tractorChip;
		case heavyMachineryTypes.truck:
			return truckIcon;
		case heavyMachineryTypes.steamRoller:
			return steamRollerChip;
		default:
			return tractorChip;
	}
};

export const delay = (ms: number): Promise<void> => {
	return new Promise((resolve) => setTimeout(() => resolve(), ms));
};

export const randomIntFromInterval = (min: number, max: number): number => {
	// min and max included
	return Math.floor(Math.random() * (max - min + 1) + min);
};

export const validateEmail = (email: string): boolean => {
	const regex =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return regex.test(email);
};

export const removeUndefinedValuesFromObject = (obj: any): any => {
	return Object.entries(obj).reduce((acc, [key, value]) => {
		if (value !== undefined) {
			acc[key] = value;
		}
		return acc;
	}, {});
};

export const parse2Int = (value: string | number): number => {
	if (typeof value === 'string') {
		return parseInt(value);
	}
	return value;
};

export const keepKeysInObject = (obj: object, keys: string[]): object => {
	return Object.entries(obj).reduce((acc, [key, value]) => {
		if (keys.includes(key)) {
			acc[key] = value;
		}
		return acc;
	}, {});
};

export const stringToNumber = (str: string): number => {
	let hash = 0;
	for (let i = 0; i < str.length; i++) {
		const char = str.charCodeAt(i);
		hash = (hash << 5) - hash + char;
		hash = hash & hash; // Convert to 32bit integer
	}
	return Math.abs(hash);
};

export const stringToNumberBetweenRange = (value: string, min: number, max: number): number => {
	const hashResult = stringToNumber(value);
	const normalized = hashResult % 100;
	return min + (normalized / 100) * (max - min);
};

export const hasParentWithId = (element: HTMLElement, id: string): boolean => {
	if (element.id === id) {
		return true;
	}
	if (element.parentElement) {
		return hasParentWithId(element.parentElement, id);
	}
	return false;
};

export const isEventInsideElementId = (event, elementId: string): boolean => {
	if (event.target.id === elementId) {
		return true;
	}
	if (event.target.parentElement) {
		return hasParentWithId(event.target.parentElement, elementId);
	}
	return false;
};
