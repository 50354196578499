import React, { useLayoutEffect, useState } from 'react';
import classes from './styles.module.scss';
import { useSelector } from 'react-redux';
import { IRootState } from '@store/slices';
import { translationService } from '@src/index';

interface ITrusstorSlideSwitcherProps {
	options: [string, string];
	handleChange: (option: string) => void;
	responsive?: boolean;
	shouldHideSwitcher?: boolean;
	initialState?: string;
	value?: string;
	width?: string;
	height?: string;
	biEventOnClick?: (option: string) => void;
}

const TrusstorSlideSwitcher = (props: ITrusstorSlideSwitcherProps) => {
	const workingProjectId: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.projectId);
	const [toggleState, setToggleState] = useState<string>(props.initialState || props.options[0]);

	const getSecondOption = (): string => {
		return toggleState === props.options[0] ? props.options[1] : props.options[0];
	};

	useLayoutEffect(() => {
		if (!props.initialState) {
			setToggleState(props.options[0]);
			props.handleChange(props.options[0]);
		}
	}, [workingProjectId]);

	useLayoutEffect(() => {
		if (props.value) {
			setToggleState(props.value);
			props.handleChange(props.value);
		}
	}, [props.value]);

	const handleToggle = (): void => {
		const secondOption = getSecondOption();
		props.handleChange(secondOption);
		props.biEventOnClick && props.biEventOnClick(secondOption);
		setToggleState(secondOption);
	};

	return (
		<section
			className={`${classes.switcherContainer} ${props.responsive ? classes.responsive : ''}`}
			onClick={handleToggle}
			style={{ display: props.shouldHideSwitcher ? 'none' : '', width: props.width, height: props.height }}
		>
			<div className={toggleState === props.options[0] ? classes.active : classes.inActive}>
				{translationService.get(props.options[0])}
			</div>
			<div className={toggleState === props.options[1] ? classes.active : classes.inActive}>
				{translationService.get(props.options[1])}
			</div>
		</section>
	);
};

export { TrusstorSlideSwitcher };
