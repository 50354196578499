export const dailyStatusDateFormat: string = 'DD-MM-yyyy';
export const TIME_FORMAT_24 = 'HH:mm';
export const TIME_FORMAT_12 = 'h:mm a';
export const dateFormats = {
	MDY: 'MM.DD.YYYY',
	DMY: 'DD.MM.YYYY',
	MSDSY_H: 'MM/DD/YYYY HH:mm',
	DSMSY_H: 'DD/MM/YYYY HH:mm',
	MSDSY: 'MM/DD/YYYY',
	DSMSY: 'DD/MM/YYYY',
	MD: 'MM.DD',
	DM: 'DD.MM',
	YMD: 'YYYY.MM.DD',
	MMMM_YYYY: 'MMMM YYYY',
};
export const SERVER_DATE_FORMAT = 'DD.MM.YYYY';
export const ISO_DATE_FORMAT: string = 'YYYY-MM-DDTHH:mm:ss.sssZ';
