import React, { useId } from 'react';
import { IMatrixCellComponentProps } from '@src/Components/Matrix/interfaces/IMatrixCellComponentProps';
import classes from './styles.module.scss';
import { TrusstorIcon } from '@src/Components/TrusstorIcon/TrusstorIcon';
import { translationService } from '@src/servicesInitializers';
import classnames from 'classnames';
import { compareProps } from '@utils/generalUtils';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { IconSize } from '@shared/components/TrusstorIconShared/TrusstorIconShared';
import TrusstorTooltip from '@shared/components/TrusstorTooltip';

interface IMatrixHeaderProps extends IMatrixCellComponentProps {
	fraction?: number;
	total?: number;
	text: string;
}

export const MatrixRowHeader = React.memo((props: IMatrixHeaderProps) => {
	const isRtl: boolean = translationService.getIsRtl();

	const shouldShowSideText: boolean = !!props.fraction && !!props.total && props.fraction > 0;

	const textChipId: string = `textChip-${props.text}-${useId()}`;

	return (
		<div className={classes.container}>
			<TrusstorTooltip idToOverflow={textChipId} text={props.text}>
				<div id={textChipId} className={classes.text}>
					{props.text}
				</div>
			</TrusstorTooltip>
			{shouldShowSideText && (
				<div className={`${classes.sideText}`}>
					<TrusstorIcon iconName={IconNames.greenCheckCircle} size={IconSize.SMALL} defaultColor />
					<div className={classnames(classes.text, { [classes.rtl]: isRtl })}>
						{props.fraction}/{props.total}
					</div>
				</div>
			)}
		</div>
	);
}, compareProps);
