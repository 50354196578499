import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { textStyle } from '@shared/utils/text.utils';
import { translationService } from '../../index';
import { IMergedTag } from '../../interfaces/IMergedTag';
import { getProfessionDisplayText } from '../../utils/professions.utils';
import { IWorkerMergedTag } from '../../interfaces/IWorkerMergedTag';
import { IManagerMergedTag } from '../../interfaces/IManagerMergedTag';
import { COLORS } from '../../constants';
import { IUtilityMergedTag } from '@interfaces/IUtilityMergedTag';
import { UtilityTypes } from '@interfaces/UtilityTypes.enum';
import { IScissorsLiftUtility } from '@interfaces/IScissorsLiftUtility';

const useStyles = makeStyles((theme) => ({
	tagText: textStyle({
		fontClass: 'body2',
		marginLeft: theme.spacing(0.5),
		marginRight: theme.spacing(0.5),
		color: COLORS.white,
	}),
}));

interface ITagsDetailsTooltipProps {
	tags: IMergedTag[];
}

const TagsDetailsTooltip = ({ tags }: ITagsDetailsTooltipProps): JSX.Element => {
	const classes = useStyles();
	return (
		<Grid container spacing={1} direction="column">
			{tags.map((tag: IMergedTag) => (
				<Grid item container key={tag.tagId}>
					<>
						<p className={classes.tagText}>
							{translationService.get('tagId')}: {tag.tagId}
						</p>
						{(tag as IWorkerMergedTag).profession ? (
							<p className={classes.tagText}>
								{translationService.get('profession')}:{' '}
								{getProfessionDisplayText((tag as IWorkerMergedTag).profession, true)}
							</p>
						) : (tag as IManagerMergedTag).manager ? (
							<p className={classes.tagText}>
								{translationService.get('manager')}: {(tag as IManagerMergedTag).manager.name}
							</p>
						) : (tag as IUtilityMergedTag).linkedUtility.type === UtilityTypes.scissorsLift ? (
							<p className={classes.tagText}>
								{translationService.get('utility')}:{' '}
								{getProfessionDisplayText(
									((tag as IUtilityMergedTag).linkedUtility as IScissorsLiftUtility).profession,
									true
								)}
							</p>
						) : null}
						<p className={classes.tagText}>
							{translationService.get('battery')}: {tag.batteryLevel}
						</p>
						<p className={classes.tagText}>
							<>
								{translationService.get('updatedTimestamp')}: {tag.timestamp}
							</>
						</p>
						<p className={classes.tagText}>
							{translationService.get('status')}: {tag.tagStatus}
						</p>
					</>
				</Grid>
			))}
		</Grid>
	);
};

export { TagsDetailsTooltip };
