import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { IIssue } from '@shared/interfaces/IIssue';
import { deleteIssueApi, updateIssueApi } from '@src/apis/issues.api';
import { errorSnackbar, successSnackbar } from '@utils/snackbar.utils';
import { translationService } from '@src/servicesInitializers';
import { getIssueStatusMainText } from '@shared/utils/issues.utils';
import { IUser } from '@shared/interfaces/IUser';
import { selectLoggedUserDetails } from '@store/slices/login.slice';

export const useIssuesCrud = (setIssues: React.Dispatch<React.SetStateAction<IIssue[]>>, issues: IIssue[]) => {
	const dispatch = useDispatch();
	const user: IUser = useSelector(selectLoggedUserDetails)!;

	const updateIssue = async (
		issueId: string,
		issueToUpdate: Partial<IIssue>,
		updateBackend: boolean = true
	): Promise<IIssue> => {
		const issueBeforeUpdate: IIssue = issues.find((issue) => issue._id === issueId)!;
		const statusUpdatedData: Partial<IIssue> = {
			statusUpdateDate: new Date(),
			statusUpdateUser: {
				name: user.name,
				username: user.username,
			},
		};
		const isStatusUpdated: boolean = !!(issueToUpdate.status && issueToUpdate.status !== issueBeforeUpdate.status);

		const optimisticUpdatedIssue: IIssue = {
			...issueBeforeUpdate,
			...issueToUpdate,
			...(isStatusUpdated && statusUpdatedData),
			isInitial: false,
		};

		setIssues((prevIssues) => {
			return prevIssues.map((issue) => {
				if (issue._id === issueId) {
					return {
						...issue,
						...optimisticUpdatedIssue,
					};
				}
				return issue;
			});
		});

		if (!updateBackend) {
			return optimisticUpdatedIssue;
		}

		try {
			const issueUpdated: IIssue = await updateIssueApi(issueId, issueToUpdate);
			if (issueToUpdate.status && issueToUpdate.status !== issueBeforeUpdate?.status) {
				successSnackbar(
					dispatch,
					translationService.get('issueStatusUpdatedSnackbarMessage', {
						new_status: getIssueStatusMainText(issueToUpdate.status, translationService),
					})
				);
			}
			return issueUpdated;
		} catch (e) {
			console.error(e);
			errorSnackbar(dispatch, translationService.get('failedToUpdateGeneric'));
			setIssues((prevIssues) => {
				return prevIssues!.map((issue) => {
					if (issue._id === issueId) {
						return {
							...issue,
							...issueBeforeUpdate,
						};
					}
					return issue;
				});
			});
			return issueBeforeUpdate;
		}
	};

	const deleteIssue = async (issueId: string) => {
		const issuesBeforeDelete: IIssue[] = [...issues];
		try {
			setIssues((prevIssues) => prevIssues!.filter((issue) => issue._id !== issueId));
			await deleteIssueApi(issueId);
			successSnackbar(dispatch, translationService.get('issueDeletedSuccessfully'));
		} catch (e) {
			setIssues(issuesBeforeDelete);
			console.error(e);
			errorSnackbar(dispatch, translationService.get('failedToDelete'));
		}
	};

	return { updateIssue, deleteIssue };
};
