import {
	IActivitiesReport,
	IBackendActivitiesReport,
	IProductivityProfessionReport,
	IProductivityReport,
} from '../interfaces';
import { isNil, mapValues } from 'lodash';
import { IRoundedProductivityReport } from '../interfaces/IRoundedProductivityReport';
import { IProfessionsAnalysisReport } from '../interfaces/IProfessionsAnalysisReport';
import { IRoundedProfessionsAnalysisReport } from '../interfaces/IRoundedProfessionsAnalysisReport';
import { IProfessionsAnalysisReportData } from '../interfaces/IProfessionReport';
import { getProductRoundedHoursFromHours, getProductRoundedHoursFromMs } from '@shared/utils/roundNumbers.utils';
import {
	IProfessionProductivity,
	IRoundedProfessionProductivityReport,
} from '@interfaces/IRoundedProfessionProductivityReport';

export const ceilBelowOneOrRound = (number: number) => {
	return number < 1 ? Math.ceil(number) : Math.round(number);
};

export const getRoundedActivityReport = (report: IBackendActivitiesReport): IActivitiesReport => {
	const roundedReport: IActivitiesReport = mapValues(report, (professionReport) => {
		return {
			...professionReport,
			floors: mapValues(professionReport.floors, (floorReport) => {
				return {
					...floorReport,
					activities: floorReport.activities.map((activity) => {
						const roundedActualWorkTime: number = getProductRoundedHoursFromMs(
							activity.accumulatedWorkTime
						);
						const roundedWorkersPerDay: number = ceilBelowOneOrRound(activity.avgNumOfWorkersPerDay);
						const roundedAverageWorkHoursPerDay: number = getProductRoundedHoursFromHours(
							activity.avgWorkHoursPerDay
						);

						return {
							...activity,
							roundedActualWorkTime,
							roundedWorkersPerDay,
							roundedAverageWorkHoursPerDay,
						};
					}),
				};
			}),
		};
	});
	return roundedReport;
};

export const getRoundedProductivityReport = (report: IProductivityReport): IRoundedProductivityReport => {
	return mapValues(report, (productivityReport: IProductivityProfessionReport) => {
		return {
			...productivityReport,
			floors: mapValues(productivityReport.floors, (floorReport) => {
				return {
					...floorReport,
					totalHours: getProductRoundedHoursFromHours(floorReport.totalHours),
				};
			}),
			areas: mapValues(productivityReport.areas, (areaReport) => {
				return {
					...areaReport,
					totalHours: getProductRoundedHoursFromMs(areaReport.totalTimeMS),
				};
			}),
			totalHoursOnSite: isNil(productivityReport.totalTimeOnSiteMS)
				? undefined
				: getProductRoundedHoursFromMs(productivityReport.totalTimeOnSiteMS),
		};
	});
};

export const getRoundedProfessionProductivityReport = (
	reports: IProfessionProductivity[]
): IRoundedProfessionProductivityReport[] => {
	return reports.map((productivityReport: IProfessionProductivity) => {
		return {
			...productivityReport,
			floors: mapValues(productivityReport.floors, (floorReport) => {
				return {
					...floorReport,
					totalHours: getProductRoundedHoursFromMs(floorReport.totalTimeMS),
				};
			}),
			areas: mapValues(productivityReport.areas, (areaReport) => {
				return {
					...areaReport,
					totalHours: getProductRoundedHoursFromMs(areaReport.totalTimeMS),
				};
			}),
		};
	});
};

export const getRoundedProfessionReport = (report: IProfessionsAnalysisReport): IRoundedProfessionsAnalysisReport => {
	return mapValues(report, (professionReport: IProfessionsAnalysisReportData) => {
		return {
			...professionReport,
			dates: mapValues(professionReport.dates, (dateProfessionReport) => {
				return {
					...dateProfessionReport,
					totalWorkHours: getProductRoundedHoursFromMs(dateProfessionReport.totalWorkTimeMS),
					totalHoursInActivities: getProductRoundedHoursFromMs(dateProfessionReport.totalTimeInActivities),
				};
			}),
			averageDayWorkDurationHours: getProductRoundedHoursFromMs(professionReport.averageDayWorkDurationTimeMS),
		};
	});
};
