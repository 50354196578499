import React, { useContext, useMemo } from 'react';
import moment from 'moment-timezone';
import classes from './styles.module.scss';
import { ISequenceProgressAnalysisContext, SequenceProgressAnalysisContext } from '../../SequenceProgressAnalysis';
import { IMilestone } from '@shared/interfaces/IMilestone';
import { translationService } from '@src/servicesInitializers';
import { IMilestoneProgressSummary } from '@interfaces/IMilestoneProgressSummary';
import { getMilestoneProgressPercentage } from '../MilestoneSelectorCarousel/milestoneSelectorCarousel.utils';
import { useSelector } from 'react-redux';
import { selectTimezone } from '@store/slices/project.slice';
import { COLORS } from '@src/constants';
import { TrusstorIcon } from '@src/Components/TrusstorIcon/TrusstorIcon';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { IconColor } from '@shared/components/TrusstorIconShared/TrusstorIconShared';
import { getDateDisplayFormat } from '@shared/utils/dateUtils';

interface IMilestoneProgressStatsProps {
	projectMilestones: IMilestone[];
	milestonesProgressSummary: IMilestoneProgressSummary[];
}

export const MilestoneProgressStats = (props: IMilestoneProgressStatsProps) => {
	const { selectedMilestoneId }: ISequenceProgressAnalysisContext = useContext(SequenceProgressAnalysisContext)!;
	const tz: string = useSelector(selectTimezone)!;
	if (!selectedMilestoneId) {
		return <></>;
	}
	const selectedMilestoneIndex = props.projectMilestones.findIndex(
		(milestone) => milestone._id === selectedMilestoneId
	);
	const milestoneProgressSummary: IMilestoneProgressSummary | undefined = props.milestonesProgressSummary.find(
		(summary) => summary.milestoneId === selectedMilestoneId
	);
	if (selectedMilestoneIndex === -1 || !milestoneProgressSummary) {
		return <></>;
	}

	const selectedMilestone: IMilestone = props.projectMilestones[selectedMilestoneIndex];
	const isDueDatePassed: boolean = moment.tz(selectedMilestone.endDate, tz).isBefore(moment.tz(tz), 'day');

	return (
		<div className={classes.milestoneProgressStatsContainer}>
			<div className={classes.milestoneIndexAndTitle}>
				<div className={classes.selectedMilestoneRectangle}>
					<p className={classes.selectedMilestoneIndexText}> {selectedMilestoneIndex + 1}</p>
				</div>
				<p className={classes.milestoneTitle}>{selectedMilestone.title}</p>
			</div>
			<div className={classes.milestoneStatsContainer}>
				<div className={classes.milestoneStat}>
					<p className={classes.milestoneStatLabel}>
						{translationService.get('milestoneProgressStats_progress')}
					</p>
					<div className={classes.progressValues}>
						<p className={classes.milestoneStatValue}>
							{milestoneProgressSummary.progress.asisLinked
								? `${getMilestoneProgressPercentage(milestoneProgressSummary)}%`
								: '-'}
						</p>
						{milestoneProgressSummary.progress.asisLinked !== 0 && (
							<p className={classes.milestoneStatValueSmall}>
								{milestoneProgressSummary.progress.asisCompleted} /{' '}
								{milestoneProgressSummary.progress.asisLinked}
							</p>
						)}
					</div>
				</div>
				<div className={classes.milestoneStat}>
					<div className={classes.dueDateFlagAndText}>
						<TrusstorIcon iconName={IconNames.flag} color={IconColor.Grey600} />
						<p className={classes.milestoneStatLabel}>
							{translationService.get('milestoneProgressStats_dueDate')}
						</p>
					</div>
					<div
						style={{ backgroundColor: isDueDatePassed ? COLORS.red100 : 'transparent' }}
						className={classes.dueDateContainer}
					>
						<p className={classes.milestoneStatValue}>
							{getDateDisplayFormat(selectedMilestone.endDate, tz, translationService, 'full')}
						</p>
					</div>
				</div>
				<div className={classes.milestoneStat}>
					<p className={classes.milestoneStatLabel}>
						{translationService.get('milestoneProgressStats_simulatedETA')}
					</p>
					<p className={classes.milestoneStatValue}>
						{milestoneProgressSummary.simulatedETA
							? getDateDisplayFormat(
									milestoneProgressSummary.simulatedETA,
									tz,
									translationService,
									'monthAndYear'
								)
							: '-'}
					</p>
				</div>
			</div>
		</div>
	);
};
