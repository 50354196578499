import React from 'react';
import classes from './styles.module.scss';
import { IProfession } from '../../interfaces/IProfession';
import { IconColor, IconSize, TrusstorIconShared } from '../TrusstorIconShared/TrusstorIconShared';
import { IconNames } from '../TrusstorIconShared/IconNames.enum';
import { ProfessionDisplayWithTradeIcon } from '../ProfessionDisplayWithTradeIcon/ProfessionDisplayWithTradeIcon';

interface IAsiReviewDialogProps {
	profession: IProfession;
	projectId: string;
}

export const ProfessionDisplayWithIcon = (props: IAsiReviewDialogProps) => {
	return (
		<div className={classes.professionSection} data-testid="ProfessionDisplayWithIcon">
			<TrusstorIconShared iconName={IconNames.helmet} size={IconSize.SMALL} color={IconColor.Grey600} />
			<div>
				<ProfessionDisplayWithTradeIcon profession={props.profession} projectId={props.projectId} />
			</div>
		</div>
	);
};
