import React from 'react';
import classes from './styles.module.scss';
import classnames from 'classnames';
import { getEquipmentIconFromType } from '@shared/utils/equipment.utils';
import { UtilityTypes } from '@interfaces/UtilityTypes.enum';
import { EquipmentTypes } from '@interfaces/EquipmentTypes.enum';

interface IEquipmentIconProps {
	type: EquipmentTypes | UtilityTypes;
	isChipIcon?: boolean;
	borderColor?: string;
}

const EquipmentIcon = (props: IEquipmentIconProps) => {
	return (
		<div
			className={classnames(classes.circle, {
				[classes.chip]: props.isChipIcon,
			})}
			data-testid={'equipmentIcon'}
			style={{
				borderColor: props.borderColor,
			}}
		>
			<img
				src={getEquipmentIconFromType(props.type)}
				alt="eqimage"
				style={{
					height: props.isChipIcon ? 10 : 12,
					width: props.isChipIcon ? 10 : 12,
				}}
			/>
		</div>
	);
};

export { EquipmentIcon };
