import { IProject } from '@interfaces/index';
import { INotification } from '@shared/interfaces/INotification';
import { SnackbarContent } from '@src/Components/SnackbarContent/SnackbarContent';
import { IRootState } from '@store/slices';
import { selectSnackbarNotifications } from '@store/slices/notifications.slice';
import { removeSnackbarById } from '@store/thunks/notifications.thunks';
import { getSnackbarNotificationCardsElements } from '@utils/notifications.utils';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import classes from './styles.module.scss';
import { selectTimezone } from '@store/slices/project.slice';
import { requestService } from '@src/servicesInitializers';
import { useHistory } from 'react-router-dom';
import { useIsFeatureFlagEnabled } from '@shared/hooks/useFeatureFlag';
import { FeatureFlagNamesEnum } from '@shared/constants/featureFlags.enum';
import { notificationsConfiguration } from '@shared/constants/notifications.constants';

export const NotificationSnackbarsSection = React.memo(() => {
	const isSequenceProgressAnalysisEnabled: boolean = useIsFeatureFlagEnabled(
		FeatureFlagNamesEnum.sequenceProgressAnalysis
	);
	const snackbarNotifications: INotification[] = useSelector(selectSnackbarNotifications);
	const dispatch = useDispatch();
	const workingProject: IProject = useSelector((state: IRootState) => state.projectReducer.workingProject!);
	const tz: string = useSelector(selectTimezone)!;
	const history = useHistory();
	const horizontalAligment = useMemo(() => {
		if (workingProject) {
			return workingProject.language === 'he-il' ? 'left' : 'right';
		}
		return 'left';
	}, [workingProject]);

	const closeNotificationSnackbar = async (key: string | number, notificationId: string) => {
		closeSnackbar(key);
		await requestService.put(`/notification/notifications/${notificationId}`, {
			body: { isViewed: true },
		});
	};

	snackbarNotifications.map((notification: INotification, id) => {
		const notificationCardElements: {
			titleElement: React.ReactNode;
			iconElement: React.ReactNode;
			descriptionElement?: React.ReactNode;
			buttonElement?: React.ReactNode;
			onClick?: () => void;
		} | null = getSnackbarNotificationCardsElements(
			notification.metadata!,
			notification.type,
			notification,
			tz,
			history,
			isSequenceProgressAnalysisEnabled
		);
		if (!notificationCardElements) {
			return;
		}
		const notificaionId = notification._id;
		const isBanner: boolean = !!notificationsConfiguration[notification.type].isBanner;
		enqueueSnackbar(notificaionId, {
			anchorOrigin: {
				vertical: 'bottom',
				horizontal: horizontalAligment,
			},
			autoHideDuration: !isBanner ? 20000 : null,
			content: (key) => {
				return (
					<SnackbarContent
						snackbarId={key}
						isBanner={isBanner}
						onClick={(e) => {
							e.stopPropagation();
							notificationCardElements.onClick?.();
							closeNotificationSnackbar?.(key, notificaionId);
						}}
						onClose={(e) => {
							e.stopPropagation();
							closeNotificationSnackbar?.(key, notificaionId);
						}}
						iconElement={notificationCardElements.iconElement}
					>
						<div className={classes.notificationData}>
							{notificationCardElements?.titleElement}
							{notificationCardElements?.descriptionElement}
							{notificationCardElements?.buttonElement}
						</div>
					</SnackbarContent>
				);
			},
		});
		dispatch(removeSnackbarById(notificaionId));
	});

	return <></>;
});
