import moment from 'moment';
import { msToHoistTimeString } from '../../../utils/dateUtils';
import { IProject } from '@shared/interfaces/IProject';
import { IFloorData } from '../../../interfaces/IFloorData';
import { hoistOperatorTradeId } from '@shared/constants/professions.constants';

export const getIsOperatorInHoist = (hoist: IFloorData): boolean => {
	return hoist.activeProfessions.some((profession) => profession.tradeId === hoistOperatorTradeId);
};

export const getHoistTimePastFromStartDay = (
	currentDayIndex: number,
	project: IProject,
	timePastFromLastHoistUpdateInMs: number
): string => {
	if (!project.workDayHours[currentDayIndex].startDayTime) {
		return msToHoistTimeString(timePastFromLastHoistUpdateInMs);
	}
	const [startDayHours, startDayMinutes]: string[] = project.workDayHours[currentDayIndex].startDayTime!.split(':');
	const currentTime: string = moment.tz(project.tz).format('HH:mm');
	const [currentDayHours, currentDayMinutes]: string[] = currentTime.split(':');
	const msFromStartDay: number = moment
		.tz(project.tz)
		.set({ hour: +currentDayHours, minute: +currentDayMinutes })
		.diff(moment.tz(project.tz).set({ hour: +startDayHours, minute: +startDayMinutes }));
	return msToHoistTimeString(msFromStartDay);
};

export const isWorkDayOver = (project: IProject): boolean => {
	const currentDayIndex: number = moment.tz(project.tz).get('day');
	if (!project.workDayHours[currentDayIndex].endDayTime) {
		return true;
	}
	const currentTime: string = moment.tz(project.tz).format('HH:mm');
	const [currentDayHours, currentDayMinutes]: string[] = currentTime.split(':');
	const calculatedCurrentDayTime: number = Number(currentDayHours) + Number(currentDayMinutes) / 60;
	const [endDayHours, endDayMinutes]: string[] = project.workDayHours[currentDayIndex].endDayTime!.split(':');
	const calculatedCurrentDayEndTime: number = Number(endDayHours) + Number(endDayMinutes) / 60;
	const [startDayHours, startDayMinutes]: string[] = project.workDayHours[currentDayIndex].startDayTime!.split(':');
	const calculatedCurrentDayStartTime: number = Number(startDayHours) + Number(startDayMinutes) / 60;
	return (
		calculatedCurrentDayTime >= calculatedCurrentDayEndTime ||
		calculatedCurrentDayTime <= calculatedCurrentDayStartTime
	);
};

export const isEmptyHoist = (hoist: IFloorData): boolean =>
	(!hoist.managers || hoist.managers.length === 0) &&
	(!hoist.activeProfessions || hoist.activeProfessions.length === 0);
