import React, { useCallback } from 'react';
import classes from './styles.module.scss';
import { StorageService } from '../../../services/storage.service';
import { PhotoDisplay } from '../../PhotoDisplay/PhotoDisplay';

interface ICommentPhotoDisplayProps {
	issueId: string;
	commentId: string;
	storageService: StorageService;
}

export const NotificationPhotoDisplay = (props: ICommentPhotoDisplayProps) => {
	const loadImage = useCallback(
		async (): Promise<string> => props.storageService.getIssueCommentImage(props.issueId, props.commentId),
		[props.issueId, props.commentId]
	);

	return (
		<div className={classes.photoContainer}>
			<PhotoDisplay fetchImageCallback={loadImage} />
		</div>
	);
};
