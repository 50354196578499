import React, { useEffect } from 'react';
import { ExpandableComponent } from '@src/Components/MainPageSections/ExpandableComponent/ExpandableComponent';
import { translationService } from '@src/servicesInitializers';
import { APP_FILTER_TYPES } from '@src/constants';
import { ISequence } from '@shared/interfaces/ISequence';
import { useDispatch, useSelector } from 'react-redux';
import {
	addSelectedSequence,
	removeSelectedSequence,
	resetSelectedSequences,
	selectSelectedSequences,
	selectActiveSequences,
} from '@store/slices/sequences.slice';
import { SequenceFilterCard } from '@shared/components/Filters/SequenceFilterCard/SequenceFilterCard';

export const SequencesFilterSection = () => {
	const dispatch = useDispatch();

	const sequences: ISequence[] = useSelector(selectActiveSequences);
	const selectedSequences: ISequence[] = useSelector(selectSelectedSequences);

	const checkShouldHideClearButton = (): boolean => {
		return selectedSequences.length === 0;
	};

	const handleResetSelectedSequences = (): void => {
		dispatch(resetSelectedSequences());
	};

	const addSequence = (selectedSequence: ISequence): void => {
		dispatch(addSelectedSequence({ selectedSequence }));
	};

	const removeSequence = (removedSequence: ISequence): void => {
		dispatch(removeSelectedSequence({ removedSequence }));
	};

	useEffect(() => {
		dispatch(resetSelectedSequences());
	}, []);

	return (
		<ExpandableComponent
			headerText={translationService.get('sequenceFilterSectionTitle')}
			childrenLength={sequences.length}
			clearFunction={handleResetSelectedSequences}
			type={APP_FILTER_TYPES.sequences}
			shouldHideClearButton={checkShouldHideClearButton()}
			clearLength={selectedSequences.length}
			testId={'usersFilterSection'}
			alwaysHideList={sequences.length <= 1}
		>
			{sequences.map((sequence: ISequence) => {
				const isSelected = selectedSequences.some((selectedSequence) => selectedSequence._id === sequence._id);
				return (
					<SequenceFilterCard
						key={sequence._id}
						handleClick={isSelected ? () => removeSequence(sequence) : () => addSequence(sequence)}
						name={sequence.name}
						isSelected={isSelected}
						sequenceType={sequence.sequenceType}
					/>
				);
			})}
		</ExpandableComponent>
	);
};
