import React from 'react';
import classes from './styles.module.scss';
import { IMatrixOptions } from '@src/Components/Matrix/interfaces/IMatrixOptions';
import { times } from 'lodash';
import classnames from 'classnames';
import { MatrixRowHeader } from '@src/Components/Matrix/MatrixRow/MatrixRowHeader/MatrixRowHeader';
export interface IMatrixRowProps {
	rowHeaderData: { rowTitle: string; doneItems?: number; totalItems?: number };
	row: any[];
	options: IMatrixOptions;
	styleOptions?: {
		colHeaderHeight?: string;
		rowHeaderWidth?: string;
		cellWidth?: string;
		cellHeight?: string;
	};
	showLoader?: boolean;
	rowLength: number;
	isRowHovered?: boolean;
}

export const MatrixRow = React.memo((props: IMatrixRowProps) => {
	const rowHeaderStyle: React.CSSProperties = {
		height: props.styleOptions?.cellHeight ?? 'auto',
		width: props.styleOptions?.rowHeaderWidth ?? 'auto',
	};
	const cellStyle: React.CSSProperties = {
		height: props.styleOptions?.cellHeight ?? 'auto',
		width: props.styleOptions?.cellWidth ?? 'auto',
	};

	return (
		<tr>
			<th
				className={classnames({ [classes.rowHovered]: !!props.isRowHovered }, classes.rowHeader)}
				style={rowHeaderStyle}
			>
				{!props.options.rowHeaderComponent ? (
					<MatrixRowHeader
						text={props.rowHeaderData.rowTitle}
						fraction={props.rowHeaderData.doneItems}
						total={props.rowHeaderData.totalItems}
					/>
				) : (
					<props.options.rowHeaderComponent data={props.rowHeaderData} />
				)}
			</th>
			{props.showLoader
				? times(props.rowLength, (index) => (
						<td key={`${index}-loader-cell`} className={classes.cell} style={cellStyle}></td>
				  ))
				: props.row.map((cellData: any, index: number) => {
						if (!cellData && cellData !== 0) {
							return null;
						}
						return (
							<td key={cellData._id} className={classes.cell} style={cellStyle}>
								<props.options.cellComponent
									data={cellData}
									onClick={(data, event, asiUpdated) =>
										props.options.onCellClick?.(cellData, event, asiUpdated)
									}
									onHover={(event) => props.options.onCellHover?.(cellData, event)}
								/>
							</td>
						);
				  })}
		</tr>
	);
});
