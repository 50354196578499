import React from 'react';
import classes from './styles.module.scss';
import { IStaticEquipment } from '@shared/interfaces/Equipment/IStaticEquipment';
import { getEquipmentIconFromType } from '@shared/utils/equipment.utils';

interface IEquipmentCardProps {
	equipment: IStaticEquipment;
}

const EquipmentCard = (props: IEquipmentCardProps) => {
	return (
		<div className={classes.equipmentCardContainer}>
			<div className={classes.circle}>
				<img
					src={getEquipmentIconFromType(props.equipment.type)}
					alt="equipmentIcon"
					className={classes.icon}
				/>
			</div>
			<div className={classes.textContainer}>
				<p className={classes.label}>{props.equipment.typeNick}</p>
				<p className={classes.subtitle}>
					{props.equipment.name}
					{props.equipment.company && ` - ${props.equipment.company}`}
				</p>
			</div>
		</div>
	);
};

export { EquipmentCard };
