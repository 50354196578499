import React from 'react';
import classes from './styles.module.scss';
import { IconColor, IconSize, TrusstorIconShared } from '../TrusstorIconShared/TrusstorIconShared';
import { IconNames } from '../TrusstorIconShared/IconNames.enum';
import { ProfessionDisplayWithIcon } from '../ProfessionDisplayWithIcon/ProfessionDisplayWithIcon';
import { ITrusstorButtonType, TrusstorButton } from '../buttons/TrusstorButton/TrusstorButton';
import { IProfession } from '../../interfaces/IProfession';
import { IConfigArea } from '../../interfaces/IConfigArea';
import { IAreaSequenceItem } from '../../interfaces/IAreaSequenceItem';
import { useSharedServices } from '../../hooks/sharedServices.context';

interface IAsiReviewDialogContentProps {
	profession: IProfession;
	projectId: string;
	area: IConfigArea;
	sequenceItemDescription: string;
	asi: IAreaSequenceItem;
	handleRejectClick: () => void;
	handleApproveClick: () => void;
	isRejectedClicked?: boolean;
	isApprovedClicked?: boolean;
}

export const AsiReviewDialogContent = (props: IAsiReviewDialogContentProps) => {
	const { translationService } = useSharedServices();
	const area: IConfigArea = props.area;

	return (
		<div className={classes.AsiReviewDialogContent_container} data-testid="AsiReviewDialogContent">
			<div className={classes.AsiReviewDialog_container} data-testid="AsiReviewDialog">
				<div className={classes.item}>
					<TrusstorIconShared iconName={IconNames.activity} size={IconSize.SMALL} color={IconColor.Grey600} />
					<div className={classes.itemText}>{props.sequenceItemDescription}</div>
				</div>
				<div className={classes.item}>
					<TrusstorIconShared iconName={IconNames.floors} size={IconSize.SMALL} color={IconColor.Grey600} />
					<div className={classes.itemText}>
						<span>
							{area.floorNick}
							{', '}
							{area.areaNick}
						</span>
					</div>
				</div>
				<ProfessionDisplayWithIcon profession={props.profession} projectId={props.projectId} />
			</div>
			<div className={classes.buttons}>
				<TrusstorButton
					handleClick={props.handleRejectClick}
					style={{ width: '100%' }}
					selected={props.isRejectedClicked}
					buttonType={ITrusstorButtonType.OUTLINE_NEGATIVE}
					iconElement={
						<TrusstorIconShared
							iconName={IconNames.issues}
							color={props.isRejectedClicked ? IconColor.FeedbackCriticalDark : IconColor.Red500}
						/>
					}
					text={translationService.get('asiReviewDialog_reject')}
				/>
				<TrusstorButton
					handleClick={props.handleApproveClick}
					style={{ width: '100%' }}
					buttonType={ITrusstorButtonType.OUTLINE_POSITIVE}
					selected={props.isApprovedClicked}
					iconElement={
						<TrusstorIconShared
							iconName={IconNames.check}
							color={props.isApprovedClicked ? IconColor.FeedbackPositiveDark : IconColor.Green500}
						/>
					}
					text={translationService.get('asiReviewDialog_approve')}
				/>
			</div>
		</div>
	);
};
