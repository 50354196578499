import React from 'react';
import classnames from 'classnames';
import classes from './styles.module.scss';
import { useSharedServices } from '../../hooks/sharedServices.context';
import { IconColor, IconSize, TrusstorIconShared } from '../TrusstorIconShared/TrusstorIconShared';
import { IconNames } from '../TrusstorIconShared/IconNames.enum';
import { getDateDisplayFormat } from '../../utils/dateUtils';

interface IDateFlagDisplayProps {
	tz: string;
	isOverdue: boolean;
	date: Date;
	isDarkTheme?: boolean;
	isFullFormat?: boolean;
	showRedBackground?: boolean;
}

export const DateFlagDisplay = (props: IDateFlagDisplayProps) => {
	const { translationService } = useSharedServices();

	const getContainerClassname = (): string => {
		return classnames(
			classes.dateFlagContainer,
			{ [classes.overdue]: props.isOverdue },
			{ [classes.darkTheme]: props.isDarkTheme },
			{ [classes.red]: props.isOverdue && props.showRedBackground }
		);
	};

	const getFlagProperties = (): { iconName: IconNames; iconColor: IconColor } => {
		if (props.isOverdue) {
			if (props.isDarkTheme) {
				return {
					iconName: IconNames.flagFilled,
					iconColor: IconColor.FeedbackCriticalDefault,
				};
			}
			return {
				iconName: IconNames.flag,
				iconColor: IconColor.FeedbackCriticalDark,
			};
		}
		if (props.isDarkTheme) {
			return {
				iconName: IconNames.flagFilled,
				iconColor: IconColor.Grey300,
			};
		}
		return {
			iconName: IconNames.flag,
			iconColor: IconColor.Grey600,
		};
	};

	const flagProperties: {
		iconName: IconNames;
		iconColor: IconColor;
	} = getFlagProperties();

	return (
		<div className={getContainerClassname()}>
			<TrusstorIconShared
				iconName={flagProperties.iconName}
				size={IconSize.SMALL}
				color={flagProperties.iconColor}
			/>
			<span className={classes.dateText}>
				{getDateDisplayFormat(props.date, props.tz, translationService, props.isFullFormat ? 'full' : 'short')}
			</span>
		</div>
	);
};
