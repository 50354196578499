import { useEffect } from 'react';

export const useTabVisibility = (onTabVisible: () => void, deps?: any[]): void => {
	useEffect(() => {
		const onVisibilityChange = () => {
			if (document.visibilityState === 'visible') {
				onTabVisible();
			}
		};
		document.addEventListener('visibilitychange', onVisibilityChange);
		return () => {
			document.removeEventListener('visibilitychange', onVisibilityChange);
		};
	}, deps || []);
};
