import React, { FocusEventHandler, MouseEventHandler, useEffect, useId, useRef, useState } from 'react';
import classes from './styles.module.scss';
import { ITrusstorButtonType, TrusstorButton } from '@shared/components/buttons/TrusstorButton/TrusstorButton';
import { translationService } from '@src/servicesInitializers';
import { TrusstorIcon } from '../TrusstorIcon/TrusstorIcon';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { IconSize } from '@shared/components/TrusstorIconShared/TrusstorIconShared';
import { DateSelectorTrusstorInput } from '@src/Components/DateSelector/DateSelectorTrusstorInput/DateSelectorTrusstorInput';
import { useAddMilestonesMutation } from '@src/hooks/queries/milestones.queries.hooks';
import { selectProjectId, selectTimezone } from '@store/slices/project.slice';
import { useSelector } from 'react-redux';
import { getTimezoneFormattedDate } from '@shared/utils/dateUtils';
import { selectElementContents } from '@utils/document.utils';
import { useOutsideClickWithCallback } from '@src/hooks/custom.hooks';

interface IMilestoneCreationCardProps {
	handleCancelClick?: () => void;
	handleCreateCallback?: () => void;
	index: number;
}

export const MilestoneCreationCard = (props: IMilestoneCreationCardProps) => {
	const containerRef = useRef<HTMLDivElement | null>(null);
	const editTitleEl = useRef<HTMLDivElement>(null);
	const projectId: string = useSelector(selectProjectId)!;
	const tz: string = useSelector(selectTimezone)!;
	const initialTitle: string = translationService.get('milestone_creation_card_title');
	const [title, setTitle] = useState<string>(initialTitle);
	const [dueDate, setDueDate] = useState<Date | null>();
	const id: string = 'editableDiv' + useId();

	useEffect(() => {
		scrollToElement();
	}, []);

	const handleTitleChange = (event: any) => {
		setTitle(event.target.innerText);
	};

	const onEditClick = () => {
		if (editTitleEl.current) {
			editTitleEl.current!.focus();
			const el: HTMLElement | null = document.getElementById(id);
			if (el) {
				selectElementContents(el);
			}
		}
	};

	const shouldCreateBeDisabled: boolean = !title || !dueDate || title === initialTitle;

	const addMilestoneMutation = useAddMilestonesMutation(projectId, tz);

	const handleCreateButtonClick = async () => {
		props.handleCreateCallback?.();
		addMilestoneMutation.mutate({
			projectId,
			title,
			endDate: getTimezoneFormattedDate(tz, dueDate)!,
			isDone: false,
		});
	};

	const scrollToElement = () => {
		const { current } = containerRef;
		if (current) {
			current.scrollIntoView({ behavior: 'smooth' });
		}
	};

	useOutsideClickWithCallback(containerRef, props.handleCancelClick);

	return (
		<div className={classes.wrapper} ref={containerRef}>
			<div className={classes.orderIndex}>{props.index}</div>
			<div className={classes.MilestoneCreationCard_container} data-testid="MilestoneCreationCard">
				<div className={classes.top}>
					<div className={classes.titleDescriptionSection}>
						<div className={classes.titleSection}>
							<div
								onClick={onEditClick}
								id={id}
								tabIndex={1}
								ref={editTitleEl}
								onBlur={handleTitleChange}
								contentEditable
								className={classes.title}
							>
								{initialTitle}
							</div>
							<TrusstorIcon
								iconName={IconNames.edit}
								size={IconSize.SMALL}
								onClick={onEditClick}
								className={classes.icon}
							/>
						</div>
					</div>
					<DateSelectorTrusstorInput
						width={84}
						minDate={new Date()}
						required
						label={dueDate ? translationService.get('dueDate') : undefined}
						inputPlaceHolder={translationService.get('dueDate')}
						handleTimeChange={(date: Date) => setDueDate(date)}
						iconElement={<TrusstorIcon iconName={IconNames.flag} />}
						selectedDate={dueDate}
					/>
				</div>
				<div className={classes.divider}></div>
				<div className={classes.bottom}>
					{props.handleCancelClick && (
						<TrusstorButton
							className={classes.secondaryButton}
							buttonType={ITrusstorButtonType.GHOST}
							text={translationService.get('cancel')}
							handleClick={props.handleCancelClick}
						/>
					)}
					<TrusstorButton
						text={translationService.get('milestone_card_create_button')}
						handleClick={handleCreateButtonClick}
						disabled={shouldCreateBeDisabled}
					/>
				</div>
			</div>
		</div>
	);
};
