import React, { useContext, useState } from 'react';
import classes from './styles.module.scss';
import classnames from 'classnames';
import { IMainPageWidgetActivityData } from '@interfaces/IMainPageWidgetActivityData';
import { WorkPlanDialog } from '../../../WorkPlan/WorkPlanDialog/WorkPlanDialog';
import { ActivityChipMenu } from '../../../Calendar/ActivityChipMenu/ActivityChipMenu';
import { IWorkPlanActivity } from '@src/interfaces';
import { ActivityStartActionDialog } from '../../../ActivityDialogs/ActivityStartActionDialog/ActivityStartActionDialog';
import { ActivityDeleteActionDialog } from '../../../ActivityDialogs/ActivityDeleteActionDialog/ActivityDeleteActionDialog';
import { ActivityMarkAsCompleteActionDialog } from '../../../ActivityDialogs/ActivityMarkAsCompleteActionDialog/ActivityMarkAsCompleteActionDialog';
import { useOutsideClick } from '@shared/hooks/custom.hooks';
import { WidgetContext } from '../widgetContext.context';
import { IActivitiesGroup } from '@interfaces/IActivitiesGroup';
import { getMainPageWidgetActivityDataFromActivitiesGroup } from '../weeklyWidget.utils';
import { IActivityActionsEnum } from '@interfaces/IActivityActions.enum';
import { workplanManualActionMade_BI } from '@utils/bi.utils';
import { IssueCreateDialog } from '@src/Components/IssueCreateDialog/IssueCreateDialog';
import { MultiIssuesViewDialog } from '@src/Components/MultiIssuesViewDialog/MultiIssuesViewDialog';
import { IConfigArea } from '@shared/interfaces/IConfigArea';
import { IIssue } from '@shared/interfaces/IIssue';
import { getColorFromActivityGroupStatus } from '@utils/generalUtils';
import { WidgetActivitiesDialog } from '@src/Components/WorkplanWidgetComponents/WeeklyWidget/WidgetActivitiesDialog/WidgetActivitiesDialog';
import { WidgetActivityCardDetails } from '@src/Components/WorkplanWidgetComponents/WeeklyWidget/WidgetActivityCardDetails/WidgetActivityCardDetails';
import { WidgetActivityCardDetailsHeader } from '@src/Components/WorkplanWidgetComponents/WeeklyWidget/WidgetActivityCardDetailsHeader/WidgetActivityCardDetailsHeader';
import { requestService, translationService } from '@src/servicesInitializers';
import { MainPageContext } from '@src/Components/Pages/MainPage';
import { useDispatch } from 'react-redux';
import { successSnackbar } from '@utils/snackbar.utils';

interface IWeeklyWidgetActivityCardProps {
	activity: IMainPageWidgetActivityData;
	onDialogClose?: () => void;
}

export enum ActivityCardDialogs {
	edit = 'edit',
	start = 'start',
	complete = 'complete',
	delete = 'delete',
}

export const WeeklyWidgetActivityCard = (props: IWeeklyWidgetActivityCardProps) => {
	const dispatch = useDispatch();
	const [workplanActivity, setWorkplanActivity] = useState<IWorkPlanActivity | undefined>(undefined);
	const [showActivityMenu, setShowActivityMenu] = useState<boolean>(false);
	const [showDialog, setShowDialog] = useState<ActivityCardDialogs | undefined>(undefined);
	const [showWidgetDialog, setShowWidgetDialog] = useState<boolean>(false);
	const [showCreateIssueDialog, setShowCreateIssueDialog] = useState<boolean>(false);
	const [isViewIssuesDialogOpen, setIsViewIssuesDialogOpen] = useState<boolean>(false);
	const [menuElement, setMenuElement] = useState<HTMLElement | null>(null);
	const isZeroPercentProgress: boolean = !props.activity.areas.some((area) => area.accumulatedWorkTime > 0);
	const { onActivityChange } = useContext(WidgetContext)!;
	const { refetchLatestWidgetActivities } = useContext(MainPageContext)!;

	useOutsideClick({ current: menuElement }, setShowActivityMenu, false, [{ current: menuElement }]);

	const onWidgetDialogClose = () => {
		setShowWidgetDialog(false);
		refetchLatestWidgetActivities();
	};

	const openEditWorkPlanDialog = async () => {
		const currentActivity: IWorkPlanActivity = await requestService.get(
			`/activities/groups/${props.activity.groupId}`
		);
		if (currentActivity) {
			currentActivity && setWorkplanActivity(currentActivity);
			setShowDialog(ActivityCardDialogs.edit);
		}
	};

	const onActivityCompleted = (): void => {
		onWidgetDialogClose();
		successSnackbar(dispatch, translationService.get('activityWasSuccessfullyUpdated'));
	};

	const onDeleteActionComplete = (): void => {
		onActivityChange(props.activity, true);
	};

	const onActivityDialogEditComplete = async (): Promise<void> => {
		const updatedWidgetActivityData: IMainPageWidgetActivityData = await requestService.get(
			`/activities/groups/mainPageWidgetActivities/${props.activity.groupId}`
		);
		onActivityChange(updatedWidgetActivityData);
		sendActionBiEvent(IActivityActionsEnum.EDIT);
	};

	const openCreateIssueDialog = async () => {
		setShowCreateIssueDialog(true);
	};

	const onCreateIssue = (createdIssue: IIssue) => {
		const updatedActivity: IMainPageWidgetActivityData = {
			...props.activity,
			issues: [...(props.activity.issues ? props.activity.issues : []), createdIssue],
		};

		onActivityChange(updatedActivity);
		setShowCreateIssueDialog(false);
	};

	const openViewIssuesDialog = async () => {
		setIsViewIssuesDialogOpen(true);
	};

	const onUpdateIssue = (updatedIssue: IIssue, deleted?: boolean) => {
		const updatedIssues: IIssue[] = deleted
			? props.activity.issues!.filter((issue) => issue._id !== updatedIssue._id)
			: props.activity.issues!.map((issue) => {
					if (issue._id === updatedIssue._id) {
						return updatedIssue;
					}
					return issue;
				});

		const updatedActivity: IMainPageWidgetActivityData = {
			...props.activity,
			issues: updatedIssues,
		};

		onActivityChange(updatedActivity);
	};

	const onCloseCreateDialog = () => {
		setShowCreateIssueDialog(false);
	};

	const sendActionBiEvent = (action: IActivityActionsEnum) => {
		workplanManualActionMade_BI('Widget', {
			actionType: action,
			description: props.activity.description,
			groupId: props.activity.groupId!,
			profession: props.activity.profession,
			status: props.activity.groupStatus,
		});
	};

	return (
		<>
			<WidgetActivitiesDialog
				openEditWorkPlanDialog={openEditWorkPlanDialog}
				onUpdateIssue={onUpdateIssue}
				activity={props.activity}
				show={showWidgetDialog}
				close={() => onWidgetDialogClose()}
				setShowDialog={setShowDialog}
				openCreateIssueDialog={openCreateIssueDialog}
				openViewIssuesDialog={openViewIssuesDialog}
			/>
			<ActivityStartActionDialog
				show={showDialog === ActivityCardDialogs.start}
				activityGroupId={props.activity.groupId!}
				onClose={() => {
					setShowDialog(undefined);
				}}
				sendBiEventOnSubmit={sendActionBiEvent}
			/>
			<ActivityMarkAsCompleteActionDialog
				show={showDialog === ActivityCardDialogs.complete}
				activityGroupId={props.activity.groupId!}
				onClose={() => {
					setShowDialog(undefined);
				}}
				sendBiEventOnSubmit={sendActionBiEvent}
				showNoWorkDetectedDisclaimerText={isZeroPercentProgress}
				onActionComplete={onActivityCompleted}
			/>
			<ActivityDeleteActionDialog
				show={showDialog === ActivityCardDialogs.delete}
				activityGroupId={props.activity.groupId!}
				onClose={() => {
					setShowDialog(undefined);
				}}
				sendBiEventOnSubmit={sendActionBiEvent}
				onActionComplete={onDeleteActionComplete}
			/>
			{showDialog === ActivityCardDialogs.edit && (
				<WorkPlanDialog
					closeDialog={() => {
						setShowDialog(undefined);
					}}
					onChangeData={onActivityDialogEditComplete}
					activity={workplanActivity!}
					activityStartDate={workplanActivity!.startDate}
					areaSequenceItemMode={!!workplanActivity?.linkedSequenceType}
				/>
			)}
			<IssueCreateDialog
				showCreateDialog={showCreateIssueDialog}
				onClose={onCloseCreateDialog}
				onCreateIssue={onCreateIssue}
				defaultProfession={props.activity.profession}
				defaultAreas={props.activity.areas as IConfigArea[]}
				defaultFloors={props.activity.floors}
				linkedActivityGroupId={props.activity.groupId}
			/>
			<MultiIssuesViewDialog
				onUpdateIssue={onUpdateIssue}
				show={isViewIssuesDialogOpen && !!props.activity.issues?.length}
				close={() => setIsViewIssuesDialogOpen(false)}
				issues={props.activity.issues || []}
				headerTitle={props.activity.description}
				headerInfo={{
					value: translationService.get(props.activity.groupStatus),
					backgroundColor: getColorFromActivityGroupStatus(props.activity.groupStatus),
				}}
			/>
			<div
				className={classnames(classes.activityCard, {
					[classes.selected]: showWidgetDialog,
				})}
				onClick={() => setShowWidgetDialog(true)}
				data-testid={`activityCard&activityId=${props.activity.groupId}`}
			>
				{showActivityMenu && (
					<div
						ref={setMenuElement}
						className={classes.activityMenu}
						style={{ [translationService.getIsRtl() ? 'left' : 'right']: '10px' }}
					>
						<ActivityChipMenu
							width={150}
							activity={{
								status: props.activity.groupStatus,
								issues: props.activity.issues,
							}}
							openEditDialog={openEditWorkPlanDialog}
							openCompleteDialog={() => {
								setShowDialog(ActivityCardDialogs.complete);
							}}
							openStartDialog={() => {
								setShowDialog(ActivityCardDialogs.start);
							}}
							openDeleteDialog={() => {
								setShowDialog(ActivityCardDialogs.delete);
							}}
							openCreateIssueDialog={openCreateIssueDialog}
							openViewIssuesDialog={openViewIssuesDialog}
						/>
					</div>
				)}

				<WidgetActivityCardDetailsHeader
					activity={props.activity}
					setShowActivityMenu={() => setShowActivityMenu(true)}
				/>
				<WidgetActivityCardDetails
					activity={props.activity}
					showIssuesDialog={() => setIsViewIssuesDialogOpen(true)}
					showCompleteDialog={() => setShowDialog(ActivityCardDialogs.complete)}
				/>
			</div>
		</>
	);
};
