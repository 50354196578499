import React from 'react';
import classes from './styles.module.scss';

export interface IHeaderStat {
	title: string;
	stat: string;
}

interface IHeaderStatsProps {
	stats: IHeaderStat[];
	rootStyle?: React.CSSProperties;
}

const HeaderStats = (props: IHeaderStatsProps) => {
	return (
		<div className={classes.statsSection} style={props.rootStyle}>
			{props.stats.map((stat, index) => {
				return (
					<>
						<div key={index} className={classes.statsInnerSection}>
							<div className={classes.statTextHeader}>{stat.title}</div>
							<div className={classes.statText}>{stat.stat}</div>
						</div>
						{index < props.stats.length - 1 && <div className={classes.divider}></div>}
					</>
				);
			})}
		</div>
	);
};

export { HeaderStats };
