import { TrusstorCheckbox } from '@shared/components/TrusstorCheckbox/TrusstorCheckbox';
import classes from './styles.module.scss';
import React from 'react';
import { Typography } from '@material-ui/core';

interface ITrusstorCheckboxWithText {
	text: string;
	disabled?: boolean;
	checked?: boolean;
	onChange?: (value: boolean) => void;
	extraLightText?: string;
	className?: any;
	testId?: string;
}

export const TrusstorCheckboxWithText = (props: ITrusstorCheckboxWithText) => {
	const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (props.onChange) {
			props.onChange(event.target.checked);
		}
	};

	return (
		<div
			className={`${classes.container} ${props.className} ${
				props.extraLightText ? classes.alignCheckboxToTop : ''
			}`}
		>
			<TrusstorCheckbox
				disabled={props.disabled}
				checked={!!props.checked}
				onChange={onChange}
				data-testid={props.testId ?? null}
			/>
			<Typography
				className={props.disabled ? classes.disabledText : classes.text}
				style={{ fontWeight: props.extraLightText ? 500 : 300 }}
			>
				{props.text}
				{props.extraLightText && (
					<div className={`${props.disabled ? classes.disabledText : ''} ${classes.lightText}`}>
						({props.extraLightText})
					</div>
				)}
			</Typography>
		</div>
	);
};
