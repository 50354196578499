import moment from 'moment-timezone';

export const getFormattedMinutesPassedFromTimestamp_BI = (timestamp: number): string => {
	if (!timestamp) {
		throw new Error('timestamp is undefined');
	}
	const totalSecondsDiff: number = moment(Date.now()).diff(timestamp, 'seconds');
	const minutesDiff: number = Math.floor(totalSecondsDiff / 60);
	const secondsDiff: number = totalSecondsDiff - minutesDiff * 60;
	return `${formatTimeUnitToString_BI(minutesDiff)}:${formatTimeUnitToString_BI(secondsDiff)}`;
};

const formatTimeUnitToString_BI = (timeUnit: number): string => {
	return timeUnit.toString().padStart(2, '0');
};
