import React from 'react';
import { useHistory } from 'react-router-dom';
import { analysisCenterReportGeneration_BI } from '@utils/bi.utils';
import { getContractorAnalysisReportRoute, getProjectOverviewReportRoute } from '@utils/analysisCenter.utils';
import { RecentReportCard } from '@src/Components/Pages/AnalysisCenterPages/RecentReportCard/RecentReportCard';
import { translationService } from '@src/servicesInitializers';
import { IContractorAnalysisReport } from '@shared/interfaces/IContractorAnalysisReport';
import { getProfessionDisplayText } from '@shared/utils/professions.utils';

interface IRecentContractorAnalysisReportCardProps {
	contractorAnalysisReport: IContractorAnalysisReport;
}

export const RecentContractorAnalysisReportCard = (props: IRecentContractorAnalysisReportCardProps) => {
	const history = useHistory();

	const redirectToContractorAnalysisReport = () => {
		history.push(getContractorAnalysisReportRoute(props.contractorAnalysisReport._id!));
		analysisCenterReportGeneration_BI(props.contractorAnalysisReport.investigationType, 'recent', [
			props.contractorAnalysisReport.dateGenerated,
		]);
	};

	return (
		<RecentReportCard
			reportId={props.contractorAnalysisReport._id!}
			dateGenerated={props.contractorAnalysisReport.dateGenerated}
			investigationReport={props.contractorAnalysisReport}
			redirectToReport={redirectToContractorAnalysisReport}
		>
			<>{getProfessionDisplayText(props.contractorAnalysisReport.profession, translationService)}</>
		</RecentReportCard>
	);
};
