import React from 'react';
import { FilterCard, IFilterCardProps } from '../FilterCard/FilterCard';
import { ActivityStatusChip } from '../../Chips/StatusChip/ActivityStatusChip';
import { ActivityGroupStatus } from '../../../interfaces/ActivityGroupStatus.enum';

interface IActivityStatusFilterCardProps extends IFilterCardProps {
	status: ActivityGroupStatus;
}

export const ActivityStatusFilterCard = (props: IActivityStatusFilterCardProps) => {
	return <FilterCard {...props} startComponent={<ActivityStatusChip status={props.status} />} />;
};
