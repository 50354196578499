import { cM } from '@shared/utils/colors.utils';
import React from 'react';
import classes from './styles.module.scss';
import { getTagName } from '@utils/tags.utils';
import { ITag } from '@interfaces/ITag';

interface IWorkerCardProps {
	tag: ITag;
	isSelected: boolean;
	handleTagClick: (tagId: string) => void;
}

export const WorkerCard = (props: IWorkerCardProps) => {
	const handleClick = () => {
		props.handleTagClick(props.tag.tagId);
	};

	return (
		<div
			className={cM(`${classes.workerCard} ${props.isSelected && classes.selected}`, classes)}
			onClick={handleClick}
		>
			<div className={cM(classes.tagNick, classes)}>{props.tag.tagNick}</div>
			<div className={cM(classes.tagName, classes)}>{getTagName(props.tag)}</div>
		</div>
	);
};
