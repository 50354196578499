import React from 'react';
import classes from './styles.module.scss';
import { IObserveLocationReport } from '@shared/interfaces/IObserveLocationReport';
import { LocationMenuEquipment } from '@src/Components/Pages/AnalysisCenterPages/ObserveLocationReportPage/ObserveLocationMenu/Equipment/LocationMenuEquipment';
import { LocationMenuActivities } from '@src/Components/Pages/AnalysisCenterPages/ObserveLocationReportPage/ObserveLocationMenu/Activities/LocationMenuActivities';
import { LocationMenuSafetyEvents } from '@src/Components/Pages/AnalysisCenterPages/ObserveLocationReportPage/ObserveLocationMenu/SafetyEvents/LocationMenuSafetyEvents';
import { ISafetyEvent } from '@interfaces/ISafetyEvent';

import { IAssignedActivity } from '@src/interfaces';
import { IMergedEquipment } from '@shared/interfaces/Equipment/IMergedEquipment';

interface IObserveLocationMenuProps {
	currentReport: IObserveLocationReport;
	safetyEvents: ISafetyEvent[];
	dailyActivities: IAssignedActivity[];
	equipments?: IMergedEquipment[];
}

const ObserveLocationEventsSection = (props: IObserveLocationMenuProps) => {
	return (
		<section className={classes.menuContainer}>
			<LocationMenuActivities dailyActivities={props.dailyActivities} />
			<LocationMenuSafetyEvents safetyEvents={props.safetyEvents} />
			<LocationMenuEquipment equipments={props.equipments} />
		</section>
	);
};

export { ObserveLocationEventsSection };
