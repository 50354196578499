import React, { useCallback } from 'react';
import { useEffect, useState } from 'react';
import { useElementIntersecting } from '@shared/hooks/useElementIntersecting.hooks';
import classnames from 'classnames';
import classes from './styles.module.scss';
import littleBlueDotIcon from '@shared/assets/icons/littleBlueDotIcon.svg';
import scrollIntoView from 'scroll-into-view-if-needed';
import { compareProps } from '../../../utils/generalUtils';

interface ITocOptionProps {
	option: any;
	isSelected: boolean;
	idField?: string;
	displayField?: string;
	onChange: (option: any) => void;
	showDot?: boolean;
	enableScrollIntoOption: boolean;
	isLongerThenContainer?: boolean;
}

export const TocOption = React.memo((props: ITocOptionProps) => {
	const [optionElement, setOptionElement] = useState<HTMLElement | null>(null);
	const [isIntersecting, setIsIntersecting] = useState(false);

	const handleTableIntersectingChange = useCallback((isIntersecting: boolean) => {
		setIsIntersecting(isIntersecting);
	}, []);

	useElementIntersecting(optionElement, handleTableIntersectingChange);

	useEffect(() => {
		if (props.isSelected && optionElement && !isIntersecting && props.enableScrollIntoOption) {
			scrollIntoView(optionElement, { behavior: 'auto', block: 'end', inline: 'nearest' });
		}
	}, [props.isSelected, isIntersecting, props.enableScrollIntoOption]);

	return (
		<div
			ref={setOptionElement}
			style={{ width: props.isLongerThenContainer ? 40 : 24 }}
			className={classnames({
				[classes.option]: true,
				[classes.selectedOption]: props.isSelected,
			})}
			onClick={() => props.onChange(props.option)}
		>
			{props.showDot && !props.isSelected && (
				<img className={classes.dotIcon} src={littleBlueDotIcon} alt={'icon'} />
			)}
			{props.displayField ? props.option[props.displayField] : props.option}
		</div>
	);
}, compareProps);
