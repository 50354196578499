import { QueryObserverResult, useQuery } from '@tanstack/react-query';
import { IInvestigationReport } from '@shared/interfaces/IInvestigationReport';
import { IWorkersTimesheetReport } from '@shared/interfaces/IWorkersTimesheetReport';
import { requestService } from '@src/index';
import { IAssignedActivity, ITag } from '@src/interfaces';
import { getTimezoneFormattedDate } from '@shared/utils/dateUtils';
import { IObserveLocationReport } from '@shared/interfaces/IObserveLocationReport';
import { IObserveLocationApiResult } from '@shared/interfaces/IObserveLocationApiResult';
import { InvestigationReportTypes } from '@shared/interfaces/InvestigationReportTypes.enum';
import { ITagRouteWithLocationData } from '@shared/interfaces/ITagRouteWithLocationData';
import moment, { MomentInput } from 'moment-timezone';
import { TIME_RANGE_INCIDENT_REPORT } from '@src/constants/analysisCenter.constants';
import { IBaseInvestigationReport } from '@shared/interfaces/IBaseInvestigationReport';
import { ISafetyEvent } from '@interfaces/ISafetyEvent';
import { getMinDateForIncidentReport } from '@utils/analysisCenter.utils';
import { useIsFeatureFlagEnabled } from '@shared/hooks/useFeatureFlag';
import { FeatureFlagNamesEnum } from '@shared/constants/featureFlags.enum';
import { IProfessionProductivityAllTimeStats } from '@shared/interfaces/IProfessionProductivityAllTimeStats';
import { getRoundedProfessionProductivityReport, getRoundedProfessionReport } from '@utils/roundedData.utils';
import { IRoundedProfessionsAnalysisReport } from '@interfaces/IRoundedProfessionsAnalysisReport';
import {
	IProfessionProductivity,
	IRoundedProfessionProductivityReport,
} from '@interfaces/IRoundedProfessionProductivityReport';
import { QueryKeys } from '@shared/constants/QueryKeys.enum';
import { IWorkersTimesheet } from '@interfaces/IWorkersTimesheets';

export const useRecentInvestigationReportsQuery = (userId: string, projectId: string) => {
	const {
		data: investigationReports,
		refetch: refetchInvestigationReports,
		isInitialLoading,
	} = useQuery<(IInvestigationReport | IWorkersTimesheetReport)[]>(
		['investigationReports', userId, projectId],
		() => requestService.get(`/archive/investigationReports/${userId}?projectId=${projectId}`),
		{
			useErrorBoundary: true,
			initialData: [],
		}
	);
	return { investigationReports, refetchInvestigationReports, isInitialLoading };
};

export const useDailyActivitiesQuery = (
	projectId: string,
	tz: string,
	dateTimestamp: number,
	floorId?: string,
	professionId?: string
): {
	dailyActivities: IAssignedActivity[];
	refetchDailyActivities: () => Promise<QueryObserverResult<IAssignedActivity[], unknown>>;
	isInitialLoading: boolean;
} => {
	const dateString: string = getTimezoneFormattedDate(tz, dateTimestamp);
	let activitiesDailyUrl: string = `/activities/daily/?projectId=${projectId}&date=${dateString}`;
	if (floorId) {
		activitiesDailyUrl += `&floorId=${floorId}`;
	}
	if (professionId) {
		activitiesDailyUrl += `&professionId=${professionId}`;
	}
	const {
		data: dailyActivities,
		refetch: refetchDailyActivities,
		isInitialLoading,
	} = useQuery<IAssignedActivity[]>(
		['dailyActivities', projectId, tz, dateTimestamp, floorId, professionId],
		() => requestService.get(activitiesDailyUrl),
		{
			useErrorBoundary: true,
			initialData: [],
		}
	);
	return { dailyActivities, refetchDailyActivities, isInitialLoading };
};

export const useObserveLocationQuery = (
	projectId: string,
	tz: string,
	report: IObserveLocationReport
): {
	observeLocationReport: IObserveLocationApiResult | undefined;
} => {
	const dateString: string = getTimezoneFormattedDate(tz, report?.investigatedTimestamp);
	const areas: string = report?.location?.map((location) => location.areaId).join(',');
	const { data: observeLocationReport } = useQuery<IObserveLocationApiResult>(
		['observeLocationReport', projectId, tz, areas, dateString],
		() =>
			requestService.get(`/archive/observeLocation/?projectId=${projectId}&date=${dateString}&areasIds=${areas}`),
		{
			useErrorBoundary: true,
		}
	);
	return { observeLocationReport };
};

export const useSafetyEventByTimeRangeQuery = (
	projectId: string,
	tz: string,
	startDate: MomentInput,
	endDate: MomentInput,
	areaId?: string,
	floorId?: string
): {
	safetyEvents: ISafetyEvent[];
	refetchSafetyEvents: () => Promise<QueryObserverResult<ISafetyEvent[], unknown>>;
	isInitialLoading: boolean;
} => {
	const startDateTimestamp: number = moment.tz(startDate, tz).valueOf();
	const endDateTimestamp: number = moment.tz(endDate, tz).valueOf();
	let areaFloorQuery: string = '';
	if (areaId) {
		areaFloorQuery += `&areaId=${areaId}`;
	}
	if (floorId) {
		areaFloorQuery += `&floorId=${floorId}`;
	}
	const {
		data: safetyEvents,
		refetch: refetchSafetyEvents,
		isInitialLoading,
	} = useQuery<ISafetyEvent[]>(
		['safetyEventsByTimeRange', projectId, tz, startDate, endDate],
		() =>
			requestService.get(
				`/safety/rangeSafetyEvents?startRangeTimestamp=${startDateTimestamp}&endRangeTimestamp=${endDateTimestamp}${areaFloorQuery}`
			),
		{
			useErrorBoundary: true,
			enabled: !!(floorId || areaId),
			initialData: [],
		}
	);
	return { safetyEvents, refetchSafetyEvents, isInitialLoading };
};

export const useArchiveMergedTagQuery = (
	currentReport: IInvestigationReport | null
): { mergedTag: ITag; isInitialLoading: boolean } => {
	const shouldGetFullDay = currentReport?.investigationType === InvestigationReportTypes.dailyRoute;
	const route = shouldGetFullDay ? 'mergedTagInOneDayTimeframe' : 'mergedTagInOneHourTimeframe';
	const url = `/archive/mergedTags/${currentReport?.tagId}/${route}`;
	const { data: mergedTag, isInitialLoading } = useQuery(
		['archiveMergedTags', currentReport?.tagId, currentReport?.projectId],
		() =>
			requestService.get(url, {
				params: {
					projectId: currentReport?.projectId,
					timestamp: currentReport?.investigatedTimestamp,
				},
			}),
		{
			enabled: !!currentReport,
			useErrorBoundary: true,
		}
	);
	return { mergedTag, isInitialLoading };
};

export const useInvestigationTagRoutesQuery = (
	currentReport: IInvestigationReport | null,
	tz: string,
	projectId: string
): { tagRoutes: ITagRouteWithLocationData[] | null; isTagRouteLoading: boolean } => {
	let startTimestamp: number;
	let endTimestamp: number;
	if (currentReport?.investigationType === InvestigationReportTypes.dailyRoute) {
		startTimestamp = moment.tz(currentReport?.investigatedTimestamp, tz).startOf('day').valueOf();
		endTimestamp = moment.tz(currentReport?.investigatedTimestamp, tz).endOf('day').valueOf();

		if (endTimestamp > Date.now()) {
			endTimestamp = Date.now();
		}
	} else {
		startTimestamp = (currentReport?.investigatedTimestamp || 0) - TIME_RANGE_INCIDENT_REPORT;
		endTimestamp = (currentReport?.investigatedTimestamp || 0) + TIME_RANGE_INCIDENT_REPORT;
	}
	const url = `/archive/investigationReports/tagRouteAndLocationData/${currentReport?.tagId}`;
	const { data: tagRoutes, isInitialLoading: isTagRouteLoading } = useQuery(
		['investigationTagRoutes', currentReport?.tagId, projectId, currentReport?.investigatedTimestamp],
		() =>
			requestService.get(url, {
				params: {
					startTimestamp,
					endTimestamp,
					projectId,
				},
			}),
		{
			enabled: !!currentReport,
			useErrorBoundary: true,
		}
	);
	return { tagRoutes, isTagRouteLoading };
};

export const useProfessionAllTimeStatsQuery = (
	projectId: string,
	professionId: string
): {
	professionAllTimeStats: IProfessionProductivityAllTimeStats;
	isLoading: boolean;
} => {
	const { data: professionAllTimeStats, isLoading } = useQuery(
		[QueryKeys.professionAllTimeStats, projectId],
		() =>
			requestService.get(`/reports/allTimeProfessionProductivityStats`, {
				params: {
					professionId,
					projectId,
				},
			}),
		{
			enabled: !!professionId,
			useErrorBoundary: true,
		}
	);
	return { professionAllTimeStats, isLoading };
};

export const useProfessionAnalysisReportQuery = (
	projectId: string,
	startDate: number,
	endDate: number,
	tz: string,
	professionId: string
): {
	professionAnalysisReport: IRoundedProfessionsAnalysisReport | undefined;
	isLoading: boolean;
	refetchProfessionProductivityReport: () => void;
} => {
	const serverFormatDateFrom: string = getTimezoneFormattedDate(tz, startDate);
	const serverFormatDateTo: string = getTimezoneFormattedDate(tz, endDate);

	const url: string = `/reports/professionsAnalysis/${professionId}?dateFrom=${serverFormatDateFrom}&dateTo=${serverFormatDateTo}&projectId=${projectId}`;

	const {
		data: professionAnalysisReport,
		isLoading,
		refetch: refetchProfessionProductivityReport,
	} = useQuery(['professionAnalysisReport', projectId, startDate, endDate], () => requestService.get(url), {
		enabled: !!professionId,
		useErrorBoundary: true,
	});
	return {
		professionAnalysisReport: getRoundedProfessionReport(professionAnalysisReport),
		isLoading,
		refetchProfessionProductivityReport,
	};
};

export const useInvestigationReportQuery = <T extends IBaseInvestigationReport>(
	reportId: string
): {
	investigationReport: T;
	isInvestigationReportLoading: boolean;
	refetchInvestigationReport: () => void;
} => {
	const {
		data: investigationReport,
		isInitialLoading: isInvestigationReportLoading,
		refetch: refetchInvestigationReport,
	} = useQuery(
		['currentInvestigationReport', reportId],
		() => requestService.get(`/archive/investigationReports/byReportId/${reportId}`),
		{
			useErrorBoundary: true,
		}
	);
	return { investigationReport, isInvestigationReportLoading, refetchInvestigationReport };
};

export const useTagsTimesheetQuery = (investigationReport: IWorkersTimesheetReport, projectId: string, tz) => {
	const tagTimeSheetApiUrl: string = (() => {
		if (useIsFeatureFlagEnabled(FeatureFlagNamesEnum.useTimesheetV2Api)) {
			return '/reports/tagsTimesheet/v2';
		}

		return '/reports/tagsTimesheet';
	})();

	const { data: tagsTimesheet, isInitialLoading: isTagsTimesheetLoading } = useQuery<IWorkersTimesheet>(
		[
			tagTimeSheetApiUrl,
			'tagsTimesheet',
			projectId,
			investigationReport?.tagIds,
			investigationReport?.fromDate,
			investigationReport?.toDate,
		],
		() =>
			requestService.get(tagTimeSheetApiUrl, {
				params: {
					projectId,
					tagIds: investigationReport?.tagIds,
					fromDate: getTimezoneFormattedDate(tz, moment(investigationReport?.fromDate)),
					toDate: getTimezoneFormattedDate(tz, moment(investigationReport?.toDate)),
				},
			}),
		{
			useErrorBoundary: true,
			enabled: !!investigationReport,
		}
	);

	return { tagsTimesheet, isTagsTimesheetLoading };
};

export const useAbsentWorkDatesForTagQuery = (
	tagId: string | undefined,
	projectId: string,
	tz: string
): Date[] | undefined => {
	const fromDate: string = getTimezoneFormattedDate(tz, getMinDateForIncidentReport(tz));
	const toDate: string = getTimezoneFormattedDate(tz, new Date());
	const { data: absentWorkDates }: { data: Date[] | undefined } = useQuery(
		['absentWorkDates', tagId, projectId],
		() =>
			requestService.get(`/reports/tagsAbsenceDatesBetweenDates/${tagId}`, {
				params: { projectId, toDate, fromDate },
			}),
		{
			useErrorBoundary: true,
			enabled: !!tagId,
		}
	);

	return absentWorkDates?.map((date) => new Date(date));
};

export const useProfessionsProductivityReportsQuery = (
	projectId: string,
	startDate: number,
	endDate: number,
	tz: string,
	professionId?: string
): {
	professionsProductivityReports: IRoundedProfessionProductivityReport[];
	isLoading: boolean;
} => {
	const serverFormatDateFrom: string = getTimezoneFormattedDate(tz, startDate);
	const serverFormatDateTo: string = getTimezoneFormattedDate(tz, endDate);

	const url: string = `/reports/professionsProductivityReports`;

	const { data: professionsProductivityReports, isLoading } = useQuery<IProfessionProductivity[]>(
		[QueryKeys.professionsProductivityReports, projectId, startDate, endDate],
		() =>
			requestService.get(url, {
				params: { professionId, dateFrom: serverFormatDateFrom, dateTo: serverFormatDateTo, projectId },
			}),
		{
			useErrorBoundary: true,
		}
	);

	return {
		professionsProductivityReports: getRoundedProfessionProductivityReport(professionsProductivityReports || []),
		isLoading,
	};
};
