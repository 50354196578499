import React from 'react';
import classes from './styles.module.scss';
import { IBaseNotificationSettings, IUserNotificationSettings } from '@shared/interfaces/INotificationSettings';
import { INotificationCard } from '@shared/interfaces/NotificationSettings/INotificationCard';
import { NotificationChannelTypes } from '@shared/interfaces/NotificationChannelTypes.enum';
import { useSelector } from 'react-redux';
import { selectLoggedUser } from '@store/slices/login.slice';
import { RoleType } from '@shared/interfaces/RoleType.enum';
import { NotificationCard } from '@src/Components/NotificationSettingCard/NotificationCard';
import { INotificationTypes, NotificationTypes } from '@shared/interfaces/NotificationTypes.enum';
import { RestrictedAreaSection } from '@src/Components/NotificationSettingCard/RestrictedArea/RestrictedAreaSection';

interface INotificationCardSectionProps {
	card: INotificationCard;
	index: number;
	isBase?: boolean;
	userSettings?: IUserNotificationSettings;
	baseSettings?: IBaseNotificationSettings;
	handleSettingsChange: (
		value: string | boolean | null,
		notificationType: INotificationTypes,
		channelName?: NotificationChannelTypes,
		frequency?: boolean
	) => Promise<void>;

	handleBaseSettingsChange: (
		notificationType: INotificationTypes,
		recipient?: { username: string; add: boolean },
		value?: string | boolean | null,
		channelName?: NotificationChannelTypes,
		frequency?: boolean
	) => Promise<void>;
}

const NotificationCardSection = (props: INotificationCardSectionProps) => {
	return (
		<div key={`notificationCard_${props.card.type}`}>
			{props.card.type === NotificationTypes.RestrictedArea ? (
				<RestrictedAreaSection
					isBase={props.isBase}
					card={props.card}
					baseSettings={props.baseSettings as IBaseNotificationSettings}
					userSettings={props.userSettings as IUserNotificationSettings}
					handleSettingsChange={props.handleSettingsChange}
					handleBaseSettingsChange={props.handleBaseSettingsChange}
				/>
			) : (
				<NotificationCard
					isBase={props.isBase}
					card={props.card}
					baseSettings={props.baseSettings as IBaseNotificationSettings}
					userSettings={props.userSettings as IUserNotificationSettings}
					handleSettingsChange={props.handleSettingsChange}
					handleBaseSettingsChange={props.handleBaseSettingsChange}
				/>
			)}
		</div>
	);
};

export { NotificationCardSection };
