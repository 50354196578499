import React from 'react';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { TrusstorIcon } from '@src/Components/TrusstorIcon/TrusstorIcon';
import classes from './styles.module.scss';
import { IconColor, IconSize } from '@shared/components/TrusstorIconShared/TrusstorIconShared';
import classnames from 'classnames';

interface ISnackbarContentProps {
	snackbarId: string | number;
	isBanner?: boolean;
	iconElement?: React.ReactNode;
	onClose?: (e: React.MouseEvent) => void;
	children: React.ReactNode;
	onClick?: (e: React.MouseEvent) => void;
}

export const SnackbarContent = React.forwardRef((props: ISnackbarContentProps, ref: any) => {
	return (
		<div
			className={classnames(classes.notificationSnackbar, {
				[classes.isBanner]: props.isBanner,
			})}
			key={props.snackbarId}
			ref={ref}
			onClick={props.onClick}
			style={{ cursor: props.onClick ? 'pointer' : 'initial' }}
		>
			<div className={classes.notificationContainer}>
				<div className={classes.notificationContent}>
					{props.iconElement}
					{props.children}
				</div>
				{props.onClose && (
					<div
						onClick={props.onClose}
						className={classnames(classes.iconContainer, { [classes.isBanner]: props.isBanner })}
					>
						<TrusstorIcon
							iconName={IconNames.close}
							size={props.isBanner ? IconSize.MEDIUM : IconSize.SMALL}
							color={props.isBanner ? IconColor.DarkBlue : IconColor.White}
						/>
					</div>
				)}
			</div>
		</div>
	);
});
