import React, { useEffect, useState } from 'react';
import { requestService, translationService } from '@src/servicesInitializers';
import { IssueCreateCard } from '@src/Components/Pages/IssuesPage/IssueCreateCard/IssueCreateCard';
import { GeneralDialog } from '@src/Components/GeneralDialog/GeneralDialog';
import { createIssueApi } from '@src/apis/issues.api';
import { ICreateIssue, IIssue } from '@shared/interfaces/IIssue';
import { errorSnackbar } from '@utils/snackbar.utils';
import { useDispatch, useSelector } from 'react-redux';
import { IProfession, IUser } from '@src/interfaces';
import { IConfigArea } from '@shared/interfaces/IConfigArea';
import { ICommentShared } from '@shared/interfaces/ICommentShared';
import { addImagesToIssue } from '@shared/utils/issues.utils';
import { selectLoggedUserDetails } from '@store/slices/login.slice';
import { IBaseFloor } from '@shared/interfaces/Floors/IBaseFloor';
import { selectProjectId } from '@store/slices/project.slice';
import { createIssue_BI } from '@utils/bi.utils';

interface IIssueCreateDialogProps {
	showCreateDialog: boolean;
	onClose: () => void;
	onCreateIssue: (issue: IIssue) => void;
	defaultAreas?: IConfigArea[];
	defaultFloors?: IBaseFloor[];
	defaultProfession?: IProfession;
	linkedAreaSequenceItemId?: string;
	linkedActivityGroupId?: string;
}

export const IssueCreateDialog = (props: IIssueCreateDialogProps) => {
	const user: IUser = useSelector(selectLoggedUserDetails)!;
	const projectId: string = useSelector(selectProjectId)!;
	const dispatch = useDispatch();
	const [images, setImages] = useState<File[]>([]);
	const [areas, setAreas] = useState<IConfigArea[]>(props.defaultAreas || []);
	const [createIssue, setCreateIssue] = useState<ICreateIssue | undefined>();

	const loadDefaultAreas = async () => {
		const areas: IConfigArea[] = await requestService.get(
			`/projectConfig/area?projectId=${projectId}&floorIds=${props.defaultFloors
				?.map((floor) => floor.floorId)
				.join(',')}`
		);
		setAreas(areas);
	};

	useEffect(() => {
		if (props.defaultFloors?.length && !props.defaultAreas?.length) {
			loadDefaultAreas();
		}
	}, [props.defaultAreas, props.defaultFloors]);

	const onCreateIssue = async () => {
		if (createIssue) {
			try {
				const newIssue: IIssue = await createIssueApi(createIssue);
				const comments: ICommentShared[] = await addImagesToIssue(newIssue._id, user, images, requestService);
				const newCreatedIssue: IIssue = { ...newIssue, comments };
				props.onCreateIssue(newCreatedIssue);
				createIssue_BI(newCreatedIssue);
			} catch (e) {
				errorSnackbar(dispatch, translationService.get('failedToCreateIssue'));
				console.log('error creating issue', e);
			}
		}
	};

	const onIssueUpdate = (issue: ICreateIssue | undefined, images: File[]) => {
		if (!issue) {
			return setCreateIssue(undefined);
		}
		setImages(images);
		setCreateIssue({
			...issue,
			...(props.linkedAreaSequenceItemId && { linkedAreaSequenceItemId: props.linkedAreaSequenceItemId }),
			...(props.linkedActivityGroupId && { linkedActivityGroupId: props.linkedActivityGroupId }),
		});
	};

	const onCloseCreateDialog = () => {
		setCreateIssue(undefined);
		props.onClose();
	};

	return (
		<GeneralDialog
			show={props.showCreateDialog}
			close={onCloseCreateDialog}
			mainButton={{
				text: translationService.get('create'),
				click: onCreateIssue,
				disabled: !createIssue,
			}}
			secondaryButton={{
				text: translationService.get('cancel'),
				click: onCloseCreateDialog,
			}}
			disableEnforceFocus
		>
			<IssueCreateCard
				onIssueUpdate={onIssueUpdate}
				defaultProfession={props.defaultProfession}
				defaultAreas={areas}
				isDialog
				hidePrivateSection={!!(props.linkedActivityGroupId || props.linkedAreaSequenceItemId)}
			/>
		</GeneralDialog>
	);
};
