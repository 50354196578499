import { biService, projectService, requestService } from '../../index';
import { setWorkingProjectSuccess } from '../slices/project.slice';
import { IProject } from '@shared/interfaces/IProject';
import { setSentryProjectId } from '@shared/utils/monitoring.util';

export const setWorkingProject = (projectId: string, project?: IProject) => async (dispatch, getState) => {
	const chosenProject: IProject =
		project || getState().projectReducer.userProjects.find((project) => project.projectId === projectId);
	projectService.setChosenProject(chosenProject);
	biService.setGlobalData({
		projectId: chosenProject.projectId,
		tz: chosenProject.tz,
	});
	dispatch(setWorkingProjectSuccess({ workingProject: chosenProject }));
	setSentryProjectId(chosenProject.projectId);
};

export const fetchAndSetWorkingProject = (projectId: string) => async (dispatch) => {
	const updatedProject: IProject = await requestService.get(
		`/projectConfig/project/byProjectId?projectId=${projectId}`
	);
	dispatch(setWorkingProject(projectId, updatedProject));
};

export const changeWorkingProject = (projectId: string) => (dispatch) => {
	dispatch(setWorkingProject(projectId));
};
