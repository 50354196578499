import React from 'react';
import lock from '../../../assets/lock.svg';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../store/slices';
import { COLORS } from '../../../constants';
import classes from './styles.module.scss';
import { getShortedWeekDayName } from '@utils/dateUtils';

interface ICalenderCellProps {
	dateTimestamp: number;
	isLocked?: boolean;
	isMarked?: boolean;
}

const CalendarDateCell = ({ dateTimestamp, isLocked = false, isMarked = false }: ICalenderCellProps) => {
	const tz: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.tz);

	return (
		<div
			className={classes.cellContainer}
			style={{
				backgroundColor: isMarked ? COLORS.blue100 : 'transparent',
			}}
		>
			<div className={classes.topRowContainer}>
				{isLocked && <img className={classes.lockIcon} alt={'lock'} src={lock} />}
				<div className={classes.cellDayText}>{getShortedWeekDayName(dateTimestamp, tz)}</div>
			</div>
			<p className={classes.cellDateNumber}>{moment.tz(dateTimestamp, tz).format('DD')}</p>
		</div>
	);
};

export { CalendarDateCell };
