import { sum } from 'lodash';
import moment from 'moment-timezone';
import { IMainPageWidgetActivityData } from '@interfaces/IMainPageWidgetActivityData';
import { ActivityStatusTypes } from '@shared/constants/constants';
import { ActivityGroupStatus } from '@shared/interfaces/ActivityGroupStatus.enum';
import { IAreaSequenceItem } from '@shared/interfaces/IAreaSequenceItem';
import { isAsiComplete } from '@shared/utils/asi.utils';
import { getTimezoneStartOfDate } from './dateUtils';

export const getActivityStatusFromPercentage = (statusPercentage: number): ActivityStatusTypes => {
	if (statusPercentage < 0) {
		return ActivityStatusTypes.NOT_BEGAN;
	}
	if (statusPercentage <= 50) {
		return ActivityStatusTypes.DEFICIENT;
	}
	if (statusPercentage > 50 && statusPercentage < 80) {
		return ActivityStatusTypes.PARTIAL;
	}
	return ActivityStatusTypes.AS_PLANNED;
};

export const getActivityStatusFromAvpRatio = (avpRatio: number): ActivityStatusTypes => {
	return getActivityStatusFromPercentage(avpRatio * 100);
};

export const getLocationsCountFromActivitiesGroupOnFloor = (
	activitiesGroup: IMainPageWidgetActivityData,
	floorId: string
): number => {
	if (activitiesGroup.areaSequenceItems.length > 0) {
		const areaSequenceItemsFromFloor: IAreaSequenceItem[] = activitiesGroup.areaSequenceItems.filter(
			(areaSequenceItem) => areaSequenceItem.area.floorId === floorId
		);
		return areaSequenceItemsFromFloor.length;
	}

	return activitiesGroup.floors.filter((floor) => floor.floorId === floorId).length;
};

export const getCompletedLocationsCountFromActivitiesGroupOnFloor = (
	activitiesGroup: IMainPageWidgetActivityData,
	floorId: string
): number => {
	if (activitiesGroup.areaSequenceItems.length > 0) {
		const areaSequenceItemsFromFloor: IAreaSequenceItem[] = activitiesGroup.areaSequenceItems.filter(
			(areaSequenceItem) => areaSequenceItem.area.floorId === floorId
		);
		return areaSequenceItemsFromFloor.filter((areaSequenceItem) => isAsiComplete(areaSequenceItem.status)).length;
	}

	if (activitiesGroup.groupStatus === ActivityGroupStatus.complete) {
		return 1;
	}

	return 0;
};

export const getActivitiesProgressPercentage = (
	professionActivities: IMainPageWidgetActivityData[],
	floorId: string
): number => {
	const totalProfessionsLocationsNumber: number = sum(
		professionActivities.map((professionActivity: IMainPageWidgetActivityData) =>
			getLocationsCountFromActivitiesGroupOnFloor(professionActivity, floorId)
		)
	);
	const completedProfessionsLocationsNumber: number = sum(
		professionActivities.map((professionActivity: IMainPageWidgetActivityData) =>
			getCompletedLocationsCountFromActivitiesGroupOnFloor(professionActivity, floorId)
		)
	);
	const progressPercentage: number =
		totalProfessionsLocationsNumber === 0
			? 0
			: Math.round((completedProfessionsLocationsNumber / totalProfessionsLocationsNumber) * 100);

	return progressPercentage;
};

export const isOneOfActivitiesOverdue = (activitiesGroups: IMainPageWidgetActivityData[], tz: string): boolean => {
	return activitiesGroups.some((activity: IMainPageWidgetActivityData) => isActivityGroupOverdue(activity, tz));
};

export const isActivityGroupOverdue = (activityGroup: IMainPageWidgetActivityData, tz: string): boolean => {
	const isDelayedOverdue: boolean =
		moment.tz(activityGroup.originalEndDate, tz).isBefore(getTimezoneStartOfDate(tz)) &&
		activityGroup.groupStatus === ActivityGroupStatus.delayed;
	return activityGroup.groupStatus === ActivityGroupStatus.overdue || isDelayedOverdue;
};
