import React, { useState } from 'react';
import classes from './styles.module.scss';
import { IRestrictedAreaTrigger } from '@interfaces/IRestrictedAreaTrigger';
import { translationService } from '@src/servicesInitializers';
import { getProfessionDisplayText } from '@shared/utils/professions.utils';
import { RestrictedAreaEditDialog } from '@src/Components/NotificationSettingCard/RestrictedArea/Dialogs/RestricteAreaEditDialog/RestrictedAreaEditDialog';
import { RestrictedAreaTitleTooltip } from '@src/Components/NotificationSettingCard/RestrictedArea/RestrictedAreaTooltip/RestrictedAreaTooltip';
import { capitalizeFirstLetter } from '@shared/utils/text.utils';

interface IRestrictedAreaTitleProps {
	trigger: IRestrictedAreaTrigger;
	updateTriggersList: () => void;
}

export const RestrictedAreaTitleSection = (props: IRestrictedAreaTitleProps) => {
	const [showEditDialog, setShowEditDialog] = useState<boolean>();
	const floorsLength: number | undefined = props.trigger.floors?.length;
	const areaLength: number | undefined = props.trigger.areas?.length;

	const getFloorsText = (): string => {
		if (!props.trigger.floors) return '';
		return floorsLength! > 1
			? `${floorsLength} ${translationService.get('floors')}`
			: `${capitalizeFirstLetter(props.trigger.floors[0].floorNick)}`;
	};

	const getAreasText = (): string => {
		if (!props.trigger.areas) return '';
		return areaLength! > 1
			? `${areaLength} ${translationService.get('areas')}`
			: `${capitalizeFirstLetter(props.trigger.areas[0].areaNick)}`;
	};

	const getLocationText = (): string => {
		if (floorsLength) return getFloorsText();
		return getAreasText();
	};

	const getProfessionText = (): string => {
		if (!props.trigger.profession) return '';
		const professionLength: number = props.trigger.profession?.professions?.length;
		const isAllSelected: boolean = props.trigger.profession?.isAllSelected;
		if (isAllSelected) return translationService.get('allProfessions');
		return professionLength! > 0
			? `${professionLength} ${translationService.get('professions')}`
			: `${getProfessionDisplayText(props.trigger.profession?.professions[0], translationService)}`;
	};

	const handleClick = () => {
		setShowEditDialog(true);
	};

	const handleCloseEditDialog = () => {
		setShowEditDialog(false);
		props.updateTriggersList();
	};

	return (
		<div className={classes.wrapper}>
			{showEditDialog && <RestrictedAreaEditDialog trigger={props.trigger} handleClose={handleCloseEditDialog} />}
			<div className={classes.title}>
				<strong>{getLocationText()}</strong>
				<div>{translationService.get('isRestrictedFor')}</div>
				<strong>{getProfessionText()}</strong>
				<RestrictedAreaTitleTooltip trigger={props.trigger} />
			</div>
			<div>
				<div onClick={handleClick} className={classes.edit}>
					{translationService.get('edit')}
				</div>
			</div>
		</div>
	);
};
