import { IInvestigationReport } from '@shared/interfaces/IInvestigationReport';
import { InvestigationReportTypes } from '@shared/interfaces/InvestigationReportTypes.enum';
import { requestService, translationService } from '../index';
import { errorSnackbar, successSnackbar } from './snackbar.utils';
import { Dispatch } from 'redux';
import moment from 'moment-timezone';
import { ICreateProjectOverviewReport } from '@shared/interfaces/IProjectOverviewReport';
import { DATE_PICKER_TOGGLE } from '@shared/interfaces/DatePickerMode';
import { getTimezoneFormattedDate } from '@shared/utils/dateUtils';
import { analysisCenterReportGeneration_BI } from '@utils/bi.utils';

export const updateInvestigationReport = async (
	reportId: string,
	attributesToUpdate: Partial<IInvestigationReport>,
	dispatch: Dispatch<any>,
	refetchInvestigationReport: () => void
) => {
	try {
		await requestService.put(`/archive/investigationReports/byReportId/${reportId}`, {
			body: {
				attributesToUpdate,
			},
		});
		successSnackbar(dispatch, translationService.get('reportUpdateSuccess'));
		await refetchInvestigationReport();
	} catch (e) {
		console.error(e);
		errorSnackbar(dispatch, translationService.get('reportUpdateFailure'));
	}
};

export const getWorkersTimesheetRoute = (reportId: string) =>
	`/analysisCenter/${InvestigationReportTypes.workersTimesheet}/${reportId}`;
export const getLocationReportRoute = (reportId: string) =>
	`/analysisCenter/${InvestigationReportTypes.observeLocation}/${reportId}`;

export const getProjectOverviewReportRoute = (reportId: string) =>
	`/analysisCenter/${InvestigationReportTypes.projectOverview}/${reportId}`;

export const getContractorAnalysisReportRoute = (reportId: string) =>
	`/analysisCenter/${InvestigationReportTypes.contractorAnalysis}/${reportId}`;
export const getMinDateForIncidentReport = (tz): Date => moment.tz(tz).subtract(4, 'month').startOf('day').toDate();
