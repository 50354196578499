import { IUtilityMergedTag } from '@interfaces/IUtilityMergedTag';
import { Dictionary, groupBy } from 'lodash';
import classes from './styles.module.scss';
import { EquipmentChip } from '@src/Components/Chips/EquipmentChip/EquipmentChip';
import { getChipDescriptionByUtilityType, getGroupByFunctionByUtilityType } from '@utils/utilities.utils';
import { getActiveScissorLiftTags } from '@utils/scissorLift.utils';
import { COLORS } from '@shared/constants/colors.constants';

interface IUtilitiesChipsContainerProps {
	utilitiesForFloor: IUtilityMergedTag[];
}

const UtilitiesChipsContainer = (props: IUtilitiesChipsContainerProps, ref: any) => {
	if (props.utilitiesForFloor.length === 0) {
		return null;
	}

	const activeScissorLiftTags: IUtilityMergedTag[] = getActiveScissorLiftTags(props.utilitiesForFloor);
	const notActiveScissorLiftTags: IUtilityMergedTag[] = props.utilitiesForFloor.filter(
		(lift) => !activeScissorLiftTags.some((activeLift) => activeLift._id === lift._id)
	);

	const activeUtilitiesGrouped: Dictionary<IUtilityMergedTag[]> = groupBy(
		activeScissorLiftTags,
		getGroupByFunctionByUtilityType(props.utilitiesForFloor[0].linkedUtility.type)
	);
	const notActiveUtilitiesGrouped: Dictionary<IUtilityMergedTag[]> = groupBy(
		notActiveScissorLiftTags,
		getGroupByFunctionByUtilityType(props.utilitiesForFloor[0].linkedUtility.type)
	);

	return (
		<div className={classes.UtilitiesChipsContainer}>
			{Object.values(activeUtilitiesGrouped).map((professionUtilities: IUtilityMergedTag[]) => {
				const description: string | undefined = getChipDescriptionByUtilityType(professionUtilities[0]);
				return (
					<EquipmentChip
						description={description}
						color={{ backgroundColor: COLORS.green400, textColor: COLORS.white }}
						type={professionUtilities[0].linkedUtility.type}
						number={professionUtilities.length}
					/>
				);
			})}
			{Object.values(notActiveUtilitiesGrouped).map((professionUtilities: IUtilityMergedTag[]) => {
				const description: string | undefined = getChipDescriptionByUtilityType(professionUtilities[0]);
				return (
					<EquipmentChip
						description={description}
						color={{ backgroundColor: COLORS.grey100, textColor: COLORS.grey600 }}
						type={professionUtilities[0].linkedUtility.type}
						number={professionUtilities.length}
					/>
				);
			})}
		</div>
	);
};

export default UtilitiesChipsContainer;
