import React, { useState } from 'react';
import moment from 'moment-timezone';
import 'moment/locale/he';
import { cM } from '@shared/utils/colors.utils';
import { getDateStringByLocale } from '@shared/utils/dateUtils';
import { IMilestone } from '@shared/interfaces/IMilestone';
import { useDispatch, useSelector } from 'react-redux';
import { selectTimezone } from '../../../../../store/slices/project.slice';
import lineBreakIcon from '../../../../../assets/milestonesIcons/lineBreak.svg';
import emptyCheckboxIcon from '../../../../../assets/milestonesIcons/emptyCheckbox.svg';
import vCheckboxIcon from '../../../../../assets/milestonesIcons/vCheckbox.svg';
import { requestService, translationService } from '../../../../../index';
import classes from './styles.module.scss';
import { errorSnackbar } from '../../../../../utils/snackbar.utils';

interface IMilestoneRowProps {
	icon: string;
	milestoneIndex: number;
	milestone: IMilestone;
	title?: string;
	bracketsText?: string | boolean;
	separateLine?: boolean;
	showMarkCompletedButton?: boolean;
}

const MilestoneRow = (props: IMilestoneRowProps) => {
	const isRtl: boolean = translationService.getIsRtl();
	const tz: string = useSelector(selectTimezone)!;
	const [manualChecked, setManualChecked] = useState<boolean>(false);
	const dispatch = useDispatch();

	const getDateString = (date: Date): string => {
		return getDateStringByLocale(date, tz, translationService.getDateLocale());
	};

	const markMilestoneAsCompleted = async () => {
		try {
			setManualChecked(true);
			await requestService.put(`/activities/milestones/${props.milestone._id}`, { body: { isDone: true } });
		} catch (e: any) {
			errorSnackbar(dispatch, translationService.get('failedToUpdateMilestone'));
			console.error(e?.message);
		}
	};

	const getMarkCompleteButton = (): JSX.Element => {
		const isPastMilestone: boolean = moment
			.tz(props.milestone.endDate, tz)
			.isSameOrBefore(moment.tz(new Date(), tz), 'day');
		const isFutureMilestone: boolean = moment
			.tz(props.milestone.endDate, tz)
			.isAfter(moment.tz(new Date(), tz), 'day');
		const isMilestoneDone: boolean = props.milestone.isDone || manualChecked;
		const icon: string = isPastMilestone && isMilestoneDone ? vCheckboxIcon : emptyCheckboxIcon;
		return (
			<div
				className={`${classes.markCompletedContainer} ${
					isFutureMilestone || isMilestoneDone ? classes.futureMilestone : ''
				}`}
				onClick={markMilestoneAsCompleted}
			>
				<section className={cM(classes.markCompletedButton, classes)}>
					<img src={icon} alt="" />
					<div className={`${classes.markCompletedText} ${isMilestoneDone ? classes.futureMilestone : ''}`}>
						{translationService.get('markCompleted')}
					</div>
				</section>
			</div>
		);
	};

	return (
		<div className={classes.rowContainer}>
			<div className={classes.row}>
				<div className={classes.iconContainer}>
					<img src={props.icon} alt="" />
					{props.milestoneIndex > 0 && (
						<div className={`${cM(classes.milestoneIndex, classes)} ${isRtl ? classes.rtl : ''}`}>
							{props.milestoneIndex}
						</div>
					)}
				</div>
				<div className={classes.infoContainer}>
					{props.title && <div className={classes.title}>{props.title}</div>}
					<div className={cM(classes.milestoneTitleText, classes)}>{props.milestone.title}</div>
					<div className={classes.dateText}>
						<span>{getDateString(props.milestone.endDate)} </span>
						{props.bracketsText && <span>({props.bracketsText})</span>}
					</div>
				</div>
				{props.showMarkCompletedButton && getMarkCompleteButton()}
			</div>
			{props.separateLine && (
				<div className={classes.separateLineContainer}>
					<img className={classes.separateLine} src={lineBreakIcon} alt="" />
				</div>
			)}
		</div>
	);
};

export { MilestoneRow };
