import React from 'react';
import { DateFlagDisplay } from '@shared/components/DateFlagDisplay/DateFlagDisplay';
import classes from './styles.module.scss';
import classnames from 'classnames';
import { IProfession } from '@interfaces/index';
import { useSelector } from 'react-redux';
import { selectProjectId, selectTimezone } from '@store/slices/project.slice';
import { TrusstorIcon } from '@src/Components/TrusstorIcon/TrusstorIcon';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { IconColor, IconSize, TrusstorIconShared } from '@shared/components/TrusstorIconShared/TrusstorIconShared';
import { LineDelimiter } from '@src/Components/LineDelimiter/LineDelimiter';
import { COLORS } from '@src/constants';
import { IIssue } from '@shared/interfaces/IIssue';
import { isIssueOverdue } from '@shared/components/Issue/issues.utils';
import { IssuePriority } from '@shared/interfaces/IIssueShared';
import { ProfessionDisplayWithTradeIcon } from '@shared/components/ProfessionDisplayWithTradeIcon/ProfessionDisplayWithTradeIcon';

interface IProfessionIssuesChipTooltipProps {
	floorId: string;
	profession: IProfession | undefined;
	issuesData: IIssue[];
}

export const ProfessionIssuesChipTooltip = (props: IProfessionIssuesChipTooltipProps) => {
	const projectId: string = useSelector(selectProjectId)!;
	const tz: string = useSelector(selectTimezone)!;
	return (
		<div className={classes.professionIssuesChipTooltipContainer}>
			{props.profession && (
				<div className={classes.topProfessionDetails}>
					<TrusstorIcon iconName={IconNames.issuesFilled} color={IconColor.Grey300} size={IconSize.MEDIUM} />
					<div>
						<ProfessionDisplayWithTradeIcon
							contractorTextClassname={classes.detailsText}
							profession={props.profession}
							projectId={projectId}
						/>
					</div>
				</div>
			)}
			{props.issuesData.map((issueData: IIssue, index: number) => {
				const isLastIssue: boolean = index === props.issuesData.length - 1;
				return (
					<div
						className={classnames(classes.issueDataContainer, {
							[classes.noBottomBorder]: isLastIssue,
						})}
					>
						<div className={classes.issueDescriptionContainer}>
							{issueData.priority === IssuePriority.CRITICAL && (
								<TrusstorIconShared
									iconName={IconNames.star}
									color={IconColor.White}
									size={IconSize.SMALL}
									filledIcon
								/>
							)}
							<p className={classes.issueDescription}>{issueData.description}</p>
						</div>
						<div className={classes.issueInfo}>
							{issueData.assignee && (
								<div className={classes.iconText}>
									<TrusstorIcon iconName={IconNames.userFilled} color={IconColor.Grey300} />
									{issueData.assignee.name}
								</div>
							)}
							{issueData.assignee && issueData.dueDate && (
								<LineDelimiter height={16} color={COLORS.grey300} />
							)}
							{issueData.dueDate && (
								<DateFlagDisplay
									date={issueData.dueDate}
									isOverdue={isIssueOverdue(issueData, tz)}
									tz={tz}
									isDarkTheme={true}
								/>
							)}
						</div>
					</div>
				);
			})}
		</div>
	);
};
