import React, { useState, useEffect, useCallback } from 'react';
import { Grid } from '@material-ui/core';
import { orderBy, partition } from 'lodash';
import { translationService } from '../../../index';
import { SearchBar } from '@src/Components/Inputs/SearchBar/SearchBar';
import { TagRow } from '../TagRow';
import {
	ADMIN_PANEL_CELL_WIDTHS,
	unassignedTradeId,
	PAGE_TITLE_PADDING,
	ADMIN_PANEL_TITLE_CENTERED,
} from '../../../constants';
import { ITag } from '../../../interfaces';
import { ISortingState } from '../../../interfaces/ISortingState';
import { AssignTagDialog } from '../../AssignTagDialog/AssignTagDialog';
import { getProfessionDisplayText } from '../../../utils/professions.utils';
import { TableTitle } from '../../TableComponents/TableTitle/TableTitle';
import {
	getBatteryLevelNumber,
	getCertificateSortOrder,
	getStatusSortOrder,
} from '../../../utils/adminPanelTags.utils';
import { IRootState } from '../../../store/slices';
import { useSelector } from 'react-redux';
import { NoTagsAvailableDialog } from '../../AssignTagDialog/NoTagsAvailableDialog/NoTagsAvailableDialog';
import { TrusstorButton } from '@shared/components/buttons/TrusstorButton/TrusstorButton';
import classes from './styles.module.scss';
import { SORT_ORDER } from '@shared/constants/constants';

const tagsTableTitles: { [key: string]: string } = {
	tagId: 'tagId',
	linkedTo: 'linkedTo',
	fullName: 'fullName',
	IDNumber: 'idNumber',
	phone: 'phoneNumber',
	certificates: 'certificates',
	status: 'status',
	lastSeen: 'lastSeen',
	battery: 'battery',
};

interface ITagsTableProps {
	tags: ITag[];
	onSearchInputChange: (text: string) => void;
	sortBy?: string;
}

const TagsTable = (props: ITagsTableProps) => {
	const [showEditTagDialog, setShowEditTagDialog] = useState<boolean>(false);
	const [showNoTagsAvailableDialog, setShowNoTagsAvailableDialog] = useState<boolean>(false);
	const staticTags: ITag[] = useSelector((state: IRootState) => state.tagsReducer.tags);

	const handleOpenLinkTagDialog = () => {
		const isAtLeastOneTagIsUnassigned: boolean = staticTags.some(
			(tag) => tag.profession?.tradeId === unassignedTradeId
		);
		if (!isAtLeastOneTagIsUnassigned) {
			setShowNoTagsAvailableDialog(true);
			return;
		}
		setShowEditTagDialog(true);
	};
	const handleCloseLinkTagDialog = () => {
		setShowEditTagDialog(false);
	};
	const handleCloseNoTagsAvailableDialog = () => {
		setShowNoTagsAvailableDialog(false);
	};
	const tz: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.tz);

	const [sortingState, setSortingState] = useState<ISortingState>({
		isSorted: false,
		columnName: '',
		sortOrder: SORT_ORDER.DESCENDING,
	});

	const updateSortingState = (newState: Partial<ISortingState>) => {
		setSortingState((prevState) => ({
			...prevState,
			...newState,
		}));
	};

	const [sortedTags, setSortedTags] = useState<ITag[]>([]);
	const [isDefaultSort, setIsDefaultSort] = useState(true);

	const sortColumnByOrder = useCallback(() => {
		const sortParameter = tagsTableTitles[sortingState.columnName];
		const currentSortOrder = sortingState.sortOrder;

		if (sortParameter === tagsTableTitles.linkedTo) {
			const lastSeenSortOrder = SORT_ORDER.DESCENDING;
			const sortedTags: ITag[] = orderBy(
				props.tags,
				[
					(tag) => tag.profession?.sortIndex || tag.manager?.sortIndex || tag.linkedUtility?.sortIndex,
					(tag) => tag.profession && getProfessionDisplayText(tag.profession),
					(tag) => tag.lastSeen,
				],
				[currentSortOrder, currentSortOrder, currentSortOrder, lastSeenSortOrder]
			);
			setSortedTags(sortedTags);
			return;
		}

		if (sortParameter === tagsTableTitles.lastPosition) {
			const [notNumericFloor, numericFloor] = partition(props.tags, (tag) => isNaN(tag.lastFloorId as any));
			const sortedNumericFloorTags = orderBy(
				numericFloor,
				[(tag) => Number(tag.lastFloorId)],
				[currentSortOrder]
			);
			const sortedNotNumericFloorTags = orderBy(notNumericFloor, [(tag) => tag.lastFloorId], [currentSortOrder]);

			setSortedTags(
				currentSortOrder === SORT_ORDER.DESCENDING
					? [...sortedNotNumericFloorTags, ...sortedNumericFloorTags]
					: [...sortedNumericFloorTags, ...sortedNotNumericFloorTags]
			);
			return;
		}

		if (sortParameter === tagsTableTitles.tagNumber) {
			const sortedTags = orderBy(props.tags, [(tag) => Number(tag[sortParameter])], [currentSortOrder]);
			setSortedTags(sortedTags);
			return;
		}

		if (sortParameter === tagsTableTitles.battery) {
			const sortedTags = orderBy(
				props.tags,
				[(tag) => getBatteryLevelNumber(tag.batteryLevel)],
				[currentSortOrder]
			);
			setSortedTags(sortedTags);
			return;
		}

		if (sortParameter === tagsTableTitles.certificates) {
			const sortedTags = orderBy(props.tags, [(tag) => getCertificateSortOrder(tag, tz)], [currentSortOrder]);
			setSortedTags(sortedTags);
			return;
		}

		if (sortParameter === tagsTableTitles.fullName) {
			const sortedTags = orderBy(props.tags, [(tag) => tag.name || tag.manager?.name], [currentSortOrder]);
			setSortedTags(sortedTags);
			return;
		}

		if (sortParameter === tagsTableTitles.status) {
			const sortedTags = orderBy(props.tags, [(tag) => getStatusSortOrder(tag)], [currentSortOrder]);
			setSortedTags(sortedTags);
			return;
		}

		const sortedTags = orderBy(props.tags, [(tag: ITag) => tag[sortParameter] || ''], [currentSortOrder]);
		setSortedTags(sortedTags);
	}, [sortingState, props.tags, tz]);

	useEffect(() => {
		if (props.tags.length === 0) {
			setSortedTags([]);
			return;
		}

		if (isDefaultSort) {
			const sortedTags = orderBy(
				props.tags,
				[(tag) => tag.profession?.sortIndex || tag.manager?.sortIndex],
				[SORT_ORDER.ASCENDING]
			);
			setSortedTags(sortedTags);
			setSortingState({
				isSorted: true,
				columnName: tagsTableTitles.linkedTo,
				sortOrder: SORT_ORDER.ASCENDING,
			});
			setIsDefaultSort(false);
		} else {
			sortColumnByOrder();
		}
	}, [props.tags, sortColumnByOrder, isDefaultSort]);

	useEffect(() => {
		sortColumnByOrder();
	}, [sortingState, sortColumnByOrder]);

	useEffect(() => {
		if (props.sortBy && tagsTableTitles[props.sortBy]) {
			setSortingState({
				isSorted: true,
				columnName: props.sortBy,
				sortOrder: SORT_ORDER.ASCENDING,
			});
			setIsDefaultSort(false);
		}
	}, [props.sortBy]);

	return (
		<React.Fragment>
			{showEditTagDialog && <AssignTagDialog close={handleCloseLinkTagDialog} />}
			<NoTagsAvailableDialog show={showNoTagsAvailableDialog} onClose={handleCloseNoTagsAvailableDialog} />
			<Grid container direction="column" className={classes.tableContainer}>
				<div
					className={classes.title}
					style={{
						padding: `${PAGE_TITLE_PADDING}px 0 ${PAGE_TITLE_PADDING}px`,
					}}
					data-testid="tagsTableTitle"
				>
					{translationService.get('tagsPage')}
				</div>
				<Grid
					item
					container
					className={classes.searchAndButtonContainer}
					justify="space-between"
					alignItems="center"
				>
					<div>
						<SearchBar
							handleChangeInput={props.onSearchInputChange}
							placeholder={translationService.get('searchTagByNumberWorkerName')}
							testId={'tagSearchBar'}
						/>
					</div>
					<TrusstorButton
						handleClick={handleOpenLinkTagDialog}
						text={translationService.get('assignTagTitle')}
						testId={'assignTagButton'}
					/>
				</Grid>
				<div className={classes.listContainer}>
					<div className={classes.listWrapper}>
						<div className={classes.headerContainer}>
							{Object.keys(tagsTableTitles).map((title) => (
								<TableTitle
									key={title}
									columnSpan={ADMIN_PANEL_CELL_WIDTHS[title]}
									sortingState={sortingState}
									currentColumn={title}
									updateSortingState={updateSortingState}
									isTextCentered={ADMIN_PANEL_TITLE_CENTERED[title]}
								/>
							))}
						</div>
						<div className={classes.rowsContainer} data-testid={'tagsTable'}>
							{sortedTags.length > 0 && sortedTags.map((tag) => <TagRow tag={tag} key={tag.tagId} />)}
						</div>
					</div>
				</div>
			</Grid>
		</React.Fragment>
	);
};

export { TagsTable };
