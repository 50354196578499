import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { orderBy } from 'lodash';
import { textStyle } from '@shared/utils/text.utils';
import { translationService } from '../../index';
import { TrusstorBox } from '../Boxes/Containers/TrusstorBox';
import { SearchBar } from '@src/Components/Inputs/SearchBar/SearchBar';
import { COLORS, PROFESSIONS_TABLE_COLUMN_WIDTH, TableRowTypes } from '../../constants';
import { IProfession, ITableTitleItems } from '../../interfaces';
import { ISortingState } from '../../interfaces/ISortingState';
import { TableTitle } from '../TableComponents/TableTitle/TableTitle';
import { IRowProperty } from '../../interfaces/IRowProperty';
import { CreateProfessionDialog } from './Dialogs/CreateProfessionDialog/CreateProfessionDialog';
import { HTTPRequestStatuses } from '@shared/interfaces/HTTPRequestStatuses.enum';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../store/slices';
import { MainTableRow } from '../TableComponents/TableRow/MainTableRow';
import { TrusstorButton } from '@shared/components/buttons/TrusstorButton/TrusstorButton';
import { SORT_ORDER } from '@shared/constants/constants';
import { SimpleToggle } from '@src/Components/SimpleToggle/SimpleToggle';
import { selectProjectId, selectTimezone } from '@store/slices/project.slice';
import { getWorkingProfessions, updateProfession } from '@store/thunks';
import { errorSnackbar } from '@utils/snackbar.utils';
import { getDoesProfessionHasActivitiesAssigned, getDoesProfessionHasTagsAssigned } from './professions.utils';
import { ProfessionTableDisplaySettingsMenu } from './ProfessionTableDisplaySettingsMenu/ProfessionTableDisplaySettingsMenu';
import { IProfessionDisplaySettings } from '@shared/interfaces/IProfessionDisplaySettings';
import { updateProjectProfessionsDisplaySettings } from '@src/apis/professions.api';
import { getTradeGroupTranslation, getTradeTranslation } from '@utils/translations.utils';
import { ProfessionDisplayWithTradeIcon } from '@shared/components/ProfessionDisplayWithTradeIcon/ProfessionDisplayWithTradeIcon';

const useStyles = makeStyles((theme) => ({
	listContainer: {
		width: '100%',
		height: '91%',
		minHeight: '435px',
		padding: '0 0 8px',
		borderRadius: 3,
		boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2)',
		backgroundColor: COLORS.white,
		overflow: 'hidden',
	},
	headerContainer: {
		display: 'flex',
		paddingInlineStart: '24px',
	},
	header: {
		display: 'flex',
		flexWrap: 'nowrap',
	},
	rowsContainer: {
		display: 'flex',
		flexDirection: 'column',
		height: 'calc(100% - 42px)',
		width: '100%',
		overflowY: 'auto',
		flexWrap: 'nowrap',
	},
	tableWrapper: {
		height: `calc(100% - 14px)`,
	},
	searchAndButtonContainer: {
		height: 48,
		width: '100%',
		marginBottom: theme.spacing(1),
	},
	arrow: {
		width: 14,
		height: 14,
	},
	tableContainer: {
		height: '100%',
		flexWrap: 'nowrap',
	},
	placeholderText: textStyle({
		textAlign: 'center',
		fontSize: '20px',
		lineHeight: '29.38px',
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		opacity: 0.5,
	}),
}));

interface IProfessionsProps {
	professions: IProfession[];
	onSearchInputChange: (text: string) => void;
	isSearchInputExist: boolean;
}

const tradeGroupSortFunctions = [
	(profession) => profession.sortIndex,
	(profession) => getTradeGroupTranslation(profession),
	(profession) => getTradeTranslation(profession),
	(profession) => profession.specialty,
	(profession) => profession.contractor,
];

const ProfessionsListTable = (props: IProfessionsProps) => {
	const dispatch = useDispatch();
	const projectId: string = useSelector(selectProjectId)!;
	const tz: string = useSelector(selectTimezone)!;
	const [showProfessionCreateDialog, setShowProfessionCreateDialog] = useState<boolean>(false);
	const classes = useStyles();
	const workingProfessionRequestStatus: HTTPRequestStatuses = useSelector(
		(state: IRootState) => state.professionsReducer.workingProfessionsRequestStatus
	);
	const handleOpenLinkProfessionDialog = () => {
		setShowProfessionCreateDialog(true);
	};
	const handleCloseLinkProfessionDialog = () => {
		setShowProfessionCreateDialog(false);
	};
	const onDisplaySettingsChange = async (newDisplaySettings: IProfessionDisplaySettings) => {
		await updateProjectProfessionsDisplaySettings(projectId, newDisplaySettings);
		dispatch(getWorkingProfessions({ projectId, includeInactive: true }));
	};

	const professionListTitles: ITableTitleItems = {
		active: { title: 'active', width: '150px' },
		tradeGroup: { title: 'tradeGroup' },
		trade: { title: 'trade' },
		specialty: { title: 'specialty' },
		contractor: { title: 'contractor' },
		display: {
			title: 'display',
			disableSort: true,
			menuContent: (
				<ProfessionTableDisplaySettingsMenu
					professions={props.professions}
					projectId={projectId}
					onSettingChange={onDisplaySettingsChange}
				/>
			),
		},
	};

	const [sortingState, setSortingState] = useState<ISortingState>({
		isSorted: true,
		columnName: 'active',
		sortOrder: SORT_ORDER.ASCENDING,
	});

	const updateSortingState = (newState: Partial<ISortingState>) => {
		dispatch(getWorkingProfessions({ projectId, includeInactive: true }));
		setSortingState((prevState) => ({
			...prevState,
			...newState,
		}));
	};

	const [sortedProfessions, setSortedProfessions] = useState<IProfession[]>([]);
	const [isDefaultSort, setIsDefaultSort] = useState<boolean>(true);

	const sortColumnByOrder = useCallback(() => {
		const sortParameter: string = professionListTitles[sortingState.columnName].title;
		const currentSortOrder: ISortingState['sortOrder'] = sortingState.sortOrder;
		const lastSeenSortOrder: ISortingState['sortOrder'] = SORT_ORDER.DESCENDING;

		switch (sortParameter) {
			case professionListTitles.active.title:
				setSortedProfessions(
					orderBy(
						props.professions,
						[(profession: IProfession) => profession.activeProjects[projectId]],
						[currentSortOrder]
					)
				);
				return;
			case professionListTitles.tradeGroup.title:
				setSortedProfessions(
					orderBy(props.professions, tradeGroupSortFunctions, [currentSortOrder, lastSeenSortOrder])
				);
				return;
			case professionListTitles.trade.title:
				setSortedProfessions(orderBy(props.professions, [`tradeId`], [currentSortOrder, lastSeenSortOrder]));
				return;
			case professionListTitles.specialty.title:
				setSortedProfessions(orderBy(props.professions, ['specialty'], [currentSortOrder]));
				return;
			default:
				setSortedProfessions(
					orderBy(
						props.professions,
						[(profession: any) => profession[sortParameter] || ''],
						[currentSortOrder]
					)
				);
		}
	}, [sortingState, props.professions, projectId]);

	useEffect(() => {
		if (props.professions.length === 0) {
			setSortedProfessions([]);
			return;
		}

		if (isDefaultSort) {
			const sortedProfessions: IProfession[] = orderBy(props.professions, tradeGroupSortFunctions, [
				SORT_ORDER.ASCENDING,
				SORT_ORDER.ASCENDING,
			]);
			setSortedProfessions(sortedProfessions);
			setSortingState({
				isSorted: true,
				columnName: professionListTitles.active.title,
				sortOrder: SORT_ORDER.ASCENDING,
			});
			setIsDefaultSort(false);
		} else {
			sortColumnByOrder();
		}
	}, [props.professions, sortColumnByOrder, isDefaultSort]);

	useEffect(() => {
		sortColumnByOrder();
	}, [sortingState, sortColumnByOrder]);

	const rowProperties: IRowProperty[] = [
		{
			getTextToDisplay: (profession: IProfession) => {
				return getTradeGroupTranslation(profession);
			},
		},
		{
			getTextToDisplay: (profession: IProfession) => {
				return getTradeTranslation(profession);
			},
		},
		{ firstField: 'specialty' },
		{ firstField: 'contractor' },
	];

	const getDisableErrorMessage = (professionHasTagsAssigned: boolean): string => {
		if (professionHasTagsAssigned) {
			return translationService.get('professionHasTagsAndActivitiesAssignedInProject');
		}
		return translationService.get('professionHasTagsAssignedInProject');
	};

	const getCanProfessionBeDeleted = async (profession: IProfession): Promise<boolean> => {
		try {
			const professionHasTagsAssigned: boolean = await getDoesProfessionHasTagsAssigned(profession, projectId);
			const professionHasActivitiesAssigned: boolean = await getDoesProfessionHasActivitiesAssigned(
				profession,
				tz,
				projectId
			);
			if (professionHasTagsAssigned || professionHasActivitiesAssigned) {
				const errorMessage: string = getDisableErrorMessage(professionHasTagsAssigned);
				errorSnackbar(dispatch, errorMessage);
				return false;
			}
			return true;
		} catch (error) {
			errorSnackbar(dispatch, translationService.get('genericError'));
			return false;
		}
	};

	const getProfessionUpdateObject = async (profession: IProfession): Promise<undefined | IProfession> => {
		const isProfessionActive: boolean = !!profession?.activeProjects[projectId];

		const professionUpdateObject: IProfession = { ...profession };
		if (isProfessionActive) {
			const canProfessionBeDeleted: boolean = await getCanProfessionBeDeleted(professionUpdateObject);
			if (!canProfessionBeDeleted) {
				return;
			}

			const { [projectId]: deletedProject, ...remainingProjects } = professionUpdateObject.activeProjects;
			professionUpdateObject.activeProjects = remainingProjects;
			return professionUpdateObject;
		} else {
			return {
				...professionUpdateObject,
				activeProjects: {
					...professionUpdateObject.activeProjects,
					[projectId]: { backgroundColor: professionUpdateObject.backgroundColor },
				},
			};
		}
	};

	const updateActiveProfession = async (profession: IProfession) => {
		const professionUpdateObject: IProfession | undefined = await getProfessionUpdateObject(profession);
		if (!professionUpdateObject) {
			return;
		}
		dispatch(updateProfession(professionUpdateObject));
	};

	return (
		<React.Fragment>
			{showProfessionCreateDialog && <CreateProfessionDialog close={handleCloseLinkProfessionDialog} />}
			<TrusstorBox customStyleClass={classes.tableWrapper}>
				<Grid container direction="column" className={classes.tableContainer}>
					<Grid
						item
						container
						className={classes.searchAndButtonContainer}
						justify="space-between"
						alignItems="center"
					>
						<div>
							<SearchBar
								handleChangeInput={props.onSearchInputChange}
								placeholder={translationService.get('searchByTradeOrSpecialtyOrContractor')}
							/>
						</div>
						<TrusstorButton
							text={translationService.get('addProfession')}
							handleClick={handleOpenLinkProfessionDialog}
						/>
					</Grid>
					<Grid container className={classes.listContainer} alignItems="center">
						<div className={classes.headerContainer}>
							<div className={classes.header}>
								{Object.entries(professionListTitles).map(([title, value]) => {
									return (
										<TableTitle
											key={title}
											columnWidth={value.width || PROFESSIONS_TABLE_COLUMN_WIDTH}
											sortingState={sortingState}
											disableSort={value.disableSort}
											currentColumn={value.title}
											updateSortingState={updateSortingState}
											menuContent={value.menuContent}
										/>
									);
								})}
							</div>
						</div>

						<div className={classes.rowsContainer}>
							{sortedProfessions.length > 0
								? sortedProfessions.map((profession) => {
										return (
											<MainTableRow
												object={profession}
												firstRowComponent={
													<SimpleToggle
														onChange={() => {
															updateActiveProfession(profession);
														}}
														checked={!!profession.activeProjects[projectId]}
													/>
												}
												lastRowComponent={
													<div>
														<ProfessionDisplayWithTradeIcon
															profession={profession}
															projectId={projectId}
														/>
													</div>
												}
												type={TableRowTypes.PROFESSION}
												columnWidth={PROFESSIONS_TABLE_COLUMN_WIDTH}
												rowProperties={rowProperties}
												key={profession._id}
											/>
										);
									})
								: workingProfessionRequestStatus === HTTPRequestStatuses.success &&
									props.professions.length === 0 && (
										<p className={classes.placeholderText}>
											{props.isSearchInputExist
												? translationService.get('noMatchingValues')
												: translationService.get('noProfessionsDefinedMessage')}
										</p>
									)}
						</div>
					</Grid>
				</Grid>
			</TrusstorBox>
		</React.Fragment>
	);
};

export { ProfessionsListTable };
