import React from 'react';
import { FilterCard, IFilterCardProps } from '../FilterCard/FilterCard';
import { EquipmentTypes } from '../../../constants/equipment.const';
import { EquipmentIcon } from '../../Icons/EquipmentIcon/EquipmentIcon';

interface IEquipmentFilterCardProps extends IFilterCardProps {
	equipmentType: EquipmentTypes;
	equipmentTypeNick: string;
}

export const EquipmentFilterCard = (props: IEquipmentFilterCardProps) => {
	return (
		<FilterCard
			{...props}
			startComponent={<EquipmentIcon type={props.equipmentType} />}
			text={props.equipmentTypeNick}
		/>
	);
};
