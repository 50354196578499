import React, { useEffect } from 'react';
import classes from './styles.module.scss';
import { Loader } from '../Loader/Loader';

interface IPhotoDisplayProps {
	fetchImageCallback: () => Promise<string>;
}

export const PhotoDisplay = (props: IPhotoDisplayProps) => {
	const [image, setImage] = React.useState<string | null>(null);
	const [isLoading, setIsLoading] = React.useState<boolean>(false);

	const handlePhotoClick = async () => {
		if (!image) return;
		const imageWithNoPrefix: string = image.split(',')[1];
		const w: Window | null = window.open('about:blank');

		if (!w) return;

		// FireFox seems to require a setTimeout for this to work.
		setTimeout(() => {
			w.document.body.appendChild(w.document.createElement('iframe')).src =
				'data:image/png;base64,' + imageWithNoPrefix;
			w.document.body.style.margin = '';
			w.document.getElementsByTagName('iframe')[0].style.width = '100%';
			w.document.getElementsByTagName('iframe')[0].style.height = '100%';
			w.document.getElementsByTagName('iframe')[0].style.border = '';
		}, 0);
	};

	const loadImage = async () => {
		setIsLoading(true);
		const imageSource: string = await props.fetchImageCallback();
		setImage(imageSource);
		setIsLoading(false);
	};

	useEffect(() => {
		loadImage();
	}, [props.fetchImageCallback]);

	if (!image && isLoading) {
		return <Loader />;
	}
	return (
		<div className={classes.photoContainer}>
			{image && <img className={classes.photo} src={image} alt="random" onClick={handlePhotoClick} />}
		</div>
	);
};
