import { authService, projectService, requestService, userService } from '../../servicesInitializers';
import { fetchAndSetWorkingProject } from './project.thunks';
import { LoginRequestStatuses } from '@shared/interfaces/ILoginReducerState';
import { setLoggedUser, setLoggedUserPreferences, setLoginStateData } from '../slices/login.slice';
import { updateUserProjects } from '../slices/project.slice';
import { RESET_ALL_REDUCERS_ACTION_TYPE } from '../store';
import { ILoggedUser } from '@shared/interfaces/IUser';
import { getIsExecutiveUser } from '@utils/users.utils';
import { initServicesDependentByUserData } from '@utils/bootstrap.utils';
import { IUserPreferences } from '@shared/interfaces/IUserPreferences';
import { Languages } from '@shared/constants/languages';

export const login =
	({ username, password, from, history }) =>
	async (dispatch) => {
		dispatch(
			setLoginStateData({
				loginRequestStatus: {
					state: LoginRequestStatuses.LOADING,
				},
			})
		);
		try {
			const loggedUser: ILoggedUser = await authService.login(username, password);
			dispatch(setLoggedUserThunk(loggedUser));
			dispatch(
				setLoginStateData({
					loginRequestStatus: {
						state: LoginRequestStatuses.SUCCESS,
					},
				})
			);

			redirectToPath(loggedUser, from, history);
		} catch (e: any) {
			dispatch(
				setLoginStateData({
					loginRequestStatus: {
						state: LoginRequestStatuses.ERROR,
						statusCode: e.request.status,
						responseMessage: e.response?.data?.message || e.message,
					},
				})
			);
		}
	};

export const setLoggedUserThunk = (loggedUser: ILoggedUser) => async (dispatch) => {
	userService.setUserDetails(loggedUser.userDetails);
	userService.setLSUserData({
		accessToken: loggedUser.accessToken,
		refreshToken: loggedUser.refreshToken,
	});
	dispatch(setLoggedUser({ user: loggedUser }));
	dispatch(updateUserProjects({ userProjects: loggedUser.userProjects }));
	dispatch(
		fetchAndSetWorkingProject(projectService.getChosenProject()?.projectId || loggedUser.userProjects[0].projectId)
	);
	initServicesDependentByUserData(
		loggedUser.userDetails,
		projectService.getChosenProject()?.projectId || loggedUser.userProjects[0].projectId
	);
};

const redirectToPath = (loggedUser: ILoggedUser, from, history) => {
	const isExecutiveUser: boolean = getIsExecutiveUser(loggedUser);
	if (isExecutiveUser) {
		history.push('/projects');
		return;
	}
	if (from === '/projects') {
		return;
	}
	history.push(from ? from : '/');
};

export const logout = () => (dispatch) => {
	userService.removeLoggedUser();
	projectService.removeChosenProject();
	dispatch({ type: RESET_ALL_REDUCERS_ACTION_TYPE });
};

export const updateUserLanguage = (username: string, language: Languages) => async (dispatch) => {
	const userPreferences: Partial<IUserPreferences> = {
		language,
	};
	await requestService.put(`/entities/userPreferences/${username}`, {
		body: userPreferences,
	});
	window.location.reload();
};
