import { useHistory, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { IIssueShared, IssuePriority, IssueStatus } from '../../interfaces/IIssueShared';
import { IProfession } from '../../interfaces/IProfession';
import classes from './styles.module.scss';
import fullStartIcon from '../../assets/icons/fullStar.svg';
import emptyStarIcon from '../../assets/icons/emptyStar.svg';
import { TranslationService } from '../../services/translation.service';
import edit from '../../assets/icons/edit.svg';
import { IssueDesktopDialog } from './IssueDesktopDialog/IssueDesktopDialog';
import { StorageService } from '../../services/storage.service';
import { LocationItem } from './IssueLocation/IssueLocation';
import { CriticalSection } from './CriticalSection/CriticalSection';
import { IssueMobileDisplay } from './IssueMobileDisplay/IssueMobileDisplay';
import { RequestService } from '../../services/request.service';
import { IUser } from '../../interfaces/IUser';
import { getIssueDescription } from '../../utils/issues.utils';
import { IssueStatusChangeButton } from './IssueStatusChangeButton/IssueStatusChangeButton';
import { IIssue } from '../../interfaces/IIssue';
import { saveIssueComment, saveIssueImage } from './issues.utils';
import { IconColor, IconSize, TrusstorIconShared } from '../TrusstorIconShared/TrusstorIconShared';
import { IconNames } from '../TrusstorIconShared/IconNames.enum';
import { IssueDateDisplay } from './IssueDateDisplay/IssueDateDisplay';
import { IssueStatusChip } from './IssueStatusChip/IssueStatusChip';
import { IssueReviewDialog } from './IssueReviewDialog/IssueReviewDialog';
import { useLinkedIssueActivityDescription } from '../../hooks/queries/issue.queries.hook';
import { ProfessionDisplayWithTradeIcon } from '../ProfessionDisplayWithTradeIcon/ProfessionDisplayWithTradeIcon';
import { IssuePrivateSection } from './IssuePrivateSection/IssuePrivateSection';

interface IIssueItemProps {
	updateIssue: (
		issueId: string,
		issue: Partial<IIssueShared<IProfession>>,
		updateBackend?: boolean
	) => Promise<IIssueShared<IProfession>>;
	deleteIssue: (issueId: string) => Promise<void>;
	translationService: TranslationService;
	issue: IIssueShared<IProfession>;
	tz: string;
	projectId: string;
	issueStatusChangeBiEvent?: (status: IssueStatus, issueUpdated: IIssue) => void;
	isMobile?: boolean;
	storageService: StorageService;
	requestService: RequestService;
	user: IUser;
	imageSizeLimitCallback: () => void;
	issueComment_BI: (issueDescription: string, comment: string, userName: string, type: string) => void;
	setIssueToEdit?: React.Dispatch<React.SetStateAction<IIssue | undefined>>;
	isEditIssueDialogShown?: boolean;
	hideProfession?: boolean;
	hideAssignee?: boolean;
	usePathForDialog?: boolean;
}

const IssueItem = (props: IIssueItemProps) => {
	const { issueId: issueIdFromParams }: { issueId: string } = useParams();
	const [starIcon, setStarIcon] = useState<string>(emptyStarIcon);
	const activityDescription: string | undefined = useLinkedIssueActivityDescription(props.issue);
	const [issueStatus, setIssueStatus] = useState<IssueStatus>(IssueStatus.PLANNED);
	const [itemHovered, setItemHovered] = useState<boolean>(false);
	const [openReviewDialog, setOpenReviewDialog] = useState<boolean>(false);
	const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

	const history = useHistory();

	useEffect(() => {
		setStarIcon(props.issue.priority === IssuePriority.CRITICAL ? fullStartIcon : emptyStarIcon);
	}, [props.issue.priority]);

	useEffect(() => {
		setIssueStatus(props.issue.status);
	}, [props.issue.status]);

	const toggleStarIcon = () => {
		setStarIcon((prev) => (prev === fullStartIcon ? emptyStarIcon : fullStartIcon));
	};

	const handleCardClick = async (e) => {
		e.stopPropagation();
		if (props.usePathForDialog) {
			history.push({ pathname: `/issues/${props.issue._id}` });
		} else {
			setIsDialogOpen(true);
		}
		setItemHovered(false);
		return;
	};

	const handlePriority = async (e?: React.MouseEvent): Promise<void> => {
		try {
			e?.stopPropagation();
			toggleStarIcon();
			await props.updateIssue(props.issue._id, {
				priority:
					props.issue.priority === IssuePriority.CRITICAL ? IssuePriority.REGULAR : IssuePriority.CRITICAL,
			});
		} catch (e) {
			toggleStarIcon();
			console.log(e);
		}
	};

	const handleStatusChange = async (updatedStatus: IssueStatus) => {
		try {
			const updatedIssue: IIssue = await props.updateIssue(props.issue._id, {
				status: updatedStatus,
			});
			props.issueStatusChangeBiEvent && props.issueStatusChangeBiEvent(updatedStatus, updatedIssue);
		} catch (e) {
			console.log(e);
		}
	};

	const handleCloseDesktopDialog = (e: React.MouseEvent) => {
		e.stopPropagation();
		removeIssueIdFromRoute();
	};

	const closeMobileEdit = (e: React.MouseEvent) => {
		e.stopPropagation();
		removeIssueIdFromRoute();
	};

	const removeIssueIdFromRoute = () => {
		if (props.usePathForDialog) {
			history.goBack();
			return;
		}
		setIsDialogOpen(false);
	};

	const handleIssueDelete = (e) => {
		e.stopPropagation();
		props.deleteIssue(props.issue._id);
		removeIssueIdFromRoute();
	};

	const shouldShowInfoSection = (): boolean => {
		const shouldShowAssignee: boolean = !!(!props.hideAssignee && !!props.issue.assignee);
		const shouldShowProfession: boolean = !!(!props.hideProfession && !!props.issue.profession);
		return !!(
			props.issue.locations?.length ||
			shouldShowAssignee ||
			activityDescription ||
			shouldShowProfession ||
			props.issue.comments?.length
		);
	};

	const onLocationMapClick = () => {
		setItemHovered(false);
	};

	const saveImage = async (image: File) => {
		const issueUpdated: IIssueShared<IProfession> | undefined = await saveIssueImage(
			props.issue._id,
			props.user,
			props.requestService,
			image
		);
		if (!issueUpdated) {
			props.imageSizeLimitCallback();
			return;
		}
		await props.updateIssue(props.issue._id, issueUpdated, false);
		props.issueComment_BI(props.issue.description, '', props.user.name, 'attachment');
	};

	const saveComment = async (comment: string) => {
		const issueUpdated: IIssueShared<IProfession> = await saveIssueComment(
			props.issue._id,
			props.user,
			props.requestService,
			comment
		);
		await props.updateIssue(props.issue._id, issueUpdated, false);
		props.issueComment_BI(props.issue.description, comment, props.user.name, 'text');
	};

	const openEditDialog = (e) => {
		e.stopPropagation();
		props.setIssueToEdit?.(props.issue);
	};

	const handleReviewCallback = () => {
		setOpenReviewDialog(true);
	};

	const isRtl: boolean = props.translationService.getIsRtl();
	const isSameIssueInRoute: boolean = issueIdFromParams === props.issue._id;
	const isEditMode: boolean = location.pathname.includes('edit');
	const isDesktopOpenDialog: boolean =
		!props.isMobile && (isSameIssueInRoute || isDialogOpen) && !isEditMode && !props.isEditIssueDialogShown;
	const isMobileOpenDialog: boolean = !!(props.isMobile && (isSameIssueInRoute || isDialogOpen));
	const isLimitedUser: boolean = props.user.permissions.roleType === 'VIEWER';

	const handleRejectClick = async (comment: string) => {
		setOpenReviewDialog(false);
		await saveComment(comment);
		await props.updateIssue(props.issue._id, {
			status: IssueStatus.REJECTED,
		});
	};

	const handleApproveClick = async () => {
		setOpenReviewDialog(false);
		await props.updateIssue(props.issue._id, {
			status: IssueStatus.COMPLETED,
		});
	};

	return (
		<>
			<div
				className={classnames({
					[classes.issueContainer]: true,
					[classes.itemHovered]: itemHovered,
				})}
				onMouseEnter={() => !props.isMobile && setItemHovered(true)}
				onMouseLeave={() => !props.isMobile && setItemHovered(false)}
				onClick={handleCardClick}
			>
				<div
					className={classnames({
						[classes.editIconContainer]: true,
						[classes.editIconRtl]: isRtl,
						[classes.hideElement]: !itemHovered,
					})}
					onClick={openEditDialog}
				>
					{!isLimitedUser && (
						<img className={classnames({ [classes.hoverEditIcon]: true })} src={edit} alt={'edit'} />
					)}
				</div>
				<section className={classes.header}>
					<CriticalSection
						isLimitedUser={isLimitedUser}
						issuePriority={props.issue.priority}
						translationService={props.translationService}
						onClick={handlePriority}
						isMobile={props.isMobile}
					/>
					<IssueDateDisplay issue={props.issue} tz={props.tz} />
				</section>
				<section className={classes.body}>
					<div className={classes.description}>
						{getIssueDescription(props.issue, props.translationService)}
					</div>
				</section>
				{shouldShowInfoSection() && (
					<section className={classes.infoSection}>
						{!!props.issue.locations?.length && (
							<LocationItem
								locations={props.issue.locations}
								projectId={props.projectId}
								onMapClick={() => {
									onLocationMapClick();
								}}
							/>
						)}
						{props.issue.profession && !props.hideProfession && (
							<div className={classes.professionSection}>
								<TrusstorIconShared
									size={IconSize.SMALL}
									iconName={IconNames.helmet}
									color={IconColor.Grey600}
								/>
								<div>
									<ProfessionDisplayWithTradeIcon
										profession={props.issue.profession}
										projectId={props.projectId}
									/>
								</div>
							</div>
						)}
						{props.issue.assignee && !props.hideAssignee && (
							<div className={classes.assigneeSection}>
								<TrusstorIconShared
									iconName={IconNames.user}
									size={IconSize.SMALL}
									color={IconColor.Grey600}
								/>
								<div className={classes.assigneeText}>{props.issue.assignee.name}</div>
							</div>
						)}
						{activityDescription && (
							<div className={classes.activitySection}>
								<TrusstorIconShared
									iconName={IconNames.activity}
									size={IconSize.SMALL}
									color={IconColor.Grey600}
								/>
								<div className={classes.activityText}>{activityDescription}</div>
							</div>
						)}
						{!!props.issue.comments?.length && (
							<div className={classes.comments}>
								<TrusstorIconShared
									iconName={IconNames.message}
									size={IconSize.SMALL}
									color={IconColor.Grey600}
								/>

								<div className={classes.commentsLength}>
									{props.issue.comments?.length}{' '}
									{props.translationService.get(
										props.issue.comments?.length === 1 ? 'comment' : 'comments'
									)}
								</div>
							</div>
						)}
					</section>
				)}
				<section className={classes.footer}>
					<IssueStatusChip status={issueStatus} />
					{props.issue.isPrivate && <IssuePrivateSection textMode={'default'} />}
					<IssueStatusChangeButton
						handleReviewCallback={handleReviewCallback}
						isLimitedUser={isLimitedUser}
						handleStatusChange={handleStatusChange}
						issueStatus={issueStatus}
						translationService={props.translationService}
						itemHovered={itemHovered}
					/>
				</section>
			</div>
			{openReviewDialog && (
				<IssueReviewDialog
					isMobile={props.isMobile}
					issue={props.issue}
					handleRejectClick={handleRejectClick}
					handleApproveClick={handleApproveClick}
					onClose={() => setOpenReviewDialog(false)}
				/>
			)}
			{isDesktopOpenDialog && (
				<IssueDesktopDialog
					handleReviewCallback={handleReviewCallback}
					isLimitedUser={isLimitedUser}
					openEditFunction={openEditDialog}
					requestService={props.requestService}
					deleteIssue={handleIssueDelete}
					show={isDesktopOpenDialog}
					close={handleCloseDesktopDialog}
					issue={props.issue}
					translationService={props.translationService}
					tz={props.tz}
					projectId={props.projectId}
					priorityIcon={starIcon}
					handlePriority={handlePriority}
					storageService={props.storageService}
					updateIssue={props.updateIssue}
					saveComment={saveComment}
					saveImage={saveImage}
					activityDescription={activityDescription}
					handleStatusChange={handleStatusChange}
				/>
			)}
			{isMobileOpenDialog && (
				<div className={classes.issueMobileDisplayContainer}>
					<IssueMobileDisplay
						handleReviewCallback={handleReviewCallback}
						isLimitedUser={isLimitedUser}
						requestService={props.requestService}
						deleteIssue={handleIssueDelete}
						issue={props.issue}
						tz={props.tz}
						translationService={props.translationService}
						storageService={props.storageService}
						priorityIcon={starIcon}
						handlePriority={handlePriority}
						updateIssue={props.updateIssue}
						onClose={closeMobileEdit}
						saveComment={saveComment}
						saveImage={saveImage}
						activityDescription={activityDescription}
						handleStatusChange={handleStatusChange}
						openEditDrawer={openEditDialog}
						projectId={props.projectId}
					/>
				</div>
			)}
		</>
	);
};

export { IssueItem };
