import { DATE_PICKER_TOGGLE } from '@shared/interfaces/DatePickerMode';

export { DATE_PICKER_TOGGLE };
export const MAIN_PATH = '/main';

export const MAIN_PAGE_ROUTES = [
	`/:projectId?${MAIN_PATH}`,
	`/:projectId?${MAIN_PATH}/floors/:floorId`,
	`/:projectId?${MAIN_PATH}/:issueId?/:edit(edit)?`,
];

export const US = 'US';

export const LOGIN_TIMEOUT = 10000;

export const UPDATE_WEATHER_FREQUENCY_MS = 60000 * 30;

export const ACTIVE_HOIST_TIME = 60000;

export const ADMIN_PANEL_LAST_SEEN_DAYS = {
	OK_MAX_DAYS: 5,
	WARNING_MAX_DAYS: 10,
};

export enum CalendarToggleOptions {
	PROFESSIONS = 'profession',
	FLOORS = 'floor',
}

export enum WorkplanViewTypes {
	CALENDAR = 'calendar',
	PROGRESS_TRACKER = 'progress_tracker',
}

export enum WorkplanWidgetViewTypes {
	PROFESSIONS = 'professions',
	FLOORS = 'floors',
	STATUSES = 'statuses',
}

export enum WorkplanInputNames {
	profession = 'profession',
	description = 'description',
	floors = 'floors',
	areas = 'areas',
	assignee = 'assignee',
	label = 'label',
	startDate = 'startDate',
	endDate = 'endDate',
	dangerousActivity = 'dangerousActivity',
	pushEndDate = 'pushEndDate',
}

export enum ActivityElementTypes {
	CHIP = 'CHIP',
	PATCH = 'PATCH',
}

export enum TableRowTypes {
	PROFESSION = 'profession',
	MANAGER = 'manager',
	USER = 'user',
	ACTIVITIES_PROGRESS = 'activities_progress',
	MILESTONES = 'milestones',
}

export const tagTypes = {
	human: 'human',
};

export const assignmentKinds = {
	worker: 'Worker',
	manager: 'manager',
	guest: 'guest',
	hoist: 'hoist',
	scissorsLift: 'scissorsLift',
};

export const REFRESH_TIMEOUT_MS = 1000 * 60 * 30;
export const CLICK_CHECK_TIMEOUT_MS = 1000 * 60 * 5;

export const EOD_WEEKDAY_TIME = '16:00';
export const EOD_FRIDAY_TIME = '12:00';

export const SUNDAY = 0;
export const THURSDAY = 4;
export const FRIDAY = 5;
export const SATURDAY = 6;
export const WORKERS_SNACKBAR_ID = 'activeWorkersSnackbar';

export const REPORTS_SIDEBAR_CARD_MAX_WIDTH = '80%';
export const SYSTEM_MANAGEMENT_SIDEBAR_CARD_MAX_WIDTH = '80%';
export const DASHBOARD_SIDEBAR_CARD_MAX_WIDTH = '80%';

export const entitiesProperties = {
	_id: '_id',
	sortIndex: 'sortIndex',
	tradeId: 'tradeId',
	tradeGroup: 'tradeGroup',
	name: 'name',
	floorId: 'floorId',
	type: 'type',
	tagId: 'tagId',
	activeTagsIds: 'activeTagsIds',
	value: 'value',
	areaId: 'areaId',
};

export const safetyStatusChannels = {
	dashboardClose: 'dashboardClose',
};

export const safetyEventProperties = {
	timestampCreated: 'timestampCreated',
};

export const MIN_LARGE_SCREEN_PIXELS = 1600;

export const environments = {
	production: 'prod',
	dev: 'dev',
	development: 'development',
	integration: 'integration',
	qa: 'qa',
	demo: 'demo',
	demoIntegration: 'demo-integration',
	demoLocal: 'demo-local',
	compose: 'compose',
};

export const scaffold = 'scaffold';

export const SCAFFOLD_SIDES = {
	north: 'north',
	south: 'south',
	east: 'east',
	west: 'west',
};

export const SCAFFOLD_AREAS = [
	{
		svgId: 'northscaffold',
		side: SCAFFOLD_SIDES.north,
	},
	{
		svgId: 'southscaffold',
		side: SCAFFOLD_SIDES.south,
	},
	{
		svgId: 'eastscaffold',
		side: SCAFFOLD_SIDES.east,
	},
	{
		svgId: 'westscaffold',
		side: SCAFFOLD_SIDES.west,
	},
];

export const PAGE_CONTAINER_MARGIN = 16;
export const PAGE_TITLE_PADDING = 23;
export const PAGE_TITLE_MARGIN_BOTTOM = 8;

export const PROFESSIONS_CHIP_MIN_WIDTH = 20;
export const PROFESSIONS_CHIP_MAX_WIDTH = 200;
export const FLOOR_CHIP_HEIGHT = 20;
export const MANAGER_CHIP_MIN_WIDTH = 22;
export const HEAVY_MACHINE_CHIP_HEIGHT = 20;
export const SAFETY_ICON_HEIGHT = 16;
export const SAFETY_ICON_WIDTH = 16;
export const ELEVATOR_ICON_HEIGHT = 25;
export const ELEVATOR_ICON_WIDTH = 25;
export const SCAFFOLD_ICON_HEIGHT = 20;
export const SCAFFOLD_ICON_WIDTH = 20;
export const EQUIPMENT_ICON_WIDTH = 24;
export const FLOOR_CHIPS_MARGIN = 8;
export const ACTIVITY_CHIP_HEIGHT = 50;
export const FLOOR_ACTIVITY_CHIP_HEIGHT = 20;
export const FLOOR_ACTIVITY_CHIP_HEIGHT_SMALL = 16;
export const FLOOR_CHIP_NUMBER_NORMAL_SIZE = 5;

export const MANAGERS_SUBTRACT_WIDTH_PA = 210;
export const ELEVATOR_SUBTRACT_SIDE = 17;
export const SCAFFOLD_SUBTRACT_SIDE = 5;
export const SAFETY_SUBTRACT_SIDE = 5;
export const PA_SUBTRACT_TOP = 2;
export const PA_OFFSET_SIDE = 4;

export const TOP_BAR_HEIGHT = 60;
export const SIDE_BAR_WIDTH = 75;

export const MAIN_VIEW_WIDTH_FLOOR_CLOSED = '60%';
export const MAIN_VIEW_WIDTH_FLOOR_OPEN = '50%';
export const FLOOR_VIEW_WIDTH = '34%';
export const LOGS_VIEW_WIDTH = '34%';
export const DASHBOARD_WIDTH_LARGE = 400;
export const DASHBOARD_WIDTH_SMALL = 260;
export const HEAVY_MACHINES_DASHBOARD_WIDTH_LARGE = 280;
export const HEAVY_MACHINES_DASHBOARD_WIDTH_SMALL = 200;

export const TOTAL_WORKERS_CELL_HEIGHT = 120;
export const PROFESSION_COLUMN_HEADER_WIDTH = 109;
export const TOP_FLOORS_CELL_HEIGHT = 120;
export const TOTAL_WORKERS_CELL_HEIGHT_SMALL_SCREEN = 85;
export const TOP_FLOORS_CELL_HEIGHT_SMALL_SCREEN = 85;
export const CHART_OFFSET_X = -5;

//Productivity Table Report
export const PRODUCTIVITY_WORKERS_QUANTITY_CELL_HEIGHT = 170;
export const PRODUCTIVITY_WORK_HOURS_CELL_HEIGHT_DAILY = 50;
export const PRODUCTIVITY_WORK_HOURS_CELL_HEIGHT_PERIOD = 150;
export const PRODUCTIVITY_LOCATION_CELL_HEIGHT = 180;
export const PRODUCTIVITY_WORKERS_QUANTITY_CELL_HEIGHT_SMALL = 150;
export const PRODUCTIVITY_WORK_HOURS_CELL_HEIGHT_SMALL = 150;
export const PRODUCTIVITY_COLUMN_WIDTH = 120;
export const PRODUCTIVITY_HEADER_HEIGHT = 75;
export const PRODUCTIVITY_HEADER_MAX_WIDTH = 100;
export const PRODUCTIVITY_HEADER_PADDING = 8;
export const PRODUCTIVITY_TOTAL_COLUMN_COLOR: string = '#2B679E';
export const NUMBER_OF_TOP_FLOOR_TO_SHOW = 3;

//Activities Table Report
export const ACTIVITIES_PROFESSION_COLUMN_WIDTH = 235;
export const ACTIVITIES_COUNT_COLUMN_WIDTH = 174;
export const ACTIVITIES_COUNT_COLUMN_HEADER_WIDTH = ACTIVITIES_COUNT_COLUMN_WIDTH + 2;
export const ACTIVITIES_FLOOR_COLUMN_WIDTH = 180;
export const ACTIVITIES_ACTIVITY_COLUMN_WIDTH = 580;
export const ACTIVITIES_WORKERS_COLUMN_WIDTH = 200;
export const ACTIVITIES_TIME_COLUMN_WIDTH = 200;
export const ACTIVITIES_MANAGER_COLUMN_WIDTH = 225;

//Admin Panel Table
export const ADMIN_PANEL_CELL_WIDTHS = {
	tagId: 2,
	linkedTo: 4,
	fullName: 3,
	IDNumber: 2,
	phone: 2,
	certificates: 1,
	status: 1,
	lastSeen: 1,
	battery: 1,
	buttons: 1,
};

export const ADMIN_PANEL_TITLE_CENTERED = {
	tagId: false,
	linkedTo: false,
	fullName: false,
	IDNumber: false,
	phone: false,
	certificates: true,
	status: true,
	lastSeen: true,
	battery: true,
	buttons: true,
};

export const SIDE_MAIN_COMPONENT_CLASS_NAME = 'sideMainComponent';

export const SIDE_MAIN_COMPONENT_WIDTH = '300px';

// Managers Roles List Table
export const MANAGERS_TABLE_COLUMN_WIDTH = '280px';
export const MANAGERS_TABLE_COLUMN_WIDTH_RESPONSIVE = '200px';

// Professions List Page
export const PROFESSIONS_TABLE_COLUMN_WIDTH = '210px';

// Users List Page
export const USERS_TABLE_COLUMN_WIDTH = '210px';

// Activities Productivity Table
export const ACTIVITIES_REPORT_ACTIVITIES_QUANTITY_CELL_HEIGHT = 200;
export const ACTIVITIES_REPORT_WORKERS_QUANTITY_CELL_HEIGHT = 70;

// Profession analysis Activity Distribution Table
export const DISTRIBUTION_ACTIVITY_WORK_HOURS_CELL_HEIGHT = 40;
export const DISTRIBUTION_ACTIVITY_ACTIVITY_PERCENTAGE_CELL_HEIGHT = 100;
export const DISTRIBUTION_ACTIVITY_COLUMN_WIDTH = 100;
export const DISTRIBUTION_ACTIVITY_HEADER_HEIGHT = 25;

// Profession analysis Activity Distribution Table
export const ACTIVITIES_GANTT_HEADERS_ROW_HEIGHT = 40;
export const ACTIVITIES_GANTT_COL_HEADERS_WIDTH = 80;
export const ACTIVITIES_GANTT_ROW_HEIGHT = 70;
export const ACTIVITIES_GANTT_COL_WIDTH = 60;
export const ACTIVITIES_GANTT_DASHED_BORDER_WIDTH = 1;

export const GRAY_COLOR = '#d0d0d0';

export const DIALOG_TEXT_GRAY_COLOR = '#919191';

export const entitiesListParents = {
	mainViewDashboard: 'mainview_dashboard',
	reportsDaily: 'reports_daily',
};

export const SIDE_BAR_BUTTONS = {
	milestones: 'sideBar_milestones',
	mainView: 'mainView',
	settings: 'settings',
	logout: 'logout',
	heavyMachinery: 'heavyMachineryButton',
	systemManagement: 'systemManagement',
	tagsPage: 'tagsPage',
	professionsList: 'professionsList',
	rolesList: 'rolesList',
	usersPage: 'usersPage',
	notifications: 'alertsHub',
};

export const filterDisplayProperties = {
	floorNick: 'floorNick',
	tradeId: 'tradeId',
	tradeGroup: 'tradeGroup',
};

export const reportProps = {
	floorId: 'floorId',
	floors: 'floors',
	tagIds: 'tagIds',
	totalHours: 'totalHours',
};

export const reportsPageConsts = {
	DEFAULT_MAX_PROFESSIONS: 10,
	NUM_OF_TOP_FLOORS: 4,
	NUM_OF_TOP_PROFESSIONS_SAFETY: 5,
	NUM_OF_TOP_FLOORS_SAFETY: 5,
	NUM_OF_TOP_PROFESSIONS_ISSUES: 3,
	NUM_OF_TOP_BARS_SAFETY: 5,
	otherProfessionsSafetyColor: '#313131',
	floorSafetyChartBaseColor: '#647794',
	DESCENDING: 'desc',
	specialProfessions: {
		guest: 'guest',
		Guest: 'Guest',
	},
	properties: {
		tradeId: 'tradeId',
		number: 'number',
		floorId: 'floorId',
		floorNick: 'floorNick',
		profession: 'profession',
		professionNick: 'professionNick',
		type: 'type',
	},
	covidViolation: 'covid',
};

export const equipmentProperties = {
	type: 'type',
	name: 'name',
};

export const heavyMachineryTypes = {
	tractor: 'Tractor',
	truck: 'Truck',
	steamRoller: 'Steam Roller',
};

export const PROFESSION_REPORT_PAGE = {
	MAX_GRAPH_TICKS: 9,
};

export const WEEKLY_WORK_PLAN_FILE_NAME = 'workplan';
export const WEEKLY_ACTIVITIES_REPORT_EXCEL_FILE_NAME = 'Weekly_Activities_Summaries';

export enum APP_FILTER_TYPES {
	professionFilter = 'professionFilter',
	floorFilter = 'floorFilter',
	areaFilter = 'areaFilter',
	managerFilter = 'managerFilter',
	equipmentFilter = 'equipmentFilter',
	allocationFilter = 'allocationFilter',
	lastSeenFilter = 'lastSeenFilter',
	activityLabels = 'activityLabels',
	status = 'status',
	battery = 'battery',
	issuesPriorities = 'issuesPriorities',
	issueStatuses = 'issueStatuses',
	users = 'users',
	sequences = 'sequences',
	utilities = 'utilities',
}

export enum BATTERY_MINIMUM_LEVELS {
	high = 60,
	medium = 30,
	low = 0,
}
