import React from 'react';
import classes from './styles.module.scss';
import { FilterCard, IFilterCardProps } from '../FilterCard/FilterCard';
import { IconNames } from '../../TrusstorIconShared/IconNames.enum';
import { TrusstorIconShared } from '../../TrusstorIconShared/TrusstorIconShared';
import { ProfessionDisplayWithTradeIcon } from '../../ProfessionDisplayWithTradeIcon/ProfessionDisplayWithTradeIcon';
import { IProfession } from '../../../interfaces/IProfession';

interface IScissorsLiftFilterCardProps extends IFilterCardProps {
	projectId: string;
	profession: IProfession;
}

export const ScissorsLiftFilterCard = (props: IScissorsLiftFilterCardProps) => {
	return (
		<FilterCard
			{...props}
			startComponent={
				<div className={classes.card}>
					<TrusstorIconShared iconName={IconNames.scissorsLift} />
					<ProfessionDisplayWithTradeIcon profession={props.profession} projectId={props.projectId} />
				</div>
			}
		/>
	);
};
