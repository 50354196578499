import React, { useEffect, useRef, useState } from 'react';
import classes from './styles.module.scss';
import { translationService } from '../../index';
import { Switch } from '@material-ui/core';
import { NotificationChannelTypes } from '@shared/interfaces/NotificationChannelTypes.enum';
import { IBaseNotificationSettings, IUserNotificationSettings } from '@shared/interfaces/INotificationSettings';
import { INotificationCard } from '@shared/interfaces/NotificationSettings/INotificationCard';
import { NotificationEditRecipientsDialog } from '@src/Components/NotificationSettingCard/NotificationEditRecipientsDialog';
import { useSelector } from 'react-redux';
import { selectLoggedUser } from '@store/slices/login.slice';

interface INotificationChannelSwitchProps {
	channelName: NotificationChannelTypes;
	handleSwitchChange: (checked: boolean, channelName: NotificationChannelTypes) => Promise<void>;
	card: INotificationCard;
	isBase?: boolean;
	userSettings?: IUserNotificationSettings;
	baseSettings?: IBaseNotificationSettings;
}

const NotificationChannelSwitch = (props: INotificationChannelSwitchProps) => {
	const [isChecked, setIsChecked] = useState<boolean>(false);
	const [displayConfirmationDialog, setDisplayConfirmationDialog] = useState<boolean>(false);
	const loggedUser = useSelector(selectLoggedUser)!;
	const baseSelected = useRef<boolean>();
	useEffect(() => {
		const checked: boolean | undefined = props.isBase
			? props.baseSettings?.settings && props.baseSettings?.settings[props.card.type]?.channels[props.channelName]
			: props.userSettings?.settings &&
			  props.userSettings?.settings[props.card.type]?.channels[props.channelName];

		setIsChecked(!!checked);
	}, [props.baseSettings, props.userSettings]);

	const handleSwitch = async (checked: boolean) => {
		if (props.isBase && getNumberOfRecipients() > 0) {
			baseSelected.current = checked;
			setDisplayConfirmationDialog(true);
			return;
		}
		await props.handleSwitchChange(checked, props.channelName);
	};

	const handleConfirmationDialog = async () => {
		await props.handleSwitchChange(baseSelected.current!, props.channelName);
		setDisplayConfirmationDialog(false);
	};

	const getNumberOfRecipients = (): number => {
		const allRecipients: string[] = props.baseSettings?.settings[props.card.type]?.recipients || [];
		const isSelfOption: boolean = allRecipients.some((option: any) => option === loggedUser!.userDetails.username);
		return isSelfOption ? allRecipients.length - 1 : allRecipients.length;
	};

	return (
		<>
			{displayConfirmationDialog && (
				<NotificationEditRecipientsDialog
					handleClose={() => setDisplayConfirmationDialog(false)}
					handleConfirmationDialog={handleConfirmationDialog}
					numberOfRecipients={getNumberOfRecipients()}
				/>
			)}
			<div className={classes.switchContainer}>
				<div className={classes.switchLabel}>{translationService.get(props.channelName)}</div>
				<Switch color="primary" onChange={(event, checked) => handleSwitch(checked)} checked={isChecked} />
			</div>
		</>
	);
};

export { NotificationChannelSwitch };
