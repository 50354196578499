import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { streamService } from '..';
import { IMainPageWidgetActivityData } from '@interfaces/IMainPageWidgetActivityData';
import { debounce, isEqual } from 'lodash';
import { useSelector } from 'react-redux';
import { selectProjectId } from '@store/slices/project.slice';
import { WidgetActivityFilters } from '@shared/interfaces/WidgetActivityFilters';

export const useMainPageWidgetActivities = () => {
	const [widgetActivityFilters, setWidgetActivityFilters] = React.useState<WidgetActivityFilters>(
		WidgetActivityFilters.toDo
	);
	const projectId: string = useSelector(selectProjectId)!;
	const [widgetActivities, setWidgetActivities] = useState<IMainPageWidgetActivityData[]>([]);
	const streamResponseRef = useRef<IMainPageWidgetActivityData[] | null>(null);
	const areStreamUpdatesEnabled = useRef<boolean>(true);

	const fetchWidgetActivities = async (): Promise<void> => {
		streamService.openStream(
			`/activities/groups/mainPageWidgetActivities/stream?projectId=${projectId}`,
			(data) => {
				streamResponseRef.current = JSON.parse(data.data) as IMainPageWidgetActivityData[];
				if (!areStreamUpdatesEnabled.current) {
					return;
				}

				const widgetActivities: IMainPageWidgetActivityData[] = JSON.parse(data.data);
				setWidgetActivities(widgetActivities);
			}
		);
	};

	const closeWidgetActivitisStream = (): void => {
		streamService.closeStreamIncludes(`/activities/groups/mainPageWidgetActivities/stream`);
	};

	const debounceEnabledStreamUpdates = useMemo(() => {
		return debounce(() => {
			areStreamUpdatesEnabled.current = true;
		}, 60000);
	}, []);

	const onActivityUpdate = useCallback(() => {
		areStreamUpdatesEnabled.current = false;
		debounceEnabledStreamUpdates();
	}, [debounceEnabledStreamUpdates]);

	const refetchLatestWidgetActivities = useCallback(() => {
		if (streamResponseRef.current && !isEqual(widgetActivities, streamResponseRef.current)) {
			setWidgetActivities(streamResponseRef.current);
		} else {
			areStreamUpdatesEnabled.current = true;
		}
	}, []);

	useEffect(() => {
		fetchWidgetActivities();

		return () => {
			closeWidgetActivitisStream();
		};
	}, [projectId]);

	return {
		widgetActivities,
		setWidgetActivities,
		onActivityUpdate,
		setWidgetActivityFilters,
		widgetActivityFilters,
		refetchLatestWidgetActivities,
		isFetched: streamResponseRef.current !== null,
	};
};
