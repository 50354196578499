import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import 'moment/locale/he';
import classes from './styles.module.scss';
import { cM } from '@shared/utils/colors.utils';
import { IProjectMilestones } from '@shared/interfaces/IProjectMilestones';
import { IMilestone } from '@shared/interfaces/IMilestone';
import { IProject } from '@shared/interfaces/IProject';
import { getDateStringByLocale } from '@shared/utils/dateUtils';
import { translationService } from '../../../../../index';
import helpCircleIcon from '../../../../../assets/helpCircle.svg';
import { ProgressLabel } from '../ProgressLabel/ProgressLabel';
import startEndPolygon from '../../../../../assets/milestonesIcons/startEndPolygon.svg';
import flagIcon from '../../../../../assets/milestonesIcons/flag.svg';
import todayLineIcon from '../../../../../assets/milestonesIcons/todayLineIcon.svg';
import upcomingPolygonIcon from '../../../../../assets/milestonesIcons/upcomingPolygon.svg';
import donePolygonIcon from '../../../../../assets/milestonesIcons/donePolygon.svg';
import defPolygonIcon from '../../../../../assets/milestonesIcons/defPolygon.svg';
import { selectTimezone } from '../../../../../store/slices/project.slice';

interface IMilestoneHeaderProps {
	projectMilestones: IProjectMilestones | undefined;
	currentProject: IProject | undefined;
	showCompletePercentage?: boolean;
}

const MilestoneHeader = (props: IMilestoneHeaderProps) => {
	const isRtl: boolean = translationService.getIsRtl();
	const tz: string = useSelector(selectTimezone)!;

	const totalDaysDiff: number = moment
		.tz(props.projectMilestones?.milestones[props.projectMilestones.milestones.length - 1]?.endDate, tz)
		.diff(moment.tz(props.currentProject?.startDate!, tz), 'days');

	const getInlineStartPercentageValueByDaysDiff = (daysDiff: number): number => {
		const inlineStartPercentageValue: number = (daysDiff / totalDaysDiff) * 100;
		return inlineStartPercentageValue > 50 ? inlineStartPercentageValue - 5 : inlineStartPercentageValue + 5; // 5 for the margin of the start and end polygons
	};

	const getTodayLineOnMilestones = (): JSX.Element => {
		const todayDaysDiff: number = moment
			.tz(new Date(), tz)
			.diff(moment.tz(props.currentProject?.startDate!, tz), 'days');
		const inlineStartPercentageValue: number = getInlineStartPercentageValueByDaysDiff(todayDaysDiff);
		return (
			<div style={{ insetInlineStart: `${inlineStartPercentageValue}%` }} className={classes.todayContainer}>
				<img src={todayLineIcon} alt="" className={classes.todayLine} />
				<div className={cM(classes.todayText, classes)}>{translationService.get('today')}</div>
			</div>
		);
	};

	const getMilestonesCompletedCount = (): number => {
		return props.projectMilestones!.milestones.filter((milestone) => milestone.isDone).length;
	};

	const getMilestonePolygon = (milestone: IMilestone, index: number): JSX.Element => {
		if (!props.projectMilestones) return <></>;
		const milestoneDaysDiff: number = moment
			.tz(milestone.endDate, tz)
			.diff(moment.tz(props.currentProject?.startDate!, tz), 'days');
		const position: number = getInlineStartPercentageValueByDaysDiff(milestoneDaysDiff);
		const isLastMilestone: boolean = index === props.projectMilestones.milestones.length - 1;
		if (isLastMilestone) return <></>; // skip last milestone
		const currentMilestone: IMilestone = props.projectMilestones.milestones[index];
		const prevMilestone: IMilestone = props.projectMilestones.milestones[index - 1];
		const isUpcoming: boolean =
			!currentMilestone.isDone && (!prevMilestone || prevMilestone?.isDone) && index !== 0;
		const polygonIcon: string = isUpcoming
			? upcomingPolygonIcon
			: currentMilestone.isDone
			? donePolygonIcon
			: defPolygonIcon;

		return (
			<img
				key={`milestonePolygon_${index}`}
				src={polygonIcon}
				alt=""
				className={classes.milestonePolygon}
				style={{ insetInlineStart: `${position}%`, zIndex: 1 + index }}
			/>
		);
	};

	if (!props.projectMilestones) return <></>;
	return (
		<>
			<section className={classes.progressSection}>
				<div className={cM(classes.progressText, classes)}>
					{translationService.get('progress')}
					{/*<img src={helpCircleIcon} alt="" className={classes.helpIcon} />*/}
				</div>
				<ProgressLabel projectStatus={props.projectMilestones.projectStatus} />
			</section>
			<section className={cM(classes.completedSection, classes)}>
				{translationService.get('milestonesCompleted')}
				<div className={`${classes.counterSection} ${isRtl ? classes.rtl : ''}`}>
					{getMilestonesCompletedCount()}/{props.projectMilestones.milestones.length}
					{props.showCompletePercentage && (
						<span className={classes.percentageText}>
							{' '}
							{Math.round(
								(getMilestonesCompletedCount() / props.projectMilestones.milestones.length) * 100
							) + '%'}
						</span>
					)}
				</div>
			</section>
			<section className={classes.milestonesProgressSection}>
				<div className={cM(classes.progressBar, classes)}>
					<img src={startEndPolygon} alt="" className={classes.startPolygon} />
					<div className={cM(classes.startDateText, classes)}>
						{getDateStringByLocale(
							props.currentProject?.startDate!,
							tz,
							translationService.getDateLocale()
						)}
					</div>
					{props.projectMilestones.milestones.map((milestone, index) =>
						getMilestonePolygon(milestone, index)
					)}
					{getTodayLineOnMilestones()}
					<img src={startEndPolygon} alt="" className={`${classes.endPolygon} ${isRtl ? classes.rtl : ''}`} />
					<div className={`${cM(classes.endDateText, classes)} ${isRtl ? classes.rtl : ''}`}>
						<img src={flagIcon} alt="" className={classes.flagIcon} />
						{getDateStringByLocale(
							props.projectMilestones.milestones[props.projectMilestones.milestones.length - 1]?.endDate,
							tz,
							translationService.getDateLocale()
						)}
					</div>
				</div>
			</section>
		</>
	);
};

export { MilestoneHeader };
