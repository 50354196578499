import { ITradeFilter, ITrade, IManagementTradesReducerState } from '../../interfaces';
import { filterArrayByAnotherArray } from '../../utils/array.util';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { entitiesProperties } from '../../constants';
import { compact } from 'lodash';
import { getStaticManagers } from '../thunks/managers.thunks';

const initialState: IManagementTradesReducerState = {
	managementTrades: [],
	selectedManagementTrades: [],
	filteredManagementTrades: [],
	filterList: [],
	visibleManagementTrades: [],
};

const managementTradesSlice = createSlice({
	name: 'managementTrades',
	initialState,
	reducers: {
		removeAllManagementTrades: (state: IManagementTradesReducerState) => {
			state.managementTrades = [];
			state.selectedManagementTrades = [];
			state.filteredManagementTrades = [];
			state.filterList = [];
			state.visibleManagementTrades = [];
		},
		addSelectedManagementTrades: (
			state: IManagementTradesReducerState,
			action: PayloadAction<{ selectedManagementTrade: ITrade }>
		) => {
			const selectedManagementTradesAfterAdd: ITrade[] = compact(
				state.selectedManagementTrades.concat(action.payload.selectedManagementTrade)
			);
			const visibleManagementTrades: ITrade[] = filterArrayByAnotherArray(
				state.filteredManagementTrades,
				selectedManagementTradesAfterAdd,
				entitiesProperties.tradeId
			);
			state.selectedManagementTrades = selectedManagementTradesAfterAdd;
			state.visibleManagementTrades = visibleManagementTrades;
		},
		removeSelectedManagementTrades: (
			state: IManagementTradesReducerState,
			action: PayloadAction<{ removedManagementTrade: ITrade }>
		) => {
			const selectedManagementTradesAfterRemove = state.selectedManagementTrades.filter((trade) => {
				return action.payload.removedManagementTrade.tradeId !== trade.tradeId;
			});
			const visibleManagementTrades: ITrade[] = filterArrayByAnotherArray(
				state.filteredManagementTrades,
				selectedManagementTradesAfterRemove,
				entitiesProperties.tradeId
			);
			state.selectedManagementTrades = selectedManagementTradesAfterRemove;
			state.visibleManagementTrades = visibleManagementTrades;
		},
		setManagementTradesFilter: (
			state: IManagementTradesReducerState,
			action: PayloadAction<{ filterList: ITradeFilter[] }>
		) => {
			const filteredManagementTrades: ITrade[] = filterArrayByAnotherArray(
				state.managementTrades,
				action.payload.filterList,
				entitiesProperties.tradeId
			);
			const selectedManagementTrades: ITrade[] = filterArrayByAnotherArray(
				state.selectedManagementTrades,
				action.payload.filterList,
				entitiesProperties.tradeId
			);
			const visibleManagementTrades: ITrade[] = filterArrayByAnotherArray(
				filteredManagementTrades,
				selectedManagementTrades,
				entitiesProperties.tradeId
			);

			state.filteredManagementTrades = filteredManagementTrades;
			state.selectedManagementTrades = selectedManagementTrades;
			state.filterList = action.payload.filterList;
			state.visibleManagementTrades = visibleManagementTrades;
		},
		removeSelectedManagementTrade: (
			state: IManagementTradesReducerState,
			action: PayloadAction<{ removedStaticManagementTrade: ITrade }>
		) => {
			const selectedStaticManagersProfessionsAfterRemove = state.managementTrades.filter((trade) => {
				return action.payload.removedStaticManagementTrade.tradeId !== trade.tradeId;
			});
			const visibleStaticManagersTrades: ITrade[] = filterArrayByAnotherArray(
				state.filteredManagementTrades,
				selectedStaticManagersProfessionsAfterRemove,
				entitiesProperties.tradeId
			);
			state.selectedManagementTrades = selectedStaticManagersProfessionsAfterRemove;
			state.visibleManagementTrades = visibleStaticManagersTrades;
		},
		resetSelectedManagementTrades: (state: IManagementTradesReducerState) => {
			state.selectedManagementTrades = [];
			state.filterList = [];
			state.filteredManagementTrades = state.managementTrades;
			state.visibleManagementTrades = state.managementTrades;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getStaticManagers.fulfilled, (state, action) => {
			const filteredManagementTrades: ITrade[] = filterArrayByAnotherArray(
				action.payload.managementTrades,
				state.filterList,
				entitiesProperties.tradeId
			);
			const selectedManagementTrades: ITrade[] = filterArrayByAnotherArray(
				state.selectedManagementTrades,
				state.filterList,
				entitiesProperties.tradeId
			);
			const visibleManagementTrades: ITrade[] = filterArrayByAnotherArray(
				filteredManagementTrades,
				selectedManagementTrades,
				entitiesProperties.tradeId
			);

			state.managementTrades = action.payload.managementTrades;
			state.filteredManagementTrades = filteredManagementTrades;
			state.visibleManagementTrades = visibleManagementTrades;
			state.selectedManagementTrades = selectedManagementTrades;
		});
	},
});

export const {
	addSelectedManagementTrades,
	removeSelectedManagementTrades,
	removeAllManagementTrades,
	resetSelectedManagementTrades,
	setManagementTradesFilter,
} = managementTradesSlice.actions;

export default managementTradesSlice.reducer;
