import React, { useCallback, useMemo } from 'react';
import {
	ACTIVITIES_ACTIVITY_COLUMN_WIDTH,
	ACTIVITIES_COUNT_COLUMN_HEADER_WIDTH,
	ACTIVITIES_COUNT_COLUMN_WIDTH,
	ACTIVITIES_FLOOR_COLUMN_WIDTH,
	ACTIVITIES_MANAGER_COLUMN_WIDTH,
	ACTIVITIES_PROFESSION_COLUMN_WIDTH,
	ACTIVITIES_TIME_COLUMN_WIDTH,
	ACTIVITIES_WORKERS_COLUMN_WIDTH,
} from '../../../../../../../constants';
import { translationService } from '../../../../../../../index';
import classes from './styles.module.scss';

interface IActivitiesHeaderRowProps {
	isDailyReport: boolean;
	setWidthToRowContainer: (width: number) => void;
}

const ActivitiesHeaderRow = (props: IActivitiesHeaderRowProps) => {
	const measuredRef = useCallback((node) => {
		if (node !== null) {
			props.setWidthToRowContainer(node.getBoundingClientRect().width);
		}
	}, []);

	const workersCellTitle = useMemo(
		() =>
			props.isDailyReport
				? translationService.get('dailyWorkers').toUpperCase()
				: translationService.get('numberOfWorkersAverageDaily').toUpperCase(),
		[props.isDailyReport]
	);
	const timeCellTitle = useMemo(
		() =>
			props.isDailyReport
				? translationService.get('dailySpentHours').toUpperCase()
				: translationService.get('activityHoursAverageDaily').toUpperCase(),
		[props.isDailyReport]
	);
	return (
		<div className={classes.rowContainer} ref={measuredRef}>
			<p
				className={`${classes.title}`}
				style={{
					minWidth: ACTIVITIES_PROFESSION_COLUMN_WIDTH,
					maxWidth: ACTIVITIES_PROFESSION_COLUMN_WIDTH,
				}}
			>
				{translationService.get('profession').toUpperCase()}
			</p>
			<p
				className={`${classes.title}`}
				style={{
					minWidth: ACTIVITIES_COUNT_COLUMN_HEADER_WIDTH,
					maxWidth: ACTIVITIES_COUNT_COLUMN_HEADER_WIDTH,
				}}
			>
				{translationService.get('numberOfActivities').toUpperCase()}
			</p>
			<p
				className={`${classes.title}`}
				style={{
					minWidth: ACTIVITIES_FLOOR_COLUMN_WIDTH,
					maxWidth: ACTIVITIES_FLOOR_COLUMN_WIDTH,
				}}
			>
				{translationService.get('location').toUpperCase()}
			</p>
			<div
				className={`${classes.twoLinesHeader}`}
				style={{
					minWidth: ACTIVITIES_ACTIVITY_COLUMN_WIDTH,
					maxWidth: ACTIVITIES_ACTIVITY_COLUMN_WIDTH,
				}}
			>
				<p className={classes.title}>{translationService.get('activityInfo').toUpperCase()}</p>
			</div>
			<div
				className={`${classes.twoLinesHeader}`}
				style={{
					minWidth: ACTIVITIES_WORKERS_COLUMN_WIDTH,
					maxWidth: ACTIVITIES_WORKERS_COLUMN_WIDTH,
				}}
			>
				<p className={classes.title}>{workersCellTitle}</p>
			</div>
			<div
				className={`${classes.twoLinesHeader}`}
				style={{
					minWidth: ACTIVITIES_TIME_COLUMN_WIDTH,
					maxWidth: ACTIVITIES_TIME_COLUMN_WIDTH,
				}}
			>
				<p className={classes.title}>{timeCellTitle}</p>
			</div>
			<p
				className={`${classes.title}`}
				style={{
					minWidth: ACTIVITIES_MANAGER_COLUMN_WIDTH,
					maxWidth: ACTIVITIES_MANAGER_COLUMN_WIDTH,
				}}
			>
				{translationService.get('manager').toUpperCase()}
			</p>
		</div>
	);
};

export { ActivitiesHeaderRow };
