import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TooltipedIcon } from '../Tooltips/TooltipedIcon';
import { textStyle } from '@shared/utils/text.utils';
import { EquipmentIcon } from './EquipmentIcon';
import { EquipmentChipTooltipContent } from '../Chips/EquipmentChipTooltipContent';
import { EquipmentTypes } from '../../interfaces';
import { IMergedEquipmentView } from '../../interfaces/IMergedEquipmentView';

const useStyles = makeStyles((theme) => ({
	avatar: {
		borderRadius: '50%',
		boxShadow: '0px 3px 2px 0px rgba(0, 0, 0, 0.45)',
		backgroundColor: '#fefefe',
		position: 'absolute',
		top: theme.spacing(-1),
		left: theme.spacing(2),
		height: 15,
		width: 15,
		zIndex: 12,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	eqIconText: textStyle({
		color: '#3b3b3b',
		fontClass: 'body1',
		textAlign: 'center',
	}),
	icon: {
		width: 20,
		height: 20,
	},
	iconContainer: {
		marginLeft: theme.spacing(0.25),
		marginRight: theme.spacing(0.25),
	},
	iconAvatarWrapper: {
		position: 'relative',
	},
}));

interface IMainViewEquipmentIconProps {
	type: EquipmentTypes;
	equipmentArray: IMergedEquipmentView[];
	click?: () => void;
}

const MainViewEquipmentIcon = (props: IMainViewEquipmentIconProps) => {
	const classes = useStyles();

	return (
		<div className={classes.iconAvatarWrapper} onClick={props.click}>
			<TooltipedIcon
				tooltipComponent={
					<div>
						{props.equipmentArray.map((equipment, index) => {
							return (
								<EquipmentChipTooltipContent
									type={equipment.type}
									name={equipment.name}
									floorId={equipment.floorId}
									key={`${equipment.name}_${index}`}
								/>
							);
						})}
					</div>
				}
			>
				<div className={classes.iconContainer}>
					<EquipmentIcon type={props.type} />
				</div>
			</TooltipedIcon>
			{props.equipmentArray.length > 1 && (
				<div className={classes.avatar}>
					<p className={classes.eqIconText}>{props.equipmentArray.length}</p>
				</div>
			)}
		</div>
	);
};

export { MainViewEquipmentIcon };
