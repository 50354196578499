import React, { useRef, useState } from 'react';
import classes from './styles.module.scss';
import { translationService } from '../../../index';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import CancelIcon from '../../../assets/cancel_icon.svg';
import CheckIcon from '../../../assets/check_icon.svg';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../store/slices';
import { ITagCertificate } from '../../../interfaces/ITagCertificate';
import moment from 'moment';
import {
	ITrusstorButtonSize,
	ITrusstorButtonType,
	TrusstorButton,
} from '@shared/components/buttons/TrusstorButton/TrusstorButton';
import { TrusstorTextInput } from '@shared/components/Inputs/TrusstorTextInput/TrusstorTextInput';
import { DateSelectorTrusstorInput } from '@src/Components/DateSelector/DateSelectorTrusstorInput/DateSelectorTrusstorInput';

interface ICertificateTagRowProps {
	certificate: ITagCertificate;
	updateCertificateDateByCertificateId: (name: string, date: Date) => void;
	removeCertificateByName: (name: string) => void;
}

const CertificateTagRow = ({
	certificate,
	updateCertificateDateByCertificateId,
	removeCertificateByName,
}: ICertificateTagRowProps) => {
	const tz: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.tz);
	const [showingDeleteSection, setShowingDeleteSection] = useState(false);
	const certificateRowEl = useRef<HTMLHeadingElement>(null);

	const handleRemoveCertificateByName = (name: string): void => {
		removeCertificateByName(name);
		setShowingDeleteSection(false);
	};

	const openDeleteSection = (): void => {
		setShowingDeleteSection(true);
		setTimeout(() => {
			certificateRowEl?.current?.scrollIntoView({
				behavior: 'smooth',
				block: 'start',
			});
		}, 0);
	};

	const isCertificateValid: boolean = new Date(String(certificate.expirationDate)).getTime() > Date.now();
	const tomorrowDate: Date = moment.tz(tz).add(1, 'day').toDate();

	return (
		<div className={classes.certificateTagRowContainer} ref={certificateRowEl}>
			<div className={classes.certificateRow}>
				<div className={classes.certificateName}>
					<TrusstorTextInput
						startIcon={isCertificateValid ? CheckIcon : CancelIcon}
						value={certificate.name}
						disabled
						placeholder={translationService.get('certificateName')}
						testId={'certificateName'}
					/>
				</div>
				<DateSelectorTrusstorInput
					label={translationService.get('expirationDate')}
					minDate={new Date()}
					maxDate={isCertificateValid ? certificate.expirationDate : tomorrowDate}
					handleTimeChange={(date) => updateCertificateDateByCertificateId(certificate.name, date as Date)}
					required
					testId={'certificateExpirationDate'}
					hideIcon
					selectedDate={new Date(certificate.expirationDate)}
				/>
				{!showingDeleteSection && (
					<div
						className={classes.exitCertificate}
						data-testid={'deleteCertificateButton'}
						onClick={openDeleteSection}
					>
						<HighlightOffRoundedIcon />
					</div>
				)}
			</div>
			{showingDeleteSection && (
				<div className={classes.deleteSection}>
					<div className={classes.deleteText}>{translationService.get('deleteThisCertificate')}</div>
					<TrusstorButton
						text={translationService.get('cancel')}
						handleClick={() => setShowingDeleteSection(false)}
						buttonSize={ITrusstorButtonSize.SMALL}
						buttonType={ITrusstorButtonType.GHOST}
						testId={'cancelDeleteCertificateButton'}
					/>
					<TrusstorButton
						text={translationService.get('yesDeleteIt')}
						handleClick={() => handleRemoveCertificateByName(certificate.name)}
						buttonSize={ITrusstorButtonSize.SMALL}
						testId={'yesDeleteCertificateButton'}
					/>
				</div>
			)}
		</div>
	);
};

export { CertificateTagRow };
