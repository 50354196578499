import React from 'react';
import classes from './styles.module.scss';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { TrusstorIcon } from '@src/Components/TrusstorIcon/TrusstorIcon';

interface IGraphStatProps {
	title: string;
	iconName: IconNames;
	value: number;
}

export const GraphStat = (props: IGraphStatProps) => {
	return (
		<div className={classes.GraphStat_container}>
			<div className={classes.icon}>
				<TrusstorIcon iconName={props.iconName} />
			</div>
			<div className={classes.section}>
				<div className={classes.title}>{props.title}</div>
				<div className={classes.value}>{props.value}</div>
			</div>
		</div>
	);
};
