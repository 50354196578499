import { IProfession } from '../interfaces/IProfession';
import { ITrade } from '../interfaces/ITrade';
import { IBaseProfession } from '../interfaces/IBaseProfession';
import { IProfessionDisplaySettings } from '../interfaces/IProfessionDisplaySettings';
import { TranslationService } from '../services/translation.service';
import { getTradeTranslationShared } from './translations.utils';

export const getProfessionDisplayText = (
	profession: IProfession | ITrade,
	translationService: TranslationService,
	withoutContractor: boolean = false
): string => {
	if (!profession) {
		return '';
	}
	let displayText: string = getTradeTranslationShared(profession, translationService);
	if ((profession as IProfession).specialty) {
		displayText += `, ${(profession as IProfession).specialty}`;
	}
	if ((profession as IProfession).contractor && !withoutContractor) {
		displayText += ` - ${(profession as IProfession).contractor}`;
	}
	return displayText;
};

export const getProfessionsById = (professions: IProfession[], id: string): IProfession | undefined => {
	return professions.find((profession) => profession._id === id);
};

export const getProfessionBackgroundColor = (profession: IProfession | IBaseProfession, projectId: string): string => {
	if (!profession) {
		return '';
	}
	return profession.activeProjects?.[projectId]?.backgroundColor ?? profession.backgroundColor;
};

export const getShouldShowProfessionDisplayProperties = (
	profession: IProfession,
	projectId: string
): Record<keyof IProfessionDisplaySettings, boolean> => {
	const professionDisplaySettings: IProfessionDisplaySettings | undefined =
		profession.activeProjects?.[projectId]?.displaySettings;
	if (!professionDisplaySettings) {
		return {
			trade: true,
			specialty: true,
			contractor: true,
		};
	}
	return {
		trade: !!professionDisplaySettings.trade,
		specialty: !!(professionDisplaySettings.specialty && profession.specialty),
		contractor: !!(professionDisplaySettings.contractor && profession.contractor),
	};
};

export const getProfessionDisplayTextFromDisplaySettings = (
	profession: IProfession,
	translationService: TranslationService,
	projectId: string
): string => {
	const professionDisplaySettings: Record<keyof IProfessionDisplaySettings, boolean> =
		getShouldShowProfessionDisplayProperties(profession, projectId);
	let displayText: string = '';
	if (professionDisplaySettings.trade) {
		displayText += `${getTradeTranslationShared(profession, translationService)}`;
	}
	if (professionDisplaySettings.specialty && profession.specialty) {
		if (displayText) {
			displayText += ', ';
		}
		displayText += `${profession.specialty}`;
	}
	if (professionDisplaySettings.contractor && profession.contractor) {
		if (displayText) {
			displayText += ' - ';
		}
		displayText += `${profession.contractor}`;
	}
	return displayText;
};
