import React, { createContext, useContext } from 'react';
import { RequestService } from '../services/request.service';
import { TranslationService } from '../services/translation.service';

export const SharedServicesContext = createContext<ISharedServicesContext>({} as ISharedServicesContext);

export interface ISharedServicesContext {
	requestService: RequestService;
	translationService: TranslationService;
}

export const useSharedServices = (): ISharedServicesContext => {
	const sharedServices: ISharedServicesContext = useContext(SharedServicesContext);
	if (!sharedServices) {
		throw new Error('useSharedServices must be used within a SharedServicesProvider');
	}
	return sharedServices;
};
