import { IIssue } from '@shared/interfaces/IIssue';
import React, { useContext, useEffect, useState } from 'react';
import { getTimezoneStartOfDate } from '@shared/utils/dateUtils';
import { useDispatch, useSelector } from 'react-redux';
import classes from './styles.module.scss';
import { selectProjectId, selectTimezone } from '@store/slices/project.slice';
import { requestService, storageService, translationService } from '@src/servicesInitializers';
import moment from 'moment';
import { IssueItem } from '@shared/components/Issue/IssueItem';
import { issueComment_BI, issueStatusChange_BI } from '@utils/bi.utils';
import { IUser } from '@shared/interfaces/IUser';
import { selectLoggedUserDetails } from '@store/slices/login.slice';
import { IssueDesktopEditDialog } from '@src/Components/Pages/IssuesPage/IssueDesktopEditDialog/IssueDesktopEditDialog';
import { IConfigArea } from '@shared/interfaces/IConfigArea';
import { useProjectAreasQuery } from '@src/hooks/queries/areas.queries.hooks';
import { MainPageContext } from '@src/Components/Pages/MainPage';
import { errorSnackbar } from '@utils/snackbar.utils';
import { sortMainPageIssues } from '@shared/utils/issues.utils';
import { isIssueOverdue } from '@shared/components/Issue/issues.utils';
import { ExpandedSection } from '@shared/components/ExpandedSection/ExpandedSection';

interface IMainPageIssuesListProps {
	issues: IIssue[];
}

export const MainPageIssuesList = (props: IMainPageIssuesListProps) => {
	const [overdueIssues, setOverdueIssues] = useState<IIssue[]>([]);
	const [dueThisWeekIssues, setDueThisWeekIssues] = useState<IIssue[]>([]);
	const [upcomingIssues, setUpcomingIssues] = useState<IIssue[]>([]);
	const [noDueDateIssues, setNoDueDateIssues] = useState<IIssue[]>([]);
	const [issueToEdit, setIssueToEdit] = useState<IIssue>();
	const tz: string = useSelector(selectTimezone)!;
	const projectId: string = useSelector(selectProjectId)!;
	const todayProjectDate: Date = getTimezoneStartOfDate(tz);
	const nextWeekProjectDate: Date = moment.tz(tz).add(6, 'days').toDate();
	const user: IUser = useSelector(selectLoggedUserDetails)!;
	const dispatch = useDispatch();
	const projectAreas: IConfigArea[] = useProjectAreasQuery(projectId);
	const { updateIssue, deleteIssue } = useContext(MainPageContext)!;

	const isDueThisWeek = (issue: IIssue): boolean => {
		const dueDateTZ = moment.tz(issue.dueDate, tz);
		return !!issue.dueDate && dueDateTZ.isSameOrAfter(todayProjectDate) && dueDateTZ.isBefore(nextWeekProjectDate);
	};

	const isUpcoming = (issue: IIssue): boolean => {
		return !!issue.dueDate && new Date(issue.dueDate) > nextWeekProjectDate;
	};

	const isNoDueDate = (issue: IIssue): boolean => {
		return !issue.dueDate;
	};

	useEffect(() => {
		const overdueIssues: IIssue[] = props.issues.filter((issue) => isIssueOverdue(issue, tz));
		const dueThisWeekIssues: IIssue[] = props.issues.filter(isDueThisWeek);
		const upcomingIssues: IIssue[] = props.issues.filter(isUpcoming);
		const noDueDateIssues: IIssue[] = props.issues.filter(isNoDueDate);

		setOverdueIssues(overdueIssues);
		setDueThisWeekIssues(dueThisWeekIssues);
		setUpcomingIssues(upcomingIssues);
		setNoDueDateIssues(noDueDateIssues);
	}, [props.issues]);

	const closeEditDesktopDialog = () => {
		setIssueToEdit(undefined);
	};

	const handleSizeLimitImageError = () => {
		errorSnackbar(dispatch, translationService.get('imageAboveTheSizeLimitError'));
	};

	const getIssuesCard = (issues: IIssue[]) => {
		return (
			<div className={classes.groupList}>
				{sortMainPageIssues(issues).map((issue) => {
					return (
						<IssueItem
							isEditIssueDialogShown={!!issueToEdit}
							setIssueToEdit={setIssueToEdit}
							issueComment_BI={issueComment_BI}
							deleteIssue={deleteIssue}
							key={issue._id}
							issue={issue}
							tz={tz}
							projectId={projectId}
							translationService={translationService}
							updateIssue={updateIssue}
							issueStatusChangeBiEvent={(status, issueUpdated) => {
								issueStatusChange_BI(issueUpdated, status, tz);
							}}
							storageService={storageService}
							requestService={requestService}
							user={user}
							imageSizeLimitCallback={handleSizeLimitImageError}
						/>
					);
				})}
			</div>
		);
	};

	return (
		<div className={classes.mainPageIssuesList}>
			{!!overdueIssues.length && (
				<ExpandedSection
					text={`${translationService.get('mainPage_issuesTab_overdue')} (${overdueIssues.length})`}
				>
					{getIssuesCard(overdueIssues)}
				</ExpandedSection>
			)}
			{!!dueThisWeekIssues.length && (
				<ExpandedSection
					text={`${translationService.get('mainPage_issuesTab_dueIn7days')} (${dueThisWeekIssues.length})`}
				>
					{getIssuesCard(dueThisWeekIssues)}
				</ExpandedSection>
			)}
			{!!upcomingIssues.length && (
				<ExpandedSection
					text={`${translationService.get('mainPage_issuesTab_upcoming')} (${upcomingIssues.length})`}
				>
					{getIssuesCard(upcomingIssues)}
				</ExpandedSection>
			)}
			{!!noDueDateIssues.length && (
				<ExpandedSection
					text={`${translationService.get('mainPage_issuesTab_noDueDate')} (${noDueDateIssues.length})`}
				>
					{getIssuesCard(noDueDateIssues)}
				</ExpandedSection>
			)}
			{!!issueToEdit && (
				<IssueDesktopEditDialog
					projectAreas={projectAreas}
					closeDialog={closeEditDesktopDialog}
					issue={issueToEdit}
					updateIssue={updateIssue}
					translationService={translationService}
				/>
			)}
		</div>
	);
};
