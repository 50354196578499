import React from 'react';
import { useSelector } from 'react-redux';
import classes from './styles.module.scss';
import moment from 'moment-timezone';
import { IProjectMilestones } from '@shared/interfaces/IProjectMilestones';
import { IProject } from '@shared/interfaces/IProject';
import { IMilestone } from '@shared/interfaces/IMilestone';
import { cM } from '@shared/utils/colors.utils';
import { MilestoneHeader } from '../MilestoneHeader/MilestoneHeader';
import { MilestoneRow } from '../MilestoneRow/MilestoneRow';
import greenPolygonIcon from '../../../../../assets/milestonesIcons/greenPolygon.svg';
import redPolygonIcon from '../../../../../assets/milestonesIcons/redPolygon.svg';
import upcomingBigPolygonIcon from '../../../../../assets/milestonesIcons/upcomingBigPolygon.svg';
import projectStartPolygonIcon from '../../../../../assets/milestonesIcons/projectStartBigPolygonIcon.svg';
import defaultBigPolygonIcon from '../../../../../assets/milestonesIcons/defaultBigPolygonIcon.svg';
import todayIndicatorIcon from '../../../../../assets/milestonesIcons/todayIndicatorIcon.svg';
import { selectTimezone } from '../../../../../store/slices/project.slice';
import { translationService } from '../../../../../index';
import lineBreakIcon from '../../../../../assets/milestonesIcons/lineBreak.svg';
import { isMilestoneLate } from '../../../../../utils/milestones.utils';

interface IMilestoneDialogProps {
	projectMilestones: IProjectMilestones | undefined;
	currentProject: IProject | undefined;
}

const MilestoneDialog = (props: IMilestoneDialogProps) => {
	const tz: string = useSelector(selectTimezone)!;

	const getMilestoneIcon = (milestone: IMilestone): string => {
		if (milestone.isDone) {
			return greenPolygonIcon;
		} else {
			if (isMilestoneLate(milestone, tz)) return redPolygonIcon;
			const upcomingMilestoneIndex: number | undefined = props.projectMilestones?.milestones.findIndex(
				(m) => !m.isDone
			);
			const currentMilestoneIndex: number | undefined = props.projectMilestones?.milestones.findIndex(
				(m) => m._id === milestone._id
			);
			return upcomingMilestoneIndex === currentMilestoneIndex ? upcomingBigPolygonIcon : defaultBigPolygonIcon;
		}
	};

	const getProjectStartMilestone = (): IMilestone => {
		return {
			title: translationService.get('projectStarted'),
			projectId: props.currentProject!.projectId,
			endDate: props.currentProject!.startDate!,
			isDone: false,
		};
	};

	const isUpcomingMilestone = (index: number): boolean => {
		const prevMilestone: IMilestone | undefined = props.projectMilestones?.milestones[index - 1];
		const nextMilestone: IMilestone | undefined = props.projectMilestones?.milestones[index];
		return (
			moment.tz(prevMilestone?.endDate, tz).isBefore(moment.tz(new Date(), tz)) &&
			moment.tz(nextMilestone?.endDate, tz).isAfter(moment.tz(new Date(), tz))
		);
	};

	const setTodayIndicator = (): JSX.Element => {
		return (
			<div className={classes.todayIndicatorContainer}>
				<img className={classes.separateLine} src={lineBreakIcon} alt="" />
				<img className={classes.todayIndicatorIcon} src={todayIndicatorIcon} alt="" />
				<div className={classes.todayIndicatorTextContainer}>
					<div className={cM(classes.todayText, classes)}>{translationService.get('today')}</div>
					<div className={classes.dateText}>{(new Date(), tz, translationService.getDateLocale())}</div>
				</div>
			</div>
		);
	};

	return (
		<section className={cM(classes.dialogContainer, classes)}>
			<MilestoneHeader
				projectMilestones={props.projectMilestones}
				currentProject={props.currentProject}
				showCompletePercentage
			/>
			<div className={classes.lineBreak}></div>
			<div className={classes.milestonesContainer}>
				<MilestoneRow
					icon={projectStartPolygonIcon}
					milestoneIndex={0}
					milestone={getProjectStartMilestone()}
					separateLine
				/>
				{props.projectMilestones?.milestones.map((milestone, index) => {
					const isUpcoming: boolean = isUpcomingMilestone(index);
					return (
						<div key={`milestoneDialogRow_${milestone._id!}`}>
							{isUpcoming && setTodayIndicator()}
							<MilestoneRow
								icon={getMilestoneIcon(milestone)}
								title={''}
								milestoneIndex={index + 1} // +1 cus of project start milestone
								milestone={milestone}
								separateLine={index !== props.projectMilestones!.milestones.length - 1}
								showMarkCompletedButton
							/>
						</div>
					);
				})}
			</div>
		</section>
	);
};
export { MilestoneDialog };
