import { IConfigArea } from '@interfaces/IConfigArea';
import { IFloor } from '@interfaces/index';
import { Dictionary, groupBy } from 'lodash';

export const getFloorsFromAreas = (areas: IConfigArea[]): IFloor[] => {
	const areasGroupedByFloors: Dictionary<IConfigArea[]> = groupBy(areas, 'floorId');
	const floors: IFloor[] = Object.keys(areasGroupedByFloors).map((floorId: string) => {
		const firstArea: IConfigArea = areasGroupedByFloors[floorId][0];
		return {
			floorId,
			floorNick: firstArea.floorNick,
			projectId: firstArea.projectId,
			shortFloorNick: firstArea.shortFloorNick,
			isGroundFloor: firstArea.isGroundFloor,
			isHoist: firstArea.isHoist,
			isMoving: firstArea.isMovingFloor,
			isSpecialFloor: firstArea.isSpecialFloor,
		};
	});
	return floors;
};
