import React from 'react';
import classes from './styles.module.scss';
import { TrusstorIcon } from '@src/Components/TrusstorIcon/TrusstorIcon';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { getPastTimeFrom } from '@utils/dateUtils';
import { IFloorData } from '@interfaces/IFloorData';
import { IconColor } from '@shared/components/TrusstorIconShared/TrusstorIconShared';
import moment from 'moment-timezone';
import { translationService } from '@src/servicesInitializers';
import { ACTIVE_HOIST_TIME } from '@src/constants';
import { IConfigArea } from '@shared/interfaces/IConfigArea';
import { useFloorAreasQuery } from '@src/hooks/queries/areas.queries.hooks';
import { selectProjectId } from '@store/slices/project.slice';
import { useSelector } from 'react-redux';
import { LiveActionAnimatedIcon } from '@src/Components/LiveActionAnimatedIcon/LiveActionAnimatedIcon';

interface ISpecialFloorTitleSectionProps {
	isHoist: boolean;
	floorData: IFloorData;
}

export const SpecialFloorTitleSection = (props: ISpecialFloorTitleSectionProps) => {
	const projectId: string = useSelector(selectProjectId)!;
	const floorAreas: IConfigArea[] = useFloorAreasQuery(projectId, props.floorData.floorId);

	const isMovingFloor: boolean =
		props.floorData.isMovingFloor &&
		!!props.floorData.matchingFixedFloorChangeTimestamp &&
		!!props.floorData.matchingFixedFloorNick;

	const getIsActiveHoist = () => {
		const differenceInMs: number = moment().diff(
			new Date(props.floorData.matchingFixedFloorChangeTimestamp as Date)
		);
		return differenceInMs < ACTIVE_HOIST_TIME;
	};

	const isActiveHoist: boolean = getIsActiveHoist();

	const getSubtitleSecondPart = () => {
		return getPastTimeFrom(new Date(props.floorData.matchingFixedFloorChangeTimestamp as Date));
	};

	const areThereMultipleAreas: boolean = floorAreas.length > 1;

	return (
		<div className={classes.SpecialFloorTitleSection_container} data-testid="SpecialFloorTitleSection">
			<div className={classes.title}>
				{props.isHoist && <TrusstorIcon iconName={IconNames.elevator} />}
				<div className={classes.floorNickContainer}>
					<div className={classes.floorNick}>
						{areThereMultipleAreas ? props.floorData.floorNick : floorAreas[0]?.areaNick}
					</div>
					{areThereMultipleAreas && (
						<div className={classes.iconContainer}>
							<TrusstorIcon iconName={IconNames.floors} color={IconColor.Grey500} />
							<div className={classes.locationAmount}>{floorAreas.length}</div>
						</div>
					)}
				</div>
			</div>
			{isMovingFloor && (
				<div className={classes.hoistInfo}>
					<div className={classes.location}>
						<TrusstorIcon iconName={IconNames.pin} color={IconColor.Grey500} />
						<div>{props.floorData.matchingFixedFloorNick}</div>
					</div>
					<div className={classes.location}>
						{isActiveHoist ? (
							<LiveActionAnimatedIcon />
						) : (
							<TrusstorIcon iconName={IconNames.clock} color={IconColor.Grey500} />
						)}

						<div>{isActiveHoist ? translationService.get('activeHoist') : getSubtitleSecondPart()}</div>
					</div>
				</div>
			)}
		</div>
	);
};
