import React from 'react';
import classes from './styles.module.scss';
import { useSequenceItemProgressByLocationSummaryQuery } from '@src/hooks/queries/sequenceItemProgressSummary.queries.hooks';
import { getFormattedDate, getTimezoneFormattedDate } from '@utils/dateUtils';
import { selectProjectId, selectTimezone } from '@store/slices/project.slice';
import { useSelector } from 'react-redux';
import { Moment } from 'moment';
import { Loader } from '@shared/components/Loader/Loader';
import { translationService } from '@src/servicesInitializers';
import { TrusstorIcon } from '@src/Components/TrusstorIcon/TrusstorIcon';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { FloorProgressGraph } from '@src/Components/SequenceProgressAnalysis/FloorProgress/FloorProgressGraph/FloorProgressGraph';

interface IFloorProgressProps {
	startDate: Date;
	endDate: Date;
}

export const FloorProgress = (props: IFloorProgressProps) => {
	const projectId: string = useSelector(selectProjectId)!;
	const tz: string = useSelector(selectTimezone)!;

	const { sequenceItemProgressByLocationReport, isLoading } = useSequenceItemProgressByLocationSummaryQuery(
		projectId,
		getTimezoneFormattedDate(tz, props.startDate),
		getTimezoneFormattedDate(tz, props.endDate)
	);

	return (
		<div className={classes.FloorProgress_container} data-testid="FloorProgress">
			<div className={classes.titleWrapper}>
				<div className={classes.titleWithIcon}>
					<TrusstorIcon iconName={IconNames.floors} />
					<div className={classes.title}>{translationService.get('progressByFloor')}</div>
				</div>
				<div className={classes.legendWrapper}>
					<div className={classes.dot}></div>
					<div className={classes.legend}>{translationService.get('activitiesCompletedThisWeek')}</div>
				</div>
			</div>
			<div style={{ overflow: 'auto' }}>
				{isLoading ? (
					<Loader />
				) : (
					<FloorProgressGraph sequenceItemProgressByLocationReport={sequenceItemProgressByLocationReport} />
				)}
			</div>
		</div>
	);
};
