import React from 'react';
import { useSelector } from 'react-redux';
import { METRIC_PERIOD } from '@shared/constants/metric.constants';
import classes from './styles.module.scss';
import moment, { Moment } from 'moment-timezone';
import 'moment/locale/he';
import helpCircleIcon from '@assets/helpCircle.svg';
import { cM } from '@shared/utils/colors.utils';
import { translationService } from '../../../../../index';
import { selectTimezone } from '../../../../../store/slices/project.slice';

const EfficiencyHeader = () => {
	const isRtl: boolean = translationService.getIsRtl();
	const tz: string = useSelector(selectTimezone)!;

	const getDaysRange = (): string => {
		const yesterday: Moment = moment.tz(tz).subtract(1, 'days');
		const countStartDate: Moment = moment.tz(tz).subtract(METRIC_PERIOD + 1, 'days');
		const startDate: string = countStartDate.locale(isRtl ? 'he' : 'en').format('MMM D');
		const endDate: string = yesterday.locale(isRtl ? 'he' : 'en').format('MMM D YYYY');
		return `${startDate} - ${endDate}`;
	};

	return (
		<div className={classes.efficiencyHeader}>
			<div className={cM(classes.efficiencyText, classes)}>
				{translationService.get('efficiency')}
				{/*<img src={helpCircleIcon} alt="" className={classes.helpIcon} />*/}
			</div>
			<div className={classes.efficiencyDateRange}>
				<div className={cM(classes.lastDaysText, classes)}>
					{translationService.get('lastXDays', { days: String(METRIC_PERIOD) })}
				</div>
				<div className={cM(classes.rangeText, classes)}>{getDaysRange()}</div>
			</div>
		</div>
	);
};

export { EfficiencyHeader };
