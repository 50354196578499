import React from 'react';
import Chart from 'react-apexcharts';
import ApexCharts from 'apexcharts';
import { makeStyles } from '@material-ui/core/styles';
import { translationService } from '../../../../../index';
import { useLargeScreen } from '../../../../../hooks/custom.hooks';
import { convertDateObjectToServerFormat } from '../../../../../utils/dateUtils';
import { PROFESSION_REPORT_PAGE } from '../../../../../constants';
import {
	getApexTickAmountOption,
	getFirstDatePointToShow,
	getLastDatePointToShow,
} from '../../../../../utils/reports.utils';
import { IFirstLastWorkersHours } from '../../../../../interfaces/IFirstLastWorkersHours';
import moment from 'moment';
import { DIRECTIONS } from '@shared/constants/directions';

const useStyles = makeStyles({
	chartContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100%',
		width: '100%',
	},
});

interface IWorkersHoursPerDateGraphProps extends IFirstLastWorkersHours {}

const WorkersHoursPerDateGraph = (props: IWorkersHoursPerDateGraphProps) => {
	const options: ApexCharts.ApexOptions = {
		chart: {
			toolbar: {
				show: true,
				tools: {
					download: false,
					selection: false,
					zoom: false,
					zoomin: false,
					zoomout: false,
					pan: true,
					reset: false,
					customIcons: [],
				},
			},
		},
		colors: ['#2192fb', '#c7c7c7'],
		fill: {
			type: 'gradient',
			gradient: {
				type: 'vertical',
				shade: 'light',
				opacityFrom: 0,
				opacityTo: 0,
			},
		},
		markers: {
			size: 3,
		},
		dataLabels: {
			enabled: false,
		},
		stroke: {
			curve: 'straight',
			width: 2,
		},
		xaxis: {
			type: 'datetime',
			categories: props.firstWorkHoursLine.map((data) => data.label),
			tickAmount: getApexTickAmountOption(props.firstWorkHoursLine, PROFESSION_REPORT_PAGE.MAX_GRAPH_TICKS),
			tickPlacement: 'on',
			min: getFirstDatePointToShow(props.firstWorkHoursLine),
			max: getLastDatePointToShow(props.firstWorkHoursLine, PROFESSION_REPORT_PAGE.MAX_GRAPH_TICKS),
			axisTicks: {
				show: true,
				height: 10,
			},
			labels: {
				style: {
					fontSize: '10px',
					fontFamily: 'Heebo, sans-serif',
					fontWeight: 500,
					cssClass: 'apexcharts-xaxis-label',
				},
				formatter: (date: string) =>
					date ? convertDateObjectToServerFormat(new Date(date)).substring(0, 5) : '',
			},
			tooltip: {
				enabled: false,
			},
		},
		yaxis: {
			labels: {
				offsetX: translationService.getIsRtl() ? -25 : -9,
				style: {
					fontSize: '10px',
					fontFamily: 'Heebo, sans-serif',
					fontWeight: 500,
					cssClass: 'apexcharts-xaxis-label',
				},
				formatter: (hoursNumber: number) => moment(`${hoursNumber}:00`, 'H:mm').format('HH:mm'),
			},
		},
		tooltip: {
			marker: {
				show: false,
			},
		},
		legend: {
			position: 'top',
			horizontalAlign: translationService.getDirection() === DIRECTIONS.RTL ? 'right' : 'left',
			fontSize: '12.5px',
			fontFamily: 'Heebo, sans-serif',
			floating: true,
			offsetY: -5,
		},
	};

	const series = [
		{
			type: 'area',
			name: translationService.get('firstWorkerHour'),
			data: props.firstWorkHoursLine.map((data) => data.data),
		},
		{
			type: 'area',
			name: translationService.get('lastWorkerHour'),
			data: props.lastWorkHoursLine.map((data) => data.data),
		},
	];
	const isLargeScreen = useLargeScreen();
	const classes = useStyles();

	return (
		<div className={classes.chartContainer}>
			<Chart options={options} series={series} type="line" width={isLargeScreen ? 520 : 320} height="90%" />
		</div>
	);
};

export { WorkersHoursPerDateGraph };
