import { IssueStatus } from '@shared/interfaces/IIssueShared';
import {
	addSelectedIssueStatus,
	removeSelectedIssueStatus,
	resetSelectedIssueStatuses,
	resetSelectedIssueStatusesToDefault,
	selectSelectedIssueStatuses,
} from '@store/slices/issueStatuses.slice';
import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { APP_FILTER_TYPES } from '../../constants';
import { translationService } from '../../index';

import { ExpandableComponent } from '../MainPageSections/ExpandableComponent/ExpandableComponent';
import { getIssueStatusMainText } from '@shared/utils/issues.utils';
import { FilterCard } from '@shared/components/Filters/FilterCard/FilterCard';

interface IIssueStatusesFilterSectionProps {
	statusesCountObj?: { [issueStatusId: string]: number };
	defaultOpen?: boolean;
}

export const IssueStatusesFilterSection = (props: IIssueStatusesFilterSectionProps) => {
	const dispatch = useDispatch();

	const issueStatuses: IssueStatus[] = Object.values(IssueStatus);
	const selectedIssueStatuses: IssueStatus[] = useSelector(selectSelectedIssueStatuses);

	const checkShouldHideClearButton = (): boolean => {
		return selectedIssueStatuses.length === 0;
	};

	const handleResetIssueStatuses = (): void => {
		dispatch(resetSelectedIssueStatuses());
	};

	const addIssueStatus = (selectedIssueStatus: IssueStatus): void => {
		dispatch(addSelectedIssueStatus({ selectedIssueStatus }));
	};

	const removeIssueStatus = (removeIssueStatus: IssueStatus): void => {
		dispatch(removeSelectedIssueStatus({ removeIssueStatus }));
	};

	useEffect(() => {
		dispatch(resetSelectedIssueStatusesToDefault());
	}, []);

	return (
		<ExpandableComponent
			headerText={translationService.get('status')}
			childrenLength={issueStatuses.length}
			clearFunction={handleResetIssueStatuses}
			type={APP_FILTER_TYPES.issueStatuses}
			shouldHideClearButton={checkShouldHideClearButton()}
			clearLength={selectedIssueStatuses.length}
			hideEmptyList
			testId={'issueStatusesFilterSection'}
			defaultOpen={props.defaultOpen}
		>
			{issueStatuses.map((IssueStatus: IssueStatus) => {
				const isSelected: boolean = selectedIssueStatuses.includes(IssueStatus);
				return (
					<FilterCard
						key={IssueStatus}
						handleClick={
							isSelected ? () => removeIssueStatus(IssueStatus) : () => addIssueStatus(IssueStatus)
						}
						text={getIssueStatusMainText(IssueStatus, translationService)}
						isSelected={isSelected}
						count={props.statusesCountObj?.[IssueStatus]}
					/>
				);
			})}
		</ExpandableComponent>
	);
};
