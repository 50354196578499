import React from 'react';
import classes from './styles.module.scss';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { ITagRouteWithLocationData } from '@shared/interfaces/ITagRouteWithLocationData';
import { TIME_FORMAT_24 } from '@shared/constants/formats.constants';
import { IRootState } from '../../../../store/slices';
import { translationService } from '../../../../index';
import { Loader } from '@shared/components/Loader/Loader';
import { getHoursMinutesDiffFormat } from '@shared/utils/dateUtils';

interface ILocationHeaderProps {
	tagLocationData: ITagRouteWithLocationData | null;
	locationNumber: number;
	icon?: any;
}

const LocationHeader = (props: ILocationHeaderProps) => {
	const tz: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.tz);

	const getLocationDataMinutesDiff = (): string => {
		return getHoursMinutesDiffFormat(
			props.tagLocationData?.arrivalTime,
			props.tagLocationData?.departureTime,
			translationService
		);
	};

	const getLocationDataRangeString = (): string => {
		return `${moment.tz(props.tagLocationData!.arrivalTime, tz).format(TIME_FORMAT_24)}-${moment
			.tz(props.tagLocationData!.departureTime, tz)
			.format(TIME_FORMAT_24)}`;
	};

	if (!props.tagLocationData) {
		return <Loader />;
	}

	return (
		<section className={classes.locationHeaderContainer}>
			<div className={`${classes.grayText} ${classes.locationIndexText}`}>
				{translationService.get('location').toUpperCase()} {props.locationNumber}
			</div>
			<div className={classes.locationInfo}>
				<div className={classes.iconAndTitle}>
					{props.icon && (
						<div className={classes.icon}>
							<img src={props.icon} alt="" />
						</div>
					)}
					<div>
						<div className={classes.titleText}>{props.tagLocationData.floorNick}</div>
						<div className={classes.grayText}>{props.tagLocationData.areaNick}</div>
					</div>
				</div>
				<div>
					<div className={`${classes.titleText} ${classes.totalMinText}`}>{getLocationDataMinutesDiff()}</div>
					<div className={classes.grayText}>{getLocationDataRangeString()}</div>
				</div>
			</div>
		</section>
	);
};

export { LocationHeader };
