import { IProductivityReportReducerState } from '../../interfaces/IProductivityReportReducerState';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IProductivityReport } from '../../interfaces/IProductivityReport';
import { getRoundedProductivityReport } from '../../utils/roundedData.utils';

const initialState: IProductivityReportReducerState = {
	roundedProductivityReport: {},
};

const productivityReportSlice = createSlice({
	name: 'productivityReport',
	initialState,
	reducers: {
		updateProductivityReport: (
			state: IProductivityReportReducerState,
			action: PayloadAction<{ report: IProductivityReport }>
		) => {
			state.roundedProductivityReport = getRoundedProductivityReport(action.payload.report);
		},
		resetProductivityReport: (state: IProductivityReportReducerState) => {
			state.roundedProductivityReport = {};
		},
	},
});

export const { updateProductivityReport, resetProductivityReport } = productivityReportSlice.actions;
export default productivityReportSlice.reducer;
