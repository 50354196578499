import React, { useState } from 'react';
import classes from './styles.module.scss';
import { requestService, translationService } from '@src/servicesInitializers';
import { GeneralDialog } from '@src/Components/GeneralDialog/GeneralDialog';
import { IAreaSequenceItem } from '@shared/interfaces/IAreaSequenceItem';
import { IProfession } from '@shared/interfaces/IProfession';
import { IConfigArea } from '@shared/interfaces/IConfigArea';
import { IssueCreateCard } from '@src/Components/Pages/IssuesPage/IssueCreateCard/IssueCreateCard';
import { IUser } from '@shared/interfaces/IUser';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoggedUserDetails } from '@store/slices/login.slice';
import { ICreateIssue, IIssue } from '@shared/interfaces/IIssue';
import { createIssueApi } from '@src/apis/issues.api';
import { ICommentShared } from '@shared/interfaces/ICommentShared';
import { addImagesToIssue } from '@shared/utils/issues.utils';
import { createIssue_BI } from '@utils/bi.utils';
import { errorSnackbar } from '@utils/snackbar.utils';
import { AsiReviewDialogContent } from '@shared/components/ASIReviewDialogContent/ASIReviewDialogContent';

interface IAsiReviewDialogProps {
	profession: IProfession;
	projectId: string;
	area: IConfigArea;
	sequenceItemDescription: string;
	show: boolean;
	onClose: () => void;
	onRejectSubmit: () => void;
	onAcceptSubmit: () => void;
	onCreateIssue?: (issue: IIssue) => void;
	asi: IAreaSequenceItem;
}

export const AsiReviewDialog = (props: IAsiReviewDialogProps) => {
	const user: IUser = useSelector(selectLoggedUserDetails)!;
	const dispatch = useDispatch();
	const area: IConfigArea = props.area;
	const [images, setImages] = useState<File[]>([]);
	const defaultIssue: Partial<ICreateIssue> = {
		projectId: props.projectId,
		locations: [
			{
				area: { areaId: area.areaId, areaNick: area.areaNick },
				floor: {
					floorId: area.floorId,
					floorNick: area.floorNick,
				},
			},
		],
		profession: props.profession,
		linkedAreaSequenceItemId: props.asi._id,
		linkedActivityGroupId: props.asi.activityGroupId,
	};
	const [createIssue, setCreateIssue] = useState<Partial<ICreateIssue> | ICreateIssue>(defaultIssue);
	const [isRejectedClicked, setIsRejectedClicked] = useState<boolean>(false);
	const [isApprovedClicked, setIsApprovedClicked] = useState<boolean>(false);

	const onCreateIssue = async () => {
		if (createIssue) {
			try {
				const newIssue: IIssue = await createIssueApi(createIssue as ICreateIssue);
				const comments: ICommentShared[] = await addImagesToIssue(newIssue._id, user, images, requestService);
				const newCreatedIssue: IIssue = { ...newIssue, comments };
				props.onCreateIssue?.(newCreatedIssue);
				createIssue_BI(newCreatedIssue);
			} catch (e) {
				errorSnackbar(dispatch, translationService.get('failedToCreateIssue'));
				console.log('error creating issue', e);
			}
		}
	};

	const onIssueUpdate = (issue: ICreateIssue | undefined, images: File[]) => {
		setImages(images);
		setCreateIssue({
			...defaultIssue,
			...issue,
		});
	};

	const isSubmitDisabled = (() => {
		if (isRejectedClicked) {
			return !createIssue?.description;
		}
		return !isApprovedClicked;
	})();

	const handleSubmit = () => {
		if (isApprovedClicked) {
			return props.onAcceptSubmit();
		}
		onCreateIssue();
		props.onRejectSubmit();
	};

	const handleRejectClick = () => {
		setIsRejectedClicked((prev) => !prev);
		setIsApprovedClicked(false);
	};

	const handleApproveClick = () => {
		setIsApprovedClicked((prev) => !prev);
		setIsRejectedClicked(false);
	};
	return (
		<GeneralDialog
			title={translationService.get('asiReviewDialog_title')}
			show={props.show}
			close={props.onClose}
			mainButton={{
				text: translationService.get('asiReviewDialog_submitReview'),
				click: handleSubmit,
				disabled: isSubmitDisabled,
			}}
			secondaryButton={{
				text: translationService.get('cancel'),
				click: props.onClose,
			}}
		>
			<AsiReviewDialogContent
				projectId={props.projectId}
				area={props.area}
				sequenceItemDescription={props.sequenceItemDescription}
				asi={props.asi}
				handleRejectClick={handleRejectClick}
				handleApproveClick={handleApproveClick}
				isRejectedClicked={isRejectedClicked}
				isApprovedClicked={isApprovedClicked}
				profession={props.profession}
			/>

			{isRejectedClicked && (
				<div className={classes.issueContainer}>
					<div className={classes.issueOpenText}>
						{translationService.get('asiReviewDialog_openIssueTitle')}
					</div>
					<div className={classes.issueCard}>
						<IssueCreateCard
							hideProfession
							hideLocations
							hidePrivateSection
							onIssueUpdate={onIssueUpdate}
							defaultAreas={[area]}
							defaultProfession={props.profession}
						/>
					</div>
				</div>
			)}
		</GeneralDialog>
	);
};
