import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { find, uniqBy } from 'lodash';
import { IFloorsViewReducerState } from '../../interfaces/IFloorsViewReducerState';
import { IMergedFloorView } from '../../interfaces';

const initialState: IFloorsViewReducerState = {
	floors: [],
	selectedFloor: undefined,
};

const floorsViewSlice = createSlice({
	name: 'floorsView',
	initialState,
	reducers: {
		updateFloorsView: (
			state: IFloorsViewReducerState,
			action: PayloadAction<{
				floors: IMergedFloorView[];
				shouldReplace: boolean;
			}>
		) => {
			state.floors = action.payload.shouldReplace
				? action.payload.floors
				: uniqBy([...action.payload.floors, ...state.floors], 'floorId');
			state.selectedFloor = !state.selectedFloor
				? undefined
				: find(state.floors, { floorId: state.selectedFloor.floorId });
		},
		selectFloor: (state: IFloorsViewReducerState, action: PayloadAction<{ floorId: string }>) => {
			state.selectedFloor = state.floors.find((stateFloor) => action.payload.floorId === stateFloor.floorId);
		},
		resetSelectedFloor: (state: IFloorsViewReducerState) => {
			state.selectedFloor = undefined;
		},
		resetFloorsView: (state: IFloorsViewReducerState) => {
			state.floors = [];
			state.selectedFloor = undefined;
		},
	},
});

export const { resetFloorsView, resetSelectedFloor, selectFloor, updateFloorsView } = floorsViewSlice.actions;
export default floorsViewSlice.reducer;
