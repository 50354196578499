import { FeatureFlagNamesEnum } from '@shared/constants/featureFlags.enum';
import { useIsFeatureFlagEnabled } from '@shared/hooks/useFeatureFlag';
import { selectProjectId } from '@store/slices/project.slice';
import { useSelector } from 'react-redux';
import { useProjectMilestonesQuery } from './queries/milestones.queries.hooks';

export const useIsProgressAnalysisV2 = (): boolean => {
	const isProgressAnalysisV2FeatureFlag: boolean = useIsFeatureFlagEnabled(
		FeatureFlagNamesEnum.useProgressAnalysisV2
	);
	const projectId: string = useSelector(selectProjectId)!;
	const { projectMilestones } = useProjectMilestonesQuery(projectId);

	return isProgressAnalysisV2FeatureFlag && !!projectMilestones.length;
};
