export enum FeatureFlagNamesEnum {
	scissorLiftAnalysis = 'use-scissor-lift-analysis',
	useMobileMonitor = 'use_mobile_monitor',
	milestonesManagementPage = 'milestones_management_page',
	sequenceProgressAnalysis = 'sequence_progress_analysis',
	useTimesheetV2Api = 'use_timesheet_v2_api',
	useDailyProductivityReportV2Api = 'use_daily_productivity_report_v2_api',
	useActivitiesChips = 'use_activities_chips',
	useIssuesChips = 'use_issues_chips',
	usePushNotifications = 'use_push_notifications',
	useProgressAnalysisV2 = 'use_progress_analysis_v2',
	useActivitiesTabV2Mobile = 'use_activities_tab_v2_mobile',
	progressTrackerZoomController = 'progressTracker_zoomController',
	contractorAnalysisV2 = 'use_contractor_analysis_V2',
	displayDatesOnProgressTrackerCells = 'display_dates_on_progress_tracker_cells',
	issuesDragAndDrop = 'issues_drag_and_drop',
}
