import { deleteProfession } from '@store/thunks/professions.thunks';
import React, { useEffect, useState } from 'react';
import { translationService } from '../../../../index';
import { GeneralDialog } from '../../../GeneralDialog/GeneralDialog';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../../../store/slices';
import { updateProfession } from '../../../../store/thunks/professions.thunks';
import { IProfession, IProject } from '../../../../interfaces';
import { ColorPicker } from '../../../Inputs/ColorPicker/ColorPicker';
import classes from './styles.module.scss';
import { updateProfession_BI } from '../../../../utils/bi.utils';
import { getProfessionBackgroundColor } from '@shared/utils/professions.utils';
import { selectProjectId } from '@store/slices/project.slice';
import { trashIconBlack } from '@assets/trashIcons';
import {
	getDoesProfessionHasActivitiesAssigned,
	getDoesProfessionHasTagsAssigned,
	getProjectsByOrganizationId,
} from '@src/Components/ProfessionsListComponents/professions.utils';
import { errorSnackbar, successSnackbar } from '@utils/snackbar.utils';
import { TrusstorTextInput } from '@shared/components/Inputs/TrusstorTextInput/TrusstorTextInput';
import { ProfessionDisplaySettingsPicker } from './ProfessionDisplaySettingsPicker/ProfessionDisplaySettingsPicker';
import { IProfessionDisplaySettings } from '@shared/interfaces/IProfessionDisplaySettings';
import { isEqual } from 'lodash';
import { getTradeTranslation } from '@utils/translations.utils';

interface ICreateProfessionProps {
	close: () => void;
	profession: IProfession;
}

const EditProfessionDialog = (props: ICreateProfessionProps) => {
	const workingProject: IProject = useSelector((state: IRootState) => state.projectReducer.workingProject)!;
	const projectId: string = useSelector(selectProjectId)!;
	const [professionInput, setProfessionInput] = useState<IProfession>(props.profession);
	const currentProjectLanguage: string = useSelector(
		(state: IRootState) => state.projectReducer.workingProject!.language
	);
	const dispatch = useDispatch();

	useEffect(() => {
		setProfessionInput(props.profession);
	}, []);

	const handleSubmitButtonClick = (): void => {
		dispatch(updateProfession(professionInput));
		updateProfession_BI(professionInput, getProfessionBackgroundColor(props.profession, projectId));
		props.close();
	};

	const changeColorFunction = (color: string): void => {
		setProfessionInput(
			(prevState) =>
				({
					...prevState,
					activeProjects: {
						...professionInput.activeProjects,
						[projectId]: {
							...professionInput.activeProjects[projectId],
							backgroundColor: color,
						},
					},
				} as IProfession)
		);
	};

	const getDeleteProfessionErrorMessage = (
		professionHasTagsAssignedInOrg: boolean,
		professionHasTasksAssignedInOrg: boolean
	): string => {
		if (professionHasTagsAssignedInOrg) {
			if (professionHasTasksAssignedInOrg) {
				return translationService.get('professionHasTagsAndTasksAssignedInOrg');
			}
			return translationService.get('professionHasTagsAssignedInOrg');
		}
		return translationService.get('professionHasTasksAssignedInOrg');
	};

	const getCanProfessionDeleted = async () => {
		let professionHasTagsAssignedInOrg = false;
		let professionHasTasksAssignedInOrg = false;
		const projects: IProject[] = await getProjectsByOrganizationId(workingProject.organizationId);
		await Promise.all(
			projects.map(async (project) => {
				const projectHasTagsAssigned = await getDoesProfessionHasTagsAssigned(
					professionInput,
					project.projectId
				);
				const projectHasTasksAssigned = await getDoesProfessionHasActivitiesAssigned(
					professionInput,
					project.tz,
					project.projectId
				);
				if (projectHasTagsAssigned) {
					professionHasTagsAssignedInOrg = true;
				}
				if (projectHasTasksAssigned) {
					professionHasTasksAssignedInOrg = true;
				}
			})
		);
		if (professionHasTagsAssignedInOrg || professionHasTasksAssignedInOrg) {
			const errorMessage: string = getDeleteProfessionErrorMessage(
				professionHasTagsAssignedInOrg,
				professionHasTasksAssignedInOrg
			);
			errorSnackbar(dispatch, errorMessage);
			return false;
		}
		return true;
	};

	const dealWithDeleteProfession = async () => {
		const canProfessionDeleted: boolean = await getCanProfessionDeleted();
		if (!canProfessionDeleted) {
			return;
		}
		dispatch(deleteProfession(professionInput._id));
		successSnackbar(dispatch, translationService.get('professionDeletedSuccessfully'));
		props.close();
	};

	const handleDisplaySettingsChange = (displaySettings: IProfessionDisplaySettings): void => {
		setProfessionInput((prevState) => ({
			...prevState,
			activeProjects: {
				...professionInput.activeProjects,
				[projectId]: {
					...((professionInput.backgroundColor && {
						backgroundColor: professionInput.backgroundColor,
					}) ||
						{}),
					...professionInput.activeProjects[projectId],
					displaySettings: displaySettings,
				},
			},
		}));
	};

	const isUpdateDisabled = (): boolean => {
		const didColorUpdate: boolean =
			getProfessionBackgroundColor(professionInput, projectId) !==
			getProfessionBackgroundColor(props.profession, projectId);
		const didDisplaySettingsUpdate: boolean = !isEqual(
			professionInput.activeProjects[projectId]?.displaySettings,
			props.profession.activeProjects[projectId]?.displaySettings
		);
		return !didColorUpdate && !didDisplaySettingsUpdate;
	};

	return (
		<GeneralDialog
			show={true}
			close={() => {
				props.close();
			}}
			title={translationService.get('editProfession')}
			mainButton={{
				text: translationService.get('update'),
				click: handleSubmitButtonClick,
				disabled: isUpdateDisabled(),
			}}
			tertiaryButton={{
				text: translationService.get('delete'),
				click: dealWithDeleteProfession,
				icon: trashIconBlack,
			}}
		>
			<Grid container direction="column" spacing={1}>
				<Grid item className={classes.dialogItem}>
					<TrusstorTextInput
						disabled
						value={getTradeTranslation(professionInput) || ''}
						label={translationService.get('trade')}
					/>
				</Grid>
				<Grid item className={classes.dialogItem}>
					<TrusstorTextInput
						disabled
						value={professionInput?.specialty || ''}
						label={translationService.get('specialty')}
					/>
				</Grid>
				<Grid item className={classes.dialogItem}>
					<TrusstorTextInput
						disabled
						value={professionInput?.contractor || ''}
						label={translationService.get('contractor')}
					/>
				</Grid>
				<Grid item className={classes.dialogItem}>
					<ProfessionDisplaySettingsPicker
						profession={professionInput}
						projectId={projectId}
						onDisplaySettingsChange={handleDisplaySettingsChange}
					/>
				</Grid>
				<Grid item className={classes.dialogItem}>
					<ColorPicker
						changeColorFunction={changeColorFunction}
						chosenColor={getProfessionBackgroundColor(professionInput, projectId)}
						title={translationService.get('professionsColor')}
					/>
				</Grid>
			</Grid>
		</GeneralDialog>
	);
};

export { EditProfessionDialog };
