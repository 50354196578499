import classnames from 'classnames';
import React from 'react';
import classes from './styles.module.scss';
import { EquipmentIcon } from '@src/Components/Equipment/EquipmentIcon';
import { IUtilityMergedTag } from '@interfaces/IUtilityMergedTag';
import { translationService } from '@src/servicesInitializers';
import { UtilityTypes } from '@interfaces/UtilityTypes.enum';
import { ProfessionDisplayWithTradeIcon } from '@shared/components/ProfessionDisplayWithTradeIcon/ProfessionDisplayWithTradeIcon';
import { IProfession } from '@shared/interfaces/IProfession';
import { selectProjectId } from '@store/slices/project.slice';
import { useSelector } from 'react-redux';
import { isScissorLiftLive } from '@utils/scissorLift.utils';
import { IconColor, IconSize } from '@shared/components/TrusstorIconShared/TrusstorIconShared';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { TrusstorIcon } from '@src/Components/TrusstorIcon/TrusstorIcon';
import { LiveActionAnimatedIcon } from '@src/Components/LiveActionAnimatedIcon/LiveActionAnimatedIcon';

interface IFloorUtilitiesSectionProps {
	utilities: IUtilityMergedTag[];
	type: UtilityTypes;
	profession?: IProfession;
	getUtilityName: (utility: IUtilityMergedTag) => string;
}

export const FloorUtilitiesSection = (props: IFloorUtilitiesSectionProps) => {
	const projectId: string = useSelector(selectProjectId)!;
	if (!props.utilities.length) {
		return null;
	}
	return (
		<div className={classes.wrapper}>
			<div className={classes.wr}>
				<div className={classes.title}>
					<EquipmentIcon type={props.type} />
					<div>
						{props.profession ? (
							<ProfessionDisplayWithTradeIcon profession={props.profession} projectId={projectId} />
						) : (
							translationService.get(props.type)
						)}
					</div>
				</div>
				<div>{props.utilities.length}</div>
			</div>
			<div className={classes.tagsSection}>
				{props.utilities.map((utility, index) => {
					const shouldShowLiveIcon: boolean =
						props.type === UtilityTypes.scissorsLift && isScissorLiftLive(utility);
					return (
						<div
							className={classnames(classes.tag, {
								[classes.hideBorder]: index === props.utilities.length - 1,
							})}
						>
							<div className={classes.nameContainer}>
								{shouldShowLiveIcon && <LiveActionAnimatedIcon />}
								<div>{props.getUtilityName(utility)}</div>
							</div>
							<div>{utility.tagNick}</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};
