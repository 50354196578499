export enum COLORS {
	transparent = '#00000000',
	grey800 = '#273547', // dark theme special color

	primaryColor = '#212a35',

	darkBlue = '#212a35',
	white = '#ffffff',
	black = '#000000',
	blue100 = '#eaf3fb',
	blue200 = '#68e4ff',
	blue300 = '#2ebffd',
	brightTurquoise = '#2cffcb',
	yellow = '#ffc700',
	grey50 = '#edf2f3',
	grey100 = '#e7e9eb',
	grey200 = '#cfd2d6',
	grey300 = '#b7bcc2',
	grey400 = '#878f99',
	grey500 = '#6f7985',
	grey600 = '#576270',
	grey700 = '#384758',

	// Feedback colors
	green100 = '#cdf3cd',
	green300 = '#a2e7a2',
	green400 = '#73d973',
	green600 = '#7aa418',

	yellow100 = '#fff9d9',
	yellow300 = '#fff3b2',
	yellow500 = '#ffe658',
	yellow600 = '#f9bc15',

	feedbackHighDefault = '#FEB953',
	feedbackPositiveDark = '#7AA418',

	red100 = '#ffdbd9',
	red300 = '#ffcac7',
	red400 = '#ff837a',
	red600 = '#e23749',

	spaceCadet = '#4d0068',
}

export enum MILESTONE_COLORS {
	late = '#CF3126',
	minorDelay = '#F46E0A',
	onTrack = '#7AA41A',
	noDataAvailable = '#576270',
}
