import { findIndex } from 'lodash';
import moment, { MomentInput } from 'moment-timezone';

interface IGetLocationIndexInvestigated {
	arrivalTime: MomentInput;
	departureTime: MomentInput;
}

export const getLocationIndexInvestigated = <T extends IGetLocationIndexInvestigated>(
	tz,
	investigatedTimestamp,
	tagRouteData: T[]
): number =>
	findIndex(
		tagRouteData,
		(data) =>
			moment.tz(data.arrivalTime, tz).isBefore(investigatedTimestamp) &&
			moment.tz(data.departureTime, tz).isAfter(investigatedTimestamp)
	);
