import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ITrusstorButtonType, TrusstorButton } from '@shared/components/buttons/TrusstorButton/TrusstorButton';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { TrusstorIcon } from '@src/Components/TrusstorIcon/TrusstorIcon';
import { translationService } from '@src/servicesInitializers';
import classes from './styles.module.scss';
import { SIDE_MAIN_COMPONENT_CLASS_NAME } from '@src/constants';
import { useDispatch, useSelector } from 'react-redux';
import { selectProjectId, selectWorkingProject } from '@store/slices/project.slice';
import {
	useIssuesSortingSettingsQuery,
	useProjectIssuesQuery,
	useUpdateIssuesSortingSettingsMutation,
} from '@src/hooks/queries/issues.queries.hooks';
import { Dictionary, groupBy, isEqual, sumBy, uniq } from 'lodash';
import { IIssue } from '@shared/interfaces/IIssue';
import { IssuesColumn } from './IssuesColumn/IssuesColumn';
import { exportIssuesToExcel } from './issuesPage.utils';
import { IssuesFilterSection } from './IssuesFilterSection/IssuesFilterSection';
import { IRootState } from '@store/slices';
import { IManager, IProfession, IProject, IUser } from '@interfaces/index';
import { IssuePriority, IssueStatus } from '@shared/interfaces/IIssueShared';
import { selectSelectedIssuePriorities } from '@store/slices/issuePriorities.slice';
import { selectSelectedIssueStatuses } from '@store/slices/issueStatuses.slice';
import { filterIssues, getSortedKeysOfIssuesColumns } from '@shared/utils/issues.utils';
import { CreateNewIssueShortcut } from '@src/Components/Pages/IssuesPage/CreateNewIssueShortcut/CreateNewIssueShortcut';
import { ExportSection } from '@src/Components/Pages/Workplan/ProgressTrackerTab/ExportSection/ExportSection';
import { IssueCreateDialog } from '@src/Components/IssueCreateDialog/IssueCreateDialog';
import { IssueDesktopEditDialog } from '@src/Components/Pages/IssuesPage/IssueDesktopEditDialog/IssueDesktopEditDialog';
import { useProjectAreasQuery } from '@src/hooks/queries/areas.queries.hooks';
import { IConfigArea } from '@shared/interfaces/IConfigArea';
import { ToggleSection } from '../../WorkPlan/ToggleSection';
import { IssuesPageViewModes, ViewModeToSortingKey } from '@shared/constants/issues.contants';
import { issuesPageToggleProfessionsManagers_BI } from '@utils/bi.utils';
import { getUsers } from '@store/thunks';
import { selectSelectedUsers } from '@store/slices/users.slice';
import { useIssuesCrud } from '@src/hooks/issues.hooks';
import { useIsNarrowLayout } from '@src/hooks/custom.hooks';
import { useRenderMonitoring } from '@shared/hooks/monitoring.hooks';
import { selectIsLimitedUser, selectLoggedUserDetails } from '@store/slices/login.slice';
import { Loader } from '@shared/components/Loader/Loader';
import { IBaseAreaBaseFloor } from '@shared/interfaces/IBaseAreaBaseFloor';

const IssuesPage = () => {
	useRenderMonitoring('issuesPage');
	const dispatch = useDispatch();
	const { issueId: issueIdFromParams }: { issueId: string } = useParams();
	const project: IProject = useSelector(selectWorkingProject)!;
	const projectId: string = useSelector(selectProjectId)!;
	const selectedProfessions: IProfession[] = useSelector(
		(state: IRootState) => state.professionsReducer.selectedProfessions
	);
	const selectedManagers: IManager[] = useSelector((state: IRootState) => state.managersReducer.selectedManagers);
	const selectedAreas: IBaseAreaBaseFloor[] = useSelector(
		(state: IRootState) => state.areasListReducer.areasFilterList
	);
	const selectedPriorities: IssuePriority[] = useSelector(selectSelectedIssuePriorities);
	const selectedStatuses: IssueStatus[] = useSelector(selectSelectedIssueStatuses);
	const selectedUsers: IUser[] = useSelector(selectSelectedUsers);
	const loggedUser: IUser = useSelector(selectLoggedUserDetails)!;
	const [showCreateDialog, setShowCreateDialog] = useState<boolean>(false);
	const { sortingSettings, isIssuesSortingSettingsLoading } = useIssuesSortingSettingsQuery(project.projectId);
	const { issues: fetchedIssues } = useProjectIssuesQuery(project.projectId, { username: loggedUser.username });
	const [issues, setIssues] = useState<IIssue[]>(fetchedIssues || []);
	const [issueToEdit, setIssueToEdit] = useState<IIssue>();
	const [issuesToDisplay, setIssuesToDisplay] = React.useState<Dictionary<IIssue[]>>({});
	const projectAreas: IConfigArea[] = useProjectAreasQuery(projectId);
	const [viewMode, setViewMode] = useState<IssuesPageViewModes>(IssuesPageViewModes.PROFESSIONS);
	const isProfessionViewMode: boolean = viewMode === IssuesPageViewModes.PROFESSIONS;
	const [sortedIssuesToDisplayKeys, setSortedIssuesToDisplayKeys] = React.useState<string[]>([]);
	const numberOfIssuesToDisplay: number = sumBy(Object.values(issuesToDisplay), 'length');
	const [showSmallColumns, setShowSmallColumns] = useState<boolean>(false);
	const isNarrowLayout: boolean = useIsNarrowLayout();
	const draggingFromIndex: React.MutableRefObject<null | number> = useRef(null);
	const { updateIssue, deleteIssue } = useIssuesCrud(setIssues, issues);

	const updateIssuesSortingSettingsMutation = useUpdateIssuesSortingSettingsMutation(projectId);

	useEffect(() => {
		const columnsNumber: number = Object.keys(issuesToDisplay).length;
		if (isNarrowLayout) {
			setShowSmallColumns(columnsNumber > 4);
		} else {
			setShowSmallColumns(columnsNumber > 5);
		}
	}, [isNarrowLayout, issuesToDisplay]);

	useEffect(() => {
		dispatch(getUsers(projectId));
	}, []);

	useLayoutEffect(() => {
		if (!fetchedIssues) {
			return undefined;
		}

		setIssues(fetchedIssues);
	}, [fetchedIssues]);

	useLayoutEffect(() => {
		if (!issues) {
			return undefined;
		}

		const filteredIssues: IIssue[] = getFilteredIssues(issues);
		const groupedIssuesByColumns: Dictionary<IIssue[]> = groupBy(filteredIssues, (issue) => {
			return isProfessionViewMode ? issue.profession?._id : issue.assignee?.username;
		});
		setIssuesToDisplay(groupedIssuesByColumns);
		setSortedColumnKeys(issues);
	}, [
		issueIdFromParams,
		issues,
		selectedProfessions.length,
		selectedManagers.length,
		selectedAreas.length,
		selectedPriorities.length,
		selectedStatuses.length,
		selectedUsers.length,
		isProfessionViewMode,
		sortingSettings,
	]);

	const setSortedColumnKeys = (issues: IIssue[]): void => {
		if (!issues.length) {
			return undefined;
		}

		const sortedKeys: string[] = getSortedKeysOfIssuesColumns(issues, viewMode, sortingSettings);
		setSortedIssuesToDisplayKeys(sortedKeys);

		if (isEqual(sortedKeys, sortingSettings?.[ViewModeToSortingKey[viewMode]])) {
			return;
		}
		updateIssuesSortingSetting(sortedKeys);
	};

	const onColumnDragStart = (index: number) => {
		draggingFromIndex.current = index;
	};

	const onColumnDragFinish = (indexDropped: number) => {
		const indexDragged: number | null = draggingFromIndex.current;
		draggingFromIndex.current = null;

		if (indexDragged === indexDropped) {
			return;
		}
		updateIssuesSortingSetting(sortedIssuesToDisplayKeys);
	};

	const moveColumn = (dragIndex: number, hoverIndex: number) => {
		setSortedIssuesToDisplayKeys((prevSortedKeys) => {
			const dragElement = prevSortedKeys[dragIndex];
			const newSortedKeys = [...prevSortedKeys];
			newSortedKeys.splice(dragIndex, 1);
			newSortedKeys.splice(hoverIndex, 0, dragElement);
			return newSortedKeys;
		});
	};

	const updateIssuesSortingSetting = (sortedKeys: string[]) => {
		updateIssuesSortingSettingsMutation.mutate({
			[ViewModeToSortingKey[viewMode]]: sortedKeys,
		});
	};
	const getFilteredIssues = (issuesToFilter: IIssue[]): IIssue[] => {
		const issuesWithoutInitial: IIssue[] = issuesToFilter.filter((issue) => !issue.isInitial) || [];
		const finalIssues: IIssue[] = issuesWithoutInitial.length > 0 ? issuesWithoutInitial : issuesToFilter || [];
		const issueFromParams: IIssue | undefined = finalIssues.find((issue) => issue._id === issueIdFromParams);
		return filterIssues({
			issues: finalIssues,
			selectedProfessions,
			selectedUsers,
			selectedAreas,
			selectedPriorities,
			selectedStatuses,
			issuesToInclude: issueFromParams ? [issueFromParams] : [],
		});
	};

	const onIssueColumnCreate = (issue: IIssue) => {
		setIssues((currentIssues) => [...currentIssues!, issue]);
	};

	const onCloseCreateDialog = () => {
		setShowCreateDialog(false);
	};

	const onCreateIssue = async (createdIssue: IIssue) => {
		setShowCreateDialog(false);
		setIssues((currentIssues) => [...currentIssues!, createdIssue]);
	};

	const closeEditDesktopDialog = () => {
		setIssueToEdit(undefined);
	};
	const isLimitedUser: boolean = useSelector(selectIsLimitedUser);
	const isOnlyInitialIssue: boolean = !!issuesToDisplay['undefined']?.[0]?.isInitial;

	if (isIssuesSortingSettingsLoading) {
		return <Loader />;
	}

	return (
		<div className={classes.issuesContainer}>
			<div className={classes.header}>
				<div className={classes.headerText}>
					{translationService.get('issues')} ({numberOfIssuesToDisplay})
				</div>
				<div>
					<ToggleSection
						value={IssuesPageViewModes.PROFESSIONS}
						firstOption={IssuesPageViewModes.PROFESSIONS}
						secondOption={IssuesPageViewModes.ASSIGNEES}
						firstAction={() => setViewMode(IssuesPageViewModes.PROFESSIONS)}
						secondAction={() => setViewMode(IssuesPageViewModes.ASSIGNEES)}
						biEventOnClick={issuesPageToggleProfessionsManagers_BI}
						height="auto"
					/>
				</div>
				<div className={classes.actionButtonsSection}>
					{!isLimitedUser && (
						<TrusstorButton
							text={translationService.get('createIssue')}
							iconElement={<TrusstorIcon iconName={IconNames.plus} />}
							buttonType={ITrusstorButtonType.GHOST}
							className={classes.button}
							handleClick={() => {
								setShowCreateDialog(true);
							}}
						/>
					)}
					<ExportSection
						onExport={async () =>
							exportIssuesToExcel(issuesToDisplay, sortedIssuesToDisplayKeys, project, viewMode)
						}
					/>
				</div>
			</div>
			<div className={classes.issuesBody}>
				<div className={`${SIDE_MAIN_COMPONENT_CLASS_NAME}`}>
					<IssuesFilterSection issues={issues} />
				</div>
				<div className={classes.issuesBoard}>
					{sortedIssuesToDisplayKeys
						? sortedIssuesToDisplayKeys.map((columnKey: string, index) => {
								if (!issuesToDisplay[columnKey]?.length) {
									return null;
								}

								return (
									<IssuesColumn
										index={index}
										viewMode={viewMode}
										isEditIssueDialogShown={!!issueToEdit}
										setIssueToEdit={setIssueToEdit}
										deleteIssue={deleteIssue}
										key={`${columnKey}-${viewMode}`}
										issues={issuesToDisplay[columnKey]}
										onIssueCreate={onIssueColumnCreate}
										updateIssue={updateIssue}
										useSmallColumnWidth={showSmallColumns}
										onDragFinish={onColumnDragFinish}
										onDragStart={onColumnDragStart}
										moveColumn={moveColumn}
									/>
								);
							})
						: null}
					{isOnlyInitialIssue && (
						<CreateNewIssueShortcut setShowCreateIssue={() => setShowCreateDialog(true)} />
					)}
				</div>
			</div>
			{!!issueToEdit && (
				<IssueDesktopEditDialog
					projectAreas={projectAreas}
					closeDialog={closeEditDesktopDialog}
					issue={issueToEdit}
					updateIssue={updateIssue}
					translationService={translationService}
				/>
			)}
			{showCreateDialog && (
				<IssueCreateDialog
					showCreateDialog={showCreateDialog}
					onClose={onCloseCreateDialog}
					onCreateIssue={onCreateIssue}
				/>
			)}
		</div>
	);
};

export { IssuesPage };
