import { IFullAreaViewData, IFullMergedFloorViewDataWithTime } from '../interfaces/IFullMergedFloorsData';
import { IMergedTag } from '../interfaces/IMergedTag';
import { IMinimizedProfession } from '../interfaces/IMinimizedProfession';
// @ts-ignore
import { orderBy, flatMap, groupBy, Dictionary } from 'lodash';
import { IProfession } from '../interfaces/IProfession';
import { ITagWorkPeriods } from '../interfaces/ITagWorkPeriods';
import { IWorkPeriod } from '../interfaces/IWorkPeriod';

export type IMergedTagWithFloorDataIndex = IMergedTag<IProfession | IMinimizedProfession> & {
	index: number;
};

export const getTagsWorkPeriodsFromMergedFloors = (
	mergedFloors: IFullMergedFloorViewDataWithTime[],
	areaIds?: string[]
): ITagWorkPeriods[] => {
	const sortedMergedFloorsByTimestamp: IFullMergedFloorViewDataWithTime[] = orderBy(
		mergedFloors,
		(floor) => floor.timestamp,
		'asc'
	);

	const sortedTimestamps: number[] = sortedMergedFloorsByTimestamp.map((floor) =>
		new Date(floor.timestamp).getTime()
	);

	const mergedTagsWithSortedFloorIndex: IMergedTagWithFloorDataIndex[] = flatMap(
		sortedMergedFloorsByTimestamp.map(
			(mergedFloorWithTimestamp: IFullMergedFloorViewDataWithTime, index: number) => {
				const relevantAreas: IFullAreaViewData[] = !areaIds?.length
					? mergedFloorWithTimestamp.areas
					: mergedFloorWithTimestamp.areas.filter((area) => areaIds.includes(area.areaId));
				const mergedTagsFromAreas: IMergedTag<IMinimizedProfession | IProfession>[] = flatMap(
					relevantAreas.map((area) => area.tags)
				);
				const tagsWithFloorIndex: IMergedTagWithFloorDataIndex[] = mergedTagsFromAreas.map((tag) => {
					return {
						...tag,
						index,
					};
				});
				return tagsWithFloorIndex;
			}
		)
	);

	const groupedTagsByTagId: Dictionary<IMergedTagWithFloorDataIndex[]> = groupBy(
		mergedTagsWithSortedFloorIndex,
		'tagId'
	);

	return Object.keys(groupedTagsByTagId).map((tagId: string) => {
		return getWorkPeriodsOfTag(groupedTagsByTagId[tagId], sortedTimestamps);
	});
};

const getWorkPeriodsOfTag = (
	mergedTagsWithTimestampIndex: IMergedTagWithFloorDataIndex[],
	sortedTimestampOfAllMergedFloors: number[]
): ITagWorkPeriods => {
	const workPeriodsOfTag: IWorkPeriod[] = mergedTagsWithTimestampIndex.reduce(
		(acc: IWorkPeriod[], curr: IMergedTagWithFloorDataIndex, index: number) => {
			if (index === 0) {
				const firstTimestamp: number = sortedTimestampOfAllMergedFloors[curr.index];
				acc.push({
					startTimestamp: firstTimestamp,
					endTimestamp: firstTimestamp,
				});
				return acc;
			}

			if (curr.index - mergedTagsWithTimestampIndex[index - 1].index === 1) {
				//if the timestamp is continues (i.e index is 1 more than the previous)
				acc[acc.length - 1].endTimestamp = sortedTimestampOfAllMergedFloors[curr.index];
				return acc;
			}

			acc.push({
				startTimestamp: sortedTimestampOfAllMergedFloors[curr.index],
				endTimestamp: sortedTimestampOfAllMergedFloors[curr.index],
			});

			return acc;
		},
		[]
	);

	return {
		tag: mergedTagsWithTimestampIndex[mergedTagsWithTimestampIndex.length - 1],
		workPeriods: workPeriodsOfTag,
	};
};
