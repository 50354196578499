import { requestService, streamService } from '../../index';
import { ITag } from '../../interfaces';
import { IRootState } from '../slices';
import { updateTags, updateTagSuccess } from '../slices/tags.slice';
import { trusstorTradeId } from '@shared/constants/professions.constants';
import moment from 'moment-timezone';
import { randomIntFromInterval } from '../../utils/generalUtils';
import { cloneDeep } from 'lodash';
import { getTimezoneFormattedDate } from '../../utils/dateUtils';
import { getMinimizedProfession } from '../../utils/professions.utils';
import { selectProjectId } from '../slices/project.slice';

export const getStaticTagsStream = (projectId: string, isAdmin: boolean) => async (dispatch: any) => {
	streamService.openStream(`/siteNetIntegrator/static/adminPanel/stream?projectId=${projectId}`, (message: any) => {
		const tags = JSON.parse(message.data, (key: string, value: string) =>
			key === 'expirationDate' ? new Date(value) : value
		);
		const filteredTags: ITag[] = tags.filter((tag) => isAdmin || tag.profession?.tradeId !== trusstorTradeId);

		dispatch(updateTags({ tags: filteredTags }));
	});
};

export const getStaticTags = (projectId: string, isAdmin: boolean) => async (dispatch: any) => {
	const tags: ITag[] = await requestService.get(`/siteNetIntegrator/static/tags/${projectId}`);
	const filteredTags: ITag[] = tags.filter((tag) => isAdmin || tag.profession?.tradeId !== trusstorTradeId);
	dispatch(updateTags({ tags: filteredTags }));
};

export const updateTag = (tagId: string, updateObject: any) => async (dispatch: any, getState: () => IRootState) => {
	const tagBeforeUpdate: ITag | undefined = getState().tagsReducer.tags.find((tag: ITag) => tag.tagId === tagId);
	const tz: string = getState().projectReducer.workingProject!.tz;

	if (tagBeforeUpdate) {
		const tagBeforeUpdateClone = cloneDeep(tagBeforeUpdate);
		const updatedTagClone = cloneDeep(updateObject);
		if (updatedTagClone.certificates?.length > 0) {
			updatedTagClone.certificates = updatedTagClone.certificates.map((certificate) => {
				certificate.expirationDate = getTimezoneFormattedDate(tz, certificate.expirationDate);
				return certificate;
			});
		} else {
			updatedTagClone.certificates = [];
		}

		if (updatedTagClone.profession) {
			updatedTagClone.profession = getMinimizedProfession(updatedTagClone.profession);
		}
		delete tagBeforeUpdateClone.manager;
		delete tagBeforeUpdateClone.profession;

		const updatedTag: ITag = await requestService.put(`/siteNetIntegrator/static/tag/${tagId}`, {
			body: updatedTagClone,
		});

		const updatedMergedTag: ITag = { ...tagBeforeUpdateClone, ...updatedTag };
		if (updatedMergedTag.certificates && updatedMergedTag.certificates.length > 0) {
			updatedMergedTag.certificates = updatedMergedTag.certificates.map((certificate) => {
				certificate.expirationDate = new Date(certificate.expirationDate);
				return certificate;
			});
		}
		dispatch(updateTagSuccess({ tagId, updatedTag: updatedMergedTag }));
	}
};

export const unassignTag = (tag: ITag) => async (dispatch: any, getState: () => IRootState) => {
	const projectId: string | undefined = selectProjectId(getState());
	const updatedTag: ITag = await requestService.put(
		`/siteNetIntegrator/static/${projectId}/tag/${tag.tagId}/unassign`
	);
	const updatedMergedTag: ITag = { ...tag, ...updatedTag };
	delete updatedMergedTag.manager;
	delete updatedMergedTag.linkedUtility;
	dispatch(updateTagSuccess({ tagId: tag.tagId, updatedTag: updatedMergedTag }));
};

export const stopGettingAdminPanelTags = () => {
	streamService.closeStreamIncludes(`/siteNetIntegrator/static/adminPanel/stream`);
};
