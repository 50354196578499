import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { textStyle } from '@shared/utils/text.utils';

const useStyles = makeStyles({
	text: textStyle({
		fontClass: 'h1',
		textAlign: 'center',
		marginTop: 24,
	}),
});

const NotFoundPage = () => {
	const classes = useStyles();
	return <p className={classes.text}>404 NOT FOUND</p>;
};

export { NotFoundPage };
