import React, { useEffect, useState } from 'react';
import { GeneralDialog } from '@src/Components/GeneralDialog/GeneralDialog';
import { requestService, translationService } from '@src/servicesInitializers';
import { LocationSelector } from '@src/Components/Dropdowns/Location/LocationSelector/LocationSelector';
import { IBaseFloor } from '@shared/interfaces/Floors/IBaseFloor';
import { IConfigArea } from '@shared/interfaces/IConfigArea';
import { IProfession } from '@shared/interfaces/IProfession';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '@store/slices';
import { NotificationTypes } from '@shared/interfaces/NotificationTypes.enum';
import { checkIfArraysAreEqual } from '@utils/array.util';
import { errorSnackbar, successSnackbar } from '@utils/snackbar.utils';
import classes from '../style.module.scss';
import { IRestrictedAreaTrigger } from '@interfaces/IRestrictedAreaTrigger';
import { getFloorsFromAreas } from '@utils/areas.utils';
import { MultiProfessionGroupedDropdown } from '@src/Components/Dropdowns/Profession/MultiProfessionGroupedDropdown/MultiProfessionGroupedDropdown';

interface IRestrictedAreaEditDialogProps {
	trigger: IRestrictedAreaTrigger;
	handleClose: () => void;
}

export const RestrictedAreaEditDialog = (props: IRestrictedAreaEditDialogProps) => {
	const activeProfessions: IProfession[] = useSelector(
		(state: IRootState) => state.professionsReducer.professions
	).filter((profession) => !profession.dateDeleted);
	const [areas, setAreas] = useState<IConfigArea[]>(props.trigger.areas || []);
	const [floors, setFloors] = useState<IBaseFloor[]>(props.trigger.floors || []);
	const [initialAreas, setInitialAreas] = useState<IConfigArea[] | undefined>(props.trigger.areas || undefined);
	const [initialFloors, setInitialFloors] = useState<IBaseFloor[] | undefined>(props.trigger.floors || undefined);
	const [professions, setProfessions] = useState<IProfession[]>(props.trigger.profession.professions || []);
	const dispatch = useDispatch();

	const onLocationChange = (floors: IBaseFloor[], areas: IConfigArea[]) => {
		const doesFloorsExist = floors.length > 0;
		const doesAreasExist = areas.length > 0;
		if (!doesFloorsExist && !doesAreasExist) {
			return undefined;
		}
		const updatedFloors: IBaseFloor[] = doesFloorsExist ? floors : getFloorsFromAreas(areas);
		setAreas(areas);
		setFloors(updatedFloors);
	};

	const handleUpdate = async () => {
		const restrictedAreaTriggerUpdateObject: Partial<IRestrictedAreaTrigger> = {
			floors,
			areas,
			profession: {
				professions,
				isAllSelected: professions.length === activeProfessions.length,
			},
			type: NotificationTypes.RestrictedArea,
		};

		try {
			await requestService.put(`/notification/customizedTriggers/${props.trigger._id}`, {
				body: restrictedAreaTriggerUpdateObject,
			});
			successSnackbar(dispatch, translationService.get('restrictedAreaUpdated'));
			props.handleClose();
		} catch (e) {
			errorSnackbar(dispatch, translationService.get('genericError'));
		}
	};

	const handleDelete = async () => {
		try {
			await requestService.delete(`/notification/customizedTriggers/${props.trigger._id}`);
			successSnackbar(dispatch, translationService.get('restrictedAreaDeleted'));
			props.handleClose();
		} catch (e) {
			errorSnackbar(dispatch, translationService.get('genericError'));
		}
	};

	const checkIfFloorsAreEqual = () => {
		return checkIfArraysAreEqual(props.trigger.floors || [], floors, 'floorId');
	};

	const checkIfAreasAreEqual = () => {
		return checkIfArraysAreEqual(props.trigger.areas || [], areas, 'areaId');
	};

	const checkIfProfessionsAreEqual = () => {
		return checkIfArraysAreEqual(props.trigger.profession.professions || [], professions, '_id');
	};

	const checkIfEditShouldBeDisabled = (): boolean => {
		return (
			(checkIfAreasAreEqual() && checkIfFloorsAreEqual() && checkIfProfessionsAreEqual()) ||
			(floors.length === 0 && areas.length === 0) ||
			professions.length === 0
		);
	};

	useEffect(() => {
		if (initialFloors) {
			return;
		}

		setInitialFloors(props.trigger.floors);
	}, [props.trigger.floors]);

	useEffect(() => {
		if (initialAreas) {
			return;
		}

		setInitialAreas(props.trigger.areas);
	}, [props.trigger.areas]);

	return (
		<GeneralDialog
			title={translationService.get('RestrictedArea')}
			show={true}
			close={props.handleClose}
			mainButton={{
				click: handleUpdate,
				disabled: checkIfEditShouldBeDisabled(),
				text: translationService.get('save'),
			}}
			tertiaryButton={{
				click: handleDelete,
				text: translationService.get('delete'),
			}}
		>
			<>
				<LocationSelector
					onChange={onLocationChange}
					selectedFloors={initialFloors}
					selectedAreas={initialAreas}
					setInitialSelectedAreas={(areas) => setInitialAreas(areas)}
					setInitialSelectedFloors={(floors) => setInitialFloors(floors)}
				/>
				<div className={classes.professionSelectSection}>
					<div className={classes.restrictedText}>{translationService.get('restrictedFor')}</div>
					{activeProfessions.length > 0 && (
						<MultiProfessionGroupedDropdown
							professions={activeProfessions}
							onChange={setProfessions}
							defaultSelectedProfessions={professions}
						/>
					)}
				</div>
			</>
		</GeneralDialog>
	);
};
