import React from 'react';
import { find, sortBy } from 'lodash';
import { TrusstorTooltip } from '../../../../shared/components/TrusstorTooltip/TrusstorTooltip';
import ManagerChipsContainer from '../Chips/ManagerChipsContainer';
import ManagerChipsTooltip from '../Chips/Tooltips/ManagerChipsTooltip';
import { entitiesProperties } from '../../constants';
import { TagsDetailsTooltip } from '../Tooltips/TagsDetailsTooltip';
import { compareProps } from '../../utils/generalUtils';
import { IFloorData } from '../../interfaces/IFloorData';
import { IBuildingSvgPoints } from '../../interfaces/IBuildingSvgPoints';
import { IManagerMergedTag } from '../../interfaces/IManagerMergedTag';
import { ISvgElementPoint } from '../../interfaces/ISvgElementPoint';
import { floorTypes } from '@shared/constants/floors.constants';

interface IFloorManagersProps {
	floorData: IFloorData;
	svgPoints: IBuildingSvgPoints;
	setFloorPath: (floorPath: string) => void;
	isAdmin: boolean;
}

const FloorManagers = React.memo((props: IFloorManagersProps) => {
	if (props.floorData.floorId === floorTypes.pa) {
		return null;
	}
	const managersForFloor: IManagerMergedTag[] = sortBy(props.floorData.managers, [
		entitiesProperties.sortIndex,
		entitiesProperties.name,
	]);

	if (!managersForFloor) {
		return null;
	}

	const managerPointForFloor: ISvgElementPoint | undefined = find(props.svgPoints.managerPointsArray, {
		floorId: props.floorData.floorId,
	});

	if (!managerPointForFloor) {
		return null;
	}
	return (
		<TrusstorTooltip
			showAlways={props.isAdmin}
			key={`managers_chips_container_${managerPointForFloor.floorId}`}
			chipsClasses={`floor_${managerPointForFloor.floorId}_managers`}
			tooltipComponent={
				props.isAdmin ? (
					<TagsDetailsTooltip tags={props.floorData.tags} />
				) : (
					<ManagerChipsTooltip mergedManagers={managersForFloor} />
				)
			}
		>
			<ManagerChipsContainer
				mergedManagersForFloor={managersForFloor}
				managerPointForFloor={managerPointForFloor}
				key={`managers_${managerPointForFloor.floorId}`}
			/>
		</TrusstorTooltip>
	);
}, compareProps);

export { FloorManagers };
