import React from 'react';
import { EquipmentTypes } from '../../../constants/equipment.const';
import classes from './styles.module.scss';
import { getEquipmentIconFromType } from '../../../utils/equipment.utils';

interface IEquipmentIconProps {
	type: EquipmentTypes;
}

const EquipmentIcon = (props: IEquipmentIconProps) => {
	return <img src={getEquipmentIconFromType(props.type)} alt="eqimage" className={classes.icon} />;
};

export { EquipmentIcon };
