import React, { useContext, useEffect, useId, useRef, useState } from 'react';
import { groupBy, uniq } from 'lodash';
import { useSelector } from 'react-redux';
import { TrusstorBox } from '../../Boxes/Containers/TrusstorBox';
import {
	compareProps,
	filterFloorVisibleManagers,
	filterVisibleProfessions,
	filterVisibleUtilitiesTags,
	getMainPagePath,
} from '../../../utils/generalUtils';
import { TrusstorTooltip } from '../../../../../shared/components/TrusstorTooltip/TrusstorTooltip';
import { translationService } from '../../../index';
import { useHistory } from 'react-router-dom';
import { IRootState } from '../../../store/slices';
import { IMergedEquipmentView } from '../../../interfaces/IMergedEquipmentView';
import { IManager, IProfession } from '../../../interfaces';
import { IProfessionWithActiveTags } from '../../../interfaces/IProfessionWithActiveTags';
import { IManagerMergedTag } from '../../../interfaces/IManagerMergedTag';
import { floorViewOpenLog_BI } from '../../../utils/bi.utils';
import classes from './styles.module.scss';
import { IMergedAreaView } from '../../../interfaces/IMergedAreaView';
import { IMergedFloorView } from '../../../interfaces/IMergedFloorView';
import { IMergedAreaViewWithEquipment } from '../../../interfaces/IMergedAreaViewWithEquipment';
import { TrusstorCloseButton } from '@src/Components/Buttons/TrusstorCloseButton/TrusstorCloseButton';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { TrusstorIcon } from '@src/Components/TrusstorIcon/TrusstorIcon';
import { FloorViewContentSection } from '@src/Components/MainPageSections/FloorView/FloorViewContentSection/FloorViewContentSection';
import { useFloorAreasQuery } from '@src/hooks/queries/areas.queries.hooks';
import { IConfigArea } from '@shared/interfaces/IConfigArea';
import { DashboardTabTypes } from '@interfaces/DashboardTabTypes.enum';
import { selectActiveMainPageLayout } from '@store/slices/mainPageLayout.slice';
import { IIssueLocation } from '@shared/interfaces/IIssueShared';
import { ISelectedPointWithAreaId } from '@shared/interfaces/ISelectedPoint';
import { getSelectedPointWithAreaIdFromIssueLocations } from '@shared/components/Issue/issues.utils';
import { MainPageContext } from '@src/Components/Pages/MainPage';
import { IIssue } from '@shared/interfaces/IIssue';
import { IWorkerMergedTag } from '@interfaces/IWorkerMergedTag';
import classnames from 'classnames';
import FloorPlanSvgDisplay from '@src/Components/FloorPlanSvgDisplay/FloorPlanSvgDisplay';
import { FloorViewExpandDialog } from '@src/Components/FloorViewExpandDialog/FloorViewExpandDialog';
import { TrusstorIconButtonV2 } from '@shared/components/buttons/TrusstorIconButtonV2/TrusstorIconButtonV2';
import { IUtilityMergedTag } from '@interfaces/IUtilityMergedTag';
import { selectSelectedUtilities } from '@store/slices/utilities.slice';

interface IFloorViewProps {
	floor: IMergedFloorView;
}

const FLOOR_PLAN_VIEW_SVG_ID: string = 'floorPlanFloorViewSvgId';

export const FloorView = React.memo((props: IFloorViewProps) => {
	const isSpecialFloor: boolean = props.floor.isSpecialFloor;
	const isHoist: boolean = props.floor.isHoist;
	const projectId: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.projectId);
	const visibleActiveEquipment: IMergedEquipmentView[] = useSelector(
		(state: IRootState) => state.equipmentReducer.visibleActiveEquipment
	);
	const visibleManagers: IManager[] = useSelector((state: IRootState) => state.managersReducer.visibleManagers);
	const visibleProfessions: IProfession[] = useSelector(
		(state: IRootState) => state.professionsReducer.visibleProfessions
	);
	const selectedFloor = props.floor;
	const visibleWorkersOnSite: IWorkerMergedTag[] = selectedFloor.tags.filter((tag) =>
		visibleProfessions.some((profession) => profession._id === (tag as IWorkerMergedTag).profession?._id)
	) as IWorkerMergedTag[];
	const selectedUtilities: IUtilityMergedTag[] = useSelector(selectSelectedUtilities);
	const [headerStyleOnScroll, setHeaderStyleOnScroll] = useState<boolean>(false);
	const history = useHistory();
	const scrollableContentRef = useRef<HTMLDivElement | null>(null);
	const { issues } = useContext(MainPageContext)!;
	const currentActiveMainPageLayout: DashboardTabTypes | null = useSelector(selectActiveMainPageLayout);
	const { widgetActivities } = useContext(MainPageContext)!;
	const isRtl: boolean = translationService.getIsRtl();
	const floorAreas: IConfigArea[] = useFloorAreasQuery(projectId, selectedFloor.floorId);
	const isMultiFloor: boolean = floorAreas.length > 1;
	const [showFloorPlanExpandDialog, setShowFloorPlanExpandDialog] = useState<boolean>(false);

	useEffect(() => {
		floorViewOpenLog_BI(props.floor, widgetActivities || [], selectedFloor);
	}, [props.floor.floorId]);

	if (!selectedFloor) {
		return null;
	}

	const onScroll = () => {
		const scrollTop: number | undefined = scrollableContentRef.current?.scrollTop;
		if (!scrollTop && scrollTop !== 0) {
			return;
		}
		setHeaderStyleOnScroll(scrollTop > 0);
	};

	const equipmentForFloor: IMergedEquipmentView[] = visibleActiveEquipment.filter(
		(visibleEq) => visibleEq.floorId === props.floor.floorId
	);

	const areasWithProfessionsMerged: IMergedAreaView[] = selectedFloor.areas.map((area) => ({
		...area,
		activeProfessions: getMergedProfessionsWithActiveTags(area.activeProfessions),
	}));

	const visibleAreasWithVisibleTrades: IMergedAreaViewWithEquipment[] = areasWithProfessionsMerged.map((area) => {
		const defaultArea: IMergedAreaViewWithEquipment = {
			...area,
			managers: filterFloorVisibleManagers(area.managers, visibleManagers),
			utilities: filterVisibleUtilitiesTags(area.utilities || [], selectedUtilities),
			equipment: equipmentForFloor.filter((eq) => eq.areaId === area.areaId),
		};
		const activeProfessionsForArea: IProfessionWithActiveTags[] = filterVisibleProfessions(
			area.activeProfessions,
			visibleProfessions
		);
		return { ...defaultArea, activeProfessions: activeProfessionsForArea };
	});

	const floorVisibleManagers: IManagerMergedTag[] = filterFloorVisibleManagers(
		selectedFloor.managers,
		visibleManagers
	);

	const handleCloseFloor = (): void => {
		const path: string = getMainPagePath();
		history.push(path);
	};

	const areaNicksTextId: string = useId() + props.floor.floorId;

	const floorIssues: IIssue[] | undefined = issues?.filter((issue) =>
		issue.locations?.some((location) => location.floor.floorId === selectedFloor.floorId)
	);
	const issuesLocations: IIssueLocation[] =
		floorIssues
			?.flatMap((issue) => issue.locations!)
			?.filter((location) => location.floor.floorId === selectedFloor.floorId) || [];
	const locationsWithSelectedPoints: IIssueLocation[] = issuesLocations.filter(
		(location: IIssueLocation) => location.area.selectedPoints?.length
	);
	const issuesCurrentFloorSelectedPoints: ISelectedPointWithAreaId[] =
		getSelectedPointWithAreaIdFromIssueLocations(locationsWithSelectedPoints);

	const areaNicks: string = floorAreas.map((area) => area.areaNick).join(' | ');

	const showAreaNicks: boolean = isMultiFloor || !isSpecialFloor;

	return (
		<TrusstorBox customStyleClass={classes.wrapper}>
			<FloorViewExpandDialog
				isSpecialFloor={isSpecialFloor}
				show={showFloorPlanExpandDialog}
				closeDialog={() => setShowFloorPlanExpandDialog(false)}
				currentActiveMainPageLayout={currentActiveMainPageLayout}
				floorId={props.floor.floorId}
				visibleAreasWithVisibleTrades={visibleAreasWithVisibleTrades}
				visibleWorkersOnSite={visibleWorkersOnSite}
				issuesCurrentFloorSelectedPoints={issuesCurrentFloorSelectedPoints}
			/>
			<div className={`${classes.headlineContainer} ${headerStyleOnScroll ? classes.boxShadow : ''}`}>
				<div className={classes.floorContainer}>
					<div className={classes.floorInfoContainer}>
						<div className={classes.iconContainer}>
							<TrusstorIcon iconName={isHoist ? IconNames.elevator : IconNames.floors} />
						</div>
						<div className={classes.floorInfo}>
							<div className={classes.floorInfoTitle} data-testid={'floorTitle'}>
								{!showAreaNicks ? floorAreas[0]?.areaNick : props.floor.floorNick}
							</div>
							{showAreaNicks && (
								<TrusstorTooltip
									tooltipComponent={<div className={classes.areaNickTooltip}>{areaNicks}</div>}
									idToOverflow={areaNicksTextId}
									dependencies={[areaNicks]}
								>
									<div
										id={areaNicksTextId}
										className={classes.floorInfoSubtitle}
										data-testid={'floorSubtitle'}
									>
										{areaNicks}
									</div>
								</TrusstorTooltip>
							)}
						</div>
					</div>
					<div className={classes.closeButton} data-testid={'closeButton'}>
						<TrusstorCloseButton click={handleCloseFloor} />
					</div>
				</div>
			</div>
			<div className={classes.contentContainer} onScroll={onScroll} ref={scrollableContentRef}>
				{!isSpecialFloor && (
					<>
						<div
							className={classnames(classes.expandIcon, { [classes.isRtl]: isRtl })}
							onClick={() => setShowFloorPlanExpandDialog(true)}
						>
							<TrusstorIconButtonV2
								squaredButton
								iconName={IconNames.expand}
								onClick={() => setShowFloorPlanExpandDialog(true)}
							/>
						</div>
						<FloorPlanSvgDisplay
							isSpecialFloor={false}
							currentActiveMainPageLayout={currentActiveMainPageLayout}
							svgId={FLOOR_PLAN_VIEW_SVG_ID}
							floorId={props.floor.floorId}
							visibleAreasWithVisibleTrades={visibleAreasWithVisibleTrades}
							visibleWorkersOnSite={visibleWorkersOnSite}
							issuesCurrentFloorSelectedPoints={issuesCurrentFloorSelectedPoints}
							svgParentContainerClass={classes.svgParentContainer}
						/>
					</>
				)}
				{selectedFloor && (
					<FloorViewContentSection
						floorEquipment={equipmentForFloor}
						visibleWorkersOnSite={visibleWorkersOnSite}
						isHoistFloor={isHoist}
						selectedFloor={selectedFloor}
						floorVisibleManagers={floorVisibleManagers}
					/>
				)}
			</div>
		</TrusstorBox>
	);
}, compareProps);

const getMergedProfessionsWithActiveTags = (professions: IProfessionWithActiveTags[]): IProfessionWithActiveTags[] => {
	return Object.values(groupBy(professions, '_id')).map((groupedProfessions: IProfessionWithActiveTags[]) => {
		return groupedProfessions.reduce(
			(mergedProfessions: IProfessionWithActiveTags, professionToMerged: IProfessionWithActiveTags) => {
				return {
					...mergedProfessions,
					activeTagsIds: uniq([...mergedProfessions.activeTagsIds, ...professionToMerged.activeTagsIds]),
				};
			}
		);
	});
};
