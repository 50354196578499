import React from 'react';
import { useHistory } from 'react-router-dom';
import { translationService } from '../../../../index';
import { AuthPage } from '../AuthPage';

export const SetPasswordConfirmationPage = () => {
	const history = useHistory();
	return (
		<div style={{ direction: translationService.getDirection() }}>
			<AuthPage
				buttonText={translationService.get('backToLogin')}
				title={translationService.get('setPassword')}
				onButtonClick={() => history.push(`/login/${translationService.getChosenLanguage()}`)}
			>
				<div>
					<p data-testid="password-updated-label">{translationService.get('passwordSuccessfullyUpdated')}</p>
				</div>
			</AuthPage>
		</div>
	);
};
