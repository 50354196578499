import React from 'react';
import classes from './styles.module.scss';

interface ILineDelimiterProps {
	height: number;
	width?: number;
	color?: string;
}

export const LineDelimiter = (props: ILineDelimiterProps) => {
	const width = props.width ?? 1;
	const color = props.color ?? 'white';
	return <div style={{ height: `${props.height}px`, width: width, backgroundColor: color }} />;
};
