import { escapeRegExp } from 'lodash';
import React, { CSSProperties } from 'react';
import classes from './styles.module.scss';

interface IProps {
	text: string;
	highlight: string;
	styles?: CSSProperties;
}

export const HighlightedTextField = (props: IProps) => {
	if (!props.highlight.trim()) {
		return <span className={classes.textStyle}>{props.text}</span>;
	}
	const regex = new RegExp(`(${escapeRegExp(props.highlight)})`, 'gi');
	const parts = props.text.split(regex);
	return (
		<div className={classes.textStyle}>
			{parts
				.filter((part) => part)
				.map((part, i) =>
					regex.test(part) ? (
						<strong className={classes.strongerText} key={i}>
							{part}
						</strong>
					) : (
						<span className={classes.textStyle} key={i}>
							{part}
						</span>
					)
				)}
		</div>
	);
};
