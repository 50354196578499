import { useEffect, useRef, useState } from 'react';
import { biService } from '../index';
import { BIEventNames } from '../interfaces/BI/BIEventNames.enum';
import { IWeekDayActivity, IWorkPlanActivities } from '../interfaces';
import moment from 'moment';
import { IBIWorkplanAccessEventData } from '../interfaces/BI/IBIWorkplanAccessEventData';
import { workplanDialogAccess_BI } from '../utils/bi.utils';

export const useWorkplanDialogAccess_BI = (tz: string, activity?: Partial<IWeekDayActivity>): number | undefined => {
	const isInitialRender = useRef<boolean>(true);

	const [openDialogTimeStamp, setOpenDialogTimeStamp] = useState<number>();

	useEffect(() => {
		if (isInitialRender.current) {
			setOpenDialogTimeStamp(Date.now());
		}
		if (!isInitialRender.current || !activity) {
			return;
		}
		workplanDialogAccess_BI(activity, tz);
		isInitialRender.current = false;

		return () => {
			isInitialRender.current = true;
		};
	}, [activity?._id]);

	return openDialogTimeStamp;
};

export const useUserMouseInteraction_BI = (workingProjectId: string, tz: string): void => {
	useEffect(() => {
		if (!workingProjectId) {
			return;
		}
		const lastMouseUserInteractionTimestamp = 'lastMouseUserInteractionTimestamp';
		const lastMouseUserInteraction = localStorage.getItem(lastMouseUserInteractionTimestamp);
		const lastMouseUserInteractionTZDate = moment.tz(lastMouseUserInteraction, tz);
		const isFirstUse: boolean = !lastMouseUserInteraction;

		let myTimeout;
		const isLastInteractionMoreThenAnHour =
			moment.tz(new Date(), tz).diff(lastMouseUserInteractionTZDate, 'hours') > 1;
		if (isFirstUse || isLastInteractionMoreThenAnHour) {
			addMouseEventListener();
			return;
		}

		const millisecondsToNextEventListener = getMillisecondsToNextEventListener();

		myTimeout = setTimeout(() => {
			addMouseEventListener();
		}, millisecondsToNextEventListener);

		function getMillisecondsToNextEventListener() {
			const oneHourAfterLastInteractionMS =
				lastMouseUserInteractionTZDate.add(1, 'hours').diff(moment.tz(new Date(), tz), 'seconds') * 1000;
			return Math.max(0, oneHourAfterLastInteractionMS);
		}

		function logMouseMovement() {
			const oneHourMS = 3600000;
			myTimeout = setTimeout(() => {
				addMouseEventListener();
			}, oneHourMS);

			localStorage.setItem(lastMouseUserInteractionTimestamp, moment.tz(new Date(), tz).format());
			biService.logEvent(BIEventNames.userMainAccess);
		}

		function addMouseEventListener() {
			document.addEventListener('mousemove', logMouseMovement, { once: true });
		}

		return () => {
			clearTimeout(myTimeout);
			document.removeEventListener('mousemove', logMouseMovement);
		};
	}, [workingProjectId]);
};

export const useWorkplanFirstAccess_BI = (tz: string, workPlanActivities?: IWorkPlanActivities): void => {
	const userFirstAccessWasLogged = useRef<boolean>(false);

	useEffect(() => {
		if (!workPlanActivities || userFirstAccessWasLogged.current) {
			return;
		}
		const eventData: IBIWorkplanAccessEventData = {
			hasActivities: Object.values(workPlanActivities).some((activity) => activity.length > 0),
		};

		biService.logEvent(BIEventNames.workplanAccess, eventData);
		userFirstAccessWasLogged.current = true;
	}, [workPlanActivities]);
};
