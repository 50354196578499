import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAppFilterState } from '../../interfaces/IAppFilterState';
import { APP_FILTER_TYPES } from '../../constants';

const initialState: IAppFilterState = {
	activeFilterType: null,
};

const appFilterSlice = createSlice({
	name: 'appFilter',
	initialState,
	reducers: {
		updateActiveFilterType: (
			state: IAppFilterState,
			action: PayloadAction<{ activeFilterType: APP_FILTER_TYPES }>
		) => {
			state.activeFilterType = action.payload.activeFilterType;
		},
	},
});

export const { updateActiveFilterType } = appFilterSlice.actions;
export default appFilterSlice.reducer;
