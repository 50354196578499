import React from 'react';
import classes from './styles.module.scss';
import { IRoundedProfessionsAnalysisReportData } from '@interfaces/IRoundedProfessionsAnalysisReport';
import Chart from 'react-apexcharts';
import ApexCharts from 'apexcharts';
import { dateFormats } from '@shared/constants/formats.constants';
import { translationService } from '@src/servicesInitializers';
import { getApexTickAmountOption } from '@utils/reports.utils';
import { COLORS, PROFESSION_REPORT_PAGE } from '@src/constants';
import moment from 'moment';
import { selectTimezone } from '@store/slices/project.slice';
import { useSelector } from 'react-redux';
import { TagsAnalysisCustomTooltip } from '../TagsAnalysisCustomTooltip/TagsAnalysisCustomTooltip';
import { renderToString } from 'react-dom/server';

interface ITagsAnalysisVsHoursGraphProps {
	currentProfessionReport: IRoundedProfessionsAnalysisReportData | undefined;
}

export const TagsAnalysisVsHoursGraph = (props: ITagsAnalysisVsHoursGraphProps) => {
	const tz: string = useSelector(selectTimezone)!;
	const report: IRoundedProfessionsAnalysisReportData | undefined = props.currentProfessionReport;

	const dates: string[] | undefined =
		report && Object.keys(report.dates).map((date) => moment.tz(date, dateFormats.DMY, tz).toDate().toDateString());

	const isRtl: boolean = translationService.getIsRtl();
	const series = [
		{
			name: translationService.get('TagsAnalysisVsHoursGraph_workersPerDay_label'),
			type: 'column',
			data: report && Object.values(report?.dates).map((date) => (date.totalWorkHours ? date.totalWorkers : 0)),
		},
		{
			name: translationService.get('TagsAnalysisVsHoursGraph_hoursPerDay_label'),
			type: 'line',
			data: report && Object.values(report?.dates).map((date) => date.totalWorkHours),
		},
	];

	const options: ApexCharts.ApexOptions = {
		chart: {
			height: 350,
			type: 'line',
			toolbar: {
				show: false,
				tools: {
					zoom: false,
				},
			},
		},
		stroke: {
			width: [0, 4],
		},
		colors: [COLORS.blue300, COLORS.spaceCadet],
		dataLabels: {
			enabled: false,
		},
		labels: dates,
		legend: {
			fontWeight: 500,
			fontSize: '12px',
		},
		tooltip: {
			style: {
				fontSize: '12px',
				fontFamily: 'Heebo, sans-serif',
			},
			custom: ({ series, seriesIndex, dataPointIndex, w }) => {
				const workHours = series[1][dataPointIndex];
				const workers = series[0][dataPointIndex];
				const date: string | undefined = dates && moment(dates![dataPointIndex]).format(dateFormats.DMY);
				return renderToString(
					<TagsAnalysisCustomTooltip workHours={workHours} workers={workers} date={date} />
				);
			},
		},
		xaxis: {
			tooltip: {
				enabled: false,
			},
			tickAmount: getApexTickAmountOption(dates as any[], PROFESSION_REPORT_PAGE.MAX_GRAPH_TICKS),
			labels: {
				style: {
					colors: COLORS.grey500,
					fontSize: '10px',
					fontWeight: 600,
					cssClass: classes.xAxisLabelsClass,
				},
				offsetY: isRtl && dates && dates.length > 19 ? 25 : 0,
				formatter: (date: string) => moment(date).format('MMM DD'),
			},
		},
		yaxis: [
			{
				labels: {
					style: {
						colors: COLORS.blue300,
						fontSize: '10px',
						fontWeight: 600,
						cssClass: classes.xAxisLabelsClass,
					},
				},
			},
			{
				opposite: true,
				labels: {
					style: {
						colors: COLORS.spaceCadet,
						fontSize: '10px',
						fontWeight: 600,
						cssClass: classes.xAxisLabelsClass,
					},
				},
			},
		],
	};

	if (!report) {
		return null;
	}
	return <Chart options={options} series={series} type="line" height={250} />;
};
