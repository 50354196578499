import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILoggedUser } from '@shared/interfaces/IUser';
import { ILoginReducerState, ILoginRequestStatus, LoginRequestStatuses } from '@shared/interfaces/ILoginReducerState';
import { IRootState } from './index';
import { RoleType } from '@shared/interfaces/RoleType.enum';

const initialState: ILoginReducerState = {
	loggedUser: null,
	loginRequestStatus: {
		state: LoginRequestStatuses.INITIAL,
	},
};

const loginSlice = createSlice({
	name: 'login',
	initialState,
	reducers: {
		setLoggedUser: (state: ILoginReducerState, action: PayloadAction<{ user: ILoggedUser }>) => {
			state.loggedUser = action.payload.user;
		},
		setLoginStateData: (
			state: ILoginReducerState,
			action: PayloadAction<{ loginRequestStatus: ILoginRequestStatus }>
		) => {
			state.loginRequestStatus = action.payload.loginRequestStatus;
		},
		setLoggedUserPreferences: (
			state: ILoginReducerState,
			action: PayloadAction<{ userPreferences: ILoggedUser['userPreferences'] }>
		) => {
			state.loggedUser!.userPreferences = action.payload.userPreferences;
		},
	},
});

export const selectIsLimitedUser = (state: IRootState) =>
	state.loginReducer.loggedUser?.userDetails.permissions.roleType === RoleType.VIEWER;
export const selectLoggedUserDetails = (state: IRootState) => state.loginReducer.loggedUser?.userDetails;
export const selectLoggedUserProjects = (state: IRootState) => state.loginReducer.loggedUser?.userProjects;
export const selectLoggedUser = (state: IRootState) => state.loginReducer.loggedUser;
export const selectLoggedUserPreferences = (state: IRootState) => state.loginReducer.loggedUser?.userPreferences;
export const { setLoggedUser, setLoginStateData, setLoggedUserPreferences } = loginSlice.actions;
export default loginSlice.reducer;
