import { requestService, streamService } from '../../index';
import { getTimezoneFormattedDate } from '../../utils/dateUtils';
import { config } from '../../config';
import { IProductivityReport } from '../../interfaces/IProductivityReport';
import { resetProductivityReport, updateProductivityReport } from '../slices/productivityReport.slice';

const getDailyProductivityReportApiBaseUrl = (useV2Api: boolean) => {
	if (useV2Api) {
		return '/reports/professionsProductivity/v2/stream';
	}

	return '/reports/professionsProductivity/stream';
};

export const getProductivityDailyReport =
	(projectId: string, date: number | Date, tz: string, useV2Api: boolean) => async (dispatch, getState) => {
		const baseUrl: string = getDailyProductivityReportApiBaseUrl(useV2Api);
		const serverFormatDate: string = getTimezoneFormattedDate(tz, date);
		streamService.openStream(
			`${baseUrl}?date=${serverFormatDate}&projectId=${projectId}&interval=${config.reportsInterval}`,
			(message) => {
				const report: IProductivityReport = JSON.parse(message.data);
				dispatch(updateProductivityReport({ report }));
			}
		);
	};

export const getProductivityPeriodicReport =
	(projectId: string, period: [number, number] | [Date, Date], tz: string) => async (dispatch, getState) => {
		const serverFormatDateFrom: string = getTimezoneFormattedDate(tz, period[0]);
		const serverFormatDateTo: string = getTimezoneFormattedDate(tz, period[1]);

		streamService.closeStreamIncludes(`/reports/professionsProductivity/stream`);
		streamService.closeStreamIncludes(`/reports/professionsProductivity/v2/stream`);
		const productivityReport: IProductivityReport = await requestService.get(
			`/reports/periodicProfessionsProductivity?dateFrom=${serverFormatDateFrom}&dateTo=${serverFormatDateTo}&projectId=${projectId}&interval=${config.reportsInterval}`
		);

		dispatch(updateProductivityReport({ report: productivityReport }));
	};

export const stopGettingProductivityReport = () => (dispatch) => {
	streamService.closeStreamIncludes(`/reports/professionsProductivity/stream`);
	dispatch(resetProductivityReport());
};
