import React, { useEffect, useRef, useState } from 'react';
import Chart from 'react-apexcharts';
import ApexCharts from 'apexcharts';
import { makeStyles } from '@material-ui/core/styles';
import { translationService } from '../../../../../index';
import { useLargeScreen } from '../../../../../hooks/custom.hooks';
import ReactApexChart from 'react-apexcharts';
import { IEventsPerHour } from '../../../../../interfaces/IEventsPerHour';

const useStyles = makeStyles({
	'chartLegendWrapper': {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100%',
		width: '100%',
	},
	'@global': {
		'.apexcharts-text tspan': {
			color: '#103045',
		},
	},
});

interface IHourlyGraph {
	dataArray: IEventsPerHour;
}

const DEFAULT_GRAPH_PADDING_PERCENTAGE: number = 0.1;

const HourlyGraphSafety = (props: IHourlyGraph) => {
	const series = [
		{
			type: 'area',
			name: translationService.get('sumAlertsByTime'),
			data: Object.values(props.dataArray),
		},
	];
	const isLargeScreen = useLargeScreen();
	const classes = useStyles();
	const [xLabelOffset, setXLabelOffset] = useState<number>(0);
	const chartEl = useRef<ReactApexChart>(null);

	useEffect(() => {
		const chartWidth: number = Number(chartEl.current?.props.width ?? 0);
		const defaultGraphPaddingWidth: number = DEFAULT_GRAPH_PADDING_PERCENTAGE * chartWidth;
		const xLabelOffset: number =
			(-1 * ((chartWidth - defaultGraphPaddingWidth) / Object.values(props.dataArray).length)) / 2;
		setXLabelOffset(xLabelOffset);
	}, [chartEl.current?.props.width, props.dataArray.length]);

	const options: ApexCharts.ApexOptions = {
		chart: {
			type: 'area',
			toolbar: {
				show: false,
			},
			selection: {
				enabled: false,
			},
			zoom: {
				enabled: false,
			},
		},
		fill: {
			type: 'gradient',
			gradient: {
				type: 'vertical',
				shade: 'light',
				opacityFrom: 0,
				opacityTo: 0,
			},
		},
		markers: {
			size: 3,
		},
		dataLabels: {
			enabled: false,
		},
		stroke: {
			curve: 'straight',
			width: 2,
		},
		xaxis: {
			tooltip: {
				enabled: false,
			},
			offsetX: xLabelOffset,
			tickPlacement: 'between',
			categories: Object.keys(props.dataArray),
			axisTicks: {
				show: true,
				height: 10,
			},
			labels: {
				style: {
					colors: [],
					fontSize: '10px',
					fontFamily: 'Heebo, sans-serif',
					fontWeight: 500,
					cssClass: 'apexcharts-xaxis-label',
				},
			},
		},
		yaxis: {
			opposite: true,
			labels: {
				offsetX: -9,
				rotate: 360,
				style: {
					fontSize: '10px',
					fontFamily: 'Heebo, sans-serif',
					fontWeight: 500,
					cssClass: 'apexcharts-xaxis-label',
				},
			},
		},
		tooltip: {
			marker: {
				show: false,
			},
		},
	};

	return (
		<div className={classes.chartLegendWrapper}>
			<Chart
				ref={chartEl}
				options={options}
				series={series}
				type="line"
				width={isLargeScreen ? 420 : 320}
				height="90%"
			/>
		</div>
	);
};

export { HourlyGraphSafety };
