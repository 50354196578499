import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IActivityGroupStatusFilterItem } from '../../interfaces/IActivityGroupStatusFilterItem';
import { IStatusesReducerState } from '../../interfaces/IStatusesReducerState';
import { compact } from 'lodash';
import { IRootState } from '.';
import { activityGroupStatuses } from '../../constants';
import { ActivityGroupStatus } from '@shared/interfaces/ActivityGroupStatus.enum';

const selectedActivityGroupStatuses = activityGroupStatuses.filter(
	(activityGroupStatus: IActivityGroupStatusFilterItem) => activityGroupStatus.status !== ActivityGroupStatus.complete
);

const initialState: IStatusesReducerState = {
	activityGroupStatuses: activityGroupStatuses,
	selectedActivityGroupStatuses: selectedActivityGroupStatuses,
};

const statusesSlice = createSlice({
	name: 'activityGroupStatuses',
	initialState,
	reducers: {
		addSelectedActivityGroupStatus: (
			state: IStatusesReducerState,
			action: PayloadAction<{ selectedStatus: IActivityGroupStatusFilterItem }>
		) => {
			const selectedActivityGroupStatusesAfterAdd: IActivityGroupStatusFilterItem[] = compact(
				state.selectedActivityGroupStatuses.concat(action.payload.selectedStatus)
			);
			state.selectedActivityGroupStatuses = selectedActivityGroupStatusesAfterAdd;
		},
		removeSelectedActivityGroupStatus: (
			state: IStatusesReducerState,
			action: PayloadAction<{ removedStatus: IActivityGroupStatusFilterItem }>
		) => {
			const selectedActivityGroupsStatusesAfterRemove: IActivityGroupStatusFilterItem[] =
				state.selectedActivityGroupStatuses.filter((selectedStatus) => {
					return action.payload.removedStatus.status !== selectedStatus.status;
				});

			state.selectedActivityGroupStatuses = selectedActivityGroupsStatusesAfterRemove;
		},
		defaultSelectedActivityGroupStatus: (state: IStatusesReducerState) => {
			state.selectedActivityGroupStatuses = selectedActivityGroupStatuses;
		},
		resetSelectedActivityGroupStatus: (state: IStatusesReducerState) => {
			state.selectedActivityGroupStatuses = [];
		},
	},
});

export const selectActivityGroupSelectedStatuses = (state: IRootState): IActivityGroupStatusFilterItem[] =>
	state.statusesReducer.selectedActivityGroupStatuses;

export const selectActivityGroupStatuses = (state: IRootState): IActivityGroupStatusFilterItem[] =>
	state.statusesReducer.activityGroupStatuses;

export const {
	addSelectedActivityGroupStatus,
	removeSelectedActivityGroupStatus,
	resetSelectedActivityGroupStatus,
	defaultSelectedActivityGroupStatus,
} = statusesSlice.actions;
export default statusesSlice.reducer;
