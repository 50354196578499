import React from 'react';
import { useHistory } from 'react-router-dom';
import { translationService } from '../../../../index';
import { AuthPage } from '../AuthPage';

interface IResetPasswordConfirmationPage {
	userEmail: string;
}

export const ResetPasswordConfirmationPage = (props: IResetPasswordConfirmationPage) => {
	const history = useHistory();
	return (
		<div style={{ direction: translationService.getDirection() }}>
			<AuthPage
				buttonText={translationService.get('backToLogin')}
				title={translationService.get('checkYourInbox')}
				onButtonClick={() => history.push(`/login/${translationService.getChosenLanguage()}`)}
			>
				<div>
					<p data-testid="reset-email-sent-label">
						{translationService.get('resetPasswordMailSent')}: <strong>{props.userEmail}</strong>
					</p>
				</div>
			</AuthPage>
		</div>
	);
};
