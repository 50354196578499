import React, { useRef, useState } from 'react';
import classnames from 'classnames';
import classes from './styles.module.scss';
import { IssueLocationSelector } from '@shared/components/IssueLocationsSelector/IssueLocationSelector';
import { IIssueLocation } from '@shared/interfaces/IIssueShared';
import { selectProjectId } from '@store/slices/project.slice';
import { useSelector } from 'react-redux';
import { useOutsideClickWithCallback } from '@src/hooks/custom.hooks';
import { translationService } from '@src/servicesInitializers';
import Popper, { PopperPlacementType } from '@material-ui/core/Popper';
import { ArrowUpDown } from '@shared/components/ArrowUpDown/ArrowUpDown';
import { isEventInsideElementId } from '@utils/generalUtils';

interface IIssueLocationSelectorPopperMenuProps {
	handleLocationsChange: (locations: IIssueLocation[]) => void;
	initialSelectedLocations?: IIssueLocation[] | null;
	selectedLocationsAmount?: number;
	isDialog?: boolean;
}

export const IssueLocationSelectorPopperMenu = (props: IIssueLocationSelectorPopperMenuProps) => {
	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => {
		event.stopPropagation();
		setAnchorEl(anchorEl ? null : event.currentTarget);
	};

	const selectLocationContainerId = 'selectLocationContainer';
	const isOpen = Boolean(anchorEl);
	// The Popper's id is used for accessibility purposes, not to match the button's id.
	const id = isOpen ? 'simple-popper' : undefined;
	const projectId: string = useSelector(selectProjectId)!;
	const issueLocationSelectorDropdownRef = useRef(null);

	useOutsideClickWithCallback(issueLocationSelectorDropdownRef, (event) => {
		if (isEventInsideElementId(event, selectLocationContainerId)) {
			return;
		}
		setAnchorEl(null);
	});

	const getSelectLocationsText = (): string => {
		if (!props.selectedLocationsAmount) {
			return translationService.get('selectLocation');
		}
		if (props.selectedLocationsAmount === 1) {
			return `${props.selectedLocationsAmount} ${translationService.get('location')}`;
		}
		return `${props.selectedLocationsAmount} ${translationService.get('locations')}`;
	};
	const popperClassname: string = classnames(classes.popper, {
		[classes.popperDialog]: props.isDialog,
	});
	const placement: PopperPlacementType = props.isDialog ? 'bottom' : 'bottom-start';

	return (
		<>
			<div
				className={classes.issueLocationSelectorDropdownContainer}
				onClick={(event) => handleClick(event)}
				aria-describedby={id}
				id={selectLocationContainerId}
			>
				<div
					className={classnames(classes.selectedIssuesText, {
						[classes.locationsText]: !!props.selectedLocationsAmount,
					})}
				>
					{getSelectLocationsText()}
				</div>
				<ArrowUpDown isOpen={isOpen} />
			</div>
			<Popper
				ref={issueLocationSelectorDropdownRef}
				id={id}
				open={isOpen}
				anchorEl={anchorEl}
				placement={placement}
				className={popperClassname}
			>
				<div className={classes.issueLocationSelectorContainer}>
					<IssueLocationSelector
						onUpdate={props.handleLocationsChange}
						initialSelectedLocations={props.initialSelectedLocations}
						projectId={projectId}
					/>
				</div>
			</Popper>
		</>
	);
};
