import { uniqBy } from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISafetyEventsReducerState } from '../../interfaces/ISafetyEventsReducerState';
import { IMinimalSafetyEventForClient } from '../../interfaces/ISafetyEvent';
import { SafetyEventStatuses } from '../../constants';
import { HTTPRequestStatuses } from '@shared/interfaces/HTTPRequestStatuses.enum';

const initialState: ISafetyEventsReducerState = {
	safetyEvents: [],
	visibleCriticalSafetyEvents: [],
	visibleSafetyEvents: [],
	safetyEventsRequestStatus: HTTPRequestStatuses.idle,
};

const safetyEventsSlice = createSlice({
	name: 'safetyEvents',
	initialState,
	reducers: {
		updateVisibleCriticalSafetyEvents: (
			state: ISafetyEventsReducerState,
			action: PayloadAction<{
				criticalSafetyEvents: IMinimalSafetyEventForClient[];
			}>
		) => {
			state.visibleCriticalSafetyEvents = uniqBy(
				[...action.payload.criticalSafetyEvents, ...state.visibleCriticalSafetyEvents],
				'_id'
			);
		},
		clearVisibleCriticalSafetyEvents: (state: ISafetyEventsReducerState) => {
			state.visibleCriticalSafetyEvents = [];
		},
		updateSafetyEvents: (
			state: ISafetyEventsReducerState,
			action: PayloadAction<{ safetyEvents: IMinimalSafetyEventForClient[] }>
		) => {
			state.safetyEventsRequestStatus = HTTPRequestStatuses.success;
			state.safetyEvents = uniqBy([...action.payload.safetyEvents, ...state.safetyEvents], '_id');
			state.visibleCriticalSafetyEvents = uniqBy(
				[...action.payload.safetyEvents, ...state.visibleCriticalSafetyEvents],
				'_id'
			).filter((event) => event.isCritical && event.status === SafetyEventStatuses.active);
		},
		endCriticalEvent: (state: ISafetyEventsReducerState, action: PayloadAction<{ safetyEventId: string }>) => {
			state.visibleCriticalSafetyEvents = state.visibleCriticalSafetyEvents.filter(
				(event) => event._id !== action.payload.safetyEventId
			);
			state.safetyEvents = state.safetyEvents.map((event) => {
				return event._id === action.payload.safetyEventId
					? { ...event, status: SafetyEventStatuses.close }
					: event;
			});
		},
		updateCurrentlyVisibleEvents: (
			state: ISafetyEventsReducerState,
			action: PayloadAction<{
				visibleSafetyEvents: IMinimalSafetyEventForClient[];
			}>
		) => {
			state.visibleSafetyEvents = action.payload.visibleSafetyEvents;
		},
		resetSafety: (state: ISafetyEventsReducerState) => {
			state.safetyEvents = [];
			state.visibleCriticalSafetyEvents = [];
			state.visibleSafetyEvents = [];
			state.safetyEventsRequestStatus = HTTPRequestStatuses.idle;
		},
		updateSafetyEventsRequestStatus: (
			state: ISafetyEventsReducerState,
			action: PayloadAction<{ status: HTTPRequestStatuses }>
		) => {
			state.safetyEventsRequestStatus = action.payload.status;
		},
	},
});

export const {
	resetSafety,
	updateVisibleCriticalSafetyEvents,
	updateCurrentlyVisibleEvents,
	endCriticalEvent,
	updateSafetyEvents,
	clearVisibleCriticalSafetyEvents,
	updateSafetyEventsRequestStatus,
} = safetyEventsSlice.actions;
export default safetyEventsSlice.reducer;
