import React from 'react';
import classes from './styles.module.scss';
import { MainProgressStatsSection } from '@src/Components/SequenceProgressAnalysis/OverallProgress/MainProgressStatsSection/MainProgressStatsSection';
import { Loader } from '@shared/components/Loader/Loader';
import { ISequenceItemProgressPeriodOverallReport } from '@shared/interfaces/ISequenceItemProgressPeriodOverallReport';
import { useOverallProgressSummaryQuery } from '@src/hooks/queries/sequenceItemProgressSummary.queries.hooks';
import { getTimezoneFormattedDate } from '@shared/utils/dateUtils';
import { IProject } from '@shared/interfaces/IProject';
import { useSelector } from 'react-redux';
import { selectWorkingProject } from '@store/slices/project.slice';

interface IOverallProgressProps {
	startDate: Date;
	endDate: Date;
}

export const OverallProgress = (props: IOverallProgressProps) => {
	const project: IProject = useSelector(selectWorkingProject)!;

	const { overallProgressSummary, isLoading } = useOverallProgressSummaryQuery(
		project.projectId,
		getTimezoneFormattedDate(project.tz, props.startDate),
		getTimezoneFormattedDate(project.tz, props.endDate)
	);

	return (
		<div className={classes.OverallProgress_container} data-testid="OverallProgress">
			{isLoading ? <Loader /> : <MainProgressStatsSection overallProgressSummary={overallProgressSummary} />}
		</div>
	);
};
