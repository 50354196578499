import React from 'react';
import { translationService } from '@src/servicesInitializers';
import { SequenceLinkWarningDialog } from '@src/Components/SequenceItemLinkTable/SequenceLinkTableContainer/WarningDialogs/SequenceLinkWarningDialog/SequenceLinkWarningDialog';

interface IBulkUnlinkeWarningDialogProps {
	show: boolean;
	hideDialog: () => void;
	unlinkSequenceItemsAction: () => void;
}

export const BulkUnlinkWarningDialog = (props: IBulkUnlinkeWarningDialogProps) => {
	return (
		<SequenceLinkWarningDialog
			show={props.show}
			hideDialog={props.hideDialog}
			actionFunction={props.unlinkSequenceItemsAction}
			title={translationService.get('sequence_unlink_table_warning_dialog_title')}
			content={translationService.get('sequence_unlink_table_warning_dialog_some_items_are_linked_toOther')}
		/>
	);
};
