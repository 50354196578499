import React, { useEffect, useState } from 'react';
import { ActionConfirmationDialog } from '@src/Components/WorkplanWidgetComponents/ActionConfirmationDialog/ActionConfirmationDialog';
import { requestService, translationService } from '@src/servicesInitializers';
import { IAssignedActivity } from '@src/interfaces';
import { useSequenceItemActivitiesQuery } from '@src/hooks/queries/sequenceItem.query';

interface SequenceItemDeleteConfirmationDialogProps {
	showConfirmationDialog: boolean;
	sequenceItemId: string;
	setShowConfirmationDialog: (showConfirmationDialog: boolean) => void;
	deleteSequenceItem?: (sequenceId: string) => void;
}

export const SequenceItemDeleteConfirmationDialog = (props: SequenceItemDeleteConfirmationDialogProps) => {
	const { activities } = useSequenceItemActivitiesQuery(props.sequenceItemId!, props.showConfirmationDialog);

	const confirmationText =
		activities.length > 0
			? translationService.get('deleteSequenceItemConfirmationTextWithActivities', {
					activitiesLength: activities.length.toString(),
			  })
			: '';

	return (
		<ActionConfirmationDialog
			show={props.showConfirmationDialog}
			onClose={() => props.setShowConfirmationDialog(false)}
			mainButtonAction={() => {
				props.setShowConfirmationDialog(false);
				props.deleteSequenceItem?.(props.sequenceItemId!);
			}}
			secondaryButtonAction={() => props.setShowConfirmationDialog(false)}
			title={translationService.get('deleteSequenceItemConfirmation')}
			text={confirmationText}
		/>
	);
};
