import { ITableComponentProps } from '@interfaces/ITableComponentProps';
import { ISequenceItemProgressBySequenceItemReport } from '@interfaces/ISequenceItemProgressBySequenceItemReport';
import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { selectProjectId } from '@store/slices/project.slice';
import classes from '@src/Components/SequenceProgressAnalysis/ActivitiesProgress/ActivitiesProgressTable/styles.module.scss';
import { ProfessionDisplayWithTradeIcon } from '@shared/components/ProfessionDisplayWithTradeIcon/ProfessionDisplayWithTradeIcon';

export const ProfessionCell = (
	props: ITableComponentProps<ISequenceItemProgressBySequenceItemReport>
): ReactElement<ITableComponentProps<ISequenceItemProgressBySequenceItemReport>> => {
	const projectId: string = useSelector(selectProjectId)!;
	const report: ISequenceItemProgressBySequenceItemReport = props.data;

	return (
		<div className={classes.professionCell}>
			<ProfessionDisplayWithTradeIcon
				isNarrow
				contractorTextClassname={classes.contractorText}
				profession={report.profession}
				projectId={projectId}
			/>
		</div>
	);
};
