import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetFilteredAreasList, setAreasListFilter } from '@store/slices/areasList.slice';
import { APP_FILTER_TYPES } from '@src/constants';
import { IRootState } from '@store/slices';
import { AreaFilterCard } from '@shared/components/Filters/AreaFilterCard/AreaFilterCard';
import { translationService } from '@src/servicesInitializers';
import { IBaseAreaBaseFloor } from '@shared/interfaces/IBaseAreaBaseFloor';
import { Dictionary, groupBy, uniqBy } from 'lodash';
import { FloorFilterCard } from '@shared/components/Filters/FloorFilterCard/FloorFilterCard';
import { TrusstorIcon } from '@src/Components/TrusstorIcon/TrusstorIcon';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { ExpandableComponent } from '../MainPageSections/ExpandableComponent/ExpandableComponent';

interface IAreasFilterSectionProps {
	projectAreas: IBaseAreaBaseFloor[];
	handleResetClick: () => void;
	areasCountObj: { [areaId: string]: number };
}

const AreasFilterSection = (props: IAreasFilterSectionProps) => {
	const dispatch = useDispatch();
	const [expandedFloorNicks, setExpandedFloorNicks] = useState<string[]>([]);

	const areasFilterList: IBaseAreaBaseFloor[] = useSelector(
		(state: IRootState) => state.areasListReducer.areasFilterList
	);
	const areasGroupedByFloor: Dictionary<IBaseAreaBaseFloor[]> = useMemo(
		() => groupBy(props.projectAreas, (area) => area.floorNick),
		[props.projectAreas]
	);
	const handleResetClick = () => {
		dispatch(resetFilteredAreasList());
	};

	const addFloor = (floorNick: string) => {
		const floorAreas = props.projectAreas.filter((area) => area.floorNick === floorNick);
		const newFilterList: IBaseAreaBaseFloor[] = uniqBy(
			[...areasFilterList, ...floorAreas],
			(a: IBaseAreaBaseFloor) => a.areaId
		);
		dispatch(setAreasListFilter({ filterList: newFilterList }));
	};

	const removeFloor = (floorNick: string) => {
		const newFilterList: IBaseAreaBaseFloor[] = areasFilterList.filter((a) => a.floorNick !== floorNick);
		dispatch(setAreasListFilter({ filterList: newFilterList }));
	};

	const addArea = (area) => {
		const newFilterList: IBaseAreaBaseFloor[] = uniqBy(
			[...areasFilterList, area],
			(a: IBaseAreaBaseFloor) => a.areaId
		);
		dispatch(setAreasListFilter({ filterList: newFilterList }));
	};

	const removeArea = (area: IBaseAreaBaseFloor) => {
		dispatch(
			setAreasListFilter({
				filterList: areasFilterList.filter((a) => a.areaId !== area.areaId),
			})
		);
	};

	const toggleFloorExpanded = (floorNick: string) => {
		const newExpandedFloorNicks = expandedFloorNicks.includes(floorNick)
			? expandedFloorNicks.filter((n) => n !== floorNick)
			: [...expandedFloorNicks, floorNick];
		setExpandedFloorNicks(newExpandedFloorNicks);
	};

	return (
		<ExpandableComponent
			headerText={translationService.get('areas')}
			childrenLength={props.projectAreas.length}
			shouldHideClearButton={areasFilterList.length === 0}
			clearFunction={handleResetClick}
			clearLength={areasFilterList.length}
			type={APP_FILTER_TYPES.areaFilter}
			hideEmptyList
			testId={'areaFilterSection'}
			defaultOpen
		>
			{Object.keys(areasGroupedByFloor).map((floorNick) => {
				const floorAreas: IBaseAreaBaseFloor[] = props.projectAreas.filter(
					(area) => area.floorNick === floorNick
				);
				if (!floorAreas.length) {
					return null;
				}
				const isFloorSelected: boolean = !props.projectAreas.some(
					(area) =>
						area.floorNick === floorNick &&
						!areasFilterList.some((listArea) => area.areaId === listArea.areaId)
				);
				const isFloorExpanded: boolean = expandedFloorNicks.includes(floorNick);
				return (
					<>
						<FloorFilterCard
							floorNick={floorNick}
							key={floorNick}
							isSelected={isFloorSelected}
							handleClick={() => (isFloorSelected ? removeFloor(floorNick) : addFloor(floorNick))}
							endComponent={
								<TrusstorIcon
									iconName={isFloorExpanded ? IconNames.chevronUp : IconNames.chevronDown}
									onClick={(e) => {
										e.stopPropagation();
										toggleFloorExpanded(floorNick);
									}}
								/>
							}
						/>
						{isFloorExpanded &&
							floorAreas.map((area) => {
								const isSelected: boolean = areasFilterList.some(
									(listArea) => area.areaId === listArea.areaId
								);
								return (
									<AreaFilterCard
										key={area.areaId}
										handleClick={isSelected ? () => removeArea(area) : () => addArea(area)}
										areaNick={area.areaNick}
										isSelected={isSelected}
										count={props.areasCountObj?.[area.areaId]}
										hasStartMargin
									/>
								);
							})}
					</>
				);
			})}
		</ExpandableComponent>
	);
};

export { AreasFilterSection };
