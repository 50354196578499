import { ITableComponentProps } from '@interfaces/ITableComponentProps';
import { ISequenceItemProgressBySequenceItemReport } from '@interfaces/ISequenceItemProgressBySequenceItemReport';
import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { selectTimezone, selectWorkingProject } from '@store/slices/project.slice';
import classes from '@src/Components/SequenceProgressAnalysis/ActivitiesProgress/ActivitiesProgressTable/styles.module.scss';
import { translationService } from '@src/servicesInitializers';
import moment from 'moment/moment';
import classnames from 'classnames';
import {
	isETABelowThreshold,
	isProgressReportCompleted,
} from '@src/Components/SequenceProgressAnalysis/ActivitiesProgress/ActivitiesProgressTable/utils';
import { IProject } from '@interfaces/index';
import { getHealthScoreColor } from '@src/Components/SequenceProgressAnalysis/MilestonesProgress/MilestoneSelectorCarousel/MilestoneSelectorCard/milestoneSelectorCard.utils';
import { useIsProgressAnalysisV2 } from '@src/hooks/useIsProgressAnalysisV2.hooks';
import { getDateDisplayFormat } from '@shared/utils/dateUtils';

export const ETACell = (
	props: ITableComponentProps<ISequenceItemProgressBySequenceItemReport>
): ReactElement<ITableComponentProps<ISequenceItemProgressBySequenceItemReport>> => {
	const report: ISequenceItemProgressBySequenceItemReport = props.data;
	const tz: string = useSelector(selectTimezone)!;
	const project: IProject = useSelector(selectWorkingProject)!;
	const isDemoProject: boolean = !!project.isDemo;
	const roundedWeeksToComplete: number = Math.round(report.estimatedWeeksToComplete ?? 0);
	const isCompleted: boolean = isProgressReportCompleted(report);
	const isProgressAnalysisV2: boolean = useIsProgressAnalysisV2();

	if (
		!isProgressReportCompleted(report) &&
		(report.etaDate === null ||
			report.estimatedWeeksToComplete === null ||
			isETABelowThreshold(report) ||
			roundedWeeksToComplete <= 0) &&
		!isDemoProject
	) {
		return (
			<div className={classnames(classes.etaEmptyState, classes.etaCell)}>{translationService.get('TBD')}</div>
		);
	}

	const weeksText: string = (() => {
		if (isCompleted) {
			return translationService.get('progressReport_activitiesTable_completed');
		}
		if (roundedWeeksToComplete === 1) {
			return translationService.get('oneWeek');
		}
		if (roundedWeeksToComplete === 2) {
			return translationService.get('twoWeeks');
		}

		return `${roundedWeeksToComplete} ${translationService.get('weeks')}`;
	})();

	const etaMonth: string = getDateDisplayFormat(
		moment.tz(report.etaDate, tz).toDate(),
		tz,
		translationService,
		'monthAndYear'
	);

	const completedEtaMonth: string = getDateDisplayFormat(
		report.completedDate,
		tz,
		translationService,
		'monthAndYear'
	);

	return (
		<div className={classes.etaCell}>
			<div className={classes.weeksAndIndicator}>
				{isProgressAnalysisV2 && !isCompleted && (
					<div
						className={classes.riskIndicator}
						style={{
							backgroundColor: getHealthScoreColor(report.healthScore),
						}}
					/>
				)}
				<div className={classnames({ [classes.weeks]: true, [classes.completed]: isCompleted })}>
					{weeksText}
				</div>
			</div>
			<div className={classes.month}>{isCompleted ? completedEtaMonth : etaMonth}</div>
		</div>
	);
};
