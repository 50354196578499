import React, { useContext, useEffect, useMemo, useState } from 'react';
import { compact, isArray, orderBy } from 'lodash';
import { accessLevelService, translationService } from '../../../index';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { IBaseFloor, IProfession, IUser, IWeekDayActivity, IWorkPlanActivity } from '../../../interfaces';
import {
	createActivity,
	deleteActivityByGroupId,
	updateActivity,
	updateAreaSequenceItemsByActivities,
	workplanAvailableGroupInputs,
} from '@utils/workPlan.utils';
import './styles.module.scss';
import { CalendarToggleOptions, WorkplanInputNames } from '../../../constants';
import { convertDateObjectToServerFormat, getTimezoneStartOfDate } from '@utils/dateUtils';
import { IRootState } from '@store/slices';
import { workplanDialogLog_BI } from '@utils/bi.utils';
import { useWorkplanDialogAccess_BI } from '@src/hooks/bi.hooks';
import { GeneralDialog } from '../../GeneralDialog/GeneralDialog';
import { TrusstorCheckboxWithText } from '../../Inputs/TrusstorCheckboxWithText/TrusstorCheckboxWithText';
import { ActivityGroupStatus } from '@shared/interfaces/ActivityGroupStatus.enum';
import { IConfigArea } from '@shared/interfaces/IConfigArea';
import { LocationSelector } from '@src/Components/Dropdowns/Location/LocationSelector/LocationSelector';
import { getFloorsFromAreas } from '@utils/areas.utils';
import { useProjectAreasQuery } from '@src/hooks/queries/areas.queries.hooks';
import { errorSnackbar, successSnackbar } from '@utils/snackbar.utils';
import { IWorkplanContext, WorkplanContext } from '@src/Components/Pages/Workplan/WorkPlan';
import { TrusstorTextInput } from '@shared/components/Inputs/TrusstorTextInput/TrusstorTextInput';
import { SingleDropdown } from '@src/Components/Dropdowns/DesignSystem/SingleDropdown/SingleDropdown';
import { DateSelectorTrusstorInput } from '@src/Components/DateSelector/DateSelectorTrusstorInput/DateSelectorTrusstorInput';
import classes from './styles.module.scss';
import { useUsersQuery } from '@src/hooks/queries/users.queries.hooks';
import { SingleProfessionGroupedDropdown } from '@src/Components/Dropdowns/Profession/SingleProfessionGroupedDropdown/SingleProfessionGroupedDropdown';
import { selectWorkingProfessions } from '@store/slices/professions.slice';

interface IWorkPlanDialogProps {
	closeDialog: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	onChangeData: () => Promise<void>;
	activity?: IWorkPlanActivity;
	section?: { key: string; value: any };
	activityStartDate: Date;
	areaSequenceItemMode?: boolean;
}

const WorkPlanDialog = (props: IWorkPlanDialogProps) => {
	const userHasAccessToActions: boolean = accessLevelService.hasAccess('workplanActivityActions');
	const isEditMode: boolean = !!props.activity;
	const notDeletedProfessions: IProfession[] = useSelector(selectWorkingProfessions).filter(
		(profession) => !profession.dateDeleted
	);
	const projectId: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.projectId);
	const tz: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.tz);
	const [validations, setValidations] = useState({} as any);
	const [isSubmitEnabled, setIsSubmitEnabled] = useState<boolean>(false);
	const [pushEndDate, setPushEndDate] = useState<boolean>(false);
	const workplanContext: IWorkplanContext | undefined = useContext(WorkplanContext);
	const projectAreas: IConfigArea[] = useProjectAreasQuery(projectId);
	const [initialEditedFloors, setInitialEditedFloors] = useState<IBaseFloor[] | undefined>(undefined);
	const [initialEditedAreas, setInitialEditedAreas] = useState<IConfigArea[] | undefined>(undefined);
	const { users }: { users: IUser[] } = useUsersQuery(projectId);

	const dispatch = useDispatch();
	const openDialogTimeStamp: number | undefined = useWorkplanDialogAccess_BI(tz, props.activity);

	const initialDatesDiff: number = useMemo(() => {
		return moment(props.activity?.endDate).diff(moment(props.activity?.startDate), 'days');
	}, [props.activity]);

	const getInitialNewActivity = (): Partial<IWorkPlanActivity> => {
		const newActivity: Partial<IWorkPlanActivity> = {
			...(props.section && { [props.section.key]: props.section.value }),
			startDate: props.activityStartDate,
			endDate: props.activityStartDate,
			areas: [],
		};

		if (props.section?.key === CalendarToggleOptions.FLOORS) {
			newActivity.floors = [props.section.value];
		}

		return newActivity;
	};

	const [editableActivity, setEditableActivity] = useState<Partial<IWeekDayActivity>>(
		props.activity || getInitialNewActivity()
	);

	const activityAreas: IConfigArea[] = useMemo(() => {
		return props.activity?.areas
			? compact(
					props.activity?.areas.map((area) => {
						const areaFromProject: IConfigArea | undefined = projectAreas.find(
							(projectArea) => projectArea.areaId === area.areaId
						);
						if (!areaFromProject) {
							return undefined;
						}
						return areaFromProject;
					})
				)
			: [];
	}, [props.activity, projectAreas]);

	useEffect(() => {
		const startDateTimeStamp: number = Date.parse(String(editableActivity.startDate));
		const endDateTimeStamp: number = Date.parse(String(editableActivity.endDate));
		if (pushEndDate) {
			changeEditableActivityByFieldName(
				moment.tz(editableActivity.startDate, tz).add(initialDatesDiff, 'days').toDate(),
				'endDate'
			);
			setPushEndDate(false);
			return;
		}
		if (endDateTimeStamp < startDateTimeStamp) {
			changeEditableActivityByFieldName(editableActivity.startDate, 'endDate');
		}
	}, [editableActivity.startDate, editableActivity.endDate]);

	const isFormCanBeSubmitted = (): boolean => {
		const requiredFields: string[] = ['description', 'profession', 'floors'];
		const areAllRequiredFieldFilled: boolean = requiredFields.every((field) =>
			isArray(editableActivity[field]) ? editableActivity[field].length > 0 : editableActivity[field]
		);

		if (!props.activity) {
			return areAllRequiredFieldFilled;
		}

		return areAllRequiredFieldFilled && didFieldsChanged();
	};

	const didFieldsChanged = (): boolean => {
		const conditions: boolean[] = [
			props.activity?.description !== editableActivity.description,
			!!props.activity?.dangerousActivity !== !!editableActivity.dangerousActivity,
			!!(
				props.activity?.endDate &&
				editableActivity.endDate &&
				new Date(props.activity.endDate).getTime() !== new Date(editableActivity.endDate).getTime()
			),
			!!(
				props.activity?.startDate &&
				editableActivity.startDate &&
				new Date(props.activity.startDate).getTime() !== new Date(editableActivity.startDate).getTime()
			),
			!!editableActivity.floors?.filter(
				(floor) => !props.activity?.floors?.some((activityFloor) => activityFloor.floorId === floor.floorId)
			).length,
			editableActivity.floors?.length !== props.activity?.floors?.length,
			!(!!props.activity?.assignee === false && !!editableActivity.assignee === false) &&
				props.activity?.assignee !== editableActivity.assignee,
			editableActivity.areas?.length !== props.activity?.areas?.length ||
				!!editableActivity.areas?.filter(
					(area) => !props.activity?.areas?.some((activityArea) => activityArea.areaId === area.areaId)
				).length,
			props.activity?.profession?._id !== editableActivity.profession?._id,
		];

		return conditions.some((c) => c);
	};

	useEffect(() => {
		setIsSubmitEnabled(isFormCanBeSubmitted());
	}, [editableActivity]);

	const changeEditableActivityByFieldName = (data: any, fieldName: string) => {
		setEditableActivity((prevEditableActivity: Partial<IWorkPlanActivity>) => ({
			...prevEditableActivity,
			[fieldName]: data,
		}));
	};

	const onDialogClose = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		setValidations({});
		props.closeDialog(event);
	};

	const editFieldInActivity = (data: any, field: string) => {
		if (validations[field]) {
			setValidations((prevState: any) => ({ ...prevState, [field]: false }));
		}
		changeEditableActivityByFieldName(data, field);
	};

	const createActivityAndShowSnackbar = async (
		editableActivity: Partial<IWorkPlanActivity>,
		projectId: string,
		tz: string
	) => {
		try {
			await createActivity(editableActivity, projectId, tz);
			await props.onChangeData();
			successSnackbar(dispatch, translationService.get('activityWasSuccessfullyCreated'));
		} catch (e) {
			errorSnackbar(dispatch, translationService.get('activityCreationFailed'));
		}
	};

	const updateActivityAndShowSnackbar = async (
		editableActivity: Partial<IWorkPlanActivity>,
		projectId: string,
		tz: string
	) => {
		try {
			const updatedActivities: IWorkPlanActivity[] = await updateActivity(editableActivity, tz, projectId);
			await props.onChangeData();
			if (workplanContext) {
				updateAreaSequenceItemsByActivities(
					updatedActivities,
					workplanContext.updateAreaSequenceItem,
					workplanContext.matrix.recreateTable,
					projectId,
					workplanContext.selectedSequenceId
				);
			}

			successSnackbar(dispatch, translationService.get('activityWasSuccessfullyUpdated'));
		} catch (e) {
			errorSnackbar(dispatch, translationService.get('activityUpdateFailed'));
		}
	};

	const saveActivity = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		workplanDialogLog_BI(editableActivity, openDialogTimeStamp!, tz, true, props.activity);

		if (isEditMode) {
			updateActivityAndShowSnackbar(editableActivity, projectId, tz);
		} else {
			if (!editableActivity.endDate && editableActivity.startDate) {
				changeEditableActivityByFieldName(new Date(editableActivity.startDate), 'endDate');
			}
			if (!editableActivity.areas) {
				changeEditableActivityByFieldName([], 'areas');
			}
			createActivityAndShowSnackbar(editableActivity, projectId, tz);
		}

		props.closeDialog(event);
	};

	const deleteActivity = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		event.preventDefault();
		if (props.activity) {
			deleteActivityByGroupId(props.activity.groupId as string);
			await props.onChangeData();
			onDialogClose(event);
		}
	};

	const changeProfessionInput = (value: IProfession | null): void => {
		editFieldInActivity(value, 'profession');
	};

	const changeAssigneeInput = (value: IUser | null): void => {
		if (props.activity?.assignee && !users.length && !value) {
			return;
		}

		editFieldInActivity(value?.username, 'assignee');
	};

	const checkDisableByInput = (inputName: WorkplanInputNames): boolean => {
		const groupStatus: ActivityGroupStatus | undefined = props.activity?.status;
		if (!groupStatus) return false;
		const availableInputs: WorkplanInputNames[] = workplanAvailableGroupInputs[groupStatus];
		return !availableInputs.includes(inputName);
	};

	const getPushDateElement = () => {
		if (!isEditMode) return <></>;
		return (
			<div>
				<div style={{ visibility: 'hidden' }}>dont remove this - line break</div>
				<TrusstorCheckboxWithText
					text={translationService.get('pushEndDate')}
					extraLightText={translationService.get('endDateAutoUpdate')}
					className={classes.checkboxDate}
					disabled={checkDisableByInput(WorkplanInputNames.pushEndDate)}
					checked={pushEndDate}
					onChange={setPushEndDate}
				/>
			</div>
		);
	};

	const onLocationChange = (floors: IBaseFloor[], areas: IConfigArea[]) => {
		const doesFloorsExist = floors.length > 0;
		const doesAreasExist = areas.length > 0;
		if (!doesFloorsExist && !doesAreasExist) {
			changeEditableActivityByFieldName([], 'floors');
			changeEditableActivityByFieldName([], 'areas');
			return undefined;
		}
		const updatedFloors: IBaseFloor[] = !doesAreasExist ? floors : getFloorsFromAreas(areas);
		changeEditableActivityByFieldName(updatedFloors, 'floors');
		changeEditableActivityByFieldName(areas, 'areas');
	};

	useEffect(() => {
		if (!props.activity) {
			setInitialEditedFloors([]);
		}

		if (initialEditedFloors) {
			return;
		}

		setInitialEditedFloors(props.activity?.floors);
	}, [props.activity?.floors]);

	useEffect(() => {
		if (!props.activity) {
			setInitialEditedAreas([]);
		}

		if (initialEditedAreas) {
			return;
		}

		setInitialEditedAreas(activityAreas);
	}, [activityAreas]);

	return (
		<GeneralDialog
			show={true}
			close={onDialogClose}
			title={
				isEditMode
					? translationService.get('workPlanDialogEditTitle')
					: translationService.get('workPlanDialogCreateTitle')
			}
			mainButton={{
				text: isEditMode ? translationService.get('update') : translationService.get('create'),
				show: true,
				click: saveActivity,
				disabled: !isSubmitEnabled,
			}}
			secondaryButton={{
				show: true,
			}}
			tertiaryButton={{
				show: isEditMode && props.activity?.status === ActivityGroupStatus.delayed && userHasAccessToActions,
				click: deleteActivity,
			}}
		>
			<div className={classes.container}>
				<div className={classes.professionsDropdownContainer}>
					<SingleProfessionGroupedDropdown
						professions={orderBy(
							notDeletedProfessions,
							[
								(profession: IProfession) => profession.sortIndex,
								(profession: IProfession) => profession.tradeId,
							],
							['asc', 'asc']
						)}
						onChange={changeProfessionInput}
						defaultSelectedProfession={editableActivity?.profession}
						disabled={
							(isEditMode && checkDisableByInput(WorkplanInputNames.profession)) ||
							!!props.areaSequenceItemMode
						}
						required
					/>
				</div>
				<div>
					<TrusstorTextInput
						required
						disabled={
							(isEditMode && checkDisableByInput(WorkplanInputNames.description)) ||
							props.areaSequenceItemMode ||
							!userHasAccessToActions
						}
						value={editableActivity?.description || ''}
						changeFunc={(val) => editFieldInActivity(val, WorkplanInputNames.description)}
						label={translationService.get('workPlanDescription')}
						testId="activity-description"
					/>
				</div>
				<LocationSelector
					setInitialSelectedAreas={(areas) => setInitialEditedAreas(areas)}
					setInitialSelectedFloors={(floors) => setInitialEditedFloors(floors)}
					onChange={onLocationChange}
					selectedFloors={initialEditedFloors}
					selectedAreas={initialEditedAreas}
					isDisabled={
						(isEditMode && checkDisableByInput(WorkplanInputNames.floors)) || !!props.areaSequenceItemMode
					}
				/>
				<div className={classes.sharedRow}>
					<div>
						<DateSelectorTrusstorInput
							calendarPosition={'bottom'}
							handleTimeChange={(date: Date) => {
								changeEditableActivityByFieldName(date, WorkplanInputNames.startDate);
							}}
							selectDefaultDate
							selectedDate={editableActivity.startDate ? new Date(editableActivity.startDate) : null}
							minDate={new Date()}
							label={translationService.get('workPlanStartDate')}
							children={getPushDateElement()}
							disableDatePicker={
								(isEditMode && checkDisableByInput(WorkplanInputNames.startDate)) ||
								!userHasAccessToActions
							}
							required
							testId={'activity-start-date'}
							hideIcon
						/>
					</div>
					<div>
						<DateSelectorTrusstorInput
							calendarPosition={'bottom'}
							handleTimeChange={(date: Date) => {
								changeEditableActivityByFieldName(date, WorkplanInputNames.endDate);
							}}
							selectDefaultDate
							selectedDate={editableActivity.endDate ? new Date(editableActivity.endDate) : null}
							minDate={editableActivity?.startDate}
							label={translationService.get('workPlanEndDate')}
							disableDatePicker={
								(isEditMode && checkDisableByInput(WorkplanInputNames.endDate)) ||
								!userHasAccessToActions
							}
							required
							testId="activity-end-date"
							hideIcon
						/>
					</div>
				</div>
				<SingleDropdown
					testId="assignee-dropdown"
					placeholder={translationService.get('selectAssignee')}
					options={users}
					onChange={changeAssigneeInput}
					getDisplayOption={(option: IUser) => option.name}
					disabled={
						!userHasAccessToActions || (isEditMode && checkDisableByInput(WorkplanInputNames.assignee))
					}
					value={users.find((user) => user.username === editableActivity?.assignee)}
				/>
				<div>
					<TrusstorCheckboxWithText
						disabled={
							!userHasAccessToActions ||
							(isEditMode && checkDisableByInput(WorkplanInputNames.dangerousActivity))
						}
						checked={!!editableActivity?.dangerousActivity}
						onChange={(checked: boolean) =>
							changeEditableActivityByFieldName(checked, WorkplanInputNames.dangerousActivity)
						}
						text={translationService.get('dangerousActivity')}
						testId="dangerous-activity-checkbox"
					/>
				</div>
			</div>
		</GeneralDialog>
	);
};

export { WorkPlanDialog };
