import { IProfession } from '@shared/interfaces/IProfession';
import { IRowProperty } from '../../../interfaces/IRowProperty';
import { IUser } from '@shared/interfaces/IUser';
import { translationService } from '../../../index';
import { getProfessionsById } from '@shared/utils/professions.utils';
import { getProfessionDisplayText } from '../../../utils/professions.utils';

export const getTableRowProperties = (professions: IProfession[]): IRowProperty[] => {
	return [
		{ firstField: 'name' },
		{ firstField: 'email' },
		{ firstField: 'phoneNumber' },
		{
			firstField: 'permissions',
			secondField: 'roleType',
			useTranslationService: true,
			center: true,
			getSubText: (user: IUser) => {
				if (!user.permissions.permittedProfessionsIds) return '';
				if (user.permissions.permittedProfessionsIds.length > 1) {
					const professionsCount = user.permissions.permittedProfessionsIds.length;
					return `${professionsCount} ${translationService.get('professions')}`;
				}

				const profession: IProfession | undefined = getProfessionsById(
					professions,
					user.permissions.permittedProfessionsIds[0]
				);
				if (!profession) return '';
				return getProfessionDisplayText(profession);
			},
		},
	];
};
