import React from 'react';
import classes from './styles.module.scss';
import { TrusstorIcon } from '@src/Components/TrusstorIcon/TrusstorIcon';
import { IconColor } from '@shared/components/TrusstorIconShared/TrusstorIconShared';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { translationService } from '@src/servicesInitializers';

interface ISpecialFloorInfoProps {
	isHoist: boolean;
	isOperatorInHoist: boolean;
	showSafetyIcon: boolean;
	safetyIcon: React.ReactNode;
}

export const SpecialFloorInfo = (props: ISpecialFloorInfoProps) => {
	return (
		<div className={classes.SpecialFloorInfo_container} data-testid="SpecialFloorInfo">
			{props.isHoist ? (
				<div className={classes.hoistOperator}>
					<TrusstorIcon
						color={props.isOperatorInHoist ? IconColor.Green500 : IconColor.Red900}
						iconName={props.isOperatorInHoist ? IconNames.userCheck : IconNames.userCross}
					/>
					<div>{translationService.get('operator')}</div>
				</div>
			) : (
				<div className={classes.safetyIcon}>{props.showSafetyIcon && props.safetyIcon}</div>
			)}
		</div>
	);
};
