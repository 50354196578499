import React, { useState } from 'react';
import classes from './styles.module.scss';
import { IIssueLocation } from '../../interfaces/IIssueShared';
import { LocationListSelector } from './LocationListSelector/LocationListSelector';
import { LocationFloorPlanSelector } from './LocationFloorPlanSelector/LocationFloorPlanSelector';
import { TrusstorSwitcher } from '../TrusstorSwitcher/TrusstorSwitcher';

interface IIssueLocationSelectorProps {
	onUpdate: (locations: IIssueLocation[]) => void;
	initialSelectedLocations?: IIssueLocation[] | null;
	projectId: string;
}

enum LocationView {
	LIST = 'list',
	FLOORPLAN = 'floorPlan',
}

export const IssueLocationSelector = (props: IIssueLocationSelectorProps) => {
	const [locationView, setLocationView] = useState<LocationView>(LocationView.FLOORPLAN);
	const [selectedLocations, setSelectedLocations] = useState<IIssueLocation[] | null>(
		props.initialSelectedLocations ?? null
	);

	const toggleLocationView = () => {
		setLocationView(locationView === LocationView.FLOORPLAN ? LocationView.LIST : LocationView.FLOORPLAN);
	};

	const onLocationUpdate = (locations: IIssueLocation[]) => {
		setSelectedLocations(locations);
		props.onUpdate(locations);
	};

	return (
		<>
			<div className={classes.switcherContainer}>
				<TrusstorSwitcher
					options={[LocationView.FLOORPLAN, LocationView.LIST]}
					handleChange={toggleLocationView}
					initialState={locationView}
				/>
			</div>
			{locationView === LocationView.FLOORPLAN ? (
				<LocationFloorPlanSelector
					onSelect={onLocationUpdate}
					initialSelectedLocations={selectedLocations}
					projectId={props.projectId}
				/>
			) : (
				<LocationListSelector
					onSelect={onLocationUpdate}
					initialSelectedLocations={selectedLocations}
					projectId={props.projectId}
				/>
			)}
		</>
	);
};
