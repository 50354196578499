import React, { useId } from 'react';
import { Chip } from '@material-ui/core';
import { ManagerAvatar } from '@shared/components/Avatars/ManagerAvatar/ManagerAvatar';
import classes from './styles.module.scss';
import { translationService } from '../../../index';
import { FLOOR_CHIP_HEIGHT } from '@src/constants';
import TrusstorTooltip from '@shared/components/TrusstorTooltip';

interface IManagerChipProps {
	chipText: string;
	chipBackground: string;
	maxWidth?: number;
	marginInlineStart?: string;
	height?: string;
	showTooltip?: boolean;
}

const ManagerChip = (props: IManagerChipProps) => {
	const rootClass: string = `${classes.root} ${!props.height ? classes.floorChipHeight : ''}`;
	const managerChipTextId: string = `${useId()}-chipWithNumberText-${props.chipText}`;

	const getManagerChipElement = (shouldUseId = true, isMaxWidthChip = false) => (
		<Chip
			label={
				<div className={classes.labelContainer}>
					<ManagerAvatar
						managerName={props.chipText}
						avatarBackground={props.chipBackground}
						initialsSize={11}
						translationService={translationService}
					/>
					<p
						id={shouldUseId ? managerChipTextId : undefined}
						className={`${classes.text} chipLabel`}
						data-testid={'chipText'}
					>
						{props.chipText}
					</p>
				</div>
			}
			classes={{
				label: classes.label,
				root: rootClass,
			}}
			style={{
				...(!isMaxWidthChip && { maxWidth: props.maxWidth }),
				...(props.height && { height: props.height, maxHeight: FLOOR_CHIP_HEIGHT }),
				...(props.marginInlineStart && { marginInlineEnd: 0, marginInlineStart: props.marginInlineStart }),
			}}
			data-testid={'managerChip'}
		/>
	);

	return (
		<TrusstorTooltip
			disableTooltip={!props.showTooltip}
			idToOverflow={managerChipTextId}
			tooltipComponent={getManagerChipElement(false, true)}
		>
			{getManagerChipElement()}
		</TrusstorTooltip>
	);
};

export { ManagerChip };
