import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { textStyle } from '@shared/utils/text.utils';

const useStyles = makeStyles((theme) => ({
	emptyStateContainer: ({ isSmall, customMargins }) => ({
		marginTop: 16,
		margin: 'auto',
		...customMargins,
		width: isSmall ? '86%' : '98%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	}),
	emptyStateText: ({ isSmall }) =>
		textStyle({
			fontClass: 'body1',
			color: 'rgba(59, 59, 59, 0.34)',
			textTransform: isSmall ? 'none' : 'capitalize',
			marginLeft: isSmall ? 16 : 0,
			marginRight: isSmall ? 16 : 0,
			textAlign: 'center',
		}),
}));

const EmptyState = ({ text, isSmall = false, customMargins = {} }) => {
	const classes = useStyles({ isSmall, customMargins });

	return (
		<div className={classes.emptyStateContainer} data-testid={'emptyState'}>
			<p className={classes.emptyStateText} data-testid={'emptyStateText'}>
				{text}
			</p>
		</div>
	);
};

export { EmptyState };
