import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { uniq } from 'lodash';
import { ISequencesReducerState } from '@interfaces/ISequencesReducerState';
import { ISequence } from '@shared/interfaces/ISequence';
import { IRootState } from '@store/slices/index';

const initialState: ISequencesReducerState = {
	activeSequences: [],
	selectedSequences: [],
};

const sequencesSlice = createSlice({
	name: 'sequences',
	initialState,
	reducers: {
		addActiveSequences: (state: ISequencesReducerState, action: PayloadAction<{ sequences: ISequence[] }>) => {
			state.activeSequences = action.payload.sequences;
		},
		addSelectedSequence: (
			state: ISequencesReducerState,
			action: PayloadAction<{ selectedSequence: ISequence }>
		) => {
			const selectedSequencesAfterAdd: ISequence[] = uniq(
				state.selectedSequences.concat(action.payload.selectedSequence)
			);
			state.selectedSequences = selectedSequencesAfterAdd;
		},
		removeSelectedSequence: (
			state: ISequencesReducerState,
			action: PayloadAction<{ removedSequence: ISequence }>
		) => {
			const selectedSequencesAfterRemove: ISequence[] = state.selectedSequences.filter((selectedSequence) => {
				return action.payload.removedSequence._id !== selectedSequence._id;
			});

			state.selectedSequences = selectedSequencesAfterRemove;
		},
		resetSelectedSequencesToDefault: (state: ISequencesReducerState) => {
			state.selectedSequences = [];
		},
		resetSelectedSequences: (state: ISequencesReducerState) => {
			state.selectedSequences = [];
		},
	},
});

export const selectSelectedSequences = (state: IRootState): ISequence[] => state.sequencesReducer.selectedSequences;
export const selectActiveSequences = (state: IRootState): ISequence[] => state.sequencesReducer.activeSequences;

export const {
	addSelectedSequence,
	removeSelectedSequence,
	resetSelectedSequencesToDefault,
	resetSelectedSequences,
	addActiveSequences,
} = sequencesSlice.actions;
export default sequencesSlice.reducer;
