import { othersTradeGroup } from '@shared/constants/professions.constants';
import { ICreateInvestigationReport } from '@shared/interfaces/IInvestigationReport';
import { InvestigationReportTypes } from '@shared/interfaces/InvestigationReportTypes.enum';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { requestService, translationService } from '../../../../../index';
import { ITag, IUser } from '@src/interfaces';
import { IRootState } from '@store/slices';
import { selectProjectId, selectTimezone } from '@store/slices/project.slice';
import { errorSnackbar } from '@utils/snackbar.utils';
import { getTagName } from '@utils/tags.utils';
import { ReportCard } from '../ReportCard';
import classes from './styles.module.scss';
import { analysisCenterReportGeneration_BI } from '@utils/bi.utils';
import { useAbsentWorkDatesForTagQuery } from '@src/hooks/queries/analysisCenter.queries.hooks';
import { getMinDateForIncidentReport } from '@utils/analysisCenter.utils';
import { selectLoggedUserDetails } from '@store/slices/login.slice';
import { DateSelectorTrusstorInput } from '@src/Components/DateSelector/DateSelectorTrusstorInput/DateSelectorTrusstorInput';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { getTradeTranslation } from '@utils/translations.utils';
import { SingleDropdown } from '@src/Components/Dropdowns/DesignSystem/SingleDropdown/SingleDropdown';
import { getTimezoneStartOfDate } from '@shared/utils/dateUtils';

interface IInvestigationReportCardReportCardProps {
	isSelected: boolean;
	icon: IconNames;
	title: string;
	description: string;
	name: InvestigationReportTypes;
	setSelectedCard: (name: string) => void;
	showTimeSelector?: boolean;
}

const getTagOption = (tag: ITag): string => {
	if (!tag) return '';
	const tagNickString: string = `-${tag.tagNick}`;
	if (tag.profession?.tradeGroup === othersTradeGroup) {
		return getTradeTranslation(tag.profession) + tagNickString;
	}
	return `${tag.name || tag.manager?.name || 'N/A'}` + tagNickString;
};

const InvestigationReportCard = (props: IInvestigationReportCardReportCardProps) => {
	const dispatch = useDispatch();
	const projectId: string = useSelector(selectProjectId)!;
	const tz: string = useSelector(selectTimezone)!;
	const staticTags: ITag[] = useSelector((state: IRootState) => state.tagsReducer.tags);
	const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
	const [selectedTag, setSelectedTag] = useState<ITag | null>(null);
	const history = useHistory();
	const loggedUserDetails: IUser = useSelector(selectLoggedUserDetails)!;

	const tagOptions: ITag[] = [...staticTags].sort((a, b) => {
		const sortedA = getTagOption(a).toLowerCase();
		const sortedB = getTagOption(b).toLowerCase();
		return sortedA.localeCompare(sortedB);
	});

	useEffect(() => {
		return () => {
			setSelectedTag(null);
			setSelectedDate(null);
		};
	}, []);

	const disabledDatesFromDatePicker: Date[] | undefined = useAbsentWorkDatesForTagQuery(
		selectedTag?.tagId,
		projectId,
		tz
	);
	const isGenerateDisabled = (): boolean => {
		return !selectedTag || !selectedDate;
	};

	const handleTagChange = (value: ITag | null) => {
		setSelectedTag(value);
		setSelectedDate(new Date());
	};

	const handleTimeChange = (date: Date) => {
		if (date.getTime() > Date.now()) {
			const getStartOfDayTime = getTimezoneStartOfDate(tz);
			const newTime: Date = new Date(getStartOfDayTime);
			newTime.setMinutes(30);
			setSelectedDate(newTime);
			return;
		}

		const selectedHour: number = moment.tz(date, tz).hour();
		const selectedMinute: number = moment.tz(date, tz).minute();
		const selectedTime: number = selectedHour + selectedMinute / 60;
		if (selectedTime < 0.5 || selectedTime > 23.5) {
			const newTime: Date = new Date(date);
			newTime.setMinutes(30);
			setSelectedDate(newTime);
			return;
		}

		setSelectedDate(date);
	};

	const getInvestigationReport = (): ICreateInvestigationReport | null => {
		if (isGenerateDisabled()) return null;
		if (!selectedTag || !selectedDate) return null;
		return {
			userId: loggedUserDetails._id!,
			investigatedTimestamp: selectedDate.getTime(),
			investigationType: props.name,
			title: props.title,
			description: props.description,
			tagId: selectedTag.tagId,
			tagName: getTagName(selectedTag),
			tagNick: selectedTag.tagNick,
			projectId: projectId,
		};
	};

	const generateInvestigationReport = async () => {
		try {
			const investigationReport: ICreateInvestigationReport | null = getInvestigationReport();
			if (!investigationReport) return;
			const reportId = await requestService.post(`/archive/investigationReports/`, {
				body: {
					investigationReport,
				},
			});
			analysisCenterReportGeneration_BI(props.name, 'new', [new Date(investigationReport.investigatedTimestamp)]);
			history.push(`/analysisCenter/${investigationReport.investigationType}/${reportId}`);
		} catch (e) {
			errorSnackbar(dispatch, translationService.get('failedToGenerateReport'));
			console.error('Failed to generate report', e);
		}
	};

	const marchFirstDate: Date = moment.tz('2023-03-01', tz).toDate(); //The date from when data started being accumulated in the archive microservice

	return (
		<ReportCard
			name={props.name}
			isSelected={props.isSelected}
			icon={props.icon}
			title={props.title}
			description={props.description}
			generateReport={generateInvestigationReport}
			isGenerateReportDisabled={isGenerateDisabled()}
			setSelectedCard={props.setSelectedCard}
		>
			<>
				<section className={classes.searchTagContainer}>
					<SingleDropdown
						getInputDisplay={(option) => getTagOption(option)}
						onChange={handleTagChange}
						getDisplayOption={(option) => getTagOption(option).split('-')[0]}
						getSecondDisplayOption={(option) => getTagOption(option).split('-')[1]}
						options={tagOptions}
						value={selectedTag ? selectedTag : undefined}
						label={translationService.get('nameOrTagId')}
					/>
				</section>
				<DateSelectorTrusstorInput
					disableDatePicker={!selectedTag}
					handleTimeChange={handleTimeChange}
					selectedDate={selectedDate}
					displayTime={!!props.showTimeSelector}
					setThirtyMinutesGap
					maxDate={new Date()}
					minDate={new Date(Math.max(marchFirstDate.getTime(), getMinDateForIncidentReport(tz).getTime()))}
					disabledDates={disabledDatesFromDatePicker}
					calendarPosition={translationService.getIsRtl() ? 'left-end' : 'right-end'}
					selectDefaultDate
				/>
			</>
		</ReportCard>
	);
};

export { InvestigationReportCard };
