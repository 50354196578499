import React, { ReactNode } from 'react';
import classes from './styles.module.scss';

interface ITitleColoredBox {
	title: string;
	subtitleFirstPart?: string;
	subtitleSecondPart?: string;
	subtitleSeparator?: string;
	textSeparated?: boolean;
	iconSrc?: string;
	showIcon?: boolean;
	customRootStyleClass?: string;
	customTitleStyleClass?: string;
	safetyIcon?: ReactNode;
	children?: ReactNode;
	displayColumnTitle?: boolean;
	testId?: string;
}

const TitleColoredBox = (props: ITitleColoredBox) => {
	return (
		<div className={`${classes.boxContainer} ${props.customRootStyleClass}`} data-testid={props.testId}>
			{props?.subtitleFirstPart || props?.subtitleSecondPart || props?.subtitleSeparator || props.showIcon ? (
				<div
					className={classes.titleContainer}
					style={{
						justifyContent: props.textSeparated ? 'spaceBetween' : 'flexStart',
						flexDirection: props.displayColumnTitle ? 'column' : 'row',
					}}
				>
					<div className={classes.titleWrapper}>
						{props.showIcon && props.iconSrc && (
							<img className={classes.iconContainer} src={props.iconSrc} alt="icon" />
						)}
						{props.showIcon && props.safetyIcon && props.safetyIcon}
						<p className={`${classes.title} ${props.customTitleStyleClass}`} data-testid={'title'}>
							{props.title}
						</p>
					</div>

					<div className={classes.subtitleContainer}>
						{props?.subtitleFirstPart && (
							<p className={`${classes.subtitle}`} data-testid={'subtitleFirstPart'}>
								{props.subtitleFirstPart}
							</p>
						)}
						{props?.subtitleSeparator && <p className={`${classes.subtitle}`}>{props.subtitleSeparator}</p>}
						{props?.subtitleSecondPart && (
							<p className={`${classes.subtitle}`} data-testid={'subtitleSecondPart'}>
								{props.subtitleSecondPart}
							</p>
						)}
					</div>
				</div>
			) : (
				<p className={`${classes.title} ${props.customTitleStyleClass}`} data-testid={'title'}>
					{props.title}
				</p>
			)}
			{props.children}
		</div>
	);
};

export { TitleColoredBox };
