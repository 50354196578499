import React, { useState } from 'react';
import classes from './styles.module.scss';
import { PeriodGraphRow } from './PeriodGraphRow';

export interface IPeriod {
	startTimestamp: number;
	endTimestamp: number;
}

export interface IPeriodGraphRowData {
	periods: IPeriod[];
	titleText: string;
	color: string;
	extraTitleText?: string;
	secondTitleText: string;
	titleInfoText?: string;
	secondTitleInfoText?: string;
}

export interface IPeriodsGraphProps {
	graphTitles: string[];
	periodGraphRowData: IPeriodGraphRowData[];
	mainTitle: string;
	subTitle: string;
	icon: string;
	graphCubes: number;
}

const PeriodsGraph = (props: IPeriodsGraphProps) => {
	return (
		<section className={classes.periodsGraphContainer}>
			<section className={classes.header}>
				<div className={classes.leftHeader}>
					<div className={classes.mainTitleText}>{props.mainTitle}</div>
					<div className={classes.subtitleText}>{props.subTitle}</div>
				</div>
				<div className={classes.rightHeader}>
					{props.graphTitles.map((title, index) => {
						return (
							<div className={classes.graphTitleEl} key={index}>
								{title}
							</div>
						);
					})}
				</div>
			</section>
			{props.periodGraphRowData &&
				props.periodGraphRowData.map((rowData, index) => {
					return (
						<PeriodGraphRow
							key={index}
							icon={props.icon}
							titleText={rowData.titleText}
							secondTitleText={rowData.secondTitleText}
							titleInfoText={rowData.titleInfoText}
							secondTitleInfoText={rowData.secondTitleInfoText}
							graphCubes={props.graphCubes}
							periods={rowData.periods}
							extraTitleText={rowData.extraTitleText}
							color={rowData.color}
						/>
					);
				})}
		</section>
	);
};

export { PeriodsGraph };
