import React from 'react';
import { IProject } from '@shared/interfaces/IProject';
import { translationService } from '../../../../../index';
import { cM } from '@shared/utils/colors.utils';
import classes from './styles.module.scss';
import mailIconBold from '../../../../../assets/mailIconBold.svg';

interface IProjectManagerSectionProps {
	responsibleManager: IProject['responsibleManager'];
}

export const ProjectManagerSection = (props: IProjectManagerSectionProps) => {
	const handleMailClick = () => {
		window.open(`mailto:${props.responsibleManager?.mail}`);
	};

	return (
		<>
			{props.responsibleManager && (
				<div className={cM(classes.container, classes)}>
					<div className={cM(classes.part, classes)}>
						<div className={cM(classes.projectManagerHeader, classes)}>
							{translationService.get('projectManager')}
						</div>
						<div className={cM(classes.projectManagerName, classes)}>{props.responsibleManager?.name}</div>
					</div>
					{props.responsibleManager?.mail && (
						<div className={cM(classes.iconWrapper, classes)} onClick={handleMailClick}>
							<img src={mailIconBold} alt="email" />
						</div>
					)}
				</div>
			)}
		</>
	);
};
