import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { requestService } from '@src/index';
import { ISequenceItem } from '@interfaces/ISequenceItem';
import { SequenceTypeEnum } from '@shared/interfaces/SequenceType.enum';
import { IConfigArea } from '../../interfaces/IConfigArea';
import { IAssignedActivity } from '@src/interfaces';

export const useSequenceItemActivitiesQuery = (
	sequenceItemId: string,
	shouldFetch: boolean
): { activities: IAssignedActivity[] } => {
	const { data: activities } = useQuery(
		['sequenceItemActivities', sequenceItemId],
		() => requestService.get(`/activities/byLinkedSequenceItemId?sequenceItemId=${sequenceItemId}`),
		{
			useErrorBoundary: true,
			initialData: [],
			enabled: shouldFetch,
		}
	);

	return { activities };
};

export const useSequenceSequenceItemsQuery = (
	sequenceId?: string
): {
	sequenceItems: ISequenceItem[];
	isInitialLoading: boolean;
	refetchSequenceItems: () => void;
	isLoading: boolean;
	isFetching: boolean;
} => {
	const {
		data: sequenceItems,
		isInitialLoading,
		refetch: refetchSequenceItems,
		isLoading,
		isFetching,
	} = useQuery<ISequenceItem[]>(
		['sequenceSequenceItems', sequenceId],
		() => requestService.get(`/activities/sequenceItems/sequenceId/${sequenceId}`),
		{
			useErrorBoundary: true,
			enabled: !!sequenceId,
			initialData: [],
		}
	);

	return { sequenceItems, isInitialLoading, isLoading, refetchSequenceItems, isFetching };
};

export const useSequenceSequenceItemsByProjectQuery = (
	projectId: string,
	sequenceId?: string
): {
	sequenceItems: ISequenceItem[];
	isInitialLoading: boolean;
	refetchSequenceItems: () => void;
	isLoading: boolean;
	isFetching: boolean;
} => {
	const {
		data: sequenceItems,
		isInitialLoading,
		refetch: refetchSequenceItems,
		isLoading,
		isFetching,
	} = useQuery<ISequenceItem[]>(
		['projectSequenceItems', projectId, sequenceId],
		() => requestService.get(`/activities/sequenceItems/sequenceId/${sequenceId}`),
		{
			useErrorBoundary: true,
			enabled: !!sequenceId,
			initialData: [],
			cacheTime: 0,
		}
	);

	return { sequenceItems, isInitialLoading, isLoading, refetchSequenceItems, isFetching };
};

export const useBulkSequenceItemsLinkToMilestoneMutation = (projectId: string, sequenceId: string) => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async ({ milestoneId, sequenceItemIds }: { milestoneId?: string; sequenceItemIds: string[] }) =>
			requestService.put(`/activities/sequenceItems/bulkLinkSequenceItemToMilestone`, {
				body: { sequenceItemIds, milestoneId },
			}),
		onMutate: ({ milestoneId, sequenceItemIds }) => {
			queryClient.setQueryData(
				['projectSequenceItems', projectId, sequenceId],
				(oldData: ISequenceItem[] | undefined) => {
					return (
						oldData?.map((sequenceItem) => {
							if (sequenceItemIds.includes(sequenceItem._id)) {
								return { ...sequenceItem, linkedMilestoneId: milestoneId };
							}
							return sequenceItem;
						}) || []
					);
				}
			);
		},
		onSettled: () => {
			queryClient.invalidateQueries(['projectMilestones']);
			queryClient.invalidateQueries(['milestone']);
			queryClient.invalidateQueries(['sequenceSequenceItems']);
			queryClient.invalidateQueries(['sequenceItemsByProject']);
			queryClient.invalidateQueries(['projectSequenceItems']);
		},
	});
};

export const useSequenceItemsByProjectQuery = (
	projectId: string,
	disableRefetchOnWindowFocus?: boolean
): {
	sequenceItems: ISequenceItem[];
	isInitialLoading: boolean;
	refetchSequenceItems: () => void;
	isLoading: boolean;
	isFetching: boolean;
} => {
	const {
		data: sequenceItems,
		isInitialLoading,
		refetch: refetchSequenceItems,
		isLoading,
		isFetching,
	} = useQuery<ISequenceItem[]>(
		['sequenceItemsByProject', projectId],
		() => requestService.get(`/activities/sequenceItems/allProjectSequenceItems?projectId=${projectId}`),
		{
			useErrorBoundary: true,
			enabled: !!projectId,
			initialData: [],
			refetchOnWindowFocus: !disableRefetchOnWindowFocus,
		}
	);

	return { sequenceItems, isInitialLoading, isLoading, refetchSequenceItems, isFetching };
};

export const useProjectAreasBySequenceType = (projectId: string, sequenceType?: SequenceTypeEnum) => {
	const {
		data: areas,
		isInitialLoading,
		refetch: refetchAreas,
	} = useQuery<IConfigArea[]>(
		['projectAreaSequenceItems', projectId, sequenceType],
		() => requestService.get(`/projectConfig/area/byAreaType`, { params: { projectId, areaType: sequenceType } }),
		{
			useErrorBoundary: true,
			initialData: [],
			enabled: !!sequenceType,
			refetchOnWindowFocus: false,
		}
	);
	return { areas, isInitialLoading, refetchAreas };
};
