import React from 'react';
import classes from './styles.module.scss';
import { ArrowUpDown } from '../ArrowUpDown/ArrowUpDown';

interface IExpandedSectionProps {
	text: string;
	children: React.ReactNode;
}

export const ExpandedSection = (props: IExpandedSectionProps) => {
	const [isExpanded, setIsExpanded] = React.useState<boolean>(true);

	return (
		<>
			<div className={classes.section} onClick={() => setIsExpanded((prev) => !prev)}>
				<ArrowUpDown isOpen={isExpanded} />
				{props.text}
			</div>

			{isExpanded && props.children}
		</>
	);
};
