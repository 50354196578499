import classnames from 'classnames';
import React from 'react';
import classes from './styles.module.scss';
import { IMergedEquipmentView } from '../../interfaces/Equipment/IMergedEquipmentView';
import { EquipmentIcon } from '../Icons/EquipmentIcon/EquipmentIcon';
import { EquipmentTypes } from '../../constants/equipment.const';

interface IFloorEquipmentSectionProps {
	equipment: IMergedEquipmentView[];
	type: EquipmentTypes;
}

export const FloorEquipmentSection = (props: IFloorEquipmentSectionProps) => {
	if (!props.equipment.length) {
		return null;
	}
	return (
		<div className={classes.wrapper}>
			<div className={classes.wr}>
				<div className={classes.title}>
					<EquipmentIcon type={props.type as EquipmentTypes} />
					<div>{props.equipment[0].typeNick}</div>
				</div>
				<div>{props.equipment.length}</div>
			</div>
			<div className={classes.tagsSection}>
				{props.equipment.map((eq, index) => {
					return (
						<div
							className={classnames(classes.tag, {
								[classes.hideBorder]: index === props.equipment.length - 1,
							})}
						>
							<div>{eq.name}</div>
							<div>{eq.equipmentId}</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};
