import { IDashboardData } from '../../interfaces/IDashboardData';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: IDashboardData = {
	isLoadingDashboardData: false,
	workersOnSite: [],
	utilities: [],
};

const dashboardSlice = createSlice({
	name: 'dashboard',
	initialState,
	reducers: {
		updateIsLoadingDashboardData: (
			state: IDashboardData,
			action: PayloadAction<{ isLoadingDashboardData: boolean }>
		) => {
			state.isLoadingDashboardData = action.payload.isLoadingDashboardData;
		},
		updateDashboard: (state: IDashboardData, action: PayloadAction<{ dashboard: IDashboardData }>) => {
			state.workersOnSite = action.payload.dashboard.workersOnSite;
			state.utilities = action.payload.dashboard.utilities;
		},
		resetDashboard: (state: IDashboardData) => {
			state.workersOnSite = initialState.workersOnSite;
			state.utilities = initialState.utilities;
			state.isLoadingDashboardData = initialState.isLoadingDashboardData;
		},
	},
});

export const selectIsLoadingDashboardData = (state: { dashboardReducer: IDashboardData }) =>
	state.dashboardReducer.isLoadingDashboardData;
export const { updateDashboard, resetDashboard, updateIsLoadingDashboardData } = dashboardSlice.actions;
export default dashboardSlice.reducer;
