import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import '@shared/styles/librariesRunover/_phoneNumberInputLibrary.scss';
import classes from './styles.module.scss';
import { checkPhoneValidity } from '@shared/utils/validation.util';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../store/slices';
import { translationService } from '../../../index';

interface IPhoneInputProps {
	phoneNumber: string | null | undefined;
	onChangeFunction: (value: string) => void;
	displayError: boolean;
	setCountryCode?: (value: string) => void;
	setIsValidPhoneNumber: (value: boolean) => void;
}

export const PhoneInputField = (props: IPhoneInputProps) => {
	const countryShortName: string | undefined = useSelector(
		(state: IRootState) => state.projectReducer.workingProject!.countryShortName
	);

	return (
		<>
			<PhoneInput
				countryCodeEditable={false}
				value={props.phoneNumber || ''}
				country={countryShortName?.toLowerCase() || 'us'}
				specialLabel={''}
				placeholder={''}
				onChange={props.onChangeFunction}
				isValid={(value: string, country: any) => {
					const isPhoneValid: boolean = checkPhoneValidity(value, country.iso2.toUpperCase());
					props.setIsValidPhoneNumber(isPhoneValid);
					props.setCountryCode?.(country.countryCode);
					return true;
				}}
			/>
			<div className={classes.errorWrapper}>
				{props.displayError && (
					<p className={classes.errorText} data-testid={'phoneErrorLabel'}>
						{translationService.get('phoneNumberValidationError')}
					</p>
				)}
			</div>
		</>
	);
};
