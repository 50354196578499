import { COLORS } from '../constants/colors.constants';
import { FONTS } from './text.constants';

export const textStyle = (style: any): any => {
	const { fontSize, fontWeight } = style.fontClass
		? FONTS[style.fontClass]
		: {
				fontSize: style.fontSize || FONTS['body2'].fontSize,
				fontWeight: style.fontWeight || FONTS['body2'].fontWeight,
		  };

	style.fontClass = undefined;
	return {
		fontFamily: 'Heebo, sans-serif !important',
		fontWeight: fontWeight + ' !important',
		fontSize: fontSize + 'px !important',
		letterSpacing: 0.25,
		lineHeight: 1.28,
		color: COLORS.primaryColor,
		marginTop: 0,
		marginBottom: 0,
		...style,
	};
};

export function capitalizeFirstLetter(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}
