import React from 'react';
import classes from '@src/Components/Pages/Workplan/PlanningMenu/styles.module.scss';
import { getAreasChipsFromAreas, IAreaChip } from '@utils/areasChips.utils';
import { SimpleChip } from '@shared/components/SimpleChip/SimpleChip';
import { COLORS } from '@shared/constants/colors.constants';
import { IConfigArea } from '@shared/interfaces/IConfigArea';
import { uniqBy } from 'lodash';
import { IMergedAreaSequenceItem } from '@interfaces/IMergedAreaSequenceItem';

interface IPlanningMenuFloorsProps {
	professionSequenceItems: IMergedAreaSequenceItem[];
	floorNick: string;
}

const PlanningMenuFloors = (props: IPlanningMenuFloorsProps) => {
	const uniqueAreas: IConfigArea[] = uniqBy(props.professionSequenceItems, (item) => item.area.areaId).map(
		(item) => item.area
	);
	const areasChips: IAreaChip[] = getAreasChipsFromAreas(uniqueAreas);

	return (
		<div className={classes.floorContainer}>
			<div className={classes.floorTitle}>{props.floorNick}</div>
			<div className={classes.chipsContainer}>
				{areasChips.map((areaChip: IAreaChip) => {
					return (
						<SimpleChip
							key={`${areaChip.areaIds}`}
							chipColor={COLORS.white}
							chipTextColor={COLORS.primaryColor}
							chipText={areaChip.label}
							chipHeight={22}
							capitalizeText
							border={true}
							textClassName={areaChip.label}
							testId={'planningAreaChip'}
						/>
					);
				})}
			</div>
		</div>
	);
};

export { PlanningMenuFloors };
