import React, { useContext, useMemo } from 'react';
import classes from './styles.module.scss';
import { ISequenceItemProgressBySequenceItemReport } from '@interfaces/ISequenceItemProgressBySequenceItemReport';
import { ActivitiesProgressTable } from '@src/Components/SequenceProgressAnalysis/ActivitiesProgress/ActivitiesProgressTable/ActivitiesProgressTable';
import { translationService } from '@src/servicesInitializers';
import { ActivitiesProgressFilterSection } from '@src/Components/SequenceProgressAnalysis/ActivitiesProgress/ActivitiesProgressFilterSection/ActivitiesProgressFilterSection';
import { useSelector } from 'react-redux';
import { selectWorkingProject } from '@store/slices/project.slice';
import { useSequenceItemProgressBySequenceItemQuery } from '@src/hooks/queries/sequenceItemProgressSummary.queries.hooks';
import { getTimezoneFormattedDate } from '@shared/utils/dateUtils';
import { useIsProgressAnalysisV2 } from '@src/hooks/useIsProgressAnalysisV2.hooks';
import { ActivitiesProgressTableEmptyState } from './ActivitiesProgressTable/ActivitiesProgressTableEmptyState/ActivitiesProgressTableEmptyState';
import { ISequenceProgressAnalysisContext, SequenceProgressAnalysisContext } from '../SequenceProgressAnalysis';
import { filterReportsByMilestone } from './ActivitiesProgressFilterSection/utils';
import { useSequenceItemsByProjectQuery } from '@src/hooks/queries/sequenceItem.query';
import { IProject } from '@shared/interfaces/IProject';
import { getDemoAdjustedProgressReports } from '@src/Components/SequenceProgressAnalysis/ActivitiesProgress/ActivitiesProgressTable/utils';

interface IActivitiesProgressProps {
	endDate: Date;
}

export const ActivitiesProgress = (props: IActivitiesProgressProps) => {
	const project: IProject = useSelector(selectWorkingProject)!;
	const tz: string = project.tz;
	const projectId: string = project.projectId;
	const { selectedMilestoneId }: ISequenceProgressAnalysisContext = useContext(SequenceProgressAnalysisContext)!;
	const { sequenceItems } = useSequenceItemsByProjectQuery(projectId, true);
	const { sequenceItemProgressBySequenceItemReports } = useSequenceItemProgressBySequenceItemQuery(
		projectId,
		getTimezoneFormattedDate(tz, props.endDate)
	);
	const isProgressAnalysisV2: boolean = useIsProgressAnalysisV2();
	const [filteredReports, setFilteredReports] = React.useState<ISequenceItemProgressBySequenceItemReport[]>([]);
	const reportsFilteredBySelectedMilestone: ISequenceItemProgressBySequenceItemReport[] = useMemo(() => {
		return filterReportsByMilestone(sequenceItemProgressBySequenceItemReports, sequenceItems, selectedMilestoneId);
	}, [sequenceItemProgressBySequenceItemReports, selectedMilestoneId, sequenceItems]);

	const getEmptyStateComponent = () => {
		if (!isProgressAnalysisV2 || !selectedMilestoneId || reportsFilteredBySelectedMilestone.length > 0) {
			return <></>;
		}

		return <ActivitiesProgressTableEmptyState />;
	};
	const isDemoProject: boolean = !!project.isDemo;

	const demoReports: ISequenceItemProgressBySequenceItemReport[] = getDemoAdjustedProgressReports(
		tz,
		filteredReports
	);
	return (
		<div className={classes.ActivitiesProgress_container} data-testid="ActivitiesProgress">
			<div className={classes.header}>
				{!isProgressAnalysisV2 && (
					<div className={classes.title}>
						{translationService.get('activities')} ({sequenceItemProgressBySequenceItemReports.length})
					</div>
				)}
				<ActivitiesProgressFilterSection
					reports={reportsFilteredBySelectedMilestone}
					setFilteredReports={setFilteredReports}
				/>
			</div>
			<ActivitiesProgressTable
				reports={isDemoProject ? demoReports : filteredReports}
				getEmptyStateComponent={getEmptyStateComponent}
				showLoader={isProgressAnalysisV2 && selectedMilestoneId === null}
			/>
		</div>
	);
};
