import React, { useEffect, useState } from 'react';
import { groupBy } from 'lodash';
import { FloorPlanPointSelector } from './FloorPlanPointsSelector/FloorPlanPointsSelector';
import classes from './styles.module.scss';
import { IIssueLocation } from '../../../interfaces/IIssueShared';
import { IFloor } from '../../../interfaces/IFloor';
import { Loader } from '../../Loader/Loader';
import { sortFloors } from '../../../utils/sort.utils';
import { SORT_ORDER } from '../../../constants/constants';
import { useSharedServices } from '../../../hooks/sharedServices.context';
import { useFloorDataQuery } from '../../../hooks/queries/floors.queries.hooks';

interface ISelectedPointsPerFloor {
	[floorId: string]: IIssueLocation[];
}

interface ILocationFloorPlanSelectorProps {
	onSelect: (locations: IIssueLocation[]) => void;
	initialSelectedLocations: IIssueLocation[] | null;
	projectId: string;
}

export const LocationFloorPlanSelector = (props: ILocationFloorPlanSelectorProps) => {
	const { translationService, requestService } = useSharedServices();
	const floors: IFloor[] = useFloorDataQuery(requestService, props.projectId);
	const getInitialSelectedPointsPerFloor = () => {
		if (!props.initialSelectedLocations || !Object.keys(props.initialSelectedLocations).length) {
			return undefined;
		}
		return groupBy(props.initialSelectedLocations, (location: IIssueLocation) => location.floor.floorId);
	};
	const [floorsSelectedPoints, setFloorsSelectedPoints] = useState<ISelectedPointsPerFloor | undefined>(
		getInitialSelectedPointsPerFloor()
	);
	const [selectedFloor, setSelectedFloor] = useState<IFloor | undefined>(undefined);

	const onFloorClick = (floor: IFloor) => {
		setSelectedFloor(floor);
	};

	const onFloorPointSelect = (selectedPoints: IIssueLocation[]) => {
		setFloorsSelectedPoints((prevFloorsSelectedPoints) => ({
			...prevFloorsSelectedPoints,
			[selectedFloor!.floorId]: selectedPoints,
		}));
	};

	useEffect(() => {
		if (!floorsSelectedPoints) return;
		const selectedLocations: IIssueLocation[] = Object.values(floorsSelectedPoints).reduce<IIssueLocation[]>(
			(acc, curr) => [...acc, ...(curr as IIssueLocation[])],
			[]
		);
		props.onSelect(selectedLocations);
	}, [floorsSelectedPoints]);

	if (!floors) return <Loader />;
	const sortedFloors: IFloor[] = sortFloors(floors, SORT_ORDER.ASCENDING);

	if (selectedFloor) {
		return (
			<FloorPlanPointSelector
				floor={selectedFloor}
				onClose={() => setSelectedFloor(undefined)}
				onSelect={onFloorPointSelect}
				selectedLocations={floorsSelectedPoints && floorsSelectedPoints[selectedFloor.floorId]}
				projectId={props.projectId}
			/>
		);
	}

	return (
		<div className={classes.floorPlanContainer}>
			{sortedFloors.map((floor: IFloor) => (
				<div className={classes.floorItem} onClick={() => onFloorClick(floor)}>
					<p className={classes.floorNickText}>{floor.floorNick}</p>
					{floorsSelectedPoints &&
						floorsSelectedPoints[floor.floorId] &&
						floorsSelectedPoints[floor.floorId].length > 0 && (
							<div className={classes.selectedFloorMark}>
								{floorsSelectedPoints[floor.floorId].length}
								{floorsSelectedPoints[floor.floorId].length > 1
									? ` ${translationService.get('areas')}`
									: ` ${translationService.get('area')}`}
							</div>
						)}
				</div>
			))}
		</div>
	);
};
