import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ListItem } from '@material-ui/core';
import classes from './styles.module.scss';
import { DIRECTIONS } from '@shared/constants/directions';
import { translationService } from '../../../../index';
import classnames from 'classnames';
import { useWindowHightSmallerThenN } from '@src/hooks/window.hooks';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { TrusstorIcon } from '@src/Components/TrusstorIcon/TrusstorIcon';
import { IconColor, IconSize } from '@shared/components/TrusstorIconShared/TrusstorIconShared';

interface ISideBarItemProps {
	path?: string;
	text?: string;
	showSubMenu?: boolean;
	handleClick?: () => void;
	name: string;
	show?: boolean;
	children?: React.ReactNode;
	isComponentSelected?: boolean;
	iconName: IconNames;
}

const SideBarItem = ({
	path,
	text = '',
	handleClick,
	name,
	children,
	showSubMenu,
	isComponentSelected = false,
	show = true,
	iconName,
}: ISideBarItemProps) => {
	const currentPath: string = useLocation().pathname;
	const isSelected: boolean = isComponentSelected || currentPath === path || currentPath.split('_')[0].includes(name);
	const isRtl: boolean = translationService.getDirection() === DIRECTIONS.RTL;
	const [isHover, setIsHover] = React.useState<boolean>(false);
	const isSmallScreen = useWindowHightSmallerThenN(800);

	useEffect(() => {
		if (!showSubMenu) setIsHover(false);
	}, [showSubMenu]);

	return (
		<Link
			onMouseEnter={() => setIsHover(true)}
			onMouseLeave={() => setIsHover(false)}
			onClick={handleClick}
			to={path || currentPath}
			className={classnames(classes.linkContainer, {
				[classes.show]: show,
				[classes.isSmallScreen]: isSmallScreen,
			})}
		>
			<ListItem className={classes.buttonContainer}>
				<div
					className={classnames(classes.icon, {
						[classes.isSelected]: isSelected,
						[classes.isHover]: showSubMenu || isHover,
					})}
				>
					<TrusstorIcon
						iconName={iconName}
						color={isSelected ? IconColor.White : IconColor.Grey400}
						size={IconSize.LARGE}
					/>
				</div>

				<p
					className={classnames(classes.text, {
						[classes.selected]: isSelected,
						[classes.isHover]: showSubMenu || isHover,
					})}
				>
					{text}
				</p>
				{children && showSubMenu && (
					<section className={classnames(classes.subMenuContainer, { [classes.rtl]: isRtl })}>
						<div className={classnames(classes.subMenuListContainer, classes.fullListContainer)}>
							{children}
						</div>
					</section>
				)}
			</ListItem>
		</Link>
	);
};

export default SideBarItem;
