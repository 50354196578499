import tagIcon from '@shared/assets/icons/tag.svg';
import { IManagerMergedTag } from '@shared/interfaces/IManagerMergedTag';
import { getHourseMinuteRangeFormat } from '@shared/utils/dateUtils';
import { getProfessionDisplayText } from '@shared/utils/professions.utils';
import React from 'react';
import { useSelector } from 'react-redux';
import verticalLine from '../../../../../assets/workplanIcons/verticalLine.svg';
import { translationService } from '../../../../../index';
import { IProfession } from '../../../../../interfaces';
import { IRootState } from '../../../../../store/slices';
import { getTagName } from '../../../../../utils/tags.utils';
import classes from './styles.module.scss';
import { TagEntityCard } from '../../../../Boxes/Cards/TagEntityCard/TagEntityCard';
import { IMergedTag } from 'src/interfaces/IMergedTag';
import { IWorkPeriod } from '@shared/interfaces/IWorkPeriod';

interface ITagOnLocationRowProps {
	tagId: string;
	timeRanges: IWorkPeriod[];
	mergedTagInfo: IMergedTag;
	shouldHideBorder?: boolean;
}

export const TagOnLocationRow = (props: ITagOnLocationRowProps) => {
	const tz: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.tz);
	const phoneNumber: string | undefined = (props.mergedTagInfo as IManagerMergedTag).manager?.phoneNumber;
	return (
		<div className={`${classes.tagData} ${!props.shouldHideBorder && classes.tagDataBorder}`}>
			<div className={classes.tagStaticData}>
				<div className={classes.tagHeader}>
					<p className={classes.tagName}>{getTagName(props.mergedTagInfo)}</p>
					<TagEntityCard tag={props.mergedTagInfo} />
				</div>
				<div className={classes.tagDetails}>
					<img className={classes.tagIcon} src={tagIcon} alt="Tag Icon" />
					<p>{props.tagId}</p>
					{phoneNumber && (
						<>
							<img src={verticalLine} alt="Vertical Line" className={classes.verticalLine} />
							<p>{phoneNumber}</p>
						</>
					)}
					{'profession' in props.mergedTagInfo && (
						<>
							<img src={verticalLine} alt="Vertical Line" className={classes.verticalLine} />
							{getProfessionDisplayText(
								props.mergedTagInfo.profession as IProfession,
								translationService
							)}
						</>
					)}
				</div>
			</div>
			<div className={classes.tagsTime}>
				{props.timeRanges.map((timeRange: IWorkPeriod) => (
					<p>{getHourseMinuteRangeFormat(timeRange.startTimestamp, timeRange.endTimestamp, tz)}</p>
				))}
			</div>
		</div>
	);
};
