import React, { useState } from 'react';
import classes from './styles.module.scss';
import {
	TagsLocationDistributionGraph,
	TagsLocationDistributionGraphSortByEnum,
} from './TagsLocationDistributionGraph/TagsLocationDistributionGraph';
import {
	useProfessionAnalysisReportQuery,
	useProfessionsProductivityReportsQuery,
} from '@src/hooks/queries/analysisCenter.queries.hooks';
import { useSelector } from 'react-redux';
import { IRootState } from '@store/slices';
import { Loader } from '@shared/components/Loader/Loader';
import { translationService } from '@src/servicesInitializers';
import { GraphStat } from '../GraphStat/GraphStat';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import {
	getActiveLocationsFromReports,
	getTotalTimeFromProfessionAnalysisReport,
} from './tagsLocationDistribution.utils';
import { TrusstorIcon } from '@src/Components/TrusstorIcon/TrusstorIcon';

interface ITagsLocationDistributionProps {
	professionId: string;
}

export const TagsLocationDistribution = (props: ITagsLocationDistributionProps) => {
	const projectId: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.projectId);
	const tz: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.tz);
	const [startDate, endDate]: [number, number] = useSelector(
		(state: IRootState) => state.datePickerReducer.visiblePeriodTimestamps
	);
	const [tableSortBy, setTableSortBy] = useState<TagsLocationDistributionGraphSortByEnum>(
		TagsLocationDistributionGraphSortByEnum.LOCATION
	);
	const { professionAnalysisReport, isLoading: isProfessionAnalysisLoading } = useProfessionAnalysisReportQuery(
		projectId,
		startDate,
		endDate,
		tz,
		props.professionId
	);

	const { isLoading: isProfessionProductivityLoading, professionsProductivityReports } =
		useProfessionsProductivityReportsQuery(projectId, startDate, endDate, tz, props.professionId);

	const sortText: string =
		tableSortBy === TagsLocationDistributionGraphSortByEnum.LOCATION
			? translationService.get('locationDistribution_sortByFloor')
			: translationService.get('locationDistribution_sortByTime');

	return (
		<div className={classes.tagsLocationDistributionContainer}>
			<p className={classes.graphTitle}>{translationService.get('workResourcesLocation')}</p>
			{isProfessionProductivityLoading || isProfessionAnalysisLoading ? (
				<Loader />
			) : (
				<>
					<div className={classes.statsContainer}>
						<GraphStat
							title={translationService.get('locationDistribution_activeLocations')}
							value={getActiveLocationsFromReports(professionsProductivityReports)}
							iconName={IconNames.pin}
						/>
						<GraphStat
							title={translationService.get('locationDistribution_totalTime')}
							value={getTotalTimeFromProfessionAnalysisReport(professionAnalysisReport)}
							iconName={IconNames.clock}
						/>
					</div>
					<div className={classes.aboveGraphSection}>
						<p className={classes.smallTitle}>
							{translationService.get('locationDistribution_percentageSpentOnLocation')}
						</p>
						<div
							className={classes.sortSection}
							onClick={() =>
								setTableSortBy(
									tableSortBy === TagsLocationDistributionGraphSortByEnum.LOCATION
										? TagsLocationDistributionGraphSortByEnum.TIME
										: TagsLocationDistributionGraphSortByEnum.LOCATION
								)
							}
						>
							<p className={classes.sortText}>{sortText}</p>
							<TrusstorIcon iconName={IconNames.chevronDown} />
						</div>
					</div>
					<TagsLocationDistributionGraph
						professionsProductivityReports={professionsProductivityReports}
						sortBy={tableSortBy}
					/>
				</>
			)}
		</div>
	);
};
