import React from 'react';
import { FilterCard, IFilterCardProps } from '../FilterCard/FilterCard';
import { IProfession } from '../../../interfaces/IProfession';
import { ProfessionDisplayWithTradeIcon } from '../../ProfessionDisplayWithTradeIcon/ProfessionDisplayWithTradeIcon';
import classes from './styles.module.scss';

interface IProfessionFilterCardProps extends IFilterCardProps {
	profession: IProfession;
	projectId: string;
}

export const ProfessionFilterCard = (props: IProfessionFilterCardProps) => {
	return (
		<FilterCard
			{...props}
			startComponent={
				<ProfessionDisplayWithTradeIcon
					profession={props.profession}
					projectId={props.projectId}
					professionContainerClassname={classes.professionChip}
				/>
			}
		/>
	);
};
