import { IUtilityMergedTag } from '@interfaces/IUtilityMergedTag';
import { EquipmentTypes } from '@shared/interfaces/Equipment/EquipmentTypes';

export const getActiveScissorLiftTags = (scissorLifts: IUtilityMergedTag[]): IUtilityMergedTag[] => {
	return scissorLifts.filter((scissorLift) =>
		!!scissorLift.closestEquipment.length ? isScissorLiftLive(scissorLift) : true
	);
};

export const isScissorLiftLive = (scissorLift: IUtilityMergedTag): boolean => {
	return scissorLift.closestEquipment.every((equipment) => equipment.type !== EquipmentTypes.ScissorsLift);
};
