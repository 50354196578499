import { RoleType } from '../interfaces/RoleType.enum';
import { IUserFeatureProhibitions } from '../interfaces/IUserFeatureProhibitions';
import { UserService } from './user.service';

export class UserAccessLevelService<T extends string> {
	private readonly featuresPermissionObject: IUserFeatureProhibitions<T>;
	private userService: UserService;

	constructor(userFeaturesProhibitedList: IUserFeatureProhibitions<T>, userService: UserService) {
		this.userService = userService;
		this.featuresPermissionObject = userFeaturesProhibitedList;
	}

	public hasAccess(featureName: T): boolean {
		const userRoleType: RoleType = this.userService.getUserRoleType()!;
		return this.roleTypeHasAccess(userRoleType, featureName);
	}

	public roleTypeHasAccess(roleType: RoleType, featureName: T): boolean {
		try {
			if (!this.featuresPermissionObject[featureName]) {
				throw new Error(
					`AccessLevelService: featureName ${featureName} is not defined in the featuresPermissionObject`
				);
			}
			return !this.featuresPermissionObject[featureName].includes(roleType);
		} catch (e: any) {
			throw new Error(e);
		}
	}
}
