import { store } from '../store/store';
import { showSnackbar } from '../store/slices/snackbar.slice';
import { COLORS } from '@shared/constants/colors.constants';

export const successSnackbar = (dispatch: typeof store.dispatch, message: string, secondaryMessage?: string) => {
	dispatch(
		showSnackbar({
			alertType: 'success',
			message,
			secondaryMessage,
			vertical: 'bottom',
			horizontal: 'center',
			backgroundColor: COLORS.darkBlue,
			id: message,
		})
	);
};

export const infoSnackbar = (dispatch: typeof store.dispatch, message: string, secondaryMessage?: string) => {
	dispatch(
		showSnackbar({
			alertType: 'info',
			message,
			secondaryMessage,
			vertical: 'bottom',
			horizontal: 'center',
			backgroundColor: COLORS.darkBlue,
			id: message,
		})
	);
};

export const errorSnackbar = (dispatch: typeof store.dispatch, message: string, secondaryMessage?: string) => {
	dispatch(
		showSnackbar({
			alertType: 'error',
			message,
			secondaryMessage,
			vertical: 'bottom',
			horizontal: 'center',
			backgroundColor: COLORS.darkBlue,
			id: message,
		})
	);
};
