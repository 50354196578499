import React from 'react';
import classes from './styles.module.scss';

interface IGeneralAttentionPointProps {
	text: string | React.ReactNode;
	iconSrc: string;
	backgroundColor: string;
	rootClassName?: string;
}

export const GeneralAttentionPoint = (props: IGeneralAttentionPointProps) => {
	return (
		<div
			className={`${classes.container} ${props.rootClassName}`}
			style={{ backgroundColor: props.backgroundColor }}
		>
			<img alt={'icon'} src={props.iconSrc} className={classes.icon} />
			<div className={classes.text}>{props.text}</div>
		</div>
	);
};
