import React from 'react';
import { GeneralDialog } from '@src/Components/GeneralDialog/GeneralDialog';
import { translationService } from '@src/servicesInitializers';
import classes from './styles.module.scss';

interface INotificationEditRecipientsDialogProps {
	handleClose: () => void;
	handleConfirmationDialog: () => Promise<void>;
	numberOfRecipients?: number;
}

const NotificationEditRecipientsDialog = (props: INotificationEditRecipientsDialogProps) => {
	return (
		<GeneralDialog
			children={
				<div className={classes.notificationDialogContainer}>
					<div className={classes.mainText}>{translationService.get('areYouSure')}</div>
					<div className={classes.text}>
						{translationService.get('thisChangeWillAffectXUsers', {
							users: String(props.numberOfRecipients),
						})}
					</div>
				</div>
			}
			show={true}
			close={props.handleClose}
			mainButton={{
				text: translationService.get('yes'),
				click: props.handleConfirmationDialog,
			}}
			secondaryButton={{
				text: translationService.get('cancel'),
				click: props.handleClose,
			}}
		/>
	);
};

export { NotificationEditRecipientsDialog };
