import React, { useMemo } from 'react';
import { map, sortBy } from 'lodash';
import { elevatorIcon, elevatorStaticIcon, locationIcon, locationGrayIcon } from '../../assets/elevatorIcons';
import { entitiesProperties, hoistOperatorTradeId } from '../../constants';
import { TooltipedIcon } from '../Tooltips/TooltipedIcon';
import { translationService } from '../../index';
import { ManagerChip } from '../Chips/ManagerChip/ManagerChip';
import { ChipWithNumber } from '../Chips/ChipWithNumber';
import { IFloorData } from '../../interfaces/IFloorData';
import { getProfessionDisplayText } from '../../utils/professions.utils';
import classes from './styles.module.scss';
import { msToHoistTimeString } from '../../utils/dateUtils';
import { useSelector } from 'react-redux';
import { IRootState } from '../../store/slices';
import { IProject } from '@shared/interfaces/IProject';
import moment from 'moment';
import { getHoistTimePastFromStartDay, isEmptyHoist, getIsOperatorInHoist, isWorkDayOver } from './utils/hoist.utils';
import { openHoistMainViewTooltip_BI } from '../../utils/bi.utils';
import { getProfessionBackgroundColor } from '@shared/utils/professions.utils';
import { IConfigArea } from '@shared/interfaces/IConfigArea';
import { useProjectAreasQuery } from '@src/hooks/queries/areas.queries.hooks';

interface IHoistIconProps {
	hoists: IFloorData[];
}

export const HoistIcon = (props: IHoistIconProps) => {
	const workingProject: IProject = useSelector((state: IRootState) => state.projectReducer.workingProject!);
	const projectAreas: IConfigArea[] = useProjectAreasQuery(workingProject.projectId);

	const isStatic: boolean = useMemo(() => props.hoists.some((hoist) => hoist.isMovingFloorStatic), [props.hoists]);

	const getHoistStaticTime = (hoist: IFloorData): string => {
		const timePastFromLastHoistUpdateInMs: number = moment
			.tz(workingProject.tz)
			.diff(new Date(hoist.matchingFixedFloorChangeTimestamp as Date));
		const differenceInHours: number = timePastFromLastHoistUpdateInMs / 1000 / 60 / 60;
		const currentDayIndex: number = moment.tz(workingProject.tz).get('day');
		if (differenceInHours > workingProject.workDayHours[currentDayIndex].dailyWorkerHours) {
			return getHoistTimePastFromStartDay(currentDayIndex, workingProject, timePastFromLastHoistUpdateInMs);
		}
		return msToHoistTimeString(timePastFromLastHoistUpdateInMs);
	};

	const isHoistAvailable = (hoist: IFloorData): boolean => {
		const isCurrentWorkDayOver: boolean = isWorkDayOver(workingProject);
		if (!isCurrentWorkDayOver) {
			return true;
		}
		const staticHoistMinutes: number = Math.round(
			moment().diff(new Date(hoist.matchingFixedFloorChangeTimestamp as Date)) / 1000 / 60
		);
		return staticHoistMinutes <= 30;
	};

	const onOpenTooltip = (): void => {
		openHoistMainViewTooltip_BI(props.hoists, isStatic);
	};

	return (
		<React.Fragment>
			<TooltipedIcon
				key="elevators_tooltip"
				onOpen={onOpenTooltip}
				tooltipComponent={
					<div data-testid={'hoistTooltip'}>
						{map(props.hoists, (hoist, index) => {
							const hoistArea: IConfigArea | undefined = projectAreas.find(
								(area) => area.floorId === hoist.floorId
							);
							return (
								<React.Fragment key={hoist.floorId}>
									<div className={classes.tooltipSection} key={`hoist_${hoist.floorId}`}>
										<section className={classes.hoistTopSection}>
											<div className={classes.hoistHeader}>
												<p
													className={classes.tooltipTextStyles}
													key={hoist.floorId}
													data-testid={'floorNick'}
												>
													{hoistArea?.areaNick}
												</p>
												<div className={classes.timeSpentContainer}>
													{isHoistAvailable(hoist) ? (
														<>
															<p
																className={classes.timeSpentTextStyles}
																data-testid={'timeSpentOnFloorHeader'}
															>
																{translationService.get('timeSpentOnFloor')}
															</p>
															<p
																className={classes.actualTimeSpentTextStyles}
																data-testid={'timeSpentOnFloor'}
															>
																{getHoistStaticTime(hoist)}{' '}
															</p>
														</>
													) : (
														<p
															className={`${classes.notAvailableText}`}
															data-testid={'hoistNotAvailableLabel'}
														>
															{translationService.get('hoistNotAvailable')}
														</p>
													)}
												</div>
											</div>
											<div className={classes.hoistOperator}>
												<img
													className={classes.hoistIcon}
													src={getIsOperatorInHoist(hoist) ? locationIcon : locationGrayIcon}
													alt="hoistIcon"
													data-testid={'hoistOperatorIcon'}
												/>
												<p className={classes.hoistOperatorText}>
													{translationService.get('hoistOperator')}
												</p>
											</div>
										</section>

										<section className={classes.hoistChipsSection}>
											{isEmptyHoist(hoist) ? (
												<p className={classes.noWorkersText} data-testid={'noWorkersFound'}>
													{translationService.get('noWorkersFound')}
												</p>
											) : (
												<div
													className={classes.chipsContainer}
													data-testid={'hoistChipContainer'}
												>
													{map(
														sortBy(hoist.managers, [
															entitiesProperties.sortIndex,
															entitiesProperties.name,
														]),
														(manager) => (
															<ManagerChip
																key={`floorview_${hoist.floorId}_${manager.manager.name}`}
																chipText={manager.manager.name}
																chipBackground={manager.manager.backgroundColor}
															/>
														)
													)}
													{map(
														sortBy(hoist.activeProfessions, [
															entitiesProperties.sortIndex,
															entitiesProperties.tradeId,
														]),
														(activeProfession) => {
															if (activeProfession.tradeId !== hoistOperatorTradeId)
																return (
																	<ChipWithNumber
																		key={`${activeProfession._id}_${hoist.floorId}`}
																		chipColor={getProfessionBackgroundColor(
																			activeProfession,
																			workingProject.projectId
																		)}
																		chipText={getProfessionDisplayText(
																			activeProfession
																		)}
																		number={activeProfession.activeTagsIds.length}
																	/>
																);
														}
													)}
												</div>
											)}
										</section>
									</div>
									{index < props.hoists.length - 1 ? (
										<div className={classes.divider} key={'divider' + hoist.floorId} />
									) : null}
								</React.Fragment>
							);
						})}
					</div>
				}
			>
				<img className={classes.hoist} src={isStatic ? elevatorStaticIcon : elevatorIcon} alt="hoistIcon" />
			</TooltipedIcon>
			{props.hoists.length > 1 && !isStatic && (
				<div className={classes.avatar} key="elevators_div">
					<p className={classes.hoistIconText}>{props.hoists.length}</p>
				</div>
			)}
		</React.Fragment>
	);
};
