import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
interface IStyleProps {
	light?: boolean;
}

const useStyles = makeStyles({
	box: ({ light }: IStyleProps) => ({
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: 4,
		height: '100%',
	}),
});

interface ITrusstorBoxProps {
	customStyleClass?: string;
	id?: string;
	light?: boolean;
	children: React.ReactNode;
	additionalStyle?: any;
	testId?: string;
}

const TrusstorBox = ({
	customStyleClass = '',
	children,
	id,
	light = false,
	additionalStyle = {},
	testId = '',
}: ITrusstorBoxProps): JSX.Element => {
	const classes = useStyles({ light });

	return (
		<div
			className={`${classes.box} ${customStyleClass}`}
			style={{ ...additionalStyle }}
			id={id}
			data-testid={testId}
		>
			{children}
		</div>
	);
};

export { TrusstorBox };
