import { LOGIN_TIMEOUT } from '../constants';
import decode from 'jwt-decode';
import { ILoggedUser } from '@shared/interfaces/IUser';
import { RequestService } from '@shared/services/request.service';
import { UserService } from '@shared/services/user.service';
import { store } from '@store/store';
import { setLoggedUserThunk } from '@store/thunks';

class AuthService {
	private refreshTokenPromise: Promise<ILoggedUser> | null;

	constructor(
		private requestService: RequestService,
		private userService: UserService
	) {
		this.refreshTokenPromise = null;
	}

	public async login(username: string, password: string) {
		const loggedUser: ILoggedUser = await this.requestService.post('/auth/authentication', {
			disableAuth: true,
			body: {
				username: username.toLowerCase(),
				password,
			},
			timeout: LOGIN_TIMEOUT,
		});
		return loggedUser;
	}

	public refreshUserToken = async (): Promise<ILoggedUser | undefined> => {
		const refreshToken: string | null = this.userService.getLoggedUserRefreshToken();
		if (!refreshToken) {
			return undefined;
		}

		const loggedUser: ILoggedUser = await this.fetchRefreshToken(refreshToken);
		store.dispatch(setLoggedUserThunk(loggedUser));
		return loggedUser;
	};

	public async getIsTokenExpired() {
		try {
			const accessToken: string | null = this.userService.getLoggedUserAccessToken();

			if (!accessToken) {
				return true;
			}

			const decodedAccessToken: any = decode(accessToken);
			const expireDateInSecondsSinceEpoch: number = decodedAccessToken.exp;
			return new Date().getTime() > expireDateInSecondsSinceEpoch * 1000;
		} catch (e) {
			return true;
		}
	}

	private async fetchRefreshToken(refreshToken: string): Promise<ILoggedUser> {
		if (this.refreshTokenPromise) {
			return this.refreshTokenPromise;
		}

		this.refreshTokenPromise = this.requestService.post('/auth/authentication/refresh', {
			body: {
				refreshToken,
			},
		});
		const loggedUser: ILoggedUser = await this.refreshTokenPromise;
		this.refreshTokenPromise = null;
		return loggedUser;
	}
}

export { AuthService };
