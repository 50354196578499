import { requestService, translationService } from '../../../index';
import { SafetyEventDisplayContent } from '../SafetyEventDisplayContent/SafetyEventDisplayContent';
import React, { useCallback, useEffect, useState } from 'react';
import { SafetyEventStatuses } from '../../../constants';
import { IMinimalSafetyEventForClient, ISafetyEvent } from '../../../interfaces/ISafetyEvent';
import { Loader } from '@shared/components/Loader/Loader';
import classes from './styles.module.scss';
import { GeneralDialog } from '../../GeneralDialog/GeneralDialog';
import { safetyOrange } from '../../../assets/safetyIcons';
import { arrowLeft, arrowRight } from '../../../assets';

interface ISafetyEventPopupProps {
	minimalSafetyEvents: IMinimalSafetyEventForClient[];
	handleClose: () => void;
	handleDoneClick: (doneEvent: ISafetyEvent, shouldClosedDialog: boolean) => void;
}

const SafetyEventPopup = (props: ISafetyEventPopupProps) => {
	const [safetyEvents, setSafetyEvents] = useState([] as ISafetyEvent[]);
	const [currentSafetyEventIndex, setCurrentSafetyEventIndex] = useState(0);

	const isRtl: boolean = translationService.getIsRtl();

	useEffect(() => {
		getSafetyEvents();
	}, [props.minimalSafetyEvents]);

	const closePopupAndResetState = useCallback(() => {
		setSafetyEvents([] as ISafetyEvent[]);
		props.handleClose();
	}, []);

	const getSafetyEvents = async (): Promise<void> => {
		const safetyEvents: ISafetyEvent[] = await requestService.get(
			`/safety/getSafetyEventsByIds?ids=${props.minimalSafetyEvents.map((event) => event._id)}`
		);
		safetyEvents.sort((a, b) => {
			if (a.isCritical && !b.isCritical) {
				return -1;
			}
			return a.timestampCreated - b.timestampCreated;
		});
		setSafetyEvents(safetyEvents);
	};

	const handleCloseEvent: (doneEvent: ISafetyEvent) => void = (doneEvent: ISafetyEvent) => {
		const newSafetyEvents: ISafetyEvent[] = safetyEvents.filter((event) => event._id !== doneEvent._id);
		if (currentSafetyEventIndex === newSafetyEvents.length) {
			setCurrentSafetyEventIndex((prevState) => --prevState);
		}
		setSafetyEvents(newSafetyEvents);
		const shouldClosedDialog: boolean = newSafetyEvents.length === 0;
		props.handleDoneClick(doneEvent, shouldClosedDialog);
	};

	const handleLeftArrowClick: () => void = () => {
		if (currentSafetyEventIndex === 0) {
			return;
		}
		setCurrentSafetyEventIndex((prevState) => --prevState);
	};

	const handleRightArrowClick: () => void = () => {
		if (currentSafetyEventIndex === safetyEvents.length - 1) {
			return;
		}
		setCurrentSafetyEventIndex((prevState) => ++prevState);
	};

	const rightArrowStyle: string = currentSafetyEventIndex === safetyEvents.length - 1 ? classes.arrowInactive : '';
	const leftArrowStyle: string = currentSafetyEventIndex === 0 ? classes.arrowInactive : '';

	const PagesController = (): JSX.Element | undefined => {
		if (props.minimalSafetyEvents.length <= 1) {
			return undefined;
		}
		return (
			<div className={classes.pagesController}>
				<img
					alt=""
					className={`${classes.arrow} ${isRtl ? rightArrowStyle : leftArrowStyle}`}
					src={isRtl ? arrowRight : arrowLeft}
					onClick={isRtl ? handleRightArrowClick : handleLeftArrowClick}
					data-testid={`arrow-${isRtl ? 'right' : 'left'}`}
				/>
				<span className={classes.pagesNumber} data-testid={'pagesNumber'}>
					{currentSafetyEventIndex + 1}/{safetyEvents.length ? safetyEvents.length : ''}
				</span>
				<img
					alt=""
					className={`${classes.arrow} ${isRtl ? leftArrowStyle : rightArrowStyle}`}
					src={isRtl ? arrowLeft : arrowRight}
					onClick={isRtl ? handleLeftArrowClick : handleRightArrowClick}
					data-testid={`arrow-${isRtl ? 'left' : 'right'}`}
				/>
			</div>
		);
	};

	const shouldShowDialogButton: boolean =
		!!safetyEvents[currentSafetyEventIndex] &&
		safetyEvents[currentSafetyEventIndex].status !== SafetyEventStatuses.close;

	return (
		<GeneralDialog
			show={true}
			close={closePopupAndResetState}
			title={
				safetyEvents.length === 1
					? translationService.get('safetyAlert')
					: translationService.get('safetyAlerts')
			}
			titleIcon={safetyOrange}
			subtitle={PagesController()}
			rootStyle={classes.dialogRootStyle}
			mainButton={{
				text: translationService.get('endEvent'),
				click: () => {
					handleCloseEvent(safetyEvents[currentSafetyEventIndex]);
				},
				show: shouldShowDialogButton,
			}}
			secondaryButton={{
				text: translationService.get('cancel'),
				click: closePopupAndResetState,
				show: shouldShowDialogButton,
			}}
		>
			<div className={classes.safetyContent}>
				{!safetyEvents.length ? (
					<Loader />
				) : (
					<SafetyEventDisplayContent event={safetyEvents[currentSafetyEventIndex]} />
				)}
			</div>
		</GeneralDialog>
	);
};

export { SafetyEventPopup };
