import React from 'react';
import classes from './styles.module.scss';
import classnames from 'classnames';
import DropdownItemCard from './DropdownItemCard/DropdownItemCard';
import { DropdownTextInput } from '../../../../Inputs/TrusstorTextInput/DropdownTextInput/DropdownTextInput';
import { ChangeFunctionType } from '../../../../Inputs/TrusstorTextInput/TrusstorTextInput';

export interface IDropdownDisplayProps<T> {
	isDropdownOpen: boolean;
	handleInputChange: ChangeFunctionType<any>;
	valueForInput: string;
	placeholder?: string;
	label?: string;
	disabled?: boolean;
	required?: boolean;
	handleInputFocusChange: (isFocused: boolean) => void;
	filteredOptions: T[];
	highlightedIndex: number;
	setIsDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
	handleKeyDown: (event: React.KeyboardEvent) => void;
	handleOptionClick: (option: T) => void;
	handleOptionHover: (index: number) => void;
	getDisplayOption: (option: T) => string | React.ReactNode;
	getSecondDisplayOption?: (option: T) => string;
	testId?: string;
	dropdownRef: React.RefObject<HTMLDivElement>;
	getIsSelected: (option: T) => boolean;
	showCheckbox?: boolean;
	hideClearTextButton?: boolean;
	isError?: boolean;
	errorText?: string;
	handleClearClickCallback?: () => void;
	sendFullEvent?: boolean;
	shouldNotSelectInputTextOnFocus?: boolean;
	optionsContainerRef: React.RefObject<HTMLDivElement>;
	inputRef?: React.RefObject<HTMLInputElement>;
	startIcon?: React.ReactNode | string;
	dropdownContainerClassName?: string;
	inputContainerClassName?: string;
	footer?: React.ReactElement;
}

export function DropdownDisplay<T>(props: IDropdownDisplayProps<T>) {
	return (
		<div
			className={classes.SimpleDropdown_container}
			onKeyDown={props.handleKeyDown}
			data-testid={props.testId}
			ref={props.dropdownRef}
			tabIndex={0}
		>
			<DropdownTextInput
				ref={props.inputRef}
				sendFullEvent={props.sendFullEvent}
				handleClearClickCallback={props.handleClearClickCallback}
				setDropdownOpen={props.setIsDropdownOpen}
				isDropdownOpen={props.isDropdownOpen}
				changeFunc={props.handleInputChange}
				value={props.valueForInput}
				placeholder={props.placeholder}
				label={props.label}
				disabled={props.disabled}
				required={props.required}
				handleInputFocusChange={props.handleInputFocusChange}
				hideClearTextButton={props.hideClearTextButton}
				isError={props.isError}
				errorText={props.errorText}
				shouldNotSelectInputTextOnFocus={props.shouldNotSelectInputTextOnFocus}
				startIcon={props.startIcon}
			/>
			{props.isDropdownOpen && props.filteredOptions.length > 0 && (
				<div
					className={classnames(classes.SimpleDropdown_options, props.dropdownContainerClassName)}
					ref={props.optionsContainerRef}
				>
					{props.filteredOptions.map((option, index) => (
						<DropdownItemCard
							showCheckbox={props.showCheckbox}
							isSelected={props.getIsSelected(option)}
							isHighlighted={props.highlightedIndex === index}
							option={option}
							index={index}
							handleOptionClick={props.handleOptionClick}
							handleOptionHover={props.handleOptionHover}
							inputValue={props.valueForInput}
							optionDisplayValue={props.getDisplayOption(option)}
							optionSecondDisplayValue={props.getSecondDisplayOption?.(option)}
						/>
					))}
					{props.footer && <div className={classes.footer}>{props.footer}</div>}
				</div>
			)}
		</div>
	);
}
