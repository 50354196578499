import { useQuery } from '@tanstack/react-query';
import { requestService } from '@src/servicesInitializers';
import { IConfigArea } from '@shared/interfaces/IConfigArea';
import { QueryKeys } from '@shared/constants/QueryKeys.enum';

export const useFloorAreasQuery = (projectId: string, floorId: string): IConfigArea[] => {
	const { data: areas } = useQuery<IConfigArea[]>(
		[QueryKeys.floorAreasData, projectId, floorId],
		() => requestService.get(`/projectConfig/area?projectId=${projectId}&floorId=${floorId}`),
		{
			useErrorBoundary: true,
			initialData: [],
		}
	);
	return areas;
};

export const useProjectAreasQuery = (projectId: string): IConfigArea[] => {
	const { data: areas } = useQuery<IConfigArea[]>(
		[QueryKeys.projectAreas, projectId],
		() => requestService.get(`/projectConfig/area?projectId=${projectId}`),
		{
			useErrorBoundary: true,
			initialData: [],
		}
	);
	return areas;
};
