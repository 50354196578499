import * as React from 'react';
import classes from './styles.module.scss';

interface ICalendarCellTooltipRowProps {
	icon: string;
	children: React.ReactNode;
}

export const CalendarCellTooltipRow = (props: ICalendarCellTooltipRowProps) => {
	return (
		<div className={classes.tooltipRow}>
			<img className={classes.tooltipIcon} src={props.icon} alt="" />
			<p className={classes.tooltipRowText}>{props.children}</p>
		</div>
	);
};
