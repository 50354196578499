import { IProfessionDisplaySettings } from '@shared/interfaces/IProfessionDisplaySettings';
import { requestService } from '../';

export const updateProjectProfessionsDisplaySettings = async (
	projectId: string,
	displaySettings: IProfessionDisplaySettings
): Promise<void> => {
	await requestService.put(`/projectConfig/professions/${projectId}/displaySettings`, {
		body: displaySettings,
	});
};
