import { useState, useEffect } from 'react';

export const useVerticalScrollPosition = (divRef) => {
	const [verticalScrollPosition, setVerticalScrollPosition] = useState(0);

	useEffect(() => {
		function handleScroll() {
			if (divRef) {
				setVerticalScrollPosition(divRef.scrollTop);
			}
		}

		if (divRef) {
			divRef.addEventListener('scroll', handleScroll);
			setVerticalScrollPosition(divRef.scrollTop);
		}

		return () => {
			if (divRef) {
				divRef.removeEventListener('scroll', handleScroll);
			}
		};
	}, [divRef]);

	return verticalScrollPosition;
};
