import React from 'react';
import classes from './styles.module.scss';
import { Dictionary } from 'lodash';
import { IMainPageWidgetActivityData } from '@interfaces/IMainPageWidgetActivityData';
import TrusstorTooltip from '@shared/components/TrusstorTooltip';
import { ProfessionActivitiesChipTooltip } from '../ProfessionActivitiesChip/ProfessionActivitiesChipTooltip/ProfessionActivitiesChipTooltip';

interface IShowMoreActivitiesChipProps {
	activitiesGroupedByProfession: Dictionary<IMainPageWidgetActivityData[]>;
	floorId: string;
	height: string;
}

export const ShowMoreActivitiesChip = (props: IShowMoreActivitiesChipProps) => {
	return (
		<TrusstorTooltip
			tooltipComponent={
				<ProfessionActivitiesChipTooltip
					floorId={props.floorId}
					activitiesData={props.activitiesGroupedByProfession}
				/>
			}
			showAlways={true}
			interactive
		>
			<div className={classes.showMoreActivitiesChip} style={{ height: props.height }}>
				+{Object.keys(props.activitiesGroupedByProfession).length}
			</div>
		</TrusstorTooltip>
	);
};
