import { Dispatch } from 'redux';
import { requestService, streamService } from '../../index';
import { getTimezoneFormattedDate } from '../../utils/dateUtils';
import { config } from '../../config';
import { IBackendActivitiesReport } from '../../interfaces/IActivitiesReport';
import { resetActivityReport, updateActivityReport } from '../slices/activityReport.slice';
import { IRootState } from '../slices';

export const getActivityDailyReport =
	(projectId: string, date: number | Date, tz: string) => async (dispatch: Dispatch, getState: () => IRootState) => {
		const serverFormatDate = getTimezoneFormattedDate(tz, date);
		streamService.openStream(
			`/reports/professionsActivities/stream?date=${serverFormatDate}&projectId=${projectId}`,
			(message: any) => {
				const activityReport = JSON.parse(message.data);
				dispatch(updateActivityReport({ activityReport }));
			}
		);
	};

export const getActivityPeriodicReport =
	(projectId: string, startDate: number | Date, endDate: number | Date, tz: string, isAnalysisReport = false) =>
	async (dispatch: Dispatch) => {
		const serverFormatDateFrom = getTimezoneFormattedDate(tz, startDate);
		const serverFormatDateTo = getTimezoneFormattedDate(tz, endDate);

		streamService.closeStreamIncludes(`/reports/professionsActivities/stream`);
		const activityReport: IBackendActivitiesReport = await requestService.get(
			`/reports/periodicActivities?dateFrom=${serverFormatDateFrom}&dateTo=${serverFormatDateTo}&projectId=${projectId}&interval=${config.reportsInterval}`
		);
		dispatch(updateActivityReport({ activityReport }));
	};

export const stopGettingActivityReport = () => (dispatch: Dispatch) => {
	streamService.closeStreamIncludes(`/reports/professionsActivities/stream`);
	dispatch(resetActivityReport());
};
