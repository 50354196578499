import React from 'react';
import classes from './styles.module.scss';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { translationService } from '@src/servicesInitializers';
import { ISequenceItemProgressPeriodOverallReport } from '@shared/interfaces/ISequenceItemProgressPeriodOverallReport';
import { StatCard } from './StatCard';

interface ITopProps {
	overallProgressSummary: ISequenceItemProgressPeriodOverallReport;
}

export const TopStats = (props: ITopProps) => {
	const periodCount: number = props.overallProgressSummary?.periodProgressCount;
	const completedText: string = `${periodCount} ${translationService.get('completed')}`;
	const overallProgressCount: number | undefined = props.overallProgressSummary?.overallProgressCount;
	const totalAmount: number | undefined = props.overallProgressSummary?.totalAmount;
	const progressText: string = `${overallProgressCount}/${totalAmount}`;
	const totalProgressPercentage: number =
		overallProgressCount && totalAmount ? Number(((overallProgressCount / totalAmount) * 100).toFixed(1)) : 0;

	const periodProgressPercentage: number =
		periodCount && totalAmount ? Number(((periodCount / totalAmount) * 100).toFixed(1)) : 0;

	return (
		<div className={classes.TopStats_container} data-testid="TopStats">
			<StatCard
				iconName={IconNames.calendar}
				title={translationService.get('recentWeekProgress')}
				percentage={periodProgressPercentage}
			>
				<div className={classes.subtitle}>{completedText}</div>
			</StatCard>
			<StatCard
				iconName={IconNames.chart2}
				title={translationService.get('overallProgress')}
				percentage={totalProgressPercentage}
			>
				<div className={classes.progressTotalText}>{progressText}</div>
			</StatCard>
		</div>
	);
};
