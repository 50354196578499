import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { ManagersFilterSection } from '../../FilterSections/ManagersFilterSection';
import { FloorsSelectorSection } from './ReportsComponents/ReportsSections/FloorsSelectorSection';
import { SafetySection } from './ReportsComponents/CombinedReport/SafetySection';
import { ProductivitySection } from './ReportsComponents/CombinedReport/ProductivitySection';
import { ActivitiesSection } from './ReportsComponents/CombinedReport/ActivitiesSection';
import { IBackendActivitiesReport, IFloor, IProfession, ISafetyReport } from '../../../interfaces';
import { DatePeriodPickerSection } from './ReportsComponents/ReportsSections/DatePeriodPickerSection/DatePeriodPickerSection';
import { requestService, translationService } from '../../../index';
import { APP_FILTER_TYPES, DATE_PICKER_TOGGLE, SIDE_MAIN_COMPONENT_CLASS_NAME } from '../../../constants/constants';
import { getTimezoneFormattedDate } from '../../../utils/dateUtils';
import { IProfessionsAnalysisReport } from '../../../interfaces/IProfessionReport';
import { IActivitiesReport } from '../../../interfaces/IActivitiesReport';
import { IWorkDayHours } from '@shared/interfaces/IProject';
import { IRootState } from '../../../store/slices';
import { config } from '../../../config';
import { ProfessionsFilterSection } from '../../FilterSections/ProfessionsFilterSection';
import { useFilters } from '../../../hooks/useFilter.hook';
import { exportAdminReportToExcel, exportCombinedReportToExcel } from './CombinedReport.utils';
import { SummaryTypes } from '../../../interfaces/SummaryTypes.enum';
import { IRoundedProductivityReport } from '../../../interfaces/IRoundedProductivityReport';
import { textStyle } from '@shared/utils/text.utils';
import { TrusstorButton } from '@shared/components/buttons/TrusstorButton/TrusstorButton';
import { regularBorder } from '../../../constants/genericStyles.constants';
import { getRoundedActivityReport } from '../../../utils/roundedData.utils';
import { useSetActiveFilterType } from '../../../hooks/useSetActiveFilterType.hooks';
import { TitleSectionWithExportButton } from './ReportsComponents/ReportsSections/TitleSectionWithExportButton/TitleSectionWithExportButton';
import { RoleType } from '@shared/interfaces/RoleType.enum';
import { useInvestigationReportQuery } from '@src/hooks/queries/analysisCenter.queries.hooks';
import { IProjectOverviewReport } from '@shared/interfaces/IProjectOverviewReport';
import { Loader } from '@shared/components/Loader/Loader';
import arrow from '@shared/assets/icons/ArrowRight.svg';
import classNames from 'classnames';
import { useRenderMonitoring } from '@shared/hooks/monitoring.hooks';
import { exportTagDistributionReportToExcel } from '@utils/tagDistributionReport.utils';
import { IConfigArea } from '@shared/interfaces/IConfigArea';
import { useAreasDataQuery } from '@shared/hooks/queries/areas.queries.hooks';
import { useFloorDataQuery } from '@shared/hooks/queries/floors.queries.hooks';

const useStyles = makeStyles((theme) => ({
	wholePageContainer: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		overflow: 'auto',
	},
	pageContainer: {
		display: 'flex',
		width: '100%',
		overflow: 'hidden',
	},
	dataContainer: {
		display: 'flex',
		flexDirection: 'column',
		width: '85%',
		marginLeft: theme.spacing(1.5),
		marginRight: theme.spacing(1.5),
		marginBottom: theme.spacing(1),
		marginTop: theme.spacing(1),
		overflowX: 'auto',
	},
	titleSectionWithExportContainer: {
		marginTop: theme.spacing(1),
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	filterSectionWrapperClass: {
		marginBottom: theme.spacing(1),
		borderBottom: regularBorder,
		height: 'unset',
	},
	yellowBoxWithTitle: {
		width: '100%',
		justifyContent: 'space-between',
		flexDirection: 'row',
	},
	titleText: textStyle({
		fontClass: 'h1',
		fontWeight: 700,
	}),
	createButtonRoot: {
		height: 40,
		minWidth: 150,
		marginRight: 4,
		marginTop: 6,
		marginBottom: 6,
	},
	buttonsContainer: {
		display: 'flex',
		gap: 10,
	},
	backArrowContainer: {
		'minHeight': 32,
		'width': 32,
		'cursor': 'pointer',
		'borderRadius': 24,
		'backgroundColor': '#ffffff',
		'display': 'flex',
		'alignItems': 'center',
		'justifyContent': 'center',

		'&:hover': {
			backgroundColor: '#E7E9EB',
		},
	},
	backArrow: {
		height: 24,
		width: 24,
	},
	transform: {
		transform: 'rotate(180deg)',
	},
}));

const getPeriodicSafetyReportForAdminReport = async (
	formattedStartDate: string,
	formattedEndDate: string,
	projectId: string
): Promise<ISafetyReport> => {
	return requestService.get(
		`/reports/periodicProfessionsSafety?dateFrom=${formattedStartDate}&dateTo=${formattedEndDate}&projectId=${projectId}&interval=${config.reportsInterval}`
	);
};

const getActivityReportRoundedForAdminReport = async (
	formattedStartDate: string,
	formattedEndDate: string,
	projectId: string
): Promise<IActivitiesReport> => {
	const activityReport: IBackendActivitiesReport = await requestService.get(
		`/reports/periodicActivities?dateFrom=${formattedStartDate}&dateTo=${formattedEndDate}&projectId=${projectId}&interval=${config.reportsInterval}`
	);
	return getRoundedActivityReport(activityReport);
};

const getPeriodicProfessionReportForAdminReport = async (
	formattedStartDate: string,
	formattedEndDate: string,
	projectId: string
): Promise<IProfessionsAnalysisReport> => {
	return requestService.get(
		`/reports/professionsAnalysis?dateFrom=${formattedStartDate}&dateTo=${formattedEndDate}&projectId=${projectId}`
	);
};

const CombinedReportPage = (props: { match }) => {
	useRenderMonitoring('CombinedReportPage');
	const classes = useStyles();
	const history = useHistory();
	const reportId: string = props.match.params.reportId;
	const tz: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.tz);
	const workDayHours: IWorkDayHours[] = useSelector(
		(state: IRootState) => state.projectReducer.workingProject!.workDayHours
	);
	const professions: IProfession[] = useSelector((state: IRootState) => state.professionsReducer.professions);
	const floorsList: IFloor[] = useSelector((state: IRootState) => state.floorsListReducer.floorsList);
	const roundedProductivityReport: IRoundedProductivityReport = useSelector(
		(state: IRootState) => state.productivityReportReducer.roundedProductivityReport
	);
	const activityReportRounded: IActivitiesReport = useSelector(
		(state: IRootState) => state.activityReportReducer.activityReportRounded
	);
	const toggleStateDatePicker: DATE_PICKER_TOGGLE = useSelector(
		(state: IRootState) => state.datePickerReducer.toggleState
	);
	const visibleDateTimestamp: number = useSelector(
		(state: IRootState) => state.datePickerReducer.visibleDateTimestamp
	);
	const visiblePeriodTimestamps: [number, number] = useSelector(
		(state: IRootState) => state.datePickerReducer.visiblePeriodTimestamps
	);
	const visibleProfessions: IProfession[] = useSelector(
		(state: IRootState) => state.professionsReducer.visibleProfessions
	);
	const userRoleType: RoleType = useSelector(
		(state: IRootState) => state.loginReducer.loggedUser!.userDetails!.permissions.roleType
	);
	const projectId: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.projectId);
	const areas: IConfigArea[] = useAreasDataQuery(requestService, projectId);
	const floors: IFloor[] = useFloorDataQuery(requestService, projectId);
	const [safetyReport, setSafetyReport] = useState<ISafetyReport>();
	const [professionReport, setProfessionReport] = useState<IProfessionsAnalysisReport>();
	const [adminActivityReportRounded, setAdminActivityReportRounded] = useState<IActivitiesReport>();
	const [adminReportLoading, setAdminReportLoading] = useState<boolean>(false);
	const [adminTagDistributionReportLoading, setAdminTagDistributionReportLoading] = useState<boolean>(false);
	const isAdmin: boolean = userRoleType === RoleType.TRUSSTOR_ADMIN;

	const { investigationReport: currentReport, isInvestigationReportLoading } =
		useInvestigationReportQuery<IProjectOverviewReport>(reportId);

	useFilters(
		{
			useOnlyStartDate: toggleStateDatePicker === DATE_PICKER_TOGGLE.date,
			includeDeleted: false,
		},
		{ shouldResetDatePicker: false }
	);

	useSetActiveFilterType(APP_FILTER_TYPES.professionFilter);

	useEffect(() => {
		if (isAdmin) {
			(async () => {
				setAdminReportLoading(true);
				const formattedStartDate: string = getTimezoneFormattedDate(tz, visiblePeriodTimestamps[0]);
				const formattedEndDate: string = getTimezoneFormattedDate(tz, visiblePeriodTimestamps[1]);
				const adminActivityReportRounded: IActivitiesReport = await getActivityReportRoundedForAdminReport(
					formattedStartDate,
					formattedEndDate,
					projectId
				);
				const safetyReport: ISafetyReport = await getPeriodicSafetyReportForAdminReport(
					formattedStartDate,
					formattedEndDate,
					projectId
				);
				const professionReport: IProfessionsAnalysisReport = await getPeriodicProfessionReportForAdminReport(
					formattedStartDate,
					formattedEndDate,
					projectId
				);
				setAdminActivityReportRounded(adminActivityReportRounded);
				setSafetyReport(safetyReport);
				setProfessionReport(professionReport);
				setAdminReportLoading(false);
			})();
		}
	}, [tz, projectId, visiblePeriodTimestamps]);

	const handleBackClick = () => {
		history.goBack();
	};

	const handleExportTagDistributionReportClick = async () => {
		setAdminTagDistributionReportLoading(true);
		await exportTagDistributionReportToExcel(
			tz,
			projectId,
			visiblePeriodTimestamps,
			visibleDateTimestamp,
			toggleStateDatePicker,
			areas,
			floors
		);
		setAdminTagDistributionReportLoading(false);
	};

	const isRtl: boolean = translationService.getIsRtl();

	if (isInvestigationReportLoading) return <Loader />;

	return (
		<div className={classes.wholePageContainer}>
			<div className={classes.pageContainer}>
				<div className={SIDE_MAIN_COMPONENT_CLASS_NAME}>
					<DatePeriodPickerSection
						wrapperClass={classes.filterSectionWrapperClass}
						source={SummaryTypes.combined}
						toggleState={currentReport.periodPickerMode}
						fromDate={new Date(currentReport.fromDate)}
						toDate={currentReport.toDate && new Date(currentReport.toDate)}
					/>
					<ProfessionsFilterSection />
					<ManagersFilterSection />
					<FloorsSelectorSection
						floors={floorsList}
						handleResetClick={() => {}}
						currentFilter={[]}
						shouldUseReducer={true}
					/>
				</div>
				<div className={classes.dataContainer}>
					<div className={classes.backArrowContainer}>
						<img
							src={arrow}
							alt=""
							className={classNames({
								[classes.transform]: isRtl,
								[classes.backArrow]: true,
							})}
							onClick={handleBackClick}
						/>
					</div>
					<TitleSectionWithExportButton
						title={translationService.get('executiveSummary')}
						onClick={() =>
							exportCombinedReportToExcel(
								tz,
								toggleStateDatePicker,
								visibleDateTimestamp,
								visiblePeriodTimestamps,
								roundedProductivityReport,
								activityReportRounded,
								workDayHours
							)
						}
						children={
							isAdmin && (
								<>
									<TrusstorButton
										showLoader={adminReportLoading}
										text={translationService.get('exportAdminReportToExcel')}
										handleClick={
											!adminReportLoading
												? () =>
														exportAdminReportToExcel(
															tz,
															projectId,
															visiblePeriodTimestamps,
															visibleDateTimestamp,
															toggleStateDatePicker,
															roundedProductivityReport,
															adminActivityReportRounded,
															visibleProfessions,
															floorsList,
															professions,
															safetyReport,
															professionReport
														)
												: () => {}
										}
									/>
									<TrusstorButton
										showLoader={adminTagDistributionReportLoading}
										text={translationService.get('exportAdminReportToExcel_tagDistribution')}
										handleClick={(e) => {
											e.stopPropagation();
											if (adminTagDistributionReportLoading) {
												return;
											}

											handleExportTagDistributionReportClick();
										}}
									/>
								</>
							)
						}
					/>
					<ProductivitySection />
					<ActivitiesSection tableHeight={'420px'} />
					<SafetySection />
				</div>
			</div>
		</div>
	);
};

export { CombinedReportPage };
