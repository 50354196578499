import React from 'react';
import { makeStyles } from '@material-ui/core';
import { SafetyIcon } from '../Safety/SafetyIcon';
import { safetyOrange, safetyRed } from '../../assets/safetyIcons';
import { compareProps } from '../../utils/generalUtils';
import { IFloorData } from '../../interfaces/IFloorData';
import { IBuildingSvgPoints } from '../../interfaces/IBuildingSvgPoints';
import { IMinimalSafetyEventForClient } from '../../interfaces/ISafetyEvent';
import { ISvgElementPoint } from '../../interfaces/ISvgElementPoint';

const useStyles = makeStyles({
	safetyWrapper: {
		position: 'absolute',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
});

interface IFloorSafetyProps {
	floorData: IFloorData;
	svgPoints: IBuildingSvgPoints;
	openSafetyDialog: (safetyEvent: IMinimalSafetyEventForClient[]) => void;
}

const FloorSafety = React.memo((props: IFloorSafetyProps) => {
	const classes = useStyles();
	const safetyEventsForFloor: IMinimalSafetyEventForClient[] = props.floorData.safetyAlerts;
	const safetyPointForFloor: ISvgElementPoint | undefined = props.svgPoints.alertPointsArray.find(
		(floor) => floor.floorId === props.floorData.floorId
	);

	if (!safetyPointForFloor || safetyEventsForFloor.length === 0) {
		return null;
	}

	const iconSource: string = safetyEventsForFloor.find((event) => event.isCritical) ? safetyRed : safetyOrange;

	return (
		<div
			key={`floor${props.floorData.floorId}_safetyContainer`}
			className={classes.safetyWrapper}
			style={{
				top: safetyPointForFloor.top,
				left: safetyPointForFloor.left,
				right: safetyPointForFloor.right,
			}}
		>
			<SafetyIcon
				safetyEvents={safetyEventsForFloor}
				iconSource={iconSource}
				click={() => props.openSafetyDialog(safetyEventsForFloor)}
			/>
		</div>
	);
}, compareProps);

export { FloorSafety };
