import React, { useRef, useState } from 'react';
import { ICreateIssue, IIssue } from '@shared/interfaces/IIssue';
import { IssueItem } from '@shared/components/Issue/IssueItem';
import classes from './styles.module.scss';
import { IProfession } from '@interfaces/IProfession';
import { requestService, storageService, translationService } from '@src/servicesInitializers';
import { useDispatch, useSelector } from 'react-redux';
import { selectProjectId, selectTimezone } from '@store/slices/project.slice';
import { issueComment_BI, issueStatusChange_BI } from '@utils/bi.utils';
import { IssueColumnHeader } from './IssueColumnHeader/IssueColumnHeader';
import { IIssueAssignee, IIssueShared, IssueStatus } from '@shared/interfaces/IIssueShared';
import { IssueCreateCard } from '../IssueCreateCard/IssueCreateCard';
import { createIssueApi } from '@src/apis/issues.api';
import { selectLoggedUserDetails } from '@store/slices/login.slice';
import { IUser } from '@shared/interfaces/IUser';
import { errorSnackbar } from '@utils/snackbar.utils';
import { ICommentShared } from '@shared/interfaces/ICommentShared';
import { addImagesToIssue, sortIssuesByDueDateAndPriority } from '@shared/utils/issues.utils';
import { IssuesPageViewModes } from '@shared/constants/issues.contants';
import classnames from 'classnames';
import { useDragAndDrop } from '@src/hooks/dragAndDrop.hooks';
import { TrusstorIcon } from '@src/Components/TrusstorIcon/TrusstorIcon';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { useIsFeatureFlagEnabled } from '@shared/hooks/useFeatureFlag';
import { FeatureFlagNamesEnum } from '@shared/constants/featureFlags.enum';
import { IconSize } from '@shared/components/TrusstorIconShared/TrusstorIconShared';

interface IIssuesColumnProps {
	issues: IIssue[];
	updateIssue: (
		issueId: string,
		issue: Partial<IIssueShared<IProfession>>,
		updateBackend?: boolean
	) => Promise<IIssueShared<IProfession>>;
	deleteIssue: (issueId: string) => Promise<void>;
	onIssueCreate: (issue: IIssue) => void;
	setIssueToEdit: React.Dispatch<React.SetStateAction<IIssue | undefined>>;
	isEditIssueDialogShown: boolean;
	viewMode: IssuesPageViewModes;
	useSmallColumnWidth?: boolean;
	onDragStart: (dragIndex: number) => void;
	onDragFinish: (dragIndex: number) => void;
	moveColumn: (dragIndex: number, hoverIndex: number) => void;
	index: number;
}

export const IssuesColumn = (props: IIssuesColumnProps) => {
	const dispatch = useDispatch();
	const tz: string = useSelector(selectTimezone)!;
	const projectId: string = useSelector(selectProjectId)!;
	const user: IUser = useSelector(selectLoggedUserDetails)!;
	const professionColumn: IProfession | undefined = props.issues[0].profession;
	const assigneeColumn: IIssueAssignee | undefined = props.issues[0].assignee || undefined;
	const [showCreateCard, setShowCreateCard] = useState<boolean>(false);
	const dragAndDropRef = useRef(null);
	const dragAndDropPreviewRef = useRef(null);
	const isDragAndDropEnabled: boolean = useIsFeatureFlagEnabled(FeatureFlagNamesEnum.issuesDragAndDrop);
	const { isDragging } = useDragAndDrop({
		dragAndDropRef,
		dragAndDropPreview: dragAndDropPreviewRef,
		currentIndex: props.index,
		id:
			(props.viewMode === IssuesPageViewModes.PROFESSIONS ? professionColumn?._id : assigneeColumn?.username) ||
			'general',
		onDragStart: props.onDragStart,
		onDragFinish: props.onDragFinish,
		moveElement: props.moveColumn,
		dragType: 'issuesColumn',
	});

	const handlePlusClick = () => {
		setShowCreateCard(true);
	};

	const handleCreateIssue = async (createIssue: ICreateIssue, images: File[]) => {
		setShowCreateCard(false);
		const issueWithViewModeEntity: ICreateIssue = {
			...createIssue,
			...(props.viewMode === IssuesPageViewModes.PROFESSIONS
				? { profession: professionColumn }
				: { assignee: assigneeColumn }),
		};
		if (issueWithViewModeEntity) {
			try {
				const newIssue: IIssue = await createIssueApi(issueWithViewModeEntity);
				const comments: ICommentShared[] = await addImagesToIssue(newIssue._id, user, images, requestService);
				const fullIssue: IIssue = {
					...newIssue,
					comments,
				};
				props.onIssueCreate(fullIssue);
			} catch (e) {
				console.log('error creating issue', e);
			}
		}
	};

	const sortedIssuesByDate: IIssue[] = sortIssuesByDueDateAndPriority(props.issues);

	const handleSizeLimitImageError = () => {
		console.log('handleSizeLimitImageError');
		errorSnackbar(dispatch, translationService.get('imageAboveTheSizeLimitError'));
	};

	return (
		<div
			className={classnames({
				[classes.column]: true,
				[classes.smallColumn]: props.useSmallColumnWidth,
				[classes.hide]: isDragging,
			})}
			ref={dragAndDropPreviewRef}
		>
			<div
				className={classnames(classes.dragArea, { [classes.show]: isDragAndDropEnabled })}
				ref={dragAndDropRef}
			>
				<TrusstorIcon size={IconSize.SMALL} className={classes.dragIcon} iconName={IconNames.drag} />
			</div>
			<div className={classes.issuesHeaderContainer}>
				<IssueColumnHeader
					count={sortedIssuesByDate.length}
					viewMode={props.viewMode}
					profession={professionColumn}
					assignee={assigneeColumn}
					onPlusClick={handlePlusClick}
				/>
			</div>
			{showCreateCard && (
				<div className={classes.issueCreateContainer}>
					<IssueCreateCard
						onIssueCreate={handleCreateIssue}
						onCancel={() => {
							setShowCreateCard(false);
						}}
						hideProfession={props.viewMode === IssuesPageViewModes.PROFESSIONS}
						hideAssignee={props.viewMode === IssuesPageViewModes.ASSIGNEES}
					/>
				</div>
			)}

			{sortedIssuesByDate.map((issue) => {
				return (
					<IssueItem
						isEditIssueDialogShown={props.isEditIssueDialogShown}
						setIssueToEdit={props.setIssueToEdit}
						issueComment_BI={issueComment_BI}
						deleteIssue={props.deleteIssue}
						key={issue._id}
						issue={issue}
						tz={tz}
						projectId={projectId}
						translationService={translationService}
						updateIssue={props.updateIssue}
						issueStatusChangeBiEvent={(status: IssueStatus, issueUpdated: IIssue) => {
							issueStatusChange_BI(issueUpdated, status, tz);
						}}
						storageService={storageService}
						requestService={requestService}
						user={user}
						imageSizeLimitCallback={handleSizeLimitImageError}
						hideAssignee={props.viewMode === IssuesPageViewModes.ASSIGNEES}
						hideProfession={props.viewMode === IssuesPageViewModes.PROFESSIONS}
						usePathForDialog
					/>
				);
			})}
		</div>
	);
};
