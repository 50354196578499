import React, { useCallback, useEffect, useState } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { minBy, orderBy } from 'lodash';
import { CalendarCell } from '../CalendarCell/CalendarCell';
import { IWorkPlanActivity, ICalendarSection, IBaseFloor } from '../../../interfaces';
import { ACTIVITY_CHIP_HEIGHT, CalendarToggleOptions } from '../../../constants';
import classes from './styles.module.scss';
import { CalendarActivityLongChip } from '../CalendarActivityLongChip/CalendarActivityLongChip';
import { IRootState } from '../../../store/slices';
import { useSelector } from 'react-redux';
import { IWorkDayHours } from '@shared/interfaces/IWorkDayHours';
import { useResizeObserver } from '@shared/hooks/useResizeObserver';

const MIN_ACTIVITIES_IN_CELL = 3;

interface CalendarRowProps {
	activitiesPerWeek: IWorkPlanActivity[][] | null;
	cellStyleClass: string;
	workPlanToggle: CalendarToggleOptions;
	section: ICalendarSection;
	checkOpenCreateDialog: (ICalendarSection, Date) => void;
	dateRange: Date[];
	checkOpenEditActivityDialog: (date: Date, activityId: string) => void;
	isToday: (date: Date) => boolean;
	isCalendarCellClickable: (date: Date) => boolean;
}

const CalendarRow = (props: CalendarRowProps) => {
	const { observeElement: observeTableElement, contentBoxSize: tableBoxSize } = useResizeObserver();
	const { observeElement: observeColHeader, contentBoxSize: colHeaderBoxSize } = useResizeObserver();

	const workDayHours: IWorkDayHours[] = useSelector(
		(state: IRootState) => state.projectReducer.workingProject!.workDayHours
	);
	const [calendarCellHeight, setCalendarCellHeight] = useState<number>();
	const [tableCellWidth, setTableCellWidth] = useState<number>(0);

	useEffect(() => {
		if (!tableBoxSize || !colHeaderBoxSize) {
			return;
		}
		const numberOfRowCells: number = props.dateRange.length;
		if (tableBoxSize) {
			const cellSize: number = (tableBoxSize.inlineSize - colHeaderBoxSize.inlineSize - 4) / numberOfRowCells;
			setTableCellWidth(cellSize);
		}
	}, [tableBoxSize, colHeaderBoxSize, props.dateRange]);

	const tableRowRef = useCallback(
		(node) => {
			if (node !== null) {
				observeTableElement(node);
			}
		},
		[observeTableElement]
	);

	const firstColElementRef = useCallback(
		(node) => {
			if (node !== null) {
				observeColHeader(node);
			}
		},
		[observeColHeader]
	);

	useEffect(() => {
		const maxActivitiesInCell: number | undefined = props.activitiesPerWeek?.flat().length;
		const maxActivitiesPerDay = maxActivitiesInCell || MIN_ACTIVITIES_IN_CELL;
		const activityChipTopMargin: number = 8;
		const calendarRowBottomMargin: number = 16;
		const calendarCellHeight: number =
			maxActivitiesPerDay * (ACTIVITY_CHIP_HEIGHT + activityChipTopMargin) + calendarRowBottomMargin;
		setCalendarCellHeight(calendarCellHeight);
	}, [calendarCellHeight, props.activitiesPerWeek, props.section.id]);

	const isDayOff = (dayIndex: number): boolean => {
		if (props.dateRange[dayIndex] === undefined) {
			return false;
		}
		const weekDay: number = props.dateRange[dayIndex].getDay();
		return workDayHours[weekDay].dailyWorkerHours === 0;
	};

	return (
		<React.Fragment>
			{calendarCellHeight && (
				<TableRow ref={tableRowRef} key={props.section.id} className={classes.activityRow}>
					<TableCell ref={firstColElementRef} className={`${props.cellStyleClass} ${classes.firstColumn}`}>
						<CalendarCell
							text={props.section.title}
							profession={props.section.profession}
							isClickable={false}
							height={calendarCellHeight}
						/>
					</TableCell>
					{props.activitiesPerWeek ? (
						<React.Fragment>
							{props.activitiesPerWeek.map((activitiesOfTheDay, index) => (
								<TableCell className={props.cellStyleClass} key={`${index}_${props.section.id}`}>
									<CalendarCell
										height={calendarCellHeight}
										workPlanToggle={props.workPlanToggle}
										activities={orderBy(activitiesOfTheDay, 'floor.floorId')}
										isMarked={props.isToday(props.dateRange[index])}
										openCreateDialog={() =>
											props.checkOpenCreateDialog(props.section, props.dateRange[index])
										}
										openEditDialog={(groupId: string) =>
											props.checkOpenEditActivityDialog(props.dateRange[index], groupId)
										}
										isClickable={props.isCalendarCellClickable(props.dateRange[index])}
										isDayOff={isDayOff(index)}
									/>
								</TableCell>
							))}
						</React.Fragment>
					) : (
						props.dateRange.map((_, index) => {
							return (
								<TableCell className={props.cellStyleClass} key={`${index}_empty_table`}>
									<CalendarCell
										height={calendarCellHeight}
										isMarked={props.isToday(props.dateRange[index])}
										openCreateDialog={() =>
											props.checkOpenCreateDialog(props.section, props.dateRange[index])
										}
										isClickable={props.isCalendarCellClickable(props.dateRange[index])}
										isDayOff={isDayOff(index)}
									/>
								</TableCell>
							);
						})
					)}

					{props.activitiesPerWeek &&
						props.activitiesPerWeek.map((activitiesOfSection, index) =>
							orderBy(activitiesOfSection, (activity: IWorkPlanActivity) => {
								const lowestFloorOfActivity: IBaseFloor | undefined = minBy(
									activity.floors,
									(floor) => floor.floorId
								);
								return lowestFloorOfActivity?.floorId;
							}).map((activity: IWorkPlanActivity) => {
								return (
									<CalendarActivityLongChip
										activity={activity}
										tableCellWidth={tableCellWidth}
										activityDayIndex={index}
										calendarCellHeight={calendarCellHeight}
										workPlanToggle={props.workPlanToggle}
										checkOpenEditActivityDialog={props.checkOpenEditActivityDialog}
										isCalendarCellClickable={props.isCalendarCellClickable}
										dateRange={props.dateRange}
									/>
								);
							})
						)}
				</TableRow>
			)}
		</React.Fragment>
	);
};

export { CalendarRow };
