export class MilestonesLocalStorage {
	private isNotFirstTimeToSelectSequenceItemToLinkString: string = 'isNotFirstTimeToSelectSequenceItemToLink';

	public getIsNotFirstTimeTolinkSequenceItemToMilestone(): boolean {
		return localStorage.getItem(this.isNotFirstTimeToSelectSequenceItemToLinkString) === 'true';
	}

	public setIsNotFirstTimeToLinkSequenceItemToMilestone(isNotFirstTimeToSelectSequenceItemToLink: boolean): void {
		localStorage.setItem(
			this.isNotFirstTimeToSelectSequenceItemToLinkString,
			isNotFirstTimeToSelectSequenceItemToLink.toString()
		);
	}
}
