import { useQuery } from '@tanstack/react-query';
import { IConfigArea } from '../../interfaces/IConfigArea';
import { RequestService } from '../../services/request.service';

export const useAreasByFloorIdDataQuery = (
	requestService: RequestService,
	projectId: string,
	floorId: string
): IConfigArea[] | undefined => {
	const { data: areas } = useQuery<IConfigArea[]>(
		['areasData', projectId, floorId],
		() => requestService.get(`/projectConfig/area?projectId=${projectId}&floorId=${floorId}`),
		{
			useErrorBoundary: true,
			cacheTime: Infinity,
		}
	);
	return areas;
};

export const useAreasDataQuery = (requestService: RequestService, projectId: string): IConfigArea[] => {
	const { data: areas } = useQuery<IConfigArea[]>(
		['areasData', projectId],
		() => requestService.get(`/projectConfig/area?projectId=${projectId}`),
		{
			useErrorBoundary: true,
			initialData: [],
			cacheTime: Infinity,
		}
	);
	return areas;
};
