import React from 'react';
import { Loader } from '@shared/components/Loader/Loader';
import { ITrusstorButtonType, TrusstorButton } from '@shared/components/buttons/TrusstorButton/TrusstorButton';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { TrusstorIcon } from '@src/Components/TrusstorIcon/TrusstorIcon';
import { translationService } from '@src/servicesInitializers';
import { errorSnackbar, successSnackbar } from '@utils/snackbar.utils';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import classes from './styles.module.scss';
import { IconColor } from '@shared/components/TrusstorIconShared/TrusstorIconShared';

interface IExportSectionProps {
	onExport: () => Promise<void>;
	disableSnackbars?: boolean;
}

export const ExportSection = (props: IExportSectionProps) => {
	const [isExporting, setIsExporting] = useState<boolean>(false);
	const dispatch = useDispatch();

	const handleExportToExcel = async () => {
		if (isExporting) return;
		setIsExporting(true);
		try {
			await props.onExport();
			if (!props.disableSnackbars) {
				successSnackbar(dispatch, translationService.get('exportSuccess'));
			}
		} catch (e) {
			if (!props.disableSnackbars) {
				errorSnackbar(dispatch, translationService.get('exportError'));
			}
		}
		setIsExporting(false);
	};

	return (
		<TrusstorButton
			text={translationService.get(isExporting ? 'exporting' : 'export')}
			iconElement={
				isExporting ? <Loader /> : <TrusstorIcon iconName={IconNames.download} color={IconColor.Grey600} />
			}
			buttonType={ITrusstorButtonType.GHOST}
			handleClick={handleExportToExcel}
			className={classes.exportButton}
		/>
	);
};
