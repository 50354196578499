import React from 'react';
import classes from './styles.module.scss';
import { useSequenceItemsByProjectQuery } from '@src/hooks/queries/sequenceItem.query';
import { Dictionary, groupBy } from 'lodash';
import { ISequenceItem } from '@interfaces/ISequenceItem';
import { useSelector } from 'react-redux';
import { selectProjectId } from '@store/slices/project.slice';
import { translationService } from '@src/servicesInitializers';
import { SequenceTypeDropdown } from '@shared/components/SequenceTypeDropDown/SequenceTypeDropdown';
import { TrusstorIcon } from '../TrusstorIcon/TrusstorIcon';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import classnames from 'classnames';
import { useProjectSequencesQuery } from '@src/hooks/queries/sequences.hooks';
import { ISequence } from '@shared/interfaces/ISequence';

interface SequenceSelectorParams {
	currentMilestoneId: string;
	selectedSequenceId: string | null;
	selectSequence: (sequenceId: string) => void;
}

export const SequenceSelector = (props: SequenceSelectorParams) => {
	const projectId: string = useSelector(selectProjectId)!;
	const { sequences } = useProjectSequencesQuery(projectId);
	const { sequenceItems } = useSequenceItemsByProjectQuery(projectId);
	const sequenceItemsBySequence: Dictionary<ISequenceItem[]> = groupBy(sequenceItems, 'sequenceId');

	const isRtl: boolean = translationService.getIsRtl();
	return (
		<div className={classes.SequenceSelector_container} data-testid="SequenceSelector">
			<div className={classes.sequenceTitle}>{`${translationService.get('milestones_page_sequences_title')} (${
				sequences.length
			})`}</div>
			{sequences.map((sequence) => {
				const sequenceId: string = sequence._id;
				const sequenceSequenceItems: ISequenceItem[] | undefined = sequenceItemsBySequence[sequenceId];
				const linkedSequenceItems: ISequenceItem[] = sequenceSequenceItems?.filter(
					(sequenceItem) => sequenceItem.linkedMilestoneId === props.currentMilestoneId
				);
				const isSequenceSelected: boolean = sequenceId === props.selectedSequenceId;
				return (
					<div
						key={sequenceId}
						className={classnames({ [classes.selected]: isSequenceSelected }, classes.sequenceSection)}
						onClick={() => props.selectSequence(sequenceId)}
					>
						<div className={classes.section}>
							<SequenceTypeDropdown currentSequenceType={sequence.sequenceType} readOnly />
							<div className={classes.description}>{sequence.name}</div>
						</div>
						<div className={classes.section}>
							<div className={classes.amountLinked}>{`${linkedSequenceItems?.length || 0}/${
								sequenceSequenceItems?.length || 0
							}`}</div>
							<TrusstorIcon iconName={isRtl ? IconNames.chevronLeft : IconNames.chevronRight} />
						</div>
					</div>
				);
			})}
		</div>
	);
};
