import React from 'react';
import { TitleColoredBox } from '../../../../Boxes/Containers/TitleColoredBox/TitleColoredBox';
import { translationService } from '../../../../../index';
import { WorkersQuantityPerDateGraph } from '../Charts/WorkersQuantityPerDateGraph';
import { makeStyles } from '@material-ui/core';
import { textStyle } from '@shared/utils/text.utils';
import { getDateFromFormat } from '../../../../../utils/dateUtils';
import { WorkersHoursPerDateGraph } from '../Charts/WorkersHoursPerDateGraph';
import { ILineGraphData } from '../Charts/interfaces/ILineGraphData';
import { IFirstLastWorkersHours } from '../../../../../interfaces/IFirstLastWorkersHours';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../../../store/slices';
import {
	IRoundedDateProfessionAnalysisData,
	IRoundedProfessionsAnalysisReportData,
} from '../../../../../interfaces/IRoundedProfessionsAnalysisReport';
import { dateFormats } from '@shared/constants/formats.constants';
import { getProductRoundedHoursFromHours } from '@shared/utils/roundNumbers.utils';

const useStyles = makeStyles((theme) => ({
	averageHourCard: {
		height: '100%',
		marginRight: 0,
		marginLeft: 0,
	},
	averageHourNumberContainer: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	averageHourNumber: textStyle({
		fontSize: '6.5rem',
		marginLeft: theme.spacing(0.5),
	}),
	hourText: textStyle({
		fontClass: 'h1',
		marginTop: theme.spacing(-2),
		marginLeft: theme.spacing(0.5),
		marginBottom: theme.spacing(1),
	}),
	workersAmountGraphContainer: {
		flex: '1',
		marginInlineStart: '15px',
		height: '100%',
		marginRight: 0,
		marginLeft: 0,
	},
}));

interface IWorkersPerDaySectionProps {
	professionReport: IRoundedProfessionsAnalysisReportData;
}

const WorkersPerDaySection = (props: IWorkersPerDaySectionProps) => {
	const classes = useStyles();

	const tz: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.tz);

	const getWorkersQuantityGraphData = () => {
		if (Object.keys(props.professionReport).length === 0) {
			return [];
		}

		return Object.entries(props.professionReport.dates).map(
			([date, dateProfessionReport]: [string, IRoundedDateProfessionAnalysisData]) => {
				return {
					label: getDateFromFormat(date, dateFormats.DMY).toDate().toDateString(),
					data: dateProfessionReport.totalWorkers,
				};
			}
		);
	};
	const getTotalAverageWorkDayHours = (): number => {
		if (props.professionReport.dates) {
			const totalWorkHoursPerWorker: number = Object.values(props.professionReport.dates).reduce(
				(acc, dateProfessionReport) => {
					if (dateProfessionReport.totalWorkHours && dateProfessionReport.totalWorkers)
						return acc + dateProfessionReport.totalWorkHours / dateProfessionReport.totalWorkers;
					return acc;
				},
				0
			);
			return getProductRoundedHoursFromHours(
				totalWorkHoursPerWorker / Object.keys(props.professionReport.dates).length
			);
		}
		return 0;
	};

	const getWorkerHours = (
		professionReport: IRoundedProfessionsAnalysisReportData,
		hoursFieldName: string
	): ILineGraphData[] => {
		return Object.entries(props.professionReport.dates).map(
			([date, dateProfessionReport]: [string, IRoundedDateProfessionAnalysisData]) => {
				return {
					label: getDateFromFormat(date, dateFormats.DMY).toDate().toDateString(),
					data: dateProfessionReport[hoursFieldName]
						? moment.tz(dateProfessionReport[hoursFieldName], tz).hours()
						: 0,
				};
			}
		);
	};

	const getWorkersHoursGraphData = (): IFirstLastWorkersHours => {
		if (Object.keys(props.professionReport).length === 0) {
			return {
				firstWorkHoursLine: [],
				lastWorkHoursLine: [],
			};
		}

		const firstWorkHoursLine: ILineGraphData[] = getWorkerHours(props.professionReport, 'dayFirstWorkDateTime');

		const lastWorkHoursLine: ILineGraphData[] = getWorkerHours(props.professionReport, 'dayLastWorkDateTime');

		return {
			firstWorkHoursLine,
			lastWorkHoursLine,
		};
	};

	const averageWorkDayHours: number =
		Object.keys(props.professionReport).length === 0 ? 0 : getTotalAverageWorkDayHours();
	const workersQuantityGraphData: ILineGraphData[] = getWorkersQuantityGraphData();
	const workersHoursGraphData: IFirstLastWorkersHours = getWorkersHoursGraphData();
	return (
		<React.Fragment>
			<TitleColoredBox
				title={`${translationService.get('averageWorkDayTime')}`}
				customRootStyleClass={classes.averageHourCard}
			>
				<div className={classes.averageHourNumberContainer}>
					<p className={classes.averageHourNumber}>{averageWorkDayHours}</p>
					<p className={classes.hourText}>{`${translationService.get('hours')}`}</p>
				</div>
			</TitleColoredBox>
			<TitleColoredBox
				title={`${translationService.get('activityHours')}`}
				customRootStyleClass={classes.workersAmountGraphContainer}
			>
				<WorkersHoursPerDateGraph
					firstWorkHoursLine={workersHoursGraphData.firstWorkHoursLine}
					lastWorkHoursLine={workersHoursGraphData.lastWorkHoursLine}
				/>
			</TitleColoredBox>
			<TitleColoredBox
				title={`${translationService.get('numberOfWorkers')}`}
				customRootStyleClass={classes.workersAmountGraphContainer}
			>
				<WorkersQuantityPerDateGraph dataArray={workersQuantityGraphData} />
			</TitleColoredBox>
		</React.Fragment>
	);
};

export { WorkersPerDaySection };
