import React from 'react';
import { Avatar } from '@material-ui/core';
import classes from './styles.module.scss';
import { TranslationService } from '../../../services/translation.service';
import { DIRECTIONS } from '../../../constants/directions';
interface IManagerAvatarProps {
	avatarBackground: string;
	managerName: string;
	initialsSize: number;
	customClassName?: string;
	translationService: TranslationService;
}

const ManagerAvatar = ({
	avatarBackground,
	managerName,
	initialsSize,
	customClassName,
	translationService,
}: IManagerAvatarProps): JSX.Element => {
	const initialsSizeReformat: number =
		translationService.getDirection() === DIRECTIONS.RTL ? initialsSize : initialsSize * 0.8;

	const getManagerInitials = (managerName: string): string => {
		if (!managerName) {
			return '';
		}

		const initialsArray: string[] = managerName.trim().split(' ');

		return initialsArray.length > 1 ? `${initialsArray[0][0]}${initialsArray[1][0]}` : `${initialsArray[0][0]}`;
	};

	return (
		<Avatar
			className={`${classes.avatar} ${customClassName}`}
			data-testId="managerAvatar"
			style={{ backgroundColor: avatarBackground }}
		>
			<div className={classes.initials} style={{ fontSize: initialsSizeReformat }}>
				{getManagerInitials(managerName)}
			</div>
		</Avatar>
	);
};

export { ManagerAvatar };
