import React from 'react';
import classes from './styles.module.scss';
import { COLORS } from '@shared/constants/colors.constants';
import { translationService } from '@src/servicesInitializers';

interface ITagsAnalysisCustomTooltipProps {
	date: string | undefined;
	workers: number | undefined;
	workHours: number | undefined;
}

export const TagsAnalysisCustomTooltip = (props: ITagsAnalysisCustomTooltipProps) => {
	return (
		<div className={classes.TagsAnalysisCustomTooltip_container}>
			<div className={classes.date}>{props.date}</div>
			{typeof props.workers !== 'undefined' && (
				<div className={classes.workers}>
					<div className={classes.dot} style={{ background: COLORS.blue300 }}></div>
					<div>
						{`${translationService.get('TagsAnalysisVsHoursGraph_workersPerDay_label')}: ${props.workers}`}
					</div>
				</div>
			)}
			{typeof props.workHours !== 'undefined' && (
				<div className={classes.workHours}>
					<div className={classes.dot} style={{ background: COLORS.spaceCadet }}></div>
					<div>
						{`${translationService.get('TagsAnalysisVsHoursGraph_hoursPerDay_label')}: ${props.workHours}`}
					</div>
				</div>
			)}
		</div>
	);
};
