const minCellWidth: number = 132;
const maxCellWidth: number = 192;
const tocWidth: number = 48;
const defaultMatrixWidthLargeScreen: number = 1428;
const defaultMatrixWidthNarrowScreen: number = 1250;

export const activitySequenceMatrixConstants = {
	minCellWidth,
	maxCellWidth,
	tocWidth,
	defaultMatrixWidthLargeScreen,
	defaultMatrixWidthNarrowScreen,
};
