import { ITagRouteWithLocationData } from '@shared/interfaces/ITagRouteWithLocationData';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import warningCircle from '../../../../assets/investigationReport/warningCircle.svg';
import { IRootState } from '../../../../store/slices';
import { LocationHeader } from '../LocationHeader/LocationHeader';
import { ReportPersonnelOnLocation } from '../ReportPersonnelOnLocation/PersonnelOnLocation';
import { InvestigationReportLocationActivitiesSection } from './LocationInfoDataListSections/InvestigationReportActivitiesSection';
import { InvestigationReportEquipmentSection } from './LocationInfoDataListSections/InvestigationReportEquipmentSection/InvestigationReportEquipmentSection';
import { InvestigationReportSafetyEventsSection } from './LocationInfoDataListSections/InvestigationReportSafetyEventsSection';
import classes from './styles.module.scss';
import { useDailyActivitiesQuery } from '@src/hooks/queries/analysisCenter.queries.hooks';
import { useSafetyEventByTimeRangeQuery } from '@src/hooks/queries/safetyEvent.queries.hooks';

interface IReportTagsLocationInfoProps {
	tagLocationData: ITagRouteWithLocationData;
	locationNumber: number;
	locationNumberOfTheIncident?: number;
	isIncidentReport?: boolean;
	pdfMode: boolean;
}

export const ReportTagsLocationInfo = (props: IReportTagsLocationInfoProps) => {
	const projectId: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.projectId);
	const tz: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.tz);

	const { safetyEvents, isInitialLoading: isInitialLoadingSafetyEvents } = useSafetyEventByTimeRangeQuery(
		projectId,
		tz,
		props.tagLocationData?.arrivalTime,
		props.tagLocationData?.departureTime,
		props.tagLocationData.areaId
	);

	const { arrivalTime, floorId } = props.tagLocationData;
	const { dailyActivities, isInitialLoading: isInitialLoadingDailyActivities } = useDailyActivitiesQuery(
		projectId,
		tz,
		moment(arrivalTime).valueOf(),
		floorId
	);

	const shouldShowIconOnHeader: boolean = !!(
		props.isIncidentReport && props.locationNumber === props.locationNumberOfTheIncident
	);
	return (
		<div className={classes.reportTagsLocationInfo}>
			<LocationHeader
				tagLocationData={props.tagLocationData}
				locationNumber={props.locationNumber}
				icon={shouldShowIconOnHeader ? warningCircle : undefined}
			/>
			<div className={classes.bottomBorder}>
				<ReportPersonnelOnLocation tagLocationData={props.tagLocationData} />
			</div>
			{props.isIncidentReport && (
				<div className={classes.bottomBorder}>
					<InvestigationReportSafetyEventsSection
						safetyEvents={safetyEvents}
						isLoading={isInitialLoadingSafetyEvents}
						pdfMode={props.pdfMode}
					/>
				</div>
			)}
			<div className={classes.bottomBorder}>
				<InvestigationReportLocationActivitiesSection
					activities={dailyActivities}
					isLoading={isInitialLoadingDailyActivities}
				/>
			</div>
			<InvestigationReportEquipmentSection tagLocationData={props.tagLocationData} />
		</div>
	);
};
