import React from 'react';
import { map } from 'lodash';
import { ChipWithNumber } from '../ChipWithNumber';
import { ISvgElementPoint } from '../../../interfaces/ISvgElementPoint';
import { IProfessionWithActiveTags } from '../../../interfaces/IProfessionWithActiveTags';
import {
	getProfessionBackgroundColor,
	getProfessionDisplayTextFromDisplaySettings,
} from '@shared/utils/professions.utils';
import { useSelector } from 'react-redux';
import { selectProjectId } from '@store/slices/project.slice';
import { translationService } from '@src/servicesInitializers';

interface IProfessionsChipsTooltipProps {
	professionPointForFloor: ISvgElementPoint;
	professionsForFloor: IProfessionWithActiveTags[];
}

const ProfessionsChipsTooltip = (props: IProfessionsChipsTooltipProps) => {
	const projectId: string = useSelector(selectProjectId)!;
	return (
		<div
			style={{
				display: 'flex',
			}}
		>
			{map(props.professionsForFloor, (profession) => {
				const chipText: string = getProfessionDisplayTextFromDisplaySettings(
					profession,
					translationService,
					projectId
				);
				return (
					<ChipWithNumber
						key={`floor_${props.professionPointForFloor.floorId}_${profession._id}`}
						chipText={chipText}
						chipColor={getProfessionBackgroundColor(profession, projectId)}
						number={profession.activeTagsIds.length}
					/>
				);
			})}
		</div>
	);
};

export default ProfessionsChipsTooltip;
