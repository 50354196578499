import React from 'react';
import classes from './styles.module.scss';
import warningIcon from '@shared/assets/icons/dangerousActivityIcon.svg';
import { SimpleChip } from '@shared/components/SimpleChip/SimpleChip';
interface IInvestigationReportDataListSectionRowProps {
	label: string;
	chipContent: string;
	chipColor: string;
	showWarningIcon?: boolean;
	pdfMode?: boolean;
}

export const InvestigationReportDataListSectionRow = (props: IInvestigationReportDataListSectionRowProps) => {
	return (
		<div className={classes.sectionRow}>
			<div className={classes.textIconSection}>
				{props.showWarningIcon && <img src={warningIcon} />}
				<p className={classes.label}>{props.label}</p>
			</div>
			{!props.pdfMode && (
				<SimpleChip chipText={props.chipContent} chipColor={props.chipColor} fontClass="h1" chipHeight={16} />
			)}
		</div>
	);
};
