import React, { useContext, useEffect, useState } from 'react';
import classnames from 'classnames';
import classes from '@src/Components/Matrix/cellComponents/MatrixHeader/styles.module.scss';
import { errorSnackbar } from '@utils/snackbar.utils';
import { translationService } from '@src/servicesInitializers';
import { WorkplanContext } from '@src/Components/Pages/Workplan/WorkPlan';
import { useDispatch } from 'react-redux';

interface IHeaderProps {
	text: string;
	handleTextChange?: (text: string) => void;
	sequenceItemId?: string;
	isTextEditable?: boolean;
	shouldMakeSpace?: boolean;
}

export const HeaderTextSection = (props: IHeaderProps) => {
	const { sequenceItems } = useContext(WorkplanContext)!;
	const dispatch = useDispatch();
	const [text, setText] = useState<string>(props.text);
	const [showInput, setShowInput] = useState<boolean>(false);

	useEffect(() => {
		setText(props.text);
	}, [props.text]);

	const submitTextChange = async (e) => {
		const name: string = e.target.value;
		const nameAlreadyExists: boolean = sequenceItems.some(
			(sequenceItem) => sequenceItem.description === name && sequenceItem._id !== props.sequenceItemId
		);
		if (nameAlreadyExists) {
			errorSnackbar(dispatch, translationService.get('nameAlreadyExists'));
			setText(props.text);
			setShowInput(false);
			return;
		}
		if (text === '') {
			setText(props.text);
			setShowInput(false);
			return;
		}
		await props.handleTextChange?.(name);
		setShowInput(false);
	};

	const handleTextClick = () => {
		if (!props.isTextEditable) return;
		setShowInput(true);
	};

	const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setText(e.target.value);
	};
	return (
		<div className={classes.wrapper}>
			{!showInput ? (
				<div
					onClick={handleTextClick}
					className={classnames(classes.text, {
						[classes.makePlace]: props.shouldMakeSpace,
					})}
				>
					{text}
				</div>
			) : (
				<textarea
					onFocus={(e) => e.target.select()}
					placeholder={translationService.get('enterText')}
					autoFocus
					value={text}
					className={classnames(classes.text, classes.textInput)}
					onChange={handleTextChange}
					onBlur={submitTextChange}
				></textarea>
			)}
		</div>
	);
};
