import React, { useRef, useState } from 'react';
import classnames from 'classnames';
import { Grid } from '@material-ui/core';
import { translationService } from '../../../index';
import { SortingArrow } from '../SortingArrow';
import { ISortingState } from '@interfaces/ISortingState';
import { IArrowShown } from '@interfaces/IArrowShown';
import classes from './styles.module.scss';
import { SORT_ORDER } from '@shared/constants/constants';
import { useOutsideClick } from '@src/hooks/custom.hooks';
import { ArrowUpDown } from '@shared/components/ArrowUpDown/ArrowUpDown';
import TrusstorTooltip from '@shared/components/TrusstorTooltip';
import { TrusstorIcon } from '@src/Components/TrusstorIcon/TrusstorIcon';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';

interface ITableTitleProps {
	disableSort?: boolean;
	columnWidth?: string;
	columnSpan?: string;
	currentColumn: string;
	sortingState: ISortingState;
	updateSortingState: (sortingState: Partial<ISortingState>) => void;
	isTextCentered?: boolean;
	menuContent?: React.ReactNode;
	titleComponent?: React.ReactNode;
	tooltipText?: string;
	boldText?: boolean;
}

const TableTitle = ({
	columnWidth,
	columnSpan,
	currentColumn,
	sortingState,
	updateSortingState,
	disableSort,
	isTextCentered,
	menuContent,
	tooltipText,
	titleComponent,
	boldText,
}: ITableTitleProps) => {
	const [showMenu, setShowMenu] = useState<boolean>(false);
	const [arrowShown, setArrowShown] = useState<IArrowShown>({
		columnName: '',
		isShown: false,
	});
	const menuTitleRef = useRef(null);

	const handleMouseEnter = (columnName: string) => {
		setArrowShown({
			columnName: columnName,
			isShown: true,
		});
	};

	const handleMouseLeave = (columnName: string) => {
		setArrowShown({
			columnName: columnName,
			isShown: false,
		});
	};

	const handleSortClick = (columnName: string) => {
		if (sortingState.columnName === columnName) {
			updateSortingState({
				sortOrder:
					sortingState.sortOrder === SORT_ORDER.ASCENDING ? SORT_ORDER.DESCENDING : SORT_ORDER.ASCENDING,
			});
			return;
		}
		updateSortingState({
			isSorted: true,
			columnName: columnName,
			sortOrder: SORT_ORDER.ASCENDING,
		});
	};

	const handleClick = (e) => {
		e.stopPropagation();
		if (menuContent) {
			setShowMenu((prevState) => !prevState);
			return;
		}
		if (!disableSort) {
			handleSortClick(currentColumn);
		}
	};

	const containerClassName: string = classnames({
		[classes.headerTitleWrapper]: true,
		[classes.center]: isTextCentered,
		[classes.menuTitle]: !!menuContent,
	});

	const headerTextWrapperClassName: string = classnames({
		[classes.headerTextWrapper]: true,
		[classes.bordered]: !!menuContent,
	});
	useOutsideClick(menuTitleRef, setShowMenu, false);
	const headerTextClassName: string = classnames({
		[classes.headerText]: true,
		[classes.boldText]: boldText,
	});

	return (
		<div
			className={containerClassName}
			style={{ gridColumn: `span ${columnSpan}`, minWidth: columnWidth, maxWidth: columnWidth }}
			ref={menuTitleRef}
		>
			<div
				className={headerTextWrapperClassName}
				onMouseEnter={() => handleMouseEnter(currentColumn)}
				onMouseLeave={() => handleMouseLeave(currentColumn)}
			>
				{titleComponent && titleComponent}
				<Grid item>
					<div className={classes.headerContainer} onClick={handleClick}>
						{tooltipText && (
							<TrusstorTooltip placement="top" showAlways cleanStyling text={tooltipText}>
								<div>
									<TrusstorIcon iconName={IconNames.information} />
								</div>
							</TrusstorTooltip>
						)}
						<p className={headerTextClassName}>{translationService.get(currentColumn)}</p>
					</div>
				</Grid>
				{menuContent && <ArrowUpDown isOpen={showMenu} />}
				{!disableSort && (
					<div className={classes.sortArrow}>
						<SortingArrow
							arrowShown={arrowShown}
							sortingState={sortingState}
							currentColumn={currentColumn}
						/>
					</div>
				)}
			</div>
			{showMenu && <div className={classes.menuContent}>{menuContent}</div>}
		</div>
	);
};

export { TableTitle };
