import { compact, countBy, Dictionary, groupBy, sortBy, uniq, uniqBy } from 'lodash';
import { IIssue } from '../interfaces/IIssue';
import { TranslationService } from '../services/translation.service';
import { RequestService } from '../services/request.service';
import { IUser } from '../interfaces/IUser';
import { ICommentShared, IssueCommentType } from '../interfaces/ICommentShared';
import { IIssueLocation, IssuePriority, IssueStatus } from '../interfaces/IIssueShared';
import { IProfession } from '../interfaces/IProfession';
import { IBaseFloor } from '../interfaces/IBaseFloor';
import { ISelectedPoint } from '../interfaces/ISelectedPoint';
import { IIssuesSortingSettings } from '../interfaces/IIssuesSortingSettings';
import { IssuesPageViewModes } from '../constants/issues.contants';
import { IBaseAreaBaseFloor } from '../interfaces/IBaseAreaBaseFloor';

export const sortIssuesByProfession = (issues: IIssue[]): IIssue[] => {
	const countByProfessions = countBy(issues, (issue) => issue.profession?._id);
	return [...issues].sort((a, b) => {
		if (!a.profession) {
			return -1;
		}
		if (!b.profession) {
			return 1;
		}
		if (countByProfessions[a.profession._id] !== countByProfessions[b.profession._id]) {
			return countByProfessions[b.profession._id] - countByProfessions[a.profession._id];
		}
		return a.profession._id.localeCompare(b.profession._id);
	});
};

export const sortIssuesByDueDateAndPriority = (issues: IIssue[]): IIssue[] => {
	return sortBy(issues, [
		(issue) => {
			if (issue.dueDate) {
				return new Date(issue.dueDate).getTime();
			}
			return undefined;
		},
		(issue) => issue.priority,
	]);
};

export const sortMainPageIssues = (issues: IIssue[]): IIssue[] => {
	return sortBy(issues, [
		(issue: IIssue) => issue.dueDate,
		(issue: IIssue) => issue.priority,
		(issue: IIssue) => {
			if (issue.status === IssueStatus.FOR_REVIEW) {
				return 1;
			}
			if (issue.status === IssueStatus.REJECTED) {
				return 2;
			}
			return 3;
		},
	]);
};

export const getSortedKeysOfIssuesColumns = (
	issues: IIssue[],
	viewMode: IssuesPageViewModes,
	sortingSettings?: IIssuesSortingSettings
): string[] => {
	const issuesGroupedByEntity: Dictionary<IIssue[]> = groupBy(issues, (issue) => {
		return viewMode === IssuesPageViewModes.PROFESSIONS ? issue.profession?._id : issue.assignee?.username;
	});

	if (viewMode === IssuesPageViewModes.PROFESSIONS && sortingSettings?.byProfession?.length) {
		const professionIds: string[] = Object.keys(issuesGroupedByEntity);
		return uniq([...sortingSettings.byProfession, ...professionIds]);
	}

	if (viewMode === IssuesPageViewModes.ASSIGNEES && sortingSettings?.byAssignee?.length) {
		const usernames: string[] = Object.keys(issuesGroupedByEntity);
		return uniq([...sortingSettings.byAssignee, ...usernames]);
	}

	return getIssuesDefaultSorted(issuesGroupedByEntity);
};

const getIssuesDefaultSorted = (issuesGroupedByEntity: Dictionary<IIssue[]>) => {
	return Object.keys(issuesGroupedByEntity).sort((colAKey, colBKey) => {
		if (colAKey === 'undefined') {
			return -1;
		}
		if (colBKey === 'undefined') {
			return 1;
		}

		const colALength: number = issuesGroupedByEntity[colAKey].length;
		const colBLength: number = issuesGroupedByEntity[colBKey].length;

		if (colALength !== colBLength) {
			return colBLength - colALength;
		}

		return colAKey.localeCompare(colBKey);
	});
};

export const getIssueDescription = (issue: IIssue, translationService: TranslationService) => {
	return issue.isInitial ? translationService.get('initialIssueTitle') : issue.description;
};

export const addImagesToIssue = async (
	issueId: string,
	user: IUser,
	images: File[],
	requestService: RequestService
): Promise<ICommentShared[]> => {
	if (!images.length) {
		return [];
	}
	let comments: ICommentShared[] = [];
	await Promise.all(
		images.map(async (image) => {
			const formData = new FormData();
			formData.append('value', image);
			formData.append('name', user.name);
			formData.append('username', user.username);
			formData.append('type', image.type.includes('pdf') ? IssueCommentType.PDF : IssueCommentType.IMAGE);
			formData.append('size', image.size.toString());
			formData.append('filename', image.name);

			const issueUpdated: IIssue = await requestService.put(`/issues/${issueId}/comments`, {
				body: formData,
			});
			if (issueUpdated.comments) {
				comments = issueUpdated.comments;
			}
		})
	);
	return comments;
};

export const getIssueStatusMainText = (issueStatus: IssueStatus, translationService: TranslationService): string => {
	if (issueStatus === IssueStatus.REJECTED) {
		return translationService.get('issueRejectedStatus');
	}
	if (issueStatus === IssueStatus.FOR_REVIEW) {
		return translationService.get('issueForReviewStatus');
	}
	if (issueStatus === IssueStatus.PLANNED) {
		return translationService.get('issueOpenStatus');
	}
	if (issueStatus === IssueStatus.COMPLETED) {
		return translationService.get('issueCompletedStatus');
	}
	return '';
};

interface IFilterIssuesParameters {
	issues: IIssue[];
	selectedProfessions: IProfession[];
	selectedUsers: Pick<IUser, 'username'>[];
	selectedFloors?: IBaseFloor[];
	selectedAreas?: IBaseAreaBaseFloor[];
	selectedPriorities: IssuePriority[];
	selectedStatuses: IssueStatus[];
	issuesToInclude?: IIssue[];
}

export const filterIssues = ({
	issues,
	selectedProfessions,
	selectedUsers,
	selectedFloors,
	selectedAreas,
	selectedPriorities,
	selectedStatuses,
	issuesToInclude = [],
}: IFilterIssuesParameters): IIssue[] => {
	if (
		!selectedProfessions.length &&
		!selectedUsers.length &&
		!selectedFloors?.length &&
		!selectedAreas?.length &&
		!selectedPriorities.length &&
		!selectedStatuses.length
	) {
		return issues;
	}
	const issuesFiltered = issues.filter((issue) => {
		if (issuesToInclude.length && issuesToInclude.find((issueToInclude) => issueToInclude._id === issue._id)) {
			return true;
		}
		if (
			selectedProfessions.length &&
			(!issue.profession || !selectedProfessions.find((profession) => profession._id === issue.profession!._id))
		) {
			return false;
		}
		if (
			selectedUsers.length &&
			(!issue.assignee || !selectedUsers.find((user) => user.username === issue.assignee!.username))
		) {
			return false;
		}
		if (
			selectedFloors?.length &&
			(!issue.locations?.length ||
				!issue.locations!.some((location) =>
					selectedFloors.find((floor) => floor.floorId === location.floor.floorId)
				))
		) {
			return false;
		}
		if (
			selectedAreas?.length &&
			(!issue.locations?.length ||
				!issue.locations!.some((location) =>
					selectedAreas.find((area) => area.areaId === location.area.areaId)
				))
		) {
			return false;
		}
		if (
			selectedPriorities.length &&
			!selectedPriorities.find((selectedPriority) => selectedPriority === issue.priority)
		) {
			return false;
		}
		if (selectedStatuses.length && !selectedStatuses.find((selectedStatus) => selectedStatus === issue.status)) {
			return false;
		}
		return true;
	});

	return issuesFiltered;
};

export const areIssuesLocationsEqual = (issuesLocations1: IIssueLocation[], issuesLocations2: IIssueLocation[]) => {
	if (issuesLocations1.length !== issuesLocations2.length) {
		return false;
	}
	return issuesLocations1.every((issueLocation1) => {
		const areaLocation2 = issuesLocations2.find(
			(issueLocation2) => issueLocation2.area.areaId === issueLocation1.area.areaId
		);
		if (!areaLocation2) {
			return false;
		}
		const areaLocation1SelectedPoints = issueLocation1.area.selectedPoints;
		const areaLocation2SelectedPoints = areaLocation2.area.selectedPoints;
		if (areaLocation1SelectedPoints?.length !== areaLocation2SelectedPoints?.length) {
			return false;
		}
		if (!areaLocation2SelectedPoints) {
			return true;
		}
		return areaLocation2SelectedPoints.every((selectedPoint2) => {
			const selectedPoint1 = areaLocation1SelectedPoints?.find(
				(selectedPoint1) =>
					selectedPoint1.xPercentage === selectedPoint2.xPercentage &&
					selectedPoint1.yPercentage === selectedPoint2.yPercentage
			);
			return !!selectedPoint1;
		});
	});
};

export const combineIssuesLocationsSelectedPoints = (
	issuesLocations1: IIssueLocation[],
	issuesLocations2: IIssueLocation[]
): IIssueLocation[] => {
	const issuesLocationsCopy = [...issuesLocations1, ...issuesLocations2];
	const issuesLocationsGroupedByAreaId: { [areaId: string]: IIssueLocation[] } = groupBy(
		issuesLocationsCopy,
		(issueLocation) => issueLocation.area.areaId
	);
	const issuesLocationsCombined: IIssueLocation[] = [];
	Object.keys(issuesLocationsGroupedByAreaId).forEach((areaId) => {
		const issuesLocations: IIssueLocation[] = issuesLocationsGroupedByAreaId[areaId];
		const selectedPoints: ISelectedPoint[] = compact(
			issuesLocations.map((issueLocation) => issueLocation.area.selectedPoints).flat()
		);
		const uniqSelectedPoints: ISelectedPoint[] = uniqBy(
			selectedPoints,
			(selectedPoint) => `${selectedPoint.xPercentage}-${selectedPoint.yPercentage}`
		);
		const issueLocationCombined: IIssueLocation = {
			...issuesLocations[0],
			area: {
				...issuesLocations[0].area,
				selectedPoints: uniqSelectedPoints,
			},
		};
		issuesLocationsCombined.push(issueLocationCombined);
	});
	return issuesLocationsCombined;
};

export const filterIssuesByProfessionAndFloors = (
	issues: IIssue[],
	selectedProfessionsIds: string[],
	selectedFloorsIds: string[]
): IIssue[] => {
	if (!selectedProfessionsIds.length && !selectedFloorsIds.length) {
		return issues;
	}
	return issues.filter((issue) => {
		if (
			issue.profession &&
			selectedProfessionsIds.length &&
			!selectedProfessionsIds.includes(issue.profession._id)
		) {
			return false;
		}
		if (
			issue.locations?.length &&
			selectedFloorsIds.length &&
			!issue.locations!.some((location) =>
				selectedFloorsIds.find((floorId: string) => floorId === location.floor.floorId)
			)
		) {
			return false;
		}
		return true;
	});
};
