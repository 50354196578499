import { AreaSequenceItemStatus } from '@shared/interfaces/AreaSequenceItemStatus.enum';
import { IActivitySequenceItemCellData } from '@src/Components/WorkPlan/ActivityProgressTracker/components/ActivitySequenceMatrix/cellComponents/ActivitySequenceItemCell/ActivitySequenceItemCell';
import { useEffect, useRef } from 'react';
import { debounce } from 'lodash';

const DEBOUNCE_DELAY_MS = 10000;

export const useAreaSequenceItemsStatusUpdate = (
	cellsStatusUpdateCallback: (
		cellsData: IActivitySequenceItemCellData[],
		newStatus: AreaSequenceItemStatus
	) => Promise<void>
) => {
	const nextUpdateTimestampRef = useRef<number | null>(null);
	const batchStatusUpdateRef = useRef<
		Record<string, { newStatus: AreaSequenceItemStatus; cellData: IActivitySequenceItemCellData }>
	>({});

	const scheduleAreaSequenceItemsForStatusUpdate = (
		newStatus: AreaSequenceItemStatus,
		cellData: IActivitySequenceItemCellData[]
	) => {
		cellData.forEach((cellData) => {
			if (!cellData._id) {
				return;
			}
			batchStatusUpdateRef.current[cellData._id] = { newStatus, cellData };
		});
		sendBulkStatusUpdatesDebounced();
	};

	const sendBulkStatusUpdates = () => {
		if (Object.keys(batchStatusUpdateRef.current).length === 0) {
			return;
		}
		const batchStatusUpdatesCopy: Record<
			string,
			{ newStatus: AreaSequenceItemStatus; cellData: IActivitySequenceItemCellData }
		> = { ...batchStatusUpdateRef.current };
		batchStatusUpdateRef.current = {};
		const groupedByStatus: Record<AreaSequenceItemStatus, string[]> = Object.entries(batchStatusUpdatesCopy).reduce(
			(acc, [id, cellUpdateData]) => {
				acc[cellUpdateData.newStatus] = [...(acc[cellUpdateData.newStatus] || []), id];
				return acc;
			},
			{} as Record<AreaSequenceItemStatus, string[]>
		);

		Promise.all(
			Object.entries(groupedByStatus).map(async ([status, ids]) => {
				const updatableCellsData: IActivitySequenceItemCellData[] = ids.map(
					(id) => batchStatusUpdatesCopy[id].cellData
				);
				await cellsStatusUpdateCallback(updatableCellsData, status as AreaSequenceItemStatus);
			})
		);
	};

	const sendBulkStatusUpdatesDebounced = () => {
		if (nextUpdateTimestampRef.current) {
			return;
		}
		nextUpdateTimestampRef.current = Date.now() + DEBOUNCE_DELAY_MS;
		setTimeout(() => {
			sendBulkStatusUpdates();
			nextUpdateTimestampRef.current = null;
		}, DEBOUNCE_DELAY_MS);
	};

	const handleBeforeUnload = (event) => {
		if (Object.keys(batchStatusUpdateRef.current).length === 0) {
			return;
		}
		event.preventDefault();
		event.returnValue = '';
		sendBulkStatusUpdates();
	};

	useEffect(() => {
		window.addEventListener('beforeunload', handleBeforeUnload);
		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
			sendBulkStatusUpdates();
		};
	}, []);

	return { scheduleAreaSequenceItemsForStatusUpdate, sendCurrentBulkStatusUpdates: sendBulkStatusUpdates };
};
