import { InvestigationReportTypes } from '@shared/interfaces/InvestigationReportTypes.enum';
import { ICreateWorkersTimesheetReport } from '@shared/interfaces/ICreateWorkersTimesheetReport';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ITag, IUser } from '../../../../../interfaces';
import { IRootState } from '../../../../../store/slices';
import { ReportCard } from '../ReportCard';
import { requestService, translationService } from '../../../../../index';
import classes from './styles.module.scss';
import { selectProjectId, selectTimezone } from '../../../../../store/slices/project.slice';
import { getTimezoneFormattedDate } from '@shared/utils/dateUtils';
import { useHistory } from 'react-router-dom';
import { getWorkersTimesheetRoute } from '@utils/analysisCenter.utils';
import { analysisCenterReportGeneration_BI } from '@utils/bi.utils';
import { selectLoggedUserDetails } from '@store/slices/login.slice';
import { PeriodSelectorTrusstorInput } from '@src/Components/DateSelector/PeriodSelectorTrusstorInput/PeriodSelectorTrusstorInput';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { getTimesheetReportRelaseDate } from '@src/Components/Pages/AnalysisCenterPages/timesheets.utils';
import { MultiTagsGroupedByTradeDropdown } from '@src/Components/Dropdowns/Tags/MultiTagsGroupedByTradeDropdown/MultiTagsGroupedByTradeDropdown';

interface ITimesheetReportCardProps {
	isSelected: boolean;
	setSelectedCard: (name: string) => void;
}

export const TimesheetReportCard = (props: ITimesheetReportCardProps) => {
	const staticTags: ITag[] = useSelector((state: IRootState) => state.tagsReducer.tags);
	const [selectedTags, setSelectedTags] = useState<ITag[] | undefined>([]);
	const [datePeriod, setDatePeriod] = useState<[Date, Date] | undefined>(undefined);
	const projectId: string = useSelector(selectProjectId)!;
	const tz: string = useSelector(selectTimezone)!;
	const history = useHistory();
	const loggedUserDetails: IUser = useSelector(selectLoggedUserDetails)!;

	const getWorkerTimesheet = (): ICreateWorkersTimesheetReport | undefined => {
		if (!selectedTags?.length || !datePeriod) return;
		const toDate: Date = datePeriod[1] ?? datePeriod[0]; //if only one date is selected
		return {
			userId: loggedUserDetails._id!,
			title: translationService.get('workerTimesheet'),
			description: translationService.get('describeTheIncident'),
			projectId,
			fromDate: getTimezoneFormattedDate(tz, datePeriod[0]),
			toDate: getTimezoneFormattedDate(tz, toDate),
			investigationType: InvestigationReportTypes.workersTimesheet,
			tagIds: selectedTags.map((tag) => tag.tagId),
		};
	};

	const generateTimesheetReport = async () => {
		const workersTimesheetReport: ICreateWorkersTimesheetReport | undefined = getWorkerTimesheet();
		if (!workersTimesheetReport) return;
		const reportId = await requestService.post(`/archive/investigationReports/`, {
			body: {
				investigationReport: workersTimesheetReport,
			},
		});
		analysisCenterReportGeneration_BI(InvestigationReportTypes.workersTimesheet, 'new', [
			new Date(workersTimesheetReport.fromDate),
			new Date(workersTimesheetReport.toDate),
		]);
		history.push(getWorkersTimesheetRoute(reportId));
	};

	const isGenerateDisabled = () => {
		return selectedTags?.length && datePeriod ? false : true;
	};

	const handleTagChange = (dropDownSelectedTags: ITag[]) => {
		setSelectedTags(dropDownSelectedTags);
	};

	const updatePeriod = (period: [Date, Date]) => {
		setDatePeriod(period);
	};

	return (
		<ReportCard
			name={InvestigationReportTypes.workersTimesheet}
			description={translationService.get('reviewYourWorkersTimesheet')}
			icon={IconNames.clock}
			title={translationService.get('workerTimesheet')}
			isSelected={props.isSelected}
			generateReport={generateTimesheetReport}
			isGenerateReportDisabled={isGenerateDisabled()}
			setSelectedCard={props.setSelectedCard}
		>
			<div className={classes.searchTagContainer}>
				<MultiTagsGroupedByTradeDropdown staticTags={staticTags} onChange={handleTagChange} />
			</div>
			<PeriodSelectorTrusstorInput
				minDate={getTimesheetReportRelaseDate(tz)}
				updatePeriod={updatePeriod}
				datePeriod={datePeriod}
			/>
		</ReportCard>
	);
};
