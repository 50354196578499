import React from 'react';
import { IMilestone } from '@shared/interfaces/IMilestone';
import { IMilestoneProgressSummary } from '@interfaces/IMilestoneProgressSummary';
import { Carousel } from '@src/Components/Carousel/Carousel';
import { selectTimezone } from '@store/slices/project.slice';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { SequenceProgressAnalysisContext } from '../../SequenceProgressAnalysis';
import { MilestoneSelectorCard } from './MilestoneSelectorCard/MilestoneSelectorCard';
import { ITodayMarkerPosition, getNextMilestoneIndex, getTodayMarkerPosition } from './milestoneSelectorCarousel.utils';

interface IMilestoneSelectorCarouselProps {
	projectMilestones: IMilestone[];
	milestonesProgressSummary: IMilestoneProgressSummary[];
}

export const MilestoneSelectorCarousel = (props: IMilestoneSelectorCarouselProps) => {
	const tz: string = useSelector(selectTimezone)!;

	const [selectedMilestoneIndex, setSelectedMilestoneIndex] = useState<number>(
		getNextMilestoneIndex(props.projectMilestones, tz)
	);
	const { setSelectedMilestoneId } = useContext(SequenceProgressAnalysisContext)!;

	const todayMarkerPosition: { index: number; position: ITodayMarkerPosition } | undefined = useMemo(
		() => getTodayMarkerPosition(props.projectMilestones, tz),
		[props.projectMilestones.length, tz]
	);

	useEffect(() => {
		const selectedSummary: IMilestoneProgressSummary | undefined =
			props.milestonesProgressSummary[selectedMilestoneIndex];
		if (selectedSummary) {
			setSelectedMilestoneId(selectedSummary.milestoneId);
		}
	}, [selectedMilestoneIndex, props.milestonesProgressSummary.length]);

	const renderItem = (index: number) => {
		const milestone: IMilestone = props.projectMilestones[index];
		const milestoneProgressSummary = props.milestonesProgressSummary.find(
			(summary) => summary.milestoneId === milestone._id
		);
		if (!milestoneProgressSummary) {
			return <></>;
		}
		const isSelected: boolean = selectedMilestoneIndex === index;

		return (
			<MilestoneSelectorCard
				key={milestone._id}
				isSelected={isSelected}
				milestone={milestone}
				milestoneProgressSummary={milestoneProgressSummary}
				onClick={() => setSelectedMilestoneIndex(index)}
				index={index}
				totalNumberOfMilestones={props.projectMilestones.length}
				todayMarkerPosition={index === todayMarkerPosition?.index ? todayMarkerPosition.position : undefined}
				darkTimeline={todayMarkerPosition ? index < todayMarkerPosition?.index : false}
			/>
		);
	};

	return (
		<Carousel
			numItems={props.projectMilestones.length}
			renderItem={renderItem}
			maxItemsToShow={8}
			selectedIndex={selectedMilestoneIndex}
		/>
	);
};
