import { omit, cloneDeep, isEmpty, groupBy, countBy } from 'lodash';
import { reportsPageConsts } from '../constants';

export const convertSafetyReportToSafetyEventsArray = (safetyReport) => {
	if (!safetyReport) {
		return [];
	}
	const safetyArray = Object.values(safetyReport)
		.map((professionValue) => {
			return Object.values(professionValue.floors)
				.map((floor) => {
					return floor.events.map((event) => {
						return {
							...event,
							...omit(floor, 'events'),
							...omit(professionValue, 'floors'),
						};
					});
				})
				.flat();
		})
		.flat();
	return safetyArray;
};

export const convertEventsToCovid = (safetyEventsArray) => {
	const covidEventsArray = safetyEventsArray.filter((event) => event.type === reportsPageConsts.covidViolation);
	const eventsGroupedBySharedId = groupBy(covidEventsArray, 'sharedEventId');
	return Object.values(eventsGroupedBySharedId).map((sharedEvents) => {
		const professionsCount = countBy(sharedEvents, (event) => event.profession);
		const tradesForSharedEvent = Object.keys(professionsCount).map((professionKey) => {
			const eventWithCurrentProfession = sharedEvents.find((event) => event.profession === professionKey);
			return {
				profession: eventWithCurrentProfession.profession,
				professionNick: eventWithCurrentProfession.professionNick,
				backgroundColor: eventWithCurrentProfession.backgroundColor,
				value: professionsCount[professionKey],
			};
		});
		return { ...sharedEvents[0], trades: tradesForSharedEvent };
	});
};

export const getVisibleReportFromVisibleProfessions = (report, visibleProfessions) => {
	return visibleProfessions.reduce((finalVisibleReport, trade) => {
		return report[trade.profession]
			? {
					[`${trade.profession}`]: report[trade.profession],
					...finalVisibleReport,
			  }
			: finalVisibleReport;
	}, {});
};

export const getVisibleReportFromVisibleFloors = (report, visibleFloors) => {
	let finalReport = cloneDeep(report);
	for (const reportKey in report) {
		const currentReportTrade = finalReport[reportKey];
		currentReportTrade.floors = visibleFloors.reduce((finalVisibleReport, floor) => {
			return currentReportTrade.floors[floor.floorId]
				? {
						[`${floor.floorId}`]: currentReportTrade.floors[floor.floorId],
						...finalVisibleReport,
				  }
				: finalVisibleReport;
		}, {});
		if (isEmpty(currentReportTrade.floors)) {
			finalReport = omit(finalReport, `${reportKey}`);
		}
	}
	return finalReport;
};
