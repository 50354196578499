import { InvestigationReportTypes } from '@shared/interfaces/InvestigationReportTypes.enum';

export const BiSafetyEventsActionSource = {
	buildingMainView: 'Building main view',
	safetyViolationLog: 'Safety violations log',
	autoOpening: 'Auto opening',
};

export const BiAnalysisCenterReportTypes = {
	[InvestigationReportTypes.incidentReport]: 'Incident Report',
	[InvestigationReportTypes.dailyRoute]: 'Daily Route',
	[InvestigationReportTypes.workersTimesheet]: 'Worker Timesheet',
	[InvestigationReportTypes.observeLocation]: 'Observe a floor',
	[InvestigationReportTypes.contractorAnalysis]: 'Contractor analysis',
	[InvestigationReportTypes.projectOverview]: 'Project overview',
};
