import React from 'react';
import classnames from 'classnames';
import classes from './styles.module.scss';

export enum ITrusstorIconButtonSize {
	SMALL = 'small',
	DEFAULT = 'default',
	LARGE = 'large',
}

export enum ITrusstorIconButtonStyle {
	OUTLINE = 'outline',
	GHOST = 'ghost',
	DEFAULT = 'default',
}

interface ITrusstorIconButtonProps {
	iconElement?: React.ReactNode;
	iconSrc?: string;
	onClick?: (e: React.MouseEvent) => void;
	disabled?: boolean;
	buttonSize?: ITrusstorIconButtonSize;
	iconClassName?: string;
	style?: ITrusstorIconButtonStyle;
	rootClassName?: string;
	testId?: string;
	squaredButton?: boolean;
}

export const TrusstorIconButtonDeprecated = (props: ITrusstorIconButtonProps) => {
	const containerClassName: string = classnames(
		classes.iconContainer,
		props.style ? classes[props.style] : classes.default,
		{
			[classes.small]: props.buttonSize === ITrusstorIconButtonSize.SMALL,
			[classes.large]: props.buttonSize === ITrusstorIconButtonSize.LARGE,
			[classes.disabled]: props.disabled,
			[props.rootClassName || '']: !!props.rootClassName,
			[classes.squaredButton]: props.squaredButton,
		}
	);

	const iconClassname: string = classnames(classes.iconElement, {
		[props.iconClassName || '']: !!props.iconClassName,
	});

	const IconElement = props.iconElement ? (
		<div className={iconClassname}>{props.iconElement}</div>
	) : (
		<img src={props.iconSrc} alt="icon alt" className={iconClassname} />
	);

	return (
		<div onClick={props.onClick} className={containerClassName} data-testid={props.testId}>
			{IconElement}
		</div>
	);
};
