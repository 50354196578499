import { ActivityGroupStatus } from '@shared/interfaces/ActivityGroupStatus.enum';
import { COLORS } from '../constants';
import { getDaysDiffBetweenDateAndToday } from './dateUtils';
import { translationService } from '../index';

export const getWidgetGroupColor = (groupStatus: ActivityGroupStatus): string => {
	if (groupStatus === ActivityGroupStatus.inProgress) return COLORS.yellow100;
	if (groupStatus === ActivityGroupStatus.complete) return COLORS.green100;
	return COLORS.red100;
};

export const getTooltipForDelayedActivity = (activityStartDate: Date, tz: string) => {
	const daysDelayed: number = getDaysDiffBetweenDateAndToday(activityStartDate, tz);

	return daysDelayed > 0
		? `${translationService.get('delayedBy')}${daysDelayed} ${translationService.get('days')}`
		: `${translationService.get('shouldHaveStartedToday')}`;
};
