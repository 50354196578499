import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import classes from './styles.module.scss';
import {
	changeOpenNotificationsTab,
	selectNotifications,
	selectNotificationsIsOpen,
} from '@store/slices/notifications.slice';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { closeIcon } from '@src/assets';
import { accessLevelService, requestService, storageService, translationService } from '@src/index';
import { NotificationsEmptyState } from './NotificationsEmptyState/NotificationsEmptyState';
import { INotification } from '@shared/interfaces/INotification';
import { selectProjectId, selectTimezone } from '@store/slices/project.slice';
import { NotificationCard } from '@shared/components/NotificationCard/NotificationCard';
import { isDateToday, isDateYesterday } from '@shared/utils/dateUtils';
import { Loader } from '@shared/components/Loader/Loader';
import { IUser } from '@shared/interfaces/IUser';
import { selectLoggedUserDetails } from '@store/slices/login.slice';
import { markAllNotificationsAsViewed } from '@store/thunks/notifications.thunks';
import { useOutsideClickWithCallback } from '@src/hooks/custom.hooks';

export const NotificationsSection = () => {
	const tz: string = useSelector(selectTimezone)!;
	const projectId: string = useSelector(selectProjectId)!;
	const isOpen: boolean = useSelector(selectNotificationsIsOpen);
	const notifications: INotification[] | undefined = useSelector(selectNotifications);
	const [removedNotificationIds, setRemovedNotificationIds] = useState<string[]>([]);
	const [displayedNotifications, setDisplayedNotifications] = useState<INotification[]>([]);
	const loggedUserDetails: IUser | undefined = useSelector(selectLoggedUserDetails);
	const dispatch = useDispatch();
	const ref = React.useRef(null);

	const handleCloseNotifications = useCallback(async () => {
		if (!isOpen) return;
		dispatch(changeOpenNotificationsTab());
		dispatch(markAllNotificationsAsViewed(projectId, loggedUserDetails!.username));
	}, [isOpen, projectId, loggedUserDetails?.username]);

	const notificationButtonElement: HTMLElement | null = document.getElementById('sideBarNotificationButton');
	useOutsideClickWithCallback(ref, handleCloseNotifications, undefined, [{ current: notificationButtonElement }]);

	useEffect(() => {
		if (!notifications) {
			return;
		}
		if (notifications.length !== displayedNotifications.length) {
			const removedIds = displayedNotifications
				.filter(
					(prevNotification) =>
						!notifications.find((notification) => notification._id === prevNotification._id)
				)
				.map((removedNotification) => removedNotification._id);

			setRemovedNotificationIds(removedIds);
			setTimeout(() => {
				setDisplayedNotifications(notifications);
			}, 300);
		} else {
			setDisplayedNotifications(notifications);
		}
	}, [notifications]);

	if (!notifications) {
		return <Loader />;
	}

	const todayNotifications: INotification[] = displayedNotifications.filter((notification) =>
		isDateToday(notification.createdAt, tz)
	);
	const yesterdayNotifications: INotification[] = displayedNotifications.filter((notification) =>
		isDateYesterday(notification.createdAt, tz)
	);
	const earlierNotifications: INotification[] = displayedNotifications.filter((notification) =>
		moment.tz(notification.createdAt, tz).isBefore(moment.tz(tz).subtract(1, 'day'), 'day')
	);

	const handleAnimationEnd = (notificationId) => {
		setRemovedNotificationIds((prevIds) => prevIds.filter((id) => id !== notificationId));
	};

	const getCardSection = (notificationsToDisplay: INotification[], title?: string) => (
		<>
			{notificationsToDisplay.length > 0 && (
				<div className={classes.cardsSection}>
					{title && <div className={classes.title}>{title}</div>}
					{notificationsToDisplay.map((notification) => (
						<>
							<div
								key={notification._id}
								id={`notification-${notification._id}`}
								className={classnames({
									[classes.notification]: true,
									[classes.notificationRemove]: removedNotificationIds.includes(notification._id),
								})}
								onAnimationEnd={() => handleAnimationEnd(notification._id)}
							>
								<NotificationCard
									storageService={storageService}
									handleCloseNotifications={handleCloseNotifications}
									notification={notification}
									translationService={translationService}
									tz={tz}
									requester={requestService}
									accessLevelService={accessLevelService}
									projectId={projectId}
									userDetails={loggedUserDetails!}
								/>
							</div>
							<div className={classes.divider} />
						</>
					))}
				</div>
			)}
		</>
	);
	const showShowEarlierTitle: boolean = todayNotifications.length > 0 || yesterdayNotifications.length > 0;
	return (
		<div ref={ref} className={classnames({ [classes.container]: true, [classes.notDisplay]: !isOpen })}>
			<div className={classnames({ [classes.header]: true, [classes.notDisplay]: !isOpen })}>
				<div className={classes.title}>{translationService.get('sideBar_notifications')}</div>
				<img src={closeIcon} className={classes.close} alt={''} onClick={handleCloseNotifications} />
			</div>
			<div className={classes.content}>
				{!notifications.length ? (
					<NotificationsEmptyState />
				) : (
					<div className={classes.cardsContainer}>
						{getCardSection(todayNotifications, translationService.get('today'))}
						{getCardSection(yesterdayNotifications, translationService.get('yesterday'))}
						{getCardSection(
							earlierNotifications,
							showShowEarlierTitle ? translationService.get('earlier') : undefined
						)}
					</div>
				)}
			</div>
		</div>
	);
};
