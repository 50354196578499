import React from 'react';
import classes from './styles.module.scss';
import { checkIcon } from '@src/assets';

interface ISimpleToggleProps {
	onChange: (option: any) => void;
	checked: boolean;
	clearStyle?: boolean;
}

export const SimpleToggle = (props: ISimpleToggleProps) => {
	return (
		<div>
			<label className={classes.switch}>
				<input
					className={classes.checkbox}
					type={'checkbox'}
					checked={props.checked}
					onChange={props.onChange}
				/>
				<span className={classes.sliderRound}>
					{props.checked && !props.clearStyle && <img className={classes.checkIcon} src={checkIcon} />}
				</span>
			</label>
		</div>
	);
};
