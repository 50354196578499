import React from 'react';
import classes from './styles.module.scss';
import { cM } from '@shared/utils/colors.utils';
import { IScoreBar } from '@interfaces/IScoreBar';
import { translationService } from '../../../../../index';
import { ScoreBar } from '../../../../Bars/ScoreBar/ScoreBar';

interface IEfficiencyOverallProps {
	overallScore: number;
}

const EfficiencyOverall = (props: IEfficiencyOverallProps) => {
	const overallBars: IScoreBar[] = [
		{
			color: '#E7E9EB',
			startText: translationService.get('low'),
			isChosenBar: props.overallScore < 1,
			tooltipTextColor: 'black',
		},
		{
			color: '#68B6FD',
			isChosenBar: props.overallScore >= 1 && props.overallScore < 2,
			tooltipText: translationService.get('med'),
			tooltipTextColor: 'white',
		},
		{
			color: '#1F8AEC',
			endText: translationService.get('high'),
			isChosenBar: props.overallScore >= 2,
			tooltipTextColor: 'white',
		},
	];
	return (
		<section className={classes.overallContainer}>
			<div className={cM(classes.overallText, classes)}>{translationService.get('overallEfficiencyScore')}</div>
			<ScoreBar bars={overallBars} />
		</section>
	);
};

export { EfficiencyOverall };
