import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { createMuiTheme, ThemeOptions, ThemeProvider } from '@material-ui/core/styles';
import { WorkPlan } from './Pages/Workplan/WorkPlan';
import ProtectedRoute from './ProtectedRoute/ProtectedRoute';
import { LoginPage } from './Pages/AuthPages/LoginPage/LoginPage';
import { TagsInventoryPage } from './Pages/SystemManagementPages/TagsInventoryPage/TagsInventoryPage';
import { ManagersRolesListPage } from './Pages/SystemManagementPages/ManagersRolesListPage';
import { IntercomProvider } from 'react-use-intercom';
import { accessLevelService, projectAccessLevelService, translationService } from '../servicesInitializers';
import { config } from '../config';
import moment from 'moment';
import 'moment/locale/he';
import 'moment/locale/ja';
import { CLICK_CHECK_TIMEOUT_MS, MAIN_PAGE_ROUTES, MAIN_PATH, REFRESH_TIMEOUT_MS } from '../constants';
import { NotFoundPage } from './Pages/NotFoundPage';
import { CombinedReportPage } from './Pages/Reports/CombinedReportPage';
import { ProfessionAnalysisReportPage } from './Pages/Reports/ProfessionAnalysisReportPage';
import { ProfessionsListPage } from './Pages/SystemManagementPages/ProfessionListPage/ProfessionsListPage';
import { UsersListPage } from './Pages/SystemManagementPages/UsersListPage/UsersListPage';
import { MainPage } from './Pages/MainPage';
import { useTabVisibility } from '@shared/hooks/useTabVisibility.hook';
import { useUserMouseInteraction_BI } from '../hooks/bi.hooks';
import { ResetPasswordPage } from './Pages/AuthPages/ResetPasswordPage/ResetPasswordPage';
import { SetPasswordPage } from './Pages/AuthPages/SetPasswordPage/SetPasswordPage';
import { AnalysisCenterPage } from './Pages/AnalysisCenterPages/AnalysisCenterPage/AnalysisCenterPage';
import { ExecutiveViewPage } from './Pages/ExecutiveViewPage/ExecutiveViewPage';
import { Environments } from '@shared/constants/environments';
import { InvestigationReportTypes } from '@shared/interfaces/InvestigationReportTypes.enum';
import { InvestigationReportPage } from './Pages/AnalysisCenterPages/InvestigationReportPage/InvestigationReportPage';
import { TimesheetReportPage } from './Pages/AnalysisCenterPages/TimesheetAnalysisPages/TimesheetReportPage';
import { ObserveLocationReportPageProps } from './Pages/AnalysisCenterPages/ObserveLocationReportPage/ObserveLocationReportPage';
import { getIsExecutiveUser, redirectToMobile } from '@utils/users.utils';
import { getNotificationsFromStream, stopGettingNotifications } from '@store/thunks/notifications.thunks';
import { PreferencesPage } from '@src/Components/Pages/PreferencesPage/PreferencesPage';
import { NotificationSettingsPage } from '@src/Components/Pages/NotificationSettingsPage/NotificationSettingsPage';
import { IssuesPage } from '@src/Components/Pages/IssuesPage/IssuesPage';
import { Loader } from '@shared/components/Loader/Loader';
import { tabBecameVisible_BI } from '@utils/bi.utils';
import { initPostHogByUserData } from '@shared/utils/posthog.utils';
import { SequenceProgressAnalysis } from '@src/Components/SequenceProgressAnalysis/SequenceProgressAnalysis';
import { errorSnackbar } from '@utils/snackbar.utils';
import { ILoggedUser, IProject } from '@interfaces/index';
import { Languages } from '@shared/constants/languages';
import { IUserPreferences } from '@shared/interfaces/IUserPreferences';
import * as Sentry from '@sentry/react';
import { MilestonesPage } from '@src/Components/Pages/SystemManagementPages/MilestonesPage/MilestonesPage';
import { useIsFeatureFlagEnabled } from '@shared/hooks/useFeatureFlag';
import { FeatureFlagNamesEnum } from '@shared/constants/featureFlags.enum';
import { initFullStoryByUserData } from '@shared/utils/fullstory.utils';
import { MilestonesInnerPage } from '@src/Components/Pages/SystemManagementPages/MilestonesPage/MilestonesInnerPage/MilestonesInnerPage';
import { ErrorPage } from './Pages/ErrorPage/ErrorPage';
import { customHistory } from '@shared/utils/history.utils';
import { MonitoringRoute } from '@shared/utils/monitoring.util';
import { ProfessionAnalysisReportPageV2 } from '@src/Components/Pages/Reports/ProfessionAnalysisReportPageV2/ProfessionAnalysisReportPageV2';

const getThemeStyles = () =>
	({
		direction: document.body.dir,
		typography: {
			button: {
				textTransform: 'none',
			},
		},
	}) as ThemeOptions;

const App = ({ loggedUser, workingProject }: { loggedUser: ILoggedUser; workingProject: IProject }) => {
	const [areTranslationsFetched, setAreTranslationsFetched] = useState<boolean>(false);
	const loggedUsername = loggedUser?.userDetails?.username;
	const workingProjectId = workingProject?.projectId;
	const loggedUserPreferences: IUserPreferences | undefined = loggedUser?.userPreferences;
	// only for app render
	const [language, setLanguage] = useState<Languages | undefined>();
	const tz = workingProject?.tz;
	const dispatch = useDispatch();

	useTabVisibility(tabBecameVisible_BI);

	useUserMouseInteraction_BI(workingProjectId, tz);

	document.body.dir = translationService.getDirection();
	const [theme, setTheme] = useState<ThemeOptions>(createMuiTheme(getThemeStyles()));

	useEffect(() => {
		const initTranslation = async () => {
			const { error } = await translationService.init();
			setAreTranslationsFetched(true);
			if (error) {
				errorSnackbar(dispatch, 'Error fetching translations, please try again later');
			}
		};
		if (loggedUsername) {
			setAreTranslationsFetched(false);
			initTranslation();
			return;
		}
		setAreTranslationsFetched(true);
	}, [loggedUsername]);

	const updateUserLayout = () => {
		const language: string = loggedUser.userPreferences?.language || workingProject?.language;
		translationService.changeChosenLanguage(language);
		moment.locale(translationService.getDateLocale());
		document.body.dir = translationService.getDirection();
		setTheme(createMuiTheme(getThemeStyles()));
		setLanguage(language as Languages); //only for app render
	};

	useEffect(() => {
		if (loggedUser && workingProjectId) {
			dispatch(getNotificationsFromStream(workingProjectId, loggedUsername));
			updateUserLayout();
			initFullStoryByUserData(loggedUser.userDetails, workingProjectId);
			initPostHogByUserData(
				loggedUser.userDetails,
				workingProjectId,
				process.env.REACT_APP_STAGE as Environments
			);
		}
		return () => {
			dispatch(stopGettingNotifications());
		};
	}, [loggedUsername, workingProjectId, setTheme]);

	useEffect(() => {
		if (loggedUserPreferences) {
			updateUserLayout();
		}
	}, [loggedUserPreferences]);

	const RedirectToMain = ({ location }) => {
		return (
			<Redirect to={{ pathname: location?.state?.from?.pathname ? location.state.from.pathname : MAIN_PATH }} />
		);
	};

	useEffect(() => {
		const isUserSleeping = () => {
			return new Promise((resolve) => {
				const clickTimer = setTimeout(() => {
					resolve(true);
					document.removeEventListener('mousedown', handleUserClick);
				}, CLICK_CHECK_TIMEOUT_MS);

				const handleUserClick = () => {
					resolve(false);
					clearTimeout(clickTimer);
					document.removeEventListener('mousedown', handleUserClick);
				};

				document.addEventListener('mousedown', handleUserClick);
			});
		};

		let refreshTimer;
		const refreshTimeoutCallback = () => {
			refreshTimer = setTimeout(async () => {
				const isSleeping = await isUserSleeping();

				if (isSleeping) {
					window.location.reload();
				} else {
					resetTimer();
				}
			}, REFRESH_TIMEOUT_MS);
		};

		function resetTimer() {
			clearTimeout(refreshTimer);
			refreshTimeoutCallback();
		}

		refreshTimeoutCallback();
		return () => {
			document.removeEventListener('mousedown', resetTimer);
			clearTimeout(refreshTimer);
		};
	}, []);

	const getAnalysisCenterReportPath = (subPath: InvestigationReportTypes): string => {
		return `/:projectId?/analysisCenter/${subPath}/:reportId`;
	};

	const isExecutiveUser: boolean = getIsExecutiveUser(loggedUser);

	redirectToMobile(loggedUser, config.shouldRedirectToMobile);

	const userHasAccessToSystemManagement: boolean = accessLevelService.hasAccess('systemPagesAccess');
	const userHasAccessToMilestonesPage: boolean = useIsFeatureFlagEnabled(
		FeatureFlagNamesEnum.milestonesManagementPage
	);
	const isSequenceProgressAnalysisEnabled: boolean = useIsFeatureFlagEnabled(
		FeatureFlagNamesEnum.sequenceProgressAnalysis
	);
	const isProfessionAnalysisV2Enabled: boolean = useIsFeatureFlagEnabled(FeatureFlagNamesEnum.contractorAnalysisV2);
	const userHasAccessToProjectAnalysis: boolean =
		accessLevelService.hasAccess('projectProgressAnalysisAccess') && isSequenceProgressAnalysisEnabled;

	const userHasAccessToAnalysisCenter: boolean =
		accessLevelService.hasAccess('investigationReportAccess') &&
		projectAccessLevelService.hasAccess('analysisCenterPages');
	const userAccessToExecutiveView: boolean = accessLevelService.hasAccess('executiveViewAccess');
	const isProduction: boolean = process.env.REACT_APP_STAGE === Environments.prod;

	if (!areTranslationsFetched) {
		return (
			<div className={'App'} style={{ height: '100vh', display: 'flex' }}>
				<Loader />
			</div>
		);
	}

	return (
		<IntercomProvider appId={config.intercomAppId} shouldInitialize={isProduction}>
			<ThemeProvider theme={theme}>
				<div className="App">
					<Sentry.ErrorBoundary fallback={<ErrorPage />}>
						<Router history={customHistory}>
							<Switch>
								<MonitoringRoute
									exact
									path="/login/:lang"
									component={
										loggedUser && !config.shouldRedirectToMobile ? RedirectToMain : LoginPage
									}
								/>
								<MonitoringRoute
									exact
									path="/login"
									render={(props) => (
										<Redirect
											to={{
												pathname: `/login/${config.defaultLanguage}`,
												state: { from: props.location },
											}}
										/>
									)}
								/>
								<MonitoringRoute
									exact
									path="/resetPassword"
									render={() => (
										<Redirect
											to={{
												pathname: `/resetPassword/${config.defaultLanguage}`,
											}}
										/>
									)}
								/>
								<MonitoringRoute
									exact
									path="/resetPassword/:lang"
									component={
										loggedUser && !config.shouldRedirectToMobile
											? RedirectToMain
											: ResetPasswordPage
									}
								/>
								<MonitoringRoute
									exact
									path="/setPassword"
									component={
										loggedUser && !config.shouldRedirectToMobile ? RedirectToMain : SetPasswordPage
									}
								/>
								<MonitoringRoute
									exact
									path={'/'}
									component={isExecutiveUser ? ExecutiveViewPage : RedirectToMain}
								/>
								<ProtectedRoute
									exact
									path={MAIN_PAGE_ROUTES}
									component={MainPage}
									hideProjectSelector
								/>
								<ProtectedRoute
									exact
									path="/:projectId?/workplan"
									component={WorkPlan}
									isAllowed={projectAccessLevelService.hasAccess('workplanPage')}
								/>
								<ProtectedRoute
									exact
									path="/:projectId?/workplan/fullPage"
									component={WorkPlan}
									emptyLayout
									isAllowed={projectAccessLevelService.hasAccess('workplanPage')}
								/>
								<ProtectedRoute
									exact
									isAllowed={userHasAccessToProjectAnalysis}
									path={`/:projectId?/workplan/projectProgressAnalysis`}
									component={SequenceProgressAnalysis}
								/>
								<ProtectedRoute
									exact
									path="/:projectId?/workplan/:viewType"
									component={WorkPlan}
									isAllowed={projectAccessLevelService.hasAccess('workplanPage')}
								/>
								<ProtectedRoute
									exact
									path={`/:projectId?/workplan/dateFrom/:dateFrom`}
									component={WorkPlan}
									isAllowed={projectAccessLevelService.hasAccess('workplanPage')}
								/>
								<ProtectedRoute
									exact
									path={`/:projectId?/analysisCenter/projectProgressAnalysis`}
									component={SequenceProgressAnalysis}
								/>
								<ProtectedRoute
									exact
									path={`/:projectId?/analysis`}
									component={SequenceProgressAnalysis}
								/>
								<ProtectedRoute
									exact
									path="/:projectId?/system/admin_panel"
									isAllowed={
										userHasAccessToSystemManagement &&
										projectAccessLevelService.hasAccess('tagsPage')
									}
									component={TagsInventoryPage}
								/>
								<ProtectedRoute
									isAllowed={
										userHasAccessToSystemManagement &&
										projectAccessLevelService.hasAccess('tagsPage')
									}
									exact
									path="/:projectId?/system/tags/:sortBy?"
									component={TagsInventoryPage}
								/>
								<ProtectedRoute
									isAllowed={
										userHasAccessToSystemManagement &&
										projectAccessLevelService.hasAccess('managersListPage')
									}
									exact
									path="/:projectId?/system/management"
									component={ManagersRolesListPage}
								/>
								<ProtectedRoute
									isAllowed={userHasAccessToSystemManagement}
									exact
									path="/:projectId?/system/professions"
									component={ProfessionsListPage}
								/>
								<ProtectedRoute
									isAllowed={userHasAccessToSystemManagement}
									exact
									path="/:projectId?/system/users"
									component={UsersListPage}
								/>
								<ProtectedRoute
									isAllowed={userHasAccessToSystemManagement && userHasAccessToMilestonesPage}
									exact
									path="/:projectId?/system/milestones/:create(create)?"
									component={MilestonesPage}
								/>
								<ProtectedRoute
									isAllowed={userHasAccessToSystemManagement && userHasAccessToMilestonesPage}
									exact
									path="/:projectId?/system/milestones/:id"
									component={MilestonesInnerPage}
								/>
								<ProtectedRoute
									isAllowed={userHasAccessToSystemManagement}
									exact
									path="/:projectId?/system/notifications"
									component={NotificationSettingsPage}
								/>
								<ProtectedRoute
									isAllowed={userHasAccessToAnalysisCenter}
									exact
									path="/:projectId?/analysisCenter"
									component={AnalysisCenterPage}
								/>
								<ProtectedRoute
									isAllowed={userHasAccessToAnalysisCenter}
									exact
									path={getAnalysisCenterReportPath(InvestigationReportTypes.incidentReport)}
									component={InvestigationReportPage}
								/>
								<ProtectedRoute
									isAllowed={userHasAccessToAnalysisCenter}
									exact
									path={getAnalysisCenterReportPath(InvestigationReportTypes.dailyRoute)}
									component={InvestigationReportPage}
								/>
								<ProtectedRoute
									isAllowed={userHasAccessToAnalysisCenter}
									exact
									path={getAnalysisCenterReportPath(InvestigationReportTypes.workersTimesheet)}
									component={TimesheetReportPage}
								/>
								<ProtectedRoute
									isAllowed={userHasAccessToAnalysisCenter}
									exact
									path={getAnalysisCenterReportPath(InvestigationReportTypes.observeLocation)}
									component={ObserveLocationReportPageProps}
								/>
								<ProtectedRoute
									isAllowed={userHasAccessToAnalysisCenter}
									exact
									path={getAnalysisCenterReportPath(InvestigationReportTypes.projectOverview)}
									component={CombinedReportPage}
								/>
								<ProtectedRoute
									hideTopBar={isProfessionAnalysisV2Enabled}
									isAllowed={userHasAccessToAnalysisCenter}
									exact
									path={getAnalysisCenterReportPath(InvestigationReportTypes.contractorAnalysis)}
									component={
										isProfessionAnalysisV2Enabled
											? ProfessionAnalysisReportPageV2
											: ProfessionAnalysisReportPage
									}
								/>
								<ProtectedRoute
									isAllowed={userAccessToExecutiveView}
									exact
									path="/:projectId?/projects"
									component={ExecutiveViewPage}
									emptyLayout
									ignoreUrlProjectId
								/>
								<ProtectedRoute exact path="/:projectId?/preferences" component={PreferencesPage} />
								<ProtectedRoute
									path="/:projectId?/issues/:issueId?/:edit(edit)?"
									component={IssuesPage}
									isAllowed={projectAccessLevelService.hasAccess('issuesPage')}
								/>
								<Route path="*" render={() => <NotFoundPage />} />
							</Switch>
						</Router>
					</Sentry.ErrorBoundary>
				</div>
			</ThemeProvider>
		</IntercomProvider>
	);
};

const mapStateToProps = ({ loginReducer, projectReducer }) => {
	return {
		loggedUser: loginReducer.loggedUser,
		workingProject: projectReducer.workingProject,
	};
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(App);
