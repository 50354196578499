import { SequenceTypeEnum } from '../../interfaces/SequenceType.enum';
import { useQuery } from '@tanstack/react-query';
import { useSharedServices } from '../sharedServices.context';

export const useSequenceTypesQuery = (projectId: string | undefined) => {
	const { requestService } = useSharedServices();
	const { data: sequenceTypes } = useQuery<SequenceTypeEnum[]>(
		['sequenceTypesFromAreas', projectId],
		() => requestService.get(`/projectConfig/area/projectAreaTypes`, { params: { projectId } }),
		{
			useErrorBoundary: true,
			initialData: [],
			enabled: !!projectId,
		}
	);

	const filteredSequenceTypes: SequenceTypeEnum[] =
		(sequenceTypes && sequenceTypes.filter((type) => Object.values(SequenceTypeEnum).includes(type))) || [];

	return { sequenceTypes: filteredSequenceTypes };
};
