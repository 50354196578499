import React, { CSSProperties } from 'react';
import { translationService } from '@src/index';
import { HeaderBox } from '@src/Components/Boxes/Containers/HeaderBox/HeaderBox';
import { IAssignedActivity } from '@src/interfaces';
import classes from './styles.module.scss';
import { ActivityProfessionRow } from '@src/Components/ActivityProfessionRow/ActivityProfessionRow';
import { Loader } from '@shared/components/Loader/Loader';
import { EquipmentCard } from '@src/Components/Boxes/Cards/EquipmentCard/EquipmentCard';

interface ILocationMenuActivitiesProps {
	dailyActivities: IAssignedActivity[];
}

const LocationMenuActivities = (props: ILocationMenuActivitiesProps) => {
	const styles: CSSProperties = {
		maxHeight: '300px',
		overflowY: 'auto',
	};
	const getDailyActivitiesContent = (): JSX.Element | JSX.Element[] => {
		if (!props.dailyActivities) {
			return <Loader />;
		}

		return props.dailyActivities?.length ? (
			props.dailyActivities.map((activity) => <ActivityProfessionRow activity={activity} />)
		) : (
			<div className={classes.noData}>{translationService.get('noDailyActivitiesFoundInFloor')}</div>
		);
	};

	return (
		<HeaderBox boldTitle title={translationService.get('activities')} customRootStyles={styles}>
			<section className={classes.activitiesContainer}>{getDailyActivitiesContent()}</section>
		</HeaderBox>
	);
};

export { LocationMenuActivities };
