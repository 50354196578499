import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { IRoundedActivityReport } from '../../../../../../../interfaces/IActivitiesReport';
import { IRootState } from '../../../../../../../store/slices';
import classes from './styles.module.scss';
import { ManagerChip } from '../../../../../../Chips/ManagerChip/ManagerChip';
import { translationService } from '../../../../../../../index';
import TrusstorTooltip from '@shared/components/TrusstorTooltip';
import { getDaysDiffBetweenDateAndToday } from '../../../../../../../utils/dateUtils';
import { COLORS } from '@shared/constants/colors.constants';
import { ActivityGroupStatus } from '@shared/interfaces/ActivityGroupStatus.enum';
import { getTooltipForDelayedActivity } from '../../../../../../../utils/widget.utils';

interface IActivityRowProps {
	activityReport: IRoundedActivityReport;
	isDailyReport: boolean;
}

const ActivityRow = (props: IActivityRowProps) => {
	const tz: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.tz);

	const getStartDateString = (startDate: string): string => {
		const dateFormat: string = translationService.getDateFormat();
		const startDay: string = moment.tz(startDate, tz).format(dateFormat);
		return startDay;
	};

	const getEndDateString = (endDate: string): string => {
		const dateFormat: string = translationService.getDateFormat();
		const endDay: string = moment.tz(endDate, tz).format(dateFormat);
		return endDay;
	};

	const isOverdue: boolean = props.activityReport.activitiesGroupStatus === ActivityGroupStatus.overdue;
	const isDelayed: boolean = props.activityReport.activitiesGroupStatus === ActivityGroupStatus.delayed;

	return (
		<div className={classes.rowContainer}>
			<div className={classes.activityInfoCell}>
				<div className={classes.datesContainer}>
					<TrusstorTooltip
						cleanStyling
						text={getTooltipForDelayedActivity(moment.tz(props.activityReport.startDate, tz).toDate(), tz)}
						showAlways={isDelayed}
					>
						<span style={isDelayed ? { color: COLORS.red600 } : {}}>
							{getStartDateString(props.activityReport.startDate)}
						</span>
					</TrusstorTooltip>
					&nbsp;-&nbsp;
					<TrusstorTooltip
						cleanStyling
						text={`${translationService.get('lateBy')}${getDaysDiffBetweenDateAndToday(
							moment.tz(props.activityReport.endDate, tz).toDate(),
							tz
						)} ${translationService.get('days')}`}
						showAlways={isOverdue}
					>
						<span style={isOverdue ? { color: COLORS.red600 } : {}}>
							{getEndDateString(props.activityReport.endDate)}
						</span>
					</TrusstorTooltip>
				</div>
				<div className={classes.activityTextContainer}>{props.activityReport.description}</div>
				<div className={classes.activityHoursContainer}>{props.activityReport.roundedActualWorkTime}h</div>
			</div>
			<div className={classes.workersCell}>{props.activityReport.roundedWorkersPerDay}</div>
			<div className={classes.timeCell}>{props.activityReport.roundedAverageWorkHoursPerDay}</div>
			{props.activityReport.manager && (
				<div className={classes.managerCell}>
					<ManagerChip
						chipText={props.activityReport.manager.name}
						chipBackground={props.activityReport.manager.backgroundColor}
					/>
				</div>
			)}
		</div>
	);
};

export { ActivityRow };
