import Worker from '@shared/assets/icons/worker.svg';
import * as React from 'react';
import GreyClipboard from '../../../assets/workplanIcons/greyClipboard.svg';
import GreyClock from '../../../assets/workplanIcons/greyClock.svg';
import GreyFloorsIcon from '../../../assets/workplanIcons/greyFloorsIcon.svg';
import GreyLocation from '../../../assets/workplanIcons/greyLocation.svg';
import GreyChatIcon from '@shared/assets/icons/grey_chat_icon.svg';
import { translationService } from '../../../index';
import { IWeekDayActivity } from '../../../interfaces';
import {
	getFloorsDisplayText,
	getNumberOfActiveFloors,
	getTotalWorkTimeForWeekDayActivity,
} from '../../../utils/calendar.utils';
import { getProfessionDisplayText } from '../../../utils/professions.utils';
import { CalendarCellTooltipRow } from '../CalendarCellTooltipRow/CalenderCellTooltipRow';
import classes from './styles.module.scss';

interface ICalendarCellTooltipProps {
	activity: IWeekDayActivity;
	isViewByFloor?: boolean;
}

export const CalendarCellTooltip = (props: ICalendarCellTooltipProps) => {
	const spenHoursText = props.isViewByFloor
		? translationService.get('hoursSpentOnFloor')
		: translationService.get('totalAccumulatedWorkHours');
	return (
		<div className={classes.tooltipDisplayComponent}>
			<CalendarCellTooltipRow icon={GreyClipboard}>{props.activity.description}</CalendarCellTooltipRow>
			<CalendarCellTooltipRow icon={Worker}>
				{getProfessionDisplayText(props.activity?.profession, true)}
			</CalendarCellTooltipRow>
			<CalendarCellTooltipRow icon={GreyLocation}>{getFloorsDisplayText(props.activity)}</CalendarCellTooltipRow>
			{!props.isViewByFloor && (
				<CalendarCellTooltipRow icon={GreyFloorsIcon}>
					{translationService.get('activeFloors')}{' '}
					<strong>{getNumberOfActiveFloors(props.activity.floors)}</strong>/{props.activity.floors.length}
				</CalendarCellTooltipRow>
			)}
			<CalendarCellTooltipRow icon={GreyClock}>
				{spenHoursText}{' '}
				<strong>{getTotalWorkTimeForWeekDayActivity(props.activity, props.isViewByFloor)}</strong>
			</CalendarCellTooltipRow>
		</div>
	);
};
