import { IBackendActivitiesReport } from '../../interfaces/IActivitiesReport';
import { IActivityReportState } from '../../interfaces/IActivityReportState';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getRoundedActivityReport } from '../../utils/roundedData.utils';

const initialState: IActivityReportState = {
	activityReportRounded: {},
};

const activityReportSlice = createSlice({
	name: 'activityReport',
	initialState,
	reducers: {
		updateActivityReport: (
			state: IActivityReportState,
			action: PayloadAction<{ activityReport: IBackendActivitiesReport }>
		) => {
			state.activityReportRounded = getRoundedActivityReport(action.payload.activityReport);
		},
		resetActivityReport: (state: IActivityReportState) => {
			state.activityReportRounded = {};
		},
	},
});

export const { updateActivityReport, resetActivityReport } = activityReportSlice.actions;
export default activityReportSlice.reducer;
