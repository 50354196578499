import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { IProfession } from '@shared/interfaces/IProfession';
import { IIssueAssignee, IIssueLocation, IssuePriority } from '@shared/interfaces/IIssueShared';
import { TranslationService } from '@shared/services/translation.service';
import { CriticalSection } from '@shared/components/Issue/CriticalSection/CriticalSection';
import { TrusstorTextInput } from '@shared/components/Inputs/TrusstorTextInput/TrusstorTextInput';
import classes from './styles.module.scss';
import { IIssue } from '@shared/interfaces/IIssue';
import { IConfigArea } from '@shared/interfaces/IConfigArea';
import { selectProjectId, selectTimezone } from '@store/slices/project.slice';
import { useSelector } from 'react-redux';
import { GeneralDialog } from '@src/Components/GeneralDialog/GeneralDialog';
import { isEqualWith, sortBy } from 'lodash';
import { requestService, translationService } from '@src/servicesInitializers';
import { DateSelectorTrusstorInput } from '@src/Components/DateSelector/DateSelectorTrusstorInput/DateSelectorTrusstorInput';
import { IUser } from '@shared/interfaces/IUser';
import { useUsersQuery } from '@src/hooks/queries/users.queries.hooks';
import { SingleDropdown } from '@src/Components/Dropdowns/DesignSystem/SingleDropdown/SingleDropdown';
import { SingleProfessionGroupedDropdown } from '@src/Components/Dropdowns/Profession/SingleProfessionGroupedDropdown/SingleProfessionGroupedDropdown';
import { IssueLocationSelectorPopperMenu } from '@src/Components/IssueLocationSelectorPopperMenu/IssueLocationSelectorPopperMenu';
import { Loader } from '@shared/components/Loader/Loader';
import { filterUsersByPermittedProfessions } from '@shared/utils/users.utils';

interface IIssueDesktopEditDialogProps {
	issue: IIssue;
	updateIssue: (issueId: string, issue: IIssue, updateBackend?: boolean) => Promise<IIssue>;
	translationService: TranslationService;
	closeDialog: () => void;
	projectAreas: IConfigArea[];
}

export const IssueDesktopEditDialog = (props: IIssueDesktopEditDialogProps) => {
	const projectId: string = useSelector(selectProjectId)!;
	const tz: string = useSelector(selectTimezone)!;
	const [priority, setPriority] = useState<IssuePriority>(props.issue.priority);
	const [description, setDescription] = useState<string>(props.issue.description);
	const [selectedProfession, setSelectedProfession] = useState<IProfession | undefined>(props.issue.profession);
	const [selectedAssignee, setSelectedAssignee] = useState<IIssueAssignee | undefined | null>(props.issue.assignee);
	const [selectedLocations, setSelectedLocations] = useState<IIssueLocation[] | undefined>(props.issue.locations);
	const [dueDate, setDueDate] = useState<Date | null>(props.issue.dueDate || null);
	const [issue, setIssue] = useState<IIssue>(props.issue);
	const [defaultAreas, setDefaultAreas] = useState<IConfigArea[]>(
		props.projectAreas?.filter((area) => {
			return props.issue.locations?.some((location) => location.area.areaId === area.areaId);
		})
	);
	const [usersOptions, setUsersOptions] = useState<IUser[]>([]);
	const { users, isLoading }: { users: IUser[]; isLoading: boolean } = useUsersQuery(projectId);

	useEffect(() => {
		const filteredUsers: IUser[] = selectedProfession
			? filterUsersByPermittedProfessions(users, [selectedProfession?._id])
			: users;
		setUsersOptions(filteredUsers);

		const selectedUser: IUser | undefined = filteredUsers.find(
			(user) => user.username === selectedAssignee?.username
		);
		const isSelectedAssigneePermitted: boolean =
			!selectedUser?.permissions.permittedProfessionsIds?.length ||
			selectedUser?.permissions.permittedProfessionsIds?.some((id) => id === selectedProfession?._id);
		if (selectedProfession && !isSelectedAssigneePermitted) {
			setSelectedAssignee(undefined);
		}
	}, [users, selectedProfession, selectedAssignee]);

	const loadDefaultAreas = async () => {
		let areas: IConfigArea[];
		if (props.projectAreas.length) {
			areas = props.projectAreas?.filter((area) => {
				return props.issue.locations?.some((location) => location.area.areaId === area.areaId);
			});
		} else {
			areas = await requestService.get(
				`/projectConfig/area?projectId=${projectId}&areaIds=${props.issue.locations
					?.map((l) => l.area.areaId)
					.join(',')}`
			);
		}

		setDefaultAreas(areas);
	};

	useEffect(() => {
		if (props.issue.locations?.length) {
			loadDefaultAreas();
		}
	}, [props.issue.locations]);

	const changePriority = () => {
		setPriority((prev) => (prev === IssuePriority.REGULAR ? IssuePriority.CRITICAL : IssuePriority.REGULAR));
	};

	const titlePlaceholder: string = `${props.translationService.get('issueDescription')}*`;
	const handleProfessionChange = (profession?: IProfession | null) => {
		if (!profession) {
			setSelectedProfession(undefined);
			return;
		}
		setSelectedProfession(profession);
	};

	const handleAssigneeChange = (user?: IUser | null) => {
		setSelectedAssignee(
			user
				? {
						username: user.username,
						name: user.name,
					}
				: null
		);
	};

	const handleLocationsChange = (locations: IIssueLocation[]) => {
		setSelectedLocations(locations);
	};

	const handleSelectDueDate = useCallback((date: Date) => {
		setDueDate(moment.tz(date, tz).toDate());
	}, []);

	useEffect(() => {
		const issue: IIssue = {
			createDate: props.issue.createDate,
			_id: props.issue._id,
			description,
			profession: selectedProfession,
			assignee: selectedAssignee,
			status: props.issue.status,
			priority,
			projectId,
			locations: selectedLocations,
			...(dueDate && { dueDate }),
		};
		setIssue(issue);
	}, [description, selectedProfession, selectedAssignee, selectedLocations, priority, dueDate]);

	const updateIssue = () => {
		issue && props.updateIssue(props.issue._id, issue, true);
		props.closeDialog();
	};

	const isLocationsTheSame: boolean = isEqualWith(
		sortBy(props.issue.locations, (l) => l.area.areaId) || [],
		sortBy(selectedLocations, (l) => l.area.areaId) || [],
		(objValue, otherValue, key) => {
			if (key === 'area') {
				return objValue.areaId === otherValue.areaId;
			}
		}
	);

	const shouldSaveBeDisabled =
		!issue ||
		!issue.description ||
		(props.issue.priority === priority &&
			props.issue.description === description &&
			props.issue.profession?._id === selectedProfession?._id &&
			props.issue.assignee?.username === selectedAssignee?.username &&
			moment.tz(props.issue.dueDate, tz).isSame(dueDate) &&
			isLocationsTheSame);

	if (isLoading) {
		return <Loader />;
	}

	return (
		<GeneralDialog
			show={true}
			close={props.closeDialog}
			mainButton={{
				text: props.translationService.get('save'),
				click: updateIssue,
				disabled: shouldSaveBeDisabled,
			}}
			secondaryButton={{
				text: props.translationService.get('cancel'),
				click: props.closeDialog,
			}}
			disableEnforceFocus
		>
			<div className={classes.createCardContainer}>
				<CriticalSection
					isLimitedUser={false}
					issuePriority={priority}
					translationService={props.translationService}
					onClick={changePriority}
				/>
				<div className={classes.details}>
					<TrusstorTextInput
						changeFunc={(newVal) => {
							setDescription(newVal);
						}}
						value={description}
						placeholder={titlePlaceholder}
						rootClassName={classes.inputContainer}
						textClassName={classes.titleInputText}
						autoFocus
					/>
				</div>
				<div className={classes.metadataSection}>
					<SingleProfessionGroupedDropdown
						onChange={handleProfessionChange}
						defaultSelectedProfession={issue.profession}
						useWorkingProfessions
					/>

					<IssueLocationSelectorPopperMenu
						handleLocationsChange={handleLocationsChange}
						initialSelectedLocations={selectedLocations}
						selectedLocationsAmount={selectedLocations?.length}
						isDialog
					/>
					{!!usersOptions.length && (
						<SingleDropdown
							value={usersOptions.find((user) => user.username === selectedAssignee?.username)}
							placeholder={translationService.get('selectAssignee')}
							options={usersOptions}
							onChange={handleAssigneeChange}
							getDisplayOption={(option: IUser) => option.name}
							disabled={props.issue.isPrivate}
						/>
					)}
					<DateSelectorTrusstorInput
						handleTimeChange={handleSelectDueDate}
						selectedDate={dueDate ? dueDate : null}
						minDate={new Date()}
						inputPlaceHolder={translationService.get('dueDate')}
						selectDefaultDate={!!dueDate}
					/>
				</div>
			</div>
		</GeneralDialog>
	);
};
