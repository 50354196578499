import { accessLevelService } from '@src/index';
import { whiteCheckIcon } from '@src/assets';
import minusIcon from '@shared/assets/icons/minusIcon.svg';
import { AreaSequenceItemStatus } from '@shared/interfaces/AreaSequenceItemStatus.enum';
import { workplanIcon } from '@shared/assets/icons';
import { workplanWhiteIcon } from '@shared/assets/icons';
import { workplanGreyIcon } from '@shared/assets/icons';
import { isAsiComplete } from '@shared/utils/asi.utils';

export const getCellIconSrc = (
	status: AreaSequenceItemStatus,
	isHover: boolean,
	isCheckedForActivityCreation: boolean
): any | undefined => {
	const isReadyForReview: boolean = status === AreaSequenceItemStatus.readyForReview;
	const userHasAccessToActivityActions: boolean = accessLevelService.hasAccess('workplanActivityActions');
	const sequenceIsDone: boolean = isAsiComplete(status);
	const shouldReturnWorkplanIcon: boolean = (() => {
		if (isHover) {
			if (!userHasAccessToActivityActions) {
				return !sequenceIsDone;
			}
			return isReadyForReview;
		}
		return false;
	})();

	const shouldReturnWhiteCheckIcon: boolean =
		sequenceIsDone && (!userHasAccessToActivityActions || !isHover || isCheckedForActivityCreation);

	if (shouldReturnWorkplanIcon) {
		return workplanIcon;
	}

	if (shouldReturnWhiteCheckIcon) {
		return whiteCheckIcon;
	}

	if (!isHover) {
		if (!sequenceIsDone) {
			return undefined;
		}
	}

	if (isReadyForReview) {
		if (userHasAccessToActivityActions) {
			return workplanWhiteIcon;
		}
		return workplanGreyIcon;
	}

	if (sequenceIsDone) {
		return minusIcon;
	}

	return whiteCheckIcon;
};
