import tagIcon from '@shared/assets/icons/tag.svg';
import classnames from 'classnames';
import React from 'react';
import classes from './styles.module.scss';
import { ManagerDisplayCard } from '@src/Components/MainPageSections/FloorView/ManagerDisplayCard/ManagerDisplayCard';
import { IManagerMergedTag } from '@shared/interfaces/IManagerMergedTag';

interface IFloorProfessionSectionProps {
	tags?: IManagerMergedTag[];
}

export const FloorManagersSection = (props: IFloorProfessionSectionProps) => {
	if (!props.tags?.length) {
		return null;
	}
	return (
		<div className={classes.wrapper} data-testid={'areaManagersCard'}>
			<div className={classes.wr}>
				<ManagerDisplayCard />
				<div data-testid={'professionCount'}>{props.tags.length}</div>
			</div>
			<div className={classes.tagsSection}>
				{props.tags.map((tag) => {
					return (
						<div className={classnames(classes.tag)} data-testid={'tag'}>
							<img src={tagIcon} alt={'tagIcon'} />
							<div>{tag.tagNick}</div>
							<div className={classes.tagName}>{tag.manager.name}</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};
