import { requestService } from '../index';
import { WorkplanInputNames } from '../constants';
import { IFloor, IProfession, IUser, IWeekDayActivity, IWorkPlanActivities, IWorkPlanActivity } from '../interfaces';
import { getTimezoneFormattedDate } from './dateUtils';
import { getMinimizedProfession } from './professions.utils';
import { ActivityGroupStatus } from '@shared/interfaces/ActivityGroupStatus.enum';
import { IActivitiesGroup } from '../interfaces/IActivitiesGroup';
import { IActivitySequenceItemCellData } from '@src/Components/WorkPlan/ActivityProgressTracker/components/ActivitySequenceMatrix/cellComponents/ActivitySequenceItemCell/ActivitySequenceItemCell';
import { ISequenceMatrixTableData } from '@src/Components/WorkPlan/ActivityProgressTracker/components/ActivitySequenceMatrix/ActivitySequenceMatrix';
import { IConfigArea } from '@shared/interfaces/IConfigArea';
import { IClientInputActivityShared } from '@shared/interfaces/IClientInputActivity';
import { IMergedAreaSequenceItem } from '@interfaces/IMergedAreaSequenceItem';
import { isArray, mapValues, uniqBy } from 'lodash';
import { IActivityLabel } from '@interfaces/IActivityLabel';
import { IActivityGroupStatusFilterItem } from '@interfaces/IActivityGroupStatusFilterItem';
import { ISequence } from '@shared/interfaces/ISequence';
import { IConfigAreaWithAreaSequenceItem } from '@shared/interfaces/IConfigAreaWithAreaSequenceItem';

export const getVisibleActivities = (
	visibleStaticUsers: IUser[],
	visibleProfessions: IProfession[],
	visibleFloors: IFloor[],
	visibleLabels: IActivityLabel[],
	selectedActivityGroupStatuses: IActivityGroupStatusFilterItem[],
	selectedSequences: ISequence[],
	activities: IWorkPlanActivities
): IWorkPlanActivities => {
	const filteredActivityByProfession: IWorkPlanActivities = getVisibleActivitiesReportByProfessions(
		visibleProfessions,
		activities
	);
	const filteredActivityByFloors: IWorkPlanActivities = getVisibleActivitiesReportByFloors(
		visibleFloors,
		filteredActivityByProfession
	);
	const filteredActivitiesByLabels: IWorkPlanActivities = getVisibleActivitiesByLabels(
		visibleLabels,
		filteredActivityByFloors
	);
	const filteredActivitiesByActivityGroupStatuses: IWorkPlanActivities = getVisibleActivitiesByActivityGroupStatuses(
		selectedActivityGroupStatuses,
		filteredActivitiesByLabels
	);
	const visibleActivitiesByUsers = getVisibleActivitiesReportByUsers(
		visibleStaticUsers,
		filteredActivitiesByActivityGroupStatuses,
		visibleStaticUsers.length > 0
	);

	const visibleActivitiesBySequences = getVisibleActivitiesReportBySequences(
		selectedSequences,
		visibleActivitiesByUsers
	);
	return visibleActivitiesBySequences;
};

export const filterActivitiesByVisibleEntities = (
	activities: IWorkPlanActivities,
	visibleEntities: any[],
	propertyToCompareInActivity: string,
	propertyToFilterBy: string
): IWorkPlanActivities => {
	if (visibleEntities.length === 0) {
		return activities;
	}
	const propertyToCompareInActivityWith: string[] = propertyToCompareInActivity.split('.');

	const filteredActivities: IWorkPlanActivities = mapValues(activities, (weeklyActivities: IWeekDayActivity[][]) => {
		return weeklyActivities.map((weekDayActivities: IWeekDayActivity[]) => {
			return weekDayActivities.filter((activity: IWeekDayActivity) => {
				return hasActivityAnyVisibleProperty(
					propertyToCompareInActivityWith.length === 1
						? activity
						: activity[propertyToCompareInActivityWith[0]],
					visibleEntities,
					propertyToCompareInActivityWith.length === 1
						? propertyToCompareInActivity
						: propertyToCompareInActivityWith[1],
					propertyToFilterBy
				);
			});
		});
	});

	return filteredActivities;
};

export const hasActivityAnyVisibleProperty = (
	activityPropertyToCompareWith: any,
	visibleEntities: any[],
	propertyToCompareInActivity: string,
	propertyToFilterBy: string
): boolean => {
	if (!activityPropertyToCompareWith) {
		return false;
	}

	if (isArray(activityPropertyToCompareWith)) {
		return visibleEntities.some((visibleItem) => {
			return activityPropertyToCompareWith.some(
				(activityItemToCompareWith) =>
					activityItemToCompareWith[propertyToCompareInActivity] === visibleItem[propertyToFilterBy]
			);
		});
	}

	const activityValueToCompareWith = activityPropertyToCompareWith[propertyToCompareInActivity];
	return visibleEntities.some((visibleItem: any) => {
		return visibleItem[propertyToFilterBy] === activityValueToCompareWith;
	});
};

export const getVisibleActivitiesReportByUsers = (
	visibleUsers: IUser[],
	activities: IWorkPlanActivities,
	shouldFilterByVisibleUsers: boolean
): IWorkPlanActivities => {
	if (visibleUsers.length === 0 || !shouldFilterByVisibleUsers) {
		return activities;
	}

	return filterActivitiesByVisibleEntities(activities, visibleUsers, 'assignee', 'username');
};

export const getVisibleActivitiesReportBySequences = (
	visibleSequences: ISequence[],
	activities: IWorkPlanActivities
): IWorkPlanActivities => {
	if (visibleSequences.length === 0) {
		return activities;
	}

	return filterActivitiesByVisibleEntities(activities, visibleSequences, 'linkedSequenceId', '_id');
};

export const getVisibleActivitiesReportByFloors = (
	visibleFloors: IFloor[],
	activities: IWorkPlanActivities
): IWorkPlanActivities => {
	if (visibleFloors.length === 0) {
		return activities;
	}
	return filterActivitiesByVisibleEntities(activities, visibleFloors, 'floors.floorId', 'floorId');
};

export const getVisibleActivitiesReportByProfessions = (
	visibleProfessions: IProfession[],
	activities: IWorkPlanActivities
): IWorkPlanActivities => {
	if (visibleProfessions.length === 0) {
		return activities;
	}

	return filterActivitiesByVisibleEntities(activities, visibleProfessions, 'profession._id', '_id');
};

export const getVisibleActivitiesByLabels = (
	visibleLabels: IActivityLabel[],
	activities: IWorkPlanActivities
): IWorkPlanActivities => {
	if (visibleLabels.length === 0) {
		return activities;
	}

	return filterActivitiesByVisibleEntities(activities, visibleLabels, 'label._id', '_id');
};

export const getVisibleActivitiesByActivityGroupStatuses = (
	selectedActivityGroupStatuses: IActivityGroupStatusFilterItem[],
	activities: IWorkPlanActivities
): IWorkPlanActivities => {
	return filterActivitiesByVisibleEntities(activities, selectedActivityGroupStatuses, 'status', 'status');
};

export const updateAreaSequenceItemsByActivities = async (
	activities: IClientInputActivityShared<IProfession>[] | Partial<IWorkPlanActivity>[],
	updateAreaSequenceItem: (areaSequenceItem: IMergedAreaSequenceItem) => void,
	recreateTable: (floorId: string, areaSequenceItem: IMergedAreaSequenceItem[]) => void,
	projectId: string,
	selectedSequenceId?: string
) => {
	if (!activities.length) {
		return;
	}
	const floorIds: string[] = uniqBy(
		activities.map((activity) => activity.floor!.floorId!),
		(floorId) => floorId
	);
	const areaSequenceItemsFromUpdatedActivities: IMergedAreaSequenceItem[] = await requestService.get(
		`/activities/sequenceItems/areaSequenceItem/byFloorId`,
		{
			params: {
				projectId,
				floorIds: floorIds.join(','),
				sequenceId: selectedSequenceId,
			},
		}
	);
	const areaSequenceItemsToUiUpdate: { [floorId: string]: IMergedAreaSequenceItem[] } = {};

	activities.forEach((activity) => {
		activity.areas!.forEach((area: IConfigAreaWithAreaSequenceItem) => {
			const areaSequenceItem: IMergedAreaSequenceItem | undefined = areaSequenceItemsFromUpdatedActivities.find(
				(areaSequenceItem: IMergedAreaSequenceItem) => areaSequenceItem._id === area.areaSequenceItemId
			);
			if (areaSequenceItem) {
				areaSequenceItemsToUiUpdate[areaSequenceItem.area.floorId]
					? areaSequenceItemsToUiUpdate[areaSequenceItem.area.floorId].push(areaSequenceItem)
					: (areaSequenceItemsToUiUpdate[areaSequenceItem.area.floorId] = [areaSequenceItem]);
				updateAreaSequenceItem(areaSequenceItem);
			}
		});
	});
	Object.keys(areaSequenceItemsToUiUpdate).forEach((floorId: string) => {
		recreateTable(floorId, areaSequenceItemsToUiUpdate[floorId]);
	});
};

export const deleteActivityByGroupId = async (groupId: string) => {
	await requestService.delete(`/activities/groups/${groupId}`);
};

export const createActivity = async (activity: Partial<IWorkPlanActivity>, projectId: string, tz: string) => {
	await requestService.post(`/activities`, {
		body: {
			...activity,
			projectId,
			profession: getMinimizedProfession(activity.profession),
			startDate: getTimezoneFormattedDate(tz, activity.startDate),
			endDate: getTimezoneFormattedDate(tz, activity.endDate),
		},
	});
};

export const updateActivity = async (
	activity: Partial<IWorkPlanActivity>,
	tz: string,
	projectId: string
): Promise<IWorkPlanActivity[]> => {
	activity.startDate = new Date(String(activity.startDate));
	if (activity.endDate) {
		activity.endDate = new Date(String(activity.endDate));
	}
	return await requestService.put(`/activities/groups/${activity.groupId}`, {
		body: {
			...activity,
			startDate: getTimezoneFormattedDate(tz, activity.startDate),
			endDate: getTimezoneFormattedDate(tz, activity.endDate),
			profession: getMinimizedProfession(activity.profession),
		},
	});
};

export const calculateCellsRange = (
	currentCell: IActivitySequenceItemCellData,
	lastSelectedShiftCell: IActivitySequenceItemCellData,
	floorsList: IConfigArea[],
	tables: ISequenceMatrixTableData[] | undefined
): IActivitySequenceItemCellData[] => {
	const getRowAndColIndex = (
		cell: IActivitySequenceItemCellData
	): {
		floorIndex: number;
		rowIndex: number;
		colIndex: number;
	} => {
		const floorIndex: number = floorsList.findIndex((floor) => floor.floorId === cell.area.floorId);
		const table: ISequenceMatrixTableData = tables![floorIndex];
		const rowIndex: number = table.rows.findIndex((row) =>
			row.some((rowCell) => rowCell.area.areaId === cell.area.areaId)
		);
		const colIndex: number = table.rows[rowIndex].findIndex(
			(rowCell) => rowCell.sequenceItemId === cell.sequenceItemId
		);
		return { rowIndex, colIndex, floorIndex };
	};

	const getRowRange = (currentCellRowIdx: number, lastSelectedCellRowIdx: number, floorRange: number[]): number[] => {
		if (floorRange[0] === floorRange[1]) {
			return [
				Math.min(currentCellRowIdx, lastSelectedCellRowIdx),
				Math.max(currentCellRowIdx, lastSelectedCellRowIdx),
			];
		}
		if (currentCellIndex.floorIndex === floorRange[0]) {
			return [currentCellRowIdx, lastSelectedCellRowIdx];
		}
		return [lastSelectedCellRowIdx, currentCellRowIdx];
	};

	const currentCellIndex = getRowAndColIndex(currentCell);
	const lastSelectedCellIndex = getRowAndColIndex(lastSelectedShiftCell);
	const floorRange = [
		Math.min(currentCellIndex.floorIndex, lastSelectedCellIndex.floorIndex),
		Math.max(currentCellIndex.floorIndex, lastSelectedCellIndex.floorIndex),
	];
	const rowRange = getRowRange(currentCellIndex.rowIndex, lastSelectedCellIndex.rowIndex, floorRange);
	const colRange = [
		Math.min(currentCellIndex.colIndex, lastSelectedCellIndex.colIndex),
		Math.max(currentCellIndex.colIndex, lastSelectedCellIndex.colIndex),
	];

	const selectedCells: IActivitySequenceItemCellData[] = [];
	for (let floorIdx = floorRange[0]; floorIdx <= floorRange[1]; floorIdx++) {
		const table: ISequenceMatrixTableData = tables![floorIdx];
		let rowIndex = floorIdx === floorRange[0] ? rowRange[0] : 0;
		const lastRowIndex = floorIdx === floorRange[1] ? rowRange[1] : table.rows.length - 1;
		while (rowIndex <= lastRowIndex) {
			for (let colIndex = colRange[0]; colIndex <= colRange[1]; colIndex++) {
				const currSelectedCell: IActivitySequenceItemCellData = table.rows[rowIndex][colIndex];
				if (currSelectedCell.isDisabled) {
					continue;
				}

				selectedCells.push(table.rows[rowIndex][colIndex]);
			}
			rowIndex++;
		}
	}
	return selectedCells;
};

export const workplanAvailableGroupInputs: { [key: string]: WorkplanInputNames[] } = {
	[ActivityGroupStatus.delayed]: [
		WorkplanInputNames.description,
		WorkplanInputNames.label,
		WorkplanInputNames.startDate,
		WorkplanInputNames.endDate,
		WorkplanInputNames.pushEndDate,
	],
	[ActivityGroupStatus.inProgress]: [
		WorkplanInputNames.description,
		WorkplanInputNames.label,
		WorkplanInputNames.endDate,
	],
	[ActivityGroupStatus.overdue]: [
		WorkplanInputNames.description,
		WorkplanInputNames.label,
		WorkplanInputNames.endDate,
	],
	[ActivityGroupStatus.planned]: [
		WorkplanInputNames.profession,
		WorkplanInputNames.description,
		WorkplanInputNames.label,
		WorkplanInputNames.endDate,
		WorkplanInputNames.startDate,
		WorkplanInputNames.floors,
		WorkplanInputNames.areas,
		WorkplanInputNames.assignee,
		WorkplanInputNames.dangerousActivity,
		WorkplanInputNames.pushEndDate,
	],
	[ActivityGroupStatus.complete]: [],
};

export const getWeekDayActivityFromActivitiesGroup = (
	weekDayActivity: IWeekDayActivity,
	activitiesGroup: IActivitiesGroup
): IWeekDayActivity => {
	const {
		profession,
		description,
		originalStartDate,
		startDate,
		endDate,
		label,
		groupStatus,
		linkedSequenceType,
		dangerousActivity,
		projectId,
	} = activitiesGroup;

	return {
		...weekDayActivity,
		profession,
		description,
		startDate: new Date(originalStartDate ?? startDate),
		endDate: new Date(endDate),
		label,
		status: groupStatus,
		linkedSequenceType,
		dangerousActivity,
		projectId,
	};
};
