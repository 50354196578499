export const professionBackgroundColorToWorkplanChipColorMappings = {
	spaceCadet: '#4d0068',
	azure: '#004fc6',
	blueSky: '#4e9fe5',
	beetrootPurple: '#cf2d71',
	fandango: '#a5398d',
	africanViolet: '#b990c2',
	freshPurple: '#d260e1',
	pinkSherbet: '#f8839f',
	orange: '#ffa253',
	lightSeaGreen: '#14a4a4',
	supremeTurquoise: '#19cbdc',
	cerise: '#98001b',
};
