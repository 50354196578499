import { IMatrixColHeader } from '@src/Components/Matrix/Matrix';
import { IProfession } from '@shared/interfaces/IProfession';

export function getFilteredMatrixColumnIndexes(
	tablesColHeaders: IMatrixColHeader[],
	visibleProfessions: IProfession[],
	hasProfessionFilter: boolean,
	showOnlyStarred?: boolean
): number[] | undefined {
	if (!tablesColHeaders.length) {
		return [];
	}

	const filteredHeadersByProfession: number[] = tablesColHeaders
		.map((header, index) => {
			if (visibleProfessions.some((profession) => profession._id === header.profession._id)) {
				return index;
			}
			return undefined;
		})
		.filter((index: number | undefined) => index !== undefined)
		.map((index: number | undefined) => index!);

	const filteredHeadersByStarred: number[] = tablesColHeaders
		.map((header, index: number) => {
			if (header.isStarred) {
				return index;
			}
			return undefined;
		})
		.filter((index: number | undefined) => index !== undefined)
		.map((index: number | undefined) => index!);

	if (hasProfessionFilter && showOnlyStarred) {
		const intersection: number[] = filteredHeadersByProfession.filter((index) =>
			filteredHeadersByStarred.includes(index)
		);
		return intersection;
	}

	if (hasProfessionFilter) {
		return filteredHeadersByProfession;
	}

	if (showOnlyStarred) {
		return filteredHeadersByStarred;
	}

	return undefined;
}
