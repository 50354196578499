import React from 'react';
import { DropdownDisplay } from '../Displays/DropdownDisplay/DropdownDisplay';
import { useDropdownKeyboardNavigation, useSingleSelectionDropdown } from './singleSelectionDropdown.utils';
import { SingleDropdownProps } from '../dropdown.interface';

function SingleDropdownShared<T>(props: SingleDropdownProps<T>) {
	const {
		filteredOptions,
		isDropdownOpen,
		dropdownRef,
		highlightedIndex,
		setHighlightedIndex,
		handleInputChange,
		handleOptionClick,
		handleOptionHover,
		setIsDropdownOpen,
		handleInputFocusChange,
		valueForInput,
		optionsContainerRef,
		isSelected,
	} = useSingleSelectionDropdown<T>(props);

	const handleKeyDown = useDropdownKeyboardNavigation(
		highlightedIndex,
		setHighlightedIndex,
		optionsContainerRef,
		filteredOptions,
		handleOptionClick,
		true
	);

	return (
		<DropdownDisplay
			errorText={props.errorText}
			isError={props.isError}
			testId={props.testId}
			dropdownRef={dropdownRef}
			optionsContainerRef={optionsContainerRef}
			isDropdownOpen={isDropdownOpen}
			handleInputChange={handleInputChange}
			valueForInput={valueForInput}
			placeholder={props.placeholder}
			label={props.label}
			disabled={props.disabled}
			required={props.required}
			handleInputFocusChange={handleInputFocusChange}
			filteredOptions={filteredOptions}
			highlightedIndex={highlightedIndex}
			setIsDropdownOpen={setIsDropdownOpen}
			handleKeyDown={handleKeyDown}
			handleOptionClick={handleOptionClick}
			handleOptionHover={handleOptionHover}
			getDisplayOption={props.getDisplayOption}
			getSecondDisplayOption={props.getSecondDisplayOption}
			getIsSelected={isSelected}
			hideClearTextButton={props.hideClearTextButton}
			inputRef={props.inputRef}
			startIcon={props.startIcon}
			dropdownContainerClassName={props.dropdownContainerClassName}
			shouldNotSelectInputTextOnFocus={props.shouldNotSelectInputTextOnFocus}
		/>
	);
}

export { SingleDropdownShared };
