import { IWorkerTimesheet } from '@shared/interfaces/IWorkerTimesheet';
import { selectTimezone } from '@store/slices/project.slice';
import { getDateRanges } from '@utils/dateUtils';
import moment from 'moment/moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { InvestigationReportEmptyPage } from '../../../InvestigationReportEmptyPage/InvestigationReportEmptyPage';
import { EMPTY_ITEM_CONTENT } from '../../../reports.constants';
import { getDayTimesheetByDate, getTimesheetColumns } from '../../../timesheets.utils';
import classes from './styles.module.scss';
import { sortBy } from 'lodash';
import classnames from 'classnames';
import { TrusstorIcon } from '@src/Components/TrusstorIcon/TrusstorIcon';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { IconColor, IconSize } from '@shared/components/TrusstorIconShared/TrusstorIconShared';
import TrusstorTooltip from '@shared/components/TrusstorTooltip';
import { translationService } from '@src/servicesInitializers';

interface IWorkerTimesheetTableProps {
	timesheet: IWorkerTimesheet[];
	startDate: Date;
	endDate: Date;
	isEmptyState: boolean;
}

export const WorkerTimesheetTable = ({ timesheet, startDate, endDate, isEmptyState }: IWorkerTimesheetTableProps) => {
	const tz: string = useSelector(selectTimezone)!;
	const dateRange: Date[] = getDateRanges(moment.tz(startDate, tz), moment.tz(endDate, tz));

	const columns = getTimesheetColumns(tz);

	return (
		<>
			<table className={classes.table}>
				<thead>
					<tr>
						{columns.map((column) => (
							<th key={column.title}>{column.title}</th>
						))}
					</tr>
				</thead>
				<tbody>
					{!isEmptyState &&
						dateRange.map((date, dateIndex) => {
							const timesheetsOfDate: IWorkerTimesheet[] = getDayTimesheetByDate(date, timesheet, tz);
							const sortedTimesheetsOfDateByStartTime: IWorkerTimesheet[] = sortBy(
								timesheetsOfDate,
								(timesheet) => timesheet.arrivalTimeTimestamp
							);
							const records =
								sortedTimesheetsOfDateByStartTime.length === 0
									? [{ date }]
									: sortedTimesheetsOfDateByStartTime;
							const isMultipleRecords: boolean = timesheetsOfDate.length > 1;
							return records.map((item, index) => (
								<tr key={`${dateIndex}-${index}`}>
									{columns.map((column, columnIndex) => {
										const isFirstColumn: boolean = columnIndex === 0;
										return (
											<td
												key={column.title}
												className={classnames({ [classes.dateCell]: isFirstColumn })}
											>
												{column.renderTableContentMethod(item) || EMPTY_ITEM_CONTENT}
												<div className={classes.multipleRecordsIconContainer}>
													{isFirstColumn && isMultipleRecords && (
														<TrusstorTooltip
															text={translationService.get(
																'timesheet_multipleRecordsTooltip'
															)}
															showAlways
														>
															<div>
																<TrusstorIcon
																	iconName={IconNames.warningTriangle}
																	size={IconSize.SMALL}
																	color={IconColor.FeedbackHighDark}
																/>
															</div>
														</TrusstorTooltip>
													)}
												</div>
											</td>
										);
									})}
								</tr>
							));
						})}
				</tbody>
			</table>
			{isEmptyState && <InvestigationReportEmptyPage height={300} />}
		</>
	);
};
