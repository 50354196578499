import React, { useId } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import { textStyle } from '@shared/utils/text.utils';
import { COLORS, FLOOR_CHIP_HEIGHT, PROFESSIONS_CHIP_MIN_WIDTH } from '../../constants';
import TrusstorTooltip from '@shared/components/TrusstorTooltip';

interface IStyleProps {
	chipColor?: string;
	maxWidth?: number;
	isMaxHeight?: boolean;
	marginInlineStart?: string;
}

const useStyles = makeStyles((theme) => ({
	root: ({ chipColor, maxWidth, isMaxHeight, marginInlineStart }: IStyleProps) => ({
		backgroundColor: chipColor,
		height: isMaxHeight ? 'calc(100% - 4px)' : FLOOR_CHIP_HEIGHT,
		maxHeight: FLOOR_CHIP_HEIGHT,
		margin: 2,
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		maxWidth: maxWidth,
		minWidth: PROFESSIONS_CHIP_MIN_WIDTH,
		...(marginInlineStart && { marginInlineStart, marginInlineEnd: 0 }),
	}),
	label: {
		paddingLeft: 2,
		paddingRight: 2,
	},
	avatar: ({ chipColor }: IStyleProps) =>
		textStyle({
			fontClass: 'body2',
			height: 14 + 'px !important',
			width: 16 + 'px !important',
			color: chipColor + ' !important',
			paddingTop: 2 + 'px !important',
			backgroundColor: COLORS.white + ' !important',
		}),
	text: textStyle({
		marginLeft: 4,
		marginRight: 6,
		fontClass: 'body1',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		color: COLORS.white,
	}),
	labelContainer: {
		display: 'flex',
		alignItems: 'center',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
	},
	clock: {
		width: 16,
		height: 16,
	},
	maxContentWidth: {
		maxWidth: 'fit-content !important',
	},
}));

interface IChipWithNumberProps {
	chipText: string;
	number: number;
	chipColor: string;
	className?: string;
	maxWidth?: number;
	fullHeightChip?: boolean;
	marginInlineStart?: string;
	showTooltip?: boolean;
}

const ChipWithNumber = ({
	chipText,
	number,
	chipColor,
	className,
	maxWidth,
	fullHeightChip,
	marginInlineStart,
	showTooltip,
}: IChipWithNumberProps): JSX.Element => {
	const classes = useStyles({ chipColor, maxWidth, isMaxHeight: fullHeightChip, marginInlineStart });
	const chipTextId: string = `${useId()}-chipWithNumberText-${chipText}`;

	const getChipElement = (chipRootClass: string, setTextId: boolean = true) => (
		<Chip
			label={
				<div className={classes.labelContainer}>
					<Avatar className={classes.avatar} data-testid={'chipNumber'}>
						{number}
					</Avatar>
					<p id={setTextId ? chipTextId : undefined} className={classes.text} data-testid={'chipText'}>
						{chipText}
					</p>
				</div>
			}
			classes={{
				label: classes.label,
				root: chipRootClass,
			}}
			className={className}
			data-testid={'chipWithNumber'}
		/>
	);

	return (
		<TrusstorTooltip
			disableTooltip={!showTooltip}
			idToOverflow={chipTextId}
			tooltipComponent={getChipElement(`${classes.root} ${classes.maxContentWidth}`, false)}
		>
			{getChipElement(classes.root)}
		</TrusstorTooltip>
	);
};

export { ChipWithNumber };
