import React, { useCallback } from 'react';
import classes from './styles.module.scss';
import { IAssignedActivityArea } from '../../../interfaces/IAssignedActivityArea';
import { TranslationService } from '../../../services/translation.service';
import { getProductRoundedHoursFromMs } from '../../../utils/roundNumbers.utils';
import doneSequenceIcon from '../../../assets/checkbox/doneSequenceIcon.svg';
import greyDoneSequenceIcon from '../../../assets/checkbox/greyDoneSequenceIcon.svg';
import unDoneSequenceIcon from '../../../assets/checkbox/unDoneSequenceIcon.svg';
import greenUnDoneSequenceIcon from '../../../assets/checkbox/greenUnDoneSequenceIcon.svg';
import doneForReviewSequenceIcon from '../../../assets/checkbox/doneForReviewSequenceIcon.svg';
import orangeUnDoneSequenceIcon from '../../../assets/checkbox/orangeUnDoneSequenceIcon.svg';
import { IAreaSequenceItem } from '../../../interfaces/IAreaSequenceItem';
import { useSharedServices } from '../../../hooks/sharedServices.context';
import { getAreaSequenceItemCellColorClassName } from '../../../utils/sequence.utils';
import { ActivitySequenceMatrixMode } from '../../../interfaces/ActivitySequenceMatrixMode.enum';
import { isAsiComplete } from '../../../utils/asi.utils';
import { getIsTouchDevice } from '../../../utils/mobile.utils';
import { IAreaSequenceItemWithActivityStatus } from '../../../interfaces/IMainPageWidgetActivityData';

interface IWidgetAreaSequenceItemProps {
	onClick: (areaSequenceItem: IAreaSequenceItemWithActivityStatus) => void;
	areaSequenceItem: IAreaSequenceItemWithActivityStatus;
	area: IAssignedActivityArea;
	translationService: TranslationService;
	userHasAccessToActivityActions: boolean;
	isDoneCell: boolean;
	isReadyForReview: boolean;
}

export const WidgetAreaSequenceItem = (props: IWidgetAreaSequenceItemProps) => {
	const isTouchDevice: boolean = getIsTouchDevice();
	const { translationService } = useSharedServices();
	const [isHovered, setIsHovered] = React.useState<boolean>(false);

	const getStatusIcon = useCallback(() => {
		const defaultLimitedUserIcon = props.isReadyForReview ? doneForReviewSequenceIcon : unDoneSequenceIcon;
		const hoveredLimitedUserIcon = props.isReadyForReview ? orangeUnDoneSequenceIcon : greyDoneSequenceIcon;
		if (!props.userHasAccessToActivityActions) {
			if (props.isDoneCell) {
				return doneSequenceIcon;
			}
			if (isHovered) {
				return hoveredLimitedUserIcon;
			}
			return defaultLimitedUserIcon;
		}
		if (props.isReadyForReview) {
			return unDoneSequenceIcon;
		}
		const defaultIcon = props.isDoneCell ? doneSequenceIcon : unDoneSequenceIcon;
		const hoveredIcon = props.isDoneCell ? greenUnDoneSequenceIcon : greyDoneSequenceIcon;
		if (isHovered) {
			return hoveredIcon;
		}
		return defaultIcon;
	}, [isHovered, props.isReadyForReview, props.userHasAccessToActivityActions, props.isDoneCell]);

	const handleClick = async (): Promise<void> => {
		if (!props.userHasAccessToActivityActions && isAsiComplete(props.areaSequenceItem.status)) return;
		props.onClick(props.areaSequenceItem);
	};

	return (
		<div
			className={`${classes.areaSequenceItemContainer} ${getAreaSequenceItemCellColorClassName(
				props.areaSequenceItem.status,
				false,
				isTouchDevice,
				classes,
				ActivitySequenceMatrixMode.display,
				props.isDoneCell,
				props.isReadyForReview
			)}`}
			onClick={handleClick}
			onMouseEnter={() => !isTouchDevice && setIsHovered(true)}
			onMouseLeave={() => !isTouchDevice && setIsHovered(false)}
		>
			<div className={classes.areaText}>{props.area.areaNick}</div>
			{props.isReadyForReview && (
				<div className={classes.forReview}>{translationService.get('readyForReview')}</div>
			)}
			<div className={classes.timeText}>
				{!props.isDoneCell && (
					<>
						{getProductRoundedHoursFromMs(props.area.accumulatedWorkTime)}
						{props.translationService.get('h')}
					</>
				)}
			</div>
			<img src={getStatusIcon()} />
		</div>
	);
};
