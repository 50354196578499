import { RequestService } from './request.service';

class StorageService {
	private requestService: RequestService;
	private base64Prefix: string;

	constructor(requestService: RequestService) {
		this.requestService = requestService;
		this.base64Prefix = 'data:image/png;base64,';
	}

	async getProjectLogo(projectId: string, isMobile = false) {
		try {
			return (
				this.base64Prefix +
				(await this.requestService.get(`/storage/projects/${projectId}/logo?&isMobile=${isMobile}`))
			);
		} catch (e) {
			return '';
		}
	}

	async getProjectImage(projectId: string) {
		try {
			const projectImage = await this.requestService.get(`/storage/projects/${projectId}/projectImage`);
			return this.base64Prefix + projectImage;
		} catch (e) {
			return '';
		}
	}

	async getOrganizationLogo(organizationId: string, theme: string) {
		try {
			const organizationLogo = await this.requestService.get(
				`/storage/organizations/${organizationId}/logo?theme=${theme}`
			);
			return this.base64Prefix + organizationLogo;
		} catch (e) {
			return '';
		}
	}

	async getBuildingSvg(projectId: string) {
		try {
			return await this.requestService.get(`/storage/projects/${projectId}/buildingView`);
		} catch (e) {
			return '';
		}
	}

	async getFloorSvg(projectId: string, floorId: string) {
		try {
			return await this.requestService.get(`/storage/projects/${projectId}/floorView?floorId=${floorId}`);
		} catch (err) {
			return '';
		}
	}

	async getIssueCommentImage(issueId: string, commentId: string): Promise<string> {
		try {
			return (
				this.base64Prefix +
				(await this.requestService.get(`/storage/issues/${issueId}/attachments/${commentId}`))
			);
		} catch (e) {
			return '';
		}
	}

	async getIssueCommentPDF(issueId: string, commentId: string): Promise<string> {
		try {
			return await this.requestService.get(`/storage/issues/${issueId}/attachments/${commentId}`);
		} catch (e) {
			return '';
		}
	}

	async getTranslationsCsv(): Promise<string> {
		return await this.requestService.get('/storage/translations');
	}
}

export { StorageService };
