import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMainPageLayoutState } from '@interfaces/IMainPageLayoutState';
import { DashboardTabTypes } from '@interfaces/DashboardTabTypes.enum';
import { IRootState } from '.';

const initialState: IMainPageLayoutState = {
	activeMainPageLayout: null,
};

const mainPageLayoutSlice = createSlice({
	name: 'mainPageLayout',
	initialState,
	reducers: {
		updateActiveMainPageLayout: (
			state: IMainPageLayoutState,
			action: PayloadAction<{ activeMainPageLayout: DashboardTabTypes | null }>
		) => {
			state.activeMainPageLayout = action.payload.activeMainPageLayout;
		},
	},
});
export const selectActiveMainPageLayout = (state: IRootState): DashboardTabTypes | null =>
	state.mainPageLayoutReducer.activeMainPageLayout;
export const { updateActiveMainPageLayout } = mainPageLayoutSlice.actions;
export default mainPageLayoutSlice.reducer;
