import { useSequenceSequenceItemsQuery } from '@src/hooks/queries/sequenceItem.query';
import { ISequence } from '@shared/interfaces/ISequence';
import React from 'react';
import { SequenceTypeDropdown } from '@shared/components/SequenceTypeDropDown/SequenceTypeDropdown';
import classes from './styles.module.scss';
import { SequenceTypeEnum } from '@shared/interfaces/SequenceType.enum';
import classnames from 'classnames';
import { translationService } from '@src/servicesInitializers';

interface IRestOfSequenceItemProps {
	sequence: ISequence;
	changeSequenceType: (sequenceType: SequenceTypeEnum) => void;
	selectSequence: (sequence: ISequence) => void;
	isSelected: boolean;
	canChangeSequenceType?: boolean;
}

export const SequencesDropdownItem = (props: IRestOfSequenceItemProps) => {
	return (
		<div
			key={props.sequence._id}
			onClick={(e) => {
				e.stopPropagation();
				props.selectSequence(props.sequence);
			}}
			className={classnames(classes.dropdownItem, { [classes.selected]: props.isSelected })}
		>
			<SequenceTypeDropdown
				readOnly
				onSelect={props.changeSequenceType}
				currentSequenceType={props.sequence.sequenceType}
				projectId={props.sequence.projectId}
			/>
			<div className={classes.input} placeholder={translationService.get('enterChecklistName')}>
				{props.sequence.name}
			</div>
		</div>
	);
};
