import React, { ReactElement } from 'react';
import { ITableComponentProps } from '@interfaces/ITableComponentProps';
import { ISequenceItem } from '@interfaces/ISequenceItem';
import {
	ITrusstorButtonSize,
	ITrusstorButtonType,
	TrusstorButton,
} from '@shared/components/buttons/TrusstorButton/TrusstorButton';
import { TrusstorIcon } from '@src/Components/TrusstorIcon/TrusstorIcon';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { translationService } from '@src/servicesInitializers';
import { IconColor } from '@shared/components/TrusstorIconShared/TrusstorIconShared';
import classes from './styles.module.scss';

interface ILinkToMilestoneCellProps {
	sequenceItem: ISequenceItem;
	handleLinkClick: (sequenceItem: ISequenceItem, isLinkAction: boolean) => void;
	currentMilestoneId: string;
}

export const LinkToMilestoneCell = (
	props: ITableComponentProps<ILinkToMilestoneCellProps>
): ReactElement<ITableComponentProps<ISequenceItem>> => {
	const sequenceItem: ISequenceItem = props.data.sequenceItem;

	const isLinkedToCurrentMilestone: boolean = sequenceItem.linkedMilestoneId === props.data.currentMilestoneId;
	const isLinked: boolean = !!sequenceItem.linkedMilestoneId;

	const isLinkAction: boolean = !isLinkedToCurrentMilestone || !isLinked;

	const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.stopPropagation();
		props.data.handleLinkClick(sequenceItem, isLinkAction);
	};
	return (
		<div data-testid="LinkToMilestoneCell">
			{!isLinkAction ? (
				<TrusstorButton
					className={classes.button}
					buttonType={ITrusstorButtonType.PRIMARY_WITH_LIGHT_HOVER}
					buttonSize={ITrusstorButtonSize.SMALL}
					iconElement={<TrusstorIcon iconName={IconNames.checkCircle} color={IconColor.White} />}
					hoverIconElement={<TrusstorIcon iconName={IconNames.unlink} />}
					hoverText={translationService.get('sequence_link_table_unlink_button')}
					text={translationService.get('sequence_linked_table_button')}
					handleClick={handleButtonClick}
				/>
			) : (
				<TrusstorButton
					className={classes.button}
					buttonType={ITrusstorButtonType.OUTLINE}
					buttonSize={ITrusstorButtonSize.SMALL}
					iconElement={<TrusstorIcon iconName={IconNames.link} />}
					text={translationService.get('sequence_link_table_link_button')}
					handleClick={handleButtonClick}
				/>
			)}
		</div>
	);
};
