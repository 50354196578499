import React, { useState, useEffect } from 'react';
import classes from './styles.module.scss';
import {
	ITrusstorIconButtonStyle,
	TrusstorIconButtonDeprecated,
} from '../buttons/TrusstorIconButton/TrusstorIconButtonDeprecated';
import { IconColor, TrusstorIconShared } from '../TrusstorIconShared/TrusstorIconShared';
import { IconNames } from '../TrusstorIconShared/IconNames.enum';
import { useSharedServices } from '../../hooks/sharedServices.context';

interface IBackForwardSectionProps {
	onIndexChange: (index: number) => void;
	totalCount: number;
}

export const BackForwardSection = (props: IBackForwardSectionProps) => {
	const [currIndex, setCurrIndex] = useState<number>(0);
	const { translationService } = useSharedServices();
	const isRtl: boolean = translationService.getIsRtl();

	const onPreviousClick = (e) => {
		e.stopPropagation();
		if (currIndex > 0) {
			setCurrIndex(currIndex - 1);
			props.onIndexChange(currIndex - 1);
		}
	};

	const onNextClick = (e) => {
		e.stopPropagation();
		if (currIndex < props.totalCount) {
			setCurrIndex(currIndex + 1);
			props.onIndexChange(currIndex + 1);
		}
	};

	return (
		<div className={classes.backForwardSection}>
			<TrusstorIconButtonDeprecated
				onClick={onPreviousClick}
				style={ITrusstorIconButtonStyle.OUTLINE}
				iconClassName={classes.arrow}
				disabled={currIndex === 0}
				iconElement={
					<TrusstorIconShared
						color={currIndex === 0 ? IconColor.Grey100 : IconColor.DarkBlue}
						iconName={isRtl ? IconNames.chevronRight : IconNames.chevronLeft}
					/>
				}
			/>
			<div className={classes.backForwardText}>
				{currIndex + 1}/{props.totalCount}
			</div>
			<TrusstorIconButtonDeprecated
				onClick={onNextClick}
				style={ITrusstorIconButtonStyle.OUTLINE}
				iconClassName={classes.arrow}
				disabled={currIndex === props.totalCount - 1}
				iconElement={
					<TrusstorIconShared
						color={currIndex === props.totalCount - 1 ? IconColor.Grey100 : IconColor.DarkBlue}
						iconName={isRtl ? IconNames.chevronLeft : IconNames.chevronRight}
					/>
				}
			/>
		</div>
	);
};
