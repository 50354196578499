import React from 'react';
import classes from './styles.module.scss';
import { IProfessionWithActiveTags } from '@interfaces/IProfessionWithActiveTags';
import { IManagerMergedTag } from '@interfaces/IManagerMergedTag';
import { ManagerChip } from '@src/Components/Chips/ManagerChip/ManagerChip';
import { ISvgElementContainer } from '@interfaces/ISvgElementContainer';
import {
	EQUIPMENT_ICON_WIDTH,
	FLOOR_CHIPS_MARGIN,
	FLOOR_CHIP_HEIGHT,
	PROFESSIONS_CHIP_MAX_WIDTH,
	FLOOR_CHIP_NUMBER_NORMAL_SIZE,
} from '@src/constants';
import { useSelector } from 'react-redux';
import { selectProjectId } from '@store/slices/project.slice';
import { translationService } from '@src/servicesInitializers';
import { Dictionary, groupBy } from 'lodash';
import { IMergedEquipmentView } from '@interfaces/IMergedEquipmentView';
import { ChipWithNumber } from '@src/Components/Chips/ChipWithNumber';
import {
	getProfessionBackgroundColor,
	getProfessionDisplayTextFromDisplaySettings,
} from '@shared/utils/professions.utils';
import { EquipmentTypes } from '@interfaces/EquipmentTypes.enum';
import { parse2Int } from '@utils/generalUtils';
import { IUtilityMergedTag } from '@interfaces/IUtilityMergedTag';
import UtilitiesChipsContainer from '@src/Components/Chips/UtilitiesChipsContainer/UtilitiesChipsContainer';
import { FloorMixedEntitiesTooltip } from '@src/Components/MainPageSections/FloorView/FloorMixedEntitiesTooltip/FloorMixedEntitiesTooltip';
import TrusstorTooltip from '@shared/components/TrusstorTooltip';
import { IMergedTag } from '@interfaces/IMergedTag';
import { IWorkerMergedTag } from '@interfaces/IWorkerMergedTag';
import { EquipmentChip } from '@src/Components/Chips/EquipmentChip/EquipmentChip';

interface IPersonnelFloorEntitiesProps {
	floorProfessions: IProfessionWithActiveTags[];
	floorManagers: IManagerMergedTag[];
	chipsContainerSvgElement: ISvgElementContainer;
	isAdmin: boolean;
	equipmentData: IMergedEquipmentView[];
	utilitiesData: IUtilityMergedTag[];
	workersOnFloor: IMergedTag[];
	floorNick: string;
}

export const PersonnelFloorEntities = (props: IPersonnelFloorEntitiesProps) => {
	const projectId: string = useSelector(selectProjectId)!;

	const equipmentByType: Dictionary<IMergedEquipmentView[]> = groupBy(props.equipmentData, 'type');
	const groupUtilitiesByType: Dictionary<IUtilityMergedTag[]> = groupBy(
		props.utilitiesData,
		(utility) => utility.linkedUtility.type
	);
	const numberOfEquipmentTypes: number =
		Object.keys(equipmentByType).length + Object.keys(groupUtilitiesByType).length;
	const numberOfChips: number = props.floorProfessions.length + props.floorManagers.length + numberOfEquipmentTypes;
	const numberOfRows: number = props.chipsContainerSvgElement.isMultipleLines
		? Math.floor(props.chipsContainerSvgElement.height! / (FLOOR_CHIP_HEIGHT + 4))
		: 1;
	const maximumNumberOfChipsPerRow: number = Math.ceil(numberOfChips / numberOfRows);

	const getMaxChipWidth = (): number => {
		if (!props.chipsContainerSvgElement.width || isNaN(parse2Int(props.chipsContainerSvgElement.width)))
			return PROFESSIONS_CHIP_MAX_WIDTH;
		//substracting the width of the equipment icons and the margin between the chips from the width of the container
		const maxChipWidthByNumberOfChips: number =
			(parse2Int(props.chipsContainerSvgElement.width) -
				maximumNumberOfChipsPerRow * FLOOR_CHIPS_MARGIN -
				numberOfEquipmentTypes * EQUIPMENT_ICON_WIDTH) /
			maximumNumberOfChipsPerRow;
		return Math.min(maxChipWidthByNumberOfChips, PROFESSIONS_CHIP_MAX_WIDTH);
	};

	const maxChipWidth: number = getMaxChipWidth();

	return (
		<TrusstorTooltip
			tooltipClasses={classes.mixedEntitiesTooltip}
			showAlways
			tooltipComponent={
				<FloorMixedEntitiesTooltip
					visibleWorkersOnSite={props.workersOnFloor as IWorkerMergedTag[]}
					activeProfessions={props.floorProfessions}
					managers={props.floorManagers}
					equipment={props.equipmentData}
					utilities={props.utilitiesData}
					floorId={props.chipsContainerSvgElement.floorId}
					title={props.floorNick}
				/>
			}
		>
			<div
				className={classes.personnelFloorEntitiesContainer}
				style={{ flexWrap: maxChipWidth > 20 ? 'wrap' : 'nowrap' }}
			>
				{props.floorManagers.map((mergedManager: IManagerMergedTag) => {
					return (
						<div className={classes.chipElement}>
							<ManagerChip
								key={`floor_${props.chipsContainerSvgElement.floorId}_${mergedManager.manager.name}`}
								chipBackground={mergedManager.manager.backgroundColor}
								chipText={mergedManager.manager.name}
								maxWidth={maxChipWidth}
								marginInlineStart={`${FLOOR_CHIPS_MARGIN}px`}
								height={'calc(100% - 4px)'}
								showTooltip={false}
							/>
						</div>
					);
				})}
				{props.floorProfessions.map((professionForFloor) => {
					const chipText: string = getProfessionDisplayTextFromDisplaySettings(
						professionForFloor,
						translationService,
						projectId
					);
					return (
						<div className={classes.chipElement}>
							<ChipWithNumber
								key={`floor_${props.chipsContainerSvgElement.floorId}_${professionForFloor._id}`}
								chipText={chipText}
								chipColor={getProfessionBackgroundColor(professionForFloor, projectId)}
								number={professionForFloor.activeTagsIds.length}
								maxWidth={maxChipWidth}
								fullHeightChip
								marginInlineStart={`${FLOOR_CHIPS_MARGIN}px`}
								showTooltip={false}
							/>
						</div>
					);
				})}
				{Object.entries(equipmentByType).map(([type, equipmentFamily]) => {
					return (
						<div className={classes.chipElement}>
							<EquipmentChip
								isSmall={numberOfChips >= FLOOR_CHIP_NUMBER_NORMAL_SIZE}
								type={type as EquipmentTypes}
								number={equipmentFamily.length}
							/>
						</div>
					);
				})}
				{Object.keys(groupUtilitiesByType).length > 0 &&
					Object.values(groupUtilitiesByType).map((utilities: IUtilityMergedTag[]) => {
						return (
							<div className={classes.chipElement}>
								<UtilitiesChipsContainer utilitiesForFloor={utilities} />
							</div>
						);
					})}
			</div>
		</TrusstorTooltip>
	);
};
