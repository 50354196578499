import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getFloorsList, getUsers } from '../store/thunks';
import { resetDatePicker } from '../store/slices/datePicker.slice';
import { resetFilteredFloorsList } from '../store/slices/floorsList.slice';
import { IRootState } from '../store/slices';
import { useWorkingProfessions } from './useWorkingProfessions.hook';
import { IDateRangesHookGetOptions } from '../interfaces';
import { useManagers } from './useManagers.hook';
import { resetSelectedProfessions } from '../store/slices/professions.slice';
import { defaultSelectedActivityGroupStatus } from '../store/slices/ActivityGroupStatuses.slice';
import { resetSelectedSequences } from '@store/slices/sequences.slice';

interface IFilterResetOptions {
	dontResetSelectedProfession?: boolean;
	shouldResetDatePicker?: boolean;
}
export function useFilters(
	{ useVisibleWeek, useOnlyStartDate, includeDeleted }: IDateRangesHookGetOptions,
	{ dontResetSelectedProfession = false, shouldResetDatePicker = true }: IFilterResetOptions = {}
) {
	const tz: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.tz);
	const projectId: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.projectId);

	const dispatch = useDispatch();

	useWorkingProfessions({ useVisibleWeek, useOnlyStartDate, includeDeleted });
	useManagers({ useVisibleWeek, useOnlyStartDate, includeDeleted });

	useEffect(() => {
		dispatch(getUsers(projectId));
		dispatch(getFloorsList(projectId));
		if (shouldResetDatePicker) {
			dispatch(resetDatePicker({ tz }));
		}
		return () => {
			dispatch(resetFilteredFloorsList());
			if (!dontResetSelectedProfession) {
				dispatch(resetSelectedProfessions());
			}
			dispatch(defaultSelectedActivityGroupStatus());
			dispatch(resetSelectedSequences());
		};
	}, [projectId, tz]);
}
