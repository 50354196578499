import React, { useState } from 'react';
import { map, orderBy } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { TrusstorBox } from '../Boxes/Containers/TrusstorBox';
import { HeaderBox } from '../Boxes/Containers/HeaderBox/HeaderBox';
import { SafetyAlertRow } from './SafetyAlertRow/SafetyAlertRow';
import { safetyOrange, safetyRed } from '../../assets/safetyIcons';
import { translationService } from '../../index';
import { SafetyEventDialog } from './SafetyEventDialog';
import { getProfessionDisplayText } from '../../utils/professions.utils';
import { updateCurrentlyVisibleEvents } from '../../store/slices/safetyEvents.slice';
import { SafetyEventStatuses } from '../../constants';
import { getSafetyViolationTypeTranslationVars } from '../../utils/safety.utils';
import { sendSafetyEvent_BI } from '../../utils/bi.utils';
import { BiSafetyEventsActionSource } from '../../constants/bi.constants';
import { LineDividerWithText } from '../Boxes/Containers/LineDividerWithText/LineDividerWithText';
import { IRootState } from '../../store/slices';
import { IMinimalSafetyEventForClient } from '../../interfaces/ISafetyEvent';
import { getProfessionBackgroundColor } from '@shared/utils/professions.utils';
import { selectProjectId } from '@store/slices/project.slice';
import { HTTPRequestStatuses } from '@shared/interfaces/HTTPRequestStatuses.enum';
import Lottie from 'react-lottie';
import AlertsLottieLoader from '@src/Components/DashboardSections/DashboardLoaders/TabListLoader.json';
import AlertsLottieLoaderRTL from '@src/Components/DashboardSections/DashboardLoaders/TabListLoaderRTL.json';
import { EmptyState } from '../Boxes/Containers/EmptyState';
import {
	DashboardTabEmptyState,
	EmptyStateResourcesEnum,
} from '@shared/components/DashboardTabEmptyState/DashboardTabEmptyState';

const useStyles = makeStyles((theme) => ({
	paddingHeader: {
		paddingLeft: 12,
	},
	loaderContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	scrollArrowContainer: {
		position: 'absolute',
		bottom: -25,
		zIndex: 1000,
		paddingTop: 4,
		width: '100%',
		alignSelf: 'center',
		display: 'flex',
		justifyContent: 'center',
		cursor: 'pointer',
		transition: 'all .3s ease',
	},
	rowsContainer: {
		width: '100%',
		paddingBottom: 4,
	},
	safetyContent: {
		padding: 16,
		height: '100%',
		width: '100%',
		overflowY: 'auto',
		overflowX: 'hidden',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginTop: 10,
	},
	arrowIcon: () => ({
		padding: theme.spacing(0.4),
	}),
}));

interface IAlertsProps {
	floorId?: string;
}

const Alerts = (props: IAlertsProps) => {
	const safetyEvents: IMinimalSafetyEventForClient[] = useSelector(
		(state: IRootState) => state.safetyEventsReducer.safetyEvents
	).filter((event) => (props.floorId ? event.floorId === props.floorId : true));
	const safetyEventsRequestStatus: HTTPRequestStatuses = useSelector(
		(state: IRootState) => state.safetyEventsReducer.safetyEventsRequestStatus
	);
	const isRtl: boolean = translationService.getIsRtl();
	const projectId: string = useSelector(selectProjectId)!;
	const tz: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.tz);
	const [safetyDialogOpen, setSafetyDialogOpen] = useState(false);
	const dispatch = useDispatch();
	const classes = useStyles();

	const handleSafetyDialogClose = () => {
		setSafetyDialogOpen(false);
	};

	const openSafetyDialog = (currentSafetyEvent) => {
		dispatch(
			updateCurrentlyVisibleEvents({
				visibleSafetyEvents: [currentSafetyEvent],
			})
		);
		setSafetyDialogOpen(true);
		sendSafetyEvent_BI(BiSafetyEventsActionSource.safetyViolationLog, currentSafetyEvent._id, 1);
	};
	const activeSafetyEvents = orderBy(
		safetyEvents.filter((event) => event.status === SafetyEventStatuses.active),
		[(event) => event.isCritical, (event) => event.timestampCreated],
		['desc', 'desc']
	);
	const closedSafetyEvents = orderBy(
		safetyEvents.filter((event) => event.status === SafetyEventStatuses.close),
		(event) => event.timestampClosed,
		['desc']
	);

	const mapSafetyAlerts = (safetyEvents: IMinimalSafetyEventForClient[]) => {
		return safetyEvents.length > 0 ? (
			map(safetyEvents, (alert) => {
				const translationVars = getSafetyViolationTypeTranslationVars(
					alert.trade?.tradeId,
					alert.safetyViolationType
				);
				return (
					<SafetyAlertRow
						key={`${alert._id}`}
						safetyEventText={translationService.get(alert.safetyViolationType, translationVars)}
						floorText={alert.floorNick!}
						professionDisplayText={getProfessionDisplayText(
							alert.profession! || alert.trade || alert.manager,
							true
						)}
						professionColor={
							(alert.profession && getProfessionBackgroundColor(alert.profession, projectId)) ||
							alert.trade?.backgroundColor ||
							alert.manager?.backgroundColor ||
							''
						}
						safetyIcon={alert.isCritical ? safetyRed : safetyOrange}
						alertTimestamp={alert.timestampCreated}
						click={() => openSafetyDialog(alert)}
						tz={tz}
					/>
				);
			})
		) : (
			<EmptyState
				text={translationService.get('noSafetyAlerts')}
				isSmall={true}
				data-testid={'safetyEmptyState'}
			/>
		);
	};

	if (safetyEventsRequestStatus === HTTPRequestStatuses.pending) {
		return (
			<div className={classes.loaderContainer}>
				<Lottie
					options={{
						loop: true,
						autoplay: true,
						animationData: isRtl ? AlertsLottieLoaderRTL : AlertsLottieLoader,
					}}
				/>
			</div>
		);
	}

	return (
		<React.Fragment>
			<TrusstorBox testId={'safetyContainer'}>
				<HeaderBox
					title={safetyEvents.length > 0 ? translationService.get('safetyLog') : ''}
					boldTitle
					customRootStyles={{
						overflow: 'auto',
						justifyContent: 'space-between',
						height: '100%',
						marginTop: '12px',
					}}
					customHeaderClass={classes.paddingHeader}
				>
					<div className={classes.safetyContent}>
						{safetyEvents.length > 0 ? (
							<div className={classes.rowsContainer}>
								<LineDividerWithText text={translationService.get('live')} />
								{mapSafetyAlerts(activeSafetyEvents)}
								<LineDividerWithText text={translationService.get('eventsLog')} />
								{mapSafetyAlerts(closedSafetyEvents)}
							</div>
						) : (
							<DashboardTabEmptyState resource={EmptyStateResourcesEnum.alerts} />
						)}
					</div>
				</HeaderBox>
			</TrusstorBox>
			{safetyDialogOpen && <SafetyEventDialog handleClose={handleSafetyDialogClose} />}
		</React.Fragment>
	);
};

export { Alerts };
