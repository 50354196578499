import React from 'react';
import classes from './styles.module.scss';
import { TrusstorIcon } from '@src/Components/TrusstorIcon/TrusstorIcon';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { useHistory } from 'react-router-dom';
import { translationService } from '@src/servicesInitializers';
import { IProfession } from '@shared/interfaces/IProfession';
import { getSpecialtyAndContractorDisplayText } from '@utils/professions.utils';
import { ContractorAnalysisAllTimeStats } from '@src/Components/Pages/Reports/ProfessionAnalysisReportPageV2/Components/ContractorAnalysisAllTimeStats/ContractorAnalysisAllTimeStats';
import { getTradeTranslation } from '@utils/translations.utils';
import classnames from 'classnames';
import { TrusstorIconButtonDeprecated } from '@shared/components/buttons/TrusstorIconButton/TrusstorIconButtonDeprecated';

interface IContractorAnalysisHeaderProps {
	profession: IProfession;
}

export const ContractorAnalysisHeader = (props: IContractorAnalysisHeaderProps) => {
	const history = useHistory();

	const handleBackClick = () => {
		history.goBack();
	};

	const isRtl: boolean = translationService.getIsRtl();

	return (
		<div className={classes.ContractorAnalysisHeader_container} data-testid="ContractorAnalysisHeader">
			<TrusstorIconButtonDeprecated
				iconElement={<TrusstorIcon iconName={isRtl ? IconNames.arrowRight : IconNames.arrowLeft} />}
				onClick={handleBackClick}
			/>
			<div className={classes.ContractorAnalysisHeader_middleSection}>
				<div
					className={classnames(classes.ContractorAnalysisHeader_title, {
						[classes.isRtl]: translationService.getIsRtl(),
					})}
				>
					<span>
						{`${
							getSpecialtyAndContractorDisplayText(props.profession) ||
							getTradeTranslation(props.profession)
						} `}
					</span>{' '}
					<span className={classes.light}>{translationService.get('contractorAnalysis_header_title')}</span>
				</div>
				<ContractorAnalysisAllTimeStats profession={props.profession} />
			</div>
			<div></div>
		</div>
	);
};
