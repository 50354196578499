import { IconPatch } from '../../../../shared/components/IconPatch/IconPatch';
import { IProfessionWithActiveTags } from '../../interfaces/IProfessionWithActiveTags';
import { IManagerMergedTag } from '../../interfaces/IManagerMergedTag';
import { IMergedEquipmentView } from '../../interfaces/IMergedEquipmentView';
import { ISvgElementPoint } from '../../interfaces/ISvgElementPoint';
import { ScaffoldingSides } from '../../interfaces/ScaffoldingSides.enum';
import { COLORS, SCAFFOLD_SIDES } from '../../constants';
import { compact, Dictionary, groupBy } from 'lodash';
import { makeStyles } from '@material-ui/core';
import React from 'react';
import { getProfessionBackgroundColor } from '@shared/utils/professions.utils';
import { selectProjectId } from '@store/slices/project.slice';
import { useSelector } from 'react-redux';
import { FloorViewEquipmentIcon } from '@src/Components/Equipment/FloorViewEquipmentIcon/FloorViewEquipmentIcon';
import { EquipmentTypes } from '@shared/constants/equipment.const';
import { IUtilityMergedTag } from '@interfaces/IUtilityMergedTag';
import { getChipBorderColorByUtilityType, getGroupByFunctionByUtilityType } from '@utils/utilities.utils';
import { UtilityTypes } from '@interfaces/UtilityTypes.enum';

const useStyles = makeStyles((theme) => ({
	patchesContainer: {
		position: 'absolute',
		opacity: 1,
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'center',
		justifyContent: 'center',
	},
}));

interface IFloorViewPatchesProps {
	activeProfessions: IProfessionWithActiveTags[];
	managers: IManagerMergedTag[];
	equipment: IMergedEquipmentView[];
	utilities: IUtilityMergedTag[];
	workersElement: ISvgElementPoint;
	areaId: string;
	scaffoldingSide?: ScaffoldingSides;
}

export const FloorViewAreaPatches = React.forwardRef((props: IFloorViewPatchesProps, ref: any) => {
	const projectId: string = useSelector(selectProjectId)!;
	const { activeProfessions, managers, equipment, workersElement, areaId, scaffoldingSide, ...otherProps } = props;
	const equipmentByType: Dictionary<IMergedEquipmentView[]> = groupBy(equipment, (eq) => eq.type);
	const utilitiesByType: Dictionary<IUtilityMergedTag[]> = groupBy(
		props.utilities,
		(typeUtilities) => typeUtilities.linkedUtility.type
	);
	const classes = useStyles();
	const isNorthSouthScaffold = scaffoldingSide === SCAFFOLD_SIDES.north || scaffoldingSide === SCAFFOLD_SIDES.south;
	const isEastWestScaffold = scaffoldingSide === SCAFFOLD_SIDES.east || scaffoldingSide === SCAFFOLD_SIDES.west;

	const managersWithValue: (IManagerMergedTag & { value: number }) | null =
		managers.length > 0 ? { ...managers[0], value: managers.length } : null;
	const equipmentWithValue: (IMergedEquipmentView & { value: number }) | null =
		equipment.length > 0 ? { ...equipment[0], value: equipment.length } : null;

	const entities = compact([...activeProfessions, managersWithValue, equipmentWithValue]);

	const ENTITY_SIDE_LENGTH = 17;
	const ENTITY_SIDE_PLUS_MARGIN_LENGTH = 25;
	let width = ENTITY_SIDE_PLUS_MARGIN_LENGTH * Math.sqrt(entities.length);
	let height = ENTITY_SIDE_PLUS_MARGIN_LENGTH * Math.sqrt(entities.length);
	if (isNorthSouthScaffold) {
		height = ENTITY_SIDE_LENGTH;
		width = ENTITY_SIDE_PLUS_MARGIN_LENGTH * entities.length;
	} else if (isEastWestScaffold) {
		height = ENTITY_SIDE_PLUS_MARGIN_LENGTH * entities.length;
		width = ENTITY_SIDE_LENGTH;
	}
	let workersElementLeft = workersElement.left ?? 0 - width / 2 + ENTITY_SIDE_PLUS_MARGIN_LENGTH / 5;
	let workersElementTop = workersElement.top ?? 0 - height / 2 + ENTITY_SIDE_PLUS_MARGIN_LENGTH / 5;

	if (entities.length >= 2) {
		workersElementLeft -= ENTITY_SIDE_LENGTH / 2;
	}

	if (entities.length > 2) {
		workersElementTop -= (entities.length * (ENTITY_SIDE_LENGTH / 2)) / 2;
	}

	return (
		<div
			{...otherProps}
			ref={ref}
			className={classes.patchesContainer}
			style={{
				height: height,
				width: width,
				left: workersElementLeft,
				top: workersElementTop,
			}}
		>
			{!!Object.entries(equipmentByType)?.length &&
				Object.entries(equipmentByType)?.map(([type, entities]) => (
					<FloorViewEquipmentIcon type={type as EquipmentTypes} elementsArray={entities} />
				))}

			{!!Object.entries(utilitiesByType)?.length &&
				Object.entries(utilitiesByType)?.map(([type, utilitiesOfType]) => {
					const utilitiesByProfession = groupBy(
						utilitiesOfType,
						getGroupByFunctionByUtilityType(type as UtilityTypes)
					);
					return Object.values(utilitiesByProfession).map((utilities) => {
						const chipBorderColor: string | undefined = getChipBorderColorByUtilityType(utilities[0]);
						return (
							<FloorViewEquipmentIcon
								type={type as EquipmentTypes}
								elementsArray={utilities}
								chipBorderColor={chipBorderColor}
							/>
						);
					});
				})}

			{!!activeProfessions.length &&
				activeProfessions.map((entity) => (
					<IconPatch
						color={getProfessionBackgroundColor(entity, projectId)}
						containerHeight={ENTITY_SIDE_LENGTH}
						containerWidth={ENTITY_SIDE_LENGTH}
						number={entity.activeTagsIds?.length}
						key={(entity as IProfessionWithActiveTags)._id}
						isSelected
						testId="workerIcon"
					/>
				))}

			{!!managers.length && (
				<IconPatch
					color={COLORS.white}
					containerHeight={ENTITY_SIDE_LENGTH}
					containerWidth={ENTITY_SIDE_LENGTH}
					isManager={true}
					number={managers.length}
					isSelected
					testId="managerIcon"
				/>
			)}
		</div>
	);
});
