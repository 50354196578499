import classes from '@src/Components/MainPageSections/FloorView/FloorWorkforceSection/styles.module.scss';
import { ArrowUpDown } from '@src/Components/WorkplanWidgetComponents/ArrowUpDown';
import React from 'react';
import { IWorkerMergedTag } from '@interfaces/IWorkerMergedTag';
import { translationService } from '@src/servicesInitializers';
import { IManagerMergedTag } from '@shared/interfaces/IManagerMergedTag';
import { FloorViewWorkersGroupedSection } from '@src/Components/MainPageSections/FloorView/FloorWorkforceSection/FloorProfessionSection/FloorViewWorkersGroupedSection';
import { FloorManagersSection } from '@src/Components/MainPageSections/FloorView/FloorWorkforceSection/FloorManagersSection/FloorManagersSection';
import { Dictionary, groupBy } from 'lodash';
import { hoistOperatorTradeId } from '@shared/constants/professions.constants';
import { IMergedEquipmentView } from '@interfaces/IMergedEquipmentView';
import { IUtilityMergedTag } from '@interfaces/IUtilityMergedTag';
import { FloorUtilitiesSection } from './FloorUtilitiesSection/FloorUtilitiesSection';
import { UtilityTypes } from '@interfaces/UtilityTypes.enum';
import { getUtilityNameByType } from './areaSection.utils';
import { FloorEquipmentSection } from '@shared/components/FloorEquipmentSection/FloorEquipmentSection';
import { EquipmentTypes } from '@shared/constants/equipment.const';
import { getGroupByFunctionByUtilityType } from '@utils/utilities.utils';
import { IScissorsLiftUtility } from '@interfaces/IScissorsLiftUtility';

interface IAreaSectionProps {
	equipments: IMergedEquipmentView[] | undefined;
	areaNick: string;
	managerTags?: IManagerMergedTag[];
	workerTags?: IWorkerMergedTag[];
	utilities?: IUtilityMergedTag[];
	isHoistFloor?: boolean;
	shouldHideExpandButton?: boolean;
}

export const AreaSection = (props: IAreaSectionProps) => {
	const [isExpanded, setIsExpanded] = React.useState<boolean>(true);
	const workerTagsByProfessions: Dictionary<IWorkerMergedTag[]> = groupBy(
		props.workerTags,
		(tag) => (tag as IWorkerMergedTag).profession?._id
	) as Dictionary<IWorkerMergedTag[]>;
	const hoistOperatorTags: IWorkerMergedTag[] | undefined = props.workerTags?.filter(
		(tag) => tag.profession?.tradeId === hoistOperatorTradeId
	);
	const equipmentByType: Dictionary<IMergedEquipmentView[]> = groupBy(props.equipments, (eq) => eq.type);
	const utilitiesByType: Dictionary<IUtilityMergedTag[]> = groupBy(
		props.utilities,
		(utility) => utility.linkedUtility.type
	);
	return (
		<>
			{props.shouldHideExpandButton && <div className={classes.areaSection} data-testid={'areaSection'}></div>}
			{!props.shouldHideExpandButton && (
				<div
					className={classes.areaSection}
					onClick={() => setIsExpanded((prev) => !prev)}
					data-testid={'areaSection'}
				>
					<ArrowUpDown isOpen={isExpanded} />
					{translationService.get('area') + ' ' + props.areaNick}
				</div>
			)}
			{isExpanded && (
				<div className={classes.professionSection} data-testid={'professionSection'}>
					{props.isHoistFloor && <FloorViewWorkersGroupedSection isHoistSection tags={hoistOperatorTags} />}
					{!!Object.values(workerTagsByProfessions).length &&
						Object.values(workerTagsByProfessions).map((tags) => {
							return <FloorViewWorkersGroupedSection profession={tags[0].profession} tags={tags} />;
						})}
					{props.managerTags?.length ? <FloorManagersSection tags={props.managerTags} /> : null}

					{!!Object.entries(equipmentByType).length &&
						Object.entries(equipmentByType).map(([type, equipment]) => (
							<FloorEquipmentSection type={type as EquipmentTypes} equipment={equipment} />
						))}
					{!!Object.entries(utilitiesByType).length &&
						Object.entries(utilitiesByType).map(([type, typeUtilities]) => {
							const utilitiesByProfession = groupBy(
								typeUtilities,
								getGroupByFunctionByUtilityType(type as UtilityTypes)
							);
							return Object.values(utilitiesByProfession).map((utilities) => {
								return (
									<FloorUtilitiesSection
										type={type as UtilityTypes}
										profession={(utilities[0]?.linkedUtility as IScissorsLiftUtility)?.profession}
										utilities={utilities}
										getUtilityName={getUtilityNameByType}
									/>
								);
							});
						})}
				</div>
			)}
		</>
	);
};
