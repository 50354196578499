import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TrusstorIconButton from '../../Buttons/TrusstorIconButton';
import SideBarItem from './SideBarItem/SideBarItem';
import { useOutsideClick } from '@src/hooks/custom.hooks';
import { MAIN_PATH, SIDE_BAR_BUTTONS } from '@src/constants';
import { accessLevelService, projectAccessLevelService, translationService } from '../../../index';
import {
	adminPanelAccess_BI,
	issuesTabAccess_BI,
	managersPageAccess_BI,
	notificationCenterAccess_BI,
	professionsPageAccess_BI,
	projectMonitorMainTabAccess_BI,
	usersPageAccess_BI,
} from '@utils/bi.utils';
import TrusstorLogoWhite from '@shared/assets/logos/trusstor_logo_white.svg';
import classes from './styles.module.scss';
import { SubSideBarItem } from './SubSideBarItem/SubSideBarItem';
import { useIntercom } from 'react-use-intercom';
import { IUser } from '@shared/interfaces/IUser';
import {
	changeOpenNotificationsTab,
	selectNotifications,
	selectNotificationsIsOpen,
} from '@store/slices/notifications.slice';
import { config } from '@src/config';
import { selectLoggedUserDetails } from '@store/slices/login.slice';
import { INotification } from '@shared/interfaces/INotification';
import { markAllNotificationsAsViewed } from '@store/thunks/notifications.thunks';
import { selectProjectId } from '@store/slices/project.slice';
import { IconColor, IconSize } from '@shared/components/TrusstorIconShared/TrusstorIconShared';
import { TrusstorIcon } from '@src/Components/TrusstorIcon/TrusstorIcon';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { UserSettingMenu } from '@src/Components/Bars/SideBar/UserSettingMenu/UserSettingMenu';
import classnames from 'classnames';
import { useWindowHightSmallerThenN } from '@src/hooks/window.hooks';
import { useIsFeatureFlagEnabled } from '@shared/hooks/useFeatureFlag';
import { FeatureFlagNamesEnum } from '@shared/constants/featureFlags.enum';

const SideBar = () => {
	const [displayExpandMenu, setDisplayExpandMenu] = useState<boolean>(false);
	const [displaySystemSubButtons, setDisplaySystemSubButtons] = useState<boolean>(false);
	const [displayUserMenu, setDisplayUserMenu] = useState<boolean>(false);
	const isNotificationSectionOpen: boolean = useSelector(selectNotificationsIsOpen);
	const notifications: INotification[] | undefined = useSelector(selectNotifications);
	const dispatch = useDispatch();
	const loggedUserDetails: IUser | undefined = useSelector(selectLoggedUserDetails);
	const projectId: string = useSelector(selectProjectId)!;
	const systemMenuRef = useRef(null);
	const expandMenuRef = useRef(null);
	const isSequenceProgressAnalysisEnabled: boolean = useIsFeatureFlagEnabled(
		FeatureFlagNamesEnum.sequenceProgressAnalysis
	);
	const hasAccessToMilestonesPage: boolean = useIsFeatureFlagEnabled(FeatureFlagNamesEnum.milestonesManagementPage);

	let systemMenuTimeoutId: NodeJS.Timeout;
	let expandMenuTimeoutId: NodeJS.Timeout;

	const toggleExpandMenu = () => {
		setDisplayExpandMenu(!displayExpandMenu);
	};

	const toggleSystemMenu = () => {
		setDisplaySystemSubButtons(!displaySystemSubButtons);
	};

	const handleExpandMenuMouseEnter = () => {
		clearAllStates();
		clearTimeout(expandMenuTimeoutId);
		setDisplayExpandMenu(true);
	};

	const handleExpandMenuMouseLeave = () => {
		expandMenuTimeoutId = setTimeout(() => {
			setDisplayExpandMenu(false);
		}, 100);
	};

	const handleSystemMenuMouseEnter = () => {
		clearAllStates();
		clearTimeout(systemMenuTimeoutId);
		setDisplaySystemSubButtons(true);
	};

	const handleSystemMenuMouseLeave = () => {
		systemMenuTimeoutId = setTimeout(() => {
			setDisplaySystemSubButtons(false);
		}, 100);
	};

	const sideBarAdminPanelClick = () => {
		adminPanelAccess_BI();
	};

	const sideBarProfessionListClick = () => {
		professionsPageAccess_BI();
	};

	const sideBarRolesListClick = () => {
		managersPageAccess_BI();
	};

	const sideBarUsersPageClick = () => {
		usersPageAccess_BI();
	};

	const notificationPageClick = () => {
		setDisplaySystemSubButtons(false);
		setDisplayExpandMenu(false);
	};

	const handleIssuesClick = () => {
		issuesTabAccess_BI();
	};

	const handleNotificationsClick = (e: React.MouseEvent) => {
		e.stopPropagation();
		setDisplayExpandMenu(false);
		if (!isNotificationSectionOpen) {
			notificationCenterAccess_BI();
		} else {
			dispatch(markAllNotificationsAsViewed(projectId, loggedUserDetails!.username));
		}
		dispatch(changeOpenNotificationsTab());
	};

	const handleMainPageClick = () => {
		projectMonitorMainTabAccess_BI();
	};

	const clearAllStates = useCallback(() => {
		setDisplayExpandMenu(false);
		setDisplaySystemSubButtons(false);
	}, []);

	const { boot, shutdown, show: showIntercom, hide: hideIntercom, isOpen: isIntercomOpen } = useIntercom();

	useEffect(() => {
		if (!loggedUserDetails) {
			return;
		}

		boot({
			alignment: translationService.getIsRtl() ? 'left' : 'right',
			email: loggedUserDetails.email,
			name: loggedUserDetails.name,
			userId: loggedUserDetails._id,
			customLauncherSelector: '#my-custom_launcher',
			customAttributes: { userRole: loggedUserDetails.permissions.roleType },
		});
		return () => {
			shutdown();
		};
	}, [
		loggedUserDetails?.email,
		loggedUserDetails?.name,
		loggedUserDetails?._id,
		loggedUserDetails?.permissions.roleType,
	]);

	useOutsideClick(systemMenuRef, setDisplaySystemSubButtons, false);
	useOutsideClick(expandMenuRef, setDisplayExpandMenu, false);

	const notViewedNotifications: INotification[] = !notifications
		? []
		: notifications.filter((notification: INotification) => !notification.isViewed);

	const getNotificationsIcon = () => (
		<div
			className={classnames(classes.iconContainer, {
				[classes.isSelected]: isNotificationSectionOpen,
			})}
		>
			{notViewedNotifications.length > 0 && (
				<div className={classes.dot}>
					<span className={classes.dotText}>{notViewedNotifications.length}</span>
				</div>
			)}
			<TrusstorIcon
				iconName={IconNames.bell}
				size={IconSize.LARGE}
				color={isNotificationSectionOpen ? IconColor.White : IconColor.Grey400}
			/>
		</div>
	);

	const userHasAccessToInvestigationReport: boolean = accessLevelService.hasAccess('investigationReportAccess');
	const shouldDisplayExpandMenu: boolean = useWindowHightSmallerThenN(800);
	return (
		<div className={classes.list}>
			<img src={TrusstorLogoWhite} className={classes.logo} alt="logo" />
			<div className={classes.sideBarItem}>
				<SideBarItem
					path={`${MAIN_PATH}`}
					name="main"
					iconName={IconNames.home}
					text={translationService.get('sideBar_mainView')}
					handleClick={handleMainPageClick}
				/>
			</div>
			{projectAccessLevelService.hasAccess('workplanPage') && (
				<div className={classes.sideBarItem}>
					<SideBarItem
						name="workplan"
						path={`/workplan`}
						iconName={IconNames.workplan}
						text={translationService.get('sideBar_workplan')}
					/>
				</div>
			)}
			{config.showIssues && projectAccessLevelService.hasAccess('issuesPage') && (
				<div className={classes.sideBarItem}>
					<SideBarItem
						name="issues"
						iconName={IconNames.issues}
						path={`/issues`}
						text={translationService.get('sideBar_issues')}
						handleClick={handleIssuesClick}
					/>
				</div>
			)}
			{userHasAccessToInvestigationReport && projectAccessLevelService.hasAccess('analysisCenterPages') && (
				<div className={classes.sideBarItem}>
					<SideBarItem
						name="analysisCenter"
						path={`/analysisCenter`}
						iconName={IconNames.chart2}
						text={translationService.get('sideBar_analysisCenter')}
					/>
				</div>
			)}
			{isSequenceProgressAnalysisEnabled &&
				userHasAccessToInvestigationReport &&
				projectAccessLevelService.hasAccess('progressAnalysisNavbarItem') &&
				accessLevelService.hasAccess('projectProgressAnalysisAccess') && (
					<div className={classes.sideBarItem}>
						<SideBarItem
							name="analysis"
							path={`/analysis`}
							iconName={IconNames.chart2}
							text={translationService.get('sideBar_progressAnalysisReport')}
						/>
					</div>
				)}
			{accessLevelService.hasAccess('systemPagesAccess') && (
				<div
					onMouseEnter={handleSystemMenuMouseEnter}
					onMouseLeave={handleSystemMenuMouseLeave}
					className={`${classes.menuContainer}`}
					ref={systemMenuRef}
				>
					<SideBarItem
						name="system"
						iconName={IconNames.settings}
						handleClick={toggleSystemMenu}
						showSubMenu={displaySystemSubButtons}
						text={translationService.get('sideBar_systemManagement')}
					>
						{projectAccessLevelService.hasAccess('tagsPage') && (
							<SubSideBarItem
								iconName={IconNames.tag}
								text={translationService.get(SIDE_BAR_BUTTONS.tagsPage)}
								path={`/system/tags`}
								handleClick={sideBarAdminPanelClick}
							/>
						)}
						<SubSideBarItem
							iconName={IconNames.worker}
							text={translationService.get(SIDE_BAR_BUTTONS.professionsList)}
							path={`/system/professions`}
							handleClick={sideBarProfessionListClick}
						/>
						{projectAccessLevelService.hasAccess('managersListPage') && (
							<SubSideBarItem
								iconName={IconNames.manager}
								text={translationService.get(SIDE_BAR_BUTTONS.rolesList)}
								path={`/system/management`}
								handleClick={sideBarRolesListClick}
							/>
						)}
						<SubSideBarItem
							iconName={IconNames.user}
							text={translationService.get(SIDE_BAR_BUTTONS.usersPage)}
							path={`/system/users`}
							handleClick={sideBarUsersPageClick}
						/>
						<SubSideBarItem
							iconName={IconNames.bell}
							text={translationService.get(SIDE_BAR_BUTTONS.notifications)}
							path={`/system/notifications`}
							handleClick={notificationPageClick}
						/>
						{hasAccessToMilestonesPage && (
							<SubSideBarItem
								iconName={IconNames.milestone}
								text={translationService.get(SIDE_BAR_BUTTONS.milestones)}
								path={`/system/milestones`}
							/>
						)}
					</SideBarItem>
				</div>
			)}

			<div
				onMouseEnter={handleExpandMenuMouseEnter}
				onMouseLeave={handleExpandMenuMouseLeave}
				ref={expandMenuRef}
				className={classes.lastItemContainer}
			>
				{shouldDisplayExpandMenu ? (
					<SideBarItem
						name="expandMenu"
						iconName={IconNames.menu}
						isComponentSelected={displayExpandMenu}
						handleClick={toggleExpandMenu}
						showSubMenu={displayExpandMenu}
						text={translationService.get('sideBar_expandMoreMenu')}
					>
						{config.showNotifications && (
							<SubSideBarItem
								iconName={IconNames.bell}
								text={translationService.get('sideBar_notifications')}
								path={''}
								handleClick={handleNotificationsClick}
							/>
						)}
						<div>
							<SubSideBarItem
								iconName={IconNames.helpCircle}
								text={translationService.get('sideBar_help')}
								path={''}
								handleClick={(e) => {
									e.stopPropagation();
									setDisplayExpandMenu(false);
									isIntercomOpen ? hideIntercom() : showIntercom();
								}}
							/>
						</div>
					</SideBarItem>
				) : (
					<>
						{config.showNotifications && (
							<TrusstorIconButton
								id="sideBarNotificationButton"
								imageSource={getNotificationsIcon()}
								styleClass={classnames(classes.iconRootClass, {
									[classes.isSelected]: isNotificationSectionOpen,
								})}
								click={handleNotificationsClick}
							/>
						)}
						<TrusstorIconButton
							id={'my-custom_launcher'}
							imageSource={
								<div className={classnames(classes.iconContainer)}>
									<TrusstorIcon
										iconName={IconNames.helpCircle}
										size={IconSize.LARGE}
										color={IconColor.Grey400}
									/>
								</div>
							}
							styleClass={classes.sideBarIcon}
						/>
					</>
				)}
			</div>
			<TrusstorIconButton
				click={() => setDisplayUserMenu(true)}
				imageSource={
					<div
						className={classnames(classes.iconContainer, {
							[classes.isSelected]: displayUserMenu,
						})}
					>
						<TrusstorIcon
							iconName={IconNames.userV2}
							size={IconSize.LARGE}
							color={displayUserMenu ? IconColor.White : IconColor.Grey400}
						/>
					</div>
				}
				styleClass={classnames(classes.iconRootClass, {
					[classes.isSelected]: displayUserMenu,
				})}
			/>
			{displayUserMenu && <UserSettingMenu setDisplayUserMenu={setDisplayUserMenu} />}
		</div>
	);
};

export { SideBar };
