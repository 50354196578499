import React, { useEffect, useState } from 'react';
import { TrusstorBox } from '../../../Boxes/Containers/TrusstorBox';
import { Grid, Typography } from '@material-ui/core';
import { translationService } from '../../../../index';
import { ProfessionsListTable } from '../../../ProfessionsListComponents/ProfessionsTable';
import { IProfession } from '../../../../interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../../../store/slices';
import { useWorkingProfessions } from '../../../../hooks/useWorkingProfessions.hook';
import { getStaticTagsStream, stopGettingAdminPanelTags } from '../../../../store/thunks';
import { removeAllProfessions } from '../../../../store/slices/professions.slice';
import classes from './styles.module.scss';
import { RoleType } from '@shared/interfaces/RoleType.enum';
import { getTradeGroupTranslation, getTradeTranslation } from '@utils/translations.utils';
import { useRenderMonitoring } from '@shared/hooks/monitoring.hooks';

const filterProfessionsBySearchInput = (professions: IProfession[], searchInput: string): IProfession[] => {
	const lowerCaseSearchInput: string = searchInput.toLowerCase();
	return professions.filter(
		(profession: IProfession) =>
			getTradeTranslation(profession).toLowerCase().includes(lowerCaseSearchInput) ||
			getTradeGroupTranslation(profession).toLowerCase().includes(lowerCaseSearchInput) ||
			profession.specialty?.toLowerCase().includes(lowerCaseSearchInput) ||
			profession.contractor?.toLowerCase().includes(lowerCaseSearchInput)
	);
};

const ProfessionsListPage = () => {
	useRenderMonitoring('ProfessionsListPage');
	const [visibleProfessions, setVisibleProfessions] = useState<IProfession[]>([]);
	const [searchInput, setSearchInput] = useState<string>('');
	const workingProjectId: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.projectId);
	const isAdmin: boolean =
		useSelector((state: IRootState) => state.loginReducer.loggedUser?.userDetails?.permissions.roleType) ===
		RoleType.TRUSSTOR_ADMIN;
	const professions: IProfession[] = useSelector((state: IRootState) => state.professionsReducer.professions);
	const dispatch = useDispatch();

	useEffect(() => {
		setVisibleProfessions(professions);
	}, [professions]);

	//filter
	useEffect(() => {
		setVisibleProfessions(filterProfessionsBySearchInput(professions, searchInput));
	}, [searchInput, professions]);

	useWorkingProfessions({ withoutDateRanges: true, includeInactive: true });

	useEffect(() => {
		dispatch(getStaticTagsStream(workingProjectId, isAdmin));
		setVisibleProfessions(professions);
		return () => {
			stopGettingAdminPanelTags();
			dispatch(removeAllProfessions());
		};
	}, [workingProjectId, isAdmin]);

	return (
		<Grid container direction="column" className={classes.pageContainer}>
			<Grid item container className={classes.titleContainer}>
				<TrusstorBox customStyleClass={classes.pageTitleBox}>
					<Typography className={classes.title}>{translationService.get('professionsList')}</Typography>
				</TrusstorBox>
			</Grid>
			<div className={classes.pageContent}>
				<Grid item container direction="column" className={classes.pageTableContainer}>
					<ProfessionsListTable
						professions={visibleProfessions}
						onSearchInputChange={setSearchInput}
						isSearchInputExist={!!searchInput}
					/>
				</Grid>
			</div>
		</Grid>
	);
};

export { ProfessionsListPage };
