import React from 'react';
import classes from './styles.module.scss';
import { ISequenceItemProgressPeriodOverallReport } from '@shared/interfaces/ISequenceItemProgressPeriodOverallReport';
import { translationService } from '@src/servicesInitializers';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import moment from 'moment/moment';
import { renderToString } from 'react-dom/server';
import { useSelector } from 'react-redux';
import { selectWorkingProject } from '@store/slices/project.slice';
import { IProject } from '@interfaces/index';

interface IGraphProps {
	overallProgressSummary: ISequenceItemProgressPeriodOverallReport;
}

interface IWeekPercentage {
	date: Date;
	cumulativePercentage: number;
	count: number;
}

function calculateAccumulativeWeekPercentages(data: ISequenceItemProgressPeriodOverallReport): IWeekPercentage[] {
	const weeksProgress = data.totalCountPerWeek
		.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
		.reduce((acc, week) => {
			return acc + week.count;
		}, 0);
	const initialPercentage: number = ((data.overallProgressCount - weeksProgress) / data.totalAmount) * 100;
	const weekPercentages: IWeekPercentage[] = [];
	const totalAmount: number = data.totalAmount;
	let cumulativePercentage: number = initialPercentage;

	data.totalCountPerWeek.forEach((week) => {
		const { count, date } = week;

		if (count === 0) {
			weekPercentages.push({ date, count, cumulativePercentage });
			return;
		}
		cumulativePercentage += (count / totalAmount) * 100;
		weekPercentages.push({ date, count, cumulativePercentage });
	});

	return weekPercentages;
}

const getFakeDemoProgressData = (realData: number[]): number[] => {
	const fakeDelta: number[] = [2.5, 2.3, 1.2, 0.8, 1.0];
	const fakeData: number[] = [];
	// insert fake data from the start of the array
	for (let i = realData.length - 1; i > 0; i--) {
		const fakeValue: number = i >= realData.length - 2 ? realData[i] : fakeData[0] - fakeDelta[i - 1];
		fakeData.unshift(fakeValue);
	}
	return fakeData;
};

export const TotalProgressGraph = (props: IGraphProps) => {
	const project: IProject = useSelector(selectWorkingProject)!;
	const isDemoProject: boolean = !!project.isDemo;
	const data: IWeekPercentage[] =
		(props.overallProgressSummary && calculateAccumulativeWeekPercentages(props.overallProgressSummary)) || [];
	const dataComulativePercentage: number[] = data.map((data) => data.cumulativePercentage) || [];
	const seriesData: number[] = isDemoProject
		? getFakeDemoProgressData(dataComulativePercentage)
		: dataComulativePercentage || [];
	const isRtl: boolean = translationService.getIsRtl();
	const series = [
		{
			type: 'area',
			name: translationService.get('progressPercentageOverTime'),
			data: isRtl ? seriesData.reverse() : seriesData,
		},
	];

	const totalCountPerWeek = props.overallProgressSummary?.totalCountPerWeek.map((data) => data.date) || [];
	const categories = isRtl ? totalCountPerWeek.reverse() : totalCountPerWeek;

	const options: ApexOptions = {
		chart: {
			type: 'area',
			toolbar: {
				show: false,
			},
			selection: {
				enabled: false,
			},
			zoom: {
				enabled: false,
			},
		},
		markers: {
			size: 0,
		},
		colors: ['#2EBFFD'],
		dataLabels: {
			enabled: false,
		},
		stroke: {
			curve: 'straight',
			width: 3,
		},
		fill: {
			type: 'gradient',
			gradient: {
				shade: 'light',
				type: 'vertical',
				shadeIntensity: 0,
				opacityFrom: 0.7,
				opacityTo: 0.2,
			},
		},
		xaxis: {
			tooltip: {
				enabled: false,
			},
			categories,
			labels: {
				style: {
					cssClass: 'projectProgressGraphYAxisLabel',
				},
				formatter: (date: string) => moment(date).format('MMM DD'),
			},
		},

		yaxis: {
			labels: {
				style: {
					cssClass: 'projectProgressGraphYAxisLabel',
				},
				formatter: (count: number) => {
					const roundedCount: string = count.toFixed(0).toString();
					return roundedCount + '%';
				},
			},
		},

		tooltip: {
			custom: function ({ series, seriesIndex, dataPointIndex }) {
				const roundedCount: string = series[seriesIndex][dataPointIndex].toFixed(1).toString();
				return renderToString(
					<div className={classes.tooltip}>
						<div className={classes.topText}>{roundedCount + ' %'}</div>
					</div>
				);
			},
		},
	};

	if (props.overallProgressSummary.totalAmount === 0) {
		return null;
	}

	return (
		<div className={classes.TotalProgressGraph_container} data-testid="TotalProgressGraph">
			<div className={classes.title}>{translationService.get('progressPercentageOverTime')}</div>
			<Chart options={options} series={series} type="line" height={250} />
		</div>
	);
};
