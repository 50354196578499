import { IProject } from '../interfaces/IProject';

class ProjectService {
	static chosenProject = 'chosenProject';

	setChosenProject(project) {
		sessionStorage.setItem(ProjectService.chosenProject, JSON.stringify(project));
	}

	removeChosenProject() {
		sessionStorage.removeItem(ProjectService.chosenProject);
	}

	getChosenProject(): IProject | undefined {
		return JSON.parse(sessionStorage.getItem(ProjectService.chosenProject) as any) as IProject;
	}
}

export { ProjectService };
