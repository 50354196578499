import { kebabCase, map, split } from 'lodash';
import { IFloorSvgPoints } from '../interfaces/IFloorSvgPoints';
import { ISvgElementPoint } from '../interfaces/ISvgElementPoint';

export const getSvgElement = (svgId: string): HTMLElement => {
	const svg: HTMLElement | null = document.getElementById(svgId);

	if (!svg) {
		throw new Error(`There is no svg element with id of '${svgId}'`);
	}

	return svg;
};

export const cleanId = (id: string): string => {
	const idCollection = split(id, '_');
	return idCollection[0];
};

export const setCleanIds = (elements: NodeListOf<Element>): Element[] => {
	return map(elements, (element) => {
		element.setAttribute('id', cleanId(element.id));
		return element;
	});
};

export const getElementById = (id: string, parentElementId?: string): HTMLElement | null => {
	if (parentElementId) {
		const parentElement: HTMLElement | null = document.getElementById(parentElementId);
		if (!parentElement) return null;
		return parentElement.querySelector(`#${id}`);
	}

	return document.getElementById(id);
};

export const setElementStyle = (id: string, newStyle: any, parentElementId?: string): void => {
	const element: HTMLElement | null = getElementById(id, parentElementId);

	if (!element) {
		return;
	}

	Object.entries(newStyle).forEach(([key, value]: [string, any]) => {
		element.style[kebabCase(key)] = value;
	});
};

export const getFloorSvgPoints = (svgId: string): IFloorSvgPoints => {
	const svg: HTMLElement = getSvgElement(svgId);

	const areas: Element[] = setCleanIds(svg.querySelectorAll(`*[id*="area"]`));
	const workers: Element[] = setCleanIds(svg.querySelectorAll(`*[id*="workers"]`));

	const areasPointsArray: ISvgElementPoint[] = map(areas, (element) => {
		return {
			type: 'area',
			id: element.id,
		};
	});
	const workersPointsArray: ISvgElementPoint[] = map(workers, (element) => {
		return {
			type: 'workers',
			id: element.id,
			left: element.getBoundingClientRect().left - svg.getBoundingClientRect().left,
			top: element.getBoundingClientRect().top - svg.getBoundingClientRect().top,
		};
	});

	return {
		areasPointsArray,
		workersPointsArray,
	};
};

export const getAreasSvgPoints = (parentId: string): ISvgElementPoint[] => {
	const parentElement: HTMLElement | null = getElementById(parentId);
	if (!parentElement) return [];
	const areas: Element[] = setCleanIds(parentElement.querySelectorAll(`*[id*="area"]`));

	return map(areas, (element) => {
		return {
			type: 'area',
			id: element.id,
		};
	});
};

export const getAreaIdFromElementId = (elementId: string): string => {
	if (elementId.startsWith('f')) {
		return elementId.split('area')[1];
	}
	return elementId.replace('area', '');
};

export const paintSelectedAreas = (selectedAreasIds: string[], selectedStyle: {}, svgId: string) => {
	const areasSvgPoints: ISvgElementPoint[] = getAreasSvgPoints(svgId);
	areasSvgPoints.forEach((areaSvgPoint) => {
		const svgPointId: string | undefined = areaSvgPoint.id;
		if (!svgPointId) return;
		const areaId: string = getAreaIdFromElementId(svgPointId);
		const isAreaSelected: boolean = selectedAreasIds.includes(areaId);
		setElementStyle(
			svgPointId,
			isAreaSelected
				? selectedStyle
				: {
						opacity: '',
						fillOpacity: '',
						fill: '',
						stroke: '',
						strokeWidth: '',
					},
			svgId
		);
	});
};
