import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SORT_ORDER } from '@shared/constants/constants';
import { entitiesProperties } from '../../constants';
import { filterArrayByAnotherArray } from '../../utils/array.util';
import { IAreasListReducerState } from '@interfaces/IAreasListReducerState';
import { sortByAreas } from '@shared/utils/sort.utils';
import { IBaseAreaBaseFloor } from '@shared/interfaces/IBaseAreaBaseFloor';

const initialState: IAreasListReducerState = {
	areasList: [],
	areasFilterList: [],
	visibleAreasList: [],
};

const areaListSlice = createSlice({
	name: 'areasList',
	initialState,
	reducers: {
		updateAreasList: (state: IAreasListReducerState, action: PayloadAction<{ areas: IBaseAreaBaseFloor[] }>) => {
			const areasList: IBaseAreaBaseFloor[] = sortByAreas(action.payload.areas, SORT_ORDER.DESCENDING);
			state.visibleAreasList = areasList;
			state.areasList = areasList;
			state.areasFilterList = [];
		},
		setAreasListFilter: (
			state: IAreasListReducerState,
			action: PayloadAction<{ filterList: IBaseAreaBaseFloor[] }>
		) => {
			state.visibleAreasList = filterArrayByAnotherArray(
				state.areasList,
				action.payload.filterList,
				entitiesProperties.areaId
			);
			state.areasFilterList = action.payload.filterList;
		},
		resetFilteredAreasList: (state: IAreasListReducerState) => {
			state.areasFilterList = [];
			state.visibleAreasList = state.areasList;
		},
	},
});

export const { resetFilteredAreasList, setAreasListFilter, updateAreasList } = areaListSlice.actions;
export default areaListSlice.reducer;
