import React, { useCallback, useEffect, useRef, useState } from 'react';
import classes from './styles.module.scss';
import { requestService, translationService } from '../../../index';
import { assignmentKinds } from '../../../constants';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import { cloneDeep } from 'lodash';
import { CertificateTagRow } from './CertificateTagRow';
import { ITagCertificate } from '../../../interfaces/ITagCertificate';
import { ICertificateValidationError } from '../../../interfaces/ICertificateValidationError';
import GroupedAutoCompleteDropdown from '../../../../../shared/components/Dropdowns/GroupedAutoCompleteDropdown/GroupedAutoCompleteDropdown';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../store/slices';
import { convertDateObjectToServerFormat, getTimezoneStartOfDate } from '../../../utils/dateUtils';
import { ITagEditedCertificate } from '../../../interfaces/ITagEditedCertificate';
import { DateSelectorTrusstorInput } from '@src/Components/DateSelector/DateSelectorTrusstorInput/DateSelectorTrusstorInput';
import classnames from 'classnames';

interface ICertificationSectionProps {
	certificates: ITagCertificate[];
	currentCertificate: ITagEditedCertificate | null;
	setCurrentCertificate: (certificate: ITagEditedCertificate | null | ((c: any) => any)) => void;
	setCertificates: (certificates: ITagCertificate[]) => void;
	isError: ICertificateValidationError | null;
	setCertificateError: (error: ICertificateValidationError | null) => void;
	checkCurrentCertificateValidation: () => boolean;
	selectedTagKind: string;
}

const CertificateSection = (props: ICertificationSectionProps) => {
	const projectId: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.projectId);
	const tz: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.tz);
	const bottomContainerEl = useRef<HTMLHeadingElement>(null);

	const [certificatesList, setCertificatesList] = useState([] as ITagCertificate[]);

	const isEmptyCertificates: boolean =
		!props.certificates.length && props.certificates.length === 0 && !props.currentCertificate;

	useEffect(() => {
		const getCertificatesList = async (): Promise<void> => {
			const certificatesList = await requestService.get(`/projectConfig/certificates?projectId=${projectId}`);
			setCertificatesList(certificatesList);
		};

		getCertificatesList();
	}, [projectId]);

	const resetCurrentCertificate = useCallback((): void => {
		props.setCurrentCertificate({
			name: '',
			expirationDate: null,
		});
		props.setCertificateError(null);
	}, []);

	const removeCertificateByName = useCallback(
		(name: string): void => {
			props.setCertificates(props.certificates.filter((certificate) => certificate.name !== name));
		},
		[props.certificates]
	);

	const updateCertificateDateByCertificateId = useCallback(
		(name: string, date: Date): void => {
			const certificatesClone: ITagCertificate[] = cloneDeep(props.certificates);
			const updatedCertificate: ITagEditedCertificate | undefined = certificatesClone.find(
				(certificate) => certificate.name === name
			);
			if (updatedCertificate) {
				updatedCertificate.expirationDate = date;
			}
			props.setCertificates(certificatesClone);
		},
		[props.certificates]
	);

	const scrollDown: (ref: any) => void = (ref) => {
		setTimeout(() => {
			ref.current.scrollIntoView({ behavior: 'smooth' });
		}, 0);
	};

	const addAnotherCertificate = (): void => {
		if (!props.checkCurrentCertificateValidation()) {
			if (props.currentCertificate) {
				props.setCertificates([...props.certificates, cloneDeep(props.currentCertificate as ITagCertificate)]);
				resetCurrentCertificate();
			}
		}
		if (!props.currentCertificate) {
			resetCurrentCertificate();
		}
		scrollDown(bottomContainerEl);
	};

	const onCertificateNameChange = (value: ITagCertificate | null): void => {
		props.setCurrentCertificate((prevCertificate: ITagCertificate) => ({
			...prevCertificate,
			name: value?.name,
		}));
	};

	const onCertificateExpirationDateChange = (date: Date): void => {
		props.setCurrentCertificate((prevCertificate: ITagCertificate) => ({
			...prevCertificate,
			expirationDate: date,
		}));
	};

	return (
		<>
			<div className={classes.certificateSectionTitle}> {translationService.get('certificates')}</div>
			<div className={classes.certificateSection}>
				<div
					className={`${classes.certificateSectionContent} ${
						props.selectedTagKind === assignmentKinds.manager ? classes.managerMaxHeight : ''
					}`}
				>
					<div className={classes.scrollContainer}>
						{props.certificates.length > 0 &&
							props.certificates.map((certificate) => {
								return (
									<CertificateTagRow
										certificate={certificate}
										key={certificate.name}
										updateCertificateDateByCertificateId={updateCertificateDateByCertificateId}
										removeCertificateByName={removeCertificateByName}
									/>
								);
							})}
						{props.currentCertificate && (
							<div className={classes.certificateRow}>
								<div className={classes.certificateNameSection}>
									<div className={classes.certificateDropdown}>
										<GroupedAutoCompleteDropdown
											allowFreeText
											required
											value={props.currentCertificate}
											placeholder={translationService.get('certificateName')}
											optionKey={'name'}
											optionGroupProperty={'groupName'}
											optionIsCommonProperty={'isCommonlyUsed'}
											commonGroupName={translationService.get('commonForSstCard')}
											getOptionLabel={(option: ITagCertificate) => option.name || ''}
											options={certificatesList}
											isError={!!props.isError?.name}
											errorText={props.isError?.name}
											onChange={onCertificateNameChange}
											translationService={translationService}
											testId={'certificateNameDropdown'}
										/>
									</div>
								</div>
								<DateSelectorTrusstorInput
									label={translationService.get('expirationDate')}
									selectedDate={
										props.currentCertificate.expirationDate
											? new Date(props.currentCertificate.expirationDate)
											: null
									}
									minDate={new Date()}
									handleTimeChange={onCertificateExpirationDateChange}
									required
									testId={'certificateExpirationDate'}
									hideIcon
									isError={!!props.isError?.date}
									errorText={translationService.get('pleaseSelectExpirationDate')}
								/>
								<div
									className={classes.exitCertificate}
									onClick={() => props.setCurrentCertificate(null)}
									data-testid={'exitCertificateButton'}
								>
									<HighlightOffRoundedIcon />
								</div>
							</div>
						)}
						<div
							className={classnames(classes.addCertificateButton)}
							data-testid={'addCertificateButton'}
							onClick={isEmptyCertificates ? resetCurrentCertificate : addAnotherCertificate}
						>
							+ {translationService.get('addCertificate')}
						</div>
						<div ref={bottomContainerEl}> </div>
					</div>
				</div>
			</div>
		</>
	);
};

export { CertificateSection };
