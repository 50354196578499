import React, { useCallback, useContext, useEffect } from 'react';
import { IMatrixTable } from './interfaces/IMatrixTable';
import { IMatrixColHeader } from './Matrix';
import { compact, isEqual, sortBy } from 'lodash';
import { reorderItemInList } from '../../utils/array.util';
import { getFilteredMatrixColumnIndexes } from '@utils/matrix.utils';
import { IProfession } from '@shared/interfaces/IProfession';
import { useSelector } from 'react-redux';
import { IRootState } from '@store/slices';
import { WorkplanContext } from '@src/Components/Pages/Workplan/WorkPlan';
import { ProgressTrackerTabContext } from '@src/Components/Pages/Workplan/ProgressTrackerTab/ProgressTrackerTab';
import { ISequenceItem } from '@interfaces/ISequenceItem';
import { countAreaSequenceItemsDoneFromSequenceItem } from '@utils/sequence.utils';

export const useMatrixHeaders = (originalTables: IMatrixTable[], showToc: boolean = false) => {
	const { hasProfessionFilter, sequenceItems, isTableFinishToRender, areaSequenceItemsByFloor } =
		useContext(WorkplanContext)!;
	const { showOnlyStarred } = useContext(ProgressTrackerTabContext)!;
	const [headers, setHeaders] = React.useState<IMatrixColHeader[]>([]);
	const [tables, setTables] = React.useState<IMatrixTable[]>(originalTables);
	const [colIndexesToShow, setColIndexesToShow] = React.useState<number[] | undefined>();
	const originalColHeadersRef = React.useRef<IMatrixColHeader[]>([]);
	const isDraggingRef = React.useRef<boolean>(false);
	const startDragIndexRef = React.useRef<number | undefined>(undefined);
	const startDragHeaderIdRef = React.useRef<string | undefined>(undefined);
	const headerIdsOrderRef = React.useRef<string[]>([]);
	const visibleProfessions: IProfession[] = useSelector(
		(state: IRootState) => state.professionsReducer.visibleProfessions
	);

	useEffect(() => {
		headerIdsOrderRef.current = headers.map((header) => header.id);
	}, [headers]);

	const getMatchingUpdatedHeaders = (
		headers: IMatrixColHeader[],
		colHeaders: IMatrixColHeader[]
	): IMatrixColHeader[] =>
		headers.map((header) => {
			const matchingHeader = colHeaders.find((colHeader) => colHeader.id === header.id);
			if (matchingHeader) {
				return matchingHeader;
			}

			return header;
		});

	useEffect(() => {
		const tablesColHeaders = sequenceItems.map((sequenceItem: ISequenceItem) => {
			return {
				isStarred: !!sequenceItem.isStarred,
				sequenceItemId: sequenceItem._id,
				id: sequenceItem._id,
				...sequenceItem,
				...(!isTableFinishToRender
					? {}
					: countAreaSequenceItemsDoneFromSequenceItem(
							sequenceItem,
							Object.values(areaSequenceItemsByFloor!).flat()
						)),
			};
		});

		const originalSortedColHeadersIds: string[] = originalColHeadersRef.current.map((header) => header.id).sort();
		const currentSortedColHeadersIds: string[] = tablesColHeaders.map((header) => header.id).sort();
		const isColHeadersChanged: boolean = !isEqual(originalSortedColHeadersIds, currentSortedColHeadersIds);
		if (isColHeadersChanged) {
			originalColHeadersRef.current = tablesColHeaders;
			setHeaders(tablesColHeaders);
			setColIndexesToShow(
				getFilteredMatrixColumnIndexes(
					tablesColHeaders,
					visibleProfessions,
					hasProfessionFilter,
					showOnlyStarred
				)
			);
			return;
		}
		setHeaders((prevHeaders) => {
			const updatedHeaders: IMatrixColHeader[] = getMatchingUpdatedHeaders(prevHeaders, tablesColHeaders);
			setColIndexesToShow(
				getFilteredMatrixColumnIndexes(updatedHeaders, visibleProfessions, hasProfessionFilter, showOnlyStarred)
			);
			return updatedHeaders;
		});
	}, [
		sequenceItems,
		areaSequenceItemsByFloor,
		showToc,
		isTableFinishToRender,
		visibleProfessions,
		hasProfessionFilter,
		showOnlyStarred,
	]);

	const sortTablesByHeadersPositions = (tables: IMatrixTable[]): IMatrixTable[] => {
		return tables.map((table: IMatrixTable) => {
			const newRows = table.rows.map((row) => {
				let index = 0;
				const filteredRow = row?.filter((cell, index) => {
					const originalHeaderOfCell: IMatrixColHeader | undefined = originalColHeadersRef.current[index];
					return originalHeaderOfCell !== undefined;
				});
				return sortBy(filteredRow, () => {
					const originalHeaderOfCell: IMatrixColHeader = originalColHeadersRef.current[index];
					const modifiedHeaderIndex: number = headers.findIndex(
						(header) => header.id === originalHeaderOfCell?.id
					);
					index++;
					return modifiedHeaderIndex < 0 ? undefined : modifiedHeaderIndex;
				});
			});
			return { ...table, rows: newRows };
		});
	};

	useEffect(() => {
		if (!isDraggingRef.current) {
			const tablesSortedByHeadersPositions: IMatrixTable[] = sortTablesByHeadersPositions(originalTables);
			setTables(tablesSortedByHeadersPositions);
			return;
		}

		setTables(originalTables);
	}, [originalTables]);

	const moveHeader = useCallback((dragIndex: number, hoverIndex: number) => {
		setHeaders((prevHeaders: IMatrixColHeader[]) => reorderItemInList(prevHeaders, dragIndex, hoverIndex));
		headerIdsOrderRef.current = reorderItemInList(headerIdsOrderRef.current, dragIndex, hoverIndex);
	}, []);

	const onDragStart = useCallback(
		(dragIndex: number) => {
			isDraggingRef.current = true;

			if (startDragIndexRef.current === undefined) {
				startDragIndexRef.current = dragIndex;
				startDragHeaderIdRef.current = headers[dragIndex].id;
			}
		},
		[headers]
	);

	const onFinishDrag = useCallback((dragIndex, callback) => {
		isDraggingRef.current = false;

		if (startDragIndexRef.current === undefined) {
			return;
		}

		const fromIndex = startDragIndexRef.current;
		if (fromIndex !== dragIndex) {
			setTables((prevTables: IMatrixTable[]) => {
				const newTables = prevTables.map((table: IMatrixTable) => {
					const newRows = table.rows.map((row) => reorderItemInList(row, fromIndex, dragIndex));
					return { ...table, rows: newRows };
				});
				callback(newTables);
				return newTables;
			});
		}
		startDragIndexRef.current = undefined;
		startDragHeaderIdRef.current = undefined;
	}, []);

	return {
		headers,
		tables,
		colIndexesToShow,
		moveHeader,
		onDragStart,
		onFinishDrag,
		isDraggingRef,
		headerIdsOrderRef,
		startDragHeaderIdRef,
	};
};
