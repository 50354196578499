import React from 'react';
import { getSafetyEventColor, getSafetyViolationTypeTranslationVars } from '../../../../../utils/safety.utils';
import { ISafetyEvent } from '../../../../../interfaces/ISafetyEvent';
import { translationService } from '../../../../../index';
import { getProfessionDisplayText } from '@shared/utils/professions.utils';
import { InvestigationReportDataListSection } from '../../ReportTagLocationDataListSection/InvestigationReportDataListSection';

interface IInvestigationReportSafetyEventsSectionPropsProps {
	safetyEvents: ISafetyEvent[];
	isLoading?: boolean;
	pdfMode: boolean;
}

export const InvestigationReportSafetyEventsSection = (props: IInvestigationReportSafetyEventsSectionPropsProps) => {
	return (
		<InvestigationReportDataListSection
			data={props.safetyEvents}
			getChipColor={getSafetyEventColor}
			getLabel={(alert: ISafetyEvent) => {
				const translationVars: { [key: string]: string } = getSafetyViolationTypeTranslationVars(
					alert.trade?.tradeId,
					alert.safetyViolationType
				);
				return translationService.get(alert.safetyViolationType, translationVars);
			}}
			getChipContent={(alert: ISafetyEvent) =>
				getProfessionDisplayText(alert.profession! || alert.trade || alert.manager, translationService, true)
			}
			header={translationService.get('safetyEvents')}
			emptyStateText={'noSafetyEventsOccurred'}
			isLoading={props.isLoading}
			pdfMode={props.pdfMode}
		/>
	);
};
