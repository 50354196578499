import React from 'react';
import classes from './styles.module.scss';
import { TrusstorIcon } from '@src/Components/TrusstorIcon/TrusstorIcon';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { IconColor, IconSize, TrusstorIconShared } from '@shared/components/TrusstorIconShared/TrusstorIconShared';
import TrusstorTooltip from '@shared/components/TrusstorTooltip';
import { LineDelimiter } from '@src/Components/LineDelimiter/LineDelimiter';

interface IProfessionIssuesChipProps {
	backgroundColor: string;
	numberOfIssues: number;
	isStarred?: boolean;
	isOverdue?: boolean;
	isMinimized?: boolean;
	getToolTipComponent?: () => JSX.Element;
}

export const ProfessionIssuesChip = (props: IProfessionIssuesChipProps) => {
	return (
		<TrusstorTooltip tooltipComponent={props.getToolTipComponent?.()} showAlways={true}>
			<div className={classes.professionIssuesChipContainer} style={{ backgroundColor: props.backgroundColor }}>
				<div className={classes.flexContainer}>
					<TrusstorIcon iconName={IconNames.issuesFilled} color={IconColor.White} size={IconSize.SMALL} />
					{props.numberOfIssues}
				</div>
				{props.isStarred && !props.isMinimized && (
					<div className={classes.flexContainer}>
						<LineDelimiter height={12} />
						<TrusstorIconShared
							iconName={IconNames.star}
							color={IconColor.White}
							size={IconSize.SMALL}
							filledIcon
						/>
					</div>
				)}
				{props.isOverdue && !props.isMinimized && (
					<div className={classes.flexContainer}>
						<LineDelimiter height={12} />
						<TrusstorIcon iconName={IconNames.flag} color={IconColor.White} size={IconSize.SMALL} />
					</div>
				)}
			</div>
		</TrusstorTooltip>
	);
};
