import React, { useRef } from 'react';
import { SmallDropdownDisplay } from '../../Displays/SmallDropdownDisplay/SmallDropdownDisplay';
import { SingleDropdownProps } from '../../dropdown.interface';
import { useDropdownKeyboardNavigation, useSingleSelectionDropdown } from '../singleSelectionDropdown.utils';

function SmallSingleDropdown<T>(props: SingleDropdownProps<T>) {
	const {
		filteredOptions,
		isDropdownOpen,
		dropdownRef,
		highlightedIndex,
		setHighlightedIndex,
		handleOptionClick,
		handleOptionHover,
		setIsDropdownOpen,
		optionsContainerRef,
		isSelected,
	} = useSingleSelectionDropdown<T>(props);

	const handleKeyDown = useDropdownKeyboardNavigation(
		highlightedIndex,
		setHighlightedIndex,
		optionsContainerRef,
		filteredOptions,
		handleOptionClick,
		true
	);

	return (
		<SmallDropdownDisplay
			shouldShowSelectedOptionAsLabel
			options={filteredOptions}
			dropdownRef={dropdownRef}
			containerRef={optionsContainerRef}
			isDropdownOpen={isDropdownOpen}
			label={props.label}
			disabled={props.disabled}
			setIsDropdownOpen={setIsDropdownOpen}
			handleKeyDown={handleKeyDown}
			handleOptionClick={handleOptionClick}
			handleOptionHover={handleOptionHover}
			getDisplayOption={props.getDisplayOption}
			getSecondDisplayOption={props.getSecondDisplayOption}
			testId={props.testId}
			getIsSelected={isSelected}
			highlightedIndex={highlightedIndex}
		/>
	);
}

export { SmallSingleDropdown };
