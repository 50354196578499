import React from 'react';
import classes from './styles.module.scss';
import { TrusstorIcon } from '@src/Components/TrusstorIcon/TrusstorIcon';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';

interface ILeftRightSelectionProps {
	leftAction: () => void;
	rightAction: () => void;
}
export const LeftRightSelection = (props: ILeftRightSelectionProps) => {
	return (
		<div className={classes.LeftRightSelection_container} data-testid="LeftRightSelection">
			<TrusstorIcon iconName={IconNames.chevronLeft} className={classes.icon} onClick={props.leftAction} />
			<TrusstorIcon iconName={IconNames.chevronRight} className={classes.icon} onClick={props.rightAction} />
		</div>
	);
};
