import { ICreateIssue, IIssue } from '@shared/interfaces/IIssue';
import { requestService } from '../index';

export const createIssueApi = async (issue: ICreateIssue): Promise<IIssue> => {
	const issueCreated: IIssue = await requestService.post(`/issues`, {
		body: issue,
	});
	return issueCreated;
};

export const deleteIssueApi = async (issueId: string): Promise<void> => {
	await requestService.delete(`/issues/${issueId}`);
};

export const updateIssueApi = async (issueId: string, issue: Partial<IIssue>): Promise<IIssue> => {
	return await requestService.put(`/issues/${issueId}`, {
		body: issue,
	});
};
