import React from 'react';
import classes from './styles.module.scss';
import { IntercomLinkSection } from '../IntercomLinkSection/IntercomLinkSection';
import { IIntercomLinkSectionProps } from '../IntercomLinkSection/IIntercomLinkSectionProps';

interface IRadioButtonTopSectionProps {
	title: string;
	intercomLinkSection: IIntercomLinkSectionProps;
}

export const RadioButtonTopSection = (props: IRadioButtonTopSectionProps) => {
	return (
		<div className={classes.RadioButtonTopSection_container} data-testid="RadioButtonTopSection">
			<div className={classes.topSection}>
				<div className={classes.title}>{props.title}:</div>
				<IntercomLinkSection {...props.intercomLinkSection} />
			</div>
		</div>
	);
};
