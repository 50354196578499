import React, { useEffect, useState } from 'react';
import classes from './styles.module.scss';
import { useSharedServices } from '../../hooks/sharedServices.context';

interface ITrusstorSwitcherProps {
	options: [string, string];
	handleChange: (option: string) => void;
	initialState?: string;
	value?: string;
	width?: string;
	height?: string;
	biEventOnClick?: (option: string) => void;
}

const TrusstorSwitcher = (props: ITrusstorSwitcherProps) => {
	const { translationService } = useSharedServices();
	const [toggleState, setToggleState] = useState<string>(props.initialState || props.options[0]);

	const getSecondOption = (): string => {
		return toggleState === props.options[0] ? props.options[1] : props.options[0];
	};

	useEffect(() => {
		if (!props.initialState) {
			setToggleState(props.options[0]);
			props.handleChange(props.options[0]);
		}
	}, []);

	useEffect(() => {
		if (props.value) {
			setToggleState(props.value);
			props.handleChange(props.value);
		}
	}, [props.value]);

	const handleToggle = (): void => {
		const secondOption = getSecondOption();
		props.handleChange(secondOption);
		props.biEventOnClick && props.biEventOnClick(secondOption);
		setToggleState(secondOption);
	};

	return (
		<section
			className={classes.switcherContainer}
			onClick={handleToggle}
			style={{ width: props.width, height: props.height }}
		>
			<div className={toggleState === props.options[0] ? classes.active : classes.inActive}>
				{translationService.get(props.options[0])}
			</div>
			<div className={toggleState === props.options[1] ? classes.active : classes.inActive}>
				{translationService.get(props.options[1])}
			</div>
		</section>
	);
};

export { TrusstorSwitcher };
