import { ISequenceItem } from '@interfaces/ISequenceItem';
import { ISequenceItemProgressBySequenceItemReport } from '@interfaces/ISequenceItemProgressBySequenceItemReport';
import { IProfession } from '@shared/interfaces/IProfession';

const getReportsFilteredByProgress = (
	reports: ISequenceItemProgressBySequenceItemReport[],
	showNotStarted: boolean,
	showOnGoing: boolean,
	showCompleted: boolean
): ISequenceItemProgressBySequenceItemReport[] => {
	if (!showNotStarted && !showOnGoing && !showCompleted) return reports;

	const notStartedReports: ISequenceItemProgressBySequenceItemReport[] = !showNotStarted
		? []
		: reports.filter((report) => report.overallProgress.completedAsiInPeriod === 0);
	const onGoingReports: ISequenceItemProgressBySequenceItemReport[] = !showOnGoing
		? []
		: reports.filter(
				(report) =>
					report.overallProgress.totalAsisInPeriod !== report.overallProgress.completedAsiInPeriod &&
					report.overallProgress.completedAsiInPeriod !== 0
			);
	const completedReports: ISequenceItemProgressBySequenceItemReport[] = !showCompleted
		? []
		: reports.filter(
				(report) => report.overallProgress.totalAsisInPeriod === report.overallProgress.completedAsiInPeriod
			);

	return [...notStartedReports, ...onGoingReports, ...completedReports];
};

export const filterActivitiesProgressReports = (
	reports: ISequenceItemProgressBySequenceItemReport[],
	showStarred: boolean,
	showNotStarred: boolean,
	showNotStarted: boolean,
	showOnGoing: boolean,
	showCompleted: boolean,
	selectedProfessions: IProfession[]
): ISequenceItemProgressBySequenceItemReport[] => {
	const reportFilterByProfessions: ISequenceItemProgressBySequenceItemReport[] = !selectedProfessions.length
		? reports
		: reports.filter((report) => {
				return selectedProfessions.some((profession) => profession._id === report.profession._id);
			});
	const reportFilterByStarred: ISequenceItemProgressBySequenceItemReport[] =
		showStarred && !showNotStarred
			? reportFilterByProfessions.filter((report) => report.isStarred)
			: reportFilterByProfessions;
	const reportFilterByNotStarred: ISequenceItemProgressBySequenceItemReport[] =
		!showStarred && showNotStarred
			? reportFilterByProfessions.filter((report) => !report.isStarred)
			: reportFilterByStarred;
	return getReportsFilteredByProgress(reportFilterByNotStarred, showNotStarted, showOnGoing, showCompleted);
};

export const filterReportsByMilestone = (
	reports: ISequenceItemProgressBySequenceItemReport[],
	sequenceItems: ISequenceItem[],
	selectedMilestoneId?: string | null
): ISequenceItemProgressBySequenceItemReport[] => {
	if (!selectedMilestoneId) return reports;
	const sequenceItemsLinkedToMilestone: ISequenceItem[] = sequenceItems.filter(
		(sequenceItem) => sequenceItem.linkedMilestoneId === selectedMilestoneId
	);

	if (!sequenceItemsLinkedToMilestone.length) return [];

	const selectedSequenceItemsIds: string[] = sequenceItemsLinkedToMilestone.map((sequenceItem) => sequenceItem._id);
	const reportFilterBySequenceItems: ISequenceItemProgressBySequenceItemReport[] = reports.filter((report) =>
		selectedSequenceItemsIds.includes(report.sequenceItemId)
	);

	return reportFilterBySequenceItems;
};
