import React, { useEffect, useState } from 'react';
import { IAssignedActivityArea } from '@shared/interfaces/IAssignedActivityArea';
import { WidgetAreaSequenceItem } from '@shared/components/widget/WidgetAreaSequenceItem/WidgetAreaSequenceItem';
import { accessLevelService, requestService, translationService } from '../../../../../../servicesInitializers';
import { errorSnackbar, successSnackbar } from '../../../../../../utils/snackbar.utils';
import { useDispatch, useSelector } from 'react-redux';
import { ActivityGroupStatus } from '@shared/interfaces/ActivityGroupStatus.enum';
import { IAreaSequenceItem } from '@shared/interfaces/IAreaSequenceItem';
import { ActionConfirmationDialog } from '@src/Components/WorkplanWidgetComponents/ActionConfirmationDialog/ActionConfirmationDialog';
import { workplanMatrixInteraction_BI } from '@utils/bi.utils';
import { AreaSequenceItemStatus } from '@shared/interfaces/AreaSequenceItemStatus.enum';
import { isAsiComplete } from '@shared/utils/asi.utils';
import { AsiReviewDialog } from '@src/Components/ASIReviewDialog/ASIReviewDialog';
import { IProfession } from '@shared/interfaces/IProfession';
import { selectProjectId } from '@store/slices/project.slice';
import { updateAreaSequenceItemReadyForReviewStatus } from '@src/apis/areaSequenceItems.api';
import { IMergedAreaSequenceItem } from '@interfaces/IMergedAreaSequenceItem';
import { IAreaSequenceItemWithActivityStatus } from '@shared/interfaces/IMainPageWidgetActivityData';

interface IWidgetAreaCardProps {
	description: string;
	profession: IProfession;
	areaSequenceItem: IAreaSequenceItem;
	area: IAssignedActivityArea;
	onStatusChange?: (isDone: boolean, areaSequenceItem: IAreaSequenceItem, status?: AreaSequenceItemStatus) => void;
}

export const WidgetAreaCard = (props: IWidgetAreaCardProps) => {
	const projectId: string = useSelector(selectProjectId)!;
	const [showReviewDialog, setShowReviewDialog] = useState(false);
	const [isReadyForReview, setIsReadyForReview] = useState<boolean>(
		props.areaSequenceItem.status === AreaSequenceItemStatus.readyForReview
	);
	const [isDone, setIsDone] = useState<boolean>(props.areaSequenceItem.status === AreaSequenceItemStatus.complete);

	useEffect(() => {
		setIsReadyForReview(props.areaSequenceItem.status === AreaSequenceItemStatus.readyForReview);
		setIsDone(props.areaSequenceItem.status === AreaSequenceItemStatus.complete);
	}, [props.areaSequenceItem.status]);

	const [isWarningDialogOpen, setIsWarningDialogOpen] = useState<boolean>(false);
	const dispatch = useDispatch();
	const userHasAccessToActivityActions: boolean = accessLevelService.hasAccess('workplanActivityActions');

	const handleCellClick = async (cell: IAreaSequenceItemWithActivityStatus): Promise<void> => {
		if (!userHasAccessToActivityActions) {
			await handleLimitedUserChangeStatus(cell, !isReadyForReview);
			return;
		}
		if (userHasAccessToActivityActions && isReadyForReview) {
			return setShowReviewDialog(true);
		}
		if (cell.groupStatus === ActivityGroupStatus.complete && isAsiComplete(cell.status)) {
			setIsWarningDialogOpen(true);
			return;
		}

		await handleDone(cell);
	};

	const handleLimitedUserChangeStatus = async (
		cell: IAreaSequenceItem,
		isReadyForReviewUpdate: boolean
	): Promise<IAreaSequenceItem | void> => {
		try {
			if (!cell) return;
			if (isReadyForReviewUpdate) {
				setIsReadyForReview(true);
			} else {
				setIsReadyForReview(false);
			}
			if (props.onStatusChange)
				props.onStatusChange(
					false,
					cell,
					isReadyForReviewUpdate ? AreaSequenceItemStatus.readyForReview : AreaSequenceItemStatus.planned
				);
			const asiUpdated: IMergedAreaSequenceItem = await updateAreaSequenceItemReadyForReviewStatus(
				cell._id!,
				isReadyForReviewUpdate
			);
			if (asiUpdated.status === AreaSequenceItemStatus.readyForReview) {
				successSnackbar(dispatch, translationService.get('markReadyForReviewSuccess'));
			} else {
				successSnackbar(dispatch, translationService.get('undoASISubmissionForReview'));
			}
			return asiUpdated;
		} catch (e) {
			errorSnackbar(dispatch, translationService.get('markReadyForReviewFailed'));
			if (props.onStatusChange) props.onStatusChange(false, cell, cell.status);
		}
	};

	const handleDone = async (cell: IAreaSequenceItem): Promise<void> => {
		try {
			if (!cell) return;
			if (isDone) {
				setIsDone(false);
			} else {
				setIsDone(true);
			}
			if (props.onStatusChange) props.onStatusChange(!isAsiComplete(cell.status), cell);
			await requestService.put(`/activities/sequenceItems/areaSequenceItem/done`, {
				body: { mergedAreaSequenceItem: { ...cell }, isDone: !isDone },
			});

			const actionType: string = isDone ? 'undone' : 'complete';
			workplanMatrixInteraction_BI({
				actionType,
				floorId: cell.area.floorId,
				areaId: cell.area.areaId,
				sequenceItem: props.description,
				areaSequenceItemId: cell._id!,
				source: 'Widget',
			});
		} catch (err) {
			errorSnackbar(dispatch, translationService.get('markDoneFailed'));
			props.onStatusChange?.(isAsiComplete(cell.status), cell);
		}
	};

	const handleDoneWarningAction = async (): Promise<void> => {
		await handleDone(props.areaSequenceItem as IAreaSequenceItem);
		setIsWarningDialogOpen((prev) => !prev);
	};

	const onApproveSubmit = async () => {
		setShowReviewDialog(false);
		await handleDone(props.areaSequenceItem);
		successSnackbar(dispatch, translationService.get('asiReviewDialog_reviewApproved'));
	};

	const onRejectSubmit = async () => {
		setShowReviewDialog(false);
		const asiUpdated = await handleLimitedUserChangeStatus(props.areaSequenceItem, false);
		if (asiUpdated) {
			successSnackbar(
				dispatch,
				translationService.get('asiReviewDialog_reviewRejected', {
					new_status: translationService.get(asiUpdated.status),
				})
			);
		}
	};

	return (
		<>
			{showReviewDialog && (
				<AsiReviewDialog
					asi={props.areaSequenceItem}
					projectId={projectId}
					area={props.area}
					sequenceItemDescription={props.description}
					profession={props.profession}
					show={showReviewDialog}
					onClose={() => setShowReviewDialog(false)}
					onAcceptSubmit={onApproveSubmit}
					onRejectSubmit={onRejectSubmit}
				/>
			)}
			<ActionConfirmationDialog
				title={translationService.get('areYouSure')}
				text={translationService.get('markDoneWarning')}
				mainButtonAction={handleDoneWarningAction}
				secondaryButtonAction={() => setIsWarningDialogOpen((prev) => !prev)}
				show={isWarningDialogOpen}
				onClose={() => setIsWarningDialogOpen((prev) => !prev)}
			/>
			<WidgetAreaSequenceItem
				isReadyForReview={isReadyForReview}
				isDoneCell={isDone}
				userHasAccessToActivityActions={userHasAccessToActivityActions}
				area={props.area}
				areaSequenceItem={props.areaSequenceItem as IAreaSequenceItem}
				onClick={handleCellClick}
				translationService={translationService}
			/>
		</>
	);
};
