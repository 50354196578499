import { EquipmentTypes } from '../constants/equipment.const';
import {
	bondingMachinery,
	electricity,
	heavy,
	height,
	movingLifting,
	optic,
	pavingTiling,
	power,
	safety,
	waterDrainage,
	welding,
	scissorsLift,
} from '../assets/icons/equipment';
import { UtilityTypes } from '../constants/utilityTypes.enum';
import { IconNames } from '../components/TrusstorIconShared/IconNames.enum';

export const getEquipmentIconFromType = (type: EquipmentTypes | UtilityTypes): string => {
	switch (type) {
		case EquipmentTypes.Height:
			return height;
		case EquipmentTypes.BondingMachinery:
			return bondingMachinery;
		case EquipmentTypes.Electricity:
			return electricity;
		case EquipmentTypes.HeavyEquipment:
			return heavy;
		case EquipmentTypes.MovingLifting:
			return movingLifting;
		case EquipmentTypes.Optics:
			return optic;
		case EquipmentTypes.PavingTiling:
			return pavingTiling;
		case EquipmentTypes.PowerTools:
			return power;
		case EquipmentTypes.Safety:
			return safety;
		case EquipmentTypes.Welding:
			return welding;
		case EquipmentTypes.WaterDrainage:
			return waterDrainage;
		case UtilityTypes.scissorsLift:
			return scissorsLift;
		default:
			return power;
	}
};

export const getEquipmentIconNameFromType = (type: EquipmentTypes | UtilityTypes): IconNames => {
	switch (type) {
		case EquipmentTypes.Height:
			return IconNames.height;
		case EquipmentTypes.BondingMachinery:
			return IconNames.bonding;
		case EquipmentTypes.Electricity:
			return IconNames.electrical;
		case EquipmentTypes.HeavyEquipment:
			return IconNames.heavy;
		case EquipmentTypes.MovingLifting:
			return IconNames.movingLifting;
		case EquipmentTypes.Optics:
			return IconNames.optics;
		case EquipmentTypes.PavingTiling:
			return IconNames.hardscape;
		case EquipmentTypes.PowerTools:
			return IconNames.drill;
		case EquipmentTypes.Safety:
			return IconNames.shieldTick;
		case EquipmentTypes.Welding:
			return IconNames.welding;
		case EquipmentTypes.WaterDrainage:
			return IconNames.drainage;
		case UtilityTypes.scissorsLift:
			return IconNames.scissorsLift;
		default:
			return IconNames.drill;
	}
};
