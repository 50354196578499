import { ActivityGroupStatus } from '@shared/interfaces/ActivityGroupStatus.enum';
import { IActivityGroupStatusFilterItem } from '../interfaces/IActivityGroupStatusFilterItem';
import { activitiesStatusesColors } from '@shared/constants/constants';

export enum SafetyEventStatuses {
	close = 'close',
	active = 'active',
}

export enum StatusChannels {
	manual = 'manual',
	automatic = 'automatic',
}

export enum NotificationStatuses {
	notSent = 'notSent',
	sent = 'sent',
	sending = 'sending',
}

export enum TagStatuses {
	Storage = 'Storage',
	Sleeping = 'Sleeping',
	Good = 'Good',
	NoReception = 'NoReception',
}

export enum HttpStatusCode {
	badRequest = 400,
	internalServerError = 500,
	unauthorized = 401,
	forbidden = 403,
	conflict = 409,
	okSuccess = 200,
	imATeapot = 418,
	notFound = 404,
	requestTimeout = 408,
	created = 201,
}

export const activityGroupStatuses: IActivityGroupStatusFilterItem[] = [
	{
		status: ActivityGroupStatus.overdue,
		color: activitiesStatusesColors[ActivityGroupStatus.overdue],
	},
	{
		status: ActivityGroupStatus.delayed,
		color: activitiesStatusesColors[ActivityGroupStatus.delayed],
	},
	{
		status: ActivityGroupStatus.inProgress,
		color: activitiesStatusesColors[ActivityGroupStatus.inProgress],
	},
	{
		status: ActivityGroupStatus.planned,
		color: activitiesStatusesColors[ActivityGroupStatus.planned],
	},
	{
		status: ActivityGroupStatus.complete,
		color: activitiesStatusesColors[ActivityGroupStatus.complete],
	},
];
