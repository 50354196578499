import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IProfessionsAnalysisReport } from '../../interfaces/IProfessionsAnalysisReport';
import { IRoundedProfessionsAnalysisReport } from '../../interfaces/IRoundedProfessionsAnalysisReport';
import { getRoundedProfessionReport } from '../../utils/roundedData.utils';

interface IProfessionReportReducerState {
	roundedProfessionReport: IRoundedProfessionsAnalysisReport;
}

const initialState: IProfessionReportReducerState = {
	roundedProfessionReport: {},
};

const professionReportSlice = createSlice({
	name: 'professionReport',
	initialState,
	reducers: {
		updateProfessionReport: (
			state: IProfessionReportReducerState,
			action: PayloadAction<{ report: IProfessionsAnalysisReport }>
		) => {
			state.roundedProfessionReport = getRoundedProfessionReport(action.payload.report);
		},
		resetProfessionReport: (state: IProfessionReportReducerState) => {
			state.roundedProfessionReport = {};
		},
	},
});

export const { updateProfessionReport, resetProfessionReport } = professionReportSlice.actions;
export default professionReportSlice.reducer;
