import React, { useEffect } from 'react';
import classes from './styles.module.scss';
import { CriticalSection } from '../CriticalSection/CriticalSection';
import { IssueMenu } from '../IssueMenu/IssueMenu';
import { closeIcon } from '../../../assets';
import { LocationItem } from '../IssueLocation/IssueLocation';
import userIcon from '../../../assets/icons/user_icon.svg';
import { CommentsThread } from '../../CommentsThread/CommentsThread';
import { TranslationService } from '../../../services/translation.service';
import { StorageService } from '../../../services/storage.service';
import { IIssueShared, IssueStatus } from '../../../interfaces/IIssueShared';
import { IProfession } from '../../../interfaces/IProfession';
import { getIssueDescription } from '../../../utils/issues.utils';
import activityIcon from '../../../assets/icons/Activity.svg';
import { CommentInput } from '../../Inputs/CommentInput/CommentInput';
import {
	ITrusstorIconButtonSize,
	TrusstorIconButtonDeprecated,
} from '../../buttons/TrusstorIconButton/TrusstorIconButtonDeprecated';
import { RequestService } from '../../../services/request.service';
import { IssueStatusChangeButton } from '../IssueStatusChangeButton/IssueStatusChangeButton';
import { IssueDateDisplay } from '../IssueDateDisplay/IssueDateDisplay';
import { ProfessionDisplayWithIcon } from '../../ProfessionDisplayWithIcon/ProfessionDisplayWithIcon';
import { IssueStatusChip } from '../IssueStatusChip/IssueStatusChip';
import { IssueMetadataSection } from '../IssueMetadataSection/IssueMetadataSection';
import TrusstorTooltip from '../../TrusstorTooltip';
import { IssuePrivateSection } from '../IssuePrivateSection/IssuePrivateSection';

const COMMENT_INPUT_HEIGHT = 65;

interface IIssueDesktopViewSectionProps {
	close: (e: React.MouseEvent) => void;
	handlePriority: (issueId: string) => void;
	priorityIcon: string;
	activityDescription?: string;
	translationService: TranslationService;
	storageService: StorageService;
	issue: IIssueShared<IProfession>;
	tz: string;
	projectId: string;
	updateIssue: (
		issueId: string,
		issueToUpdate: Partial<IIssueShared<IProfession>>,
		updateBackend?: boolean
	) => Promise<IIssueShared<IProfession>>;
	deleteIssue: (e: React.MouseEvent) => void;
	saveComment: (comment: string) => Promise<void>;
	saveImage?: (file: File) => Promise<void>;
	hideCloseButton?: boolean;
	hideProfession?: boolean;
	requestService: RequestService;
	openEditFunction?: (e) => void;
	handleStatusChange: (updatedStatus: IssueStatus, issueId: string) => Promise<void>;
	handleReviewCallback: () => void;
	isLimitedUser: boolean;
}

export const IssueDesktopViewSection = (props: IIssueDesktopViewSectionProps) => {
	const [description, setDescription] = React.useState<string>(
		getIssueDescription(props.issue, props.translationService)
	);

	const descriptionRef = React.useRef<HTMLDivElement>(null);
	useEffect(() => {
		setDescription(getIssueDescription(props.issue, props.translationService));
	}, [props.issue.description]);

	const language: string = props.translationService.getChosenLanguage();

	const submitChanges = async () => {
		if (!descriptionRef.current?.innerText.length) {
			setDescription(props.issue.description);
			return;
		}
		await props.updateIssue(props.issue._id, {
			description: descriptionRef.current?.innerText,
		});
	};

	const handlePriority = (e: React.MouseEvent) => {
		e.stopPropagation();
		props.handlePriority(props.issue._id);
	};

	const handleStatusChange = async (updatedStatus: IssueStatus) => {
		await props.handleStatusChange(updatedStatus, props.issue._id);
	};

	return (
		<>
			<section className={classes.header}>
				<CriticalSection
					isLimitedUser={props.isLimitedUser}
					issuePriority={props.issue.priority}
					onClick={handlePriority}
					translationService={props.translationService}
				/>
				<div className={classes.leftHeader}>
					<IssueStatusChip status={props.issue.status} />
					{props.issue.isPrivate && (
						<TrusstorTooltip
							tooltipComponent={
								<p className={classes.privateTooltipText}>
									{props.translationService.get('issuePrivateDisplayLabel')}
								</p>
							}
							cleanStyling
							showAlways
							placement={'left'}
						>
							<div>
								<IssuePrivateSection textMode={'minimal'} />
							</div>
						</TrusstorTooltip>
					)}
					{props.issue?.dueDate && <IssueDateDisplay issue={props.issue} tz={props.tz} />}
					{!props.isLimitedUser && (
						<IssueMenu
							deleteFunction={(e) => props.deleteIssue(e)}
							openEditFunction={props.openEditFunction}
							requestService={props.requestService}
							translationService={props.translationService}
						/>
					)}
					{props.hideCloseButton ? null : (
						<TrusstorIconButtonDeprecated
							buttonSize={ITrusstorIconButtonSize.SMALL}
							iconSrc={closeIcon}
							onClick={props.close}
						/>
					)}
				</div>
			</section>
			<section className={classes.body}>
				<div className={classes.descriptionContainer}>
					<div
						ref={descriptionRef}
						contentEditable={!props.isLimitedUser}
						className={classes.title}
						onBlur={submitChanges}
					>
						{description}
					</div>
					<IssueStatusChangeButton
						handleReviewCallback={props.handleReviewCallback}
						isLimitedUser={props.isLimitedUser}
						issueStatus={props.issue.status}
						handleStatusChange={handleStatusChange}
						itemHovered={false}
						translationService={props.translationService}
					/>
				</div>
				<div className={classes.infoSection}>
					{props.issue.profession && !props.hideProfession && (
						<div className={classes.professionSection}>
							<ProfessionDisplayWithIcon
								profession={props.issue.profession}
								projectId={props.projectId}
							/>
						</div>
					)}
					{props.issue.locations && props.issue.locations.length > 0 && (
						<div className={classes.locationItemsContainer}>
							<LocationItem locations={props.issue.locations} projectId={props.projectId} />
						</div>
					)}
					{props.issue.assignee && (
						<div className={classes.assigneeSection}>
							<img src={userIcon} alt="" />
							<div className={classes.assigneeText}>{props.issue.assignee.name}</div>
						</div>
					)}
					{props.activityDescription && (
						<div className={classes.activitySection}>
							<img src={activityIcon} alt="" />
							<div className={classes.activityText}>{props.activityDescription}</div>
						</div>
					)}
				</div>
				<IssueMetadataSection issue={props.issue} tz={props.tz} />
			</section>
			{props.issue.comments && props.issue.comments.length > 0 && (
				<>
					<div className={classes.divider} />
					<section className={classes.threadsSection}>
						<CommentsThread
							comments={props.issue.comments}
							issueId={props.issue._id}
							tz={props.tz}
							translationService={props.translationService}
							storageService={props.storageService}
						/>
					</section>
				</>
			)}
			<section style={{ minHeight: `${COMMENT_INPUT_HEIGHT}px` }}>
				<CommentInput
					hasCamera
					rootStyle={{ minHeight: `${COMMENT_INPUT_HEIGHT}px`, position: 'absolute' }}
					saveComment={props.saveComment}
					translationService={props.translationService}
					saveImage={props.saveImage}
					setFocusOnCommentInput
				/>
			</section>
		</>
	);
};
