import React from 'react';
import { BaseNotificationCard } from '../BaseNotificationCard/BaseNotificationCard';
import classes from './styles.module.scss';
import { INotification } from '../../../interfaces/INotification';
import { TranslationService } from '../../../services/translation.service';
import { IUser } from '../../../interfaces/IUser';
import { progressAnalysis } from '../../../assets/icons';
import { useSharedServices } from '../../../hooks/sharedServices.context';
import { TrusstorButton } from '../../buttons/TrusstorButton/TrusstorButton';
import classnames from 'classnames';

interface IDailyReportCardProps {
	notification: INotification;
	tz: string;
	handleCardClick: () => void;
	projectId: string;
	userDetails: IUser;
}

export const getProgressAnalysisTitleElement = (translationService: TranslationService): React.ReactNode => {
	return <div className={classes.title}>{translationService.get('yourProgressAnalysisIsReady')}</div>;
};

export const getButton = (translationService: TranslationService): React.ReactNode => {
	return (
		<div className={classes.button}>
			<TrusstorButton text={translationService.get('view')} />
		</div>
	);
};

export const getProgressAnalysisDescriptionElement = (
	translationService: TranslationService,
	isBanner?: boolean
): React.ReactNode => {
	return (
		<div className={classnames(classes.description, { [classes.isBanner]: isBanner })}>
			{translationService.get('yourProgressAnalysisIsReadyDescription')}
		</div>
	);
};

export const getProgressAnalysisIconElement = (isBanner?: boolean): React.ReactNode => {
	return <img className={classnames(classes.image, { [classes.isBanner]: isBanner })} src={progressAnalysis} />;
};

export const WeeklyProgressAnalysisReportCard = (props: IDailyReportCardProps) => {
	const { translationService, requestService } = useSharedServices();

	return (
		<BaseNotificationCard
			isClickable
			icon={getProgressAnalysisIconElement()}
			descriptionElement={getProgressAnalysisDescriptionElement(translationService)}
			titleElement={getProgressAnalysisTitleElement(translationService)}
			createdAt={props.notification.createdAt}
			tz={props.tz}
			translationService={translationService}
			requester={requestService}
			notificationId={props.notification._id}
			handleCardClick={props.handleCardClick}
			isViewed={props.notification.isViewed}
		/>
	);
};
