import { IDistressButtonNotificationMetadata } from '../interfaces/IDistressButtonNotificationMetadata';
import { INotification } from '../interfaces/INotification';
import { NotificationTypes } from '../interfaces/NotificationTypes.enum';
import { TranslationService } from '../services/translation.service';
import { InvestigationReportTypes } from '../interfaces/InvestigationReportTypes.enum';
import { INotificationTitleAndBody } from '../interfaces/INotificationTitleAndBody';
import { getDailyReportTitleText } from '../components/NotificationCard/DailyReportCard/DailyReportNotification.texts';
import {
	getDistressButtonDescriptionText,
	getDistressButtonTitleText,
} from '../components/NotificationCard/DistressButtonCard/DistressButtonNotification.texts';
import {
	getTagHealthDescriptionText,
	getTagHealthTitleText,
} from '../components/NotificationCard/TagHealthCard/TagHealthNotification.texts';
import {
	getIssueCommentCreatedDescriptionText,
	getIssueCommentCreatedTitleText,
} from '../components/NotificationCard/IssueCommentCreatedCard/IssueCommentCreatedNotification.texts';
import { IIssueCommentCreatedNotificationMetadata } from '../interfaces/IIssueCommentCreatedNotificationMetadata';
import { ITagHealthNotificationMetadata } from '../interfaces/ITagHealthNotificationMetadata';
import {
	getCreatedIssuesDescriptionText,
	getCreatedIssuesTitleText,
} from '../components/NotificationCard/CreatedIssuesCard/CreatedIssuesNotification.texts';
import { ICreatedIssuesNotificationMetadata } from '../interfaces/ICreatedIssuesNotificationMetadata';
import { getPlanningCreatedTitleText } from '../components/NotificationCard/PlanningCreatedCard/PlanningCreatedCardNotification.texts';
import { IPlanningCreatedNotificationMetadata } from '../interfaces/IPlanningCreatedNotificationMetadata';
import {
	getRestrictedAreaDescriptionText,
	getRestrictedAreaTitleText,
} from '../components/NotificationCard/RestrictedAreaNotificationCard/RestrictedAreaNotification.texts';
import {
	getProgressAnalysisDescriptionText,
	getProgressAnalysisTitleText,
} from '../components/NotificationCard/WeeklyProgressAnalysisReportCard/WeeklyProgressAnalysisReportNotification.texts';
import { getIssueStatusUpdatedTitleText } from '../components/NotificationCard/IssueStatusUpdatedCard.tsx/IssueStatusUpdatedCardNotifications.texts';
import { IIssueStatusUpdatedNotificationMetadata } from '../interfaces/IIssueStatusUpdatedNotificationMetadata';
import {
	getIssueAssignedDescriptionText,
	getIssueAssignedTitleText,
} from '../components/NotificationCard/IssueAssignedCard/IssueAssignedNotifications.texts';
import { IIssueAssigneeUpdatedNotificationMetadata } from '../interfaces/IIssueAssigneeUpdatedNotificationMetadata';
import {
	getAreaSequenceItemStatusUpdateCardDescriptionText,
	getAreaSequenceItemStatusUpdateCardTitleText,
} from '../components/NotificationCard/AreaSequenceItemStatusUpdateCard/AreaSequenceItemStatusUpdateCard.texts';
import { IAreaSequenceItemStatusUpdateNotificationMetadata } from '../interfaces/IAreaSequenceItemStatusUpdateNotificationMetadata';

export const getMessageTitle = (notification: INotification, translationService: TranslationService): string => {
	switch (notification.type) {
		case NotificationTypes.DistressButton:
			return getDistressButtonTitleText(translationService);
		case NotificationTypes.TagHealth:
			return getTagHealthTitleText(notification.metadata as ITagHealthNotificationMetadata, translationService);
		case NotificationTypes.DailyReport:
			return getDailyReportTitleText(translationService);
		case NotificationTypes.IssueStatusUpdated:
			return getIssueStatusUpdatedTitleText(
				translationService,
				notification.metadata as IIssueStatusUpdatedNotificationMetadata
			);
		case NotificationTypes.AssignedIssue:
			return getIssueAssignedTitleText(translationService);
		case NotificationTypes.CreatedIssues:
			return getCreatedIssuesTitleText(
				notification.metadata as ICreatedIssuesNotificationMetadata,
				translationService
			);
		case NotificationTypes.IssueCommentCreated:
			return getIssueCommentCreatedTitleText(
				notification.metadata as IIssueCommentCreatedNotificationMetadata,
				translationService
			);
		case NotificationTypes.PlanningCreated:
			return getPlanningCreatedTitleText(
				notification.metadata as IPlanningCreatedNotificationMetadata,
				translationService
			);
		case NotificationTypes.RestrictedArea:
			return getRestrictedAreaTitleText(translationService);
		case NotificationTypes.SequenceProgressAnalysisReport:
			return getProgressAnalysisTitleText(translationService);
		case NotificationTypes.AreaSequenceItemStatusUpdate:
			return getAreaSequenceItemStatusUpdateCardTitleText(
				notification.metadata as IAreaSequenceItemStatusUpdateNotificationMetadata,
				translationService
			);
	}
};

export const getMessageBody = (notification: INotification, translationService: TranslationService): string | null => {
	switch (notification.type) {
		case NotificationTypes.DistressButton:
			return getDistressButtonDescriptionText(
				notification.metadata as IDistressButtonNotificationMetadata,
				translationService
			);
		case NotificationTypes.TagHealth:
			return getTagHealthDescriptionText(
				notification.metadata as ITagHealthNotificationMetadata,
				translationService
			);
		case NotificationTypes.DailyReport:
			return null;
		case NotificationTypes.AssignedIssue:
			return getIssueAssignedDescriptionText(notification.metadata as IIssueAssigneeUpdatedNotificationMetadata);
		case NotificationTypes.IssueStatusUpdated:
			return null;
		case NotificationTypes.CreatedIssues:
			return getCreatedIssuesDescriptionText(notification.metadata as ICreatedIssuesNotificationMetadata);
		case NotificationTypes.IssueCommentCreated:
			return getIssueCommentCreatedDescriptionText(
				notification.metadata as IIssueCommentCreatedNotificationMetadata
			);
		case NotificationTypes.PlanningCreated:
			return null;
		case NotificationTypes.RestrictedArea:
			return getRestrictedAreaDescriptionText(notification, translationService);
		case NotificationTypes.SequenceProgressAnalysisReport:
			return getProgressAnalysisDescriptionText(translationService);
		case NotificationTypes.AreaSequenceItemStatusUpdate:
			return getAreaSequenceItemStatusUpdateCardDescriptionText(
				translationService,
				notification.metadata as IAreaSequenceItemStatusUpdateNotificationMetadata
			);
	}
};

export const getNotificationMessage = (
	notification: INotification,
	translationService: TranslationService
): INotificationTitleAndBody => {
	const title: string = getMessageTitle(notification, translationService);
	const body: string | null = getMessageBody(notification, translationService);

	return {
		title,
		...(body && { body }),
	};
};

export const getNotificationRouteByType = (type: NotificationTypes, customData?: string | Date): string => {
	const NotificationRoutes: { [key: string]: string } = {
		[NotificationTypes.DailyReport]: `/analysisCenter/${InvestigationReportTypes.projectOverview}/${customData}`,
		[NotificationTypes.TagHealth]: '/system/tags/battery',
		[NotificationTypes.PlanningCreated]: `/workplan/dateFrom/${customData}`,
		[NotificationTypes.IssueCommentCreated]: `/issues/${customData}`,
		[NotificationTypes.AssignedIssue]: `/issues/${customData}`,
		[NotificationTypes.IssueStatusUpdated]: `/issues/${customData}`,
		[NotificationTypes.CreatedIssues]: `/issues`,
		[NotificationTypes.SequenceProgressAnalysisReport]: `/workplan/projectProgressAnalysis`,
		[NotificationTypes.AreaSequenceItemStatusUpdate]: `/workplan`,
	};
	return NotificationRoutes[type];
};
