import React, { useContext } from 'react';
import { config } from '../../../../config';
import { IMainPageWidgetActivityData } from '@interfaces/IMainPageWidgetActivityData';
import classes from './styles.module.scss';
import { useSelector } from 'react-redux';
import { selectTimezone } from '@store/slices/project.slice';
import { MainPageContext } from '@src/Components/Pages/MainPage';
import { useFilteredMainPageActivities } from '@shared/hooks/useFilteredMainPageActivities.hook';
import {
	IWidgetContext,
	useWidgetContextValue,
	WidgetContext,
} from '@src/Components/WorkplanWidgetComponents/WeeklyWidget/widgetContext.context';
import ActivitiesLottieLoader from '@src/Components/DashboardSections/DashboardLoaders/CardsLoader.json';
import {
	DashboardTabEmptyState,
	EmptyStateResourcesEnum,
} from '@shared/components/DashboardTabEmptyState/DashboardTabEmptyState';
import { WeeklyWidgetHeader } from '@src/Components/WorkplanWidgetComponents/WeeklyWidget/WeeklyWidgetHeader/WeeklyWidgetHeader';
import { WidgetActivityFilters } from '@shared/interfaces/WidgetActivityFilters';
import { WidgetOpenActivitiesList } from '@src/Components/WorkplanWidgetComponents/WeeklyWidget/WidgetOpenActivitiesList/WidgetOpenActivitiesList';
import { WeeklyWidgetActivityCard } from '@src/Components/WorkplanWidgetComponents/WeeklyWidget/WeeklyWidgetActivityCard/WeeklyWidgetActivityCard';
import Lottie from 'react-lottie';

interface IWorkplanWidget {
	widgetActivities: IMainPageWidgetActivityData[];
	widgetHeaderClass?: string;
}

const WorkplanWidget = (props: IWorkplanWidget) => {
	const tz: string = useSelector(selectTimezone)!;
	const {
		setWidgetActivities,
		onActivityUpdate,
		widgetActivityFilters,
		setWidgetActivityFilters,
		areWidgetActivitiesFetched,
	} = useContext(MainPageContext)!;
	const {
		selectedActivities,
		overdueActivities,
		noProgressActivities,
		dueInSevenDaysActivities,
		futureActivities,
		forReviewActivities,
		completedActivities,
		toDoActivitiesCount,
		inProgressActivities,
		dueTodayActivities,
	} = useFilteredMainPageActivities(props.widgetActivities, widgetActivityFilters, tz);

	const widgetContextValue: IWidgetContext = useWidgetContextValue(setWidgetActivities, onActivityUpdate);

	if (!props.widgetActivities || !areWidgetActivitiesFetched) {
		return (
			<div className={classes.loaderContainer}>
				<Lottie
					options={{
						loop: true,
						autoplay: true,
						animationData: ActivitiesLottieLoader,
					}}
				/>
			</div>
		);
	}

	if (props.widgetActivities.length === 0) {
		return <DashboardTabEmptyState resource={EmptyStateResourcesEnum.activities} createLink={'/workplan'} />;
	}

	return (
		<div className={classes.wrapper} style={{ padding: !config.showWeeklyWidget ? '0px 8px' : '' }}>
			<WidgetContext.Provider value={widgetContextValue}>
				<div className={classes.widgetContainer} data-testid={'widgetContainer'}>
					<WeeklyWidgetHeader
						widgetHeaderClass={props.widgetHeaderClass}
						forReviewActivitiesCount={forReviewActivities?.length}
						toDoActivities={toDoActivitiesCount}
						noProgressActivitiesCount={noProgressActivities?.length}
						inProgressActivitiesCount={inProgressActivities?.length}
						completedActivitiesCount={completedActivities?.length}
						setWidgetActivityFilters={setWidgetActivityFilters}
					/>
					<div className={classes.widgetBody} data-testid={'widgetBody'}>
						{widgetActivityFilters === WidgetActivityFilters.toDo ? (
							<WidgetOpenActivitiesList
								overdueActivities={overdueActivities!}
								dueTodayActivities={dueTodayActivities!}
								dueInSevenDaysActivities={dueInSevenDaysActivities!}
								futureActivities={futureActivities!}
							/>
						) : (
							selectedActivities?.map((activity) => (
								<WeeklyWidgetActivityCard key={activity.groupId} activity={activity} />
							))
						)}
					</div>
				</div>
			</WidgetContext.Provider>
		</div>
	);
};

export { WorkplanWidget };
