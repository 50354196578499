import React from 'react';
import { TrusstorIcon } from '@src/Components/TrusstorIcon/TrusstorIcon';
import classes from './styles.module.scss';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { IconColor, IconSize } from '@shared/components/TrusstorIconShared/TrusstorIconShared';

interface IIssueCreateButtonProps {
	onClick?: () => void;
}

export const IssueCreateButton = (props: IIssueCreateButtonProps) => {
	return (
		<div className={classes.createButton} onClick={props.onClick}>
			<TrusstorIcon iconName={IconNames.plus} color={IconColor.White} size={IconSize.SMALL} />
		</div>
	);
};
