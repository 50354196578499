import React from 'react';
import classnames from 'classnames';
import { IssueStatus } from '../../../interfaces/IIssueShared';
import { ITrusstorButtonType, TrusstorButton } from '../../buttons/TrusstorButton/TrusstorButton';
import classes from './styles.module.scss';
import { TranslationService } from '../../../services/translation.service';
import { getCTAText, getStatusToUpdate } from './utils';

interface ICompleteButtonProps {
	issueStatus: IssueStatus;
	handleStatusChange: (updatedStatus: IssueStatus) => Promise<void>;
	itemHovered: boolean;
	translationService: TranslationService;
	className?: any;
	isLimitedUser: boolean;
	handleReviewCallback: () => void;
}

export const IssueStatusChangeButton = (props: ICompleteButtonProps) => {
	const ctaText: string = getCTAText(props.isLimitedUser, props.issueStatus, props.translationService);

	const classesToRender: string = classnames({
		[classes.completeButton]: true,
		[classes.itemHovered]: props.itemHovered && props.issueStatus !== IssueStatus.COMPLETED,
		[props.className]: !!props.className,
	});

	const handleCTAClick = (e: React.MouseEvent) => {
		e.stopPropagation();
		const statusToUpdate: IssueStatus | void = getStatusToUpdate(props.isLimitedUser, props.issueStatus, () =>
			props.handleReviewCallback()
		);
		if (statusToUpdate) {
			props.handleStatusChange(statusToUpdate);
		}
	};

	if (props.isLimitedUser && props.issueStatus === IssueStatus.COMPLETED) {
		return null;
	}
	return (
		<TrusstorButton
			text={ctaText}
			handleClick={handleCTAClick}
			buttonType={ITrusstorButtonType.OUTLINE}
			className={classesToRender}
		/>
	);
};
