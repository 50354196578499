import { ILoginRequestStatus, LoginRequestStatuses } from '@shared/interfaces/ILoginReducerState';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import userNewIcon from '@assets/login/email.svg';
import lockNewIcon from '../../../../assets/login/lockNewIcon.svg';
import { HttpStatusCode } from '../../../../constants';
import { IRootState } from '../../../../store/slices';
import { setLoginStateData } from '../../../../store/slices/login.slice';
import { login } from '../../../../store/thunks';
import { AuthPage } from '../AuthPage';
import classes from './styles.module.scss';
import { TrusstorTextInput } from '@shared/components/Inputs/TrusstorTextInput/TrusstorTextInput';
import { Languages } from '@shared/constants/languages';
import classnames from 'classnames';
import { useRenderMonitoring } from '@shared/hooks/monitoring.hooks';

interface ILoginPageComponentProps {
	match: any;
	location: any;
	history: any;
}

const LoginPage = (props: ILoginPageComponentProps) => {
	useRenderMonitoring('LoginPage');
	const [username, setUsername] = useState<string>('');
	const [password, setPassword] = useState<string>('');
	const [usernameError, setUsernameError] = useState<string>('');
	const [passwordError, setPasswordError] = useState<string>('');
	const [generalError, setGeneralError] = useState<string>('');
	const loginRequestStatus: ILoginRequestStatus = useSelector(
		(state: IRootState) => state.loginReducer.loginRequestStatus
	);
	const dispatch = useDispatch();
	document.body.dir = 'ltr';
	useEffect(() => {
		if (loginRequestStatus.state === LoginRequestStatuses.ERROR) {
			setLoginErrorMessage(loginRequestStatus.statusCode, loginRequestStatus.responseMessage);
		}
	}, [loginRequestStatus.state]);

	const onLoginClick = (username: string, password: string) => {
		if (isUsernameValid(username) && isPasswordValid(password)) {
			dispatch(
				login({
					username,
					password,
					from: props.location?.state?.from?.pathname,
					history: props.history,
				})
			);
		}
	};

	const setLoginErrorMessage = (errorStatus: number | undefined, errorMessage: string | undefined) => {
		if (!errorMessage) {
			setGeneralError(`Unexpected server response, please contact support.`);
			return;
		}
		if (errorStatus === HttpStatusCode.unauthorized && errorMessage.includes('password')) {
			setPasswordError('Invalid password');
			return;
		}
		if (errorStatus === HttpStatusCode.unauthorized && errorMessage.includes('User')) {
			setUsernameError('Invalid email address');
			return;
		}
		if (errorMessage.includes('timeout') || errorStatus === HttpStatusCode.requestTimeout) {
			setGeneralError(`Server connection timeout please contact support`);
			return;
		}
		setGeneralError(`Unexpected server response, please contact support.`);
	};

	const onUsernameInputChange = (username: string) => {
		setLoginStateBackToNullIfError();
		setUsernameError('');
		setUsername(username);
	};

	const onPasswordInputChange = (password: string) => {
		setLoginStateBackToNullIfError();
		setPasswordError('');
		setPassword(password);
	};

	const setLoginStateBackToNullIfError = () => {
		if (loginRequestStatus.state === LoginRequestStatuses.ERROR) {
			dispatch(
				setLoginStateData({
					loginRequestStatus: { state: LoginRequestStatuses.INITIAL },
				})
			);
		}
	};

	const isUsernameValid = (username: string): boolean => {
		if (!username || username.trim().length === 0) {
			setUsernameError('Email required');
			return false;
		}
		return true;
	};

	const isPasswordValid = (password: string) => {
		if (!password || password.trim().length === 0) {
			setPasswordError('Password required');
			return false;
		}
		return true;
	};

	const redirectToResetPassword = () => {
		props.history.push(`/resetPassword/${Languages.ENGLISH_US}`);
	};

	return (
		<AuthPage
			buttonText={'Login'}
			onButtonClick={() => onLoginClick(username, password)}
			showLoaderButton={loginRequestStatus.state === LoginRequestStatuses.LOADING}
			title={'Welcome to Trusstor'}
			errorText={generalError}
			submitOnEnter={true}
			subLink={{
				text: 'Reset Password',
				onClick: redirectToResetPassword,
			}}
		>
			<div className={classnames(classes.loginInput, { [classes.gap]: usernameError.length > 0 })}>
				<TrusstorTextInput
					changeFunc={onUsernameInputChange}
					startIcon={userNewIcon}
					value={username}
					isError={usernameError.length > 0}
					errorText={usernameError}
					type={'email'}
					testId="username-input"
					placeholder={'Email'}
				/>
				<TrusstorTextInput
					value={password}
					changeFunc={onPasswordInputChange}
					startIcon={lockNewIcon}
					errorText={passwordError}
					isError={passwordError.length > 0}
					testId="password-input"
					type={'password'}
					placeholder={'Password'}
				/>
			</div>
		</AuthPage>
	);
};

export { LoginPage };
