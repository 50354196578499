import React, { useState, useEffect, useRef, useMemo } from 'react';
import { forEach } from 'lodash';
import classes from './styles.module.scss';
import { TrusstorIcon } from '../TrusstorIcon/TrusstorIcon';
import { translationService } from '@src/servicesInitializers';
import {
	ITrusstorIconButtonStyle,
	TrusstorIconButtonDeprecated,
} from '@shared/components/buttons/TrusstorIconButton/TrusstorIconButtonDeprecated';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { IconColor } from '@shared/components/TrusstorIconShared/TrusstorIconShared';
import useCarouselDimensions from './Carousel.hooks';

interface IDynamicCarouselProps {
	numItems: number;
	renderItem: (index: number) => JSX.Element;
	maxItemsToShow?: number;
	selectedIndex?: number;
}

export const Carousel = (props: IDynamicCarouselProps) => {
	const carouselRef: any = useRef(null);
	const { itemsToShow, itemsWidths } = useCarouselDimensions(carouselRef, props.numItems);
	const [currentIndex, setCurrentIndex] = useState(0);
	const isRtl: boolean = translationService.getIsRtl();

	const shiftWidth = useMemo(
		() => itemsWidths.slice(0, currentIndex).reduce((acc, width) => acc + width, 0),
		[itemsWidths, currentIndex]
	);

	const moveBackward = () => {
		setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
	};

	const moveForward = () => {
		setCurrentIndex((prevIndex) =>
			prevIndex < props.numItems - itemsToShow ? prevIndex + 1 : props.numItems - itemsToShow
		);
	};

	useEffect(() => {
		if (props.selectedIndex !== undefined) {
			const newIndex: number = Math.max(0, Math.min(props.selectedIndex, props.numItems - itemsToShow));
			setCurrentIndex(newIndex);
		}
	}, [props.selectedIndex, props.numItems, itemsToShow]);

	const shouldShowArrows: boolean = props.numItems > itemsToShow;
	const itemsContainerMaxWidth: number | undefined = useMemo(() => {
		if (!props.maxItemsToShow) {
			return undefined;
		}
		const containedItemsWidth: number = itemsWidths
			.slice(0, props.maxItemsToShow)
			.reduce((acc, width) => acc + width, 0);
		if (containedItemsWidth > 0) {
			return containedItemsWidth;
		}
	}, [itemsWidths, props.maxItemsToShow]);

	return (
		<div className={classes.carouselContainer}>
			{shouldShowArrows && (
				<TrusstorIconButtonDeprecated
					onClick={moveBackward}
					style={ITrusstorIconButtonStyle.OUTLINE}
					iconClassName={classes.arrow}
					disabled={currentIndex === 0}
					iconElement={
						<TrusstorIcon
							color={currentIndex === 0 ? IconColor.Grey100 : IconColor.DarkBlue}
							iconName={isRtl ? IconNames.chevronRight : IconNames.chevronLeft}
						/>
					}
				/>
			)}
			<div className={classes.viewport}>
				<div
					className={classes.itemsContainer}
					style={{
						transform: `translateX(${isRtl ? '' : '-'}${shiftWidth}px)`,
						maxWidth: itemsContainerMaxWidth,
					}}
					ref={carouselRef}
				>
					{Array.from({ length: props.numItems }, (_, index) => (
						<div key={index} className={classes.item}>
							{props.renderItem(index)}
						</div>
					))}
				</div>
			</div>
			{shouldShowArrows && (
				<TrusstorIconButtonDeprecated
					onClick={moveForward}
					style={ITrusstorIconButtonStyle.OUTLINE}
					iconClassName={classes.arrow}
					disabled={currentIndex === props.numItems - itemsToShow}
					iconElement={
						<TrusstorIcon
							color={
								currentIndex === props.numItems - itemsToShow ? IconColor.Grey100 : IconColor.DarkBlue
							}
							iconName={isRtl ? IconNames.chevronLeft : IconNames.chevronRight}
						/>
					}
				/>
			)}
		</div>
	);
};
