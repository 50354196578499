import { IProjectMetrics } from '@shared/interfaces/IProjectMetrics';
import { useQuery } from '@tanstack/react-query';
import { requestService } from '@src/index';
import { IProject } from '@shared/interfaces/IProject';

export const useTodayProjectMetrics = (projectId: string, tz: string): IProjectMetrics => {
	const { data: projectMetrics } = useQuery(
		['todayProjectMetrics', projectId],
		() => requestService.get(`/metrics/todayMetricsData?projectId=${projectId}&tz=${tz}`),
		{
			useErrorBoundary: true,
		}
	);

	return projectMetrics;
};

export const useOverallProjectMetricsScore = (projectId: string): { [key: string]: number } => {
	const { data: overallScore } = useQuery(
		['overallProjectMetricsScore', projectId],
		() => requestService.get(`/metrics/overallProjectScore?projectId=${projectId}`),
		{
			useErrorBoundary: true,
		}
	);
	return overallScore;
};

export const useExecutiveViewEnabledProjectsQuery = (
	organizationId?: string
): {
	projects: IProject[];
} => {
	const { data: projects } = useQuery(
		['executiveViewProjects'],
		() => requestService.get(`/projectConfig/project/executiveViewEnabled?organizationId=${organizationId}`),
		{
			enabled: !!organizationId,
			useErrorBoundary: true,
		}
	);

	return { projects };
};
