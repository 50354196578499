import React from 'react';
import classes from './styles.module.scss';
import { IRadioButtonOption, IRadioButtonOptionWithOptionalInput } from '../IRadioButtonOption';
import GroupedAutoCompleteDropdown from '@shared/components/Dropdowns/GroupedAutoCompleteDropdown/GroupedAutoCompleteDropdown';

interface IRadioButtonItemProps {
	option: IRadioButtonOptionWithOptionalInput;
	onClickFunction: (option: IRadioButtonOption | undefined) => void;
	onDropDownChange?: (option: any) => void;
	isSelected: boolean;
	emptyDropdownStateComponent?: React.ReactNode;
	initialDropdownValue?: any;
}

export const RadioButtonItem = (props: IRadioButtonItemProps) => {
	const onClick = () => {
		const { dropdownProps, ...option } = props.option;
		props.onClickFunction(option);
		!props.option.dropdownProps && props.onDropDownChange?.(undefined);
	};

	const handleDropdownChange = (optionValue: any) => {
		props.onDropDownChange?.(optionValue);
	};

	return (
		<div className={classes.masterWrapper} data-testid="RadioButtonItem" onClick={onClick}>
			<div className={classes.wrapper}>
				<div className={`${classes.radioButton} ${props.isSelected ? classes.selected : ''}`} />
				<div className={classes.textWrapper}>
					<div className={classes.title}>{props.option.title}</div>
					<div className={classes.description}>{props.option.description}</div>
				</div>
			</div>
			{props.isSelected && props.option.dropdownProps && (
				<div className={classes.dropdownWrapper}>
					{props.option.dropdownProps.options.length > 0 ? (
						<GroupedAutoCompleteDropdown
							{...props.option.dropdownProps}
							value={props.initialDropdownValue}
							onChange={handleDropdownChange}
							optionStyleAdditionalClass={classes.selectAllOptionClass}
						/>
					) : (
						props.emptyDropdownStateComponent
					)}
				</div>
			)}
		</div>
	);
};
