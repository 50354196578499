import React from 'react';
import { translationService } from '@src/servicesInitializers';
import { SequenceLinkWarningDialog } from '@src/Components/SequenceItemLinkTable/SequenceLinkTableContainer/WarningDialogs/SequenceLinkWarningDialog/SequenceLinkWarningDialog';

interface IBulkSingleUnlinkWarningDialogProps {
	show: boolean;
	hideDialog: () => void;
	unlinkSingleSequenceItemAction: () => void;
}

export const BulkSingleUnlinkWarningDialog = (props: IBulkSingleUnlinkWarningDialogProps) => {
	return (
		<SequenceLinkWarningDialog
			show={props.show}
			hideDialog={props.hideDialog}
			actionFunction={props.unlinkSingleSequenceItemAction}
			title={translationService.get('sequence_single_unlink_table_warning_dialog_title')}
			content={translationService.get(
				'sequence_unlink_table_single_warning_dialog_some_items_are_linked_toOther'
			)}
		/>
	);
};
