import React from 'react';
import { DropdownDisplay } from '../Displays/DropdownDisplay/DropdownDisplay';
import { useDropdownKeyboardNavigation } from '../SingleDropdown/singleSelectionDropdown.utils';
import { useMultipleSelectionDropdown } from './multipleSelectionDropdown.utils';
import { IMultipleDropdownProps } from '../dropdown.interface';
import { DropdownFooter } from './MultipleDropdownFooter';

function MultipleDropdownShared<T>(props: IMultipleDropdownProps<T>) {
	const {
		filteredOptions,
		isDropdownOpen,
		dropdownRef,
		highlightedIndex,
		setHighlightedIndex,
		handleInputChange,
		handleOptionClick,
		handleOptionHover,
		setIsDropdownOpen,
		handleInputFocusChange,
		valueForInput,
		optionsContainerRef,
		handleClearClick,
		getIsSelected,
	} = useMultipleSelectionDropdown(props);

	const handleKeyDown = useDropdownKeyboardNavigation(
		highlightedIndex,
		setHighlightedIndex,
		optionsContainerRef,
		filteredOptions,
		handleOptionClick,
		false
	);

	return (
		<DropdownDisplay
			showCheckbox
			shouldNotSelectInputTextOnFocus
			handleClearClickCallback={handleClearClick}
			isError={props.isError}
			hideClearTextButton={props.hideClearTextButton}
			dropdownRef={dropdownRef}
			optionsContainerRef={optionsContainerRef}
			isDropdownOpen={isDropdownOpen}
			handleInputChange={handleInputChange}
			valueForInput={valueForInput}
			placeholder={props.placeholder}
			label={props.label}
			disabled={props.disabled}
			required={props.required}
			handleInputFocusChange={handleInputFocusChange}
			filteredOptions={filteredOptions}
			highlightedIndex={highlightedIndex}
			setIsDropdownOpen={setIsDropdownOpen}
			handleKeyDown={handleKeyDown}
			handleOptionClick={handleOptionClick}
			handleOptionHover={handleOptionHover}
			getDisplayOption={props.getDisplayOption}
			getSecondDisplayOption={props.getSecondDisplayOption}
			testId={props.testId}
			getIsSelected={getIsSelected}
			startIcon={props.startIcon}
			footer={<DropdownFooter setIsDropdownOpen={setIsDropdownOpen} />}
		/>
	);
}

export { MultipleDropdownShared };
