import React from 'react';
import { InputAdornment, TextField } from '@material-ui/core';
import classes from './styles.module.scss';
import { Directions } from '../../../interfaces/Directions';

interface ITrusstorTextInputProps {
	direction: Directions;
	placeholder?: string;
	label?: string;
	value?: string;
	errorText?: string;
	isError?: boolean;
	changeFunc?: (value: any) => void;
	disabled?: boolean;
	type?: string;
	required?: boolean;
	startAdornmentType?: 'color' | 'icon';
	iconAdornmentSource?: string;
	colorAdornmentValue?: string;
	params?: any;
	name?: string;
	sendFullEvent?: boolean;
	onClick?: () => void;
	maxLength?: number;
	disabledEnterKey?: boolean;
	inputStyle?: string;
	inputRef?: any;
	testId?: string;
	onBlur?: (e: any) => void;
	disableAutoCapitalize?: boolean;
	inputContainerClassName?: string;
}

// Deprecated, use DropdownTextInput instead
const MuiTrusstorTextInput = React.forwardRef((props: ITrusstorTextInputProps, ref) => {
	const getColorAdornmentBorderStyle = (): string => {
		if (
			!props.colorAdornmentValue ||
			props.colorAdornmentValue === '' ||
			props.colorAdornmentValue === 'transparent'
		) {
			return 'solid 1px black';
		}

		return '';
	};

	const handleChange = (event: any) => {
		if (props.sendFullEvent) {
			props.changeFunc && props.changeFunc(event);
			return;
		}
		props.changeFunc && props.changeFunc(event.target.value);
	};

	return (
		<div>
			<div
				className={`${classes.inputContainer} ${
					props.inputContainerClassName ? props.inputContainerClassName : ''
				}`}
			>
				<TextField
					onBlur={props.onBlur}
					data-testid={props.testId ?? null}
					onClick={props.onClick}
					ref={ref}
					inputRef={props.inputRef}
					{...props.params}
					name={props.name}
					required={props.required}
					value={props.disabled && !props.value ? ' ' : props.value}
					onChange={handleChange}
					classes={{ root: `${classes.root}` }}
					label={props.label || props.placeholder}
					variant={'filled'}
					disabled={props.disabled}
					InputProps={{
						...props.params?.InputProps,
						...(props.disableAutoCapitalize && { autoCapitalize: 'off' }),
						className: classes.textInput,
						classes: {
							underline: classes.underline,
							input: `${classes.textInput} ${classes.multiline} ${props.inputStyle || ''}`,
						},
						...(props.startAdornmentType && {
							startAdornment: (
								<InputAdornment position="start">
									{props.startAdornmentType === 'icon' ? (
										<img className={classes.adornment} alt="" src={props.iconAdornmentSource} />
									) : (
										<div
											className={`${classes.adornment} ${classes.colorAdornment}`}
											style={{
												backgroundColor: props.colorAdornmentValue,
												border: getColorAdornmentBorderStyle(),
											}}
										/>
									)}
								</InputAdornment>
							),
						}),
					}}
					inputProps={{
						...props.params?.inputProps,
						...(props.disableAutoCapitalize && { autoCapitalize: 'off' }),
						maxLength: props.maxLength,
						onKeyDown: (e) => {
							if (props.disabledEnterKey && e.key === 'Enter') {
								e.stopPropagation();
							}
						},
					}}
					InputLabelProps={{
						className: props.startAdornmentType
							? classes[`labelAdornment-${props.direction}`]
							: classes.label,
					}}
					type={props.type || 'text'}
				/>
				<div className={classes.errorContainer}>
					{props.isError && (
						<p className={classes.errorText} data-testid="input-error">
							{props.errorText}
						</p>
					)}
				</div>
			</div>
		</div>
	);
});

export { MuiTrusstorTextInput };
