import { SpecialFloorsContainer } from '../MainViewFloorSections/SpecialFloorsContainer/SpecialFloorsContainer';
import React, { useContext, useEffect } from 'react';
import { IMergedEquipmentView } from '../../interfaces/IMergedEquipmentView';
import { IRootState } from '../../store/slices';
import { useSelector } from 'react-redux';
import { getBuildingSvgWidth } from '../../utils/svgHelpers';
import { Dictionary, groupBy } from 'lodash';
import { IMergedFloorView } from '../../interfaces/IMergedFloorView';
import { IFloorData } from '../../interfaces/IFloorData';
import { IBuildingSvgPoints } from '../../interfaces/IBuildingSvgPoints';
import { FloorProfessionsEquipment } from '../MainViewFloorSections/FloorProfessionsEquipment';
import { FloorManagers } from '../MainViewFloorSections/FloorManagers';
import { FloorSafety } from '../MainViewFloorSections/FloorSafety';
import { FloorScaffolds } from '../MainViewFloorSections/FloorScaffolds';
import { FloorElevators } from '../MainViewFloorSections/FloorElevators';
import { IMinimalSafetyEventForClient } from '../../interfaces/ISafetyEvent';
import { svgSelectedStyle } from '../../constants/genericStyles.constants';
import { RoleType } from '@shared/interfaces/RoleType.enum';
import { ISvgElementContainer } from '@interfaces/ISvgElementContainer';
import { FloorSvgElements } from './FloorSvgElements/FloorSvgElements';
import { IMainPageWidgetActivityData } from '@interfaces/IMainPageWidgetActivityData';
import { MainPageContext } from '../Pages/MainPage';
import { setElementStyle } from '@shared/utils/svg.utils';
import { IIssue } from '@shared/interfaces/IIssue';
import { filterFloorActivities } from '@src/Components/MainPageSections/utils';
import { accessLevelService } from '@src/servicesInitializers';

interface IBuildingDetailsProps {
	buildingSvg: string;
	svgPoints: IBuildingSvgPoints | null;
	floorsData: IFloorData[];
	setFloorPath: (floorPath: string) => void;
	openSafetyDialog: (safetyEvent: IMinimalSafetyEventForClient[]) => void;
}

export const BuildingDetails = (props: IBuildingDetailsProps) => {
	const equipment: IMergedEquipmentView[] = useSelector(
		(state: IRootState) => state.equipmentReducer.visibleActiveEquipment
	);
	const userRole: RoleType = useSelector(
		(state: IRootState) => state.loginReducer.loggedUser!.userDetails.permissions.roleType
	);
	const selectedFloor: IMergedFloorView | undefined = useSelector(
		(state: IRootState) => state.floorsViewReducer.selectedFloor
	);
	const scaffoldsPerFloor: Dictionary<IFloorData[]> = groupBy(
		props.floorsData.filter((floor) => floor?.floorId?.includes('scaffold')),
		'matchingFixedFloorId'
	);
	const elevatorsPerFloor: Dictionary<IFloorData[]> = groupBy(
		props.floorsData.filter((floor) => floor?.isHoist),
		'matchingFixedFloorId'
	);
	const isUserAdmin: boolean = userRole === RoleType.TRUSSTOR_ADMIN;
	const { widgetActivities, issues } = useContext(MainPageContext)!;

	const renderSelectedFloor = (floorData: IFloorData): void | null => {
		if (floorData.floorId === 'storage') {
			return null;
		}

		if (floorData.floorId === selectedFloor?.floorId) {
			setElementStyle(`floor${selectedFloor.floorId}`, svgSelectedStyle);
			return;
		}
		setElementStyle(`floor${floorData.floorId}`, {
			opacity: '',
			fillOpacity: '',
			fill: '',
			stroke: '',
			strokeWidth: '',
		});
		return;
	};

	if (!props.buildingSvg || !props.svgPoints) {
		return null;
	}

	const specialFloors: IFloorData[] = props.floorsData.filter((floor) => floor.isSpecialFloor);

	const IsFloorChipsContainerSvg: boolean =
		props.svgPoints.chipElementsArray && props.svgPoints.chipElementsArray.length > 0;

	return (
		<React.Fragment>
			<SpecialFloorsContainer
				floorsData={specialFloors}
				equipment={equipment}
				openSafetyDialog={props.openSafetyDialog}
				isAdmin={isUserAdmin}
			/>
			{props.floorsData.map((floorData) => {
				const floorChipsContainer: ISvgElementContainer | undefined = props.svgPoints!.chipElementsArray.find(
					(chip) => chip.floorId === floorData.floorId
				);

				if (IsFloorChipsContainerSvg && floorChipsContainer) {
					const widgetFloorActivities: IMainPageWidgetActivityData[] = filterFloorActivities(
						widgetActivities,
						floorData?.floorId
					);
					const floorIssues: IIssue[] | undefined = issues?.filter((issue) =>
						issue.locations?.some((location) => location.floor.floorId === floorData?.floorId)
					);
					return (
						<FloorSvgElements
							key={`floorDetails_${floorData.floorId}`}
							floorData={floorData}
							svgPoints={props.svgPoints as IBuildingSvgPoints}
							setFloorPath={props.setFloorPath}
							floorElevators={elevatorsPerFloor[floorData.floorId]}
							floorScaffolds={scaffoldsPerFloor[floorData.floorId]}
							floorChipsContainer={floorChipsContainer}
							openSafetyDialog={props.openSafetyDialog}
							floorWidgetActivities={widgetFloorActivities}
							floorIssues={floorIssues || []}
						/>
					);
				}

				renderSelectedFloor(floorData);
				return (
					<div key={`floorDetails_${floorData.floorId}`} data-testid={`floorDetailsId=${floorData.floorId}`}>
						<FloorProfessionsEquipment
							floorData={floorData}
							svgPoints={props.svgPoints as IBuildingSvgPoints}
							selectedFloor={selectedFloor}
							setFloorPath={props.setFloorPath}
							equipmentData={equipment.filter((eq) => eq.floorId === floorData.floorId)}
							isAdmin={isUserAdmin}
							buildingSvgWidth={getBuildingSvgWidth()}
						/>
						<FloorManagers
							floorData={floorData}
							svgPoints={props.svgPoints as IBuildingSvgPoints}
							setFloorPath={props.setFloorPath}
							isAdmin={isUserAdmin}
						/>
						{accessLevelService.hasAccess('projectMonitor-safety') && (
							<FloorSafety
								floorData={floorData}
								svgPoints={props.svgPoints as IBuildingSvgPoints}
								openSafetyDialog={props.openSafetyDialog}
							/>
						)}
						<FloorScaffolds
							scaffolds={scaffoldsPerFloor[floorData.floorId]}
							svgPoints={props.svgPoints as IBuildingSvgPoints}
						/>
						<FloorElevators
							elevators={elevatorsPerFloor[floorData.floorId]}
							svgPoints={props.svgPoints as IBuildingSvgPoints}
						/>
					</div>
				);
			})}
		</React.Fragment>
	);
};
