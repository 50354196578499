import { IProfession, ITableTitleItems } from '@src/interfaces';
import { ISequenceItem } from '@interfaces/ISequenceItem';
import { orderBy } from 'lodash';
import { getTradeGroupTranslation, getTradeTranslation } from '@utils/translations.utils';
import { SORT_ORDER } from '@shared/constants/constants';
import { IMilestone } from '@shared/interfaces/IMilestone';

const tradeGroupSortFunctions = (profession: IProfession) => {
	return [
		profession.sortIndex,
		getTradeGroupTranslation(profession),
		getTradeTranslation(profession),
		profession.specialty,
		profession.contractor,
	];
};

export enum SequenceLinkFilterOptionsEnum {
	all = 'sequence_link_filter_option_All',
	linked = 'sequence_link_filter_option_Linked',
	unlinked = 'sequence_link_filter_option_Unlinked',
}

export const getFilteredSequenceItems = (
	sequenceItems: ISequenceItem[],
	filterOption: SequenceLinkFilterOptionsEnum
) => {
	switch (filterOption) {
		case SequenceLinkFilterOptionsEnum.linked:
			return sequenceItems.filter((sequenceItem) => sequenceItem.linkedMilestoneId);
		case SequenceLinkFilterOptionsEnum.unlinked:
			return sequenceItems.filter((sequenceItem) => !sequenceItem.linkedMilestoneId);
		default:
			return sequenceItems;
	}
};

export const getSortedSequenceItems = (
	sequenceItems: ISequenceItem[],
	sortColumn: string,
	sortOrder: SORT_ORDER,
	milestones: IMilestone[]
) => {
	switch (sortColumn) {
		case titleNamesEnum.SEQUENCE_ITEM:
			return orderBy(sequenceItems, (sequenceItem) => sequenceItem.description, [sortOrder]);

		case titleNamesEnum.PROFESSION:
			return orderBy(
				sequenceItems,
				(sequenceItem) => tradeGroupSortFunctions(sequenceItem.profession),
				sortOrder
			);

		case titleNamesEnum.LINKED_MILESTONE:
			return orderBy(
				sequenceItems,
				(sequenceItem) => {
					const linkedMilestone: IMilestone | undefined = milestones.find(
						(milestone) => milestone._id === sequenceItem.linkedMilestoneId
					);
					return linkedMilestone?.title;
				},
				[sortOrder]
			);

		default:
			return orderBy(sequenceItems, (seq) => seq.orderIndex);
	}
};

export const titleNamesEnum = {
	ORDER_INDEX: 'sequenceItem_milestones_table_orderIndex',
	SEQUENCE_ITEM: 'sequenceItem_milestones_table_itemName',
	PROFESSION: 'profession',
	LINKED_MILESTONE: 'sequenceItem_milestones_table_linkedMilestone',
};

export const columnWidthList: string[] = ['35%', '25%', '25%', '10%'];
