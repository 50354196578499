import axios, { AxiosError, AxiosPromise, AxiosRequestConfig, AxiosResponse } from 'axios';
import { VersionService } from './version.service';
import { IRequestHeaders } from '../interfaces/IRequsetHeaders';
import { HEADERS } from '../constants/constants';
import { IRequestOptions } from '../interfaces/IRequestOptions';
import { IUserService } from '../interfaces/IUserService';
import { IProjectService } from '../interfaces/IProjectService';
import { EnhancedStore } from '@reduxjs/toolkit';
import { IUser } from '../interfaces/IUser';
import { IProject } from '../interfaces/IProject';
import { captureMessage } from '../utils/monitoring.util';

class RequestService {
	constructor(
		private baseUrl: string,
		private userService: IUserService,
		private projectService: IProjectService,
		private versionService: VersionService,
		private store: EnhancedStore
	) {}

	public get = async (url: string, options?: IRequestOptions): Promise<any> => {
		try {
			const finalHeaders: IRequestHeaders = this.getRequestHeaders(options);
			const finalUrl: string = this.getApiUrl(url, options);
			const response: AxiosResponse<any> = await axios.get(finalUrl, {
				headers: finalHeaders,
				timeout: options?.timeout,
				params: options?.params,
			});
			return response.data;
		} catch (err: any) {
			err.message = `Failed to GET ${url}, received status code ${err.response?.status}: ${err.message}`;
			captureMessage(err.message);
			throw err;
		}
	};

	public post = async (url: string, options?: IRequestOptions): Promise<any> => {
		try {
			const finalHeaders: IRequestHeaders = this.getRequestHeaders(options);
			const finalUrl: string = this.getApiUrl(url, options);
			const response: AxiosResponse<any> = await axios.post(finalUrl, options?.body, {
				headers: finalHeaders,
				timeout: options?.timeout,
			});
			return response.data;
		} catch (err: any) {
			err.message = `Failed to POST ${url}, received status code ${err.response?.status}: ${err.message}`;
			captureMessage(err.message);
			throw err;
		}
	};

	public put = async (url: string, options?: IRequestOptions): Promise<any> => {
		try {
			const finalHeaders: IRequestHeaders = this.getRequestHeaders(options);
			const finalUrl: string = this.getApiUrl(url, options);
			const response: AxiosResponse<any> = await axios.put(finalUrl, options?.body, {
				headers: finalHeaders,
				timeout: options?.timeout,
			});
			return response.data;
		} catch (err: any) {
			err.message = `Failed to PUT ${url}, received status code ${err.response?.status}: ${err.message}`;
			captureMessage(err.message);
			throw err;
		}
	};

	public delete = async (url: string, options?: IRequestOptions): Promise<any> => {
		try {
			const finalHeaders: IRequestHeaders = this.getRequestHeaders(options);
			const finalUrl: string = this.getApiUrl(url, options);
			const response: AxiosResponse<any> = await axios.delete(finalUrl, {
				headers: finalHeaders,
				timeout: options?.timeout,
			});
			return response.data;
		} catch (err: any) {
			err.message = `Failed to DELETE ${url}, received status code ${err.response?.status}: ${err.message}`;
			captureMessage(err.message);
			throw err;
		}
	};

	public resendRequest = (config: AxiosRequestConfig): AxiosPromise<any> => axios(config);

	public addResponseErrorInterceptor = (callback: (error: AxiosError<any>) => void): void => {
		axios.interceptors.response.use(
			(response: AxiosResponse<any>) => response,
			(error: AxiosError<any>) => callback(error)
		);
	};

	public addRequestInterceptor = (callback: (req: AxiosRequestConfig) => Promise<AxiosRequestConfig>): void => {
		axios.interceptors.request.use(async (req: AxiosRequestConfig) => callback(req));
	};

	private getRequestHeaders = (options?: IRequestOptions) => {
		const defaultHeaders: IRequestHeaders = options?.disableAuth ? {} : this.getDefaultHeaders();

		return {
			...defaultHeaders,
			...options?.headers,
		};
	};

	public getDefaultHeaders = () => {
		try {
			const webClientVersion: string | null = this.versionService.getVersion();
			const userDetails: IUser | null = this.userService.getUserDetails();
			const userAccessToken: string | null = this.userService.getLoggedUserAccessToken();

			if (!userAccessToken) {
				return {
					[HEADERS.webClientVersion]: webClientVersion,
				};
			}

			const requestProject: IProject | undefined = this.store.getState().projectReducer.workingProject;

			return {
				[HEADERS.authorization]: `Bearer ${userAccessToken}`,
				[HEADERS.tz]: requestProject?.tz,
				[HEADERS.projectId]: requestProject?.projectId,
				[HEADERS.organizationId]: requestProject?.organizationId,
				[HEADERS.username]: userDetails?.username,
				[HEADERS.webClientVersion]: webClientVersion,
				[HEADERS.isDemo]: !!requestProject?.isDemo,
			};
		} catch (err: any) {
			console.error('Failed to get request headers', err);
			throw err;
		}
	};

	private getApiUrl = (url: string, options?: IRequestOptions): string =>
		options?.isGlobal ? url : `${this.baseUrl}${url}`;
}

export { RequestService };
