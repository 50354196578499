import React, { useState } from 'react';
import { IconColor, IconSize, TrusstorIconShared } from '../TrusstorIconShared/TrusstorIconShared';
import { IconNames } from '../TrusstorIconShared/IconNames.enum';

interface ITradeIconSharedProps {
	tradeId: string;
	iconColor?: IconColor;
	ignoreFallback?: boolean;
}

export const TradeIconShared = (props: ITradeIconSharedProps) => {
	const [isLoaded, setIsLoaded] = useState<boolean>(false);
	const [isFallbackSvgEmpty, setIsFallbackSvgEmpty] = useState<boolean>(false);
	const tradeIconName: string = `trade-${props.tradeId}`;
	const onLoad = () => {
		setIsLoaded(true);
	};
	const onFallbackEmptySvg = () => {
		setIsFallbackSvgEmpty(true);
	};
	if (isFallbackSvgEmpty) {
		return null;
	}

	const getDisplayStyle = () => {
		if (props.ignoreFallback) {
			return 'inherit';
		}
		return isLoaded ? 'inherit' : 'none';
	};

	return (
		<>
			<div style={{ display: getDisplayStyle() }}>
				<TrusstorIconShared
					iconName={tradeIconName as IconNames}
					color={props.iconColor}
					onLoad={onLoad}
					size={IconSize.SMALL}
				/>
			</div>
			{!isLoaded && !props.ignoreFallback && (
				<TrusstorIconShared
					iconName={IconNames.tradeGeneral}
					color={props.iconColor}
					onEmptySvg={onFallbackEmptySvg}
					size={IconSize.SMALL}
				/>
			)}
		</>
	);
};
