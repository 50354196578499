import { useFeatureFlagEnabled } from 'posthog-js/react';
import { FeatureFlagNamesEnum } from '../constants/featureFlags.enum';
import { Environments } from '../constants/environments';

export const useIsFeatureFlagEnabled = (featureName: FeatureFlagNamesEnum): boolean => {
	const isLocal: boolean =
		process.env.REACT_APP_STAGE === Environments.compose || process.env.REACT_APP_STAGE === Environments.dev;
	if (isLocal) {
		return true;
	}
	return !!useFeatureFlagEnabled(featureName);
};
