import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getTimezoneFormattedDate } from '../utils/dateUtils';
import { IRootState } from '../store/slices';
import { IDateRangesHookGetOptions } from '../interfaces';
import { getStaticManagers } from '../store/thunks';
import { resetSelectedManagers } from '../store/slices/managers.slice';

export const useManagers = ({
	withoutDateRanges,
	useVisibleWeek,
	useOnlyStartDate,
	includeDeleted,
}: IDateRangesHookGetOptions) => {
	const projectId: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.projectId);
	const tz: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.tz);
	const visibleDateRangeTimestamps: number[] = useSelector(
		(state: IRootState) => state.datePickerReducer.visibleDateRangeTimestamps
	);
	const visibleDateTimestamp: number = useSelector(
		(state: IRootState) => state.datePickerReducer.visibleDateTimestamp
	);
	const visiblePeriodTimestamps: [number, number] = useSelector(
		(state: IRootState) => state.datePickerReducer.visiblePeriodTimestamps
	);

	const dispatch = useDispatch();

	const SUNDAY: number = 0;
	const FRIDAY: number = 5;
	const visibleWeekStartDate: string = getTimezoneFormattedDate(tz, new Date(visibleDateRangeTimestamps[SUNDAY]));
	const visibleWeekEndDate: string = getTimezoneFormattedDate(tz, new Date(visibleDateRangeTimestamps[FRIDAY]));
	const visibleDate: string = getTimezoneFormattedDate(tz, new Date(visibleDateTimestamp));
	const visiblePeriodStartDate: string = getTimezoneFormattedDate(tz, new Date(visiblePeriodTimestamps[0]));
	const visiblePeriodEndDate: string = getTimezoneFormattedDate(tz, new Date(visiblePeriodTimestamps[1]));

	useEffect(() => {
		return () => {
			dispatch(resetSelectedManagers());
		};
	}, []);

	useEffect(() => {
		if (withoutDateRanges) {
			dispatch(getStaticManagers({ projectId }));
			return;
		}
		if (useVisibleWeek) {
			dispatch(
				getStaticManagers({
					projectId,
					startDate: visibleWeekStartDate,
					endDate: visibleWeekEndDate,
					includeDeleted: includeDeleted,
				})
			);
		} else if (useOnlyStartDate) {
			dispatch(
				getStaticManagers({
					projectId,
					startDate: visibleDate,
					includeDeleted: includeDeleted,
				})
			);
		} else {
			dispatch(
				getStaticManagers({
					projectId,
					startDate: visiblePeriodStartDate,
					endDate: visiblePeriodEndDate,
					includeDeleted: includeDeleted,
				})
			);
		}
	}, [
		visibleDateRangeTimestamps[0],
		visibleDateRangeTimestamps[visibleDateRangeTimestamps.length - 1],
		visibleDateTimestamp,
		visiblePeriodTimestamps[0],
		visiblePeriodTimestamps[1],
		projectId,
		includeDeleted,
		useVisibleWeek,
		useOnlyStartDate,
	]);
};
