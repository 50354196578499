import React from 'react';
import { BaseNotificationCard } from '../BaseNotificationCard/BaseNotificationCard';
import { shield } from '../../../assets/icons';
import classes from './styles.module.scss';
import { INotification } from '../../../interfaces/INotification';
import { IDistressButtonNotificationMetadata } from '../../../interfaces/IDistressButtonNotificationMetadata';
import { TranslationService } from '../../../services/translation.service';
import { RequestService } from '../../../services/request.service';
import { isTrusstorMobile } from '../../../utils/mobile.utils';

interface IDistressButtonCardProps {
	notification: INotification;
	translationService: TranslationService;
	tz: string;
	requester: RequestService;
	handleCardClick: () => void;
}

export const getDistressButtonTitleElement = (
	translationService: TranslationService,
	isDarkTheme?: boolean
): React.ReactNode => {
	const titleClass: string = `${classes.title} ${isDarkTheme ? classes.titleDarkTheme : ''}`;
	return <div className={titleClass}>{translationService.get('distressButtonPressed')}</div>;
};

export const getDistressButtonDescriptionElement = (
	metadata: IDistressButtonNotificationMetadata,
	translationService: TranslationService,
	isDarkTheme?: boolean
): React.ReactNode => {
	const descriptionClass: string = `${classes.description} ${isDarkTheme ? classes.descriptionDarkTheme : ''}`;

	return (
		<div className={descriptionClass}>
			<strong>
				{translationService.get('tag')} {metadata.tagId}, {metadata.name}
			</strong>{' '}
			{translationService.get('wasPressed')}{' '}
			<strong>
				{metadata.floorNick} {metadata.areaNick}
			</strong>
		</div>
	);
};

export const getShieldIconElement = (): React.ReactNode => {
	return <img className={classes.icon} src={shield} alt={''} />;
};

export const DistressButtonCard = (props: IDistressButtonCardProps) => {
	return (
		<BaseNotificationCard
			icon={getShieldIconElement()}
			titleElement={getDistressButtonTitleElement(props.translationService)}
			descriptionElement={getDistressButtonDescriptionElement(
				props.notification.metadata as IDistressButtonNotificationMetadata,
				props.translationService
			)}
			createdAt={props.notification.createdAt}
			tz={props.tz}
			translationService={props.translationService}
			requester={props.requester}
			notificationId={props.notification._id}
			handleCardClick={props.handleCardClick}
			isViewed={props.notification.isViewed}
			isClickable={!isTrusstorMobile()}
		/>
	);
};
