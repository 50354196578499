import { ITableComponentProps } from '@interfaces/ITableComponentProps';
import React, { ReactElement } from 'react';
import { ISequenceItem } from '@interfaces/ISequenceItem';
import { TrusstorCheckbox } from '@shared/components/TrusstorCheckbox/TrusstorCheckbox';
import classes from './styles.module.scss';
import classnames from 'classnames';

interface ISequenceItemNameAndActionProps {
	sequenceItem: ISequenceItem;
	isSelected: boolean;
}

export const SequenceItemNameAndAction = (
	props: ITableComponentProps<ISequenceItemNameAndActionProps>
): ReactElement<ITableComponentProps<ISequenceItem>> => {
	const sequenceItem: ISequenceItem = props.data.sequenceItem;
	return (
		<div className={classes.SequenceItemNameAndActionCell}>
			<div>
				<TrusstorCheckbox
					checked={props.data.isSelected}
					onClick={(e) => {}}
					onChange={(e) => {
						e.stopPropagation();
					}}
				/>
			</div>

			{sequenceItem.description}
		</div>
	);
};
