import { IProfession } from '@shared/interfaces/IProfession';
import { getTimezoneFormattedDate } from '@utils/dateUtils';
import { IAssignedActivity } from '@src/interfaces';
import { IUnassignedActivity } from '@interfaces/IUnassignedActivity';
import { requestService } from '@src/servicesInitializers';

export const getDoesProfessionHasTagsAssigned = async (profession: IProfession, projectId: string) => {
	const projectStaticTags = await requestService.get(`/siteNetIntegrator/static/tags/${projectId}`);
	return projectStaticTags.some((tag) => tag.profession?._id === profession._id);
};

export const getDoesProfessionHasActivitiesAssigned = async (
	profession: IProfession,
	tz: string,
	projectId: string
) => {
	const dateFrom: string = getTimezoneFormattedDate(tz, new Date());
	const projectActivities: {
		[profession: string]: (IAssignedActivity | IUnassignedActivity)[];
	} = await requestService.get(
		`/activities/activitiesBetweenDates?projectId=${projectId}&dateFrom=${dateFrom}&tz=${tz}`
	);
	const doesActivitiesIncludeProfession: boolean = Object.values(projectActivities).some(
		(activities: (IAssignedActivity | IUnassignedActivity)[]) =>
			activities.find((activity) => (activity as IAssignedActivity).profession?._id === profession._id)
	);

	if (doesActivitiesIncludeProfession) {
		return true;
	}

	return false;
};

export const getProjectsByOrganizationId = async (organizationId: string) => {
	return requestService.get(`/projectConfig/organization/${organizationId}/projects `);
};
