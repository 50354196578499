import React, { useState } from 'react';
import classnames from 'classnames';
import classes from './styles.module.scss';
import { Loader } from '../../Loader/Loader';

export enum ITrusstorButtonType {
	PRIMARY = 'primary',
	PRIMARY_COLORED = 'primary-colored',
	PRIMARY_WITH_LIGHT_HOVER = 'primary-with-light-hover',
	SECONDARY = 'secondary',
	OUTLINE = 'outline',
	OUTLINE_POSITIVE = 'outline-positive',
	OUTLINE_NEGATIVE = 'outline-negative',
	GHOST = 'ghost',
	GHOST_COLOURED = 'ghost-coloured',
}

export enum ITrusstorButtonSize {
	SMALL = 'small',
	DEFAULT = 'default',
	LARGE = 'large',
}

interface ITrusstorButtonProps {
	text: string;
	buttonType?: ITrusstorButtonType;
	buttonSize?: ITrusstorButtonSize;
	handleClick?: (arg0: React.MouseEvent<HTMLButtonElement, MouseEvent>) => any;
	className?: any;
	disabled?: boolean;
	showLoader?: boolean;
	startIcon?: string;
	endIcon?: string;
	iconElement?: React.ReactNode;
	hoverIconElement?: React.ReactNode;
	hoverText?: string;
	style?: React.CSSProperties;
	testId?: string;
	iconClassName?: string;
	loaderSize?: number;
	selected?: boolean;
}

const getTypeClassname = (buttonType?: ITrusstorButtonType) => {
	if (!buttonType) {
		return classes.primary;
	}
	switch (buttonType) {
		case ITrusstorButtonType.PRIMARY:
			return classes.primary;
		case ITrusstorButtonType.PRIMARY_COLORED:
			return classes.primaryColored;
		case ITrusstorButtonType.PRIMARY_WITH_LIGHT_HOVER:
			return classes.primaryWithLightHover;
		case ITrusstorButtonType.SECONDARY:
			return classes.secondary;
		case ITrusstorButtonType.OUTLINE:
			return classes.outline;
		case ITrusstorButtonType.OUTLINE_POSITIVE:
			return classes.outlinePositive;
		case ITrusstorButtonType.OUTLINE_NEGATIVE:
			return classes.outlineNegative;
		case ITrusstorButtonType.GHOST:
			return classes.ghost;
		case ITrusstorButtonType.GHOST_COLOURED:
			return classes.ghostColored;
		default:
			return classes.primary;
	}
};

const getSizeClassname = (buttonSize?: ITrusstorButtonSize) => {
	if (!buttonSize) {
		return classes.defaultButton;
	}
	switch (buttonSize) {
		case ITrusstorButtonSize.SMALL:
			return classes.smallButton;
		case ITrusstorButtonSize.LARGE:
			return classes.largeButton;
		default:
			return classes.defaultButton;
	}
};

const TrusstorButton = (props: ITrusstorButtonProps) => {
	const [isHovered, setIsHovered] = useState<boolean>(false);

	const buttonClassnames: string = classnames({
		[classes.selected]: props.selected,
		[classes.trusstorButton]: true,
		[getTypeClassname(props.buttonType)]: true,
		[getSizeClassname(props.buttonSize)]: true,
		[props.className]: !!props.className,
		[classes.disabled]: props.disabled,
		[classes.startPadding]: !props.startIcon,
		[classes.hasIconElement]: props.iconElement,
		[classes.endPadding]: !props.endIcon,
	});

	const getIconElement = () => {
		if (isHovered && props.hoverIconElement) {
			return props.hoverIconElement;
		}
		return props.iconElement;
	};

	const getText = () => {
		if (isHovered && props.hoverText) {
			return props.hoverText;
		}
		return props.text;
	};

	return (
		<button
			className={buttonClassnames}
			onClick={props.handleClick}
			style={props.style}
			data-testid={props.testId ?? null}
			onMouseEnter={() => {
				setIsHovered(true);
			}}
			onMouseLeave={() => {
				setIsHovered(false);
			}}
		>
			{getIconElement()}
			{props.startIcon && <img className={props.iconClassName} src={props.startIcon} alt="" />}
			{props.showLoader && <Loader size={props.loaderSize || 10} className={classes.loaderClass} />}
			<div className={classnames({ [classes.invisible]: props.showLoader })}>{getText()}</div>
			{props.endIcon && <img src={props.endIcon} alt="" />}
		</button>
	);
};

export { TrusstorButton };
