import { useRef, useEffect } from 'react';
import { captureMessage } from '../utils/monitoring.util';

export const useRenderMonitoring = (componentName: string) => {
	const renderCountRef = useRef(0);
	const numberOfIntervals: number = 5;
	const intervalIndex = useRef(0);
	const isIntervalInProgress = useRef(true);

	useEffect(() => {
		const intervalId = setInterval(() => {
			intervalIndex.current += 1;
			if (intervalIndex.current > numberOfIntervals) {
				isIntervalInProgress.current = false;
				clearInterval(intervalId);
				return;
			}
			if (renderCountRef.current > 50) {
				console.log(
					`Render loop detected! ${componentName} rendered ${renderCountRef.current} times in the last second.`
				);

				captureMessage(`Render loop detected in ${componentName}!`, {
					componentName,
					renderCount: renderCountRef.current,
				});
				isIntervalInProgress.current = false;
				clearInterval(intervalId);
				return;
			}
			renderCountRef.current = 0;
		}, 1000);

		return () => clearInterval(intervalId);
	}, []);

	if (isIntervalInProgress.current) {
		renderCountRef.current += 1;
	}
};
