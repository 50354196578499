import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { mapValues, pickBy } from 'lodash';
import { TrusstorBox } from '../../../../Boxes/Containers/TrusstorBox';
import { HeaderBox } from '../../../../Boxes/Containers/HeaderBox/HeaderBox';
import { translationService } from '../../../../../index';
import { IBaseProductivityFloorReport } from '../../../../../interfaces/IProductivityReport';
import { IFloor, IProfession } from '../../../../../interfaces';
import { CombinedProductivityTable } from '../Tables/CombinedProductivityTable/CombinedProductivityTable';
import {
	getProductivityDailyReport,
	getProductivityPeriodicReport,
	stopGettingProductivityReport,
} from '../../../../../store/thunks';
import { DATE_PICKER_TOGGLE } from '../../../../../constants';
import { IRootState } from '../../../../../store/slices';
import {
	IRoundedProductivityProfessionReport,
	IRoundedProductivityReport,
} from '../../../../../interfaces/IRoundedProductivityReport';
import { useIsFeatureFlagEnabled } from '@shared/hooks/useFeatureFlag';
import { FeatureFlagNamesEnum } from '@shared/constants/featureFlags.enum';

const useStyles = makeStyles({
	reportsGreyHeader: {
		marginTop: 2,
		marginLeft: 0,
	},
});

const ProductivitySection = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [isDailyReport, setIsDailyReport] = useState<boolean>(true);
	const projectId: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.projectId);
	const roundedProductivityReport: IRoundedProductivityReport = useSelector(
		(state: IRootState) => state.productivityReportReducer.roundedProductivityReport
	);
	const visibleFloorsList: IFloor[] = useSelector((state: IRootState) => state.floorsListReducer.visibleFloorsList);
	const visibleProfessions: IProfession[] = useSelector(
		(state: IRootState) => state.professionsReducer.visibleProfessions
	);
	const visibleDateTimestamp: number = useSelector(
		(state: IRootState) => state.datePickerReducer.visibleDateTimestamp
	);
	const visiblePeriodTimestamps: [number, number] = useSelector(
		(state: IRootState) => state.datePickerReducer.visiblePeriodTimestamps
	);
	const toggleStateDatePicker: DATE_PICKER_TOGGLE = useSelector(
		(state: IRootState) => state.datePickerReducer.toggleState
	);
	const tz: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.tz);
	const shouldUseProductivityReportV2Api: boolean = useIsFeatureFlagEnabled(
		FeatureFlagNamesEnum.useDailyProductivityReportV2Api
	);

	useEffect(() => {
		toggleStateDatePicker === DATE_PICKER_TOGGLE.date
			? dispatch(
					getProductivityDailyReport(projectId, visibleDateTimestamp, tz, shouldUseProductivityReportV2Api)
			  )
			: dispatch(getProductivityPeriodicReport(projectId, visiblePeriodTimestamps, tz));

		return () => {
			dispatch(stopGettingProductivityReport());
		};
	}, [
		projectId,
		toggleStateDatePicker,
		visibleDateTimestamp,
		visiblePeriodTimestamps,
		shouldUseProductivityReportV2Api,
	]);

	const getVisibleProductivityReportByFloors = (
		visibleFloors: IFloor[],
		currentVisibleProductivityReport: IRoundedProductivityReport
	) => {
		if (visibleFloors.length === 0) {
			return currentVisibleProductivityReport;
		}

		const reportWithFilteredFloorsData = mapValues(
			currentVisibleProductivityReport,
			(visibleProfessionReport: IRoundedProductivityProfessionReport) => {
				const floorsForProfession = pickBy(
					visibleProfessionReport.floors,
					(floorValue: IBaseProductivityFloorReport, floorKey: string) => {
						return visibleFloors.some((floorToFilterBy: IFloor) => floorToFilterBy.floorId === floorKey);
					}
				);
				return {
					...visibleProfessionReport,
					floors: floorsForProfession,
				};
			}
		);

		const finalReport = pickBy(
			reportWithFilteredFloorsData,
			(professionReport: IRoundedProductivityProfessionReport) => {
				return Object.keys(professionReport.floors).length > 0;
			}
		);

		return finalReport;
	};

	const getVisibleProductivityReportByTotalTimeOnSite = (
		report: IRoundedProductivityReport
	): IRoundedProductivityReport => {
		if (!isDailyReport) {
			return report;
		}

		return pickBy(report, (professionReport: IRoundedProductivityProfessionReport) => {
			return !!professionReport.totalHoursOnSite;
		});
	};

	const getVisibleProductivityReportByProfessions = (
		visibleProfessions: IProfession[],
		currentVisibleProductivityReport: IRoundedProductivityReport
	) => {
		if (visibleProfessions.length === 0) {
			return currentVisibleProductivityReport;
		}

		const reportWithFilteredProfessionsData = pickBy(
			currentVisibleProductivityReport,
			(professionReport: IRoundedProductivityProfessionReport) => {
				return visibleProfessions.some(
					(professionToFilterBy: IProfession) => professionToFilterBy._id === professionReport.profession._id
				);
			}
		);

		return reportWithFilteredProfessionsData;
	};

	const visibleProductivityReport = useMemo(() => {
		const reportFilteredByTotalTimeOnSite: IRoundedProductivityReport =
			getVisibleProductivityReportByTotalTimeOnSite(roundedProductivityReport);

		if (visibleFloorsList.length > 0 || visibleProfessions.length > 0) {
			const filteredProductivityByProfession = getVisibleProductivityReportByProfessions(
				visibleProfessions,
				reportFilteredByTotalTimeOnSite
			);
			return getVisibleProductivityReportByFloors(visibleFloorsList, filteredProductivityByProfession);
		}

		return reportFilteredByTotalTimeOnSite;
	}, [roundedProductivityReport, visibleFloorsList, visibleProfessions, isDailyReport]);

	useEffect(() => {
		setIsDailyReport(toggleStateDatePicker === DATE_PICKER_TOGGLE.date);
	}, [roundedProductivityReport, toggleStateDatePicker]);

	return (
		<TrusstorBox>
			<HeaderBox
				title={translationService.get('workResourceAnalysis')}
				customHeaderClass={classes.reportsGreyHeader}
				customRootStyles={{ width: '100%', height: '100%' }}
			>
				<CombinedProductivityTable
					report={visibleProductivityReport}
					isDailyReport={isDailyReport}
					startDate={new Date(isDailyReport ? visibleDateTimestamp : visiblePeriodTimestamps[0])}
					endDate={new Date(visiblePeriodTimestamps[1])}
				/>
			</HeaderBox>
		</TrusstorBox>
	);
};

export { ProductivitySection };
