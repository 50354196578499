import { requestService, translationService } from '../../index';
import { IProfession } from '../../interfaces';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { IRootState } from '../slices';
import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import { IBaseProfession } from '@shared/interfaces/IBaseProfession';
import {
	removeProfession,
	updateAdminPanelProfessions,
	updateProfessions,
	updateSingleProfession,
} from '../slices/professions.slice';
import { IWorkingThunkArgs } from '../../interfaces/IWorkingThunkArgs';
import { errorSnackbar } from '@utils/snackbar.utils';

export const getAdminPanelProfessions =
	(isAdmin: boolean) =>
	async (dispatch: any, getState: () => IRootState): Promise<void> => {
		const projectId: string = getState().projectReducer.workingProject!.projectId;

		const professions: IProfession[] = await requestService.get(`/projectConfig/professions/${projectId}`);
		dispatch(updateAdminPanelProfessions({ professions: professions, isAdmin }));
	};

export const getAllProfessions = () => async (dispatch: any, getState: () => IRootState) => {
	const projectId: string = getState().projectReducer.workingProject!.projectId;
	const professions: IProfession[] = await requestService.get(`/projectConfig/professions/${projectId}`);
	dispatch(updateProfessions({ professions }));
};

export const updateProfession =
	(professionWithFieldsToUpdate: IProfession) => async (dispatch: any, getState: () => IRootState) => {
		try {
			await requestService.put(`/projectConfig/professions/profession/${professionWithFieldsToUpdate._id}`, {
				body: professionWithFieldsToUpdate,
			});
		} catch (e) {
			errorSnackbar(dispatch, translationService.get('failedToUpdateProfession'));
			return;
		}
		dispatch(
			updateSingleProfession({
				updatedProfession: professionWithFieldsToUpdate,
			})
		);
	};

export const getWorkingProfessions = createAsyncThunk(
	'getWorkingProfessions',
	async (
		{ projectId, startDate, endDate, includeDeleted = false, includeInactive = false }: IWorkingThunkArgs,
		{ fulfillWithValue }
	) => {
		const queryParams: string[] = [];
		if (startDate) {
			queryParams.push(`startDate=${startDate}`);
		}
		if (endDate) {
			queryParams.push(`endDate=${endDate}`);
		}
		if (includeDeleted) {
			queryParams.push(`includeDeleted=${includeDeleted}`);
		}
		if (includeInactive) {
			queryParams.push(`includeInactive=${includeInactive}`);
		}

		const queryParamsString: string = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';
		const professionsGetUrl: string = `/projectConfig/professions/${projectId}/working${queryParamsString}`;
		const professions: IProfession[] = await requestService.get(professionsGetUrl);
		return { professions };
	}
);

export const createProfession = createAsyncThunk<any, IBaseProfession, { state: IRootState }>(
	'createProfession',
	async (profession: IBaseProfession, { rejectWithValue, getState }) => {
		try {
			const createdProfession: IProfession = await requestService.post(`/projectConfig/professions/profession`, {
				body: profession,
			});
			return { profession: createdProfession };
		} catch (e: any) {
			return rejectWithValue(e.response.status);
		}
	}
);

export const deleteProfession =
	(professionId: string): ThunkAction<void, IRootState, unknown, AnyAction> =>
	async (dispatch: any, getState: () => IRootState) => {
		await requestService.delete(`/projectConfig/professions/profession/${professionId}`);
		dispatch(removeProfession({ deletedProfessionId: professionId }));
	};
