import React, { useEffect } from 'react';
import classnames from 'classnames';
import classes from './styles.module.scss';
import fullArrowRight from '../../../assets/icons/fullArrowRight.svg';
import activityIcon from '../../../assets/icons/Activity.svg';
import { IIssueShared, IssueStatus } from '../../../interfaces/IIssueShared';
import { IProfession } from '../../../interfaces/IProfession';
import { TranslationService } from '../../../services/translation.service';
import { CommentsThread } from '../../CommentsThread/CommentsThread';
import { StorageService } from '../../../services/storage.service';
import helmet from '../../../assets/icons/helmet.svg';
import { LocationItem } from '../IssueLocation/IssueLocation';
import userIcon from '../../../assets/icons/user_icon.svg';
import { CommentInput } from '../../Inputs/CommentInput/CommentInput';
import { getIssueDescription } from '../../../utils/issues.utils';
import { CriticalSection } from '../CriticalSection/CriticalSection';
import { IssueMenu } from '../IssueMenu/IssueMenu';
import { RequestService } from '../../../services/request.service';
import { IssueStatusChangeButton } from '../IssueStatusChangeButton/IssueStatusChangeButton';
import { IssueDateDisplay } from '../IssueDateDisplay/IssueDateDisplay';
import { IssueMetadataSection } from '../IssueMetadataSection/IssueMetadataSection';
import { ProfessionDisplayWithTradeIcon } from '../../ProfessionDisplayWithTradeIcon/ProfessionDisplayWithTradeIcon';
import { IssuePrivateSection } from '../IssuePrivateSection/IssuePrivateSection';

const COMMENT_INPUT_HEIGHT = 65;

interface IIssueMobileDisplayProps {
	requestService: RequestService;
	issue: IIssueShared<IProfession>;
	tz: string;
	projectId: string;
	translationService: TranslationService;
	storageService: StorageService;
	activityDescription?: string;
	priorityIcon: string;
	handlePriority: () => void;
	updateIssue: (
		issueId: string,
		issueToUpdate: Partial<IIssueShared<IProfession>>,
		updateBackend?: boolean
	) => Promise<IIssueShared<IProfession>>;
	onClose: (e: React.MouseEvent) => void;
	saveComment: (comment: string) => Promise<void>;
	saveImage?: (file: File) => Promise<void>;
	deleteIssue?: (e) => void;
	handleStatusChange: (statusChange: IssueStatus) => Promise<void>;
	handleReviewCallback: () => void;
	openEditDrawer?: (e) => void;
	hideHeader?: boolean;
	hideProfession?: boolean;
	isLimitedUser: boolean;
}

export const IssueMobileDisplay = (props: IIssueMobileDisplayProps) => {
	const [description, setDescription] = React.useState<string>(
		getIssueDescription(props.issue, props.translationService)
	);
	const [threadsSectionHeight, setThreadsSectionHeight] = React.useState<string>('100svh');
	const fixedSectionEl = React.useRef<HTMLDivElement>(null);
	const isRtl: boolean = props.translationService.getIsRtl();
	const language: string = props.translationService.getChosenLanguage();

	useEffect(() => {
		const fixedSectionHeight: number = fixedSectionEl.current?.clientHeight || 0;
		setThreadsSectionHeight(`calc(100svh - ${fixedSectionHeight + COMMENT_INPUT_HEIGHT}px)`);
	}, []);

	useEffect(() => {
		setDescription(getIssueDescription(props.issue, props.translationService));
	}, [props.issue.description]);

	const submitChanges = async () => {
		if (!description?.length) {
			setDescription(props.issue.description);
			return;
		}
		await props.updateIssue(props.issue._id, {
			description,
		});
	};

	const handlePriority = (e: React.MouseEvent) => {
		e.stopPropagation();
		props.handlePriority();
	};

	const handleEditClick = (e: React.MouseEvent) => {
		e.stopPropagation();
		props.openEditDrawer?.(e);
	};

	const descriptionContainerClassname: string = classnames(classes.descriptionContainer, {
		[classes.bodyWithoutHeader]: props.hideHeader,
	});

	return (
		<div className={classes.issueEditContainer}>
			{!props.hideHeader && (
				<>
					<section className={classes.header} ref={fixedSectionEl}>
						<div className={classes.backButton} onClick={props.onClose}>
							<img src={fullArrowRight} alt="<" className={isRtl ? classes.rotateArrow : ''} />
						</div>
						<div className={classes.rightTopSection}>
							<IssueStatusChangeButton
								handleReviewCallback={props.handleReviewCallback}
								isLimitedUser={props.isLimitedUser}
								className={classnames(classes.completeButton, { [classes.rtl]: isRtl })}
								handleStatusChange={props.handleStatusChange}
								issueStatus={props.issue.status}
								translationService={props.translationService}
								itemHovered={false}
							/>
							<IssueDateDisplay issue={props.issue} tz={props.tz} />
							{!props.isLimitedUser && (
								<IssueMenu
									deleteFunction={props.deleteIssue!}
									translationService={props.translationService}
									requestService={props.requestService}
									openEditFunction={handleEditClick}
								/>
							)}
						</div>
					</section>
					<div className={classes.divider} />
				</>
			)}
			<div className={classes.scrollable} style={{ height: props.hideHeader ? '100%' : threadsSectionHeight }}>
				<section className={classes.infoSection}>
					<div className={descriptionContainerClassname}>
						<div className={classes.row}>
							<CriticalSection
								isLimitedUser={props.isLimitedUser}
								issuePriority={props.issue.priority}
								translationService={props.translationService}
								onClick={handlePriority}
								isMobile
							/>
							{props.issue.isPrivate && (
								<div className={classes.privateSection}>
									<IssuePrivateSection textMode={'default'} />
								</div>
							)}
						</div>
						<textarea
							value={description}
							disabled={props.isLimitedUser}
							className={classes.description}
							onChange={(e) => setDescription(e.target.value)}
							onBlur={submitChanges}
						>
							{description}
						</textarea>
					</div>
					<div className={classes.infoDetails}>
						{!props.hideProfession && props.issue.profession && (
							<div className={classes.professionSection}>
								<img src={helmet} alt="" />
								<div>
									<ProfessionDisplayWithTradeIcon
										profession={props.issue.profession}
										projectId={props.projectId}
									/>
								</div>
							</div>
						)}
						{props.issue.locations && props.issue.locations.length > 0 && (
							<div className={classes.locationItemsContainer}>
								<LocationItem
									locations={props.issue.locations}
									rootClass={classes.locationItem}
									projectId={props.projectId}
								/>
							</div>
						)}
						{props.issue.assignee && (
							<div className={classes.assigneeSection}>
								<img src={userIcon} alt="" />
								<div className={classes.assigneeText}>{props.issue.assignee.name}</div>
							</div>
						)}
						{props.activityDescription && (
							<div className={classes.activitySection}>
								<img src={activityIcon} alt="" />
								<div className={classes.activityText}>{props.activityDescription}</div>
							</div>
						)}

						<IssueMetadataSection issue={props.issue} tz={props.tz} />
					</div>
				</section>
				{props.issue.comments && props.issue.comments.length > 0 ? (
					<section className={classes.threadsSection}>
						<CommentsThread
							comments={props.issue.comments}
							issueId={props.issue._id}
							tz={props.tz}
							translationService={props.translationService}
							storageService={props.storageService}
						/>
					</section>
				) : (
					<div className={classes.noComments}> {props.translationService.get('noComments')}</div>
				)}
			</div>
			<section>
				<CommentInput
					isMobile
					hasCamera
					rootStyle={{ minHeight: `${COMMENT_INPUT_HEIGHT}px` }}
					saveComment={props.saveComment}
					translationService={props.translationService}
					saveImage={props.saveImage}
				/>
			</section>
		</div>
	);
};
