import React from 'react';
import classnames from 'classnames';
import classes from './styles.module.scss';
import { TrusstorIcon } from '@src/Components/TrusstorIcon/TrusstorIcon';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { IconColor, IconSize } from '@shared/components/TrusstorIconShared/TrusstorIconShared';
import TrusstorTooltip from '@shared/components/TrusstorTooltip';

interface IProfessionActivitiesChipProps {
	backgroundColor: string;
	numberOfActivities: number;
	progressPercentage?: number;
	isOverdue?: boolean;
	isMinimized?: boolean;
	getToolTipComponent?: () => JSX.Element;
	height: number;
}

export const ProfessionActivitiesChip = (props: IProfessionActivitiesChipProps) => {
	return (
		<TrusstorTooltip tooltipComponent={props.getToolTipComponent?.()} showAlways={true} interactive>
			<div
				className={classes.professionActivitiesChipContainer}
				style={{ backgroundColor: props.backgroundColor, height: `${props.height}px` }}
			>
				<div
					className={classnames(classes.flexContainer, classes.numberOfActivitiesChip, {
						[classes.minimizedMode]: props.isMinimized,
					})}
					style={{ height: `${props.height - 4}px` }}
				>
					{props.numberOfActivities}
				</div>
				{props.progressPercentage !== undefined && !props.isMinimized && (
					<div className={classnames(classes.flexContainer, classes.progressPercentageContainer)}>
						{props.progressPercentage}%
					</div>
				)}
				{props.isOverdue && !props.isMinimized && (
					<div className={classes.flexContainer}>
						<TrusstorIcon
							iconName={IconNames.flagFilled}
							color={IconColor.White}
							size={IconSize.EXTRA_SMALL}
						/>
					</div>
				)}
			</div>
		</TrusstorTooltip>
	);
};
