import React from 'react';
import { orderBy } from 'lodash';
import { translationService } from '../../index';
import { ExpandableComponent } from '../MainPageSections/ExpandableComponent/ExpandableComponent';
import { getTradeTranslation } from '../../utils/translations.utils';
import { ProfessionFilterCard } from '@shared/components/Filters/ProfessionFilterCard/ProfessionFilterCard';
import { ManagerFilterCard } from '@shared/components/Filters/ManagerFilterCard/ManagerFilterCard';

const EntitiesWithValuesFilterSection = ({
	handleResetEntitiesClick,
	disableShowAll,
	filteredEntities,
	removeEntities,
	addEntities,
	selectedEntities,
	type,
	title = translationService.get('professions'),
	isProfession,
}) => {
	return (
		<ExpandableComponent
			childrenLength={filteredEntities.length}
			headerText={title}
			clearFunction={handleResetEntitiesClick}
			shouldHideClearButton={disableShowAll}
			clearLength={selectedEntities.length}
			type={type}
		>
			{orderBy(
				filteredEntities,
				[
					(entitiesProperties) => entitiesProperties.value,
					(entitiesProperties) => getTradeTranslation(entitiesProperties),
				],
				['desc', 'asc']
			).map((entity) => {
				const isSelected = selectedEntities.some((selectedEntity) => entity._id === selectedEntity._id);

				if (!isProfession)
					return (
						<ManagerFilterCard
							key={entity._id}
							managerTradeText={getTradeTranslation(entity)}
							isSelected={isSelected}
							handleClick={isSelected ? () => removeEntities(entity) : () => addEntities(entity)}
							count={entity.value}
							name={entity.name}
						/>
					);

				return (
					<ProfessionFilterCard
						key={entity._id}
						isSelected={isSelected}
						handleClick={isSelected ? () => removeEntities(entity) : () => addEntities(entity)}
						count={entity.value}
						profession={entity}
						projectId={entity.projectId}
					/>
				);
			})}
		</ExpandableComponent>
	);
};

export { EntitiesWithValuesFilterSection };
