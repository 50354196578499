import React, { useContext, useEffect, useRef, useState } from 'react';
import classes from './styles.module.scss';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { translationService } from '@src/servicesInitializers';
import { WorkplanPlanningPeriods } from '@src/Components/Pages/Workplan/WorkplanPlanningDialog/WorkplanPlanningPeriods/WorkplanPlanningPeriods';
import { IMergedAreaSequenceItem } from '@interfaces/IMergedAreaSequenceItem';
import { PlanningContext, WorkplanContext } from '@src/Components/Pages/Workplan/WorkPlan';
import { getProfessionDisplayText } from '@utils/professions.utils';
import { groupBy } from 'lodash';
import { TrusstorButton } from '@shared/components/buttons/TrusstorButton/TrusstorButton';
import { createBulkActivities } from '@src/apis/activities.api';
import { getFormattedDate } from '@shared/utils/dateUtils';
import { errorSnackbar, successSnackbar } from '@utils/snackbar.utils';
import { useDispatch, useSelector } from 'react-redux';
import { createActivityPlanning_BI } from '@utils/bi.utils';
import classnames from 'classnames';
import { PlanningMenuEmptyState } from '@src/Components/Pages/Workplan/PlanningMenu/PlanningMenuEmptyState';
import { PlanningMenuFloors } from '@src/Components/Pages/Workplan/PlanningMenu/PlanningMenuFloors';
import { IProfession } from '@src/interfaces';
import { IClientInputActivityShared } from '@shared/interfaces/IClientInputActivity';
import { updateAreaSequenceItemsByActivities } from '@utils/workPlan.utils';
import { TrusstorIcon } from '@src/Components/TrusstorIcon/TrusstorIcon';
import { selectProjectId } from '@store/slices/project.slice';
interface IPlanningMenuProps {
	onClose: () => void;
	onMinimize: () => void;
	isMinimized: boolean;
}

const PlanningMenu = (props: IPlanningMenuProps) => {
	const projectId: string = useSelector(selectProjectId)!;
	const { areaSequenceItemsSelected, setAreaSequenceItemsSelected } = useContext(PlanningContext)!;
	const {
		updateAreaSequenceItem,
		selectedSequenceId,
		matrix: { recreateTable },
	} = useContext(WorkplanContext)!;
	const [startDate, setStartDate] = useState<Date | undefined>(undefined);
	const [endDate, setEndDate] = useState<Date | undefined>(undefined);
	const [isCreateButtonLoading, setIsCreateButtonLoading] = useState<boolean>(false);
	const dispatch = useDispatch();
	const menuEl = useRef(null);

	const onDateChange = (startDate: Date, endDate: Date) => {
		setStartDate(startDate);
		setEndDate(endDate);
	};

	useEffect(() => {
		window.addEventListener('keydown', handleKeyDown);
		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	}, [areaSequenceItemsSelected.length]);

	const handleKeyDown = (event: KeyboardEvent) => {
		if (event.key === 'Enter' && areaSequenceItemsSelected.length > 0) {
			handleCreate();
		}
	};

	const handleCreate = async () => {
		try {
			if (areaSequenceItemsSelected.length === 0 || isCreateButtonLoading) return;
			setIsCreateButtonLoading(true);
			const startDateFormattedDate: string = getFormattedDate(startDate);
			const endDateFormattedDate: string = getFormattedDate(endDate);
			const updatedActivities: IClientInputActivityShared<IProfession>[] = await createBulkActivities(
				[],
				areaSequenceItemsSelected,
				startDateFormattedDate,
				endDateFormattedDate
			);
			const activitySnackbarMessage: string =
				areaSequenceItemsSelected.length > 1
					? `${areaSequenceItemsSelected.length} ${translationService.get(
							'activitiesWereSuccessfullyCreated'
						)}`
					: translationService.get('activityWasSuccessfullyCreated');

			successSnackbar(dispatch, activitySnackbarMessage);
			createActivityPlanning_BI(0, areaSequenceItemsSelected.length);
			setAreaSequenceItemsSelected([]);
			props.onClose();
			updateAreaSequenceItemsByActivities(
				updatedActivities,
				updateAreaSequenceItem,
				recreateTable,
				projectId,
				selectedSequenceId
			);
		} catch (e) {
			console.error(e);
			errorSnackbar(dispatch, translationService.get('errorWhileCreatingActivities'));
		}
		setIsCreateButtonLoading(false);
	};

	const handleCancelClick = (): void => {
		props.onClose();
	};

	const isRtl: boolean = translationService.getIsRtl();
	return (
		<section
			className={classnames(classes.menuContainer, {
				[classes.slideLeft]: !props.isMinimized && !isRtl,
				[classes.slideRight]: !props.isMinimized && isRtl,
				[classes.hideLeft]: props.isMinimized && !isRtl,
				[classes.hideRight]: props.isMinimized && isRtl,
			})}
			ref={menuEl}
		>
			<div className={classes.menuHeader}>
				<div
					className={classnames({
						[classes.arrowContainer]: true,
						[classes.arrowContainerRtl]: isRtl,
					})}
				>
					<TrusstorIcon
						iconName={IconNames.arrowRight}
						onClick={props.onMinimize}
						className={classes.closeButton}
					/>
				</div>
				<WorkplanPlanningPeriods onChange={onDateChange} />
			</div>
			<div className={classes.menuBody}>
				<div className={classes.counterSection}>
					<div className={classes.counterSectionText}>
						{translationService.get('selectLocationsToAddYourPlan')}
					</div>
					<div className={classes.counterSectionNumber}>{`${
						areaSequenceItemsSelected.length
					} ${translationService.get('selected')}`}</div>
				</div>
				<div className={classes.sequenceTitle}>{translationService.get('apartmentSequence')}</div>
				{areaSequenceItemsSelected.length === 0 && <PlanningMenuEmptyState />}
				{Object.entries(
					groupBy(areaSequenceItemsSelected, (areaSequenceItem) =>
						getProfessionDisplayText(areaSequenceItem.profession)
					)
				).map(([professionName, professionSequences]) => {
					return (
						<div className={classes.professionSequences} key={professionName}>
							<div className={classes.professionTitle}>
								<TrusstorIcon iconName={IconNames.clipboard} />
								<div className={classes.professionText}>{professionName}</div>
							</div>
							{Object.entries(
								groupBy(
									professionSequences,
									(areaSequenceItem) => (areaSequenceItem as IMergedAreaSequenceItem).area.floorNick
								)
							).map(([floorNick, floorItems]) => (
								<PlanningMenuFloors
									floorNick={floorNick}
									professionSequenceItems={floorItems as IMergedAreaSequenceItem[]}
								/>
							))}
						</div>
					);
				})}
			</div>
			<div className={classes.menuFooter}>
				<TrusstorButton
					text={translationService.get('cancel')}
					handleClick={handleCancelClick}
					className={classes.cancelButton}
				/>
				<TrusstorButton
					disabled={areaSequenceItemsSelected.length === 0}
					text={translationService.get('create')}
					handleClick={handleCreate}
					className={classes.createButton}
					showLoader={isCreateButtonLoading}
				/>
			</div>
		</section>
	);
};

export { PlanningMenu };
