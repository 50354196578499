import React from 'react';
import classes from './styles.module.scss';
import bookOpenIcon from '@shared/assets/icons/bookOpenIcon.svg';
import { IIntercomLinkSectionProps } from './IIntercomLinkSectionProps';

export const IntercomLinkSection = (props: IIntercomLinkSectionProps) => {
	return (
		<div
			className={classes.wrapper}
			data-testid="IntercomLinkSection"
			onClick={() => window.open(props.linkPath, '_blank')}
		>
			<img className={classes.img} src={bookOpenIcon} />
			<div className={classes.linkText}>{props.linkText}</div>
		</div>
	);
};
