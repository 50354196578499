import React, { useRef, useMemo, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { textStyle } from '@shared/utils/text.utils';
import { translationService } from '../../../../../../index';
import {
	PRODUCTIVITY_LOCATION_CELL_HEIGHT,
	PRODUCTIVITY_WORKERS_QUANTITY_CELL_HEIGHT,
	PRODUCTIVITY_HEADER_HEIGHT,
	guestTradeId,
	COLORS,
	PRODUCTIVITY_WORK_HOURS_CELL_HEIGHT_DAILY,
	PRODUCTIVITY_WORK_HOURS_CELL_HEIGHT_PERIOD,
} from '../../../../../../constants';
import { GenericHeaderColumns, IHeaderColumnCell } from '../common/GenericHeaderColumns/GenericHeaderColumns';
import { ProductivityColumn } from './ProductivityColumn';
import { ProductivityEmptyColumn } from './ProductivityEmptyColumn';
import { IProfession } from '../../../../../../interfaces';
import { IWorkDayHours } from '@shared/interfaces/IProject';
import {
	getProfessionAvgWorkersNumber,
	getTotalUniqueWorkersInProfessionProductivityReport,
} from '../../../../../../utils/reports.utils';
import { max } from 'lodash';
import { getNumberOfDaysWithoutRestDays } from '../../../../../../utils/dateUtils';
import { IRootState } from '../../../../../../store/slices';
import { unassignedTradeId } from '@shared/constants/professions.constants';
import {
	IRoundedProductivityProfessionReport,
	IRoundedProductivityReport,
} from '../../../../../../interfaces/IRoundedProductivityReport';
import { regularBorder } from '../../../../../../constants/genericStyles.constants';
import { ProductivityTotalColumn } from './ProductivityTotalColumn';

interface IStylesProps {
	isDailyReport?: boolean;
}

const useStyles = makeStyles((theme) => ({
	tableContainerWrapper: {
		display: 'flex',
		backgroundColor: COLORS.white,
		marginTop: 8,
	},
	tableContainer: {
		width: '100%',
		overflowX: 'auto',
		overflowY: 'hidden',
		display: 'flex',
		alignItems: 'flex-start',
		backgroundColor: COLORS.white,
		border: regularBorder,
	},
	emptyHeaderCell: {
		height: PRODUCTIVITY_HEADER_HEIGHT,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: COLORS.white,
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		width: '95%',
		marginTop: 8,
		marginLeft: 8,
	},
	title: textStyle({
		textAlign: 'left',
		fontClass: 'h2Light',
		margin: 12,
	}),
	headerContainer: {
		minWidth: 109,
		maxWidth: 109,
		height: 26,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		border: regularBorder,
	},
	whiteHeader: {
		height: '100%',
		backgroundColor: COLORS.white,
	},
	scrollArrowContainer: {
		height: 20,
		width: 20,
		alignSelf: 'center',
	},
	workHoursCell: ({ isDailyReport }: IStylesProps) => ({
		height: isDailyReport ? PRODUCTIVITY_WORK_HOURS_CELL_HEIGHT_DAILY : PRODUCTIVITY_WORK_HOURS_CELL_HEIGHT_PERIOD,
		display: 'flex',
		alignItems: 'center',
	}),
	workersQuantityCell: {
		height: PRODUCTIVITY_WORKERS_QUANTITY_CELL_HEIGHT,
		display: 'flex',
		alignItems: 'center',
		border: regularBorder,
	},
	workResourceLocationCell: {
		height: PRODUCTIVITY_LOCATION_CELL_HEIGHT,
		display: 'flex',
		alignItems: 'center',
	},
}));

interface IWorkResourcesTableProps {
	report: IRoundedProductivityReport;
	startDate: Date;
	endDate?: Date;
	isDailyReport: boolean;
}

interface IProfessionAvgWorkersObject {
	[profession: string]: number;
}

const CombinedProductivityTable = (props: IWorkResourcesTableProps) => {
	const classes = useStyles({ isDailyReport: props.isDailyReport });
	const tableEl: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
	const projectDayWorkHours: IWorkDayHours[] = useSelector(
		(state: IRootState) => state.projectReducer.workingProject!.workDayHours
	);
	const visibleProfessions: IProfession[] = useSelector(
		(state: IRootState) => state.professionsReducer.visibleProfessions
	);
	const [professionWorkersObject, setProfessionWorkersObject] = useState<{
		[profession: string]: number;
	}>({});
	const [maxWorkerNumber, setMaxWorkerNumber] = useState<number>(0);

	const maxWorkTime = useMemo(() => {
		return getNumberOfDaysWithoutRestDays(props.startDate, props.endDate ?? props.startDate, projectDayWorkHours);
	}, [props.isDailyReport, props.endDate, props.startDate]);

	const WORK_RESOURCE_HEADERS_COLUMNS: IHeaderColumnCell[] = [
		{
			title: props.isDailyReport
				? translationService.get('workersQuantity')
				: translationService.get('workersQuantityAvgDaily'),
			cellStyleClass: classes.workersQuantityCell,
			tooltip: props.isDailyReport ? undefined : translationService.get('averageWorkersInfo'),
		},
		{
			title: props.isDailyReport
				? translationService.get('totalTimeOnSite')
				: translationService.get('totalWorkDays'),
			cellStyleClass: classes.workHoursCell,
		},
		{
			title: translationService.get('workResourcesLocation'),
			cellStyleClass: classes.workResourceLocationCell,
		},
	];

	const professionsToFill: IProfession[] = useMemo(() => {
		const professionFromReport: string[] = Object.keys(props.report);

		return visibleProfessions.filter((profession) => {
			return (
				!professionFromReport.includes(profession._id) &&
				profession.tradeId !== unassignedTradeId &&
				profession.tradeId !== guestTradeId
			);
		});
	}, [visibleProfessions, props.report]);

	useEffect(() => {
		const professionAvgWorkersObject: IProfessionAvgWorkersObject = Object.entries(props.report).reduce(
			(
				professionAvgWorkersObject: IProfessionAvgWorkersObject,
				[professionId, professionReport]: [string, IRoundedProductivityProfessionReport]
			) => {
				const professionWorkersNumber = props.isDailyReport
					? getTotalUniqueWorkersInProfessionProductivityReport(professionReport)
					: getProfessionAvgWorkersNumber(professionReport);

				professionAvgWorkersObject[professionId] = Number(professionWorkersNumber.toFixed(1));
				return professionAvgWorkersObject;
			},
			{}
		);

		setProfessionWorkersObject(professionAvgWorkersObject);
	}, [props.isDailyReport, props.report]);

	useEffect(() => {
		const maxWorkers: number = max(Object.values(professionWorkersObject)) as number;
		setMaxWorkerNumber(maxWorkers);
	}, [professionWorkersObject]);

	const totalWorkersNumber: number = Number(
		Object.values(professionWorkersObject)
			.reduce((sum, workersNumber) => sum + workersNumber, 0)
			.toFixed(1)
	);

	return (
		<div ref={tableEl} className={classes.tableContainerWrapper}>
			<div className={classes.tableContainer}>
				<GenericHeaderColumns
					emptyCellStyleClass={classes.emptyHeaderCell}
					headerColumnCells={WORK_RESOURCE_HEADERS_COLUMNS}
				/>
				<ProductivityTotalColumn
					isDailyReport={props.isDailyReport}
					workTimeUnit={maxWorkTime}
					maxWorkers={maxWorkerNumber}
					workersNumber={totalWorkersNumber}
					professionReports={props.report}
				/>
				{Object.entries(props.report).map(([profession, professionReport]) => {
					return (
						<ProductivityColumn
							isDailyReport={props.isDailyReport}
							workTimeUnit={maxWorkTime}
							maxWorkers={maxWorkerNumber}
							workersNumber={professionWorkersObject[profession]}
							professionReport={professionReport}
							key={`${professionReport.profession._id}`}
						/>
					);
				})}
				{professionsToFill.map((profession: IProfession, index: number) => (
					<ProductivityEmptyColumn
						isDailyReport={props.isDailyReport}
						profession={profession}
						key={`${index}_WorkResourcesEmptyProfessionColumn`}
					/>
				))}
			</div>
		</div>
	);
};

export { CombinedProductivityTable };
