import React, { useMemo, useState } from 'react';
import { resetUserPasswordApi } from '../../../../apis/entities.api';
import { validateEmail } from '../../../../utils/generalUtils';
import { AuthPage } from '../AuthPage';
import { ResetPasswordConfirmationPage } from './ResetPasswordConfirmationPage';
import userNewIcon from '@assets/login/email.svg';
import { TrusstorTextInput } from '@shared/components/Inputs/TrusstorTextInput/TrusstorTextInput';

interface IResetPasswordPageProps {
	match: {
		params: any;
	};
}

export const ResetPasswordPage = (props: IResetPasswordPageProps) => {
	const [email, setEmail] = useState<string>('');
	const [emailError, setEmailError] = useState<string>('');
	const [showConfirmationPage, setShowConfirmationPage] = useState<boolean>(false);

	const onEmailInputChange = (email: string) => {
		setEmailError('');
		setEmail(email);
	};

	const onResetPasswordClick = async () => {
		const isValidEmail = validateEmail(email);
		if (!isValidEmail) {
			return setEmailError('Invalid email address');
		}
		const lowerCasedEmail: string = email.toLowerCase();
		await resetUserPasswordApi(lowerCasedEmail);
		setShowConfirmationPage(true);
	};

	return !showConfirmationPage ? (
		<AuthPage
			buttonText={'Reset Password'}
			title={'Reset Password'}
			onButtonClick={onResetPasswordClick}
			submitOnEnter={true}
		>
			<div>
				<TrusstorTextInput
					changeFunc={onEmailInputChange}
					startIcon={userNewIcon}
					isError={emailError.length > 0}
					errorText={emailError}
					testId="email-field"
					placeholder={'Email'}
				/>
			</div>
		</AuthPage>
	) : (
		<ResetPasswordConfirmationPage userEmail={email} />
	);
};
