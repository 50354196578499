import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { textStyle } from '@shared/utils/text.utils';
import { GeneralDialog } from '../GeneralDialog/GeneralDialog';

const useStyles = makeStyles({
	contentText: textStyle({
		fontWeight: 300,
		marginBottom: 10,
		marginTop: 25,
	}),
});

interface GeneralAlertDialogProps {
	show: boolean;
	close: () => void;
	onSubmitButtonClick?: () => void;
	title: string;
	message?: string;
	children?: React.ReactNode;
	closeButtonText: string;
}

const GeneralAlertDialog = (props: GeneralAlertDialogProps) => {
	const classes = useStyles();

	return (
		<GeneralDialog
			show={props.show}
			close={props.close}
			title={props.title}
			mainButton={{
				text: props.closeButtonText,
				click: props.onSubmitButtonClick,
			}}
			rootStyle={{ width: 300 }}
		>
			{props.message && <p className={classes.contentText}>{props.message}</p>}
			{props.children}
		</GeneralDialog>
	);
};

export { GeneralAlertDialog };
