import React from 'react';
import { BaseNotificationCard } from '../BaseNotificationCard/BaseNotificationCard';
import classes from './styles.module.scss';
import { logoCircle } from '../../../assets';
import { INotification } from '../../../interfaces/INotification';
import { TranslationService } from '../../../services/translation.service';
import { RequestService } from '../../../services/request.service';
import { UserAccessLevelService } from '../../../services/userAccessLevelService';
import { isTrusstorMobile } from '../../../utils/mobile.utils';
import { DATE_PICKER_TOGGLE } from '../../../interfaces/DatePickerMode';
import { IUser } from '../../../interfaces/IUser';
import { generateInvestigationReport } from '../../../utils/analysisCenter.utils';

interface IDailyReportCardProps {
	notification: INotification;
	translationService: TranslationService;
	tz: string;
	requester: RequestService;
	handleCardClick: (reportId: string) => void;
	accessLevelService?: UserAccessLevelService<string>;
	projectId: string;
	userDetails: IUser;
}

export const getDailyReportTitleElement = (
	translationService: TranslationService,
	isDarkTheme?: boolean
): React.ReactNode => {
	const titleClass: string = `${classes.title} ${isDarkTheme ? classes.titleDarkTheme : ''}`;
	return <div className={titleClass}>{translationService.get('yourDailyReportIsReady')}!</div>;
};

export const getDailyReportIconElement = (): React.ReactNode => {
	return <img className={classes.icon} src={logoCircle} alt={''} />;
};

export const DailyReportCard = (props: IDailyReportCardProps) => {
	const generateAndGoToInvestigationReport = async (): Promise<string> => {
		try {
			const reportId: string = await generateInvestigationReport(
				props.requester,
				props.translationService,
				props.userDetails._id!,
				props.projectId,
				props.tz,
				DATE_PICKER_TOGGLE.date,
				new Date()
			);

			return reportId;
		} catch (e) {
			console.error('Failed to generate report in daily report notification click', e);
			throw e;
		}
	};

	const handleDailyReportCardClick = async () => {
		if (props.accessLevelService && !props.accessLevelService.hasAccess('investigationReportAccess')) {
			return;
		}

		const reportId: string = await generateAndGoToInvestigationReport();
		props.handleCardClick(reportId);
	};

	const isCardClickable: boolean =
		!!props.accessLevelService &&
		props.accessLevelService.hasAccess('investigationReportAccess') &&
		!isTrusstorMobile();
	return (
		<BaseNotificationCard
			icon={getDailyReportIconElement()}
			titleElement={getDailyReportTitleElement(props.translationService)}
			createdAt={props.notification.createdAt}
			tz={props.tz}
			translationService={props.translationService}
			requester={props.requester}
			notificationId={props.notification._id}
			handleCardClick={handleDailyReportCardClick}
			isViewed={props.notification.isViewed}
			isClickable={isCardClickable}
		/>
	);
};
