import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { uniq } from 'lodash';
import { IRootState } from '.';
import { IssueStatus } from '@shared/interfaces/IIssueShared';
import { IIssueStatusesReducerState } from '@interfaces/IIssueStatusesReducerState';
import { ongoingIssuesStatuses } from '@shared/constants/issues.constants';

const defaultIssueStatuses: IssueStatus[] = ongoingIssuesStatuses;

const initialState: IIssueStatusesReducerState = {
	selectedIssueStatuses: defaultIssueStatuses,
};

const issuesStatusesSlice = createSlice({
	name: 'issuesStatuses',
	initialState,
	reducers: {
		addSelectedIssueStatus: (
			state: IIssueStatusesReducerState,
			action: PayloadAction<{ selectedIssueStatus: IssueStatus }>
		) => {
			const selectedIssuesPriorityAfterAdd: IssueStatus[] = uniq(
				state.selectedIssueStatuses.concat(action.payload.selectedIssueStatus)
			);
			state.selectedIssueStatuses = selectedIssuesPriorityAfterAdd;
		},
		removeSelectedIssueStatus: (
			state: IIssueStatusesReducerState,
			action: PayloadAction<{ removeIssueStatus: IssueStatus }>
		) => {
			const selectedActivityGroupsStatusesAfterRemove: IssueStatus[] = state.selectedIssueStatuses.filter(
				(selectedIssueStatus) => {
					return action.payload.removeIssueStatus !== selectedIssueStatus;
				}
			);

			state.selectedIssueStatuses = selectedActivityGroupsStatusesAfterRemove;
		},
		resetSelectedIssueStatusesToDefault: (state: IIssueStatusesReducerState) => {
			state.selectedIssueStatuses = defaultIssueStatuses;
		},
		resetSelectedIssueStatuses: (state: IIssueStatusesReducerState) => {
			state.selectedIssueStatuses = [];
		},
	},
});

export const selectSelectedIssueStatuses = (state: IRootState): IssueStatus[] =>
	state.issuesStatusesReducer.selectedIssueStatuses;

export const {
	addSelectedIssueStatus,
	removeSelectedIssueStatus,
	resetSelectedIssueStatusesToDefault,
	resetSelectedIssueStatuses,
} = issuesStatusesSlice.actions;
export default issuesStatusesSlice.reducer;
