import { ISafetyEvent } from '@interfaces/ISafetyEvent';
import classes from './styles.module.scss';
import React, { useRef } from 'react';
import { safetyOrange, safetyRed } from '../../assets/safetyIcons';
import { getSafetyViolationTypeTranslationVars } from '@utils/safety.utils';
import { translationService } from '../../index';
import { getProfessionDisplayText } from '@utils/professions.utils';
import { SimpleChip } from '@shared/components/SimpleChip/SimpleChip';
import { getProfessionBackgroundColor } from '@shared/utils/professions.utils';
import { selectProjectId } from '@store/slices/project.slice';
import { useSelector } from 'react-redux';

interface IMinimalSafetyRowProps {
	safetyEvent: ISafetyEvent;
}

const MinimalSafetyRow = (props: IMinimalSafetyRowProps) => {
	const projectId: string = useSelector(selectProjectId)!;
	const translationVars: { [key: string]: string } = getSafetyViolationTypeTranslationVars(
		props.safetyEvent.trade?.tradeId,
		props.safetyEvent.safetyViolationType
	);

	const professionDisplayText: string = getProfessionDisplayText(
		props.safetyEvent.profession! || props.safetyEvent.trade || props.safetyEvent.manager,
		true
	);

	const professionColor: string =
		(props.safetyEvent.profession && getProfessionBackgroundColor(props.safetyEvent.profession, projectId)) ||
		props.safetyEvent.trade?.backgroundColor ||
		props.safetyEvent.manager?.backgroundColor ||
		'';

	return (
		<section className={classes.minimalSafetyRowContainer}>
			<img
				src={props.safetyEvent.isCritical ? safetyRed : safetyOrange}
				alt="safety"
				className={classes.iconClass}
			/>
			<div className={classes.safetyEventText}>
				{translationService.get(props.safetyEvent.safetyViolationType, translationVars)}
			</div>
			{professionDisplayText && (
				<div className={classes.chipContainer}>
					<SimpleChip chipText={professionDisplayText} chipColor={professionColor} maxChipWidth={'200px'} />
				</div>
			)}
		</section>
	);
};
export { MinimalSafetyRow };
