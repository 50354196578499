import React from 'react';
import classes from './styles.module.scss';
import { translationService } from '@src/servicesInitializers';
import { IssueCreateButton } from '@src/Components/Pages/IssuesPage/IssueCreateButton/IssueCreateButton';

interface ICreateNewIssueColumnShortcutProps {
	setShowCreateIssue: () => void;
}

export const CreateNewIssueShortcut = (props: ICreateNewIssueColumnShortcutProps) => {
	return (
		<div className={classes.wrapper} onClick={props.setShowCreateIssue}>
			<div>{translationService.get('addIssue')}</div>
			<IssueCreateButton />
		</div>
	);
};
