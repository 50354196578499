import React from 'react';
import { useHistory } from 'react-router-dom';
import { INotification } from '../../interfaces/INotification';
import { NotificationTypes } from '../../interfaces/NotificationTypes.enum';
import { PlanningCreatedCard } from './PlanningCreatedCard/PlanningCreatedCard';
import { TagHealthCard } from './TagHealthCard/TagHealthCard';
import { DistressButtonCard } from './DistressButtonCard/DistressButtonCard';
import { DailyReportCard } from './DailyReportCard/DailyReportCard';
import { TranslationService } from '../../services/translation.service';
import { RequestService } from '../../services/request.service';
import { getNotificationRouteByType } from '../../utils/notifications.utils';
import { isTrusstorMobile } from '../../utils/mobile.utils';
import { RestrictedAreaNotificationCard } from './RestrictedAreaNotificationCard/RestrictedAreaNotificationCard';
import { UserAccessLevelService } from '../../services/userAccessLevelService';
import { IssueCommentCreatedCard } from './IssueCommentCreatedCard/IssueCommentCreatedCard';
import { CreatedIssuesCard } from './CreatedIssuesCard/CreatedIssuesCard';
import { StorageService } from '../../services/storage.service';
import { IUser } from '../../interfaces/IUser';
import { WeeklyProgressAnalysisReportCard } from './WeeklyProgressAnalysisReportCard/WeeklyProgressAnalysisReportCard';
import { FeatureFlagNamesEnum } from '../../constants/featureFlags.enum';
import { useIsFeatureFlagEnabled } from '../../hooks/useFeatureFlag';
import { IssueAssignedCard } from './IssueAssignedCard/IssueAssignedCard';
import { IssueStatusUpdatedCard } from './IssueStatusUpdatedCard.tsx/IssueStatusUpdatedCard';
import { AreaSequenceItemStatusUpdateCard } from './AreaSequenceItemStatusUpdateCard/AreaSequenceItemStatusUpdateCard';

interface INotificationCardProps {
	notification: INotification;
	translationService: TranslationService;
	storageService: StorageService;
	accessLevelService?: UserAccessLevelService<string>;
	tz: string;
	requester: RequestService;
	handleCloseNotifications?: () => void;
	projectId: string;
	userDetails: IUser;
}

export const NotificationCard = (props: INotificationCardProps) => {
	const isSequenceProgressAnalysisEnabled: boolean = useIsFeatureFlagEnabled(
		FeatureFlagNamesEnum.sequenceProgressAnalysis
	);
	const history = useHistory();

	const handleCardClick = (customData?: string | Date) => {
		if (isTrusstorMobile()) {
			return;
		}
		const notificationRoute: string = getNotificationRouteByType(props.notification.type, customData);
		props.handleCloseNotifications && props.handleCloseNotifications();
		history.push(notificationRoute);
	};
	if (props.notification.type === NotificationTypes.IssueCommentCreated) {
		return (
			<IssueCommentCreatedCard
				storageService={props.storageService}
				notification={props.notification}
				translationService={props.translationService}
				tz={props.tz}
				requester={props.requester}
				handleCardClick={handleCardClick}
			/>
		);
	}
	if (props.notification.type === NotificationTypes.AssignedIssue) {
		return (
			<IssueAssignedCard
				storageService={props.storageService}
				notification={props.notification}
				translationService={props.translationService}
				tz={props.tz}
				requester={props.requester}
				handleCardClick={handleCardClick}
			/>
		);
	}
	if (props.notification.type === NotificationTypes.IssueStatusUpdated) {
		return (
			<IssueStatusUpdatedCard
				storageService={props.storageService}
				notification={props.notification}
				translationService={props.translationService}
				tz={props.tz}
				requester={props.requester}
				handleCardClick={handleCardClick}
			/>
		);
	}
	if (props.notification.type === NotificationTypes.CreatedIssues) {
		return (
			<CreatedIssuesCard
				requestService={props.requester}
				notification={props.notification}
				translationService={props.translationService}
				tz={props.tz}
				requester={props.requester}
				handleCardClick={handleCardClick}
			/>
		);
	}
	if (props.notification.type === NotificationTypes.PlanningCreated) {
		return (
			<PlanningCreatedCard
				notification={props.notification}
				translationService={props.translationService}
				tz={props.tz}
				requester={props.requester}
				handleCardClick={handleCardClick}
			/>
		);
	}
	if (props.notification.type === NotificationTypes.TagHealth) {
		return (
			<TagHealthCard
				notification={props.notification}
				translationService={props.translationService}
				tz={props.tz}
				requester={props.requester}
				handleCardClick={handleCardClick}
				accessLevelService={props.accessLevelService}
			/>
		);
	}
	if (
		props.notification.type === NotificationTypes.SequenceProgressAnalysisReport &&
		isSequenceProgressAnalysisEnabled
	) {
		return (
			<WeeklyProgressAnalysisReportCard
				notification={props.notification}
				tz={props.tz}
				handleCardClick={handleCardClick}
				userDetails={props.userDetails}
				projectId={props.projectId}
			/>
		);
	}
	if (props.notification.type === NotificationTypes.DistressButton) {
		return (
			<DistressButtonCard
				notification={props.notification}
				translationService={props.translationService}
				tz={props.tz}
				requester={props.requester}
				handleCardClick={handleCardClick}
			/>
		);
	}
	if (props.notification.type === NotificationTypes.DailyReport) {
		return (
			<DailyReportCard
				notification={props.notification}
				translationService={props.translationService}
				tz={props.tz}
				requester={props.requester}
				handleCardClick={handleCardClick}
				accessLevelService={props.accessLevelService}
				userDetails={props.userDetails}
				projectId={props.projectId}
			/>
		);
	}
	if (props.notification.type === NotificationTypes.RestrictedArea) {
		return (
			<RestrictedAreaNotificationCard
				notification={props.notification}
				translationService={props.translationService}
				tz={props.tz}
				requester={props.requester}
			/>
		);
	}
	if (props.notification.type === NotificationTypes.AreaSequenceItemStatusUpdate) {
		return (
			<AreaSequenceItemStatusUpdateCard
				notification={props.notification}
				requestService={props.requester}
				translationService={props.translationService}
				tz={props.tz}
				handleCardClick={handleCardClick}
			/>
		);
	}
	return <div></div>;
};
