import React from 'react';
import classes from './styles.module.scss';
import blackWarning from '@shared/assets/icons/blackWarning.svg';
import { translationService } from '../../../../index';

interface IEmptyPage {
	text?: string;
	height?: number;
	clearStyle?: boolean;
}

export const InvestigationReportEmptyPage = (props: IEmptyPage) => {
	return (
		<div
			className={`${classes.InvestigationReportEmptyPage_container} ${
				props.clearStyle ? classes.clearStyle : ''
			}`}
			style={props.height ? { height: props.height } : {}}
			data-testid="InvestigationReportEmptyPage"
		>
			<div className={classes.innerContainer}>
				<div className={classes.warningClass}>
					<img src={blackWarning} />
				</div>
				<div className={classes.bottomContainer}>
					<div className={classes.noData}>{translationService.get('noDataAvailable')}</div>
					<div className={classes.noDataDescription}>
						{props.text ? props.text : translationService.get('tagHasNoData')}
					</div>
				</div>
			</div>
		</div>
	);
};
