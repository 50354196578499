import { compact, groupBy, omit, uniq } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import fingerTapIcon from '../../../../assets/icons/fingerTap.svg';
import classes from './styles.module.scss';
import { IFloor } from '../../../../interfaces/IFloor';
import { IIssueLocation } from '../../../../interfaces/IIssueShared';
import { ISelectedPoint, ISelectedPointWithAreaId } from '../../../../interfaces/ISelectedPoint';
import { useSharedServices } from '../../../../hooks/sharedServices.context';
import { IConfigArea } from '../../../../interfaces/IConfigArea';
import { useFloorViewDataQuery } from '../../../../hooks/queries/floors.queries.hooks';
import { areasLayoutId } from '../../../FloorPlanDisplay/FloorPlanDisplay.constants';
import { useAreasByFloorIdDataQuery } from '../../../../hooks/queries/areas.queries.hooks';
import { combineIssuesLocationsSelectedPoints } from '../../../../utils/issues.utils';
import { TrusstorIconButtonDeprecated } from '../../../buttons/TrusstorIconButton/TrusstorIconButtonDeprecated';
import { IconColor, IconSize, TrusstorIconShared } from '../../../TrusstorIconShared/TrusstorIconShared';
import { IconNames } from '../../../TrusstorIconShared/IconNames.enum';
import { Loader } from '../../../Loader/Loader';
import { FloorPlanDisplay } from '../../../FloorPlanDisplay/FloorPlanDisplay';
import { ITrusstorButtonType, TrusstorButton } from '../../../buttons/TrusstorButton/TrusstorButton';
import { getAreaIdFromElementId } from '../../../../utils/svg.utils';

interface IFloorPlanPointSelectorProps {
	floor: IFloor;
	onClose: () => void;
	onSelect: (selectedLocations: IIssueLocation[]) => void;
	selectedLocations?: IIssueLocation[];
	projectId: string;
}

interface ISelectedPointsPerArea {
	[areaId: string]: ISelectedPoint[];
}

export const FloorPlanPointSelector = (props: IFloorPlanPointSelectorProps) => {
	const { translationService, requestService } = useSharedServices();
	const getInitialSelectedPoints = () => {
		if (!props.selectedLocations) return undefined;
		const selectedPoints: ISelectedPointWithAreaId[] = compact(
			props.selectedLocations.flatMap((location) => {
				const { areaId } = location.area;
				if (!location.area.selectedPoints?.length) {
					return null;
				}
				return location.area.selectedPoints.map((point) => ({ ...point, areaId }));
			})
		);
		return selectedPoints;
	};
	const [pinCoordinates, setPinCoordinates] = useState<ISelectedPointWithAreaId[] | undefined>(
		getInitialSelectedPoints()
	);
	const [newPinMode, setNewPinMode] = useState<boolean>(!props.selectedLocations?.length);
	const [firstInitializeSelectedLocations, setFirstInitializeSelectedLocations] = useState<
		IIssueLocation[] | undefined
	>(props.selectedLocations);
	const [didUpdatePoints, setDidUpdatePoints] = useState<boolean>(false);
	const parentDivRef: React.RefObject<HTMLHeadingElement> = useRef<HTMLHeadingElement>(null);
	const isRtl: boolean = translationService.getIsRtl();
	const floorAreas: IConfigArea[] | undefined = useAreasByFloorIdDataQuery(
		requestService,
		props.projectId,
		props.floor.floorId
	);
	const {
		floorViewSvg: originalFloorSvg,
		isLoading,
		error,
	} = useFloorViewDataQuery(requestService, props.projectId, props.floor.floorId);
	const floorViewSvg: string | undefined = originalFloorSvg?.replace(/<svg/g, `<svg id="${areasLayoutId}"`);

	const selectedPointsPerArea: ISelectedPointsPerArea = useMemo(() => {
		if (!pinCoordinates) return {};
		const groupedPointsPerArea: ISelectedPointsPerArea = groupBy(pinCoordinates, (point) => point.areaId);
		return groupedPointsPerArea;
	}, [pinCoordinates]);

	const handleClearClick = () => {
		setNewPinMode(true);
		setFirstInitializeSelectedLocations([]);
		setPinCoordinates([]);
	};

	useEffect(() => {
		if (!pinCoordinates || !floorAreas) return;
		const pinCoordinatesGroupedByAreaId: ISelectedPointsPerArea = groupBy(pinCoordinates, (point) => point.areaId);
		const pinIssueLocations: IIssueLocation[] = Object.keys(pinCoordinatesGroupedByAreaId).map((areaId) => {
			const selectedPointsWithoutAreaId: ISelectedPoint[] = selectedPointsPerArea[areaId].map((selectedPoint) =>
				omit(selectedPoint, 'areaId')
			);
			return {
				area: {
					areaId,
					areaNick: floorAreas.find((area) => area.areaId === areaId)!.areaNick,
					selectedPoints: selectedPointsWithoutAreaId,
				},
				floor: {
					floorId: props.floor.floorId,
					floorNick: props.floor.floorNick,
				},
			};
		});
		const combinedSelectedLocations: IIssueLocation[] = combineIssuesLocationsSelectedPoints(
			pinIssueLocations,
			firstInitializeSelectedLocations || []
		);
		props.onSelect(combinedSelectedLocations);
	}, [pinCoordinates, floorAreas]);

	if (error) {
		return (
			<div className={classes.errorContainer}>
				<div className={classes.backButton}>
					<TrusstorIconButtonDeprecated
						iconElement={
							<TrusstorIconShared iconName={isRtl ? IconNames.arrowRight : IconNames.arrowLeft} />
						}
						onClick={props.onClose}
					/>
				</div>
				{translationService.get('noFloorPlanAvailable')}
			</div>
		);
	}

	if (isLoading || !floorViewSvg || !floorAreas) {
		return (
			<div className={classes.loaderContainer}>
				<div className={classes.backButton}>
					<TrusstorIconButtonDeprecated
						iconElement={
							<TrusstorIconShared iconName={isRtl ? IconNames.arrowRight : IconNames.arrowLeft} />
						}
						onClick={props.onClose}
					/>
				</div>
				<Loader />
			</div>
		);
	}

	const handlePinPlacement = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		const elementId: string = (event.target as HTMLDivElement).id;
		const didntUpdatePointsAndNotInNewPinMode: boolean = !didUpdatePoints && !newPinMode;
		if (!elementId.includes('area') || elementId === areasLayoutId || didntUpdatePointsAndNotInNewPinMode) {
			return;
		}
		const areaId: string = getAreaIdFromElementId(elementId);
		const isAreaIdExists: boolean = floorAreas.some((area) => area.areaId === areaId);
		if (!isAreaIdExists) return;
		const parentDiv: HTMLHeadingElement | null = parentDivRef.current;
		if (!parentDiv) return;
		const { offsetX, offsetY } = event.nativeEvent;
		const xPercentage = (offsetX / parentDiv.clientWidth) * 100;
		const yPercentage = (offsetY / parentDiv.clientHeight) * 100;

		setPinCoordinates((prev) => {
			const selectedPoint: ISelectedPointWithAreaId = { xPercentage, yPercentage, areaId };
			if (!prev) return [selectedPoint];
			if (!newPinMode) {
				return [...prev.slice(0, prev.length - 1), selectedPoint];
			}
			return [...prev, selectedPoint];
		});
		setNewPinMode(false);
		setDidUpdatePoints(true);
	};

	const isNewPinClickDisabled = () => {
		return !pinCoordinates?.length && !firstInitializeSelectedLocations?.length;
	};

	return (
		<div className={classes.floorPlanPointsSelectorContainer}>
			<div className={classes.topSection}>
				<TrusstorIconButtonDeprecated
					iconElement={<TrusstorIconShared iconName={isRtl ? IconNames.arrowRight : IconNames.arrowLeft} />}
					onClick={props.onClose}
				/>
				<p className={classes.floorText}>{props.floor.floorNick}</p>
				<TrusstorIconShared
					iconName={IconNames.plusCircle}
					onClick={() => {
						if (isNewPinClickDisabled()) return;
						setNewPinMode(true);
					}}
					size={IconSize.LARGE}
					color={isNewPinClickDisabled() ? IconColor.Grey100 : IconColor.Black}
					className={isNewPinClickDisabled() ? undefined : classes.cursorPointer}
				/>
			</div>
			<div ref={parentDivRef} className={classes.floorPlanContainer}>
				{
					<FloorPlanDisplay
						pinCoordinates={pinCoordinates || []}
						additionalSelectedAreasIds={firstInitializeSelectedLocations?.map(
							(location) => location.area.areaId
						)}
						floorPlanSvg={floorViewSvg}
						onClick={handlePinPlacement}
					/>
				}
				{!props.selectedLocations && (
					<div className={classes.instructionsText}>
						<p>{translationService.get('tapOnFloorPlanToPinLocation')}</p>
						<img src={fingerTapIcon} alt="fingerTap" />
					</div>
				)}
			</div>
			<div className={classes.bottomSection}>
				<TrusstorButton
					text={translationService.get('clear')}
					buttonType={ITrusstorButtonType.GHOST}
					className={classes.floorText}
					disabled={!pinCoordinates || pinCoordinates.length === 0}
					handleClick={() => {
						handleClearClick();
					}}
				/>
			</div>
		</div>
	);
};
