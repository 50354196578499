import React, { useEffect, useRef, useState } from 'react';
import { ISvgElementContainer } from '@interfaces/ISvgElementContainer';
import classes from './styles.module.scss';
import classnames from 'classnames';

interface IFloorHighlightedAreaProps {
	highlightedArea?: ISvgElementContainer;
	isChoosen: boolean;
	onClick?: () => void;
}

export const FloorHighlightedArea = (props: IFloorHighlightedAreaProps) => {
	const [isHovered, setIsHovered] = useState<boolean>(false);
	const parentRef = useRef<HTMLHeadingElement | null>(null);

	useEffect(() => {
		const handleMouseEnter = () => {
			setIsHovered(true);
		};

		const isMouseInsideContainer = (event: MouseEvent, containerBounds: DOMRect): boolean => {
			const { clientX, clientY } = event;

			const diffX: number = Math.min(
				Math.abs(clientX - containerBounds.left),
				Math.abs(clientX - containerBounds.right)
			);
			const diffY: number = Math.min(
				Math.abs(clientY - containerBounds.top),
				Math.abs(clientY - containerBounds.bottom)
			);

			if (
				clientX >= containerBounds.left &&
				clientX <= containerBounds.right &&
				clientY >= containerBounds.top &&
				clientY <= containerBounds.bottom &&
				diffX > 2 &&
				diffY > 2
			) {
				return true;
			}
			return false;
		};

		const handleMouseLeave = (event) => {
			if (!parentRef.current) {
				return;
			}
			const containerBounds = parentRef.current.getBoundingClientRect();
			if (isMouseInsideContainer(event, containerBounds)) {
				return;
			}

			setIsHovered(false);
		};

		const parentElement = parentRef.current;
		if (!parentElement) {
			return;
		}

		parentElement.addEventListener('mouseenter', handleMouseEnter);
		parentElement.addEventListener('mouseleave', handleMouseLeave);

		return () => {
			parentElement.removeEventListener('mouseenter', handleMouseEnter);
			parentElement.removeEventListener('mouseleave', handleMouseLeave);
		};
	}, []);

	if (!props.highlightedArea) {
		return <></>;
	}

	const highlightedAreaClassName: string = classnames({
		[classes.floorHighlightedArea]: true,
		[classes.floorHighlightedAreaChoosen]: props.isChoosen,
		[classes.highlightedChoosenAreaHovered]: props.isChoosen && isHovered,
		[classes.highlightedAreaHovered]: isHovered,
	});

	return (
		<div
			key={`floor_${props.highlightedArea.floorId}_highlightedArea`}
			className={highlightedAreaClassName}
			ref={parentRef}
			style={{
				top: props.highlightedArea.top,
				right: props.highlightedArea.right,
				left: props.highlightedArea.left,
				width: props.highlightedArea.width,
				height: props.highlightedArea.height,
			}}
			onClick={props.onClick}
		/>
	);
};
