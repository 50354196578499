import React, { useEffect, useMemo } from 'react';
import { IProfession } from '@interfaces/index';
import { ProfessionsFilterSection } from '@src/Components/FilterSections/ProfessionsFilterSection';
import { resetSelectedProfessions, updateActiveProfessions } from '@store/slices/professions.slice';
import { useDispatch } from 'react-redux';
import { IssuePrioritiesFilterSection } from '@src/Components/FilterSections/IssuePrioritiesFilterSection';
import { IssueStatusesFilterSection } from '@src/Components/FilterSections/IssueStatusesFilterSection';
import { IIssue } from '@shared/interfaces/IIssue';
import { updateActiveUsers } from '@store/slices/users.slice';
import { IIssueAssignee } from '@shared/interfaces/IIssueShared';
import { UsersFilterSection } from '@src/Components/FilterSections/UsersFilterSection';
import {
	IUseIssuesFilterPropertiesData,
	useIssuesFilterPropertiesData,
} from '@shared/hooks/useIssuesFilterPropertiesData.hook';
import { AreasFilterSection } from '@src/Components/FilterSections/AreasFilterSection';
import { resetFilteredAreasList } from '@store/slices/areasList.slice';

interface IIssuesFilterSectionProps {
	issues: IIssue[];
}

export const IssuesFilterSection = (props: IIssuesFilterSectionProps) => {
	const dispatch = useDispatch();

	const displayedIssues = useMemo((): IIssue[] => {
		const issuesWithoutInitial: IIssue[] = props.issues.filter((issue) => !issue.isInitial) || [];
		const displayedIssues: IIssue[] = issuesWithoutInitial.length > 0 ? issuesWithoutInitial : props.issues;
		return displayedIssues;
	}, [props.issues]);

	const {
		assigneesCountObj,
		professionCountObj,
		prioritiesCountObj,
		areasCountObj,
		statusesCountObj,
		activeProfessions,
		activeAssignees,
		activeAreas,
	}: IUseIssuesFilterPropertiesData = useIssuesFilterPropertiesData(displayedIssues);

	const setFilteredProfessions = (professions: IProfession[]) => {
		dispatch(
			updateActiveProfessions({
				activeProfessions: professions,
			})
		);
	};

	const setFilteredAssignees = (users: IIssueAssignee[]) => {
		dispatch(
			updateActiveUsers({
				activeUsers: users,
			})
		);
	};

	useEffect(() => {
		if (activeProfessions.length > 0) {
			setFilteredProfessions(activeProfessions);
		}
		if (activeAssignees.length > 0) {
			setFilteredAssignees(activeAssignees);
		}
	}, [activeProfessions, activeAssignees]);

	useEffect(() => {
		return () => {
			dispatch(resetSelectedProfessions());
			dispatch(resetFilteredAreasList());
		};
	}, []);

	return (
		<div>
			<IssuePrioritiesFilterSection prioritiesCountObj={prioritiesCountObj} defaultOpen />
			<IssueStatusesFilterSection statusesCountObj={statusesCountObj} defaultOpen />
			<ProfessionsFilterSection professionCountObj={professionCountObj} defaultOpen />
			<UsersFilterSection usersCountObj={assigneesCountObj} defaultOpen />
			<AreasFilterSection projectAreas={activeAreas} handleResetClick={() => {}} areasCountObj={areasCountObj} />
		</div>
	);
};
