import React from 'react';
import classes from './styles.module.scss';
import { ProjectInfoSection } from '../ProjectInfoSection/ProjectInfoSection';
import { ProjectMilestonesSection } from '../ProjectMilestonesSection/ProjectMilestonesSection';
import { cM } from '@shared/utils/colors.utils';
import { IProject } from '@shared/interfaces/IProject';
import { IProjectMilestones } from '@shared/interfaces/IProjectMilestones';
import { ProjectEfficiencySection } from '../ProjectEfficiencySection/ProjectEfficiencySection';

interface IProjectCardProps {
	project: IProject;
	projectMilestones: IProjectMilestones | undefined;
}

function ProjectCard(props: IProjectCardProps) {
	return (
		<div className={cM(classes.cardContainer, classes)} data-testid="ProjectCard">
			<ProjectInfoSection project={props.project} />
			<ProjectMilestonesSection projectMilestones={props.projectMilestones} currentProject={props.project} />
			<ProjectEfficiencySection project={props.project} />
		</div>
	);
}

export { ProjectCard };
