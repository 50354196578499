import React, { useEffect } from 'react';
import { Redirect, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { config } from '../../config';
import classes from './styles.module.scss';
import { translationService, userService } from '../..';
import { ILoggedUser } from '@shared/interfaces/IUser';
import { COLORS } from '@shared/constants/colors.constants';
import { TrusstorSnackbar } from '../TrusstorSnackbar/TrusstorSnackbar';
import { hideSnackbar, selectSnackbarState } from '../../store/slices/snackbar.slice';
import { TopBar } from '../Bars/TopBar/TopBar';
import { SideBar } from '../Bars/SideBar/SideBar';
import { ISnackbarReducerState } from '../../interfaces/ISanckbarReducerState';
import { IProject } from '@shared/interfaces/IProject';
import { fetchAndSetWorkingProject } from '../../store/thunks';
import { IRootState } from '../../store/slices';
import classnames from 'classnames';
import { getIsExecutiveUser } from '@utils/users.utils';
import { selectLoggedUser } from '@store/slices/login.slice';
import { NotificationsSection } from '@src/Components/Pages/Notifications/NotificationsSection';
import { NotificationSnackbarsSection } from '../Pages/Notifications/NotificationsSnackbarsSection/NotificationsSnackbarsSection';
import { selectProjectId } from '@store/slices/project.slice';
import { MonitoringRoute } from '@shared/utils/monitoring.util';

interface IProtectedRouteProps {
	component: any;
	loggedUser?: ILoggedUser;
	isAllowed?: boolean;
	exact?: boolean;
	path: string | string[];
	emptyLayout?: boolean;
	ignoreUrlProjectId?: boolean;
	hideProjectSelector?: boolean;
	hideTopBar?: boolean;
}

const ProtectedRoute = ({
	component: Component,
	exact,
	path,
	isAllowed = true,
	emptyLayout = false,
	ignoreUrlProjectId = false,
	hideProjectSelector = false,
	hideTopBar = false,
}: IProtectedRouteProps) => {
	const dispatch = useDispatch();
	const {
		vertical,
		horizontal,
		open,
		snackbarMessage,
		snackbarSecondaryMessage,
		backgroundColor,
		color,
		alertType,
	}: ISnackbarReducerState = useSelector(selectSnackbarState);
	const isRtl: boolean = translationService.getIsRtl();
	const workingProjectId: string | undefined = useSelector(selectProjectId);
	const workingProjectName: string | undefined = useSelector(
		(state: any) => state.projectReducer.workingProject?.name
	);
	const userProjects: IProject[] | undefined = useSelector((state: IRootState) => state.projectReducer.userProjects);
	const [isExecutiveUser, setIsExecutiveUser] = React.useState<boolean>(false);
	const loggedUser: ILoggedUser | null = useSelector(selectLoggedUser);
	const accessToken: string | null = userService.getLoggedUserAccessToken();
	// 14.9 HOTFIX - remove!!
	const isTzukeiHashmura: boolean = workingProjectId === 'shikunbinui-tzukei-hashmura';
	const location = useLocation();
	const history = useHistory();
	const match = useRouteMatch<{ projectId: string }>(path);

	useEffect(() => {
		if (!loggedUser) {
			setIsExecutiveUser(false);
			return;
		}

		setIsExecutiveUser(getIsExecutiveUser(loggedUser));
	}, [loggedUser?.userDetails?.permissions?.roleType]);

	useEffect(() => {
		document.title = `Trusstor ${!location.pathname.includes('projects') ? `| ${workingProjectName}` : ''} `;
		const previousLoginExists: boolean = userService.getPreviousLoginExistsAtt();
		const projectId: string | undefined = match?.params?.projectId;
		const shouldRedirectToExecutiveView: boolean =
			isExecutiveUser && !projectId && !previousLoginExists && !location.pathname.includes('projects');
		if (shouldRedirectToExecutiveView) {
			history.push('/projects', location.state);
			userService.setPreviousLoginExistsAtt(true);
			return;
		}
		if (workingProjectId && !projectId && !ignoreUrlProjectId) {
			history.replace(`/${workingProjectId}${location.pathname}`, location.state);
		} else if (projectId !== workingProjectId) {
			if (projectId && userProjects?.some((project: IProject) => project.projectId === projectId)) {
				fetchAndSetWorkingProject(projectId)(dispatch);
			}
		}
	}, [location, history, workingProjectId]);

	const checkIsAllowed = (): boolean => {
		const isUserProjectEnabled: boolean = loggedUser!.userProjects?.some(
			(userProject: IProject) => userProject.isEnabled
		);
		return !!(isAllowed && isUserProjectEnabled);
	};

	const isAppRequiredDataLoaded: boolean = !!(loggedUser && userProjects?.length && workingProjectId);

	return (
		<>
			{!emptyLayout && loggedUser && workingProjectId && !config.shouldRedirectToMobile && (
				<>
					<TrusstorSnackbar
						alertType={alertType}
						backgroundColor={backgroundColor}
						color={color as COLORS}
						vertical={vertical}
						horizontal={horizontal}
						message={snackbarMessage}
						secondaryMessage={snackbarSecondaryMessage}
						open={open}
						close={() => dispatch(hideSnackbar())}
					/>
					{!hideTopBar && <TopBar projectId={workingProjectId} hideProjectSelector={hideProjectSelector} />}
					<SideBar />
					{config.showNotifications && !isTzukeiHashmura && <NotificationSnackbarsSection />}
					{config.showNotifications && !isTzukeiHashmura && <NotificationsSection />}
				</>
			)}
			<MonitoringRoute
				exact={exact}
				path={path}
				render={(props) => {
					if (isAppRequiredDataLoaded) {
						if ((!config.shouldRedirectToMobile || isExecutiveUser) && checkIsAllowed()) {
							return (
								<div
									className={classnames({
										[classes.fullAppContainer]: emptyLayout,
										[classes.appContainer]: !emptyLayout,
										[classes.isRtl]: isRtl,
										[classes.noProjectSelector]: hideProjectSelector || hideTopBar,
									})}
									id="appContainer"
								>
									<Component {...props} />
								</div>
							);
						}

						return <div />;
					}

					if (accessToken) {
						return <div />;
					}

					return (
						<Redirect
							to={{
								pathname: `/login/${config.defaultLanguage}`,
								state: {
									from: props.location,
								},
							}}
						/>
					);
				}}
			/>
		</>
	);
};

export default ProtectedRoute;
