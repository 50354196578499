import posthog from 'posthog-js';
import { Environments } from '../constants/environments';
import { IUser } from '../interfaces/IUser';

export const initPostHog = (posthogAPIKey: string, env: Environments): void => {
	const isLocal: boolean = env === Environments.compose || env === Environments.dev;
	if (isLocal) {
		return;
	}

	const isProd: boolean = env === Environments.prod;
	posthog?.init(posthogAPIKey, {
		api_host: 'https://d1dwpd1oa12w9c.cloudfront.net',
		ui_host: 'https://app.posthog.com',
		disable_session_recording: !isProd,
		autocapture: isProd,
		opt_out_capturing_by_default: !isProd,
		capture_pageview: isProd,
		capture_pageleave: isProd,
		loaded: function (posthog) {
			posthog.capture('config used', { disable_session_recording: !isProd });
		},
	});
};

export const initPostHogByUserData = (userDetails: IUser, projectId: string, env: Environments): void => {
	const isLocal: boolean = env === Environments.compose || env === Environments.dev;
	if (isLocal) {
		return;
	}

	posthog?.identify(userDetails._id, {
		userId: `user_${userDetails?._id}`,
		displayName: userDetails?.name,
		username: userDetails?.username,
		email: userDetails?.email,
		role: userDetails?.permissions.roleType,
		projectId,
		env,
	});
};
