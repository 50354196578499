import React from 'react';
import classes from './styles.module.scss';
import classnames from 'classnames';
import {
	ITrusstorIconButtonStyle,
	TrusstorIconButtonDeprecated,
} from '@shared/components/buttons/TrusstorIconButton/TrusstorIconButtonDeprecated';
import { TrusstorIcon } from '@src/Components/TrusstorIcon/TrusstorIcon';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { IconColor, IconSize } from '@shared/components/TrusstorIconShared/TrusstorIconShared';
import { IMainPageWidgetActivityData } from '@interfaces/IMainPageWidgetActivityData';
import { isAsiComplete } from '@shared/utils/asi.utils';
import { selectProjectId } from '@store/slices/project.slice';
import { useSelector } from 'react-redux';
import { accessLevelService, translationService } from '@src/servicesInitializers';
import { ActivityGroupStatus } from '@shared/interfaces/ActivityGroupStatus.enum';
import {
	ITrusstorButtonSize,
	ITrusstorButtonType,
	TrusstorButton,
} from '@shared/components/buttons/TrusstorButton/TrusstorButton';
import { ProfessionDisplayWithTradeIcon } from '@shared/components/ProfessionDisplayWithTradeIcon/ProfessionDisplayWithTradeIcon';
import { TrusstorIconButtonV2 } from '@shared/components/buttons/TrusstorIconButtonV2/TrusstorIconButtonV2';

interface IWidgetActivityCardDetailsProps {
	activity: IMainPageWidgetActivityData;
	showIssuesDialog: () => void;
	showCompleteDialog: () => void;
	isDialog?: boolean;
}

export const WidgetActivityCardDetails = (props: IWidgetActivityCardDetailsProps) => {
	const projectId: string = useSelector(selectProjectId)!;
	const language: string = translationService.getChosenLanguage();
	const completedAreaSequenceItemCount: number = props.activity.areaSequenceItems.filter((areaSequenceItem) =>
		isAsiComplete(areaSequenceItem.status)
	).length;

	const isSequenceActivity: boolean = props.activity.areaSequenceItems.length > 0;

	const userHasAccessToActions: boolean = accessLevelService.hasAccess('activitiesWidgetActions');

	const shouldShowCompleteButton = (): boolean => {
		if (!props.isDialog || props.activity.groupStatus === ActivityGroupStatus.complete || !userHasAccessToActions) {
			return false;
		}

		if (!isSequenceActivity) {
			return true;
		}

		return completedAreaSequenceItemCount === props.activity.areaSequenceItems.length;
	};

	return (
		<div className={classes.body} data-testid="WidgetActivityCardDetails">
			<div className={classnames(classes.bodyContent, classes.bodyHeader)}>
				<div className={classes.professionWrapper}>
					<ProfessionDisplayWithTradeIcon profession={props.activity.profession} projectId={projectId} />
				</div>
				<div className={classes.bottomRight}>
					{!!props.activity.issues?.length && (
						<div
							className={classnames(classes.infoContainer)}
							onClick={(e) => {
								e.stopPropagation();
								props.showIssuesDialog();
							}}
							data-testid={'issuesButton'}
						>
							<TrusstorIconButtonV2
								style={ITrusstorIconButtonStyle.GHOST}
								iconName={IconNames.issues}
								size={IconSize.MEDIUM}
								color={IconColor.Grey600}
							/>
							<div data-testid={'issuesCount'}>{props.activity.issues?.length}</div>
						</div>
					)}

					{!!props.activity.areaSequenceItems.length && (
						<div className={classnames(classes.infoContainer)}>
							<TrusstorIcon
								iconName={IconNames.checkCircle}
								size={IconSize.MEDIUM}
								color={IconColor.FeedbackPositiveDark}
							/>
							<div data-testid={'completedCount'}>
								{`${completedAreaSequenceItemCount}/${props.activity.areaSequenceItems.length}`}
							</div>
						</div>
					)}

					{shouldShowCompleteButton() && (
						<div
							className={classes.completeButtonContainer}
							onClick={(e) => {
								e.stopPropagation();
								props.showCompleteDialog();
							}}
							data-testid={'completeButton'}
						>
							<TrusstorButton
								buttonSize={ITrusstorButtonSize.SMALL}
								buttonType={ITrusstorButtonType.OUTLINE}
								text={translationService.get('markActivityComplete')}
								className={classes.completeButton}
							/>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
