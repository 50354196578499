import React from 'react';
import { ManagerChip } from '../../../Chips/ManagerChip/ManagerChip';
import { EquipmentChipTooltipContent } from '../../../Chips/EquipmentChipTooltipContent';
import { IManagerMergedTag } from '@interfaces/IManagerMergedTag';
import { IMergedEquipmentView } from '@interfaces/IMergedEquipmentView';
import { IProfessionWithActiveTags } from '@interfaces/IProfessionWithActiveTags';
import { selectProjectId } from '@store/slices/project.slice';
import { useSelector } from 'react-redux';
import classes from './styles.module.scss';
import { IWorkerMergedTag } from '@interfaces/IWorkerMergedTag';
import { ProfessionDisplayWithTradeIcon } from '@shared/components/ProfessionDisplayWithTradeIcon/ProfessionDisplayWithTradeIcon';
import { IProfession } from '@shared/interfaces/IProfession';
import { TrusstorIcon } from '@src/Components/TrusstorIcon/TrusstorIcon';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { IconColor, IconSize } from '@shared/components/TrusstorIconShared/TrusstorIconShared';
import classnames from 'classnames';
import { IUtilityMergedTag } from '@interfaces/IUtilityMergedTag';
import { Dictionary, groupBy } from 'lodash';
import { IScissorsLiftUtility } from '@interfaces/IScissorsLiftUtility';
import { LiveActionAnimatedIcon } from '@src/Components/LiveActionAnimatedIcon/LiveActionAnimatedIcon';
import { getActiveScissorLiftTags } from '@utils/scissorLift.utils';
import { getEquipmentIconNameFromType } from '@shared/utils/equipment.utils';

interface IMixedEntitiesTooltipProps {
	floorId: string;
	title: string;
	activeProfessions: IProfessionWithActiveTags[];
	managers: IManagerMergedTag[];
	equipment: IMergedEquipmentView[];
	utilities: IUtilityMergedTag[];
	visibleWorkersOnSite: IWorkerMergedTag[];
}

export const FloorMixedEntitiesTooltip = (props: IMixedEntitiesTooltipProps) => {
	const projectId: string = useSelector(selectProjectId)!;

	const utilitiesGroupedByType: Dictionary<IUtilityMergedTag[]> = groupBy(
		props.utilities,
		(utility) => utility.linkedUtility.type
	);
	const activeScissorLiftTags: IUtilityMergedTag[] = getActiveScissorLiftTags(props.utilities);

	return (
		<div className={classes.container}>
			<div className={classes.title}>{props.title}</div>
			<div className={classes.content}>
				{!!(props.equipment.length || props.managers.length) && (
					<div
						className={classnames(classes.chips, {
							[classes.bottomDivider]: !!props.activeProfessions.length,
						})}
					>
						{props.equipment.map((entity) => {
							return (
								<EquipmentChipTooltipContent
									name={entity.name}
									key={`floor_${props.floorId}_${entity.equipmentId}`}
									type={entity.type}
									floorId={undefined}
									className={undefined}
								/>
							);
						})}
						{props.managers.map((entity) => {
							return (
								<ManagerChip
									key={`floor_${props.floorId}_${(entity as IManagerMergedTag).manager.name}`}
									chipText={(entity as IManagerMergedTag).manager.name}
									chipBackground={(entity as IManagerMergedTag).manager.backgroundColor}
								/>
							);
						})}
					</div>
				)}
				{props.activeProfessions.map((entity, index) => {
					const tags: IWorkerMergedTag[] = props.visibleWorkersOnSite.filter((worker) =>
						entity.activeTagsIds.includes(worker.tagId)
					);
					return (
						<div
							className={classnames(classes.tagsContainer, {
								[classes.bottomDivider]: index < props.activeProfessions.length - 1,
							})}
						>
							<ProfessionDisplayWithTradeIcon profession={entity as IProfession} projectId={projectId} />
							<div className={classnames(classes.tags, { [classes.isOnlyOneTag]: tags.length === 1 })}>
								{tags.map((tag) => {
									return (
										<div className={classes.tag}>
											<div className={classes.tagInitialPart}>
												<TrusstorIcon iconName={IconNames.tag} color={IconColor.White} />
												<div className={classes.tagNick}>{tag.tagNick}</div>
											</div>
											<div className={classes.tagName}>{tag.name}</div>
										</div>
									);
								})}
							</div>
						</div>
					);
				})}
				{Object.values(utilitiesGroupedByType).map((utilities) => {
					const utilitiesGroupedByProfession = groupBy(
						utilities,
						(utility) => (utility.linkedUtility as IScissorsLiftUtility).profession._id
					);
					return Object.values(utilitiesGroupedByProfession).map((utilities, index) => {
						const profession = (utilities[0].linkedUtility as IScissorsLiftUtility).profession;
						const iconName: IconNames = getEquipmentIconNameFromType(utilities[0].linkedUtility.type);
						return (
							<div
								className={classnames(classes.tagsContainer, {
									[classes.bottomDivider]: index < props.activeProfessions.length - 1,
								})}
							>
								<div className={classes.utilitiesHeader}>
									<TrusstorIcon iconName={iconName} color={IconColor.White} size={IconSize.SMALL} />
									<ProfessionDisplayWithTradeIcon profession={profession} projectId={projectId} />
								</div>
								<div
									className={classnames(classes.tags, {
										[classes.isOnlyOneTag]: utilities.length === 1,
									})}
								>
									{utilities.map((tag) => {
										return (
											<div className={classes.tag}>
												<div className={classes.tagInitialPart}>
													{activeScissorLiftTags.some((active) => active._id === tag._id) && (
														<LiveActionAnimatedIcon />
													)}
													<TrusstorIcon iconName={IconNames.tag} color={IconColor.White} />
													<div className={classes.tagNick}>{tag.tagNick}</div>
												</div>
												<div className={classes.tagName}>
													{(tag.linkedUtility as IScissorsLiftUtility).name}
												</div>
											</div>
										);
									})}
								</div>
							</div>
						);
					});
				})}
			</div>
		</div>
	);
};
