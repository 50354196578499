import { IManager, IProfession, ITag, ITrade } from '../interfaces';
import { getNumberDaysAgo, getTimezoneFormattedDate, getTimezoneStartOfDate } from './dateUtils';
import {
	AssignedFilters,
	BatteryFilter,
	IAdminPanelFilters,
	IAdminPanelFiltersTypes,
	LastSeenFilter,
} from '../interfaces/IAdminPanelFilters';
import { ADMIN_PANEL_LAST_SEEN_DAYS, TagStatuses } from '../constants';
import { unassignedTradeId } from '@shared/constants/professions.constants';
import { ITagCertificate } from '../interfaces/ITagCertificate';
import { getProfessionDisplayText } from './professions.utils';
import { getProfessionBackgroundColor } from '@shared/utils/professions.utils';
import { getTradeTranslation } from './translations.utils';
import { UtilityTypes } from '@interfaces/UtilityTypes.enum';
import { IScissorsLiftUtility } from '@interfaces/IScissorsLiftUtility';

export const getTagChipDisplayText = (tag: ITag): string => {
	if (tag.manager) {
		const managerTadeTranslation: string = getTradeTranslation(tag.manager);
		return managerTadeTranslation;
	}

	if (tag.linkedUtility) {
		return getTradeTranslation(tag.linkedUtility);
	}

	return getProfessionDisplayText(tag.profession!);
};

export const getTagBackgroundColor = (tag: ITag, projectId: string): string => {
	if (tag.manager) {
		return tag.manager.backgroundColor;
	}
	if (tag.linkedUtility) {
		if (tag.linkedUtility.type === UtilityTypes.scissorsLift) {
			return getProfessionBackgroundColor((tag.linkedUtility as IScissorsLiftUtility).profession, projectId);
		}
		return tag.linkedUtility.backgroundColor;
	}
	return getProfessionBackgroundColor(tag.profession!, projectId);
};

const isAtLeastOneFilterSelected = (filterState: IAdminPanelFilters): boolean => {
	return (
		filterState[IAdminPanelFiltersTypes.assignedFilter][AssignedFilters.assignedTags] ||
		filterState[IAdminPanelFiltersTypes.assignedFilter][AssignedFilters.unassignedTags] ||
		filterState[IAdminPanelFiltersTypes.lastSeenFilter][LastSeenFilter.lastSeenOk] ||
		filterState[IAdminPanelFiltersTypes.lastSeenFilter][LastSeenFilter.lastSeenWarning] ||
		filterState[IAdminPanelFiltersTypes.lastSeenFilter][LastSeenFilter.lastSeenAlert] ||
		filterState[IAdminPanelFiltersTypes.statusFilter][TagStatuses.Good] ||
		filterState[IAdminPanelFiltersTypes.statusFilter][TagStatuses.Storage] ||
		filterState[IAdminPanelFiltersTypes.statusFilter][TagStatuses.Sleeping] ||
		filterState[IAdminPanelFiltersTypes.statusFilter][TagStatuses.NoReception] ||
		filterState[IAdminPanelFiltersTypes.batteryFilter][BatteryFilter.low] ||
		filterState[IAdminPanelFiltersTypes.batteryFilter][BatteryFilter.medium] ||
		filterState[IAdminPanelFiltersTypes.batteryFilter][BatteryFilter.high]
	);
};

const doesTagFitAssignedFilters = (filters: IAdminPanelFilters, tag: ITag): boolean => {
	if (!Object.values(filters.assignedFilter).includes(true)) {
		return true;
	}

	if (filters.assignedFilter.assignedTags && tag.profession?.tradeId !== unassignedTradeId) {
		return true;
	}
	if (filters.assignedFilter.unassignedTags && tag.profession?.tradeId === unassignedTradeId) {
		return true;
	}

	return false;
};

const doesTagFitLastSeenFilters = (filters: IAdminPanelFilters, tag: ITag, tz: string): boolean => {
	if (!Object.values(filters.lastSeenFilter).includes(true)) {
		return true;
	}

	const daysAgo = getNumberDaysAgo(tag.lastSeen, tz);
	if (filters.lastSeenFilter.lastSeenOk && daysAgo <= ADMIN_PANEL_LAST_SEEN_DAYS.OK_MAX_DAYS) {
		return true;
	}
	if (
		filters.lastSeenFilter.lastSeenWarning &&
		daysAgo > ADMIN_PANEL_LAST_SEEN_DAYS.OK_MAX_DAYS &&
		daysAgo <= ADMIN_PANEL_LAST_SEEN_DAYS.WARNING_MAX_DAYS
	) {
		return true;
	}
	if (filters.lastSeenFilter.lastSeenAlert && daysAgo > ADMIN_PANEL_LAST_SEEN_DAYS.WARNING_MAX_DAYS) {
		return true;
	}

	return false;
};

const doesTagFitStatusFilters = (filters: IAdminPanelFilters, tag: ITag): boolean => {
	if (!Object.values(filters[IAdminPanelFiltersTypes.statusFilter]).includes(true)) {
		return true;
	}
	if (filters[IAdminPanelFiltersTypes.statusFilter][TagStatuses.Good] && tag.tagStatus === TagStatuses.Good)
		return true;
	if (filters[IAdminPanelFiltersTypes.statusFilter][TagStatuses.Sleeping] && tag.tagStatus === TagStatuses.Sleeping)
		return true;
	if (filters[IAdminPanelFiltersTypes.statusFilter][TagStatuses.Storage] && tag.tagStatus === TagStatuses.Storage)
		return true;
	if (
		filters[IAdminPanelFiltersTypes.statusFilter][TagStatuses.NoReception] &&
		tag.tagStatus === TagStatuses.NoReception
	)
		return true;

	return false;
};

const doesTagFitBatteryFilters = (filters: IAdminPanelFilters, tag: ITag): boolean => {
	if (!Object.values(filters[IAdminPanelFiltersTypes.batteryFilter]).includes(true)) {
		return true;
	}
	if (
		filters[IAdminPanelFiltersTypes.batteryFilter][BatteryFilter.high] &&
		getBatteryLevelNumber(tag.batteryLevel) > 60
	)
		return true;
	if (
		filters[IAdminPanelFiltersTypes.batteryFilter][BatteryFilter.medium] &&
		getBatteryLevelNumber(tag.batteryLevel) > 30 &&
		getBatteryLevelNumber(tag.batteryLevel) <= 60
	)
		return true;
	if (
		filters[IAdminPanelFiltersTypes.batteryFilter][BatteryFilter.low] &&
		getBatteryLevelNumber(tag.batteryLevel) >= 0 &&
		getBatteryLevelNumber(tag.batteryLevel) <= 30
	)
		return true;
	return false;
};

export const filterTagsByFixedFilters = (tags: ITag[], filterState: IAdminPanelFilters, tz: string): ITag[] => {
	return tags.filter((tag: ITag) => {
		if (!isAtLeastOneFilterSelected(filterState)) {
			return true;
		}

		return (
			doesTagFitAssignedFilters(filterState, tag) &&
			doesTagFitLastSeenFilters(filterState, tag, tz) &&
			doesTagFitStatusFilters(filterState, tag) &&
			doesTagFitBatteryFilters(filterState, tag)
		);
	});
};

export const getInitialFixedFilterState = (): IAdminPanelFilters => ({
	lastSeenFilter: {
		lastSeenOk: false,
		lastSeenAlert: false,
		lastSeenWarning: false,
	},
	assignedFilter: { assignedTags: false, unassignedTags: false },
	batteryFilter: { high: false, medium: false, low: false },
	statusFilter: {
		Good: false,
		Sleeping: false,
		Storage: false,
		NoReception: false,
	},
});

export const getVisibleTagsAfterManagementAndProfessionsFilters = (
	tags: ITag[],
	isProfessionsFilterInitialState: boolean,
	visibleProfessions,
	isManagementTradesFilterInitialState,
	visibleManagementTrades
) => {
	const visibleTagsAfterProfessionsFilters: ITag[] = filterTagsByProfessionsFilters(
		tags,
		isProfessionsFilterInitialState ? [] : visibleProfessions
	);
	const visibleTagsAfterManagementFilters: ITag[] = filterTagsByManagementFilters(
		tags,
		isManagementTradesFilterInitialState ? [] : visibleManagementTrades
	);

	const visibleTagsAfterManagementAndProfessionsFilters = [
		...visibleTagsAfterProfessionsFilters,
		...visibleTagsAfterManagementFilters,
	];
	if (visibleTagsAfterManagementAndProfessionsFilters.length === 0) {
		return [...tags];
	}

	return visibleTagsAfterManagementAndProfessionsFilters;
};

export const filterTagsByManagementFilters = (tags: ITag[], managementTradesToFilterBy: ITrade[]): ITag[] => {
	return tags.filter((tag) => {
		return managementTradesToFilterBy.some((trade) => {
			if (tag.manager) {
				return tag.manager.tradeId === trade.tradeId;
			}
			return false;
		});
	});
};

export const filterTagsByProfessionsFilters = (tags: ITag[], professionsToFilterBy: IProfession[] | []): ITag[] => {
	return tags.filter((tag) => {
		return professionsToFilterBy.some((profession) => {
			if (tag.profession) {
				return tag.profession._id === profession._id;
			}
			return false;
		});
	});
};

export const filterTagsBySearchInput = (tags: ITag[], searchInput: string): ITag[] => {
	const lowerCaseSearchInput: string = searchInput.toLowerCase();
	return tags.filter(
		(tag: ITag) =>
			tag.tagNick.toLowerCase().includes(lowerCaseSearchInput) ||
			(tag.idNumber && tag.idNumber.includes(lowerCaseSearchInput)) ||
			tag?.name?.toLowerCase().includes(lowerCaseSearchInput) ||
			tag?.manager?.name?.toLowerCase().includes(lowerCaseSearchInput)
	);
};

export const getBatteryLevelNumber = (batteryLevelString: string | undefined): number => {
	if (!batteryLevelString) return -Infinity;
	if (batteryLevelString.includes('%')) {
		return Number(batteryLevelString.replace('%', ''));
	}
	return Number(batteryLevelString);
};

export const isAtLeastOneOfTagCertificatesExpired = (tag: ITag, tz: string): boolean => {
	const todayDate: Date = getTimezoneStartOfDate(tz);
	return (
		!!tag.certificates &&
		tag.certificates.some((certificate: ITagCertificate) => {
			return isCertificateExpired(certificate, tz, todayDate);
		})
	);
};

export const isCertificateExpired = (certificate: ITagCertificate, tz: string, todayDate?: Date): boolean => {
	todayDate = todayDate || getTimezoneStartOfDate(tz);
	const expirationDate: Date = getTimezoneStartOfDate(
		tz,
		getTimezoneFormattedDate(tz, new Date(certificate.expirationDate))
	);
	return expirationDate <= todayDate;
};

export const getCertificateSortOrder = (tag: ITag, tz: string): number => {
	if (tag.certificates?.length === 0) {
		return 0;
	}

	if (isAtLeastOneOfTagCertificatesExpired(tag, tz)) {
		return 1;
	}

	return 2;
};

export const getStatusSortOrder = (tag: ITag): number => {
	if (tag.tagStatus === TagStatuses.Good) return 0;
	if (tag.tagStatus === TagStatuses.Sleeping) return 1;
	if (tag.tagStatus === TagStatuses.Storage) return 2;
	return 3;
};
