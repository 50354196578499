import { TrusstorIcon } from '@src/Components/TrusstorIcon/TrusstorIcon';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import classes from './styles.module.scss';
import React, { useState } from 'react';
import { translationService } from '@src/servicesInitializers';
import classnames from 'classnames';
import { useOutsideClick } from '@src/hooks/custom.hooks';
import { IMenuItem, TrusstorMenu } from '@shared/components/TrusstorMenu/TrusstorMenu';
import { IconSize } from '@shared/components/TrusstorIconShared/TrusstorIconShared';

interface ISequenceItemMenuProps {
	openEditDialog: () => void;
	handleAddColumnToTheRight: () => void;
	deleteSequenceItem: () => void;
}

export const SequenceItemMenu = (props: ISequenceItemMenuProps) => {
	const [menuElement, setMenuElement] = useState<HTMLElement | null>(null);

	const [showDropdown, setShowDropdown] = useState<boolean>(false);

	const handleOnClick = (callback: () => void) => {
		setShowDropdown(false);
		callback();
	};

	const editMenuItem: IMenuItem = {
		title: translationService.get('edit'),
		iconComponent: <TrusstorIcon iconName={IconNames.edit} size={IconSize.SMALL} />,
		onClick: () => handleOnClick(props.openEditDialog),
	};

	const addColumnToTheRightMenuItem: IMenuItem = {
		title: translationService.get('addColumnToTheRight'),
		iconComponent: <TrusstorIcon iconName={IconNames.plus} size={IconSize.SMALL} />,
		onClick: () => handleOnClick(props.handleAddColumnToTheRight),
	};

	const deleteMenuItem: IMenuItem = {
		title: translationService.get('delete'),
		iconComponent: <TrusstorIcon iconName={IconNames.trash} size={IconSize.SMALL} />,
		onClick: () => handleOnClick(props.deleteSequenceItem),
		borderTop: true,
	};
	const isRtl: boolean = translationService.getIsRtl();

	useOutsideClick({ current: menuElement }, setShowDropdown, false);

	const getHorizontalIndentationPixels = () => {
		if (menuElement) {
			const matrixContainerEl = document.querySelector('#progressTrackerBodyId');
			if (matrixContainerEl) {
				const menuLeftPosition: number =
					menuElement.getBoundingClientRect().left - menuElement.offsetWidth / 2 - 100;
				const menuRightPosition: number = menuElement.getBoundingClientRect().right + 100;
				const matrixTableLeftPosition: number = matrixContainerEl.getBoundingClientRect().left;
				const matrixTableRightPosition: number = matrixContainerEl.getBoundingClientRect().right;
				if (
					menuLeftPosition < matrixTableLeftPosition ||
					(!isRtl ? menuRightPosition < 0 : menuLeftPosition < 0)
				) {
					return isRtl ? 0 : -180;
				}
				if (menuRightPosition > matrixTableRightPosition || menuRightPosition > window.innerWidth) {
					return isRtl ? -180 : 0;
				}
			}
		}
		return -180;
	};

	return (
		<>
			<div
				className={classnames(classes.menuContainer, { [classes.showDropdown]: showDropdown })}
				onClick={() => setShowDropdown((prev) => !prev)}
			>
				<TrusstorIcon iconName={IconNames.dotsHorizontal} size={IconSize.MEDIUM} defaultColor />
			</div>

			<div
				style={{
					...(isRtl
						? { left: `${getHorizontalIndentationPixels()}px` }
						: { right: `${getHorizontalIndentationPixels()}px` }),
				}}
				ref={setMenuElement}
				className={classes.dropdownContainer}
			>
				{showDropdown && (
					<TrusstorMenu width={170} items={[editMenuItem, addColumnToTheRightMenuItem, deleteMenuItem]} />
				)}
			</div>
		</>
	);
};
