import React, { KeyboardEvent } from 'react';

export const scrollIntoView = (dropdownRef: React.RefObject<HTMLDivElement>, highlightedIndex: number) => {
	if (dropdownRef.current && highlightedIndex !== -1) {
		const selectedItem = dropdownRef.current.childNodes[highlightedIndex];
		if (selectedItem) {
			(selectedItem as Element).scrollIntoView({
				behavior: 'smooth',
				block: 'nearest',
			});
		}
	}
};

export const scrollIntoGroupOption = (
	optionsContainerRef: React.RefObject<HTMLDivElement>,
	highlightedGroupIndex: number,
	highlightedIndex: number
) => {
	if (optionsContainerRef.current && highlightedGroupIndex !== -1 && highlightedIndex !== -1) {
		const selectedItem =
			optionsContainerRef.current.childNodes[highlightedGroupIndex].childNodes[highlightedIndex + 1];
		if (selectedItem) {
			(selectedItem as Element).scrollIntoView({
				behavior: 'smooth',
				block: 'nearest',
			});
		}
	}
};

export const handleDropdownKeyDown = (
	e: KeyboardEvent,
	callbacks: {
		arrowDownCallback?: () => void;
		arrowUpCallback?: () => void;
		enterCallback?: () => void;
	}
) => {
	switch (e.key) {
		case 'ArrowDown':
			callbacks.arrowDownCallback?.();
			break;
		case 'ArrowUp':
			callbacks.arrowUpCallback?.();
			break;
		case 'Enter':
			callbacks.enterCallback?.();
			break;
		default:
			break;
	}
};
