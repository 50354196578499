import React from 'react';
import Chart from 'react-apexcharts';
import ApexCharts from 'apexcharts';
import { makeStyles } from '@material-ui/core/styles';
import { translationService } from '../../../../../index';
import { useLargeScreen } from '../../../../../hooks/custom.hooks';
import { convertDateObjectToServerFormat } from '../../../../../utils/dateUtils';
import { ILineGraphData } from './interfaces/ILineGraphData';
import { PROFESSION_REPORT_PAGE } from '../../../../../constants';
import { ISeriesLineGraph } from '../../../../../interfaces/ISeriesLineGraph';
import {
	getApexTickAmountOption,
	getFirstDatePointToShow,
	getLastDatePointToShow,
} from '../../../../../utils/reports.utils';

const useStyles = makeStyles({
	chartContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100%',
		width: '100%',
	},
});

interface IWorkersQuantityPerDateGraphProps {
	dataArray: ILineGraphData[];
}

const WorkersQuantityPerDateGraph = (props: IWorkersQuantityPerDateGraphProps) => {
	const options: ApexCharts.ApexOptions = {
		chart: {
			toolbar: {
				show: true,
				tools: {
					download: false,
					selection: false,
					zoom: false,
					zoomin: false,
					zoomout: false,
					pan: true,
					reset: false,
					customIcons: [],
				},
			},
		},
		dataLabels: {
			enabled: false,
		},
		fill: {
			type: 'gradient',
			gradient: {
				type: 'vertical',
				shade: 'light',
				opacityFrom: 0,
				opacityTo: 0,
			},
		},
		markers: {
			size: 3,
		},
		stroke: {
			curve: 'straight',
			width: 2,
		},
		xaxis: {
			type: 'datetime',
			categories: props.dataArray.map((data) => data.label),
			tickAmount: getApexTickAmountOption(props.dataArray, PROFESSION_REPORT_PAGE.MAX_GRAPH_TICKS),
			tickPlacement: 'on',
			min: getFirstDatePointToShow(props.dataArray),
			max: getLastDatePointToShow(props.dataArray, PROFESSION_REPORT_PAGE.MAX_GRAPH_TICKS),
			axisTicks: {
				show: true,
				height: 10,
			},
			tooltip: {
				enabled: false,
			},
			labels: {
				style: {
					colors: [],
					fontSize: '10px',
					fontFamily: 'Heebo, sans-serif',
					fontWeight: 500,
					cssClass: 'apexcharts-xaxis-label',
				},
				formatter: (date: string) =>
					date ? convertDateObjectToServerFormat(new Date(date)).substring(0, 5) : '',
			},
		},
		yaxis: {
			labels: {
				offsetX: -9,
				style: {
					fontSize: '10px',
					fontFamily: 'Heebo, sans-serif',
					fontWeight: 500,
					cssClass: 'apexcharts-xaxis-label',
				},
			},
		},
		tooltip: {
			marker: {
				show: false,
			},
		},
	};

	const series: [ISeriesLineGraph] = [
		{
			type: 'area',
			name: translationService.get('numberOfWorkers'),
			data: props.dataArray.map((data) => data.data),
		},
	];
	const isLargeScreen = useLargeScreen();
	const classes = useStyles();

	return (
		<div className={classes.chartContainer}>
			<Chart options={options} series={series} type="line" width={isLargeScreen ? 520 : 320} height="90%" />
		</div>
	);
};

export { WorkersQuantityPerDateGraph };
