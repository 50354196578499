import React from 'react';
import { IMenuItem } from '@shared/components/TrusstorMenu/TrusstorMenu';
import { AreaSequenceItemStatus } from '@shared/interfaces/AreaSequenceItemStatus.enum';
import { COLORS } from '@src/constants';
import { accessLevelService, translationService } from '@src/servicesInitializers';
import { compact, flatMap, unionBy } from 'lodash';
import { TrusstorIcon } from '@src/Components/TrusstorIcon/TrusstorIcon';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { IconColor } from '@shared/components/TrusstorIconShared/TrusstorIconShared';

export enum ActivitySequenceItemMenuActions {
	status = 'status',
	createIssue = 'createIssue',
	viewIssues = 'viewIssues',
	viewActivity = 'viewActivity',
	doReview = 'doReview',
}

export const getDoneMenuItem = (
	onClickCallback?: (actionType: ActivitySequenceItemMenuActions, status: AreaSequenceItemStatus) => Promise<void>
): IMenuItem => ({
	key: AreaSequenceItemStatus.complete,
	title: translationService.get('markAsDone'),
	circleColor: COLORS.green400,
	onClick: async (event) => {
		event.stopPropagation();
		await onClickCallback?.(ActivitySequenceItemMenuActions.status, AreaSequenceItemStatus.complete);
	},
});

export const getInProgressMenuItem = (
	onClickCallback?: (actionType: ActivitySequenceItemMenuActions, status: AreaSequenceItemStatus) => Promise<void>
): IMenuItem => ({
	key: AreaSequenceItemStatus.inProgress,
	title: translationService.get('inProgress'),
	circleColor: COLORS.yellow500,
	onClick: async (event) => {
		event.stopPropagation();
		await onClickCallback?.(ActivitySequenceItemMenuActions.status, AreaSequenceItemStatus.inProgress);
	},
});

export const getReadyForReviewMenuItem = (
	onClickCallback?: (actionType: ActivitySequenceItemMenuActions, status: AreaSequenceItemStatus) => Promise<void>
): IMenuItem => ({
	key: AreaSequenceItemStatus.readyForReview,
	title: translationService.get('readyForReview'),
	circleColor: COLORS.feedbackHighDefault,
	onClick: async (event) => {
		event.stopPropagation();
		await onClickCallback?.(ActivitySequenceItemMenuActions.status, AreaSequenceItemStatus.readyForReview);
	},
});

export const getUpcomingMenuItem = (
	onClickCallback?: (actionType: ActivitySequenceItemMenuActions, status: AreaSequenceItemStatus) => Promise<void>
): IMenuItem => ({
	key: AreaSequenceItemStatus.planned,
	title: translationService.get('upcoming'),
	circleColor: COLORS.grey600,
	onClick: async (event) => {
		event.stopPropagation();
		await onClickCallback?.(ActivitySequenceItemMenuActions.status, AreaSequenceItemStatus.planned);
	},
});

export const getUnplannedMenuItem = (
	onClickCallback?: (actionType: ActivitySequenceItemMenuActions, status: AreaSequenceItemStatus) => Promise<void>
): IMenuItem => ({
	key: AreaSequenceItemStatus.unplanned,
	title: translationService.get('unplanned'),
	circleColor: COLORS.white,
	onClick: async (event) => {
		event.stopPropagation();
		onClickCallback?.(ActivitySequenceItemMenuActions.status, AreaSequenceItemStatus.unplanned);
	},
});

export const getOpenActivityDialogMenuItem = (
	onClickCallback?: (actionType: ActivitySequenceItemMenuActions) => Promise<void>
): IMenuItem => ({
	title: translationService.get('edit'),
	iconComponent: <TrusstorIcon iconName={IconNames.clipboard} color={IconColor.Grey400} />,
	borderBottom: true,
	borderTop: true,
	onClick: (event) => {
		event.stopPropagation();
		onClickCallback?.(ActivitySequenceItemMenuActions.viewActivity);
	},
});

export const getCreateNewIssueMenuItem = (
	onClickCallback?: (actionType: ActivitySequenceItemMenuActions) => Promise<void>
): IMenuItem => ({
	title: translationService.get('createIssue'),
	iconComponent: <TrusstorIcon iconName={IconNames.plus} color={IconColor.Grey400} />,
	onClick: (event) => {
		event.stopPropagation();
		onClickCallback?.(ActivitySequenceItemMenuActions.createIssue);
	},
});

export const getViewIssuesMenuItem = (
	onClickCallback?: (actionType: ActivitySequenceItemMenuActions) => Promise<void>,
	styleOptions: { borderTop?: boolean; borderBottom?: boolean } = {}
): IMenuItem => ({
	title: translationService.get('viewIssues'),
	iconComponent: <TrusstorIcon iconName={IconNames.issues} color={IconColor.Grey400} />,
	borderBottom: true,
	...styleOptions,
	onClick: (event) => {
		event.stopPropagation();
		onClickCallback?.(ActivitySequenceItemMenuActions.viewIssues);
	},
});

export const getDoReviewMenuItem = (
	onClickCallback?: (actionType: ActivitySequenceItemMenuActions) => Promise<void>,
	styleOptions: { borderTop?: boolean; borderBottom?: boolean } = {}
): IMenuItem => ({
	title: translationService.get('sequenceMenu_doReview'),
	iconComponent: <TrusstorIcon iconName={IconNames.workplan} color={IconColor.Grey400} />,
	borderBottom: true,
	...styleOptions,
	onClick: (event) => {
		event.stopPropagation();
		onClickCallback?.(ActivitySequenceItemMenuActions.doReview);
	},
});

export const getCellMenuItemsByStatus = (
	status: AreaSequenceItemStatus,
	showActionButtons?: boolean,
	onClickCallback?: (actionType: ActivitySequenceItemMenuActions, status?: AreaSequenceItemStatus) => Promise<void>,
	hasIssues?: boolean
): IMenuItem[] => {
	const canUserCompleteASI: boolean = accessLevelService.hasAccess('workplanActivityActions');
	const upcomingMenuItem: IMenuItem = getUpcomingMenuItem(onClickCallback);
	const inProgressMenuItem: IMenuItem = getInProgressMenuItem(onClickCallback);
	const readyForReviewMenuItem: IMenuItem = getReadyForReviewMenuItem(onClickCallback);
	const doneMenuItem: IMenuItem = getDoneMenuItem(onClickCallback);
	const unplannedMenuItem: IMenuItem = getUnplannedMenuItem(onClickCallback);
	const openActivityDialogMenuItem: IMenuItem = getOpenActivityDialogMenuItem(onClickCallback);
	const createNewIssueMenuItem: IMenuItem = getCreateNewIssueMenuItem(onClickCallback);
	const viewIssuesMenuItem: IMenuItem = getViewIssuesMenuItem(onClickCallback);
	const doReviewMenuItem: IMenuItem = getDoReviewMenuItem(onClickCallback);

	if (!canUserCompleteASI) {
		const actionItemsToShow: IMenuItem[] = [
			...(showActionButtons ? [...(hasIssues ? [viewIssuesMenuItem] : [])] : []),
		];
		if (status === AreaSequenceItemStatus.unplanned || status === AreaSequenceItemStatus.planned) {
			return [inProgressMenuItem, readyForReviewMenuItem, ...actionItemsToShow];
		}
		if (status === AreaSequenceItemStatus.readyForReview) {
			return [upcomingMenuItem, ...actionItemsToShow];
		}
		return [readyForReviewMenuItem, ...actionItemsToShow];
	}

	switch (status) {
		case undefined:
			return [upcomingMenuItem, inProgressMenuItem, doneMenuItem];
		case AreaSequenceItemStatus.unplanned:
			return [upcomingMenuItem, inProgressMenuItem, doneMenuItem];
		case AreaSequenceItemStatus.planned:
			return [
				unplannedMenuItem,
				inProgressMenuItem,
				doneMenuItem,
				...(showActionButtons
					? [openActivityDialogMenuItem, ...(hasIssues ? [viewIssuesMenuItem] : []), createNewIssueMenuItem]
					: []),
			];
		case AreaSequenceItemStatus.delayed:
			return [
				unplannedMenuItem,
				inProgressMenuItem,
				doneMenuItem,
				...(showActionButtons
					? [openActivityDialogMenuItem, ...(hasIssues ? [viewIssuesMenuItem] : []), createNewIssueMenuItem]
					: []),
			];
		case AreaSequenceItemStatus.inProgress:
			return [
				unplannedMenuItem,
				doneMenuItem,
				...(showActionButtons
					? [openActivityDialogMenuItem, ...(hasIssues ? [viewIssuesMenuItem] : []), createNewIssueMenuItem]
					: []),
			];
		case AreaSequenceItemStatus.delayedOverdue:
			return [
				unplannedMenuItem,
				doneMenuItem,
				...(showActionButtons
					? [openActivityDialogMenuItem, ...(hasIssues ? [viewIssuesMenuItem] : []), createNewIssueMenuItem]
					: []),
			];
		case AreaSequenceItemStatus.overdue:
			return [
				unplannedMenuItem,
				doneMenuItem,
				...(showActionButtons
					? [openActivityDialogMenuItem, ...(hasIssues ? [viewIssuesMenuItem] : []), createNewIssueMenuItem]
					: []),
			];
		case AreaSequenceItemStatus.complete:
			return [unplannedMenuItem, upcomingMenuItem, inProgressMenuItem];
		case AreaSequenceItemStatus.readyForReview:
			return [
				unplannedMenuItem,
				inProgressMenuItem,
				doneMenuItem,
				...(showActionButtons
					? [
							doReviewMenuItem,
							openActivityDialogMenuItem,
							...(hasIssues ? [viewIssuesMenuItem] : []),
							createNewIssueMenuItem,
						]
					: []),
			];
		default:
			return [];
	}
};

export const getMultiSelectedCellsMenuItems = (
	statuses: AreaSequenceItemStatus[],
	onClickCallback: (actionType: ActivitySequenceItemMenuActions, status?: AreaSequenceItemStatus) => Promise<void>
): IMenuItem[] => {
	if (!statuses.length) {
		return [];
	}
	if (statuses.length === 1) {
		return getCellMenuItemsByStatus(statuses[0], false, onClickCallback);
	}
	const statusesMenuItems: IMenuItem[] = flatMap(statuses, (status: AreaSequenceItemStatus) =>
		getCellMenuItemsByStatus(status, false, onClickCallback)
	);
	const unionMenuItems: IMenuItem[] = unionBy(statusesMenuItems, (menuItem) => menuItem.title);
	const orderedMenuItems: AreaSequenceItemStatus[] = [
		AreaSequenceItemStatus.unplanned,
		AreaSequenceItemStatus.planned,
		AreaSequenceItemStatus.inProgress,
		AreaSequenceItemStatus.readyForReview,
		AreaSequenceItemStatus.complete,
	];
	return compact(orderedMenuItems.map((status) => unionMenuItems.find((menuItem) => menuItem.key === status)));
};
