import React from 'react';
import { HeaderBox } from '@src/Components/Boxes/Containers/HeaderBox/HeaderBox';
import { translationService } from '@src/index';
import { EquipmentCard } from '@src/Components/Boxes/Cards/EquipmentCard/EquipmentCard';
import { IMergedEquipment } from '@shared/interfaces/Equipment/IMergedEquipment';
import classes from './styles.module.scss';
import { Loader } from '@shared/components/Loader/Loader';

interface ILocationMenuEquipmentProps {
	equipments?: IMergedEquipment[];
}

const LocationMenuEquipment = (props: ILocationMenuEquipmentProps) => {
	const getEquipmentContent = () => {
		if (!props.equipments) {
			return <Loader />;
		}

		return props.equipments?.length ? (
			props.equipments.map((equipment, index) => {
				return <EquipmentCard key={index} equipment={equipment} />;
			})
		) : (
			<div className={classes.noData}>{translationService.get('noEquipmentFoundInFloor')}</div>
		);
	};

	return (
		<HeaderBox boldTitle title={translationService.get('equipment')}>
			{getEquipmentContent()}
		</HeaderBox>
	);
};

export { LocationMenuEquipment };
