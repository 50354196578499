import React, { useMemo } from 'react';
import { GenericHeaderColumns, IHeaderColumnCell } from '../common/GenericHeaderColumns/GenericHeaderColumns';
import { getTimezoneDateRanges, getTimezoneFormattedDate } from '../../../../../../utils/dateUtils';
import { translationService } from '../../../../../../index';
import { ActivityDistributionColumn } from './ActivityDistributionColumn';
import { IRootState } from '../../../../../../store/slices';
import { useSelector } from 'react-redux';
import { IRoundedProfessionsAnalysisReportData } from '../../../../../../interfaces/IRoundedProfessionsAnalysisReport';
import classes from './styles.module.scss';

interface IActivityDistributionTableProps {
	professionReport: IRoundedProfessionsAnalysisReportData;
	dateFrom: Date;
	dateTo: Date;
}

const ActivityDistributionTable = (props: IActivityDistributionTableProps) => {
	const tz: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.tz);

	const reportDates: string[] = useMemo(() => {
		return getTimezoneDateRanges(props.dateFrom, props.dateTo, tz).map((date: Date) =>
			getTimezoneFormattedDate(tz, date)
		);
	}, [props.dateFrom.getTime(), props.dateTo.getTime(), tz]);

	const ROW_HEADERS: IHeaderColumnCell[] = [
		{
			title: translationService.get('hoursSpentInActivities').toUpperCase(),
			cellStyleClass: classes.activityPercentage,
		},
		{
			title: translationService.get('totalSpentHours').toUpperCase(),
			cellStyleClass: classes.activityPercentage,
		},
	];

	return (
		<div className={classes.tableContainerWrapper}>
			<div className={classes.tableContainer}>
				<GenericHeaderColumns
					emptyCellStyleClass={classes.emptyHeaderCell}
					headerColumnCells={ROW_HEADERS}
					width={'auto'}
				/>
				<div className={classes.rowsContainer}>
					<div className={classes.rowContainer}>
						{reportDates.map((formattedDate: string) => (
							<ActivityDistributionColumn
								key={`${formattedDate}_activityDistributionTableTotalInActivities`}
								dateProfessionAnalysisData={
									props.professionReport.dates && props.professionReport.dates[formattedDate]
								}
								formattedDate={formattedDate}
								analysisDataProperty={'totalHoursInActivities'}
							/>
						))}
					</div>

					<div className={classes.rowContainer}>
						{reportDates.map((formattedDate: string) => (
							<ActivityDistributionColumn
								hideTitle
								key={`${formattedDate}_activityDistributionTableTotalWorkers`}
								dateProfessionAnalysisData={
									props.professionReport.dates && props.professionReport.dates[formattedDate]
								}
								formattedDate={formattedDate}
								analysisDataProperty={'totalWorkHours'}
							/>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export { ActivityDistributionTable };
