import React from 'react';
import { RecentInvestigationReportCard } from '../RecentReportCard/RecentInvestigationReportCard/RecentInvestigationReportCard';
import classes from './styles.module.scss';
import { translationService } from '../../../../index';
import { Loader } from '@shared/components/Loader/Loader';
import { IInvestigationReport } from '@shared/interfaces/IInvestigationReport';
import { InvestigationReportTypes } from '@shared/interfaces/InvestigationReportTypes.enum';
import { RecentWorkersTimesheetReportCard } from '../RecentReportCard/RecentWorkersTimesheetReportCard/RecentWorkersTimesheetReportCard';
import { IWorkersTimesheetReport } from '@shared/interfaces/IWorkersTimesheetReport';
import { RecentLocationReportCard } from '../RecentReportCard/RecentLocationReportCard/RecentLocationReportCard';
import { IObserveLocationReport } from '@shared/interfaces/IObserveLocationReport';
import { RecentProjectOverviewReportCard } from '@src/Components/Pages/AnalysisCenterPages/RecentReportCard/RecentProjectOverviewReportCard/RecentProjectOverviewReportCard';
import { IProjectOverviewReport } from '@shared/interfaces/IProjectOverviewReport';
import { RecentContractorAnalysisReportCard } from '@src/Components/Pages/AnalysisCenterPages/RecentReportCard/RecentContractorAnalysisReportCard/RecentContractorAnalysisReportCard';
import { IContractorAnalysisReport } from '@shared/interfaces/IContractorAnalysisReport';

interface IRecentReportsSectionProps {
	investigationReports: (
		| IInvestigationReport
		| IWorkersTimesheetReport
		| IObserveLocationReport
		| IProjectOverviewReport
		| IContractorAnalysisReport
	)[];
	isLoading: boolean;
}

const RecentReportsSection = (props: IRecentReportsSectionProps) => {
	return (
		<div className={classes.section}>
			{props.isLoading ? (
				<Loader />
			) : (
				props.investigationReports.length !== 0 && (
					<>
						<div className={classes.title}>{translationService.get('recentAnalysisTitle')}</div>
						<div className={classes.recentReportSectionWrapper}>
							<div className={classes.divider} />
							{props.investigationReports.map((investigationReport) => {
								if (
									investigationReport.investigationType === InvestigationReportTypes.workersTimesheet
								) {
									return (
										<RecentWorkersTimesheetReportCard
											key={investigationReport._id}
											workerTimesheetReport={investigationReport as IWorkersTimesheetReport}
										/>
									);
								}
								if (
									investigationReport.investigationType === InvestigationReportTypes.observeLocation
								) {
									return (
										<RecentLocationReportCard
											key={investigationReport._id}
											investigationReport={investigationReport as IObserveLocationReport}
										/>
									);
								}
								if (
									investigationReport.investigationType === InvestigationReportTypes.projectOverview
								) {
									return (
										<RecentProjectOverviewReportCard
											key={investigationReport._id}
											projectOverviewReport={investigationReport as IProjectOverviewReport}
										/>
									);
								}
								if (
									investigationReport.investigationType ===
									InvestigationReportTypes.contractorAnalysis
								) {
									return (
										<RecentContractorAnalysisReportCard
											key={investigationReport._id}
											contractorAnalysisReport={investigationReport as IContractorAnalysisReport}
										/>
									);
								}
								return (
									<RecentInvestigationReportCard
										key={investigationReport._id}
										investigationReport={investigationReport as IInvestigationReport}
									/>
								);
							})}
						</div>
					</>
				)
			)}
		</div>
	);
};

export { RecentReportsSection };
