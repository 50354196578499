import React, { useRef, useState } from 'react';
import classnames from 'classnames';
import { useOutsideClick } from '../../../../hooks/custom.hooks';
import { useHistory } from 'react-router-dom';
import classes from './styles.module.scss';
import { IUser } from '@shared/interfaces/IUser';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoggedUserDetails } from '@store/slices/login.slice';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { TrusstorIcon } from '@src/Components/TrusstorIcon/TrusstorIcon';
import { RoleType } from '@shared/interfaces/RoleType.enum';
import { translationService } from '../../../../index';
import { logout, updateUserLanguage } from '@store/thunks';
import { IMenuItem, TrusstorMenu } from '@shared/components/TrusstorMenu/TrusstorMenu';
import { ILanguageAndDisplayName, Languages } from '@shared/constants/languages';
import { ArrowUpDown } from '@shared/components/ArrowUpDown/ArrowUpDown';
import { COLORS } from '@src/constants';

interface IUserSettingMenuProps {
	setDisplayUserMenu: (display: boolean) => void;
}

const UserSettingMenu = (props: IUserSettingMenuProps) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const userSettingMenuRef = useRef(null);
	const [showLanguagesMenu, setShowLanguagesMenu] = useState<boolean>(false);
	const loggedUserDetails: IUser = useSelector(selectLoggedUserDetails)!;
	const selectedLanguage: string = translationService.getChosenLanguage();
	const languages: ILanguageAndDisplayName[] = translationService.getLanguagesAndDisplayNames();
	const selectedLanguageDisplayName: string = languages.find(
		(language) => language.language === selectedLanguage
	)!.displayName;

	useOutsideClick(userSettingMenuRef, props.setDisplayUserMenu, false);

	const navigateToPreferences = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
		event.stopPropagation();
		props.setDisplayUserMenu(false);
		history.push('/preferences');
	};

	const handleLogout = (): void => {
		history.push(`/login/${translationService.getChosenLanguage()}`);
		props.setDisplayUserMenu(false);
		dispatch(logout());
	};

	const isAdmin: boolean =
		loggedUserDetails.permissions.roleType === RoleType.PROJECT_ADMIN ||
		loggedUserDetails.permissions.roleType === RoleType.TRUSSTOR_ADMIN;

	const isRtl: boolean = translationService.getIsRtl();

	const onLanguageChange = (language: Languages): void => {
		if (language === selectedLanguage) {
			return;
		}
		updateUserLanguage(loggedUserDetails.username, language)(dispatch);
	};

	const languagesMenu: IMenuItem[] = languages.map((language) => ({
		title: language.displayName,
		onClick: () => onLanguageChange(language.language),
		backgroundColor: language.language === selectedLanguage ? COLORS.blue100 : '',
	}));

	return (
		<div ref={userSettingMenuRef} className={`${classes.menuContainer} ${isRtl ? classes.rtl : ''}`}>
			<section className={classes.userDetails}>
				<TrusstorIcon iconName={IconNames.user} className={classes.profileIcon} />
				<div className={classes.userTexts}>
					<div className={classes.userRoleName}>
						<div className={classes.name}>{loggedUserDetails.name}</div>
						{isAdmin && <div className={classes.admin}>{translationService.get('admin')}</div>}
					</div>
					<div className={classes.email}>{loggedUserDetails.email}</div>
				</div>
			</section>
			<section className={classes.menuItem} onClick={navigateToPreferences}>
				<TrusstorIcon iconName={IconNames.bell} className={classes.icon} />
				<div>{translationService.get('alerts')}</div>
			</section>
			<section className={classes.menuItem}>
				<TrusstorIcon iconName={IconNames.globe} className={classes.icon} />
				<div className={classes.languagesMenuItem}>
					<div>{translationService.get('languages')}</div>
					<div
						onClick={() => {
							setShowLanguagesMenu((curr) => !curr);
						}}
						className={classes.selectLanguageContainer}
					>
						<p className={classes.selectedLanguage}>{selectedLanguageDisplayName}</p>
						<ArrowUpDown isOpen={showLanguagesMenu} />
					</div>
					{showLanguagesMenu && (
						<div className={classnames(classes.languagesDropdown, { [classes.rtl]: isRtl })}>
							<TrusstorMenu items={languagesMenu} />
						</div>
					)}
				</div>
			</section>
			<section className={classes.menuItem} onClick={handleLogout}>
				<TrusstorIcon iconName={IconNames.logOut} className={classes.icon} />
				<div>{translationService.get('logout')}</div>
			</section>
		</div>
	);
};

export { UserSettingMenu };
