import { exportService, requestService, translationService } from '@src/servicesInitializers';
import { getTimezoneDateRanges, getTimezoneFormattedDate } from '@utils/dateUtils';
import moment from 'moment';
import { IRow, ISheets, ISheetsInfoValues } from '@src/services';
import { getDateDisplayFormat } from '@shared/utils/dateUtils';
import { IScissorLiftDateReport, IScissorLiftReport } from '@shared/interfaces/IScissorLiftReport';
import { IUtilityStaticTag } from '@interfaces/IUtilityStaticTag';
import { IScissorsLiftUtility } from '@interfaces/IScissorsLiftUtility';
import { getStaticScissorLiftTags } from '@utils/api.utils';

const FIRST_REPORT_HOUR: number = 7;
const LAST_REPORT_HOUR: number = 19;
export const exportScissorLiftReportToExcel = async (
	projectId: string,
	projectName: string,
	tz: string,
	startDate: Date,
	endDate: Date,
	countryShortName?: string
) => {
	const staticScissorLiftTags: IUtilityStaticTag[] = await getStaticScissorLiftTags(projectId);
	const formattedStartDate: string = getTimezoneFormattedDate(tz, startDate);
	const formattedEndDate: string = getTimezoneFormattedDate(tz, endDate);
	const scissorLiftReports: IScissorLiftReport = await requestService.get(
		`/archive/scissorLiftReports?projectId=${projectId}&startDate=${formattedStartDate}&endDate=${formattedEndDate}&tz=${tz}`
	);

	const dateRange: Date[] = getTimezoneDateRanges(startDate, endDate, tz);
	const sheets: ISheets = {};
	const sheetsInfoValues: ISheetsInfoValues = {};

	dateRange.forEach((date) => {
		const formattedDate: string = getTimezoneFormattedDate(tz, date);
		const reportForDate: IScissorLiftDateReport | undefined = scissorLiftReports[formattedDate];
		const sheetName: string = moment(date)
			.tz(tz)
			.locale(countryShortName || 'us')
			.format('MMM Do, YYYY');

		sheets[sheetName] = [];
		sheetsInfoValues[sheetName] = {
			[translationService.get('project')]: { rowNumber: 4, columnChar: 'A', value: projectName },
			[translationService.get('date')]: {
				rowNumber: 4,
				columnChar: 'AA',
				value: sheetName,
			},
		};

		staticScissorLiftTags.forEach((tag, index) => {
			const row: IRow = {};
			const linkedUtility: IScissorsLiftUtility = tag.linkedUtility as IScissorsLiftUtility;
			row[translationService.get('scissorLift_excel_index')] = {
				value: index + 1,
			};
			row[translationService.get('scissorLift_excel_specialty')] = {
				value: linkedUtility.profession?.specialty || '',
			};
			row[translationService.get('scissorLift_excel_tagName')] = {
				value: linkedUtility.name || '',
			};
			row[translationService.get('scissorLift_excel_tagNick')] = { value: tag.tagNick || '' };

			const hoursWorked: number[] =
				reportForDate && reportForDate.tags[tag.tagId] ? reportForDate.tags[tag.tagId].hoursWorked : [];

			for (let hour: number = FIRST_REPORT_HOUR; hour < LAST_REPORT_HOUR; hour++) {
				const hourColumn: string = getHourColumn(hour);
				const isActive: boolean = hoursWorked.includes(hour);
				const valueTranslated: string = isActive
					? translationService.get('scissorLiftReports_Excel_TagWorked')
					: translationService.get('scissorLiftReports_Excel_TagNotWorked');
				row[hourColumn] = { value: valueTranslated, color: isActive ? 'B6D7A8' : undefined };
			}

			sheets[sheetName].push(row);
		});
	});
	const HEADLINE: string = translationService.get('scissorLiftReports_Excel_Title');

	const FILE_NAME: string = `${HEADLINE} - ${projectName} - ${getDateDisplayFormat(startDate, tz, translationService, 'full')} - ${getDateDisplayFormat(endDate, tz, translationService, 'full')}`;
	await exportService.exportMultipleSheetsToExcel(
		sheets,
		FILE_NAME,
		HEADLINE,
		undefined,
		{
			cellColor: 'FFFFFF',
			headerColor: '103045',
			columnWidth: 20,
			headerRow: 6,
		},
		undefined,
		sheetsInfoValues
	);
};

const formatHour = (hour: number): string => {
	const period: string = hour < 12 ? 'AM' : 'PM';
	const formattedHour: number = hour % 12 === 0 ? 12 : hour % 12;
	return `${formattedHour}${period}`;
};

const getHourColumn = (hour: number): string => {
	const isRTL: boolean = translationService.getIsRtl();
	const startHour: string = formatHour(hour);
	const endHour: string = formatHour((hour + 1) % 24);
	const formattedTime: string = `${startHour} - ${endHour}`;

	if (isRTL) {
		return formattedTime.split(' - ').reverse().join(' - ');
	}

	return formattedTime;
};
