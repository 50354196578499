import React, { useEffect, useState } from 'react';
import { GeneralDialog } from '@src/Components/GeneralDialog/GeneralDialog';
import { requestService, translationService } from '@src/servicesInitializers';
import { LocationSelector } from '@src/Components/Dropdowns/Location/LocationSelector/LocationSelector';
import { IBaseFloor } from '@shared/interfaces/Floors/IBaseFloor';
import { IConfigArea } from '@shared/interfaces/IConfigArea';
import { IProfession } from '@shared/interfaces/IProfession';
import classes from '../style.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '@store/slices';
import { NotificationTypes } from '@shared/interfaces/NotificationTypes.enum';
import { selectProjectId } from '@store/slices/project.slice';
import { errorSnackbar, successSnackbar } from '@utils/snackbar.utils';
import { createRestrictedAreaTrigger_BI } from '@utils/bi.utils';
import { IRestrictedAreaTrigger } from '@interfaces/IRestrictedAreaTrigger';
import { MultiProfessionGroupedDropdown } from '@src/Components/Dropdowns/Profession/MultiProfessionGroupedDropdown/MultiProfessionGroupedDropdown';
import { getAllProfessions } from '@store/thunks';

interface IRestrictedAreaCreateDialogProps {
	handleClose: () => void;
}

export const RestrictedAreaCreateDialog = (props: IRestrictedAreaCreateDialogProps) => {
	const activeProfessions: IProfession[] = useSelector(
		(state: IRootState) => state.professionsReducer.professions
	).filter((profession) => !profession.dateDeleted);
	const projectId: string = useSelector(selectProjectId)!;
	const [areas, setAreas] = useState<IConfigArea[]>([]);
	const [floors, setFloors] = useState<IBaseFloor[]>([]);
	const [professions, setProfessions] = useState<IProfession[]>([]);

	const dispatch = useDispatch();

	const onLocationChange = (floors: IBaseFloor[], areas: IConfigArea[]) => {
		setAreas(areas);
		setFloors(floors);
	};

	useEffect(() => {
		dispatch(getAllProfessions());
	}, []);

	useEffect(() => {
		setProfessions(activeProfessions);
	}, [activeProfessions.length]);

	const handleCreate = async () => {
		const restrictedAreaTrigger: IRestrictedAreaTrigger = {
			projectId,
			floors,
			areas,
			profession: {
				professions,
				isAllSelected: professions.length === activeProfessions.length,
			},
			type: NotificationTypes.RestrictedArea,
		};
		try {
			await requestService.post(`/notification/customizedTriggers?projectId=${projectId}`, {
				body: restrictedAreaTrigger,
			});
			successSnackbar(dispatch, translationService.get('restrictedAreaCreatedSuccessfully'));
			createRestrictedAreaTrigger_BI(restrictedAreaTrigger);
			props.handleClose();
		} catch (e) {
			errorSnackbar(dispatch, translationService.get('restrictedAreaCreatedError'));
		}
	};

	return (
		<GeneralDialog
			title={translationService.get('RestrictedArea')}
			show={true}
			close={props.handleClose}
			children={
				<>
					<LocationSelector onChange={onLocationChange} selectedFloors={floors} selectedAreas={areas} />
					<div className={classes.professionSelectSection}>
						<div className={classes.restrictedText}>{translationService.get('restrictedFor')}</div>
						{activeProfessions.length > 0 && (
							<MultiProfessionGroupedDropdown professions={activeProfessions} onChange={setProfessions} />
						)}
					</div>
				</>
			}
			mainButton={{
				click: handleCreate,
				disabled: (areas.length === 0 && floors.length === 0) || professions.length === 0,
				text: translationService.get('create'),
			}}
			secondaryButton={{
				text: translationService.get('cancel'),
			}}
		/>
	);
};
