import React, { forwardRef, useRef, useState } from 'react';
import { IDateMenuItem, PeriodSelector } from '@src/Components/DateSelector/PeriodSelector/PeriodSelector';
import { translationService } from '@src/servicesInitializers';
import { useSelector } from 'react-redux';
import { selectTimezone } from '@store/slices/project.slice';
import { IDatePickerInputProps } from '@interfaces/IDatePickerInput';
import { TrusstorDatePickerInput } from '@shared/components/TrusstorSelectOption/TrusstorDatePickerInput';
import { useOutsideClick } from '@src/hooks/custom.hooks';
import classes from './styles.module.scss';
import classnames from 'classnames';
import moment from 'moment/moment';
import { getDateRangeString } from '@shared/utils/dateUtils';
import { PopperPlacement } from '@src/Components/DateSelector/DateAndTimeSelector/DateAndTimeSelector';

interface IPeriodSelectorTrusstorInputProps {
	updatePeriod: (period: [Date, Date]) => void;
	datePeriod?: [Date, Date];
	minDate?: Date;
	maxDate?: Date;
	itemsAbove?: IDateMenuItem[];
	showSingleMonth?: boolean;
	hideIcon?: boolean;
	disableDatePicker?: boolean;
	isBigInputBox?: boolean;
	required?: boolean;
	inputPlaceHolder?: string;
	label?: string;
	rootClassName?: string;
	inputTextFormat?: boolean;
	calendarPosition?: PopperPlacement;
	isSmall?: boolean;
	maximumDaysForPeriod?: number;
}

export const PeriodSelectorTrusstorInput = (props: IPeriodSelectorTrusstorInputProps) => {
	const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);
	const tz: string = useSelector(selectTimezone)!;

	const dateInputRef = useRef(null);

	const DatePickerInput = forwardRef(({ onClick }: IDatePickerInputProps, ref: any) => {
		const getDateInputRangeText = (startDate?: Date, endDate?: Date): string | undefined => {
			if (!startDate && !endDate) {
				return undefined;
			}
			const startDateFormat: string = moment
				.tz(startDate, tz)
				.locale(translationService.getDateLocale())
				.format(translationService.getDateFormat());
			const endDateFormat: string = moment.tz(endDate, tz).isValid()
				? moment
						.tz(endDate, tz)
						.locale(translationService.getDateLocale())
						.format(translationService.getDateFormat())
				: '';
			const dateRangeFormat: string = `${startDateFormat} - ${endDateFormat}`;
			return startDateFormat === endDateFormat || !endDate ? startDateFormat : dateRangeFormat;
		};

		const onInputClick = () => {
			setIsCalendarOpen(true);
			onClick?.();
		};

		return (
			<TrusstorDatePickerInput
				isSmall={props.isSmall}
				hideIcon={props.hideIcon}
				onClick={onInputClick}
				isOpen={isCalendarOpen}
				text={
					props.inputTextFormat || props.isSmall
						? getDateRangeString(
								props.datePeriod?.[0],
								props.datePeriod?.[1],
								tz,
								translationService.getDateLocale()
							)
						: getDateInputRangeText(props.datePeriod?.[0], props.datePeriod?.[1])
				}
				placeholder={props.inputPlaceHolder ?? translationService.get('enterDate')}
				label={props.label}
				ref={ref}
			/>
		);
	});

	const updatePeriod = (period: [Date, Date]) => {
		props.updatePeriod(period);
		setIsCalendarOpen(false);
	};

	useOutsideClick(dateInputRef, setIsCalendarOpen, false);

	return (
		<div className={classnames(props.rootClassName)} ref={dateInputRef}>
			<PeriodSelector
				itemsAbove={props.itemsAbove}
				updatePeriod={updatePeriod}
				monthsShown={props.showSingleMonth ? 1 : 2}
				maxDate={props.maxDate}
				minDate={props.minDate}
				defaultStartDate={props.datePeriod?.[0]}
				defaultEndDate={props.datePeriod?.[1]}
				isPossibleToSelectSingleDay
				placeHolderText={translationService.get('enterDate')}
				textInputClassName={`${classes.dateRangeInput} ${
					!props.datePeriod ? classes.emptyDateInput : classes.dateInputValue
				}`}
				datePickerClassName={props.showSingleMonth ? '' : classes.largeDatePicker}
				containerDivAdditionalClassName={classes.fullLengthContainerDiv}
				isDefaultCalendarStyle
				setDefaultDates={true}
				customInput={<DatePickerInput />}
				calendarPosition={props.calendarPosition}
				maximumDaysForPeriod={props.maximumDaysForPeriod}
			/>
		</div>
	);
};
