import React from 'react';
import classes from './styles.module.scss';
import classnames from 'classnames';
import { useOutsideClick } from '../../hooks/custom.hooks';
import { useSharedServices } from '../../hooks/sharedServices.context';
import { SequenceTypeEnum } from '../../interfaces/SequenceType.enum';
import { IconNames } from '../TrusstorIconShared/IconNames.enum';
import { ArrowUpDown } from '../ArrowUpDown/ArrowUpDown';
import { useSequenceTypesQuery } from '../../hooks/queries/sequenceItems.queries';
import { TrusstorIconShared } from '../TrusstorIconShared/TrusstorIconShared';

interface ISequenceTypeDropdownProps {
	currentSequenceType?: SequenceTypeEnum;
	onSelect?: (sequenceType: SequenceTypeEnum) => void;
	readOnly?: boolean;
	onClickSideEffect?: () => void;
	projectId?: string;
	rootClass?: string;
}

export const SequenceTypeDropdown = (props: ISequenceTypeDropdownProps) => {
	const { translationService } = useSharedServices();
	const { sequenceTypes } = useSequenceTypesQuery(props.projectId);
	const [isDropdownOpen, setIsDropdownOpen] = React.useState<boolean>(false);
	const ref = React.useRef<HTMLDivElement>(null);
	useOutsideClick(ref, setIsDropdownOpen, false);

	const handleClick = (sequenceType: SequenceTypeEnum) => {
		props.onSelect?.(sequenceType);
		setIsDropdownOpen(false);
	};

	const onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		if (props.readOnly) return;
		e.stopPropagation();
		setIsDropdownOpen((prev) => !prev);
		props.onClickSideEffect?.();
	};

	const currentSequenceTypeTextKey: string = props.currentSequenceType || sequenceTypes?.[0];

	return (
		<div
			ref={ref}
			className={classnames(classes.container, { [classes.hoverState]: !props.readOnly })}
			data-testid="SequenceTypeDropdown"
			onClick={onClick}
		>
			<TrusstorIconShared iconName={IconNames.pin} />
			{currentSequenceTypeTextKey && (
				<p className={classes.currentSequenceTypeText}>{translationService.get(currentSequenceTypeTextKey)}</p>
			)}
			{!props.readOnly && <ArrowUpDown isOpen={isDropdownOpen} />}
			{isDropdownOpen && (
				<div className={classes.dropdownItems}>
					<div className={classnames(classes.item, classes.dropdownDescription)}>
						{translationService.get('chooseSequenceType')}
					</div>
					{sequenceTypes.map((sequenceType: SequenceTypeEnum) => {
						return (
							<div
								className={classnames(classes.item, {
									[classes.selected]: sequenceType === props.currentSequenceType,
								})}
								onClick={() => handleClick(sequenceType)}
								key={sequenceType}
							>
								<TrusstorIconShared iconName={IconNames.pin} />
								<div className={classnames(classes.text)}>{translationService.get(sequenceType)}</div>
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
};
