import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IManager, ITradeFilter } from '../../interfaces';
import { filterArrayByAnotherArray } from '../../utils/array.util';
import { entitiesProperties, HttpStatusCode } from '../../constants';
import { compact, uniqBy } from 'lodash';
import { createManager, getStaticManagers, updateManager } from '../thunks/managers.thunks';
import { IManagersReducerState } from '../../interfaces/IManagersReducerState';
import { HTTPRequestStatuses } from '@shared/interfaces/HTTPRequestStatuses.enum';

export interface IActiveManager extends IManager {
	disconnected?: boolean;
}

const initialState: IManagersReducerState = {
	activeManagers: [],
	staticManagers: [],
	selectedManagers: [],
	filteredManagers: [],
	filterList: [],
	visibleManagers: [],
	managersRequestStatus: HTTPRequestStatuses.idle,
	createManagerStatus: {
		status: HTTPRequestStatuses.idle,
		code: undefined,
	},
	updateManagerStatus: {
		status: HTTPRequestStatuses.idle,
		code: undefined,
	},
};

const managersSlice = createSlice({
	name: 'managers',
	initialState,
	reducers: {
		resetCreateManagerStatus: (state: IManagersReducerState) => {
			state.createManagerStatus = initialState.createManagerStatus;
		},
		resetUpdateManagerStatus: (state: IManagersReducerState) => {
			state.updateManagerStatus = initialState.createManagerStatus;
		},
		removeManager: (state: IManagersReducerState, action) => {
			state.activeManagers = removeManagerFromList(state.activeManagers, action.payload.deletedManagerId);
			state.staticManagers = removeManagerFromList(state.staticManagers, action.payload.deletedManagerId);
			state.visibleManagers = removeManagerFromList(state.visibleManagers, action.payload.deletedManagerId);
			state.selectedManagers = removeManagerFromList(state.selectedManagers, action.payload.deletedManagerId);
			state.filteredManagers = removeManagerFromList(state.filteredManagers, action.payload.deletedManagerId);
		},
		updateActiveManagers: (
			state: IManagersReducerState,
			action: PayloadAction<{ activeManagers: IActiveManager[] }>
		) => {
			const filteredDropdownManagers: IActiveManager[] = filterArrayByAnotherArray(
				action.payload.activeManagers,
				state.filterList,
				entitiesProperties.tradeId
			);
			const selectedActiveManagers: IActiveManager[] = filterArrayByAnotherArray(
				state.selectedManagers,
				state.filterList,
				entitiesProperties.tradeId
			);
			const visibleManagers: IActiveManager[] = filterArrayByAnotherArray(
				filteredDropdownManagers,
				selectedActiveManagers,
				entitiesProperties._id
			);
			state.activeManagers = action.payload.activeManagers.filter((manager) => !manager.disconnected);
			state.staticManagers = action.payload.activeManagers;
			state.filteredManagers = filteredDropdownManagers;
			state.selectedManagers = selectedActiveManagers;
			state.visibleManagers = visibleManagers;
		},
		removeAllManagers: (state: IManagersReducerState) => {
			state = initialState;
		},
		addSelectedManager: (
			state: IManagersReducerState,
			action: PayloadAction<{ selectedStaticManager: IManager }>
		) => {
			const selectedManagersAfterAdd: IManager[] | IActiveManager[] = compact(
				state.selectedManagers.concat(action.payload.selectedStaticManager)
			);
			const visibleManagers: IManager[] | IActiveManager[] = filterArrayByAnotherArray(
				state.filteredManagers,
				selectedManagersAfterAdd,
				entitiesProperties._id
			);
			state.selectedManagers = selectedManagersAfterAdd;
			state.visibleManagers = visibleManagers;
		},
		removeSelectedManager: (
			state: IManagersReducerState,
			action: PayloadAction<{ removedStaticManager: IManager }>
		) => {
			const selectedManagersAfterRemove: IManager[] = state.selectedManagers.filter((selectedManager) => {
				return action.payload.removedStaticManager._id !== selectedManager._id;
			});
			const visibleManagers: IManager[] = filterArrayByAnotherArray(
				state.filteredManagers,
				selectedManagersAfterRemove,
				entitiesProperties._id
			);
			state.selectedManagers = selectedManagersAfterRemove;
			state.visibleManagers = visibleManagers;
		},
		setManagersFilter: (state: IManagersReducerState, action: PayloadAction<{ filterList: ITradeFilter[] }>) => {
			const filteredStaticManagers: IManager[] = filterArrayByAnotherArray(
				state.staticManagers,
				action.payload.filterList,
				entitiesProperties.tradeId
			);
			const filteredActiveManagers: IManager[] = filterArrayByAnotherArray(
				state.activeManagers,
				action.payload.filterList,
				entitiesProperties.tradeId
			);
			const selectedManagers: IManager[] = filterArrayByAnotherArray(
				state.selectedManagers,
				action.payload.filterList,
				entitiesProperties.tradeId
			);
			const visibleStaticManagers: IManager[] = filterArrayByAnotherArray(
				filteredStaticManagers,
				selectedManagers,
				entitiesProperties._id
			);
			const visibleActiveManagers: IManager[] = filterArrayByAnotherArray(
				filteredActiveManagers,
				selectedManagers,
				entitiesProperties._id
			);
			state.filteredManagers = visibleActiveManagers.length > 0 ? filteredActiveManagers : filteredStaticManagers;
			state.selectedManagers = selectedManagers;
			state.filterList = action.payload.filterList;
			state.visibleManagers = visibleActiveManagers.length > 0 ? visibleActiveManagers : visibleStaticManagers;
		},
		resetSelectedManagers: (state: IManagersReducerState) => {
			state.selectedManagers = [];
			state.filterList = [];
			state.filteredManagers = state.activeManagers.length > 0 ? state.activeManagers : state.staticManagers;
			state.visibleManagers = state.activeManagers.length > 0 ? state.activeManagers : state.staticManagers;
		},
		resetStaticManagers: (state: IManagersReducerState) => {
			state.staticManagers = [];
		},
		updateStaticManagerTag: (state: IManagersReducerState, action: PayloadAction<{ manager: IManager }>) => {
			state.activeManagers = upsertTagInStaticManagerState(
				action.payload.manager.tradeId,
				action.payload.manager,
				state.activeManagers
			);
			state.staticManagers = upsertTagInStaticManagerState(
				action.payload.manager.tradeId,
				action.payload.manager,
				state.staticManagers
			);
			state.visibleManagers = upsertTagInStaticManagerState(
				action.payload.manager.tradeId,
				action.payload.manager,
				state.visibleManagers
			);
			state.selectedManagers = upsertTagInStaticManagerState(
				action.payload.manager.tradeId,
				action.payload.manager,
				state.selectedManagers
			);
			state.filteredManagers = upsertTagInStaticManagerState(
				action.payload.manager.tradeId,
				action.payload.manager,
				state.filteredManagers
			);
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getStaticManagers.pending, (state: IManagersReducerState) => {
				state.managersRequestStatus = HTTPRequestStatuses.pending;
			})
			.addCase(getStaticManagers.fulfilled, (state: IManagersReducerState, action) => {
				state.managersRequestStatus = HTTPRequestStatuses.success;
				const filteredManagers: IManager[] = filterArrayByAnotherArray(
					action.payload.managers,
					state.filterList,
					entitiesProperties.tradeId
				);
				const selectedManagers: IManager[] = filterArrayByAnotherArray(
					state.selectedManagers,
					state.filterList,
					entitiesProperties.tradeId
				);
				const visibleStaticManagers: IManager[] = filterArrayByAnotherArray(
					filteredManagers,
					selectedManagers,
					entitiesProperties._id
				);
				state.staticManagers = action.payload.managers;
				state.filteredManagers = filteredManagers;
				state.selectedManagers = selectedManagers;
				state.visibleManagers = visibleStaticManagers;
			})
			.addCase(getStaticManagers.rejected, (state: IManagersReducerState) => {
				state.managersRequestStatus = HTTPRequestStatuses.failed;
			})
			.addCase(createManager.fulfilled, (state: IManagersReducerState, action: any) => {
				state.createManagerStatus = {
					status: HTTPRequestStatuses.success,
					code: HttpStatusCode.okSuccess,
				};
				state.staticManagers = addManagerToList(state.staticManagers, action.payload.manager);
			})
			.addCase(createManager.rejected, (state: IManagersReducerState, action: any) => {
				state.createManagerStatus = {
					status: HTTPRequestStatuses.failed,
					code: action.payload,
				};
			})
			.addCase(updateManager.fulfilled, (state: IManagersReducerState, action: any) => {
				state.updateManagerStatus = {
					status: HTTPRequestStatuses.success,
					code: HttpStatusCode.okSuccess,
				};
				state.staticManagers = uniqBy(
					[action.payload.manager, ...state.staticManagers],
					entitiesProperties._id
				);
			})
			.addCase(updateManager.rejected, (state: IManagersReducerState, action: any) => {
				state.updateManagerStatus = {
					status: HTTPRequestStatuses.failed,
					code: action.payload,
				};
			});
	},
});

const removeManagerFromList = (managerList: IManager[], deletedManagerId: string): IManager[] => {
	return managerList.filter((manager: IManager) => manager._id !== deletedManagerId);
};

const addManagerToList = (managerList: IManager[], manager: IManager): IManager[] => {
	return compact(managerList.concat(manager));
};

const upsertTagInStaticManagerState = (managerId: string, updateObject: any, managerList: IManager[]): IManager[] => {
	const isExist = managerList.some((manager) => manager.tradeId === managerId);
	if (!isExist) {
		return [...managerList, updateObject];
	}
	return managerList.map((staticManager) =>
		staticManager.tradeId === managerId ? { ...staticManager, ...updateObject } : staticManager
	);
};

export const {
	resetCreateManagerStatus,
	resetUpdateManagerStatus,
	addSelectedManager,
	removeSelectedManager,
	removeAllManagers,
	resetSelectedManagers,
	resetStaticManagers,
	setManagersFilter,
	updateStaticManagerTag,
	updateActiveManagers,
	removeManager,
} = managersSlice.actions;

export default managersSlice.reducer;
