import moment from 'moment-timezone';
import 'moment/locale/he';
import { IMilestone } from '@shared/interfaces/IMilestone';

export const isMilestoneLate = (milestone: IMilestone, tz: string): boolean => {
	return moment.tz(milestone.endDate, tz).isBefore(moment.tz(new Date(), tz));
};

export const isMilestoneRemaining = (milestone: IMilestone, tz: string): boolean => {
	return moment.tz(milestone.endDate, tz).isAfter(moment.tz(new Date(), tz));
};

export const milestoneLateDays = (milestone: IMilestone, tz: string): number => {
	return moment.tz(new Date(), tz).diff(moment.tz(milestone.endDate, tz), 'days');
};

export const milestoneRemainingDays = (milestone: IMilestone, tz: string): number => {
	return moment.tz(milestone.endDate, tz).diff(moment.tz(new Date(), tz), 'days');
};
