import React from 'react';
import { ChipWithNumber } from './ChipWithNumber';
import { calculatePositionForChipsContainer } from '../../utils/generalUtils';
import { ISvgElementPoint } from '../../interfaces/ISvgElementPoint';
import { getProfessionDisplayText } from '../../utils/professions.utils';
import { PROFESSIONS_CHIP_MAX_WIDTH } from '../../constants';
import { IProfessionWithActiveTags } from '../../interfaces/IProfessionWithActiveTags';
import {
	getProfessionBackgroundColor,
	getProfessionDisplayTextFromDisplaySettings,
} from '@shared/utils/professions.utils';
import { useSelector } from 'react-redux';
import { selectProjectId } from '@store/slices/project.slice';
import { translationService } from '@src/servicesInitializers';

interface IProfessionChipsContainerProps {
	professionsForFloor: IProfessionWithActiveTags[];
	professionPointForFloor: ISvgElementPoint;
	equipmentPrefixContainer: any;
	id: string;
	buildingSvgWidth: number;
	onClick: () => void;
}

const ProfessionChipsContainer = React.forwardRef((props: IProfessionChipsContainerProps, ref: any) => {
	const { professionsForFloor, professionPointForFloor, equipmentPrefixContainer, ...others } = props;
	const projectId: string = useSelector(selectProjectId)!;
	const { left, right } = calculatePositionForChipsContainer(equipmentPrefixContainer, professionPointForFloor);
	const maxWidth: number = props.buildingSvgWidth - (right! || left!) - 10;
	const maxChipWidth: number = Math.min(maxWidth / professionsForFloor.length, PROFESSIONS_CHIP_MAX_WIDTH);

	return (
		<div
			{...others}
			ref={ref}
			style={{
				position: 'absolute',
				top: professionPointForFloor.top,
				right: right,
				left: left,
				maxWidth: maxWidth,
				display: 'flex',
				overflow: 'hidden',
				justifyContent: 'flex-start',
			}}
		>
			{professionsForFloor.map((professionForFloor) => {
				const chipText: string = getProfessionDisplayTextFromDisplaySettings(
					professionForFloor,
					translationService,
					projectId
				);
				return (
					<ChipWithNumber
						key={`floor_${professionPointForFloor.floorId}_${professionForFloor._id}`}
						chipText={chipText}
						chipColor={getProfessionBackgroundColor(professionForFloor, projectId)}
						number={professionForFloor.activeTagsIds.length}
						maxWidth={maxChipWidth}
					/>
				);
			})}
		</div>
	);
});

export default ProfessionChipsContainer;
