import { DATE_PICKER_TOGGLE } from '../interfaces/DatePickerMode';
import { ICreateProjectOverviewReport } from '../interfaces/IProjectOverviewReport';
import { InvestigationReportTypes } from '../interfaces/InvestigationReportTypes.enum';
import { getTimezoneFormattedDate } from './dateUtils';
import { TranslationService } from '../services/translation.service';
import { RequestService } from '../services/request.service';

const getProjectOverviewReport = (
	translationService: TranslationService,
	userId: string,
	projectId: string,
	tz: string,
	datePickerMode: DATE_PICKER_TOGGLE,
	fromDate: Date,
	toDate?: Date
): ICreateProjectOverviewReport => {
	return {
		userId: userId,
		investigationType: InvestigationReportTypes.projectOverview,
		title: `${translationService.get('executiveSummary')}`,
		description: '',
		projectId: projectId,
		periodPickerMode: datePickerMode,
		fromDate: getTimezoneFormattedDate(tz, fromDate),
		...(toDate && {
			toDate: getTimezoneFormattedDate(tz, toDate),
		}),
	};
};
export const generateInvestigationReport = async (
	requestService: RequestService,
	translationService: TranslationService,
	userId: string,
	projectId: string,
	tz: string,
	datePickerMode: DATE_PICKER_TOGGLE,
	fromDate: Date,
	toDate?: Date
): Promise<string> => {
	try {
		const investigationReport: ICreateProjectOverviewReport = getProjectOverviewReport(
			translationService,
			userId,
			projectId,
			tz,
			datePickerMode,
			fromDate,
			toDate
		);
		const reportId = await requestService.post(`/archive/investigationReports/`, {
			body: {
				investigationReport,
			},
		});
		return reportId;
	} catch (e) {
		console.error('Failed to generate report', e);
		throw e;
	}
};
