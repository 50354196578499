import { IInvestigationReport } from '@shared/interfaces/IInvestigationReport';
import { Languages } from '@shared/constants/languages';
import React from 'react';
import classes from './styles.module.scss';
import { InvestigationReportTypes } from '@shared/interfaces/InvestigationReportTypes.enum';
import { getDateFormattedWithTimeFromTimestamp, getRelativeHour } from '@shared/utils/dateUtils';
import { useSelector } from 'react-redux';
import { selectTimezone } from '../../../../../store/slices/project.slice';
import { translationService } from '../../../../../index';
import { useHistory } from 'react-router-dom';
import { RecentReportCard } from '../RecentReportCard';
import { analysisCenterReportGeneration_BI } from '@utils/bi.utils';

interface IRecentInvestigationReportCardProps {
	investigationReport: IInvestigationReport;
}

export const RecentInvestigationReportCard = (props: IRecentInvestigationReportCardProps) => {
	const tz: string = useSelector(selectTimezone)!;
	const history = useHistory();

	const getTagAndDateSection = (): JSX.Element => {
		return (
			<>
				<div className={classes.tagSection}>
					<div className={classes.tagName}>{props.investigationReport.tagName}</div>-
					<div className={classes.tagId}>
						{props.investigationReport.tagNick || props.investigationReport.tagId}
					</div>
				</div>
				<div className={classes.divSeparator}></div>
				<div>
					{getDateFormattedWithTimeFromTimestamp(
						props.investigationReport.investigatedTimestamp,
						props.investigationReport.investigationType === InvestigationReportTypes.incidentReport,
						translationService.getChosenLanguage() === Languages.HEBREW,
						tz,
						translationService.getDateLocale()
					)}
				</div>
			</>
		);
	};

	const redirectToInvestigationReport = () => {
		analysisCenterReportGeneration_BI(props.investigationReport.investigationType, 'recent', [
			new Date(props.investigationReport.investigatedTimestamp),
		]);

		history.push(`/analysisCenter/${props.investigationReport.investigationType}/${props.investigationReport._id}`);
	};

	return (
		<RecentReportCard
			reportId={props.investigationReport._id!}
			investigationReport={props.investigationReport}
			redirectToReport={redirectToInvestigationReport}
			dateGenerated={props.investigationReport.dateGenerated}
		>
			{getTagAndDateSection()}
		</RecentReportCard>
	);
};
