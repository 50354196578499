import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ReportCard } from '@src/Components/Pages/AnalysisCenterPages/CardsSection/ReportCard';
import { InvestigationReportTypes } from '@shared/interfaces/InvestigationReportTypes.enum';
import { requestService, translationService } from '@src/servicesInitializers';
import { analysisCenterReportGeneration_BI } from '@utils/bi.utils';
import { getContractorAnalysisReportRoute } from '@utils/analysisCenter.utils';
import { ICreateContractorAnalysisReport } from '@shared/interfaces/IContractorAnalysisReport';
import { useSelector } from 'react-redux';
import { selectProjectId } from '@store/slices/project.slice';
import { IUser } from '@shared/interfaces/IUser';
import { selectLoggedUserDetails } from '@store/slices/login.slice';
import { IProfession } from '@shared/interfaces/IProfession';
import { IRootState } from '@store/slices';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { SingleProfessionGroupedDropdown } from '@src/Components/Dropdowns/Profession/SingleProfessionGroupedDropdown/SingleProfessionGroupedDropdown';

interface IContractorAnalysisCardProps {
	isSelected: boolean;
	icon: IconNames;
	title: string;
	description: string;
	name: InvestigationReportTypes;
	setSelectedCard: (name: string) => void;
}

const ContractorAnalysisCard = (props: IContractorAnalysisCardProps) => {
	const [selectedProfession, setSelectedProfession] = useState<IProfession | undefined>();
	const projectId: string = useSelector(selectProjectId)!;
	const history = useHistory();
	const loggedUserDetails: IUser = useSelector(selectLoggedUserDetails)!;
	const getWorkerTimesheet = (): ICreateContractorAnalysisReport | undefined => {
		if (!selectedProfession) return;
		return {
			userId: loggedUserDetails._id!,
			title: translationService.get('contractorAnalysis'),
			description: '',
			projectId,
			investigationType: InvestigationReportTypes.contractorAnalysis,
			profession: selectedProfession,
		};
	};

	const generateContractorAnalysisReport = async () => {
		const contractorAnalysisReport: ICreateContractorAnalysisReport | undefined = getWorkerTimesheet();
		if (!contractorAnalysisReport) return;
		const reportId = await requestService.post(`/archive/investigationReports/`, {
			body: {
				investigationReport: contractorAnalysisReport,
			},
		});
		analysisCenterReportGeneration_BI(InvestigationReportTypes.contractorAnalysis, 'new', [new Date()]);
		history.push(getContractorAnalysisReportRoute(reportId));
	};

	const isGenerateDisabled = () => {
		return !selectedProfession;
	};

	return (
		<ReportCard
			name={props.name}
			isSelected={props.isSelected}
			icon={props.icon}
			title={props.title}
			description={props.description}
			generateReport={generateContractorAnalysisReport}
			isGenerateReportDisabled={isGenerateDisabled()}
			setSelectedCard={props.setSelectedCard}
		>
			<SingleProfessionGroupedDropdown
				onChange={(profession: IProfession | null) =>
					setSelectedProfession(profession ? profession : undefined)
				}
				useWorkingProfessions
			/>
		</ReportCard>
	);
};

export { ContractorAnalysisCard };
