import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { textStyle } from '@shared/utils/text.utils';
import {
	PRODUCTIVITY_COLUMN_WIDTH,
	PRODUCTIVITY_HEADER_HEIGHT,
	PRODUCTIVITY_LOCATION_CELL_HEIGHT,
	PRODUCTIVITY_WORKERS_QUANTITY_CELL_HEIGHT,
	PRODUCTIVITY_WORK_HOURS_CELL_HEIGHT_DAILY,
	PRODUCTIVITY_WORK_HOURS_CELL_HEIGHT_PERIOD,
} from '../../../../../../constants';
import { ProductivityColumnHeader } from './ProductivityColumnHeader/ProductivityColumnHeader';
import { IProfession } from '../../../../../../interfaces';
import { regularBorder } from '../../../../../../constants/genericStyles.constants';

interface IStylesProps {
	isDailyReport?: boolean;
}

const useStyles = makeStyles((theme) => ({
	columnContainer: {
		display: 'flex',
		flexDirection: 'column',
		width: PRODUCTIVITY_COLUMN_WIDTH,
		minWidth: PRODUCTIVITY_COLUMN_WIDTH,
	},
	title: textStyle({
		fontClass: 'h2',
		margin: 12,
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	}),
	headerContainer: {
		height: PRODUCTIVITY_HEADER_HEIGHT,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		border: regularBorder,
	},
	workerQuantity: {
		height: PRODUCTIVITY_WORKERS_QUANTITY_CELL_HEIGHT,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		border: regularBorder,
	},
	workHours: ({ isDailyReport }: IStylesProps) => ({
		height: isDailyReport ? PRODUCTIVITY_WORK_HOURS_CELL_HEIGHT_DAILY : PRODUCTIVITY_WORK_HOURS_CELL_HEIGHT_PERIOD,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		border: regularBorder,
	}),
	workResourcesLocation: {
		height: PRODUCTIVITY_LOCATION_CELL_HEIGHT,
		minHeight: PRODUCTIVITY_LOCATION_CELL_HEIGHT,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		border: regularBorder,
	},
	horizontalBarsContainer: {
		width: '90%',
		height: '90%',
	},
}));

interface IWorkResourcesEmptyColumn {
	profession: IProfession;
	isDailyReport: boolean;
}

const ProductivityEmptyColumn = (props: IWorkResourcesEmptyColumn) => {
	const classes = useStyles({ isDailyReport: props.isDailyReport });

	return (
		<div className={classes.columnContainer}>
			<ProductivityColumnHeader profession={props.profession} />
			<div className={classes.workerQuantity} />
			<div className={classes.workHours} />
			<div className={classes.workResourcesLocation}>
				<div className={classes.horizontalBarsContainer}></div>
			</div>
		</div>
	);
};

export { ProductivityEmptyColumn };
