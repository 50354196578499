import React from 'react';
import { Dictionary, groupBy, uniqBy } from 'lodash';
import { translationService } from '../../index';
import { APP_FILTER_TYPES, COLORS, equipmentProperties } from '../../constants';
import { ExpandableComponent } from '../MainPageSections/ExpandableComponent/ExpandableComponent';
import { EquipmentTypes, IStaticEquipment } from '../../interfaces';
import { IMergedEquipmentView } from '../../interfaces/IMergedEquipmentView';
import { useDispatch, useSelector } from 'react-redux';
import {
	addOneSelectedEquipmentType,
	removeOneSelectedEquipmentType,
	resetSelectedEquipment,
} from '../../store/slices/equipment.slice';
import { IRootState } from '../../store/slices';
import { EquipmentFilterCard } from '@shared/components/Filters/EquipmentFilterCard/EquipmentFilterCard';
import { ScissorsLiftFilterCard } from '@shared/components/Filters/ScissorsLiftFilterCard/ScissorsLiftFilterCard';
import { IUtilityMergedTag } from '@interfaces/IUtilityMergedTag';
import { IScissorsLiftUtility } from '@interfaces/IScissorsLiftUtility';
import { selectProjectId } from '@store/slices/project.slice';
import { UtilityTypes } from '@interfaces/UtilityTypes.enum';
import { getActiveScissorLiftTags } from '@utils/scissorLift.utils';
import { LiveActionAnimatedIcon } from '@src/Components/LiveActionAnimatedIcon/LiveActionAnimatedIcon';
import { addSelectedUtilities, removeSelectedUtilities, selectSelectedUtilities } from '@store/slices/utilities.slice';

interface IEquipmentTypeCount {
	countActive: number;
	countStatic: number;
	typeNick: string;
	type: EquipmentTypes;
}

const getEquipmentTypesWithCount = (
	activeEquipment: IMergedEquipmentView[],
	staticEquipment: IStaticEquipment[]
): IEquipmentTypeCount[] => {
	const staticEquipmentByTypes: IStaticEquipment[] = uniqBy(staticEquipment, equipmentProperties.type);
	return staticEquipmentByTypes.map((equipment) => {
		const activeEquipmentCount: number = activeEquipment.filter(
			(activeEq) => activeEq.type === equipment.type
		).length;
		const staticEquipmentCount: number = staticEquipment.filter(
			(staticEq) => staticEq.type === equipment.type
		).length;
		return {
			type: equipment.type,
			typeNick: equipment.typeNick,
			countActive: activeEquipmentCount,
			countStatic: staticEquipmentCount,
		};
	});
};

const EquipmentTypeFilterSection = () => {
	const projectId: string = useSelector(selectProjectId)!;
	const activeEquipment = useSelector((state: IRootState) => state.equipmentReducer.activeEquipment);
	const staticEquipment = useSelector((state: IRootState) => state.equipmentReducer.staticEquipment);
	const selectedEquipment = useSelector((state: IRootState) => state.equipmentReducer.selectedStaticEquipment);
	const utilitiesOnSite: IUtilityMergedTag[] = useSelector(
		(state: IRootState) => state.utilitiesReducer.activeUtilities
	);
	const selectedUtilities: IUtilityMergedTag[] = useSelector(selectSelectedUtilities);
	const scissorsLiftsOnSite: IUtilityMergedTag[] = utilitiesOnSite.filter(
		(utility: IUtilityMergedTag) => utility.linkedUtility.type === UtilityTypes.scissorsLift
	);
	const groupScissorsLiftByProfession: Dictionary<IUtilityMergedTag[]> = groupBy(
		scissorsLiftsOnSite,
		(utility: IUtilityMergedTag) => (utility.linkedUtility as IScissorsLiftUtility).profession._id
	);

	const equipmentTypesWithCount: IEquipmentTypeCount[] = getEquipmentTypesWithCount(activeEquipment, staticEquipment);
	const selectedEquipmentTypes: EquipmentTypes[] = uniqBy(selectedEquipment, equipmentProperties.type).map(
		(equipment) => equipment.type
	);
	const entitiesCount: number = equipmentTypesWithCount.length + Object.keys(groupScissorsLiftByProfession).length;

	const dispatch = useDispatch();

	return (
		<ExpandableComponent
			headerText={translationService.get('equipment')}
			childrenLength={entitiesCount}
			clearFunction={() => dispatch(resetSelectedEquipment())}
			shouldHideClearButton={selectedEquipmentTypes.length === 0}
			clearLength={selectedEquipmentTypes.length}
			type={APP_FILTER_TYPES.equipmentFilter}
			defaultOpen
			testId={'equipmentFilterSection'}
			hideEmptyList
		>
			{equipmentTypesWithCount.map((equipmentData: IEquipmentTypeCount) => {
				const isSelected: boolean = selectedEquipmentTypes.includes(equipmentData.type);
				return (
					<EquipmentFilterCard
						key={equipmentData.type}
						handleClick={
							isSelected
								? () =>
										dispatch(
											removeOneSelectedEquipmentType({
												removedEquipmentType: equipmentData.type,
											})
										)
								: () =>
										dispatch(
											addOneSelectedEquipmentType({
												selectedEquipmentType: equipmentData.type,
											})
										)
						}
						equipmentType={equipmentData.type}
						equipmentTypeNick={equipmentData.typeNick}
						count={equipmentData.countActive}
						countTotal={equipmentData.countStatic}
						isSelected={isSelected}
					/>
				);
			})}
			{Object.values(groupScissorsLiftByProfession).map((professionScissorsLifts: IUtilityMergedTag[]) => {
				const activeCount: number = getActiveScissorLiftTags(professionScissorsLifts).length;
				const totalCount: number = professionScissorsLifts.length;
				const shouldColorTotalCount: boolean = activeCount === totalCount;
				const isSelected: boolean = selectedUtilities.some((selectedUtility) =>
					professionScissorsLifts.some((scissorsLift) => scissorsLift._id === selectedUtility._id)
				);
				return (
					<ScissorsLiftFilterCard
						handleClick={
							isSelected
								? () =>
										dispatch(
											removeSelectedUtilities({
												removedUtilities: professionScissorsLifts,
											})
										)
								: () =>
										dispatch(
											addSelectedUtilities({
												selectedUtilities: professionScissorsLifts,
											})
										)
						}
						endComponent={activeCount > 0 ? <LiveActionAnimatedIcon /> : null}
						key={professionScissorsLifts[0]?._id}
						countTotal={totalCount}
						countColor={activeCount > 0 ? COLORS.feedbackPositiveDark : undefined}
						totalColor={shouldColorTotalCount ? COLORS.feedbackPositiveDark : undefined}
						count={activeCount}
						isSelected={isSelected}
						projectId={projectId}
						profession={(professionScissorsLifts[0]?.linkedUtility as IScissorsLiftUtility)?.profession}
					/>
				);
			})}
		</ExpandableComponent>
	);
};

export { EquipmentTypeFilterSection };
