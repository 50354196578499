import React from 'react';
import classes from './styles.module.scss';
import { TopBar } from '@src/Components/Bars/TopBar/TopBar';
import { SideBar } from '@src/Components/Bars/SideBar/SideBar';
import { selectProjectId } from '@store/slices/project.slice';
import { useSelector } from 'react-redux';
import { SomethingWentWrongPage } from '@shared/components/SomethingWentWrongPage/SomethingWentWrongPage';
import { useHistory } from 'react-router-dom';
import { MAIN_PAGE_ROUTES } from '@src/constants';

export const ErrorPage = () => {
	const history = useHistory();
	const workingProjectId: string = useSelector(selectProjectId)!;
	return (
		<div className={classes.errorPageContainer}>
			<TopBar projectId={workingProjectId} />
			<SideBar />
			<SomethingWentWrongPage
				onRefresh={() => {
					history.push(MAIN_PAGE_ROUTES[0].replace(':projectId?', workingProjectId));
					window.location.reload();
				}}
			/>
		</div>
	);
};
