import * as Sentry from '@sentry/react';
import { Environments } from '../constants/environments';
import { IUser } from '../interfaces/IUser';
import { customHistory } from './history.utils';
import { Route } from 'react-router-dom';
import posthog from 'posthog-js';

export const initMonitoringService = (
	dsn: string,
	environment: string,
	release: string,
	sentryOrg: string,
	sentryProjectId: number
): void => {
	const allowedEnvironments: Environments[] = [Environments.prod];
	if (!allowedEnvironments.includes(environment as Environments)) {
		return;
	}

	Sentry.init({
		dsn: dsn,
		environment,
		release,
		integrations: [
			new Sentry.Replay({
				maskAllText: false,
				blockAllMedia: false,
			}),
			Sentry.reactRouterV5BrowserTracingIntegration({ history: customHistory, enableInp: true }),
			new posthog.SentryIntegration(posthog, sentryOrg, sentryProjectId),
		],
		tracesSampleRate: 1.0,
		// Session Replay
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});
};

export const MonitoringRoute = Sentry.withSentryRouting(Route);

export const initSentryUser = (user: IUser): void => {
	Sentry.setUser({
		id: user.username,
		username: user.username,
		userId: user._id,
		email: user.email,
		roleType: user.permissions.roleType,
	});
};

export const setSentryProjectId = (projectId: string): void => {
	Sentry.setTag('projectId', projectId);
};

const setMetadata = (scope: any, metadata?: any) => {
	if (!metadata) {
		return;
	}

	Object.keys(metadata).forEach((key) => {
		scope.setExtra(key, metadata[key]);
	});
};

export const captureError = (err, metadata?: any) => {
	Sentry.withScope((scope) => {
		setMetadata(scope, metadata);
		Sentry.captureException(err);
	});
};

export const captureMessage = (message: string, metadata?: any) => {
	Sentry.withScope((scope) => {
		setMetadata(scope, metadata);
		Sentry.captureMessage(message);
	});
};

export const startSpan = (name: string, callback: () => Promise<void>) => {
	return Sentry.startSpan({ name }, async () => {
		await callback();
	});
};
