import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { requestService, translationService } from '../../index';
import { GeneralDialog } from '../GeneralDialog/GeneralDialog';
import { ManagerChip } from '../Chips/ManagerChip/ManagerChip';
import { textStyle } from '@shared/utils/text.utils';
import { deleteManager } from '../../store/thunks';
import { useDispatch, useSelector } from 'react-redux';
import { IAssignedActivity, IManager, ITag } from '../../interfaces';
import { IRootState } from '../../store/slices';
import { getTimezoneFormattedDate } from '../../utils/dateUtils';
import { IUnassignedActivity } from '../../interfaces/IUnassignedActivity';
import { deleteManager_BI } from '../../utils/bi.utils';

const useStyles = makeStyles((theme) => ({
	avatar: {
		width: 20,
		height: 20,
		cursor: 'pointer',
	},
	dialogContent: {
		width: '96%',
		marginTop: 10,
		marginBottom: 10,
	},
	dialogText: textStyle({
		fontSize: 12,
		fontWeight: 300,
	}),
	description: textStyle({
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(1),
		fontWeight: 300,
	}),
}));

interface IManagerDeleteDialogProps {
	manager: IManager;
	handleCloseDeleteRoleDialog: () => void;
}

const ManagerDeleteDialog = (props: IManagerDeleteDialogProps) => {
	const projectId: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.projectId);
	const tz: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.tz);
	const [canManagerBeDeleted, setCanManagerBeDeleted] = useState<boolean>(false);
	const [isTagError, setIsTagError] = useState<boolean>(false);
	const tags: ITag[] = useSelector((state: IRootState) => state.tagsReducer.tags);
	const classes = useStyles();
	const dispatch = useDispatch();

	useEffect(() => {
		(async () => {
			const doesManagerHasTagAssigned: boolean = tags.some(
				(tag) => tag.manager && tag.manager._id === props.manager._id
			);
			if (doesManagerHasTagAssigned) {
				setIsTagError(true);
				return;
			}

			const dateFrom: string = getTimezoneFormattedDate(tz, new Date());
			const projectActivities: {
				[profession: string]: (IAssignedActivity | IUnassignedActivity)[];
			} = await requestService.get(
				`/activities/activitiesBetweenDates?projectId=${projectId}&dateFrom=${dateFrom}&tz=${tz}`
			);
			const doesActivitiesIncludeManager: boolean = Object.values(projectActivities).some(
				(activities: (IAssignedActivity | IUnassignedActivity)[]) =>
					activities.find((activity) => (activity as IAssignedActivity).manager?._id === props.manager._id)
			);

			if (doesActivitiesIncludeManager) {
				return;
			}

			setCanManagerBeDeleted(true);
		})();
	}, [props.manager._id, tags, tz]);

	const handleDeleteManager = () => {
		dispatch(deleteManager(props.manager._id));
		deleteManager_BI(props.manager);
		props.handleCloseDeleteRoleDialog();
	};

	return (
		<GeneralDialog
			children={
				<div className={`${classes.dialogContent} ${classes.dialogText}`}>
					{canManagerBeDeleted ? (
						<p className={classes.description}>{translationService.get('managerDeleteConfirmQuestion')}</p>
					) : (
						<p className={classes.description}>
							{translationService.get(
								isTagError ? 'managerHasTagsAssignedMessage' : 'managerHasActivitiesAssignedMessage'
							)}
						</p>
					)}
					<ManagerChip
						key={`${props.manager.name}`}
						chipText={props.manager.name}
						chipBackground={props.manager.backgroundColor}
					/>
				</div>
			}
			show={true}
			close={props.handleCloseDeleteRoleDialog}
			mainButton={{
				text: !canManagerBeDeleted
					? translationService.get('okGotIt')
					: translationService.get('deleteConfirm'),
				click: canManagerBeDeleted
					? handleDeleteManager
					: () => {
							deleteManager_BI(props.manager, isTagError);
							props.handleCloseDeleteRoleDialog();
					  },
			}}
			title={translationService.get('deleteManager')}
			rootStyle={{ width: 500, height: 300 }}
		/>
	);
};

export { ManagerDeleteDialog };
