import { combineReducers } from 'redux';
import floorsViewReducer from './floorsView.slice';
import loginReducer from './login.slice';
import safetyEventsReducer from './safetyEvents.slice';
import projectReducer from './project.slice';
import productivityReportReducer from './productivityReport.slice';
import safetyReportReducer from './safetyReport.slice';
import activityReportReducer from './activityReport.slice';
import professionsReducer from './professions.slice';
import floorsListReducer from './floorsList.slice';
import areasListReducer from './areasList.slice';
import equipmentReducer from './equipment.slice';
import snackbarReducer from './snackbar.slice';
import managersReducer from './managers.slice';
import tagsReducer from './tags.slice';
import managementTradesReducer from './managementTrades.slice';
import datePickerReducer from './datePicker.slice';
import professionReportReducer from './professionReport.slice';
import appFilterReducer from './appFilter.slice';
import statusesReducer from './ActivityGroupStatuses.slice';
import notificationsReducer from './notifications.slice';
import issuesPrioritiesReducer from './issuePriorities.slice';
import issuesStatusesReducer from './issueStatuses.slice';
import usersReducer from './users.slice';
import mainPageLayoutReducer from './mainPageLayout.slice';
import sequencesReducer from './sequences.slice';
import dashboardReducer from './dashboard.slice';
import utilitiesReducer from './utilities.slice';

const rootReducer = combineReducers({
	floorsViewReducer,
	loginReducer,
	safetyEventsReducer,
	projectReducer,
	productivityReportReducer,
	safetyReportReducer,
	activityReportReducer,
	professionsReducer,
	floorsListReducer,
	areasListReducer,
	equipmentReducer,
	snackbarReducer,
	managersReducer,
	tagsReducer,
	managementTradesReducer,
	datePickerReducer,
	professionReportReducer,
	appFilterReducer,
	statusesReducer,
	notificationsReducer,
	issuesPrioritiesReducer,
	issuesStatusesReducer,
	usersReducer,
	mainPageLayoutReducer,
	sequencesReducer,
	dashboardReducer,
	utilitiesReducer,
});

export type IRootState = ReturnType<typeof rootReducer>;
export default rootReducer;
