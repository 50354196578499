import React, { useEffect, useState, useLayoutEffect } from 'react';
import { Directions } from '../interfaces/Directions';
import { isNumber } from 'lodash';

export const useOutsideClick = (
	ref,
	setComponentState,
	componentStateOnClickOutside,
	ignoreOutsideClicksRefs?: any[]
) => {
	useEffect(() => {
		function handleClickOutside(event) {
			if (
				ref.current &&
				!ref.current.contains(event.target) &&
				!ignoreOutsideClicksRefs?.some((ignoreRef) => ignoreRef.current.contains(event.target))
			) {
				setComponentState(componentStateOnClickOutside);
			}
		}

		// Bind the event listener
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [setComponentState, componentStateOnClickOutside, ref.current]);
};

export const useOutsideClickWithCallback = (ref, callback, deps = []) => {
	useEffect(() => {
		function handleClickOutside(event) {
			if (ref.current && !ref.current.contains(event.target)) {
				callback();
			}
		}

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [callback, ref.current, ...deps]);
};

export const useElementDistanceFromEnd = (element: HTMLElement | null, dir: Directions): number | undefined => {
	const [distance, setDistance] = useState<number | undefined>(undefined);

	const rect: DOMRect | undefined = element?.getBoundingClientRect();

	useEffect(() => {
		if (!element) {
			return;
		}

		if (dir === 'rtl' && isNumber(rect?.left)) {
			setDistance(rect?.left);
			return;
		}

		if (isNumber(rect?.right)) {
			setDistance(window.innerWidth - rect?.right!);
		}
	}, [element, window.innerWidth, rect?.right, dir]);

	return distance;
};

export const useElementDistanceFromBottom = (element: HTMLElement | null): number | undefined => {
	const [distance, setDistance] = useState<number | undefined>(undefined);
	const rect: DOMRect | undefined = element?.getBoundingClientRect();

	useEffect(() => {
		if (!element) {
			return;
		}

		if (isNumber(rect?.bottom)) {
			setDistance(window.innerHeight - rect!.bottom!);
		}
	}, [element, window.innerHeight, rect?.bottom]);

	return distance;
};

export const useHeightOverflow = (ref) => {
	const [isOverflow, setIsOverflow] = useState(false);

	useLayoutEffect(() => {
		const { current } = ref;
		const checkForOverflow = () => setIsOverflow(current.scrollHeight > current.clientHeight);

		if (current) {
			if ('ResizeObserver' in window) {
				new ResizeObserver(checkForOverflow).observe(current);
			}
			checkForOverflow();
		}
	}, [ref]);

	return isOverflow;
};

export function useKeyPress(targetKey: string, callback: (e: KeyboardEvent) => void) {
	const [keyPressed, setKeyPressed] = useState(false);

	function handler(e: KeyboardEvent) {
		if (e.key === targetKey) {
			setKeyPressed(true);
			callback(e);
		}
	}

	useEffect(() => {
		window.addEventListener('keydown', handler);
		return () => {
			window.removeEventListener('keydown', handler);
		};
	});

	return keyPressed;
}

export const useWidthOverflow = (elementRef) => {
	const [elementOverflow, setElementOverflow] = useState(false);

	useEffect(() => {
		if (!elementRef.current) {
			return;
		}
		const scrollWidth = elementRef.current.scrollWidth;
		const clientWidth = elementRef.current.clientWidth;
		setElementOverflow(clientWidth < scrollWidth);
	}, [elementRef.current?.scrollWidth, elementRef.current?.clientWidth, setElementOverflow]);

	return elementOverflow;
};
