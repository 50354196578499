import { omit } from 'lodash';
import { streamService } from '../../index';
import { Dispatch } from 'redux';
import { IDashboardData } from '../../interfaces/IDashboardData';
import { IProfession, IMergedFloorView } from '../../interfaces';
import { resetDashboard, updateDashboard } from '../slices/dashboard.slice';
import { IActiveManager, updateActiveManagers } from '../slices/managers.slice';
import { resetFloorsView, updateFloorsView } from '../slices/floorsView.slice';
import { updateActiveProfessions } from '../slices/professions.slice';
import { updateIsLoadingDashboard } from './dashboard.thunks';
import { addActiveUtilities } from '@store/slices/utilities.slice';

export const stopGettingFloorsAndDashboard = () => async (dispatch: Dispatch) => {
	streamService.closeStreamIncludes(`/location/merged/floors/stream`);
	streamService.closeStreamIncludes(`/location/merged/dashboardData/stream`);
	dispatch(resetFloorsView());
	dispatch(resetDashboard());
};

export const stopGettingDashboardData = () => async (dispatch: Dispatch) => {
	streamService.closeStreamIncludes(`/location/merged/dashboardData/stream`);
	dispatch(resetDashboard());
};

const filterUtilitiesWithoutLinkedUtility = (mergedFloorViews: IMergedFloorView[]): IMergedFloorView[] => {
	return mergedFloorViews.map((mergedFloorView: IMergedFloorView) => {
		mergedFloorView.utilities = mergedFloorView.utilities?.filter((utility) => utility.linkedUtility);
		mergedFloorView.areas = mergedFloorView.areas?.map((area) => {
			area.utilities = area.utilities?.filter((utility) => utility.linkedUtility);
			return area;
		});
		return mergedFloorView;
	});
};

export const getFloorsData = (projectId: string) => async (dispatch: Dispatch) => {
	streamService.openStream(`/location/merged/floors/stream?projectId=${projectId}`, (message) => {
		const { mergedFloors, shouldReplace }: { [key: string]: IMergedFloorView[]; shouldReplace } = JSON.parse(
			message.data
		);
		const fixedMergedFloors: IMergedFloorView[] = filterUtilitiesWithoutLinkedUtility(mergedFloors);
		dispatch(updateFloorsView({ floors: fixedMergedFloors, shouldReplace }));
	});
};

export const stopGettingFloorsData = () => async (dispatch: Dispatch) => {
	streamService.closeStreamIncludes(`/location/merged/floors/stream`);
	dispatch(resetFloorsView());
};

export const getDashboardData = (projectId: string) => async (dispatch: Dispatch) => {
	updateIsLoadingDashboard(true)(dispatch);
	streamService.openStream(
		`/location/merged/dashboardData/stream?projectId=${projectId}`,
		(message) => {
			const { dashboardData }: { [key: string]: IDashboardDataFromServer } = JSON.parse(message.data);
			updateIsLoadingDashboard(false)(dispatch);
			dispatch(
				updateDashboard({
					dashboard: omit(dashboardData, ['managers', 'professionsWithActiveTags']),
				})
			);
			dispatch(updateActiveManagers({ activeManagers: dashboardData.managers }));
			dispatch(
				updateActiveProfessions({
					activeProfessions: dashboardData.professionsWithActiveTags,
				})
			);
			dispatch(addActiveUtilities({ utilities: dashboardData.utilities }));
		},
		() => {
			updateIsLoadingDashboard(false)(dispatch);
		}
	);
};

interface IDashboardDataFromServer extends IDashboardData {
	managers: IActiveManager[];
	professionsWithActiveTags: IProfession[];
}
