export enum Languages {
	ENGLISH_US = 'en-us',
	HEBREW = 'he-il',
	JAPANESE = 'jpn',
}

export interface ILanguageAndDisplayName {
	language: Languages;
	displayName: string;
}
