import { IFloorData } from '@interfaces/IFloorData';
import { ISvgElementPoint } from '@interfaces/ISvgElementPoint';
import { HoistIcon } from '@src/Components/Hoists/HoistIcon';
import { ScaffoldIcon } from '@src/Components/Scaffolds/ScaffoldIcon';
import { getBuildingSvgWidth } from '@utils/svgHelpers';
import React from 'react';
import classes from './styles.module.scss';

interface IFloorElevatorScaffoldProps {
	floorId: string;
	floorElevators?: IFloorData[];
	floorScaffolds?: IFloorData[];
	floorElevatorSvgElement?: ISvgElementPoint;
}

export const FloorElevatorScaffold = (props: IFloorElevatorScaffoldProps) => {
	//flips orientation to the middle of the screen if needed
	const shouldFlipLeftRightPosition = (
		svgWidth: number,
		leftRightPosition: {
			left?: number;
			right?: number;
		}
	) => {
		const aligmentValue: number | undefined = leftRightPosition.left || leftRightPosition.right;
		if (!aligmentValue) {
			return false;
		}
		return aligmentValue > svgWidth / 2;
	};

	const flipLeftRightPosition = (leftRightPosition: { left?: number; right?: number }) => {
		if (leftRightPosition.left) {
			return {
				right: 'calc(100% - ' + leftRightPosition.left + 'px)',
			};
		}
		if (leftRightPosition.right) {
			return {
				left: 'calc(100% - ' + leftRightPosition.right + 'px)',
			};
		}
		return {};
	};

	if (!props.floorElevatorSvgElement || (!props.floorElevators && !props.floorScaffolds)) {
		return <></>;
	}

	const buildingSvgWidth: number = getBuildingSvgWidth();

	const leftRightPosition = shouldFlipLeftRightPosition(buildingSvgWidth, props.floorElevatorSvgElement)
		? flipLeftRightPosition(props.floorElevatorSvgElement)
		: props.floorElevatorSvgElement;

	return (
		<div
			className={classes.floorElevatorScaffoldContainer}
			style={{
				top: props.floorElevatorSvgElement.top,
				...leftRightPosition,
			}}
		>
			{props.floorElevators && <HoistIcon hoists={props.floorElevators} />}
			{props.floorScaffolds && <ScaffoldIcon scaffolds={props.floorScaffolds} floorId={props.floorId} />}
		</div>
	);
};
