import React from 'react';
import classes from './styles.module.scss';
import { useSharedServices } from '../../../hooks/sharedServices.context';
import { TrusstorCheckbox } from '../../TrusstorCheckbox/TrusstorCheckbox';
import { IconColor, IconSize, TrusstorIconShared } from '../../TrusstorIconShared/TrusstorIconShared';
import { IconNames } from '../../TrusstorIconShared/IconNames.enum';

interface IssuePrivateSectionProps {
	checkboxController?: {
		isPrivate: boolean;
		setIsPrivate: (value: boolean) => void;
	};
	textMode?: 'complete' | 'default' | 'minimal';
}
export const IssuePrivateSection = (props: IssuePrivateSectionProps) => {
	const { translationService } = useSharedServices();
	const { textMode = 'default' } = props;

	const getText = (mode: string) => {
		switch (mode) {
			case 'complete':
				return (
					<div>
						<span className={classes.privatePrimaryText}>
							{translationService.get('issuePrivatePrimaryLabel')}
						</span>
						<span className={classes.privateSecondaryText}>
							{' '}
							({translationService.get('issuePrivateSecondLabel')})
						</span>
					</div>
				);
			case 'default':
				return <div className={classes.privateText}>{translationService.get('issuePrivateDisplayLabel')}</div>;
			case 'minimal':
				return null;
			default:
				return '';
		}
	};
	return (
		<div className={classes.privateSection}>
			{props.checkboxController && (
				<TrusstorCheckbox
					isMobile
					checked={props.checkboxController.isPrivate}
					onChange={() => {
						props.checkboxController?.setIsPrivate(!props.checkboxController.isPrivate);
					}}
				/>
			)}
			<div className={classes.privateLabel}>
				<TrusstorIconShared
					iconName={IconNames.lock}
					color={props.checkboxController ? IconColor.Grey500 : IconColor.Grey600}
					size={IconSize.SMALL}
				/>
				{getText(textMode)}
			</div>
		</div>
	);
};
