import { entitiesProperties, HttpStatusCode, othersTradeGroup } from '../../constants';
import { filterArrayByAnotherArray } from '../../utils/array.util';
import { IProfession, IProfessionsReducerState, IProfessionFilter } from '../../interfaces';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HTTPRequestStatuses } from '@shared/interfaces/HTTPRequestStatuses.enum';
import { createProfession, getWorkingProfessions } from '../thunks/professions.thunks';
import { compact } from 'lodash';
import { guestTradeId, trusstorTradeId, unassignedTradeId } from '@shared/constants/professions.constants';
import { IRootState } from './index';

const initialState: IProfessionsReducerState = {
	unassignedProfession: undefined,
	guestProfession: undefined,
	activeProfessions: [],
	professions: [],
	selectedProfessions: [],
	filteredProfessions: [],
	filterList: [],
	visibleProfessions: [],
	workingProfessionsRequestStatus: HTTPRequestStatuses.idle,
	professionCreateStatus: { status: HTTPRequestStatuses.idle, code: undefined },
	workingProfessionsRequestCount: 0,
};

const professionsSlice = createSlice({
	name: 'professions',
	initialState,
	reducers: {
		resetProfessionCreateStatus: (state: IProfessionsReducerState) => {
			state.professionCreateStatus = initialState.professionCreateStatus;
		},
		resetWorkingProfessionsRequestCount: (state: IProfessionsReducerState) => {
			state.workingProfessionsRequestCount = initialState.workingProfessionsRequestCount;
		},
		updateAdminPanelProfessions: (
			state: IProfessionsReducerState,
			action: PayloadAction<{ isAdmin: boolean; professions: IProfession[] }>
		) => {
			const adminPanelProfessions: IProfession[] = action.payload.professions.filter((profession) => {
				if (!action.payload.isAdmin && profession.tradeId === trusstorTradeId) {
					return false;
				}
				return profession.tradeId !== unassignedTradeId;
			});
			const filteredProfessions: IProfession[] = filterArrayByAnotherArray(
				adminPanelProfessions,
				state.filterList,
				entitiesProperties.tradeGroup
			);
			const selectedProfessions: IProfession[] = filterArrayByAnotherArray(
				state.selectedProfessions,
				filteredProfessions,
				entitiesProperties._id
			);
			const visibleProfessions: IProfession[] = filterArrayByAnotherArray(
				filteredProfessions,
				selectedProfessions,
				entitiesProperties._id
			);
			const guestProfession: IProfession | undefined = action.payload.professions.find(
				(profession) => profession.tradeId === guestTradeId
			);
			const unassignedProfession: IProfession | undefined = action.payload.professions.find(
				(profession) => profession.tradeId === unassignedTradeId
			);
			state.unassignedProfession = unassignedProfession;
			state.guestProfession = guestProfession;
			state.professions = adminPanelProfessions;
			state.filteredProfessions = filteredProfessions;
			state.selectedProfessions = selectedProfessions;
			state.visibleProfessions = visibleProfessions;
		},
		updateActiveProfessions: (
			state: IProfessionsReducerState,
			action: PayloadAction<{ activeProfessions: IProfession[] }>
		) => {
			const filteredProfessions: IProfession[] = filterArrayByAnotherArray(
				action.payload.activeProfessions,
				state.filterList,
				entitiesProperties.tradeGroup
			);
			const selectedProfessions: IProfession[] = filterArrayByAnotherArray(
				state.selectedProfessions,
				filteredProfessions,
				entitiesProperties._id
			);
			const visibleProfessions: IProfession[] = filterArrayByAnotherArray(
				filteredProfessions,
				selectedProfessions,
				entitiesProperties._id
			);
			state.activeProfessions = action.payload.activeProfessions;
			state.filteredProfessions = filteredProfessions;
			state.selectedProfessions = selectedProfessions;
			state.visibleProfessions = visibleProfessions;
		},
		updateProfessions: (state: IProfessionsReducerState, action: PayloadAction<{ professions: IProfession[] }>) => {
			const filteredProfessions: IProfession[] = filterArrayByAnotherArray(
				action.payload.professions,
				state.filterList,
				entitiesProperties.tradeGroup
			);
			const selectedProfessions: IProfession[] = filterArrayByAnotherArray(
				state.selectedProfessions,
				filteredProfessions,
				entitiesProperties._id
			);
			const visibleProfessions: IProfession[] = filterArrayByAnotherArray(
				filteredProfessions,
				selectedProfessions,
				entitiesProperties._id
			);
			state.professions = action.payload.professions;
			state.filteredProfessions = filteredProfessions;
			state.selectedProfessions = selectedProfessions;
			state.visibleProfessions = visibleProfessions;
		},
		updateSingleProfession: (
			state: IProfessionsReducerState,
			action: PayloadAction<{ updatedProfession: IProfession }>
		) => {
			state.professions = state.professions.map((profession: IProfession) =>
				profession._id === action.payload.updatedProfession._id ? action.payload.updatedProfession : profession
			);
		},
		removeProfession: (state: IProfessionsReducerState, action: PayloadAction<{ deletedProfessionId: string }>) => {
			state.professions = state.professions.filter(
				(profession) => profession._id !== action.payload.deletedProfessionId
			);
		},
		removeAllProfessions: (state: IProfessionsReducerState) => {
			state.activeProfessions = [];
			state.professions = [];
			state.filterList = [];
			state.selectedProfessions = [];
			state.filteredProfessions = [];
			state.visibleProfessions = [];
		},
		addOneSelectedProfession: (
			state: IProfessionsReducerState,
			action: PayloadAction<{ selectedProfession: IProfession }>
		) => {
			const selectedProfessionsAfterAdd: IProfession[] = compact(
				state.selectedProfessions.concat(action.payload.selectedProfession)
			);
			const visibleProfessions: IProfession[] = filterArrayByAnotherArray(
				state.filteredProfessions,
				selectedProfessionsAfterAdd,
				entitiesProperties._id
			);
			state.selectedProfessions = selectedProfessionsAfterAdd;
			state.visibleProfessions = visibleProfessions;
		},
		removeOneSelectedProfession: (
			state: IProfessionsReducerState,
			action: PayloadAction<{ removedProfession: IProfession }>
		) => {
			const selectedProfessionsAfterRemove: IProfession[] = state.selectedProfessions.filter(
				(selectedProfession) => {
					return action.payload.removedProfession._id !== selectedProfession._id;
				}
			);
			const visibleProfessions: IProfession[] = filterArrayByAnotherArray(
				state.filteredProfessions,
				selectedProfessionsAfterRemove,
				entitiesProperties._id
			);

			state.selectedProfessions = selectedProfessionsAfterRemove;
			state.visibleProfessions = visibleProfessions;
		},
		resetSelectedProfessions: (state: IProfessionsReducerState) => {
			state.selectedProfessions = [];
			state.filterList = [];
			state.visibleProfessions = state.professions;
		},
		setProfessionsFilter: (
			state: IProfessionsReducerState,
			action: PayloadAction<{ filterList: IProfessionFilter[] }>
		) => {
			const filteredProfessions: IProfession[] = filterArrayByAnotherArray(
				state.professions,
				action.payload.filterList,
				entitiesProperties.tradeGroup
			);
			const selectedProfessions: IProfession[] = filterArrayByAnotherArray(
				state.selectedProfessions,
				filteredProfessions,
				entitiesProperties._id
			);
			const visibleProfessions: IProfession[] = filterArrayByAnotherArray(
				filteredProfessions,
				selectedProfessions,
				entitiesProperties._id
			);

			state.filterList = action.payload.filterList;
			state.filteredProfessions = filteredProfessions;
			state.selectedProfessions = selectedProfessions;
			state.visibleProfessions = visibleProfessions;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getWorkingProfessions.pending, (state: IProfessionsReducerState) => {
				state.workingProfessionsRequestStatus = HTTPRequestStatuses.pending;
			})
			.addCase(
				getWorkingProfessions.fulfilled,
				(state: IProfessionsReducerState, action: PayloadAction<{ professions: IProfession[] }>) => {
					state.workingProfessionsRequestCount = state.workingProfessionsRequestCount + 1;
					state.workingProfessionsRequestStatus = HTTPRequestStatuses.success;
					const filteredProfessions: IProfession[] = filterArrayByAnotherArray(
						action.payload.professions,
						state.filterList,
						entitiesProperties.tradeGroup
					);
					const selectedProfessions: IProfession[] = filterArrayByAnotherArray(
						state.selectedProfessions,
						state.filterList,
						entitiesProperties._id
					);
					const visibleProfessions: IProfession[] = filterArrayByAnotherArray(
						filteredProfessions,
						selectedProfessions,
						entitiesProperties._id
					);
					state.professions = action.payload.professions;
					state.filteredProfessions = filteredProfessions;
					state.selectedProfessions = selectedProfessions;
					state.visibleProfessions = visibleProfessions;
				}
			)
			.addCase(getWorkingProfessions.rejected, (state: IProfessionsReducerState) => {
				state.workingProfessionsRequestStatus = HTTPRequestStatuses.failed;
			})
			.addCase(createProfession.rejected, (state: IProfessionsReducerState, action: any) => {
				state.professionCreateStatus = {
					status: HTTPRequestStatuses.failed,
					code: action.payload,
				};
			})
			.addCase(createProfession.fulfilled, (state: IProfessionsReducerState, action: any) => {
				state.professions = state.professions.concat(action.payload.profession);
				state.professionCreateStatus = {
					status: HTTPRequestStatuses.success,
					code: HttpStatusCode.okSuccess,
				};
			});
	},
});

export const selectProfessions = (state: IRootState) => state.professionsReducer.professions;
export const selectWorkingProfessions = (state: IRootState) =>
	state.professionsReducer.professions.filter((profession) => profession.tradeGroup !== othersTradeGroup);

export const {
	resetWorkingProfessionsRequestCount,
	resetProfessionCreateStatus,
	setProfessionsFilter,
	resetSelectedProfessions,
	removeAllProfessions,
	removeProfession,
	addOneSelectedProfession,
	removeOneSelectedProfession,
	updateAdminPanelProfessions,
	updateProfessions,
	updateSingleProfession,
	updateActiveProfessions,
} = professionsSlice.actions;
export default professionsSlice.reducer;
