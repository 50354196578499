import { IProject, IProjectReducerState, IWorkDayHours } from '@shared/interfaces/IProject';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRootState } from './index';
import { ProjectService } from '@shared/services/project.service';

const chosenProject: IProject | undefined = new ProjectService().getChosenProject();

const initialState: IProjectReducerState = {
	workingProject: chosenProject,
	userProjects: [],
};

const projectSlice = createSlice({
	name: 'project',
	initialState,
	reducers: {
		setWorkingProjectSuccess: (
			state: IProjectReducerState,
			action: PayloadAction<{ workingProject: IProject }>
		) => {
			state.workingProject = action.payload.workingProject;
		},
		updateUserProjects: (state: IProjectReducerState, action: PayloadAction<{ userProjects: IProject[] }>) => {
			state.userProjects = action.payload.userProjects;
		},
	},
});

export const selectWorkingProject = (state: IRootState): IProject | undefined => state.projectReducer.workingProject;
export const selectProjectId = (state: IRootState): string | undefined =>
	state.projectReducer.workingProject?.projectId;
export const selectOrganizationId = (state: IRootState): string | undefined =>
	state.projectReducer.workingProject?.organizationId;
export const selectTimezone = (state: IRootState): string | undefined => state.projectReducer.workingProject?.tz;
export const selectLanguage = (state: IRootState): string | undefined => state.projectReducer.workingProject?.language;
export const selectWorkDayHours = (state: IRootState): IWorkDayHours[] | undefined =>
	state.projectReducer.workingProject?.workDayHours;

export const { setWorkingProjectSuccess, updateUserProjects } = projectSlice.actions;
export default projectSlice.reducer;
