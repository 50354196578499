import React from 'react';
import { FilterCard, IFilterCardProps } from '../FilterCard/FilterCard';
import classes from './styles.module.scss';

interface IFloorFilterCardProps extends IFilterCardProps {
	floorNick: string;
}

export const FloorFilterCard = (props: IFloorFilterCardProps) => {
	return <FilterCard {...props} text={props.floorNick} textClass={classes.floorText} />;
};
