import React from 'react';
import classes from './styles.module.scss';
import { ISequenceItemByLocationProgressReport } from '@shared/interfaces/ISequenceItemByLocationProgressReport';
import { FloorGraphRow } from '@src/Components/SequenceProgressAnalysis/FloorProgress/FloorProgressGraph/FloorGraphRow';
import { SORT_ORDER } from '@shared/constants/constants';
import { useSequenceItemsByProjectQuery } from '@src/hooks/queries/sequenceItem.query';
import { selectProjectId } from '@store/slices/project.slice';
import { useSelector } from 'react-redux';
import { sortFloors } from '@shared/utils/sort.utils';

interface IFloorProps {
	sequenceItemProgressByLocationReport: ISequenceItemByLocationProgressReport[];
}

export const FloorProgressGraph = (props: IFloorProps) => {
	const projectId: string = useSelector(selectProjectId)!;
	const { sequenceItems } = useSequenceItemsByProjectQuery(projectId, true);
	const sortedSummaries: ISequenceItemByLocationProgressReport[] = sortFloors(
		props.sequenceItemProgressByLocationReport,
		SORT_ORDER.ASCENDING
	);
	return (
		<div className={classes.FloorProgressGraph_container} data-testid="FloorProgressGraph">
			{sortedSummaries.map((summary) => {
				return <FloorGraphRow summary={summary} sequenceItemsOfProject={sequenceItems} />;
			})}
		</div>
	);
};
