import { ICreateIssue } from '@shared/interfaces/IIssue';
import moment from 'moment-timezone';
import { IProfession, IUser } from '@interfaces/index';
import { TrusstorTextInput } from '@shared/components/Inputs/TrusstorTextInput/TrusstorTextInput';
import { IIssueLocation, IssuePriority, IssueStatus } from '@shared/interfaces/IIssueShared';
import { translationService } from '@src/servicesInitializers';
import { selectProjectId } from '@store/slices/project.slice';
import { removeUndefinedValuesFromObject } from '@utils/generalUtils';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classes from './styles.module.scss';
import { ITrusstorButtonType, TrusstorButton } from '@shared/components/buttons/TrusstorButton/TrusstorButton';
import { CriticalSection } from '@shared/components/Issue/CriticalSection/CriticalSection';
import { ImageUploadInput } from '@shared/components/Inputs/ImageUploadInput/ImageUploadInput';
import { errorSnackbar } from '@utils/snackbar.utils';
import { isValidFileUploaded } from '@shared/utils/validation.utils';
import { IConfigArea } from '@shared/interfaces/IConfigArea';
import { DateSelectorTrusstorInput } from '@src/Components/DateSelector/DateSelectorTrusstorInput/DateSelectorTrusstorInput';
import { useUsersQuery } from '@src/hooks/queries/users.queries.hooks';
import { SingleDropdown } from '@src/Components/Dropdowns/DesignSystem/SingleDropdown/SingleDropdown';
import { SingleProfessionGroupedDropdown } from '@src/Components/Dropdowns/Profession/SingleProfessionGroupedDropdown/SingleProfessionGroupedDropdown';
import { IssueLocationSelectorPopperMenu } from '@src/Components/IssueLocationSelectorPopperMenu/IssueLocationSelectorPopperMenu';
import { baseLocationToIssueLocation } from '@shared/components/IssueLocationsSelector/LocationListSelector/locationListSelector.utils';
import { selectLoggedUserDetails } from '@store/slices/login.slice';
import { IssuePrivateSection } from '@shared/components/Issue/IssuePrivateSection/IssuePrivateSection';
import { filterUsersByPermittedProfessions } from '@shared/utils/users.utils';

interface IIssueCreateCardProps {
	onIssueUpdate?: (issue: ICreateIssue | undefined, images: File[]) => void;
	onIssueCreate?: (issue: ICreateIssue, images: File[]) => void;
	onCancel?: () => void;
	hideProfession?: boolean;
	hideLocations?: boolean;
	hideAssignee?: boolean;
	hidePrivateSection?: boolean;
	defaultAreas?: IConfigArea[];
	defaultProfession?: IProfession;
	isDialog?: boolean;
}

export const IssueCreateCard = (props: IIssueCreateCardProps) => {
	const getInitialIssuesLocation = (): IIssueLocation[] | undefined => {
		if (props.defaultAreas?.length) {
			return props.defaultAreas.map((area) => baseLocationToIssueLocation(area));
		}
	};

	const projectId: string = useSelector(selectProjectId)!;
	const loggedUser: IUser = useSelector(selectLoggedUserDetails)!;
	const [selectedImages, setSelectedImages] = useState<File[]>([]);
	const [priority, setPriority] = useState<IssuePriority>(IssuePriority.REGULAR);
	const [description, setDescription] = useState<string | undefined>();
	const [selectedProfession, setSelectedProfession] = useState<IProfession | undefined>();
	const [selectedAssignee, setSelectedAssignee] = useState<IUser | undefined>(loggedUser);
	const [selectedLocations, setSelectedLocations] = useState<IIssueLocation[] | undefined>(
		getInitialIssuesLocation()
	);
	const [dueDate, setDueDate] = useState<Date | null>(null);
	const [isPrivate, setIsPrivate] = useState<boolean>(false);
	const [issue, setIssue] = useState<ICreateIssue | undefined>();
	const [usersOptions, setUsersOptions] = useState<IUser[]>([]);
	const { users }: { users: IUser[] } = useUsersQuery(projectId);
	const tz: string = useSelector(selectProjectId)!;
	const dispatch = useDispatch();

	useEffect(() => {
		const filteredUsers: IUser[] = selectedProfession
			? filterUsersByPermittedProfessions(users, [selectedProfession?._id])
			: users;
		setUsersOptions(filteredUsers);

		const isSelectedAssigneePermitted: boolean =
			!selectedAssignee?.permissions.permittedProfessionsIds?.length ||
			selectedAssignee?.permissions.permittedProfessionsIds?.some((id) => id === selectedProfession?._id);
		if (selectedProfession && !isSelectedAssigneePermitted) {
			setSelectedAssignee(undefined);
		}
	}, [users, selectedProfession, selectedAssignee]);

	useEffect(() => {
		if (isPrivate) {
			setSelectedAssignee(loggedUser);
		}
	}, [isPrivate]);

	const changePriority = () => {
		setPriority((prev) => (prev === IssuePriority.REGULAR ? IssuePriority.CRITICAL : IssuePriority.REGULAR));
	};

	const titlePlaceholder: string = `${translationService.get('issueDescription')}*`;

	const handleProfessionChange = (profession?: IProfession | null) => {
		if (!profession) {
			setSelectedProfession(undefined);
			return;
		}
		setSelectedProfession(profession);
	};

	const handleAssigneeChange = (assignee?: IUser | null) => {
		setSelectedAssignee(assignee || undefined);
	};

	const handleLocationsChange = (locations: IIssueLocation[]) => {
		setSelectedLocations(locations);
	};

	const handleSelectDueDate = (date: Date) => {
		setDueDate(moment.tz(date, tz).toDate());
	};

	const handleSetIsPrivate = (isPrivate: boolean) => {
		setIsPrivate(isPrivate);
	};

	const saveImages = async (images: FileList) => {
		const sizeLimit: number = 1e7; // 10MB
		if (Array.from(images).some((image) => image.size > sizeLimit)) {
			errorSnackbar(dispatch, translationService.get('imageAboveTheSizeLimitError'));
			return;
		}
		if (Array.from(images).some((image) => !isValidFileUploaded(image))) {
			errorSnackbar(dispatch, translationService.get('imageInvalidFormatError'));
			return;
		}
		setSelectedImages((prev) => [...prev, ...images]);
	};

	const removeImage = (index: number) => {
		setSelectedImages((prev) => prev.filter((_, i) => i !== index));
	};

	useEffect(() => {
		props.onIssueUpdate?.(issue, selectedImages);
	}, [issue, selectedImages]);

	useEffect(() => {
		if (description === undefined) {
			return;
		}
		if (description === '') {
			setIssue(undefined);
			return;
		}
		const issue: ICreateIssue = {
			description,
			profession: selectedProfession,
			status: IssueStatus.PLANNED,
			priority,
			projectId,
			...(selectedLocations?.length && { locations: selectedLocations }),
			...(selectedAssignee && {
				assignee: {
					username: selectedAssignee?.username,
				},
			}),
			...(dueDate && { dueDate }),
			isPrivate,
		};
		setIssue(removeUndefinedValuesFromObject(issue));
	}, [description, selectedProfession, selectedAssignee, selectedLocations, priority, dueDate, isPrivate]);

	return (
		<div className={classes.createCardContainer}>
			<CriticalSection
				isLimitedUser={false}
				issuePriority={priority}
				translationService={translationService}
				onClick={changePriority}
			/>
			<div className={classes.details}>
				<TrusstorTextInput
					changeFunc={(newVal) => {
						setDescription(newVal);
					}}
					placeholder={titlePlaceholder}
					rootClassName={classes.inputContainer}
					textClassName={classes.titleInputText}
					autoFocus
				/>
			</div>
			<div className={classes.metadataSection}>
				{!props.hideProfession && (
					<SingleProfessionGroupedDropdown
						useWorkingProfessions
						onChange={handleProfessionChange}
						defaultSelectedProfession={props.defaultProfession}
						disabled={!!props.defaultProfession}
					/>
				)}
				{!props.hideLocations && (
					<IssueLocationSelectorPopperMenu
						handleLocationsChange={handleLocationsChange}
						initialSelectedLocations={selectedLocations}
						selectedLocationsAmount={selectedLocations?.length}
						isDialog={props.isDialog}
					/>
				)}
				{!props.hideAssignee && (
					<SingleDropdown
						disabled={isPrivate}
						placeholder={translationService.get('selectAssignee')}
						options={usersOptions}
						onChange={handleAssigneeChange}
						getDisplayOption={(option: IUser) => option.name}
						value={selectedAssignee}
					/>
				)}
				<DateSelectorTrusstorInput
					handleTimeChange={handleSelectDueDate}
					selectedDate={dueDate}
					minDate={new Date()}
					inputPlaceHolder={translationService.get('dueDate')}
				/>
				<ImageUploadInput removeImage={removeImage} saveImages={saveImages} selectedImages={selectedImages} />
				{!props.hidePrivateSection && (
					<IssuePrivateSection
						textMode={'complete'}
						checkboxController={{ isPrivate, setIsPrivate: handleSetIsPrivate }}
					/>
				)}
			</div>
			{props.onIssueCreate || props.onCancel ? (
				<div className={classes.buttonsContainer}>
					{props.onCancel ? (
						<TrusstorButton
							text={translationService.get('cancel')}
							handleClick={props.onCancel}
							buttonType={ITrusstorButtonType.GHOST}
						/>
					) : null}
					{props.onIssueCreate ? (
						<TrusstorButton
							text={translationService.get('create')}
							handleClick={() => props.onIssueCreate!(issue!, selectedImages)}
							disabled={!issue}
						/>
					) : null}
				</div>
			) : null}
		</div>
	);
};
