import React, { useContext, useMemo, useState } from 'react';
import classes from './styles.module.scss';
import { GeneralDialog } from '@src/Components/GeneralDialog/GeneralDialog';
import { requestService, translationService } from '@src/servicesInitializers';
import { StarButton } from '@shared/components/buttons/StarButton/StarButton';
import { errorSnackbar } from '@utils/snackbar.utils';
import { useDispatch, useSelector } from 'react-redux';
import { TrusstorTextInput } from '@shared/components/Inputs/TrusstorTextInput/TrusstorTextInput';
import { SingleProfessionGroupedDropdown } from '@src/Components/Dropdowns/Profession/SingleProfessionGroupedDropdown/SingleProfessionGroupedDropdown';
import { IProfession } from '@shared/interfaces/IProfession';
import { GroupedMultipleDropdown } from '@src/Components/Dropdowns/DesignSystem/GroupedMultipleDropdown/GroupedMultipleDropdown';
import { IConfigArea } from '@shared/interfaces/IConfigArea';
import { sortByAreas } from '@shared/utils/sort.utils';
import { SORT_ORDER } from '@shared/constants/constants';
import { selectProjectId } from '@store/slices/project.slice';
import { SequenceTypeEnum } from '@shared/interfaces/SequenceType.enum';
import { ISequenceItemWithoutId } from '@interfaces/ISequenceItemWithoutId';
import { ISequenceItem } from '@interfaces/ISequenceItem';
import { RadioButton } from '@shared/components/RadioButton/RadioButton';
import { useProjectAreasBySequenceType } from '@src/hooks/queries/sequenceItem.query';
import { WorkplanContext } from '@src/Components/Pages/Workplan/WorkPlan';

interface ICreateSequenceItemDialogProps {
	show: boolean;
	close: () => void;
	nextOrderIndex: number;
	sequenceId: string;
	sequenceType: SequenceTypeEnum;
	onCreateSequenceItem: (newSequenceItem: ISequenceItem) => void;
}

export const CreateSequenceItemDialog = (props: ICreateSequenceItemDialogProps) => {
	const dispatch = useDispatch();
	const projectId: string = useSelector(selectProjectId)!;
	const { areas } = useProjectAreasBySequenceType(projectId, props.sequenceType);
	const [isStarred, setIsStarred] = useState<boolean>(false);
	const [description, setDescription] = useState<string>('');
	const [selectedProfession, setSelectedProfession] = useState<IProfession | undefined>();
	const [isAreaRange, setIsAreaRange] = useState<boolean>(false);
	const [selectedAreas, setSelectedAreas] = useState<IConfigArea[] | undefined>(undefined);
	const [showDescriptionValidationError, setShowDescriptionValidationError] = useState<boolean>(false);
	const { sequenceItems } = useContext(WorkplanContext)!;

	const handleClose = () => {
		setIsStarred(false);
		setDescription('');
		setSelectedProfession(undefined);
		setIsAreaRange(false);
		setSelectedAreas(undefined);
		props.close();
	};

	const onCreateSequenceItem = async () => {
		if (!selectedProfession) {
			return;
		}

		const isDescriptionAlreadyExists: boolean = sequenceItems.some(
			(sequenceItem) => sequenceItem.description === description
		);

		if (isDescriptionAlreadyExists) {
			setShowDescriptionValidationError(true);
			return;
		}

		const newSequenceItem: ISequenceItemWithoutId = {
			projectId,
			sequenceId: props.sequenceId,
			sequenceType: props.sequenceType,
			description,
			orderIndex: props.nextOrderIndex,
			profession: selectedProfession,
			isStarred,
		};
		const areaIds: string[] = !isAreaRange ? [] : selectedAreas?.map((area) => area.areaId) || [];
		const newSequenceItemWithId: ISequenceItem = await requestService.post('/activities/sequenceItems', {
			body: {
				sequenceItem: newSequenceItem,
				...(areaIds.length ? { areaIds } : {}),
			},
		});
		props.onCreateSequenceItem(newSequenceItemWithId);
		handleClose();
	};

	const onStarClickError = () => {
		errorSnackbar(dispatch, translationService.get('genericError'));
	};

	const handleStarClick = async () => {
		setIsStarred((prev) => !prev);
	};

	const handleProfessionChange = (profession?: IProfession | null) => {
		if (!profession) {
			setSelectedProfession(undefined);
			return;
		}
		setSelectedProfession(profession);
	};

	const handleIsAreaRangeChange = () => {
		setIsAreaRange((prev) => !prev);
	};

	const sortedAreas: IConfigArea[] = useMemo(() => {
		return sortByAreas(areas, SORT_ORDER.DESCENDING);
	}, [areas]);

	const handleDescriptionChange = (description: string) => {
		setDescription(description);
		setShowDescriptionValidationError(false);
	};
	return (
		<GeneralDialog
			show={props.show}
			close={handleClose}
			mainButton={{
				text: translationService.get('create'),
				click: onCreateSequenceItem,
				disabled: description === '' || !selectedProfession || (isAreaRange && !selectedAreas?.length),
			}}
			secondaryButton={{
				text: translationService.get('cancel'),
				click: handleClose,
			}}
		>
			<div className={classes.sequenceItemContainer}>
				<div>
					<StarButton
						isStarred={isStarred}
						handleStarClick={handleStarClick}
						onClickError={onStarClickError}
					/>
					<div className={classes.descriptionContainer}>
						<TrusstorTextInput
							changeFunc={handleDescriptionChange}
							placeholder={`${translationService.get('createSequenceItemDialog_descriptionPlaceholder')}*`}
							rootClassName={classes.inputContainer}
							textClassName={classes.titleInputText}
							autoFocus
						/>
						{showDescriptionValidationError && (
							<div className={classes.errorText}>{translationService.get('nameAlreadyExists')}</div>
						)}
					</div>
				</div>
				<SingleProfessionGroupedDropdown useWorkingProfessions onChange={handleProfessionChange} required />
				<div className={classes.areasContainer}>
					<div className={classes.radioButtonContainer}>
						<RadioButton isSelected={!isAreaRange} onClick={handleIsAreaRangeChange} />
						<div>{translationService.get('createSequenceItemDialog_allAreas')}</div>
					</div>
					<div className={classes.radioButtonContainer}>
						<RadioButton isSelected={isAreaRange} onClick={handleIsAreaRangeChange} />
						<div>{translationService.get('createSequenceItemDialog_areaRange')}</div>
					</div>
				</div>
			</div>
			{isAreaRange && (
				<GroupedMultipleDropdown<IConfigArea>
					getDisplayOption={(area: IConfigArea) => area.areaNick}
					groupByFunction={(option: IConfigArea) => {
						return option.floorNick;
					}}
					options={sortedAreas}
					onChange={(options) => {
						setSelectedAreas(options);
					}}
					optionSelectedKey={'areaId'}
					placeholder={translationService.get('selectLocation')}
					totalSelectedInputText={translationService.get('locations')}
					testId={'areasAutocomplete'}
					required
				/>
			)}
		</GeneralDialog>
	);
};
