import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { translationService } from '../../../../../index';
import { resetFilteredFloorsList, setFloorsListFilter } from '@store/slices/floorsList.slice';
import { ExpandableComponent } from '../../../../MainPageSections/ExpandableComponent/ExpandableComponent';
import { APP_FILTER_TYPES } from '@src/constants';
import { IRootState } from '@store/slices';
import { IBaseFloor, IFloor } from '@interfaces/index';
import { FloorFilterCard } from '@shared/components/Filters/FloorFilterCard/FloorFilterCard';

interface IFloorsSelectorSectionProps {
	floors: IBaseFloor[];
	handleResetClick: () => void;
	currentFilter: IFloor[];
	shouldUseReducer?: boolean;
	floorsCountObj?: { [floorId: string]: number };
	defaultOpen?: boolean;
}

const FloorsSelectorSection = ({
	floors,
	handleResetClick,
	currentFilter,
	floorsCountObj,
	defaultOpen,
	shouldUseReducer = false,
}: IFloorsSelectorSectionProps) => {
	const dispatch = useDispatch();

	const floorsFilterList: IFloor[] = useSelector((state: IRootState) => state.floorsListReducer.floorsFilterList);

	const handleResetClickReducer = () => {
		dispatch(resetFilteredFloorsList());
	};

	const addFloor = (floor) => {
		dispatch(setFloorsListFilter({ filterList: [...floorsFilterList, floor] }));
	};

	const removeFloor = (floor) => {
		dispatch(
			setFloorsListFilter({
				filterList: floorsFilterList.filter((f) => f.floorId !== floor.floorId),
			})
		);
	};

	return (
		<ExpandableComponent
			headerText={translationService.get('floors')}
			childrenLength={floors.length}
			shouldHideClearButton={currentFilter.length === 0 && floorsFilterList.length === 0}
			clearFunction={shouldUseReducer ? handleResetClickReducer : handleResetClick}
			clearLength={shouldUseReducer ? floorsFilterList.length : currentFilter.length}
			type={APP_FILTER_TYPES.floorFilter}
			hideEmptyList
			testId={'floorFilterSection'}
			defaultOpen={defaultOpen}
		>
			{floors.map((floor) => {
				const isSelected: boolean = floorsFilterList.some((listFloor) => floor.floorId === listFloor.floorId);
				return (
					<FloorFilterCard
						key={floor.floorId}
						handleClick={isSelected ? () => removeFloor(floor) : () => addFloor(floor)}
						floorNick={floor.floorNick}
						isSelected={isSelected}
						count={floorsCountObj?.[floor.floorId]}
					/>
				);
			})}
		</ExpandableComponent>
	);
};

export { FloorsSelectorSection };
