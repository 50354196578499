import { requestService, translationService } from '../../../index';
import { ActionConfirmationDialog } from '../../WorkplanWidgetComponents/ActionConfirmationDialog/ActionConfirmationDialog';
import React from 'react';
import { IActivityActionsEnum } from '../../../interfaces/IActivityActions.enum';
import { IActivitiesGroup } from '../../../interfaces/IActivitiesGroup';
import { useDispatch } from 'react-redux';
import { errorSnackbar } from '@utils/snackbar.utils';

interface IActivityMarkAsCompleteActionDialogProps {
	show: boolean;
	onClose: () => void;
	onActionComplete?: (updatedActivitiesGroup: IActivitiesGroup) => void;
	activityGroupId: string;
	showNoWorkDetectedDisclaimerText?: boolean;
	disclaimerPoints?: string[];
	sendBiEventOnSubmit: (action: IActivityActionsEnum) => void;
}
export const ActivityMarkAsCompleteActionDialog = (props: IActivityMarkAsCompleteActionDialogProps) => {
	const dispatch = useDispatch();

	const completeAction = async () => {
		try {
			const updatedActivitiesGroup: IActivitiesGroup = await requestService.put(
				`/activities/groups/${props.activityGroupId}/complete`
			);
			props.sendBiEventOnSubmit?.(IActivityActionsEnum.COMPLETE);
			props.onActionComplete?.(updatedActivitiesGroup);
			props.onClose();
		} catch (error: any) {
			if (error.response?.status === 409) {
				props.onClose();
				return;
			}
			errorSnackbar(dispatch, translationService.get('activityUpdateFailed'));
		}
	};

	return (
		<ActionConfirmationDialog
			show={props.show}
			onClose={props.onClose}
			mainButtonAction={completeAction}
			secondaryButtonAction={props.onClose}
			title={translationService.get('markActivityComplete')}
			text={translationService.get('isActivityCompleted')}
		/>
	);
};
