import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { textStyle } from '@shared/utils/text.utils';
import { KeysMatching } from '@shared/utils/types.utils';
import {
	DISTRIBUTION_ACTIVITY_ACTIVITY_PERCENTAGE_CELL_HEIGHT,
	DISTRIBUTION_ACTIVITY_COLUMN_WIDTH,
	DISTRIBUTION_ACTIVITY_HEADER_HEIGHT,
	DISTRIBUTION_ACTIVITY_WORK_HOURS_CELL_HEIGHT,
} from '../../../../../../constants';
import moment from 'moment';
import { translationService } from '../../../../../../index';
import { regularBorder } from '../../../../../../constants/genericStyles.constants';
import { IRoundedDateProfessionAnalysisData } from '../../../../../../interfaces/IRoundedProfessionsAnalysisReport';
import { Languages } from '@shared/constants/languages';
import { dateFormatMappings } from '@shared/services/translation.service';
import { SERVER_DATE_FORMAT } from '@shared/constants/formats.constants';

const ACTIVITY_DISTRIBUTION_DATE_FORMAT_HEB: string = 'DD.MM.YY';
const ACTIVITY_DISTRIBUTION_DATE_FORMAT_US: string = 'MM.DD.YY';

const useStyles = makeStyles((theme) => ({
	columnContainer: {
		'display': 'flex',
		'flexDirection': 'column',
		'minWidth': DISTRIBUTION_ACTIVITY_COLUMN_WIDTH,
		'maxWidth': DISTRIBUTION_ACTIVITY_COLUMN_WIDTH,
		'& > *': {
			border: regularBorder,
		},
	},
	title: textStyle({
		fontClass: 'h2',
		margin: 12,
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		display: 'flex',
	}),
	headerContainer: {
		height: DISTRIBUTION_ACTIVITY_HEADER_HEIGHT,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	workHours: {
		height: DISTRIBUTION_ACTIVITY_WORK_HOURS_CELL_HEIGHT,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	activityPercentage: {
		height: DISTRIBUTION_ACTIVITY_ACTIVITY_PERCENTAGE_CELL_HEIGHT,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontWeight: 500,
	},
	horizontalBarsContainer: {
		width: '90%',
		height: '90%',
	},
	boldNumber: textStyle({
		fontWeight: 500,
		fontSize: 17,
	}),
}));

interface IActivityDistributionColumnProps {
	formattedDate: string;
	dateProfessionAnalysisData?: IRoundedDateProfessionAnalysisData;
	hideTitle?: boolean;
	analysisDataProperty: KeysMatching<IRoundedDateProfessionAnalysisData, number>;
}

const ActivityDistributionColumn = (props: IActivityDistributionColumnProps) => {
	const classes = useStyles();

	const [formattedDateTitle, setFormattedDateTitle] = useState<string>('');
	const [workHours, setWorkHours] = useState<number>(0);

	useEffect(() => {
		if (props.dateProfessionAnalysisData && props.dateProfessionAnalysisData[props.analysisDataProperty]) {
			setWorkHours(props.dateProfessionAnalysisData[props.analysisDataProperty]);
			return;
		}
		setWorkHours(0);
	}, [props.dateProfessionAnalysisData?.totalHoursInActivities, props.dateProfessionAnalysisData?.totalWorkHours]);

	useEffect(() => {
		setFormattedDateTitle(
			moment(props.formattedDate, SERVER_DATE_FORMAT).format(translationService.getDateFormat())
		);
	}, [props.formattedDate]);

	return (
		<div className={classes.columnContainer}>
			{!props.hideTitle && (
				<div className={classes.headerContainer}>
					<p className={classes.title}>{formattedDateTitle}</p>
				</div>
			)}
			<div className={classes.activityPercentage}>{workHours}</div>
		</div>
	);
};

export { ActivityDistributionColumn };
