import React, { useMemo } from 'react';
import { compact, orderBy } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { getChartBackgroundColorByPercentage } from '../../../../../../utils/generalUtils';
import {
	IPeriodicProductivityFloorReport,
	IBaseProductivityFloorReport,
	IDailyProductivityFloorReport,
} from '../../../../../../interfaces/IProductivityReport';
import {
	NUMBER_OF_TOP_FLOOR_TO_SHOW,
	PRODUCTIVITY_COLUMN_WIDTH,
	PRODUCTIVITY_HEADER_HEIGHT,
	PRODUCTIVITY_LOCATION_CELL_HEIGHT,
	PRODUCTIVITY_WORK_HOURS_CELL_HEIGHT_DAILY,
	PRODUCTIVITY_WORK_HOURS_CELL_HEIGHT_PERIOD,
	PRODUCTIVITY_WORKERS_QUANTITY_CELL_HEIGHT,
} from '../../../../../../constants';
import { HorizontalBars } from '../../Charts/HorizontalBars';
import { translationService } from '../../../../../../index';
import { sumByField } from '../../../../../../utils/array.util';
import { IHorizontalBarData } from '../../Charts/interfaces/IHorizontalBarData.interface';
import { SingleVerticalBar } from '../../Charts/SingleVerticalBar';
import { getProfessionTotalWorkDays } from '../../../../../../utils/reports.utils';
import { ProductivityColumnHeader } from './ProductivityColumnHeader/ProductivityColumnHeader';
import { DonutChart } from '../../Charts/DonutChart/DonutChart';
import { IRoundedProductivityProfessionReport } from '../../../../../../interfaces/IRoundedProductivityReport';
import { textStyle } from '@shared/utils/text.utils';
import { regularBorder } from '../../../../../../constants/genericStyles.constants';
import { getProductRoundedHoursFromHours } from '@shared/utils/roundNumbers.utils';
import { getProfessionBackgroundColor } from '@shared/utils/professions.utils';
import { useSelector } from 'react-redux';
import { selectProjectId } from '@store/slices/project.slice';
import { getHoursFromTotalHoursPercentage } from '@src/Components/Pages/Reports/ReportsComponents/Tables/CombinedProductivityTable/utils';

interface IStylesProps {
	isDailyReport?: boolean;
}

const useStyles = makeStyles((theme) => ({
	columnContainer: {
		'display': 'flex',
		'flexDirection': 'column',
		'minWidth': PRODUCTIVITY_COLUMN_WIDTH,
		'maxWidth': PRODUCTIVITY_COLUMN_WIDTH,
		'& > *': {
			border: regularBorder,
		},
	},
	title: textStyle({
		fontClass: 'h2Light',
		margin: 12,
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	}),
	headerContainer: {
		height: PRODUCTIVITY_HEADER_HEIGHT,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		border: regularBorder,
	},
	workerQuantity: {
		height: PRODUCTIVITY_WORKERS_QUANTITY_CELL_HEIGHT,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	workHours: ({ isDailyReport }: IStylesProps) => ({
		height: isDailyReport ? PRODUCTIVITY_WORK_HOURS_CELL_HEIGHT_DAILY : PRODUCTIVITY_WORK_HOURS_CELL_HEIGHT_PERIOD,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	}),
	workHoursText: textStyle({
		fontClass: 'h2Light',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		fontWeight: 500,
	}),
	workResourcesLocation: {
		height: PRODUCTIVITY_LOCATION_CELL_HEIGHT,
		minHeight: PRODUCTIVITY_LOCATION_CELL_HEIGHT,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	horizontalBarsContainer: {
		width: '90%',
		height: '90%',
	},
}));

interface IWorkResourcesColumnProps {
	professionReport: IRoundedProductivityProfessionReport;
	maxWorkers: number;
	workersNumber: number;
	workTimeUnit: number;
	isDailyReport: boolean;
}

const ProductivityColumn = (props: IWorkResourcesColumnProps) => {
	const classes = useStyles({ isDailyReport: props.isDailyReport });
	const projectId: string = useSelector(selectProjectId)!;

	const totalWorkTimeUnit = useMemo(() => {
		if (props.isDailyReport) {
			return sumByField(Object.values(props.professionReport.floors), 'totalHours');
		} else {
			return getProfessionTotalWorkDays(props.professionReport, props.workTimeUnit);
		}
	}, [props.isDailyReport, props.professionReport.floors, props.workTimeUnit]);

	const computedFloorsReport: IBaseProductivityFloorReport[] = useMemo(() => {
		const sortedFloorsReports = orderBy(Object.values(props.professionReport.floors), ['totalHours'], ['desc']);
		const topActivatedFloors = sortedFloorsReports.slice(0, NUMBER_OF_TOP_FLOOR_TO_SHOW);
		const restFloorsReport: IDailyProductivityFloorReport & IPeriodicProductivityFloorReport = {
			floorId: 'other',
			floorNick: translationService.get('other'),
			shortFloorNick: translationService.get('other'),
			tagIds: [],
			uniqueTagIdsPerDay: {},
			totalHours: sumByField(sortedFloorsReports.slice(NUMBER_OF_TOP_FLOOR_TO_SHOW), 'totalHours'),
			totalDays: !props.isDailyReport
				? sumByField(sortedFloorsReports.slice(NUMBER_OF_TOP_FLOOR_TO_SHOW), 'totalDays')
				: 1,
		};
		const mergedFloorsReport: IBaseProductivityFloorReport[] =
			restFloorsReport.totalHours > 0 ? [...topActivatedFloors, restFloorsReport] : topActivatedFloors;

		return mergedFloorsReport;
	}, [props.isDailyReport, props.professionReport.floors]);

	const totalActiveWorkHours: number = useMemo(() => {
		return sumByField(Object.values(props.professionReport.floors), 'totalHours');
	}, [props.professionReport.floors]);

	const horizontalBarsDataList: IHorizontalBarData[] = useMemo(() => {
		const floorsBarsDataList: IHorizontalBarData[] = compact(
			computedFloorsReport.map((floorReport: IBaseProductivityFloorReport) => {
				const totalHoursPercentage: number = getHoursFromTotalHoursPercentage(
					floorReport.totalHours,
					totalActiveWorkHours
				);
				if (!totalHoursPercentage) {
					return null;
				}

				return {
					number: totalHoursPercentage,
					backgroundColor: getProfessionBackgroundColor(props.professionReport.profession, projectId),
					label: floorReport.floorNick,
				} as IHorizontalBarData;
			})
		);

		return floorsBarsDataList;
	}, [props.professionReport, computedFloorsReport]);

	const donutBackgroundColor = useMemo(() => {
		return getChartBackgroundColorByPercentage(totalWorkTimeUnit, props.workTimeUnit);
	}, [totalWorkTimeUnit, props.workTimeUnit]);

	const shouldShowWorkDistributionChart: boolean = totalActiveWorkHours > 0;

	return (
		<div className={classes.columnContainer}>
			<ProductivityColumnHeader profession={props.professionReport.profession} />
			<div className={classes.workerQuantity}>
				<SingleVerticalBar
					barDataList={[
						{
							barNumber: props.workersNumber,
							backgroundColor: getProfessionBackgroundColor(props.professionReport.profession, projectId),
						},
					]}
					numberLabel={true}
					barMaximum={props.maxWorkers}
					barWidth={40}
				/>
			</div>
			<div className={classes.workHours}>
				{props.isDailyReport ? (
					<p className={classes.workHoursText}>
						{!!props.professionReport.totalHoursOnSite && `${props.professionReport.totalHoursOnSite}h`}
					</p>
				) : (
					<DonutChart
						fragment={getProductRoundedHoursFromHours(totalWorkTimeUnit)}
						maxNumber={props.workTimeUnit}
						backgroundColor={donutBackgroundColor}
						size={'70%'}
					/>
				)}
			</div>
			<div className={classes.workResourcesLocation}>
				<div className={classes.horizontalBarsContainer}>
					{shouldShowWorkDistributionChart && (
						<div>
							<HorizontalBars
								endNumberLabel={false}
								data={horizontalBarsDataList}
								maxNumber={100}
								barHeight={18}
								tooltipMessage={translationService.get('totalHours')}
								showPercentage
							/>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export { ProductivityColumn };
