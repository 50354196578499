import { CSSProperties } from 'react';
import { ITodayMarkerPosition } from '../milestoneSelectorCarousel.utils';
import { COLORS } from '@src/constants';
import { HealthScoreEnum } from '@shared/interfaces/HealthScoreEnum';
import { translationService } from '@src/servicesInitializers';

export const getMarkerPositionPercentage = (todayMarkerPosition: ITodayMarkerPosition, isFirstDay?: boolean): string =>
	todayMarkerPosition === ITodayMarkerPosition.START
		? isFirstDay
			? `60%`
			: `80%`
		: todayMarkerPosition === ITodayMarkerPosition.MIDDLE
			? `99%`
			: `16%`;

export const getLineTwoBackgroundColorsStyle = (
	todayMarkerPosition: ITodayMarkerPosition | undefined,
	isRtl: boolean,
	isFirstDay: boolean
): CSSProperties | undefined => {
	if (!todayMarkerPosition) {
		return undefined;
	}
	const markerPositionPercentage = getMarkerPositionPercentage(todayMarkerPosition, isFirstDay);

	return {
		backgroundImage: `linear-gradient(to ${isRtl ? 'left' : 'right'}, ${
			COLORS.grey200
		} ${markerPositionPercentage}, ${COLORS.grey100} ${markerPositionPercentage})`,
	};
};

export const getHealthScoreColor = (healthScore: HealthScoreEnum): string => {
	switch (healthScore) {
		case HealthScoreEnum.ON_TRACK:
			return COLORS.green400;
		case HealthScoreEnum.MAJOR_RISK:
			return COLORS.red400;
		case HealthScoreEnum.MINOR_RISK:
			return COLORS.yellow500;
	}
};

export const getAtRiskText = (healthScore: HealthScoreEnum): string => {
	switch (healthScore) {
		case HealthScoreEnum.ON_TRACK:
			return translationService.get('progressAnalysis_onTrack');
		case HealthScoreEnum.MINOR_RISK:
			return translationService.get('progressAnalysis_minorRisk');
		case HealthScoreEnum.MAJOR_RISK:
			return translationService.get('progressAnalysis_majorRisk');
	}
};
