import { IScoreBar } from '@interfaces/IScoreBar';
import React from 'react';
import classes from './styles.module.scss';
import { translationService } from '../../../index';

interface IScoreBarProps {
	bars: IScoreBar[];
}

const ScoreBar = (props: IScoreBarProps) => {
	const isRtl: boolean = translationService.getIsRtl();
	return (
		<section className={classes.scoreBarContainer}>
			{props.bars.map((bar, index) => {
				const barText: string | undefined = bar.tooltipText || bar.startText || bar.endText;
				return (
					<div className={classes.barContainer} key={index}>
						<div
							className={classes.bar}
							style={{ backgroundColor: bar.color, height: bar.isChosenBar ? '6px' : '2px' }}
						>
							{bar.startText && <div className={classes.startText}>{bar.startText}</div>}
							{bar.endText && <div className={classes.endText}>{bar.endText}</div>}
							{bar.isChosenBar && (
								<div
									className={`${classes.tooltipContainer} ${isRtl ? classes.rtl : ''}`}
									style={{ backgroundColor: bar.tooltipBackgroundColor || bar.color }}
								>
									<div
										className={classes.tooltipText}
										style={{ color: bar.tooltipTextColor ?? 'black' }}
									>
										{barText}
										{bar.extraTooltipEl && bar.extraTooltipEl}
									</div>
									<div
										className={classes.arrowEl}
										style={{
											borderColor: `transparent transparent ${
												bar.tooltipBackgroundColor || bar.color
											} transparent`,
										}}
									></div>
								</div>
							)}
						</div>
					</div>
				);
			})}
		</section>
	);
};
export { ScoreBar };
