import React from 'react';
import { BaseNotificationCard } from '../BaseNotificationCard/BaseNotificationCard';
import classes from './styles.module.scss';
import { INotification } from '../../../interfaces/INotification';
import { TranslationService } from '../../../services/translation.service';
import { RequestService } from '../../../services/request.service';
import { isTrusstorMobile } from '../../../utils/mobile.utils';
import { StorageService } from '../../../services/storage.service';
import { IIssueAssigneeUpdatedNotificationMetadata } from '../../../interfaces/IIssueAssigneeUpdatedNotificationMetadata';
import { IconNames } from '../../TrusstorIconShared/IconNames.enum';
import { IconColor, TrusstorIconShared } from '../../TrusstorIconShared/TrusstorIconShared';
import { getIssueAssignedDescriptionText, getIssueAssignedTitleText } from './IssueAssignedNotifications.texts';

interface ICommentAddedCardProps {
	notification: INotification;
	storageService: StorageService;
	translationService: TranslationService;
	tz: string;
	requester: RequestService;
	handleCardClick: (id: string) => void;
}

export const getIssueAssignedCardTitleElement = (
	metadata: IIssueAssigneeUpdatedNotificationMetadata,
	translationService: TranslationService,
	isDarkTheme?: boolean
): React.ReactNode => {
	const titleClass = `${classes.title} ${isDarkTheme ? classes.titleDarkTheme : ''}`;
	return <div className={titleClass}>{getIssueAssignedTitleText(translationService)}</div>;
};

export const getIssuesIssueAssignedCardDescriptionElement = (
	metadata: IIssueAssigneeUpdatedNotificationMetadata,
	isSnackbar?: boolean
): React.ReactNode => {
	const descriptionClass = `${classes.description} ${isSnackbar ? classes.descriptionSnackbar : ''}`;
	return <div className={descriptionClass}>“{getIssueAssignedDescriptionText(metadata)}”</div>;
};

export const getIssuesIssueAssignedCardIconElement = (): React.ReactNode => {
	return <TrusstorIconShared iconName={IconNames.issues} color={IconColor.White} />;
};

export const IssueAssignedCard = (props: ICommentAddedCardProps) => {
	const metadata: IIssueAssigneeUpdatedNotificationMetadata = props.notification
		.metadata as IIssueAssigneeUpdatedNotificationMetadata;

	const handleCommentClick = () => {
		const issueId: string = metadata.issueDetails.issueId;
		if (!issueId || issueId === 'undefined') {
			return;
		}
		props.handleCardClick(issueId);
	};

	return (
		<BaseNotificationCard
			icon={getIssuesIssueAssignedCardIconElement()}
			titleElement={getIssueAssignedCardTitleElement(metadata, props.translationService)}
			descriptionElement={getIssuesIssueAssignedCardDescriptionElement(metadata)}
			createdAt={props.notification.createdAt}
			tz={props.tz}
			translationService={props.translationService}
			requester={props.requester}
			notificationId={props.notification._id}
			handleCardClick={handleCommentClick}
			isViewed={props.notification.isViewed}
			isClickable={
				!isTrusstorMobile() && !!metadata.issueDetails.issueId && metadata.issueDetails.issueId !== 'undefined'
			}
		/>
	);
};
