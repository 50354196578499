import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sortBy } from 'lodash';
import { translationService } from '../../index';
import { APP_FILTER_TYPES, entitiesProperties } from '../../constants';
import {
	addOneSelectedProfession,
	removeOneSelectedProfession,
	resetSelectedProfessions,
} from '../../store/slices/professions.slice';
import { ExpandableComponent } from '../MainPageSections/ExpandableComponent/ExpandableComponent';
import { selectProjectId } from '@store/slices/project.slice';
import { IRootState } from '@store/slices';
import { IProfession } from '@interfaces/index';
import { ProfessionFilterCard } from '@shared/components/Filters/ProfessionFilterCard/ProfessionFilterCard';

const ProfessionsFilterSection = ({
	professionCountObj,
	defaultOpen,
	dontResetSelectedProfessions,
}: {
	defaultOpen?: boolean;
	dontResetSelectedProfessions?: boolean;
	professionCountObj?: { [professionId: string]: number };
}) => {
	const dispatch = useDispatch();
	const projectId = useSelector(selectProjectId)!;
	const filteredProfessions: IProfession[] = useSelector(
		(state: IRootState) => state.professionsReducer.filteredProfessions
	);
	const filterList = useSelector((state: IRootState) => state.professionsReducer.filterList);
	const selectedProfessions: IProfession[] = useSelector(
		(state: IRootState) => state.professionsReducer.selectedProfessions
	);
	const visibleProfessions: IProfession[] = useSelector(
		(state: IRootState) => state.professionsReducer.visibleProfessions
	);

	useEffect(() => {
		if (!dontResetSelectedProfessions) {
			dispatch(resetSelectedProfessions());
		}
	}, []);

	const checkForInitialValues = () => {
		return !(filterList.length > 0 || selectedProfessions.length > 0);
	};

	const handleResetProfessionsClickReducer = () => {
		dispatch(resetSelectedProfessions());
	};

	const addProfessionReducer = (profession) => {
		dispatch(addOneSelectedProfession({ selectedProfession: profession }));
	};

	const removeProfessionReducer = (profession) => {
		dispatch(removeOneSelectedProfession({ removedProfession: profession }));
	};

	return (
		<ExpandableComponent
			defaultOpen={defaultOpen}
			headerText={translationService.get('professions')}
			childrenLength={filteredProfessions.length}
			shouldHideClearButton={checkForInitialValues()}
			clearFunction={handleResetProfessionsClickReducer}
			clearLength={visibleProfessions.length}
			type={APP_FILTER_TYPES.professionFilter}
			hideEmptyList
			testId={'professionFilterSection'}
		>
			{sortBy(filteredProfessions, [entitiesProperties.sortIndex, entitiesProperties.tradeGroup]).map(
				(professionEntity) => {
					const isSelected = selectedProfessions.find(
						(selectedEntity) => professionEntity._id === selectedEntity._id
					);
					return (
						<ProfessionFilterCard
							key={professionEntity._id}
							handleClick={
								isSelected
									? () => removeProfessionReducer(professionEntity)
									: () => addProfessionReducer(professionEntity)
							}
							isSelected={!!isSelected}
							count={professionCountObj ? professionCountObj[professionEntity._id] : undefined}
							profession={professionEntity}
							projectId={projectId}
							additionalTestId={professionEntity._id}
						/>
					);
				}
			)}
		</ExpandableComponent>
	);
};

export { ProfessionsFilterSection };
