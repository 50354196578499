import { projectAccessLevelService } from '../index';
import { DashboardTabTypes } from '@interfaces/DashboardTabTypes.enum';
import { IProject } from '@shared/interfaces/IProject';

export const getProjectInitialSelectedTab = (
	currentActiveMainPageLayout?: DashboardTabTypes | null,
	project?: IProject
): DashboardTabTypes => {
	if (currentActiveMainPageLayout) {
		return currentActiveMainPageLayout;
	}
	if (projectAccessLevelService.hasAccess('projectMonitor-personnel', project)) {
		return DashboardTabTypes.workForce;
	}
	if (projectAccessLevelService.hasAccess('projectMonitor-activities', project)) {
		return DashboardTabTypes.activities;
	}
	return DashboardTabTypes.safetyEvents;
};
