import React, { useEffect } from 'react';
import classes from './styles.module.scss';
import { ISequence } from '@shared/interfaces/ISequence';
import { SequencesDropdown } from '@src/Components/Pages/Workplan/ProgressTrackerTab/SequenceSelectionSection/SequencesDropdown/SequencesDropdown';
import { TrusstorIcon } from '@src/Components/TrusstorIcon/TrusstorIcon';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { translationService, workplanLocalStorageService } from '@src/servicesInitializers';
import classnames from 'classnames';
import { CreateNewSequence } from '@src/Components/Pages/Workplan/ProgressTrackerTab/SequenceSelectionSection/CreateNewSequence/CreateNewSequence';

interface ISequenceSelectionSectionProps {
	selectedSequence?: ISequence;
	sequences: ISequence[];
	onChange: (sequence: ISequence) => void;
	isCreatingNewSequence: boolean;
	setIsCreatingNewSequence: (isCreatingNewSequence: boolean) => void;
}

export const SequenceSelectionSection = React.memo((props: ISequenceSelectionSectionProps) => {
	const [isInfoOpen, setIsInfoOpen] = React.useState<boolean>(false);

	useEffect(() => {
		const shouldShowSequenceInitialTooltipFromLocalStorage =
			workplanLocalStorageService.getShouldShowSequenceInitialTooltip();
		if (shouldShowSequenceInitialTooltipFromLocalStorage === undefined) {
			setIsInfoOpen(true);
			workplanLocalStorageService.setShouldShowSequenceInitialTooltip(false);
			return;
		}
	}, []);

	return (
		<div className={classes.SequenceSelectionSection_container} data-testid="SequenceSelectionSection">
			{props.isCreatingNewSequence ? (
				<CreateNewSequence
					sequences={props.sequences}
					setShowCreateSequence={() => props.setIsCreatingNewSequence(false)}
				/>
			) : (
				<SequencesDropdown
					setShowCreateSequence={() => props.setIsCreatingNewSequence(true)}
					selectedSequence={props.selectedSequence}
					sequences={props.sequences.filter((sequence) => sequence._id !== props.selectedSequence?._id)}
					onChange={props.onChange}
				/>
			)}
			<div className={classnames(classes.tooltipComponent, { [classes.isInfoOpen]: isInfoOpen })}>
				<div
					className={classnames({ [classes.isInfoOpen]: isInfoOpen })}
					onClick={() => {
						setIsInfoOpen((prev) => !prev);
					}}
				>
					<TrusstorIcon iconName={IconNames.information} />
				</div>
				{isInfoOpen && (
					<div className={classes.tooltip}>
						<div className={classes.title}>{translationService.get('progressTracker')}</div>
						{translationService.get('sequenceSelectionSectionTooltip')}
						<div className={classes.close} onClick={() => setIsInfoOpen(false)}>
							{translationService.get('okGotIt')}
						</div>
					</div>
				)}
			</div>
		</div>
	);
});
