import { Loader } from '@shared/components/Loader/Loader';
import { ITagRouteWithLocationData } from '@shared/interfaces/ITagRouteWithLocationData';
import { findIndex } from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { translationService } from '../../../../index';
import { ITimelineData } from '../../../../interfaces/ITimelineData';
import { IRootState } from '../../../../store/slices';
import { Timeline } from '../../../Timeline/Timeline';
import classes from './styles.module.scss';
import { getLocationIndexInvestigated } from '../../../../utils/investigationReports.utils';

interface IReportTagRouteProps {
	tagRoute: ITagRouteWithLocationData[] | null;
	investigatedTimestamp?: number;
	isIncidentReport?: boolean;
	onClick?: (index: number) => void;
	tagName: string;
}

const ReportTagRoute = (props: IReportTagRouteProps) => {
	const tz: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.tz);
	if (!props.tagRoute || !props.investigatedTimestamp) {
		return <Loader />;
	}

	const timelineData: ITimelineData[] = props.tagRoute.map((route) => {
		return {
			floorNick: route.floorNick,
			areaNick: route.areaNick,
			peopleWith: route.tagsIdsCountedInLocation.length,
			arrivalTime: route.arrivalTime,
			departureTime: route.departureTime,
			stayTime: moment(route.departureTime).diff(route.arrivalTime),
		};
	});

	const iconIndex: number = getLocationIndexInvestigated(tz, props.investigatedTimestamp, timelineData);

	useEffect(() => {
		props.onClick &&
			props.tagRoute?.length &&
			props.onClick(props.isIncidentReport && iconIndex >= 0 ? iconIndex : 0);
	}, [props.tagRoute.length]);

	const onTagLocationClick = (index: number) => {
		props.onClick && props.tagRoute && props.onClick(index);
	};
	const nameTitle: string = translationService.getIsRtl()
		? `${translationService.get('personRoute')} ${props.tagName}`
		: `${props.tagName} ${translationService.get('personRoute')} `;

	return (
		<div className={classes.reportTagRouteSection}>
			<div className={classes.nameTitle}>{nameTitle}</div>
			{timelineData.length > 0 && (
				<Timeline
					timelineData={timelineData}
					indexMarked={props.isIncidentReport && iconIndex >= 0 ? iconIndex : undefined}
					onClick={onTagLocationClick}
				/>
			)}
		</div>
	);
};

export { ReportTagRoute };
