import React from 'react';
import { useSharedServices } from '../../../../hooks/sharedServices.context';
import classes from './styles.module.scss';
import {
	ITrusstorButtonSize,
	ITrusstorButtonType,
	TrusstorButton,
} from '../../../buttons/TrusstorButton/TrusstorButton';

interface IFooterProps {
	setIsDropdownOpen: (isOpen: boolean) => void;
}

export const DropdownFooter = (props: IFooterProps) => {
	const { translationService } = useSharedServices();

	const onSave = () => {
		props.setIsDropdownOpen(false);
	};
	return (
		<div className={classes.footer}>
			<TrusstorButton
				handleClick={onSave}
				text={translationService.get('multipleGroupedDropdown-save')}
				buttonSize={ITrusstorButtonSize.SMALL}
			/>
		</div>
	);
};
