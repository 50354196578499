import { ILoggedUser } from './IUser';

export interface ILoginReducerState {
	loggedUser: ILoggedUser | null;
	loginRequestStatus: ILoginRequestStatus;
}

export interface ILoginRequestStatus {
	state: LoginRequestStatuses;
	statusCode?: number;
	responseMessage?: string;
}

export enum LoginRequestStatuses {
	INITIAL = 'initial',
	LOADING = 'loading',
	ERROR = 'error',
	SUCCESS = 'success',
}
