export enum SafetyViolationTypes {
	dangerousActivity = 'dangerousActivity',
	unscheduledLoneWork = 'unscheduledLoneWork',
	unaccompaniedGuest = 'unaccompaniedGuest',
	sos = 'sos',
	requiredRoleOnSite = 'requiredRoleOnSite',
	workersShiftExceeded = 'workersShiftExceeded',
	certificateExpired = 'certificateExpired',
	hoistOperatorRequired = 'hoistOperatorRequired',
	restrictedArea = 'restrictedArea',
}
