import { useQuery } from '@tanstack/react-query';
import { RequestService } from '../../services/request.service';
import { IFloor } from '../../interfaces/IFloor';

export const useFloorViewDataQuery = (
	requestService: RequestService,
	projectId: string,
	floorId: string
): { floorViewSvg: string | undefined; isLoading: boolean; error } => {
	const {
		data: floorViewSvg,
		isLoading,
		error,
	} = useQuery<string>(
		['floorView', projectId, floorId],
		async () => {
			const floorView = await requestService.get(`/storage/projects/${projectId}/floorView?floorId=${floorId}`);
			return floorView;
		},
		{}
	);
	return { floorViewSvg, isLoading, error };
};

export const useFloorDataQuery = (requestService: RequestService, projectId: string): IFloor[] => {
	const { data: floors } = useQuery<IFloor[]>(
		['floorsData', projectId],
		() => requestService.get(`/projectConfig/floor?projectId=${projectId}`),
		{
			useErrorBoundary: true,
			initialData: [],
		}
	);
	return floors;
};
