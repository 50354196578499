import React, { useState } from 'react';
import { IProfession } from '@interfaces/index';
import { IProfessionDisplaySettings, ProfessionSettingType } from '@shared/interfaces/IProfessionDisplaySettings';
import { omit } from 'lodash';
import { DisplaySettingsPicker } from '../../../DisplaySettingsPicker/DisplaySettingsPicker';
import { getDefaultProfessionDisplaySettings } from './ProfessionDisplaySettingsPicker.utils';

interface IProfessionDisplaySettingsPickerProps {
	profession: IProfession;
	projectId: string;
	onDisplaySettingsChange: (displaySettings: IProfessionDisplaySettings) => void;
}

export const ProfessionDisplaySettingsPicker = (props: IProfessionDisplaySettingsPickerProps) => {
	const [professionDisplaySettings, setProfessionDisplaySettings] = useState<IProfessionDisplaySettings>(
		getDefaultProfessionDisplaySettings(props.profession, props.projectId)
	);

	const onCheckboxChange = (isChecked: boolean, propertyName: keyof IProfessionDisplaySettings) => {
		const newProfessionDisplaySettings: IProfessionDisplaySettings = isChecked
			? { ...professionDisplaySettings, [propertyName]: ProfessionSettingType.TEXT }
			: omit(professionDisplaySettings, propertyName);
		setProfessionDisplaySettings(newProfessionDisplaySettings);
		props.onDisplaySettingsChange(newProfessionDisplaySettings);
	};

	const isPropertyChecked = (propertyName: keyof IProfessionDisplaySettings): boolean => {
		return !!professionDisplaySettings[propertyName];
	};

	const numberOfExistingSettingKeysInProfession = (): number => {
		const existingSettings: string[] = Object.keys(professionDisplaySettings).filter((displaySetting: string) => {
			if (displaySetting === 'trade') {
				return true;
			}
			return props.profession[displaySetting];
		});
		return existingSettings.length;
	};

	const isDisabled = (propertyName: keyof IProfessionDisplaySettings): boolean => {
		return isPropertyChecked(propertyName) && numberOfExistingSettingKeysInProfession() === 1;
	};

	return (
		<DisplaySettingsPicker
			isPropertyChecked={isPropertyChecked}
			isDisabled={isDisabled}
			onCheckboxChange={onCheckboxChange}
			hideSpecialty={!props.profession.specialty}
			hideContractor={!props.profession.contractor}
		/>
	);
};
