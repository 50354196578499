import { IMatrixCellComponentProps } from '@src/Components/Matrix/interfaces/IMatrixCellComponentProps';
import React, { useContext, useState } from 'react';
import classes from './styles.module.scss';
import { TrusstorIcon } from '@src/Components/TrusstorIcon/TrusstorIcon';
import { accessLevelService, requestService, translationService } from '@src/servicesInitializers';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { compareProps } from '../../../../utils/generalUtils';
import { IProfession } from '@shared/interfaces/IProfession';
import { ProfessionSection } from '@src/Components/Matrix/cellComponents/MatrixHeader/ProfessionSection/ProfessionSection';
import { SequenceItemMenu } from '@src/Components/Matrix/cellComponents/MatrixHeader/SequenceItemMenu/SequenceItemMenu';
import { HeaderTextSection } from '@src/Components/Matrix/cellComponents/MatrixHeader/HeaderTextSection';
import { SequenceItemDeleteConfirmationDialog } from '@src/Components/Matrix/cellComponents/MatrixHeader/SequenceItemDeleteConfirmationDialog';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { IconSize } from '@shared/components/TrusstorIconShared/TrusstorIconShared';
import { StarButton } from '@shared/components/buttons/StarButton/StarButton';
import { errorSnackbar } from '@utils/snackbar.utils';
import { EditSequenceItemDialog } from '@src/Components/Matrix/cellComponents/MatrixHeader/AddSequenceItem/EditSequenceItemDialog/EditSequenceItemDialog';
import { SequenceTypeEnum } from '@shared/interfaces/SequenceType.enum';
import { CreateSequenceItemDialog } from '@src/Components/Matrix/cellComponents/MatrixHeader/AddSequenceItem/CreateSequenceItemDialog/CreateSequenceItemDialog';
import { ISequenceItemWithoutId } from '@interfaces/ISequenceItemWithoutId';
import { sortBy } from 'lodash';
import { selectProjectId } from '@store/slices/project.slice';
import { WorkplanContext } from '@src/Components/Pages/Workplan/WorkPlan';
import { IMergedAreaSequenceItem } from '@interfaces/IMergedAreaSequenceItem';
import { addSequenceItemsToTable } from '@utils/sequence.utils';
import { useProjectAreasBySequenceType, useSequenceSequenceItemsQuery } from '@src/hooks/queries/sequenceItem.query';
import { ISequenceItem } from '@interfaces/ISequenceItem';

interface IMatrixHeaderProps extends IMatrixCellComponentProps {
	currentOrderIndex?: number;
	sequenceItemId?: string;
	isTextEditable?: boolean;
	profession?: IProfession;
	text: string;
	iconSrc?: any;
	iconClass?: string;
	fraction?: number;
	total?: number;
	secondaryTextPosition?: ISideTextPosition;
	showStarredSection?: boolean;
	showSequenceMenu?: boolean;
	isStarred?: boolean;
	sequenceType?: SequenceTypeEnum;
	handleStarClick?: () => Promise<void>;
	sideTextPosition?: ISideTextPosition;
	handleProfessionUpdate?: (sequenceId: string, profession: IProfession) => void;
	handleDescriptionChange?: (text: string) => void;
	deleteSequenceItem?: (sequenceId: string) => void;
	handleEditSequenceItem?: (
		sequenceItemId: string,
		profession: IProfession,
		description: string,
		isStarred: boolean
	) => void;
}

export enum ISideTextPosition {
	TOP = 'top',
	BOTTOM = 'bottom',
	START = 'start',
	END = 'end',
}

export const MatrixHeader = React.memo((props: IMatrixHeaderProps) => {
	const isRtl: boolean = translationService.getIsRtl();
	const projectId: string = useSelector(selectProjectId)!;
	const dispatch = useDispatch();
	const [showCreateDialog, setShowCreateDialog] = useState<boolean>(false);
	const [showDeleteConfirmation, setShowConfirmationDialog] = useState<boolean>(false);
	const [showEditDialog, setShowEditDialog] = useState<boolean>(false);
	const userHasAccessToWorkplanActivityActions: boolean = accessLevelService.hasAccess('workplanActivityActions');
	const { areas } = useProjectAreasBySequenceType(projectId, props.sequenceType);
	const {
		matrix: { setTables },
		setSequenceItems,
		setAreaSequenceItemsByFloor,
		selectedSequenceId,
	} = useContext(WorkplanContext)!;
	const { sequenceItems } = useSequenceSequenceItemsQuery(selectedSequenceId);

	const getPositionClass = () => {
		if (
			props.secondaryTextPosition === ISideTextPosition.TOP ||
			props.secondaryTextPosition === ISideTextPosition.BOTTOM
		) {
			return classes[props.secondaryTextPosition];
		}
		if (props.secondaryTextPosition === ISideTextPosition.START) {
			return isRtl ? classes.end : classes.start;
		}
		if (props.secondaryTextPosition === ISideTextPosition.END) {
			return isRtl ? classes.start : classes.end;
		}
	};

	const onStarClickError = () => {
		errorSnackbar(dispatch, translationService.get('genericError'));
	};

	const shouldShowSideText: boolean = !!props.fraction && !!props.total && props.fraction > 0;
	const shouldMakeSpace: boolean =
		shouldShowSideText &&
		(props.sideTextPosition === ISideTextPosition.END || props.sideTextPosition === ISideTextPosition.START);

	const handleAddColumnToTheRight = () => {
		setShowCreateDialog(true);
	};

	const updateNewSequenceItemToCorrectOrderIndex = async (newSequenceItem: ISequenceItemWithoutId) => {
		const newSequenceItemWithCorrectOrderIndex: ISequenceItemWithoutId = {
			...newSequenceItem,
			orderIndex: (props.currentOrderIndex || 0) + 1,
		};
		const newSequenceItems: ISequenceItemWithoutId[] = [newSequenceItemWithCorrectOrderIndex, ...sequenceItems];
		const orderedNewSequenceItems: ISequenceItemWithoutId[] = sortBy(newSequenceItems, 'orderIndex');

		try {
			const updatedSequenceItems: ISequenceItem[] = await requestService.put(
				`/activities/sequenceItems/bulk?projectId=${projectId}&sequenceId=${selectedSequenceId}`,
				{
					body: { sequenceItems: orderedNewSequenceItems },
				}
			);

			setSequenceItems(updatedSequenceItems);
			const areaSequenceItemsFromApi: IMergedAreaSequenceItem[] = await requestService.get(
				`/activities/sequenceItems/areaSequenceItem?sequenceId=${selectedSequenceId}`
			);

			addSequenceItemsToTable(
				areaSequenceItemsFromApi,
				setTables,
				setAreaSequenceItemsByFloor,
				updatedSequenceItems,
				areas
			);
		} catch (e) {
			setSequenceItems(sequenceItems);
			console.log(e);
			errorSnackbar(dispatch, translationService.get('genericError'));
		}
	};

	const openEditDialog = () => {
		setShowEditDialog(true);
	};

	const closeEditDialog = () => {
		setShowEditDialog(false);
	};
	const onEditSequenceItem = async (profession: IProfession, isStarred: boolean, description: string) => {
		props.handleEditSequenceItem?.(props.sequenceItemId!, profession, description, isStarred);

		const areaSequenceItemsFromApi: IMergedAreaSequenceItem[] = await requestService.get(
			`/activities/sequenceItems/areaSequenceItem?sequenceId=${selectedSequenceId}`
		);

		addSequenceItemsToTable(areaSequenceItemsFromApi, setTables, setAreaSequenceItemsByFloor, sequenceItems, areas);
	};

	return (
		<div className={classes.container}>
			<div className={classes.topSection}>
				{props.showStarredSection && (
					<StarButton
						isStarred={!!props.isStarred}
						handleStarClick={props.handleStarClick}
						onClickError={onStarClickError}
					/>
				)}

				{props.showSequenceMenu && userHasAccessToWorkplanActivityActions && (
					<SequenceItemMenu
						openEditDialog={openEditDialog}
						handleAddColumnToTheRight={handleAddColumnToTheRight}
						deleteSequenceItem={() => {
							setShowConfirmationDialog(true);
						}}
					/>
				)}
			</div>
			{props.iconSrc && <img className={props.iconClass} src={props.iconSrc} alt={'icon'} />}
			<HeaderTextSection
				isTextEditable={props.isTextEditable}
				shouldMakeSpace={shouldMakeSpace}
				sequenceItemId={props.sequenceItemId}
				handleTextChange={(text) => {
					props.handleDescriptionChange?.(text);
				}}
				text={props.text}
			/>
			{shouldShowSideText && (
				<div className={`${classes.sideText} ${getPositionClass()}`}>
					<TrusstorIcon iconName={IconNames.greenCheckCircle} size={IconSize.SMALL} defaultColor />
					<div className={classnames(classes.text, { [classes.rtl]: isRtl })}>
						{props.fraction}/{props.total}
					</div>
				</div>
			)}
			<SequenceItemDeleteConfirmationDialog
				showConfirmationDialog={showDeleteConfirmation}
				deleteSequenceItem={props.deleteSequenceItem}
				sequenceItemId={props.sequenceItemId!}
				setShowConfirmationDialog={setShowConfirmationDialog}
			/>
			<ProfessionSection
				handleProfessionUpdate={props.handleProfessionUpdate}
				profession={props.profession}
				sequenceItemId={props.sequenceItemId}
			/>
			{showCreateDialog && props.currentOrderIndex && props.sequenceType && selectedSequenceId && (
				<CreateSequenceItemDialog
					close={() => setShowCreateDialog(false)}
					show={showCreateDialog}
					nextOrderIndex={sequenceItems.length}
					onCreateSequenceItem={updateNewSequenceItemToCorrectOrderIndex}
					sequenceId={selectedSequenceId}
					sequenceType={props.sequenceType}
				/>
			)}
			{props.profession && props.sequenceItemId && props.sequenceType && showEditDialog && (
				<EditSequenceItemDialog
					show={showEditDialog}
					close={closeEditDialog}
					onEditSequenceItem={onEditSequenceItem}
					profession={props.profession}
					description={props.text}
					isStarred={!!props.isStarred}
					sequenceItemId={props.sequenceItemId}
					sequenceType={props.sequenceType}
				/>
			)}
		</div>
	);
}, compareProps);
