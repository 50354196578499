import { IProjectPackagesFeatureProhibitions } from '@shared/interfaces/IProjectPackagesFeatureProhibitions';
import { ProjectPackageTypeEnum } from '@shared/interfaces/ProjectPackageType.enum';

const featureNames = [
	'projectMonitor-personnel',
	'projectMonitor-activities',
	'projectMonitor-issues',
	'projectMonitor-safety',
	'workplanPage',
	'issuesPage',
	'tagsPage',
	'managersListPage',
	'notifications-safety',
	'notifications-reports',
	'notifications-activities',
	'notifications-issues',
	'analysisCenterPages',
	'progressAnalysisPage',
	'progressAnalysisNavbarItem',
] as const;

export type ProjectPackagesFeaturesNamesType = (typeof featureNames)[number];
type IProjectPackagesFeatureProhibitionsFrontend =
	IProjectPackagesFeatureProhibitions<ProjectPackagesFeaturesNamesType>;

export const projectPackagesFeaturesProhibitedList: IProjectPackagesFeatureProhibitionsFrontend = {
	'projectMonitor-personnel': [ProjectPackageTypeEnum.management],
	'projectMonitor-activities': [ProjectPackageTypeEnum.monitor],
	'projectMonitor-issues': [ProjectPackageTypeEnum.monitor],
	'projectMonitor-safety': [ProjectPackageTypeEnum.management],
	'workplanPage': [ProjectPackageTypeEnum.monitor],
	'issuesPage': [ProjectPackageTypeEnum.monitor],
	'tagsPage': [ProjectPackageTypeEnum.management],
	'managersListPage': [ProjectPackageTypeEnum.management],
	'notifications-safety': [ProjectPackageTypeEnum.management],
	'notifications-reports': [ProjectPackageTypeEnum.management],
	'notifications-activities': [ProjectPackageTypeEnum.monitor],
	'notifications-issues': [ProjectPackageTypeEnum.monitor],
	'analysisCenterPages': [ProjectPackageTypeEnum.management],
	'progressAnalysisPage': [ProjectPackageTypeEnum.management, ProjectPackageTypeEnum.monitor],
	'progressAnalysisNavbarItem': [ProjectPackageTypeEnum.bundle, ProjectPackageTypeEnum.monitor],
};
