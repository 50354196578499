import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import classes from './styles.module.scss';
import DatePicker from 'react-datepicker';
import { translationService } from '../../../index';
import moment, { Moment } from 'moment-timezone';
import { selectTimezone } from '@store/slices/project.slice';
import { getProjectDateWithBrowserTZOffset, getTimezoneTimestamp } from '@utils/dateUtils';
import classnames from 'classnames';
import { convertDateObjectToServerFormat, getTimezoneStartOfDate } from '@shared/utils/dateUtils';
import { DatePickerHeader } from '@shared/components/DatePickerHeader/DatePickerHeader';
import { TimeInput } from '@src/Components/DateSelector/DateAndTimeSelector/TimeInput';

type DatePickerTypeProps = React.ComponentProps<typeof DatePicker>;
export type PopperPlacement = DatePickerTypeProps['popperPlacement'];
interface IDateAndTimeSelectorProps {
	handleTimeChange: (date: Date) => void;
	selectedDate?: Date | null;
	displayTime?: boolean;
	setThirtyMinutesGap?: boolean;
	minDate?: Date;
	maxDate?: Date;
	disableDatePicker?: boolean;
	disabledDates?: Date[];
	inline?: boolean;
	containerClassName?: string;
	customInput?: React.ReactNode;
	wrapperClassName: string;
	selectDefaultDate?: boolean;
	children?: React.ReactNode;
	calendarPosition?: PopperPlacement;
}

const DateAndTimeSelector = (props: IDateAndTimeSelectorProps) => {
	const tz: string = useSelector(selectTimezone)!;
	const isRtl: boolean = translationService.getIsRtl();
	const timeFormat: string = translationService.getTimeFormat();
	const dateFormat: string = (isRtl ? `dd/MM/yyyy ` : `MM/dd/yyyy `) + (props.displayTime ? timeFormat : '');
	const disabledDates: Date[] = props.disabledDates?.map((date) => getProjectDateWithBrowserTZOffset(tz, date)) || [];
	const [selectedDate, setSelectedDate] = React.useState<Date | null>(
		props.selectDefaultDate ? props.selectedDate || null : null
	);

	useEffect(() => {
		if (
			props.selectDefaultDate &&
			props.selectedDate &&
			selectedDate &&
			!moment.tz(props.selectedDate, tz).isSame(moment.tz(selectedDate, tz), 'day')
		) {
			setSelectedDate(props.selectedDate);
		}
	}, [props.selectedDate]);

	const getMaxTime = (): Date | undefined => {
		const thirtyMinutesGap: Moment = moment('23:30', 'HH:mm');
		if (props.setThirtyMinutesGap) {
			if (props.maxDate && moment.tz(props.selectedDate, tz).isSame(props.maxDate, 'day')) {
				return props.maxDate;
			}
			return thirtyMinutesGap.toDate();
		}
		if (props.maxDate) {
			return props.maxDate;
		}
		return undefined;
	};

	const isDateDisabledDate = (date?: Date): boolean =>
		!!props.disabledDates?.find((disabledDate) => moment(disabledDate).isSame(moment(date), 'day'));

	const disableDisplayTimeOnOpening: boolean = false; // !props.selectedDate && isDateDisabledDate();

	const renderDayContents = (day, date) => {
		const tooltipText = isDateDisabledDate(date) ? translationService.get('tagNotActiveInDay') : '';
		return <span title={tooltipText}>{day}</span>;
	};

	const onDateChange = (date: Date, e: React.SyntheticEvent<any, Event> | undefined) => {
		e?.stopPropagation();
		const dateOnTz: Date = new Date(getTimezoneTimestamp(date, tz));
		setSelectedDate(dateOnTz);
		if (props.displayTime) {
			props.handleTimeChange(dateOnTz);
			return;
		}
		props.handleTimeChange(getTimezoneStartOfDate(tz, convertDateObjectToServerFormat(date)));
	};

	return (
		<div
			className={classnames(
				classes.timeSelectorContainer,
				props.containerClassName,
				props.displayTime && classes.datePickerIcon
			)}
		>
			<DatePicker
				renderCustomHeader={({
					monthDate,
					decreaseMonth,
					increaseMonth,
					prevMonthButtonDisabled,
					nextMonthButtonDisabled,
				}) => (
					<DatePickerHeader
						monthDate={monthDate}
						decreaseMonth={decreaseMonth}
						increaseMonth={increaseMonth}
						prevMonthButtonDisabled={prevMonthButtonDisabled}
						nextMonthButtonDisabled={nextMonthButtonDisabled}
					/>
				)}
				fixedHeight
				formatWeekDay={(nameOfDay) => translationService.get(`datepicker_weekday_${nameOfDay}`)}
				onChange={onDateChange}
				shouldCloseOnSelect={!props.displayTime}
				showTimeInput={!!props.displayTime && !disableDisplayTimeOnOpening}
				selected={getProjectDateWithBrowserTZOffset(tz, selectedDate || new Date())}
				placeholderText={translationService.get(props.displayTime ? 'enterDateAndTime' : 'enterDate')}
				popperPlacement={props.calendarPosition}
				timeIntervals={15}
				disabled={!!props.disableDatePicker}
				dateFormat={dateFormat}
				timeFormat={timeFormat}
				minDate={props.minDate && getProjectDateWithBrowserTZOffset(tz, props.minDate)}
				maxDate={props.maxDate && getProjectDateWithBrowserTZOffset(tz, props.maxDate)}
				minTime={props.setThirtyMinutesGap ? new Date(0, 0, 0, 0, 30) : undefined}
				maxTime={getMaxTime() && getProjectDateWithBrowserTZOffset(tz, getMaxTime())}
				excludeDates={disabledDates}
				renderDayContents={renderDayContents}
				inline={props.inline}
				customInput={props.customInput}
				wrapperClassName={props.wrapperClassName}
				popperClassName={classes.calendar}
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				customTimeInput={<TimeInput />}
			>
				{props.children}
			</DatePicker>
		</div>
	);
};

export { DateAndTimeSelector };
