import React from 'react';
import classes from './styles.module.scss';
import classnames from 'classnames';
import { HighlightedTextField } from '../../../../../TextFields/HighlightedTextField';
import { TrusstorCheckbox } from '../../../../../TrusstorCheckbox/TrusstorCheckbox';

interface DropdownItemCardProps<T> {
	option: T;
	index: number;
	isSelected: boolean;
	isHighlighted: boolean;
	handleOptionClick: (option: T) => void;
	handleOptionHover: (index: number) => void;
	inputValue: string;
	optionDisplayValue: string | React.ReactNode;
	optionSecondDisplayValue?: string;
	showCheckbox?: boolean;
}

function DropdownItemCard<T>({
	option,
	index,
	isSelected,
	isHighlighted,
	handleOptionClick,
	handleOptionHover,
	inputValue,
	optionDisplayValue,
	optionSecondDisplayValue,
	showCheckbox,
}: DropdownItemCardProps<T>) {
	return (
		<div
			key={index}
			data-testid="DropdownItemCard"
			onClick={() => handleOptionClick(option)}
			onMouseEnter={() => handleOptionHover(index)}
			className={classnames(classes.dropdownItem, {
				[classes.selected]: isSelected,
				[classes.highlighted]: isHighlighted,
			})}
		>
			<div className={classes.leftSection}>
				{showCheckbox && <TrusstorCheckbox checked={isSelected} onChange={() => handleOptionClick(option)} />}
				{typeof optionDisplayValue === 'string' ? (
					<HighlightedTextField text={optionDisplayValue} highlight={inputValue} />
				) : (
					optionDisplayValue
				)}
			</div>
			{optionSecondDisplayValue && (
				<HighlightedTextField text={optionSecondDisplayValue} highlight={inputValue} />
			)}
		</div>
	);
}

export default DropdownItemCard;
