import React from 'react';
import classes from './styles.module.scss';
import { ITimelineData } from '../../interfaces/ITimelineData';
import { translationService } from '../../index';
import { TimelineItem } from './TimelineItem';

interface TimelineProps {
	timelineData: ITimelineData[];
	indexMarked?: number;
	onClick?: (index: number) => void;
}

export const Timeline = (props: TimelineProps) => {
	const isRtl: boolean = translationService.getIsRtl();
	const [indexSelected, setIndexSelected] = React.useState<number | undefined>(props.indexMarked || 0);
	const onItemClick = (index: number) => {
		setIndexSelected(index);
		props.onClick && props.onClick(index);
	};
	return (
		<div className={`${isRtl ? classes.timelineContainerRTL : classes.timelineContainer}`}>
			{props.timelineData.map((data, idx) => (
				<TimelineItem
					data={data}
					key={idx}
					index={idx}
					lastIndex={props.timelineData.length - 1}
					indexMarked={props.indexMarked}
					onClick={onItemClick}
					isSelected={indexSelected === idx}
				/>
			))}
		</div>
	);
};
