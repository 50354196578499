import { TranslationService } from '../../../services/translation.service';
import { RequestService } from '../../../services/request.service';
import { INotification } from '../../../interfaces/INotification';
import { BaseNotificationCard } from '../BaseNotificationCard/BaseNotificationCard';
import classes from './styles.module.scss';
import React from 'react';
import { getShieldIconElement } from '../DistressButtonCard/DistressButtonCard';
import { IRestrictedAreaNotificationMetadata } from '../../../interfaces/IRestrictedAreaNotificationMetadata';
import { getRelativeDateFormattedToDisplay } from '../../../utils/dateUtils';
import { isTrusstorMobile } from '../../../utils/mobile.utils';

interface IRestrictedAreaNotificationCardProps {
	notification: INotification;
	translationService: TranslationService;
	tz: string;
	requester: RequestService;
}

export const getRestrictedAreaTitleElement = (translationService: TranslationService, isDarkTheme?: boolean) => {
	const titleClass: string = `${classes.title} ${isDarkTheme ? classes.titleDarkTheme : ''}`;
	return <div className={titleClass}>{translationService.get('restrictedAreaEntry')}!</div>;
};

export const getRestrictedAreaDescriptionElement = (
	notification: INotification,
	translationService: TranslationService,
	tz: string,
	isDarkTheme?: boolean
) => {
	const descriptionClass: string = `${classes.description} ${isDarkTheme ? classes.descriptionDarkTheme : ''}`;
	const metadata: IRestrictedAreaNotificationMetadata = notification.metadata as IRestrictedAreaNotificationMetadata;
	const time: string = getRelativeDateFormattedToDisplay(notification.createdAt, tz, translationService);

	return (
		<div className={descriptionClass}>
			<strong>
				{translationService.get('tag')} {metadata.tagId}
				{metadata.name ? `, ${metadata.name}` : ''}
			</strong>{' '}
			{translationService.get('hasEntered')}{' '}
			<strong>{metadata.floor ? metadata.floor.floorNick : metadata.area?.areaNick}</strong>
		</div>
	);
};

export const RestrictedAreaNotificationCard = (props: IRestrictedAreaNotificationCardProps) => {
	return (
		<BaseNotificationCard
			descriptionElement={getRestrictedAreaDescriptionElement(
				props.notification,
				props.translationService,
				props.tz,
				false
			)}
			icon={getShieldIconElement()}
			titleElement={getRestrictedAreaTitleElement(props.translationService)}
			createdAt={props.notification.createdAt}
			tz={props.tz}
			translationService={props.translationService}
			requester={props.requester}
			isViewed={props.notification.isViewed}
			notificationId={props.notification._id}
			isClickable={!isTrusstorMobile()}
		/>
	);
};
