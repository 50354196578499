import React from 'react';
import { NotificationStatuses, SafetyEventStatuses, StatusChannels } from '../../../constants';
import { translationService } from '../../../index';
import { useSelector } from 'react-redux';
import { getProfessionDisplayText } from '../../../utils/professions.utils';
import { ManagerChip } from '../../Chips/ManagerChip/ManagerChip';
import { ISafetyEvent } from '../../../interfaces/ISafetyEvent';
import {
	getHoursMinutesFormat,
	getDateByLocaleFormat,
	getDateAndHourFromFormat,
	getDateFromFormat,
} from '../../../utils/dateUtils';
import { getSafetyViolationTypeTranslationVars } from '../../../utils/safety.utils';
import { IRootState } from '../../../store/slices';
import classes from './styles.module.scss';

interface ISafetyEventDisplayContentProps {
	event: ISafetyEvent;
}

const SafetyEventDisplayContent = (props: ISafetyEventDisplayContentProps) => {
	const isClosed: boolean = props.event.status === SafetyEventStatuses.close;
	const tz: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.tz);

	const getCloseChannel = (statusChannel) => {
		if (statusChannel === StatusChannels.manual) {
			return translationService.get('manual');
		} else {
			return translationService.get('auto');
		}
	};
	const translationVars: { [key: string]: string } = getSafetyViolationTypeTranslationVars(
		props.event.trade?.tradeId,
		props.event.safetyViolationType
	);
	const getTagsOwnerText = () => {
		const tagsOwnersFirstWord: string | undefined =
			props.event.tags?.[0]?.tagOwner || props.event.tags?.[0]?.phoneNumber;
		const tagsOwnersSecondWord: string =
			props.event.tags?.[0]?.tagOwner && props.event.tags?.[0]?.phoneNumber
				? ` (${props.event.tags?.[0]?.phoneNumber})`
				: '';
		return `${tagsOwnersFirstWord}${tagsOwnersSecondWord}`;
	};
	const isRtl: boolean = translationService.getIsRtl();
	return (
		<div className={classes.container}>
			<div className={`${classes.eventDetailsContainer} ${isClosed ? classes.eventDetailsContainerClosed : ''}`}>
				<p className={classes.eventTitle} data-testid={'eventTitle'}>
					{translationService.get(props.event.safetyViolationType, translationVars)}
				</p>
				<div className={classes.row}>
					{props.event.timestampCreated && (
						<div className={classes.detailContainer}>
							<p className={classes.detailTitle}>{translationService.get('openingTime')}</p>
							<p className={classes.detailText} data-testid={'openingTime'}>
								{getDateAndHourFromFormat(
									props.event.timestampCreated,
									translationService.getDateFormat(),
									tz
								)}
							</p>
						</div>
					)}
					{props.event?.location?.floorId && (
						<div className={classes.detailContainer}>
							<p className={classes.detailTitle}>{translationService.get('location')}</p>
							<p
								className={`${classes.detailText} ${classes.initialDirection}`}
								data-testid={'locationDetails'}
							>
								{props.event.location.floorNick},{' '}
								{props.event?.location?.areas?.length > 0 &&
									props.event.location.areas.map((area) => area.areaNick).join(', ')}
							</p>
						</div>
					)}
					{props.event.tags && props.event.tags?.length > 0 && (
						<div className={classes.detailContainer}>
							<p className={classes.detailTitle}>{translationService.get('tagId')}</p>
							<p
								className={`${classes.detailText} ${isRtl ? classes.initialDirection : ''}`}
								data-testid={'tagNick'}
							>
								{props.event.tags[0].tagNick}
							</p>
						</div>
					)}
				</div>
				{(props.event?.tags?.[0]?.tagOwner || props.event?.tags?.[0]?.phoneNumber) && (
					<div className={classes.detailContainer}>
						<p className={classes.detailTitle}>{translationService.get('tagsOwner')}</p>
						<p
							className={`${classes.detailText} ${isRtl ? classes.initialDirection : ''}`}
							data-testid={'tagOwner'}
						>
							{getTagsOwnerText()}
						</p>
					</div>
				)}
				{props.event.profession && (
					<div className={classes.detailContainer}>
						<p className={classes.detailTitle}>{translationService.get('profession')}</p>
						<p className={classes.detailText} data-testid={'profession'}>
							{getProfessionDisplayText(props.event.profession)}
						</p>
					</div>
				)}
				{props.event?.activity?.activityDescription && (
					<div className={classes.detailContainer}>
						<p className={classes.detailTitle}>{translationService.get('dangerousActivity')}</p>
						<p className={classes.detailText} data-testid={'activityDescription'}>
							{props.event.activity.activityDescription}
						</p>
					</div>
				)}
				{props.event.smsNotifications.filter(
					(notification) => notification.status === NotificationStatuses.sent
				).length > 0 && (
					<div className={classes.detailContainer}>
						<p className={classes.detailTitle}>{translationService.get('smsSentTo')}:</p>
						<p className={classes.detailText}>
							{props.event.smsNotifications
								.filter((notification) => notification.status === NotificationStatuses.sent)
								.map((smsNotification) => (
									<ManagerChip
										key={`managerKey_${smsNotification.manager._id}`}
										chipBackground={smsNotification.manager.backgroundColor}
										chipText={smsNotification.manager.name}
									/>
								))}
						</p>
					</div>
				)}
			</div>
			{isClosed && (
				<div className={classes.closeDataContainer}>
					<p className={classes.detailTitle}>{translationService.get('eventClosed')}</p>
					<p className={classes.detailText} data-testid={'eventClosedDate'}>
						{getDateByLocaleFormat(props.event.timestampClosed, tz)},
						{getHoursMinutesFormat(props.event.timestampClosed, tz)},{' '}
						{getCloseChannel(props.event.statusChannel)}
					</p>
				</div>
			)}
		</div>
	);
};

export { SafetyEventDisplayContent };
