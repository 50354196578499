export const openFileMobile = async (fileString: string) => {
	const binary: string = atob(fileString.replace(/\s/g, ''));
	const binaryLength: number = binary.length;
	const buffer: ArrayBuffer = new ArrayBuffer(binaryLength);
	const view: Uint8Array = new Uint8Array(buffer);
	for (let i = 0; i < binaryLength; i++) {
		view[i] = binary.charCodeAt(i);
	}
	const blob: Blob = new Blob([view], { type: 'application/pdf' });

	// Create a link element
	const link: HTMLAnchorElement = document.createElement('a');
	link.href = window.URL.createObjectURL(blob);
	link.target = '_blank';
	link.download = 'document.pdf';

	// Append the link to the body
	document.body.appendChild(link);

	// Trigger click on the link
	link.click();

	// Remove the link from the body
	document.body.removeChild(link);
};

export const openFileDesktop = async (fileString: string) => {
	const byteCharacters: string = atob(fileString);
	const byteNumbers: any[] = new Array(byteCharacters.length);
	for (let i = 0; i < byteCharacters.length; i++) {
		byteNumbers[i] = byteCharacters.charCodeAt(i);
	}
	const byteArray: Uint8Array = new Uint8Array(byteNumbers);
	const file: Blob = new Blob([byteArray], { type: 'application/pdf;base64' });
	const fileURL: string = URL.createObjectURL(file);
	window.open(fileURL);
};
