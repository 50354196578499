import React from 'react';
import { BaseNotificationCard } from '../BaseNotificationCard/BaseNotificationCard';
import classes from './styles.module.scss';
import { INotification } from '../../../interfaces/INotification';
import { TranslationService } from '../../../services/translation.service';
import { RequestService } from '../../../services/request.service';
import { ICreatedIssuesNotificationMetadata } from '../../../interfaces/ICreatedIssuesNotificationMetadata';
import { isTrusstorMobile } from '../../../utils/mobile.utils';
import { TrusstorIconShared } from '../../TrusstorIconShared/TrusstorIconShared';
import { IconNames } from '../../TrusstorIconShared/IconNames.enum';

interface ICommentAddedCardProps {
	notification: INotification;
	requestService: RequestService;
	translationService: TranslationService;
	tz: string;
	requester: RequestService;
	handleCardClick: () => void;
}

export const getCreatedIssuesTitleElement = (
	metadata: ICreatedIssuesNotificationMetadata,
	translationService: TranslationService,
	isDarkTheme?: boolean
): React.ReactNode => {
	const titleClass = `${classes.title} ${isDarkTheme ? classes.titleDarkTheme : ''}`;
	return (
		<div className={titleClass}>
			{metadata.issuesDescriptions.length} {translationService.get('issues')}{' '}
			{translationService.get('wereOpened')}{' '}
			{translationService.get(metadata.isWeekly ? 'thisWeek' : 'today').toLowerCase()} -{' '}
			<strong>{metadata.issuesDescriptions.join(', ')}</strong>
		</div>
	);
};

export const getCreatedIssuesIconElement = (requestService: RequestService): React.ReactNode => {
	return <TrusstorIconShared iconName={IconNames.issues} className={classes.icon} />;
};

export const CreatedIssuesCard = (props: ICommentAddedCardProps) => {
	const metadata: ICreatedIssuesNotificationMetadata = props.notification
		.metadata as ICreatedIssuesNotificationMetadata;

	const handleCommentClick = () => {
		props.handleCardClick();
	};

	return (
		<BaseNotificationCard
			icon={getCreatedIssuesIconElement(props.requestService)}
			titleElement={getCreatedIssuesTitleElement(metadata, props.translationService)}
			createdAt={props.notification.createdAt}
			tz={props.tz}
			translationService={props.translationService}
			requester={props.requester}
			notificationId={props.notification._id}
			handleCardClick={handleCommentClick}
			isViewed={props.notification.isViewed}
			isClickable={!isTrusstorMobile()}
		/>
	);
};
