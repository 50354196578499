import React, { useId, useRef } from 'react';
import classes from './styles.module.scss';
import { IMilestone } from '@shared/interfaces/IMilestone';
import { TrusstorIcon } from '../TrusstorIcon/TrusstorIcon';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { useUpdateMilestoneMutation } from '@src/hooks/queries/milestones.queries.hooks';
import { selectProjectId, selectTimezone } from '@store/slices/project.slice';
import { useSelector } from 'react-redux';
import { IconColor, IconSize } from '@shared/components/TrusstorIconShared/TrusstorIconShared';
import { translationService } from '@src/servicesInitializers';
import { ISequenceItem } from '@interfaces/ISequenceItem';
import { DateSelectorTrusstorInput } from '@src/Components/DateSelector/DateSelectorTrusstorInput/DateSelectorTrusstorInput';
import { getTimezoneFormattedDate } from '@shared/utils/dateUtils';
import { uniqBy } from 'lodash';
import { useHistory } from 'react-router-dom';

interface IMilestoneItemCardProps {
	milestone: IMilestone;
	linkedSequenceItems?: ISequenceItem[];
	index: number;
}

export const MilestoneItemCard = (props: IMilestoneItemCardProps) => {
	const history = useHistory();
	const location = history.location.pathname;
	const projectId: string = useSelector(selectProjectId)!;
	const tz: string = useSelector(selectTimezone)!;
	const [title, setTitle] = React.useState<string>(props.milestone.title);
	const [dueDate, setDueDate] = React.useState<Date>(props.milestone.endDate);
	const editTitleEl = useRef<HTMLDivElement>(null);
	const id: string = 'editableDiv' + useId();
	const updateMutation = useUpdateMilestoneMutation(projectId);

	const updateTitle = () => {
		if (editTitleEl.current!.innerText === title) return;
		setTitle(editTitleEl.current!.innerText);
		updateMutation.mutate({
			milestoneId: props.milestone._id!,
			title: editTitleEl.current!.innerText,
		});
	};

	const uniqueSequencesLinked: number = uniqBy(props.linkedSequenceItems, 'sequenceId').length;

	const handleDateChange = (date: Date | null) => {
		if (!date) return;
		setDueDate(date);
		updateMutation.mutate({
			milestoneId: props.milestone._id!,
			dueDate: getTimezoneFormattedDate(tz, date),
		});
	};

	const handleCardClick = () => {
		history.push(location + `/${props.milestone._id}`);
	};

	const onTitleClick = (e) => {
		e.stopPropagation();
	};

	return (
		<div className={classes.wrapper}>
			<div className={classes.orderIndex}>{props.index}</div>
			<div
				className={classes.MilestoneItemCard_container}
				onClick={handleCardClick}
				data-testid="MilestoneItemCard"
			>
				<div className={classes.titleDescriptionSection}>
					<div className={classes.titleSection}>
						<div
							suppressContentEditableWarning={true}
							onClick={onTitleClick}
							onBlur={updateTitle}
							tabIndex={1}
							ref={editTitleEl}
							id={id}
							contentEditable
							className={classes.title}
						>
							{title}
						</div>
						<div className={classes.bottomPart}>
							{!props.linkedSequenceItems?.length ? (
								<>
									<TrusstorIcon
										iconName={IconNames.link}
										color={IconColor.Grey500}
										size={IconSize.SMALL}
									/>
									<div>{translationService.get('milestone_card_link_to_sequence')}</div>
								</>
							) : (
								<div className={classes.linkedSection}>
									<div>{`${uniqueSequencesLinked} ${translationService.get(
										'milestone_card_sequence_linked'
									)}`}</div>
									<div>{`${props.linkedSequenceItems.length} ${translationService.get(
										'milestone_card_sequenceItems_linked'
									)}`}</div>
								</div>
							)}
						</div>
					</div>
					<DateSelectorTrusstorInput
						width={84}
						minDate={new Date()}
						required
						label={dueDate ? translationService.get('dueDate') : undefined}
						inputPlaceHolder={translationService.get('dueDate')}
						handleTimeChange={handleDateChange}
						iconElement={<TrusstorIcon iconName={IconNames.flag} />}
						selectedDate={new Date(dueDate)}
						selectDefaultDate={true}
					/>
				</div>
			</div>
		</div>
	);
};
