import React, { useContext, useEffect, useRef } from 'react';
import classes from './styles.module.scss';
import { Dictionary, groupBy, sortBy } from 'lodash';
import { getProfessionBackgroundColor } from '@shared/utils/professions.utils';
import { IProfession, IUser } from '@interfaces/index';
import { useSelector } from 'react-redux';
import { selectProjectId, selectTimezone } from '@store/slices/project.slice';
import { IIssue } from '@shared/interfaces/IIssue';
import { ProfessionIssuesChip } from '@src/Components/Chips/ProfessionIssuesChip/ProfessionIssuesChip';
import { ProfessionIssuesChipTooltip } from '@src/Components/Chips/ProfessionIssuesChip/ProfessionIssuesChipTooltip/ProfessionIssuesChipTooltip';
import { isIssueOverdue } from '@shared/components/Issue/issues.utils';
import { IssuePriority, IssueStatus } from '@shared/interfaces/IIssueShared';
import { COLORS } from '@shared/constants/colors.constants';
import { MainPageContext } from '@src/Components/Pages/MainPage';
import { IssuesTabQuickFilterTypes } from '@src/Components/MainPageIssuesTab/MainPageIssuesTab';
import { selectLoggedUserDetails } from '@store/slices/login.slice';
import { useAreChipsOverlapping } from '@src/Components/MainViewFloorSections/MainViewFloorSection.hooks';

interface IIssuesFloorEntitiesProps {
	floorIssues: IIssue[];
	floorId: string;
}

export const IssuesFloorEntities = React.memo((props: IIssuesFloorEntitiesProps) => {
	const containerRef = useRef<HTMLDivElement>(null);
	const { areChipsOverlapping, updateChipsOverlapping } = useAreChipsOverlapping(containerRef, props.floorIssues);
	const userDetails: IUser = useSelector(selectLoggedUserDetails)!;
	const { issuesTabSelectedFilter } = useContext(MainPageContext)!;
	const getFilteredIssues = (filter: IssuesTabQuickFilterTypes, issues: IIssue[]): IIssue[] => {
		switch (filter) {
			case IssuesTabQuickFilterTypes.all:
				return issues;
			case IssuesTabQuickFilterTypes.forReview:
				return issues.filter((issue) => issue.status === IssueStatus.FOR_REVIEW);
			case IssuesTabQuickFilterTypes.critical:
				return issues.filter((issue) => issue.priority == IssuePriority.CRITICAL);
			case IssuesTabQuickFilterTypes.assignedToMe:
				return issues.filter((issue) => issue.assignee?.username === userDetails.username);
		}
	};

	const issuesFilteredBySelectedFilter: IIssue[] = getFilteredIssues(issuesTabSelectedFilter, props.floorIssues);
	const sortedFloorIssues: IIssue[] = sortBy(issuesFilteredBySelectedFilter, (issue) => issue.profession?._id);
	const issuesGroupedByProfession: Dictionary<IIssue[]> = groupBy(
		sortedFloorIssues,
		(issue) => issue.profession?._id
	);
	const projectId: string = useSelector(selectProjectId)!;
	const tz: string = useSelector(selectTimezone)!;

	useEffect(() => {
		updateChipsOverlapping();
	}, [issuesTabSelectedFilter]);

	if (!props.floorIssues.length) {
		return null;
	}

	return (
		<div className={classes.issuesFloorEntitiesContainer} ref={containerRef}>
			{Object.keys(issuesGroupedByProfession).map((professionId: string) => {
				const professionIssues: IIssue[] = issuesGroupedByProfession[professionId];
				const profession: IProfession | undefined = professionIssues[0].profession;
				const isAtleastOneIssueOverdue: boolean = professionIssues.some((issue) => isIssueOverdue(issue, tz));
				const isAtleastOneIssueStarred: boolean = professionIssues.some(
					(issue) => issue.priority === IssuePriority.CRITICAL
				);

				const backgroundColor: string = profession
					? getProfessionBackgroundColor(profession, projectId)
					: COLORS.darkBlue;
				const getProfessionIssuesChipTooltip = () => {
					return (
						<ProfessionIssuesChipTooltip
							floorId={props.floorId}
							profession={profession}
							issuesData={professionIssues}
						/>
					);
				};

				return (
					<ProfessionIssuesChip
						key={`floor_${props.floorId}_profession_${professionId}`}
						backgroundColor={backgroundColor}
						numberOfIssues={issuesGroupedByProfession[professionId].length}
						isOverdue={isAtleastOneIssueOverdue}
						isMinimized={areChipsOverlapping}
						getToolTipComponent={getProfessionIssuesChipTooltip}
						isStarred={isAtleastOneIssueStarred}
					/>
				);
			})}
		</div>
	);
});
