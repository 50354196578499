import React, { CSSProperties, useState } from 'react';
import classes from './styles.module.scss';
import { Loader } from '../../Loader/Loader';
import classnames from 'classnames';
import { getIsTouchDevice } from '../../../utils/mobile.utils';

interface IBaseButtonProps {
	key?: string;
	text: string;
	handleClick?: (arg0: React.MouseEvent<HTMLButtonElement, MouseEvent>) => any;
	stylesOptions: {
		mainClass: any;
		disabledClass?: any;
		iconClass?: any;
		additionalCssProps?: CSSProperties;
		selectedClass?: any;
		hoverClass?: any;
	};
	disabled?: boolean;
	selected?: boolean;
	showLoader?: boolean;
	icon?: any;
	testId?: string;
	disableHover?: boolean;
}

const BaseButton = (props: IBaseButtonProps) => {
	const isTouchDevice: boolean = getIsTouchDevice();
	const [isHovered, setIsHovered] = useState(false);

	return (
		<button
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
			key={props.key}
			className={classnames({
				[classes.baseButton]: true,
				[props.stylesOptions.mainClass]: true,
				[props.stylesOptions.disabledClass]: props.disabled,
				[props.stylesOptions.selectedClass]: props.selected,
				[props.stylesOptions.hoverClass]: isHovered && !isTouchDevice && !props.disableHover,
			})}
			style={props.stylesOptions.additionalCssProps}
			onClick={props.handleClick}
			data-testid={props.testId ?? null}
		>
			{props.icon && (
				<img className={classnames(classes.baseIcon, props.stylesOptions.iconClass)} src={props.icon} alt="" />
			)}
			{props.showLoader ? <Loader /> : props.text}
		</button>
	);
};

export { BaseButton };
