import { IMergedAreaSequenceItem } from '@interfaces/IMergedAreaSequenceItem';
import { ISequenceItem } from '@interfaces/ISequenceItem';
import { AreaSequenceItemStatus } from '@shared/interfaces/AreaSequenceItemStatus.enum';
import { IConfigArea } from '@shared/interfaces/IConfigArea';
import { updateAreaSequenceItemReadyForReviewStatus } from '@src/apis/areaSequenceItems.api';
import { IMatrixTable } from '@src/Components/Matrix/interfaces/IMatrixTable';
import { IMatrixColHeader, Matrix } from '@src/Components/Matrix/Matrix';
import {
	ProgressTrackerTabContext,
	TableModeEnum,
} from '@src/Components/Pages/Workplan/ProgressTrackerTab/ProgressTrackerTab';
import { PlanningContext, WorkplanContext } from '@src/Components/Pages/Workplan/WorkPlan';
import { VerticalTOC } from '@src/Components/VerticalTOC/VerticalTOC';
import {
	ActivitySequenceItemCellCLick,
	ActivitySequenceItemCellSchedule,
	IActivitySequenceItemCellData,
} from '@src/Components/WorkPlan/ActivityProgressTracker/components/ActivitySequenceMatrix/cellComponents/ActivitySequenceItemCell/ActivitySequenceItemCell';
import { activitySequenceMatrixConstants } from '@src/Components/WorkPlan/ActivityProgressTracker/components/ActivitySequenceMatrix/constants';
import { FloorHeaderSeparator } from '@src/Components/WorkPlan/ActivityProgressTracker/components/ActivitySequenceMatrix/FloorHeaderSeparator/FloorHeaderSeparator';
import { useLargeScreen } from '@src/hooks/custom.hooks';
import { accessLevelService, requestService, translationService } from '@src/index';
import { selectProjectId } from '@store/slices/project.slice';
import { workplanMatrixFloorNavigation_BI, workplanMatrixInteraction_BI } from '@utils/bi.utils';
import { ActivitySequenceMatrixMode } from '@shared/interfaces/ActivitySequenceMatrixMode.enum';
import { changePropertyOnAreaSequenceItems, removeSequenceItemFromTable } from '@utils/sequence.utils';
import { errorSnackbar, successSnackbar } from '@utils/snackbar.utils';
import classnames from 'classnames';
import { debounce, Dictionary, unionBy } from 'lodash';
import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import Lottie from 'react-lottie';
import { useDispatch, useSelector } from 'react-redux';
import { IConfigFloor } from '../../../../../../../shared/interfaces/IConfigFloor';
import MatrixLoader from './sequenceMatrixLoader.json';
import classes from './styles.module.scss';
import { calculateCellsRange } from '@utils/workPlan.utils';
import { config } from '../../../../../config';
import { IMatrixOptions } from '../../../../Matrix/interfaces/IMatrixOptions';
import { IProfession } from '@shared/interfaces/IProfession';
import { MatrixEmptyState } from '@src/Components/WorkPlan/ActivityProgressTracker/components/ActivitySequenceMatrix/MatrixEmptyState/MatrixEmptyState';
import { isAsiComplete } from '@shared/utils/asi.utils';

export interface IMatrixDisabledCell {
	isDisabled: boolean;
	area: IConfigArea;
}

export interface ISequenceMatrixTableData extends IMatrixTable {
	rows: IActivitySequenceItemCellData[][];
	rowHeaders: { rowTitle: string; doneItems?: number; totalItems?: number }[];
	commonHeader: string;
	id: string;
	isEmpty?: boolean;
}

interface IActivitySequenceMatrixProps {
	hasProfessionFilter: boolean;
	mode: ActivitySequenceMatrixMode;
	areaSequenceItemsByFloor: Dictionary<IMergedAreaSequenceItem[] | null> | undefined;
	isTableFinishToRender?: boolean;
	setMarginForCards?: boolean;
	showOnlyStarred?: boolean;
	updateAreaSequenceItem: (updatedAreaSequenceItem: IMergedAreaSequenceItem) => void;
	fetchMoreAreaSequenceItemsByCurrentFloor: (floorId: string) => Promise<void>;
	showToc: boolean;
}

export interface IActivitySequenceItemHeaderData extends IMatrixColHeader {
	description: string;
	doneItems?: number;
	totalItems?: number;
	isStarred: boolean;
	sequenceItemId: string;
}

interface IActivitySequenceMatrixContext {
	multiSelectedCells: IActivitySequenceItemCellData[];
	handleMultiSelectedCellClick: (cell: IActivitySequenceItemCellData, isShiftPressed: boolean) => void;
}

export const ActivitySequenceMatrixContext = createContext<IActivitySequenceMatrixContext | undefined>(undefined);

export const ActivitySequenceMatrix = (props: IActivitySequenceMatrixProps) => {
	const projectId: string = useSelector(selectProjectId)!;
	const selectedFloorFromTOCRef = useRef<IConfigArea | null>(null);
	const [visibleFloorTable, setVisibleFloorTable] = useState<IConfigFloor>({} as IConfigFloor);
	const [hoveredCell, setHoveredCell] = useState<IMergedAreaSequenceItem | null>(null);
	const [updatedTables, setUpdatedTables] = useState<IMatrixTable[] | undefined>(undefined);

	const [matrixCellWidth, setMatrixCellWidth] = useState<number>(activitySequenceMatrixConstants.minCellWidth);
	const [showToc, setShowToc] = useState<boolean>(props.showToc);
	const dispatch = useDispatch();
	const isClickedToc = useRef<boolean>(false);
	const isInitialRender = useRef<boolean>(true);
	const isLargeScreen: boolean = useLargeScreen();

	const timeOutRef = React.useRef<number | null>(null);

	const {
		areaSequenceItemsSelected,
		setAreaSequenceItemsSelected,
		matrixTopScrollPosition,
		setMatrixTopScrollPosition,
	} = useContext(PlanningContext)!;
	const [startingScrollPosition, setStartingScrollPosition] = useState<number | undefined>(undefined);
	const {
		setSequenceItems,
		sequenceItems,
		matrix: { setTables, tables, createTableData, recreateTable, floorsList },
		selectedSequenceId,
		setShouldMatrixFetchAllFloors,
		setAreaSequenceItemsByFloor,
		sequences,
	} = useContext(WorkplanContext)!;

	const floorIdsThatAlreadyIntersectedRef = useRef<string[]>([]);
	const userHasAccessToActivityActions: boolean = accessLevelService.hasAccess('workplanActivityActions');

	useEffect(() => {
		if (!floorsList.length) {
			return;
		}

		const defaultFloor: IConfigFloor = floorsList[0];
		setVisibleFloorTable(defaultFloor);
		props.fetchMoreAreaSequenceItemsByCurrentFloor(defaultFloor.floorId);
	}, [floorsList, props.fetchMoreAreaSequenceItemsByCurrentFloor]);

	useEffect(() => {
		setStartingScrollPosition(matrixTopScrollPosition?.[TableModeEnum.AREAS] || 0);
	}, []);

	useEffect(() => {
		if (props.mode === ActivitySequenceMatrixMode.display) {
			areaSequenceItemsSelected.forEach((areaSequenceItem: IMergedAreaSequenceItem) => {
				toggleScheduleCell(areaSequenceItem, false);
				setNewAreaSequenceItemSelected(areaSequenceItem);
			});
		}
	}, [props.mode, areaSequenceItemsSelected.length]);

	useEffect(() => {
		const resetComponent = () => {
			setTables(undefined);
			selectedFloorFromTOCRef.current = null;
			setVisibleFloorTable({} as IConfigFloor);
			setShowToc(props.showToc);
			isClickedToc.current = false;
			isInitialRender.current = true;
			timeOutRef.current = null;
			floorIdsThatAlreadyIntersectedRef.current = [];
			setMatrixCellWidth(activitySequenceMatrixConstants.minCellWidth);
		};

		resetComponent();
	}, [selectedSequenceId, projectId]);
	const isLastCellAddedRef = useRef<boolean>(false);
	const isLastSelectedCellAddedRef = useRef<boolean>(false);
	const {
		multiSelectedCells,
		setMultiSelectedCells,
		scheduleAreaSequenceItemsForStatusUpdate,
		sendCurrentBulkStatusUpdates,
	} = useContext(ProgressTrackerTabContext)!;
	const lastShiftSelectionData = useRef<{
		lastSelectedCell: IActivitySequenceItemCellData;
		selectedCells: IActivitySequenceItemCellData[];
	} | null>(null);
	const lastScheduleShiftSelectionData = useRef<{
		lastSelectedCell: IActivitySequenceItemCellData;
		selectedCells: IActivitySequenceItemCellData[];
	} | null>(null);
	const handleShiftMultiCellSelection = (
		currentCell: IActivitySequenceItemCellData,
		lastSelectedCell: IActivitySequenceItemCellData
	): boolean => {
		const lastSelectedShiftCell: IActivitySequenceItemCellData =
			lastShiftSelectionData.current?.lastSelectedCell || lastSelectedCell;

		if (currentCell._id === lastSelectedShiftCell._id) {
			return true;
		}

		const selectedCells: IActivitySequenceItemCellData[] = calculateCellsRange(
			currentCell,
			lastSelectedShiftCell,
			floorsList,
			updatedTables || tables
		);

		const userHasAccessToActivityActions: boolean = accessLevelService.hasAccess('workplanActivityActions');

		const notCompleteSelectedCells: IActivitySequenceItemCellData[] = selectedCells.filter(
			(cell) => !isAsiComplete(cell.status)
		);

		const finalSelectedCells: IActivitySequenceItemCellData[] = userHasAccessToActivityActions
			? selectedCells
			: notCompleteSelectedCells;

		//filter the previous shift selection
		if (lastShiftSelectionData.current) {
			const lastSelectedCells: IActivitySequenceItemCellData[] = lastShiftSelectionData.current.selectedCells;
			const filteredCells: IActivitySequenceItemCellData[] = multiSelectedCells.filter(
				(cell) => !lastSelectedCells.some((lastCell) => lastCell._id === cell._id)
			);
			const combine = unionBy(filteredCells, finalSelectedCells, '_id');
			setMultiSelectedCells(combine);
		} else {
			const combinedCells: IActivitySequenceItemCellData[] = unionBy(
				finalSelectedCells,
				multiSelectedCells,
				'_id'
			);
			setMultiSelectedCells(combinedCells);
		}

		lastShiftSelectionData.current = {
			lastSelectedCell: lastSelectedShiftCell,
			selectedCells: finalSelectedCells,
		};
		return true;
	};

	const handleMultiSelectedCellClick = (cell: IActivitySequenceItemCellData, isShiftPressed: boolean) => {
		const isCellSelected: boolean = multiSelectedCells.some((selectedCell) => selectedCell._id === cell._id);
		if (isShiftPressed && isLastCellAddedRef.current) {
			const didShiftSelectionWork: boolean = handleShiftMultiCellSelection(
				cell,
				multiSelectedCells[multiSelectedCells.length - 1]
			);
			if (didShiftSelectionWork) {
				return;
			}
		}
		lastShiftSelectionData.current = null;
		if (!isCellSelected) {
			setMultiSelectedCells([...multiSelectedCells, cell]);
			isLastCellAddedRef.current = true;
		} else {
			setMultiSelectedCells(multiSelectedCells.filter((selectedCell) => selectedCell._id !== cell._id));
			isLastCellAddedRef.current = false;
		}
	};

	useEffect(() => {
		const numberOfSequenceItems: number = sequenceItems.length;
		const matrixContainerWidth: number = isLargeScreen
			? activitySequenceMatrixConstants.defaultMatrixWidthLargeScreen
			: activitySequenceMatrixConstants.defaultMatrixWidthNarrowScreen;
		const totalContainerSpace: number =
			(matrixContainerWidth ?? activitySequenceMatrixConstants.defaultMatrixWidthNarrowScreen) -
			activitySequenceMatrixConstants.tocWidth;
		const itemsWidthWithMaxCellWidth: number = numberOfSequenceItems * activitySequenceMatrixConstants.maxCellWidth;

		if (itemsWidthWithMaxCellWidth <= totalContainerSpace) {
			setMatrixCellWidth(activitySequenceMatrixConstants.maxCellWidth);
			return;
		}

		setMatrixCellWidth(activitySequenceMatrixConstants.minCellWidth);
	}, [sequenceItems, isLargeScreen]);

	useEffect(() => {
		if (!props.areaSequenceItemsByFloor || !floorsList?.length || !sequenceItems || tables?.length) {
			return;
		}

		const matrixDataTables: ISequenceMatrixTableData[] = floorsList.map((floor) => createTableData(floor));
		setTables(matrixDataTables);

		return () => {
			sendCurrentBulkStatusUpdates();
		};
	}, [sequenceItems, floorsList.length, props.areaSequenceItemsByFloor, tables?.length]);

	const setNewAreaSequenceItemSelected = (areaSequenceItem: IActivitySequenceItemCellData) => {
		const { isCheckedForActivityCreation, ...mergedAreaSequenceItem }: IActivitySequenceItemCellData =
			areaSequenceItem;
		if (isCheckedForActivityCreation) {
			setAreaSequenceItemsSelected((prevState: IMergedAreaSequenceItem[]) => [
				...prevState,
				mergedAreaSequenceItem,
			]);
			return;
		}

		const newAreaSequenceItemsSelected: IMergedAreaSequenceItem[] = areaSequenceItemsSelected.filter(
			(areaSequenceItem: IMergedAreaSequenceItem) => areaSequenceItem._id !== mergedAreaSequenceItem._id
		);
		setAreaSequenceItemsSelected(newAreaSequenceItemsSelected);
	};

	useEffect(() => {
		if (tables && props.areaSequenceItemsByFloor) {
			const floorIds: string[] = Object.keys(props.areaSequenceItemsByFloor);
			const floorIdsWithoutTable: string[] = floorIds.filter((floorId: string) => {
				const table: ISequenceMatrixTableData | undefined = tables.find(
					(table: ISequenceMatrixTableData) => table.id === floorId
				);
				return table?.isEmpty;
			});

			if (!floorIdsWithoutTable.length) {
				return;
			}

			const updatedTables: ISequenceMatrixTableData[] = floorIdsWithoutTable.map((floorId: string) => {
				const floorData: IConfigFloor | undefined = floorsList.find(
					(floor: IConfigFloor) => floor.floorId === floorId
				);
				return createTableData(floorData!);
			});
			setTables((prevTables) => {
				if (!prevTables) {
					return;
				}

				return [
					...prevTables.map((table: ISequenceMatrixTableData) => {
						const updatedTable: ISequenceMatrixTableData | undefined = updatedTables.find(
							(updatedTable: ISequenceMatrixTableData) => updatedTable.id === table.id
						);
						return updatedTable || table;
					}),
				];
			});
		}
	}, [props.areaSequenceItemsByFloor, floorsList, tables, sequenceItems]);

	useEffect(() => {
		handleScrollBiEvent();
	}, [visibleFloorTable]);

	const handleScrollBiEvent = useCallback(() => {
		tocScrollBiDebounce();
	}, []);

	const tocScrollBiDebounce = debounce(() => {
		if (!isClickedToc.current && !isInitialRender.current) {
			workplanMatrixFloorNavigation_BI('scroll');
			return;
		}
		isInitialRender.current = false;
	}, 2500);

	const handleFloorChangeOnToc = useCallback(
		(floor: IConfigArea) => {
			props.fetchMoreAreaSequenceItemsByCurrentFloor(floor.floorId);
			selectedFloorFromTOCRef.current = floor;
			scrollToTable(floor.floorId);
			workplanMatrixFloorNavigation_BI('click');
			setTocClicked();
			disableintersectingChangesWhileScrolling();
		},
		[floorsList, props.fetchMoreAreaSequenceItemsByCurrentFloor]
	);

	const setTocClicked = () => {
		isClickedToc.current = true;
		setTimeout(() => (isClickedToc.current = false), 3500);
	};

	const scrollToTable = (tableId: string) => {
		const selectedTable: HTMLElement | null = document.getElementById(tableId);
		if (selectedTable && floorsList.length) {
			const floorOfTable: IConfigFloor = floorsList.find((floor) => floor.floorId === tableId)!;
			setVisibleFloorTable(floorOfTable);
			selectedTable.scrollIntoView({
				behavior: 'smooth',
				block: 'start',
				inline: 'nearest',
			});
		}
	};

	const disableintersectingChangesWhileScrolling = () => {
		if (timeOutRef.current) {
			clearTimeout(timeOutRef.current);
		}
		timeOutRef.current = window.setTimeout(() => {
			selectedFloorFromTOCRef.current = null;
		}, 3000);
	};

	const handleTableIntersectingChange = useCallback(
		(tableId: string) => {
			const floorOfTable: IConfigFloor | undefined = floorsList.find((floor) => floor.floorId === tableId);
			if (!floorOfTable) {
				return;
			}

			if (selectedFloorFromTOCRef.current) {
				return;
			}

			setVisibleFloorTable(floorOfTable);
			if (floorIdsThatAlreadyIntersectedRef.current.includes(floorOfTable.floorId)) {
				return;
			}
			props.fetchMoreAreaSequenceItemsByCurrentFloor(floorOfTable.floorId);
			floorIdsThatAlreadyIntersectedRef.current.push(floorOfTable.floorId);
		},
		[floorsList, props.fetchMoreAreaSequenceItemsByCurrentFloor]
	);

	const toggleCellStatus = useCallback(
		(cell: IMergedAreaSequenceItem, updatedStatus: AreaSequenceItemStatus): void => {
			const updatedAreaSequenceItem: IMergedAreaSequenceItem = {
				...cell,
				status: updatedStatus,
			};
			updateCell(updatedAreaSequenceItem);
		},
		[props.updateAreaSequenceItem, recreateTable]
	);

	const toggleScheduleCell = useCallback(
		(cell: IMergedAreaSequenceItem, scheduleState: boolean): void => {
			const updateAreaSequenceItem: IMergedAreaSequenceItem = {
				...cell,
				isCheckedForActivityCreation: scheduleState,
			} as any;
			props.updateAreaSequenceItem(updateAreaSequenceItem);
			recreateTable(cell.area.floorId, [updateAreaSequenceItem]);
		},
		[props.updateAreaSequenceItem, recreateTable]
	);

	const updateCell = (mergedAreaSequenceItem: IMergedAreaSequenceItem): void => {
		props.updateAreaSequenceItem(mergedAreaSequenceItem);
		recreateTable(mergedAreaSequenceItem.area.floorId, [mergedAreaSequenceItem]);
	};

	const handleDoneCell = async (cell: IMergedAreaSequenceItem): Promise<void> => {
		const currentCellStatus: AreaSequenceItemStatus = cell.status;
		toggleCellStatus(cell, AreaSequenceItemStatus.complete);
		try {
			scheduleAreaSequenceItemsForStatusUpdate(AreaSequenceItemStatus.complete, [cell]);
		} catch (e) {
			errorSnackbar(dispatch, translationService.get('markDoneFailed'));
			toggleCellStatus(cell, currentCellStatus);
		} finally {
			const actionType: string = isAsiComplete(cell.status) ? 'undone' : 'complete';
			const currentSequenceItemDescription: string = sequenceItems.find(
				(sequenceItem: ISequenceItem) => sequenceItem._id === cell.sequenceItemId
			)!.description!;
			workplanMatrixInteraction_BI({
				actionType,
				floorId: cell.area.floorId,
				areaId: cell.area.areaId,
				sequenceName: sequences.find((sequence) => sequence._id === cell.sequenceId)?.name,
				sequenceItem: currentSequenceItemDescription,
				areaSequenceItemId: cell._id!,
				source: 'Workplan',
			});
		}
	};

	const handleScheduleChange = (cells: IActivitySequenceItemCellData[]) => {
		setAreaSequenceItemsSelected((prevSelectedCells) => {
			const removedCells: IActivitySequenceItemCellData[] = prevSelectedCells.filter(
				(prevCell) => !cells.find((cell) => cell._id === prevCell._id)
			);
			const addedCells: IActivitySequenceItemCellData[] = cells.filter(
				(cell) => !prevSelectedCells.find((prevCell) => prevCell._id === cell._id)
			);
			removedCells.forEach((cell) => toggleScheduleCell(cell, false));
			addedCells.forEach((cell) => toggleScheduleCell(cell, true));
			return cells;
		});
	};

	const handleScheduleMultiCellSelection = (
		currentCell: IActivitySequenceItemCellData,
		lastSelectedCell: IActivitySequenceItemCellData
	): boolean => {
		const lastScheduleSelectedShiftCell: IActivitySequenceItemCellData =
			lastScheduleShiftSelectionData.current?.lastSelectedCell || lastSelectedCell;
		if (currentCell._id === lastScheduleSelectedShiftCell._id) {
			return true;
		}
		const selectedCells: IActivitySequenceItemCellData[] = calculateCellsRange(
			currentCell,
			lastScheduleSelectedShiftCell,
			floorsList,
			updatedTables || tables
		);

		if (lastScheduleShiftSelectionData.current) {
			const lastSelectedCells: IActivitySequenceItemCellData[] =
				lastScheduleShiftSelectionData.current.selectedCells;
			const filteredCells: IActivitySequenceItemCellData[] = areaSequenceItemsSelected.filter(
				(cell) => !lastSelectedCells.some((lastCell) => lastCell._id === cell._id)
			);
			const combine: IActivitySequenceItemCellData[] = unionBy(filteredCells, selectedCells, '_id').filter(
				(cell) => cell.status === AreaSequenceItemStatus.unplanned
			);
			handleScheduleChange(combine);
		} else {
			const combinedCells: IActivitySequenceItemCellData[] = unionBy(
				selectedCells,
				areaSequenceItemsSelected,
				'_id'
			).filter((cell) => cell.status === AreaSequenceItemStatus.unplanned);
			handleScheduleChange(combinedCells);
		}

		lastScheduleShiftSelectionData.current = {
			lastSelectedCell: lastScheduleSelectedShiftCell,
			selectedCells: selectedCells,
		};
		return true;
	};

	const handleScheduleClick = (cell: IActivitySequenceItemCellData, event: any) => {
		const isShiftPressed: boolean = event.metaKey || event.shiftKey;
		const isCellSelected: boolean = areaSequenceItemsSelected.some((selectedCell) => selectedCell._id === cell._id);
		if (isShiftPressed && isLastSelectedCellAddedRef.current) {
			const didShiftSelectionWork: boolean = handleScheduleMultiCellSelection(
				cell,
				areaSequenceItemsSelected[areaSequenceItemsSelected.length - 1]
			);
			if (didShiftSelectionWork) {
				return;
			}
		}
		lastScheduleShiftSelectionData.current = null;
		if (!isCellSelected) {
			handleScheduleChange([...areaSequenceItemsSelected, cell]);
			isLastSelectedCellAddedRef.current = true;
		} else {
			handleScheduleChange(areaSequenceItemsSelected.filter((selectedCell) => selectedCell._id !== cell._id));
			isLastSelectedCellAddedRef.current = false;
		}
	};

	const updatedAreaSequenceItemStatusForLimitedUser = async (cell: IMergedAreaSequenceItem): Promise<void> => {
		const prevStatus = cell.status;
		if (cell.status === AreaSequenceItemStatus.complete) {
			return;
		}
		const isReadyForReviewUpdate: boolean = cell.status !== AreaSequenceItemStatus.readyForReview;
		updateCell({
			...cell,
			status: isReadyForReviewUpdate ? AreaSequenceItemStatus.readyForReview : AreaSequenceItemStatus.planned,
		});
		try {
			const updatedAreaSequenceItem: IMergedAreaSequenceItem = await updateAreaSequenceItemReadyForReviewStatus(
				cell._id!,
				isReadyForReviewUpdate
			);
			updateCell(updatedAreaSequenceItem);
			if (isReadyForReviewUpdate) {
				successSnackbar(dispatch, translationService.get('markReadyForReviewSuccess'));
			} else {
				successSnackbar(dispatch, translationService.get('undoASISubmissionForReview'));
			}
		} catch (e) {
			errorSnackbar(dispatch, translationService.get('markReadyForReviewFailed'));
			updateCell({
				...cell,
				status: prevStatus,
			});
		}
	};

	const handleCellClick = (cell, event?, updatedAsi?: IMergedAreaSequenceItem) => {
		if (props.mode !== ActivitySequenceMatrixMode.display) {
			return handleScheduleClick(cell, event);
		}
		if (!userHasAccessToActivityActions) {
			return updatedAreaSequenceItemStatusForLimitedUser(cell);
		}
		if (updatedAsi) {
			return updateCell(updatedAsi);
		}
		return handleDoneCell(cell);
	};

	const handleCellHover = useCallback(
		(cell) => {
			setHoveredCell(cell);
		},
		[setHoveredCell]
	);

	const toggleSequenceItemIsStarred = (sequenceItemId: string, isStarred: boolean) => {
		const sequenceItem: ISequenceItem | undefined = sequenceItems.find(
			(sequence) => sequence._id === sequenceItemId
		);
		if (!sequenceItem) {
			return;
		}
		setSequenceItems((prevSequenceItems) => {
			return [
				...prevSequenceItems.map((sequence) => {
					if (sequence._id === sequenceItemId) {
						return { ...sequence, isStarred };
					}
					return sequence;
				}),
			];
		});
	};

	const handleProfessionUpdate = (sequenceItemId: string, profession: IProfession) => {
		const sequenceItem: ISequenceItem | undefined = sequenceItems.find(
			(sequence) => sequence._id === sequenceItemId
		);
		if (!sequenceItem) {
			return;
		}
		setSequenceItems((prevSequenceItems) => {
			return [
				...prevSequenceItems.map((sequence) => {
					if (sequence._id === sequenceItemId) {
						return { ...sequence, profession };
					}
					return sequence;
				}),
			];
		});
		changePropertyOnAreaSequenceItems(sequenceItemId, { profession }, setTables);
	};

	const handleDescriptionChange = useCallback(
		async (text: string, sequenceItemId) => {
			if (!sequenceItemId) return;
			try {
				await requestService.put(`/activities/sequenceItems/${sequenceItemId}`, {
					body: { description: text },
				});
				changePropertyOnAreaSequenceItems(sequenceItemId, { description: text }, setTables);
				setSequenceItems((prevSequenceItems) => {
					return [
						...prevSequenceItems.map((sequence) => {
							if (sequence._id === sequenceItemId) {
								return { ...sequence, description: text, isInitial: false };
							}
							return sequence;
						}),
					];
				});
			} catch (e) {
				console.log(e);
			}
		},
		[setTables]
	);

	const handleStarClick = useCallback(
		async (headerData: IActivitySequenceItemHeaderData) => {
			const currentSequenceItem: ISequenceItem = sequenceItems.find(
				(sequenceItem) => sequenceItem._id === headerData.sequenceItemId
			)!;
			if (!currentSequenceItem) {
				return;
			}
			toggleSequenceItemIsStarred(currentSequenceItem._id, !headerData.isStarred);
			try {
				await requestService.put(`/activities/sequenceItems/${currentSequenceItem._id}`, {
					body: { ...currentSequenceItem, isStarred: !headerData.isStarred },
				});
			} catch (e) {
				errorSnackbar(dispatch, translationService.get('markStarredFailed'));
				toggleSequenceItemIsStarred(currentSequenceItem._id, headerData.isStarred);
			}
		},
		[toggleSequenceItemIsStarred]
	);

	const handleEditSequenceItem = (
		sequenceItemId: string,
		profession: IProfession,
		description: string,
		isStarred: boolean
	) => {
		const sequenceItem: ISequenceItem | undefined = sequenceItems.find(
			(sequence) => sequence._id === sequenceItemId
		);
		if (!sequenceItem) {
			return;
		}
		setSequenceItems((prevSequenceItems) => {
			return [
				...prevSequenceItems.map((sequence) => {
					if (sequence._id === sequenceItemId) {
						return { ...sequence, profession, description, isStarred };
					}
					return sequence;
				}),
			];
		});
		changePropertyOnAreaSequenceItems(sequenceItemId, { profession, description }, setTables);
	};

	const handleTopScroll = (topScroll: number) => {
		setMatrixTopScrollPosition((prevTopScroll) => ({
			...prevTopScroll,
			[TableModeEnum.AREAS]: topScroll + 1,
		}));
	};

	const options: IMatrixOptions = useMemo(() => {
		return {
			cellComponent:
				props.mode === ActivitySequenceMatrixMode.display
					? (ActivitySequenceItemCellCLick as any)
					: (ActivitySequenceItemCellSchedule as any),
			onCellClick: handleCellClick,
			onCellHover: handleCellHover,
			commonHeaderComponent: FloorHeaderSeparator,
			disableDnd:
				!userHasAccessToActivityActions ||
				!config.enableMatrixDnd ||
				props.showOnlyStarred ||
				props.hasProfessionFilter ||
				multiSelectedCells.length > 0 ||
				props.mode === ActivitySequenceMatrixMode.schedule,
			disableColHeaderHover:
				!userHasAccessToActivityActions ||
				!config.enableMatrixDnd ||
				multiSelectedCells.length > 0 ||
				props.mode === ActivitySequenceMatrixMode.schedule,
		};
	}, [props.mode, handleCellClick]);

	const styleOptions = useMemo(() => {
		return {
			cellHeight: '32px',
			cellWidth: `${matrixCellWidth}px`,
			colHeaderHeight: '130px',
			rowHeaderWidth: '200px',
		};
	}, [matrixCellWidth]);

	useLayoutEffect(() => {
		if (tables) {
			const isAllTablesHasOnlyOneRow: boolean = tables.every((table) => table.rows.length === 1);

			if (isAllTablesHasOnlyOneRow) {
				setShouldMatrixFetchAllFloors(true);
				setShowToc(false);
				return;
			}

			setShowToc(props.showToc);
		}
	}, [tables, props.showToc]);

	const onHeadersOrderChange = async (headersIdsByNewOrder: string[]) => {
		try {
			const newSequenceItemsOrder: ISequenceItem[] = headersIdsByNewOrder.map(
				(headerId: string, index: number) => {
					const currSequenceItem = sequenceItems.find(
						(sequenceItem: ISequenceItem) => sequenceItem._id === headerId
					)!;
					return {
						...currSequenceItem,
						orderIndex: index,
					};
				}
			);

			await requestService.put(
				`/activities/sequenceItems/bulk?projectId=${projectId}&sequenceId=${selectedSequenceId}`,
				{
					body: {
						sequenceItems: newSequenceItemsOrder,
					},
				}
			);
			setSequenceItems(newSequenceItemsOrder);
		} catch (err) {
			errorSnackbar(dispatch, translationService.get('updateSequenceItemsOrderFailed'));
		}
	};

	const deleteSequenceItem = useCallback(
		async (sequenceItemId) => {
			if (!sequenceItemId) return;
			try {
				await requestService.delete(`/activities/sequenceItems/${sequenceItemId}?projectId=${projectId}`);
				setSequenceItems((prevSequenceItems) => {
					const newSequenceItems =
						prevSequenceItems.filter((sequenceItem) => sequenceItem._id !== sequenceItemId)! || [];
					return newSequenceItems;
				});
				removeSequenceItemFromTable(sequenceItemId, setTables, setAreaSequenceItemsByFloor);
			} catch (e) {
				errorSnackbar(dispatch, translationService.get('deleteSequenceItemFailed'));
				console.log(e);
			}
		},
		[removeSequenceItemFromTable, setTables, projectId, setSequenceItems, setAreaSequenceItemsByFloor]
	);

	const handleTablesUpdate = (tables: ISequenceMatrixTableData[]) => {
		setUpdatedTables(tables);
	};

	if (!tables && sequenceItems.length) {
		const isRtl: boolean = translationService.getIsRtl();
		return (
			<div
				className={classnames({
					[classes.loaderContainer]: true,
					[classes.rtl]: isRtl,
				})}
			>
				<Lottie
					options={{
						loop: true,
						autoplay: false,
						animationData: MatrixLoader,
					}}
				/>
			</div>
		);
	}

	if (!tables) {
		return <MatrixEmptyState />;
	}

	return (
		<ActivitySequenceMatrixContext.Provider
			value={{
				multiSelectedCells,
				handleMultiSelectedCellClick,
			}}
		>
			<div className={classes.activityMatrixContainer}>
				{showToc && (
					<VerticalTOC
						options={floorsList}
						selectedOption={visibleFloorTable}
						onChange={handleFloorChangeOnToc}
						displayField={'shortFloorNick'}
						idField={'floorId'}
						rootClassName={classes.floorTocContainer}
					/>
				)}
				<Matrix
					onMouseLeave={() => setHoveredCell(null)}
					hoveredCell={{ headerId: hoveredCell?.sequenceItemId, rowId: hoveredCell?.area.areaId }}
					showSequenceMenu
					isTextEditable
					showToc={showToc}
					handleDescriptionChange={handleDescriptionChange}
					options={options}
					onTableIntersectingChange={handleTableIntersectingChange}
					tables={tables}
					styleOptions={styleOptions}
					deleteSequenceItem={deleteSequenceItem}
					handleProfessionUpdate={handleProfessionUpdate}
					handleStarClick={handleStarClick}
					handleEditSequenceItem={handleEditSequenceItem}
					onHeadersOrderChange={onHeadersOrderChange}
					onTablesUpdate={handleTablesUpdate}
					onTopScroll={handleTopScroll}
					topScrollPosition={startingScrollPosition}
				/>
			</div>
		</ActivitySequenceMatrixContext.Provider>
	);
};
