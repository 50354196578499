import { IConfigArea } from '@interfaces/IConfigArea';
import { compact, Dictionary, flatMap, groupBy, orderBy } from 'lodash';

export interface IAreaChip {
	areaIds: string[];
	areaType?: string;
	label: string;
}

const areAreasSequential = (areaId1: string, areaId2: string): boolean => {
	const areaIdOnlyNumbers1: number = parseInt(areaId1.replace(/\D/g, ''), 10);
	const areaIdOnlyNumbers2: number = parseInt(areaId2.replace(/\D/g, ''), 10);
	return Math.abs(areaIdOnlyNumbers1 - areaIdOnlyNumbers2) === 1;
};

export const getAreasChipsFromAreas = (areas: IConfigArea[]): IAreaChip[] => {
	const areasWithoutAreaType: IConfigArea[] = areas.filter((area: IConfigArea) => !area.areaType);
	const combinedAreasTexts: IAreaChip[] = areasWithoutAreaType.map((area: IConfigArea) => ({
		areaIds: [area.areaId],
		areaType: area.areaType,
		label: area.areaNick,
	}));
	const areasWithAreaType: IConfigArea[] = areas.filter((area: IConfigArea) => area.areaType);
	const groupedAreasByAreaType: Dictionary<IConfigArea[]> = groupBy(areasWithAreaType, 'areaType');

	const mergedByAreaType: IAreaChip[] = flatMap(groupedAreasByAreaType, (areas: IConfigArea[], areaType: string) => {
		const areasByFloorId: Dictionary<IConfigArea[]> = groupBy(areas, 'floorId');
		return flatMap(areasByFloorId, (areas: IConfigArea[]) => {
			const sortedByAreaId = orderBy(areas, ['areaId'], ['asc']);
			const areaRanges: IAreaChip[] = [];

			let rangeStart: IConfigArea = sortedByAreaId[0];
			let rangeEnd: IConfigArea = rangeStart;

			for (let i = 1; i < sortedByAreaId.length; i++) {
				const area: IConfigArea = sortedByAreaId[i];

				if (areAreasSequential(rangeEnd.areaId, area.areaId)) {
					rangeEnd = area;
				} else {
					const areaIdsRange: string[] =
						rangeStart === rangeEnd ? [rangeStart.areaId] : [rangeStart.areaId, rangeEnd.areaId];
					const areaChipLabel: string =
						rangeStart === rangeEnd ? rangeStart.areaNick : `${rangeStart.areaNick}-${rangeEnd.areaNick}`;
					const areaChip: IAreaChip = {
						areaIds: areaIdsRange,
						areaType,
						label: areaChipLabel,
					};
					areaRanges.push(areaChip);
					rangeStart = area;
					rangeEnd = area;
				}
			}
			const areaIdsRange: string[] =
				rangeStart === rangeEnd ? [rangeStart.areaId] : [rangeStart.areaId, rangeEnd.areaId];
			const areaChipLabel: string =
				rangeStart === rangeEnd ? rangeStart.areaNick : `${rangeStart.areaNick}-${rangeEnd.areaNick}`;
			const areaChip: IAreaChip = {
				areaIds: areaIdsRange,
				areaType,
				label: areaChipLabel,
			};
			areaRanges.push(areaChip);
			return areaRanges;
		});
	});

	return [...combinedAreasTexts, ...mergedByAreaType];
};

const getAreasFromAreaChips = (areaChips: IAreaChip[], areas: IConfigArea[]): IConfigArea[] => {
	const areasFromAreaChips: IConfigArea[] = flatMap(areaChips, (areaChip: IAreaChip) => {
		const areasFromAreaChip: IConfigArea[] = compact(
			areaChip.areaIds.map((areaId: string) => {
				const area = areas.find((area: IConfigArea) => area.areaId === areaId);
				return area;
			})
		);
		return areasFromAreaChip;
	});
	return areasFromAreaChips;
};
