import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filter } from 'lodash';
import { makeStyles } from '@material-ui/core';
import { TrusstorBox } from '../Boxes/Containers/TrusstorBox';
import { getBuildingSvgPoints, setSvgClicks } from '../../utils/svgHelpers';
import {
	compareProps,
	filterVisibleProfessions,
	filterFloorVisibleManagers,
	filterVisibleUtilitiesTags,
} from '../../utils/generalUtils';
import { usePrevious, useWindowSize } from '../../hooks/custom.hooks';
import { SafetyEventDialog } from '../Safety/SafetyEventDialog';
import { COLORS, SafetyEventStatuses } from '../../constants';
import { BiSafetyEventsActionSource } from '../../constants/bi.constants';
import { useHistory } from 'react-router-dom';
import { IRootState } from '../../store/slices';
import { IManager, IProfession } from '../../interfaces';
import { IMergedFloorView } from '../../interfaces/IMergedFloorView';
import { IProfessionWithActiveTags } from '../../interfaces/IProfessionWithActiveTags';
import { BuildingDetails } from './BuildingDetails';
import { IFloorData } from '../../interfaces/IFloorData';
import { IBuildingSvgPoints } from '../../interfaces/IBuildingSvgPoints';
import { IMinimalSafetyEventForClient } from '../../interfaces/ISafetyEvent';
import { updateCurrentlyVisibleEvents } from '../../store/slices/safetyEvents.slice';
import { sendSafetyEvent_BI } from '../../utils/bi.utils';
import { getFloorsData, stopGettingFloorsData } from '../../store/thunks';
import { resetSelectedFloor, selectFloor } from '../../store/slices/floorsView.slice';
import { useParams } from 'react-router-dom';
import { textStyle } from '@shared/utils/text.utils';
import { floorTypes } from '@shared/constants/floors.constants';
import { useBuildingSvgQuery } from '@src/hooks/queries/getBuildingSvg.hooks';
import { Loader } from '@shared/components/Loader/Loader';
import { setFloorPathForFloorView } from '@utils/floors.utils';
import { selectProjectId } from '@store/slices/project.slice';
import { selectSelectedUtilities } from '@store/slices/utilities.slice';
import { IUtilityMergedTag } from '@interfaces/IUtilityMergedTag';

const useStyles = makeStyles((theme) => ({
	safetyIconText: textStyle({
		color: '#3b3b3b',
		fontClass: 'body1',
		textAlign: 'center',
	}),
	elevatorWrapper: {
		position: 'absolute',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	box: () => ({
		position: 'relative',
		overflowY: 'auto',
		overflowX: 'hidden',
		display: 'block',
		padding: 0,
		height: '100%',
		left: '2%',
		width: 'calc(100% - 30px)',
	}),
	paSvgContainer: () => ({
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	}),
	wrapper: {
		flexGrow: 1,
		marginBottom: theme.spacing(1),
		marginTop: '40px',
	},
	buttonLabel: textStyle({
		color: COLORS.black,
		fontClass: 'h2',
	}),
}));

export const BUILDING_WRAPPER_ID = 'building-wrapper';

export const MainView = React.memo(() => {
	const projectId: string = useSelector(selectProjectId)!;
	const floorsView: IMergedFloorView[] = useSelector((state: IRootState) => state.floorsViewReducer.floors);
	const safetyEvents: IMinimalSafetyEventForClient[] = useSelector(
		(state: IRootState) => state.safetyEventsReducer.safetyEvents
	);
	const visibleProfessions: IProfession[] = useSelector(
		(state: IRootState) => state.professionsReducer.visibleProfessions
	);
	const visibleManagers: IManager[] = useSelector((state: IRootState) => state.managersReducer.visibleManagers);
	const selectedUtilities: IUtilityMergedTag[] = useSelector(selectSelectedUtilities);
	const workingProjectId: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.projectId);
	const selectedFloor: any = useSelector((state: IRootState) => state.floorsViewReducer.selectedFloor);
	const selectedStaticEquipment: any = useSelector(
		(state: IRootState) => state.equipmentReducer.selectedStaticEquipment
	);

	const classes = useStyles({ isSelectdFloor: !!selectedFloor });
	const [svgPoints, setSvgPoints] = useState<IBuildingSvgPoints | null>(null);
	const [safetyDialogOpen, setSafetyDialogOpen] = useState<boolean>(false);
	const [svgClicksApplied, setSvgClicksApplied] = useState<boolean>(false);
	const [screenWidth, screenHeight] = useWindowSize();
	const buildingRef = useRef<HTMLDivElement>(null);
	const buildingWidth = buildingRef.current?.clientWidth;
	const prevProjectId = usePrevious(workingProjectId);
	const dispatch = useDispatch();
	const history = useHistory();
	const [buildingSvg, setBuildingSvg] = useState<string>('');

	const { buildingSvg: buildingSvgFile, isLoadingSvg } = useBuildingSvgQuery(workingProjectId);

	const { floorId }: { floorId: string } = useParams();

	useEffect(() => {
		if (floorsView.length === 0) {
			return;
		}
		if (selectedFloor && !floorId) {
			dispatch(resetSelectedFloor());
			return;
		}
		if (floorId) {
			dispatch(selectFloor({ floorId }));
		}
	}, [floorId, floorsView.length]);

	useEffect(() => {
		dispatch(getFloorsData(workingProjectId));
		return () => {
			dispatch(resetSelectedFloor());
			dispatch(stopGettingFloorsData());
		};
	}, [workingProjectId]);

	const floorsWithoutStorage: IMergedFloorView[] = floorsView.filter((floor) => floor.floorId !== floorTypes.storage);
	const floorsData: IFloorData[] = floorsWithoutStorage.map((floor: IMergedFloorView) => {
		const defaultFloor: IFloorData = {
			...floor,
			managers: filterFloorVisibleManagers(floor.managers, visibleManagers),
			utilities: filterVisibleUtilitiesTags(floor.utilities || [], selectedUtilities),
			safetyAlerts: filter(safetyEvents, {
				floorId: floor.floorId,
				status: SafetyEventStatuses.active,
			}),
		};

		const activeProfessions: IProfessionWithActiveTags[] = filterVisibleProfessions(
			floor.activeProfessions,
			visibleProfessions
		);
		return {
			...defaultFloor,
			activeProfessions: activeProfessions,
		};
	});

	const openSafetyDialog = (currentSafetyEvents: IMinimalSafetyEventForClient[]) => {
		dispatch(updateCurrentlyVisibleEvents({ visibleSafetyEvents: currentSafetyEvents }));
		sendSafetyEvent_BI(
			BiSafetyEventsActionSource.buildingMainView,
			currentSafetyEvents[0]._id,
			currentSafetyEvents.length
		);
		setSafetyDialogOpen(true);
	};

	const handleSafetyDialogClose = () => {
		setSafetyDialogOpen(false);
	};

	const setFloorPath = (floorId: string) => setFloorPathForFloorView(floorId, history);

	useEffect(() => {
		if ((svgClicksApplied && prevProjectId !== workingProjectId) || floorsData.length === 0) {
			return;
		}
		if (buildingSvg) {
			setSvgClicks(setFloorPath, floorsData);
			setSvgClicksApplied(true);
		}
	}, [buildingSvg, setFloorPath, floorsData, svgClicksApplied, prevProjectId, workingProjectId]);

	useEffect(() => {
		if (buildingSvg) {
			setSvgPoints(getBuildingSvgPoints('building'));
		}
	}, [
		buildingWidth,
		screenWidth,
		screenHeight,
		buildingSvg,
		svgClicksApplied,
		selectedFloor,
		selectedStaticEquipment.length,
	]);

	useEffect(() => {
		const svgFileEdited: string = buildingSvgFile.replace('<svg', '<svg id="building" style="opacity: 0.8;" ');
		if (buildingSvgFile !== '' && svgFileEdited !== buildingSvg) {
			setBuildingSvg(svgFileEdited);
		}
	}, [buildingSvgFile]);

	if (isLoadingSvg && buildingSvg === '') {
		return <Loader />;
	}

	// selecting a floor resizes the svg, manipulating the points.
	// dependency array. props or states that when changed, useEffect will run. if array is empty,
	// then useEffect isn't 'dependant' on any state or prop, so it runs only once, after initial render.

	return (
		<div className={classes.wrapper}>
			<TrusstorBox customStyleClass={classes.box} id={BUILDING_WRAPPER_ID}>
				<div
					id="buildingBox"
					className={classes.paSvgContainer}
					dangerouslySetInnerHTML={{ __html: buildingSvg }}
					ref={buildingRef}
				/>
				<BuildingDetails
					buildingSvg={buildingSvg}
					svgPoints={svgPoints}
					floorsData={floorsData}
					openSafetyDialog={openSafetyDialog}
					setFloorPath={setFloorPath}
				/>
				{safetyDialogOpen && <SafetyEventDialog handleClose={handleSafetyDialogClose} />}
			</TrusstorBox>
		</div>
	);
}, compareProps);
