import React, { forwardRef, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { textStyle } from '@shared/utils/text.utils';
import arrowLeft from '@assets/floor_icon_arrow_left.svg';
import arrowRight from '@assets/floor_icon_arrow_right.svg';
import './styles.module.css';
import { translationService } from '../../../index';
import { COLORS } from '../../../constants';
import {
	convertDateObjectToServerFormat,
	generateTimezoneMultipleWeekDatesRange,
	getDate,
	getProjectDateWithBrowserTZOffset,
	getTimezoneStartOfDate,
} from '@utils/dateUtils';
import { useSelector } from 'react-redux';
import { DIRECTIONS } from '@shared/constants/directions';
import { IRootState } from '@store/slices';
import { DatePickerHeader } from '@shared/components/DatePickerHeader/DatePickerHeader';

const useStyles = makeStyles((theme) => ({
	containerDiv: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		width: '70%',
	},
	floatingHeaderContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		top: theme.spacing(2),
		margin: 'auto',
		border: '1px solid #CDCDCD',
		borderRadius: '4px',
	},
	datePickerContainer: ({ isRtl }: any) => ({
		flex: '2 100%',
	}),
	datePickerWrapper: ({ datePickerButtonWidth }: any) => ({
		width: datePickerButtonWidth,
	}),
	dateButton: {
		width: '100%',
		zIndex: 10,
		height: 24,
		cursor: 'pointer',
		userSelect: 'none',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		border: 0,
		background: 'none',
		boxShadow: 'none',
	},
	dateInput: textStyle({
		fontClass: 'body1',
		color: '#2f2d2d',
		fontWeight: 500,
		fontFamily: 'Heebo, sans-serif',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	}),
	arrow: {
		height: 9,
		width: 9,
		margin: '0 4px',
		cursor: 'pointer',
		flex: 1,
	},
	datePicker: {
		fontSize: '0.7rem',
	},
	datePopper: {
		zIndex: 100,
	},
	calendarDay: {
		width: '1.3rem',
		lineHeight: '1.3rem',
	},
	weekDay: {
		width: '1.3rem',
	},
	calenderWeekHover: {
		'&:hover': {
			backgroundColor: COLORS.white,
		},
		'backgroundColor': COLORS.black,
	},
}));

interface IDateSelectorProps {
	updateDate: (date: Date | Date[]) => void;
	isWeek: boolean;
	weekDatesRange: Date | Date[] | number[];
	dateLimit?: Date | null;
	numberOfWeeks?: number;
	datePickerButtonWidth?: string;
	initialDate?: Date;
}

// This component is not part of the design system, it is only used on the calendar page.
// It is not recommended to use it in other places. After changing the calendar page to the design system, this component will be deleted.
const DateSelectorWithBackAndForward = ({
	updateDate,
	isWeek,
	weekDatesRange,
	dateLimit = null,
	numberOfWeeks = 1,
	datePickerButtonWidth = '100%',
	...props
}: IDateSelectorProps) => {
	const isRtl = translationService.getDirection() === DIRECTIONS.RTL;
	const classes = useStyles({ datePickerButtonWidth, isRtl });
	const tz = useSelector((state: IRootState) => state.projectReducer.workingProject!.tz);
	const [date, setDate] = useState(props.initialDate || getTimezoneStartOfDate(tz));
	const [isDateInputDisabled, setIsDateInputDisabled] = useState(false);

	const DateInput = forwardRef(({ value, onClick }: any, ref: any) => {
		return (
			<button
				className={classes.dateButton}
				disabled={isDateInputDisabled}
				onClick={onClick}
				ref={ref}
				data-testid={'dateInput'}
			>
				<div className={classes.dateInput}>
					{isWeek
						? `${moment.tz(weekDatesRange[0], tz).format(translationService.getDateFormat())} - ${moment
								.tz(weekDatesRange[(weekDatesRange as []).length - 1], tz)
								.format(translationService.getDateFormat())}`
						: value}
				</div>
			</button>
		);
	});

	const getMaxDate = (dateLimit) => (dateLimit ? getDate(dateLimit) : undefined);
	const getMaxDateMoment = (dateLimit) => (dateLimit ? moment.tz(dateLimit, tz) : undefined);

	const incrementOneDay = () => {
		return setDate(new Date(date.setDate(date.getDate() + 1)));
	};

	const decreaseOneDay = () => {
		return setDate(new Date(date.setDate(date.getDate() - 1)));
	};

	const incrementOneWeek = () => {
		const newWeekStartDate = new Date(moment.tz(weekDatesRange[0], tz).add(1, 'w').valueOf());
		setDate(newWeekStartDate);
		return updateDate(generateTimezoneMultipleWeekDatesRange(newWeekStartDate, tz, numberOfWeeks));
	};

	const decreaseOneWeek = () => {
		const lastWeekStartDate = new Date(moment.tz(weekDatesRange[0], tz).subtract(1, 'w').valueOf());
		setDate(lastWeekStartDate);
		return updateDate(generateTimezoneMultipleWeekDatesRange(lastWeekStartDate, tz, numberOfWeeks));
	};

	useEffect(() => {
		if (!isWeek) {
			updateDate(date);
		}
	}, [date, isWeek]);

	useEffect(() => {
		if (isWeek) {
			updateDate(generateTimezoneMultipleWeekDatesRange(date, tz, numberOfWeeks));
		}
	}, [numberOfWeeks]);

	const onChangeDate = (date) => {
		const startOfDate: Date = getTimezoneStartOfDate(tz, convertDateObjectToServerFormat(date));
		if (isWeek) {
			updateDate(generateTimezoneMultipleWeekDatesRange(startOfDate, tz, numberOfWeeks));
		}

		setDate(startOfDate);
	};

	return (
		<div className={classes.containerDiv}>
			<div className={classes.floatingHeaderContainer}>
				<img
					src={isRtl ? arrowRight : arrowLeft}
					alt="arrowDec"
					className={classes.arrow}
					onClick={() => {
						if (isWeek) {
							return decreaseOneWeek();
						}
						return decreaseOneDay();
					}}
				/>
				<div className={classes.datePickerContainer}>
					<DatePicker
						renderCustomHeader={({
							monthDate,
							decreaseMonth,
							increaseMonth,
							prevMonthButtonDisabled,
							nextMonthButtonDisabled,
							customHeaderCount,
						}) => (
							<DatePickerHeader
								monthDate={monthDate}
								decreaseMonth={decreaseMonth}
								increaseMonth={increaseMonth}
								prevMonthButtonDisabled={prevMonthButtonDisabled}
								nextMonthButtonDisabled={nextMonthButtonDisabled}
								customHeaderCount={customHeaderCount}
								monthsShown={1}
							/>
						)}
						formatWeekDay={(nameOfDay) => translationService.get(`datepicker_weekday_${nameOfDay}`)}
						selected={getProjectDateWithBrowserTZOffset(tz, date)}
						onChange={onChangeDate}
						popperClassName={classes.datePopper}
						popperPlacement={'bottom'}
						dayClassName={() => classes.calendarDay}
						weekDayClassName={() => classes.weekDay}
						calendarClassName={classes.datePicker}
						className={classes.calenderWeekHover}
						wrapperClassName={classes.datePickerWrapper}
						customInput={<DateInput />}
						maxDate={getMaxDate(dateLimit)}
						onCalendarOpen={() => setIsDateInputDisabled(true)}
						onCalendarClose={() => setIsDateInputDisabled(false)}
						highlightDates={
							isWeek
								? (weekDatesRange as Date[]).map((projectDate) =>
										getProjectDateWithBrowserTZOffset(tz, projectDate)
									)
								: undefined
						}
					/>
				</div>
				<img
					src={isRtl ? arrowLeft : arrowRight}
					alt="arrowInc"
					className={classes.arrow}
					onClick={() => {
						const maxDate = getMaxDateMoment(dateLimit);
						const diff = maxDate?.diff(date, 'd');
						if ((isWeek && diff && diff > 6) || !diff) {
							return incrementOneWeek();
						}
						return diff > 0 ? incrementOneDay() : null;
					}}
				/>
			</div>
		</div>
	);
};

export { DateSelectorWithBackAndForward };
