import React, { useState } from 'react';
import { groupBy } from 'lodash';
import Dialog from '@material-ui/core/Dialog';
import classes from './styles.module.scss';
import { IIssueLocation } from '../../../interfaces/IIssueShared';
import { TrusstorIconButtonDeprecated } from '../../buttons/TrusstorIconButton/TrusstorIconButtonDeprecated';
import { IconColor, IconSize, TrusstorIconShared } from '../../TrusstorIconShared/TrusstorIconShared';
import { IconNames } from '../../TrusstorIconShared/IconNames.enum';
import { MultiFloorPlanDisplay } from '../../MultiFloorPlanDisplay/MultiFloorPlanDisplay';

interface ILocationItemProps {
	locations: IIssueLocation[];
	projectId: string;
	onMapClick?: (e) => void;
	rootClass?: string;
}

export const LocationItem = (props: ILocationItemProps): JSX.Element => {
	const [showPointsView, setShowPointsView] = useState<boolean>(false);
	const doesHaveSelectedPoints: boolean = props.locations.some((location) => location.area.selectedPoints?.length);

	const locationsGroupedByFloor: Record<string, IIssueLocation[]> = groupBy(
		props.locations,
		(location) => location.floor.floorId
	);

	const onPointsViewClick = (e): void => {
		e.stopPropagation();
		setShowPointsView(true);
		props.onMapClick?.(e);
	};

	const onDialogClose = (): void => {
		setShowPointsView(false);
	};

	return (
		<div className={classes.issueLocationContainer}>
			<div className={`${classes.locationItem} ${props.rootClass ? props.rootClass : ''}`}>
				<TrusstorIconShared size={IconSize.SMALL} iconName={IconNames.floors} color={IconColor.Grey600} />
				<div className={classes.floorText}>
					{Object.values(locationsGroupedByFloor).map((locations, index) => {
						const floor = locations[0].floor;
						return (
							<span key={floor.floorId}>
								{floor.floorNick}, {locations.map((location) => location.area.areaNick).join(', ')}{' '}
								{index !== Object.values(locationsGroupedByFloor).length - 1 ? ' | ' : ''}
							</span>
						);
					})}
				</div>
			</div>
			{doesHaveSelectedPoints && (
				<TrusstorIconButtonDeprecated
					iconElement={<TrusstorIconShared iconName={IconNames.map} />}
					onClick={onPointsViewClick}
				/>
			)}
			{showPointsView && (
				<Dialog
					open={showPointsView}
					onClose={onDialogClose}
					classes={{
						paper: `${classes.dialog}`,
					}}
				>
					<MultiFloorPlanDisplay projectId={props.projectId} issueLocations={props.locations} />
				</Dialog>
			)}
		</div>
	);
};
