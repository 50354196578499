import React, { useRef, useState } from 'react';
import classes from './styles.module.scss';
import { accessLevelService, projectAccessLevelService, translationService } from '../../../../index';
import { InvestigationReportTypes } from '@shared/interfaces/InvestigationReportTypes.enum';
import { InvestigationReportCard } from './InvestigationReportCard/InvestigationReportCard';
import { TimesheetReportCard } from './WorkersTimesheetReportCard/WorkersTimesheetReportCard';
import { ObserveLocationCard } from '@src/Components/Pages/AnalysisCenterPages/CardsSection/ObserveLocationCard/ObserveLocationCard';
import { ProjectOverviewCard } from '@src/Components/Pages/AnalysisCenterPages/CardsSection/ProjectOverviewCard/ProjectOverviewCard';
import { ContractorAnalysisCard } from '@src/Components/Pages/AnalysisCenterPages/CardsSection/ContractorAnalysisCard/ContractorAnalysisCard';
import { useWorkingProfessions } from '@src/hooks/useWorkingProfessions.hook';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { ProgressAnalysisReportCard } from '@src/Components/Pages/AnalysisCenterPages/CardsSection/ProgressAnalysisReportCard/ProgressAnalysisReportCard';
import { FeatureFlagNamesEnum } from '@shared/constants/featureFlags.enum';
import { useIsFeatureFlagEnabled } from '@shared/hooks/useFeatureFlag';
import { ScissorLiftExportCard } from '@src/Components/ScissorLiftExportCard/ScissorLiftExportCard';

interface ICardsSectionProps {
	areThereInvestigationReports: boolean;
}

const CardsSection = (props: ICardsSectionProps) => {
	const [selectedCard, setSelectedCard] = useState<InvestigationReportTypes | null>(null);
	const cardRow1 = useRef(null);
	const cardRow2 = useRef(null);
	const isSequenceProgressAnalysisEnabled: boolean = useIsFeatureFlagEnabled(
		FeatureFlagNamesEnum.sequenceProgressAnalysis
	);
	const isScissorLiftAnalysisEnabled: boolean = useIsFeatureFlagEnabled(FeatureFlagNamesEnum.scissorLiftAnalysis);

	useWorkingProfessions({
		includeDeleted: false,
	});

	const setSelectedCardHandler = (name?: string) => {
		setSelectedCard((name as InvestigationReportTypes) || null);
	};

	return (
		<div className={classes.allCardsContainer}>
			{isSequenceProgressAnalysisEnabled &&
				projectAccessLevelService.hasAccess('progressAnalysisPage') &&
				accessLevelService.hasAccess('projectProgressAnalysisAccess') && <ProgressAnalysisReportCard />}
			<section className={classes.cardsContainer}>
				{selectedCard && (
					<div
						style={{ opacity: '30%' }}
						onClick={() => setSelectedCard(null)}
						className={classes.greyBackground}
					></div>
				)}
				<div className={classes.cardsRow} ref={cardRow1}>
					{isScissorLiftAnalysisEnabled && (
						<ScissorLiftExportCard
							isSelected={selectedCard === InvestigationReportTypes.scissorLiftReportExport}
							setSelectedCard={setSelectedCardHandler}
						/>
					)}
					<ProjectOverviewCard
						name={InvestigationReportTypes.projectOverview}
						isSelected={selectedCard === InvestigationReportTypes.projectOverview}
						icon={IconNames.clipboard}
						title={translationService.get('executiveSummary')}
						description={translationService.get('projectOverviewCardDescription')}
						setSelectedCard={setSelectedCardHandler}
					/>
					<ContractorAnalysisCard
						name={InvestigationReportTypes.contractorAnalysis}
						isSelected={selectedCard === InvestigationReportTypes.contractorAnalysis}
						icon={IconNames.helmet}
						title={translationService.get('contractorAnalysis')}
						description={translationService.get('contractorAnalysisCardDescription')}
						setSelectedCard={setSelectedCardHandler}
					/>
					<TimesheetReportCard
						isSelected={selectedCard === InvestigationReportTypes.workersTimesheet}
						setSelectedCard={setSelectedCardHandler}
					/>
				</div>
				<div className={classes.cardsRow} ref={cardRow2}>
					<InvestigationReportCard
						name={InvestigationReportTypes.incidentReport}
						isSelected={selectedCard === InvestigationReportTypes.incidentReport}
						icon={IconNames.calendarPlus}
						title={translationService.get('incidentReport')}
						description={translationService.get('discoverPresentDuringPersonIncident')}
						setSelectedCard={setSelectedCardHandler}
						showTimeSelector
					/>
					<InvestigationReportCard
						name={InvestigationReportTypes.dailyRoute}
						isSelected={selectedCard === InvestigationReportTypes.dailyRoute}
						icon={IconNames.route}
						title={translationService.get('dailyRoute')}
						description={translationService.get('trackWhereAboutsGivenDayPersonJobSite')}
						setSelectedCard={setSelectedCardHandler}
					/>
					<ObserveLocationCard
						name={InvestigationReportTypes.observeLocation}
						isSelected={selectedCard === InvestigationReportTypes.observeLocation}
						icon={IconNames.pin}
						title={translationService.get('observeLocation')}
						description={translationService.get('findOutOnSpecificLocation')}
						setSelectedCard={setSelectedCardHandler}
					/>
				</div>
			</section>
		</div>
	);
};

export { CardsSection };
