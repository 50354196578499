import React, { useState } from 'react';
import { IWeekDayActivity } from '../../../interfaces';
import { ACTIVITY_CHIP_HEIGHT, CalendarToggleOptions, COLORS } from '../../../constants';
import { ActivityGroupStatus } from '@shared/interfaces/ActivityGroupStatus.enum';
import redFlag from '../../../assets/workplanIcons/redFlag.svg';
import classes from './styles.module.scss';
import { getFloorsDisplayText } from '../../../utils/calendar.utils';
import { CalendarCellTooltip } from '../../Tooltips/CalendarCellTooltip/CalendarCellTooltip';
import TrusstorTooltip from '@shared/components/TrusstorTooltip';
import { IconsComponent } from './IconsComponent';
import verticalLine from '@assets/workplanIcons/verticalLine.svg';
import { v4 as uuidv4 } from 'uuid';
import { accessLevelService, translationService } from '@src/index';
import { threeDotsVertical } from '@shared/assets/icons/';
import { getColorFromActivityGroupStatus, getHoverColorFromActivityGroupStatus } from '@utils/generalUtils';

interface ICalendarCellChipsContentProps {
	activity: IWeekDayActivity;
	maxChipWidth: number;
	workPlanToggle?: CalendarToggleOptions;
	disableTooltip?: boolean;
	onThreeDotsClick?: (e) => void;
	maxContentWidth: number;
	chipWidth: number;
	click: (e) => void;
}

const FloorTextComponent = ({ floorsDisplayText }: { floorsDisplayText: string }) => {
	const floorsTextId: string = `floorsText-${uuidv4()}`;
	return (
		<TrusstorTooltip cleanStyling text={floorsDisplayText} idToOverflow={floorsTextId}>
			<p id={floorsTextId} className={classes.floorsText} data-testid={'floorsText'}>
				{floorsDisplayText}
			</p>
		</TrusstorTooltip>
	);
};

const ProfessionDetailsComponent = ({ activity }: { activity: IWeekDayActivity }) => {
	return (
		<div className={classes.professionDetails} data-testid={'professionDetails'}>
			{activity.profession.contractor && (
				<>
					<TrusstorTooltip showAlways={true} text={translationService.get('contractor')}>
						<p className={classes.professionText}>{activity.profession.contractor}</p>
					</TrusstorTooltip>
				</>
			)}
			{activity.profession.contractor && activity.profession.specialty && (
				<>
					<img style={{ height: '16px' }} src={verticalLine} alt="vertical line icon"></img>
				</>
			)}
			{activity.profession.specialty && (
				<>
					<TrusstorTooltip showAlways={true} text={translationService.get('specialty')}>
						<p className={classes.professionText}>{activity.profession.specialty}</p>
					</TrusstorTooltip>
				</>
			)}
		</div>
	);
};

const getActivityDisplayTextElement = (
	activity: IWeekDayActivity,
	workPlanToggle?: CalendarToggleOptions
): JSX.Element => {
	const floorsDisplayText: string = getFloorsDisplayText(activity);
	if (!workPlanToggle || workPlanToggle === CalendarToggleOptions.PROFESSIONS) {
		return <FloorTextComponent floorsDisplayText={floorsDisplayText} />;
	}

	if (!activity.profession.contractor && !activity.profession.specialty) {
		return <></>;
	}

	return <ProfessionDetailsComponent activity={activity} />;
};

export const CalendarCellChipContent = (props: ICalendarCellChipsContentProps) => {
	const [shouldShowTooltip, setShouldShowTooltip] = useState(false);
	const [isChipHover, setIsChipHover] = useState<boolean>(false);
	const chipColor: string = getColorFromActivityGroupStatus(props.activity.status);
	const chipTestId: string = `description=${props.activity.description}&activityId=${props.activity._id}&groupId=${props.activity.groupId}`;

	return (
		<div
			style={{
				width: 'max-content',
			}}
		>
			<div
				className={classes.chipRoot}
				style={{
					backgroundColor: isChipHover
						? getHoverColorFromActivityGroupStatus(props.activity.status)
						: chipColor,
					height: ACTIVITY_CHIP_HEIGHT,
					maxWidth: props.chipWidth ? `${props.chipWidth}px` : '100%',
					minWidth: props.chipWidth ? `${props.chipWidth}px` : undefined,
					border: `1px solid ${chipColor === COLORS.white ? '#c4c4c4' : undefined}`,
				}}
				onClick={props.click}
				onMouseEnter={() => setIsChipHover(true)}
				onMouseLeave={() => setIsChipHover(false)}
				data-testid={chipTestId}
			>
				<div className={classes.labelRoot} style={{ maxWidth: props.maxContentWidth - 6 }}>
					<div className={classes.labelContainer}>
						<p className={classes.text}>
							<TrusstorTooltip
								key={`${props.activity._id}`}
								cleanStyling
								tooltipComponent={
									<CalendarCellTooltip
										activity={props.activity}
										isViewByFloor={props.workPlanToggle === CalendarToggleOptions.FLOORS}
									/>
								}
								idToOverflow={`chipText-${props.activity._id}`}
								dependencies={[props.maxChipWidth]}
								showAlways={shouldShowTooltip}
								disableTooltip={props.disableTooltip}
							>
								<div className={classes.contentWrapper} data-testid="chip-content">
									<div className={classes.activityDisplayContainer}>
										<div className={classes.flexBox}>
											{props.activity.status === ActivityGroupStatus.overdue && (
												<img src={redFlag} alt="red flag" />
											)}
											<div className={classes.flexBox}>
												<p className={classes.descriptionText} data-testid={'description'}>
													{props.activity.description}
												</p>
												{accessLevelService.hasAccess('workplanActivityActions') && (
													<div className={classes.menuDots} onClick={props.onThreeDotsClick}>
														<img
															alt={''}
															data-testid={'threeDots'}
															src={threeDotsVertical}
														/>
													</div>
												)}
											</div>
										</div>
										<div className={classes.activityDetails}>
											{getActivityDisplayTextElement(props.activity, props.workPlanToggle)}
											<IconsComponent
												setShouldShowTooltip={setShouldShowTooltip}
												activity={props.activity}
												chipWidth={props.maxChipWidth}
												workPlanToggle={props.workPlanToggle}
											/>
										</div>
									</div>
								</div>
							</TrusstorTooltip>
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};
