import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { IConfigArea } from '@interfaces/IConfigArea';
import { IFloor, IFloorChipData } from '@src/interfaces';
import { SimpleChip } from '@shared/components/SimpleChip/SimpleChip';
import { SORT_ORDER } from '@shared/constants/constants';
import {
	getFloorsChipFromFloorsList,
	getFloorsListFromFloorsChips,
	getUnitedFloorChipText,
} from '@shared/utils/floorsSelection.utils';
import { ToggleSection } from '@src/Components/WorkPlan/ToggleSection';
import { COLORS } from '@src/constants';
import { useProjectAreasQuery } from '@src/hooks/queries/areas.queries.hooks';
import { useFloorDataQuery } from '@src/hooks/queries/floors.queries.hooks';
import { translationService } from '@src/index';
import { selectProjectId } from '@store/slices/project.slice';
import { getAreasChipsFromAreas, IAreaChip } from '@utils/areasChips.utils';
import { useSelector } from 'react-redux';
import classes from './styles.module.scss';
import { IBaseFloor } from '@shared/interfaces/Floors/IBaseFloor';
import classnames from 'classnames';
import { sortByAreas, sortFloors } from '@shared/utils/sort.utils';
import { MultipleDropdown } from '@src/Components/Dropdowns/DesignSystem/MultipleDropdown/MultipleDropdown';
import { GroupedMultipleDropdown } from '@src/Components/Dropdowns/DesignSystem/GroupedMultipleDropdown/GroupedMultipleDropdown';
import { isEqual } from 'lodash';

enum LOCATION_INPUT_TOGGLE {
	floors = 'floors',
	areas = 'areas',
}

interface ILocationSelectorProps {
	onChange: (floors: IBaseFloor[], areas: IConfigArea[]) => void;
	isDisabled?: boolean;
	selectedFloors?: IBaseFloor[];
	selectedAreas?: IConfigArea[];
	setInitialSelectedFloors?: (floors: IBaseFloor[]) => void;
	setInitialSelectedAreas?: (areas: IConfigArea[]) => void;
}

export const LocationSelector = (props: ILocationSelectorProps) => {
	const projectId: string = useSelector(selectProjectId)!;
	const projectAreas: IConfigArea[] = useProjectAreasQuery(projectId);
	const floors: IFloor[] = useFloorDataQuery(projectId);
	const sortedFloors: IFloor[] = sortFloors(floors, SORT_ORDER.DESCENDING);
	const [selectedFloors, setSelectedFloors] = useState<IBaseFloor[] | undefined>(props.selectedFloors || undefined);
	const [selectedAreas, setSelectedAreas] = useState<IConfigArea[] | undefined>(props.selectedAreas || undefined);
	const [floorAreaToggle, setFloorAreaToggle] = useState<LOCATION_INPUT_TOGGLE | undefined>(undefined);
	const prevSelectedFloorsRef = useRef(selectedFloors);
	const prevSelectedAreasRef = useRef(selectedAreas);

	useLayoutEffect(() => {
		if (floorAreaToggle || (!props.selectedAreas && !props.selectedFloors)) {
			return;
		}

		const defaultFloorAreaToggle: LOCATION_INPUT_TOGGLE = props.selectedAreas?.length
			? LOCATION_INPUT_TOGGLE.areas
			: LOCATION_INPUT_TOGGLE.floors;
		setFloorAreaToggle(defaultFloorAreaToggle);
	}, [props.selectedFloors, props.selectedAreas]);

	useEffect(() => {
		if (
			!isEqual(prevSelectedFloorsRef.current, selectedFloors) ||
			!isEqual(prevSelectedAreasRef.current, selectedAreas)
		) {
			props.onChange(selectedFloors || [], selectedAreas || []);
			prevSelectedFloorsRef.current = selectedFloors;
			prevSelectedAreasRef.current = selectedAreas;
		}
	}, [selectedFloors, selectedAreas]);

	const selectedAreasChips: IAreaChip[] = useMemo(() => {
		if (!selectedAreas?.length) {
			return [];
		}
		return getAreasChipsFromAreas(selectedAreas);
	}, [selectedAreas]);

	const selectedFloorsChips: IFloorChipData[] = useMemo(() => {
		if (!selectedFloors?.length) {
			return [];
		}
		return getFloorsChipFromFloorsList(selectedFloors);
	}, [selectedFloors]);

	const handleToggleChange = (toggleState: LOCATION_INPUT_TOGGLE) => {
		if (!toggleState || toggleState === floorAreaToggle) {
			return;
		}

		setSelectedFloors([]);
		setSelectedAreas([]);
	};

	const sortedAreas: IConfigArea[] = useMemo(() => {
		return sortByAreas(projectAreas, SORT_ORDER.DESCENDING);
	}, [projectAreas]);

	const areasGroupedMultiDropdown = (
		<GroupedMultipleDropdown<IConfigArea>
			disabled={props.isDisabled}
			getDisplayOption={(area: IConfigArea) => area.areaNick}
			groupByFunction={(option: IConfigArea) => {
				return option.floorNick;
			}}
			options={sortedAreas}
			onChange={(options) => {
				setSelectedAreas(options);
			}}
			optionSelectedKey={'areaId'}
			value={props.selectedAreas}
			label={translationService.get('selectLocation')}
			totalSelectedInputText={translationService.get('locations')}
			testId={'areasAutocomplete'}
			required
		/>
	);

	const floorsAutocompleteElement = (
		<MultipleDropdown
			options={sortedFloors}
			value={props.selectedFloors}
			onChange={(options) => setSelectedFloors(options)}
			label={translationService.get('selectLocation')}
			required
			getDisplayOption={(option) => option.floorNick}
			optionSelectedKey={'floorId'}
			testId={'floorsAutocomplete'}
			disabled={props.isDisabled}
		/>
	);

	const deleteFloorChip = (chip: IFloorChipData) => {
		const chipsAfterDeletion: IFloorChipData[] = selectedFloorsChips.filter(
			(selectedChip) => selectedChip.key !== chip.key
		);
		const newFloors = getFloorsListFromFloorsChips(chipsAfterDeletion, floors);
		setSelectedFloors(newFloors);
		props.setInitialSelectedFloors?.(newFloors);
	};

	const deleteAreaChip = (chip: IAreaChip) => {
		const selectedAreasAfterDeletion: IConfigArea[] = selectedAreas!.filter(
			(selectedChip) => !chip.areaIds.includes(selectedChip.areaId)
		);
		setSelectedAreas(selectedAreasAfterDeletion);
		props.setInitialSelectedAreas?.(selectedAreasAfterDeletion);
	};

	const containerClass: string = classnames({
		[classes.disabled]: props.isDisabled,
	});

	return (
		<div className={containerClass} data-testid={'locationSelector'}>
			<div className={classes.locationSelect}>
				<p className={classes.selectLocationText}>{translationService.get('selectLocation')}</p>
				<ToggleSection
					initialState={floorAreaToggle || ' '}
					value={floorAreaToggle}
					firstOption={LOCATION_INPUT_TOGGLE.floors}
					secondOption={LOCATION_INPUT_TOGGLE.areas}
					firstAction={(toggleState) => {
						handleToggleChange(toggleState);
						setFloorAreaToggle(LOCATION_INPUT_TOGGLE.floors);
					}}
					secondAction={(toggleState) => {
						handleToggleChange(toggleState);
						setFloorAreaToggle(LOCATION_INPUT_TOGGLE.areas);
					}}
					width={'118px'}
					height={'32px'}
				/>
			</div>
			{floorAreaToggle === LOCATION_INPUT_TOGGLE.areas ? areasGroupedMultiDropdown : floorsAutocompleteElement}
			<div className={classes.chipsContainer}>
				{floorAreaToggle === LOCATION_INPUT_TOGGLE.floors
					? selectedFloorsChips.map((chip) => (
							<SimpleChip
								key={chip.key}
								chipColor={COLORS.white}
								chipTextColor={COLORS.primaryColor}
								chipText={getUnitedFloorChipText(chip.fromFloor, chip.toFloor, translationService)}
								chipHeight={22}
								capitalizeText
								onDeleteClick={() => deleteFloorChip(chip)}
								border={true}
								textClassName={classes.chipText}
								testId={'floorChip'}
							/>
						))
					: selectedAreasChips.map((areaChip) => (
							<SimpleChip
								key={`${areaChip.areaIds}`}
								chipColor={COLORS.white}
								chipTextColor={COLORS.primaryColor}
								chipText={areaChip.label}
								chipHeight={22}
								capitalizeText
								onDeleteClick={() => deleteAreaChip(areaChip)}
								border={true}
								textClassName={classes.chipText}
								testId={'areaChip'}
							/>
						))}
			</div>
		</div>
	);
};
