import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { translationService } from '../../index';
import { IProfession, ITag, ITrade, ITradeFilter } from '../../interfaces';
import { ADMIN_PANEL_LAST_SEEN_DAYS, APP_FILTER_TYPES, BATTERY_MINIMUM_LEVELS, TagStatuses } from '../../constants';
import { getNumberDaysAgo } from '../../utils/dateUtils';
import {
	AssignedFilters,
	BatteryFilter,
	IAdminPanelFilters,
	IAdminPanelFiltersTypes,
	LastSeenFilter,
} from '../../interfaces/IAdminPanelFilters';
import { IRootState } from '../../store/slices';
import {
	addSelectedManagementTrades,
	removeSelectedManagementTrades,
	resetSelectedManagementTrades,
} from '../../store/slices/managementTrades.slice';
import {
	addOneSelectedProfession,
	removeOneSelectedProfession,
	resetSelectedProfessions,
} from '../../store/slices/professions.slice';
import { unassignedTradeId } from '@shared/constants/professions.constants';
import { ExpandableComponent } from '../MainPageSections/ExpandableComponent/ExpandableComponent';
import { EntitiesWithValuesFilterSection } from '../FilterSections/EntitiesWithValuesFilterSection';
import { useSetActiveFilterType } from '../../hooks/useSetActiveFilterType.hooks';
import { getBatteryLevelNumber } from '../../utils/adminPanelTags.utils';
import { FilterCard } from '@shared/components/Filters/FilterCard/FilterCard';

interface IInventoryFilterSideBarProps {
	tagsFilterList: ITradeFilter[];
	selectedProfessions: IProfession[];
	isProfessionsFilterInitialState: boolean;
	isManagementTradesFilterInitialState: boolean;
	filterData: ITradeFilter[];
	wrapperStyleClass?: string;
	onFilterChange: (filterType: string, currentFilter: string, ev?: MouseEvent) => void;
	filterState: IAdminPanelFilters;
	clearFilterChange: (filterType: string, ev?: MouseEvent) => void;
}

const InventoryFilterSideBar = (props: IInventoryFilterSideBarProps) => {
	const dispatch = useDispatch();
	const tags: ITag[] = useSelector((state: IRootState) => state.tagsReducer.tags);
	const tz: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.tz);
	const filteredProfessions: IProfession[] = useSelector(
		(state: IRootState) => state.professionsReducer.filteredProfessions
	);
	const selectedManagementTrades: ITrade[] = useSelector(
		(state: IRootState) => state.managementTradesReducer.selectedManagementTrades
	);
	const filteredManagementTrades: ITrade[] = useSelector(
		(state: IRootState) => state.managementTradesReducer.filteredManagementTrades
	);
	const [lastSeenOkCount, setLastSeenOkCount] = useState<number>(0);
	const [lastSeenWarningCount, setLastSeenWarningCount] = useState<number>(0);
	const [lastSeenAlertCount, setLastSeenAlertCount] = useState<number>(0);
	const [assignedTagsCount, setAssignedTagsCount] = useState<number>(0);
	const [unassignedTagsCount, setUnassignedTagsCount] = useState<number>(0);
	const [statusCount, setStatusCount] = useState<{ [key: string]: number }>({});
	const [batteryLevelCount, setBatteryLevelCount] = useState<{
		[key: string]: number;
	}>({});

	useSetActiveFilterType(APP_FILTER_TYPES.allocationFilter);

	useEffect(() => {
		const assignedTagsCount: number = tags.filter(
			(tag: ITag) => tag.profession?.tradeId !== unassignedTradeId
		).length;
		const unassignedTagsCount: number = tags.length - assignedTagsCount;
		setAssignedTagsCount(assignedTagsCount);
		setUnassignedTagsCount(unassignedTagsCount);
	}, [tags]);

	useEffect(() => {
		const lastSeenOkCount: number = tags.filter(
			(tag: ITag) => getNumberDaysAgo(tag.lastSeen, tz) <= ADMIN_PANEL_LAST_SEEN_DAYS.OK_MAX_DAYS
		).length;
		const lastSeenWarningCount: number = tags.filter(
			(tag: ITag) =>
				getNumberDaysAgo(tag.lastSeen, tz) <= ADMIN_PANEL_LAST_SEEN_DAYS.WARNING_MAX_DAYS &&
				getNumberDaysAgo(tag.lastSeen, tz) > ADMIN_PANEL_LAST_SEEN_DAYS.OK_MAX_DAYS
		).length;
		const lastSeenAlertCount: number = tags.filter(
			(tag: ITag) => getNumberDaysAgo(tag.lastSeen, tz) > ADMIN_PANEL_LAST_SEEN_DAYS.WARNING_MAX_DAYS
		).length;
		const statusCounts: { [key: string]: number } = {};
		const batteryCount: { [key: string]: number } = {
			high: 0,
			medium: 0,
			low: 0,
		};
		tags.forEach((tag: ITag) => {
			statusCounts[tag.tagStatus] ? statusCounts[tag.tagStatus]++ : (statusCounts[tag.tagStatus] = 1);
			const batteryLevelNumber: number = getBatteryLevelNumber(tag.batteryLevel);
			if (batteryLevelNumber > BATTERY_MINIMUM_LEVELS.high) return batteryCount[BatteryFilter.high]++;
			if (batteryLevelNumber > BATTERY_MINIMUM_LEVELS.medium) return batteryCount[BatteryFilter.medium]++;
			if (batteryLevelNumber >= BATTERY_MINIMUM_LEVELS.low) return batteryCount[BatteryFilter.low]++;
		});

		setLastSeenOkCount(lastSeenOkCount);
		setLastSeenWarningCount(lastSeenWarningCount);
		setLastSeenAlertCount(lastSeenAlertCount);
		setStatusCount(statusCounts);
		setBatteryLevelCount(batteryCount);
	}, [tags, tz]);
	const handleHighBatteryClick = (ev) => {
		props.onFilterChange(IAdminPanelFiltersTypes.batteryFilter, BatteryFilter.high, ev);
	};
	const handleMediumBatteryClick = (ev) => {
		props.onFilterChange(IAdminPanelFiltersTypes.batteryFilter, BatteryFilter.medium, ev);
	};
	const handleLowBatteryClick = (ev) => {
		props.onFilterChange(IAdminPanelFiltersTypes.batteryFilter, BatteryFilter.low, ev);
	};
	const handleGoodStatusClick = (ev) => {
		props.onFilterChange(IAdminPanelFiltersTypes.statusFilter, TagStatuses.Good, ev);
	};
	const handleSleepingStatusClick = (ev) => {
		props.onFilterChange(IAdminPanelFiltersTypes.statusFilter, TagStatuses.Sleeping, ev);
	};
	const handleStorageStatusClick = (ev) => {
		props.onFilterChange(IAdminPanelFiltersTypes.statusFilter, TagStatuses.Storage, ev);
	};
	const handleNoReceptionStatusClick = (ev) => {
		props.onFilterChange(IAdminPanelFiltersTypes.statusFilter, TagStatuses.NoReception, ev);
	};
	const handleAssignedTagClick = (ev) => {
		props.onFilterChange(IAdminPanelFiltersTypes.assignedFilter, AssignedFilters.assignedTags, ev);
	};
	const handleUnassignedTagClick = (ev) => {
		props.onFilterChange(IAdminPanelFiltersTypes.assignedFilter, AssignedFilters.unassignedTags, ev);
	};
	const handleLastSeenOkTagClick = () => {
		props.onFilterChange(IAdminPanelFiltersTypes.lastSeenFilter, LastSeenFilter.lastSeenOk);
	};
	const handleLastSeenWarningTagClick = () => {
		props.onFilterChange(IAdminPanelFiltersTypes.lastSeenFilter, LastSeenFilter.lastSeenWarning);
	};
	const handleLastSeenAlertTagClick = () => {
		props.onFilterChange(IAdminPanelFiltersTypes.lastSeenFilter, LastSeenFilter.lastSeenAlert);
	};
	const handleResetManagementTradesClick = () => {
		dispatch(resetSelectedManagementTrades());
	};
	const handleResetProfessionsClick = () => {
		dispatch(resetSelectedProfessions());
	};
	const handleRemoveManagerTrade = (trade: ITrade) => {
		dispatch(removeSelectedManagementTrades({ removedManagementTrade: trade }));
	};
	const handleAddManagementTrade = (trade: ITrade) => {
		dispatch(addSelectedManagementTrades({ selectedManagementTrade: trade }));
	};
	const handleRemoveProfession = (profession: IProfession) => {
		dispatch(removeOneSelectedProfession({ removedProfession: profession }));
	};
	const handleAddProfession = (profession: IProfession) => {
		dispatch(addOneSelectedProfession({ selectedProfession: profession }));
	};

	const addValuesToProfessions = (professions: IProfession[], tags: ITag[]): ITrade[] => {
		return professions.map((profession) => {
			return {
				...profession,
				value: tags.filter((tag) => tag.profession?._id === profession._id).length,
			};
		});
	};

	const addValuesToManagers = (trades: ITrade[], tags: ITag[]): ITrade[] => {
		return trades.map((trade) => {
			return {
				...trade,
				value: tags.filter((tag) => tag.manager?.tradeId === trade.tradeId).length,
			};
		});
	};

	const handleFilterTypeClearFunction = (adminPanelFilterType: IAdminPanelFiltersTypes, ev?: MouseEvent) => {
		props.clearFilterChange(adminPanelFilterType, ev);
	};

	const getFilterTypeLength = (adminPanelFilterType: IAdminPanelFiltersTypes): number => {
		return Object.values(props.filterState[adminPanelFilterType]).filter((item) => item).length;
	};

	return (
		<React.Fragment>
			<ExpandableComponent
				childrenLength={2}
				headerText={translationService.get('allocation')}
				clearFunction={(ev) => handleFilterTypeClearFunction(IAdminPanelFiltersTypes.assignedFilter, ev)}
				shouldHideClearButton={getFilterTypeLength(IAdminPanelFiltersTypes.assignedFilter) <= 0}
				clearLength={getFilterTypeLength(IAdminPanelFiltersTypes.assignedFilter)}
				type={APP_FILTER_TYPES.allocationFilter}
				testId="allocationFilterSection"
			>
				<FilterCard
					text={translationService.get('assigned')}
					isSelected={props.filterState[IAdminPanelFiltersTypes.assignedFilter][AssignedFilters.assignedTags]}
					handleClick={handleAssignedTagClick}
					count={assignedTagsCount}
				/>
				<FilterCard
					text={translationService.get('unassigned')}
					isSelected={
						props.filterState[IAdminPanelFiltersTypes.assignedFilter][AssignedFilters.unassignedTags]
					}
					handleClick={handleUnassignedTagClick}
					count={unassignedTagsCount}
				/>
			</ExpandableComponent>
			<ExpandableComponent
				childrenLength={4}
				headerText={translationService.get('status')}
				clearFunction={(ev) => handleFilterTypeClearFunction(IAdminPanelFiltersTypes.statusFilter, ev)}
				shouldHideClearButton={getFilterTypeLength(IAdminPanelFiltersTypes.statusFilter) <= 0}
				clearLength={getFilterTypeLength(IAdminPanelFiltersTypes.statusFilter)}
				type={APP_FILTER_TYPES.status}
				testId="statusFilterSection"
			>
				<FilterCard
					text={translationService.get('Good')}
					isSelected={props.filterState[IAdminPanelFiltersTypes.statusFilter][TagStatuses.Good]}
					handleClick={handleGoodStatusClick}
					count={statusCount[TagStatuses.Good] || 0}
				/>

				<FilterCard
					text={translationService.get('Sleeping')}
					isSelected={props.filterState[IAdminPanelFiltersTypes.statusFilter][TagStatuses.Sleeping]}
					handleClick={handleSleepingStatusClick}
					count={statusCount[TagStatuses.Sleeping] || 0}
				/>
				<FilterCard
					text={translationService.get('Storage')}
					isSelected={props.filterState[IAdminPanelFiltersTypes.statusFilter][TagStatuses.Storage]}
					handleClick={handleStorageStatusClick}
					count={statusCount[TagStatuses.Storage] || 0}
				/>
				<FilterCard
					text={translationService.get('NoReception')}
					isSelected={props.filterState[IAdminPanelFiltersTypes.statusFilter][TagStatuses.NoReception]}
					handleClick={handleNoReceptionStatusClick}
					count={statusCount[TagStatuses.NoReception] || 0}
				/>
			</ExpandableComponent>

			<ExpandableComponent
				childrenLength={3}
				headerText={translationService.get('batteryLevel')}
				clearFunction={(ev) => handleFilterTypeClearFunction(IAdminPanelFiltersTypes.batteryFilter, ev)}
				shouldHideClearButton={getFilterTypeLength(IAdminPanelFiltersTypes.batteryFilter) <= 0}
				clearLength={getFilterTypeLength(IAdminPanelFiltersTypes.batteryFilter)}
				type={APP_FILTER_TYPES.battery}
				testId="batteryFilterSection"
			>
				<FilterCard
					text={translationService.get('highBatteryPercentage')}
					isSelected={props.filterState[IAdminPanelFiltersTypes.batteryFilter][BatteryFilter.high]}
					handleClick={handleHighBatteryClick}
					count={batteryLevelCount[BatteryFilter.high] || 0}
				/>
				<FilterCard
					text={translationService.get('mediumBatteryPercentage')}
					isSelected={props.filterState[IAdminPanelFiltersTypes.batteryFilter][BatteryFilter.medium]}
					handleClick={handleMediumBatteryClick}
					count={batteryLevelCount[BatteryFilter.medium] || 0}
				/>
				<FilterCard
					text={translationService.get('lowBatteryPercentage')}
					isSelected={props.filterState[IAdminPanelFiltersTypes.batteryFilter][BatteryFilter.low]}
					handleClick={handleLowBatteryClick}
					count={batteryLevelCount[BatteryFilter.low] || 0}
				/>
			</ExpandableComponent>

			<ExpandableComponent
				childrenLength={3}
				headerText={translationService.get('lastSeen')}
				clearLength={getFilterTypeLength(IAdminPanelFiltersTypes.lastSeenFilter)}
				clearFunction={(ev) => handleFilterTypeClearFunction(IAdminPanelFiltersTypes.lastSeenFilter, ev)}
				shouldHideClearButton={getFilterTypeLength(IAdminPanelFiltersTypes.lastSeenFilter) <= 0}
				type={APP_FILTER_TYPES.lastSeenFilter}
				testId="lastSeenFilterSection"
			>
				<FilterCard
					text={translationService.get('lessThan5Days')}
					isSelected={props.filterState[IAdminPanelFiltersTypes.lastSeenFilter][LastSeenFilter.lastSeenOk]}
					handleClick={handleLastSeenOkTagClick}
					count={lastSeenOkCount}
				/>
				<FilterCard
					text={translationService.get('6To10Days')}
					isSelected={
						props.filterState[IAdminPanelFiltersTypes.lastSeenFilter][LastSeenFilter.lastSeenWarning]
					}
					handleClick={handleLastSeenWarningTagClick}
					count={lastSeenWarningCount}
				/>
				<FilterCard
					text={translationService.get('moreThan10Days')}
					isSelected={props.filterState[IAdminPanelFiltersTypes.lastSeenFilter][LastSeenFilter.lastSeenAlert]}
					handleClick={handleLastSeenAlertTagClick}
					count={lastSeenAlertCount}
				/>
			</ExpandableComponent>
			<EntitiesWithValuesFilterSection
				isProfession
				handleResetEntitiesClick={handleResetProfessionsClick}
				selectedEntities={props.selectedProfessions}
				filteredEntities={addValuesToProfessions(filteredProfessions, tags)}
				removeEntities={handleRemoveProfession}
				addEntities={handleAddProfession}
				disableShowAll={props.isProfessionsFilterInitialState}
				type={APP_FILTER_TYPES.professionFilter}
			/>
			<EntitiesWithValuesFilterSection
				isProfession={false}
				handleResetEntitiesClick={handleResetManagementTradesClick}
				selectedEntities={selectedManagementTrades}
				filteredEntities={addValuesToManagers(filteredManagementTrades, tags)}
				removeEntities={handleRemoveManagerTrade}
				addEntities={handleAddManagementTrade}
				disableShowAll={props.isManagementTradesFilterInitialState}
				title={translationService.get('Managers')}
				type={APP_FILTER_TYPES.managerFilter}
			/>
		</React.Fragment>
	);
};

export { InventoryFilterSideBar };
