import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectTimezone } from '@store/slices/project.slice';
import { DateSelectorWithBackAndForward } from '../../../DateSelector/DateSelectorWithBackAndForward/DateSelectorWithBackAndForward';
import { updateVisibleWeek } from '@store/slices/datePicker.slice';
import { weekRangeDateSelection_BI } from '@utils/bi.utils';
import { IRootState } from '@store/slices';
import { getWeekPeriodFromDate } from '@shared/utils/dateUtils';

const DatePickerSection = ({ week, source = undefined, numberOfWeeks = 1, startDate }) => {
	const dispatch = useDispatch();
	const tz: string = useSelector(selectTimezone)!;

	const visibleDateRangeTimestamps = useSelector(
		(state: IRootState) => state.datePickerReducer.visibleDateRangeTimestamps
	);

	useEffect(() => {
		if (!startDate) return;
		setStartDateFromLink();
	}, [startDate]);

	const setStartDateFromLink = (): void => {
		const dateRange: Date[] = getWeekPeriodFromDate(startDate!, tz);
		updateReportDateReducer(dateRange);
	};

	const updateReportDateReducer = (dateRange) => {
		weekRangeDateSelection_BI(source);
		dispatch(
			updateVisibleWeek({
				visibleDateRangeTimestamps: dateRange.map((date) => date.getTime()),
			})
		);
	};

	return (
		<DateSelectorWithBackAndForward
			updateDate={updateReportDateReducer}
			isWeek={week}
			weekDatesRange={visibleDateRangeTimestamps}
			numberOfWeeks={numberOfWeeks}
		/>
	);
};

export { DatePickerSection };
