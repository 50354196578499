import { IWorkersTimesheet } from '@interfaces/IWorkersTimesheets';
import { IMergedTagCarrier } from '@interfaces/IMergedTagCarrier';
import {
	calculateHoursOnSite,
	convertDateObjectToServerFormat,
	getAmPmTimeFormatFromDate,
	getDayAndMonthLocaleFormat,
	getTimezoneStartOfDate,
	getWeekDayFromDate,
} from '@utils/dateUtils';
import { IWorkerTimesheet } from '@shared/interfaces/IWorkerTimesheet';
import { IMergedTagType, MergedTagType } from '@shared/interfaces/IMergedTagType';
import { getContractorAndTradeFromProfession } from '@utils/professions.utils';
import { IProfession } from '@shared/interfaces/IProfession';
import { IManager } from '@shared/interfaces/IManager';
import { translationService } from '../../../index';
import { IWorkDayHours } from '@shared/interfaces/IWorkDayHours';
import moment from 'moment/moment';
import { ITimesheetByTagType } from './ITimesheetByTagType';
import { IMergedTagCarrierWorkerData } from '@shared/interfaces/IMergedTagCarrierWorkerData';
import { getTradeTranslation } from '@utils/translations.utils';

const getValueToGroupBy = (
	tagType: IMergedTagType,
	tagData: IMergedTagCarrier['tagData'],
	managers: IManager[]
): string => {
	if (tagType === MergedTagType.Worker) {
		return getContractorAndTradeFromProfession((tagData as IMergedTagCarrierWorkerData<IProfession>).profession);
	}
	const managerTradeTranslation: string = getTradeTranslation(tagData as IManager);
	return managerTradeTranslation;
};

const isTodayRecord = (record: IWorkerTimesheet, tz: string): boolean => {
	const todayStartOfDay: Date = getTimezoneStartOfDate(tz);
	return record.arrivalTimeTimestamp >= todayStartOfDay.getTime();
};

export const getTimesheetByTagType = (
	workersTimesheet: IWorkersTimesheet,
	managers: IManager[]
): ITimesheetByTagType => {
	return <ITimesheetByTagType>Object.entries(workersTimesheet).reduce((timesheetByTagType, [tagId, value]) => {
		const tagType: IMergedTagType | undefined = value.tagCarrier?.tagType;
		if (tagType) {
			const tagData: IMergedTagCarrier['tagData'] | undefined = value.tagCarrier?.tagData;
			if (!tagData) return timesheetByTagType;
			const timesheet: IWorkerTimesheet[] = value.timesheet || [];
			const groupByValue: string = getValueToGroupBy(tagType, tagData, managers);
			timesheetByTagType[tagType] = timesheetByTagType[tagType] || {};
			timesheetByTagType[tagType][groupByValue] = timesheetByTagType[tagType][groupByValue] || [];
			timesheetByTagType[tagType][groupByValue].push({
				tagId,
				timesheet,
			});
		}
		return timesheetByTagType;
	}, {});
};

export const getWorkerTimesheetElementId = (tagId: string): string => {
	return `workerTimesheet-${tagId}`;
};

export const getTagIdsList = (timesheetByTagType: ITimesheetByTagType): string[] => {
	let tagIdsList: string[] = [];

	timesheetByTagType?.Worker &&
		Object.values(timesheetByTagType?.Worker)
			.map((worker) => {
				return worker.map((tagTimesheets) => {
					tagIdsList = [...tagIdsList, tagTimesheets.tagId];
				});
			})
			.flat();

	timesheetByTagType?.Manager &&
		Object.values(timesheetByTagType?.Manager)
			.map((worker) => {
				return worker.map((tagTimesheets) => {
					tagIdsList.push(tagTimesheets.tagId);
					tagIdsList = [...tagIdsList, tagTimesheets.tagId];
				});
			})
			.flat();

	return tagIdsList;
};

export const getDayTimesheetByDate = (date: Date, timesheet: IWorkerTimesheet[], tz: string): IWorkerTimesheet[] => {
	return timesheet.filter((record) => {
		return moment.tz(date, tz).isSame(moment.tz(record.date, tz), 'day');
	});
};

export const getExitTimeString = (timesheet: IWorkerTimesheet, tz: string): string => {
	if (!timesheet.exitTimeTimestamp) return '';
	if (isTodayRecord(timesheet, tz)) return '';

	const timeString: string = getAmPmTimeFormatFromDate(timesheet.exitTimeTimestamp, tz);
	const isExitTimeNextDay: boolean = moment
		.tz(timesheet.exitTimeTimestamp, tz)
		.isAfter(moment.tz(timesheet.date, tz), 'day');
	return isExitTimeNextDay
		? `${timeString} (${getDayAndMonthLocaleFormat(timesheet.exitTimeTimestamp, tz)})`
		: timeString;
};

export const getTotalTimeOnSiteString = (timesheet: IWorkerTimesheet, tz: string): string => {
	if (!timesheet.exitTimeTimestamp) return '';
	if (isTodayRecord(timesheet, tz)) return '';

	return calculateHoursOnSite(timesheet.arrivalTimeTimestamp, timesheet.exitTimeTimestamp);
};

export const getTimesheetColumns = (tz: string) => {
	return [
		{
			title: translationService.get('date'),
			renderTableContentMethod: (record: IWorkerTimesheet) => getDayAndMonthLocaleFormat(record.date, tz),
		},
		{
			title: translationService.get('day'),
			renderTableContentMethod: (record: IWorkerTimesheet) =>
				getWeekDayFromDate(record.date, tz, translationService.getDateLocale()),
		},
		{
			title: translationService.get('arrivalTime'),
			renderTableContentMethod: (record: IWorkerTimesheet) =>
				record.arrivalTimeTimestamp && getAmPmTimeFormatFromDate(record.arrivalTimeTimestamp, tz),
		},
		{
			title: translationService.get('departureTime'),
			renderTableContentMethod: (record: IWorkerTimesheet) =>
				record.exitTimeTimestamp && getExitTimeString(record, tz),
		},
		{
			title: translationService.get('totalHoursOnSite'),
			renderTableContentMethod: (record: IWorkerTimesheet) =>
				record.exitTimeTimestamp && getTotalTimeOnSiteString(record, tz),
		},
	];
};

export const getTimesheetReportRelaseDate = (tz: string) => {
	const releaseDate = new Date(2023, 2, 26); //2 - March
	return getTimezoneStartOfDate(tz, convertDateObjectToServerFormat(releaseDate));
};
