import { ITranslations } from '../interfaces/ITranslations';
import { Directions } from '../interfaces/Directions';
import { ILanguageAndDisplayName, Languages } from '../constants/languages';
import { DIRECTIONS } from '../constants/directions';
import { dateFormats, TIME_FORMAT_12, TIME_FORMAT_24 } from '../constants/formats.constants';
import { ITranslationsLanguages } from '../interfaces/ITranslationsLanguages';
// @ts-ignore
import Papa from 'papaparse';
class TranslationService {
	private translations: ITranslations;
	private chosenLanguage!: string;
	private direction!: Directions;
	private dateFormat!: string;
	private dateAndMonthFormat!: string;
	private shortDateDisplayFormat!: string;
	private monthAndYearDisplayFormat!: string;
	private fullDateDisplayFormat!: string;
	private datePickerFormat!: string;
	private languages: Languages[] = Object.values(Languages);
	private didInit: boolean = false;

	constructor(
		language: string,
		private fetchTranslationsCsvCallback: () => Promise<string>,
		additionalTranslations?: ITranslations
	) {
		this.changeChosenLanguage(language);
		this.translations = {
			...additionalTranslations,
		};
	}

	public async init(): Promise<{ error?: Error }> {
		if (this.didInit) {
			return {};
		}
		const translationsJsonData: {
			data: ITranslations;
			error?: Error;
		} = await this.getTranslationJsonDataFromCsv();
		if (translationsJsonData.error) {
			return {
				error: translationsJsonData.error,
			};
		}
		this.translations = {
			...this.translations,
			...translationsJsonData.data,
		};
		this.didInit = true;
		return {};
	}

	// Key format: meaningInCamelCase
	get(key: string, translationVariables: { [key: string]: string } = {}): string {
		if (!this.translations[key]) {
			return '';
		}
		const translation: string = this.translations[key][this.chosenLanguage];
		if (Object.keys(translationVariables).length > 0) {
			return this.getTranslationParsed(translation, translationVariables);
		}
		return translation || '';
	}

	getTranslationParsed(translationToConvert: string, translationVariables: { [key: string]: string }): string {
		return Object.keys(translationVariables).reduce((translationToReturn, key) => {
			return translationToReturn.replaceAll(`{${key}}`, translationVariables[key]);
		}, translationToConvert);
	}

	getDirection(): Directions {
		return this.direction;
	}

	getIsRtl(): boolean {
		return this.direction === DIRECTIONS.RTL;
	}

	getDateFormat(): string {
		return this.dateFormat;
	}

	getDatePickerFormat(): string {
		return this.datePickerFormat;
	}

	getTimeFormat(): string {
		return timeFormatMappings[this.chosenLanguage];
	}

	getDayAndMonthFormat(): string {
		return this.dateAndMonthFormat;
	}

	getShortDateDisplayFormat(): string {
		return this.shortDateDisplayFormat;
	}

	getMonthAndYearDisplayFormat(): string {
		return this.monthAndYearDisplayFormat;
	}

	getFullDateDisplayFormat(): string {
		return this.fullDateDisplayFormat;
	}

	getChosenLanguage(): string {
		return this.chosenLanguage;
	}

	getChosenLanguageDisplayName(): string {
		return languagesNamesMappings[this.chosenLanguage];
	}

	getDateLocale(): string {
		return dateLocalesMappings[this.chosenLanguage];
	}

	changeChosenLanguage(language: string): void {
		this.chosenLanguage = language ?? this.chosenLanguage;
		this.direction = ltrMappings[language];
		this.dateFormat = dateFormatMappings[language];
		this.dateAndMonthFormat = dayAndMonthFormatMappings[language];
		this.datePickerFormat = dateFormatMappingsForDatePicker[language];
		this.shortDateDisplayFormat = shortDateFormatMappings[language];
		this.monthAndYearDisplayFormat = monthAndYearFormatMappings[language];
		this.fullDateDisplayFormat = fullDateFormatMappings[language];
		changeLanguageTagOnHTML(language);
	}

	getLanguagesAndDisplayNames(): ILanguageAndDisplayName[] {
		return this.languages.map((language: Languages) => {
			return {
				language,
				displayName: languagesNamesMappings[language],
			};
		});
	}

	private async getTranslationJsonDataFromCsv(): Promise<{
		data: ITranslations;
		error?: Error;
	}> {
		try {
			const translationsJsonDataFromS3: ITranslations = await this.fetchTranslationsFromCsv();
			return {
				data: translationsJsonDataFromS3,
			};
		} catch (e: any) {
			console.error(`Failed to fetch translations from csv: ${e}`);
			return {
				data: {},
				error: e,
			};
		}
	}

	private async fetchTranslationsFromCsv(): Promise<ITranslations> {
		try {
			const translationsCsv: string = await this.fetchTranslationsCsvCallback();
			const translationsJsonData: ITranslations = await this.convertCsvToJson(translationsCsv);
			return translationsJsonData;
		} catch (e: any) {
			throw new Error(`Failed to fetch translations from csv: ${e}`);
		}
	}

	private convertCsvToJson = (translationsCsv: string): Promise<ITranslations> => {
		return new Promise((resolve, reject) => {
			const trnalsations: ITranslations = {};
			Papa.parse(translationsCsv, {
				delimiter: ';',
				header: true,
				skitEmptyLines: true,
				step: (results) => {
					if (Object.keys(results.data as any).length <= 1) {
						reject('Row count is less than 1');
						return;
					}
					const { key, ...translations } = results.data as { [key: string]: string };
					trnalsations[key] = translations as ITranslationsLanguages;
				},
				complete: () => {
					if (Object.keys(trnalsations).length === 0) {
						reject('Translations object is empty');
						return;
					}
					resolve(trnalsations);
				},
				error: (error) => {
					reject(error);
				},
			});
		});
	};
}

const dateLocalesMappings: Record<Languages, string> = {
	[Languages.ENGLISH_US]: 'en',
	[Languages.HEBREW]: 'he',
	[Languages.JAPANESE]: 'ja',
};

const ltrMappings: Record<Languages, string> = {
	[Languages.ENGLISH_US]: DIRECTIONS.LTR,
	[Languages.HEBREW]: DIRECTIONS.RTL,
	[Languages.JAPANESE]: DIRECTIONS.LTR,
};

const dateFormatMappings: Record<Languages, string> = {
	[Languages.ENGLISH_US]: dateFormats.MDY,
	[Languages.HEBREW]: dateFormats.DMY,
	[Languages.JAPANESE]: dateFormats.YMD,
};

const dateFormatMappingsForDatePicker: Record<Languages, string> = {
	[Languages.ENGLISH_US]: dateFormats.MSDSY_H,
	[Languages.HEBREW]: dateFormats.DSMSY_H,
	[Languages.JAPANESE]: dateFormats.MSDSY_H,
};

const timeFormatMappings: Record<Languages, string> = {
	[Languages.ENGLISH_US]: TIME_FORMAT_12,
	[Languages.HEBREW]: TIME_FORMAT_24,
	[Languages.JAPANESE]: TIME_FORMAT_24,
};
const dayAndMonthFormatMappings: Record<Languages, string> = {
	[Languages.ENGLISH_US]: dateFormats.MD,
	[Languages.HEBREW]: dateFormats.DM,
	[Languages.JAPANESE]: dateFormats.MD,
};

const languagesNamesMappings: Record<Languages, string> = {
	[Languages.ENGLISH_US]: 'English (US)',
	[Languages.HEBREW]: 'עברית',
	[Languages.JAPANESE]: '日本語',
};

const shortDateFormatMappings: Record<Languages, string> = {
	[Languages.ENGLISH_US]: 'MMM DD',
	[Languages.HEBREW]: 'DD/MM',
	[Languages.JAPANESE]: 'MM月DD日',
};

const monthAndYearFormatMappings: Record<Languages, string> = {
	[Languages.ENGLISH_US]: 'MMM YYYY',
	[Languages.HEBREW]: 'MM/YYYY',
	[Languages.JAPANESE]: 'YYYY年MM月',
};

const fullDateFormatMappings: Record<Languages, string> = {
	[Languages.ENGLISH_US]: 'MM/DD/YYYY',
	[Languages.HEBREW]: 'DD/MM/YYYY',
	[Languages.JAPANESE]: 'YYYY年MM月DD日',
};
const changeLanguageTagOnHTML = (language: string): void => {
	try {
		document.documentElement.lang = language;
	} catch (e) {}
};

export { TranslationService, ltrMappings, dateFormatMappings };
