import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import { requestService } from '../../index';
import { IBaseManager, IManager, ITrade } from '../../interfaces';
import { IRootState } from '../slices';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { removeManager } from '../slices/managers.slice';
import { IWorkingThunkArgs } from '../../interfaces/IWorkingThunkArgs';

export const getStaticManagers = createAsyncThunk(
	'getStaticManagers',
	async ({ projectId, startDate, endDate, includeDeleted = false }: IWorkingThunkArgs, { fulfillWithValue }) => {
		const managementTrades: ITrade[] = await requestService.get(`/entities/trades/management`);

		const queryParams: string[] = [];
		if (startDate) {
			queryParams.push(`startDate=${startDate}`);
		}
		if (endDate) {
			queryParams.push(`endDate=${endDate}`);
		}
		if (includeDeleted) {
			queryParams.push(`includeDeleted=${includeDeleted}`);
		}

		const queryParamsString: string = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';
		const managersGetUrl: string = `/projectConfig/managers/${projectId}${queryParamsString}`;

		const managers: IManager[] = await requestService.get(managersGetUrl);

		return { managers, managementTrades };
	}
);

export const createManager = createAsyncThunk<any, IBaseManager, { state: IRootState }>(
	'createManager',
	async (manager: IBaseManager, { rejectWithValue, getState }) => {
		try {
			const createdManager: IManager = await requestService.post(`/projectConfig/managers/manager`, {
				body: manager,
			});
			return { manager: createdManager };
		} catch (e: any) {
			return rejectWithValue(e.response.status);
		}
	}
);

export const updateManager = createAsyncThunk<any, IManager, { state: IRootState }>(
	'updateManager',
	async (manager: IManager, { rejectWithValue, getState }) => {
		try {
			const updatedManager: IManager = await requestService.put(
				`/projectConfig/managers/manager/${manager._id}`,
				{
					body: {
						phoneNumber: manager.phoneNumber,
						receiveNotifications: manager.receiveNotifications,
					},
				}
			);
			return { manager: updatedManager };
		} catch (e: any) {
			return rejectWithValue(e.response.status);
		}
	}
);

export const deleteManager =
	(managerId: string): ThunkAction<void, IRootState, unknown, AnyAction> =>
	async (dispatch: any, getState: () => IRootState) => {
		await requestService.delete(`/projectConfig/managers/manager/${managerId}`);
		dispatch(removeManager({ deletedManagerId: managerId }));
	};
