import React, { useRef } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { SimpleChip } from '../../../../../shared/components/SimpleChip/SimpleChip';
import { useWidthOverflow } from '../../../hooks/custom.hooks';
import classes from './styles.module.scss';
import { getHoursMinutesFormat } from '../../../utils/dateUtils';

interface ISafetyAlertRowProps {
	safetyEventText: string;
	floorText: string;
	professionDisplayText: string;
	professionColor: string;
	safetyIcon: string;
	alertTimestamp: number;
	tz: string;
	click: () => void;
}

const SafetyAlertRow = (props: ISafetyAlertRowProps) => {
	const descriptionRef: any = useRef(null);
	const floorRef: any = useRef(null);
	const professionRef: any = useRef(null);
	const descriptionOverflow: boolean = useWidthOverflow(descriptionRef);
	const floorOverflow: boolean = useWidthOverflow(floorRef);
	const professionOverflow: boolean = useWidthOverflow(professionRef);
	const alertHour: string = getHoursMinutesFormat(props.alertTimestamp, props.tz);
	const renderRow = () => {
		return (
			<div className={classes.safetyEventContainer} onClick={props.click} data-testid={'safetyEventCard'}>
				{props.safetyEventText && (
					<div className={classes.iconTextContainer} data-testid={'safetyHeader'}>
						<img src={props.safetyIcon} alt="safety" className={classes.iconClass} />
						<p className={classes.eventText} ref={descriptionRef}>
							{props.safetyEventText}
						</p>
					</div>
				)}
				<div className={classes.eventPropsContainer}>
					{
						<p className={classes.eventProps} data-testid={'safetyHour'}>
							{alertHour}
						</p>
					}
					{
						<p className={classes.eventProps} ref={floorRef} data-testid={'safetyFloor'}>
							{props.floorText}
						</p>
					}
				</div>
				{props.professionDisplayText && (
					<div className={classes.chipContainer}>
						<SimpleChip
							chipText={props.professionDisplayText}
							chipColor={props.professionColor}
							maxChipWidth={'150px'}
							ref={professionRef}
							testId={'safetyProfession'}
						/>
					</div>
				)}
			</div>
		);
	};

	return descriptionOverflow || floorOverflow || professionOverflow ? (
		<Tooltip
			arrow
			placement="top"
			classes={{
				tooltip: classes.tooltipRoot,
				arrow: classes.arrow,
			}}
			title={
				<p className={classes.eventTextTooltip}>{`${props.safetyEventText}${
					props.floorText && `, ${props.floorText}`
				}, ${props.professionDisplayText}`}</p>
			}
		>
			{renderRow()}
		</Tooltip>
	) : (
		renderRow()
	);
};

export { SafetyAlertRow };
