import moment, { MomentInput } from 'moment/moment';
import { ISafetyEvent } from '@interfaces/ISafetyEvent';
import { QueryObserverResult, useQuery } from '@tanstack/react-query';
import { requestService } from '@src/index';

export const useSafetyEventByTimeRangeQuery = (
	projectId: string,
	tz: string,
	startDate: MomentInput,
	endDate: MomentInput,
	areaId?: string,
	floorId?: string
): {
	safetyEvents: ISafetyEvent[];
	refetchSafetyEvents: () => Promise<QueryObserverResult<ISafetyEvent[], unknown>>;
	isInitialLoading: boolean;
} => {
	const startDateTimestamp: number = moment.tz(startDate, tz).valueOf();
	const endDateTimestamp: number = moment.tz(endDate, tz).valueOf();
	let areaFloorQuery: string = '';
	if (areaId) {
		areaFloorQuery += `&areaId=${areaId}`;
	}
	if (floorId) {
		areaFloorQuery += `&floorId=${floorId}`;
	}
	const {
		data: safetyEvents,
		refetch: refetchSafetyEvents,
		isInitialLoading,
	} = useQuery<ISafetyEvent[]>(
		['safetyEventsByTimeRange', projectId, tz, startDate, endDate],
		() =>
			requestService.get(
				`/safety/rangeSafetyEvents?startRangeTimestamp=${startDateTimestamp}&endRangeTimestamp=${endDateTimestamp}${areaFloorQuery}`
			),
		{
			useErrorBoundary: true,
			initialData: [],
			enabled: !!(areaId || floorId),
		}
	);
	return { safetyEvents, refetchSafetyEvents, isInitialLoading };
};

export const useSafetyEventsByTagIdQuery = (
	tagId: string,
	investigatedTimestamp: number,
	shouldGetFullDay: boolean,
	enabled: boolean
): { safetyEvents: ISafetyEvent[]; isInitialLoading: boolean } => {
	const route = shouldGetFullDay ? 'safetyEventsByTagIdInOneDayTimeframe' : 'safetyEventsByTagIdInOneHourTimeframe';
	const url = `/safety/${tagId}/${route}?timestamp=${investigatedTimestamp}`;
	const { data: safetyEvents, isInitialLoading } = useQuery(
		['safetyEventsByTagId', tagId],
		() => requestService.get(url),
		{
			enabled,
			useErrorBoundary: true,
		}
	);
	return { safetyEvents, isInitialLoading };
};
