import { IWeekDayActivity } from '../../../interfaces';
import { CalendarToggleOptions } from '../../../constants';
import React, { useEffect } from 'react';
import classes from './styles.module.scss';
import greyFloorsIcon from '../../../assets/workplanIcons/greyFloorsIcon.svg';
import { translationService } from '../../../index';
import { getNumberOfActiveFloors, getTotalWorkTimeForWeekDayActivity } from '../../../utils/calendar.utils';
import greyClock from '../../../assets/workplanIcons/greyClock.svg';
import { issuesIcon } from '@shared/assets/icons';
import { ActivityGroupStatus } from '@shared/interfaces/ActivityGroupStatus.enum';
import { TooltipedIcon } from '@src/Components/Tooltips/TooltipedIcon';

export const IconsComponent = ({
	setShouldShowTooltip,
	activity,
	chipWidth,
	workPlanToggle,
}: {
	activity: IWeekDayActivity;
	chipWidth: number;
	workPlanToggle?: CalendarToggleOptions;
	setShouldShowTooltip: (shouldShowTooltip: boolean) => void;
}) => {
	const isRtl: boolean = translationService.getIsRtl();
	const isMediumLargeChipLength: boolean = chipWidth > 200;

	useEffect(() => {
		setShouldShowTooltip(!isMediumLargeChipLength);
	}, [chipWidth, setShouldShowTooltip, isMediumLargeChipLength]);

	return (
		<>
			{isMediumLargeChipLength && (
				<div className={classes.iconsWrapper}>
					{activity.status !== ActivityGroupStatus.planned && (
						<div className={classes.itemWithIcon}>
							<TooltipedIcon tooltipText={translationService.get('activeFloors')}>
								<img className={classes.icon} src={greyFloorsIcon} alt="floors icon" />
							</TooltipedIcon>
							<div className={classes.activeFloorsText} style={{ direction: isRtl ? 'initial' : 'ltr' }}>
								<div className={classes.bigNumber} data-testid="active-floors">
									{getNumberOfActiveFloors(activity.floors)}
								</div>
								<div className={classes.smallNumber} data-testid="floors-count">
									/{activity.floors.length}
								</div>
							</div>
						</div>
					)}
					{activity.status !== ActivityGroupStatus.planned && (
						<div className={classes.itemWithIcon}>
							<TooltipedIcon
								tooltipText={
									CalendarToggleOptions.PROFESSIONS
										? translationService.get('totalAccumulatedWorkHours')
										: translationService.get('hoursSpentOnFloor')
								}
							>
								<img className={classes.icon} src={greyClock} alt="hours icon" />
							</TooltipedIcon>
							<div className={classes.smallNumber} data-testid="total-work-time">
								{getTotalWorkTimeForWeekDayActivity(
									activity,
									workPlanToggle === CalendarToggleOptions.FLOORS
								)}
								h
							</div>
						</div>
					)}
					{!!activity.issues?.length && (
						<div className={classes.itemWithIcon}>
							<TooltipedIcon tooltipText={translationService.get('issues')}>
								<img
									className={classes.icon}
									style={{ height: '18px', width: '18px' }}
									src={issuesIcon}
									alt="issues"
								/>
							</TooltipedIcon>
							<div className={classes.smallNumber} data-testid="issues-count">
								{activity.issues.length}
							</div>
						</div>
					)}
				</div>
			)}
		</>
	);
};
