import React, { useState } from 'react';
import { IProfession } from '@interfaces/index';
import { translationService } from '@src/servicesInitializers';
import { ProfessionDisplayWithTradeIcon } from '@shared/components/ProfessionDisplayWithTradeIcon/ProfessionDisplayWithTradeIcon';
import { useSelector } from 'react-redux';
import { selectProjectId } from '@store/slices/project.slice';
import { GroupedMultipleDropdown } from '../../DesignSystem/GroupedMultipleDropdown/GroupedMultipleDropdown';
import { getTradeGroupTranslation } from '@utils/translations.utils';
import { getProfessionDisplayText } from '@utils/professions.utils';

interface IMultiProfessionGroupedDropdownProps {
	professions: IProfession[];
	onChange: (professions: IProfession[]) => void;
	defaultSelectedProfessions?: IProfession[];
	placeholder?: string;
}

export const MultiProfessionGroupedDropdown = (props: IMultiProfessionGroupedDropdownProps) => {
	const [selectedProfessions, setSelectedProfessions] = useState<IProfession[] | undefined>(
		props.defaultSelectedProfessions
	);
	const projectId: string = useSelector(selectProjectId)!;

	const getOptionLabel = (profession: IProfession): React.ReactNode => {
		return <ProfessionDisplayWithTradeIcon profession={profession} projectId={projectId} />;
	};

	const groupByFunction = (option: IProfession) => {
		return getTradeGroupTranslation(option);
	};

	const onProfessionChange = (value: IProfession[]) => {
		setSelectedProfessions(value);
		props.onChange(value);
	};

	return (
		<GroupedMultipleDropdown<IProfession>
			options={props.professions}
			value={selectedProfessions}
			onChange={onProfessionChange}
			placeholder={props.placeholder || translationService.get('selectProfession')}
			required
			getDisplayOption={getOptionLabel}
			getInputDisplay={(option: IProfession) => getProfessionDisplayText(option)}
			optionSelectedKey={'_id'}
			testId={'multiProfessionGroupedDropdown'}
			groupByFunction={groupByFunction}
			totalSelectedInputText={translationService.get('professions')}
			isSelectAllOption
		/>
	);
};
