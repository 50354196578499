import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IHorizontalBarData } from './interfaces/IHorizontalBarData.interface';
import { textStyle } from '@shared/utils/text.utils';
import { TrusstorTooltip } from '@shared/components/TrusstorTooltip/TrusstorTooltip';
import { COLORS } from '../../../../../constants';

interface IStyleProps {
	barWidthPercentage?: number;
	backgroundColor?: string;
	barHeight?: number;
}

const useStyles = makeStyles((theme) => ({
	labelContainer: textStyle({
		fontClass: 'body1',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	}),
	bar: ({ barWidthPercentage, backgroundColor, barHeight }: IStyleProps) => ({
		width: `${barWidthPercentage}%`,
		minWidth: '1px',
		backgroundColor: backgroundColor,
		borderRadius: '0 5px 5px 0',
		minHeight: barHeight,
	}),
	barContainer: textStyle({
		fontClass: 'h2',
		width: '100%',
		marginBottom: theme.spacing(1),
	}),
	barWrapper: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	endNumberLabel: {
		marginLeft: theme.spacing(1),
	},
	tableText: textStyle({
		fontClass: 'body2',
		textAlign: 'center',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		color: COLORS.white,
	}),
}));

interface ISingleHorizontalBar {
	barData: IHorizontalBarData;
	barWidthPercentage: number;
	barHeight: number;
	endNumberLabel: boolean;
	labelStyleClass?: string;
	toolTipMessage?: string;
}

const SingleHorizontalBar = (props: ISingleHorizontalBar) => {
	const classes = useStyles({
		barWidthPercentage: props.barWidthPercentage,
		backgroundColor: props.barData.backgroundColor,
		barHeight: props.barHeight,
	});
	const barEl = useRef<HTMLDivElement>(null);
	return (
		<div className={classes.barContainer} ref={barEl}>
			<div className={`${classes.labelContainer} ${props.labelStyleClass || ''}`}>{props.barData.label}</div>
			<div className={classes.barWrapper}>
				<TrusstorTooltip
					refChildren={barEl}
					cleanStyling
					tooltipComponent={<p className={classes.tableText}>{props.toolTipMessage}</p>}
					showAlways={!!props.toolTipMessage && !props.barData.disabledTooltip}
				>
					<div className={classes.bar} />
				</TrusstorTooltip>
				{props.endNumberLabel && <div className={classes.endNumberLabel}>{props.barData.number}</div>}
			</div>
		</div>
	);
};

export { SingleHorizontalBar };
