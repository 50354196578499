import { useProjectIssuesQuery } from '@src/hooks/queries/issues.queries.hooks';
import React, { useEffect } from 'react';
import { IIssue } from '@shared/interfaces/IIssue';
import { useSelector } from 'react-redux';
import { selectProjectId } from '@store/slices/project.slice';
import { useIssuesCrud } from '@src/hooks/issues.hooks';
import { IssuesTabQuickFilterTypes } from '@src/Components/MainPageIssuesTab/MainPageIssuesTab';

export const useMainPageIssues = () => {
	const projectId: string = useSelector(selectProjectId)!;
	const { issues: fetchedIssues, isFetched } = useProjectIssuesQuery(projectId, {
		notCompleted: true,
	});
	const [issues, setIssues] = React.useState<IIssue[]>([]);
	const { updateIssue, deleteIssue } = useIssuesCrud(setIssues, issues);
	const [issuesTabSelectedFilter, setIssuesTabSelectedFilter] = React.useState<IssuesTabQuickFilterTypes>(
		IssuesTabQuickFilterTypes.all
	);

	useEffect(() => {
		if (!fetchedIssues) return;
		const issuesWithoutInitial: IIssue[] = fetchedIssues.filter((issue) => !issue.isInitial);
		setIssues(issuesWithoutInitial);
	}, [fetchedIssues]);

	return {
		issues,
		setIssues,
		updateIssue,
		deleteIssue,
		issuesTabSelectedFilter,
		setIssuesTabSelectedFilter,
		isFetched,
	};
};
