import React from 'react';
import { IconSize, TrusstorIconShared } from '../TrusstorIconShared/TrusstorIconShared';
import { IconNames } from '../TrusstorIconShared/IconNames.enum';
import { useSharedServices } from '../../hooks/sharedServices.context';

interface IArrowUpDownProps {
	isOpen: boolean;
	horizontalClosed?: boolean;
	size?: string;
	disabled?: boolean;
	useReverseOpenDirection?: boolean;
}

export const ArrowUpDown = React.memo((props: IArrowUpDownProps) => {
	const { translationService } = useSharedServices();

	const openArrowIconName: IconNames = !props.useReverseOpenDirection ? IconNames.chevronUp : IconNames.chevronDown;
	const closedArrowIconName: IconNames = !props.useReverseOpenDirection ? IconNames.chevronDown : IconNames.chevronUp;

	const getIconOpenName = (): IconNames => {
		if (props.horizontalClosed) {
			if (props.isOpen) {
				return openArrowIconName;
			}
			return translationService.getIsRtl() ? IconNames.chevronLeft : IconNames.chevronRight;
		}
		return props.isOpen ? openArrowIconName : closedArrowIconName;
	};

	return <TrusstorIconShared iconName={getIconOpenName()} size={IconSize.MEDIUM} />;
});
