import React, { useContext, useEffect, useMemo } from 'react';
import classes from './styles.module.scss';
import { translationService } from '@src/servicesInitializers';
import { ISequenceItemProgressBySequenceItemReport } from '@interfaces/ISequenceItemProgressBySequenceItemReport';
import { filterActivitiesProgressReports } from '@src/Components/SequenceProgressAnalysis/ActivitiesProgress/ActivitiesProgressFilterSection/utils';
import { IProfession } from '@shared/interfaces/IProfession';
import { compact, countBy, uniqBy } from 'lodash';
import { Placement } from '@popperjs/core';
import { getProfessionDisplayText } from '@utils/professions.utils';
import { SmallMultipleDropdown } from '@src/Components/Dropdowns/DesignSystem/SmallMultipleDropdown/SmallMultipleDropdown';
import { useSequenceItemsByProjectQuery } from '@src/hooks/queries/sequenceItem.query';
import { selectProjectId } from '@store/slices/project.slice';
import { useSelector } from 'react-redux';
import { ISequenceProgressAnalysisContext, SequenceProgressAnalysisContext } from '../../SequenceProgressAnalysis';

enum ProgressFilterStatus {
	NotStarted = 'notStarted',
	Completed = 'Completed',
	Ongoing = 'Ongoing',
}

enum StarredFilterStatus {
	starred = 'starred',
	notStarred = 'notStarred',
}

interface IStarredFilterOption {
	text: string;
	count: number;
	type: StarredFilterStatus;
}

interface IProgressFilterOption {
	text: string;
	count: number;
	type: ProgressFilterStatus;
}

interface IActivitiesProgressFilterSectionProps {
	reports: ISequenceItemProgressBySequenceItemReport[];
	setFilteredReports: (reports: ISequenceItemProgressBySequenceItemReport[]) => void;
}

export const ActivitiesProgressFilterSection = (props: IActivitiesProgressFilterSectionProps) => {
	const dropdownPlacement: Placement = translationService.getIsRtl() ? 'bottom-start' : 'bottom-end';
	const [selectedProgressStatuses, setSelectedProgressStatuses] = React.useState<IProgressFilterOption[]>([]);
	const [selectedStarredStatuses, setSelectedStarredStatuses] = React.useState<IStarredFilterOption[]>([]);
	const [selectedProfessions, setSelectedProfessions] = React.useState<IProfession[]>([]);

	const activeProfessions: IProfession[] = useMemo(() => {
		const reportProfessions: (IProfession | undefined)[] = uniqBy(props.reports, (report) => {
			return report.profession._id;
		}).map((report) => report.profession);
		return compact(reportProfessions);
	}, [props.reports]);
	const professionToReportCountMap: { [professionId: string]: number } = useMemo(() => {
		return countBy(props.reports, (report) => report.profession._id);
	}, [props.reports]);

	const starredFilterOptions: IStarredFilterOption[] = useMemo(() => {
		const starredCount: number = props.reports.filter((report) => report.isStarred).length;
		const notStarredCount: number = props.reports.length - starredCount;
		return [
			{
				type: StarredFilterStatus.starred,
				text: translationService.get('starred'),
				count: starredCount,
			},
			{
				type: StarredFilterStatus.notStarred,
				text: translationService.get('notStarred'),
				count: notStarredCount,
			},
		];
	}, [props.reports]);

	const progressFilterOptions: IProgressFilterOption[] = useMemo(() => {
		const completedCount: number = props.reports.filter(
			(report) => report.overallProgress.totalAsisInPeriod === report.overallProgress.completedAsiInPeriod
		).length;
		const notStartedCount: number = props.reports.filter(
			(report) => report.overallProgress.completedAsiInPeriod === 0
		).length;
		const ongoingCount: number = props.reports.length - completedCount - notStartedCount;
		return [
			{
				text: translationService.get('progressReport_activitiesTableFilters_notStarted'),
				count: notStartedCount,
				type: ProgressFilterStatus.NotStarted,
			},
			{
				text: translationService.get('ongoing'),
				count: ongoingCount,
				type: ProgressFilterStatus.Ongoing,
			},
			{
				text: translationService.get('completed'),
				count: completedCount,
				type: ProgressFilterStatus.Completed,
			},
		];
	}, [props.reports]);

	useEffect(() => {
		const filteredReports: ISequenceItemProgressBySequenceItemReport[] = filterActivitiesProgressReports(
			props.reports,
			selectedStarredStatuses.filter((status) => status.type === StarredFilterStatus.starred).length > 0,
			selectedStarredStatuses.filter((status) => status.type === StarredFilterStatus.notStarred).length > 0,
			selectedProgressStatuses.filter((status) => status.type === ProgressFilterStatus.NotStarted).length > 0,
			selectedProgressStatuses.filter((status) => status.type === ProgressFilterStatus.Ongoing).length > 0,
			selectedProgressStatuses.filter((status) => status.type === ProgressFilterStatus.Completed).length > 0,
			selectedProfessions
		);
		props.setFilteredReports(filteredReports);
	}, [selectedProgressStatuses, selectedStarredStatuses, selectedProfessions, props.reports]);

	return (
		<div className={classes.filtersContainer}>
			<SmallMultipleDropdown
				options={progressFilterOptions}
				value={selectedProgressStatuses}
				onChange={(options) => setSelectedProgressStatuses(options)}
				getDisplayOption={(option) => option.text}
				getSecondDisplayOption={(option) => option.count.toString()}
				label={translationService.get('progressStatus')}
				dropdownWidth={'175px'}
				popperPlacement={dropdownPlacement}
			/>
			<SmallMultipleDropdown
				options={starredFilterOptions}
				value={selectedStarredStatuses}
				onChange={(options) => setSelectedStarredStatuses(options)}
				getDisplayOption={(option) => option.text}
				getSecondDisplayOption={(option) => option.count.toString()}
				label={translationService.get('starredFilter')}
				dropdownWidth={'175px'}
				popperPlacement={dropdownPlacement}
			/>
			<SmallMultipleDropdown
				options={activeProfessions}
				value={selectedProfessions}
				onChange={(options) => setSelectedProfessions(options)}
				getDisplayOption={(option) => getProfessionDisplayText(option)}
				getSecondDisplayOption={(option) => professionToReportCountMap[option._id].toString()}
				label={translationService.get('profession')}
				dropdownWidth={'max-content'}
				popperPlacement={dropdownPlacement}
			/>
		</div>
	);
};
