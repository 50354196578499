import React, { useMemo, useState } from 'react';
import classes from './styles.module.scss';
import { TooltipedHoverIcon } from '../../TooltipedHoverIcon';
import mailIconGray from '../../../assets/mailIconGray.svg';
import mailIconBold from '../../../assets/mailIconBold.svg';
import { requestService, translationService } from '../../../index';
import { trashIconBlack, trashIconGrey } from '../../../assets/trashIcons';
import { pencilGreyIcon, pencilIcon } from '../../../assets/adminPanel';
import { resetUserPasswordApi } from '../../../apis/entities.api';
import { DeleteUserDialog } from '../../DeleteUserDialog/DeleteUserDialog';
import { IUser, IUserWithLanguage } from '@shared/interfaces/IUser';
import { UserDialog } from '../../UserDialog/UserDialog';
import { useDispatch } from 'react-redux';
import { errorSnackbar, successSnackbar } from '../../../utils/snackbar.utils';

interface IUsersTableRowButtonsProps {
	user: IUser;
	onExitDialog: () => void;
	users: IUser[];
}

export const UsersTableRowButtons = (props: IUsersTableRowButtonsProps) => {
	const [showEditUserDialog, setShowEditUserDialog] = useState<boolean>(false);
	const [showDeleteUserDialog, setShowDeleteUserDialog] = useState<boolean>(false);
	const dispatch = useDispatch();

	const sendUserResetPasswordEmail = async () => {
		try {
			await resetUserPasswordApi(props.user.email);
			successSnackbar(
				dispatch,
				`${translationService.get('resetPasswordEmailSent')} ${translationService.get('to:').toLowerCase()} ${
					props.user.email
				}`
			);
		} catch (e) {
			errorSnackbar(dispatch, translationService.get('resetPasswordEmailSentFailed'));
		}
	};

	const onCloseDeleteUserDialog = () => {
		setShowDeleteUserDialog(false);
		props.onExitDialog();
	};

	const onEditUserSubmit = async (user: Partial<IUserWithLanguage>) => {
		try {
			await requestService.put(`/entities/user/${user.username}`, {
				body: {
					...user,
					username: undefined,
					email: undefined,
				},
			});
			successSnackbar(dispatch, translationService.get('userUpdatedSuccessfully'));
			setShowEditUserDialog(false);
			props.onExitDialog();
		} catch (e) {
			errorSnackbar(dispatch, translationService.get('userUpdateFailed'));
		}
	};

	return (
		<React.Fragment>
			{showDeleteUserDialog && (
				<DeleteUserDialog user={props.user} show={showDeleteUserDialog} onClose={onCloseDeleteUserDialog} />
			)}
			{showEditUserDialog && (
				<UserDialog
					user={props.user}
					users={props.users}
					onClose={() => setShowEditUserDialog(false)}
					handleSubmitButtonClick={onEditUserSubmit}
				/>
			)}

			<div className={classes.deleteButtonWrapper}>
				<TooltipedHoverIcon
					onClick={sendUserResetPasswordEmail}
					baseIcon={mailIconGray}
					hoverIcon={mailIconBold}
					tooltipText={translationService.get('sendResetPassword')}
					styleClass={classes.avatar}
				/>
				<TooltipedHoverIcon
					onClick={() => setShowEditUserDialog(true)}
					baseIcon={pencilGreyIcon}
					hoverIcon={pencilIcon}
					tooltipText={translationService.get('edit')}
					styleClass={classes.avatar}
				/>
				<TooltipedHoverIcon
					onClick={() => setShowDeleteUserDialog(true)}
					baseIcon={trashIconGrey}
					hoverIcon={trashIconBlack}
					tooltipText={translationService.get('delete')}
					styleClass={classes.avatar}
				/>
			</div>
		</React.Fragment>
	);
};
