import React from 'react';
import classes from './styles.module.scss';
import Lottie from 'react-lottie';
import LiveIconAnimation from './liveIconAnimation.json';

export const LiveActionAnimatedIcon = () => {
	return (
		<div className={classes.LiveActionAnimatedIcon_container} data-testid="LiveActionAnimatedIcon">
			<Lottie
				options={{
					loop: true,
					autoplay: true,
					animationData: LiveIconAnimation,
				}}
			/>
		</div>
	);
};
