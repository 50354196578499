import React, { useState } from 'react';
import { ReportCard } from '@src/Components/Pages/AnalysisCenterPages/CardsSection/ReportCard';
import { InvestigationReportTypes } from '@shared/interfaces/InvestigationReportTypes.enum';
import { translationService } from '@src/servicesInitializers';
import { useDispatch, useSelector } from 'react-redux';
import { selectProjectId, selectTimezone, selectWorkingProject } from '@store/slices/project.slice';
import { PeriodSelectorTrusstorInput } from '@src/Components/DateSelector/PeriodSelectorTrusstorInput/PeriodSelectorTrusstorInput';
import { errorSnackbar } from '@utils/snackbar.utils';
import { exportScissorLiftReportToExcel } from '@utils/scissorLiftReport.excel.utils';
import { IProject } from '@shared/interfaces/IProject';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { getTimezoneStartOfDate } from '@utils/dateUtils';
import { getIsProdEnv } from '@utils/env.utils';

interface IScissorLiftExportCardProps {
	isSelected: boolean;
	setSelectedCard: (name?: string) => void;
}

const ScissorLiftExportCard = (props: IScissorLiftExportCardProps) => {
	const workingProject: IProject = useSelector(selectWorkingProject)!;
	const title: string = translationService.get('scissorLiftExportCardTitle');
	const description: string = translationService.get('scissorLiftExportCardDescription');
	const name: InvestigationReportTypes = InvestigationReportTypes.scissorLiftReportExport;
	const tz: string = useSelector(selectTimezone)!;
	const projectId: string = useSelector(selectProjectId)!;
	const [selectedPeriod, setSelectedPeriod] = useState<[Date, Date] | undefined>(undefined);
	const dispatch = useDispatch();
	const [isExporting, setIsExporting] = useState(false);

	const generateAndExportReport = async () => {
		try {
			setIsExporting(true);
			await exportScissorLiftReportToExcel(
				projectId,
				workingProject.name,
				tz,
				selectedPeriod![0],
				selectedPeriod![1],
				workingProject.countryShortName
			);
			setIsExporting(false);
			setSelectedPeriod(undefined);
			props.setSelectedCard(undefined);
		} catch (e) {
			errorSnackbar(dispatch, translationService.get('failedToGenerateReport'));
			console.error('Failed to generate report', e);
		}
	};

	const isGenerateDisabled = () => {
		return !selectedPeriod?.[0] || !selectedPeriod?.[1];
	};

	const updatePeriod = (period: [Date, Date]) => {
		setSelectedPeriod(period);
	};

	const minDate: Date | undefined = getIsProdEnv() ? getTimezoneStartOfDate(tz, '04.06.2024') : undefined;

	return (
		<ReportCard
			isLoading={isExporting}
			buttonText={translationService.get('exportScissorLiftReportCardButtonText')}
			name={name}
			isSelected={props.isSelected}
			icon={IconNames.scissorsLift}
			title={title}
			description={description}
			generateReport={generateAndExportReport}
			isGenerateReportDisabled={isGenerateDisabled()}
			setSelectedCard={props.setSelectedCard}
		>
			<PeriodSelectorTrusstorInput updatePeriod={updatePeriod} datePeriod={selectedPeriod} minDate={minDate} />
		</ReportCard>
	);
};

export { ScissorLiftExportCard };
