import { Checkbox, FormControl, Popper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { cloneDeep, groupBy, isEqual, orderBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { HighlightedTextField } from '../../TextFields/HighlightedTextField';
import { TrusstorCheckbox } from '../../TrusstorCheckbox/TrusstorCheckbox';
import { MuiTrusstorTextInput } from '../../Inputs/MuiTrusstorTextInput/MuiTrusstorTextInput';
import { IGroupedAutoCompleteDropdownProps } from './IGroupedAutoCompleteDropdownProps';
import classes from './styles.module.scss';
import Autocomplete, { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete';

interface ISecondGroupOption {
	type: 'secondGroupOption';
	name: string;
	groupName: string;
	values: any[];
}

export default function GroupedAutoCompleteDropdown(props: IGroupedAutoCompleteDropdownProps) {
	const getInitialAutoCompleteValue = () => {
		if (Array.isArray(props.value)) {
			return '';
		}

		if (props.value) {
			return props.getOptionLabel(props.value);
		}

		return '';
	};

	const getInitialSelectedOptions = () => {
		if (Array.isArray(props.value)) {
			return props.value;
		}
		return [];
	};

	interface IStylesProps {
		iconSize?: string;
	}

	const useStyles = makeStyles({
		root: {
			'paddingInlineStart': 0,
			'&:hover': {
				backgroundColor: 'transparent',
			},
		},

		icon: ({ iconSize }: IStylesProps) => ({
			width: iconSize ? `${iconSize} !important` : undefined,
			height: iconSize ? `${iconSize} !important` : undefined,
		}),
	});

	const [autoCompleteValue, setAutoCompleteValue] = useState<string>(getInitialAutoCompleteValue());
	const [selectedOptions, setSelectedOptions] = useState<any[]>(getInitialSelectedOptions());
	const [finalOptions, setFinalOptions] = useState<any[]>([]);

	useEffect(() => {
		const selectedOptionsValue: any[] = props.value ?? [];
		setSelectedOptions(selectedOptionsValue);
	}, [props.value]);

	const onAutoCompleteChange = (event: any, value: any) => {
		// This fixes a bug when the input get cleared automatically, and we are checking that when the input needs to really be cleared we are actually clearing it
		if (!event && value === '' && (props.optionKey ? props.value[props.optionKey] !== '' : props.value !== '')) {
			return;
		}

		setAutoCompleteValue(value);

		if (props.allowFreeText) {
			const updatedValue = props.optionKey ? { [props.optionKey]: value } : value;
			props.onChange(updatedValue);
		}
	};

	const groupBySecondProperty = (options: any[]): any[] => {
		const firstGroupBy: {
			[key: string]: any[];
		} = groupBy(options, dropDownGroupBy);
		return Object.keys(firstGroupBy).reduce((acc, firstGroupByKey): any => {
			const values: any[] = firstGroupBy[firstGroupByKey];
			const secondGroupBy: {
				[key: string]: any[];
			} = groupBy(values, props.secondGroupByFunction!);
			const isNotGroupByKey = (key: string) => key === '' || key === firstGroupByKey;
			const sortedSecondGroupByKeys: string[] = Object.keys(secondGroupBy).sort((a, b) => {
				if (a === '' || isNotGroupByKey(a)) {
					return 1;
				}
				if (b === '' || isNotGroupByKey(b)) {
					return -1;
				}
				return secondGroupBy[b].length - secondGroupBy[a].length;
			});
			const secondGroupedValues: any[] = sortedSecondGroupByKeys.flatMap((secondGroupByKey) => {
				const firstGroupByValue: string = dropDownGroupBy(secondGroupBy[secondGroupByKey][0]);
				const secondGroupOption: ISecondGroupOption[] =
					secondGroupByKey === firstGroupByKey
						? []
						: [
								{
									type: 'secondGroupOption',
									name: secondGroupByKey === '' ? firstGroupByKey : secondGroupByKey,
									values: secondGroupBy[secondGroupByKey],
									groupName: firstGroupByValue,
								},
						  ];
				return [...secondGroupOption, ...secondGroupBy[secondGroupByKey]];
			});
			return [...acc, ...secondGroupedValues];
		}, []);
	};

	useEffect(() => {
		const finalOptions: any[] = [];

		if (props.isSelectAllOption) finalOptions.push({ type: 'selectAll' });

		if (props.commonGroupName && props.optionIsCommonProperty) {
			const commonOptions: any[] = cloneDeep(
				props.options.filter((option) => option[props.optionIsCommonProperty!])
			).map((option) => ({
				...option,
				groupName: props.commonGroupName,
			}));

			finalOptions.push(...commonOptions);
		}

		const optionsSortByGroup: any[] = props.optionsAreSorted
			? props.options
			: orderBy(props.options, [props.optionGroupProperty, props.optionKey], ['asc', 'asc']);

		const secondGroupedOptions: any[] = !props.secondGroupByFunction
			? optionsSortByGroup
			: groupBySecondProperty(optionsSortByGroup);

		finalOptions.push(...secondGroupedOptions);
		setFinalOptions(finalOptions);
	}, [
		props.commonGroupName,
		props.optionGroupProperty,
		props.optionIsCommonProperty,
		props.optionKey,
		props.options,
	]);

	const SelectAllOption = ({ checked }) => (
		<div className={classes.selectAllOption}>
			<div className={classes.selectAllOptionText}>
				<Checkbox checked={checked} className={useStyles({}).root} />
				<HighlightedTextField text={props.translationService.get('selectAll')} highlight={autoCompleteValue} />
			</div>
			<div>
				<p>{finalOptions.length - 1}</p>
			</div>
		</div>
	);

	const SelectSecondGroupOption = ({ option, selected }: { option: ISecondGroupOption; selected: boolean }) => (
		<div className={classes.secondGroupClass}>
			<div className={classes.secondGroupClassText}>
				<Checkbox checked={selected} />
				<p>{option.name} </p>
			</div>
			<div>
				<p>{option.values.length}</p>
			</div>
		</div>
	);

	const isSelectedOptionSelectAll = (option: any): boolean => option && option.type === 'selectAll';

	const getOptionColor = (option?: any): string => {
		if ((!props.optionColorProperty && !props.optionColorPropertyFunction) || !option) {
			return 'transparent';
		}
		const colorOption: string | undefined = props.optionColorPropertyFunction
			? props.optionColorPropertyFunction(option)
			: option[props.optionColorProperty!];

		return colorOption || 'transparent';
	};

	const getOptionsText = (tags: any[]): string => {
		if (props.isSelectAllOption) tags = tags.filter((tag) => tag.type !== 'selectAll');
		if (Array.isArray(tags)) {
			return tags.map((option) => props.getOptionLabel(option)).join(', ');
		}

		return props.getOptionLabel(tags);
	};

	const dropDownGroupBy = (option: any): string => {
		return props.groupByFunction ? props.groupByFunction(option) : option[props.optionGroupProperty];
	};

	const finalGroupByFunction = (option: any): string => {
		if (props.secondGroupByFunction && option.type === 'secondGroupOption') {
			return option.groupName;
		}
		return dropDownGroupBy(option);
	};

	const autocompleteGroupBy = (option: any): string => {
		if (props.isSelectAllOption && isSelectedOptionSelectAll(option)) return 'selectAllGroup';
		return finalGroupByFunction(option);
	};

	const autocompleteGetOptionLabel = (value: any): string => {
		if (props.isSelectAllOption && isSelectedOptionSelectAll(value)) {
			return props.translationService.get('selectAll');
		}
		if (!value || (Array.isArray(value) && value.length === 0)) {
			return props.placeholder ?? '';
		}
		return props.getOptionLabel(value);
	};

	const onSelectAllOptionClicked = () => {
		const areAllOptionsChecked: boolean = selectedOptions.length === finalOptions.length - 1;
		if (areAllOptionsChecked) {
			//unselect all
			setSelectedOptions([]);
			return props.onChange([]);
		}
		const filteredValues: any[] = finalOptions.filter((option) => !isSelectedOptionSelectAll(option));
		setSelectedOptions(filteredValues);
		return props.onChange(filteredValues);
	};

	const areOptionsSelected = (options: any[]): boolean => {
		return options.every(isOptionSelected);
	};

	const onSecondGroupOptionClicked = (optionClicked: ISecondGroupOption) => {
		const secondGroupOptions: any[] = optionClicked.values;
		const areAllSecondGroupOptionsSelected: boolean = areOptionsSelected(secondGroupOptions);
		const newSelectedOptions: any[] = areAllSecondGroupOptionsSelected
			? selectedOptions.filter(
					(selectedOption) => !secondGroupOptions.some((option) => isEqual(option, selectedOption))
			  )
			: [...selectedOptions, ...secondGroupOptions];
		setSelectedOptions(newSelectedOptions);
		return props.onChange(newSelectedOptions);
	};

	const isSelectAllOptionClicked = (optionClicked: any): boolean => {
		return (
			props.isSelectAllOption &&
			optionClicked &&
			optionClicked.length > 0 &&
			isSelectedOptionSelectAll(optionClicked[optionClicked.length - 1])
		);
	};
	const [grouped, setGrouped] = useState(true);
	const handleGroupedChange = (event) => {
		setGrouped(event.target.checked);
	};
	const isSecondGroupClicked = (optionClicked: any): boolean => {
		return (
			props.secondGroupByFunction &&
			optionClicked &&
			optionClicked.length > 0 &&
			optionClicked[optionClicked.length - 1].type === 'secondGroupOption'
		);
	};

	const isOptionSelected = (option: any): boolean => {
		return selectedOptions.some((selectedOption) => isEqual(selectedOption, option));
	};

	const uniqeArray = (arr: any[]): any[] => {
		const grouped: {
			[key: string]: any[];
		} = groupBy(arr, (option) => JSON.stringify(option));
		const removeMoreThanOnceAppearences: any[] = Object.values(grouped).reduce((acc, groupedValues) => {
			if (groupedValues.length === 1) {
				return [...acc, groupedValues[0]];
			}
			return acc;
		}, []);
		return removeMoreThanOnceAppearences;
	};

	return (
		<FormControl className={classes.autocompleteContainer}>
			<Autocomplete
				popupIcon={<ExpandMoreIcon className={useStyles({ iconSize: props.iconSize }).icon} />}
				renderTags={(value) => {
					return props.getRenderTagsElement ? (
						props.getRenderTagsElement(value, autoCompleteValue === '')
					) : (
						<span className={classes.tagsContainer}>{getOptionsText(value)}</span>
					);
				}}
				multiple={props.isMultipleChoice}
				disableCloseOnSelect={props.isMultipleChoice}
				disableClearable={props.hideClearTextButton}
				inputValue={autoCompleteValue}
				onInputChange={onAutoCompleteChange}
				onChange={(event: any, value: any) => {
					if (isSelectAllOptionClicked(value)) {
						//select all option already been triggered
						return onSelectAllOptionClicked();
					}
					if (isSecondGroupClicked(value)) {
						//second group option already been triggered
						return onSecondGroupOptionClicked(value[value.length - 1] as ISecondGroupOption);
					}

					const newValue = !props.isMultipleChoice ? value : uniqeArray(value);

					setSelectedOptions(newValue);
					props.onChange(newValue);
				}}
				defaultValue={props.value}
				value={selectedOptions}
				onFocus={() => {
					!props.getTextInputElement && setAutoCompleteValue('');
				}}
				onBlur={() => {
					!props.getTextInputElement && setAutoCompleteValue(getInitialAutoCompleteValue());
				}}
				freeSolo={props.allowFreeText}
				forcePopupIcon={true}
				options={finalOptions}
				groupBy={autocompleteGroupBy}
				getOptionLabel={autocompleteGetOptionLabel}
				disabled={props.disabled}
				placeholder={props.placeholder}
				classes={{
					option: `${classes.optionStyle} ${
						props.optionStyleAdditionalClass ? props.optionStyleAdditionalClass : ''
					}`,
					popper: classes.popperStyle,
					loading: classes.loading,
					endAdornment: props.endAdornmentClassname,
				}}
				loading={props.loading}
				loadingText={props.loadingText}
				renderGroup={(params) => {
					if (props.isSelectAllOption && params.group === 'selectAllGroup')
						return (
							<div className={classes.groupContainer}>
								<div style={{ display: 'flex' }}>{params.children}</div>
							</div>
						);
					return (
						<div className={classes.groupContainer}>
							<div className={classes.groupUl}>
								<span className={classes.groupLabel}>{params.group.toUpperCase()}</span>
							</div>
							{params.children}
						</div>
					);
				}}
				renderOption={(option: any, { selected }) => {
					if (props.isSelectAllOption && isSelectedOptionSelectAll(option)) {
						return <SelectAllOption checked={selectedOptions.length === finalOptions.length - 1} />;
					}
					if (props.secondGroupByFunction && option.type === 'secondGroupOption') {
						return <SelectSecondGroupOption selected={areOptionsSelected(option.values)} option={option} />;
					}
					const optionSelected: boolean = props.isMultipleChoice ? isOptionSelected(option) : selected;
					return (
						<div
							className={classes.optionContainer}
							style={{
								[props.translationService.getDirection() === 'ltr'
									? 'borderLeft'
									: 'borderRight']: `2px solid ${getOptionColor(option)}`,
								...{
									...(props.secondGroupByFunction && {
										paddingInlineStart: '16px',
										marginInlineStart: '11px',
									}),
								},
							}}
						>
							{props.displayCheckbox && <TrusstorCheckbox checked={optionSelected} propagateEvent />}
							<HighlightedTextField text={props.getOptionLabel(option)} highlight={autoCompleteValue} />
						</div>
					);
				}}
				renderInput={(params: AutocompleteRenderInputParams) => {
					if (props.getTextInputElement) {
						const labeledOptions: any[] = Array.isArray(props.value) ? props.value : [props.value];
						const selectedValueOptionLabel: string = labeledOptions
							.map((option) => props.getOptionLabel(option))
							.join(', ');
						const isUserAutocompleteValue: boolean =
							autoCompleteValue !== '' && autoCompleteValue !== selectedValueOptionLabel;
						return props.getTextInputElement(params, isUserAutocompleteValue);
					}

					return (
						<MuiTrusstorTextInput
							direction={props.translationService.getDirection()}
							params={params}
							value={autoCompleteValue}
							label={props.label}
							placeholder={props.placeholder}
							disabled={props.disabled}
							isError={props.isError}
							errorText={props.errorText}
							startAdornmentType={props.optionColorProperty ? 'color' : undefined}
							colorAdornmentValue={props.optionColorProperty && getOptionColor(props.value)}
							required={props.required}
							disabledEnterKey={props.allowFreeText}
							inputContainerClassName={props.inputContainerClassName}
						/>
					);
				}}
				data-testid={props.testId ?? null}
			/>
		</FormControl>
	);
}
