export enum BIEventNames {
	professionAnalysisSelectDate = 'Subcontractor_select_date',
	summariesExport = 'Summaries - summary type_export summary',
	summariesDateFilter = 'Summaries - summary type_filter by time',
	summariesAccess = 'Summaries - summary type_access',
	userMainAccess = 'User main access while logged in',
	tabBecameVisible = 'Browser event - the application tab became visible',
	mainViewOpenFloor = 'Main View - open floor view',
	toggleDailyActivitiesProfessionsFloors = 'Daily Activities - view by floors or professions',
	openDailyActivitiesMainComponent = 'Daily activities - open main component',
	openDailyActivitiesSubComponent = 'Daily activities - open subcomponent',
	workplanAccess = 'Workplan - User access to the module',
	workplanToggleFloorsProfessions = 'Workplan - sort by profession or floors',
	workplanExportToExcel = 'Workplan - export workplan',
	workplanOpenActivityDialog = 'Workplan - workplan module_ open activity popup',
	workplanCreateActivity = 'Workplan - workplan module_activity creation',
	workplanEditActivity = 'Workplan - workplan module_update activity',
	workplanDeleteActivity = 'Workplan - workplan module_delete activity',
	hoistMainViewTooltip = 'Hoist - Main view_tooltip',
	systemTagPageAccess = 'System Management - Tags_module access',
	systemTagUnassignment = 'System Management - Tags_tag unassignment',
	systemTagAssignment = 'System Management - Tags_tag assignment',
	systemTagUpdate = 'System Management - Tags_tag update',
	systemProfessionsPageAccess = 'System Management - Professions_module access',
	systemProfessionCreate = 'System Management - Professions_create profession',
	systemProfessionUpdate = 'System Management - Professions_update profession',
	systemManagersAccess = 'System Management - Roles_module access',
	systemUsersAccess = 'System Management - Users_module access',
	addUserButtonClicked = 'System Management - Users_open_add_modal',
	userInvitation = 'System Management - Users_invite',
	userDelete = 'System Management - Users_delete',
	userUpdate = 'System Management - Users_update',
	userSendResetPassword = 'System Management - Users_send_reset_password',
	systemManagersCreate = 'System Management - Roles_create role',
	systemManagersUpdate = 'System Management - Roles_update role',
	systemManagersDelete = 'System Management - Roles_delete role',
	loginToMobileView = 'Mobile - Access to the mobile view',
	workplanWidgetSelectedView = 'Workplan widget - selected view',
	workplanActivityActions = 'Workplan - Activity actions',
	analysisCenterMainPageAccess = 'Analysis Center - Access',
	analysisCenterReportGeneration = 'Analysis Center - Report Generation',
	projectMonitorTabAccess = 'Project Monitor - Tab Access',
	workplanCalendarTabAccess = 'Workplan_calendar_tab access',
	workplanProgressTrackerTabAccess = 'Workplan_progress_tracker_tab_access',
	workplanOpenPlanning = 'Workplan_open_planning',
	workplanFloorNavigation = 'workplan_floor_navigation',
	workplanMatrixInteraction = 'workplan_matrix_interaction',
	batchWorkplanMatrixInteraction = 'workplan_matrix_batch_interaction',
	notificationCenterAccess = 'Desktop_Notification_center_tab_access ',
	projectMonitorMainTabAccess = 'Project Monitor -  Main Tab Access',
	workplanCreateLookahed = 'workplan_create_lookahed',
	notificationSettingsTabAccess = 'Desktop_Notification_settings_tab_access',
	notificationSettingsEngagement = 'Desktop_Notification_settings_engagement',
	notificationPreferencesEngagement = 'Desktop_Notification_preferences_engagement',
	createRestrictedAreaTrigger = 'create_restricted_area_trigger',
	issuesTabAccess = 'Desktop_issues_tab_access',
	issueStatusChange = 'Desktop_issues_status_change_click',
	issueComment = 'Desktop_issue_comment_creation',
	issueCreate = 'Desktop_issues_create_issue',
	issuesPageToggleProfessionsManagers = 'Issues Page - sort by profession or managers',
	progressAnalysisAccess = 'Desktop_progress_analysis_access',
	progressTrackerZoomIn = 'Desktop_progress_tracker_zoom_in',
	progressTrackerZoomOut = 'Desktop_progress_tracker_zoom_out',
	progressTrackerExpand = 'Desktop_progress_tracker_expand',
	progressTrackerMinimize = 'Desktop_progress_tracker_minimize',
}
