import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { checkForInitialValues, getProfessionsFilterData } from '../../../../utils/generalUtils';
import { TagsTable } from '../../../AdminPanelComponents/TagsTable/TagsTable';
import { InventoryFilterSideBar } from '../../../AdminPanelComponents/InventoryFilterSideBar';
import { ITag, IProfession, ITradeFilter } from '../../../../interfaces';
import { IAdminPanelFilters } from '../../../../interfaces/IAdminPanelFilters';
import {
	filterTagsByFixedFilters,
	filterTagsBySearchInput,
	getInitialFixedFilterState,
	getVisibleTagsAfterManagementAndProfessionsFilters,
} from '../../../../utils/adminPanelTags.utils';
import { IRootState } from '../../../../store/slices';
import { getStaticTagsStream, stopGettingAdminPanelTags } from '../../../../store/thunks';
import { getAdminPanelProfessions } from '../../../../store/thunks/professions.thunks';
import { getStaticManagers } from '../../../../store/thunks/managers.thunks';
import { ITrade } from '../../../../interfaces/ITrade';
import { removeAllProfessions, resetSelectedProfessions } from '../../../../store/slices/professions.slice';
import classes from './styles.module.scss';
import { RoleType } from '@shared/interfaces/RoleType.enum';
import { useRenderMonitoring } from '@shared/hooks/monitoring.hooks';

const TagsInventoryPage = ({ match }) => {
	useRenderMonitoring('TagsInventoryPage');
	const dispatch = useDispatch();
	const [visibleTags, setVisibleTags] = useState<ITag[]>([]);
	const projectId: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.projectId);
	const tz: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.tz);
	const filterList: ITradeFilter[] = useSelector((state: IRootState) => state.professionsReducer.filterList);
	const professions: IProfession[] = useSelector((state: IRootState) => state.professionsReducer.professions);
	const selectedProfessions: IProfession[] = useSelector(
		(state: IRootState) => state.professionsReducer.selectedProfessions
	);
	const visibleProfessions: IProfession[] = useSelector(
		(state: IRootState) => state.professionsReducer.visibleProfessions
	);
	const tags: ITag[] = useSelector((state: IRootState) => state.tagsReducer.tags);
	const visibleManagementTrades: ITrade[] = useSelector(
		(state: IRootState) => state.managementTradesReducer.visibleManagementTrades
	);
	const selectedManagementTrades: ITrade[] = useSelector(
		(state: IRootState) => state.managementTradesReducer.selectedManagementTrades
	);
	const managerFilterList: ITradeFilter[] = useSelector((state: IRootState) => state.managersReducer.filterList);
	const isProfessionsFilterInitialState: boolean = checkForInitialValues(selectedProfessions, filterList);
	const isManagementTradesFilterInitialState: boolean = checkForInitialValues(
		selectedManagementTrades,
		managerFilterList
	);
	const [searchInput, setSearchInput] = useState<string>('');
	const [topSectionFiltersState, setTopSectionFiltersState] = useState<IAdminPanelFilters>(
		getInitialFixedFilterState()
	);
	const isAdmin: boolean =
		useSelector((state: IRootState) => state.loginReducer.loggedUser?.userDetails.permissions.roleType) ===
		RoleType.TRUSSTOR_ADMIN;

	useEffect(() => {
		const visibleTagsAfterTopFilters: ITag[] = filterTagsByFixedFilters(tags, topSectionFiltersState, tz);
		const visibleTagsAfterManagementAndProfessionsFilter: ITag[] =
			getVisibleTagsAfterManagementAndProfessionsFilters(
				visibleTagsAfterTopFilters,
				isProfessionsFilterInitialState,
				visibleProfessions,
				isManagementTradesFilterInitialState,
				visibleManagementTrades
			);
		const visibleTagsAfterSearchFilter: ITag[] = filterTagsBySearchInput(
			visibleTagsAfterManagementAndProfessionsFilter,
			searchInput
		);
		setVisibleTags(visibleTagsAfterSearchFilter);
	}, [tags, topSectionFiltersState, searchInput, visibleProfessions, visibleManagementTrades]);

	useEffect(() => {
		dispatch(getAdminPanelProfessions(isAdmin));
		dispatch(getStaticTagsStream(projectId, isAdmin));
		dispatch(getStaticManagers({ projectId }));

		return () => {
			dispatch(removeAllProfessions());
			dispatch(resetSelectedProfessions());
			stopGettingAdminPanelTags();
		};
	}, [projectId, isAdmin]);

	const onFilterChange = (filterType: string, currentFilter: string, ev?: MouseEvent): void => {
		if (ev) {
			ev.stopPropagation();
		}
		setTopSectionFiltersState((prevState: IAdminPanelFilters) => ({
			...prevState,
			[filterType]: {
				...prevState[filterType],
				[currentFilter]: !prevState[filterType][currentFilter],
			},
		}));
	};

	const clearFilterChange = (filterType: string, ev?: MouseEvent): void => {
		if (ev) {
			ev.stopPropagation();
		}
		setTopSectionFiltersState((prevState: IAdminPanelFilters) => ({
			...prevState,
			[filterType]: getInitialFixedFilterState()[filterType],
		}));
	};

	return (
		<Grid container direction="column" className={classes.pageContainer}>
			<div className={classes.pageContent}>
				<Grid container direction="column" className={classes.pageDashboardContainer}>
					<InventoryFilterSideBar
						onFilterChange={onFilterChange}
						selectedProfessions={selectedProfessions}
						isProfessionsFilterInitialState={isProfessionsFilterInitialState}
						isManagementTradesFilterInitialState={isManagementTradesFilterInitialState}
						tagsFilterList={filterList}
						filterData={getProfessionsFilterData(professions)}
						filterState={topSectionFiltersState}
						clearFilterChange={clearFilterChange}
					/>
				</Grid>
				<Grid item container direction="column">
					<TagsTable tags={visibleTags} onSearchInputChange={setSearchInput} sortBy={match.params?.sortBy} />
				</Grid>
			</div>
		</Grid>
	);
};

export { TagsInventoryPage };
