import React from 'react';
import { map, sortBy } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { textStyle } from '@shared/utils/text.utils';
import { scaffoldIcon } from '../../assets/scaffolds';
import { entitiesProperties, SCAFFOLD_ICON_HEIGHT, SCAFFOLD_ICON_WIDTH } from '../../constants';
import { ManagerChip } from '../Chips/ManagerChip/ManagerChip';
import { ChipWithNumber } from '../Chips/ChipWithNumber';
import { EmptyState } from '../Boxes/Containers/EmptyState';
import { translationService } from '../../index';
import { TooltipedIcon } from '../Tooltips/TooltipedIcon';
import { IFloorData } from '../../interfaces/IFloorData';
import { getProfessionDisplayText } from '../../utils/professions.utils';
import { getProfessionBackgroundColor } from '@shared/utils/professions.utils';
import { useSelector } from 'react-redux';
import { selectProjectId } from '@store/slices/project.slice';

const useStyles = makeStyles({
	tooltipTextStyles: textStyle({
		fontClass: 'body1',
		color: '#242424',
		marginBottom: 3,
		marginTop: 3,
		marginRight: 4,
	}),
	divider: {
		height: 2,
		opacity: 0.12,
		backgroundColor: '#242424',
	},
	avatar: {
		borderRadius: '50%',
		boxShadow: '0px 3px 2px 0px rgba(0, 0, 0, 0.45)',
		backgroundColor: '#fefefe',
		position: 'absolute',
		top: -5,
		left: -3,
		height: 15,
		width: 15,
		zIndex: 12,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	scaffoldIconText: textStyle({
		color: '#3b3b3b',
		fontClass: 'body1',
		textAlign: 'center',
	}),
	scaffold: {
		width: SCAFFOLD_ICON_WIDTH,
		height: SCAFFOLD_ICON_HEIGHT,
	},
	tooltipSection: {
		display: 'flex',
		flexDirection: 'column',
	},
	chipsContainer: {
		display: 'flex',
		marginTop: 2,
		marginBottom: 4,
	},
});

interface IScaffoldIconProps {
	scaffolds: IFloorData[];
	floorId: string;
}

export const ScaffoldIcon = (props: IScaffoldIconProps) => {
	const classes = useStyles();
	const projectId: string = useSelector(selectProjectId)!;
	const isEmptyScaffold = (scaffold: IFloorData): boolean =>
		(!scaffold.managers || scaffold.managers.length === 0) &&
		(!scaffold.activeProfessions || scaffold.activeProfessions.length === 0);

	return (
		<React.Fragment>
			<TooltipedIcon
				key="scaffolds_tooltip"
				tooltipComponent={
					<div>
						{map(props.scaffolds, (scaffold, index) => {
							return (
								<React.Fragment>
									<div className={classes.tooltipSection} key={`div_${scaffold.floorId}_${index}`}>
										<p className={classes.tooltipTextStyles} key={scaffold.floorId}>
											{scaffold.floorNick}
										</p>
										{isEmptyScaffold(scaffold) ? (
											<EmptyState
												text={translationService.get('noWorkersFound')}
												customMargins={{ marginTop: 2, marginBottom: 4 }}
											/>
										) : (
											<div className={classes.chipsContainer}>
												{map(
													sortBy(scaffold.managers, [
														entitiesProperties.sortIndex,
														entitiesProperties.name,
													]),
													(mergedManager) => (
														<ManagerChip
															key={`floorview_${props.floorId}_${mergedManager.manager.name}`}
															chipText={mergedManager.manager.name}
															chipBackground={mergedManager.manager.backgroundColor}
														/>
													)
												)}
												{map(
													sortBy(scaffold.activeProfessions, [entitiesProperties.sortIndex]),
													(profession) => (
														<ChipWithNumber
															key={profession._id}
															chipColor={getProfessionBackgroundColor(
																profession,
																projectId
															)}
															chipText={getProfessionDisplayText(profession)}
															number={profession.activeTagsIds.length}
														/>
													)
												)}
											</div>
										)}
									</div>
									{index < props.scaffolds.length - 1 ? (
										<div className={classes.divider} key={'divider' + index} />
									) : null}
								</React.Fragment>
							);
						})}
					</div>
				}
			>
				<img className={classes.scaffold} src={scaffoldIcon} alt="scaffoldIcon" />
			</TooltipedIcon>
			<div className={classes.avatar} key="scaffolds_div">
				<p className={classes.scaffoldIconText}>{props.scaffolds.length}</p>
			</div>
		</React.Fragment>
	);
};
