import { useState, useCallback, useEffect, useRef } from 'react';

export const useElementIntersecting = (
	targetElement: HTMLElement | null,
	triggerCallback: (isIntersecting: boolean) => void,
	observerOptions?: IntersectionObserverInit,
	shoudlTriggerOnMount: boolean = false
) => {
	const isIntersectingRef = useRef(false);
	const isTriggeredOnMountRef = useRef(false);

	const observerCallback = useCallback(
		(entries) => {
			const isElementIntersecting = entries.some((entry) => entry.isIntersecting);

			if (shoudlTriggerOnMount && !isTriggeredOnMountRef.current) {
				triggerCallback(isElementIntersecting);
				isTriggeredOnMountRef.current = true;
				return;
			}

			if (!isElementIntersecting && isIntersectingRef.current) {
				triggerCallback(false);
				isIntersectingRef.current = false;
				return;
			}

			if (isElementIntersecting && !isIntersectingRef.current) {
				triggerCallback(true);
				isIntersectingRef.current = true;
			}
		},
		[triggerCallback]
	);

	useEffect(() => {
		if (!targetElement) return;

		const defaultObserverOptions = {
			root: null,
			rootMargin: '0px',
			threshold: 1,
		};

		const observer: IntersectionObserver = new IntersectionObserver(
			observerCallback,
			observerOptions || defaultObserverOptions
		);

		observer.observe(targetElement);
	}, [observerCallback, observerOptions, targetElement]);
};
