import classes from './styles.module.scss';
import { CalendarCellChip } from '../CalendarCellChips/CalendarCellChip';
import React, { useEffect, useState } from 'react';
import { IWorkPlanActivity } from '../../../interfaces';
import moment, { Moment } from 'moment';
import { selectTimezone } from '../../../store/slices/project.slice';
import { IRootState } from '../../../store/slices';
import { useSelector } from 'react-redux';
import { CalendarToggleOptions } from '../../../constants';
import { translationService } from '../../../index';
import { ActivityGroupStatus } from '@shared/interfaces/ActivityGroupStatus.enum';

interface ICalendarActivityLongChipProps {
	activity: IWorkPlanActivity;
	tableCellWidth: number;
	activityDayIndex: number;
	calendarCellHeight: number;
	workPlanToggle: CalendarToggleOptions;
	checkOpenEditActivityDialog: (ICalendarSection, Date) => void;
	isCalendarCellClickable: (date: Date) => boolean;
	dateRange: Date[];
}

const SIDE_MARGIN_WIDTH: number = 8;

export const CalendarActivityLongChip = (props: ICalendarActivityLongChipProps) => {
	const tz: string = useSelector(selectTimezone)!;
	const visibleDateRangeTimestamps: number[] = useSelector(
		(state: IRootState) => state.datePickerReducer.visibleDateRangeTimestamps
	);
	const firstDateTimestamp: number = visibleDateRangeTimestamps[0];
	const lastDateTimestamp: number = visibleDateRangeTimestamps[visibleDateRangeTimestamps.length - 1];
	const [rightPixel, setRightPixel] = useState<number>(0);
	const [chipWidth, setChipWidth] = useState<number>(0);

	const getChipCellEndDate = (activityEndDate: Moment, weekEndDate: Moment): Moment => {
		if (activityEndDate.isAfter(weekEndDate)) {
			return weekEndDate;
		}
		if (props.activity.status === ActivityGroupStatus.overdue) {
			return moment.tz(tz).isAfter(weekEndDate, 'd') ? weekEndDate : moment.tz(tz);
		}
		if (props.activity.status === ActivityGroupStatus.delayed) {
			if (moment.tz(tz).isAfter(weekEndDate, 'd')) {
				return weekEndDate;
			}
			if (moment.tz(tz).isBefore(activityEndDate)) {
				return activityEndDate;
			}
			return moment.tz(tz);
		}

		return activityEndDate;
	};

	useEffect(() => {
		const activityStartDate: Moment = moment.tz(props.activity.startDate, tz);
		const weekStartDate: Moment = moment.tz(firstDateTimestamp, tz);

		const finalDayIndex: number = activityStartDate.isBefore(weekStartDate) ? 0 : props.activityDayIndex;
		const rightPixels: number = props.tableCellWidth * (props.dateRange.length - finalDayIndex) - SIDE_MARGIN_WIDTH;

		setRightPixel(rightPixels);
	}, [props.activity.startDate, firstDateTimestamp, props.tableCellWidth, props.activityDayIndex, tz]);

	useEffect(() => {
		const activityStartDate: Moment = moment.tz(props.activity.startDate, tz);
		const activityEndDate: Moment = moment.tz(props.activity.endDate, tz);
		const weekStartDate: Moment = moment.tz(firstDateTimestamp, tz);
		const weekEndDate: Moment = moment.tz(lastDateTimestamp, tz);

		const cellStartDate: Moment = activityStartDate.isBefore(weekStartDate) ? weekStartDate : activityStartDate;
		const cellEndDate: Moment = getChipCellEndDate(activityEndDate, weekEndDate);
		const cellDaysLength: number = cellEndDate.diff(cellStartDate, 'days') + 1;
		const chipWidth: number = cellDaysLength * props.tableCellWidth - 2 * SIDE_MARGIN_WIDTH;

		setChipWidth(chipWidth);
	}, [
		props.activity.endDate,
		props.activity.startDate,
		props.tableCellWidth,
		tz,
		lastDateTimestamp,
		firstDateTimestamp,
	]);

	return (
		<div
			className={classes.activitiesContainer}
			style={translationService.getIsRtl() ? { left: `${rightPixel}px` } : { right: `${rightPixel}px` }}
			key={props.activity._id}
		>
			<CalendarCellChip
				workPlanToggle={props.workPlanToggle}
				activity={props.activity}
				openEditDialog={(activityId: string) =>
					props.checkOpenEditActivityDialog(props.activity.endDate, activityId)
				}
				chipWidth={chipWidth}
				maxContentWidth={rightPixel}
			/>
		</div>
	);
};
