import React, { useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { textStyle } from '@shared/utils/text.utils';
import { TrusstorTooltip } from '../../../shared/components/TrusstorTooltip/TrusstorTooltip';
import { COLORS } from '../constants';

const useStyles = makeStyles({
	detailTooltip: textStyle({
		color: COLORS.white,
		fontClass: 'body2',
	}),
	iconContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
});

const TooltipedHoverIcon = ({ baseIcon, hoverIcon, styleClass, tooltipText, onClick = () => {}, testId = '' }) => {
	const classes = useStyles();
	const [isHover, setIsHover] = useState(false);

	const handleMouseEnter = useCallback(() => {
		setIsHover(true);
	}, []);

	const handleMouseLeave = useCallback(() => {
		setIsHover(false);
	}, []);

	return (
		<div
			className={classes.iconContainer}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			onClick={onClick}
			data-testid={testId ?? ''}
		>
			<TrusstorTooltip
				placement="top"
				showAlways
				cleanStyling
				tooltipComponent={<p className={classes.detailTooltip}>{tooltipText}</p>}
			>
				<img className={styleClass} src={isHover ? hoverIcon : baseIcon} alt="link" />
			</TrusstorTooltip>
		</div>
	);
};

export { TooltipedHoverIcon };
