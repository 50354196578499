import classes from '../styles.module.scss';
import React from 'react';
import { TrusstorIconShared } from '../../../TrusstorIconShared/TrusstorIconShared';
import { IconNames } from '../../../TrusstorIconShared/IconNames.enum';
import { ChangeFunctionType, TrusstorTextInput, TrusstorTextInputProps } from '../TrusstorTextInput';

interface DropdownTextInputProps extends TrusstorTextInputProps {
	handleInputFocusChange: (bol: boolean) => void;
	isDropdownOpen: boolean;
	setDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
	changeFunc: ChangeFunctionType<any>;
	hideClearTextButton?: boolean;
	sendFullEvent?: boolean;
	handleClearClickCallback?: () => void;
	shouldNotSelectInputTextOnFocus?: boolean;
	inputContainerClassName?: string;
}

export const DropdownTextInput = React.forwardRef((props: DropdownTextInputProps, ref: React.Ref<HTMLInputElement>) => {
	return (
		<TrusstorTextInput
			ref={ref}
			isSmall
			shouldNotSelectInputTextOnFocus={props.shouldNotSelectInputTextOnFocus}
			sendFullEvent={props.sendFullEvent}
			handleClearClickCallback={props.handleClearClickCallback}
			focusOnClear
			handleInputFocusChange={props.handleInputFocusChange}
			changeFunc={props.changeFunc}
			value={props.value}
			disabled={props.disabled}
			autoFocus={props.autoFocus}
			required={props.required}
			label={props.label}
			placeholder={props.placeholder}
			innerComponent={props.innerComponent}
			startIcon={props.startIcon}
			showClearButton={!props.disabled && !props.hideClearTextButton}
			rootClassName={props.inputContainerClassName}
			endIcon={
				!props.disabled && (
					<div className={classes.icon}>
						<TrusstorIconShared
							iconName={props.isDropdownOpen ? IconNames.chevronUp : IconNames.chevronDown}
							onClick={() => props.setDropdownOpen((prev) => !prev)}
						/>
					</div>
				)
			}
		/>
	);
});
