// TODO: Move to services folder, export as class (singleton in index.tsx)
import { kebabCase, map, split } from 'lodash';
import { getSvgElement, setCleanIds } from '@shared/utils/svg.utils';
import {
	SAFETY_SUBTRACT_SIDE,
	FLOOR_CHIP_HEIGHT,
	ELEVATOR_ICON_HEIGHT,
	SCAFFOLD_SUBTRACT_SIDE,
	SCAFFOLD_ICON_HEIGHT,
	SAFETY_ICON_HEIGHT,
	SAFETY_ICON_WIDTH,
} from '../constants';
import { translationService } from '../index';
import { IFloorData } from '../interfaces/IFloorData';
import { ISvgElementPoint } from '../interfaces/ISvgElementPoint';
import { IBuildingSvgPoints } from '../interfaces/IBuildingSvgPoints';
import { DIRECTIONS } from '@shared/constants/directions';
import { BUILDING_WRAPPER_ID } from '@src/Components/MainPageSections/MainView';
import { ISvgElementContainer } from '@interfaces/ISvgElementContainer';

const getFloorFromElement = (element: Element, type: string): string => {
	const idCollection = split(element.id, type);
	return idCollection[idCollection.length - 1];
};

const offset = (element: Element, value: number) => element.getBoundingClientRect().height / 2 - value / 2;

export const getBuildingSvgPoints = (svgId: string): IBuildingSvgPoints => {
	// get all the "settings" positions of the svg in the different floors
	const svg: HTMLElement = getSvgElement(svgId);
	const alerts: Element[] = setCleanIds(document.querySelectorAll(`*[id^="alert"]`)); // starting with alert
	const scaffolds: Element[] = setCleanIds(document.querySelectorAll(`*[id^="scaffold"]`));
	const professions: Element[] = setCleanIds(document.querySelectorAll(`*[id^="trade"]`));
	const managers: Element[] = setCleanIds(document.querySelectorAll(`*[id^="manager"]`));
	const elevators: Element[] = setCleanIds(document.querySelectorAll(`*[id^="elevator"]`));
	const chips: Element[] = setCleanIds(document.querySelectorAll(`*[id^="chip"]`));
	const highlightedElements: Element[] = setCleanIds(document.querySelectorAll(`*[id^="highlighted"] *`));
	const multipleLinesRGBvalue: string = 'rgb(166, 221, 255)';

	const buildingWrapperElement = document.getElementById(BUILDING_WRAPPER_ID);

	const chipsElementArray: ISvgElementContainer[] = map(chips, (element) => {
		const sideObj =
			translationService.getDirection() === DIRECTIONS.LTR
				? {
						left: element.getBoundingClientRect().left - svg.getBoundingClientRect().left,
				  }
				: {
						right: svg.getBoundingClientRect().right - element.getBoundingClientRect().right,
				  };

		const isMultipleLinesChip = (): boolean => {
			const elementClass: string | null = element.getAttribute('class');
			if (!elementClass) {
				return false;
			}
			const qsItem: Element | null = document.querySelector(`.${elementClass}`);
			if (!qsItem) {
				return false;
			}
			return getComputedStyle(qsItem).fill === multipleLinesRGBvalue;
		};

		return {
			floorId: getFloorFromElement(element, 'chip'),
			type: 'chip',
			top: element.getBoundingClientRect().top - svg.getBoundingClientRect().top,
			...sideObj,
			width: element.getBoundingClientRect().width,
			height: element.getBoundingClientRect().height,
			isMultipleLines: isMultipleLinesChip(),
		};
	});

	const highlightedElementsArray: ISvgElementContainer[] = map(highlightedElements, (element) => {
		const sideObj =
			translationService.getDirection() === DIRECTIONS.LTR
				? {
						left: element.getBoundingClientRect().left - svg.getBoundingClientRect().left,
				  }
				: {
						right: svg.getBoundingClientRect().right - element.getBoundingClientRect().right,
				  };
		return {
			floorId: getFloorFromElement(element, 'floor'),
			type: 'highlight',
			top: element.getBoundingClientRect().top - svg.getBoundingClientRect().top,
			...sideObj,
			width: element.getBoundingClientRect().width,
			height: element.getBoundingClientRect().height,
		};
	});

	const alertPointsArray: ISvgElementPoint[] = map(alerts, (element: Element) => {
		const sideObj =
			translationService.getDirection() === DIRECTIONS.LTR
				? {
						left:
							element.getBoundingClientRect().left -
							svg.getBoundingClientRect().left -
							SAFETY_ICON_WIDTH / 2,
				  }
				: {
						right:
							svg.getBoundingClientRect().right -
							element.getBoundingClientRect().right -
							SAFETY_ICON_WIDTH / 2,
				  };

		const top =
			element.getBoundingClientRect().top - svg.getBoundingClientRect().top + offset(element, SAFETY_ICON_HEIGHT);

		return {
			floorId: getFloorFromElement(element, 'alert'),
			type: 'alert',
			top,
			...sideObj,
		};
	});

	const professionPointsArray: ISvgElementPoint[] = map(professions, (element) => {
		const sideObj =
			translationService.getDirection() === DIRECTIONS.LTR
				? {
						left: element.getBoundingClientRect().left - svg.getBoundingClientRect().left,
				  }
				: {
						right: svg.getBoundingClientRect().right - element.getBoundingClientRect().right,
				  };

		return {
			floorId: getFloorFromElement(element, 'trade'),
			type: 'trade',
			top:
				element.getBoundingClientRect().top -
				svg.getBoundingClientRect().top +
				offset(element, FLOOR_CHIP_HEIGHT) -
				2,
			width: element.getBoundingClientRect().x - svg.getBoundingClientRect().x,
			height: element.getBoundingClientRect().height,
			...sideObj,
		};
	});

	const managerPointsArray: ISvgElementPoint[] = map(managers, (element) => {
		const floorId = getFloorFromElement(element, 'manager');

		const managerWidth: number =
			translationService.getDirection() === DIRECTIONS.RTL
				? svg.getBoundingClientRect().right - element.getBoundingClientRect().right
				: element.getBoundingClientRect().x - svg.getBoundingClientRect().x;

		const sideObj =
			translationService.getDirection() === DIRECTIONS.LTR
				? {
						left: element.getBoundingClientRect().left - svg.getBoundingClientRect().left - managerWidth,
				  }
				: {
						left:
							element.getBoundingClientRect().left - buildingWrapperElement!.getBoundingClientRect().left,
				  };

		return {
			floorId,
			type: 'manager',
			top:
				element.getBoundingClientRect().top -
				svg.getBoundingClientRect().top +
				offset(element, FLOOR_CHIP_HEIGHT) -
				2,
			width: managerWidth,
			...sideObj,
		};
	});

	const elevatorPointsArray: ISvgElementPoint[] = map(elevators, (element) => {
		const sideObj =
			translationService.getDirection() === DIRECTIONS.LTR
				? {
						left: element.getBoundingClientRect().left - svg.getBoundingClientRect().left,
				  }
				: {
						right: svg.getBoundingClientRect().right - element.getBoundingClientRect().right,
				  };

		return {
			floorId: getFloorFromElement(element, 'elevator'),
			type: 'elevator',
			top:
				element.getBoundingClientRect().top -
				svg.getBoundingClientRect().top +
				offset(element, ELEVATOR_ICON_HEIGHT),
			...sideObj,
		};
	});

	const scaffoldPointsArray: ISvgElementPoint[] = map(scaffolds, (element) => {
		const sideObj =
			translationService.getDirection() === DIRECTIONS.LTR
				? {
						left:
							element.getBoundingClientRect().left -
							svg.getBoundingClientRect().left -
							SCAFFOLD_SUBTRACT_SIDE,
				  }
				: {
						left:
							svg.getBoundingClientRect().right -
							element.getBoundingClientRect().right -
							SCAFFOLD_SUBTRACT_SIDE,
				  };

		return {
			floorId: getFloorFromElement(element, 'scaffold'),
			type: 'scaffold',
			top:
				element.getBoundingClientRect().top -
				svg.getBoundingClientRect().top +
				offset(element, SCAFFOLD_ICON_HEIGHT),
			...sideObj,
		};
	});

	return {
		alertPointsArray,
		professionPointsArray: professionPointsArray,
		managerPointsArray,
		elevatorPointsArray,
		scaffoldPointsArray,
		chipElementsArray: chipsElementArray,
		highlightedElementsArray,
	};
};

export const setSvgClicks = (action: (id: string) => void, existingFloors: IFloorData[]) => {
	const floorElements: HTMLElement[] = setCleanIds(document.querySelectorAll(`*[id^="floor"]`)) as HTMLElement[];
	for (const floorElement of floorElements) {
		const idCollection: string[] = split(floorElement.id, 'floor');
		const elementFloorId: string = idCollection[1];
		if (existingFloors.find((existingFloor) => existingFloor.floorId === elementFloorId)) {
			floorElement.onclick = () => {
				action(elementFloorId);
			};
		}
	}
};

export const getBuildingSvgWidth = (): number => {
	const svg: HTMLElement = getSvgElement('building');
	return svg.getBoundingClientRect().width;
};

export const getBuildingSvgHeight = (): number => {
	const svg: HTMLElement = getSvgElement('building');
	return svg.getBoundingClientRect().height;
};
