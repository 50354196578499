import React from 'react';
import { find } from 'lodash';
import { ScaffoldIcon } from '../Scaffolds/ScaffoldIcon';
import { makeStyles } from '@material-ui/core';
import { compareProps } from '../../utils/generalUtils';
import { IFloorData } from '../../interfaces/IFloorData';
import { IBuildingSvgPoints } from '../../interfaces/IBuildingSvgPoints';
import { ISvgElementPoint } from '../../interfaces/ISvgElementPoint';

const useStyles = makeStyles({
	scaffoldWrapper: {
		position: 'absolute',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
});

interface IFloorScaffoldsProps {
	scaffolds: IFloorData[];
	svgPoints: IBuildingSvgPoints;
}

const FloorScaffolds = React.memo((props: IFloorScaffoldsProps) => {
	const classes = useStyles();

	if (!props.scaffolds) {
		return null;
	}

	const scaffoldPointForFloor: ISvgElementPoint | undefined = find(props.svgPoints.scaffoldPointsArray, {
		floorId: props.scaffolds?.[0]?.matchingFixedFloorId,
	});

	if (!scaffoldPointForFloor) {
		return null;
	}

	return (
		<div
			key={`floor${props.scaffolds[0].floorId}_scaffoldsContainer`}
			className={classes.scaffoldWrapper}
			style={{
				top: scaffoldPointForFloor.top,
				left: scaffoldPointForFloor.left,
				right: scaffoldPointForFloor.right,
			}}
		>
			<ScaffoldIcon scaffolds={props.scaffolds} floorId={props.scaffolds[0].floorId} />
		</div>
	);
}, compareProps);

export { FloorScaffolds };
