import React, { ReactElement, useEffect, useState } from 'react';
import { find } from 'lodash';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import classes from './styles.module.scss';
import { isElementOverflowed } from './tooltip.utils';

interface ITrusstorTooltipProps {
	showAlways?: boolean;
	tooltipComponent?: React.ReactElement | null;
	children: ReactElement<any, any>;
	chipsClasses?: string;
	cleanStyling?: boolean;
	disableTooltip?: boolean;
	idToOverflow?: string;
	maxWidth?: number;
	placement?: TooltipProps['placement'];
	refChildren?: React.RefObject<HTMLParagraphElement>;
	text?: string;
	tooltipClasses?: string;
	onOpen?: () => void;
	dependencies?: any[];
	interactive?: boolean;
	setOverflow?: (isOverflowed: boolean) => void;
}

const TrusstorTooltip = (props: ITrusstorTooltipProps) => {
	const { placement = 'top', cleanStyling = false, maxWidth } = props;
	const [isOverflowed, setIsOverflowed] = useState(false);

	useEffect(() => {
		if (props.setOverflow) {
			props.setOverflow(isOverflowed);
		}
	}, [isOverflowed]);

	// chip has span > div > p
	const checkChipsOverflow = (childChipsElements: HTMLCollectionOf<Element>) => {
		if (!childChipsElements) {
			return;
		}
		setIsOverflowed(
			!!find(childChipsElements, (childElement) => {
				const pElement = childElement?.children[0].children[0].getElementsByClassName('chipLabel')[0];
				if (!pElement) {
					return false;
				}
				return isElementOverflowed(pElement);
			})
		);
	};

	const changeOverflowForElement = (overflowElement: HTMLElement | null) => {
		if (!overflowElement) {
			return;
		}
		const isOverflowed: boolean = isElementOverflowed(overflowElement);
		setIsOverflowed(isOverflowed);
	};

	useEffect(() => {
		if (props.refChildren?.current) {
			return changeOverflowForElement(props.refChildren.current);
		}
		// check for children chips overflow
		if (!props.chipsClasses && props.idToOverflow) {
			const elementToOverflow = document.getElementById(props.idToOverflow);
			if (elementToOverflow) {
				return changeOverflowForElement(elementToOverflow);
			}
		}
		if (props.chipsClasses) {
			const childChipsElements = document.getElementsByClassName(props.chipsClasses);
			return checkChipsOverflow(childChipsElements);
		}
	}, [props.refChildren?.current, props.chipsClasses, props.idToOverflow, props.dependencies]);

	if ((!props.showAlways && !isOverflowed) || props.disableTooltip) {
		return props.children;
	}

	return (
		<Tooltip
			onOpen={props.onOpen}
			placement={placement}
			classes={{
				tooltip: `${classes.tooltipRoot} ${cleanStyling ? classes.cleanStyle : ''} ${props.tooltipClasses}`,
			}}
			style={{ maxWidth: maxWidth ? maxWidth : '' }}
			key="trusstortooltip"
			title={props.tooltipComponent || <div className={classes.textTooltip}>{props.text}</div>}
			interactive={props.interactive}
		>
			{props.children}
		</Tooltip>
	);
};

export { TrusstorTooltip };
