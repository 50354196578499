import React from 'react';
import classes from './styles.module.scss';
import { IObserveLocationReport } from '@shared/interfaces/IObserveLocationReport';
import greyFloorsIcon from '../../../../../assets/workplanIcons/greyFloorsIcon.svg';
import { translationService } from '@src/index';
import { HeaderStats, IHeaderStat } from '../../HeaderStats/HeaderStats';
import { sortBy } from 'lodash';

export interface IObserveLocationFloorDataStats {
	totalPersonnel: number;
	activities: number;
	safetyEvents: number;
	equipment: number;
}

interface IObserveLocationFloorSectionProps {
	currentReport: IObserveLocationReport;
	floorDataStats: IObserveLocationFloorDataStats;
}

const ObserveLocationFloorSection = (props: IObserveLocationFloorSectionProps) => {
	const statTitlesSortIndexed = {
		[translationService.get('totalPersonnel')]: 1,
		[translationService.get('activities')]: 2,
		[translationService.get('safetyEvents')]: 3,
		[translationService.get('equipment')]: 4,
	};

	const getStats = (): IHeaderStat[] => {
		return sortBy(
			Object.entries(props.floorDataStats).map(([stat, value]) => ({
				title: translationService.get(stat),
				stat: String(value),
			})),
			(stat) => statTitlesSortIndexed[stat.title]
		);
	};

	return (
		<section className={classes.floorContainer}>
			<div>
				<div className={classes.floorInfoContainer}>
					<div className={classes.iconContainer}>
						<img className={classes.icon} src={greyFloorsIcon} alt="floors" />
					</div>
					<div className={classes.floorInfo}>
						<div className={classes.floorInfoTitle}>{props.currentReport.location[0].floorNick}</div>
						<div className={classes.floorInfoSubtitle}>
							{props.currentReport.location.map((location) => location.areaNick).join(' | ')}
						</div>
					</div>
				</div>
			</div>
			<HeaderStats stats={getStats()} />
		</section>
	);
};

export { ObserveLocationFloorSection };
