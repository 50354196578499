import React from 'react';
import classes from './styles.module.scss';
import { ITag } from '@src/interfaces';
import { Loader } from '@shared/components/Loader/Loader';
import { getProfessionDisplayText } from '@utils/professions.utils';
import { adminPanelIconWhite } from '../../../../assets/sideBar';
import { InvestigationReportSafetyEventCard } from './InvestigationReportSafetyEventCard/InvestigationReportSafetyEventCard';
import { getTagName } from '@utils/tags.utils';
import { TagEntityCard } from '../../../Boxes/Cards/TagEntityCard/TagEntityCard';
import { useSafetyEventsByTagIdQuery } from '@src/hooks/queries/safetyEvent.queries.hooks';
import warning from '@shared/assets/icons/warning.svg';
import { translationService } from '@src/servicesInitializers';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { selectTimezone } from '@store/slices/project.slice';

interface IInvestigationReportTagInfoCardProps {
	tag: ITag;
	investigatedTimestamp: number;
	isTagLoading: boolean;
	isDailyReport?: boolean;
	displayDate: Date;
	isTagWasAssignedToDifferentPerson: boolean;
}

export const InvestigationReportTagInfoCard = (props: IInvestigationReportTagInfoCardProps) => {
	const { safetyEvents, isInitialLoading: isSafetyLoading } = useSafetyEventsByTagIdQuery(
		props.tag?.tagId,
		props.investigatedTimestamp,
		!!props.isDailyReport,
		!props.isTagLoading
	);
	const tz: string = useSelector(selectTimezone)!;
	const DMYformat: string = translationService.getChosenLanguage() === 'jpn' ? 'YYYY年MM月DD日' : 'MMMM Do, YYYY';
	const dateString: string = moment.tz(new Date(props.displayDate), tz).format(DMYformat);
	const tagName: string = getTagName(props.tag);

	return (
		<div
			className={classes.InvestigationReportTagInfoCard_container}
			data-testid={'InvestigationReportTagInfoCard'}
		>
			{props.isTagLoading || isSafetyLoading ? (
				<Loader />
			) : (
				<div className={classes.content}>
					<div className={classes.topPart}>
						<div className={classes.tagName}>{tagName}</div>
						<TagEntityCard tag={props.tag} />
					</div>
					<div className={classes.bottomPart}>
						<div className={classes.tagIdSection}>
							<span className={classes.tagIcon}>
								<img src={adminPanelIconWhite} alt={''}></img>
							</span>
							<div className={classes.tagId}>{props.tag.tagId}</div>
						</div>
						{props.tag.phoneNumber && (
							<>
								| <div className={classes.phoneNumber}>{props.tag.phoneNumber}</div>
							</>
						)}
						|
						{props.tag.profession && (
							<div className={classes.profession}>{getProfessionDisplayText(props.tag.profession)}</div>
						)}
					</div>

					<div className={classes.safetySection}>
						{props.isTagWasAssignedToDifferentPerson && (
							<div className={classes.differentTagNameWarningContainer}>
								<img src={warning} alt={''} />
								<div className={classes.warningText}>
									<div>
										{translationService.get('investigationReport_differentTagNameWarning', {
											date: dateString,
											name: tagName,
										})}
									</div>
								</div>
							</div>
						)}
						{!props.isDailyReport &&
							safetyEvents &&
							safetyEvents.map((safetyEvent) => (
								<InvestigationReportSafetyEventCard key={safetyEvent._id} safetyEvent={safetyEvent} />
							))}
					</div>
				</div>
			)}
		</div>
	);
};
