import { ISequenceItem } from '@interfaces/ISequenceItem';
import { AreaSequenceItemStatus } from '@shared/interfaces/AreaSequenceItemStatus.enum';
import { IConfigArea } from '@shared/interfaces/IConfigArea';
import { IActivitySequenceItemCellData } from '@src/Components/WorkPlan/ActivityProgressTracker/components/ActivitySequenceMatrix/cellComponents/ActivitySequenceItemCell/ActivitySequenceItemCell';

export const getFakeDisabledAsi = (sequenceItem: ISequenceItem, area: IConfigArea): IActivitySequenceItemCellData => ({
	...sequenceItem,
	area: area,
	sequenceItemId: sequenceItem._id,
	status: AreaSequenceItemStatus.planned,
	statusChannel: 'manual',
	isDisabled: true,
});
