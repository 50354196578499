import React from 'react';
import IconButton from '@material-ui/core/IconButton';

const TrusstorIconButton = ({ click = (e) => {}, imageSource, styleClass, id = '', labelClass = '' }) => {
	const isImageString = typeof imageSource === 'string';
	return (
		<IconButton id={id} onClick={click} classes={{ label: labelClass }}>
			{isImageString ? <img alt="icon" src={imageSource} className={styleClass} /> : imageSource}
		</IconButton>
	);
};

export default TrusstorIconButton;
