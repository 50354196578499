import { debounce } from 'lodash';
import { RefObject, useEffect, useLayoutEffect, useState } from 'react';

export const useAreChipsOverlapping = <T>(chipsContainerRef: RefObject<HTMLDivElement>, chipsData: T[]) => {
	const [areChipsOverlapping, setAreChipsOverlapping] = useState<boolean>(false);

	const updateChipsOverlapping = () => {
		setAreChipsOverlapping(false);
		const chipsContainer: HTMLElement | null = chipsContainerRef.current;
		if (!chipsContainer || !chipsContainer.parentElement) {
			return;
		}
		const chipsContainerWidthIsGreaterThanContainerWidth: boolean =
			chipsContainer.parentElement.offsetWidth < chipsContainer.offsetWidth;
		if (chipsContainerWidthIsGreaterThanContainerWidth) {
			setAreChipsOverlapping(chipsContainerWidthIsGreaterThanContainerWidth);
		}
	};

	const debounceResizeEffect = debounce(() => {
		updateChipsOverlapping();
	}, 400);

	useEffect(() => {
		updateChipsOverlapping();
	}, [chipsData.length]);

	useLayoutEffect(() => {
		window.addEventListener('resize', () => {
			debounceResizeEffect();
		});

		return () => {
			window.removeEventListener('resize', () => {
				updateChipsOverlapping();
			});
		};
	}, []);

	return { areChipsOverlapping, setAreChipsOverlapping, updateChipsOverlapping };
};
