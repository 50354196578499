import React from 'react';
import classnames from 'classnames';
import { ArrowUpDown } from '../ArrowUpDown/ArrowUpDown';
import { TrusstorTextInput } from '../Inputs/TrusstorTextInput/TrusstorTextInput';
import classes from './styles.module.scss';
import { TrusstorIconShared } from '../TrusstorIconShared/TrusstorIconShared';
import { IconNames } from '../TrusstorIconShared/IconNames.enum';

interface TrusstorDatePickerInput {
	width?: number;
	isOpen: boolean;
	rootClassName?: string;
	text?: string;
	label?: string;
	placeholder?: string;
	onClick?: (e?: any) => void;
	ref?: any;
	disabled?: boolean;
	isBigInputBox?: boolean;
	required?: boolean;
	testId?: string;
	hideIcon?: boolean;
	isError?: boolean;
	errorText?: string;
	iconElement?: React.ReactNode;
	isSmall?: boolean;
}

export const TrusstorDatePickerInput = (props: TrusstorDatePickerInput) => {
	const getStartIcon = () => {
		if (props.hideIcon) {
			return undefined;
		}
		if (props.iconElement) {
			return props.iconElement;
		}
		return <TrusstorIconShared iconName={IconNames.calendar} />;
	};

	return (
		<div
			className={classnames({
				[classes.isDisabled]: props.disabled,
				[props.rootClassName || '']: true,
			})}
			onClick={props.onClick}
			ref={props.ref}
		>
			{props.isSmall ? (
				<div className={classes.smallPicker}>
					<TrusstorIconShared iconName={IconNames.calendar} />
					<div>{props.text}</div>
					<ArrowUpDown isOpen={props.isOpen} size={'22px'} />
				</div>
			) : (
				<TrusstorTextInput
					testId={props.testId}
					required={props.required}
					isSmall={!props.isBigInputBox}
					label={props.label}
					placeholder={props.placeholder}
					value={props.text}
					disabled={props.disabled}
					width={props.width}
					startIcon={getStartIcon()}
					endIcon={<ArrowUpDown disabled={props.disabled} isOpen={props.isOpen} size={'22px'} />}
					errorText={props.errorText}
					isError={props.isError}
				/>
			)}
		</div>
	);
};
