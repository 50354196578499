import React, { useState } from 'react';
import { TooltipedHoverIcon } from '../../TooltipedHoverIcon';
import { translationService } from '../../../index';
import { trashIconBlack, trashIconGrey } from '../../../assets/trashIcons';
import { ManagerDeleteDialog } from '../ManagerDeleteDialog';
import { IManager } from '../../../interfaces';
import { CreateManagerDialog } from '../CreateManagerDialog';
import { pencilGreyIcon, pencilIcon } from '../../../assets/adminPanel';
import classes from './styles.module.scss';

interface IManagerTableRowButtonsProps {
	manager: IManager;
	onExitDialog: () => void;
}

const ManagerTableRowButtons = (props: IManagerTableRowButtonsProps) => {
	const [showDeleteRoleDialog, setShowDeleteRoleDialog] = useState<boolean>(false);
	const [showManagerEditDialog, setShowManagerEditDialog] = useState<boolean>(false);

	const handleDeleteRoleClick = () => setShowDeleteRoleDialog(true);
	const handleEditManagerButtonClick = () => setShowManagerEditDialog(true);
	const handleCloseDeleteRoleDialog = () => {
		setShowDeleteRoleDialog(false);
		props.onExitDialog();
	};
	const handleCloseLinkManagerDialog = () => {
		setShowManagerEditDialog(false);
		props.onExitDialog();
	};

	const isRtl: boolean = translationService.getIsRtl();

	return (
		<React.Fragment>
			{showDeleteRoleDialog && (
				<ManagerDeleteDialog
					handleCloseDeleteRoleDialog={handleCloseDeleteRoleDialog}
					manager={props.manager}
				/>
			)}
			<div className={`${classes.actionButtons} ${isRtl ? classes.right : ''}`}>
				<TooltipedHoverIcon
					onClick={handleEditManagerButtonClick}
					baseIcon={pencilGreyIcon}
					hoverIcon={pencilIcon}
					tooltipText={translationService.get('editManagerProfile')}
					styleClass={classes.avatar}
				/>
				<TooltipedHoverIcon
					onClick={handleDeleteRoleClick}
					baseIcon={trashIconGrey}
					hoverIcon={trashIconBlack}
					tooltipText={translationService.get('deleteRole')}
					styleClass={classes.avatar}
				/>
			</div>
			<CreateManagerDialog
				show={showManagerEditDialog}
				close={handleCloseLinkManagerDialog}
				manager={props.manager}
			/>
		</React.Fragment>
	);
};

export { ManagerTableRowButtons };
