import React, { useContext } from 'react';
import classes from './styles.module.scss';
import { translationService } from '../../../../servicesInitializers';
import { compareProps } from '../../../../utils/generalUtils';
import { QuickFilterButton } from '@shared/components/buttons/QuickFilterButton/QuickFilterButton';
import { MainPageContext } from '@src/Components/Pages/MainPage';
import { WidgetActivityFilters } from '@shared/interfaces/WidgetActivityFilters';
import classnames from 'classnames';

interface IWeeklyWidgetHeaderProps {
	toDoActivities: number | undefined;
	noProgressActivitiesCount: number | undefined;
	inProgressActivitiesCount: number | undefined;
	forReviewActivitiesCount: number | undefined;
	completedActivitiesCount: number | undefined;
	setWidgetActivityFilters: (tab: WidgetActivityFilters) => void;
	widgetHeaderClass?: string;
}

export interface IFilterItem {
	id: string;
	text: string;
	number?: number;
	onClick: () => void;
}

export const WeeklyWidgetHeader = React.memo((props: IWeeklyWidgetHeaderProps) => {
	const { widgetActivityFilters } = useContext(MainPageContext)!;

	const quickFilters: IFilterItem[] = [
		{
			id: WidgetActivityFilters.toDo,
			text: translationService.get('activities_widget_quick_filter_toDo'),
			number: props.toDoActivities,
			onClick: () => {
				props.setWidgetActivityFilters(WidgetActivityFilters.toDo);
			},
		},
		{
			id: WidgetActivityFilters.inProgress,
			text: translationService.get('activities_widget_quick_filter_inProgress'),
			number: props.inProgressActivitiesCount,
			onClick: () => {
				props.setWidgetActivityFilters(WidgetActivityFilters.inProgress);
			},
		},
		{
			id: WidgetActivityFilters.noProgress,
			text: translationService.get('activities_widget_quick_filter_noProgress'),
			number: props.noProgressActivitiesCount,
			onClick: () => {
				props.setWidgetActivityFilters(WidgetActivityFilters.noProgress);
			},
		},
		{
			id: WidgetActivityFilters.forReview,
			text: translationService.get('activities_widget_quick_filter_for_review'),
			number: props.forReviewActivitiesCount,
			onClick: () => {
				props.setWidgetActivityFilters(WidgetActivityFilters.forReview);
			},
		},
		{
			id: WidgetActivityFilters.completed,
			text: translationService.get('completed'),
			number: props.completedActivitiesCount,
			onClick: () => {
				props.setWidgetActivityFilters(WidgetActivityFilters.completed);
			},
		},
	];

	const handleFilterClick = (filter: IFilterItem): void => {
		filter.onClick();
	};

	return (
		<div className={classnames(classes.widgetHeader, props.widgetHeaderClass)}>
			<div className={classes.tabsSection}>
				<div className={classes.secondaryTabsContainer} data-testId={'widgetTabsSection'}>
					{quickFilters.map((filter) => {
						const text: string =
							filter.number !== undefined ? `${filter.text} (${filter.number})` : filter.text;
						const isSelected: boolean = widgetActivityFilters === filter.id;
						return (
							<QuickFilterButton
								testId={'quickFilterButton&type=' + filter.id}
								disabled={filter.number === 0}
								key={filter.id}
								text={text}
								handleClick={() => handleFilterClick(filter)}
								selected={isSelected}
							/>
						);
					})}
				</div>
			</div>
		</div>
	);
}, compareProps);
