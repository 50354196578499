import React, { useLayoutEffect, useState } from 'react';
import classes from './styles.module.scss';
import { IconSize, TrusstorIconShared } from '../../TrusstorIconShared/TrusstorIconShared';
import { IconNames } from '../../TrusstorIconShared/IconNames.enum';

interface IStarButtonProps {
	isStarred: boolean;
	handleStarClick?: () => Promise<void>;
	onClickError?: () => void;
}

export const StarButton = (props: IStarButtonProps) => {
	const [staredIconName, setStaredIconName] = useState<IconNames>(IconNames.emptyStar);

	useLayoutEffect(() => {
		setStaredIconName(props.isStarred ? IconNames.fullStar : IconNames.emptyStar);
	}, [props.isStarred]);

	const handleStarClick = async () => {
		const currentIcon: IconNames = staredIconName === IconNames.fullStar ? IconNames.emptyStar : IconNames.fullStar;
		setStaredIconName(currentIcon);
		try {
			props.handleStarClick && (await props.handleStarClick());
		} catch (e) {
			setStaredIconName(currentIcon === IconNames.fullStar ? IconNames.emptyStar : IconNames.fullStar);
			props.onClickError?.();
		}
	};

	const handleHoverStar = () => {
		if (staredIconName === IconNames.fullStar) return;
		setStaredIconName(IconNames.hoverStar);
	};

	const handleLeaveStar = () => {
		if (staredIconName === IconNames.fullStar) return;
		setStaredIconName(IconNames.emptyStar);
	};

	return (
		<div
			className={classes.starredSection}
			onMouseEnter={handleHoverStar}
			onMouseLeave={handleLeaveStar}
			onClick={handleStarClick}
		>
			<TrusstorIconShared iconName={staredIconName} size={IconSize.MEDIUM} defaultColor />
		</div>
	);
};
