import { IRootState } from '@store/slices';
import { selectProjectId } from '@store/slices/project.slice';
import { getStaticManagers } from '@store/thunks';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IManager } from '@shared/interfaces/IManager';
import { SingleDropdown } from '@src/Components/Dropdowns/DesignSystem/SingleDropdown/SingleDropdown';
import { translationService } from '@src/index';
import { getManagerDisplayText } from '@utils/managers.utils';

interface IManagersAutocompleteDropdownProps<T> {
	onChange: (value?: T) => void;
	inputContainerClassName?: string;
	defaultManager?: T;
	disabled?: boolean;
	required?: boolean;
}

export const ManagersAutocompleteDropdown = <T extends IManager>(props: IManagersAutocompleteDropdownProps<T>) => {
	const [currentValue, setCurrentValue] = React.useState<T | undefined>(props.defaultManager);
	useEffect(() => {
		setCurrentValue(props.defaultManager);
	}, [currentValue]);

	const dispatch = useDispatch();
	const projectId: string = useSelector(selectProjectId)!;

	const staticManagers: IManager[] = useSelector((state: IRootState) => state.managersReducer.staticManagers);

	useEffect(() => {
		dispatch(getStaticManagers({ projectId }));
	}, []);

	const onChange = (value: IManager | null) => {
		props.onChange(value as T);
	};

	return staticManagers.length > 0 ? (
		<SingleDropdown
			required={props.required}
			placeholder={translationService.get('selectManager')}
			options={staticManagers}
			value={currentValue}
			onChange={onChange}
			getDisplayOption={(manager: IManager) => getManagerDisplayText(manager)}
			testId={'managerDropdown'}
		/>
	) : null;
};
