import React, { useEffect, useState } from 'react';
import { translationService } from '../../../../../../index';
import { makeStyles } from '@material-ui/core/styles';
import { textStyle } from '@shared/utils/text.utils';
import {
	ACTIVITIES_GANTT_COL_HEADERS_WIDTH,
	ACTIVITIES_GANTT_COL_WIDTH,
	ACTIVITIES_GANTT_HEADERS_ROW_HEIGHT,
} from '../../../../../../constants';
import moment from 'moment';
import { Languages } from '@shared/constants/languages';
import { dateFormatMappings } from '@shared/services/translation.service';
import { SERVER_DATE_FORMAT } from '@shared/constants/formats.constants';

const GANTT_DATE_FORMAT_HEB: string = 'DD.MM';
const GANTT_DATE_FORMAT_US: string = 'MM.DD';

const useStyles = makeStyles((theme) => ({
	rowContainer: {
		display: 'flex',
		flexDirection: 'row',
		width: 'fit-content',
		minHeight: ACTIVITIES_GANTT_HEADERS_ROW_HEIGHT,
		position: 'sticky',
		top: 0,
	},
	title: textStyle({
		fontClass: 'h2',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	}),
	dateHeader: {
		width: ACTIVITIES_GANTT_COL_WIDTH,
		borderRight: '1px dashed #f2f2f2',
	},
	locationHeader: {
		width: ACTIVITIES_GANTT_COL_HEADERS_WIDTH,
		borderRight: '1px dashed #f2f2f2',
	},
}));

interface IActivitiesGantRowHeadersProps {
	dateRanges: string[];
}

const ActivitiesGanttRowHeaders = (props: IActivitiesGantRowHeadersProps) => {
	const classes = useStyles();
	const [ganttHeaderProjectDateFormat, setGanttHeaderProjectDateFormat] = useState<string>(GANTT_DATE_FORMAT_HEB);

	useEffect(() => {
		setGanttHeaderProjectDateFormat(
			translationService.getDateFormat() === dateFormatMappings[Languages.ENGLISH_US]
				? GANTT_DATE_FORMAT_US
				: GANTT_DATE_FORMAT_HEB
		);
	}, [translationService.getDateFormat()]);

	return (
		<div className={classes.rowContainer}>
			<p className={`${classes.title} ${classes.locationHeader}`}>{translationService.get('location')}</p>
			{props.dateRanges.map((formattedDate: string) => (
				<p key={`gantHeaders_${formattedDate}`} className={`${classes.title} ${classes.dateHeader}`}>
					{moment(formattedDate, SERVER_DATE_FORMAT).format(ganttHeaderProjectDateFormat)}
				</p>
			))}
		</div>
	);
};

export { ActivitiesGanttRowHeaders };
