import { ISequenceItemProgressBySequenceItemReport } from '@interfaces/ISequenceItemProgressBySequenceItemReport';
import React from 'react';
import classes from '@src/Components/SequenceProgressAnalysis/ActivitiesProgress/ActivitiesProgressTable/styles.module.scss';
import classnames from 'classnames';
import {
	isProgressReportCompleted,
	shouldShowProgressTrend,
} from '@src/Components/SequenceProgressAnalysis/ActivitiesProgress/ActivitiesProgressTable/utils';
import { translationService } from '@src/servicesInitializers';
import { IProject } from '@interfaces/index';
import { useSelector } from 'react-redux';
import { selectWorkingProject } from '@store/slices/project.slice';
import { stringToNumberBetweenRange } from '@utils/generalUtils';

interface ITrendCellProps {
	report: ISequenceItemProgressBySequenceItemReport;
}
export const TrendCell = (props: ITrendCellProps) => {
	const isCompleted: boolean = isProgressReportCompleted(props.report);
	const project: IProject = useSelector(selectWorkingProject)!;
	const isDemoProject: boolean = !!project.isDemo;

	if (isCompleted) {
		return <div />;
	}

	if (!shouldShowProgressTrend(props.report) && !isDemoProject) {
		return <div className={classes.tbd}>{translationService.get('TBD')}</div>;
	}

	const roundedTrend: number = isDemoProject
		? Number(
				stringToNumberBetweenRange(
					`${props.report.description}${props.report.sequenceItemId}${props.report.profession._id}`,
					-35,
					50
				).toFixed(1)
		  )
		: Number(props.report.trendPercentage!.toFixed(1));

	const trendColorClassName: string = (() => {
		if (roundedTrend > 1) {
			return classes.positiveTrend;
		}
		if (roundedTrend < -1) {
			return classes.negativeTrend;
		}

		return classes.neutralTrend;
	})();

	const abovePositiveThresholdElement = <div dir="ltr">{'> 300%'}</div>;

	const belowNegativeThresholdElement = <div dir="ltr">{'-100%'}</div>;

	const trendSign: string | null = (() => {
		if (roundedTrend > 1 || roundedTrend < -1) {
			return '';
		}

		return null;
	})();

	const getElementToShow = () => {
		if (roundedTrend > 300) {
			return abovePositiveThresholdElement;
		}
		if (roundedTrend < -100) {
			return belowNegativeThresholdElement;
		}

		return (
			<div dir="ltr">
				{trendSign}
				{roundedTrend}%
			</div>
		);
	};

	return (
		<div className={classnames(classes.trendCell, trendColorClassName)}>
			{trendSign !== null ? getElementToShow() : <div>~</div>}
		</div>
	);
};
