import React, { forwardRef, useRef, useState } from 'react';
import { TrusstorDatePickerInput } from '@shared/components/TrusstorSelectOption/TrusstorDatePickerInput';
import moment from 'moment-timezone';
import { translationService } from '@src/servicesInitializers';
import {
	DateAndTimeSelector,
	PopperPlacement,
} from '@src/Components/DateSelector/DateAndTimeSelector/DateAndTimeSelector';
import classes from './styles.module.scss';
import { useOutsideClick } from '@src/hooks/custom.hooks';
import { IDatePickerInputProps } from '@interfaces/IDatePickerInput';
import { useSelector } from 'react-redux';
import { selectTimezone } from '@store/slices/project.slice';

interface IDateSelectorTrusstorInputProps {
	handleTimeChange: (date: Date) => void;
	selectedDate?: Date | null;
	selectDefaultDate?: boolean;
	displayTime?: boolean;
	setThirtyMinutesGap?: boolean;
	minDate?: Date;
	maxDate?: Date;
	disableDatePicker?: boolean;
	disabledDates?: Date[];
	inputPlaceHolder?: string;
	label?: string;
	children?: React.ReactNode;
	isBigInputBox?: boolean;
	required?: boolean;
	testId?: string;
	hideIcon?: boolean;
	isError?: boolean;
	errorText?: string;
	calendarPosition?: PopperPlacement;
	iconElement?: React.ReactNode;
	width?: number;
}

export const DateSelectorTrusstorInput = (props: IDateSelectorTrusstorInputProps) => {
	const tz: string = useSelector(selectTimezone)!;
	const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);
	const dateInputRef = useRef(null);

	const timeFormat: string = translationService.getTimeFormat();
	const dateFormat: string =
		`${translationService.getDateFormat().replaceAll('.', '/')} ` + (props.displayTime ? timeFormat : '');

	const DatePickerInput = forwardRef(({ onClick }: IDatePickerInputProps, ref: any) => {
		const onInputClick = (e) => {
			e.stopPropagation();
			if (props.disableDatePicker) return;
			setIsCalendarOpen(true);
			onClick?.();
		};

		return (
			<TrusstorDatePickerInput
				ref={ref}
				onClick={onInputClick}
				isOpen={isCalendarOpen}
				text={props.selectedDate ? moment.tz(props.selectedDate, tz).format(dateFormat) : undefined}
				placeholder={
					props.inputPlaceHolder ||
					(!props.label ? translationService.get(props.displayTime ? 'enterDateAndTime' : 'enterDate') : '')
				}
				label={props.label}
				disabled={props.disableDatePicker}
				isBigInputBox={props.isBigInputBox}
				required={props.required}
				testId={props.testId}
				width={props.width}
				iconElement={props.iconElement}
				hideIcon={props.hideIcon}
				isError={props.isError}
				errorText={props.errorText}
			/>
		);
	});

	const handleTimeChange = (date: Date) => {
		props.handleTimeChange(date);
		setIsCalendarOpen(false);
	};

	useOutsideClick(dateInputRef, setIsCalendarOpen, false);

	return (
		<div ref={dateInputRef} onClick={(e) => e.stopPropagation()}>
			<DateAndTimeSelector
				disableDatePicker={props.disableDatePicker}
				handleTimeChange={handleTimeChange}
				selectedDate={props.selectedDate}
				displayTime={!!props.displayTime}
				setThirtyMinutesGap
				maxDate={props.maxDate}
				minDate={props.minDate}
				disabledDates={props.disabledDates}
				customInput={<DatePickerInput />}
				wrapperClassName={classes.datePickerWrapper}
				selectDefaultDate={props.selectDefaultDate}
				calendarPosition={props.calendarPosition}
				children={props.children}
			/>
		</div>
	);
};
