import React, { useMemo } from 'react';
import { translationService } from '../../../index';
import { clearCrossedHalfLocation, clearCrossedLocation, clearLocation } from '../../../assets/locationIcons';
import { getNumberDaysAgo, getTimezoneDateStringFromDateObject } from '../../../utils/dateUtils';
import { ADMIN_PANEL_LAST_SEEN_DAYS } from '../../../constants';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../store/slices';
import { TooltipedIcon } from '../../Tooltips/TooltipedIcon';
import classes from './styles.module.scss';

interface ILastSeenFieldProps {
	lastSeen: Date;
	lastSeenFloorNick: string;
	lastSeenProjectName: string;
}

const LastSeenField = (props: ILastSeenFieldProps) => {
	const tz: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.tz);
	const projectId: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.projectId);
	const daysAgo = useMemo(() => getNumberDaysAgo(props.lastSeen, tz), [props.lastSeen, tz]);

	const getLastSeenString = (lastSeen: Date): string => {
		const lastSeenDateString: string = getTimezoneDateStringFromDateObject(lastSeen, tz);
		const lastSeenLocationString: string =
			props.lastSeenProjectName !== projectId ? `${props.lastSeenProjectName}` : `${props.lastSeenFloorNick}`;
		return `${translationService.get('at')}${lastSeenLocationString} ${translationService.get(
			'on'
		)}${lastSeenDateString}`;
	};

	const getFieldIconByDaysAgo = (daysAgo: number) => {
		if (daysAgo <= ADMIN_PANEL_LAST_SEEN_DAYS.OK_MAX_DAYS) {
			return clearLocation;
		}
		if (
			daysAgo > ADMIN_PANEL_LAST_SEEN_DAYS.OK_MAX_DAYS &&
			daysAgo <= ADMIN_PANEL_LAST_SEEN_DAYS.WARNING_MAX_DAYS
		) {
			return clearCrossedLocation;
		}

		return clearCrossedHalfLocation;
	};
	return (
		<TooltipedIcon
			children={<img className={classes.avatar} src={getFieldIconByDaysAgo(daysAgo)} alt="lastseen" />}
			tooltipText={getLastSeenString(props.lastSeen)}
		/>
	);
};

export { LastSeenField };
