import React from 'react';
import { TextField } from '@material-ui/core';
import classes from './styles.module.scss';
import { isArray } from 'lodash';
import { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete';

interface IAutocompleteTextInputOutlinedProps {
	params: AutocompleteRenderInputParams;
	value: any | any[];
	getCustomInputElement?: (selectedValue: any) => JSX.Element;
	label?: string;
	isAutocompleteValue?: boolean;
}

export const AutocompleteTextInputOutlined = (props: IAutocompleteTextInputOutlinedProps) => {
	const isSelectedValue: boolean = isArray(props.value) ? props.value.length > 0 : !!props.value;
	const isInputPropsValueEmpty: boolean = props.params.inputProps['value'] === '';
	const shouldShowCustomElement: boolean =
		isSelectedValue && props.getCustomInputElement !== undefined && !(props.isAutocompleteValue === true);
	const shouldShowLabel: boolean =
		!isSelectedValue && isInputPropsValueEmpty && !shouldShowCustomElement && !(props.isAutocompleteValue === true);
	return (
		<div className={classes.textInputContainer}>
			<TextField
				{...props.params}
				variant={'outlined'}
				label={shouldShowLabel && props.label}
				inputProps={{
					...props.params.inputProps,
					...(shouldShowCustomElement && { value: '' }),
				}}
				InputProps={{
					...props.params?.InputProps,
					classes: {
						root: classes.root,
						input: classes.input,
					},
					...(shouldShowCustomElement && { startAdornment: props.getCustomInputElement!(props.value) }),
				}}
				InputLabelProps={{
					...props.params?.InputLabelProps,
					shrink: false,
					classes: {
						root: classes.label,
						shrink: classes.labelShrink,
					},
				}}
			/>
		</div>
	);
};
