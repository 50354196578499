import React, { useEffect, useState } from 'react';
import classes from './styles.module.scss';
import { GroupedSingleDropdown } from '../../DesignSystem/GroupedSingleDropdown/GroupedSingleDropdown';
import { IProfession } from '@interfaces/index';
import { translationService } from '@src/servicesInitializers';
import { ProfessionDisplayWithTradeIcon } from '@shared/components/ProfessionDisplayWithTradeIcon/ProfessionDisplayWithTradeIcon';
import { useDispatch, useSelector } from 'react-redux';
import { selectProjectId, selectTimezone } from '@store/slices/project.slice';
import { getProfessionDisplayText } from '@utils/professions.utils';
import { getTradeGroupTranslation } from '@utils/translations.utils';
import { IRootState } from '@store/slices';
import { guestTradeId } from '@src/constants';
import { getAllProfessions, getWorkingProfessions } from '@store/thunks';
import { getTimezoneFormattedDate } from '@shared/utils/dateUtils';

interface ISingleProfessionGroupedDropdownProps {
	professions?: IProfession[];
	useWorkingProfessions?: boolean;
	onChange: (profession: IProfession | null) => void;
	disabled?: boolean;
	defaultSelectedProfession?: IProfession;
	required?: boolean;
	noBorder?: boolean;
}

export const SingleProfessionGroupedDropdown = (props: ISingleProfessionGroupedDropdownProps) => {
	const dispatch = useDispatch();
	const [selectedProfession, setSelectedProfession] = useState<IProfession | undefined>(
		props.defaultSelectedProfession
	);
	const projectId: string = useSelector(selectProjectId)!;
	const tz: string = useSelector(selectTimezone)!;
	const projectProfessions: IProfession[] = useSelector((state: IRootState) => state.professionsReducer.professions);
	const professionsWithoutGuest: IProfession[] = projectProfessions.filter(
		(profession) => profession.tradeId !== guestTradeId
	);
	const dropDownProfession = props.professions || professionsWithoutGuest;

	useEffect(() => {
		if (props.professions?.length) {
			return;
		}
		if (props.useWorkingProfessions) {
			dispatch(getWorkingProfessions({ projectId, startDate: getTimezoneFormattedDate(tz, new Date()) }));
		} else {
			dispatch(getAllProfessions());
		}
	}, [projectId]);

	const getOptionLabel = (profession: IProfession): React.ReactNode => {
		return <ProfessionDisplayWithTradeIcon profession={profession} projectId={projectId} />;
	};

	const handleProfessionChange = (value: IProfession | null) => {
		setSelectedProfession(value ? value : undefined);
		props.onChange(value);
	};

	const groupByFunction = (option: IProfession) => {
		return getTradeGroupTranslation(option);
	};

	return (
		<GroupedSingleDropdown<IProfession>
			getDisplayOption={getOptionLabel}
			onChange={handleProfessionChange}
			options={dropDownProfession}
			label={translationService.get('selectProfession')}
			value={selectedProfession}
			groupByFunction={groupByFunction}
			getInputDisplay={(option: IProfession) => getProfessionDisplayText(option)}
			disabled={props.disabled}
			testId="professionDropdown"
			required={props.required}
			inputContainerClassName={props.noBorder ? classes.noBorder : undefined}
		/>
	);
};
