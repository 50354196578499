import React from 'react';
import classnames from 'classnames';
import classes from './styles.module.scss';
import { IconColor, IconSize, TrusstorIconShared } from '../../TrusstorIconShared/TrusstorIconShared';
import { IconNames } from '../../TrusstorIconShared/IconNames.enum';
import { getDateDisplayFormat } from '../../../utils/dateUtils';
import { IIssueShared, IssueStatus } from '../../../interfaces/IIssueShared';
import { IProfession } from '../../../interfaces/IProfession';
import { useSharedServices } from '../../../hooks/sharedServices.context';
import { isIssueOverdue } from '../issues.utils';

interface IIssueDateDisplayProps {
	issue: IIssueShared<IProfession>;
	tz: string;
}

export const IssueDateDisplay = (props: IIssueDateDisplayProps) => {
	const isIssueDelayed: boolean = isIssueOverdue(props.issue, props.tz);
	const isIssueDelayedAndNotCompleted: boolean = isIssueDelayed && props.issue.status !== IssueStatus.COMPLETED;
	const { translationService } = useSharedServices();

	return (
		<div className={classnames(classes.dateTextContainer, { [classes.red]: isIssueDelayedAndNotCompleted })}>
			{props.issue?.dueDate && (
				<>
					<TrusstorIconShared
						iconName={IconNames.flag}
						size={IconSize.SMALL}
						color={isIssueDelayedAndNotCompleted ? IconColor.FeedbackCriticalDark : IconColor.Grey600}
					/>
					<span className={classes.dateText}>
						{getDateDisplayFormat(props.issue?.dueDate, props.tz, translationService)}
					</span>
				</>
			)}
		</div>
	);
};
