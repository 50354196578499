import React from 'react';
import { arrowDown, arrowUp } from '@shared/assets/icons/tradesBox';

interface IArrowUpDownProps {
	isOpen: boolean;
	size?: string;
}

export const ArrowUpDown = (props: IArrowUpDownProps) => {
	return (
		<img
			src={!props.isOpen ? arrowDown : arrowUp}
			alt={props.isOpen ? 'arrowUp' : 'arrowDown'}
			style={{ height: props.size || '16px' }}
		/>
	);
};
