import React from 'react';
import classnames from 'classnames';
import moment from 'moment-timezone';
import classes from './styles.module.scss';
import { IMilestoneProgressSummary } from '@interfaces/IMilestoneProgressSummary';
import { useSelector } from 'react-redux';
import { selectTimezone } from '@store/slices/project.slice';
import { IMilestone } from '@shared/interfaces/IMilestone';
import { translationService } from '@src/servicesInitializers';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { TrusstorIcon } from '@src/Components/TrusstorIcon/TrusstorIcon';
import { IconColor, IconSize } from '@shared/components/TrusstorIconShared/TrusstorIconShared';
import { ITodayMarkerPosition } from '../milestoneSelectorCarousel.utils';
import { DateFlagDisplay } from '@shared/components/DateFlagDisplay/DateFlagDisplay';
import TrusstorTooltip from '@shared/components/TrusstorTooltip';
import {
	getAtRiskText,
	getHealthScoreColor,
	getLineTwoBackgroundColorsStyle,
	getMarkerPositionPercentage,
} from './milestoneSelectorCard.utils';

interface IMilestoneSelectorCardProps {
	isSelected: boolean;
	milestone: IMilestone;
	milestoneProgressSummary: IMilestoneProgressSummary;
	onClick: () => void;
	index: number;
	totalNumberOfMilestones: number;
	todayMarkerPosition?: ITodayMarkerPosition;
	darkTimeline?: boolean;
}

export const MilestoneSelectorCard = (props: IMilestoneSelectorCardProps) => {
	const tz: string = useSelector(selectTimezone)!;
	const isRtl: boolean = translationService.getIsRtl();
	const hasStarted: boolean = props.milestoneProgressSummary.progress.asisCompleted > 0;
	const progressPercentage: number = hasStarted
		? Math.floor(
				(props.milestoneProgressSummary.progress.asisCompleted /
					props.milestoneProgressSummary.progress.asisLinked) *
					100
			)
		: 0;
	const isCompleted: boolean = progressPercentage === 100;
	const isOverdue: boolean =
		!isCompleted && moment.tz(props.milestone.endDate, tz).isBefore(moment.tz(new Date(), tz), 'day');
	const milestoneTitleId: string = `${props.milestone._id}-milestone-text`;

	return (
		<div
			key={props.milestoneProgressSummary.milestoneId}
			onClick={props.onClick}
			className={classnames(classes.milestoneSelector, {
				[classes.selected]: props.isSelected,
			})}
		>
			<TrusstorTooltip text={props.milestone.title} idToOverflow={milestoneTitleId} cleanStyling>
				<p id={milestoneTitleId} className={classes.milestoneTitle}>
					{props.milestone.title}
				</p>
			</TrusstorTooltip>
			{isCompleted ? (
				<div className={classes.completedSection}>
					<TrusstorIcon
						iconName={IconNames.checkCircleFull}
						color={IconColor.Blue200}
						size={IconSize.SMALL}
					/>
					<p className={classes.completedText}>{translationService.get('completedAction')}</p>
				</div>
			) : (
				<div className={classes.progressSection}>
					{hasStarted && (
						<>
							<p className={classes.percentage}>{progressPercentage}%</p>
							<div className={classes.progressBar}>
								<div className={classes.percentSection} style={{ width: `${progressPercentage}%` }} />
							</div>
						</>
					)}
				</div>
			)}
			<div className={classes.timeline}>
				<div
					className={classnames(classes.line, {
						[classes.first]: props.index === 0,
						[classes.last]: props.index === props.totalNumberOfMilestones - 1,
						[classes.rtl]: isRtl,
						[classes.dark]: props.darkTimeline,
					})}
					style={getLineTwoBackgroundColorsStyle(props.todayMarkerPosition, isRtl, props.index === 0)}
				/>
				<div
					className={classnames(classes.indexContainer, {
						[classes.selected]: props.isSelected,
						[classes.notStarted]: !hasStarted,
					})}
				>
					<p className={classes.indexText}>{props.index + 1}</p>
				</div>
				{props.todayMarkerPosition && (
					<div
						className={classes.todayMarker}
						style={{
							[isRtl ? 'right' : 'left']: getMarkerPositionPercentage(props.todayMarkerPosition),
						}}
					>
						<p className={classnames(classes.todayText, { [classes.rtl]: isRtl })}>
							{translationService.get('today')}
						</p>
						<div className={classes.todayLine} />
					</div>
				)}
			</div>
			<div className={classnames({ [classes.dateFlagContainer]: isOverdue })}>
				<DateFlagDisplay tz={tz} date={props.milestone.endDate} isOverdue={isOverdue} isFullFormat />
			</div>
			{!isOverdue && !isCompleted && hasStarted && (
				<div className={classes.healthIndicatorContainer}>
					<div
						className={classes.healthIndicator}
						style={{
							backgroundColor: getHealthScoreColor(props.milestoneProgressSummary.healthScore),
						}}
					></div>
					<p className={classes.healthScoreText}>
						{getAtRiskText(props.milestoneProgressSummary.healthScore)}
					</p>
				</div>
			)}
		</div>
	);
};
