import { createAsyncThunk } from '@reduxjs/toolkit';
import { requestService } from '@src/index';
import { IUser } from '@src/interfaces';

export const getUsers = createAsyncThunk('getUsers', async (projectId: string) => {
	const usersGetUrl: string = `/entities/user/all/${projectId}`;
	const users: IUser[] = await requestService.get(usersGetUrl);

	return { users };
});
