import React from 'react';
import classes from './styles.module.scss';
import { TranslationService } from '../../../services/translation.service';
import classnames from 'classnames';
import { getIsTouchDevice } from '../../../utils/mobile.utils';
import { IssuePriority } from '../../../interfaces/IIssueShared';
import { IconColor, IconSize, TrusstorIconShared } from '../../TrusstorIconShared/TrusstorIconShared';
import { IconNames } from '../../TrusstorIconShared/IconNames.enum';

interface ICriticalSectionProps {
	translationService: TranslationService;
	onClick: (e: React.MouseEvent) => void;
	styles?: any;
	issuePriority: IssuePriority;
	isMobile?: boolean;
	isLimitedUser: boolean;
}

export const CriticalSection = (props: ICriticalSectionProps) => {
	const [isHovered, setIsHovered] = React.useState<boolean>(false);
	const isTouchDevice: boolean = getIsTouchDevice();
	const isCritical: boolean = props.issuePriority === IssuePriority.CRITICAL;

	const toggleIsHovered = () => {
		if (isTouchDevice || props.isLimitedUser) {
			return;
		}
		setIsHovered((prev) => !prev);
	};

	const getLabel = (): string => {
		if (isHovered) {
			return (
				`${props.translationService.get('markAs')}` +
				`${
					isCritical
						? props.translationService.get('regularMin')
						: props.translationService.get('criticalMin')
				}`
			);
		}
		if (props.isMobile && isCritical) {
			return props.translationService.get('criticalUpper');
		}
		return '';
	};

	const shouldShowFullStarIcon = (): boolean => {
		if (props.isLimitedUser) {
			return isCritical;
		}
		if (isHovered) {
			return true;
		}
		return isCritical;
	};

	return (
		<div
			onMouseEnter={toggleIsHovered}
			onMouseLeave={toggleIsHovered}
			className={classes.criticalSection}
			onClick={!props.isLimitedUser ? (e) => props.onClick(e) : undefined}
		>
			<TrusstorIconShared
				onHoverStyleClass={classnames({ [classes.filledIcon]: !props.isLimitedUser && !props.isMobile })}
				className={classnames({ [classes.filledIcon]: shouldShowFullStarIcon() })}
				iconName={IconNames.star}
				color={shouldShowFullStarIcon() ? IconColor.Grey800 : IconColor.Grey200}
				size={IconSize.MEDIUM}
			/>
			{(props.isMobile || isHovered) && (
				<div className={classes.label} style={props.styles}>
					{getLabel()}
				</div>
			)}
		</div>
	);
};
