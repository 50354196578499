import { Alignment, Cell, Row, Workbook, Worksheet } from 'exceljs';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { mapValues } from 'lodash';
import { projectService, storageService, translationService } from '../index';
import { IProject } from '@shared/interfaces/IProject';
import { trusstorLogoBase64 } from '../constants/logos.constants';
import { TranslationService } from '@shared/services/translation.service';
import { DIRECTIONS } from '@shared/constants/directions';
import Borders from 'exceljs/index';

interface IExcelExportOptions {
	centerAligned?: boolean;
	mergeColumnEqualCells?: boolean;
	mergeFirstColumnEqualRowCells?: boolean;
	mergeRowsEqualCells?: boolean;
	cellColor?: string;
	headerColor?: string;
	columnWidth?: number;
	firstColumnWidth?: number;
	headerFontSize?: number;
	disableRowHeight?: boolean;
	headerRow?: number;
}

interface ISequenceOfRows {
	start: number;
	end: number;
}

export class ExportService {
	private readonly headlineRow = 2;
	private readonly secondHeadlineRow = 4;
	private headerRow = 6;
	private readonly headlineRowHeight = 50;
	private readonly projectNameCell = 'A4';
	constructor(private translationService: TranslationService) {}

	public async exportMultipleSheetsToExcel(
		sheets: ISheets,
		fileName: string,
		headLine?: string,
		excelTimes?: string,
		options?: IExcelExportOptions,
		singleColor?: boolean,
		sheetsInfoValues?: ISheetsInfoValues
	): Promise<void> {
		if (options?.headerRow) {
			this.headerRow = options.headerRow;
		}
		const chosenProject: IProject = projectService.getChosenProject()!;
		const projectLogo: string = await storageService.getProjectLogo(chosenProject.projectId);

		const workbook: Workbook = new Workbook();
		Object.entries(sheets).forEach(([sheetName, sheetRowsToAdd]) => {
			this.createWorksheet(
				sheetName,
				sheetRowsToAdd,
				workbook,
				chosenProject,
				projectLogo,
				headLine,
				excelTimes,
				options,
				singleColor,
				sheetsInfoValues ? sheetsInfoValues[sheetName] : undefined
			);
		});
		const buffer: ArrayBuffer = await workbook.xlsx.writeBuffer();
		saveAs(new Blob([buffer]), ExportService.addExcelExtension(fileName));
	}

	private createWorksheet = (
		sheetName: string,
		sheetRowsToAdd: IRow[],
		workbook: Workbook,
		chosenProject: IProject,
		projectLogo: string,
		headLine?: string,
		excelTimes?: string,
		options?: IExcelExportOptions,
		singleColor?: boolean,
		sheetsInfoValues?: ISheetInfoValues
	) => {
		const worksheet: Worksheet = workbook.addWorksheet();
		worksheet.name = sheetName;
		if (sheetRowsToAdd.length === 0) {
			return;
		}
		this.addColumnHeadersToSheet(worksheet, sheetRowsToAdd);
		const excelSheetRows: Row[] = worksheet.addRows(
			sheetRowsToAdd.map((row: IRow) => mapValues(row, (cell: ICell) => cell.value || ''))
		);

		this.addStylesToColumns(worksheet, options?.centerAligned, options?.columnWidth, options?.firstColumnWidth);
		this.addStylesToRows(
			excelSheetRows,
			sheetRowsToAdd,
			worksheet,
			options?.centerAligned,
			options?.mergeColumnEqualCells,
			singleColor,
			options?.cellColor,
			options?.headerColor,
			options?.headerFontSize,
			options?.disableRowHeight
		);
		sheetsInfoValues
			? this.addCustomSheetsFilesInfo(worksheet, sheetsInfoValues)
			: this.addDefaultSheetFilesInfo(worksheet, chosenProject, excelTimes!);

		if (options?.mergeFirstColumnEqualRowCells) {
			this.mergeFirstColumnEqualRowCells(worksheet);
		}

		if (options?.mergeRowsEqualCells) {
			this.mergeRowsEqualCells(worksheet);
		}
		if (options?.mergeColumnEqualCells) {
			this.mergeColumnEqualCells(worksheet, 1);
		}
		this.addFontToCells(worksheet, this.translationService.getDirection() === DIRECTIONS.RTL);
		this.addLogosToSheet(workbook, worksheet, projectLogo);
		if (headLine) {
			this.addHeadlineToSheet(worksheet, headLine);
		}

		worksheet.views = [
			{
				rightToLeft: this.translationService.getDirection() === DIRECTIONS.RTL,
				showGridLines: false,
			},
		];
	};

	private addFontToCells(worksheet: Worksheet, isHebrew?: boolean) {
		const hebFont = 'Noto Sans Hebrew';
		worksheet.eachRow((row) => {
			row.eachCell((cell) => {
				cell.font = {
					...cell.font,
					name: isHebrew ? hebFont : 'Outfit',
				};
			});
		});
	}

	private addRegularBorderToCell(cell) {
		const previousBorder = cell.border;
		cell.border = {
			top: { style: 'thin', color: { argb: '000000' } },
			left: { style: 'thin', color: { argb: '000000' } },
			bottom: { style: 'thin', color: { argb: '000000' } },
			right: { style: 'thin', color: { argb: '000000' } },
			...previousBorder,
		};
	}

	private addBottomBorderToRow(row: Row) {
		row.eachCell({ includeEmpty: true }, (cell) => {
			const previousBorder = cell.border;
			cell.border = {
				...previousBorder,
				bottom: { style: 'medium', color: { argb: '000000' } },
			};
		});
	}

	private getMaxColumns(worksheet: Worksheet) {
		return worksheet.getRow(1).cellCount + 1;
	}

	private swapCellsValues(worksheet: Worksheet, rowNumber: number, endRow: number, colNumber: number, cell) {
		let currentRow: number = rowNumber;
		let nextCellToSwap: Cell = worksheet.getCell(currentRow + 1, colNumber);
		while (currentRow < endRow) {
			const nextCell: Cell = worksheet.getCell(currentRow + 1, colNumber);
			if (nextCell.value !== '') {
				nextCellToSwap = nextCell;
				break;
			}
			currentRow++;
		}
		cell.value = nextCellToSwap.value;
		nextCellToSwap.value = '';
	}

	private moveEmptyCellsDown(
		worksheet: Worksheet,
		startRow: number,
		endRow: number,
		startColumn: number,
		endColumn: number
	) {
		for (let colNumber = startColumn; colNumber < endColumn; colNumber++) {
			worksheet.getColumn(colNumber).eachCell((cell, rowNumber) => {
				if (rowNumber >= startRow && rowNumber < endRow) {
					if (cell.value === '' || !cell.value) {
						this.swapCellsValues(worksheet, rowNumber, endRow, colNumber, cell);
					}
				}
			});
		}
	}

	private getSequencesOfRows(worksheet: Worksheet, columnIndex: number, minRow: number): ISequenceOfRows[] {
		const sequencesOfRows: ISequenceOfRows[] = [];
		let currentSequenceOfRows: ISequenceOfRows = { start: 0, end: 0 };
		worksheet.getColumn(columnIndex).eachCell((cell, rowNumber) => {
			if (rowNumber < minRow) {
				return;
			}
			const nextCellValue = worksheet.getCell(rowNumber + 1, columnIndex).value;
			if (nextCellValue && cell.value === nextCellValue) {
				if (currentSequenceOfRows.start === 0) {
					currentSequenceOfRows.start = rowNumber;
				}
				currentSequenceOfRows.end = rowNumber + 1;
				return;
			}
			if (currentSequenceOfRows.start === 0) {
				sequencesOfRows.push({ start: rowNumber, end: rowNumber });
				return;
			}
			sequencesOfRows.push(currentSequenceOfRows);
			currentSequenceOfRows = { start: 0, end: 0 };
		});
		return sequencesOfRows;
	}

	private mergeRowsEqualCells(worksheet: Worksheet) {
		const rowsToMerge = worksheet.getRows(this.headerRow + 1, worksheet.rowCount);
		rowsToMerge?.forEach((row) => {
			let firstCellToMerge = row.getCell(1);
			let lastCellToMerge;
			row.eachCell({ includeEmpty: true }, (cell) => {
				const firstCellToMergeValue = firstCellToMerge.value;
				if (cell.address === firstCellToMerge.address) {
					return;
				}
				if (cell.value === firstCellToMergeValue) {
					lastCellToMerge = cell;
					return;
				} else {
					if (lastCellToMerge && lastCellToMerge.address !== firstCellToMerge.address) {
						worksheet.mergeCells(`${firstCellToMerge.address}:${lastCellToMerge.address}`);
					}
					firstCellToMerge = cell;
					lastCellToMerge = undefined;
				}
			});
			if (lastCellToMerge) {
				worksheet.mergeCells(`${firstCellToMerge.address}:${lastCellToMerge.address}`);
			}
		});
	}

	private mergeFirstColumnEqualRowCells(worksheet: Worksheet) {
		const firstColumn = worksheet.getColumn(1);
		let firstCellToMerge;
		let lastCellToMerge;
		firstColumn.eachCell((cell) => {
			if (!cell.value) {
				return;
			}
			if (!firstCellToMerge) {
				firstCellToMerge = cell;
				return;
			}
			const firstCellToMergeValue = firstCellToMerge.value;
			if (cell.address === firstCellToMerge.address) {
				return;
			}
			if (cell.value === firstCellToMergeValue) {
				lastCellToMerge = cell;
				return;
			} else {
				if (lastCellToMerge && lastCellToMerge.address !== firstCellToMerge.address) {
					worksheet.mergeCells(`${firstCellToMerge.address}:${lastCellToMerge.address}`);
				}
				firstCellToMerge = cell;
				lastCellToMerge = undefined;
			}
		});
		if (lastCellToMerge) {
			worksheet.mergeCells(`${firstCellToMerge.address}:${lastCellToMerge.address}`);
		}
	}

	private mergeColumnEqualCells(worksheet: Worksheet, columnToFilterBy: number) {
		const maxColumns: number = this.getMaxColumns(worksheet);
		this.getSequencesOfRows(worksheet, columnToFilterBy, this.headerRow).forEach((sequenceOfRows) => {
			this.moveEmptyCellsDown(worksheet, sequenceOfRows.start, sequenceOfRows.end, 1, maxColumns);
		});

		this.getSequencesOfRows(worksheet, columnToFilterBy, this.headerRow).forEach((sequenceOfRows) => {
			worksheet.mergeCells(`A${sequenceOfRows.start}:A${sequenceOfRows.end}`);
			this.addBottomBorderToRow(worksheet.getRow(sequenceOfRows.end));
		});
	}

	private addColumnHeadersToSheet(worksheet: Worksheet, sheetRows: IRow[]) {
		const columns: IColumn[] = ExportService.getColumnsFromRow(sheetRows[0]);
		worksheet.getRow(this.headerRow).values = columns.map((column) => column.key);
		worksheet.columns = columns as any;
	}

	private async addLogosToSheet(workbook: Workbook, worksheet: Worksheet, projectLogoBase64: any) {
		const trusstorLogo: number = workbook.addImage({
			base64: trusstorLogoBase64,
			extension: 'png',
		});
		if (projectLogoBase64) {
			const projectLogo: number = workbook.addImage({
				base64: projectLogoBase64,
				extension: 'png',
			});
			worksheet.addImage(projectLogo, {
				tl: { col: 0.5, row: 1.5 },
				ext: { width: 120, height: 30 },
			});
		}
		const trusstorLogoColumnPosition: number = worksheet.columnCount <= 2 ? 2.3 : worksheet.columnCount - 1;
		worksheet.addImage(trusstorLogo, {
			tl: { col: trusstorLogoColumnPosition, row: 1.5 },
			ext: { width: 120, height: 30 },
		});
	}

	private getCharAfterLetter = (key: string): string => {
		if (key === 'Z' || key === 'z') {
			const baseChar: string = String.fromCharCode(key.charCodeAt(0) - 25);
			return baseChar + baseChar;
		} else {
			const lastChar: string = key.slice(-1);
			const sub: string = key.slice(0, -1);

			if (lastChar === 'Z' || lastChar === 'z') {
				const baseChar: string = String.fromCharCode(lastChar.charCodeAt(0) - 25);
				return this.getCharAfterLetter(sub) + baseChar;
			} else {
				return sub + String.fromCharCode(lastChar.charCodeAt(0) + 1);
			}
		}
	};

	private getColumnNumber(columnChar: string): number {
		let result = 0;
		for (let i = 0; i < columnChar.length; i++) {
			result = result * 26 + (columnChar.charCodeAt(i) - 'A'.charCodeAt(0) + 1);
		}
		return result;
	}

	private addCustomSheetsFilesInfo(worksheet: Worksheet, infoValues: ISheetInfoValues) {
		const side: string = translationService.getDirection();
		const horizontalAlignment: Alignment['horizontal'] = side !== 'rtl' ? 'left' : 'right';
		const maxTextLength: {
			[valueColumnChar: string]: number;
		} = {};
		Object.entries(infoValues).forEach(([infoValue, { rowNumber, columnChar: keyColumnChar, value }]) => {
			const keyCellPosition: string = `${keyColumnChar}${rowNumber}`;
			const valueColumnChar: string = this.getCharAfterLetter(keyColumnChar);
			const valueCellPosition: string = `${valueColumnChar}${rowNumber}`;
			const keyCell = worksheet.getCell(keyCellPosition);
			keyCell.value = `${infoValue}:`;
			keyCell.alignment = {
				vertical: 'middle',
				horizontal: horizontalAlignment,
				wrapText: true,
			};
			keyCell.font = {
				size: 12,
				bold: true,
			};

			const valueCell = worksheet.getCell(valueCellPosition);
			valueCell.value = value;
			valueCell.alignment = {
				vertical: 'middle',
				horizontal: horizontalAlignment,
				wrapText: true,
			};
			valueCell.font = {
				size: 12,
				bold: true,
			};

			worksheet.getColumn(keyColumnChar).width = 15;
			worksheet.getRow(rowNumber).height = 50;
			if (!maxTextLength[valueColumnChar] || maxTextLength[valueColumnChar] < value.length) {
				maxTextLength[valueColumnChar] = value.length;
			}
		});
		Object.entries(maxTextLength).forEach(([columnChar, maxTextLength]) => {
			worksheet.getColumn(columnChar).width = maxTextLength * 1.2 + 2;
		});
		const { lowestRowNumber, lowestColumnChar, highestRowNumber, highestColumnChar } =
			this.findLowestAndHighestInfoValuesCells(infoValues);
		this.createOuterBorder(
			worksheet,
			{ col: this.getColumnNumber(lowestColumnChar), row: lowestRowNumber },
			{ col: this.getColumnNumber(highestColumnChar) + 1, row: highestRowNumber }
		);
	}

	private addDefaultSheetFilesInfo(worksheet: Worksheet, chosenProject: IProject, excelTimes: string) {
		const side: string = translationService.getDirection();
		const horizontalAlignment: Alignment['horizontal'] = side !== 'rtl' ? 'left' : 'right';
		const projectNameCell: Cell = worksheet.getCell(this.projectNameCell);
		const summariesDateCell: Cell = worksheet.getCell(
			`${worksheet.getColumn(worksheet.columnCount).letter}:${this.secondHeadlineRow}`
		);
		worksheet.mergeCells(
			`${summariesDateCell.address}:${worksheet.getColumn(worksheet.columnCount - 1)?.letter}${
				this.secondHeadlineRow
			}`
		);

		projectNameCell.value = `${this.translationService.get('projectName')}: ${
			chosenProject.name || chosenProject.projectId
		}`;
		summariesDateCell.value = `${this.translationService.get('dates')}: ${
			excelTimes || moment.tz(chosenProject.tz).format('DD.MM.YYYY')
		}`;
		projectNameCell.alignment = {
			vertical: 'middle',
			horizontal: horizontalAlignment,
			wrapText: true,
		};
		summariesDateCell.alignment = {
			vertical: 'middle',
			horizontal: horizontalAlignment,
			wrapText: true,
		};
		projectNameCell.font = { bold: true, size: 12 };
		summariesDateCell.font = { bold: true, size: 12 };
		worksheet.getRow(this.secondHeadlineRow).height = this.headlineRowHeight;

		if (!this.isCellMerged(worksheet, 'B4') && !this.isCellMerged(worksheet, this.projectNameCell)) {
			worksheet.mergeCells(`${this.projectNameCell}:B4`);
		}

		this.createOuterBorder(
			worksheet,
			{ row: this.secondHeadlineRow, col: 1 },
			{ row: this.secondHeadlineRow, col: worksheet.getRow(this.headerRow).cellCount }
		);
	}

	private addHeadlineToSheet(worksheet: Worksheet, headLine: string) {
		const headLineCell: Cell = worksheet.getCell(
			`${worksheet.getColumn(Math.ceil(worksheet.columnCount / 2)).letter}:2`
		);

		headLineCell.value = headLine;
		headLineCell.font = {
			size: 26,
			bold: true,
		};
		headLineCell.alignment = {
			vertical: 'middle',
			horizontal: 'center',
		};
		worksheet.getRow(this.headlineRow).height = this.headlineRowHeight;
	}

	private isCellMerged(worksheet: Worksheet, cellAddress: string): boolean {
		const cell = worksheet.getCell(cellAddress);
		return !!cell.master;
	}

	private findLowestAndHighestInfoValuesCells(sheetInfoValues: ISheetInfoValues): {
		lowestRowNumber: number;
		lowestColumnChar: string;
		highestRowNumber: number;
		highestColumnChar: string;
	} {
		let lowestRowNumber = Number.MAX_SAFE_INTEGER;
		let lowestColumnChar = 'Z';
		let highestRowNumber = 0;
		let highestColumnChar = 'A';

		for (const sheetName in sheetInfoValues) {
			const sheetInfo = sheetInfoValues[sheetName];
			const { rowNumber, columnChar } = sheetInfo;
			if (columnChar > highestColumnChar) {
				highestColumnChar = columnChar;
			}
			if (rowNumber > highestRowNumber) {
				highestRowNumber = rowNumber;
			}
			if (columnChar < lowestColumnChar) {
				lowestColumnChar = columnChar;
			}
			if (rowNumber < lowestRowNumber) {
				lowestRowNumber = rowNumber;
			}
		}

		return {
			lowestRowNumber,
			lowestColumnChar,
			highestRowNumber,
			highestColumnChar,
		};
	}

	private createOuterBorder = (
		worksheet: Worksheet,
		start: { row: number; col: number },
		end: { row: number; col: number },
		borderWidth = 'thin'
	) => {
		const borderStyle = {
			style: borderWidth,
		} as Borders;
		for (let i = start.row; i <= end.row; i++) {
			const leftBorderCell = worksheet.getCell(i, start.col);
			const rightBorderCell = worksheet.getCell(i, end.col);
			leftBorderCell.border = {
				...leftBorderCell.border,
				left: borderStyle,
			};
			rightBorderCell.border = {
				...rightBorderCell.border,
				right: borderStyle,
			};
		}

		for (let i = start.col; i <= end.col; i++) {
			const topBorderCell = worksheet.getCell(start.row, i);
			const bottomBorderCell = worksheet.getCell(end.row, i);
			topBorderCell.border = {
				...topBorderCell.border,
				top: borderStyle,
			};
			bottomBorderCell.border = {
				...bottomBorderCell.border,
				bottom: borderStyle,
			};
		}
	};

	private addStylesToColumns(
		worksheet: Worksheet,
		centerAligned?: boolean,
		columnWidth?: number,
		firstColumnWidth?: number
	) {
		const firstColumn = worksheet.getColumn(1);
		firstColumn.font = {
			size: 12,
		};
		firstColumn.width = firstColumnWidth || 15;
		worksheet.columns.forEach((column, i) => {
			if (i === 0) {
				return;
			}
			column.width = columnWidth || 20;
		});

		firstColumn.alignment = { vertical: 'middle', wrapText: true };
		if (centerAligned) {
			firstColumn.alignment = { vertical: 'middle', wrapText: true };
		}
	}

	private addStylesToRows(
		excelSheetRows: Row[],
		sheetRows: IRow[],
		worksheet: Worksheet,
		centerAligned?: boolean,
		mergeColumnEqualCells?: boolean,
		singleColors?: boolean,
		cellColor?: string,
		headerColor?: string,
		headerFontSize?: number,
		disableRowHeight?: boolean
	) {
		const headersRow = worksheet.getRow(this.headerRow);
		headersRow.height = this.headlineRowHeight;
		headersRow.font = {
			size: headerFontSize || 14,
			bold: true,
			color: { argb: headerColor?.toUpperCase() === 'FFFFFF' ? '000000' : 'FFFFFF' },
		};

		headersRow.alignment = {
			vertical: 'middle',
			horizontal: 'center',
			wrapText: true,
		};

		headersRow.eachCell({ includeEmpty: true }, (cell) => {
			cell.border = {
				top: { style: 'thin', color: { argb: '000000' } },
				left: { style: 'thin', color: { argb: '000000' } },
				bottom: { style: 'thin', color: { argb: '000000' } },
				right: { style: 'thin', color: { argb: '000000' } },
			};
			cell.fill = {
				type: 'pattern',
				pattern: 'solid',
				fgColor: { argb: headerColor || '4F81BD' },
				bgColor: { argb: headerColor || '4F81BD' },
			};
		});

		if (mergeColumnEqualCells) {
			this.addBottomBorderToRow(headersRow);
			headersRow.eachCell({ includeEmpty: true }, (cell, colNumber) => {
				if (colNumber < this.getMaxColumns(worksheet)) {
					this.addRegularBorderToCell(cell);
				}
			});
		}
		excelSheetRows.forEach((row: Row, index: number) => {
			worksheet.properties.defaultRowHeight = 45;
			if (!disableRowHeight) {
				row.height = 45;
			}
			row.eachCell((cell, colNumber) => {
				const celColumnKey: string = worksheet.getColumn(colNumber).key as string;
				if (centerAligned && colNumber !== 1) {
					cell.alignment = { horizontal: 'center' };
				}
				cell.alignment = {
					...cell.alignment,
					wrapText: true,
					vertical: 'middle',
					horizontal: 'center',
				};
				this.addRegularBorderToCell(cell);
				cell.fill = {
					type: 'pattern',
					pattern: 'solid',
					bgColor: {
						argb: index % 2 !== 0 && !singleColors ? 'B8CCE4' : 'DBE5F1',
					},
					fgColor: {
						argb: index % 2 !== 0 && !singleColors ? 'B8CCE4' : 'DBE5F1',
					},
				};
				if (cellColor) {
					cell.fill = {
						type: 'pattern',
						pattern: 'solid',
						bgColor: { argb: cellColor },
						fgColor: { argb: cellColor },
					};
				}
				if (sheetRows[index][celColumnKey] && sheetRows[index][celColumnKey].color) {
					const cellColor: string = ExportService.getExcelColor(sheetRows[index][celColumnKey].color);
					cell.fill = {
						type: 'pattern',
						pattern: 'solid',
						fgColor: { argb: cellColor },
						bgColor: { argb: cellColor },
					};
					cell.font = {
						color: { argb: '000000' },
					};
				}
			});
		});
	}

	private static addExcelExtension(fileName: string): string {
		return `${fileName}.xlsx`;
	}

	private static getColumnsFromRow(row: IRow): IColumn[] {
		const width = Object.keys(row).length > 2 ? 30 : 50;
		return Object.keys(row).map((column) => ({
			key: column,
			width: column.length < 30 ? width : column.length + 7,
		}));
	}

	private static getExcelColor(color: string | undefined) {
		if (!color) {
			return '';
		}

		if (color[0] === '#') {
			return color.slice(1).toUpperCase();
		}

		return color.toUpperCase();
	}
}

export interface ISheets {
	[sheetName: string]: IRow[];
}

export interface IRow {
	[column: string]: ICell;
}

export interface ICell {
	color?: string;
	value?: string | number;
	id?: string;
}

interface IColumn {
	key: string;
}

export interface ISheetsInfoValues {
	[sheetName: string]: ISheetInfoValues;
}

export interface ISheetInfoValues {
	[infoValue: string]: {
		rowNumber: number;
		columnChar: string;
		value: string;
	};
}
