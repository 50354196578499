import { useState, useEffect } from 'react';
import { forEach } from 'lodash';

const useCarouselDimensions = (carouselRef, numItems) => {
	const [itemsToShow, setItemsToShow] = useState(0);
	const [itemsWidths, setItemsWidths] = useState<number[]>([]);

	useEffect(() => {
		const updateItemsToShowAndWidths = () => {
			if (carouselRef.current) {
				const containerWidth = carouselRef.current.offsetWidth;
				let totalWidth = 0;
				let itemsToFit = 0;
				const widths: number[] = [];

				forEach(carouselRef.current.children, (child, index) => {
					widths[index] = child.offsetWidth;
					if (totalWidth + child.offsetWidth <= containerWidth) {
						totalWidth += child.offsetWidth;
						itemsToFit += 1;
					}
				});

				setItemsToShow(itemsToFit);
				setItemsWidths(widths);
			}
		};

		// Initialize the observer
		const resizeObserver = new ResizeObserver(() => {
			updateItemsToShowAndWidths();
		});

		if (carouselRef.current) {
			// Observe the carousel container
			resizeObserver.observe(carouselRef.current);

			// Optionally, observe each child if their sizes can change independently
			forEach(carouselRef.current.children, (child) => {
				resizeObserver.observe(child);
			});
		}

		updateItemsToShowAndWidths();
		window.addEventListener('resize', updateItemsToShowAndWidths);

		return () => {
			window.removeEventListener('resize', updateItemsToShowAndWidths);
			if (carouselRef.current) {
				resizeObserver.unobserve(carouselRef.current);
				forEach(carouselRef.current.children, (child) => {
					resizeObserver.unobserve(child);
				});
			}
		};
	}, [numItems, carouselRef]);

	return { itemsToShow, itemsWidths };
};

export default useCarouselDimensions;
