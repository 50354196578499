import { useEffect } from 'react';
import { updateActiveFilterType } from '../store/slices/appFilter.slice';
import { APP_FILTER_TYPES } from '../constants';
import { useDispatch } from 'react-redux';

export const useSetActiveFilterType = (filterType: APP_FILTER_TYPES) => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(updateActiveFilterType({ activeFilterType: filterType }));
	}, []);
};
