import React from 'react';
import classnames from 'classnames';
import { TrusstorIcon } from '@src/Components/TrusstorIcon/TrusstorIcon';
import classes from './styles.module.scss';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';

interface IFilterToggleProps {
	hasActiveFilters: boolean;
	toggleFilterSection: () => void;
	isOpen: boolean;
}

export const FiltersToggle = (props: IFilterToggleProps) => {
	return (
		<div
			onClick={props.toggleFilterSection}
			className={classnames(classes.filtersToggle, {
				[classes.activeFilter]: props.hasActiveFilters,
				[classes.isOpen]: props.isOpen,
			})}
			data-testid={'filtersToggle'}
		>
			<TrusstorIcon iconName={IconNames.settingsSliders} />
		</div>
	);
};
