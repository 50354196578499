import React from 'react';
import { BaseNotificationCard } from '../BaseNotificationCard/BaseNotificationCard';
import classes from './styles.module.scss';
import { INotification } from '../../../interfaces/INotification';
import { TranslationService } from '../../../services/translation.service';
import { RequestService } from '../../../services/request.service';
import { isTrusstorMobile } from '../../../utils/mobile.utils';
import { IconColor, IconSize, TrusstorIconShared } from '../../TrusstorIconShared/TrusstorIconShared';
import { IconNames } from '../../TrusstorIconShared/IconNames.enum';
import {
	getAreaSequenceItemStatusUpdateCardDescriptionText,
	getAreaSequenceItemStatusUpdateCardTitleText,
} from './AreaSequenceItemStatusUpdateCard.texts';
import { IAreaSequenceItemStatusUpdateNotificationMetadata } from '../../../interfaces/IAreaSequenceItemStatusUpdateNotificationMetadata';

interface IAreaSequenceItemStatusUpdateCardProps {
	notification: INotification;
	requestService: RequestService;
	translationService: TranslationService;
	tz: string;
	handleCardClick: () => void;
}

export const getAreaSequenceItemStatusUpdateCardTitleElement = (
	metadata: IAreaSequenceItemStatusUpdateNotificationMetadata,
	translationService: TranslationService,
	isDarkTheme?: boolean
): React.ReactNode => {
	const titleClass = `${classes.title} ${isDarkTheme ? classes.titleDarkTheme : ''}`;
	return (
		<div className={titleClass}>{getAreaSequenceItemStatusUpdateCardTitleText(metadata, translationService)}</div>
	);
};

export const getAreaSequenceItemStatusUpdateCardIconElement = (requestService: RequestService): React.ReactNode => {
	return <TrusstorIconShared iconName={IconNames.workplan} color={IconColor.White} size={IconSize.MEDIUM} />;
};

export const getAreaSequenceItemStatusUpdateCardDescriptionElement = (
	translationService: TranslationService,
	metadata: IAreaSequenceItemStatusUpdateNotificationMetadata,
	isDarkTheme?: boolean
): React.ReactNode => {
	const descriptionClass: string = `${classes.description} ${isDarkTheme ? classes.descriptionDarkTheme : ''}`;
	return (
		<div className={descriptionClass}>
			{getAreaSequenceItemStatusUpdateCardDescriptionText(translationService, metadata)}
		</div>
	);
};

export const AreaSequenceItemStatusUpdateCard = (props: IAreaSequenceItemStatusUpdateCardProps) => {
	const metadata: IAreaSequenceItemStatusUpdateNotificationMetadata = props.notification
		.metadata as IAreaSequenceItemStatusUpdateNotificationMetadata;

	const handleCardClick = () => {
		props.handleCardClick();
	};

	return (
		<BaseNotificationCard
			icon={getAreaSequenceItemStatusUpdateCardIconElement(props.requestService)}
			titleElement={getAreaSequenceItemStatusUpdateCardTitleElement(metadata, props.translationService)}
			descriptionElement={getAreaSequenceItemStatusUpdateCardDescriptionElement(
				props.translationService,
				metadata
			)}
			createdAt={props.notification.createdAt}
			tz={props.tz}
			translationService={props.translationService}
			requester={props.requestService}
			notificationId={props.notification._id}
			handleCardClick={handleCardClick}
			isViewed={props.notification.isViewed}
			isClickable={!isTrusstorMobile()}
		/>
	);
};
