import React from 'react';
import classes from './styles.module.scss';
import { translationService } from '@src/servicesInitializers';
import { progressAnalysis } from '@shared/assets/icons';
import { TrusstorIcon } from '@src/Components/TrusstorIcon/TrusstorIcon';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { IconColor, IconSize } from '@shared/components/TrusstorIconShared/TrusstorIconShared';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectProjectId } from '@store/slices/project.slice';

export const ProgressAnalysisReportCard = () => {
	const isRtl: boolean = translationService.getIsRtl();
	const history = useHistory();
	const projectId: string = useSelector(selectProjectId)!;

	const handleCardClick = () => {
		history.push(`/${projectId}/analysisCenter/projectProgressAnalysis`);
	};

	return (
		<div className={classes.progressCard} onClick={handleCardClick}>
			<div className={classes.topSection}>
				<div className={classes.newLabel}>{translationService.get('progressAnalysisCard_newLabel')}</div>
				<img className={classes.image} src={progressAnalysis} />
				<div className={classes.reportTitle}>{translationService.get('progressAnalysisCard_reportTitle')}</div>
				<div className={classes.reportDescription}>
					{translationService.get('progressAnalysisCard_reportDescription')}
				</div>
			</div>
			<div className={classnames({ [classes.triangleSection]: true, [classes.rtlTriangle]: isRtl })}>
				<div className={classes.arrowIcon}>
					<TrusstorIcon
						iconName={isRtl ? IconNames.arrowLeft : IconNames.arrowRight}
						color={IconColor.Blue200}
						size={IconSize.LARGE}
					/>
				</div>
			</div>
		</div>
	);
};
