import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ReportCard } from '@src/Components/Pages/AnalysisCenterPages/CardsSection/ReportCard';
import { InvestigationReportTypes } from '@shared/interfaces/InvestigationReportTypes.enum';
import classes from '@src/Components/Pages/AnalysisCenterPages/CardsSection/ObserveLocationCard/styles.module.scss';
import { requestService, translationService } from '@src/servicesInitializers';
import { DateSelectorTrusstorInput } from '@src/Components/DateSelector/DateSelectorTrusstorInput/DateSelectorTrusstorInput';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { selectProjectId, selectTimezone } from '@store/slices/project.slice';
import { DATE_PICKER_TOGGLE } from '@src/constants';
import { PeriodSelectorTrusstorInput } from '@src/Components/DateSelector/PeriodSelectorTrusstorInput/PeriodSelectorTrusstorInput';
import { analysisCenterReportGeneration_BI } from '@utils/bi.utils';
import { errorSnackbar } from '@utils/snackbar.utils';
import { IUser } from '@shared/interfaces/IUser';
import { selectLoggedUserDetails } from '@store/slices/login.slice';
import { generateInvestigationReport } from '@shared/utils/analysisCenter.utils';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { SingleDropdown } from '@src/Components/Dropdowns/DesignSystem/SingleDropdown/SingleDropdown';

interface IProjectOverviewCardProps {
	isSelected: boolean;
	icon: IconNames;
	title: string;
	description: string;
	name: InvestigationReportTypes;
	setSelectedCard: (name: string) => void;
}

const ProjectOverviewCard = (props: IProjectOverviewCardProps) => {
	const tz: string = useSelector(selectTimezone)!;
	const projectId: string = useSelector(selectProjectId)!;
	const [datePickerMode, setDatePickerMode] = useState<DATE_PICKER_TOGGLE>(DATE_PICKER_TOGGLE.date);
	const today: Date = moment.tz(tz).startOf('day').toDate();
	const [selectedDate, setSelectedDate] = useState(today);
	const [selectedPeriod, setSelectedPeriod] = useState<[Date, Date] | undefined>(undefined);
	const history = useHistory();
	const dispatch = useDispatch();
	const loggedUserDetails: IUser = useSelector(selectLoggedUserDetails)!;

	const generateAndGoToInvestigationReport = async () => {
		try {
			const reportId: string = await generateInvestigationReport(
				requestService,
				translationService,
				loggedUserDetails._id!,
				projectId,
				tz,
				datePickerMode,
				datePickerMode === DATE_PICKER_TOGGLE.date ? selectedDate : selectedPeriod![0],
				datePickerMode === DATE_PICKER_TOGGLE.period ? selectedPeriod![1] : undefined
			);
			analysisCenterReportGeneration_BI(
				InvestigationReportTypes.projectOverview,
				'new',
				datePickerMode === DATE_PICKER_TOGGLE.date ? [selectedDate] : selectedPeriod!
			);
			history.push(`/analysisCenter/${InvestigationReportTypes.projectOverview}/${reportId}`);
		} catch (e) {
			errorSnackbar(dispatch, translationService.get('failedToGenerateReport'));
			console.error('Failed to generate report', e);
		}
	};

	const isGenerateDisabled = () => {
		if (datePickerMode === DATE_PICKER_TOGGLE.date) {
			return !selectedDate;
		} else {
			return !selectedPeriod?.[0] || !selectedPeriod?.[1];
		}
	};

	const handleDatePickerModeChange = (mode: DATE_PICKER_TOGGLE | null) => {
		setDatePickerMode(mode!);
	};

	const handleTimeChange = (date: Date | null) => {
		if (date) {
			setSelectedDate(date);
		}
	};

	const updatePeriod = (period: [Date, Date]) => {
		setSelectedPeriod(period);
	};
	const options = [DATE_PICKER_TOGGLE.period, DATE_PICKER_TOGGLE.date];
	return (
		<ReportCard
			name={props.name}
			isSelected={props.isSelected}
			icon={props.icon}
			title={props.title}
			description={props.description}
			generateReport={generateAndGoToInvestigationReport}
			isGenerateReportDisabled={isGenerateDisabled()}
			setSelectedCard={props.setSelectedCard}
		>
			<>
				<section className={classes.searchLocationContainer}>
					<SingleDropdown
						hideClearTextButton
						getDisplayOption={(option) => translationService.get(option)}
						onChange={handleDatePickerModeChange}
						options={options}
						value={datePickerMode}
					/>
				</section>
				{datePickerMode === DATE_PICKER_TOGGLE.period ? (
					<PeriodSelectorTrusstorInput updatePeriod={updatePeriod} datePeriod={selectedPeriod} />
				) : (
					<DateSelectorTrusstorInput
						selectDefaultDate
						handleTimeChange={handleTimeChange}
						selectedDate={selectedDate}
						maxDate={moment.tz(tz).toDate()}
						calendarPosition={translationService.getIsRtl() ? 'bottom-end' : 'bottom-start'}
					/>
				)}
			</>
		</ReportCard>
	);
};

export { ProjectOverviewCard };
