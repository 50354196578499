import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '@store/slices';
import { getProductivityPeriodicReport, stopGettingProductivityReport } from '@store/thunks';
import { useWorkingProfessions } from '../../../../hooks/useWorkingProfessions.hook';
import { useInvestigationReportQuery } from '@src/hooks/queries/analysisCenter.queries.hooks';
import { IContractorAnalysisReport } from '@shared/interfaces/IContractorAnalysisReport';
import { Loader } from '@shared/components/Loader/Loader';
import { useRenderMonitoring } from '@shared/hooks/monitoring.hooks';
import { ContractorAnalysisHeader } from '@src/Components/Pages/Reports/ProfessionAnalysisReportPageV2/Components/ContractorAnalysisHeader/ContractorAnalysisHeader';
import classes from './styles.module.scss';
import { TagsAnalysisSection } from './Components/TagsAnalysisSection/GraphsSection';
import { updateVisiblePeriod } from '@store/slices/datePicker.slice';
import { IProfession } from '@shared/interfaces/IProfession';
import moment from 'moment';

export const ProfessionAnalysisReportPageV2 = (props: { match }) => {
	useRenderMonitoring('ProfessionAnalysisReportPageV2');
	const dispatch = useDispatch();
	const reportId: string = props.match.params.reportId;
	const tz: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.tz);
	const projectId: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.projectId);
	const [startDate, endDate]: [number, number] = useSelector(
		(state: IRootState) => state.datePickerReducer.visiblePeriodTimestamps
	);

	const { investigationReport: currentReport, isInvestigationReportLoading } =
		useInvestigationReportQuery<IContractorAnalysisReport>(reportId);

	const currentProfession: IProfession | undefined = currentReport?.profession;

	useEffect(() => {
		const previous7Days: [Date, Date] = [moment().subtract(7, 'days').add(1, 'day').toDate(), moment().toDate()];
		updatePeriod(previous7Days);
	}, []);

	const updatePeriod = (period: [Date, Date]) => {
		const periodTimestamps: [number, number] = [period[0].getTime(), period[1].getTime()];
		dispatch(updateVisiblePeriod({ visiblePeriodTimestamps: periodTimestamps }));
	};

	useWorkingProfessions({
		includeDeleted: false,
	});

	useEffect(() => {
		dispatch(getProductivityPeriodicReport(projectId, [startDate, endDate], tz));

		return () => {
			dispatch(stopGettingProductivityReport());
		};
	}, [projectId, startDate, endDate]);

	if (isInvestigationReportLoading) return <Loader />;
	return (
		<div className={classes.wholePageContainer}>
			<ContractorAnalysisHeader profession={currentProfession} />
			<div className={classes.bodyContent}>
				<TagsAnalysisSection currentProfessionId={currentProfession?._id} updatePeriod={updatePeriod} />
			</div>
		</div>
	);
};
