import { useDrag, useDrop } from 'react-dnd';
import { Identifier, type XYCoord } from 'dnd-core';
import { RefObject, useEffect } from 'react';

interface DragItem {
	index: number;
	id: string;
	type: string;
}

interface DragAndDropProps {
	dragAndDropRef: RefObject<HTMLDivElement>;
	dragAndDropPreview?: RefObject<HTMLDivElement>;
	currentIndex: number;
	id: string;
	onDragStart: (dragIndex: number) => void;
	onDragFinish: (dragIndex: number) => void;
	moveElement?: (dragIndex: number, hoverIndex: number) => void;
	dragType: string;
}

export const useDragAndDrop = ({
	dragAndDropRef,
	currentIndex,
	id,
	onDragStart,
	onDragFinish,
	dragType,
	moveElement,
	dragAndDropPreview,
}: DragAndDropProps): { isDragging: boolean; isOver: boolean } => {
	const [{ isOver }, dropRef] = useDrop<DragItem, void, { handlerId: Identifier | null; isOver: boolean }>(
		{
			accept: dragType,
			hover(item: DragItem, monitor) {
				if (!dragAndDropRef?.current) {
					return;
				}
				const dragIndex = item.index;
				const hoverIndex = currentIndex;

				if (dragIndex === hoverIndex) {
					return;
				}

				const hoverBoundingRect = dragAndDropRef.current.getBoundingClientRect();

				const hoverMiddleX: number = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

				const clientOffset: XYCoord | null = monitor.getClientOffset();

				const hoverClientX: number = (clientOffset as XYCoord).x - hoverBoundingRect.left;

				// Only perform the move when the mouse has crossed half of the items height
				// When dragging forwards, only move when the cursor is below 50%
				// When dragging backwords, only move when the cursor is above 50%

				// Dragging forwards
				if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
					return;
				}

				// Dragging backwards
				if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
					return;
				}

				moveElement?.(dragIndex, hoverIndex);
				item.index = hoverIndex;
			},
			collect(monitor) {
				return {
					handlerId: monitor.getHandlerId(),
					isOver: monitor.isOver(),
				};
			},
		},
		[currentIndex, id, dragAndDropRef, dragAndDropPreview]
	);

	const [{ isDragging }, dragRef, dragPreview] = useDrag(
		() => ({
			type: dragType,
			item: () => {
				onDragStart(currentIndex);
				return { id: id, index: currentIndex };
			},
			collect: (monitor) => ({
				isDragging: monitor.isDragging(),
			}),
			end: (item, monitor) => {
				onDragFinish(item.index);
			},
		}),
		[currentIndex, id]
	);

	if (dragAndDropRef.current) {
		dragRef(dragAndDropRef);
	}
	if (dragAndDropPreview && dragAndDropPreview.current) {
		dropRef(dragAndDropPreview);
		dragPreview(dragAndDropPreview);
	} else {
		dropRef(dragAndDropRef);
	}

	return { isOver, isDragging };
};
