import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment-timezone';
import { SingleDropdown } from '@src/Components/Dropdowns/DesignSystem/SingleDropdown/SingleDropdown';
import { translationService } from '@src/servicesInitializers';
import { TrusstorIcon } from '@src/Components/TrusstorIcon/TrusstorIcon';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import classes from '@src/Components/DateSelector/DateAndTimeSelector/styles.module.scss';

interface TimeInputProps {
	date: Date;
	value: string;
	onChange: (value: string) => void;
}

export const TimeInput = ({ date, onChange, value }: TimeInputProps) => {
	const [dateTime, setDateTime] = useState<Date>();

	useEffect(() => {
		if (dateTime && moment(dateTime).isSame(moment(date))) {
			return;
		}
		setDateTime(date);
	}, [date]);

	const timeSlots = useMemo((): Date[] => {
		const startTime: Date = new Date(date);
		startTime.setHours(0, 30, 0, 0);

		const endTime = new Date(date);
		endTime.setHours(23, 30, 0, 0);

		const timeSlots: Date[] = [];

		const currentTime: Date = new Date(startTime);

		while (currentTime <= endTime) {
			const temp: Date = new Date(currentTime);
			timeSlots.push(temp);
			currentTime.setMinutes(currentTime.getMinutes() + 30);
		}

		return timeSlots;
	}, [date]);

	const onTimeChange = (dateChanged: Date | null) => {
		if (!dateChanged) {
			return;
		}
		const formattedTime = moment(dateChanged).format('HH:mm');
		onChange(formattedTime);
	};

	if (date?.getFullYear() === 1970) {
		return null;
	}
	return (
		<SingleDropdown
			hideClearTextButton
			options={timeSlots}
			getDisplayOption={(option: Date) => {
				return option ? moment(option).format(translationService.getTimeFormat()) : '';
			}}
			value={dateTime}
			onChange={(value) => onTimeChange(value)}
			startIcon={<TrusstorIcon iconName={IconNames.clock} />}
			dropdownContainerClassName={classes.timeDropdown}
		/>
	);
};
