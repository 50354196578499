import React, { ReactElement, useState } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { BATTERY_MINIMUM_LEVELS, COLORS, TagStatuses } from '../../constants';
import { LastSeenField } from './LastSennField/LastSeenField';
import { TagsTableRowButtons } from './TagsTableRowButtons';
import { textStyle } from '@shared/utils/text.utils';
import { ITag } from '../../interfaces';
import { translationService } from '../../index';
import { TagCertificateIcon } from './TagCertificateIcon/TagCertificateIcon';
import { AssignTagDialog } from '../AssignTagDialog/AssignTagDialog';
import { TagEntityChip } from '../Chips/TagEntityChip';
import { batteryHigh, batteryMedium, batteryLow, fullSignal, noSignal, sleeping, storage } from '../../assets/tagIcons';
import { TooltipedIcon } from '../Tooltips/TooltipedIcon';
import { getBatteryLevelNumber } from '../../utils/adminPanelTags.utils';
import { IScissorsLiftUtility } from '@interfaces/IScissorsLiftUtility';
import { UtilityTypes } from '@interfaces/UtilityTypes.enum';

const useStyles = makeStyles((theme) => ({
	rowWrapper: {
		'borderBottom': `1px solid ${COLORS.grey100}`,
		'&:hover': {
			backgroundColor: `${COLORS.grey50}`,
		},
		'paddingInlineStart': '12px',
	},
	row: {
		display: 'grid',
		gridTemplateColumns: 'repeat(20 , 1fr)',
		alignItems: 'center',
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
		height: 35,
	},
	nameText: textStyle({
		fontClass: 'body1',
	}),
	tableText: textStyle({
		fontClass: 'body2',
	}),
	headerTextWrapper: {
		minHeight: 20,
		textAlign: 'center',
	},
	dropdownParent: {
		position: 'relative',
	},
	phoneNumber: {
		direction: translationService.getDirection(),
		gridColumn: 'span 2',
	},
	tagIdCell: {
		gridColumn: 'span 2',
	},
	lastSeenCell: {
		gridColumn: 'span 1',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	professionCell: {
		gridColumn: 'span 4',
	},
	fullNameCell: {
		gridColumn: 'span 3',
	},
	idCell: {
		gridColumn: 'span 2',
	},
	phoneNumberCell: {
		gridColumn: 'span 2',
	},
	certificatesCell: {
		gridColumn: 'span 1',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	statusCell: {
		gridColumn: 'span 1',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	batteryCell: {
		gridColumn: 'span 1',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	buttonsCell: {
		'display': 'flex',
		'alignItems': 'center',
		'justifyContent': 'center',
		'gridColumn': '-3/-1',

		'& img': {
			width: '16px',
			height: '16px',
		},
	},
}));

interface ITagRowProps {
	tag: ITag;
}

const getTagPhoneNumber = (tag: ITag): string => {
	if (tag.manager) {
		return tag.manager.phoneNumber || '';
	}
	return tag.phoneNumber;
};

const getTagName = (tag: ITag): string | undefined => {
	if (tag.manager) {
		return tag.manager.name;
	}
	if (tag.linkedUtility?.type === UtilityTypes.scissorsLift) {
		return (tag.linkedUtility as IScissorsLiftUtility).name;
	}
	return tag.name;
};

const TagRow = (props: ITagRowProps) => {
	const classes = useStyles();
	const [buttonsShown, setButtonsShown] = useState(false);
	const [showEditTagDialog, setShowEditTagDialog] = useState<boolean>(false);

	const handleCloseEditTagDialog = (): void => {
		setButtonsShown(false);
		setShowEditTagDialog(false);
	};

	const handleMouseEnter = () => {
		setButtonsShown(true);
	};

	const handleMouseLeave = () => {
		setButtonsShown(false);
	};

	const getBatteryIcon = (batteryLevelNumber: number): string => {
		if (batteryLevelNumber > BATTERY_MINIMUM_LEVELS.high) return batteryHigh;
		if (batteryLevelNumber > BATTERY_MINIMUM_LEVELS.medium) return batteryMedium;
		if (batteryLevelNumber >= BATTERY_MINIMUM_LEVELS.low) return batteryLow;
		return '';
	};

	const getBatteryLevelComponent = (batteryLevel: string): ReactElement => {
		if (!batteryLevel) {
			return <></>;
		}
		const batteryNumber: number = getBatteryLevelNumber(batteryLevel);
		const icon: string = getBatteryIcon(batteryNumber);
		return <TooltipedIcon tooltipText={batteryLevel} children={<img src={icon} alt={'batteryIcon'} />} />;
	};

	const getTagStatusIcon = (status: string): string => {
		if (status === TagStatuses.Good) return fullSignal;
		if (status === TagStatuses.Sleeping) return sleeping;
		if (status === TagStatuses.Storage) return storage;
		if (status === TagStatuses.NoReception) return noSignal;
		return '';
	};

	const getTagStatusComponent = (status: TagStatuses): ReactElement => {
		if (!status) {
			return <></>;
		}
		return (
			<TooltipedIcon
				tooltipText={translationService.get(status)}
				children={<img src={getTagStatusIcon(status)} alt={'statusIcon'} />}
			/>
		);
	};

	return (
		<div className={classes.rowWrapper}>
			<div
				className={classes.row}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				data-testid={'tagRow'}
			>
				<Grid container className={`${classes.headerTextWrapper} ${classes.tagIdCell}`} alignItems="center">
					<p className={classes.nameText} data-testid={'tagNick'}>
						{props.tag.tagNick}
					</p>
				</Grid>
				<Grid
					container
					className={`${classes.headerTextWrapper} ${classes.professionCell}`}
					alignItems="center"
					data-testid={'tagProfessionChip'}
				>
					<TagEntityChip tag={props.tag} />
				</Grid>
				<Grid container className={`${classes.headerTextWrapper} ${classes.fullNameCell}`} alignItems="center">
					<p className={classes.tableText} data-testid={'tagFullName'}>
						{getTagName(props.tag)}
					</p>
				</Grid>
				<Grid container className={`${classes.headerTextWrapper} ${classes.idCell}`} alignItems="center">
					<p className={classes.tableText} data-testid={'tagIdNumber'}>
						{props.tag.idNumber}
					</p>
				</Grid>
				<Grid
					container
					className={`${classes.headerTextWrapper} ${classes.phoneNumberCell}`}
					alignItems="center"
				>
					<p className={`${classes.tableText} ${classes.phoneNumber}`} data-testid={'tagPhoneNumber'}>
						{getTagPhoneNumber(props.tag)}
					</p>
				</Grid>
				<Grid
					container
					className={`${classes.headerTextWrapper} ${classes.certificatesCell}`}
					alignItems="center"
				>
					<p className={`${classes.tableText}`}>
						<TagCertificateIcon
							tag={props.tag}
							openDialog={() => {
								setShowEditTagDialog(true);
							}}
						/>{' '}
					</p>
				</Grid>
				<Grid container className={`${classes.headerTextWrapper} ${classes.statusCell}`} alignItems="center">
					{getTagStatusComponent(props.tag.tagStatus)}
				</Grid>
				<Grid container className={`${classes.headerTextWrapper} ${classes.lastSeenCell}`} alignItems="center">
					{props.tag.lastSeen && (
						<LastSeenField
							lastSeen={props.tag.lastSeen}
							lastSeenFloorNick={props.tag.lastFloorNick}
							lastSeenProjectName={props.tag.lastSeenProjectName}
						/>
					)}
				</Grid>
				<Grid container className={`${classes.headerTextWrapper} ${classes.batteryCell}`} alignItems="center">
					{getBatteryLevelComponent(props.tag.batteryLevel)}
				</Grid>
				<Grid
					container
					className={`${classes.headerTextWrapper} ${classes.buttonsCell}`}
					justify="center"
					alignItems="center"
				>
					{buttonsShown && (
						<TagsTableRowButtons
							tag={props.tag}
							setButtonsShown={setButtonsShown}
							setShowDialog={(show: boolean) => setShowEditTagDialog(show)}
						/>
					)}
				</Grid>
				{showEditTagDialog && <AssignTagDialog tag={props.tag} close={handleCloseEditTagDialog} />}
			</div>
		</div>
	);
};

export { TagRow };
