import React from 'react';
import classes from './stylers.module.scss';
import { IObserveLocationReport } from '@shared/interfaces/IObserveLocationReport';
import { IObserveLocationApiResult } from '@shared/interfaces/IObserveLocationApiResult';
import { Loader } from '@shared/components/Loader/Loader';
import { LocationPersonnel } from './Personnel/LocationPersonnel';
import { LocationVisits } from './Visits/LocationVisits';
import { LocationHistogram } from './Histogram/LocationHistogram';
import { InvestigationReportEmptyPage } from '@src/Components/Pages/AnalysisCenterPages/InvestigationReportEmptyPage/InvestigationReportEmptyPage';
import { translationService } from '@src/index';

interface IObserveLocationDataProps {
	currentReport: IObserveLocationReport;
	observeLocationReport: IObserveLocationApiResult;
}

const ObserveLocationTagsSection = (props: IObserveLocationDataProps) => {
	const shouldShowEmptyState: boolean =
		!props.observeLocationReport?.Personnel ||
		!props.observeLocationReport.Personnel.length ||
		!props.observeLocationReport.visits.histogram;
	if (shouldShowEmptyState) {
		return (
			<InvestigationReportEmptyPage
				clearStyle
				text={translationService.get('observeLocationReportEmptyStateText')}
			/>
		);
	}

	if (!props.observeLocationReport?.Personnel) {
		return <Loader />;
	}
	if (!props.observeLocationReport.Personnel.length) {
		return <InvestigationReportEmptyPage text={translationService.get('personnelEmptyStateText')} />;
	}
	return (
		<section className={classes.locationDataContainer}>
			<section className={classes.topSection}>
				<LocationVisits visits={props.observeLocationReport.visits} />
				<LocationHistogram data={props.observeLocationReport.visits.histogram!} />
			</section>
			<LocationPersonnel observeLocationReport={props.observeLocationReport.Personnel} />
		</section>
	);
};

export { ObserveLocationTagsSection };
