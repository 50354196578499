import React from 'react';
import { Grid } from '@material-ui/core';
import { IFloorData } from '@interfaces/IFloorData';
import { IMergedEquipmentView } from '@interfaces/IMergedEquipmentView';
import { IMinimalSafetyEventForClient } from '@interfaces/ISafetyEvent';
import { SafetyIcon } from '../../Safety/SafetyIcon';
import { safetyOrange, safetyRed } from '@assets/safetyIcons';
import { SpecialFloorBox } from '../SpecialFloorBox/SpecialFloorBox';
import { sortBy } from 'lodash';
import classes from './styles.module.scss';
import { translationService } from '@src/servicesInitializers';

interface ISpecialFloorsContainerProps {
	floorsData: IFloorData[];
	equipment: IMergedEquipmentView[];
	isAdmin: boolean;
	openSafetyDialog: (safetyEvent: IMinimalSafetyEventForClient[]) => void;
}

const SpecialFloorsContainer = (props: ISpecialFloorsContainerProps) => {
	const sortedFloors: IFloorData[] = sortBy(props.floorsData, (floor) => floor.floorNick);
	return (
		<Grid container className={classes.specialFloorsContainer}>
			{!!sortedFloors.length && (
				<>
					<div className={classes.title}>{translationService.get('specialAreas')}</div>
					<div className={classes.rowTitles}>
						<div>{translationService.get('specialAreas_areaName')}</div>
						<div>{translationService.get('specialAreas_resources')} </div>
						<div>{translationService.get('specialAreas_info')} </div>
					</div>
				</>
			)}
			{sortedFloors.map((floorData: IFloorData) => {
				const iconSource: string = floorData.safetyAlerts.some((event) => event.isCritical)
					? safetyRed
					: safetyOrange;
				return (
					<SpecialFloorBox
						floorData={floorData}
						equipmentData={props.equipment.filter((eq) => eq.floorId === floorData.floorId)}
						isAdmin={props.isAdmin}
						key={floorData.floorId}
						showSafetyIcon={floorData.safetyAlerts.length > 0}
						safetyIcon={
							<SafetyIcon
								safetyEvents={floorData.safetyAlerts}
								iconSource={iconSource}
								click={() => props.openSafetyDialog(floorData.safetyAlerts)}
							/>
						}
					/>
				);
			})}
		</Grid>
	);
};

export { SpecialFloorsContainer };
