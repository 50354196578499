import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Grid } from '@material-ui/core';
import classes from './styles.module.scss';
import { ISequenceItemProgressBySequenceItemReport } from '@interfaces/ISequenceItemProgressBySequenceItemReport';
import { ISortingState } from '@interfaces/ISortingState';
import { SORT_ORDER } from '@shared/constants/constants';
import { IRowProperty } from '@interfaces/IRowProperty';
import { TrusstorBox } from '@src/Components/Boxes/Containers/TrusstorBox';
import { TableTitle } from '@src/Components/TableComponents/TableTitle/TableTitle';
import { TableRowTypes } from '@src/constants';
import { MainTableRow } from '@src/Components/TableComponents/TableRow/MainTableRow';
import {
	getActivitiesProgressTableRowProperties,
	getActivitiesProgressTableTitles,
	getSortedReports,
	maxActivitiesProgressTableColumnsWidth,
	minActivitiesProgressTableColumnsWidth,
} from '@src/Components/SequenceProgressAnalysis/ActivitiesProgress/ActivitiesProgressTable/utils';
import { ITableTitleItems } from '@src/interfaces';
import { useSelector } from 'react-redux';
import { selectProjectId } from '@store/slices/project.slice';
import { useIsNarrowLayout } from '@src/hooks/custom.hooks';
import { Loader } from '@shared/components/Loader/Loader';

interface IActivitiesProgressTableProps {
	reports: ISequenceItemProgressBySequenceItemReport[];
	getEmptyStateComponent?: () => JSX.Element;
	showLoader?: boolean;
}

export const ActivitiesProgressTable = (props: IActivitiesProgressTableProps) => {
	const [sortingState, setSortingState] = useState<ISortingState>({
		isSorted: true,
		columnName: 'name',
		sortOrder: SORT_ORDER.ASCENDING,
	});
	const [sortedReports, setSortedReports] = useState<ISequenceItemProgressBySequenceItemReport[]>(props.reports);
	const [isDefaultSort, setIsDefaultSort] = useState<boolean>(true);
	const projectId: string = useSelector(selectProjectId)!;
	const activitiesProgressTableTitles: ITableTitleItems = useMemo(getActivitiesProgressTableTitles, [projectId]);

	const updateSortingState = (newState: Partial<ISortingState>) => {
		setSortingState((prevState) => ({
			...prevState,
			...newState,
		}));
	};

	const sortColumnByOrder = useCallback(() => {
		const sortParameter: string = activitiesProgressTableTitles[sortingState.columnName].title;
		const currentSortOrder: ISortingState['sortOrder'] = sortingState.sortOrder;
		setSortedReports(
			getSortedReports(props.reports, sortParameter, currentSortOrder, activitiesProgressTableTitles)
		);
	}, [sortingState, props.reports, activitiesProgressTableTitles]);

	useEffect(() => {
		if (props.reports.length === 0) {
			setSortedReports([]);
			return;
		}

		if (isDefaultSort) {
			setSortedReports(
				getSortedReports(
					props.reports,
					activitiesProgressTableTitles.simulatedEta.title,
					SORT_ORDER.ASCENDING,
					activitiesProgressTableTitles
				)
			);
			setSortingState({
				isSorted: true,
				columnName: activitiesProgressTableTitles.simulatedEta.title,
				sortOrder: SORT_ORDER.ASCENDING,
			});
			setIsDefaultSort(false);
		} else {
			sortColumnByOrder();
		}
	}, [props.reports, sortColumnByOrder, isDefaultSort]);

	useEffect(() => {
		sortColumnByOrder();
	}, [sortingState, sortColumnByOrder]);

	const rowProperties: IRowProperty[] = useMemo(() => getActivitiesProgressTableRowProperties(), []);
	const isNarrowLayout: boolean = useIsNarrowLayout();
	const columnWidthList: string[] = isNarrowLayout
		? minActivitiesProgressTableColumnsWidth
		: maxActivitiesProgressTableColumnsWidth;

	return (
		<React.Fragment>
			<TrusstorBox customStyleClass={classes.tableWrapper}>
				<Grid container direction="column" className={classes.tableContainer}>
					<Grid container className={classes.listContainer} alignItems="center">
						<div className={classes.headerContainer}>
							<div className={classes.header}>
								{Object.entries(activitiesProgressTableTitles).map(([title, value], index: number) => {
									return (
										<TableTitle
											key={title}
											columnWidth={columnWidthList[index]}
											sortingState={sortingState}
											disableSort={value.disableSort}
											currentColumn={value.title}
											isTextCentered={value.isTextCentered}
											tooltipText={value.tooltipText}
											updateSortingState={updateSortingState}
											boldText
										/>
									);
								})}
							</div>
						</div>

						<div className={classes.rowsContainer}>
							{props.showLoader ? (
								<Loader />
							) : sortedReports.length ? (
								sortedReports.map((report) => {
									return (
										<MainTableRow
											object={report}
											additionalObjects={props.reports}
											type={TableRowTypes.ACTIVITIES_PROGRESS}
											columnWidth={''}
											columnWidthList={columnWidthList}
											rowProperties={rowProperties}
											key={report.sequenceItemId}
										/>
									);
								})
							) : (
								props.getEmptyStateComponent?.()
							)}
						</div>
					</Grid>
				</Grid>
			</TrusstorBox>
		</React.Fragment>
	);
};
