import React from 'react';
import userWithCross from '../../../../../assets/investigationReport/userWithCross.svg';
import classes from './styles.module.scss';

interface IEmptyStateRowProps {
	text: string;
}

export const EmptyStateRow = (props: IEmptyStateRowProps) => {
	return (
		<div className={classes.container}>
			<img src={userWithCross} />
			<p>{props.text}</p>
		</div>
	);
};
