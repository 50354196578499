import { IMultipleDropdownProps } from '../dropdown.interface';
import { useEffect, useRef, useState } from 'react';
import { useOutsideClickWithCallback } from '../../../../hooks/custom.hooks';
import { isEqual } from 'lodash';

export const useMultipleSelectionDropdown = <T>(props: IMultipleDropdownProps<T>) => {
	const [inputValue, setInputValue] = useState<string>('');
	const [filteredOptions, setFilteredOptions] = useState<T[]>(props.options);
	const [selectedOptions, setSelectedOptions] = useState<T[]>(props.value || []);
	const [highlightedIndex, setHighlightedIndex] = useState<number>(-1);
	const dropdownRef = useRef<HTMLDivElement>(null);
	const optionsContainerRef = useRef<HTMLDivElement>(null);
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [isTyping, setIsTyping] = useState(false);
	const prevValue = useRef<T[]>();

	useEffect(() => {
		if (!inputValue) {
			setInputValue('');
		}

		if (!isEqual(props.value, prevValue.current)) {
			setSelectedOptions(props.value || []);
			prevValue.current = props.value;
		}
	}, [props.value]);

	useEffect(() => {
		const filtered: T[] = props.options.filter((option) => {
			const displayOptionText: string =
				typeof props.getDisplayOption(option) === 'string'
					? (props.getDisplayOption(option) as string)
					: props.getInputDisplay?.(option) || '';
			const secondDisplayOptionText: string | undefined = props.getSecondDisplayOption?.(option);
			return (
				displayOptionText.toLowerCase().includes(inputValue?.toLowerCase()) ||
				secondDisplayOptionText?.toLowerCase().includes(inputValue?.toLowerCase())
			);
		});
		setFilteredOptions(filtered);
	}, [inputValue, props.options]);

	useEffect(() => {
		props.onChange(selectedOptions);
	}, [selectedOptions]);

	const handleInputChange = (value: string) => {
		const currentValue: string = getValueForInput();
		const valueDiff: string = value.replace(currentValue, '');
		if ((valueDiff === currentValue || currentValue.length - valueDiff.length === 1) && !isTyping) {
			setSelectedOptions((prev) => {
				const updatedOptions = [...prev];
				updatedOptions.pop();
				if (!updatedOptions.length) {
					setInputValue('');
				}
				return updatedOptions;
			});
			return;
		}
		setIsTyping(true);
		setIsDropdownOpen(true);
		setInputValue(valueDiff);
		setHighlightedIndex(-1);
	};

	const isOptionEqual = (option: T, selectedOption: T) => {
		return props.optionSelectedKey
			? selectedOption[props.optionSelectedKey] === option[props.optionSelectedKey]
			: isEqual(selectedOption, option);
	};

	const getIsSelected = (option: T) =>
		selectedOptions.some((selectedOption) => isOptionEqual(option, selectedOption));

	const handleOptionClick = (option: T) => {
		setSelectedOptions((selectedOptions) => {
			return getIsSelected(option)
				? selectedOptions.filter((selectedOption) => !isOptionEqual(option, selectedOption))
				: [...selectedOptions, option];
		});
		setInputValue('');
		setIsTyping(false);
	};

	const handleOptionHover = (index: number) => {
		setHighlightedIndex(index);
	};

	const handleInputFocusChange = (isFocused: boolean) => {
		if (isFocused) {
			setIsDropdownOpen(true);
		}
	};

	useOutsideClickWithCallback(dropdownRef, () => {
		setHighlightedIndex(-1);
		setIsDropdownOpen(false);
		setIsTyping(false);
		setInputValue('');
	});

	const getValueForInput = () => {
		if (props.totalSelectedInputText && selectedOptions.length > 0) {
			return `${selectedOptions.length} ${props.totalSelectedInputText}`;
		}

		return selectedOptions.map(props.getDisplayOption).join(', ');
	};

	const valueForInput: string = isTyping ? inputValue : getValueForInput();

	const handleClearClick = () => {
		setInputValue('');
		setSelectedOptions([]);
	};

	return {
		selectedOptions,
		setSelectedOptions,
		filteredOptions,
		setFilteredOptions,
		inputValue,
		setInputValue,
		isDropdownOpen,
		setIsDropdownOpen,
		dropdownRef,
		optionsContainerRef,
		setHighlightedIndex,
		handleInputChange,
		handleOptionClick,
		handleOptionHover,
		highlightedIndex,
		handleInputFocusChange,
		valueForInput,
		handleClearClick,
		getIsSelected,
	};
};
