import React from 'react';
import {
	ITrusstorIconButtonStyle,
	TrusstorIconButtonDeprecated,
} from '@shared/components/buttons/TrusstorIconButton/TrusstorIconButtonDeprecated';
import { TrusstorIcon } from '@src/Components/TrusstorIcon/TrusstorIcon';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import classes from './styles.module.scss';
import { IconSize } from '@shared/components/TrusstorIconShared/TrusstorIconShared';
import { TrusstorIconButtonV2 } from '@shared/components/buttons/TrusstorIconButtonV2/TrusstorIconButtonV2';

interface IZoomControllerProps {
	zoomPercentage: number;
	onZoomIn: () => void;
	onZoomOut: () => void;
	onExpand: () => void;
	isExpanded: boolean;
	maxZoom?: number;
	minZoom?: number;
}

export const ZoomController = (props: IZoomControllerProps) => {
	return (
		<div className={classes.container}>
			<div className={classes.zoomContainer}>
				<TrusstorIconButtonV2
					disabled={!!(props.minZoom && props.zoomPercentage <= props.minZoom)}
					onClick={props.onZoomOut}
					style={ITrusstorIconButtonStyle.GHOST}
					iconName={IconNames.minus}
					size={IconSize.SMALL}
				/>
				<div className={classes.zoomNumber}>{props.zoomPercentage}%</div>
				<TrusstorIconButtonV2
					disabled={!!(props.maxZoom && props.zoomPercentage >= props.maxZoom)}
					onClick={props.onZoomIn}
					style={ITrusstorIconButtonStyle.GHOST}
					iconName={IconNames.plus}
					size={IconSize.SMALL}
				/>
			</div>
			<div>
				<TrusstorIconButtonDeprecated
					squaredButton
					onClick={props.onExpand}
					iconElement={<TrusstorIcon iconName={props.isExpanded ? IconNames.minimize : IconNames.expand} />}
				/>
			</div>
		</div>
	);
};
