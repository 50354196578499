import React, { useMemo } from 'react';
import { compact } from 'lodash';
import classes from './styles.module.scss';
import { progressAnalysis } from '@shared/assets/icons';
import { IMilestoneProgressSummary } from '@interfaces/IMilestoneProgressSummary';
import { HealthScoreEnum } from '@shared/interfaces/HealthScoreEnum';
import {
	IMilestoneSummaryFields,
	getSummaryHeaderMessage,
	getMultipleMilestonesSummaryText,
} from './milestonesProgressSummary.utils';
import { IMilestone } from '@shared/interfaces/IMilestone';
import { translationService } from '@src/servicesInitializers';
import { selectTimezone } from '@store/slices/project.slice';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';

interface IMilestonesProgressSummaryProps {
	projectMilestones: IMilestone[];
	milestonesProgressSummary: IMilestoneProgressSummary[];
}

export const MilestonesProgressSummary = (props: IMilestonesProgressSummaryProps) => {
	const tz: string = useSelector(selectTimezone)!;
	const futureMilestones: IMilestone[] = useMemo(
		() =>
			props.projectMilestones.filter((milestone) =>
				moment.tz(milestone.endDate, tz).isAfter(moment.tz(new Date(), tz), 'day')
			),
		[props.projectMilestones, tz]
	);
	const futureMilestonesSummaries: IMilestoneProgressSummary[] = props.milestonesProgressSummary.filter((milestone) =>
		futureMilestones.find((futureMilestone) => futureMilestone._id === milestone.milestoneId)
	);

	const majorRiskMilestonesSummaries: IMilestoneProgressSummary[] = futureMilestonesSummaries.filter(
		(milestone) => milestone.healthScore === HealthScoreEnum.MAJOR_RISK
	);

	const minorRiskMilestonesSummaries: IMilestoneProgressSummary[] = futureMilestonesSummaries.filter(
		(milestone) => milestone.healthScore === HealthScoreEnum.MINOR_RISK
	);

	const areAllMilestonesHealty: boolean =
		majorRiskMilestonesSummaries.length === 0 && minorRiskMilestonesSummaries.length === 0;

	const displayedHealthScore: HealthScoreEnum = majorRiskMilestonesSummaries.length
		? HealthScoreEnum.MAJOR_RISK
		: HealthScoreEnum.MINOR_RISK;
	const summaryRelevantFields: IMilestoneSummaryFields[] = compact(
		majorRiskMilestonesSummaries.map((milestoneSummary: IMilestoneProgressSummary) => {
			const milestone: IMilestone | undefined = props.projectMilestones.find(
				(milestone) => milestone._id === milestoneSummary.milestoneId
			);
			if (!milestone) {
				return undefined;
			}
			return {
				title: milestone.title,
				dueDate: milestone.endDate,
				sequenceItems: majorRiskMilestonesSummaries.length
					? milestoneSummary.sequenceItemsAtMajorRisk
					: milestoneSummary.sequenceItemsAtMinorRisk,
			};
		})
	);

	const milestoneSummaryTexts: string[] = getMultipleMilestonesSummaryText(summaryRelevantFields);
	const headerMessage: string = `${getSummaryHeaderMessage(summaryRelevantFields, displayedHealthScore)}${
		summaryRelevantFields.length > 1 ? '' : ` ${milestoneSummaryTexts[0]}`
	}`;
	const headerMessageSplitByExclamationMark: string[] = headerMessage.split('!');

	return (
		<div className={classes.milestonesProgressSummaryContainer}>
			<div className={classes.secondaryContainer}>
				<div className={classes.summaryContainer}>
					<img className={classes.image} src={progressAnalysis} />
					<p className={classes.summaryText}>{translationService.get('summary')}</p>
				</div>

				{areAllMilestonesHealty ? (
					<p className={classes.milestonesProgressSummaryText}>
						{translationService.get('progressAnalysis_allMilestonesAreOnTrack')}
					</p>
				) : (
					<div className={classes.textsContainer}>
						<p className={classes.summaryHeaderText}>
							{headerMessageSplitByExclamationMark.length > 1 && (
								<span className={classes.summaryHeaderTextBold}>
									{headerMessageSplitByExclamationMark[0]}!
								</span>
							)}
							<span>
								{headerMessageSplitByExclamationMark[headerMessageSplitByExclamationMark.length - 1]}
							</span>
						</p>
						{summaryRelevantFields.length > 1 &&
							milestoneSummaryTexts.map((summaryText) => (
								<p key={summaryText} className={classes.milestonesProgressSummaryText}>
									{summaryText}
								</p>
							))}
					</div>
				)}
			</div>
		</div>
	);
};
