import React from 'react';
import { useHistory } from 'react-router-dom';
import classes from './styles.module.scss';
import { IconNames } from '../TrusstorIconShared/IconNames.enum';
import { ITrusstorButtonType, TrusstorButton } from '../buttons/TrusstorButton/TrusstorButton';
import { useSharedServices } from '../../hooks/sharedServices.context';
import { TrusstorIconShared } from '../TrusstorIconShared/TrusstorIconShared';
import IssuesEmptyStateIcon from '../../assets/icons/EmptyStateIcons/issuesEmptyStateIcon.svg';
import PersonnelEmptyStateIcon from '../../assets/icons/EmptyStateIcons/personnelEmptyState.svg';
import ActivitiesEmptyStateIcon from '../../assets/icons/EmptyStateIcons/activitiesEmptyStateIcon.svg';
import SafetyAlertsEmptyStateIcon from '../../assets/icons/EmptyStateIcons/safetyEventsEmptyStateIcon.svg';

export enum EmptyStateResourcesEnum {
	issues = 'issues',
	personnel = 'personnel',
	activities = 'activities',
	alerts = 'alerts',
}
interface IDashboardTabEmptyStateProps {
	resource: EmptyStateResourcesEnum;
	createLink?: string;
}

export const DashboardTabEmptyState = (props: IDashboardTabEmptyStateProps) => {
	const { translationService } = useSharedServices();
	const history = useHistory();

	const getImageSource = () => {
		switch (props.resource) {
			case 'issues':
				return IssuesEmptyStateIcon;
			case 'personnel':
				return PersonnelEmptyStateIcon;
			case 'activities':
				return ActivitiesEmptyStateIcon;
			case 'alerts':
				return SafetyAlertsEmptyStateIcon;
			default:
				return '';
		}
	};

	const getTitle = () => {
		switch (props.resource) {
			case 'issues':
				return translationService.get('dashboardIssuesEmptyStateText');
			case 'personnel':
				return translationService.get('dashboardPersonnelEmptyStateText');
			case 'activities':
				return translationService.get('dashboardActivitiesEmptyStateText');
			case 'alerts':
				return translationService.get('dashboardSafetyEventsEmptyStateText');
			default:
				return '';
		}
	};
	return (
		<div className={classes.DashboardTabEmptyStateContainer}>
			<img className={classes.img} src={getImageSource()} alt={getTitle()} />
			<div className={classes.DashboardTabEmptyStateTitle}>{getTitle()}</div>
			{props.createLink && (
				<TrusstorButton
					text={translationService.get('create')}
					buttonType={ITrusstorButtonType.OUTLINE}
					handleClick={() => history.push(props.createLink!)}
					iconElement={<TrusstorIconShared iconName={IconNames.plus} />}
				/>
			)}
		</div>
	);
};
