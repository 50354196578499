import React, { useEffect, useRef, useState } from 'react';
import { useOutsideClickWithCallback } from '../../../../hooks/custom.hooks';
import { handleDropdownKeyDown, scrollIntoView } from '../../../../utils/dropdown.utils';
import { SingleDropdownProps } from '../dropdown.interface';
import { isEqual } from 'lodash';

interface sideEffectsOptions {
	outsideClickEffect?: () => void;
}

export const useSingleSelectionDropdown = <T>(
	props: SingleDropdownProps<T>,
	sideEffectOptions?: sideEffectsOptions
) => {
	const [inputValue, setInputValue] = useState<string>('');
	const [filteredOptions, setFilteredOptions] = useState<T[]>(props.options);
	const [selectedOption, setSelectedOption] = useState<T | undefined>(props.value);
	const [highlightedIndex, setHighlightedIndex] = useState<number>(-1);
	const dropdownRef = useRef<HTMLDivElement>(null);
	const optionsContainerRef = useRef<HTMLDivElement>(null);
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);

	useEffect(() => {
		if (selectedOption !== props.value) {
			setSelectedOption(props.value);
		}
	}, [props.value]);

	useEffect(() => {
		props.onChange(selectedOption || null);
	}, [selectedOption]);

	useEffect(() => {
		const filtered: T[] = props.options.filter((option) => {
			const displayOptionText: string =
				typeof props.getDisplayOption(option) === 'string'
					? (props.getDisplayOption(option) as string)
					: props.getInputDisplay?.(option) || '';
			const secondDisplayOptionText: string | undefined = props.getSecondDisplayOption?.(option);
			return (
				displayOptionText.toLowerCase().includes(inputValue?.toLowerCase()) ||
				secondDisplayOptionText?.toLowerCase().includes(inputValue?.toLowerCase())
			);
		});
		setFilteredOptions(filtered);
	}, [inputValue, props.options]);

	const handleInputChange = (value: string) => {
		setIsDropdownOpen(true);
		setInputValue(value);
		setSelectedOption(undefined);
		setHighlightedIndex(-1);
	};

	useOutsideClickWithCallback(dropdownRef, () => {
		setHighlightedIndex(-1);
		setIsDropdownOpen(false);
		sideEffectOptions?.outsideClickEffect?.();
	});

	const handleOptionClick = (option: any) => {
		setSelectedOption(option);
		setIsDropdownOpen(false);
	};

	const handleOptionHover = (optionIndex: number) => {
		setHighlightedIndex(optionIndex);
	};

	const handleInputFocusChange = (isFocused: boolean) => {
		if (!isFocused && !selectedOption && filteredOptions.length === 1) {
			setSelectedOption(filteredOptions[0]);
		}
		if (isFocused) {
			setIsDropdownOpen(true);
		}
	};

	const valueForInput: string = selectedOption
		? props.getInputDisplay
			? props.getInputDisplay(selectedOption)
			: (props.getDisplayOption(selectedOption) as string)
		: inputValue;

	const isSelected = (option: T) => isEqual(selectedOption, option);

	return {
		selectedOption,
		setSelectedOption,
		filteredOptions,
		setFilteredOptions,
		inputValue,
		setInputValue,
		isDropdownOpen,
		setIsDropdownOpen,
		dropdownRef,
		optionsContainerRef,
		setHighlightedIndex,
		handleInputChange,
		handleOptionClick,
		handleOptionHover,
		highlightedIndex,
		handleInputFocusChange,
		valueForInput,
		isSelected,
	};
};

export const useDropdownKeyboardNavigation = <T>(
	highlightedIndex: number,
	setHighlightedIndex: React.Dispatch<React.SetStateAction<number>>,
	optionContainerRef: React.RefObject<HTMLDivElement>,
	filteredOptions: T[],
	handleOptionClick: (option: T) => void,
	shouldResetHighlightedIndexOnEnter: boolean
) => {
	return (event: React.KeyboardEvent) => {
		handleDropdownKeyDown(event, {
			arrowDownCallback: () => {
				if (highlightedIndex < filteredOptions.length - 1) {
					setHighlightedIndex((prevIndex) => {
						const updatedIndex = prevIndex + 1;
						scrollIntoView(optionContainerRef, updatedIndex);
						return updatedIndex;
					});
				}
			},
			arrowUpCallback: () => {
				if (highlightedIndex > 0) {
					setHighlightedIndex((prevIndex) => {
						const updatedIndex = prevIndex - 1;
						scrollIntoView(optionContainerRef, updatedIndex);
						return updatedIndex;
					});
				}
			},
			enterCallback: () => {
				if (highlightedIndex !== -1) {
					event.stopPropagation();
					handleOptionClick(filteredOptions[highlightedIndex]);
					if (shouldResetHighlightedIndexOnEnter) {
						setHighlightedIndex(-1);
					}
				}
			},
		});
	};
};
