import React, { useEffect } from 'react';
import { MilestonesPageHeader } from '@src/Components/MilestonesPageHeader/MilestonesPageHeader';
import { useProjectMilestonesQuery } from '@src/hooks/queries/milestones.queries.hooks';
import classes from './styles.module.scss';
import { selectProjectId } from '@store/slices/project.slice';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { MilestoneInnerPageHeader } from '@src/Components/MilestoneInnerPageHeader/MilestoneInnerPageHeader';
import { SequenceSelector } from '@src/Components/SequenceSelector/SequenceSelector';
import { useSequenceItemsByProjectQuery } from '@src/hooks/queries/sequenceItem.query';
import { ISequenceItem } from '@interfaces/ISequenceItem';
import { SequenceLinkTableContainer } from '@src/Components/SequenceItemLinkTable/SequenceLinkTableContainer/SequenceLinkTableContainer';
import { useProjectSequencesQuery } from '@src/hooks/queries/sequences.hooks';
import { NoSequencesEmptyState } from '@src/Components/NoSequencesEmptyState/NoSequencesEmptyState';
import { Loader } from '@shared/components/Loader/Loader';

export const MilestonesInnerPage = () => {
	const projectId: string = useSelector(selectProjectId)!;
	const history = useHistory();
	const currentMilestoneId: string | undefined = history.location.pathname.split('/').pop();
	const { projectMilestones } = useProjectMilestonesQuery(projectId);
	const { sequences, isFetched } = useProjectSequencesQuery(projectId);
	const { sequenceItems } = useSequenceItemsByProjectQuery(projectId);
	const [selectedSequenceId, setSelectedSequenceId] = React.useState<string | null>(null);

	useEffect(() => {
		setSelectedSequenceId(sequences[0]?._id);
	}, [sequences.length]);

	const linkedSequenceItems: ISequenceItem[] = sequenceItems.filter(
		(sequenceItem) => sequenceItem.linkedMilestoneId === currentMilestoneId
	);

	const createButtonClick = () => {
		history.push(`/${projectId}/system/milestones/create`);
	};

	if (!currentMilestoneId) {
		return null;
	}

	const selectSequence = (sequenceId: string) => {
		setSelectedSequenceId(sequenceId);
	};

	const isEmptyState: boolean = !sequences.length;

	return (
		<div className={classes.page}>
			<MilestonesPageHeader milestonesAmount={projectMilestones.length} createButtonClick={createButtonClick} />
			<div className={classes.content}>
				<MilestoneInnerPageHeader
					currentMilestoneId={currentMilestoneId}
					linkedSequenceItems={linkedSequenceItems}
				/>
				<div className={classes.bottomContent}>
					{!isFetched ? (
						<Loader />
					) : isEmptyState ? (
						<NoSequencesEmptyState />
					) : (
						<>
							<SequenceSelector
								currentMilestoneId={currentMilestoneId}
								selectedSequenceId={selectedSequenceId}
								selectSequence={selectSequence}
							/>
							{!!selectedSequenceId && (
								<SequenceLinkTableContainer
									selectedSequenceId={selectedSequenceId}
									currentMilestoneId={currentMilestoneId}
								/>
							)}
						</>
					)}
				</div>
			</div>
		</div>
	);
};
