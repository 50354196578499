import React from 'react';
import { GroupedDropdownDisplay } from '../../Displays/GroupedDropdownDisplay/GroupedDropdownDisplay';
import { useSingleSelectionDropdown } from '../singleSelectionDropdown.utils';
import { useGroupDropdown, useGroupDropdownKeyboardNavigation } from '../../groupDropdown.utils';
import { IGroupedDropdownSharedProps, SingleDropdownProps } from '../../dropdown.interface';

export interface IGroupedSingleDropdownProps<T> extends SingleDropdownProps<T>, IGroupedDropdownSharedProps<T> {}

function GroupedSingleDropdownShared<T>(props: IGroupedSingleDropdownProps<T>) {
	const {
		filteredOptions,
		isDropdownOpen,
		dropdownRef,
		optionsContainerRef,
		highlightedIndex,
		setHighlightedIndex,
		handleInputChange,
		handleOptionClick,
		handleOptionHover,
		setIsDropdownOpen,
		handleInputFocusChange,
		valueForInput,
		isSelected,
	} = useSingleSelectionDropdown<T>(props, {
		outsideClickEffect: () => {
			setHighlightedGroupIndex(0);
		},
	});
	const {
		groupedOptions,
		highlightedGroupIndex,
		setHighlightedGroupIndex,
		topLevelGroups,
		sortedGroupKeys,
		handleOptionHoverCallback,
		handleInputChangeCallback,
	} = useGroupDropdown<T>(props, filteredOptions, handleInputChange, handleOptionHover);
	const handleKeyDown = useGroupDropdownKeyboardNavigation(
		highlightedIndex,
		setHighlightedIndex,
		highlightedGroupIndex,
		setHighlightedGroupIndex,
		optionsContainerRef,
		sortedGroupKeys,
		groupedOptions,
		handleOptionClick,
		isSelected
	);

	return (
		<GroupedDropdownDisplay
			groupedOptions={groupedOptions}
			sortedGroupKeys={sortedGroupKeys}
			errorText={props.errorText}
			isError={props.isError}
			testId={props.testId}
			dropdownRef={dropdownRef}
			optionsContainerRef={optionsContainerRef}
			isDropdownOpen={isDropdownOpen}
			handleInputChange={handleInputChangeCallback}
			valueForInput={valueForInput}
			placeholder={props.placeholder}
			label={props.label}
			disabled={props.disabled}
			required={props.required}
			handleInputFocusChange={handleInputFocusChange}
			highlightedIndex={highlightedIndex}
			highlightedGroupIndex={highlightedGroupIndex}
			setIsDropdownOpen={setIsDropdownOpen}
			handleKeyDown={handleKeyDown}
			handleOptionClick={handleOptionClick}
			handleOptionHover={handleOptionHoverCallback}
			getDisplayOption={props.getDisplayOption}
			getSecondDisplayOption={props.getSecondDisplayOption}
			getIsSelected={isSelected}
			hideClearTextButton={props.hideClearTextButton}
			inputRef={props.inputRef}
			getGroupDisplayOption={props.getGroupDisplayOption}
			topLevelGroups={topLevelGroups}
			inputContainerClassName={props.inputContainerClassName}
		/>
	);
}

export { GroupedSingleDropdownShared };
