import React, { ChangeEvent, useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { translationService } from '../../index';
import { GeneralDialog } from '../GeneralDialog/GeneralDialog';
import { textStyle } from '@shared/utils/text.utils';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '@store/slices';
import { IBaseManager, IManager, IProject, IRequestStatus } from '../../interfaces';
import { isValidName } from '@shared/utils/validation.util';
import { createManager, updateManager } from '@store/thunks';
import { ITrade } from '../../interfaces/ITrade';
import { HTTPRequestStatuses } from '@shared/interfaces/HTTPRequestStatuses.enum';
import { showSnackbar } from '@store/slices/snackbar.slice';
import { resetCreateManagerStatus, resetUpdateManagerStatus } from '../../store/slices/managers.slice';
import { COLORS, HttpStatusCode } from '../../constants';
import { PhoneInputField } from '../Inputs/PhoneInputField/PhoneInputField';
import { managerCreate_BI, managerUpdate_BI } from '@utils/bi.utils';
import { TrusstorTextInput } from '@shared/components/Inputs/TrusstorTextInput/TrusstorTextInput';
import { getTradeTranslation } from '@utils/translations.utils';
import { SingleDropdown } from '@src/Components/Dropdowns/DesignSystem/SingleDropdown/SingleDropdown';

const useStyles = makeStyles((theme: Theme) => ({
	'selectContainer': {
		width: '100%',
	},
	'dialogItem': {
		width: '100%',
	},
	'descriptionContainer': {
		color: COLORS.black,
	},
	'inputRootClass': {
		backgroundColor: COLORS.white,
		borderRadius: 3,
		boxShadow: '0px 2px 0 0 rgba(0, 0, 0, 0.1)',
	},
	'descriptionText': {
		color: '#000000 !important',
	},
	'shrinkLabel': {
		color: '#b3b2b2 !important',
	},
	'errorWrapper': {
		height: 15,
		marginTop: theme.spacing(0.5),
	},
	'errorText': textStyle({
		fontClass: 'body1',
		color: COLORS.red400,
	}),
	'containerError': {},
	'receiveSmsContainer': {
		'display': 'flex',
		'alignItems': 'center',
		'fontSize': 12,

		'.MuiCheckbox-colorPrimary.Mui-checked': {
			color: COLORS.primaryColor + '!important',
		},
		'& :nth-child(1)': {
			padding: '2px !important',
		},
	},
	'@global': {
		'.MuiCheckbox-colorPrimary.Mui-checked': {
			color: COLORS.primaryColor + '!important',
		},
	},
}));

interface AddManagerProps {
	show: boolean;
	close: () => void;
	manager?: IManager;
}

interface IValidation {
	[fieldName: string]: boolean;
}

interface INewManager {
	trade: ITrade;
	name: string;
	phoneNumber?: string;
	receiveNotifications: boolean;
}

const CreateManagerDialog = (props: AddManagerProps) => {
	const [newManager, setNewManager] = useState<Partial<INewManager>>({});
	const [countryCode, setCountryCode] = useState<string>('');
	const [isValidPhoneNumber, setIsValidPhoneNumber] = useState<boolean>(true);
	const [showPhoneNumberError, shouldShowPhoneNumberError] = useState<boolean>(false);
	const [validations, setValidations] = useState<IValidation>({});
	const project: IProject = useSelector((state: IRootState) => state.projectReducer.workingProject!);
	const managementTrades: ITrade[] = useSelector(
		(state: IRootState) => state.managementTradesReducer.managementTrades
	);
	const createManagerStatus: IRequestStatus = useSelector(
		(state: IRootState) => state.managersReducer.createManagerStatus
	);
	const updateManagerStatus: IRequestStatus = useSelector(
		(state: IRootState) => state.managersReducer.updateManagerStatus
	);
	const isEditMode: boolean = !!props.manager;
	const isPhoneNumberFilled: boolean =
		!!newManager && !!newManager.phoneNumber && newManager.phoneNumber.length > countryCode.length;

	const dispatch = useDispatch();
	const classes = useStyles();

	useEffect(() => {
		if (props.manager) {
			setNewManager({
				trade: {
					tradeGroup: props.manager.tradeGroup,
					tradeId: props.manager.tradeId,
					backgroundColor: props.manager.backgroundColor,
					sortIndex: props.manager.sortIndex,
				},
				name: props.manager.name,
				phoneNumber: props.manager.phoneNumber,
				receiveNotifications: props.manager.receiveNotifications,
			});
		}
	}, [props.manager?.name, props.manager?.phoneNumber, props.manager?.tradeId, props.manager?.receiveNotifications]);

	useEffect(() => {
		if (createManagerStatus.status === HTTPRequestStatuses.success) {
			props.close();
			resetComponentState();
			dispatch(resetCreateManagerStatus());
		}
		if (createManagerStatus.status === HTTPRequestStatuses.failed) {
			dispatch(
				showSnackbar({
					message:
						createManagerStatus.code === HttpStatusCode.conflict
							? translationService.get('createManagerDuplicatedError')
							: translationService.get('generalSnackbarError'),
					vertical: 'top',
					horizontal: 'center',
					backgroundColor: COLORS.white,
					color: '#CF3126',
					id: 'professionDialogSnackbar',
				})
			);
			dispatch(resetCreateManagerStatus());
		}
	}, [createManagerStatus.status, createManagerStatus.code]);

	useEffect(() => {
		if (updateManagerStatus.status === HTTPRequestStatuses.success) {
			props.close();
			resetComponentState();
			dispatch(resetUpdateManagerStatus());
		}
		if (updateManagerStatus.status === HTTPRequestStatuses.failed) {
			dispatch(
				showSnackbar({
					message: translationService.get('generalSnackbarError'),
					vertical: 'top',
					horizontal: 'center',
					backgroundColor: COLORS.white,
					color: '#CF3126',
					id: 'professionDialogSnackbar',
				})
			);
			dispatch(resetUpdateManagerStatus());
		}
	}, [updateManagerStatus.status, updateManagerStatus.code]);

	const validateFormFields = (manager: Partial<INewManager>, checkOnlyValidation: boolean = false): boolean => {
		let isManagerValid = true;
		if (manager.name && !isValidName(manager.name)) {
			isManagerValid = false;
			!checkOnlyValidation && setValidations((prevState) => ({ ...prevState, validName: true }));
		}
		if (!manager.name) {
			isManagerValid = false;
			!checkOnlyValidation && setValidations((prevState) => ({ ...prevState, nameExists: true }));
		}
		if (manager.phoneNumber) {
			if (!isValidPhoneNumber && isPhoneNumberFilled) {
				!checkOnlyValidation && shouldShowPhoneNumberError(true);
				isManagerValid = false;
				!checkOnlyValidation && setValidations((prevState) => ({ ...prevState, phoneNumber: true }));
			}
		}
		if (!manager.trade) {
			isManagerValid = false;
			!checkOnlyValidation && setValidations((prevState) => ({ ...prevState, trade: true }));
		}
		return isManagerValid;
	};

	const checkIfButtonDisabled = (newManager: Partial<INewManager>): boolean => {
		if (isEditMode) return false;
		if (Object.keys(newManager).length === 0) return true;
		return !validateFormFields(newManager, true);
	};

	const resetComponentState = (): void => {
		setValidations({});
		setNewManager({});
	};

	const handleSubmitButtonClick = (): void => {
		const areFormFieldsValid = validateFormFields(newManager);
		if (areFormFieldsValid) {
			isEditMode ? handleUpdateManager() : handleCreateManager();
		}
	};

	const handleUpdateManager = (): void => {
		const updatedManager: IManager = {
			...props.manager!,
		};
		if (isPhoneNumberFilled) {
			updatedManager.receiveNotifications = newManager.receiveNotifications;
			updatedManager.phoneNumber = newManager.phoneNumber;
		} else {
			updatedManager.receiveNotifications = false;
			updatedManager.phoneNumber = '';
		}
		dispatch(updateManager(updatedManager));
		managerUpdate_BI(props.manager!, updatedManager);
	};

	const handleCreateManager = () => {
		const fullManager: IBaseManager = {
			name: newManager.name!,
			...newManager.trade!,
			projectId: project.projectId,
			organizationId: project.organizationId,
		};
		if (isPhoneNumberFilled) {
			fullManager.receiveNotifications = newManager.receiveNotifications;
			fullManager.phoneNumber = newManager.phoneNumber;
		} else {
			fullManager.receiveNotifications = false;
			fullManager.phoneNumber = '';
		}
		dispatch(createManager(fullManager));
		managerCreate_BI(fullManager);
	};

	const handleReceiveSmsChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const isChecked: boolean = event.target.checked;
		setNewManager((prevState) => ({
			...prevState,
			receiveNotifications: isChecked,
		}));
	};

	const validateNameField = (
		event: ChangeEvent<{ name?: string | undefined; value: any }>,
		field: string,
		value: string
	): void => {
		if (!value) {
			return setValidations((prevState) => ({
				...prevState,
				nameExists: true,
			}));
		}
		if (isValidName(value)) {
			setValidations((prevState) => ({ ...prevState, [field]: false }));
		} else {
			setValidations((prevState) => ({ ...prevState, [field]: true }));
		}
		setValidations((prevState) => ({ ...prevState, nameExists: false }));
	};

	const validateFieldAndUpdateState = (event: ChangeEvent<{ name?: string | undefined; value: any }>): void => {
		if (event.target.value) {
			setValidations((prevState) => ({
				...prevState,
				[event.target.name!]: false,
			}));
		}
		if (event.target.name === 'name') {
			validateNameField(event, 'validName', event.target.value);
			if (!isValidName(event.target.value)) {
				if (event.target.value.length === 0) {
					setNewManager((prevState) => ({
						...prevState,
						[event.target.name!]: event.target.value,
					}));
				}
				return;
			}
		}
		setNewManager((prevState) => ({
			...prevState,
			[event.target.name!]: event.target.value,
		}));
	};

	const updateTrade = (trade: ITrade | null): void => {
		if (!trade) return;
		if (trade) {
			setValidations((prevState) => ({ ...prevState, trade: false }));
		}
		setNewManager((prevState) => ({ ...prevState, trade: trade }));
	};

	const shouldCheckboxBeChecked = (btnDisabled: boolean, receiveNotification: boolean | undefined): boolean => {
		if (btnDisabled) return false;
		if (!btnDisabled && !receiveNotification) return false;
		return !btnDisabled && !!receiveNotification;
	};

	const updatePhoneNumber = (value: string): void => {
		setValidations((prevState) => ({
			...prevState,
			phoneNumber: isValidPhoneNumber,
		}));
		setNewManager((prevState) => ({ ...prevState, phoneNumber: value }));
	};

	const isReceiveSmsOptionDisabled: boolean = !isValidPhoneNumber;

	return (
		<GeneralDialog
			show={props.show}
			close={() => {
				props.close();
				resetComponentState();
			}}
			mainButton={{
				text: isEditMode ? translationService.get('update') : translationService.get('create'),
				click: handleSubmitButtonClick,
				disabled: checkIfButtonDisabled(newManager),
			}}
			title={isEditMode ? translationService.get('editManagerProfile') : translationService.get('addManager')}
			rootStyle={{ height: 420, width: 536, overflow: 'unset' }}
		>
			<Grid container direction="column" spacing={1}>
				<Grid item className={classes.dialogItem}>
					<SingleDropdown
						getDisplayOption={(trade: ITrade) => getTradeTranslation(trade)}
						value={props.manager || newManager?.trade}
						options={managementTrades}
						disabled={isEditMode}
						isError={!!validations.trade}
						onChange={updateTrade}
						errorText={translationService.get('managerTradeErrorMassage')}
						label={`${translationService.get('managerRole')}`}
					/>
				</Grid>
				<Grid item className={classes.dialogItem}>
					<TrusstorTextInput
						name="name"
						value={props.manager?.name || newManager?.name || ''}
						disabled={isEditMode}
						label={`${translationService.get('fullName')}`}
						sendFullEvent
						changeFunc={validateFieldAndUpdateState}
						isError={!!validations.nameExists || !!validations.validName}
						errorText={
							validations.nameExists
								? translationService.get('managerNameErrorMassage')
								: translationService.get('managerValidNameErrorMassage')
						}
					/>
				</Grid>
				<Grid item className={classes.dialogItem}>
					<PhoneInputField
						setIsValidPhoneNumber={setIsValidPhoneNumber}
						phoneNumber={newManager?.phoneNumber || props.manager?.phoneNumber || ''}
						displayError={!isValidPhoneNumber && showPhoneNumberError}
						onChangeFunction={updatePhoneNumber}
						setCountryCode={setCountryCode}
					/>
				</Grid>
			</Grid>
		</GeneralDialog>
	);
};

export { CreateManagerDialog };
