import { useQuery } from '@tanstack/react-query';
import { requestService } from '@src/servicesInitializers';
import { QueryKeys } from '@shared/constants/QueryKeys.enum';
import { IAreaSequenceItem } from '@shared/interfaces/IAreaSequenceItem';
import { SequenceTypeEnum } from '@shared/interfaces/SequenceType.enum';
import { selectProjectId } from '@store/slices/project.slice';
import { useSelector } from 'react-redux';
import { AreaTypeEnum } from '@shared/interfaces/AreaType.enum';

export const useAreaSequenceItemsBySequenceItem = (sequenceItemId: string) => {
	const {
		data: asis,
		isLoading,
		refetch: refetchAsis,
	} = useQuery<IAreaSequenceItem[] | undefined>(
		[QueryKeys.areaSequenceItemsBySequenceItemId, sequenceItemId],
		() =>
			requestService.get(`/activities/sequenceItems/areaSequenceItem/bySequenceItem`, {
				params: { sequenceItemId },
			}),
		{
			useErrorBoundary: true,
			initialData: undefined,
		}
	);
	return { asis, isLoading, refetchAreas: refetchAsis };
};

export const useAreaSequenceItemsCountBySequenceType = (
	sequenceType?: SequenceTypeEnum | AreaTypeEnum
): {
	asisCount: number | undefined;
	isInitialLoading: boolean;
} => {
	if (!sequenceType) {
		return { asisCount: undefined, isInitialLoading: false };
	}

	const projectId: string = useSelector(selectProjectId)!;
	const { data, isInitialLoading } = useQuery<{ count: number } | undefined>(
		[QueryKeys.areaSequenceItemsCountBySequenceType, sequenceType, projectId],
		() =>
			requestService.get(`/activities/sequenceItems/areaSequenceItem/count/bySequenceType`, {
				params: { sequenceType, projectId },
			}),
		{
			useErrorBoundary: true,
			initialData: undefined,
		}
	);
	return { asisCount: data?.count, isInitialLoading };
};

export const useAreaSequenceItemsCountByFloorId = (
	floorId: string
): {
	asisCount: number | undefined;
	isInitialLoading: boolean;
} => {
	const projectId: string = useSelector(selectProjectId)!;
	const { data, isInitialLoading } = useQuery<{ count: number } | undefined>(
		[QueryKeys.areaSequenceItemsCountByFloorId, floorId, projectId],
		() =>
			requestService.get(`/activities/sequenceItems/areaSequenceItem/count/byFloorId`, {
				params: { floorId, projectId },
			}),
		{
			useErrorBoundary: true,
			initialData: undefined,
		}
	);
	return { asisCount: data?.count, isInitialLoading };
};
