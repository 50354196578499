import React from 'react';
import classes from './styles.module.scss';

interface IHeaderBoxProps {
	title: string;
	children: React.ReactNode;
	secondarySection?: React.ReactElement;
	customRootStyles?: any;
	customHeaderClass?: string;
	boldTitle?: boolean;
}

const HeaderBox = ({
	title,
	customRootStyles,
	customHeaderClass,
	children,
	secondarySection,
	boldTitle = false,
}: IHeaderBoxProps): JSX.Element => {
	return (
		<div className={`${classes.container}`} style={customRootStyles}>
			<div className={`${classes.header} ${customHeaderClass}`}>
				<p className={`${classes.headerText} ${boldTitle && classes.boldTitle}`}>{title}</p>
				{secondarySection}
			</div>
			{children}
		</div>
	);
};

export { HeaderBox };
