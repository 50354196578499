import { ISequence } from '@shared/interfaces/ISequence';
import { accessLevelService, requestService, translationService } from '@src/servicesInitializers';
import classes from './styles.module.scss';
import React, { useContext, useState } from 'react';
import { ArrowUpDown } from '@shared/components/ArrowUpDown/ArrowUpDown';
import { SequenceTypeEnum } from '@shared/interfaces/SequenceType.enum';
import { SequencesDropdownItem } from '@src/Components/Pages/Workplan/ProgressTrackerTab/SequenceSelectionSection/SequencesDropdown/SequencesDropdownItem';
import { WorkplanContext } from '@src/Components/Pages/Workplan/WorkPlan';
import { useOutsideClick } from '@src/hooks/custom.hooks';
import classnames from 'classnames';
import { SequenceSelectionItem } from '@src/Components/Pages/Workplan/ProgressTrackerTab/SequenceSelectionSection/SequenceSelectionItem/SequenceSelectionItem';
import {
	ProgressTrackerTabContext,
	TableModeEnum,
} from '@src/Components/Pages/Workplan/ProgressTrackerTab/ProgressTrackerTab';
import { TrusstorIcon } from '@src/Components/TrusstorIcon/TrusstorIcon';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { IconColor } from '@shared/components/TrusstorIconShared/TrusstorIconShared';

interface IRestOfSequencesProps {
	sequences: ISequence[];
	selectedSequence?: ISequence;
	onChange: (sequence: ISequence) => void;
	setShowCreateSequence: () => void;
}

export const SequencesDropdown = (props: IRestOfSequencesProps) => {
	const { tableView } = useContext(ProgressTrackerTabContext)!;
	const { setSequences, setSelectedSequenceId } = useContext(WorkplanContext)!;
	const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
	const isAllowedToActivityActions: boolean = accessLevelService.hasAccess('workplanActivityActions');

	const ref: React.RefObject<HTMLDivElement> = React.useRef<HTMLDivElement>(null);

	useOutsideClick(ref, setIsDropdownOpen, false);
	const changeSequenceType = async (sequenceType: SequenceTypeEnum, sequenceId: string) => {
		try {
			const updatedSequence: ISequence = await requestService.put(`/activities/sequences/${sequenceId}`, {
				body: {
					sequenceType,
				},
			});
			setSequences((prev) =>
				prev?.map((sequence) => {
					if (sequence._id === updatedSequence._id) {
						return updatedSequence;
					}
					return sequence;
				})
			);
		} catch (e) {
			console.log(e);
		}
	};

	const selectSequence = (sequence: ISequence) => {
		setIsDropdownOpen(false);
		setSelectedSequenceId(sequence._id);
	};

	const isSelected: boolean = !!props.sequences.find((sequence) => sequence._id === props.selectedSequence?._id);

	return (
		<div
			ref={ref}
			onClick={() => setIsDropdownOpen((prev) => !prev)}
			className={classnames(classes.container, { [classes.selected]: isSelected })}
		>
			<SequenceSelectionItem
				isSelected={true}
				sequence={props.selectedSequence}
				onClick={props.onChange}
				closeDropdown={() => setIsDropdownOpen(false)}
			/>
			<ArrowUpDown isOpen={isDropdownOpen} />
			{isDropdownOpen && (
				<div className={classnames(classes.dropdown)}>
					{props.sequences.map((sequence) => {
						const isSelected: boolean = props.selectedSequence?._id === sequence._id;
						return (
							<SequencesDropdownItem
								isSelected={isSelected}
								sequence={sequence}
								selectSequence={selectSequence}
								changeSequenceType={(sequenceType) => changeSequenceType(sequenceType, sequence._id)}
							/>
						);
					})}
					{tableView === TableModeEnum.AREAS && isAllowedToActivityActions && (
						<div className={classes.newButton} onClick={props.setShowCreateSequence}>
							<TrusstorIcon iconName={IconNames.plus} color={IconColor.Grey500} />
							<div>{translationService.get('addNew')}</div>
						</div>
					)}
				</div>
			)}
		</div>
	);
};
