import React from 'react';
import { ProjectStatus } from '@shared/interfaces/ProjectStatus.enum';
import { COLORS, MILESTONE_COLORS } from '@shared/constants/colors.constants';
import classes from './styles.module.scss';
import { translationService } from '../../../../../index';

interface IProgressLabelProps {
	projectStatus: ProjectStatus;
}

const ProgressLabel = (props: IProgressLabelProps) => {
	const getLabelBackground = (): MILESTONE_COLORS => {
		switch (props.projectStatus) {
			case ProjectStatus.onTrack:
				return MILESTONE_COLORS.onTrack;
			case ProjectStatus.minorDelay:
				return MILESTONE_COLORS.minorDelay;
			case ProjectStatus.noDataAvailable:
				return MILESTONE_COLORS.noDataAvailable;
			default:
				return MILESTONE_COLORS.late;
		}
	};

	const getLabelTextColor = (): string => {
		if (props.projectStatus === ProjectStatus.minorDelay) {
			return COLORS.grey800;
		}
		return COLORS.white;
	};

	return (
		<div
			className={classes.progressLabelContainer}
			style={{ backgroundColor: getLabelBackground(), color: getLabelTextColor() }}
		>
			{translationService.get(props.projectStatus)}
		</div>
	);
};

export { ProgressLabel };
