import React from 'react';
import { IUser } from '@shared/interfaces/IUser';
import { ActionConfirmationDialog } from '../WorkplanWidgetComponents/ActionConfirmationDialog/ActionConfirmationDialog';
import { requestService, translationService } from '../../index';
import { userDelete_BI } from '../../utils/bi.utils';
import { errorSnackbar, successSnackbar } from '../../utils/snackbar.utils';
import { useDispatch } from 'react-redux';

interface IDeleteUserDialogProps {
	show: boolean;
	onClose: () => void;
	user: IUser;
	onDeleteClick?: () => void;
}

export const DeleteUserDialog = (props: IDeleteUserDialogProps) => {
	const dispatch = useDispatch();

	const deleteAction = async (): Promise<void> => {
		try {
			await requestService.delete(`/entities/user?id=${props.user._id}`);
			props.onClose();
			props.onDeleteClick?.();
			userDelete_BI(props.user);
			successSnackbar(dispatch, translationService.get('userDeletedSuccessfully'));
		} catch (e) {
			errorSnackbar(dispatch, translationService.get('userDeleteFailed'));
		}
	};

	return (
		<ActionConfirmationDialog
			show={props.show}
			onClose={props.onClose}
			mainButtonAction={() => deleteAction()}
			secondaryButtonAction={props.onClose}
			title={translationService.get('deleteUser')}
			text={translationService.get('areYouSureDeleteUser', { name: props.user.name })}
		/>
	);
};
