import React, { useEffect, useId, useState } from 'react';
import classes from './styles.module.scss';
import classnames from 'classnames';
import TrusstorTooltip from '@shared/components/TrusstorTooltip';

interface IGraphRowProps {
	rowHeader: string;
	rowValue: string;
	percentage: number;
	secondaryPercentage?: number;
	color?: string;
	height?: string;
	animated?: boolean;
}

export const GraphRow = (props: IGraphRowProps) => {
	const [isLoaded, setIsLoaded] = useState<boolean>(!props.animated);
	const rowTextId: string = `graphRowText-${props.rowHeader}-${useId()}`;
	const secondaryPercentageText: string | undefined = props.secondaryPercentage
		? `${props.secondaryPercentage > 0 ? '+' : ''}${props.secondaryPercentage.toFixed(1)} %`
		: undefined;

	useEffect(() => {
		setIsLoaded(true);
	}, []);

	return (
		<div className={classes.row} style={{ height: props.height }}>
			<TrusstorTooltip idToOverflow={rowTextId} text={props.rowHeader}>
				<div id={rowTextId} className={classes.rowText}>
					{props.rowHeader}
				</div>
			</TrusstorTooltip>
			<div className={classes.bar}>
				<div
					className={classnames(classes.barCompleted, { [classes.popUp]: props.animated })}
					style={{
						width: isLoaded ? `${props.percentage}%` : '0%',
						transition: props.animated ? 'width 1s ease-out' : 'none',
						backgroundColor: props.color,
					}}
				></div>
				{!!props.percentage && (
					<div
						className={classnames(classes.secondaryBar, { [classes.popUp]: props.animated })}
						style={{ width: `${props.secondaryPercentage}%` }}
					></div>
				)}
				{!!secondaryPercentageText && <div className={classes.secondaryText}>{secondaryPercentageText}</div>}
			</div>
			<div className={classes.rowValue}>{props.rowValue}</div>
		</div>
	);
};
