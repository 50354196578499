import { requestService, translationService } from '../../../index';
import { ActionConfirmationDialog } from '../../WorkplanWidgetComponents/ActionConfirmationDialog/ActionConfirmationDialog';
import React from 'react';
import { ActivityDialogDisclaimer } from '../ActivityDialogDisclaimer/ActivityDialogDisclaimer';
import { IActivityActionsEnum } from '@interfaces/IActivityActions.enum';

interface IActivityDeleteActionDialogProps {
	show: boolean;
	onClose: () => void;
	activityGroupId: string;
	disclaimerPoints?: string[];
	onDeleteClick?: () => void;
	sendBiEventOnSubmit: (action: IActivityActionsEnum) => void;
	onActionComplete?: () => void;
}

export const ActivityDeleteActionDialog = (props: IActivityDeleteActionDialogProps) => {
	const deleteAction = async (): Promise<void> => {
		props.onDeleteClick?.();
		await requestService.delete(`/activities/groups/${props.activityGroupId}`);
		props.onActionComplete?.();
		props.onClose();
		props.sendBiEventOnSubmit(IActivityActionsEnum.DELETE);
	};

	const disclaimer: JSX.Element | undefined = !props.disclaimerPoints?.length ? undefined : (
		<ActivityDialogDisclaimer disclaimerPoints={props.disclaimerPoints} />
	);

	return (
		<ActionConfirmationDialog
			show={props.show}
			onClose={props.onClose}
			mainButtonAction={() => deleteAction()}
			secondaryButtonAction={props.onClose}
			title={translationService.get('deleteActivity')}
			text={translationService.get('areYouSureDeleteActivity')}
			secondaryText={disclaimer}
		/>
	);
};
