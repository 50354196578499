import React, { useCallback, useState } from 'react';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import classes from './styles.module.scss';
import { COLORS } from '../../constants/colors.constants';
import { closeIcon } from '../../assets';
import classnames from 'classnames';
interface ISimpleChipProps {
	chipText?: string | React.ReactElement;
	chipTextColor?: string;
	chipColor: string;
	click?: React.MouseEventHandler<HTMLElement>;
	maxChipWidth?: string;
	minChipWidth?: string;
	fontClass?: string;
	chipHeight?: number;
	containerWidth?: string;
	margin?: string;
	onDeleteClick?: () => void;
	lightText?: boolean;
	capitalizeText?: boolean;
	textStart?: boolean;
	border?: boolean;
	borderColor?: string;
	testId?: string;
	isRectangular?: boolean;
	allowTextOverflow?: boolean;
	maxContentWidth?: number;
	hoverColor?: string;
	isMobile?: boolean;
	textClassName?: string;
}

interface IStyles {
	maxContentWidth?: number;
}
const useStyles = makeStyles({
	labelWidth: ({ maxContentWidth }: IStyles) => ({
		width: maxContentWidth ? maxContentWidth - 6 : undefined,
	}),
});

const getBorderStyle = (props: ISimpleChipProps): string | 0 => {
	if (props.border || props.borderColor) return `1px solid ${props.borderColor || props.chipTextColor}`;
	if (props.chipTextColor === COLORS.black) return `1px solid`;
	return 0;
};

const SimpleChip = React.forwardRef<HTMLParagraphElement, ISimpleChipProps>((props, ref) => {
	const {
		chipText = '',
		border,
		chipTextColor = COLORS.white,
		chipColor = '',
		click,
		textStart,
		onDeleteClick,
		maxChipWidth = 'auto',
		minChipWidth,
		chipHeight = 20,
		containerWidth = 'auto',
		margin = '0px',
		lightText,
		isMobile,
		capitalizeText,
		textClassName = '',
		...rest
	} = props;
	const additionalClasses = useStyles({ maxContentWidth: props.maxContentWidth });
	const [isChipHover, setIsChipHover] = useState(false);

	const hoverChip = useCallback(() => {
		click && setIsChipHover(true);
	}, []);

	const leaveChip = useCallback(() => {
		click && setIsChipHover(false);
	}, []);

	const borderStyle: string | 0 = getBorderStyle(props);
	const chipLabelTextClassName: string = `${classes.text} ${lightText || !isMobile ? classes.lightText : ''} ${
		isMobile ? classes.mobileText : ''
	} ${textClassName}`;

	const labelClass = classnames({
		[classes.label]: true,
		[classes.fullWidth]: !props.allowTextOverflow,
		[classes.allowTextOverflow]: props.allowTextOverflow,
		[additionalClasses.labelWidth]: !!props.maxContentWidth,
	});

	const rootClass = classnames({
		[classes.root]: true,
		[classes.allowTextOverflow]: props.allowTextOverflow,
	});

	return (
		<div
			{...rest}
			style={{
				width: 'max-content',
				margin: margin,
			}}
		>
			<Chip
				label={
					<div className={classes.labelContainer}>
						<p
							ref={ref}
							className={chipLabelTextClassName}
							style={{
								color: chipTextColor,
								textTransform: capitalizeText ? 'capitalize' : 'initial',
							}}
						>
							{chipText}
						</p>
					</div>
				}
				classes={{
					root: rootClass,
					clickable: click ? classes.clickable : '',
					label: labelClass,
				}}
				style={{
					borderRadius: props.isRectangular ? 0 : 10,
					backgroundColor: props.hoverColor && isChipHover ? props.hoverColor : chipColor,
					opacity: isChipHover && !props.hoverColor ? 0.6 : 1,
					height: chipHeight,
					maxWidth: maxChipWidth,
					minWidth: minChipWidth,
					border: borderStyle,
					justifyContent: textStart ? 'start' : 'center',
				}}
				onClick={click}
				onMouseEnter={hoverChip}
				onMouseLeave={leaveChip}
				deleteIcon={<img alt="close" className={classes.closeIcon} src={closeIcon} />}
				onDelete={onDeleteClick}
				data-testid={props.testId ?? null}
			/>
		</div>
	);
});

export { SimpleChip };
