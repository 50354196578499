import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { arrowDown, arrowUp } from '@shared/assets/icons/tradesBox';
import { IArrowShown } from '../../interfaces/IArrowShown';
import { ISortingState } from '../../interfaces/ISortingState';
import { SORT_ORDER } from '@shared/constants/constants';

const useStyles = makeStyles({
	arrow: {
		width: 14,
		height: 14,
	},
});

interface ISortingArrowProps {
	arrowShown: IArrowShown;
	sortingState: ISortingState;
	currentColumn: string;
}

const SortingArrow = (props: ISortingArrowProps) => {
	const classes = useStyles();

	if (props.sortingState.isSorted && props.sortingState.columnName === props.currentColumn) {
		return props.sortingState.sortOrder === SORT_ORDER.ASCENDING ? (
			<img className={classes.arrow} src={arrowDown} alt={'arrow'} />
		) : (
			<img className={classes.arrow} src={arrowUp} alt={'arrow'} />
		);
	} else if (props.arrowShown.columnName === props.currentColumn && props.arrowShown.isShown) {
		return <img className={classes.arrow} src={arrowDown} alt={'arrow'} />;
	}
	return null;
};

export { SortingArrow };
