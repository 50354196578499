import {
	generateTimezoneWeekDatesRange,
	getProjectDateWithBrowserTZOffset,
	getTimezoneStartOfDate,
} from '../../utils/dateUtils';
import moment, { Moment } from 'moment';
import { IDatePickerState } from '../../interfaces';
import { DATE_PICKER_TOGGLE } from '../../constants';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProjectService } from '@shared/services/project.service';

const getDefaultVisiblePeriodTimestampsForTimezone = (tz: string): [number, number] => {
	const now: Moment = moment.tz(tz);
	if (now.get('day') === 0) {
		const weekDaysRange = generateTimezoneWeekDatesRange(now.subtract(1, 'w').toDate(), tz);
		return [weekDaysRange[0].getTime(), weekDaysRange[5].getTime()];
	} else {
		const weekDaysRange = generateTimezoneWeekDatesRange(now.toDate(), tz);
		return [weekDaysRange[0].getTime(), weekDaysRange[now.get('day')]?.getTime()];
	}
};
const getTimezone = (): string => new ProjectService().getChosenProject()?.tz || 'Asia/Jerusalem';

const getDefaultVisiblePeriod = (): [number, number] => {
	return getDefaultVisiblePeriodTimestampsForTimezone(getTimezone());
};

const getDefaultWeekTimestamps = (): number[] => {
	const tz: string = getTimezone();
	return generateTimezoneWeekDatesRange(getProjectDateWithBrowserTZOffset(tz), tz).map((date) => date.getTime());
};

const initialState: IDatePickerState = {
	visibleDateTimestamp: getTimezoneStartOfDate(getTimezone()).getTime(),
	visiblePeriodTimestamps: getDefaultVisiblePeriod(),
	toggleState: DATE_PICKER_TOGGLE.date,
	visibleDateRangeTimestamps: getDefaultWeekTimestamps(),
};

const datePickerSlice = createSlice({
	name: 'datePicker',
	initialState,
	reducers: {
		updateVisibleDate: (state: IDatePickerState, action: PayloadAction<{ visibleDateTimestamp: number }>) => {
			state.visibleDateTimestamp = action.payload.visibleDateTimestamp;
		},
		updateVisiblePeriod: (
			state: IDatePickerState,
			action: PayloadAction<{ visiblePeriodTimestamps: [number, number] }>
		) => {
			state.visiblePeriodTimestamps = action.payload.visiblePeriodTimestamps;
		},
		updateDatePickerToggle: (
			state: IDatePickerState,
			action: PayloadAction<{ toggleState: DATE_PICKER_TOGGLE }>
		) => {
			state.toggleState = action.payload.toggleState;
		},
		updateVisibleWeek: (
			state: IDatePickerState,
			action: PayloadAction<{ visibleDateRangeTimestamps: number[] }>
		) => {
			state.visibleDateRangeTimestamps = action.payload.visibleDateRangeTimestamps;
		},
		resetDatePicker: (state: IDatePickerState, action: PayloadAction<{ tz: string }>) => {
			state.visibleDateTimestamp = getTimezoneStartOfDate(action.payload.tz).getTime();
			state.visiblePeriodTimestamps = getDefaultVisiblePeriodTimestampsForTimezone(action.payload.tz);
			state.toggleState = DATE_PICKER_TOGGLE.date;
			state.visibleDateRangeTimestamps = generateTimezoneWeekDatesRange(
				getProjectDateWithBrowserTZOffset(action.payload.tz),
				action.payload.tz
			).map((date) => date.getTime());
		},
	},
});

export const { updateVisibleDate, updateVisiblePeriod, updateDatePickerToggle, updateVisibleWeek, resetDatePicker } =
	datePickerSlice.actions;
export default datePickerSlice.reducer;
