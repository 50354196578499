import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import classes from './styles.module.scss';
import { translationService } from '../../../../index';
import classnames from 'classnames';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { TrusstorIcon } from '@src/Components/TrusstorIcon/TrusstorIcon';
import { IconColor } from '@shared/components/TrusstorIconShared/TrusstorIconShared';

interface ISubSideBarItemProps {
	path: string;
	text: string;
	handleClick?: (e?: any) => void;
	iconName: IconNames;
}

const SubSideBarItem = ({ path, text, handleClick, iconName }: ISubSideBarItemProps) => {
	const [isHover, setIsHover] = React.useState<boolean>(false);
	const currentPath: string = useLocation().pathname;
	const pathName: string = path.split('/')[path.split('/').length - 1];
	const currentPathName: string = currentPath.split('/')[currentPath.split('/').length - 1];
	const isSelected: boolean = pathName === currentPathName;
	const isRtl: boolean = translationService.getIsRtl();

	return (
		<Link
			onMouseEnter={() => setIsHover(true)}
			onMouseLeave={() => setIsHover(false)}
			onClick={handleClick}
			to={path || currentPath}
			className={classnames(classes.subLinkContainer, {
				[classes.isSelected]: isSelected,
				[classes.isHover]: isHover,
			})}
		>
			<div className={`${classes.hoverBorder} ${isRtl ? classes.right : classes.left}`}></div>
			<TrusstorIcon iconName={iconName} color={isSelected ? IconColor.White : IconColor.Grey200} />
			<p
				className={classnames(classes.subLinkText, {
					[classes.isHover]: isHover,
					[classes.isSelected]: isSelected,
				})}
			>
				{text}
			</p>
		</Link>
	);
};

export { SubSideBarItem };
