import React from 'react';
import classes from './styles.module.scss';
import { translationService } from '@src/servicesInitializers';

export const SpecialFloorEmptyState = () => {
	return (
		<div className={classes.SpecialFloorEmptyState_container} data-testid="SpecialFloorEmptyState">
			<div>{translationService.get('specialFloorEmptyState')}</div>
		</div>
	);
};
