import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ErrorRounded } from '@material-ui/icons';
import Snackbar from '@material-ui/core/Snackbar';
import { COLORS } from '../../constants';
import Alert from '@material-ui/lab/Alert';
import { checkCircleWhiteIcon, tip } from '../../assets';
import { textStyle } from '@shared/utils/text.utils';
import { alertType } from 'src/interfaces/SnackbarAlertTypes';
import { warningTransparentIcon } from '@shared/assets/icons';

interface IStyleProps {
	backgroundColor: string;
	color: string;
}

const useStyles = makeStyles((theme) => ({
	snackbar: ({ backgroundColor }: IStyleProps) => ({
		backgroundColor: backgroundColor,
		borderRadius: '8px',
	}),
	snackbarContent: ({ color }: IStyleProps) =>
		textStyle({
			color: color,
			display: 'flex',
			flexDirection: 'column',
			gap: 2,
		}),
	messagePrimaryText: ({ color }: IStyleProps) =>
		textStyle({
			maxWidth: '400px',
			fontClass: 'h1',
			color: color ? color : COLORS.white,
		}),
	secondaryText: ({ color }: IStyleProps) =>
		textStyle({
			fontClass: 'body2',
			color: color ? color : COLORS.white,
		}),
	iconWrapper: {
		width: 16,
	},
}));

interface ITrusstorSnackbarProps {
	backgroundColor?: string;
	color?: string;
	vertical: 'top' | 'bottom';
	horizontal: 'left' | 'center' | 'right';
	message: string;
	secondaryMessage?: string;
	open: boolean;
	close: () => void;
	alertType?: alertType;
}

const TrusstorSnackbar = ({
	backgroundColor = '#2196f3',
	color = COLORS.white,
	vertical,
	horizontal,
	message,
	secondaryMessage,
	open,
	close,
	alertType = 'success',
}: ITrusstorSnackbarProps) => {
	const classes = useStyles({ backgroundColor, color });

	const getAlertIcon = (alertType: alertType) => {
		switch (alertType) {
			case 'success':
				return checkCircleWhiteIcon;
			case 'error':
				return warningTransparentIcon;
			case 'info':
				return tip;
			default:
				return checkCircleWhiteIcon;
		}
	};

	return (
		<Snackbar
			anchorOrigin={{ vertical, horizontal }}
			open={open}
			onClose={close}
			key={vertical + horizontal}
			autoHideDuration={3000}
		>
			<Alert
				classes={{
					root: classes.snackbar,
					message: classes.snackbarContent,
				}}
				icon={
					<img className={classes.iconWrapper} src={getAlertIcon(alertType)} alt={'snackbarIcon'} /> || (
						<ErrorRounded />
					)
				}
			>
				<div className={classes.messagePrimaryText}>{message}</div>
				<div className={classes.secondaryText}>{secondaryMessage}</div>
			</Alert>
		</Snackbar>
	);
};

export { TrusstorSnackbar };
