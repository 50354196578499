import React from 'react';
import moment from 'moment-timezone';
import 'moment/locale/he';
import 'moment/locale/ja';
import classes from './styles.module.scss';
import { useSharedServices } from '../../hooks/sharedServices.context';
import { ITrusstorIconButtonStyle } from '../buttons/TrusstorIconButton/TrusstorIconButtonDeprecated';
import { IconNames } from '../TrusstorIconShared/IconNames.enum';
import { dateFormats } from '../../constants/formats.constants';
import { TrusstorIconButtonV2 } from '../buttons/TrusstorIconButtonV2/TrusstorIconButtonV2';

interface DatePickerHeaderType {
	monthDate: Date;

	changeYear?(year: number): void;

	changeMonth?(month: number): void;

	customHeaderCount?: number;

	decreaseMonth(): void;

	increaseMonth(): void;

	prevMonthButtonDisabled: boolean;
	nextMonthButtonDisabled: boolean;

	decreaseYear?(): void;

	increaseYear?(): void;

	prevYearButtonDisabled?: boolean;
	nextYearButtonDisabled?: boolean;
	monthsShown?: number;
}

export const DatePickerHeader = (props: DatePickerHeaderType) => {
	const { translationService } = useSharedServices();
	const isRtl: boolean = translationService.getIsRtl();
	const getMonthYearString = () => {
		return moment(props.monthDate).locale(translationService.getDateLocale()).format(dateFormats.MMMM_YYYY);
	};

	const shouldShowPrevMonthButton = () => {
		return !props.customHeaderCount;
	};

	const shouldShowNextMonthButton = () => {
		return (
			props.customHeaderCount === undefined ||
			(props.monthsShown && props.customHeaderCount === props.monthsShown - 1)
		);
	};

	const decreaseMonth = () => {
		props.decreaseMonth();
	};

	const increaseMonth = () => {
		props.increaseMonth();
	};

	const getPrevMonthButton = () => {
		if (isRtl) {
			return shouldShowPrevMonthButton() ? (
				<TrusstorIconButtonV2
					style={ITrusstorIconButtonStyle.GHOST}
					disabled={props.prevMonthButtonDisabled}
					iconName={IconNames.chevronRight}
					onClick={decreaseMonth}
					testId="prevMonthButton"
				/>
			) : (
				<div></div>
			);
		}
		return shouldShowPrevMonthButton() ? (
			<TrusstorIconButtonV2
				style={ITrusstorIconButtonStyle.GHOST}
				disabled={props.prevMonthButtonDisabled}
				iconName={IconNames.chevronLeft}
				onClick={decreaseMonth}
				testId="prevMonthButton"
			/>
		) : (
			<div></div>
		);
	};

	const getNextMonthButton = () => {
		if (isRtl) {
			return shouldShowNextMonthButton() ? (
				<TrusstorIconButtonV2
					style={ITrusstorIconButtonStyle.GHOST}
					disabled={props.nextMonthButtonDisabled}
					iconName={IconNames.chevronLeft}
					onClick={increaseMonth}
					testId="nextMonthButton"
				/>
			) : (
				<div></div>
			);
		}
		return shouldShowNextMonthButton() ? (
			<TrusstorIconButtonV2
				style={ITrusstorIconButtonStyle.GHOST}
				disabled={props.nextMonthButtonDisabled}
				iconName={IconNames.chevronRight}
				onClick={increaseMonth}
				testId="nextMonthButton"
			/>
		) : (
			<div></div>
		);
	};

	return (
		<div className={classes.datePickerHeader}>
			{getPrevMonthButton()}
			<div className={classes.datePickerHeaderDate}>{getMonthYearString()}</div>
			{getNextMonthButton()}
		</div>
	);
};
