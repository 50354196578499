import React from 'react';
import { ITagRouteWithLocationData } from '@shared/interfaces/ITagRouteWithLocationData';
import { IMergedEquipment } from '@shared/interfaces/IMergedEquipment';
import { translationService } from '../../../../../../index';
import { SimpleChip } from '@shared/components/SimpleChip/SimpleChip';
import { COLORS } from '@shared/constants/colors.constants';
import { compact, uniqBy } from 'lodash';
import classes from './styles.module.scss';
import { EmptyStateRow } from '../../EmptyStateRow/EmptyStateRow';

interface IInvestigationReportEquipmentSection {
	tagLocationData: ITagRouteWithLocationData | null;
}

export const InvestigationReportEquipmentSection = (props: IInvestigationReportEquipmentSection) => {
	const equipments: IMergedEquipment[] = uniqBy(
		compact(
			props.tagLocationData?.floors.map(
				(floor) => floor.areas.find((area) => area.areaId === props.tagLocationData?.areaId)?.equipments
			)
		).flat(2),
		'equipmentId'
	);

	const getEquipmentName = (equipment: IMergedEquipment): string => {
		return `${equipment.name}${equipment.serialNumber ? ` - ${equipment.serialNumber}` : ''}`;
	};
	return (
		<div>
			<p className={classes.header}>{translationService.get('equipment')}</p>
			{!equipments.length ? (
				<EmptyStateRow text={translationService.get('noEquipmentsFound')} />
			) : (
				<div className={classes.dataSection}>
					{equipments.map((equipment) => (
						<SimpleChip
							textClassName={classes.chipText}
							chipText={getEquipmentName(equipment)}
							chipColor={COLORS.white}
							chipTextColor={COLORS.primaryColor}
							chipHeight={22}
						></SimpleChip>
					))}
				</div>
			)}
		</div>
	);
};
