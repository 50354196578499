import React, { useRef } from 'react';
import { sortBy, groupBy } from 'lodash';
import ProfessionsChipsContainer from '../Chips/ProfessionChipsContainer';
import ProfessionsChipsTooltip from '../Chips/Tooltips/ProfessionsChipsTooltip';
import { TrusstorTooltip } from '../../../../shared/components/TrusstorTooltip/TrusstorTooltip';
import { entitiesProperties, equipmentProperties } from '../../constants';
import { makeStyles } from '@material-ui/core';
import { MainViewEquipmentIcon } from '../Equipment/MainViewEquipmentIcon';
import { translationService } from '../../index';
import { TagsDetailsTooltip } from '../Tooltips/TagsDetailsTooltip';
import { compareProps } from '../../utils/generalUtils';
import { IFloorData } from '../../interfaces/IFloorData';
import { IBuildingSvgPoints } from '../../interfaces/IBuildingSvgPoints';
import { IMergedFloorView } from '../../interfaces/IMergedFloorView';
import { IMergedEquipmentView } from '../../interfaces/IMergedEquipmentView';
import { ISvgElementPoint } from '../../interfaces/ISvgElementPoint';
import { IProfessionWithActiveTags } from '../../interfaces/IProfessionWithActiveTags';
import { DIRECTIONS } from '@shared/constants/directions';
import { floorTypes } from '@shared/constants/floors.constants';

interface IStyleProps {
	professionPointForFloor?: ISvgElementPoint;
	direction?: string;
}

const useStyles = makeStyles((theme) => ({
	equipmentContainer: ({ professionPointForFloor, direction }: IStyleProps) => ({
		position: 'absolute',
		top: professionPointForFloor?.top,
		left: direction === DIRECTIONS.LTR ? professionPointForFloor?.left : professionPointForFloor?.right,
		maxWidth: professionPointForFloor?.width,
		display: 'flex',
		justifyContent: 'flex-start',
	}),
}));

interface IFloorProfessionsEquipmentProps {
	floorData: IFloorData;
	svgPoints: IBuildingSvgPoints;
	selectedFloor?: IMergedFloorView;
	setFloorPath: (floorPath: string) => void;
	equipmentData: IMergedEquipmentView[];
	isAdmin: boolean;
	buildingSvgWidth: number;
}

const FloorProfessionsEquipment = React.memo((props: IFloorProfessionsEquipmentProps) => {
	const professionPointForFloor: ISvgElementPoint | undefined = props.svgPoints.professionPointsArray.find(
		(point) => point.floorId === props.floorData.floorId
	);
	const classes = useStyles({
		professionPointForFloor,
		direction: translationService.getDirection(),
	});
	const equipmentContainerRef = useRef(null);

	if (props.floorData.floorId === floorTypes.pa) {
		return null;
	}

	const equipmentByType = Object.values(groupBy(props.equipmentData, equipmentProperties.type)).map(
		(equipmentArray) => {
			return {
				type: equipmentArray[0].type,
				childrenEquipment: equipmentArray,
			};
		}
	);
	const professionsForFloor: IProfessionWithActiveTags[] = sortBy(props.floorData.activeProfessions, [
		entitiesProperties.sortIndex,
		entitiesProperties.tradeId,
	]);

	if (!professionPointForFloor) {
		return null;
	}

	return (
		<React.Fragment>
			<div className={classes.equipmentContainer} ref={equipmentContainerRef}>
				{equipmentByType.map((equipmentFamily) => {
					return (
						<MainViewEquipmentIcon
							key={equipmentFamily.type}
							type={equipmentFamily.type}
							equipmentArray={equipmentFamily.childrenEquipment}
							click={() => props.setFloorPath(props.floorData.floorId)}
						/>
					);
				})}
			</div>
			<TrusstorTooltip
				showAlways={props.isAdmin ? true : !!props.selectedFloor}
				key={`trades_chips_container_${professionPointForFloor.floorId}`}
				chipsClasses={`floor_${professionPointForFloor.floorId}_trades`}
				tooltipComponent={
					props.isAdmin ? (
						<TagsDetailsTooltip tags={props.floorData.tags} />
					) : (
						<ProfessionsChipsTooltip
							professionsForFloor={professionsForFloor}
							professionPointForFloor={professionPointForFloor}
						/>
					)
				}
			>
				<ProfessionsChipsContainer
					professionsForFloor={professionsForFloor}
					professionPointForFloor={professionPointForFloor}
					equipmentPrefixContainer={equipmentContainerRef}
					key={`trades_${professionPointForFloor.floorId}`}
					id={`trades_chips_container_${professionPointForFloor.floorId}`}
					onClick={() => props.setFloorPath(props.floorData.floorId)}
					buildingSvgWidth={props.buildingSvgWidth}
				/>
			</TrusstorTooltip>
		</React.Fragment>
	);
}, compareProps);

export { FloorProfessionsEquipment };
