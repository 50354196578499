import React, { SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { IAreaSequenceItem } from '../../../../shared/interfaces/IAreaSequenceItem';
import { IConfigArea } from '../../interfaces/IConfigArea';
import { selectProjectId } from '../../store/slices/project.slice';
import { useSelector } from 'react-redux';
import { requestService } from '../../servicesInitializers';
import { IMergedAreaSequenceItem } from '../../interfaces/IMergedAreaSequenceItem';
import { Dictionary, groupBy, pick } from 'lodash';
import { IConfigFloor } from '../../interfaces/IConfigFloor';
import { IProfession } from '@shared/interfaces/IProfession';
import { IRootState } from '@store/slices';

const getAreaSequenceItems = async (
	floorIds: string[],
	projectId: string,
	sequenceId?: string
): Promise<IMergedAreaSequenceItem[]> => {
	if (floorIds.length === 0) return [];

	return await requestService.get(`/activities/sequenceItems/areaSequenceItem/byFloorId`, {
		params: {
			projectId,
			floorIds: floorIds.join(','),
			sequenceId,
		},
	});
};

const floorsToShow: number = 5;

const getFloorsToShowByCurrentFloorId = (allFloors: IConfigArea[], currentFloorId: string): IConfigFloor[] => {
	const currentFloorIndex: number = allFloors.findIndex((floor) => floor.floorId === currentFloorId);

	const halfFloorsToShow: number = Math.floor(floorsToShow / 2);
	let startIndex: number = currentFloorIndex - halfFloorsToShow;
	let endIndex: number = currentFloorIndex + halfFloorsToShow + 1;

	if (startIndex < 0 && endIndex <= allFloors.length) {
		const amountToAddToEndIndex: number = Math.abs(startIndex);
		endIndex = Math.min(endIndex + amountToAddToEndIndex, allFloors.length);
		startIndex = 0;
	} else if (endIndex > allFloors.length && startIndex >= 0) {
		const amountToAddToStartIndex: number = Math.abs(endIndex - allFloors.length);
		startIndex = Math.max(startIndex - amountToAddToStartIndex, 0);
		endIndex = allFloors.length;
	} else if (startIndex < 0 && endIndex > allFloors.length) {
		startIndex = 0;
		endIndex = allFloors.length;
	}

	const floorsToShowList: IConfigFloor[] = allFloors.slice(startIndex, endIndex);
	const currentFloorIndexInFloorToShow: number = floorsToShowList.findIndex(
		(floor) => floor.floorId === currentFloorId
	);
	const currentFloor: IConfigFloor = floorsToShowList.splice(currentFloorIndexInFloorToShow, 1)[0];
	floorsToShowList.unshift(currentFloor);

	return floorsToShowList;
};

export const useAreaSequenceItems = (
	floors: IConfigArea[],
	areaSequenceItemsByFloor: Dictionary<IMergedAreaSequenceItem[] | null> | undefined,
	setAreaSequenceItemsByFloor: React.Dispatch<
		SetStateAction<Dictionary<IMergedAreaSequenceItem[] | null> | undefined>
	>,
	shouldFetchAllFloors: boolean,
	selectedSequenceId?: string
): {
	fetchMore: (currentFloorId: string) => Promise<void>;
	updateAreaSequenceItem: (areaSequenceItem: IMergedAreaSequenceItem) => void;
	isTableFinishToRender: boolean;
	setAreaSequenceItemsByFloorWithRef: (areaSequenceItems: IMergedAreaSequenceItem[]) => void;
} => {
	const projectId: string = useSelector(selectProjectId)!;
	const areaSequenceItemsByFloorRef = useRef<Dictionary<IMergedAreaSequenceItem[]> | undefined>(undefined);
	const pendingFloorIdsToBeFetchedRef = useRef<string[]>([]);
	const isFetchAllFloorsEnded = useRef<boolean>(false);
	const [isTableFinishToRender, setIsTableFinishToRender] = useState<boolean>(false);
	const cancelLoopsRef = useRef<number[]>([]);
	const loopIndexRef = useRef<number>(0);
	const currentSequenceIdRef = useRef<string | undefined>(selectedSequenceId);
	const shouldFetchAllFloorsRef = useRef<boolean>(shouldFetchAllFloors);
	const visibleProfessions: IProfession[] = useSelector(
		(state: IRootState) => state.professionsReducer.visibleProfessions
	);

	useEffect(() => {
		currentSequenceIdRef.current = selectedSequenceId;
	}, [selectedSequenceId]);

	useEffect(() => {
		shouldFetchAllFloorsRef.current = shouldFetchAllFloors;
		setAreaSequenceItemsByFloor(areaSequenceItemsByFloorRef.current);
	}, [shouldFetchAllFloors, selectedSequenceId]);

	const getFloorsByIds = useCallback(
		async (floorIds: string[], shouldReRender?: boolean) => {
			const floorIdsToFetch: string[] = floorIds.filter((floorId) => {
				return !areaSequenceItemsByFloorRef.current?.[floorId];
			});

			if (floorIdsToFetch.length) {
				const newAreaSequenceItems: IMergedAreaSequenceItem[] = await getAreaSequenceItems(
					floorIdsToFetch,
					projectId,
					selectedSequenceId
				);

				const newAreaSequenceItemsByFloorId: { [floorId: string]: IMergedAreaSequenceItem[] } = groupBy(
					newAreaSequenceItems,
					(area: IAreaSequenceItem) => area.area.floorId
				);
				const floorIdsWithNoAsis: string[] = floorIdsToFetch.filter(
					(floorId: string) => !newAreaSequenceItemsByFloorId[floorId]?.length
				);
				const disabledFloorAsisDict: Dictionary<IMergedAreaSequenceItem[]> = floorIdsWithNoAsis.reduce(
					(acc, floorId) => {
						acc[floorId] = null;
						return acc;
					},
					{}
				);
				const allAsisByFloorId: Dictionary<IMergedAreaSequenceItem[]> = {
					...newAreaSequenceItemsByFloorId,
					...disabledFloorAsisDict,
				};

				if (selectedSequenceId !== currentSequenceIdRef.current) {
					return;
				}

				areaSequenceItemsByFloorRef.current = {
					...areaSequenceItemsByFloorRef.current,
					...allAsisByFloorId,
				};
			}

			if (shouldReRender) {
				const desiredFloorsFromCache: Dictionary<IMergedAreaSequenceItem[]> = pick(
					areaSequenceItemsByFloorRef.current!,
					floorIds
				);

				setAreaSequenceItemsByFloor((prev) => {
					return {
						...prev,
						...desiredFloorsFromCache,
					};
				});
			}
		},
		[projectId, selectedSequenceId]
	);

	const pushFloorIdsToStart = (floorIds: string[]) => {
		pendingFloorIdsToBeFetchedRef.current = [...floorIds, ...pendingFloorIdsToBeFetchedRef.current];
	};

	const fetchPending = useCallback(async () => {
		if (!pendingFloorIdsToBeFetchedRef.current.length) return;
		while (pendingFloorIdsToBeFetchedRef.current.length) {
			const floorIdsToFetch: string[] = pendingFloorIdsToBeFetchedRef.current.splice(0, 2);
			await getFloorsByIds(floorIdsToFetch, true);
		}
	}, [getFloorsByIds]);

	useEffect(() => {
		const resetHook = () => {
			setAreaSequenceItemsByFloor(undefined);
			areaSequenceItemsByFloorRef.current = {};
			pendingFloorIdsToBeFetchedRef.current = [];
			isFetchAllFloorsEnded.current = false;
			setIsTableFinishToRender(false);
		};

		const startFetchingFloors = async (loopIndex: number) => {
			for (let i = 0; i < floors.length; i++) {
				if (cancelLoopsRef.current.includes(loopIndex)) {
					cancelLoopsRef.current = cancelLoopsRef.current.filter((loop) => loop !== loopIndex);
					return;
				}

				await fetchPending();
				const shouldReRender: boolean = i === 0 || shouldFetchAllFloorsRef.current;
				await getFloorsByIds([floors[i].floorId], shouldReRender);
			}

			isFetchAllFloorsEnded.current = true;
			setAreaSequenceItemsByFloor(areaSequenceItemsByFloorRef.current);
			setIsTableFinishToRender(true);
		};

		if (!floors.length) {
			return;
		}

		loopIndexRef.current++;
		const loopIndex: number = loopIndexRef.current;
		resetHook();
		startFetchingFloors(loopIndex);

		return () => {
			cancelLoopsRef.current.push(loopIndex);
		};
	}, [floors, selectedSequenceId, getFloorsByIds, fetchPending]);

	const fetchMore = useCallback(
		async (currentFloorId: string) => {
			if (floors.length === 0) return;

			const floorsToFetch: IConfigFloor[] = getFloorsToShowByCurrentFloorId(floors, currentFloorId);
			const floorIds: string[] = floorsToFetch.map((floor) => floor.floorId);

			const floorIdsThatShouldBeFetched: string[] = floorIds.filter((floorId) => {
				return !areaSequenceItemsByFloor?.[floorId];
			});

			pushFloorIdsToStart(floorIdsThatShouldBeFetched);

			if (isFetchAllFloorsEnded.current) {
				await fetchPending();
			}
		},
		[floors, fetchPending]
	);

	const updateAreaSequenceItem = useCallback((areaSequenceItem: IMergedAreaSequenceItem) => {
		if (
			!areaSequenceItemsByFloorRef.current ||
			!areaSequenceItemsByFloorRef.current![areaSequenceItem.area.floorId]
		) {
			return;
		}
		const updatedFloor: IMergedAreaSequenceItem[] = areaSequenceItemsByFloorRef.current![
			areaSequenceItem.area.floorId
		].map((item) => {
			if (item._id === areaSequenceItem._id) {
				return areaSequenceItem;
			}

			return item;
		});

		setAreaSequenceItemsByFloor((prev: Dictionary<IMergedAreaSequenceItem[] | null> | undefined) => {
			if (!prev) return prev;

			return {
				...prev,
				[areaSequenceItem.area.floorId]: updatedFloor,
			};
		});
		areaSequenceItemsByFloorRef.current = {
			...areaSequenceItemsByFloorRef.current,
			[areaSequenceItem.area.floorId]: updatedFloor,
		};
	}, []);

	const setAreaSequenceItemsByFloorWithRef = (areaSequenceItems: IMergedAreaSequenceItem[]) => {
		const areaSequenceItemsByFloor = groupBy(
			areaSequenceItems,
			(areaSequenceItem) => areaSequenceItem.area.floorId
		);
		setAreaSequenceItemsByFloor(areaSequenceItemsByFloor);
		areaSequenceItemsByFloorRef.current = areaSequenceItemsByFloor;
	};

	return {
		fetchMore,
		updateAreaSequenceItem,
		isTableFinishToRender,
		setAreaSequenceItemsByFloorWithRef,
	};
};
