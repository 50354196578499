import React from 'react';
import moment from 'moment-timezone';
import classes from './styles.module.scss';
import { translationService } from '@src/index';
import { HeaderBox } from '@src/Components/Boxes/Containers/HeaderBox/HeaderBox';
import { IObserveLocationVisitsApiResult } from '@shared/interfaces/IObserveLocationApiResult';
import { useSelector } from 'react-redux';
import { selectTimezone } from '@store/slices/project.slice';
import { EMPTY_ITEM_CONTENT } from '@src/Components/Pages/AnalysisCenterPages/reports.constants';

interface ILocationVisitsProps {
	visits: IObserveLocationVisitsApiResult;
}

const LocationVisits = (props: ILocationVisitsProps) => {
	const tz: string = useSelector(selectTimezone)!;

	return (
		<HeaderBox boldTitle title={translationService.get('visits')}>
			<div className={classes.visitsContainer}>
				<div className={classes.row}>
					<div className={classes.visitsTitle}>{translationService.get('firstEntered')}</div>
					<div className={classes.visitsValue}>
						{props.visits.startWorkTime
							? moment.tz(props.visits.startWorkTime, tz).format(translationService.getTimeFormat())
							: EMPTY_ITEM_CONTENT}
					</div>
				</div>
				<div className={classes.row}>
					<div className={classes.visitsTitle}>{translationService.get('lastLeave')}</div>
					<div className={classes.visitsValue}>
						{props.visits.endWorkTime
							? moment.tz(props.visits.endWorkTime, tz).format(translationService.getTimeFormat())
							: EMPTY_ITEM_CONTENT}
					</div>
				</div>
				<div className={classes.row}>
					<div className={classes.visitsTitle}>{translationService.get('busiestTime')}</div>
					<div className={classes.visitsValue}>
						{props.visits.busiestTime
							? `${moment
									.tz(props.visits.busiestTime.startTime, tz)
									.format(translationService.getTimeFormat())} - ` +
							  `${moment
									.tz(props.visits.busiestTime.endTime, tz)
									.format(translationService.getTimeFormat())}`
							: EMPTY_ITEM_CONTENT}
					</div>
				</div>
			</div>
		</HeaderBox>
	);
};

export { LocationVisits };
