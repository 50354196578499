import React from 'react';
import { map } from 'lodash';
import { ManagerChip } from './ManagerChip/ManagerChip';
import { ISvgElementPoint } from '../../interfaces/ISvgElementPoint';
import { IManagerMergedTag } from '../../interfaces/IManagerMergedTag';

interface IManagerChipsContainerProps {
	mergedManagersForFloor: IManagerMergedTag[];
	managerPointForFloor: ISvgElementPoint;
}

const ManagerChipsContainer = React.forwardRef(function ManagerChipsContainer(
	props: IManagerChipsContainerProps,
	ref: any
) {
	const { mergedManagersForFloor, managerPointForFloor, ...others } = props;

	const maxChipWidth: number = Number(managerPointForFloor.width) / mergedManagersForFloor.length;

	return (
		<div
			{...others}
			ref={ref}
			style={{
				position: 'absolute',
				top: managerPointForFloor.top,
				left: managerPointForFloor.left,
				right: managerPointForFloor.right,
				maxWidth: managerPointForFloor.width,
				display: 'flex',
				overflow: 'hidden',
				justifyContent: 'flex-start',
			}}
			data-testid={'managerChipsContainer'}
		>
			{map(mergedManagersForFloor, (mergedManager: IManagerMergedTag) => {
				return (
					<ManagerChip
						key={`floor_${managerPointForFloor.floorId}_${mergedManager.manager.name}`}
						chipBackground={mergedManager.manager.backgroundColor}
						chipText={mergedManager.manager.name}
						maxWidth={maxChipWidth}
					/>
				);
			})}
		</div>
	);
});

export default ManagerChipsContainer;
