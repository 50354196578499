import { IMergedAreaSequenceItem } from '@interfaces/IMergedAreaSequenceItem';
import { ISequenceItem } from '@interfaces/ISequenceItem';
import { Loader } from '@shared/components/Loader/Loader';
import { IMatrixOptions } from '@src/Components/Matrix/interfaces/IMatrixOptions';
import { IMatrixTable } from '@src/Components/Matrix/interfaces/IMatrixTable';
import { translationService } from '@src/servicesInitializers';
import { Dictionary, groupBy } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { PlanningContext, WorkplanContext } from '../../WorkPlan';
import { OverviewTableCell } from './OverviewTableCell/OverviewTableCell';
import classes from './styles.module.scss';
import { Matrix } from '@src/Components/Matrix/Matrix';
import { activitySequenceMatrixConstants } from '@src/Components/WorkPlan/ActivityProgressTracker/components/ActivitySequenceMatrix/constants';
import { useLargeScreen } from '@src/hooks/custom.hooks';
import { FloorHeaderSeparator } from '@src/Components/WorkPlan/ActivityProgressTracker/components/ActivitySequenceMatrix/FloorHeaderSeparator/FloorHeaderSeparator';
import { IConfigArea } from '../../../../../interfaces/IConfigArea';
import { TableModeEnum } from '../ProgressTrackerTab';

import { isAsiComplete } from '@shared/utils/asi.utils';
import { IActivitySequenceItemCellData } from '@src/Components/WorkPlan/ActivityProgressTracker/components/ActivitySequenceMatrix/cellComponents/ActivitySequenceItemCell/ActivitySequenceItemCell';

interface IOverviewTableProps {
	areaSequenceItemsByFloor: Dictionary<IMergedAreaSequenceItem[] | null> | undefined;
	isInitialLoading?: boolean;
	hasProfessionFilter: boolean;
}

export const OverviewTable = (props: IOverviewTableProps) => {
	const {
		matrix: { floorsList },
		sequenceItems,
	} = useContext(WorkplanContext)!;
	const { matrixTopScrollPosition, setMatrixTopScrollPosition } = useContext(PlanningContext)!;
	const [matrixCellWidth, setMatrixCellWidth] = useState<number>(activitySequenceMatrixConstants.minCellWidth);
	const [startingScrollPosition, setStartingScrollPosition] = useState<number | undefined>(undefined);
	const isLargeScreen: boolean = useLargeScreen();

	useEffect(() => {
		setStartingScrollPosition(matrixTopScrollPosition?.[TableModeEnum.FLOORS] || 0);
	}, []);

	useEffect(() => {
		const numberOfSequenceItems: number = sequenceItems.length;
		const matrixContainerWidth: number = isLargeScreen
			? activitySequenceMatrixConstants.defaultMatrixWidthLargeScreen
			: activitySequenceMatrixConstants.defaultMatrixWidthNarrowScreen;
		const totalContainerSpace: number =
			(matrixContainerWidth ?? activitySequenceMatrixConstants.defaultMatrixWidthNarrowScreen) -
			activitySequenceMatrixConstants.tocWidth;
		const itemsWidthWithMaxCellWidth: number = numberOfSequenceItems * activitySequenceMatrixConstants.maxCellWidth;

		if (itemsWidthWithMaxCellWidth <= totalContainerSpace) {
			setMatrixCellWidth(activitySequenceMatrixConstants.maxCellWidth);
			return;
		}

		setMatrixCellWidth(activitySequenceMatrixConstants.minCellWidth);
	}, [sequenceItems, isLargeScreen]);

	const handleTopScroll = (topScroll: number) => {
		setMatrixTopScrollPosition((prevTopScroll) => ({
			...prevTopScroll,
			[TableModeEnum.FLOORS]: topScroll + 1,
		}));
	};

	const matrixRows: number[][] = floorsList.map((floor: IConfigArea) => {
		const floorAreaSequenceItems: IMergedAreaSequenceItem[] = props.areaSequenceItemsByFloor?.[floor.floorId] || [];

		if (!floorAreaSequenceItems.length) {
			return [];
		}

		const asisGroupedBySequenceItemId: Dictionary<IMergedAreaSequenceItem[]> = groupBy(
			floorAreaSequenceItems,
			(areaSequenceItem: IMergedAreaSequenceItem) => areaSequenceItem.sequenceItemId
		);
		const result = sequenceItems.map((sequenceItem: ISequenceItem) => {
			const sequenceItemAsis: IMergedAreaSequenceItem[] | undefined =
				asisGroupedBySequenceItemId[sequenceItem._id];
			const notDisabledAsis: IMergedAreaSequenceItem[] | undefined = sequenceItemAsis?.filter(
				(sequenceItemAsi: IMergedAreaSequenceItem) =>
					!(sequenceItemAsi as IActivitySequenceItemCellData).isDisabled
			);
			if (!notDisabledAsis?.length) {
				return -1;
			}
			const numberOfDoneSequenceItems: number = sequenceItemAsis.filter((sequenceItem: IMergedAreaSequenceItem) =>
				isAsiComplete(sequenceItem.status)
			).length;
			return Math.round((numberOfDoneSequenceItems / sequenceItemAsis.length) * 100);
		});
		return result;
	});

	const overviewTableMatrix: IMatrixTable = {
		id: 'overviewTable',
		rowHeaders: floorsList.map((floor) => floor.floorNick),
		commonHeader: translationService.get('floors'),
		rows: matrixRows,
	};
	const overviewTableOptions: IMatrixOptions = {
		cellComponent: OverviewTableCell,
		rowHeaderComponent: (props: any) => {
			return <div className={classes.rowHeader}>{props?.data}</div>;
		},
		commonHeaderComponent: FloorHeaderSeparator,
		disableColHeaderHover: true,
		disableDnd: true,
	};
	if (props.isInitialLoading) {
		return <Loader />;
	}

	return (
		<div className={classes.overviewMatrix}>
			<Matrix
				tables={[overviewTableMatrix]}
				options={overviewTableOptions}
				styleOptions={{
					cellHeight: '32px',
					cellWidth: `${matrixCellWidth}px`,
					colHeaderHeight: '130px',
					rowHeaderWidth: '150px',
				}}
				hideStarredSection
				onTopScroll={handleTopScroll}
				topScrollPosition={startingScrollPosition}
			/>
		</div>
	);
};
