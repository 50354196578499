import React from 'react';
import classes from './styles.module.scss';
import { ITag } from '../../../../interfaces';
import { IMergedTag } from 'src/interfaces/IMergedTag';
import { TranslationService } from '@shared/services/translation.service';
import { guestTradeId } from '@shared/constants/professions.constants';
import { translationService } from '../../../../index';

interface IProps {
	tag: ITag | IMergedTag;
}

const getTagEntityDisplayText = (tag: ITag, translationService: TranslationService): string => {
	return translationService.get(
		tag.manager ? 'manager' : !tag.name ? (tag.profession?.tradeId === guestTradeId ? 'visitor' : 'Worker') : ''
	);
};

export const TagEntityCard = (props: IProps) => {
	return (
		<>
			{((props.tag as ITag).manager || !(props.tag as ITag).name) && (
				<div className={classes.EntityCard_container} data-testid="TagEntityCard">
					{getTagEntityDisplayText(props.tag as ITag, translationService)}
				</div>
			)}
		</>
	);
};
