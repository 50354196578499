import { ISafetyEvent } from '../interfaces/ISafetyEvent';
import { translationService } from '../index';
import { IMergedFloorView, SafetyViolationTypes } from '../interfaces';
import { store } from '../store/store';
import { COLORS } from '../constants';
import { getProfessionBackgroundColor } from '@shared/utils/professions.utils';
import { useSelector } from 'react-redux';
import { selectProjectId } from '@store/slices/project.slice';

export const getSafetyViolationTypeTranslationVars = (
	tradeId: string | undefined,
	safetyViolationType: SafetyViolationTypes
): { [key: string]: string } => {
	if (safetyViolationType === SafetyViolationTypes.requiredRoleOnSite && tradeId) {
		return { roleName: translationService.get(tradeId) };
	}
	if (safetyViolationType === SafetyViolationTypes.hoistOperatorRequired) {
		const floors: IMergedFloorView[] = store.getState().floorsViewReducer.floors;
		const hoistsCount: number = floors.filter((floor) => floor.isHoist).length;
		if (hoistsCount > 1) {
			return {
				eventText: translationService.get('multipleHoistOperatorRequired'),
			};
		}
		return { eventText: translationService.get('singleHoistOperatorRequired') };
	}
	return {};
};

export const getSafetyEventColor = (safetyEvent: ISafetyEvent, projectId?: string): string => {
	return (
		(safetyEvent.profession && projectId && getProfessionBackgroundColor(safetyEvent.profession, projectId)) ||
		safetyEvent.trade?.backgroundColor ||
		safetyEvent.manager?.backgroundColor ||
		COLORS.primaryColor
	);
};
