import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { IPeriod } from '@src/Components/PeriodsGraph/PeriodsGraph';
import { selectTimezone } from '@store/slices/project.slice';
import classes from './styles.module.scss';
import { TrusstorTooltip } from '@shared/components/TrusstorTooltip/TrusstorTooltip';

interface IPeriodGraphRowProps {
	icon: string;
	color: string;
	titleText: string;
	extraTitleText?: string;
	secondTitleText: string;
	titleInfoText?: string;
	secondTitleInfoText?: string;
	graphCubes: number;
	periods: IPeriod[];
}

const PeriodGraphRow = (props: IPeriodGraphRowProps) => {
	const graphCubeEl = useRef<HTMLDivElement>(null);
	const tz: string = useSelector(selectTimezone)!;
	const [graphCubeElementWidth, setGraphCubeElementWidth] = useState<number>(0);

	useEffect(() => {
		setGraphCubeElementWidth(graphCubeEl.current!.offsetWidth);
	}, [graphCubeEl.current]);

	const getPeriods = (): JSX.Element[] => {
		const getPeriodInset = (period: IPeriod | undefined): number => {
			if (!period) {
				return 0;
			}
			const oneHourPercent: number = 100 / (props.graphCubes + props.graphCubes / 12);
			const exactHours: number = moment.tz(period.startTimestamp, tz).hours();
			const partialHour: number = moment.tz(period.startTimestamp, tz).minutes() / 60;
			return oneHourPercent * (exactHours + partialHour);
		};

		const getInset = (period: IPeriod): string => {
			const inlineToZero: number = 4;
			const inset: number = getPeriodInset(period);
			return `${inset + inlineToZero}%`;
		};

		const getPeriodWidth = (period: IPeriod): string => {
			const cubeToHour: number = props.graphCubes / 24;
			const oneHourWidth: number = graphCubeElementWidth / cubeToHour;
			const periodTimeHours: number = (period.endTimestamp - period.startTimestamp) / 1000 / 60 / 60;
			if (periodTimeHours < 0.12) return '0px'; // 0.12 === 5min. If period less than 5min, return 0px
			return `${periodTimeHours * oneHourWidth}px`;
		};

		return props.periods.map((workPeriod) => {
			return (
				<div
					key={workPeriod.startTimestamp}
					className={classes.periodBar}
					style={{
						left: getInset(workPeriod),
						width: getPeriodWidth(workPeriod),
						backgroundColor: props.color,
					}}
				></div>
			);
		});
	};

	return (
		<section className={classes.rowContainer}>
			<section className={classes.leftRowSide}>
				<div className={classes.leftRowDetails}>
					<div className={classes.leftRowTitleContainer}>
						<img src={props.icon} alt="tag icon" />
						<div className={classes.titleText}>{props.titleText}</div>
						<TrusstorTooltip
							tooltipComponent={<p>{props.extraTitleText}</p>}
							idToOverflow={`periodGraph_extraTitleText_${props.extraTitleText}`}
							cleanStyling
						>
							<div
								className={classes.extraTitleText}
								id={`periodGraph_extraTitleText_${props.extraTitleText}`}
							>
								{props.extraTitleText}
							</div>
						</TrusstorTooltip>
					</div>
					<div className={classes.leftRowInfoContainer}>
						<div className={classes.titleInfoText}>{props.titleInfoText}</div>
						{props.secondTitleInfoText && props.titleInfoText && <div className={classes.divider}>|</div>}
						{props.secondTitleInfoText && (
							<div className={classes.secondTitleText}>{props.secondTitleInfoText}</div>
						)}
					</div>
				</div>
				<div className={classes.secondTitleText}>{props.secondTitleText}</div>
			</section>
			<section className={classes.graphContainer}>
				{new Array(props.graphCubes + props.graphCubes / 12).fill(null).map((cube, index) => {
					return (
						<div
							className={classes.cube}
							style={{ width: `calc(100% / ${props.graphCubes + props.graphCubes / 12}` }}
							ref={graphCubeEl}
							key={index}
						></div>
					);
				})}
				{/*(props.graphCubes / 12) for the clock close*/}
				{getPeriods()}
			</section>
		</section>
	);
};

export { PeriodGraphRow };
