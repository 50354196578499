import { requestService, streamService } from '../../index';
import { config } from '../../config';
import { getTimezoneFormattedDate } from '../../utils/dateUtils';
import { ISafetyReport } from '../../interfaces';
import { resetSafetyReport, updateSafetyReport } from '../slices/safetyReport.slice';

export const getSafetyDailyReport = (projectId: string, date: Date | number, tz: string) => async (dispatch) => {
	const serverFormatDate: string = getTimezoneFormattedDate(tz, date);
	streamService.openStream(
		`/reports/professionsSafety/stream?date=${serverFormatDate}&projectId=${projectId}&interval=${config.reportsInterval}`,
		(message) => {
			const report: ISafetyReport = JSON.parse(message.data);
			dispatch(updateSafetyReport({ report }));
		}
	);
};

export const getSafetyPeriodicReport =
	(projectId: string, period: [Date, Date] | [number, number], tz: string) => async (dispatch) => {
		const serverFormatDateFrom: string = getTimezoneFormattedDate(tz, period[0]);
		const serverFormatDateTo: string = getTimezoneFormattedDate(tz, period[1]);

		streamService.closeStreamIncludes(`/reports/professionsSafety/stream`);
		const safetyReport: ISafetyReport = await requestService.get(
			`/reports/periodicProfessionsSafety?dateFrom=${serverFormatDateFrom}&dateTo=${serverFormatDateTo}&projectId=${projectId}&interval=${config.reportsInterval}`
		);

		dispatch(updateSafetyReport({ report: safetyReport }));
	};

export const stopGettingSafetyReport = () => (dispatch) => {
	streamService.closeStreamIncludes(`/reports/professionsSafety/stream`);
	dispatch(resetSafetyReport());
};
