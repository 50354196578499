import React, { useRef } from 'react';
import { IProfession } from '../../../../../../../interfaces';
import { PRODUCTIVITY_HEADER_HEIGHT, PRODUCTIVITY_HEADER_MAX_WIDTH } from '../../../../../../../constants';
import { TrusstorTooltip } from '@shared/components/TrusstorTooltip/TrusstorTooltip';
import { getProfessionDisplayText } from '../../../../../../../utils/professions.utils';
import { translationService } from '../../../../../../../index';
import classes from './styles.module.scss';
import { getTradeTranslation } from '@utils/translations.utils';

interface IWorkResourcesColumnProps {
	profession?: IProfession;
	text?: string;
}

const ProductivityColumnHeader = (props: IWorkResourcesColumnProps) => {
	const headerTitleEl = useRef(null);

	return (
		<div className={classes.headerContainer} style={{ height: PRODUCTIVITY_HEADER_HEIGHT }}>
			{props.profession ? (
				<TrusstorTooltip
					tooltipComponent={<p className={classes.title}>{getProfessionDisplayText(props.profession)}</p>}
					cleanStyling
					refChildren={headerTitleEl}
				>
					<div style={{ maxHeight: PRODUCTIVITY_HEADER_MAX_WIDTH }} ref={headerTitleEl}>
						<TrusstorTooltip
							tooltipComponent={
								<p className={classes.title}>{getProfessionDisplayText(props.profession)}</p>
							}
							cleanStyling
							refChildren={headerTitleEl}
						>
							<div
								className={classes.titleContainer}
								style={{ maxHeight: PRODUCTIVITY_HEADER_MAX_WIDTH }}
							>
								<p className={classes.contractor}>{props.profession.contractor}</p>
								<p className={classes.mainText}>
									{getTradeTranslation(props.profession)}
									{props.profession.specialty ? ',' : ''}
								</p>
								<p className={classes.specialty}>{props.profession.specialty}</p>
							</div>
						</TrusstorTooltip>
					</div>
				</TrusstorTooltip>
			) : (
				<div>
					<div className={classes.titleContainer} style={{ maxHeight: PRODUCTIVITY_HEADER_MAX_WIDTH }}>
						<div></div>
						<p className={classes.mainText}>{props.text}</p>
					</div>
				</div>
			)}
		</div>
	);
};

export { ProductivityColumnHeader };
