import { useEffect, useState } from 'react';
import { CalendarToggleOptions } from '../constants';
import { ICalendarSection, IProfession } from '../interfaces';
import { IFloor } from '@shared/interfaces/IFloor';
import { getShouldShowProfessionDisplayProperties } from '@shared/utils/professions.utils';
import { IProfessionDisplaySettings } from '@shared/interfaces/IProfessionDisplaySettings';

interface IGetCalendarSectionArgs {
	projectId: string;
	toggleStateProfessionsFloors: CalendarToggleOptions;
	floorsList: IFloor[];
	professions: IProfession[];
}

const getCalendarFloorSection = (floor: IFloor): ICalendarSection => {
	return {
		title: floor.floorNick,
		id: floor.floorId,
	};
};

export const getCalendarSections = ({
	toggleStateProfessionsFloors,
	floorsList,
	professions,
}: IGetCalendarSectionArgs): ICalendarSection[] => {
	return toggleStateProfessionsFloors === CalendarToggleOptions.FLOORS
		? floorsList.map((floor) => getCalendarFloorSection(floor))
		: professions.map((profession: IProfession) => {
				const professionSection: ICalendarSection = {
					id: profession._id,
					sortIndex: profession.sortIndex,
					profession,
				};

				return professionSection;
		  });
};

export const useCalendarSectionsState = ({
	toggleStateProfessionsFloors,
	floorsList,
	professions,
	projectId,
}: IGetCalendarSectionArgs) => {
	const [calendarSections, setCalendarSections] = useState<ICalendarSection[]>([]);
	useEffect(() => {
		const calendarSectionsResponse: ICalendarSection[] = getCalendarSections({
			toggleStateProfessionsFloors,
			floorsList,
			professions,
			projectId,
		});
		setCalendarSections(calendarSectionsResponse);
	}, [toggleStateProfessionsFloors, floorsList.length, professions.length]);
	return calendarSections;
};
