import classes from './styles.module.scss';
import React, { useCallback, useEffect } from 'react';
import { IMatrixOptions } from '@src/Components/Matrix/interfaces/IMatrixOptions';
import { MatrixTable } from '@src/Components/Matrix/MatrixTable/MatrixTable';
import { IMatrixTable } from '@src/Components/Matrix/interfaces/IMatrixTable';
import { IMatrixStylesOptions } from '@src/Components/Matrix/interfaces/IMatrixStylesOptions';
import { accessLevelService, translationService } from '@src/servicesInitializers';
import greyFloorsIcon from '../../assets/workplanIcons/greyFloorsIcon.svg';
import { compareProps } from '../../utils/generalUtils';
import classnames from 'classnames';
import { at } from 'lodash';
import { MatrixColHeader } from './MatrixColHeader/MatrixColHeader';
import { LeftRightSelection } from '@src/Components/LeftRightSelection/LeftRightSelection';
import { useWidthOverflow } from '@src/hooks/custom.hooks';
import { IProfession } from '@shared/interfaces/IProfession';
import { AddSequenceItem } from '@src/Components/Matrix/cellComponents/MatrixHeader/AddSequenceItem/AddSequenceItem';
import { useMatrixHeaders } from '@src/Components/Matrix/useMatrixHeaders';
import { ISequenceItem } from '@interfaces/ISequenceItem';
import { useVerticalScrollPosition } from '@src/hooks/useVerticalScrollPosition';

export interface IMatrixColHeader extends ISequenceItem {
	description: string;
	isInitial?: boolean;
	id: string;
	[key: string]: any;
}

export interface IMatrixProps {
	hoveredCell?: { headerId?: string; rowId?: string } | null;
	tables: IMatrixTable[];
	options: IMatrixOptions;
	handleStarClick?: (headerData: any) => Promise<void>;
	styleOptions?: IMatrixStylesOptions;
	handleProfessionUpdate?: (sequenceId: string, profession: IProfession) => void;
	handleDescriptionChange?: (text: string, sequenceItemId: string) => void;
	onTableIntersectingChange?: (tableId: string) => void;
	containerId?: string;
	hideStarredSection?: boolean;
	onHeadersOrderChange?: (headersIds: string[]) => void;
	onTablesUpdate?: (tables: IMatrixTable[]) => void;
	onTopScroll?: (number) => void;
	topScrollPosition?: number;
	showSequenceMenu?: boolean;
	isTextEditable?: boolean;
	deleteSequenceItem?: (sequenceId: string) => void;
	onMouseLeave?: () => void;
	showToc?: boolean;
	handleEditSequenceItem?: (
		sequenceItemId: string,
		profession: IProfession,
		description: string,
		isStarred: boolean
	) => void;
}

const scrollDistance: number = 660;

export const Matrix = React.memo((props: IMatrixProps) => {
	const separatorHeight: number = 32;
	const [intersectedTableId, setIntersectedTableId] = React.useState<string | null>(null);
	const [matrixElement, setMatrixElement] = React.useState<HTMLDivElement | null>(null);
	const containterRefScroll: number | undefined = props.onTopScroll
		? useVerticalScrollPosition(matrixElement)
		: undefined;
	const [intersectedTableIndex, setIntersectedTableIndex] = React.useState<number>(0);
	const { headers, tables, colIndexesToShow, moveHeader, onDragStart, onFinishDrag, headerIdsOrderRef } =
		useMatrixHeaders(props.tables, props.showToc);

	const handleTableIntersectingChange = useCallback(
		(tableId: string, isIntersecting: boolean, tableIndex: number) => {
			setIntersectedTableId(tableId);
			setIntersectedTableIndex(tableIndex);
		},
		[]
	);

	const handleSubmitHeadersOrder = (dragIndex: number) => {
		onFinishDrag(dragIndex, () => props.onHeadersOrderChange?.(headerIdsOrderRef.current));
	};

	useEffect(() => {
		props.onTablesUpdate?.(tables);
	}, [tables]);

	useEffect(() => {
		if (containterRefScroll) {
			props.onTopScroll!(containterRefScroll);
		}
	}, [containterRefScroll]);

	useEffect(() => {
		if (!props.onTableIntersectingChange || !intersectedTableId) {
			return;
		}

		props.onTableIntersectingChange(intersectedTableId);
	}, [intersectedTableId, props.onTableIntersectingChange]);

	const colHeaderStyle: React.CSSProperties = {
		height: props.styleOptions?.colHeaderHeight ?? 'auto',
		maxHeight: props.styleOptions?.colHeaderHeight ?? 'auto',
		width: props.styleOptions?.cellWidth ?? 'auto',
		maxWidth: props.styleOptions?.cellWidth ?? 'auto',
	};

	const commonHeaderStyle: React.CSSProperties = {
		height: props.styleOptions?.colHeaderHeight ?? 'auto',
		maxHeight: props.styleOptions?.colHeaderHeight ?? 'auto',
		width: props.styleOptions?.rowHeaderWidth ?? 'auto',
		maxWidth: props.styleOptions?.rowHeaderWidth ?? 'auto',
	};

	const isAllTablesHasOnlyOneRow: boolean = props.tables.every((table) => table.rows.length === 1);
	const colHeadersToShow: IMatrixColHeader[] = colIndexesToShow ? at(headers, colIndexesToShow) : headers;

	useEffect(() => {
		if (props.topScrollPosition && matrixElement) {
			const behavior: any = 'instant';
			matrixElement.scrollTo({ top: props.topScrollPosition, behavior });
		}
	}, [props.topScrollPosition, matrixElement]);

	const scrollToRight = () => {
		if (matrixElement) {
			matrixElement.scrollLeft = matrixElement.scrollLeft + scrollDistance;
		}
	};

	const scrollToLeft = () => {
		if (matrixElement) {
			matrixElement.scrollLeft = matrixElement.scrollLeft - scrollDistance;
		}
	};

	const isOverflow: boolean = useWidthOverflow({ current: matrixElement });

	const renderHeaders = useCallback(
		(headerData: IMatrixColHeader, index: number) => {
			return (
				<MatrixColHeader
					hoveredSequenceItemId={props.hoveredCell?.headerId}
					isTextEditable={props.isTextEditable}
					deleteSequenceItem={props.deleteSequenceItem}
					headerIndex={index}
					handleDescriptionChange={props.handleDescriptionChange}
					key={headerData.id}
					headerData={headerData}
					handleProfessionUpdate={props.handleProfessionUpdate}
					colHeaderStyle={colHeaderStyle}
					options={props.options}
					handleStarClick={props.handleStarClick}
					showSequenceMenu={props.showSequenceMenu}
					hideStarredSection={props.hideStarredSection}
					moveCard={moveHeader}
					onDragFinish={handleSubmitHeadersOrder}
					onDragStart={onDragStart}
					handleEditSequenceItem={props.handleEditSequenceItem}
				/>
			);
		},
		[
			props.deleteSequenceItem,
			props.options,
			props.handleStarClick,
			props.hideStarredSection,
			props.handleProfessionUpdate,
			props.showSequenceMenu,
		]
	);

	return (
		<div className={classes.matrixContainer} ref={setMatrixElement} onMouseLeave={props.onMouseLeave}>
			<table className={classes.matrix}>
				{props.tables && props.tables.length > 0 && (
					<div className={classes.headersContainer}>
						<div className={classnames(classes.colHeader, classes.floorsTitle)} style={commonHeaderStyle}>
							<img src={greyFloorsIcon} alt={'floorsIcon'} />
							<div className={classes.floorsText}>{translationService.get('floors')}</div>
							{isOverflow && <LeftRightSelection leftAction={scrollToLeft} rightAction={scrollToRight} />}
						</div>
						{props.tables &&
							props.tables.length > 0 &&
							colHeadersToShow.map((headerData, index) => renderHeaders(headerData, index))}
						{accessLevelService.hasAccess('workplanActivityActions') && <AddSequenceItem />}
					</div>
				)}
				<div className={classes.matrixTableContainer} id={props.containerId}>
					{tables.map((table, tableIndex: number) => {
						const shouldShowEmptyTable: boolean = Math.abs(intersectedTableIndex - tableIndex) > 2;

						return (
							<MatrixTable
								onMouseLeave={props.onMouseLeave}
								hoveredRowId={props.hoveredCell?.rowId}
								showEmptyRows={shouldShowEmptyTable}
								rowLength={headers.length}
								matrixElement={matrixElement}
								table={table}
								styleOptions={props.styleOptions}
								options={props.options}
								onTableIntersectingChange={handleTableIntersectingChange}
								tableIndex={tableIndex}
								isTheLastTable={tableIndex === props.tables.length - 1}
								separatorHeight={separatorHeight}
								hideRowHeader={isAllTablesHasOnlyOneRow}
								columnsIndexesToShow={colIndexesToShow}
							/>
						);
					})}
				</div>
			</table>
		</div>
	);
}, compareProps);
