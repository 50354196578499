import { useQuery } from '@tanstack/react-query';
import { storageService } from '@src/index';

export const useProjectImageQuery = (projectId: string): { projectImage: string | undefined } => {
	const { data: projectImage } = useQuery(
		['projectImage', projectId],
		() => storageService.getProjectImage(projectId),
		{
			useErrorBoundary: true,
		}
	);

	return { projectImage };
};

export const useOrganizationLogoQuery = (organizationId?: string): { organizationLogo: string | undefined } => {
	const oneHourMS = 3600000;
	const { data: organizationLogo } = useQuery(
		['organizationLogo', organizationId],
		() => storageService.getOrganizationLogo(organizationId!, 'dark'),
		{
			useErrorBoundary: true,
			staleTime: oneHourMS,
			cacheTime: oneHourMS,
		}
	);

	return { organizationLogo };
};
