import React from 'react';
import classes from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { IInvestigationReport } from '@shared/interfaces/IInvestigationReport';
import { Loader } from '@shared/components/Loader/Loader';
import { updateInvestigationReport } from '@utils/analysisCenter.utils';
import { AnalysisCenterReportHeader } from '../AnalysisCenterReportHeader/AnalysisCenterReportHeader';
import { ObserveLocationFloorSection } from '../ObserveLocationReportPage/ObserveLocationFloorSection/ObserveLocationFloorSection';
import { ObserveLocationEventsSection } from './ObserveLocationMenu/ObserveLocationEventsSection';
import { ObserveLocationTagsSection } from './ObserveLocationData/ObserveLocationTagsSection';
import { IObserveLocationFloorDataStats } from '../ObserveLocationReportPage/ObserveLocationFloorSection/ObserveLocationFloorSection';
import moment from 'moment-timezone';
import { selectProjectId, selectTimezone } from '@store/slices/project.slice';
import { IObserveLocationReport } from '@shared/interfaces/IObserveLocationReport';
import {
	useDailyActivitiesQuery,
	useInvestigationReportQuery,
	useObserveLocationQuery,
} from '@src/hooks/queries/analysisCenter.queries.hooks';
import { useSafetyEventByTimeRangeQuery } from '@src/hooks/queries/safetyEvent.queries.hooks';
import { useRenderMonitoring } from '@shared/hooks/monitoring.hooks';

const ObserveLocationReportPageProps = (props: { match }) => {
	useRenderMonitoring('ObserveLocationReportPageProps');
	const projectId: string = useSelector(selectProjectId)!;
	const tz: string = useSelector(selectTimezone)!;
	const reportId: string = props.match.params.reportId;
	const {
		investigationReport: currentReport,
		isInvestigationReportLoading,
		refetchInvestigationReport,
	} = useInvestigationReportQuery<IObserveLocationReport>(reportId);
	const { dailyActivities } = useDailyActivitiesQuery(
		projectId,
		tz,
		currentReport?.investigatedTimestamp,
		currentReport?.location[0].floorId
	);
	const { safetyEvents } = useSafetyEventByTimeRangeQuery(
		projectId,
		tz,
		moment.tz(currentReport?.investigatedTimestamp, tz).startOf('day'),
		moment.tz(currentReport?.investigatedTimestamp, tz).startOf('day').add(1, 'day'),
		currentReport?.location.length === 1 ? currentReport?.location[0].areaId : undefined,
		currentReport?.location.length > 1 ? currentReport?.location[0].floorId : undefined
	);
	const { observeLocationReport } = useObserveLocationQuery(projectId, tz, currentReport);

	const dispatch = useDispatch();

	const updateReport = async (reportId: string, attributesToUpdate: Partial<IInvestigationReport>) => {
		await updateInvestigationReport(reportId, attributesToUpdate, dispatch, refetchInvestigationReport);
	};

	const getFloorDataBrief = (): IObserveLocationFloorDataStats => {
		return {
			safetyEvents: safetyEvents.length,
			activities: dailyActivities.length,
			equipment: observeLocationReport?.equipment.length || 0,
			totalPersonnel: observeLocationReport?.Personnel.length || 0,
		};
	};

	if (isInvestigationReportLoading) return <Loader />;
	return (
		<section className={classes.reportPageContainer}>
			<AnalysisCenterReportHeader
				updateReport={updateReport}
				reportId={reportId}
				title={currentReport?.title}
				displayDate={new Date(currentReport?.investigatedTimestamp)}
				description={currentReport?.description}
				disableDownload={!currentReport}
			/>
			<section className={classes.reportDataContainer}>
				<ObserveLocationFloorSection currentReport={currentReport} floorDataStats={getFloorDataBrief()} />
				<section className={classes.reportDescriptionContainer}>
					<ObserveLocationEventsSection
						currentReport={currentReport}
						equipments={observeLocationReport?.equipment}
						safetyEvents={safetyEvents}
						dailyActivities={dailyActivities}
					/>
					{observeLocationReport && (
						<ObserveLocationTagsSection
							currentReport={currentReport}
							observeLocationReport={observeLocationReport}
						/>
					)}
				</section>
			</section>
		</section>
	);
};

export { ObserveLocationReportPageProps };
