import React from 'react';
import { FilterCard, IFilterCardProps } from '../FilterCard/FilterCard';
import { IssuePriority } from '../../../interfaces/IIssueShared';
import { IconNames } from '../../TrusstorIconShared/IconNames.enum';
import { IconColor } from '../../TrusstorIconShared/TrusstorIconShared';
import { useSharedServices } from '../../../hooks/sharedServices.context';

interface IIssuePriorityFilterCardProps extends IFilterCardProps {
	priority: IssuePriority;
}

export const IssuePriorityFilterCard = (props: IIssuePriorityFilterCardProps) => {
	const { translationService } = useSharedServices();

	const getPriorityMainText = (issuePriority: IssuePriority): string => {
		return translationService.get(issuePriority.toLowerCase());
	};

	return (
		<FilterCard
			{...props}
			icon={props.priority === IssuePriority.CRITICAL ? IconNames.star : undefined}
			iconColor={IconColor.Grey600}
			text={getPriorityMainText(props.priority)}
		/>
	);
};
