import { IFloor } from '../interfaces/IFloor';
import { IFloorChipData } from '../interfaces/IFloorChipData';
import { IBaseFloor } from '../interfaces/IBaseFloor';
// @ts-ignore
import { compact, get, sortBy, uniqBy, uniqueId } from 'lodash';
import { SORT_ORDER } from '../constants/constants';
import { floorTypes } from '../constants/floors.constants';
import { capitalizeFirstLetter } from './text.utils';
import { IWorkHoursPerActivity } from '../interfaces/IWorkHoursPerActivity';
import { IMergedFloorView } from '../interfaces/IMergedFloorView';
import { IProfession } from '../interfaces/IProfession';
import { IProfessionWithActiveTags } from '../interfaces/IProfessionWithActiveTags';
import { TranslationService } from '../services/translation.service';

export const getActiveFloorsCountFromFloors = (floors: IMergedFloorView<IProfession>[]): number => {
	return floors.filter((floor: IMergedFloorView<IProfession>) => {
		const { activeProfessions } = floor;
		const tagsWithActiveProfessions = floor.tags.filter((tag: any) => {
			return activeProfessions.some((activeProfession: IProfessionWithActiveTags<IProfession>) => {
				if (!tag.profession) return false;
				return activeProfession._id === tag.profession._id;
			});
		});

		return tagsWithActiveProfessions.length && !floor.isSpecialFloor && !floor.isMovingFloor;
	}).length;
};

export const sortActivitiesGroupByFloors = (
	activitiesGroup: IWorkHoursPerActivity[],
	orderEnum: SORT_ORDER = SORT_ORDER.ASCENDING
) => {
	return sortBy(activitiesGroup, (activityGroup: IWorkHoursPerActivity) =>
		getFloorNumberToSort(activityGroup.floor, orderEnum)
	);
};

export const getFloorChipFromRangeFloors = (
	rangeFloors: [IFloor | undefined, IFloor | undefined]
): IFloorChipData | undefined => {
	if (!rangeFloors[0] || !rangeFloors[1]) {
		return;
	}
	const sortedRangeFloors: [IFloor, IFloor] = [...rangeFloors].sort(
		(floor1, floor2) => Number(floor1!.floorId) - Number(floor2!.floorId)
	) as [IFloor, IFloor];
	return {
		key: uniqueId(),
		label: getFloorChipText(sortedRangeFloors[0], sortedRangeFloors[1]),
		fromFloor: sortedRangeFloors[0],
		toFloor: sortedRangeFloors[1],
	};
};

export const getFloorsChipFromFloorsList = (floorsList: IBaseFloor[]): IFloorChipData[] => {
	const floorsChipResult: IFloorChipData[] = [];
	const sortedFloors: IBaseFloor[] = sortBy(floorsList, (floor) => {
		return Number(floor.floorId);
	});

	let minFloor: IBaseFloor = sortedFloors[0];
	let currentFloorId: number = Number(minFloor.floorId);
	const finalIndex: number = sortedFloors.length - 1;
	sortedFloors.forEach((floor, index) => {
		if (isNaN(Number(floor.floorId))) {
			floorsChipResult.push(getNewChipData(floor, floor));
			if (index !== finalIndex) {
				minFloor = sortedFloors[index + 1];
				currentFloorId = Number(minFloor.floorId);
			}
			return;
		}

		if (index === finalIndex || doesNextFloorBeginNewSequence(sortedFloors[index + 1], currentFloorId)) {
			floorsChipResult.push(getNewChipData(minFloor, floor));
			if (index !== finalIndex) {
				minFloor = sortedFloors[index + 1];
				currentFloorId = Number(minFloor.floorId);
			}
			return;
		}
		currentFloorId++;
	});
	return floorsChipResult;
};

export const getFloorChipText = (fromFloor: IBaseFloor, toFloor: IBaseFloor): string => {
	return fromFloor.floorId === toFloor.floorId
		? `${capitalizeFirstLetter(fromFloor.floorNick)}`
		: `${capitalizeFirstLetter(fromFloor.floorNick)} - ${capitalizeFirstLetter(toFloor.floorNick)}`;
};

export const doesNickStartsWithFloor = (floorNick: string, translationService: TranslationService): boolean => {
	return floorNick.toLowerCase().startsWith(translationService.get('floor').toLowerCase());
};

const removeFloorPrefix = (floorNick: string, translationService: TranslationService): string => {
	return floorNick.slice(translationService.get('floor').length + 1);
};

export const getUnitedFloorChipText = (
	fromFloor: IBaseFloor,
	toFloor: IBaseFloor,
	translationService: TranslationService
): string => {
	if (fromFloor.floorId === toFloor.floorId) return `${capitalizeFirstLetter(fromFloor.floorNick)}`;
	if (
		doesNickStartsWithFloor(fromFloor.floorNick, translationService) &&
		doesNickStartsWithFloor(toFloor.floorNick, translationService)
	) {
		return `${translationService.get('floors')} ${removeFloorPrefix(
			fromFloor.floorNick,
			translationService
		)} - ${removeFloorPrefix(toFloor.floorNick, translationService)}`;
	}
	return `${capitalizeFirstLetter(fromFloor.floorNick)} - ${capitalizeFirstLetter(toFloor.floorNick)}`;
};

export const getFloorsListFromFloorsChips = (floorChips: IFloorChipData[] | null, floors: IFloor[]): IFloor[] => {
	if (!floorChips) {
		return [];
	}
	const floorsResult: IFloor[] = [];
	floorChips.forEach((floorChip: IFloorChipData) => {
		if (floorChip.fromFloor === floorChip.toFloor) {
			floorsResult.push(floors.find((floor) => floor.floorId === floorChip.fromFloor.floorId)!);
			return;
		}

		let currentFloorId: number = Number(floorChip.fromFloor.floorId);
		while (currentFloorId <= Number(floorChip.toFloor.floorId)) {
			const floorToAdd: IFloor | undefined = floors.find((floor) => floor.floorId === String(currentFloorId));
			if (floorToAdd) {
				floorsResult.push(floorToAdd);
			}
			currentFloorId++;
		}
	});

	return uniqBy(compact(floorsResult), (floor) => floor.floorId);
};

const doesNextFloorBeginNewSequence = (nextFloor: IBaseFloor, currentFloorId: number) =>
	Number(nextFloor.floorId) !== currentFloorId + 1;

const getNewChipData = (fromFloor: IBaseFloor, toFloor: IBaseFloor): IFloorChipData => {
	return {
		key: uniqueId(),
		label: getFloorChipText(fromFloor, toFloor),
		fromFloor: fromFloor,
		toFloor: toFloor,
	};
};

const getFloorNumberToSort = (floor: IBaseFloor, orderEnum: SORT_ORDER): number => {
	if (floor.floorId === floorTypes.pa) {
		return 0;
	}
	return orderEnum === SORT_ORDER.ASCENDING ? parseInt(floor.floorId) : -parseInt(floor.floorId);
};
