import { TranslationService } from '../services/translation.service';

export interface IEntityWithTradeId {
	tradeId: string;
}

export interface IEntityWithTradeGroup {
	tradeGroup: string;
}

export const getTradeTranslationShared = (
	entityWithTradeTranslation: IEntityWithTradeId,
	translationService: TranslationService
): string => {
	return translationService.get(entityWithTradeTranslation.tradeId);
};

export const getTradeGroupTranslationShared = (
	entityWithTradeTranslation: IEntityWithTradeGroup,
	translationService: TranslationService
): string => {
	return translationService.get(entityWithTradeTranslation.tradeGroup);
};
