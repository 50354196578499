import React from 'react';
import classes from './styles.module.scss';
import { closeIcon } from '../../../assets';
import { getRelativeDateFormattedToDisplay } from '../../../utils/dateUtils';
import { TranslationService } from '../../../services/translation.service';
import { RequestService } from '../../../services/request.service';

interface INotificationCardProps {
	icon: React.ReactNode;
	isViewed: boolean;
	buttonElement?: React.ReactNode;
	titleElement: React.ReactNode;
	descriptionElement?: React.ReactNode;
	createdAt: Date;
	tz: string;
	translationService: TranslationService;
	requester: RequestService;
	notificationId: string;
	handleCardClick?: () => void;
	isClickable?: boolean;
}

export const BaseNotificationCard = (props: INotificationCardProps) => {
	const time: string = getRelativeDateFormattedToDisplay(props.createdAt, props.tz, props.translationService);
	const onClickOnClose = async (event) => {
		event.stopPropagation();
		await props.requester.put(`/notification/notifications/${props.notificationId}`, {
			body: {
				isVisible: false,
			},
		});
	};

	return (
		<div
			className={`${classes.container} ${props.isClickable ? classes.pointer : ''}`}
			onClick={props.handleCardClick}
		>
			<img onClick={onClickOnClose} src={closeIcon} className={classes.close} alt={''} />
			<div className={classes.content}>
				<div className={classes.image}>
					{!props.isViewed && <div className={classes.dot} />}
					{props.icon}
				</div>
				<div className={classes.details}>
					{props.titleElement}
					{props.descriptionElement}
					{props.buttonElement}
					<div className={classes.time}>{time}</div>
				</div>
			</div>
		</div>
	);
};
