import React, { useRef, useState } from 'react';
import classes from './styles.module.scss';
import { useHistory } from 'react-router-dom';
import arrow from '@shared/assets/icons/ArrowRight.svg';
import editIcon from '@shared/assets/icons/edit.svg';
import { TrusstorButton } from '@shared/components/buttons/TrusstorButton/TrusstorButton';
import { translationService } from '../../../../index';
import downloadIcon from '@shared/assets/icons/downloadIcon.svg';
import { IInvestigationReport } from '@shared/interfaces/IInvestigationReport';
import calendarIcon from '../../../../assets/TrusstorDateInput/calendarIcon.svg';
import moment from 'moment-timezone';
import { clockIcon } from '../../../../assets/activitiesIcons';
import { useSelector } from 'react-redux';
import { selectTimezone } from '../../../../store/slices/project.slice';
import { useOutsideClick } from '@src/hooks/custom.hooks';
import { TrusstorMenu } from '@shared/components/TrusstorMenu/TrusstorMenu';

interface IReportHeaderProps {
	reportId: string;
	title: string;
	displayDate: Date;
	secondDate?: Date;
	description: string;
	updateReport: (reportId: string, attributesToUpdate: Partial<IInvestigationReport>) => void;
	disableDownload: boolean;
	handleDownloadReport?: (rawReport?: boolean) => Promise<void>;
}

const AnalysisCenterReportHeader = (props: IReportHeaderProps) => {
	const tz: string = useSelector(selectTimezone)!;
	const [showDownloadOptions, setShowDownloadOptions] = useState<boolean>(false);
	const editTitleEl = useRef<HTMLDivElement>(null);
	const history = useHistory();
	const exportMenuWrapperRef = useRef(null);
	useOutsideClick(exportMenuWrapperRef, setShowDownloadOptions, false);
	const handleEditIconClick = () => {
		editTitleEl.current && editTitleEl.current!.focus();
	};

	const handleTitleChange = (event: any) => {
		const newTitle: string = event.target.innerText;
		if (newTitle === props.title) return;
		props.updateReport(props.reportId!, { title: newTitle });
	};

	const handleDescriptionChange = (event: any) => {
		const newDescription: string = event.target.innerText;
		if (newDescription === props.description) return;
		props.updateReport(props.reportId!, { description: newDescription });
	};

	const handleBackClick = () => {
		history.goBack();
	};

	const getDisplayDate = (): string => {
		const DMformat: string = translationService.getChosenLanguage() === 'jpn' ? 'MM月DD日' : 'MMMM Do';
		const DMYformat: string = translationService.getChosenLanguage() === 'jpn' ? 'YYYY年MM月DD日' : 'MMMM Do, YYYY';
		if (props.secondDate) {
			const firstDate: string = moment.tz(new Date(props.displayDate), tz).format(DMformat);
			const secondDate: string = moment.tz(new Date(props.secondDate), tz).format(DMYformat);
			return `${firstDate} - ${secondDate}`;
		}
		return moment.tz(new Date(props.displayDate), tz).format(DMYformat + `${isIncidentPage ? ', h:mm a' : ''}`);
	};

	const isIncidentPage: boolean = window.location.pathname.includes('incident');
	const isRtl: boolean = translationService.getIsRtl();

	const handleDownloadReportClick = () => {
		setShowDownloadOptions(true);
	};
	return (
		<section className={classes.headerContainer}>
			<div className={classes.headerContent}>
				<div className={classes.titleDescriptionSection}>
					<img src={arrow} alt="" className={isRtl ? classes.transform : ''} onClick={handleBackClick} />
					<div className={classes.titleSection}>
						<div ref={editTitleEl} onBlur={handleTitleChange} contentEditable className={classes.title}>
							{props.title}
						</div>
						<img src={editIcon} alt="" onClick={handleEditIconClick} />
					</div>
					<div className={classes.descriptionSection} contentEditable onBlur={handleDescriptionChange}>
						{props.description}
					</div>
				</div>
				<div className={classes.actionTimeSection}>
					{props.handleDownloadReport && (
						<div className={classes.downloadButton}>
							<TrusstorButton
								handleClick={handleDownloadReportClick}
								text={translationService.get('download')}
								startIcon={downloadIcon}
								disabled={props.disableDownload}
							/>
							<div
								ref={exportMenuWrapperRef}
								className={classes.exportMenuWrapper}
								style={{
									left: isRtl ? 0 : 'initial',
									right: isRtl ? 'initial' : 0,
								}}
							>
								{showDownloadOptions && (
									<TrusstorMenu
										width={170}
										items={[
											{
												title: translationService.get('analysisReport_exportFormatted'),
												onClick: () => props.handleDownloadReport!(),
												testId: 'exportFormattedTimesheet',
											},
											{
												title: translationService.get('analysisReport_exportRaw'),
												onClick: () => props.handleDownloadReport!(true),
												testId: 'exportRawTimesheet',
											},
										]}
									/>
								)}
							</div>
						</div>
					)}
					<div className={classes.dateSection}>
						<img src={calendarIcon} alt="" />
						<div className={classes.date}>{getDisplayDate()}</div>
						{isIncidentPage && (
							<div className={classes.dateSection}>
								<img src={clockIcon} alt="" />
								<div className={classes.clockText}>+- 30 {translationService.get('mins')}</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</section>
	);
};

export { AnalysisCenterReportHeader };
