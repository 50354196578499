import React from 'react';
import classes from './styles.module.scss';
import classnames from 'classnames';
import { IconColor, IconSize, TrusstorIconShared } from '../../TrusstorIconShared/TrusstorIconShared';
import { IconNames } from '../../TrusstorIconShared/IconNames.enum';
import { useSharedServices } from '../../../hooks/sharedServices.context';

interface IPhotoUploadInputProps {
	saveImages: (files: FileList) => Promise<void>;
	selectedImages: File[];
	removeImage: (index: number) => void;
	isMobile?: boolean;
}

export const ImageUploadInput = (props: IPhotoUploadInputProps) => {
	const { translationService } = useSharedServices();

	const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
		const images: FileList = e.target.files!;
		if (!images.length) return;
		props.saveImages(images);
		return;
	};

	const removeImage = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
		e.preventDefault();
		e.stopPropagation();
		props.removeImage(index);
		return;
	};

	return (
		<div>
			<label htmlFor="file-upload" className={classnames(classes.selectSection, classes.label)}>
				{props.isMobile && (
					<TrusstorIconShared iconName={IconNames.camera} size={IconSize.MEDIUM} color={IconColor.Grey500} />
				)}
				<div className={classes.text}>{translationService.get('addMedia')}</div>
			</label>
			<input
				multiple
				id="file-upload"
				type="file"
				accept="image/*, application/pdf"
				onChange={handleImageChange}
				className={classes.imageInput}
			/>
			{!!props.selectedImages.length && (
				<div className={classes.imagesWrapper}>
					{props.selectedImages.map((image, index) => (
						<div className={classes.imageContainer} key={index}>
							{image.type.includes('pdf') ? (
								<TrusstorIconShared className={classes.pdfIcon} iconName={IconNames.fileText} />
							) : (
								<img alt="preview image" src={URL.createObjectURL(image)} />
							)}
							<div className={classes.closeIcon} onClick={(e) => removeImage(e, index)}>
								<TrusstorIconShared
									iconName={IconNames.closeCircle}
									size={IconSize.MEDIUM}
									color={IconColor.Grey800}
								/>
							</div>
						</div>
					))}
				</div>
			)}
		</div>
	);
};
