import React, { ReactNode, useEffect, useRef } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { ITrusstorButtonType, TrusstorButton } from '../buttons/TrusstorButton/TrusstorButton';
import { getFormattedMinutesPassedFromTimestamp_BI } from '../../utils/bi.utils';
import classes from './styles.module.scss';
import { useSharedServices } from '../../hooks/sharedServices.context';
import { Loader } from '../Loader/Loader';

export interface GeneralDialogProps {
	show: boolean;
	close: (e: any) => void;
	title?: string;
	titleIcon?: string;
	mainButton?: IDialogButton;
	secondaryButton?: IDialogButton;
	tertiaryButton?: IDialogButton;
	children: ReactNode;
	rootStyle?: any;
	subtitle?: ReactNode;
	clearDialogBorders?: boolean;
	disableEnforceFocus?: boolean;
	isLoading?: boolean;
}

export interface IDialogButton {
	text?: string;
	icon?: string;
	click?: (e: any, timeDuration?: string) => Promise<void> | void;
	getDialogTimeDuration?: (string) => void;
	show?: boolean;
	disabled?: boolean;
}

const GeneralDialogShared = (props: GeneralDialogProps) => {
	const { translationService } = useSharedServices();
	const [isLoadingMainButton, setIsLoadingMainButton] = React.useState(false);
	const openDialogTimeStamp = useRef(Date.now());

	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			if (event.key === 'Enter' && !isLoadingMainButton && !props.mainButton?.disabled) {
				event.preventDefault();
				handleMainButtonClick(event);
			}
		};

		if (props.show) {
			document.addEventListener('keydown', handleKeyDown);
		}
		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [props.show, isLoadingMainButton, props.mainButton?.disabled, props.mainButton?.click]);

	const handleMainButtonClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | KeyboardEvent) => {
		if (props.mainButton?.click && !isLoadingMainButton) {
			const BI_duration: string = getFormattedMinutesPassedFromTimestamp_BI(openDialogTimeStamp.current);
			setIsLoadingMainButton(true);
			await props.mainButton.click(e, BI_duration);
			setIsLoadingMainButton(false);
			return;
		}
		return props.close;
	};

	const handleMouseLeave = (event: React.MouseEvent<HTMLDivElement>) => {
		event.stopPropagation();
	};
	const handleMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
		event.stopPropagation();
	};

	const doesShowButton = (button: IDialogButton | undefined): boolean => {
		return !!(button && button.show !== false);
	};

	return (
		<Dialog
			onMouseLeave={handleMouseLeave}
			onMouseEnter={handleMouseEnter}
			onClose={props.close}
			maxWidth={false}
			open={props.show}
			classes={{
				paper: `${classes.dialog} ${props.rootStyle ? props.rootStyle : ''}`,
			}}
			disableEnforceFocus={props.disableEnforceFocus}
			data-testid="dialog-container"
		>
			<div className={classes.topSection} style={{ marginBottom: props.clearDialogBorders ? 'unset' : '16px' }}>
				{props.title !== undefined && (
					<div className={classes.headerSection}>
						<div className={classes.titleSection}>
							{props.titleIcon && <img src={props.titleIcon} alt=">" className={classes.icon} />}
							<div data-testid="dialog-title" className={classes.title}>
								{props.title}
							</div>
						</div>
						{props.subtitle && <div>{props.subtitle}</div>}
					</div>
				)}
				<div data-testid="dialog-content" className={classes.dialogContent}>
					{props.isLoading ? <Loader /> : props.children}
				</div>
			</div>
			<div className={classes.buttonsContainer}>
				<div className={classes.rightButtons}>
					{doesShowButton(props.mainButton) && (
						<TrusstorButton
							text={props.mainButton?.text || ''}
							handleClick={handleMainButtonClick}
							disabled={props.mainButton?.disabled}
							testId="dialog-main-button"
							showLoader={isLoadingMainButton}
						/>
					)}
					{doesShowButton(props.secondaryButton) && (
						<TrusstorButton
							startIcon={props.secondaryButton?.icon}
							text={props.secondaryButton?.text || translationService.get('cancel')}
							handleClick={props.secondaryButton?.click || props.close}
							disabled={props.secondaryButton?.disabled}
							className={classes.secondaryButton}
							buttonType={ITrusstorButtonType.GHOST}
							testId="dialog-secondary-button"
						/>
					)}
				</div>
				<div className={classes.leftButtons}>
					{doesShowButton(props.tertiaryButton) && (
						<TrusstorButton
							text={props.tertiaryButton?.text || translationService.get('delete')}
							handleClick={props.tertiaryButton?.click || props.close}
							disabled={props.tertiaryButton?.disabled}
							className={classes.tertiaryButton}
							buttonType={ITrusstorButtonType.GHOST}
							testId="dialog-tertiary-button"
						/>
					)}
				</div>
			</div>
		</Dialog>
	);
};

export { GeneralDialogShared };
