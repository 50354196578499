import { ITag } from '../../../interfaces';
import { useEffect, useState } from 'react';
import { IRootState } from '../../../store/slices';
import { useSelector } from 'react-redux';
import { TooltipedHoverIcon } from '../../TooltipedHoverIcon';
import { invalidCertificateIcon, validCertificateIcon } from '../../../assets/certificatesIcons';
import React from 'react';
import { isAtLeastOneOfTagCertificatesExpired } from '../../../utils/adminPanelTags.utils';
import { TagCertificatesTooltipTable } from '../TagCertificatesTooltipTable/TagCertificatesTooltipTable';

interface ITagCertificateIconProps {
	tag: ITag;
	openDialog: () => void;
}

export const TagCertificateIcon = (props: ITagCertificateIconProps) => {
	const [isCertificateValid, setIsCertificateValid] = useState<boolean | null>(null);
	const tz: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.tz);

	useEffect(() => {
		if (!props.tag.certificates || props.tag.certificates?.length === 0) {
			setIsCertificateValid(null);
			return;
		}
		const isAtLeastOneCertificateExpired: boolean = isAtLeastOneOfTagCertificatesExpired(props.tag, tz);

		if (isAtLeastOneCertificateExpired) {
			setIsCertificateValid(false);
			return;
		}

		setIsCertificateValid(true);
	}, [props.tag.certificates, tz]);

	if (isCertificateValid === null) {
		return null;
	}

	const icon: string = isCertificateValid ? validCertificateIcon : invalidCertificateIcon;
	return (
		<TooltipedHoverIcon
			onClick={props.openDialog}
			baseIcon={icon}
			hoverIcon={icon}
			tooltipText={<TagCertificatesTooltipTable certificates={props.tag.certificates} />}
			styleClass={undefined}
			testId={'tagCertificateIcon'}
		/>
	);
};
