import React, { useContext, useEffect, useId, useState } from 'react';
import classes from '@src/Components/Pages/Workplan/ProgressTrackerTab/SequenceSelectionSection/SequenceSelectionItem/styles.module.scss';
import { translationService } from '@src/servicesInitializers';
import { updateSequence } from '@utils/sequence.utils';
import { ISequence } from '@shared/interfaces/ISequence';
import { WorkplanContext } from '@src/Components/Pages/Workplan/WorkPlan';
import { errorSnackbar } from '@utils/snackbar.utils';
import { useDispatch } from 'react-redux';

interface ISequenceNameChangeProps {
	sequence: ISequence;
}

export const SequenceNameChange = (props: ISequenceNameChangeProps) => {
	const { setSequences, sequences } = useContext(WorkplanContext)!;
	const [showInput, setShowInput] = useState<boolean>(!!props.sequence.isInitial);
	const [name, setName] = useState<string | undefined>(
		!props.sequence.isInitial ? props.sequence.name : translationService.get('newSequence')
	);
	const dispatch = useDispatch();

	useEffect(() => {
		setName(props.sequence.name);
	}, [props.sequence.name]);

	const submitNameChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const nameExists: boolean = sequences.some(
			(sequence) => sequence.name === name && sequence._id !== props.sequence._id
		);
		setShowInput(false);
		if (nameExists) {
			setName(props.sequence.name);
			errorSnackbar(dispatch, translationService.get('nameAlreadyExists'));
			return;
		}
		if (e.target.value === '') {
			setName(props.sequence.name);
			return;
		}
		try {
			await updateSequence(props.sequence._id, { name: e.target.value }, setSequences);
		} catch (e) {
			console.log(e);
		}
	};

	const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.stopPropagation();
		setName(e.target.value);
	};
	const id: string = useId();

	return (
		<input
			aria-disabled={!showInput}
			autoFocus={props.sequence.isInitial}
			className={classes.input}
			id={id}
			onChange={handleNameChange}
			onBlur={submitNameChange}
			type={'text'}
			value={name}
			placeholder={translationService.get('enterChecklistName')}
		></input>
	);
};
