import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { textStyle } from '@shared/utils/text.utils';
import { TrusstorTooltip } from '@shared/components/TrusstorTooltip/TrusstorTooltip';
import { ISingleVerticalBarData } from './interfaces/ISingleVerticalBarData';
import { sumByField } from '../../../../../utils/array.util';
import { COLORS } from '../../../../../constants';

interface IStyleProps {
	barHeightPercentage?: number;
	backgroundColor?: string;
	barWidth?: number;
	minBarHeight?: number;
}

const useStyles = makeStyles((theme) => ({
	labelContainer: textStyle({
		fontClass: 'h2',
	}),
	bar: ({ barHeightPercentage, backgroundColor, minBarHeight, barWidth }: IStyleProps) => ({
		height: `${barHeightPercentage}%`,
		maxHeight: `98%`,
		minHeight: minBarHeight ?? 30,
		backgroundColor: backgroundColor,
		borderRadius: '1px',
		minWidth: barWidth,
		overflow: 'hidden',
	}),
	singleVerticalBarContainer: textStyle({
		fontClass: 'h2',
		height: '100%',
		position: 'relative',
		display: 'flex',
		flexDirection: 'column-reverse',
		alignItems: 'center',
	}),
	numberLabel: textStyle({
		fontClass: 'h2',
		bottom: theme.spacing(0.5),
		height: 20,
	}),
	tooltipText: textStyle({
		fontClass: 'body1',
		fontWeight: 400,
		textAlign: 'center',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		color: COLORS.white,
	}),
	colorCircle: {
		borderRadius: '100%',
		height: 10,
		width: 10,
		marginRight: theme.spacing(1),
	},
	barDataTooltip: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		marginBottom: theme.spacing(1.5),
		marginTop: theme.spacing(1.5),
	},
}));

interface ISingleVerticalBar {
	barMaximum: number;
	barWidth: number;
	minBarHeight?: number;
	numberLabel?: boolean;
	barDataList: ISingleVerticalBarData[];
	singleVerticalBarContainerStyleClass?: string;
}

const SingleVerticalBar = React.forwardRef<HTMLDivElement, ISingleVerticalBar>((props: ISingleVerticalBar, ref) => {
	const [barNumber, setBarNumber] = useState<number>(0);

	useEffect(() => {
		if (props.barDataList) {
			const sum = sumByField(props.barDataList, 'barNumber');
			setBarNumber(sum);
		}
	}, [props.barDataList]);

	const classes = useStyles({
		barHeightPercentage: (barNumber / props.barMaximum) * 100,
		backgroundColor: props.barDataList?.length === 1 && props.barDataList[0].backgroundColor,
		barWidth: props.barWidth,
		minBarHeight: props.minBarHeight,
	});
	const barEl = useRef<HTMLDivElement>(null);

	return (
		<div
			className={`${classes.singleVerticalBarContainer} ${props.singleVerticalBarContainerStyleClass}`}
			ref={ref}
		>
			<TrusstorTooltip
				placement={'left'}
				refChildren={barEl}
				cleanStyling
				tooltipComponent={
					<div>
						{props.barDataList.map((barData: ISingleVerticalBarData, index) => (
							<div key={`singleVerticalBar_tooltip_${index}`} className={classes.barDataTooltip}>
								<div
									className={classes.colorCircle}
									style={{ backgroundColor: barData.backgroundColor }}
								/>
								<div
									className={classes.tooltipText}
								>{`${barData.barNumber} ${barData.toolTipMessage}`}</div>
							</div>
						))}
					</div>
				}
				showAlways={props.barDataList.some((data) => data.toolTipMessage)}
			>
				<div className={classes.bar} ref={barEl}>
					{props.barDataList?.map((barData: ISingleVerticalBarData, index) => {
						const barHeightPercentage: number = (barData.barNumber / barNumber) * 100;
						return (
							<div
								style={{
									height: `${barHeightPercentage}%`,
									backgroundColor: barData.backgroundColor,
								}}
								key={`singleVerticalBar_multiple_${index}`}
							/>
						);
					})}
				</div>
			</TrusstorTooltip>
			{props.numberLabel && <div className={classes.numberLabel}>{barNumber}</div>}
		</div>
	);
});

export { SingleVerticalBar };
