import { getProfessionBackgroundColor, getProfessionDisplayText } from '@shared/utils/professions.utils';
import React from 'react';
import { translationService } from '../../../../../index';
import { IAssignedActivity } from '../../../../../interfaces';
import { InvestigationReportDataListSection } from '../../ReportTagLocationDataListSection/InvestigationReportDataListSection';
import { useSelector } from 'react-redux';
import { selectProjectId } from '@store/slices/project.slice';

interface IInvestigationReportLocationActivitiesSectionProps {
	activities: IAssignedActivity[];
	isLoading?: boolean;
}

export const InvestigationReportLocationActivitiesSection = (
	props: IInvestigationReportLocationActivitiesSectionProps
) => {
	return (
		<InvestigationReportDataListSection
			data={props.activities}
			getChipColor={(activity: IAssignedActivity, projectId: string) =>
				getProfessionBackgroundColor(activity.profession, projectId)
			}
			getLabel={(activity: IAssignedActivity) => activity.description}
			getChipContent={(activity: IAssignedActivity) =>
				getProfessionDisplayText(activity.profession, translationService)
			}
			header={translationService.get('activitiesOnFloor')}
			shouldShowWarningIcon={(activity: IAssignedActivity) => activity.dangerousActivity}
			emptyStateText={'noActivitiesToDisplay'}
			isLoading={props.isLoading}
		/>
	);
};
