import tagIcon from '@shared/assets/icons/tag.svg';
import classnames from 'classnames';
import React from 'react';
import { IProfession } from '@shared/interfaces/IProfession';
import classes from './styles.module.scss';
import { ProfessionDisplayCard } from '@src/Components/ProfessionDisplayCard/ProfessionDisplayCard';
import { IWorkerMergedTag } from '@interfaces/IWorkerMergedTag';
import { translationService } from '@src/servicesInitializers';
import { IconColor } from '@shared/components/TrusstorIconShared/TrusstorIconShared';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { TrusstorIcon } from '@src/Components/TrusstorIcon/TrusstorIcon';
import { hoistOperatorTradeId } from '@shared/constants/professions.constants';

interface IFloorProfessionSectionProps {
	profession?: IProfession;
	tags?: IWorkerMergedTag[];
	isHoistSection?: boolean;
}

export const FloorViewWorkersGroupedSection = (props: IFloorProfessionSectionProps) => {
	const hasTags: boolean = !!props.tags?.length;
	return (
		<div className={classes.wrapper} data-testid={'areaTagsCard'}>
			<div className={classnames(classes.wr, { [classes.hasTags]: hasTags })}>
				{props.isHoistSection ? (
					<>
						<div>{translationService.get('hoistOperator')}</div>
						<TrusstorIcon
							color={hasTags ? IconColor.Green500 : IconColor.Red900}
							iconName={hasTags ? IconNames.userCheck : IconNames.userCross}
						/>
					</>
				) : (
					<>
						{props.profession && <ProfessionDisplayCard profession={props.profession} />}
						<div data-testid={'professionCount'}>{props.tags?.length}</div>
					</>
				)}
			</div>
			{hasTags && (
				<div className={classes.tagsSection}>
					{props.tags?.map((tag) => {
						return (
							<div className={classnames(classes.tag)} data-testid={'tag'}>
								<img src={tagIcon} alt={'tagIcon'} />
								<div>{tag.tagNick}</div>
								<div className={classes.tagName}>{(tag as IWorkerMergedTag).name}</div>
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
};
