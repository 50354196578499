import { IconChip } from '../IconChip/IconChip';
import { COLORS } from '../../../constants/colors.constants';
import { IconNames } from '../../TrusstorIconShared/IconNames.enum';
import { IconColor } from '../../TrusstorIconShared/TrusstorIconShared';
import { useSharedServices } from '../../../hooks/sharedServices.context';
import React from 'react';
import { ActivityGroupStatus } from '../../../interfaces/ActivityGroupStatus.enum';

interface IActivityStatusChipProps {
	status: ActivityGroupStatus;
}

interface IStatusProperties {
	backgroundColor: string;
	iconName?: IconNames;
	iconColor: IconColor;
	text: string;
	textColor: COLORS;
}

export const ActivityStatusChip = (props: IActivityStatusChipProps) => {
	const { translationService } = useSharedServices();
	const statusesProperties: Record<Exclude<ActivityGroupStatus, 'deleted'>, IStatusProperties> = {
		planned: {
			backgroundColor: COLORS.grey100,
			iconName: IconNames.upcomingCircleFilled,
			iconColor: IconColor.Grey300,
			text: translationService.get('planned'),
			textColor: COLORS.darkBlue,
		},
		inProgress: {
			backgroundColor: COLORS.yellow500,
			iconName: IconNames.progress,
			iconColor: IconColor.Grey700,
			text: translationService.get('inProgress'),
			textColor: COLORS.grey700,
		},
		delayed: {
			backgroundColor: COLORS.red400,
			iconColor: IconColor.White,
			text: translationService.get('delayed'),
			textColor: COLORS.white,
		},
		complete: {
			backgroundColor: COLORS.green400,
			iconName: IconNames.checkCircle,
			iconColor: IconColor.White,
			text: translationService.get('complete'),
			textColor: COLORS.white,
		},
		overdue: {
			backgroundColor: COLORS.red400,
			iconName: IconNames.flag,
			iconColor: IconColor.White,
			text: translationService.get('overdue'),
			textColor: COLORS.white,
		},
	};

	const statusProperties: IStatusProperties = statusesProperties[props.status];

	if (!statusProperties) {
		return null;
	}

	return (
		<IconChip
			backgroundColor={statusProperties.backgroundColor}
			iconName={statusProperties.iconName}
			iconColor={statusProperties.iconColor}
			textColor={statusProperties.textColor}
			text={statusProperties.text}
		/>
	);
};
