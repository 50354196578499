import React from 'react';
import classnames from 'classnames';
import classes from './styles.module.scss';
import { IconNames } from '../../TrusstorIconShared/IconNames.enum';
import { IconColor, IconSize, TrusstorIconShared } from '../../TrusstorIconShared/TrusstorIconShared';

export enum ITrusstorIconButtonSize {
	SMALL = 'small',
	DEFAULT = 'default',
	LARGE = 'large',
}

export enum ITrusstorIconButtonStyle {
	OUTLINE = 'outline',
	GHOST = 'ghost',
	DEFAULT = 'default',
}

interface ITrusstorIconButtonProps {
	iconName: IconNames;
	size?: IconSize;
	color?: IconColor;
	onClick?: (e: React.MouseEvent) => void;
	disabled?: boolean;
	buttonSize?: ITrusstorIconButtonSize;
	iconClassName?: string;
	style?: ITrusstorIconButtonStyle;
	rootClassName?: string;
	testId?: string;
	squaredButton?: boolean;
}

export const TrusstorIconButtonV2 = (props: ITrusstorIconButtonProps) => {
	const containerClassName: string = classnames(
		classes.iconContainer,
		props.style ? classes[props.style] : classes.default,
		{
			[classes.small]: props.buttonSize === ITrusstorIconButtonSize.SMALL,
			[classes.large]: props.buttonSize === ITrusstorIconButtonSize.LARGE,
			[classes.disabled]: props.disabled,
			[props.rootClassName || '']: !!props.rootClassName,
			[classes.squaredButton]: props.squaredButton,
		}
	);

	return (
		<div onClick={props.onClick} className={containerClassName} data-testid={props.testId}>
			<div
				className={classnames(classes.iconElement, {
					[props.iconClassName || '']: !!props.iconClassName,
				})}
			>
				<TrusstorIconShared
					iconName={props.iconName}
					size={props.size}
					color={props.color}
					disableColorStyling
				/>
			</div>
		</div>
	);
};
