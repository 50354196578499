import { useQuery } from '@tanstack/react-query';
import { IBaseNotificationSettings, IUserNotificationSettings } from '@shared/interfaces/INotificationSettings';
import { requestService } from '../../index';
import { IRestrictedAreaTrigger } from '@interfaces/IRestrictedAreaTrigger';
import { NotificationTypes } from '@shared/interfaces/NotificationTypes.enum';

export const useUserSettingsQuery = (
	username: string,
	projectId: string
): {
	userSettings: IUserNotificationSettings | undefined;
	refetchUserSettings: () => any;
} => {
	const { data: userSettings, refetch: refetchUserSettings } = useQuery<IUserNotificationSettings>(
		['userSettings', projectId, username],
		() => requestService.get(`/notification/settings/user?projectId=${projectId}&username=${username}`),
		{
			useErrorBoundary: false,
		}
	);
	return { userSettings, refetchUserSettings };
};

export const useBaseSettingsQuery = (
	projectId: string
): {
	baseSettings: IBaseNotificationSettings | undefined;
	refetchBaseSettings: () => any;
} => {
	const { data, refetch: refetchBaseSettings } = useQuery<{ baseSettings: IBaseNotificationSettings | undefined }>(
		['baseSettings', projectId],
		() => requestService.get(`/notification/settings/base?projectId=${projectId}`),
		{
			useErrorBoundary: false,
		}
	);
	return { baseSettings: data?.baseSettings, refetchBaseSettings };
};

export const useRestrictedAreaTriggersQuery = (
	projectId: string
): {
	restrictedAreaTriggers: IRestrictedAreaTrigger[] | undefined;
	refetchRestrictedAreaTriggers: () => any;
} => {
	const { data: restrictedAreaTriggers, refetch: refetchRestrictedAreaTriggers } = useQuery<
		IRestrictedAreaTrigger[] | undefined
	>(
		['restrictedAreaTriggers', projectId],
		() =>
			requestService.get(
				`/notification/customizedTriggers/${NotificationTypes.RestrictedArea}?projectId=${projectId}`
			),
		{
			useErrorBoundary: false,
		}
	);
	return { restrictedAreaTriggers, refetchRestrictedAreaTriggers };
};
