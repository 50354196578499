import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl } from '@material-ui/core';
import classes from './styles.module.scss';
import { HighlightedTextField } from '../../TextFields/HighlightedTextField';
import { MuiTrusstorTextInput } from '../../Inputs/MuiTrusstorTextInput/MuiTrusstorTextInput';
import { TrusstorCheckbox } from '../../TrusstorCheckbox/TrusstorCheckbox';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { isEqual } from 'lodash';
import { Directions } from '../../../interfaces/Directions';
import { AutocompleteTextInputOutlined } from '../../Inputs/AutocompleteTextInputOutlined/AutocompleteTextInputOutlined';
import { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete';
import internal from 'node:stream';

interface IAutoCompleteDropdownProps {
	direction: Directions;
	options: any[];
	onChange: (optionValue: any | null) => void;
	isMultipleChoice?: boolean;
	displayCheckbox?: boolean;
	allowFreeText?: boolean;
	placeholder?: string;
	value?: any | any[];
	errorText?: string;
	isError?: boolean;
	getOptionLabel: (value: any) => string;
	disabled?: boolean;
	label?: string;
	hideClearTextButton?: boolean;
	required?: boolean;
	loading?: boolean;
	loadingText?: string;
	inputRef?: any;
	optionSelectedKey?: string;
	renderCmpOption?: (option: any, autoCompleteValue: string) => React.ReactNode;
	testId?: string;
	getTextInputElement?: (params: AutocompleteRenderInputParams) => any;
	iconSize?: string;
	endAdornmentClassname?: string;
	getRenderTagsElement?: (options: any[], shouldShow: boolean) => React.ReactElement;
	inputContainerClassName?: string;
	useOutlinedInput?: boolean;
}

export default function AutoCompleteDropdown(props: IAutoCompleteDropdownProps) {
	const getInitialAutoCompleteValue = () => {
		if (Array.isArray(props.value)) {
			return '';
		}

		if (props.value) {
			return props.getOptionLabel(props.value);
		}

		return '';
	};

	interface IStylesProps {
		iconSize?: string;
	}

	const useStyles = makeStyles({
		icon: ({ iconSize }: IStylesProps) => ({
			width: iconSize ? `${iconSize} !important` : undefined,
			height: iconSize ? `${iconSize} !important` : undefined,
		}),
	});

	const [autoCompleteValue, setAutoCompleteValue] = useState<any | any[]>(getInitialAutoCompleteValue());
	useEffect(() => {
		setAutoCompleteValue(getInitialAutoCompleteValue());
	}, [props.value]);

	const onAutoCompleteChange = (event: any, value: any) => {
		// This fixes a bug when the input get cleared automatically, and we are checking that when the input needs to really be cleared we are actually clearing it
		if (!event && value === '' && props.value !== '') {
			return;
		}

		setAutoCompleteValue(value);

		if (props.allowFreeText) {
			props.onChange(value);
		}
	};

	const getOptionsText = (tags: any[]): string => {
		if (Array.isArray(tags)) {
			return tags.map((option) => props.getOptionLabel(option)).join(', ');
		}

		return props.getOptionLabel(tags);
	};

	return (
		<FormControl className={classes.autocompleteContainer} size={'small'}>
			<Autocomplete
				popupIcon={<ExpandMoreIcon className={useStyles({ iconSize: props.iconSize }).icon} />}
				data-testid={props.testId ?? null}
				renderTags={(value) =>
					props.getRenderTagsElement ? (
						props.getRenderTagsElement(value, autoCompleteValue === '')
					) : (
						<span className={classes.tagsContainer}>{getOptionsText(value)}</span>
					)
				}
				multiple={props.isMultipleChoice}
				disableCloseOnSelect={props.isMultipleChoice}
				disableClearable={props.hideClearTextButton}
				inputValue={autoCompleteValue}
				onInputChange={onAutoCompleteChange}
				value={props.value || null}
				getOptionSelected={(option, value) => {
					if (props.optionSelectedKey === undefined) return isEqual(option, value);
					return isEqual(option[props.optionSelectedKey], value[props.optionSelectedKey]);
				}}
				onChange={(event: any, value: any) => {
					props.onChange(value);
				}}
				freeSolo={props.allowFreeText}
				forcePopupIcon={true}
				options={props.options}
				getOptionLabel={props.getOptionLabel}
				disabled={props.disabled}
				placeholder={props.placeholder}
				classes={{
					option: classes.optionStyle,
					loading: classes.loading,
					popper: props.renderCmpOption ? classes.wideContainer : '',
					endAdornment: props.endAdornmentClassname,
				}}
				loading={props.loading}
				loadingText={props.loadingText}
				renderOption={(option: any, { selected }) => {
					if (props.renderCmpOption) {
						return props.renderCmpOption(option, autoCompleteValue);
					}
					return (
						<div className={classes.optionContainer}>
							{props.displayCheckbox && <TrusstorCheckbox checked={selected} propagateEvent />}
							<HighlightedTextField text={props.getOptionLabel(option)} highlight={autoCompleteValue} />
						</div>
					);
				}}
				renderInput={(params) => {
					if (props.getTextInputElement) {
						return props.getTextInputElement(params);
					}
					if (props.useOutlinedInput) {
						return (
							<AutocompleteTextInputOutlined params={params} label={props.label} value={props.value} />
						);
					}
					return (
						<MuiTrusstorTextInput
							direction={props.direction}
							inputRef={props.inputRef}
							required={props.required}
							params={params}
							value={autoCompleteValue}
							label={props.label}
							placeholder={props.placeholder}
							disabled={props.disabled}
							isError={props.isError}
							errorText={props.errorText}
							disabledEnterKey={props.allowFreeText}
							inputContainerClassName={props.inputContainerClassName}
						/>
					);
				}}
			/>
		</FormControl>
	);
}
