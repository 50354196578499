import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TrusstorBox } from '../../Boxes/Containers/TrusstorBox';
import { Grid, Typography } from '@material-ui/core';
import { translationService } from '../../../index';
import { textStyle } from '@shared/utils/text.utils';
import { ManagersListTable } from '../../ManagersListComponents/ManagersTable';
import { IManager } from '../../../interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../../store/slices';
import { getStaticManagers } from '../../../store/thunks/managers.thunks';
import { removeAllManagers } from '../../../store/slices/managers.slice';
import { PAGE_TITLE_MARGIN_BOTTOM, PAGE_CONTAINER_MARGIN, PAGE_TITLE_PADDING } from '../../../constants';
import { getStaticTagsStream, stopGettingAdminPanelTags } from '../../../store/thunks';
import { RoleType } from '@shared/interfaces/RoleType.enum';
import { getTradeTranslation } from '@utils/translations.utils';
import { useRenderMonitoring } from '@shared/hooks/monitoring.hooks';

const useStyles = makeStyles((theme) => ({
	pageContainer: {
		margin: PAGE_CONTAINER_MARGIN,
		flexWrap: 'unset',
	},
	titleContainer: {
		marginBottom: PAGE_TITLE_MARGIN_BOTTOM,
	},
	pageTitleBox: {
		width: '100%',
		padding: PAGE_TITLE_PADDING,
		justifyContent: 'center',
		paddingLeft: 0,
	},
	title: textStyle({
		fontSize: 20,
		fontWeight: 'bold',
		width: '100%',
	}),
	pageContent: {
		display: 'flex',
		maxHeight: '96%',
	},
	pageDashboardContainer: {
		minWidth: '15%',
		maxWidth: '15%',
		overflow: 'auto',
		flexWrap: 'nowrap',
	},
	pageTableContainer: {
		minWidth: '98%',
	},
}));

const filterManagersBySearchInput = (managers: IManager[], searchInput: string): IManager[] => {
	if (!searchInput) {
		return managers;
	}
	const lowerCaseSearchInput: string = searchInput.toLowerCase();
	return managers.filter(
		(manager: IManager) =>
			getTradeTranslation(manager).toLowerCase().includes(lowerCaseSearchInput) ||
			manager.name.toLowerCase().includes(lowerCaseSearchInput)
	);
};

const ManagersRolesListPage = () => {
	useRenderMonitoring('ManagersRolesListPage');
	const classes = useStyles();
	const staticManagers: IManager[] = useSelector((state: IRootState) => state.managersReducer.staticManagers);
	const workingProjectId: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.projectId);
	const [visibleManagers, setVisibleManagers] = useState<IManager[]>([]);
	const [searchInput, setSearchInput] = useState<string>('');
	const isAdmin: boolean =
		useSelector((state: IRootState) => state.loginReducer.loggedUser?.userDetails?.permissions.roleType) ===
		RoleType.TRUSSTOR_ADMIN;
	const dispatch = useDispatch();

	useEffect(() => {
		setVisibleManagers(filterManagersBySearchInput(staticManagers, searchInput));
	}, [searchInput, staticManagers]);

	useEffect(() => {
		dispatch(getStaticManagers({ projectId: workingProjectId }));
		dispatch(getStaticTagsStream(workingProjectId, isAdmin));
		setVisibleManagers(staticManagers);
		return () => {
			stopGettingAdminPanelTags();
			dispatch(removeAllManagers());
		};
	}, [workingProjectId, isAdmin]);

	return (
		<Grid container direction="column" className={classes.pageContainer}>
			<Grid item container className={classes.titleContainer}>
				<TrusstorBox customStyleClass={classes.pageTitleBox}>
					<Typography className={classes.title}>{translationService.get('managementRoles')}</Typography>
				</TrusstorBox>
			</Grid>
			<div className={classes.pageContent}>
				<Grid item container direction="column" className={classes.pageTableContainer}>
					<ManagersListTable
						managers={visibleManagers}
						onSearchInputChange={setSearchInput}
						isSearchInputExist={!!searchInput}
					/>
				</Grid>
			</div>
		</Grid>
	);
};

export { ManagersRolesListPage };
