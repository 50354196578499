import React from 'react';
import moment from 'moment-timezone';
import { translationService } from '@src/index';
import { selectTimezone } from '@store/slices/project.slice';
import { useSelector } from 'react-redux';
import { getTimezoneStartOfDate } from '@shared/utils/dateUtils';
import { WorkplanPeriodFixedOptionTypes } from '@src/services/workplanLocalStorage.service';
import { PeriodSelectorTrusstorInput } from '@src/Components/DateSelector/PeriodSelectorTrusstorInput/PeriodSelectorTrusstorInput';
import { IDateMenuItem } from '@src/Components/DateSelector/PeriodSelector/PeriodSelector';

interface IWorkplanPlanningDateMenuProps {
	onChange: (startDate?: Date, endDate?: Date, itemId?: string) => void;
	oneWeekDateRanges: { startDate: Date; endDate: Date };
	twoWeekDateRanges: { startDate: Date; endDate: Date };
	startDate?: Date;
	endDate?: Date;
}

export const WorkplanPlanningDateMenu = (props: IWorkplanPlanningDateMenuProps) => {
	const tz: string = useSelector(selectTimezone)!;

	const menuItems: IDateMenuItem[] = [
		{
			id: WorkplanPeriodFixedOptionTypes.nextWeek,
			label: translationService.get('nextWeek'),
			value: {
				startDate: props.oneWeekDateRanges.startDate,
				endDate: props.oneWeekDateRanges.endDate,
			},
			onClickSideEffect: () => props.onChange(undefined, undefined, WorkplanPeriodFixedOptionTypes.nextWeek),
		},
		{
			id: WorkplanPeriodFixedOptionTypes.nextTwoWeeks,
			label: translationService.get('nextTwoWeeks'),
			value: {
				startDate: props.twoWeekDateRanges.startDate,
				endDate: props.twoWeekDateRanges.endDate,
			},
			onClickSideEffect: () => props.onChange(undefined, undefined, WorkplanPeriodFixedOptionTypes.nextTwoWeeks),
		},
	];

	const onDateChange = (period: [Date, Date]) => {
		props.onChange(period[0], period[1]);
	};

	return (
		<PeriodSelectorTrusstorInput
			inputTextFormat
			updatePeriod={onDateChange}
			datePeriod={[props.startDate || new Date(), props.endDate || new Date()]}
			itemsAbove={menuItems}
			minDate={getTimezoneStartOfDate(tz)}
			maxDate={moment.tz(tz).add(10, 'week').toDate()}
			showSingleMonth={true}
			hideIcon={true}
		/>
	);
};
