import { requestService } from '@src/index';
import { selectProjectId } from '@store/slices/project.slice';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { IUtility } from '@interfaces/IUtility';

export const getProjectUtilitiesQuery = (): {
	utilities: IUtility[];
	isLoading: false;
} => {
	const projectId: string = useSelector(selectProjectId)!;
	const { data: utilities, isLoading } = useQuery<IUtility[]>(
		['utilities', projectId],
		() => requestService.get(`/projectConfig/utilities/${projectId}`),
		{
			useErrorBoundary: false,
			initialData: [],
		}
	);
	return { utilities, isLoading };
};
