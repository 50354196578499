import React from 'react';
import { GeneralAttentionPoint } from '../GeneralAttentionPoint/GeneralAttentionPoint';
import blackWarningIcon from '../../../assets/icons/blackWarning.svg';
import { COLORS } from '../../../constants/colors.constants';

interface IWarningAttentionPointProps {
	text: string | React.ReactNode;
	rootClassName?: string;
}

export const WarningAttentionPoint = (props: IWarningAttentionPointProps) => {
	return (
		<GeneralAttentionPoint
			text={props.text}
			backgroundColor={COLORS.red300}
			iconSrc={blackWarningIcon}
			rootClassName={props.rootClassName}
		/>
	);
};
