import React from 'react';
import { IProfession, IWeekDayActivity } from '../../../interfaces';
import { CalendarToggleOptions } from '../../../constants';
import classes from './styles.module.scss';
import { accessLevelService } from '../../../index';
import { ProfessionDisplayWithTradeIcon } from '@shared/components/ProfessionDisplayWithTradeIcon/ProfessionDisplayWithTradeIcon';
import { selectProjectId } from '@store/slices/project.slice';
import { useSelector } from 'react-redux';

interface ICalenderCellProps {
	text?: string;
	profession?: IProfession;
	height?: number;
	activities?: IWeekDayActivity[];
	isMarked?: boolean;
	openCreateDialog?: any;
	openEditDialog?: (activityId: string) => void;
	workPlanToggle?: CalendarToggleOptions;
	isClickable: boolean;
	isDayOff?: boolean;
}

const CalendarCell = (props: ICalenderCellProps) => {
	const projectId: string = useSelector(selectProjectId)!;
	const cellContainerStylesFromProps: { [key: string]: string | number } = {
		height: props.height ?? 0,
		cursor: props.isClickable ? 'pointer' : 'default',
		justifyContent: props.activities ? 'start' : '',
	};

	const getBackgroundColorClass = (): string => {
		if (props.isMarked && props.isDayOff) {
			return classes.blueStripes;
		}
		if (props.isDayOff) {
			return classes.stripes;
		}
		if (props.isMarked) {
			return classes.blue;
		}
		return '';
	};

	const onCellClick = () => {
		accessLevelService.hasAccess('workplanActivityActions') && props.openCreateDialog?.();
	};

	return (
		<div
			className={`${classes.cellContainer} ${getBackgroundColorClass()}`}
			style={cellContainerStylesFromProps}
			onClick={onCellClick}
			data-testid={`workplanCell&isDayOff=${props.isDayOff}&isMarked=${props.isMarked}`}
		>
			{props.text && (
				<div className={classes.textContainer}>
					<p className={classes.bigHeaderText} data-testid={'cellHeader'}>
						{props.text}
					</p>
				</div>
			)}
			{props.profession && (
				<div className={classes.professionDisplayContainer}>
					<ProfessionDisplayWithTradeIcon isNarrow profession={props.profession} projectId={projectId} />
				</div>
			)}
		</div>
	);
};

export { CalendarCell };
