import { DashboardTabTypes } from '@interfaces/DashboardTabTypes.enum';
import { IBuildingSvgPoints } from '@interfaces/IBuildingSvgPoints';
import { IFloorData } from '@interfaces/IFloorData';
import { IMainPageWidgetActivityData } from '@interfaces/IMainPageWidgetActivityData';
import { IManagerMergedTag } from '@interfaces/IManagerMergedTag';
import { IMergedEquipmentView } from '@interfaces/IMergedEquipmentView';
import { IMergedFloorView } from '@interfaces/IMergedFloorView';
import { IProfessionWithActiveTags } from '@interfaces/IProfessionWithActiveTags';
import { IMinimalSafetyEventForClient } from '@interfaces/ISafetyEvent';
import { ISvgElementContainer } from '@interfaces/ISvgElementContainer';
import { ISvgElementPoint } from '@interfaces/ISvgElementPoint';
import { RoleType } from '@shared/interfaces/RoleType.enum';
import ProfessionsChipsTooltip from '@src/Components/Chips/Tooltips/ProfessionsChipsTooltip';
import { ActivitiesFloorEntities } from '@src/Components/MainViewFloorSections/ActivitiesFloorEntities/ActivitiesFloorEntities';
import { FloorChipsContainer } from '@src/Components/MainViewFloorSections/FloorChipsContainer/FloorChipsContainer';
import { FloorElevatorScaffold } from '@src/Components/MainViewFloorSections/FloorElevatorScaffold/FloorElevatorScaffold';
import { FloorHighlightedArea } from '@src/Components/MainViewFloorSections/FloorHighlightedArea/FloorHighlightedArea';
import { FloorSafety } from '@src/Components/MainViewFloorSections/FloorSafety';
import { PersonnelFloorEntities } from '@src/Components/MainViewFloorSections/PersonnelFloorEntities/PersonnelFloorEntities';
import { TagsDetailsTooltip } from '@src/Components/Tooltips/TagsDetailsTooltip';
import { entitiesProperties } from '@src/constants';
import { IRootState } from '@store/slices';
import { selectActiveMainPageLayout } from '@store/slices/mainPageLayout.slice';
import { sortBy } from 'lodash';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIsFeatureFlagEnabled } from '@shared/hooks/useFeatureFlag';
import { FeatureFlagNamesEnum } from '@shared/constants/featureFlags.enum';
import { IIssue } from '@shared/interfaces/IIssue';
import { IssuesFloorEntities } from '@src/Components/MainViewFloorSections/IssuesFloorEntities/IssuesFloorEntities';
import { accessLevelService, projectAccessLevelService } from '@src/servicesInitializers';
import { IUtilityMergedTag } from '@interfaces/IUtilityMergedTag';

interface IFloorSvgElementsProps {
	floorData: IFloorData;
	svgPoints: IBuildingSvgPoints | null;
	setFloorPath: (floorPath: string) => void;
	floorScaffolds?: IFloorData[];
	floorElevators?: IFloorData[];
	openSafetyDialog: (safetyEvent: IMinimalSafetyEventForClient[]) => void;
	floorChipsContainer: ISvgElementContainer;
	floorWidgetActivities: IMainPageWidgetActivityData[];
	floorIssues: IIssue[];
	key: string;
}

export const FloorSvgElements = React.memo((props: IFloorSvgElementsProps) => {
	const equipment: IMergedEquipmentView[] = useSelector(
		(state: IRootState) => state.equipmentReducer.visibleActiveEquipment
	);
	const userRole: RoleType = useSelector(
		(state: IRootState) => state.loginReducer.loggedUser!.userDetails.permissions.roleType
	);
	const selectedFloor: IMergedFloorView | undefined = useSelector(
		(state: IRootState) => state.floorsViewReducer.selectedFloor
	);
	const isActivitiesChipsEnabled: boolean = useIsFeatureFlagEnabled(FeatureFlagNamesEnum.useActivitiesChips);
	const isIssuesChipsEnabled: boolean = useIsFeatureFlagEnabled(FeatureFlagNamesEnum.useIssuesChips);

	const currentActiveMainPageLayout: DashboardTabTypes | null = useSelector(selectActiveMainPageLayout);

	const isUserAdmin: boolean = userRole === RoleType.TRUSSTOR_ADMIN;
	const professionsForFloor: IProfessionWithActiveTags[] = sortBy(props.floorData.activeProfessions, [
		entitiesProperties.sortIndex,
		entitiesProperties.tradeId,
	]);

	const professionPointForFloor: ISvgElementPoint | undefined = props.svgPoints?.professionPointsArray.find(
		(point) => point.floorId === props.floorData.floorId
	);

	const showTagsAdminTooltip: boolean = isUserAdmin && props.floorData.tags.length > 0;
	const getFloorTooltipComponent = (): JSX.Element | null => {
		if (
			(isActivitiesChipsEnabled || isIssuesChipsEnabled) &&
			currentActiveMainPageLayout !== DashboardTabTypes.workForce
		)
			return null;
		if (showTagsAdminTooltip) {
			return <TagsDetailsTooltip tags={props.floorData.tags} />;
		}
		if (professionPointForFloor) {
			return (
				<ProfessionsChipsTooltip
					professionsForFloor={professionsForFloor}
					professionPointForFloor={professionPointForFloor!}
				/>
			);
		}
		return null;
	};

	const floorEquipment: IMergedEquipmentView[] = equipment.filter((eq) => eq.floorId === props.floorData.floorId);
	const floorUtilities: IUtilityMergedTag[] = props.floorData.utilities || [];
	const floorProfessions: IProfessionWithActiveTags[] = sortBy(props.floorData.activeProfessions, [
		entitiesProperties.sortIndex,
		entitiesProperties.tradeId,
	]);
	const floorManagers: IManagerMergedTag[] = sortBy(props.floorData.managers, [
		entitiesProperties.sortIndex,
		entitiesProperties.name,
	]);
	const floorElevatorSvgPoint: ISvgElementPoint | undefined = props.svgPoints?.elevatorPointsArray.find(
		(elevator) => elevator.floorId === props.floorData.floorId
	);
	const floorHighlightedArea: ISvgElementContainer | undefined = props.svgPoints?.highlightedElementsArray.find(
		(area) => area.floorId === props.floorData.floorId
	);

	const onFloorSelected = (): void => {
		props.setFloorPath(props.floorData.floorId);
	};

	return (
		<div key={props.key}>
			<FloorChipsContainer
				chipsContainerSvgElement={props.floorChipsContainer}
				getToolTipComponent={getFloorTooltipComponent}
				onClick={floorHighlightedArea && onFloorSelected}
				tooltipPlacement={showTagsAdminTooltip ? 'left' : undefined}
			>
				<EntitiesElement
					currentActiveMainPageLayout={currentActiveMainPageLayout}
					floorData={props.floorData}
					floorWidgetActivities={props.floorWidgetActivities}
					floorIssues={props.floorIssues}
					floorProfessions={floorProfessions}
					floorManagers={floorManagers}
					floorEquipment={floorEquipment}
					floorUtilities={floorUtilities}
					floorChipsContainer={props.floorChipsContainer}
					isUserAdmin={isUserAdmin}
				/>
			</FloorChipsContainer>
			<FloorHighlightedArea
				highlightedArea={floorHighlightedArea}
				isChoosen={selectedFloor?.floorId === props.floorData.floorId}
				onClick={floorHighlightedArea && onFloorSelected}
			/>
			{accessLevelService.hasAccess('projectMonitor-safety') &&
				projectAccessLevelService.hasAccess('projectMonitor-safety') && (
					<FloorSafety
						floorData={props.floorData}
						svgPoints={props.svgPoints as IBuildingSvgPoints}
						openSafetyDialog={props.openSafetyDialog}
					/>
				)}
			<FloorElevatorScaffold
				floorScaffolds={props.floorScaffolds}
				floorElevators={props.floorElevators}
				floorId={props.floorData.floorId}
				floorElevatorSvgElement={floorElevatorSvgPoint}
			/>
		</div>
	);
});

const EntitiesElement = ({
	currentActiveMainPageLayout,
	floorData,
	floorWidgetActivities,
	floorIssues,
	floorProfessions,
	floorManagers,
	floorEquipment,
	floorUtilities,
	floorChipsContainer,
	isUserAdmin,
}) => {
	const isActivitiesChipsEnabled: boolean = useIsFeatureFlagEnabled(FeatureFlagNamesEnum.useActivitiesChips);
	const isIssuesChipsEnabled: boolean = useIsFeatureFlagEnabled(FeatureFlagNamesEnum.useIssuesChips);

	if (isActivitiesChipsEnabled && currentActiveMainPageLayout === DashboardTabTypes.activities) {
		return <ActivitiesFloorEntities floorId={floorData.floorId} floorWidgetActivities={floorWidgetActivities} />;
	}

	if (isIssuesChipsEnabled && currentActiveMainPageLayout === DashboardTabTypes.issues) {
		return <IssuesFloorEntities floorId={floorData.floorId} floorIssues={floorIssues} />;
	}

	return (
		<PersonnelFloorEntities
			floorProfessions={floorProfessions}
			floorManagers={floorManagers}
			chipsContainerSvgElement={floorChipsContainer}
			isAdmin={isUserAdmin}
			equipmentData={floorEquipment}
			utilitiesData={floorUtilities}
			workersOnFloor={floorData.tags}
			floorNick={floorData.floorNick}
		/>
	);
};
