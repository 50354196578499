import React from 'react';
import { notificationIcon } from '@assets/sideBar';
import { translationService } from '@src/index';
import classes from './styles.module.scss';

export const NotificationsEmptyState = () => {
	return (
		<div className={classes.emptyState}>
			<div className={classes.emptyStateIconContainer}>
				<div className={classes.notificationIcon}>
					<img src={notificationIcon} className={classes.emptyStateIcon} alt={''} />
					<div className={classes.dot}>
						<span className={classes.dotText}>0</span>
					</div>
				</div>
			</div>
			<div className={classes.emptyStateText}>{translationService.get('noNewNotifications')}</div>
		</div>
	);
};
