import { RoleType } from '@shared/interfaces/RoleType.enum';
import { ILoggedUser } from '@shared/interfaces/IUser';
import { userService } from '@src/index';
import { loginToMobileView_BI } from '@utils/bi.utils';
import { config } from '@src/config';

export const getIsExecutiveUser = (loggedUser: ILoggedUser): boolean => {
	return loggedUser?.userDetails?.permissions?.roleType === RoleType.EXECUTIVE;
};

export const redirectToMobile = (loggedUser: ILoggedUser, shouldRedirectToMobileConfig: boolean): void => {
	const isExecutiveUser: boolean = getIsExecutiveUser(loggedUser);
	const shouldRedirectToMobile: boolean = loggedUser && !isExecutiveUser && shouldRedirectToMobileConfig;
	if (!shouldRedirectToMobile) return;
	const userAccessToken: string = userService.getLoggedUserAccessToken()!;
	loginToMobileView_BI(loggedUser.userDetails.email);
	window.location.replace(`${config.mobileUrl}?token=${userAccessToken}`);
};
