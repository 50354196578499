import { useMemo } from 'react';
import { compact, countBy, flatMap, uniqBy, Dictionary } from 'lodash';
import { IIssue } from '../interfaces/IIssue';
import { IProfession } from '../interfaces/IProfession';
import { sortIssuesByProfession } from '../utils/issues.utils';
import { IBaseFloor } from '../interfaces/IBaseFloor';
import { IIssueAssignee, IIssueLocation } from '../interfaces/IIssueShared';
import { IBaseAreaBaseFloor } from '../interfaces/IBaseAreaBaseFloor';

export interface IUseIssuesFilterPropertiesData {
	activeProfessions: IProfession[];
	activeAssignees: IIssueAssignee[];
	activeFloors: IBaseFloor[];
	activeAreas: IBaseAreaBaseFloor[];
	professionCountObj: { [professionId: string]: number };
	prioritiesCountObj: Dictionary<number>;
	statusesCountObj: Dictionary<number>;
	floorsCountObj: Dictionary<number>;
	areasCountObj: Dictionary<number>;
	assigneesCountObj: Dictionary<number>;
}

export const useIssuesFilterPropertiesData = (issues: IIssue[]): IUseIssuesFilterPropertiesData => {
	const activeProfessions: IProfession[] = useMemo(() => {
		const sortedIssues: IIssue[] = sortIssuesByProfession(issues);
		const issuesProfessions: (IProfession | undefined)[] = uniqBy(
			sortedIssues,
			(issue) => issue.profession?._id
		).map((issue) => issue.profession);
		return compact(issuesProfessions);
	}, [issues]);

	const activeAssignees = useMemo(() => {
		const issuesAssignees = uniqBy(issues, (issue) => {
			if (!issue.assignee) {
				return undefined;
			}
			return issue.assignee.username;
		}).map((issue) => issue.assignee);
		return compact(issuesAssignees);
	}, [issues]);

	const activeFloors: IBaseFloor[] = useMemo(() => {
		const issuesWithLocations = issues.filter((issue) => issue.locations);
		const issuesFloors: IBaseFloor[] = compact(
			flatMap(issuesWithLocations, (issue) => issue.locations!.map((location) => location.floor))
		);
		return uniqBy(issuesFloors, (floor) => floor.floorId);
	}, [issues]);

	const activeAreas: IBaseAreaBaseFloor[] = useMemo(() => {
		const issuesWithLocations = issues.filter((issue) => issue.locations);
		const issuesAreas: IBaseAreaBaseFloor[] = compact(
			flatMap(issuesWithLocations, (issue) =>
				issue.locations!.map((location) => ({ ...location.area, ...location.floor }))
			)
		);
		return uniqBy(issuesAreas, (area) => area.areaId);
	}, [issues]);

	const professionCountObj: { [professionId: string]: number } = useMemo(() => {
		const issuesWithProfessions: IIssue[] = issues.filter((issue) => issue.profession);
		return countBy(issuesWithProfessions, (issue) => issue.profession?._id);
	}, [issues]);

	const prioritiesCountObj: Dictionary<number> = useMemo(() => countBy(issues, (issue) => issue.priority), [issues]);

	const statusesCountObj: Dictionary<number> = useMemo(() => countBy(issues, (issue) => issue.status), [issues]);

	const floorsCountObj: Dictionary<number> = useMemo(() => {
		const totalLocations: IIssueLocation[] = issues.reduce(
			(acc, issue) => acc.concat(uniqBy(issue.locations || [], (location) => location.floor.floorId)),
			[] as IIssueLocation[]
		);
		return countBy(totalLocations, (location) => location.floor.floorId);
	}, [issues]);

	const areasCountObj: Dictionary<number> = useMemo(() => {
		const totalLocations: IIssueLocation[] = issues.reduce(
			(acc, issue) => acc.concat(uniqBy(issue.locations || [], (location) => location.area.areaId)),
			[] as IIssueLocation[]
		);
		return countBy(totalLocations, (location) => location.area.areaId);
	}, [issues]);

	const assigneesCountObj: Dictionary<number> = useMemo(
		() => countBy(issues, (issue) => issue.assignee?.username),
		[issues]
	);

	return {
		activeProfessions,
		activeAssignees,
		activeFloors,
		activeAreas,
		professionCountObj,
		prioritiesCountObj,
		statusesCountObj,
		floorsCountObj,
		areasCountObj,
		assigneesCountObj,
	};
};
