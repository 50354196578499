import { TagStatuses } from '../constants';

export enum IAdminPanelFiltersTypes {
	assignedFilter = 'assignedFilter',
	lastSeenFilter = 'lastSeenFilter',
	statusFilter = 'statusFilter',
	batteryFilter = 'batteryFilter',
}

export enum AssignedFilters {
	assignedTags = 'assignedTags',
	unassignedTags = 'unassignedTags',
}

export enum LastSeenFilter {
	lastSeenOk = 'lastSeenOk',
	lastSeenWarning = 'lastSeenWarning',
	lastSeenAlert = 'lastSeenAlert',
}

export enum BatteryFilter {
	high = 'high',
	medium = 'medium',
	low = 'low',
}

export interface IAdminPanelFilters {
	[IAdminPanelFiltersTypes.assignedFilter]: {
		[AssignedFilters.assignedTags]: boolean;
		[AssignedFilters.unassignedTags]: boolean;
	};
	[IAdminPanelFiltersTypes.lastSeenFilter]: {
		[LastSeenFilter.lastSeenOk]: boolean;
		[LastSeenFilter.lastSeenWarning]: boolean;
		[LastSeenFilter.lastSeenAlert]: boolean;
	};
	[IAdminPanelFiltersTypes.statusFilter]: {
		[TagStatuses.Good]: boolean;
		[TagStatuses.Sleeping]: boolean;
		[TagStatuses.Storage]: boolean;
		[TagStatuses.NoReception]: boolean;
	};
	[IAdminPanelFiltersTypes.batteryFilter]: {
		[BatteryFilter.high]: boolean;
		[BatteryFilter.medium]: boolean;
		[BatteryFilter.low]: boolean;
	};
}
