import React from 'react';
import classes from './styles.module.scss';

interface IAllTimeStatsCardProps {
	title: string;
	component: JSX.Element;
}

export const AllTimeStatsCard = (props: IAllTimeStatsCardProps) => {
	return (
		<div className={classes.AllTimeStatsCard_container} data-testid="AllTimeStatsCard">
			<div className={classes.title}>{props.title}</div>
			{props.component}
		</div>
	);
};
