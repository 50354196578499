import React, { useMemo } from 'react';
import classes from './styles.module.scss';

interface IDonutChartProps {
	fragment: number;
	maxNumber: number;
	backgroundColor: string;
	size?: string;
	strokeWidth?: string;
	hideText?: boolean;
	setMainContainerFullHeight?: boolean;
}

const DonutChart = (props: IDonutChartProps) => {
	const donutChartSettings: string[] = useMemo((): string[] => {
		if (!props.fragment) {
			return ['2', '98'];
		}
		const fragment = (100 / props.maxNumber) * props.fragment;
		const rest = 100 - fragment;
		return [String(fragment), String(rest)];
	}, [props.fragment, props.maxNumber, props.backgroundColor]);

	return (
		<div className={classes.svgItem} style={{ height: props.setMainContainerFullHeight ? '100%' : '' }}>
			<svg width={props.size || '35%'} height={props.size || '35%'} viewBox="0 0 40 40" className="donut">
				<circle className="donut-hole" cx="20" cy="20" r="15.91549430918954" fill="#fff"></circle>
				<circle
					className={classes.donutRing}
					cx="20"
					cy="20"
					r="15.91549430918954"
					fill="transparent"
					strokeWidth={props.strokeWidth || '3.5'}
				></circle>
				<circle
					className={classes.donutSegment}
					cx="20"
					cy="20"
					r="15.91549430918954"
					fill="transparent"
					strokeWidth={props.strokeWidth || '3.5'}
					strokeDasharray={`${donutChartSettings[0] || '0'} ${donutChartSettings[1] || '100'}`}
					strokeDashoffset="25"
					style={{ stroke: props.backgroundColor }}
				></circle>
				<g className={classes.donutText}>
					<text y="50%" transform="translate(0, 2)">
						<tspan x="50%" y="53%" textAnchor="middle" className={classes.donutPercent}>
							{!props.hideText && (
								<tspan className={`${classes.donutPercent} ${classes.fragemnt}`}>
									{props.fragment}
								</tspan>
							)}
							{!props.hideText && (
								<tspan className={`${classes.donutPercent} ${classes.maxNumber}`}>
									/{props.maxNumber}
								</tspan>
							)}
						</tspan>
					</text>
				</g>
			</svg>
		</div>
	);
};

export { DonutChart };
