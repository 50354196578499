import jsonData from './default-config-data.json';
import { IConfig } from './interfaces/IConfig';
import { IBaseConfig } from './interfaces/IBaseConfig';
import { Languages } from '@shared/constants/languages';
import { Environments } from '@shared/constants/environments';
import { isTrusstorMobile } from '@shared/utils/mobile.utils';

const {
	amplitudeApiKey,
	posthogAPIKey,
	sentryDsn,
	weatherApiKey,
	fullStoryOrganizationId,
	sentryOrganization,
	sentryProjectId,
} = jsonData;

export const getIsMobileRedirectionEnabled = (redirectToMobileFromMobile: boolean) => {
	const redirectToMobileFromPc: boolean = process.env.REACT_APP_MOBILE_REDIRECT === 'true';
	return redirectToMobileFromPc || (isTrusstorMobile() && redirectToMobileFromMobile);
};

const getShouldRedirectToMobile = (redirectToMobileFromMobile: boolean) => {
	const isExecutiveDashboardPath: boolean = window.location.href.includes('projects');
	return !isExecutiveDashboardPath && getIsMobileRedirectionEnabled(redirectToMobileFromMobile);
};

const defaultConfig: Partial<IConfig> = {
	fullStoryOrganizationId,
	intercomAppId: 'c203nuzf',
	weatherApiKey,
	posthogAPIKey,
	sentryDsn,
	sentryOrganization,
	sentryProjectId,
	amplitudeApiKey,
	weatherApiUrl: 'api.openweathermap.org/data/2.5/weather',
	commonPublicAssetsUrl: 'https://common-public-assets.s3.eu-central-1.amazonaws.com',
	defaultLanguage: Languages.ENGLISH_US,
	reportsInterval: 1000 * 60 * 10,
	requestDelayTime: 1000 * 5,
	contactEmail: 'mailto:help@trusstor.com',
};

const dev: IBaseConfig = {
	environment: 'dev',
	apiGateway: `https://${window.location.hostname}:5000/api`,
	mobileUrl: `https://${window.location.hostname}:8080`,
	redirectToMobileFromMobile: true,
	showNotifications: true,
	showWeeklyWidget: true,
	enableMatrixDnd: true,
	showIssues: true,
};

const ondemand: IBaseConfig = {
	environment: 'ondemand',
	apiGateway: `https://${window.location.hostname}:5000/api`,
	mobileUrl: `https://${window.location.hostname}:8080`,
	redirectToMobileFromMobile: true,
	showNotifications: true,
	showWeeklyWidget: true,
	enableMatrixDnd: true,
	showIssues: true,
};

const prod: IBaseConfig = {
	environment: 'prod',
	apiGateway: 'https://dash.trusstor.com:443/api',
	mobileUrl: 'https://m.dash.trusstor.com:443',
	redirectToMobileFromMobile: true,
	showNotifications: true,
	showWeeklyWidget: true,
	enableMatrixDnd: true,
	showIssues: true,
};

const staging: IBaseConfig = {
	environment: 'staging',
	apiGateway: 'https://staging.dash.trusstor.com:443/api',
	mobileUrl: 'https://m.staging.dash.trusstor.com:443',
	redirectToMobileFromMobile: true,
	showNotifications: true,
	showWeeklyWidget: true,
	enableMatrixDnd: true,
	showIssues: true,
};

const integration: IBaseConfig = {
	environment: 'integration',
	apiGateway: 'https://integration.dash.trusstor.com:443/api',
	mobileUrl: 'https://m.integration.dash.trusstor.com:443',
	redirectToMobileFromMobile: true,
	showNotifications: true,
	showWeeklyWidget: true,
	enableMatrixDnd: true,
	showIssues: true,
};

const qa: IBaseConfig = {
	environment: 'qa',
	apiGateway: 'https://qa.dash.trusstor.com:443/api',
	mobileUrl: 'https://m.qa.dash.trusstor.com:443',
	redirectToMobileFromMobile: true,
	showNotifications: true,
	showWeeklyWidget: true,
	enableMatrixDnd: true,
	showIssues: true,
};

const qaLocal: IBaseConfig = {
	environment: 'qaLocal',
	apiGateway: process.env.REACT_APP_ON_DEMAND_IP
		? `https://${process.env.REACT_APP_ON_DEMAND_IP}:5000/api`
		: 'https://qa.dash.trusstor.com:443/api',
	mobileUrl: `https://${window.location.hostname}:8080`,
	redirectToMobileFromMobile: true,
	showNotifications: true,
	showWeeklyWidget: true,
	enableMatrixDnd: true,
	showIssues: true,
};

let config: IBaseConfig;
switch (process.env.REACT_APP_STAGE) {
	case Environments.prod:
		config = prod;
		break;
	case Environments.staging:
		config = staging;
		break;
	case Environments.integration:
		config = integration;
		break;
	case Environments.qa:
		config = qa;
		break;
	case Environments.qaLocal:
		config = qaLocal;
		break;
	case Environments.ondemand:
		config = ondemand;
		break;
	default:
		config = dev;
		break;
}

export const isMobileRedirectionEnabled: boolean = getIsMobileRedirectionEnabled(config.redirectToMobileFromMobile);

const fullConfig: IConfig = {
	...defaultConfig,
	...config,
	shouldRedirectToMobile: getShouldRedirectToMobile(config.redirectToMobileFromMobile),
} as IConfig;

export { fullConfig as config };
