import React, { useState } from 'react';
import { TooltipedHoverIcon } from '../../TooltipedHoverIcon';
import { translationService } from '../../../index';
import { EditProfessionDialog } from '../Dialogs/EditProfessionDialog/EditProfessionDialog';
import classes from './styles.module.scss';
import { pencilGreyIcon, pencilIcon } from '@assets/adminPanel';

interface IProfessionTableRowButtonsProps {
	profession: any;
	onExitDialog: () => void;
}

const ProfessionTableRowButtons = (props: IProfessionTableRowButtonsProps) => {
	const [showEditProfessionColorDialog, setShowEditProfessionColorDialog] = useState<boolean>(false);

	const handleCloseDialog = (): void => {
		showEditProfessionColorDialog && setShowEditProfessionColorDialog(false);
		props.onExitDialog();
	};

	const handleEditProfessionClick = () => setShowEditProfessionColorDialog(true);

	return (
		<React.Fragment>
			{showEditProfessionColorDialog && (
				<EditProfessionDialog close={handleCloseDialog} profession={props.profession} />
			)}

			<div className={classes.deleteButtonWrapper}>
				<TooltipedHoverIcon
					onClick={handleEditProfessionClick}
					baseIcon={pencilGreyIcon}
					hoverIcon={pencilIcon}
					tooltipText={translationService.get('editProfession')}
					styleClass={classes.avatar}
				/>
			</div>
		</React.Fragment>
	);
};

export { ProfessionTableRowButtons };
