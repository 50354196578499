import { TranslationService } from '../../../services/translation.service';
import { IAreaSequenceItemStatusUpdateNotificationMetadata } from '../../../interfaces/IAreaSequenceItemStatusUpdateNotificationMetadata';

export const getAreaSequenceItemStatusUpdateCardTitleText = (
	metadata: IAreaSequenceItemStatusUpdateNotificationMetadata,
	translationService: TranslationService
): string => {
	return translationService.get('notificationTitle-AreaSequenceItemStatusUpdate', {
		user: metadata.userName,
	});
};

export const getAreaSequenceItemStatusUpdateCardDescriptionText = (
	translationService: TranslationService,
	metadata: IAreaSequenceItemStatusUpdateNotificationMetadata
): string => {
	const areasText = (() => {
		if (metadata.areasNickName.length <= 5) {
			return metadata.areasNickName.join(', ');
		}

		const moreCount = metadata.areasNickName.length - 4;
		return translationService.get('notificationDescription-AreaSequenceItemStatusUpdate-areasTextMore', {
			areas: metadata.areasNickName.slice(0, 4).join(', '),
			moreCount: moreCount.toString(),
		});
	})();

	return translationService.get('notificationDescription-AreaSequenceItemStatusUpdate', {
		areas: areasText,
		status: translationService.get(`notificationDescription-AreaSequenceItemStatusUpdate-${metadata.newStatus}`),
		sequenceItemName: metadata.sequenceItemDescription,
	});
};
