import React from 'react';
import classes from './styles.module.scss';
import { maxBy } from 'lodash';
import { IRoundedProfessionProductivityReport } from '@interfaces/IRoundedProfessionProductivityReport';
import { sortFloorsByTotalHours, sumProfessionsProductivityByFloor } from './tagsLocationDistributionGraph.utils';
import { GraphRow } from '@src/Components/GraphRow/GraphRow';
import { COLORS } from '@src/constants';
import { useFloorDataQuery } from '@src/hooks/queries/floors.queries.hooks';
import { IFloor } from '@interfaces/index';
import { useSelector } from 'react-redux';
import { selectProjectId } from '@store/slices/project.slice';
import { sortFloors } from '@shared/utils/sort.utils';
import { SORT_ORDER } from '@shared/constants/constants';

export enum TagsLocationDistributionGraphSortByEnum {
	LOCATION = 'location',
	TIME = 'time',
}

interface ITagsLocationDistributionGraphProps {
	professionsProductivityReports: IRoundedProfessionProductivityReport[];
	sortBy: TagsLocationDistributionGraphSortByEnum;
}

export const TagsLocationDistributionGraph = (props: ITagsLocationDistributionGraphProps) => {
	const projectId: string = useSelector(selectProjectId)!;
	const floors: IFloor[] = useFloorDataQuery(projectId);
	const sortedFloors: IFloor[] =
		props.sortBy === TagsLocationDistributionGraphSortByEnum.LOCATION
			? sortFloors(floors, SORT_ORDER.ASCENDING)
			: sortFloorsByTotalHours(floors, sumProfessionsProductivityByFloor(props.professionsProductivityReports));

	const floorHours: {
		[floorId: string]: {
			totalHours: number;
		};
	} = sumProfessionsProductivityByFloor(props.professionsProductivityReports);
	const floorsTotalHours: number[] = Object.values(floorHours).map((floor) => floor.totalHours);
	const maxTotalHoursFloorId: string =
		maxBy(Object.keys(floorHours), (floorId) => floorHours[floorId].totalHours) || '';
	const maxFloorTotalHours: number = floorHours[maxTotalHoursFloorId]?.totalHours || 0;
	const totalHoursForAllFloors = floorsTotalHours.reduce((acc, val) => acc + val, 0);

	return (
		<div className={classes.rowsContainer}>
			{sortedFloors.map((floor: IFloor) => {
				const floorTotalHours: number | undefined = floorHours[floor.floorId]?.totalHours;
				if (!floorTotalHours) {
					return null;
				}

				const percentageOutOfMax: number = (floorTotalHours / maxFloorTotalHours) * 100;
				const percentageOutOfTotal: number = (floorTotalHours / totalHoursForAllFloors) * 100;
				const rowText: string = `${percentageOutOfTotal.toFixed(1)}%`;
				return (
					<GraphRow
						key={floor.floorId}
						rowHeader={floor.floorNick}
						percentage={percentageOutOfMax}
						rowValue={rowText}
						color={COLORS.blue300}
						height={'30px'}
						animated
					/>
				);
			})}
		</div>
	);
};
