import React from 'react';
import { FilterCard, IFilterCardProps } from '../FilterCard/FilterCard';

interface IAssigneeFilterCardProps extends IFilterCardProps {
	name: string;
}

export const AssigneeFilterCard = (props: IAssigneeFilterCardProps) => {
	return <FilterCard {...props} text={props.name} />;
};
