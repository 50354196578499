import React, { createContext, useEffect } from 'react';
import { InvestigationReportMainPage_BI } from '../../../../utils/bi.utils';
import classes from '../InvestigationReportPage/styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getStaticManagers, getStaticTags } from '../../../../store/thunks';
import { IRootState } from '../../../../store/slices';
import { RoleType } from '@shared/interfaces/RoleType.enum';
import { selectLoggedUserDetails } from '../../../../store/slices/login.slice';
import { CardsSection } from '../CardsSection/CardsSection';
import { RecentReportsSection } from '../RecentReportsSection/RecentReportsSection';
import { translationService } from '../../../../index';
import { useRecentInvestigationReportsQuery } from '@src/hooks/queries/analysisCenter.queries.hooks';
import { useRenderMonitoring } from '@shared/hooks/monitoring.hooks';
import { exportScissorLiftReportToExcel } from '@utils/scissorLiftReport.excel.utils';
import moment from 'moment';
import { IProject } from '@shared/interfaces/IProject';

interface IAnalysisCenterPageContext {
	areThereInvestigationReports: boolean;
}

export const AnalysisCenterPageContext = createContext<IAnalysisCenterPageContext | undefined>(undefined);

const AnalysisCenterPage = () => {
	useRenderMonitoring('AnalysisCenterPage');
	const projectId: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.projectId);
	const isAdmin: boolean =
		useSelector((state: IRootState) => state.loginReducer.loggedUser?.userDetails.permissions.roleType) ===
		RoleType.TRUSSTOR_ADMIN;
	const dispatch = useDispatch();
	const { _id: userId } = useSelector(selectLoggedUserDetails)!;
	const { investigationReports, isInitialLoading } = useRecentInvestigationReportsQuery(userId!, projectId);
	const areThereInvestigationReports: boolean = investigationReports.length > 0 || isInitialLoading;

	useEffect(() => {
		InvestigationReportMainPage_BI();
	}, []);

	useEffect(() => {
		dispatch(getStaticTags(projectId, isAdmin));
		dispatch(getStaticManagers({ projectId }));
	}, [projectId, isAdmin]);

	return (
		<AnalysisCenterPageContext.Provider
			value={{
				areThereInvestigationReports,
			}}
		>
			<div
				className={classes.pageContainer}
				style={{ justifyContent: areThereInvestigationReports ? 'start' : 'center' }}
			>
				<section className={classes.cardsContainer}>
					<div
						className={classes.title}
						style={{ alignSelf: areThereInvestigationReports ? 'start' : 'center' }}
					>
						{translationService.get('getStartedWithOneOfTheFollowingAnalyses')}
					</div>
					<CardsSection areThereInvestigationReports={areThereInvestigationReports} />
				</section>
				{areThereInvestigationReports && (
					<section className={classes.mainInvestigationReportContainer}>
						<RecentReportsSection
							investigationReports={investigationReports}
							isLoading={isInitialLoading}
						/>
					</section>
				)}
			</div>
		</AnalysisCenterPageContext.Provider>
	);
};

export { AnalysisCenterPage };
