import React from 'react';
import { MilestonesPageHeader } from '@src/Components/MilestonesPageHeader/MilestonesPageHeader';
import { useProjectMilestonesQuery } from '@src/hooks/queries/milestones.queries.hooks';
import { selectProjectId } from '@store/slices/project.slice';
import { useSelector } from 'react-redux';
import { MilestoneCreationCard } from '@src/Components/MilestoneCreationCard/MilestoneCreationCard';
import classes from './styles.module.scss';
import { MilestoneItemCard } from '@src/Components/MilestoneItemCard/MilestoneItemCard';
import { useSequenceItemsByProjectQuery } from '@src/hooks/queries/sequenceItem.query';
import { ISequenceItem } from '@interfaces/ISequenceItem';
import { useHistory } from 'react-router-dom';

export const MilestonesPage = () => {
	const projectId: string = useSelector(selectProjectId)!;
	const { sequenceItems } = useSequenceItemsByProjectQuery(projectId);
	const { projectMilestones, isFetched } = useProjectMilestonesQuery(projectId);
	const location = window.location.pathname;
	const showCreateCard = location.includes('create');
	const isEmptyState: boolean = projectMilestones.length === 0 && isFetched;
	const history = useHistory();

	const exitShowCreateCard = () => {
		history.push(`/${projectId}/system/milestones`);
	};

	const createButtonClick = () => {
		if (isEmptyState) {
			return;
		}
		if (!showCreateCard) {
			history.push(`/${projectId}/system/milestones/create`);
		}
	};

	return (
		<div className={classes.page}>
			<MilestonesPageHeader
				milestonesAmount={projectMilestones.length}
				createButtonClick={createButtonClick}
				isCreateDisabled={isEmptyState || showCreateCard}
			/>
			<div className={classes.content}>
				{isEmptyState ? (
					<MilestoneCreationCard index={1} />
				) : (
					projectMilestones.map((milestone, index) => {
						const linkedSequenceItems: ISequenceItem[] = sequenceItems.filter(
							(sequenceItem) => sequenceItem.linkedMilestoneId === milestone._id?.toString()
						);
						return (
							<MilestoneItemCard
								index={index + 1}
								key={milestone._id}
								milestone={milestone}
								linkedSequenceItems={linkedSequenceItems}
							/>
						);
					})
				)}
				{showCreateCard && (
					<MilestoneCreationCard
						index={projectMilestones.length + 1}
						handleCreateCallback={exitShowCreateCard}
						handleCancelClick={exitShowCreateCard}
					/>
				)}
			</div>
		</div>
	);
};
