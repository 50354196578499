import { IProfession } from '@interfaces/index';
import { IProfessionDisplaySettings } from '@shared/interfaces/IProfessionDisplaySettings';

export const getDefaultProfessionDisplaySettings = (
	professions: IProfession[],
	projectId: string
): Record<keyof IProfessionDisplaySettings, boolean> => {
	/* We want to check that contractor and specialty are not defined in the profession
		we do it in order to check if the trade setting can be removed from the profession
		if some of the keys exist we can remove the trade option and leave the other one
	*/
	const areSomeDisplaySettingsExceptTradeKeysExistInProfession = (profession: IProfession): boolean => {
		const keysOtherThanTrade: readonly (keyof IProfessionDisplaySettings)[] = ['contractor', 'specialty'];
		const isSomeDisplaySettingKeyExistInProfession: boolean = keysOtherThanTrade.some((key) => profession[key]);
		return isSomeDisplaySettingKeyExistInProfession;
	};

	const isDisplayPropertyEnabledOnAllProfessions = (propertyName: keyof IProfessionDisplaySettings): boolean => {
		const isChecked = professions.some((profession: IProfession) => {
			const isProfessionActive: boolean = !!profession.activeProjects[projectId];
			if (!isProfessionActive) {
				return false;
			}
			const professionDisplaySettings: IProfessionDisplaySettings | undefined =
				profession.activeProjects[projectId]?.displaySettings;
			if (!professionDisplaySettings) {
				return true;
			}
			if (propertyName === 'trade') {
				if (!areSomeDisplaySettingsExceptTradeKeysExistInProfession(profession)) {
					return false;
				}
			} else {
				return professionDisplaySettings[propertyName] && profession[propertyName];
			}
			return professionDisplaySettings[propertyName];
		});
		return isChecked;
	};

	return {
		trade: isDisplayPropertyEnabledOnAllProfessions('trade'),
		specialty: isDisplayPropertyEnabledOnAllProfessions('specialty'),
		contractor: isDisplayPropertyEnabledOnAllProfessions('contractor'),
	};
};
