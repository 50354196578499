import React from 'react';
import classes from './styles.module.scss';
import { TopStats } from '@src/Components/SequenceProgressAnalysis/OverallProgress/MainProgressStatsSection/TopStats/TopStats';
import { ISequenceItemProgressPeriodOverallReport } from '@shared/interfaces/ISequenceItemProgressPeriodOverallReport';
import { TotalProgressGraph } from '@src/Components/SequenceProgressAnalysis/OverallProgress/TotalProgressGraph/TotalProgressGraph';

interface IMainProps {
	overallProgressSummary: ISequenceItemProgressPeriodOverallReport;
}

export const MainProgressStatsSection = (props: IMainProps) => {
	return (
		<div className={classes.MainProgressSection_container} data-testid="MainProgressStatsSection">
			<TopStats overallProgressSummary={props.overallProgressSummary} />
			<TotalProgressGraph overallProgressSummary={props.overallProgressSummary} />
		</div>
	);
};
