import { IMergedFloorView } from '@src/interfaces';
import React from 'react';
import { FloorView } from '@src/Components/MainPageSections/FloorView/FloorView';
import classes from './styles.module.scss';
import classnames from 'classnames';

interface IFloorViewWrapperProps {
	floor?: IMergedFloorView;
}

export const FloorViewWrapper = (props: IFloorViewWrapperProps) => {
	return (
		<div
			className={classnames({ [classes.container]: true, [classes.notDisplay]: !props.floor })}
			data-testid={'floorPanel'}
		>
			{props.floor && <FloorView floor={props.floor} />}
		</div>
	);
};
