import React, { useState } from 'react';
import classes from './styles.module.scss';
import Dialog from '@material-ui/core/Dialog';
import { IMainPageWidgetActivityData } from '@interfaces/IMainPageWidgetActivityData';
import { WidgetActivityCardDetails } from '@src/Components/WorkplanWidgetComponents/WeeklyWidget/WidgetActivityCardDetails/WidgetActivityCardDetails';
import { translationService } from '@src/servicesInitializers';
import { getColorFromActivityGroupStatus } from '@utils/generalUtils';
import { MultiIssuesViewDialog } from '@src/Components/MultiIssuesViewDialog/MultiIssuesViewDialog';
import { IIssue } from '@shared/interfaces/IIssue';
import { WidgetActivityCardDetailsHeader } from '@src/Components/WorkplanWidgetComponents/WeeklyWidget/WidgetActivityCardDetailsHeader/WidgetActivityCardDetailsHeader';
import { ActivityChipMenu } from '@src/Components/Calendar/ActivityChipMenu/ActivityChipMenu';
import { useOutsideClick } from '@shared/hooks/custom.hooks';
import { ActivitySequenceItemMenuActions } from '@src/Components/WorkPlan/ActivityProgressTracker/components/ActivitySequenceMatrix/cellComponents/ActivitySequenceItemCell/activitySequence.menuItems';
import { ActivityCardDialogs } from '@src/Components/WorkplanWidgetComponents/WeeklyWidget/WeeklyWidgetActivityCard/WeeklyWidgetActivityCard';
import { WidgetDialogContent } from '@src/Components/WorkplanWidgetComponents/WeeklyWidget/WidgetDialogContent/WidgetDialogContent';

interface IWidgetActivitiesDialogProps {
	show: boolean;
	close: () => void;
	activity: IMainPageWidgetActivityData;
	onUpdateIssue: (issue: IIssue) => void;
	setShowDialog: React.Dispatch<React.SetStateAction<ActivityCardDialogs | undefined>>;
	openCreateIssueDialog: (actionType: ActivitySequenceItemMenuActions) => Promise<void>;
	openViewIssuesDialog: (actionType: ActivitySequenceItemMenuActions) => Promise<void>;
	openEditWorkPlanDialog: (e) => void;
}

export const WidgetActivitiesDialog = (props: IWidgetActivitiesDialogProps) => {
	const [isViewIssuesDialogOpen, setIsViewIssuesDialogOpen] = useState<boolean>(false);

	const [showActivityMenu, setShowActivityMenu] = useState<boolean>(false);

	const handleMouseLeave = (event: React.MouseEvent<HTMLDivElement>) => {
		event.stopPropagation();
	};
	const handleMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
		event.stopPropagation();
	};

	const [menuElement, setMenuElement] = useState<HTMLElement | null>(null);

	useOutsideClick({ current: menuElement }, setShowActivityMenu, false, [{ current: menuElement }]);

	return (
		<Dialog
			disableEnforceFocus
			onMouseLeave={handleMouseLeave}
			onMouseEnter={handleMouseEnter}
			onClose={props.close}
			open={props.show}
			classes={{
				paper: classes.dialog,
			}}
			data-testid="dialog-container"
		>
			{showActivityMenu && (
				<div
					ref={setMenuElement}
					className={classes.activityMenu}
					style={{ [translationService.getIsRtl() ? 'left' : 'right']: '10px' }}
				>
					<ActivityChipMenu
						width={150}
						activity={{
							status: props.activity.groupStatus,
							issues: props.activity.issues,
						}}
						openEditDialog={props.openEditWorkPlanDialog}
						openCompleteDialog={() => {
							props.setShowDialog(ActivityCardDialogs.complete);
						}}
						openStartDialog={() => {
							props.setShowDialog(ActivityCardDialogs.start);
						}}
						openDeleteDialog={() => {
							props.setShowDialog(ActivityCardDialogs.delete);
						}}
						openCreateIssueDialog={props.openCreateIssueDialog}
						openViewIssuesDialog={props.openViewIssuesDialog}
					/>
				</div>
			)}
			<MultiIssuesViewDialog
				onUpdateIssue={props.onUpdateIssue}
				show={isViewIssuesDialogOpen && !!props.activity.issues?.length}
				close={() => setIsViewIssuesDialogOpen(false)}
				issues={props.activity.issues || []}
				headerTitle={props.activity.description}
				headerInfo={{
					value: translationService.get(props.activity.groupStatus),
					backgroundColor: getColorFromActivityGroupStatus(props.activity.groupStatus),
				}}
			/>
			<div className={classes.WidgetActivitiesDialog_container} data-testid="WidgetActivitiesDialog">
				<WidgetActivityCardDetailsHeader
					closeAction={props.close}
					activity={props.activity}
					setShowActivityMenu={() => setShowActivityMenu(true)}
				/>
				<WidgetActivityCardDetails
					isDialog
					activity={props.activity}
					showIssuesDialog={() => setIsViewIssuesDialogOpen(true)}
					showCompleteDialog={() => props.setShowDialog(ActivityCardDialogs.complete)}
				/>
				<WidgetDialogContent activity={props.activity} />
			</div>
		</Dialog>
	);
};
