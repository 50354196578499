import React from 'react';
import { map } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { TooltipedIcon } from '../Tooltips/TooltipedIcon';
import { textStyle } from '@shared/utils/text.utils';
import { COLORS, SAFETY_ICON_HEIGHT, SAFETY_ICON_WIDTH } from '../../constants';
import { translationService } from '../../index';
import { IMinimalSafetyEventForClient } from '../../interfaces/ISafetyEvent';
import { DIRECTIONS } from '@shared/constants/directions';

interface IStyleProps {
	direction?: string;
}

const useStyles = makeStyles({
	tooltipTextStyles: textStyle({
		fontClass: 'body2',
		marginBottom: 3,
		marginTop: 3,
		color: COLORS.white,
	}),
	divider: {
		height: 2,
		opacity: 0.12,
		backgroundColor: '#242424',
	},
	avatar: ({ direction }: IStyleProps) => ({
		borderRadius: '50%',
		boxShadow: '0px 3px 2px 0px rgba(0, 0, 0, 0.45)',
		backgroundColor: '#fefefe',
		position: 'absolute',
		top: -6,
		left: direction === DIRECTIONS.LTR ? 6 : -6,
		height: 12,
		width: 12,
		zIndex: 12,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	}),
	safetyIconText: textStyle({
		color: '#3b3b3b',
		fontClass: 'body1',
		textAlign: 'center',
	}),
	safety: {
		width: SAFETY_ICON_WIDTH,
		height: SAFETY_ICON_HEIGHT,
	},
	safetyIcon: {
		cursor: 'pointer',
	},
});

interface ISafetyIconProps {
	safetyEvents: IMinimalSafetyEventForClient[];
	iconSource: string;
	click: () => void;
}

export const SafetyIcon = (props: ISafetyIconProps) => {
	const classes = useStyles({
		direction: translationService ? translationService.getDirection() : DIRECTIONS.RTL,
	});

	return (
		<div onClick={props.click} className={classes.safetyIcon} data-testid={'safetyIcon'}>
			<TooltipedIcon
				key="safety_tooltip"
				tooltipComponent={
					<div>
						{map(props.safetyEvents, (event, index) => {
							return [
								<p className={classes.tooltipTextStyles} key={event.safetyViolationType + index}>
									{translationService.get(event.safetyViolationType)}
								</p>,
								index < props.safetyEvents.length - 1 ? (
									<div className={classes.divider} key={'divider' + index} />
								) : null,
							];
						})}
					</div>
				}
			>
				<img className={classes.safety} src={props.iconSource} alt="safetyIcon" />
			</TooltipedIcon>
			{
				<div className={classes.avatar} key="alerts_div">
					<p className={classes.safetyIconText} data-testid={'safetyEventsCount'}>
						{props.safetyEvents.length}
					</p>
				</div>
			}
		</div>
	);
};
