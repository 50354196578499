import { IBaseFloor, IProfession, IProject } from '@interfaces/index';
import { IIssueAssignee, IIssueLocation, IssueStatus } from '@shared/interfaces/IIssueShared';
import { Dictionary, groupBy } from 'lodash';
import { exportService, translationService } from '@src/servicesInitializers';
import { IRow, ISheets, ISheetsInfoValues } from '@src/services';
import moment, { Moment } from 'moment';
import { store } from '@store/store';
import { IIssue } from '@shared/interfaces/IIssue';
import { IssuesPageViewModes } from '@shared/constants/issues.contants';
import { getIssueStatusMainText } from '@shared/utils/issues.utils';
import { getProfessionDisplayText } from '@shared/utils/professions.utils';

const handleProfessionSheets = (
	colIssues: IIssue[],
	sheets: ISheets,
	sheetsInfoValues: ISheetsInfoValues,
	project: IProject,
	excelTime: string
): string => {
	const issueProfession: IProfession | undefined = colIssues[0].profession;
	const projectName: string = project.name;
	const sheetName: string = issueProfession
		? `${getProfessionDisplayText(issueProfession, translationService)}`
		: translationService.get('general');
	sheets[sheetName] = [];
	sheetsInfoValues[sheetName] = {
		[translationService.get('project')]: { rowNumber: 4, columnChar: 'A', value: projectName },
		[translationService.get('profession')]: {
			rowNumber: 5,
			columnChar: 'A',
			value: sheetName,
		},
		[translationService.get('date')]: {
			rowNumber: 4,
			columnChar: 'F',
			value: excelTime,
		},
	};

	return sheetName;
};

const handleAssigneeSheets = (
	colIssues: IIssue[],
	sheets: ISheets,
	sheetsInfoValues: ISheetsInfoValues,
	project: IProject,
	excelTime: string
): string => {
	const assignee: IIssueAssignee | undefined = colIssues[0].assignee || undefined;
	const projectName: string = project.name;
	const sheetName: string = assignee?.name || translationService.get('unassigned');
	sheets[sheetName] = [];
	sheetsInfoValues[sheetName] = {
		[translationService.get('project')]: { rowNumber: 4, columnChar: 'A', value: projectName },
		[translationService.get('assignee')]: {
			rowNumber: 5,
			columnChar: 'A',
			value: sheetName,
		},
		[translationService.get('date')]: {
			rowNumber: 4,
			columnChar: 'F',
			value: excelTime,
		},
	};

	return sheetName;
};

export const exportIssuesToExcel = async (
	issuesByEntity: Dictionary<IIssue[]>,
	sortedColumnKeys: string[],
	project: IProject,
	viewMode: IssuesPageViewModes
) => {
	const tz: string = project.tz;

	const date: Moment = moment.tz(new Date(), tz);

	const excelTime: string = `${date.format('MMM Do, YYYY')}`;
	const excelName: string = `${translationService.get('issues')} - ${
		store.getState().projectReducer?.workingProject?.name
	} - ${excelTime}`;

	const sheets: ISheets = {};
	const sheetsInfoValues: ISheetsInfoValues = {};

	for (const colKey of sortedColumnKeys) {
		const colIssues: IIssue[] = issuesByEntity[colKey];
		if (!colIssues?.length) continue;

		const issuesOfProfessionSorted: IIssue[] = sortIssuesByPriorityAndDate(colIssues);

		const sheetName: string =
			viewMode === IssuesPageViewModes.PROFESSIONS
				? handleProfessionSheets(colIssues, sheets, sheetsInfoValues, project, excelTime)
				: handleAssigneeSheets(colIssues, sheets, sheetsInfoValues, project, excelTime);

		for (const issue of issuesOfProfessionSorted) {
			const row: IRow = {};
			row[translationService.get('issuesExcel_column_created')] = {
				value: moment.tz(issue.createDate, tz).format('MMM Do, YYYY'),
			};
			row[translationService.get('issuesExcel_column_dueDate')] = {
				value: issue.dueDate ? moment.tz(issue.dueDate, tz).format('MMM Do, YYYY') : '',
			};
			row[translationService.get('issuesExcel_column_priority')] = {
				value: translationService.get(issue.priority),
			};
			row[translationService.get('issuesExcel_column_description')] = { value: issue.description };
			row[translationService.get('issuesExcel_column_status')] = {
				value: getIssueStatusMainText(issue.status, translationService),
				...(issue.status === IssueStatus.COMPLETED && { color: 'a2e7a2' }),
			};
			if (viewMode !== IssuesPageViewModes.ASSIGNEES) {
				row[translationService.get('issuesExcel_column_assignee')] = { value: issue.assignee?.name };
			}
			row[translationService.get('issuesExcel_column_location')] = { value: getLocationStringFormatted(issue) };
			if (viewMode !== IssuesPageViewModes.PROFESSIONS) {
				row[translationService.get('issuesExcel_column_profession')] = {
					value: issue.profession
						? `${getProfessionDisplayText(issue.profession, translationService)}`
						: translationService.get('general'),
				};
			}
			row[translationService.get('issuesExcel_private')] = {
				value: issue.isPrivate ? translationService.get('yes') : '',
			};
			sheets[sheetName].push(row);
		}
	}

	await exportService.exportMultipleSheetsToExcel(
		sheets,
		excelName,
		translationService.get('issues'),
		undefined,
		{ cellColor: 'FFFFFF', headerColor: '103045', columnWidth: 20, headerRow: 7 },
		undefined,
		sheetsInfoValues
	);
};

const getLocationStringFormatted = (issue: IIssue): string => {
	if (!issue.locations?.length) return '';
	let locationsStringToReturn: string = '';
	const locationsGroupedByFloor: Dictionary<IIssueLocation[]> = groupBy(
		issue.locations,
		(location) => location.floor.floorId
	);
	Object.values(locationsGroupedByFloor).forEach((locations) => {
		const floor: IBaseFloor = locations[0].floor;
		locationsStringToReturn += `${floor.floorNick}(${locations
			.map((location) => location.area.areaNick)
			.join(', ')})\n`;
	});
	return locationsStringToReturn;
};

const sortIssuesByPriorityAndDate = (issues: IIssue[]): IIssue[] => {
	return issues.sort((a, b) => {
		if (a.priority === 'critical' && b.priority === 'regular') {
			return -1;
		} else if (a.priority === 'regular' && b.priority === 'critical') {
			return 1;
		} else {
			return new Date(b.createDate).getTime() - new Date(a.createDate).getTime();
		}
	});
};
