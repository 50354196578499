import React from 'react';
import { ISequenceItemByLocationProgressReport } from '@shared/interfaces/ISequenceItemByLocationProgressReport';
import { ISequenceItem } from '@interfaces/ISequenceItem';
import { GraphRow } from '@src/Components/GraphRow/GraphRow';
import { useAreaSequenceItemsCountByFloorId } from '@src/hooks/queries/areaSequenceItems.query';

interface IFloorRow {
	summary: ISequenceItemByLocationProgressReport;
	sequenceItemsOfProject: ISequenceItem[];
}

export const FloorGraphRow = (props: IFloorRow) => {
	const floorId: string = props.summary.floorId;
	const floorNick: string = props.summary.floorNick;
	const { asisCount: numberOfAsisInFloor } = useAreaSequenceItemsCountByFloorId(floorId);

	if (!numberOfAsisInFloor) {
		return null;
	}

	const completedPercentage: number = (props.summary.overallProgress.totalCompletedCount / numberOfAsisInFloor) * 100;
	const periodPercentage: number =
		(props.summary.overallProgress.totalCompletedInPeriodCount / numberOfAsisInFloor) * 100;
	const completedPercentageWithoutPeriod: number = completedPercentage - periodPercentage;

	const completedPercentageText: string =
		completedPercentage === 0 ? `${completedPercentage}` : `${completedPercentage.toFixed(1)}%`;

	return (
		<GraphRow
			key={floorId}
			rowHeader={floorNick}
			percentage={completedPercentageWithoutPeriod}
			secondaryPercentage={periodPercentage}
			rowValue={completedPercentageText}
		/>
	);
};
