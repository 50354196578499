import React, { useEffect } from 'react';
import classes from './styles.module.scss';
import { RadioButtonItem } from './RadioButtonItem/RadioButtonItem';
import { IRadioButtonOption, IRadioButtonOptionWithOptionalInput } from './IRadioButtonOption';

interface IRadioButtonComponentProps {
	options: IRadioButtonOptionWithOptionalInput[];
	setSelectedOption: ({ option, dropdownValue }: { option?: string; dropdownValue?: any }) => void;
	selectedOptionValue?: string;
	initialDropdownValue?: any;
	emptyDropdownStateComponent?: React.ReactNode;
}

export const RadioButtonComponent = (props: IRadioButtonComponentProps) => {
	const getInitialSelectedOption = (): IRadioButtonOptionWithOptionalInput | undefined => {
		return props.selectedOptionValue
			? props.options.find((option) => option.value === props.selectedOptionValue)
			: undefined;
	};
	const [selectedOption, setSelectedOption] = React.useState<IRadioButtonOption | undefined>(
		getInitialSelectedOption()
	);
	const [dropDownValue, setDropDownValue] = React.useState<any>(props.initialDropdownValue);

	useEffect(() => {
		props.setSelectedOption({ option: selectedOption?.value, dropdownValue: dropDownValue });
	}, [selectedOption, dropDownValue]);

	return (
		<div className={classes.radioButtonsContainer} data-testid="RadioButtonComponent">
			{props.options.map((option) => {
				return (
					<RadioButtonItem
						key={option.value}
						isSelected={option.value === selectedOption?.value}
						option={option}
						onClickFunction={setSelectedOption}
						onDropDownChange={setDropDownValue}
						initialDropdownValue={props.initialDropdownValue}
						emptyDropdownStateComponent={props.emptyDropdownStateComponent}
					/>
				);
			})}
		</div>
	);
};
