class VersionService {
	static lastVersionSaved: string = 'lastVersionSaved';

	setVersion(version: string): void {
		localStorage.setItem(VersionService.lastVersionSaved, version);
	}

	removeVersion(): void {
		localStorage.removeItem(VersionService.lastVersionSaved);
	}

	getVersion(): string | null {
		return localStorage.getItem(VersionService.lastVersionSaved);
	}
}

export { VersionService };
