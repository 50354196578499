import TrusstorTooltip from '@shared/components/TrusstorTooltip';
import React from 'react';
import { ISvgElementContainer } from '../../../interfaces/ISvgElementContainer';
import classes from './styles.module.scss';

interface IFloorChipsContainerProps {
	chipsContainerSvgElement: ISvgElementContainer;
	getToolTipComponent?: () => React.ReactElement | null;
	onClick?: () => void;
	children: React.ReactNode;
	tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right';
}

export const FloorChipsContainer = (props: IFloorChipsContainerProps) => {
	return (
		<TrusstorTooltip
			showAlways={props.getToolTipComponent && !!props.getToolTipComponent()}
			key={`trades_chips_container_${props.chipsContainerSvgElement.floorId}`}
			cleanStyling
			tooltipComponent={props.getToolTipComponent ? props.getToolTipComponent() : null}
			placement={props.tooltipPlacement}
		>
			<div
				key={`floor_${props.chipsContainerSvgElement.floorId}_chipsContainer`}
				className={classes.floorChipsContainer}
				style={{
					top: props.chipsContainerSvgElement.top,
					right: props.chipsContainerSvgElement.right,
					left: props.chipsContainerSvgElement.left,
					width: props.chipsContainerSvgElement.width,
					height: props.chipsContainerSvgElement.height,
					cursor: props.onClick ? 'pointer' : 'default',
				}}
				onClick={props.onClick}
			>
				{props.children}
			</div>
		</TrusstorTooltip>
	);
};
