import React, { useEffect } from 'react';
import { uniq } from 'lodash';
import classes from './styles.module.scss';
import { ISelectedPointWithAreaId } from '../../interfaces/ISelectedPoint';
import { IconColor, IconSize, TrusstorIconShared } from '../TrusstorIconShared/TrusstorIconShared';
import { IconNames } from '../TrusstorIconShared/IconNames.enum';
import { areasLayoutId, selectedAreaSvgStyle } from './FloorPlanDisplay.constants';
import { paintSelectedAreas } from '../../utils/svg.utils';

interface IFloorPlanDisplayProps {
	pinCoordinates: ISelectedPointWithAreaId[];
	floorPlanSvg: string;
	onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
	additionalSelectedAreasIds?: string[];
	svgId?: string;
}

export const FloorPlanDisplay = (props: IFloorPlanDisplayProps) => {
	const floorPlanSvgId: string = `${areasLayoutId}-FloorPlanDisplay-Default`;
	const floorViewSvg: string | undefined = props.svgId
		? props.floorPlanSvg
		: props.floorPlanSvg.replace(/<svg/g, `<svg id="${floorPlanSvgId}"`);

	useEffect(() => {
		const selectedAreasIds: string[] = uniq(props.pinCoordinates.map((pin) => pin.areaId));
		const selectedAreasIdsWithAdditional: string[] = [
			...selectedAreasIds,
			...(props.additionalSelectedAreasIds || []),
		];
		paintSelectedAreas(selectedAreasIdsWithAdditional, selectedAreaSvgStyle, props.svgId || floorPlanSvgId);
	}, [props.pinCoordinates, floorViewSvg, props.svgId]);

	return (
		<div className={classes.floorPlanContainer} onClick={props.onClick}>
			<div className={classes.svg} dangerouslySetInnerHTML={{ __html: floorViewSvg }} />
			{props.pinCoordinates &&
				props.pinCoordinates.map((pin, index) => {
					return (
						<div
							key={index}
							className={classes.pinPoint}
							style={{
								left: `${pin.xPercentage}%`,
								top: `${pin.yPercentage}%`,
							}}
						>
							<TrusstorIconShared
								iconName={IconNames.pin}
								color={IconColor.Black}
								size={IconSize.LARGE}
							/>
						</div>
					);
				})}
		</div>
	);
};
