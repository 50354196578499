import React from 'react';
import classes from './styles.module.scss';
import classnames from 'classnames';
import { getProfessionBackgroundColor } from '@shared/utils/professions.utils';
import { translationService } from '@src/servicesInitializers';
import { IProfession } from '@shared/interfaces/IProfession';
import { selectProjectId } from '@store/slices/project.slice';
import { useSelector } from 'react-redux';
import { getTradeTranslation } from '@utils/translations.utils';

interface IProfessionDisplayCardProps {
	profession: IProfession;
}

export const ProfessionDisplayCard = (props: IProfessionDisplayCardProps) => {
	const projectId: string = useSelector(selectProjectId)!;
	return (
		<div className={classes.ProfessionDisplayCard_container} data-testid="professionDisplayCard">
			<div className={classes.headerWrapper}>
				<div
					className={classnames(classes.item, classes.trade)}
					style={{
						background: getProfessionBackgroundColor(props.profession, projectId),
					}}
					data-testid={'trade'}
				>
					{getTradeTranslation(props.profession)}
				</div>
				{props.profession.specialty && (
					<div className={classnames(classes.item, classes.specialty)} data-testid={'specality'}>
						{props.profession.specialty}
					</div>
				)}
				{props.profession.contractor && (
					<div className={classnames(classes.item)} data-testid={'contractor'}>
						{props.profession.contractor}
					</div>
				)}
			</div>
		</div>
	);
};
