import React, { useEffect } from 'react';
import { cM } from '@shared/utils/colors.utils';
import classes from './styles.module.scss';
import { translationService } from '../../../../../../index';
import { WorkersGroupedSection } from './WorkersGroupedSection/WorkersGroupedSection';
import { ITimesheetByTagType } from '../../../ITimesheetByTagType';
import { getTagIdsList, getWorkerTimesheetElementId } from '../../../timesheets.utils';

interface IWorkersListSectionProps {
	timesheetByTagType: ITimesheetByTagType;
}

export const WorkersListSection = (props: IWorkersListSectionProps) => {
	const [selectedWorkerId, setSelectedWorker] = React.useState<string | null>(null);
	let tagIdsList: string[];

	useEffect(() => {
		tagIdsList = getTagIdsList(props.timesheetByTagType);
		setSelectedWorker(tagIdsList[0]);
	}, []);

	const handleTagClick = (tagId: string) => {
		setSelectedWorker(tagId);
		handleScrollToTagTimesheet(tagId);
	};

	const handleScrollToTagTimesheet = (tagId: string) => {
		const element: HTMLElement | null = document.getElementById(getWorkerTimesheetElementId(tagId));
		if (element) {
			element.scrollIntoView({ behavior: 'smooth' });
		}
	};

	const areThereWorkersTimesheets: boolean =
		props.timesheetByTagType?.Worker && !!Object.keys(props.timesheetByTagType?.Worker).length;
	const areThereManagersTimesheets: boolean =
		props.timesheetByTagType?.Manager && !!Object.keys(props.timesheetByTagType?.Manager).length;
	return (
		<div className={cM(classes.workersListSection, classes)}>
			<div>
				{areThereWorkersTimesheets && (
					<>
						<div className={cM(classes.sectionTitle, classes)}>{translationService.get('Workers')}</div>
						{Object.entries(props.timesheetByTagType?.Worker).map(([tradeName, workerTimesheet]) => {
							return (
								<WorkersGroupedSection
									handleTagClick={handleTagClick}
									tradeName={tradeName}
									timesheetList={workerTimesheet}
									selectedWorkerId={selectedWorkerId}
								/>
							);
						})}
					</>
				)}
				{areThereManagersTimesheets && (
					<>
						<div className={cM(classes.sectionTitle, classes)}>{translationService.get('Managers')}</div>
						{Object.entries(props.timesheetByTagType?.Manager).map(([tradeName, workerTimesheet]) => {
							return (
								<WorkersGroupedSection
									handleTagClick={handleTagClick}
									tradeName={tradeName}
									timesheetList={workerTimesheet}
									selectedWorkerId={selectedWorkerId}
								/>
							);
						})}
					</>
				)}
			</div>
		</div>
	);
};
