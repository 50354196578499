import React, { useEffect, useState } from 'react';
import { FloorSvgRenderer } from '@src/Components/FloorSvgRenderer/FloorSvgRenderer';
import { FloorPlanDisplay } from '@shared/components/FloorPlanDisplay/FloorPlanDisplay';
import { ISelectedPointWithAreaId } from '@shared/interfaces/ISelectedPoint';
import { DashboardTabTypes } from '@interfaces/DashboardTabTypes.enum';
import { storageService } from '@src/servicesInitializers';
import { IFloorSvgPoints } from '@interfaces/IFloorSvgPoints';
import { useSelector } from 'react-redux';
import { IRootState } from '@store/slices';
import { getFloorSvgPoints } from '@shared/utils/svg.utils';
import { useWindowSize } from '@src/hooks/custom.hooks';

interface FloorPlanSvgDisplayProps {
	currentActiveMainPageLayout: DashboardTabTypes | null;
	svgId: string;
	floorId: string;
	visibleAreasWithVisibleTrades: any[];
	visibleWorkersOnSite: any[];
	issuesCurrentFloorSelectedPoints: ISelectedPointWithAreaId[];
	svgParentContainerClass?: string;
	isSpecialFloor: boolean;
}

const FloorPlanSvgDisplay = (props: FloorPlanSvgDisplayProps) => {
	const [screenWidth, screenHeight] = useWindowSize();
	const [floorPlanDisplayElement, setFloorPlanDisplayElement] = useState<HTMLDivElement | null>(null);
	const projectId: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.projectId);
	const [floorLoading, setFloorLoading] = useState<boolean>(false);
	const [floorSvg, setFloorSvg] = useState<string>('');
	const [svgPoints, setSvgPoints] = useState<IFloorSvgPoints | null>(null);

	useEffect(() => {
		if (floorSvg && !props.isSpecialFloor && props.currentActiveMainPageLayout === DashboardTabTypes.workForce) {
			setSvgPoints(getFloorSvgPoints(props.svgId));
		}
	}, [
		screenWidth,
		screenHeight,
		props.floorId,
		floorSvg,
		props.svgId,
		props.currentActiveMainPageLayout,
		props.isSpecialFloor,
	]);

	useEffect(() => {
		const loadAssets = async () => {
			setFloorLoading(true);
			const svgFile: string = await storageService.getFloorSvg(projectId, props.floorId);
			const editedSvgFile: string = svgFile.replace('<svg', `<svg id="${props.svgId}" `);
			setFloorSvg(editedSvgFile);
			setFloorLoading(false);
		};

		if (projectId && !props.isSpecialFloor) {
			loadAssets();
		}
	}, [projectId, props.floorId]);

	return (
		<>
			{(props.currentActiveMainPageLayout === DashboardTabTypes.workForce ||
				props.currentActiveMainPageLayout === DashboardTabTypes.safetyEvents) && (
				<div>
					<div className={props.svgParentContainerClass} data-testid={'floorSvgContainer'}>
						<FloorSvgRenderer
							svgId={props.svgId}
							floorPlanDisplayElement={floorPlanDisplayElement}
							floorSvg={floorSvg}
							svgPoints={svgPoints}
							floorId={props.floorId}
							visibleAreasWithVisibleTrades={props.visibleAreasWithVisibleTrades}
							visibleWorkersOnSite={props.visibleWorkersOnSite}
							floorLoading={floorLoading}
						/>
					</div>
				</div>
			)}
			{(props.currentActiveMainPageLayout === DashboardTabTypes.issues ||
				props.currentActiveMainPageLayout === DashboardTabTypes.activities) && (
				<div
					className={props.svgParentContainerClass}
					data-testid={'floorSvgContainer'}
					style={{ visibility: floorLoading ? 'hidden' : 'visible' }}
					ref={setFloorPlanDisplayElement}
				>
					<FloorPlanDisplay
						pinCoordinates={
							props.currentActiveMainPageLayout === DashboardTabTypes.issues
								? props.issuesCurrentFloorSelectedPoints
								: []
						}
						floorPlanSvg={floorSvg}
						svgId={props.svgId}
					/>
				</div>
			)}
		</>
	);
};

export default FloorPlanSvgDisplay;
