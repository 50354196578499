import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import React from 'react';
import classes from '@src/Components/SequenceProgressAnalysis/OverallProgress/MainProgressStatsSection/TopStats/styles.module.scss';
import { TrusstorIcon } from '@src/Components/TrusstorIcon/TrusstorIcon';

interface IStatCardProps {
	iconName: IconNames;
	title: string;
	percentage: number;
	children?: React.ReactNode;
}

export const StatCard = ({ iconName, title, percentage, children }: IStatCardProps) => {
	return (
		<div className={classes.StatCard_container} data-testid="StatCard">
			<div className={classes.title}>{title}</div>
			<div className={classes.dataSection}>
				<div className={classes.icon}>
					<TrusstorIcon iconName={iconName} />
				</div>
				<div className={classes.percentage}>{percentage + '%'}</div>
				{children}
			</div>
		</div>
	);
};
