import React from 'react-dom';
import classes from './styles.module.scss';
import classnames from 'classnames';
import greyFloorsIcon from '@assets/workplanIcons/greyFloorsIcon.svg';
import { translationService } from '@src/servicesInitializers';
import { activitySequenceMatrixConstants } from '@src/Components/WorkPlan/ActivityProgressTracker/components/ActivitySequenceMatrix/constants';
import { AddSequenceItem } from '@src/Components/Matrix/cellComponents/MatrixHeader/AddSequenceItem/AddSequenceItem';

export const MatrixEmptyState = () => {
	const styles: any = {
		cellHeight: '32px',
		cellWidth: `${activitySequenceMatrixConstants.minCellWidth}px`,
		colHeaderHeight: '130px',
		rowHeaderWidth: '150px',
	};
	return (
		<div className={classes.container}>
			<div className={classes.header}>
				<div className={classnames(classes.colHeader, classes.floorsTitle)} style={styles}>
					<img src={greyFloorsIcon} alt={'floorsIcon'} />
					<div className={classes.floorsText}>{translationService.get('floors')}</div>
				</div>
				<AddSequenceItem styles={{ borderTop: 'none' }} />
			</div>
		</div>
	);
};
