import React, { useId } from 'react';
import classes from './styles.module.scss';
import { IAssignedActivity } from '@src/interfaces';
import { translationService } from '@src/index';
import TrusstorTooltip from '@shared/components/TrusstorTooltip';
import { getProfessionBackgroundColor } from '@shared/utils/professions.utils';
import { selectProjectId } from '@store/slices/project.slice';
import { useSelector } from 'react-redux';
import { getTradeTranslation } from '@utils/translations.utils';

interface IActivityProfessionRowProps {
	activity: IAssignedActivity;
}

const ActivityProfessionRow = (props: IActivityProfessionRowProps) => {
	const descriptionId: string = `${useId()}-description-activity-row`;
	const projectId: string = useSelector(selectProjectId)!;

	return (
		<section className={classes.rowContainer}>
			<div className={classes.professionDescriptionContainer}>
				<div
					className={classes.profession}
					style={{ backgroundColor: getProfessionBackgroundColor(props.activity.profession, projectId) }}
				>
					<div className={classes.profession}>{getTradeTranslation(props.activity.profession)}</div>
				</div>

				<TrusstorTooltip
					tooltipComponent={<p>{props.activity.description}</p>}
					idToOverflow={descriptionId}
					cleanStyling
				>
					<div id={descriptionId} className={classes.description}>
						{props.activity.description}
					</div>
				</TrusstorTooltip>
			</div>
			<div className={classes.contractorSpecialtyContainer}>
				{props.activity.profession?.contractor && (
					<div className={classes.contractor}>{props.activity.profession.contractor}</div>
				)}
				{props.activity.profession?.contractor && props.activity.profession?.specialty && <span> - </span>}
				<div className={classes.specialty}>{props.activity.profession?.specialty}</div>
			</div>
		</section>
	);
};

export { ActivityProfessionRow };
