import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import {
	pencilIcon,
	brokenLinkIcon,
	linkIcon,
	pencilGreyIcon,
	brokenLinkGreyIcon,
	linkedGreyIcon,
} from '../../assets/adminPanel';
import { TooltipedHoverIcon } from '../TooltipedHoverIcon';
import { translationService } from '../../index';
import { GeneralAlertDialog } from '../GeneralAlertDialog/GeneralAlertDialog';
import { unassignTag } from '../../store/thunks';
import { ITag } from '../../interfaces';
import { unassignedTradeId, hoistTradeId, guestTradeId } from '@shared/constants/professions.constants';
import { textStyle } from '@shared/utils/text.utils';
import { TagEntityChip } from '../Chips/TagEntityChip';
import { unassignTag_BI } from '../../utils/bi.utils';

const useStyles = makeStyles((theme) => ({
	avatar: {
		width: 24,
		height: 24,
		marginRight: theme.spacing(1),
		marginLeft: theme.spacing(3),
		cursor: 'pointer',
		[theme.breakpoints.down('lg')]: {
			marginLeft: theme.spacing(1),
		},
	},
	dialogContent: {
		width: '96%',
		marginTop: 10,
		marginBottom: 10,
	},
	dialogText: textStyle({
		fontSize: 12,
		fontWeight: 300,
	}),
	description: textStyle({
		marginTop: theme.spacing(2),
		fontWeight: 400,
		fontSize: 14,
	}),
}));

interface ITagsTableRowButtonsProps {
	tag: ITag;
	setButtonsShown: any;
	setShowDialog: (show: boolean) => void;
}

const TagsTableRowButtons = (props: ITagsTableRowButtonsProps) => {
	const [showUnlinkDialog, setShowUnlinkDialog] = useState(false);
	const classes = useStyles();
	const dispatch = useDispatch();

	const handleUnlinkButtonClick = () => {
		setShowUnlinkDialog(true);
	};
	const handleCloseUnlinkTagDialog = () => {
		setShowUnlinkDialog(false);
	};
	const handleSubmitUnlinkTagDialog = () => {
		dispatch(unassignTag(props.tag));
		handleCloseUnlinkTagDialog();
		unassignTag_BI(props.tag);
	};

	const handleEditTagButtonClick = () => {
		props.setShowDialog(true);
	};

	const isNotHoistOrGuest = (): boolean => {
		return !(props.tag.linkedUtility?.tradeId === hoistTradeId || props.tag.profession?.tradeId === guestTradeId);
	};

	return (
		<React.Fragment>
			<GeneralAlertDialog
				show={showUnlinkDialog}
				close={handleCloseUnlinkTagDialog}
				closeButtonText={translationService.get('unlinkTagDialogCloseText')}
				title={translationService.get('unlinkTagDialogTitle')}
				children={
					<div className={`${classes.dialogContent} ${classes.dialogText}`}>
						<TagEntityChip tag={props.tag} />
						<p>{`${translationService.get('unlinkTagDialogContentThirdPart')}?`}</p>
					</div>
				}
				message={`${translationService.get('unlinkTagDialogContentFirstPart')} ${
					props.tag.tagNick
				} ${translationService.get('unlinkTagDialogContentSecondPart')}`}
				onSubmitButtonClick={handleSubmitUnlinkTagDialog}
			/>
			{props.tag.profession && props.tag.profession.tradeId === unassignedTradeId ? (
				<TooltipedHoverIcon
					onClick={handleEditTagButtonClick}
					baseIcon={linkedGreyIcon}
					hoverIcon={linkIcon}
					tooltipText={translationService.get('assignTagAction')}
					styleClass={classes.avatar}
					testId={'assignTagRowButton'}
				/>
			) : (
				<React.Fragment>
					<TooltipedHoverIcon
						onClick={() => handleUnlinkButtonClick()}
						baseIcon={brokenLinkGreyIcon}
						hoverIcon={brokenLinkIcon}
						tooltipText={translationService.get('unassignTag')}
						styleClass={classes.avatar}
						testId={'unassignTagRowButton'}
					/>
					{isNotHoistOrGuest() && (
						<TooltipedHoverIcon
							onClick={handleEditTagButtonClick}
							baseIcon={pencilGreyIcon}
							hoverIcon={pencilIcon}
							tooltipText={translationService.get('editTag')}
							styleClass={classes.avatar}
							testId={'editTagRowButton'}
						/>
					)}
				</React.Fragment>
			)}
		</React.Fragment>
	);
};

export { TagsTableRowButtons };
