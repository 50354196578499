import React from 'react';
import classes from './styles.module.scss';
import { TrusstorIcon } from '@src/Components/TrusstorIcon/TrusstorIcon';
import { getEquipmentIconNameFromType } from '@shared/utils/equipment.utils';
import { EquipmentTypes } from '@shared/constants/equipment.const';
import { UtilityTypes } from '@shared/constants/utilityTypes.enum';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { IconColor, IconSize } from '@shared/components/TrusstorIconShared/TrusstorIconShared';
import classNames from 'classnames';

interface IEquipmentChipProps {
	type: EquipmentTypes | UtilityTypes;
	number: number;
	description?: string;
	color?: { backgroundColor: string; textColor: string };
	isSmall?: boolean;
}
export const EquipmentChip = (props: IEquipmentChipProps) => {
	const iconName: IconNames = getEquipmentIconNameFromType(props.type);
	return (
		<div className={classNames(classes.chipContainer, { [classes.small]: props.isSmall })}>
			<div
				style={{ backgroundColor: props.color?.backgroundColor, color: props.color?.textColor }}
				className={classes.number}
			>
				{props.number}
			</div>
			<TrusstorIcon
				color={IconColor.DarkBlue}
				iconName={iconName}
				size={props.isSmall ? IconSize.EXTRA_SMALL : IconSize.SMALL}
			/>
			{props.description && <div className={classes.description}>{props.description}</div>}
		</div>
	);
};
