import { WorkPlanLocalStorageService } from '@src/services/workplanLocalStorage.service';
import { UserService } from '@shared/services/user.service';
import { UserAccessLevelService } from '@shared/services/userAccessLevelService';
import {
	UserFeaturesNamesType,
	userFeaturesProhibitedList,
} from '@src/featuresProhibitedList/userFeaturesProhibitedList';
import { ProjectService } from '@shared/services/project.service';
import { VersionService } from '@shared/services/version.service';
import { RequestService } from '@shared/services/request.service';
import { StorageService } from '@shared/services/storage.service';
import { config } from '@src/config';
import { store } from '@store/store';
import { AuthService, BIService, ExportService, StreamService } from '@src/services';
import { BIEventNames } from '@interfaces/BI/BIEventNames.enum';
import { IProject } from '@shared/interfaces/IProject';
import { TranslationService } from '@shared/services/translation.service';
import { ITranslations } from '@shared/interfaces/ITranslations';
import {
	ProjectPackagesFeaturesNamesType,
	projectPackagesFeaturesProhibitedList,
} from '@src/featuresProhibitedList/projectPackagesFeaturesProhibitedList';
import { ProjectAccessLevelService } from '@shared/services/projectAccessLevel.service';
import { MilestonesLocalStorage } from '@src/services/milestonesLocalStorage.service';

const workplanLocalStorageService: WorkPlanLocalStorageService = new WorkPlanLocalStorageService();
const milestonesLocalStorage: MilestonesLocalStorage = new MilestonesLocalStorage();
const userService: UserService = new UserService();
const projectService: ProjectService = new ProjectService();
const accessLevelService: UserAccessLevelService<UserFeaturesNamesType> =
	new UserAccessLevelService<UserFeaturesNamesType>(userFeaturesProhibitedList, userService);
const projectAccessLevelService: ProjectAccessLevelService<ProjectPackagesFeaturesNamesType> =
	new ProjectAccessLevelService<ProjectPackagesFeaturesNamesType>(
		projectPackagesFeaturesProhibitedList,
		projectService
	);

const versionService: VersionService = new VersionService();
const requestService: RequestService = new RequestService(
	config.apiGateway,
	userService,
	projectService,
	versionService,
	store
);
const authService: AuthService = new AuthService(requestService, userService);
const storageService: StorageService = new StorageService(requestService);
const biService: BIService<BIEventNames> = new BIService<BIEventNames>(config, versionService);
const streamService: StreamService = new StreamService(config, userService, store);
const chosenProject: IProject | undefined = projectService.getChosenProject();
const localTranslationsJson: ITranslations = require('@shared/translations/translations.json');
const translationService: TranslationService = new TranslationService(
	chosenProject?.language || config.defaultLanguage,
	async () => {
		return await storageService.getTranslationsCsv();
	},
	localTranslationsJson
);

const exportService: ExportService = new ExportService(translationService);

export {
	milestonesLocalStorage,
	accessLevelService,
	projectAccessLevelService,
	userService,
	requestService,
	authService,
	versionService,
	storageService,
	biService,
	translationService,
	projectService,
	streamService,
	exportService,
	workplanLocalStorageService,
};
