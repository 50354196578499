import React from 'react';
import classes from './styles.module.scss';
import { translationService } from '@src/servicesInitializers';
import { ContractorAnalysisDatePickerSection } from '@src/Components/Pages/Reports/ProfessionAnalysisReportPageV2/Components/ContractorAnalysisDatePickerSection/ContractorAnalysisDatePickerSection';
import { useProfessionAnalysisReportQuery } from '@src/hooks/queries/analysisCenter.queries.hooks';
import { useSelector } from 'react-redux';
import { IRootState } from '@store/slices';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { GraphStat } from '@src/Components/Pages/Reports/ProfessionAnalysisReportPageV2/Components/GraphStat/GraphStat';
import { Loader } from '@shared/components/Loader/Loader';
import { TagsAnalysisVsHoursGraph } from '@src/Components/TagsAnalysisVsHoursGraph/TagsAnalysisVsHoursGraph';
import { IRoundedProfessionsAnalysisReportData } from '@interfaces/IRoundedProfessionsAnalysisReport';

interface ITagsAnalysisVsHoursProps {
	currentProfessionId: string;
	updatePeriod: (period: [Date, Date]) => void;
}

export const TagsAnalysisVsHours = (props: ITagsAnalysisVsHoursProps) => {
	const [startDate, endDate]: [number, number] = useSelector(
		(state: IRootState) => state.datePickerReducer.visiblePeriodTimestamps
	);
	const projectId: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.projectId);
	const tz: string = useSelector((state: IRootState) => state.projectReducer.workingProject!.tz);

	const { professionAnalysisReport, isLoading } = useProfessionAnalysisReportQuery(
		projectId,
		startDate,
		endDate,
		tz,
		props.currentProfessionId
	);

	const counts: { totalWorkHours: number; totalWorkersCount: number; daysCount: number } | undefined =
		professionAnalysisReport &&
		Object.values(professionAnalysisReport).reduce(
			(acc, report) => {
				Object.entries(report.dates).forEach(([date, dailyReport]) => {
					const workersCount: number = dailyReport.totalWorkHours ? dailyReport.totalWorkers : 0;
					acc.totalWorkHours += dailyReport.totalWorkHours;
					acc.totalWorkersCount += workersCount;
					if (dailyReport.totalWorkHours > 0) {
						acc.daysCount++;
					}
				});
				return acc;
			},
			{
				daysCount: 0,
				totalWorkHours: 0,
				totalWorkersCount: 0,
			}
		);

	const avgWorkersPerDayInPeriod: number =
		counts?.totalWorkersCount === 0 ? 0 : (counts?.totalWorkersCount || 0) / (counts?.daysCount || 0);
	const avgWorkerHoursInPeriod: number =
		counts?.totalWorkHours === 0 ? 0 : (counts?.totalWorkHours || 0) / (counts?.daysCount || 0);

	const currentProfessionReport: IRoundedProfessionsAnalysisReportData | undefined =
		professionAnalysisReport && Object.values(professionAnalysisReport)[0];

	return (
		<div className={classes.TagsAnalysisVsHours_container} data-testid="TagsAnalysisVsHours">
			<div className={classes.datePickerWrapper}>
				<ContractorAnalysisDatePickerSection updatePeriod={props.updatePeriod} />
			</div>
			<div className={classes.graphTitle}>
				{translationService.get('workersVsHoursGraph_workersPerDay')}{' '}
				<span className={classes.vs}>{translationService.get('vs')}</span>{' '}
				{translationService.get('workersVsHoursGraph_hoursPerDay')}
			</div>

			{isLoading ? (
				<Loader />
			) : (
				<div className={classes.content}>
					<div className={classes.stats}>
						<GraphStat
							title={translationService.get('workersVsHoursGraph_avgWorkersPerDay')}
							value={Number(avgWorkersPerDayInPeriod.toFixed(2))}
							iconName={IconNames.worker}
						/>
						<GraphStat
							title={translationService.get('workersVsHoursGraph_avgHoursPerDay')}
							value={Number(avgWorkerHoursInPeriod.toFixed(2))}
							iconName={IconNames.clock}
						/>
					</div>
					<div className={classes.graphContainer}>
						<TagsAnalysisVsHoursGraph currentProfessionReport={currentProfessionReport} />
					</div>
				</div>
			)}
		</div>
	);
};
