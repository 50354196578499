import React from 'react';
import { translationService } from '@src/servicesInitializers';
import { WeeklyWidgetActivityCard } from '@src/Components/WorkplanWidgetComponents/WeeklyWidget/WeeklyWidgetActivityCard/WeeklyWidgetActivityCard';
import { IMainPageWidgetActivityData } from '@interfaces/IMainPageWidgetActivityData';
import { ExpandedSection } from '@shared/components/ExpandedSection/ExpandedSection';

interface IWidgetOpenActivitiesListProps {
	overdueActivities: IMainPageWidgetActivityData[];
	dueTodayActivities: IMainPageWidgetActivityData[];
	dueInSevenDaysActivities: IMainPageWidgetActivityData[];
	futureActivities: IMainPageWidgetActivityData[];
}

export const WidgetOpenActivitiesList = (props: IWidgetOpenActivitiesListProps) => {
	return (
		<>
			{!!props.overdueActivities?.length && (
				<ExpandedSection
					text={
						translationService.get('activities_widget_expanded_overdue') +
						` (${props.overdueActivities.length})`
					}
				>
					{props.overdueActivities.map((activity) => (
						<WeeklyWidgetActivityCard key={activity.groupId} activity={activity} />
					))}
				</ExpandedSection>
			)}
			{!!props.dueTodayActivities?.length && (
				<ExpandedSection
					text={
						translationService.get('activities_widget_expanded_dueToday') +
						` (${props.dueTodayActivities.length})`
					}
				>
					{props.dueTodayActivities.map((activity) => (
						<WeeklyWidgetActivityCard key={activity.groupId} activity={activity} />
					))}
				</ExpandedSection>
			)}
			{!!props.dueInSevenDaysActivities?.length && (
				<ExpandedSection
					text={
						translationService.get('activities_widget_expanded_dueIn7days') +
						` (${props.dueInSevenDaysActivities.length})`
					}
				>
					{props.dueInSevenDaysActivities.map((activity) => (
						<WeeklyWidgetActivityCard key={activity.groupId} activity={activity} />
					))}
				</ExpandedSection>
			)}
			{!!props.futureActivities?.length && (
				<ExpandedSection
					text={
						translationService.get('activities_widget_expanded_after7days') +
						` (${props.futureActivities.length})`
					}
				>
					{props.futureActivities.map((activity) => (
						<WeeklyWidgetActivityCard key={activity.groupId} activity={activity} />
					))}
				</ExpandedSection>
			)}
		</>
	);
};
