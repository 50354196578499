import React from 'react';
import { getWorkersTimesheetRoute } from '@utils/analysisCenter.utils';
import { RecentReportCard } from '../RecentReportCard';
import { IWorkersTimesheetReport } from '@shared/interfaces/IWorkersTimesheetReport';
import { useSelector } from 'react-redux';
import { selectTimezone } from '../../../../../store/slices/project.slice';
import { useHistory } from 'react-router-dom';
import { getDateRangeFormatted } from '../../../../../utils/dateUtils';
import { analysisCenterReportGeneration_BI } from '@utils/bi.utils';

interface IRecentWorkersTimesheetReportCardProps {
	workerTimesheetReport: IWorkersTimesheetReport;
}

export const RecentWorkersTimesheetReportCard = (props: IRecentWorkersTimesheetReportCardProps) => {
	const tz: string = useSelector(selectTimezone)!;
	const history = useHistory();

	const redirectToTimesheetReport = () => {
		analysisCenterReportGeneration_BI(props.workerTimesheetReport.investigationType, 'recent', [
			new Date(props.workerTimesheetReport.fromDate),
			new Date(props.workerTimesheetReport.toDate),
		]);

		history.push(getWorkersTimesheetRoute(props.workerTimesheetReport._id!));
	};

	return (
		<RecentReportCard
			reportId={props.workerTimesheetReport._id!}
			dateGenerated={props.workerTimesheetReport.dateGenerated}
			investigationReport={props.workerTimesheetReport}
			redirectToReport={redirectToTimesheetReport}
		>
			<>
				{getDateRangeFormatted(
					new Date(props.workerTimesheetReport.fromDate),
					new Date(props.workerTimesheetReport.toDate),
					tz
				)}
			</>
		</RecentReportCard>
	);
};
