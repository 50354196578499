import React, { useContext, useEffect, useId, useRef } from 'react';
import { ISequence, ISequenceWithoutId } from '@shared/interfaces/ISequence';
import { SequenceTypeDropdown } from '@shared/components/SequenceTypeDropDown/SequenceTypeDropdown';
import { SequenceTypeEnum } from '@shared/interfaces/SequenceType.enum';
import { requestService, translationService } from '@src/servicesInitializers';
import { selectProjectId } from '@store/slices/project.slice';
import { useDispatch, useSelector } from 'react-redux';
import TrusstorTooltip from '@shared/components/TrusstorTooltip';
import { useOutsideClickWithCallback } from '@src/hooks/custom.hooks';
import { WorkplanContext } from '@src/Components/Pages/Workplan/WorkPlan';
import { errorSnackbar } from '@utils/snackbar.utils';
import { TrusstorTextInput } from '@shared/components/Inputs/TrusstorTextInput/TrusstorTextInput';
import classes from './styles.module.scss';

interface ICreateNewSequenceProps {
	sequences: ISequence[];
	setShowCreateSequence: () => void;
}

export const CreateNewSequence = (props: ICreateNewSequenceProps) => {
	const { setSequences, setSelectedSequenceId } = useContext(WorkplanContext)!;
	const { sequences } = useContext(WorkplanContext)!;
	const dispatch = useDispatch();
	const projectId: string = useSelector(selectProjectId)!;
	const initialSequence: Omit<ISequenceWithoutId, 'sequenceType'> = {
		name: '',
		projectId,
		orderIndex: props.sequences.length,
	};
	const [sequence, setSequence] = React.useState<ISequenceWithoutId | undefined>(undefined);
	const submitNewSequence = async () => {
		props.setShowCreateSequence();
		if (!sequence || !sequence.name) {
			setSequence(undefined);
			return;
		}
		try {
			const newSequenceFromDb = await requestService.post('/activities/sequences', {
				body: sequence,
			});
			setSequences((prev) => [...prev, newSequenceFromDb]);
			setSelectedSequenceId(newSequenceFromDb._id);
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		if (sequence || !sequences?.length) {
			return;
		}

		const defaultSequenceType: SequenceTypeEnum = sequences[0].sequenceType;
		setSequence({ ...initialSequence, sequenceType: defaultSequenceType } as ISequenceWithoutId);
	}, [sequences.length]);

	const handleNewSequenceChange = (sequenceUpdate: Partial<ISequenceWithoutId>) => {
		setSequence((prevState) => ({ ...initialSequence, ...prevState, ...sequenceUpdate }) as ISequenceWithoutId);
	};

	const changeSequenceType = async (sequenceType: SequenceTypeEnum) => {
		handleNewSequenceChange({ sequenceType });
	};

	const submitNameChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		e.stopPropagation();
		const name: string = e.target.value;
		const nameExists: boolean = sequences.some((sequence) => sequence.name === name);
		if (nameExists) {
			errorSnackbar(dispatch, translationService.get('nameAlreadyExists'));
		}
		handleNewSequenceChange({ name: e.target.value });
	};

	const id: string = useId();

	const ref = useRef(null);
	useOutsideClickWithCallback(ref, submitNewSequence);

	return (
		<div ref={ref} data-testid="SequenceSelectionSection">
			<TrusstorTooltip text={sequence?.name || ''} idToOverflow={id}>
				<TrusstorTextInput
					innerComponent={
						<SequenceTypeDropdown
							readOnly={false}
							onSelect={changeSequenceType}
							currentSequenceType={sequence?.sequenceType}
							projectId={projectId}
						/>
					}
					autoFocus
					id={id}
					sendFullEvent
					changeFunc={submitNameChange}
					type={'text'}
					value={sequence?.name || ''}
					placeholder={translationService.get('enterChecklistName')}
					textClassName={classes.inputRootClassName}
				/>
			</TrusstorTooltip>
		</div>
	);
};
