import React from 'react';
import classes from './styles.module.scss';
import { BaseButton } from '../BaseButton/BaseButton';

export interface ISecondaryButtonProps {
	key?: string;
	text: string;
	handleClick?: (arg0: React.MouseEvent<HTMLButtonElement, MouseEvent>) => any;
	className?: any;
	disabled?: boolean;
	showLoader?: boolean;
	icon?: any;
	style?: React.CSSProperties;
	iconClassName?: string;
	testId?: string;
	selected?: boolean;
	disableHover?: boolean;
}

export const SecondaryButton = (props: ISecondaryButtonProps) => {
	return (
		<BaseButton
			key={props.key}
			text={props.text}
			handleClick={props.handleClick}
			stylesOptions={{
				mainClass: `${classes.secondaryButton} ${props.className}`,
				disabledClass: classes.disabled,
				selectedClass: classes.selected,
				additionalCssProps: props.style,
				iconClass: props.iconClassName,
				hoverClass: classes.hovered,
			}}
			testId={props.testId}
			disabled={props.disabled}
			selected={props.selected}
			showLoader={props.showLoader}
			icon={props.icon}
			disableHover={props.disableHover}
		/>
	);
};
