import React from 'react';
import { makeStyles, Chip, Grid } from '@material-ui/core';
import { textStyle } from '@shared/utils/text.utils';
import { EquipmentIcon } from '../Equipment/EquipmentIcon';
import { COLORS } from '../../constants';

const useStyles = makeStyles((theme) => ({
	root: {
		height: 20,
		margin: theme.spacing(0.25),
		border: 'solid 1px #313131',
		borderRadius: 13,
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		backgroundColor: COLORS.white,
	},
	label: textStyle({
		color: COLORS.black,
		fontClass: 'body2',
		paddingRight: 2,
		paddingLeft: 2,
	}),
	text: textStyle({
		marginLeft: 4,
		marginRight: 6,
		fontClass: 'body2',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		color: COLORS.black,
	}),
	labelContainer: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
	},
}));

const EquipmentChipTooltipContent = ({ name, type, floorId, className = `floor_${floorId}_equipment` }) => {
	const classes = useStyles();

	return (
		<Chip
			label={
				<Grid container alignItems="center" className={classes.labelContainer}>
					<EquipmentIcon type={type} isChipIcon />
					<p className={`${classes.text} chipLabel`}>{name}</p>
				</Grid>
			}
			classes={{
				label: classes.label,
				root: classes.root,
			}}
			className={className}
			data-testid="equipmentChip"
		/>
	);
};

export { EquipmentChipTooltipContent };
