import { ActivitySequenceMatrixMode } from '@shared/interfaces/ActivitySequenceMatrixMode.enum';
import { IMergedAreaSequenceItem } from '@interfaces/IMergedAreaSequenceItem';
import { threeDotsVertical } from '@shared/assets/icons';
import { TrusstorMenu } from '@shared/components/TrusstorMenu/TrusstorMenu';
import { useOutsideClick } from '@shared/hooks/custom.hooks';
import { AreaSequenceItemStatus } from '@shared/interfaces/AreaSequenceItemStatus.enum';
import { updateAreaSequenceItemReadyForReviewStatus } from '@src/apis/areaSequenceItems.api';
import { IMatrixCellComponentProps } from '@src/Components/Matrix/interfaces/IMatrixCellComponentProps';
import { getCellIconSrc } from '@src/Components/WorkPlan/ActivityProgressTracker/components/ActivitySequenceMatrix/cellComponents/ActivitySequenceItemCell/utils';
import { WorkPlanDialog } from '@src/Components/WorkPlan/WorkPlanDialog/WorkPlanDialog';
import { accessLevelService, requestService, translationService } from '@src/index';
import { IWorkPlanActivity } from '@src/interfaces';
import { errorSnackbar, successSnackbar } from '@utils/snackbar.utils';
import classnames from 'classnames';
import React, { memo, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { whiteCheckIcon } from '../../../../../../../assets';
import { ActivitySequenceMatrixContext } from '../../ActivitySequenceMatrix';
import { ActivitySequenceItemMenuActions, getCellMenuItemsByStatus } from './activitySequence.menuItems';
import classes from './styles.module.scss';
import { getAreaSequenceItemCellColorClassName } from '@shared/utils/sequence.utils';
import { WorkplanContext } from '@src/Components/Pages/Workplan/WorkPlan';
import { isTouchDevice } from '@utils/generalUtils';
import { workplanMatrixInteraction_BI } from '@utils/bi.utils';
import { IssueCreateDialog } from '@src/Components/IssueCreateDialog/IssueCreateDialog';
import { IIssue } from '@shared/interfaces/IIssue';
import { MultiIssuesViewDialog } from '@src/Components/MultiIssuesViewDialog/MultiIssuesViewDialog';
import { TrusstorIcon } from '@src/Components/TrusstorIcon/TrusstorIcon';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { IconColor } from '@shared/components/TrusstorIconShared/TrusstorIconShared';
import { isAsiComplete } from '@shared/utils/asi.utils';
import { AsiReviewDialog } from '@src/Components/ASIReviewDialog/ASIReviewDialog';
import { ProgressTrackerTabContext } from '@src/Components/Pages/Workplan/ProgressTrackerTab/ProgressTrackerTab';
import { ActionConfirmationDialog } from '@src/Components/WorkplanWidgetComponents/ActionConfirmationDialog/ActionConfirmationDialog';
import { ActivityGroupStatus } from '@shared/interfaces/ActivityGroupStatus.enum';
import { ActivitySequenceItemCellMainContent } from '@src/Components/WorkPlan/ActivityProgressTracker/components/ActivitySequenceMatrix/cellComponents/ActivitySequenceItemCell/ActivitySequenceItemCellMainContent';

export interface IActivitySequenceItemCellData extends IMergedAreaSequenceItem {
	isCheckedForActivityCreation?: boolean;
	isDisabled?: boolean;
}

interface IActivitySequenceItemCellProps extends IMatrixCellComponentProps {
	data: IActivitySequenceItemCellData;
	onClick: (data: any, event?: any, asiUpdated?: IMergedAreaSequenceItem) => void;
	onHover: (data: any) => void;
}

const getActivitySequenceITemCell = (mode: ActivitySequenceMatrixMode): React.FC<IActivitySequenceItemCellProps> => {
	const ActivitySequenceItemCell: React.FC<IActivitySequenceItemCellProps> = (
		props: IActivitySequenceItemCellProps
	) => {
		const [cellIconSrc, setCellIconSrc] = useState<string | undefined>(undefined);
		const [isHovered, setIsHovered] = useState<boolean>(false);
		const [showThreeDots, setShowThreeDots] = useState<boolean>(false);
		const [showMenu, setShowMenu] = useState<boolean>(false);
		const [menuElement, setMenuElement] = useState<HTMLElement | null>(null);
		const [chipElement, setChipElement] = useState<HTMLElement | null>(null);
		const [isActivityDialogOpen, setIsActivityDialogOpen] = useState<boolean>(false);
		const [isCreateIssueDialogOpen, setIsCreateIssueDialogOpen] = useState<boolean>(false);
		const [isViewIssuesDialogOpen, setIsViewIssuesDialogOpen] = useState<boolean>(false);
		const [isWarningModalOpen, setIsWarningModalOpen] = useState<boolean>(false);
		const [currentActivity, setCurrentActivity] = useState<IWorkPlanActivity>();
		const { multiSelectedCells, handleMultiSelectedCellClick } = useContext(ActivitySequenceMatrixContext)!;
		const { scheduleAreaSequenceItemsForStatusUpdate } = useContext(ProgressTrackerTabContext)!;
		const isMultiSelectedCell: boolean = multiSelectedCells.some((cell) => cell._id === props.data._id);
		const isMultiSelectMode: boolean = multiSelectedCells.length > 0;
		const [cellStatus, setCellStatus] = useState<AreaSequenceItemStatus>(props.data.status);
		const [showReviewDialog, setShowReviewDialog] = useState<boolean>(false);
		const {
			matrix: { recreateTable },
			updateAreaSequenceItem,
			sequences,
		} = useContext(WorkplanContext)!;
		const dispatch = useDispatch();
		const userHasAccessToActivityActions: boolean = accessLevelService.hasAccess('workplanActivityActions');
		const canUserSelectCell: boolean =
			userHasAccessToActivityActions || cellStatus !== AreaSequenceItemStatus.complete;
		const updateToStatusRef = React.useRef<AreaSequenceItemStatus>();

		useOutsideClick({ current: menuElement }, setShowMenu, false, [{ current: chipElement }]);

		useEffect(() => {
			setCellStatus(props.data.status);
		}, [props.data.status]);

		useEffect(() => {
			setCellIconSrc(
				getCellIconSrc(cellStatus, !isMultiSelectMode && isHovered, !!props.data.isCheckedForActivityCreation)
			);
		}, [cellStatus, isHovered, props.data.isCheckedForActivityCreation, isMultiSelectMode]);

		const updateAreaSequenceItemAndRecreateTable = (updatedMergedAreaSequenceItem: IMergedAreaSequenceItem) => {
			updateAreaSequenceItem(updatedMergedAreaSequenceItem);
			recreateTable(updatedMergedAreaSequenceItem.area.floorId, [updatedMergedAreaSequenceItem]);
		};
		const handleMultiSelectClick = (event) => {
			event.stopPropagation();
			const isShiftClicked: boolean = event.metaKey || event.shiftKey;
			handleMultiSelectedCellClick(props.data, isShiftClicked);
		};

		useEffect(() => {
			const shouldShowThreeDots: boolean =
				(isHovered || isTouchDevice()) &&
				mode === ActivitySequenceMatrixMode.display &&
				!isMultiSelectedCell &&
				!isAsiComplete(cellStatus) &&
				!isMultiSelectMode;

			setShowThreeDots(shouldShowThreeDots);
			setShowMenu(false);
		}, [isMultiSelectMode, isHovered, isMultiSelectedCell, cellStatus, mode]);

		useEffect(() => {
			if (!showMenu) {
				setIsHovered(false);
			}
		}, [showMenu]);

		const handleThreeDotsClick = (event) => {
			event.stopPropagation();
			setShowMenu(true);
		};

		const openEditWorkPlanDialog = async (groupId: string) => {
			const currentActivity: IWorkPlanActivity = await requestService.get(`/activities/groups/${groupId}`);
			if (currentActivity) {
				setCurrentActivity(currentActivity);
				setIsActivityDialogOpen(true);
			}
		};

		const isClickable: boolean =
			mode !== ActivitySequenceMatrixMode.schedule || cellStatus === AreaSequenceItemStatus.unplanned;

		const markCellOnClick = async (event?: any) => {
			props.onClick(props.data, event);
		};

		const updateCellStatus = (status: AreaSequenceItemStatus): void => {
			const currentStatus: AreaSequenceItemStatus = cellStatus;
			setCellStatus(status);
			if (status === AreaSequenceItemStatus.complete) {
				props.onClick(props.data);
				return;
			}
			scheduleAreaSequenceItemsForStatusUpdate(status, [props.data]);
			if (currentStatus === AreaSequenceItemStatus.complete) {
				updateAreaSequenceItemAndRecreateTable({
					...props.data,
					status,
				});
			}
		};

		const openViewIssuesDialog = () => {
			setIsViewIssuesDialogOpen(true);
		};

		const onMenuItemClick = async (
			actionType: ActivitySequenceItemMenuActions,
			updatedStatus?: AreaSequenceItemStatus
		) => {
			const previousStatus: AreaSequenceItemStatus = cellStatus;
			try {
				isTouchDevice() && setShowMenu(false);
				if (actionType === ActivitySequenceItemMenuActions.status) {
					if (updatedStatus) {
						if (props.data.groupStatus === ActivityGroupStatus.complete) {
							updateToStatusRef.current = updatedStatus;
							setIsWarningModalOpen(true);
							return;
						}
						updateCellStatus(updatedStatus);
						workplanMatrixInteraction_BI({
							actionType: updatedStatus,
							floorId: props.data.area.floorId,
							areaId: props.data.area.areaId,
							areaSequenceItemId: props.data._id!,
							sequenceItem: props.data.description,
							sequenceName: sequences.find((sequence) => sequence._id === props.data.sequenceId)?.name,
							source: 'Widget',
						});
					}
				}
				if (actionType === ActivitySequenceItemMenuActions.viewActivity) {
					await openEditWorkPlanDialog(props.data.activityGroupId!);
				}
				if (actionType === ActivitySequenceItemMenuActions.createIssue) {
					openCreateIssueDialog();
				}
				if (actionType === ActivitySequenceItemMenuActions.viewIssues) {
					openViewIssuesDialog();
				}
				if (actionType === ActivitySequenceItemMenuActions.doReview) {
					setShowReviewDialog(true);
				}
			} catch (error) {
				errorSnackbar(dispatch, translationService.get('statusUpdateFailed'));
				setCellStatus(previousStatus);
			}
		};

		const menuVerticalIndentationPixels: number = 28;
		const showHoverMode: boolean = (isHovered && !isMultiSelectMode) || isTouchDevice();

		const onCellClick = (event: any) => {
			if (isMultiSelectMode && canUserSelectCell) {
				return handleMultiSelectClick(event);
			}
			if (isClickable) {
				if (cellStatus === AreaSequenceItemStatus.readyForReview && userHasAccessToActivityActions) {
					return setShowReviewDialog(true);
				}
				if (cellStatus === AreaSequenceItemStatus.complete && userHasAccessToActivityActions) {
					setShowMenu(true);
				} else {
					markCellOnClick(event);
				}
			}
		};

		const onMouseEnter = (event: any) => {
			if (isClickable && !isTouchDevice()) {
				setIsHovered(true);
				props.onHover(event);
			}
		};

		const onMouseLeave = () => {
			if (isClickable) {
				setIsHovered(false);
			}
		};

		const openCreateIssueDialog = () => {
			setIsCreateIssueDialogOpen(true);
		};
		const onCloseCreateDialog = () => {
			setIsCreateIssueDialogOpen(false);
		};

		const confirmStatusUpdate = () => {
			setIsWarningModalOpen(false);
			updateCellStatus(updateToStatusRef.current!);
		};

		const onCreateIssue = (createdIssue: IIssue) => {
			const updatedMergedAreaSequenceItem: IMergedAreaSequenceItem = {
				...props.data,
				issues: [...(props.data.issues ? props.data.issues : []), createdIssue],
			};
			updateAreaSequenceItemAndRecreateTable(updatedMergedAreaSequenceItem);
			setIsCreateIssueDialogOpen(false);
		};

		const onUpdateIssue = (updatedIssue: IIssue, deleted?: boolean) => {
			const updatedIssues: IIssue[] = deleted
				? props.data.issues!.filter((issue) => issue._id !== updatedIssue._id)
				: props.data.issues!.map((issue) => {
						if (issue._id === updatedIssue._id) {
							return updatedIssue;
						}
						return issue;
					});
			const updatedMergedAreaSequenceItem: IMergedAreaSequenceItem = {
				...props.data,
				issues: updatedIssues,
			};
			updateAreaSequenceItemAndRecreateTable(updatedMergedAreaSequenceItem);
		};

		const showMultiSelectedStatus: boolean =
			mode === ActivitySequenceMatrixMode.display && (showHoverMode || isMultiSelectedCell) && canUserSelectCell;

		const showIsHoverState: boolean = isHovered && canUserSelectCell;

		const onApproveSubmit = async () => {
			setShowReviewDialog(false);
			await markCellOnClick();
			successSnackbar(dispatch, translationService.get('asiReviewDialog_reviewApproved'));
		};

		const onRejectSubmit = async () => {
			setShowReviewDialog(false);
			const asiUpdated: IMergedAreaSequenceItem = await updateAreaSequenceItemReadyForReviewStatus(
				props.data._id!,
				false
			);
			props.onClick(props.data, undefined, asiUpdated);
			successSnackbar(
				dispatch,
				translationService.get('asiReviewDialog_reviewRejected', {
					new_status: translationService.get(asiUpdated.status),
				})
			);
		};

		if (props.data.isDisabled) {
			return <div className={classes.stripes} />;
		}

		return (
			<>
				{isActivityDialogOpen && (
					<WorkPlanDialog
						closeDialog={() => setIsActivityDialogOpen(false)}
						onChangeData={async () => {}}
						activity={currentActivity}
						activityStartDate={currentActivity?.startDate!}
						areaSequenceItemMode
					/>
				)}
				{showReviewDialog && (
					<AsiReviewDialog
						asi={props.data}
						onCreateIssue={onCreateIssue}
						projectId={props.data.projectId}
						area={props.data.area}
						sequenceItemDescription={props.data.description}
						profession={props.data.profession}
						show={showReviewDialog}
						onClose={() => setShowReviewDialog(false)}
						onAcceptSubmit={onApproveSubmit}
						onRejectSubmit={onRejectSubmit}
					/>
				)}
				{isCreateIssueDialogOpen && (
					<IssueCreateDialog
						showCreateDialog={isCreateIssueDialogOpen}
						onClose={onCloseCreateDialog}
						onCreateIssue={onCreateIssue}
						defaultProfession={props.data.profession}
						defaultAreas={[props.data.area]}
						linkedAreaSequenceItemId={props.data._id}
					/>
				)}
				<MultiIssuesViewDialog
					onUpdateIssue={onUpdateIssue}
					show={isViewIssuesDialogOpen && !!props.data.issues?.length}
					close={() => setIsViewIssuesDialogOpen(false)}
					issues={props.data.issues || []}
					headerTitle={props.data.description}
					headerInfo={{
						value: translationService.get(cellStatus),
						classname: getAreaSequenceItemCellColorClassName(
							cellStatus,
							!!props.data.isCheckedForActivityCreation,
							true,
							classes,
							mode,
							isAsiComplete(cellStatus),
							cellStatus === AreaSequenceItemStatus.readyForReview
						),
					}}
				/>
				<div
					ref={setChipElement as any}
					className={classnames(
						classes.baseCell,
						{
							[classes.isReadyForReviewCell]: cellStatus === AreaSequenceItemStatus.readyForReview,
						},
						{
							[classes.isLimitedUser]: !userHasAccessToActivityActions,
						},
						{
							[classes.isHover]: showIsHoverState,
						},
						getAreaSequenceItemCellColorClassName(
							cellStatus,
							!!props.data.isCheckedForActivityCreation,
							!isClickable,
							classes,
							mode,
							isAsiComplete(cellStatus),
							cellStatus === AreaSequenceItemStatus.readyForReview
						),
						{
							[classes.multiSelectedCell]: isMultiSelectedCell,
						}
					)}
					onMouseEnter={onMouseEnter}
					onMouseLeave={onMouseLeave}
					onClick={onCellClick}
				>
					<ActivitySequenceItemCellMainContent
						status={cellStatus}
						dates={props.data}
						cellIconSrc={cellIconSrc}
						hoverMode={showHoverMode}
						matrixMode={mode}
					/>
					{showThreeDots && (
						<div className={classes.menuDots} onClick={handleThreeDotsClick}>
							<img alt={''} src={threeDotsVertical} />
						</div>
					)}
					{!showThreeDots &&
						cellStatus !== AreaSequenceItemStatus.complete &&
						!!props.data.issues?.length && (
							<TrusstorIcon
								iconName={IconNames.issues}
								color={
									cellStatus === AreaSequenceItemStatus.overdue ||
									cellStatus === AreaSequenceItemStatus.delayedOverdue
										? IconColor.Red900
										: IconColor.Grey500
								}
								className={classes.issuesIcon}
							/>
						)}
					{showMultiSelectedStatus && (
						<div
							className={classnames({
								[classes.multiSelectionCheckbox]: true,
								[classes.multiSelectionCheckboxSelected]: isMultiSelectedCell,
							})}
							onClick={(event) => handleMultiSelectClick(event)}
						>
							{isMultiSelectedCell && (
								<img className={classes.checkIcon} alt={'white check icon'} src={whiteCheckIcon} />
							)}
						</div>
					)}
					{showMenu && (
						<div
							ref={setMenuElement as any}
							className={classes.chipMenu}
							style={{
								top: `${menuVerticalIndentationPixels}px`,
								left: 40,
							}}
						>
							<TrusstorMenu
								menuTitle={translationService.get('changeStatus')}
								width={150}
								items={getCellMenuItemsByStatus(
									cellStatus,
									!!props.data.activityGroupId,
									onMenuItemClick,
									!!props.data.issues?.length
								)}
							/>
						</div>
					)}
					<ActionConfirmationDialog
						title={translationService.get('areYouSure')}
						text={translationService.get('markDoneWarning')}
						mainButtonAction={confirmStatusUpdate}
						secondaryButtonAction={() => setIsWarningModalOpen((prev) => !prev)}
						show={isWarningModalOpen}
						onClose={() => setIsWarningModalOpen((prev) => !prev)}
					/>
				</div>
			</>
		);
	};

	return ActivitySequenceItemCell;
};

export const ActivitySequenceItemCellCLick = memo(getActivitySequenceITemCell(ActivitySequenceMatrixMode.display));
export const ActivitySequenceItemCellSchedule = memo(getActivitySequenceITemCell(ActivitySequenceMatrixMode.schedule));
