import React, { useEffect, useRef } from 'react';
import classes from './styles.module.scss';
import { TranslationService } from '../../../services/translation.service';
import { useKeyPress } from '../../../hooks/custom.hooks';
import { Loader } from '../../Loader/Loader';
import { getIsTouchDevice } from '../../../utils/mobile.utils';
import classnames from 'classnames';
import { IconNames } from '../../TrusstorIconShared/IconNames.enum';
import { IconSize, TrusstorIconShared } from '../../TrusstorIconShared/TrusstorIconShared';

interface ICommentInputProps {
	saveComment: (comment: string) => Promise<void>;
	saveImage?: (file: File) => Promise<void>;
	translationService: TranslationService;
	rootStyle?: React.CSSProperties;
	hasCamera?: boolean;
	isMobile?: boolean;
	setFocusOnCommentInput?: boolean;
}

export const CommentInput = (props: ICommentInputProps) => {
	const [commentText, setCommentText] = React.useState('');
	const [showSendingLoader, setShowSendingLoader] = React.useState<boolean>(false);

	const textareaRef: React.MutableRefObject<HTMLTextAreaElement | null> = useRef(null);

	const isTouchDevice: boolean = getIsTouchDevice();

	useEffect(() => {
		const textarea = textareaRef.current;
		if (!textarea) return;
		if (textarea.value.trim() === '') {
			textarea.style.height = 'auto';
			textarea.style.height = props.isMobile ? '21px' : '16px';
		}
		if (textarea.scrollHeight < 40) return;
		textarea.style.height = 'auto';
		textarea.style.height = `${textarea.scrollHeight}px`;
	}, [commentText]);

	const handleEnterKeyPress = (event: KeyboardEvent) => {
		if (props.isMobile) {
			return;
		}

		if (event.target === textareaRef.current && event.key === 'Enter' && !event.shiftKey) {
			event.preventDefault();
			insertComment();
		}
	};

	useKeyPress('Enter', handleEnterKeyPress);

	const insertComment = (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		e?.preventDefault();
		const text: string = textareaRef.current!.value;
		if (text.trim() === '') return;
		setShowSendingLoader(true);
		props.saveComment(text).then(() => {
			setShowSendingLoader(false);
		});
		setCommentText('');
	};

	const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
		const image: File | undefined = e.target.files?.[0];
		if (!image) return;
		props.saveImage?.(image);
		return;
	};

	return (
		<div className={classes.textInputWrapper} style={props.rootStyle}>
			{props.hasCamera && (
				<label className={classnames({ [classes.imageInputContainer]: !isTouchDevice })}>
					<input
						type="file"
						accept="image/*, application/pdf"
						onChange={handleImageChange}
						className={classes.imageInput}
					/>
					<TrusstorIconShared iconName={IconNames.plus} size={IconSize.MEDIUM} />
				</label>
			)}
			<div className={`${classes.inputBox}`}>
				<textarea
					ref={textareaRef}
					value={commentText}
					onChange={(e) => setCommentText(e.target.value)}
					placeholder={props.translationService.get('writeYourComments')}
					autoFocus={props.setFocusOnCommentInput}
				/>
				<div onClick={(e) => insertComment(e)} className={classes.button}>
					{showSendingLoader ? <Loader size={20} /> : props.translationService.get('Send')}
				</div>
			</div>
		</div>
	);
};
