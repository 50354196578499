import { ITableComponentProps } from '@interfaces/ITableComponentProps';
import { ISequenceItemProgressBySequenceItemReport } from '@interfaces/ISequenceItemProgressBySequenceItemReport';
import React, { ReactElement } from 'react';
import classes from '@src/Components/SequenceProgressAnalysis/ActivitiesProgress/ActivitiesProgressTable/styles.module.scss';
import { ProgressBar } from '@shared/components/Bars/ProgressBar/ProgressBar';
import {
	getOverallProgressPercentage,
	isProgressReportCompleted,
} from '@src/Components/SequenceProgressAnalysis/ActivitiesProgress/ActivitiesProgressTable/utils';

export const OverallProgressCell = (
	props: ITableComponentProps<ISequenceItemProgressBySequenceItemReport>
): ReactElement<ITableComponentProps<ISequenceItemProgressBySequenceItemReport>> => {
	const report: ISequenceItemProgressBySequenceItemReport = props.data;
	const isCompleted: boolean = isProgressReportCompleted(report);
	const overallProgressPercentage: number = getOverallProgressPercentage(report);

	return (
		<div className={classes.overallProgressCell}>
			<div className={classes.stats}>
				<div className={classes.percentage}>{overallProgressPercentage}%</div>
				{!isCompleted && (
					<div className={classes.fraction}>
						{report.overallProgress.completedAsiInPeriod}/{report.overallProgress.totalAsisInPeriod}
					</div>
				)}
			</div>
			{!isCompleted && (
				<ProgressBar
					progressCount={report.overallProgress.completedAsiInPeriod}
					totalCount={report.overallProgress.totalAsisInPeriod}
					showPrecentage={false}
					showTooltip={false}
					color={'#2EBFFD'}
				/>
			)}
		</div>
	);
};
