import React, { useMemo } from 'react';
import { TrusstorBox } from '../../Boxes/Containers/TrusstorBox';
import { translationService } from '../../../index';
import { TableTitle } from '../../TableComponents/TableTitle/TableTitle';
import { TableRowTypes, USERS_TABLE_COLUMN_WIDTH } from '../../../constants';
import { MainTableRow } from '../../TableComponents/TableRow/MainTableRow';
import { Grid } from '@material-ui/core';
import classes from './styles.module.scss';
import { IProfession, ITableTitleItems, IUser } from '../../../interfaces';
import { ISortingState } from '../../../interfaces/ISortingState';
import { SORT_ORDER } from '@shared/constants/constants';
import { IRowProperty } from '../../../interfaces/IRowProperty';
import { IRootState } from '../../../store/slices';
import { useSelector } from 'react-redux';
import { getTableRowProperties } from './utils';

const usersListTitles: ITableTitleItems = {
	fullName: { title: 'fullName', disableSort: true },
	email: { title: 'email', disableSort: true },
	phone: { title: 'phone', disableSort: true },
	permission: { title: 'permission', disableSort: true, isTextCentered: true },
};

const sortingState: ISortingState = {
	isSorted: true,
	columnName: 'fullName',
	sortOrder: SORT_ORDER.ASCENDING,
};

interface IUsersTableProps {
	users: IUser[];
	onSearchInputChange: (text: string) => void;
	isSearchInputExist: boolean;
	fetchUsersCallback: () => void;
}

export const UsersTable = (props: IUsersTableProps) => {
	const professions: IProfession[] = useSelector((state: IRootState) => state.professionsReducer.professions);
	const rowProperties: IRowProperty[] = useMemo(() => getTableRowProperties(professions), [professions]);

	return (
		<React.Fragment>
			<TrusstorBox customStyleClass={classes.tableWrapper}>
				<Grid container direction="column" className={classes.tableContainer}>
					<Grid container className={classes.listContainer} alignItems="center">
						<div className={classes.headerContainer}>
							<div className={classes.header}>
								{Object.entries(usersListTitles).map(([title, value]) => {
									return (
										<TableTitle
											key={title}
											columnWidth={USERS_TABLE_COLUMN_WIDTH}
											sortingState={sortingState}
											disableSort={value.disableSort}
											currentColumn={value.title}
											updateSortingState={() => {}}
											isTextCentered={value.isTextCentered}
										/>
									);
								})}
							</div>
						</div>

						<div className={classes.rowsContainer}>
							{props.users && props.users.length > 0
								? props.users.map((user) => {
										return (
											<MainTableRow
												object={user}
												additionalObjects={props.users}
												type={TableRowTypes.USER}
												columnWidth={USERS_TABLE_COLUMN_WIDTH}
												rowProperties={rowProperties}
												key={user.username}
												onExitDialog={props.fetchUsersCallback}
											/>
										);
								  })
								: props.users?.length === 0 && (
										<p className={classes.placeholderText}>
											{translationService.get('noUsersDefinedMessage')}
										</p>
								  )}
						</div>
					</Grid>
				</Grid>
			</TrusstorBox>
		</React.Fragment>
	);
};
