import { store } from '../store/store';
import { logout } from '../store/thunks';
import { authService, requestService, userService } from '../index';
import { IConfig } from '../interfaces/IConfig';
import { AxiosError } from 'axios';
import { ITrusstorAxiosError } from '@shared/interfaces/ITrusstorAxiosError';
import { HEADERS } from '@shared/constants/constants';
import { ILoggedUser } from '@shared/interfaces/IUser';

export const errorInterceptor = (config: IConfig) => async (error: AxiosError<any>) => {
	if (!isErrorResponseIsFromBackend(error, config)) {
		return Promise.reject(error);
	}

	switch (error?.response?.status) {
		case 401:
			return await handleUnauthorizedResponse(error as ITrusstorAxiosError);
		case 403:
			store.dispatch(logout());
			return Promise.reject(error);
		case 418:
			window.location.reload();
			break;
		default:
			return Promise.reject(error);
	}
};

export const handleUnauthorizedResponse = async (error: ITrusstorAxiosError) => {
	if (error.config.retryCount === 3) {
		return Promise.reject(error);
	}

	const loggedUser: ILoggedUser | undefined = await authService.refreshUserToken();
	if (loggedUser) {
		error.config.retryCount = (error.config.retryCount || 0) + 1;
		error.config.headers = {
			...error.config.headers,
			[HEADERS.authorization]: `Bearer ${userService.getLoggedUserAccessToken()}`,
		};
		return requestService.resendRequest(error.config);
	}

	return Promise.reject(error);
};

export const isErrorResponseIsFromBackend = (error: AxiosError<any>, config: IConfig): boolean => {
	return !!error.config.url?.startsWith(config.apiGateway);
};
