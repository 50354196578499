import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import moment, { Moment } from 'moment-timezone';
import 'moment/locale/he';
import { IProjectMilestones } from '@shared/interfaces/IProjectMilestones';
import { IMilestone } from '@shared/interfaces/IMilestone';
import { IProject } from '@shared/interfaces/IProject';
import { cM } from '@shared/utils/colors.utils';
import { translationService } from '../../../../index';
import { selectTimezone } from '../../../../store/slices/project.slice';
import greenPolygonIcon from '../../../../assets/milestonesIcons/greenPolygon.svg';
import redPolygonIcon from '../../../../assets/milestonesIcons/redPolygon.svg';
import upcomingBigPolygonIcon from '../../../../assets/milestonesIcons/upcomingBigPolygon.svg';
import expandIcon from '../../../../assets/milestonesIcons/expandIcon.png';
import classes from './styles.module.scss';
import { MilestoneRow } from './MilestoneRow/MilestoneRow';
import { MilestoneHeader } from './MilestoneHeader/MilestoneHeader';
import { GeneralDialog } from '../../../GeneralDialog/GeneralDialog';
import { MilestoneDialog } from './MilestoneDialog/MilestoneDialog';
import {
	isMilestoneLate,
	isMilestoneRemaining,
	milestoneLateDays,
	milestoneRemainingDays,
} from '../../../../utils/milestones.utils';
import { isTrusstorMobile } from '@shared/utils/mobile.utils';

interface IProjectMilestonesSectionProps {
	projectMilestones: IProjectMilestones | undefined;
	currentProject: IProject | undefined;
}

const ProjectMilestonesSection = (props: IProjectMilestonesSectionProps) => {
	const tz: string = useSelector(selectTimezone)!;
	const [milestoneDialog, setMilestoneDialog] = useState<IProjectMilestones | null>(null);
	const currentDate: Moment = moment.tz(new Date(), tz);
	const nextMilestone: IMilestone | undefined = props.projectMilestones?.milestones.find((milestone) => {
		return moment.tz(milestone.endDate, tz).isAfter(currentDate);
	});

	const getPrevMilestoneData = (): JSX.Element => {
		if (!props.projectMilestones) {
			return <></>;
		}
		const prevMilestone: IMilestone | undefined = props.projectMilestones?.milestones
			.filter((milestone) => {
				return moment.tz(milestone.endDate, tz).isBefore(currentDate);
			})
			.pop();
		if (!prevMilestone) return <></>;
		const lastMilestoneIndex: number = props.projectMilestones.milestones.findIndex(
			(milestone) => milestone._id === prevMilestone!._id
		);
		const icon: string =
			isMilestoneLate(prevMilestone, tz) && !prevMilestone.isDone ? redPolygonIcon : greenPolygonIcon;
		const bracketsText: string | boolean =
			!prevMilestone.isDone &&
			isMilestoneLate(prevMilestone, tz) &&
			`${milestoneLateDays(prevMilestone, tz)} ${translationService.get('daysLate')}`;
		return (
			<MilestoneRow
				title={translationService.get('lastMilestoneCompleted')}
				milestone={prevMilestone}
				icon={icon}
				milestoneIndex={lastMilestoneIndex}
				bracketsText={bracketsText}
				separateLine={!!nextMilestone}
			/>
		);
	};

	const getNextMilestoneData = (): JSX.Element => {
		if (!props.projectMilestones) {
			return <></>;
		}
		if (!nextMilestone) return <></>;
		const nextMilestoneIndex: number = props.projectMilestones.milestones.findIndex(
			(milestone) => milestone._id === nextMilestone!._id
		);
		const bracketsText: string | boolean =
			!nextMilestone.isDone &&
			isMilestoneRemaining(nextMilestone, tz) &&
			`${milestoneRemainingDays(nextMilestone, tz)} ${translationService.get('daysRemaining')}`;
		return (
			<MilestoneRow
				title={translationService.get('upcoming')}
				icon={upcomingBigPolygonIcon}
				milestoneIndex={nextMilestoneIndex}
				milestone={nextMilestone}
				bracketsText={bracketsText}
			/>
		);
	};

	const handleExpand = () => {
		setMilestoneDialog(props.projectMilestones!);
	};

	const handleCloseDialog = () => {
		setMilestoneDialog(null);
	};

	return (
		<>
			<GeneralDialog
				show={!!milestoneDialog}
				close={handleCloseDialog}
				rootStyle={isTrusstorMobile() ? classes.mobileScreenDialogRootStyle : classes.dialogRootStyle}
				clearDialogBorders
			>
				<MilestoneDialog projectMilestones={props.projectMilestones} currentProject={props.currentProject} />
			</GeneralDialog>
			<section className={cM(classes.milestonesContainer, classes)}>
				<MilestoneHeader currentProject={props.currentProject} projectMilestones={props.projectMilestones} />
				{!!props.projectMilestones?.milestones.length && (
					<section className={cM(classes.lastUpcomingContainer, classes)}>
						<div>{getPrevMilestoneData()}</div>
						<div>{getNextMilestoneData()}</div>
						<img src={expandIcon} alt="expandIcon" className={classes.expandIcon} onClick={handleExpand} />
					</section>
				)}
			</section>
		</>
	);
};

export { ProjectMilestonesSection };
