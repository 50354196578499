import { RoleType } from '@shared/interfaces/RoleType.enum';
import { IUserFeatureProhibitions } from '@shared/interfaces/IUserFeatureProhibitions';
import { sequenceProjectAnalysisProhibitedRoles } from '@shared/constants/permissions.constants';

/*
 This is a list of features and the roles that don't have access to them.
*/

const featureNames = [
	'systemPagesAccess',
	'activitiesWidgetActions',
	'workplanActivityActions',
	'investigationReportAccess',
	'executiveViewAccess',
	'projectProgressAnalysisAccess',
	'notifications-safety',
	'projectMonitor-safety',
] as const;

export type UserFeaturesNamesType = (typeof featureNames)[number];
type IUserFeatureProhibitionsFrontend = IUserFeatureProhibitions<UserFeaturesNamesType>;

export const userFeaturesProhibitedList: IUserFeatureProhibitionsFrontend = {
	'projectProgressAnalysisAccess': sequenceProjectAnalysisProhibitedRoles,
	'activitiesWidgetActions': [RoleType.VIEWER],
	'workplanActivityActions': [RoleType.VIEWER],
	'systemPagesAccess': [RoleType.VIEWER, RoleType.MANAGER, RoleType.EXECUTIVE],
	'investigationReportAccess': [RoleType.VIEWER],
	'executiveViewAccess': [RoleType.VIEWER, RoleType.MANAGER, RoleType.PROJECT_ADMIN],
	'notifications-safety': [RoleType.VIEWER],
	'projectMonitor-safety': [RoleType.VIEWER],
};
