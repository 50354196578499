import React from 'react';
import classes from './styles.module.scss';
import { CommentPhotoDisplay } from './CommentPhotoDisplay/CommentPhotoDisplay';
import { ICommentShared, IssueCommentType } from '../../interfaces/ICommentShared';
import { TranslationService } from '../../services/translation.service';
import { StorageService } from '../../services/storage.service';
import { getRelativeHour } from '../../utils/dateUtils';
import { CommentTextDisplay } from './CommentTextDisplay/CommentTextDisplay';
import { CommentPdfDisplay } from './CommentPdfDisplay/CommentPdfDisplay';

interface ICommentsThreadProps {
	comments: ICommentShared[];
	issueId: string;
	tz: string;
	translationService: TranslationService;
	storageService: StorageService;
}

export const CommentsThread = (props: ICommentsThreadProps) => {
	return (
		<div className={classes.container}>
			{props.comments.map((comment: ICommentShared) => {
				const dateCreatedFormatted: string = getRelativeHour(
					comment.dateCreated,
					props.tz,
					props.translationService
				);

				return (
					<>
						{comment.content.type === IssueCommentType.IMAGE && (
							<CommentPhotoDisplay
								issueId={props.issueId}
								commentId={comment.content.value}
								dateCreated={dateCreatedFormatted}
								senderName={comment.sender.name}
								storageService={props.storageService}
							/>
						)}
						{comment.content.type === IssueCommentType.TEXT && (
							<CommentTextDisplay
								comment={comment}
								dateCreated={dateCreatedFormatted}
								translationService={props.translationService}
							/>
						)}
						{comment.content.type === IssueCommentType.PDF && (
							<CommentPdfDisplay
								issueId={props.issueId}
								commentId={comment.content.value}
								storageService={props.storageService}
								comment={comment}
								dateCreated={dateCreatedFormatted}
								translationService={props.translationService}
							/>
						)}
					</>
				);
			})}
		</div>
	);
};
