import React, { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import classes from './WarningDialogs/SequenceLinkWarningDialog/styles.module.scss';
import { SequenceItemLinkTable } from '../SequenceItemLinkTable';
import { milestonesLocalStorage, translationService } from '@src/servicesInitializers';
import {
	useBulkSequenceItemsLinkToMilestoneMutation,
	useSequenceSequenceItemsByProjectQuery,
} from '@src/hooks/queries/sequenceItem.query';
import { useDispatch, useSelector } from 'react-redux';
import { selectProjectId } from '@store/slices/project.slice';
import {
	ITrusstorButtonSize,
	ITrusstorButtonType,
	TrusstorButton,
} from '@shared/components/buttons/TrusstorButton/TrusstorButton';
import { TrusstorIcon } from '../../TrusstorIcon/TrusstorIcon';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { useHistory } from 'react-router-dom';
import { SmallSingleDropdown } from '@shared/components/Dropdowns/DesignSystem/SingleDropdown/SmallSingleDropdown/SmallSingleDropdown';
import { SequenceLinkFilterOptionsEnum } from '@src/Components/SequenceItemLinkTable/utils';
import {
	getFilteredSequenceItems,
	getSortedSequenceItems,
	titleNamesEnum,
} from '@src/Components/SequenceItemLinkTable/utils';
import { ISequenceItem } from '@interfaces/ISequenceItem';
import { ISortingState } from '@interfaces/ISortingState';
import { SORT_ORDER } from '@shared/constants/constants';
import { ITableTitleItems } from '@src/interfaces';
import { TrusstorCheckbox } from '@shared/components/TrusstorCheckbox/TrusstorCheckbox';
import { useProjectMilestonesQuery } from '@src/hooks/queries/milestones.queries.hooks';
import { orderBy, uniq } from 'lodash';
import { useOutsideClick } from '@src/hooks/custom.hooks';
import { BulkLinkWarningDialog } from '@src/Components/SequenceItemLinkTable/SequenceLinkTableContainer/WarningDialogs/BulkLinkWarningDialog';
import { BulkUnlinkWarningDialog } from '@src/Components/SequenceItemLinkTable/SequenceLinkTableContainer/WarningDialogs/BulkUnlinkWarningDialog';
import { BulkSingleLinkWarningDialog } from '@src/Components/SequenceItemLinkTable/SequenceLinkTableContainer/WarningDialogs/BulkSingleLinkWarningDialog';
import { BulkSingleUnlinkWarningDialog } from '@src/Components/SequenceItemLinkTable/SequenceLinkTableContainer/WarningDialogs/BulkSingleUnlinkWarningDialog';
import { infoSnackbar } from '@utils/snackbar.utils';

interface ISequenceLinkTableContainerProps {
	selectedSequenceId: string;
	currentMilestoneId: string;
}

export const SequenceLinkTableContainer = (props: ISequenceLinkTableContainerProps) => {
	const projectId: string = useSelector(selectProjectId)!;
	const { sequenceItems } = useSequenceSequenceItemsByProjectQuery(projectId, props.selectedSequenceId);
	const [selectedSequenceItemIds, setSelectedSequenceItemIds] = useState<string[]>([]);
	const { projectMilestones } = useProjectMilestonesQuery(projectId);

	const [selectedFilter, setSelectedFilter] = React.useState<string>(Object.values(SequenceLinkFilterOptionsEnum)[0]);
	const [isDefaultSort, setIsDefaultSort] = useState<boolean>(true);

	const [sortingState, setSortingState] = useState<ISortingState>({
		isSorted: true,
		columnName: titleNamesEnum.SEQUENCE_ITEM,
		sortOrder: SORT_ORDER.ASCENDING,
	});
	const [lastClickedSequenceItemId, setLastClickedSequenceItemId] = useState<string | null>(null);

	const [sortedSequenceItems, setSortedSequenceItems] = useState<ISequenceItem[]>(sequenceItems);
	const filteredSequenceItems: ISequenceItem[] = getFilteredSequenceItems(
		sortedSequenceItems,
		selectedFilter as SequenceLinkFilterOptionsEnum
	);
	const bulkLinkSequenceItemsMutation = useBulkSequenceItemsLinkToMilestoneMutation(
		projectId,
		props.selectedSequenceId
	);

	const [showBulkLinkWarningDialog, setShowBulkLinkWarningDialog] = useState<boolean>(false);
	const [showBulkUnlinkWarningDialog, setShowBulkUnlinkWarningDialog] = useState<boolean>(false);
	const [singleSequenceItemToUpdate, setSingleSequenceItemToUpdate] = useState<ISequenceItem | null>(null);
	const [isLinkAction, setIsLinkAction] = useState<boolean>(false);
	const [showBulkSingleLinkWarningDialog, setShowBulkSingleLinkWarningDialog] = useState<boolean>(false);
	const [showBulkSingleUnlinkWarningDialog, setShowBulkSingleUnlinkWarningDialog] = useState<boolean>(false);

	const itemsToLink: ISequenceItem[] = sequenceItems.filter(
		(sequenceItem) =>
			selectedSequenceItemIds.includes(sequenceItem._id) &&
			(!sequenceItem?.linkedMilestoneId || sequenceItem?.linkedMilestoneId !== props.currentMilestoneId)
	);

	const itemsToUnlink: ISequenceItem[] = sequenceItems.filter(
		(sequenceItem) =>
			selectedSequenceItemIds.includes(sequenceItem._id) &&
			sequenceItem?.linkedMilestoneId === props.currentMilestoneId
	);

	useEffect(() => {
		setSelectedSequenceItemIds([]);
	}, [props.selectedSequenceId, props.currentMilestoneId]);

	const isAllSelected: boolean =
		!!filteredSequenceItems.length &&
		filteredSequenceItems.every((sequenceItem) => selectedSequenceItemIds.includes(sequenceItem._id));

	const history = useHistory();

	const editClick = () => {
		history.push(`/${projectId}/workplan`);
	};

	const changeSelectAllSequenceItems = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.stopPropagation();
		if (isAllSelected) {
			selectSequenceItemIds([]);
			return;
		}
		selectSequenceItemIds(filteredSequenceItems.map((sequenceItem) => sequenceItem._id));
	};

	const sequenceItemLinkTableTitles: ITableTitleItems = {
		itemName: {
			title: titleNamesEnum.SEQUENCE_ITEM,
			titleComponent: <TrusstorCheckbox checked={isAllSelected} onChange={changeSelectAllSequenceItems} />,
		},
		profession: { title: titleNamesEnum.PROFESSION },
		linkedMilestone: {
			title: titleNamesEnum.LINKED_MILESTONE,
		},
	};

	const sortColumnByOrder = useCallback(() => {
		const sortParameter: string | undefined = Object.values(sequenceItemLinkTableTitles).find(
			(title) => title.title === sortingState.columnName
		)?.title;
		const currentSortOrder: ISortingState['sortOrder'] = sortingState.sortOrder;
		setSortedSequenceItems(
			getSortedSequenceItems(sequenceItems, sortParameter || 'orderIndex', currentSortOrder, projectMilestones)
		);
	}, [sortingState, sequenceItems]);

	useEffect(() => {
		if (sequenceItems.length === 0) {
			return;
		}

		if (isDefaultSort) {
			setSortedSequenceItems(orderBy(sequenceItems, (seq) => seq.orderIndex));
			setSortingState({
				isSorted: true,
				columnName: 'orderIndex',
				sortOrder: SORT_ORDER.ASCENDING,
			});
			setIsDefaultSort(false);
		} else {
			sortColumnByOrder();
		}
	}, [sequenceItems, sortColumnByOrder, isDefaultSort]);

	useEffect(() => {
		sortColumnByOrder();
	}, [sortingState, sortColumnByOrder]);

	const updateSortingState = (newState: Partial<ISortingState>) => {
		setSortingState((prevState) => ({
			...prevState,
			...newState,
		}));
	};

	const handleMultiSelectClick = (sequenceItem: ISequenceItem, lastClickedSequenceItemId: string | null) => {
		const selectedSequenceItemIndex: number = filteredSequenceItems.findIndex(
			(item) => item._id === lastClickedSequenceItemId
		);
		const currentSequenceItemIndex: number = filteredSequenceItems.findIndex(
			(item) => item._id === sequenceItem._id
		);
		const allSequenceItemsBetween: ISequenceItem[] = filteredSequenceItems.slice(
			Math.min(selectedSequenceItemIndex, currentSequenceItemIndex),
			Math.max(selectedSequenceItemIndex, currentSequenceItemIndex) + 1
		);
		const shouldSelectItemsInBetween: boolean = !!(
			lastClickedSequenceItemId && selectedSequenceItemIds.includes(lastClickedSequenceItemId)
		);
		if (shouldSelectItemsInBetween) {
			setSelectedSequenceItemIds((prev) =>
				uniq([...prev, ...allSequenceItemsBetween.map((sequenceItem) => sequenceItem._id)])
			);
			return;
		}
		const allSequenceItemIdsBetween = allSequenceItemsBetween.map((item) => item._id);
		setSelectedSequenceItemIds((prev) => prev.filter((id) => !allSequenceItemIdsBetween.includes(id)));
	};

	const dispatch = useDispatch();

	const handleShiftInfoTip = () => {
		const isNotFirstTimeToSelectSequenceItemToLink: boolean =
			milestonesLocalStorage.getIsNotFirstTimeTolinkSequenceItemToMilestone();

		if (!isNotFirstTimeToSelectSequenceItemToLink) {
			infoSnackbar(dispatch, translationService.get('holdShiftToSelectMultipleItems'));
			milestonesLocalStorage.setIsNotFirstTimeToLinkSequenceItemToMilestone(true);
		}
	};

	const handleSelectSequenceItem = (sequenceItem: ISequenceItem, event: any) => {
		handleShiftInfoTip();

		const previousSelectedSequenceItemsId: string | null = lastClickedSequenceItemId;
		setLastClickedSequenceItemId(sequenceItem._id);
		const isShiftClicked: boolean = event.metaKey || event.shiftKey;
		if (!isShiftClicked) {
			if (selectedSequenceItemIds.includes(sequenceItem._id)) {
				setSelectedSequenceItemIds((prev) => prev.filter((id) => id !== sequenceItem._id));
				return;
			}
			setSelectedSequenceItemIds((prev) => [...prev, sequenceItem._id]);
			return;
		}
		handleMultiSelectClick(sequenceItem, previousSelectedSequenceItemsId);
	};

	const selectSequenceItemIds = (sequenceItemIds: string[]) => {
		setSelectedSequenceItemIds(sequenceItemIds);
	};

	const clearTableState = () => {
		setSelectedSequenceItemIds([]);
		setLastClickedSequenceItemId(null);
	};

	const linkBulkSequenceItemsAction = () => {
		clearTableState();
		bulkLinkSequenceItemsMutation.mutate({
			sequenceItemIds: itemsToLink.map((sequenceItem) => sequenceItem._id),
			milestoneId: props.currentMilestoneId,
		});
	};

	const areThereSelectedSequenceItemsLinkedToOtherMilestones: boolean = selectedSequenceItemIds.some(
		(sequenceItemId) => {
			const selectedSequenceItem: ISequenceItem | undefined = sequenceItems.find(
				(sequenceItem) => sequenceItem._id === sequenceItemId
			);

			return (
				selectedSequenceItem?.linkedMilestoneId &&
				selectedSequenceItem?.linkedMilestoneId !== props.currentMilestoneId
			);
		}
	);

	const linkBulkSequenceItems = () => {
		if (areThereSelectedSequenceItemsLinkedToOtherMilestones) {
			setShowBulkLinkWarningDialog(true);
			return;
		}
		linkBulkSequenceItemsAction();
	};

	const unlinkSequenceItemsAction = () => {
		clearTableState();
		bulkLinkSequenceItemsMutation.mutate({
			sequenceItemIds: itemsToUnlink.map((sequenceItem) => sequenceItem._id),
		});
	};

	const unlinkSequenceItems = () => {
		if (areThereSelectedSequenceItemsLinkedToOtherMilestones) {
			setShowBulkUnlinkWarningDialog(true);
			return;
		}
		unlinkSequenceItemsAction();
	};

	const handleSingleButtonLinkClick = (sequenceItem: ISequenceItem, isLinkAction: boolean) => {
		const isSequenceItemLinkedToOtherMilestone: boolean =
			!!sequenceItem.linkedMilestoneId && sequenceItem.linkedMilestoneId !== props.currentMilestoneId;
		if (isSequenceItemLinkedToOtherMilestone) {
			setIsLinkAction(isLinkAction);
			setSingleSequenceItemToUpdate(sequenceItem);
			if (isLinkAction) {
				setShowBulkSingleLinkWarningDialog(true);
			} else {
				setShowBulkSingleUnlinkWarningDialog(true);
			}
			return;
		}
		handleSingleButtonLinkClickAction(sequenceItem, isLinkAction);
	};

	const handleSingleButtonLinkClickAction = (sequenceItem: ISequenceItem, isLinkAction: boolean) => {
		clearTableState();
		bulkLinkSequenceItemsMutation.mutate({
			sequenceItemIds: [sequenceItem._id],
			...(isLinkAction && {
				milestoneId: props.currentMilestoneId,
			}),
		});
	};

	const tableRef: MutableRefObject<HTMLDivElement | null> = useRef<null | HTMLDivElement>(null);

	useOutsideClick(tableRef, setLastClickedSequenceItemId, undefined);

	const handleChangeFilter = (filter: string | null) => {
		if (filter) {
			setSelectedFilter(filter);
			clearTableState();
		}
	};

	return (
		<div className={classes.SequenceLinkTableContainer_container} data-testid="SequenceLinkTableContainer">
			<div className={classes.tableContainerHeader}>
				<div className={classes.leftContainer}>
					<div className={classes.tableTitle}>{`${translationService.get(
						'milestones_sequence_table_title'
					)} (${sequenceItems.length})`}</div>
					{!!selectedSequenceItemIds.length && (
						<>
							<div className={classes.buttons}>
								{!!itemsToLink.length && (
									<TrusstorButton
										className={classes.editButton}
										buttonType={ITrusstorButtonType.OUTLINE}
										buttonSize={ITrusstorButtonSize.SMALL}
										iconElement={<TrusstorIcon iconName={IconNames.link} />}
										text={`${translationService.get('sequence_link_table_link_selected_button')} (${
											itemsToLink.length
										})`}
										handleClick={linkBulkSequenceItems}
									/>
								)}
								{!!itemsToUnlink.length && (
									<TrusstorButton
										className={classes.editButton}
										buttonType={ITrusstorButtonType.OUTLINE}
										buttonSize={ITrusstorButtonSize.SMALL}
										iconElement={<TrusstorIcon iconName={IconNames.unlink} />}
										text={`${translationService.get(
											'sequence_link_table_unlink_selected_button'
										)} (${itemsToUnlink.length})`}
										handleClick={unlinkSequenceItems}
									/>
								)}
							</div>
						</>
					)}
				</div>

				<div className={classes.rightPart}>
					<SmallSingleDropdown
						value={selectedFilter}
						onChange={handleChangeFilter}
						options={Object.values(SequenceLinkFilterOptionsEnum)}
						getDisplayOption={(option) => translationService.get(option)}
					/>
					<TrusstorButton
						className={classes.editButton}
						buttonType={ITrusstorButtonType.OUTLINE}
						buttonSize={ITrusstorButtonSize.SMALL}
						iconElement={<TrusstorIcon iconName={IconNames.edit} />}
						text={translationService.get('edit')}
						handleClick={editClick}
					/>
				</div>
			</div>

			<div ref={tableRef}>
				<SequenceItemLinkTable
					currentMilestoneId={props.currentMilestoneId}
					handleLinkClick={handleSingleButtonLinkClick}
					lastClickedSequenceItemId={lastClickedSequenceItemId}
					sequenceItemLinkTableTitles={sequenceItemLinkTableTitles}
					updateSortingState={updateSortingState}
					sortingState={sortingState}
					filteredSequenceItems={filteredSequenceItems}
					handleSelectSequenceItem={handleSelectSequenceItem}
					selectedSequenceItemIds={selectedSequenceItemIds}
				/>
			</div>

			<BulkLinkWarningDialog
				show={showBulkLinkWarningDialog}
				hideShowDialog={() => setShowBulkLinkWarningDialog(false)}
				linkBulkSequenceItemsAction={linkBulkSequenceItemsAction}
			/>

			<BulkUnlinkWarningDialog
				show={showBulkUnlinkWarningDialog}
				hideDialog={() => setShowBulkUnlinkWarningDialog(false)}
				unlinkSequenceItemsAction={unlinkSequenceItemsAction}
			/>

			<BulkSingleLinkWarningDialog
				show={showBulkSingleLinkWarningDialog}
				hideDialog={() => setShowBulkSingleLinkWarningDialog(false)}
				linkSingleSequenceItemAction={() =>
					handleSingleButtonLinkClickAction(singleSequenceItemToUpdate!, isLinkAction)
				}
			/>
			<BulkSingleUnlinkWarningDialog
				show={showBulkSingleUnlinkWarningDialog}
				hideDialog={() => setShowBulkSingleUnlinkWarningDialog(false)}
				unlinkSingleSequenceItemAction={() =>
					handleSingleButtonLinkClickAction(singleSequenceItemToUpdate!, isLinkAction)
				}
			/>
		</div>
	);
};
