import React from 'react';
import classnames from 'classnames';
import classes from './styles.module.scss';
import { IProfession } from '../../interfaces/IProfession';
import { useSharedServices } from '../../hooks/sharedServices.context';
import { IProfessionDisplaySettings } from '../../interfaces/IProfessionDisplaySettings';
import { getProfessionBackgroundColor } from '../../utils/professions.utils';
import { getTradeTranslationShared } from '../../utils/translations.utils';
import { TradeIconShared } from '../TradeIconShared/TradeIconShared';
import { IconColor } from '../TrusstorIconShared/TrusstorIconShared';
import TrusstorTooltip from '../TrusstorTooltip';

interface IProfessionDisplayWithTradeIconProps {
	profession: IProfession;
	projectId: string;
	contractorTextClassname?: string;
	professionContainerClassname?: string;
	isNarrow?: boolean;
	ignoreFallback?: boolean;
	hideContractor?: boolean;
	showTradeIcon?: boolean;
}

export const ProfessionDisplayWithTradeIcon = (props: IProfessionDisplayWithTradeIconProps) => {
	const { translationService } = useSharedServices();
	const currentProjectDisplaySettings: IProfessionDisplaySettings | undefined =
		props.profession.activeProjects?.[props.projectId]?.displaySettings;
	const shouldShowDisplaySettings = (propertyName: keyof IProfessionDisplaySettings): boolean => {
		if (!currentProjectDisplaySettings) {
			return true;
		}
		return !!currentProjectDisplaySettings[propertyName];
	};
	const professionColor: string = getProfessionBackgroundColor(props.profession, props.projectId);
	const shouldShowSpecialty: boolean = !!(props.profession.specialty && shouldShowDisplaySettings('specialty'));
	const shouldShowTradeIcon: boolean = props.showTradeIcon || shouldShowDisplaySettings('trade');
	const chipText: string = shouldShowSpecialty
		? props.profession.specialty!
		: getTradeTranslationShared(props.profession, translationService);
	const shouldShowChip: boolean = shouldShowTradeIcon || shouldShowSpecialty;
	const chipTextId: string = `${chipText}-professionChipText`;
	const [contractorTextRef, setContractorTextRef] = React.useState<HTMLDivElement | null>(null);
	const [isContractorOverflowed, setIsContractorOverflowed] = React.useState<boolean>(false);

	const handleContractorOverflow = (isOverflow: boolean) => {
		if (!isContractorOverflowed) {
			setIsContractorOverflowed(isOverflow);
		}
	};

	return (
		<div
			className={classnames(
				classes.container,
				(props.isNarrow || isContractorOverflowed) && classes.narrowContainer
			)}
		>
			<div
				className={classnames(classes.professionContainer, props.professionContainerClassname)}
				data-testId={'profession'}
			>
				{shouldShowChip && (
					<TrusstorTooltip idToOverflow={chipTextId} text={chipText}>
						<div
							className={classnames(classes.tradeChip, {
								[classes.noTradeIcon]: !shouldShowTradeIcon,
							})}
							style={{ backgroundColor: professionColor }}
						>
							{shouldShowTradeIcon && (
								<TradeIconShared
									tradeId={props.profession.tradeId}
									iconColor={IconColor.White}
									ignoreFallback={props.ignoreFallback}
								/>
							)}
							<div className={classes.chipText} id={chipTextId}>
								{chipText}
							</div>
						</div>
					</TrusstorTooltip>
				)}
			</div>
			{!props.hideContractor && props.profession.contractor && shouldShowDisplaySettings('contractor') && (
				<TrusstorTooltip
					refChildren={{ current: contractorTextRef }}
					text={props.profession.contractor}
					setOverflow={handleContractorOverflow}
				>
					<div
						className={classnames(
							classes.contractorText,
							props.contractorTextClassname,
							isContractorOverflowed && classes.overflowed
						)}
						ref={setContractorTextRef}
					>
						{props.profession.contractor}
					</div>
				</TrusstorTooltip>
			)}
		</div>
	);
};
