import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sortBy } from 'lodash';
import { translationService } from '../../index';
import { APP_FILTER_TYPES, entitiesProperties } from '../../constants';
import {
	addSelectedManager,
	IActiveManager,
	removeSelectedManager,
	resetSelectedManagers,
} from '../../store/slices/managers.slice';
import { IRootState } from '../../store/slices';
import { IManager, ITradeFilter } from '../../interfaces';
import { ExpandableComponent } from '../MainPageSections/ExpandableComponent/ExpandableComponent';
import { getTradeTranslation } from '@utils/translations.utils';
import { ManagerFilterCard } from '@shared/components/Filters/ManagerFilterCard/ManagerFilterCard';

interface IManagersFilterSectionProps {
	useActiveManagers?: boolean;
	defaultOpen?: boolean;
}

const ManagersFilterSection = ({ useActiveManagers = false, defaultOpen = false }: IManagersFilterSectionProps) => {
	const dispatch = useDispatch();

	const activeManagers: IActiveManager[] = useSelector((state: IRootState) => state.managersReducer.activeManagers);
	const staticManagers: IManager[] = useSelector((state: IRootState) => state.managersReducer.staticManagers);
	const visibleManagers: IManager[] = useSelector((state: IRootState) => state.managersReducer.visibleManagers);
	const currentManagersFilter: ITradeFilter[] = useSelector((state: IRootState) => state.managersReducer.filterList);
	const selectedManagers: IManager[] = useSelector((state: IRootState) => state.managersReducer.selectedManagers);
	const managers: IManager[] = useActiveManagers ? activeManagers : staticManagers;

	const doesFiltersHasInitialValues = (): boolean => {
		return !(selectedManagers.length > 0 || currentManagersFilter.length > 0);
	};

	const sortManagers = (managers: IActiveManager[]): IActiveManager[] => {
		return sortBy(managers, [entitiesProperties.sortIndex, entitiesProperties.name]);
	};

	const handleResetManagersClickReducer = (): void => {
		dispatch(resetSelectedManagers());
	};

	const addManagerReducer = (manager): void => {
		dispatch(addSelectedManager({ selectedStaticManager: manager }));
	};

	const removeManagerReducer = (manager): void => {
		dispatch(removeSelectedManager({ removedStaticManager: manager }));
	};

	return (
		<ExpandableComponent
			headerText={translationService.get('managementTeam')}
			childrenLength={managers.length}
			clearFunction={handleResetManagersClickReducer}
			shouldHideClearButton={doesFiltersHasInitialValues()}
			clearLength={visibleManagers.length}
			type={APP_FILTER_TYPES.managerFilter}
			hideEmptyList
			defaultOpen={defaultOpen}
			testId={'managerFilterSection'}
		>
			{sortManagers(managers).map((manager: IManager | IActiveManager) => {
				const isSelected: boolean = selectedManagers.some(
					(selectedManager) => manager._id === selectedManager._id
				);
				return (
					<ManagerFilterCard
						key={manager._id}
						handleClick={
							isSelected ? () => removeManagerReducer(manager) : () => addManagerReducer(manager)
						}
						name={manager.name}
						managerTradeText={getTradeTranslation(manager)}
						isSelected={isSelected}
					/>
				);
			})}
		</ExpandableComponent>
	);
};

export { ManagersFilterSection };
