import React from 'react';
import { IWorkersTimesheet } from '@interfaces/IWorkersTimesheets';
import { WorkerTimesheet } from '../WorkerTimesheet/WorkerTimesheet';
import { cM } from '@shared/utils/colors.utils';
import classes from './styles.module.scss';
import { ITimesheetByTagType } from '../../../ITimesheetByTagType';
import { WorkersGroupedSection } from '../../WorkersSectionComponents/WorkersListSection/WorkersGroupedSection/WorkersGroupedSection';
import { IWorkerTimesheet } from '@shared/interfaces/IWorkerTimesheet';

interface IWorkersTimesheetTablesSectionProps {
	timesheetByTagType: ITimesheetByTagType;
	startDate: Date;
	endDate: Date;
}

export const WorkersTimesheetTablesSection = (props: IWorkersTimesheetTablesSectionProps) => {
	return (
		<div className={cM(classes.timesheetTablesSection, classes)}>
			{props.timesheetByTagType?.Worker &&
				Object.entries(props.timesheetByTagType?.Worker).map(([tradeName, workers]) => {
					return workers.map((tagTimesheets) => {
						return (
							<WorkerTimesheet
								startDate={props.startDate}
								endDate={props.endDate}
								key={tagTimesheets.tagId}
								tagId={tagTimesheets.tagId}
								timesheet={tagTimesheets.timesheet}
							/>
						);
					});
				})}
			{props.timesheetByTagType?.Manager &&
				Object.entries(props.timesheetByTagType?.Manager).map(([tradeName, tradeManagers]) => {
					return tradeManagers.map((tagTimesheets) => {
						return (
							<WorkerTimesheet
								startDate={props.startDate}
								endDate={props.endDate}
								key={tagTimesheets.tagId}
								tagId={tagTimesheets.tagId}
								timesheet={tagTimesheets.timesheet}
							/>
						);
					});
				})}
		</div>
	);
};
