import { isEqual, sortBy } from 'lodash';

export const sumByField = (array: any[], fieldName: string) => {
	return array.reduce<number>((sum: number, object: any) => {
		return sum + (Number(object[fieldName]) || 0);
	}, 0);
};

export const getMaximumValueByField = (array: any[], fieldName: string) => {
	return array.reduce((maxValue: number, object: any) => {
		return Math.max(object[fieldName], maxValue);
	}, 0);
};

export const filterArrayByAnotherArray = (
	arrayToFilter: any[],
	arrayToFilterBy: any[],
	fieldToFilterBy: string,
	secondFieldToFilterBy?: string
) => {
	if (!arrayToFilterBy || arrayToFilterBy.length === 0) {
		return arrayToFilter;
	}

	return arrayToFilter.filter((itemToFilter: any) =>
		arrayToFilterBy.some((itemToFilterBy: any) => {
			if (secondFieldToFilterBy) {
				return (
					itemToFilterBy[fieldToFilterBy][secondFieldToFilterBy] ===
					itemToFilter[fieldToFilterBy][secondFieldToFilterBy]
				);
			}

			if (fieldToFilterBy) {
				return itemToFilterBy[fieldToFilterBy] === itemToFilter[fieldToFilterBy];
			}

			return itemToFilterBy === itemToFilter;
		})
	);
};

export const mixArrayWithAnotherArray = (arrayToMix: any[], arrayToMixBy: any[], fieldToFilterBy: string) => {
	return arrayToMix.map((val) => {
		const toMixedValue = arrayToMixBy.find((val2) => val[fieldToFilterBy] === val2[fieldToFilterBy]);
		if (toMixedValue) {
			return { ...val, ...toMixedValue };
		} else {
			return val;
		}
	});
};

export const checkIfArraysAreEqual = (arr1, arr2, key): boolean => {
	if (arr1.length !== arr2.length) {
		return false;
	}

	const sortedArr1 = sortBy(arr1, [key]);
	const sortedArr2 = sortBy(arr2, [key]);

	return isEqual(sortedArr1, sortedArr2);
};

export const reorderItemInList = <T>(list: T[], itemStartIndex: number, itemEndIndex: number): T[] => {
	const result: T[] = Array.from(list);
	const [removed] = result.splice(itemStartIndex, 1);
	result.splice(itemEndIndex, 0, removed);
	return result;
};
