import React, { useContext } from 'react';
import { IDashboardTabItem } from '@interfaces/IDashboardTabItem';
import { DashboardTabTypes } from '@interfaces/DashboardTabTypes.enum';
import { DashboardTabsSection } from '@src/Components/DashboardSections/DashboardTabsSection/DashboardTabsSection';
import { IMergedFloorView } from '@src/interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '@store/slices';
import { IMinimalSafetyEventForClient } from '@interfaces/ISafetyEvent';
import { IMainPageWidgetActivityData } from '@interfaces/IMainPageWidgetActivityData';
import { filterFloorActivities, getDashboardTabsItems } from '@src/Components/MainPageSections/utils';
import { IManagerMergedTag } from '@shared/interfaces/IManagerMergedTag';
import { WorkplanWidget } from '@src/Components/WorkplanWidgetComponents/WeeklyWidget/WorkplanWidget/WorkplanWidget';
import { Alerts } from '@src/Components/Safety/Alerts';
import { compact } from 'lodash';
import { IWorkerMergedTag } from '@interfaces/IWorkerMergedTag';
import { FloorWorkforceSection } from '@src/Components/MainPageSections/FloorView/FloorWorkforceSection/FloorWorkforceSection';
import { filterFloorVisibleManagers, filterVisibleUtilitiesTags } from '@utils/generalUtils';
import classes from './styles.module.scss';
import { getProjectInitialSelectedTab } from '@utils/projectAccessLevel.utils';
import { selectActiveMainPageLayout } from '@store/slices/mainPageLayout.slice';
import { updateMainPageLayout } from '@store/thunks/mainPageLayout.thunks';
import { MainPageContext } from '@src/Components/Pages/MainPage';
import { MainPageIssuesTab } from '@src/Components/MainPageIssuesTab/MainPageIssuesTab';
import { IIssue } from '@shared/interfaces/IIssue';
import { translationService } from '@src/servicesInitializers';
import { IMergedEquipmentView } from '@interfaces/IMergedEquipmentView';
import { selectIsLoadingDashboardData } from '@store/slices/dashboard.slice';
import { IUtilityMergedTag } from '@interfaces/IUtilityMergedTag';
import { selectSelectedUtilities } from '@store/slices/utilities.slice';

interface IFloorViewContentSectionProps {
	floorEquipment: IMergedEquipmentView[];
	floorVisibleManagers: IManagerMergedTag[];
	visibleWorkersOnSite: IWorkerMergedTag[];
	selectedFloor: IMergedFloorView;
	isHoistFloor?: boolean;
}

export const FloorViewContentSection = (props: IFloorViewContentSectionProps) => {
	const dispatch = useDispatch();
	const currentActiveMainPageLayout: DashboardTabTypes | null = useSelector(selectActiveMainPageLayout);
	const selectedTabType: DashboardTabTypes = getProjectInitialSelectedTab(currentActiveMainPageLayout);

	const visibleManagers = useSelector((state: IRootState) => state.managersReducer.visibleManagers);
	const selectedUtilities: IUtilityMergedTag[] = useSelector(selectSelectedUtilities);
	const visibleFloorManagers: IManagerMergedTag[] = filterFloorVisibleManagers(
		props.selectedFloor.managers,
		visibleManagers
	);
	const visibleSafetyEvents: IMinimalSafetyEventForClient[] = useSelector(
		(state: IRootState) => state.safetyEventsReducer.safetyEvents
	).filter((event) => event.floorId === props.selectedFloor.floorId);
	const floorUtilities: IUtilityMergedTag[] | undefined = props.selectedFloor.utilities;
	const visibleUtilitiesTags: IUtilityMergedTag[] = filterVisibleUtilitiesTags(
		floorUtilities || [],
		selectedUtilities
	);

	const visibleManagerTags: IManagerMergedTag[] = compact(
		props.selectedFloor.managers.filter((manager) =>
			visibleManagers.some((visibleManager) => visibleManager._id === manager.manager._id)
		)
	);
	const isFetchingDashboardPersonnelData: boolean = useSelector(selectIsLoadingDashboardData);
	const { issues, widgetActivities } = useContext(MainPageContext)!;
	const widgetFloorActivities: IMainPageWidgetActivityData[] = filterFloorActivities(
		widgetActivities,
		props.selectedFloor.floorId
	);

	const floorIssues: IIssue[] | undefined = issues?.filter((issue) =>
		issue.locations?.some((location) => location.floor.floorId === props.selectedFloor.floorId)
	);

	const handleTabClick = (selected: DashboardTabTypes) => {
		updateMainPageLayout(selected)(dispatch);
	};

	const getTabContent = (): JSX.Element => {
		switch (selectedTabType) {
			case DashboardTabTypes.workForce:
				return (
					<FloorWorkforceSection
						floor={props.selectedFloor}
						floorEquipment={props.floorEquipment}
						isHoistFloor={props.isHoistFloor}
						workerTags={props.visibleWorkersOnSite}
						managerTags={visibleManagerTags}
						floorUtilities={visibleUtilitiesTags}
					/>
				);
			case DashboardTabTypes.activities:
				return (
					<WorkplanWidget widgetActivities={widgetFloorActivities} widgetHeaderClass={classes.widgetHeader} />
				);
			case DashboardTabTypes.issues:
				return (
					<MainPageIssuesTab
						issues={floorIssues}
						emptyStateText={translationService.get('mainPage_issuesTab_floorPlan_emptyState')}
						quickFiltersClassName={classes.widgetHeader}
					/>
				);
			case DashboardTabTypes.safetyEvents:
				return <Alerts floorId={props.selectedFloor.floorId} />;
			default:
				return <></>;
		}
	};

	const dashboardItems: IDashboardTabItem[] = getDashboardTabsItems(
		isFetchingDashboardPersonnelData ? undefined : props.visibleWorkersOnSite.length,
		isFetchingDashboardPersonnelData ? undefined : visibleFloorManagers.length,
		widgetFloorActivities?.length,
		visibleSafetyEvents.length,
		floorIssues?.length
	);

	return (
		<div className={classes.contentSectionContainer}>
			<div className={classes.tabsSection}>
				<DashboardTabsSection dashboardItems={dashboardItems} onTabClick={handleTabClick} />
			</div>
			<div className={classes.tabContentContainer}>{getTabContent()}</div>
		</div>
	);
};
