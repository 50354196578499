import React, { useEffect } from 'react';
import { TrusstorTooltip } from '@shared/components/TrusstorTooltip/TrusstorTooltip';
import { getSvgElement, setElementStyle } from '@shared/utils/svg.utils';
import { find, map, split } from 'lodash';
import { FloorMixedEntitiesTooltip } from '@src/Components/MainPageSections/FloorView/FloorMixedEntitiesTooltip/FloorMixedEntitiesTooltip';
import classes from './styles.module.scss';
import { IWorkerMergedTag } from '@interfaces/IWorkerMergedTag';
import { IMergedAreaViewWithEquipment } from '@interfaces/IMergedAreaViewWithEquipment';
import { IFloorSvgPoints } from '@interfaces/IFloorSvgPoints';
import { FloorViewAreaPatches } from '../MainPageSections/FloorViewAreaPatches';
import { svgSelectedStyle } from '@src/constants/genericStyles.constants';
import { translationService } from '@src/servicesInitializers';

interface FloorSvgRendererProps {
	svgPoints: IFloorSvgPoints | null;
	floorSvg: string;
	floorId: string;
	svgId: string;
	visibleAreasWithVisibleTrades: IMergedAreaViewWithEquipment[];
	visibleWorkersOnSite: IWorkerMergedTag[];
	floorLoading: boolean;
	floorPlanDisplayElement: HTMLDivElement | null;
}

export const FloorSvgRenderer = (props: FloorSvgRendererProps) => {
	const isRtl: boolean = translationService.getIsRtl();

	useEffect(() => {
		if (!props.floorPlanDisplayElement && props.svgPoints && props.floorLoading) {
			renderDetailsOnFloor(props.visibleAreasWithVisibleTrades, props.svgPoints, props.floorId);
		}
	}, [props.floorPlanDisplayElement, props.svgPoints, props.floorLoading]);

	const renderDetailsOnFloor = (
		areas: IMergedAreaViewWithEquipment[],
		svgPoints: IFloorSvgPoints,
		floorId: string
	) => {
		const svgElement: HTMLElement = getSvgElement(props.svgId);
		areas.forEach((area) => {
			const withFloorId = `f${floorId}area${area.areaId}`;
			const withoutFloorId = `area${area.areaId}`;

			setElementStyle(
				svgElement?.querySelector(`#${withFloorId}`) ? withFloorId : withoutFloorId,
				area.activeProfessions!.length > 0 || area.managers.length > 0 || area.utilities.length > 0
					? svgSelectedStyle
					: {
							opacity: '',
							fillOpacity: '',
							fill: '',
							stroke: '',
							strokeWidth: '',
						},
				props.svgId
			);
		});

		const renderedDetails = map(areas, (area, index) => {
			const workersElement = find(svgPoints.workersPointsArray, (workersField) => {
				const workersArr = split(workersField.id, 'workers');
				return area.areaId === workersArr[workersArr.length - 1];
			});

			const areaEntities = [
				...(area.activeProfessions ?? []),
				...area.managers,
				...(area.equipment ?? []),
				...(area.utilities ?? []),
			];

			return (
				workersElement &&
				areaEntities.length > 0 && (
					<TrusstorTooltip
						tooltipClasses={classes.mixedEntitiesTooltip}
						showAlways
						placement={isRtl ? 'left-start' : 'right-start'}
						key={`entities_${area.areaId}_${index}_tooltip_container`}
						tooltipComponent={
							<FloorMixedEntitiesTooltip
								visibleWorkersOnSite={props.visibleWorkersOnSite}
								activeProfessions={area.activeProfessions}
								managers={area.managers}
								equipment={area.equipment}
								floorId={area.floorId}
								title={area.areaNick}
								utilities={area.utilities}
							/>
						}
					>
						<FloorViewAreaPatches
							activeProfessions={area.activeProfessions}
							managers={area.managers}
							equipment={area.equipment}
							utilities={area.utilities}
							workersElement={workersElement}
							areaId={area.areaId}
							scaffoldingSide={area.scaffoldingSide}
						/>
					</TrusstorTooltip>
				)
			);
		});

		return renderedDetails;
	};

	return (
		<div data-testid={'floorSvgContainer'}>
			<div
				style={{ visibility: props.floorLoading ? 'hidden' : 'visible' }}
				dangerouslySetInnerHTML={{ __html: props.floorSvg }}
				data-testid={'svg'}
			/>
			{!props.floorLoading &&
				props.svgPoints &&
				renderDetailsOnFloor(props.visibleAreasWithVisibleTrades, props.svgPoints, props.floorId)}
		</div>
	);
};
