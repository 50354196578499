import React from 'react';
import classes from './styles.module.scss';
import classnames from 'classnames';

interface ILoaderProps {
	big?: boolean;
	size?: number;
	className?: string;
}

const Loader = ({ big = false, size = 30, className }: ILoaderProps) => {
	return (
		<div
			className={classnames(classes.loading, className)}
			style={big ? { height: 60, width: 60 } : { height: size, width: size }}
		/>
	);
};

export { Loader };
