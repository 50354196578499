import React, { useState } from 'react';
import { IProfession } from '@interfaces/IProfession';
import classes from './styles.module.scss';
import { DisplaySettingsPicker } from '../DisplaySettingsPicker/DisplaySettingsPicker';
import { IProfessionDisplaySettings, ProfessionSettingType } from '@shared/interfaces/IProfessionDisplaySettings';
import { getDefaultProfessionDisplaySettings } from './ProfessionTableDisplaySettingsMenu.utils';

interface IProfessionTableDisplaySettingsMenuProps {
	professions: IProfession[];
	projectId: string;
	onSettingChange: (newDisplaySettings: IProfessionDisplaySettings) => void;
}

export const ProfessionTableDisplaySettingsMenu = (props: IProfessionTableDisplaySettingsMenuProps) => {
	const [isCheckedPropertyObj, setIsCheckedPropertyObj] = useState<Record<keyof IProfessionDisplaySettings, boolean>>(
		getDefaultProfessionDisplaySettings(props.professions, props.projectId)
	);

	const isPropertyDisabled = (propertyName: keyof IProfessionDisplaySettings): boolean => {
		const numberOfTrueValues: number = Object.values(isCheckedPropertyObj).reduce(
			(acc, val) => acc + (val ? 1 : 0),
			0
		);
		return isCheckedPropertyObj[propertyName] && numberOfTrueValues === 1;
	};

	const isPropertyChecked = (propertyName: keyof IProfessionDisplaySettings): boolean => {
		return isCheckedPropertyObj[propertyName];
	};

	const onSettingCheckboxChange = (isChecked: boolean, propertyName: keyof IProfessionDisplaySettings) => {
		setIsCheckedPropertyObj((prevState) => {
			const updatedState = {
				...prevState,
				[propertyName]: isChecked,
			};
			props.onSettingChange({
				...(updatedState.trade && { trade: ProfessionSettingType.TEXT }),
				...(updatedState.specialty && { specialty: ProfessionSettingType.TEXT }),
				...(updatedState.contractor && { contractor: ProfessionSettingType.TEXT }),
			});
			return updatedState;
		});
	};

	return (
		<div className={classes.container}>
			<DisplaySettingsPicker
				hideSpecialty={!props.professions.some((profession) => profession.specialty)}
				hideContractor={!props.professions.some((profession) => profession.contractor)}
				isDisabled={isPropertyDisabled}
				isPropertyChecked={isPropertyChecked}
				onCheckboxChange={onSettingCheckboxChange}
				hideBorder
			/>
		</div>
	);
};
