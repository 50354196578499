import { createContext, useCallback } from 'react';
import { IMainPageWidgetActivityData } from '../../../interfaces/IMainPageWidgetActivityData';

export interface IWidgetContext {
	onActivityChange: (activity: IMainPageWidgetActivityData, isDeleted?: boolean) => void;
}

export const WidgetContext = createContext<IWidgetContext | undefined>(undefined);

export const useWidgetContextValue = (setWidgetActivities: any, onActivityUpdate: () => void): IWidgetContext => {
	const onActivityChange = useCallback((activity: IMainPageWidgetActivityData, isDeleted?: boolean): void => {
		onActivityUpdate();
		setWidgetActivities((prevWidgetActivities) => {
			if (!prevWidgetActivities) {
				return;
			}

			const index: number = prevWidgetActivities.findIndex(
				(prevActivity) => prevActivity.groupId === activity.groupId
			);

			if (index === -1) {
				return;
			}

			const newWidgetActivities: IMainPageWidgetActivityData[] = [...prevWidgetActivities];
			if (isDeleted) {
				newWidgetActivities.splice(index, 1);
				return newWidgetActivities;
			}

			newWidgetActivities[index] = activity;
			return newWidgetActivities;
		});
	}, []);

	return {
		onActivityChange,
	};
};
