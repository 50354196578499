import React, { useCallback, useState } from 'react';
import { useElementIntersecting } from '../../../../../shared/hooks/useElementIntersecting.hooks';
import { ISideTextPosition, MatrixHeader } from '../cellComponents/MatrixHeader/MatrixHeader';
import { IMatrixOptions } from '../interfaces/IMatrixOptions';
import classes from './styles.module.scss';
import { IProfession } from '@shared/interfaces/IProfession';
import { useMatrixColHeaderDnd } from './useMatrixColHeaderDnd.hook';
import { IMatrixColHeader } from '../Matrix';
import classnames from 'classnames';

interface IMatrixColHeaderProps {
	hoveredSequenceItemId?: string;
	headerData: IMatrixColHeader;
	colHeaderStyle: React.CSSProperties;
	handleStarClick?: (headerData: IMatrixColHeader) => Promise<void>;
	hideStarredSection?: boolean;
	options: IMatrixOptions;
	onHeaderIntersectingChange?: (headerId: string, isIntersecting: boolean) => void;
	headerIndex: number;
	handleProfessionUpdate?: (sequenceId: string, profession: IProfession) => void;
	moveCard: (dragIndex: number, hoverIndex: number) => void;
	onDragFinish: (dragIndex: number) => void;
	onDragStart: (dragIndex: number) => void;
	showSequenceMenu?: boolean;
	handleDescriptionChange?: (text: string, sequenceItemId: string) => void;
	deleteSequenceItem?: (sequenceId: string) => void;
	isTextEditable?: boolean;
	handleEditSequenceItem?: (
		sequenceItemId: string,
		profession: IProfession,
		description: string,
		isStarred: boolean
	) => void;
}

export const MatrixColHeader = React.memo(
	({
		hoveredSequenceItemId,
		deleteSequenceItem,
		handleProfessionUpdate,
		handleDescriptionChange,
		headerData,
		colHeaderStyle,
		options,
		handleStarClick,
		hideStarredSection,
		showSequenceMenu,
		onHeaderIntersectingChange,
		headerIndex,
		moveCard,
		onDragFinish,
		onDragStart,
		isTextEditable,
		handleEditSequenceItem,
	}: IMatrixColHeaderProps) => {
		const [headerElement, setHeaderElement] = useState<HTMLElement | null>(null);
		const { isDragging, handlerId } = useMatrixColHeaderDnd(
			headerElement,
			headerIndex,
			headerData.id,
			onDragStart,
			onDragFinish,
			moveCard,
			options.disableDnd
		);

		const opacity: number = isDragging ? 0 : 1;

		const intersectionCallback = useCallback(
			(isIntersecting: boolean) => {
				onHeaderIntersectingChange?.(headerData.id, isIntersecting);
			},
			[headerData.id, isDragging]
		);

		if (onHeaderIntersectingChange) {
			useElementIntersecting(
				headerElement,
				intersectionCallback,
				{
					threshold: 0,
					rootMargin: `0px`,
					root: null,
				},
				true
			);
		}

		const getIsHeaderHovered = () => {
			if (!hoveredSequenceItemId) return false;
			return headerData.sequenceItemId === hoveredSequenceItemId;
		};

		return (
			<div
				className={classnames({
					[classes.colHeader]: true,
					[classes.colHeaderHover]: !options.disableDnd,
					[classes.disableHover]: options.disableColHeaderHover,
					[classes.colHeaderCellHovered]: getIsHeaderHovered(),
				})}
				style={{ ...colHeaderStyle, opacity }}
				ref={setHeaderElement}
				data-handler-id={handlerId}
			>
				{!options.colHeaderComponent ? (
					<MatrixHeader
						deleteSequenceItem={deleteSequenceItem}
						key={headerData.id}
						handleDescriptionChange={(text) => handleDescriptionChange?.(text, headerData.sequenceItemId)}
						currentOrderIndex={headerData.orderIndex}
						showSequenceMenu={showSequenceMenu}
						handleProfessionUpdate={handleProfessionUpdate}
						sequenceItemId={headerData.sequenceItemId}
						sequenceType={headerData.sequenceType}
						profession={headerData.profession}
						isTextEditable={isTextEditable}
						text={headerData.description}
						total={headerData.totalItems}
						fraction={headerData.doneItems}
						secondaryTextPosition={ISideTextPosition.BOTTOM}
						isStarred={headerData.isStarred}
						handleStarClick={handleStarClick ? () => handleStarClick!(headerData) : undefined}
						showStarredSection={!hideStarredSection}
						handleEditSequenceItem={handleEditSequenceItem}
					/>
				) : (
					<options.colHeaderComponent data={headerData} />
				)}
			</div>
		);
	}
);
